import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import BigButton from '../Buttons/BigButton'
import { COLORS, UserTypes } from '../variables';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import Blocked_Notif from '../Modal/Blocked_Notif';
import { logout } from '../../backend-functions/Auth';
import { changeStatus, getLatestNotificationsForUser } from '../../backend-functions/Notifications';


const OtherLinks = (props) => {
  const navigate = useNavigate();
  const {userDetails, dispatch} = useContext(AuthContext);
  const [ notifs, setNotifs ] = useState([])
  const [ showNotif, setShowNotif ] = useState(false)
  const [ isUnread, setIsUnread ] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const data = await getLatestNotificationsForUser(userDetails.uid, setIsUnread);

      setNotifs(data)
    }

    if(userDetails && userDetails.uid)
      fetchData()
  }, [])

  const handleLogout = async () => {
    const response = await logout();
    if(response){
      dispatch({type: "LOGOUT"});
      alert("You have successfully logout!");
      navigate('/');
    }
  }

  const redirectNotif = async (id, url) => {
    await changeStatus(userDetails.uid, id, true, 'viewed')

    navigate(url)
  }
  return (
    <Title>
      <Show isShown = {props.isShown}>
        {userDetails && userDetails.typehierarchy < UserTypes.Seller && <Link href='/dashboard' color={props.color}> {userDetails.usertype} View</Link>}
        <Link href='/' color={props.color}> {userDetails && userDetails.usertype === 'Seller' ? 'Dashboard' : 'Explore'} </Link>
        {userDetails && userDetails.usertype === 'Seller' && <Link href='/create?postcode=' color={props.color}> Create Listing</Link>}
        <Link href='/about-us' color={props.color}> About Us</Link>
        {userDetails && userDetails.uid && <Link onClick={handleLogout} color={props.color}> Log Out</Link>}
      </Show>
      <Link onClick={()=> setShowNotif(!showNotif)} color={props.color} style={{position: 'relative'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={COLORS.white} class="bi bi-bell-fill" viewBox="0 0 16 16">
          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
        </svg>
        <Circle unread={isUnread}/>
      </Link>
      <Link href='/account' color={props.color}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={COLORS.white} class="bi bi-person-circle" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>
      </Link>

      <Notifications showNotif={showNotif}>
        {notifs.length > 0 && notifs.map((n) => (
          <Notif key={n.id} onClick={()=>redirectNotif(n.id, n.data.url)} color={COLORS.blue} viewed={n.viewed}>
            {n.body}
          </Notif>
        ))}
        {notifs.length < 1 && <Notif color={COLORS.blue} viewed={true}>You have no new notifications</Notif>}
      </Notifications>
      <Blocked_Notif/>
    </Title>
  )
}

const Title = styled.div`
    color: white;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 3%;
`;

const Show = styled.div`
  @media only screen and (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0px;
    display: ${props => (props.isShown ? "flex" : "none")};
    background: ${COLORS.blue}
  }
`;

const Link = styled.a`
    color: ${props => (props.color ? props.color : COLORS.white)};
    text-decoration: none;
    padding-right: 50px;
    cursor: pointer;

    @media only screen and (max-width: 860px) {
      padding: 10px 20px
    }
`;

const Circle = styled.div`
    background: ${COLORS.red};
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    display: ${props => (props.unread ? "block" : "none")};

    @media only screen and (max-width: 860px) {
      top: 12px;
    }
`;

const Notifications = styled.div`
    background: ${COLORS.white};
    position: absolute;
    top: 50px;
    display: ${props => (props.showNotif ? "flex" : "none")};
    flex-direction: column;
    width: 25%;
    border: 1px solid ${COLORS.blue};

    @media only screen and (max-width: 860px) {
      width: 100%;
    }
`

const Notif = styled.a`
  color: ${props => (props.color ? props.color : COLORS.white)};
  text-decoration: none;
  padding-right: 50px;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: ${props => !props.viewed ? 'bold' : 'normal'};
`;

export default OtherLinks
