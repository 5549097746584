import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../variables';

const PaginationContainer = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageItem = styled.li`
  margin: 0 5px;
  cursor: pointer;
  color: ${COLORS.blue};
  border: 2px solid ${COLORS.blue};
  padding: 5px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;


  ${({ active }) =>
    active &&
    `
    background: ${COLORS.blue};
    color: ${COLORS.yellow};
  `}
`;

const PaginationButtons = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <PageItem
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PageItem>
      );
    }
    return pages;
  };

  return (
    <PaginationContainer>
      {renderPages()}
    </PaginationContainer>
  );
};

export default PaginationButtons;
