import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import GenieScore from './GenieScore'
import bg from '../../img/profile-1.jpg'
import SmallButton from '../Buttons/SmallButton'
import SmallAddBidding from '../Forms/SmallAddBidding'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { calculateRemainingTime } from '../../backend-functions/custom-functions'
import DealButton from '../Buttons/DealButton'
import StatusToolTip from '../Modal/StatusToolTip'

const Item = ({data, border, type, showBid, winningId, ...props}) => {
  const exclusions = ['id', 'sellerid', 'dealListing', 'invoiceStatus', 'StripeAdmin', 'winningAgent', 'Link', 'yourbid', 'type', 'hideStatus', 'place', 'Genie Score', 'img', 'Restore', 'Block', 'Approve', 'Deny', 'View', 'typehierarchy', 'distance', 'endDate'];

  const naviagate = useNavigate();
  const { userDetails } = useContext(AuthContext);

  const [ status, setStatus ] = useState('')

  const filteredKeys = Object.keys(data).filter((key) => !exclusions.includes(key));
  let keyCount = filteredKeys.length;
    if (data.hasOwnProperty('img')) {
        keyCount++;
    }

    if (data.hasOwnProperty('Restore') || data.hasOwnProperty('Approve')) {
        keyCount++;
    }

    if (data.hasOwnProperty('Block') || data.hasOwnProperty('Deny')) {
        keyCount++;
    }
    if (data.hasOwnProperty('View') || data.hasOwnProperty('Link')) {
        keyCount++;
    }
    if (showBid || data.hasOwnProperty('StripeAdmin')){
        keyCount++;
    }
  let width = 100 / keyCount;
  const id = 'id';
  const url = (type ? `/${type}/${data.id}` : null);

  let color = '';
    switch (data.hideStatus) {
    case 'Bidding Ongoing':
        color = 'orange';
        break;
    case 'List Ended':
        color = 'red';
        showBid = null;
        if(data['Your Bidding'] && data['Top Bidding'] === data['Your Bidding']) color = 'green';
        if(showBid !== 'Deal' && userDetails.typehierarchy === 3) showBid = 'Go to Portal';
        break;
    case 'Deal Ongoing':
        color = 'orange';
        if(showBid !== 'Deal' && userDetails.typehierarchy == 3 && data.winningAgent === userDetails.uid) showBid = 'Go to Portal';
        if(showBid !== 'Deal' && userDetails.typehierarchy == 3 && data.winningAgent === userDetails.uid) color = 'green'
        break;
    case 'Sold Property':
        color = 'green';
        showBid = (data.invoiceStatus && data.invoiceStatus !== 'PAID') ? 'Pay Commission' : null;
        break;
    }

    switch (data.Status) {
        case 'Bidding Ongoing':
            color = 'orange';
            break;
        case 'List Ended':
            color = 'red';
            showBid = null;
            if(data['Your Bidding'] && data['Top Bidding'] === data['Your Bidding']) color = 'green';
            if(showBid !== 'Deal' && userDetails.typehierarchy === 3) showBid = 'Go to Portal';
            break;
        case 'Deal Ongoing':
            color = 'orange';
            if(showBid !== 'Deal' && userDetails.typehierarchy == 3 && data.winningAgent === userDetails.uid) showBid = 'Go to Portal';
            if(showBid !== 'Deal' && userDetails.typehierarchy == 3 && data.winningAgent === userDetails.uid) color = 'green'
            break;
        case 'Sold Property':
            color = 'green';
            showBid = (data.invoiceStatus && data.invoiceStatus !== 'PAID') ? 'Pay Commission' : null;
            break;
        }

    const [typecolor, setTypeColor] = useState('default');
    const [endTime, setEndTime] = useState('');

    // remaining time 
    useEffect(() => {
        if (data.endDate) {
          const interval = setInterval(() => {
            setTypeColor('default');

            calculateRemainingTime(data.endDate)
              .then((remainingTime) => {
                let string = '';

                if (remainingTime.days > 0) {
                  string += remainingTime.days + 'd ';
                }
                if (remainingTime.hours > 0) {
                  string += remainingTime.hours + 'h ';
                }
                if (remainingTime.minutes > 0) {
                  string += remainingTime.minutes + 'm ';
                }
                if (remainingTime.seconds > 0) {
                  string += remainingTime.seconds + 's';
                }
                if (remainingTime.days <= 0) {
                  setTypeColor('danger');
                }
            
                setEndTime('('+string+')');
                if (
                  remainingTime.days <= 0 &&
                  remainingTime.hours <= 0 &&
                  remainingTime.minutes <= 0 &&
                  remainingTime.seconds <= 0
                ) {
                  clearInterval(interval); // Stop the interval when countdown reaches 0
                  setEndTime('');
                }
              })
              .catch((error) => {
                console.error(error); // Log any errors that occur during the promise chain
                clearInterval(interval); // Stop the interval in case of an error
              });
          }, 1000); // Recurring every 1 second
      
          return () => {
            clearInterval(interval); // Clear the interval when component is unmounted or when `data` changes
          };
        }
      }, [data]); 

      useEffect(()=>{
        if(data['Status'] === 'List Ended' && data['Your Bidding'] && data['Top Bidding'] === data['Your Bidding']){
            setStatus('Waiting for Seller')
        }
        if(data['Status'] === 'Deal Ongoing' && data.winningAgent && data.winningAgent === userDetails.uid){
            setStatus('Won the Listing')
        }
        else{
            setStatus(data['Status'])
        }
      }, [])

  return (
    <Container href={url} border={border}>
        <Label color={color}></Label>
        <Cont type={data.type} url={url}>
            {Object.keys(data).map((key) => {
                if(!exclusions.includes(key)){
                    return (
                        <Text type={data.type} width={width} border={border} color={typecolor} name={key} status={data.Status}>
                            {((winningId && winningId === data.id && key === 'Rank') || (data.winningAgent && data.winningAgent === userDetails.uid && key === 'Your Bidding')) &&
                                <Crown>
                                    👑
                                </Crown>
                            }
                            { key === 'Ends On' && endTime !== '' && <div>{endTime}</div>}
                            
                            {key === 'Status' && status !== '' ? (
                                <>
                                    <Key>{key}</Key>
                                    {userDetails.typehierarchy === 3 ? (
                                        <StatusToolTip text={status} mobilePosition="top">
                                            {status}
                                        </StatusToolTip>
                                    ) : (
                                        status
                                    )}
                                </>
                            ) : (
                                <>
                                    <Key>{key}</Key>
                                    {data[key]}
                                </>
                            )}


                            { key === 'Your Bidding' ? (<TinyText place={data.place}>
                                {data.place}
                            </TinyText>) : ''}
                        </Text>
                    );
                }

                if (key === "img" && data[key] !== "") {
                    return <Image src={bg} />
                }else if(key === "img" && data[key] === ""){
                    return <Image />
                }

                if (key === "Genie Score") {
                    return <Text type={data.type} width={width} border={border}><Key>{key}</Key><GenieScore percent={data[key]} /></Text>
                }

                // for the buttons
                if(key === "Restore" || key === "Approve"){
                    return <Link width={width} style={{display: 'flex'}}>
                        <SmallButton
                            bgColor={COLORS.green}
                            color={COLORS.blue}
                            text={key}
                            onClick={(event) => {
                                event.preventDefault();
                                if (typeof data[key] === 'function' || userDetails.typehierarchy <= data['typehierarchy']) {
                                data[key]();
                                }
                            }}
                            disabled={typeof data[key] !== 'function' || userDetails.typehierarchy > data['typehierarchy']} // Disable the button if data[key] is not a function
                        />
                    </Link>
                }else if(key === "Block" || key === "Deny"){
                    return <Link width={width} style={{display: 'flex'}}>
                        <SmallButton 
                            bgColor={COLORS.red} 
                            color={COLORS.white} 
                            text={key} 
                            onClick={(event) => {
                                event.preventDefault();
                                if (typeof data[key] === 'function' || userDetails.typehierarchy <= data['typehierarchy']) {
                                data[key]();
                                }
                            }}
                            disabled={typeof data[key] !== 'function' || userDetails.typehierarchy > data['typehierarchy']} // Disable the button if data[key] is not a function
                        />
                    </Link>
                }else if(key === "View"){
                    return <Link width={width} style={{display: 'flex'}} href={`${data[key]}/${data[id]}`}>
                        <SmallButton 
                            bgColor={COLORS.blue} 
                            color={COLORS.white} 
                            text={key}/>
                        </Link>
                }
                else if(key === "Link"){
                    return <Text type={data.type} width={width} border={border} color={typecolor} name={key} status={data.Status}>
                    <Link style={{display: 'flex'}} href={data[key]} target='_blank'>
                        <SmallButton 
                            bgColor={COLORS.blue} 
                            color={COLORS.white} 
                            text={key}/>
                        </Link>
                    </Text>
                }
            })}

            {showBid && (
            <Text width={width} stop={true} style={{ whiteSpace: 'normal' }}>
                {(() => {
                switch (showBid) {
                    case 'Go to Portal':
                        return (
                            data.dealListing && data.winningAgent && data.winningAgent === userDetails.uid ? (
                            <BidBtn href={`/portal/${data.dealListing}`}>{showBid}</BidBtn>
                            ) : null
                        );
                    case 'Pay Commission':
                    return (
                        data.dealListing && data.winningAgent && data.winningAgent === userDetails.uid ? (
                        <BidBtn href={`/portal/${data.dealListing}`}>{showBid}</BidBtn>
                        ) : null
                    );
                    case 'Deal':
                    return <DealButton data={data} {...props} />;
                    case null :
                    return null;
                    default:
                    return <SmallAddBidding data={data} showBid={showBid} width={width} />;
                }
                })()}
            </Text>
            )}


            {data.hasOwnProperty('StripeAdmin') &&(
                <Text width={width} stop={true} style={{ whiteSpace: 'normal' }}>
                {
                    data.StripeAdmin !== '' ? (
                        <BidBtn href={`https://dashboard.stripe.com/payments/${data.StripeAdmin}`} target='_blank'>
                            View in Stripe
                        </BidBtn> 
                    ) : null
                }
                </Text>
            )}
        </Cont>
    </Container>
  )
}

const Container = styled.a`
    border-bottom: ${props => (props.border == "full" ? '' : '1px solid' + COLORS.gray)};
    display: flex;
    // pointer-events: ${props => (props.href ? 'auto' : 'none')};
    width: 100%;

    @media only screen and (max-width: 780px) {
        margin: 20px 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
`;

const Cont = styled.div`
    padding: 10px;
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    position: relative;

    &:hover{
        background-color: ${props => (props.type != "header" && props.url ? COLORS.gray : '')};
    }

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

const Label = styled.div`
    background-color: ${props => props.color};
    width: 10px;
`;

const Crown = styled.span`
    position: absolute;
    top: -10px;
    font-size: 24px;

    @media only screen and (max-width: 780px) {
        top: 9px;
    }
`;

const Text =  styled.div`
// border: 2px solid red;
    font-weight: ${props => (props.type === "header" ? 'bold' : '')};
    margin: 0;
    // white-space: nowrap;
    text-overflow: ellipsis;
    width: ${props => props.width ? props.width + '%' : '100%'};
    color: ${props => props.color === 'danger' && props.name === 'Ends On' && (!props.status || props.status === 'Bidding Ongoing') ? COLORS.red : COLORS.blue};
    text-align: center;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: ${props => props.name === 'Site' ? 'start' : props.name === 'Link' ? 'end' : 'center'};
    font-size: 18px;
    flex-direction: ${props => props.name === 'Your Bidding' ? 'row' : 'column'};

    @media only screen and (max-width: 780px) {
        width: 100%;
        flex-direction: ${props => props.stop ? 'row' : 'column'};
        position: relative;
    }
`;

const BidBtn = styled.a`
    background: ${COLORS.light_blue};
    color: ${COLORS.white};
    right: 10px;
    padding: 5px 0px;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    // display: none;

    @media only screen and (max-width: 780px) {
        display: block;
    }
`;

const Link = styled.a`
    // background: red;
    width: ${props => props.width + '%'};

    @media only screen and (max-width: 780px) {
        // font-size: 12px;
        width: 100%;
    }
`;

const TinyText = styled.span`
    margin: 0px 5px;
    font-size: 14px;
    color: ${props => props.place === '1st' || props.place === '2nd' || props.place === '3rd' ? COLORS.green : COLORS.red }
`;

const Image = styled.img`
    height: 38px;
    width: 38px;
    border-radius: 50%;
    object-fit: cover;
`;

const Key = styled.div`
    display: none;
    font-weight: bold;
    color: ${COLORS.blue};

    @media only screen and (max-width: 780px) {
        display: block;
    }
`;

export default Item
