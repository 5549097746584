import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'

const Modal = ({ isOpen, setOpen, children }) => {
    if (!isOpen) return null;

    const handleBackgroundClick = (event) => {
      if (event.target === event.currentTarget && setOpen) {
        setOpen(!isOpen);
      }
    };
    return (
      <ModalWrapper onClick={handleBackgroundClick}>
        <ModalContent>
          <Content>
            {children}
          </Content>
        </ModalContent>
      </ModalWrapper>
    );
  };

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.blue_faded};
`;

const ModalContent = styled.div`
  background-color: ${COLORS.white};
  padding: 20px 80px;
  border-radius: 15px;
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  @media only screen and (max-width: 780px) {
    width: 60%;
    padding: 20px 20px;
    // margin-top: 50%;
    // margin-bottom: 10%;
  }
`;

const Content = styled.div`
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export default Modal