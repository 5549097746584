import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'
import TopNavbar from '../components/Navbar/Navbar'
import GenieScore from '../components/Containers/GenieScore'
import AgentNumbers from '../components/Containers/AgentNumbers'
import { useParams } from 'react-router-dom'
import { getUser } from '../backend-functions/Users'

const AgentAnonymous = () => {
  const { id } = useParams();
  const [ agent, setAgent ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try{
        const user = await getUser(id);
        setAgent(user);
      } catch(error){
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      <Container height="10vh">
        <TopNavbar bgColor={COLORS.blue} btncolor={COLORS.yellow}></TopNavbar>
      </Container>

      {agent && <FlexCol>
        <IconContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" fill={COLORS.black_faded} class="bi bi-incognito" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="m4.736 1.968-.892 3.269-.014.058C2.113 5.568 1 6.006 1 6.5 1 7.328 4.134 8 8 8s7-.672 7-1.5c0-.494-1.113-.932-2.83-1.205a1.032 1.032 0 0 0-.014-.058l-.892-3.27c-.146-.533-.698-.849-1.239-.734C9.411 1.363 8.62 1.5 8 1.5c-.62 0-1.411-.136-2.025-.267-.541-.115-1.093.2-1.239.735Zm.015 3.867a.25.25 0 0 1 .274-.224c.9.092 1.91.143 2.975.143a29.58 29.58 0 0 0 2.975-.143.25.25 0 0 1 .05.498c-.918.093-1.944.145-3.025.145s-2.107-.052-3.025-.145a.25.25 0 0 1-.224-.274ZM3.5 10h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Zm-1.5.5c0-.175.03-.344.085-.5H2a.5.5 0 0 1 0-1h3.5a1.5 1.5 0 0 1 1.488 1.312 3.5 3.5 0 0 1 2.024 0A1.5 1.5 0 0 1 10.5 9H14a.5.5 0 0 1 0 1h-.085c.055.156.085.325.085.5v1a2.5 2.5 0 0 1-5 0v-.14l-.21-.07a2.5 2.5 0 0 0-1.58 0l-.21.07v.14a2.5 2.5 0 0 1-5 0v-1Zm8.5-.5h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Z"/>
            </svg>
        </IconContainer>
        <h2>{agent.username}</h2>
        <GenieScore percent={agent.genie_score} />
        <br/>
        <NumberContainer>
          <AgentNumbers id={id} />
        </NumberContainer>
      </FlexCol>}

    </div>
  )
}

const Container = styled.div`
    position: relative;
    height: ${ props => props.height};
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px 0px;
`;

const IconContainer = styled.div`
    border: 2px solid ${COLORS.black_faded};
    height: 86px;
    width: 86px;
    padding: 5px;
    border-radius: 50%;
`
const  NumberContainer = styled.div`
  width: 25%;

  @media only screen and (max-width: 780px) {
    width: 90%;
  }
`;

export default AgentAnonymous
