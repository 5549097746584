import { Timestamp, addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, serverTimestamp, updateDoc, where, onSnapshot, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import { calculateDistanceBetweenPostcodes, formatFirestoreTimestamp, generateRandomString, getNumberSuffix } from "./custom-functions";
import { sortBiddingMap } from "./Biddings";
import { changeStatus, getUser } from "./Users";
import { sendMessage } from "./Messages";
import { DefaultProPic } from "../components/variables";
import { createPrice, getInvoice } from "./Payments";
import { createNotification } from "./Notifications";

export const createListing = async (listingObj) => {
  try {
    const listing = await addDoc(collection(db, "listings"), {
      ...listingObj,
      biddings: [], // Make biddings an empty array
      isDelete: false,
      timestamp: serverTimestamp(),
    });

    // alert all the agents
    const usersRef = collection(db, 'users');

    const usersSnapshot = await getDocs(usersRef);

    usersSnapshot.forEach(async (doc) => {
      const user = doc.data();
      if(user.usertype === 'Agent' && user.country === listingObj.country){
        const notifObject = {
          body: 'A new listing('+ listingObj.postcode +') has been added in your area.',
          icon: '../public/logo.png',
          data: {
            url: '/',
            action: 'View Listing'
          }
        };
    
        // Create a notification for each agent without awaiting completion
        await createNotification(notifObject, user.uid);
      }
    });

    return listing;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const getListings = async ({keyword = '', limitVal = 0, type = '', postcode = '', seller = '', active = false, country = 'UK'}) => {
  try {
    const listingObj = [];

    const listingCollectionRef = collection(db, 'listings');
    let q = listingCollectionRef;

    const limitQuery = limitVal > 0 ? limit(limitVal) : '';

    // Check if seller is specified
    if (seller !== '') {
        q = query(
            q,
            where('owner_id', '==', seller),
            ...(limitQuery ? [limitQuery] : [])
        );
    }
    
    // Check if keyword is specified
    if (typeof keyword === 'string' && keyword !== '') {
        keyword = keyword.toUpperCase();
        q = query(
            q,
            where('postcode', '>=', keyword), // Start at keyword
            where('postcode', '<=', keyword + '\uf8ff'), // End at keyword + '\uf8ff' to match any suffix
            ...(limitQuery ? [limitQuery] : [])
        );
    }
    
    // Check if type is specified
    if (type !== '' && type !== 'All') {
        q = query(
            q,
            where('status', '==', type),
            ...(limitQuery ? [limitQuery] : [])
        );
    }
    
    // Check if country is specified
    if (country !== '' && country !== 'All') {
        q = query(
            q,
            where('country', '==', country),
            ...(limitQuery ? [limitQuery] : [])
        );
    }

    const querySnapshot = await getDocs(q);

    // Create an array to store the promises for getUser calls
    const getUserPromises = [];

    querySnapshot.forEach((doc) => {
      const listingData = doc.data();

      if(!active || (active && !listingData.isDelete)){
        const sortedBiddings = sortBiddingMap(listingData.biddings);
        const topBid = sortedBiddings.length > 0 ? sortedBiddings[0].bid + "%" : "No bids yet";
        const numBids = listingData.biddings.length;
        const distance = calculateDistanceBetweenPostcodes(listingData.postcode, postcode);

        // Get the promise for getUser and add it to the array
        const getUserPromise = getUser(listingData.owner_id).then((owner) => {
          const listingEndDate = formatFirestoreTimestamp(listingData.ends_on); // Assuming this returns a Date object
          const isEndDatePassed = new Date(listingEndDate) < new Date();

          const newObj = {
            id: doc.id,
            sellerid: listingData.owner_id,
            'Seller': owner.fname + " " + owner.lname,
            [listingData.country === 'US' ? 'Zip Code' : 'Post Code']: listingData.postcode,
            'No. of Bids': numBids,
            'Top Bidding': topBid,
            'Ends On': listingEndDate,
            type: isEndDatePassed ? 'danger' : 'default', // Check if the end date is past today
            Status: listingData.status,
            distance: distance,
            endDate: listingData.ends_on
          };
          listingObj.push(newObj);
        });

        getUserPromises.push(getUserPromise);
      }
    });

    // Wait for all getUser promises to resolve
    await Promise.all(getUserPromises);

    // sort the distance of the postcodes
    listingObj.sort((a, b) => a.distance - b.distance);

    return listingObj;
  } catch (error) {
    console.error("Error getting listings:", error);
    return null;
  }
};


export const getPortalLinks = async (keyword, limitVal) => {
  try {
    const portals = [];
    keyword = keyword.toUpperCase();

    let q = collection(db, 'deal_listings');

    if (limitVal) {
      q = query(q, limit(limitVal)); // Add limit condition directly to the query
    }

    q = query(q, orderBy('timestamp', 'desc'));

    const querySnapshot = await getDocs(q);

    const fetchListingData = async (doc) => {
      const data = doc.data();

      const seller = await getUser(data.seller_id);
      const agent = await getUser(data.agent_id);

      // Promisify getListingById
      const getListing = () => {
        return new Promise((resolve, reject) => {
          getListingById(
            data.listing_id,
            (listing) => resolve(listing),
            (error) => reject(error)
          );
        });
      };

      const listing = await getListing();

      if (!keyword || (listing && listing.postcode.includes(keyword))) {
        const portalEntry = {
          id: doc.id,
          Seller: seller.fname + ' ' + seller.lname,
          Agent: agent.fname + ' ' + agent.lname,
          [data.country === 'US' ? 'Zip Code' : 'Post Code']: listing ? listing.postcode + '('+ data.country +')' : 'Listing not found',
          Bid: data.percent_bid,
          'Offer Price': parseFloat(data.offer_price).toLocaleString('en-US', {
            style: 'currency',
            currency: data.country === 'US' ? 'USD' : 'GBP'
          }),
          'Sold Price': parseFloat(data.sold_price).toLocaleString('en-US', {
            style: 'currency',
            currency: data.country === 'US' ? 'USD' : 'GBP'
          }),
        };

        return portalEntry;
      }

      return null; // Return null for cases where the keyword does not match
    };

    const portalDataPromises = querySnapshot.docs.map(fetchListingData);
    const portalData = await Promise.all(portalDataPromises);

    // Filter out null entries (entries where the keyword does not match)
    const filteredPortalData = portalData.filter((entry) => entry !== null);

    portals.push(...filteredPortalData);

    return portals;
  } catch (error) {
    console.error('Error getting portal links: ', error);
    return null;
  }
};




export const getListingsofAgent = async ({ agent, keyword = '', limitVal = 0, country = 'UK' }) => {
  try {
    const listingObj = [];
    let additionalConditions = [];

    if (keyword) {
      keyword = keyword.toUpperCase();
      additionalConditions.push(where('postcode', '==', keyword));
    }

    if (limitVal) {
      additionalConditions.push(limit(limitVal));
    }

    let q = query(
      collection(db, 'listings'),
      ...additionalConditions,
      orderBy('timestamp', 'desc')
    );

    const querySnapshot = await getDocs(q);

    const filteredListings = querySnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(listing => listing.biddings.some(bidding => bidding.agentId === agent));

    const getUserPromises = filteredListings.map(async (listingData) => {
      const sortedBiddings = sortBiddingMap(listingData.biddings);
      const topBid = sortedBiddings.length > 0 ? sortedBiddings[0].bid + "%" : "No bids yet";
      const numBids = listingData.biddings.length;

      const dealListing = await getDealListingByListing(listingData.id);

      const userData = await getUser(listingData.owner_id);

      let invoiceData = null;

      if(dealListing){
        invoiceData = await getInvoice(dealListing.id, agent)
      }

      const newObj = {
        id: listingData.id,
        sellerid: listingData.owner_id,
        "Seller": userData.username,
        [country === 'US' ? "Zip Code" : "Post Code"] : listingData.postcode,
        "No. of Bids": numBids,
        "Top Bidding": topBid,
        "Your Bidding": listingData.biddings.find(bidding => bidding.agentId === agent)?.bid + "%",
        yourbid: listingData.biddings.find(bidding => bidding.agentId === agent)?.bid,
        "Ends On": formatFirestoreTimestamp(listingData.ends_on),
        "Status": listingData.status,
        endDate: listingData.ends_on,
        place: parseInt(sortedBiddings.findIndex(bidding => bidding.agentId === agent) + 1) + getNumberSuffix(parseInt(sortedBiddings.findIndex(bidding => bidding.agentId === agent) + 1)),
        dealListing: dealListing ? dealListing.id : null,
        winningAgent: dealListing ? dealListing.agent_id : null,
        invoiceStatus: invoiceData ? invoiceData.payment_status : null,
        type: "default",
      };

      listingObj.push(newObj);
    });

    // Wait for all getUser promises to resolve
    await Promise.all(getUserPromises);

    return listingObj;
  } catch (error) {
    console.error("Error getting listings:", error);
    return null;
  }
};


export const changeListingStatus = async (listingId, newStatus) => {
  try {
    const listingRef = doc(db, "listings", listingId);

    // Update the status field of the listing document
    await updateDoc(listingRef, { status: newStatus });
  } catch (error) {
    console.error("Error updating listing status:", error);
  }
};

export const changeListingDate = async (listingId, newDate) => {
  try {
    const listingRef = doc(db, "listings", listingId);

    // Update the status field of the listing document
    await updateDoc(listingRef, { ends_on: newDate });

    await changeListingStatus(listingId, "Bidding Ongoing");

    const deal = await getDealListingByListing(listingId);
  
    if(deal && deal.id){
      // if there is an active deal, make it inactive
      const dealRef = doc(db, "deal_listings", deal.id);
      await updateDoc(dealRef, { status: "inactive" });
    }

    const listingSnapshot = await getDoc(listingRef);
    // notify all the bidders that the property has been relisted
    if(listingSnapshot.exists()){
      const data = listingSnapshot.data();
      const biddings = data.biddings;

      if(biddings){
        biddings.forEach(async bidInfo => {
          const { agentId, bid } = bidInfo;
          const notifObject = {
            body: 'The seller of listing ' + data.postcode + ' has relisted the property.',
            icon: '../public/logo.png',
            data: {
              url: '/',
              action: 'View Listing'
            }
          };
      
          // Create a notification for each agent without awaiting completion
          await createNotification(notifObject, agentId);
        });
      }
    }
  } catch (error) {
    console.error("Error updating listing status:", error);
  }
};

export const addDealListingLink = async (dealId, site, link) => {
  try {
    const dealRef = doc(db, "deal_listings", dealId);

    // Update the portal_link field of the listing document by adding a new object to the array
    await updateDoc(dealRef, {
      portal_link: arrayUnion({ Site: site, Link: link })
    });
  } catch (error) {
    console.error("Error adding portal link:", error);
  }
};

export const getMostRecentListing = async (uid) => {
  try {
    // Create a Firestore query to retrieve listings owned by the user
    const q = query(
      collection(db, "listings"),
      where("owner_id", "==", uid),
      where("isDelete", "==", false),
      orderBy("timestamp", "desc"),
      limit(1)
    );
    // Execute the query and get the documents
    const querySnapshot = await getDocs(q);
    // Check if any documents were found
    if (!querySnapshot.empty) {
      // Get the most recent listing document
      const id = querySnapshot.docs[0].id;
      const mostRecentListing = querySnapshot.docs[0].data();

      mostRecentListing.endDate = mostRecentListing.ends_on;
      mostRecentListing.ends_on = formatFirestoreTimestamp(
        mostRecentListing.ends_on
      );

      // Return the most recent listing, only the ones that are not yet sold
      return (mostRecentListing.status === "Property Sold") ?  null : { id: id, ...mostRecentListing };
    } else {
      // No listing found for the user
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const getListingById = (listingId, onDataUpdate, onError) => {
  try {
    // Create a reference to the listing document
    const listingRef = doc(db, "listings", listingId);

    // Subscribe to the changes in the document using onSnapshot
    const unsubscribe = onSnapshot(listingRef, (listingDoc) => {
      // Check if the document exists

      if (listingDoc.exists()) {
        // Listing document found
        const listingData = listingDoc.data();

        if(!listingData.isDelete){
          listingData.endDate = listingData.ends_on;
          // Format the 'ends_on' field using the formatFirestoreTimestamp function
          listingData.ends_on = formatFirestoreTimestamp(listingData.ends_on);

          // Return the listing data through the onDataUpdate callback
          onDataUpdate({ id: listingId, ...listingData });
        }else{
          onDataUpdate(null)
        }
      } else {
        // Listing document not found
        console.log("Listing not found");
        onDataUpdate(null);
      }
    }, (error) => {
      // Handle any errors that occur during the subscription
      console.error("Error subscribing to listing changes:", error);
      onError(error);
    });

    // Return the unsubscribe function to allow stopping the subscription when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error getting listing:", error);
    onError(error);
    return null;
  }
};

export const deleteListing = async(listingId) => {
  // soft delete a listing
  try{
    const listingRef = doc(db, "listings", listingId);

    // Update the status field of the listing document
    await updateDoc(listingRef, { isDelete: true });

    const deal = await getDealListingByListing(listingId);

    if(deal.id){
      // if there is an active deal, make it inactive
      const dealRef = doc(db, "deal_listings", deal.id);
      await updateDoc(dealRef, { status: "inactive" });
    }
  }catch(error){
    console.error(error);
  }
}

export const dealListing = async (listingId, sellerId, chosenAgentId, bid, country) => {
  try{
    const dealRef = await addDoc(collection(db, "deal_listings"), {
      listing_id: listingId,
      seller_id: sellerId,
      agent_id: chosenAgentId,
      percent_bid: bid,
      country: country,
      portal_link: [],
      images: [],
      offer_price: 0,
      sold_price: 0,
      status: "active",
      timestamp: serverTimestamp(),
    });

    const dealId = dealRef.id;

    if(dealId){
      // if deal is successfully made, update the status of the listing
      await changeListingStatus(listingId, "Deal Ongoing");

      // add an automated chat that will prompt users to start the conversation
      const message = "Congratulations! You can now start a conversation by adding images below, and ask questions!";
      await sendMessage(dealId, "System", message);

      // notify agents
      const listingRef = doc(db, 'listings', listingId);
      const listingSnapshot = await getDoc(listingRef)

      if(listingSnapshot.exists()){
        const data = listingSnapshot.data();
        const postcode = data.postcode;
        const biddings = data.biddings || [];

        const notifications = biddings.map(async (bidInfo) => {
          const { agentId } = bidInfo;
          let message = 'Another agent has been chosen for the listing (' + postcode + ')';
          let url = '/';

          if (agentId === chosenAgentId) {
            message = 'Congratulations! The seller has chosen you for the listing (' + postcode + ').';
            url = '/portal/' + dealId + '?deal=successful&agent=' + agentId;

            // notify superadmin
            const notifObjectSuper = {
              body: 'Good news! A new deal has been made for listing ' + postcode + '.',
              icon: '../public/logo.png',
              data: {
                url: url,
                action: 'View Deal'
              }
            };
            await createNotification(notifObjectSuper, process.env.REACT_APP_SUPERADMIN_UID);
          }

          const notifObject = {
            body: message,
            icon: '../public/logo.png',
            data: { 
              url: url,
              action: agentId === chosenAgentId ? 'View Deal' : 'Bid for another listing'
            },
          };

          // Return the promise of sending notification
          return createNotification(notifObject, agentId);
        });

        // Wait for all notifications to be sent before continuing
        await Promise.all(notifications);
      }
    }

    return dealId;
  } catch (error){
    console.error("Error dealing listing:", error);
    return null;
  }
}

export const getDealListingByListing = async (listingId) => {
  try {
    const q = query(
      collection(db, "deal_listings"),
      where("listing_id", "==", listingId),
      where("status", "==", "active"),
      orderBy("timestamp", "desc"),
      limit(1)
    );

    const querySnapshot = await getDocs(q);

    if(!querySnapshot.empty){
      const doc = querySnapshot.docs[0];
      return {id: doc.id, agent_id: doc.data().agent_id, sold_price: doc.data().sold_price};
    }else{
      // console.log(listingId+" null")
      return null;
    }
  } catch (error) {
    console.error("Error getting listing:", error);
    return null;
  }
}

export const countAgentListings = async (agentId) => {
  try {
    const totalBids = await countTotalBids(agentId);
    const winningBids = await countWinningBids(agentId);
    const soldProperties = await countSoldPropertiesOfAgent(agentId);

    const data = {
      'Total Bids': totalBids,
      'Winning Bids': winningBids,
      'Sold Properties': soldProperties
    }

    return data;
  } catch (error) {
    console.error("Error counting listing:", error);
    return null;
  }
}

const countTotalBids = async (agentId) => {
  try {
    const q = query(collection(db, "listings"));

    const querySnapshot = await getDocs(q);

    let totalCount = 0;

    querySnapshot.forEach((doc) => {
      const biddings = doc.data().biddings; // Assuming 'biddings' is an array in your document
      if (Array.isArray(biddings)) {
        biddings.forEach((bid) => {
          if (bid.agentId === agentId) {
            totalCount++;
          }
        });
      }
    });

    return totalCount;
  } catch (error) {
    console.error("Error counting listings:", error);
    return null;
  }
};

export const countWinningBids = async (agentId) => {
  try{
    const q = query(
      collection(db, "deal_listings"),
      where("agent_id", "==", agentId),
      where('status', '==', 'active')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  } catch (error) {
    console.error("Error counting listing:", error);
    return null;
  }
}

export const countListingsBasedOnField = async (table, field, comparison, value) => {
  try{
    const q = query(
      collection(db, table),
      where(field, comparison, value)
    );

    const querySnapshot = await getDocs(q);

    return querySnapshot.size;
  } catch (error) {
    console.error("Error counting "+ table +":", error);
    return null;
  }
}

const countSoldPropertiesOfAgent = async (agentId) => {
  try {
    var count = 0;
    const q = query(
      collection(db, "listings"),
      where("status", "==", "Sold Property")
    );
  
    const querySnapshot = await getDocs(q);
  
    const promises = querySnapshot.docs.map(async (doc) => {
      const qry = query(
        collection(db, "deal_listings"),
        where("listing_id", "==", doc.id),
        where("agent_id", "==", agentId)
      );
  
      const snapShot = await getDocs(qry);
      count += snapShot.size;
    });
  
    await Promise.all(promises);
  
    return count;
  } catch (error) {
    console.error(error);
  }  
}

export const getMonthlyRevenue = async () => {
  // Determine the date range for the current month
  const currentDate = new Date();
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Create a reference to the 'deal_listings' collection
  const dealListingsRef = collection(db, 'deal_listings');

  // Query for documents with 'sold_date' within the current month
  const queryRef = query(
    dealListingsRef,
    where('sold_date', '>=', startOfMonth),
    where('sold_date', '<=', endOfMonth)
  );

  // Initialize a variable to store the sum of sold prices
  let sumOfSoldPrices = 0;

  try {
    const querySnapshot = await getDocs(queryRef);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const soldPrice = data.sold_price;
      sumOfSoldPrices += soldPrice;
    });

    sumOfSoldPrices = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2, // Ensure 2 decimal places
    }).format(sumOfSoldPrices);

    return sumOfSoldPrices;
  } catch (error) {
    console.error('Error fetching documents:', error);
    throw error; // Rethrow the error to handle it elsewhere, if needed
  }
}

export const getPortalofListing = (dealListingId, onDataReceived) => {
  try {
    const dealRef = doc(db, "deal_listings", dealListingId);

    const unsubscribe = onSnapshot(dealRef, async (dealDoc) => {
      if (dealDoc.exists() && dealDoc.data().status === 'active') {
        const dealData = dealDoc.data();

        // get listing data
        const listingRef = doc(db, "listings", dealData.listing_id);
        const listingDoc = await getDoc(listingRef);
        if (listingDoc.exists()) {
          var postcode = listingDoc.data().postcode;
        }

        // get seller data
        const sellerRef = doc(db, "users", dealData.seller_id);
        const sellerDoc = await getDoc(sellerRef);
        if (sellerDoc.exists()) {
          const data = sellerDoc.data();
          var seller = data.fname;
          var seller_id = data.uid;
          var sellerImg = data.img || DefaultProPic;
        }

        // get agent data
        const agentRef = doc(db, "users", dealData.agent_id);
        const agentDoc = await getDoc(agentRef);
        if (agentDoc.exists()) {
          const data = agentDoc.data();
          var agent = data.fname;
          var agent_id = data.uid;
          var agentImg = data.img || DefaultProPic;
        }

        const newObj = {
          seller: seller,
          seller_id: seller_id,
          seller_img: sellerImg,
          agent: agent,
          agent_id: agent_id,
          agent_img: agentImg,
          postcode: postcode,
          price_ID: dealData.price_ID,
          bid: dealData.percent_bid,
          offer_price: dealData.offer_price,
          sold_price: dealData.sold_price,
          country: dealData.country,
          isRated: dealData.isRated,
          listing_id: dealData.listing_id,
          timestamp: formatFirestoreTimestamp(dealData.timestamp),
          actualtimestamp: dealData.timestamp,
          country: dealData.country,
        };

        onDataReceived(newObj);
      } else {
        console.log("Deal is not found");
        onDataReceived(null);
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error getting listing:", error);
    return null;
  }
};

export const addImagestoPortal = async (imageURL, dealId) => {
  try {
    const dealRef = doc(db, "deal_listings", dealId);
    await updateDoc(dealRef, {
      images: arrayUnion(imageURL),
    });

    console.log("Image appended successfully!");
  } catch (error) {
    console.error("Error appending image:", error);
  }
}

export const getImagesofPortal = async (dealId, onDataReceived) => {
  try {
    const dealRef = doc(db, "deal_listings", dealId);

    const unsubscribe = onSnapshot(dealRef, (dealDoc) => {
      if (dealDoc.exists()) {
        const dealData = dealDoc.data().images;
        
        // Check if the images array is empty
        if (dealData && dealData.length > 0) {
          onDataReceived(dealData);
        } else {
          onDataReceived(null);
        }
      } else {
        onDataReceived(null);
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error getting images:", error);
    return null;
  }
}

export const getLinkofPortal = async (dealId, onDataReceived) => {
  try {
    const dealRef = doc(db, "deal_listings", dealId);

    const unsubscribe = onSnapshot(dealRef, (dealDoc) => {
      if (dealDoc.exists()) {
        const portalLinks = dealDoc.data().portal_link;

        // Reorder elements in the portalLinks array
        const reorderedLinks = portalLinks.map(({ Link, Site }) => ({ Site, Link }));

        onDataReceived(reorderedLinks); // Call the provided callback with the reordered data
      } else {
        onDataReceived(null); // Call the provided callback with null if the document doesn't exist
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error getting portal link:", error);
    return null;
  }
};


export const addDealPrice = async (dealId, priceType, price, bid, agentID, postCode) => {
  try {
    const dealRef = doc(db, "deal_listings", dealId);

    const docSnapshot = await getDoc(dealRef);

    if(docSnapshot.exists()){
      // Create an object to update either offer_price or sold_price based on priceType
      const updateData = {};
      updateData[priceType] = price;

      // Update the corresponding field in the deal document with the value of price
      await updateDoc(dealRef, updateData);

      if (priceType === 'sold_price') {
        // if sold, update the sold date
        await updateDoc(dealRef, { sold_date: serverTimestamp() });
  
        const soldPrice = parseFloat(price);
        const bidPercentage = parseFloat(bid.replace('%', ''));
        const totalPrice = parseFloat(soldPrice * (bidPercentage / 100) * 0.2);
      
        // create an invoice object for admins to check manually
        const invoice = await addDoc(collection(db, "invoices"), {
          deal_ID: dealId,
          agent_ID: agentID,
          total_amount: totalPrice,
          success_token: generateRandomString(20),
          cancel_token: generateRandomString(20),
          payment_status: 'PENDING',
          timestamp: serverTimestamp(),
        });
  
        // temporarily disable account of agent
        if(invoice){
          await changeStatus(agentID, true);
        }

        const dealData = docSnapshot.data(); // Access the document data
        const listingRef = doc(db, "listings", dealData.listing_id);
  
        await updateDoc(listingRef, { status: "Sold Property" });
  
        // notify seller
        let notifObject = {
          body: "Congratulations! The property (" + postCode + ") has been sold.",
          icon: '../public/logo.png',
          data: {
            url: '/portal/' + dealId,
            action: 'View Deal'
          }
        };
        await createNotification(notifObject, dealData.seller_id);

        // notify agent
        notifObject = {
          body: "Congratulations! The property (" + postCode + ") has been sold. Please settle your payment to The Agent Genie to restore your account.",
          icon: '../public/logo.png',
          data: {
            url: '/portal/' + dealId,
            action: 'View Deal'
          }
        };
        await createNotification(notifObject, agentID);

        // send email to agent for payment
      }    
    }

    return price;
  } catch (error) {
    console.error("Error updating listing status:", error);
  }
};
