import React, { useState } from 'react'
import styled from 'styled-components'
import TitleLogo from './TitleLogo';
import OtherLinks from './OtherLinks';
import { COLORS } from '../variables.js';
import LoginLinks from './LoginLinks';
import Privacy_Policy from '../Modal/Privacy_Policy.js';

const TopNavbar = (props) => {
  let [toggle, setToggle] = useState(false); 
  
  function Toggle(){
    setToggle(!toggle);
  }

  return (
    <Navbar bgColor={ props.bgColor }>
      <Flex>
        <TitleLogo toggle = {Toggle} color = { props.color } />
        <FlexCont width='200px'>
        <Menu onClick={() => Toggle()} color={props.color}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </Menu>
        {props.type ? <LoginLinks isShown = {toggle} uType={props.uType} color = { props.color } redirect = { props.redirect } /> :  <OtherLinks isShown = {toggle} uType={props.uType} color = { props.color } btncolor = { props.btncolor } />}
        </FlexCont>
      </Flex>
      <Privacy_Policy />
    </Navbar>
  )
}

TopNavbar.defaultProps = {
  bgColor: 'transparent',
}

const Navbar = styled.div`
    background: ${props => props.bgColor};
    width: 100%;
    display: flex;
    flex: space-between;
    top: 0;
    position: absolute;
    z-index: 9999;

    @media only screen and (max-width: 860px) {
      flex-direction: column;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const FlexCont = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;

    @media only screen and (max-width: 860px) {
      width: ${props => props.width};
    }
`;

const Menu = styled.button`
    color: ${props => (props.color ? props.color : COLORS.white)};
    text-align: end;
    background-color: transparent;
    cursor: pointer;
    border: 0;
    display: none;

    @media only screen and (max-width: 860px) {
      display: block;
    }
`;

export default TopNavbar
