import React from 'react';
import styled from "styled-components";
import { COLORS } from "../variables";
import { calculatePercentRating } from '../../backend-functions/custom-functions';

const GenieScore = ({ percent, ...props }) => {
  const ctr = 5;
  const stars = [];

  const percentRating = calculatePercentRating(percent);

  for (let index = 1; index <= ctr; index++) {
    stars.push(
      <Star percent={percent} table={props.table} child={index}>
        &#9733;
      </Star>
    );
  }

  return (
      <StarWrapper>
        {stars}
        {/* <Percent table={props.table}>
          {percentRating}%
        </Percent> */}
      </StarWrapper>
      );
};

const StarWrapper = styled.div`
  display: flex;
`;

const Star = styled.span`
    color: ${props => {
      const index = props.child - 1;
      if (props.percent >= index + 1) {
        return '#ffc107'; // Color for filled stars
      }else {
        return '#e4e5e9'; // Color for empty stars
      }
    }};
    font-size: 24px;
    margin: 0px 5px;

    // @media only screen and (max-width: 780px) {
    //   display: ${props => props.table ? 'none' : 'block'};
    // }
  `;

const Percent = styled.span`
  display: none;

  @media only screen and (max-width: 780px) {
    display: ${props => props.table ? 'block' : 'none'};
  }
`;

export default GenieScore;
