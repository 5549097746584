import React, { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';

const Confetti = ({setConfetti}) => {
  const [windowDimension, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showConfetti, setShowConfetti] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [fadeOpacity, setFadeOpacity] = useState(1);

  const detectSize = () => {
    setDimension({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (elapsedTime >= 10) {
      const fadeInterval = setInterval(() => {
        setFadeOpacity((prevOpacity) => prevOpacity - 0.1);
      }, 1000);

      setTimeout(() => {
        clearInterval(fadeInterval);
        setShowConfetti(false);
        setConfetti(false);
      }, 1000);
    }
  }, [elapsedTime]);

  return (
    <>
      {showConfetti && (
        <div style={{ opacity: fadeOpacity }}>
          <ReactConfetti
            width={windowDimension.width}
            height={windowDimension.height}
            tweenDuration={100}
          />
        </div>
      )}
    </>
  );
};

export default Confetti;
