import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'

const CheckboxwithLabel = ({isChecked, handleChange, ...props}) => {
  return (
    <Container>
      <Checkbox type='checkbox' id={props.id} checked={isChecked} onChange={handleChange}/>
      <Text color={COLORS.blue_faded} htmlFor={props.id}>{props.label}</Text>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
`;

const Text = styled.label`
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    color: ${props => props.color};
    margin: 2px;
`;

const Checkbox = styled.input`
    width: 16px;
    margin-right: 10px;
`;

export default CheckboxwithLabel
