import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";
import { auth } from "../firebase";

const INITIAL_STATE = {
  authUser: JSON.parse(localStorage.getItem("authUser")) || null,
  userDetails: JSON.parse(localStorage.getItem("userDetails")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("authUser", JSON.stringify(state.authUser));
    localStorage.setItem("userDetails", JSON.stringify(state.userDetails));
  }, [state.authUser, state.userDetails]);

  // Function to update a field in userDetails
  const updateUserDetailsField = (fieldName, newValue) => {
    const updatedUserDetails = { ...state.userDetails, [fieldName]: newValue };
    dispatch({ type: "UPDATE_USER_DETAILS", payload: updatedUserDetails });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Update authUser in context
        dispatch({ type: "UPDATE_AUTH_USER", payload: user });
      }
    });

    return () => unsubscribe(); // Cleanup the subscription when component unmounts
  }, []);

  return (
    <AuthContext.Provider value={{ authUser: state.authUser, userDetails: state.userDetails, updateUserDetailsField, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};