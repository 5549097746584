import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import ProfilePicture from '../Containers/ProfilePicture'
import { AuthContext } from '../../context/AuthContext'
import { getMessages, sendFile, sendMessage } from '../../backend-functions/Messages'
import FileInput from './FileInput'
import ShowFiles from './ShowFiles'

const Chat = ({dealId, postCode, otherPerson, otherPic, status}) => {
  const { userDetails } = useContext(AuthContext);

  const [ msgs, setMsgs ] = useState([]);
  const [ msg, setMsg ] = useState('');
  const [ files, setFiles ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try{
            const unsubscribeMsgs = getMessages(dealId, (msgs) => {
                setMsgs(msgs);
            });

            return unsubscribeMsgs;
        }catch(error){
            console.error(error);
        }
    };

    fetchData();
  }, [dealId]);

  const messageContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the message container
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [msgs]);

  const handleSend = async () => {
    const message = msg;
    const selectedFiles = files;

    setMsg("");
    setFiles([]);

    if(status === 'Done'){
        alert('Listing has been sold. You cannot send a message anymore.');
        return;
    }

    if(message !== ""){
        await sendMessage(dealId, userDetails.uid, message);
    }

    if(selectedFiles.length > 0){
        for (let i = 0; i < selectedFiles.length; i++) {
            await sendFile(dealId, userDetails.uid, selectedFiles[i]);
        }
    }
  }
  
  return (
    <Container>
        <Cont height='10%' style={{borderBottom: `1px solid ${COLORS.black_faded}`}}>
            <FlexBetween>
                <Cont width='95%'>
                    <Flex>
                        <Cont padding='3px' width='45px' height='45px'>
                            <ProfilePicture image={otherPic} />
                        </Cont>
                        <Cont padding='10px'>{postCode} • {otherPerson}</Cont>
                    </Flex>
                </Cont>
                <Cont width='5%' justify='center'>&#10006;</Cont>
            </FlexBetween>
        </Cont>

        {/* CHAT MESSAGES */}
        <Cont height='80%' style={{borderBottom: `1px solid ${COLORS.black_faded}`}}>
            <MessageContainer ref={messageContainerRef}>
                {msgs.map((message, index) => (
                    <MsgCont key={index} owner={message.sender_id === userDetails.uid ? 'me' : 'you'}>
                        <Cont padding='3px' width='45px' height='45px' hide='yes'>
                            <ProfilePicture image={message.img}/>
                        </Cont>
                        {message.message && <Convo owner={message.sender_id === userDetails.uid ? 'me' : 'you'}>
                            {message.message}
                        </Convo>}
                        {message.fileUrl && <Convo owner={message.sender_id === userDetails.uid ? 'me' : 'you'}>
                            <a href={message.fileUrl} target='_blank' download>
                                {message.fileName}
                            </a>
                        </Convo>}
                        {message.imgUrl && <Convo owner={message.sender_id === userDetails.uid ? 'me' : 'you'}>
                            <img src={message.imgUrl}></img>
                        </Convo>}
                    </MsgCont>
                ))}
            </MessageContainer>    
        </Cont>

        {files.length > 0 && <ShowFiles selectedFiles={files} setSelectedFiles={setFiles} />}

        <Cont height='10%'>
            <FlexBetween>
                <Cont width='95%'>
                    <Flex>
                        <FileInput setSelectedFiles={setFiles} />
                        <Cont width='100%'>
                            <Message onChange={(e) => setMsg(e.target.value)} value={msg}></Message>
                        </Cont>
                    </Flex>
                </Cont>
                <Cont width='8%' justify='center' onClick={handleSend}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                    </svg>
                </Cont>
            </FlexBetween>
        </Cont>
    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Cont = styled.div`
    height: ${props => props.height ? props.height : '100%'};
    width: ${props => props.width ? props.width : '100%'};
    display: flex;
    align-items: center;
    justify-content: ${props => props.justify ? props.justify : 'start'};;
    padding: ${props => props.padding};
    box-sizing: border-box;

    @media only screen and (max-width: 780px) {
        display: ${props => props.hide ? 'none': 'flex'};
    }
`;

const FlexBetween = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-left: 10px;
`;

const Message = styled.textarea`
    width: 100%;
    height: 100%;
    resize: none;
    box-sizing: border-box;
    border: 0;
    padding: 10px;

    &:focus {
        outline: none;s
    }
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth; 
`;

const MsgCont = styled.div`
    display: flex;
    flex-direction:  ${props => props.owner === 'me' ? 'row-reverse' : 'row'};
    align-items: flex-start;
    margin: 5px 10px;
    width: 98%;
    box-sizing: border-box;
    @media only screen and (max-width: 780px) {
        margin: 5px;
    }
`;

const Convo = styled.div`
    background: ${props => props.owner === 'me' ? COLORS.light_blue : COLORS.green};
    margin: 0px 10px;
    width: fit-content;
    max-width: 45%;
    padding: 10px 20px;
    border-radius: 10px;
    height: fit-content;

    @media only screen and (max-width: 780px) {
        max-width: 100%;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export default Chat
