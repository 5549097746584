import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables';
import InputField from './InputField';
import BigButton from '../Buttons/BigButton';
import Error from './Error';

const InputMultipleFiles = ({selectedImages, setSelectedImages, error, onClick}) => {
  const handleImageChange = (e) => {
    const files = e.target.files;
    const selectedImagesArray = Array.from(files);
    setSelectedImages(selectedImagesArray);
  };
  return (
    <form>
      <Error error={error} />
      <BigText>Add Images</BigText>
      <InputField type="file" accept="image/*" multiple onChange={handleImageChange} />
      <br></br>
      {/* Display selected images */}
      <Flexwrap>
        {selectedImages.map((image, index) => (
          <Image
            key={index}
            src={URL.createObjectURL(image)}
            alt={`Image ${index}`}
          />
        ))}
      </Flexwrap>
      <br></br>
      <BigButton text='Upload' bgColor={COLORS.blue} color={COLORS.white} onClick={onClick} />
    </form>
  );
}

const BigText = styled.h1`
    @media only screen and (max-width: 780px) {
        font-size: 20px;
    }
`;

const Image = styled.img`
    width: 100px;
    object-fit: contain;
    @media only screen and (max-width: 780px) {
        width: 50px;
    }
`;

const Flexwrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 500px;

    @media only screen and (max-width: 780px) {
        width: 100%;
    }
`;

export default InputMultipleFiles
