import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import { useSearchParams } from "react-router-dom"
import { COLORS } from '../components/variables'
import styled from 'styled-components'
import logo from '../img/logo.png'
import RegisterAgent from '../components/Forms/RegisterAgent'
import RegisterSeller from '../components/Forms/RegisterSeller'

const RegisterPage = () => {
  const [type] = useSearchParams();
  const utype = (type.get("type") ? type.get("type") : 'Seller');
  const become = (utype == 'Seller' ? 'Become a ' : 'Become an ');

  return (
    <FlexCol marginTop='30px'>
      <Navbar type='login' uType={utype} color={COLORS.blue} redirect='register' bgColor={COLORS.white}/>
      
      <FlexRow width='50%' padding='50px'>
        <BigText>{become}
            <HighLight>{utype}</HighLight>
        </BigText>
        <p style={{marginTop: '-30px'}}>Register to Start</p>
        <br></br>
        {/* FORMS */}
        {utype == 'Seller' ? <RegisterSeller/> : <RegisterAgent/> }
      </FlexRow>
      <FlexRow width='50%' style={{paddingTop: '100px', alignItems: 'center'}}>
        <Logo src={logo} className='floating' alt='The Agent Genie'/>
      </FlexRow>
    </FlexCol>
  )
}

const Line = styled.span`
  background: ${COLORS.blue};
  height: 1px;
  width: 100%;
`;

const Form = styled.form`
  margin-top: 30px;
  width: 70%;

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const ForgotPass = styled.div`
  margin: 10px 0px;
  text-align: end;
  width: 100%;
  font-size: 12px;
  color: ${COLORS.blue};

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.height};
    width: 100%;
    box-sizing: border-box;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => (props.stop ? 'row' : 'column')};
        margin-top: ${props => (props.marginTop ? '0' : props.marginTop)};
    }
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: column;
    background: ${props => props.background};
    box-sizing: border-box;
    width: ${props => props.width};
    padding: ${props => (props.padding ? props.padding : '0px')};

    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: 20px;
    }
`;

const Text = styled.span`
  color: ${COLORS.blue};
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
`;

const BigText = styled.h1`
  color: ${COLORS.blue};
  font-weight: 900;
  font-size: 48px;
  margin-top: 10px;
  @media only screen and (max-width: 780px) {
    font-size: 34px;
    margin-top: 60px;
  }
`;

const HighLight = styled.span`
  color: ${COLORS.yellow};
`;

const Logo = styled.img`
    // background: blue;
    width: 70%;
    height: auto;
    margin-top: 5px;
    @media only screen and (max-width: 780px) {
      width: 30%;
      height: 30%;
      display: none;
    }
`;

export default RegisterPage
