import { addDoc, collection, doc, endAt, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, startAt, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { calculatePercentRating, getAverageAgentRating } from "./custom-functions";
import { stripe } from "../stripe";
import { createNotification } from "./Notifications";

export const addAgentDetails = async (details, uid) => {
  try {
    const customer = await stripe.customers.create({
      email: details.email,
      name: details.name
    });

    if(customer){
      await updateDoc(doc(collection(db, "users"), uid), {
        company: details.company,
        valid_id: details.valid_id,
        selfie: details.selfie,
        application_status: "Pending",
        genie_score: 0,
        stripeCustomerID: customer.id,
        referralToken: details.referralToken  // referral token means that the agent has agreed to the referral agreement since they cannot create an account unless they agree to it
      });
    }
  } catch (error) {
    console.log("Error adding details:", error);
  }
};

export const getUser = async (uid) => {
  try {
    const userDocSnapshot = await getDoc(doc(db, "users", uid));

    if (userDocSnapshot.exists()) {
      return userDocSnapshot.data();
    } else {
      console.log("No user found");
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUsers = (handleRestore, handleBlock, keyword = '', userType = '', onDataReceived) => {
  try {
    const usersCollectionRef = collection(db, "users");

    let q = usersCollectionRef;

    if (keyword.trim() !== '') {
      // If a keyword is provided, filter users by fname, lname, or email
      q = query(
        usersCollectionRef,
        where('fname', '>=', keyword), // Start at keyword
        where('fname', '<=', keyword + '\uf8ff'), // End at keyword + '\uf8ff' to match any suffix
        orderBy('fname') // You can adjust the orderBy as needed
      );
    }
    
    if(userType.trim() !== '' && userType !== 'All'){
      q = query(
        usersCollectionRef,
        where('usertype', '>=', userType), // Start at userType
        where('usertype', '<=', userType + '\uf8ff'), // End at keyword + '\uf8ff' to match any suffix
        orderBy('usertype') // You can adjust the orderBy as needed
      );
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const usersData = [];

      querySnapshot.forEach((userDoc) => {
        const userData = userDoc.data();

        const newObj = {
          id: userDoc.id,
          img: '',
          Name: userData.fname + " " + userData.lname,
          Country: userData.country,
          [userData.country === 'US' ? 'Zip Code' : 'Post Code']: userData.postcode,
          'User Type': userData.usertype,
          Status: userData.isDelete ? 'Blocked' : 'Active',
          View: 'view',
          ...(userData.isDelete ? { 'Restore': () => handleRestore(userDoc.id) } : { 'Block': () => handleBlock(userDoc.id) })
        };

        usersData.push(newObj);
      });

      // Call the onDataReceived function and pass the array of usersData
      onDataReceived(usersData);
    });

    return unsubscribe; // Return the unsubscribe function
  } catch (error) {
    console.error("Error fetching users:", error);
    return null;
  }
};

export const getAgents = (handleApprove, handleDeny, keyword, applicationStatus, onDataReceived) => {
  try {
    const usersCollectionRef = collection(db, "users");

    let q = usersCollectionRef;

    if (keyword.trim() !== '') {
      // If a keyword is provided, filter users by fname, lname, or email
      q = query(
        usersCollectionRef,
        where('fname', '>=', keyword), // Start at keyword
        where('fname', '<=', keyword + '\uf8ff'), // End at keyword + '\uf8ff' to match any suffix
        orderBy('fname') // You can adjust the orderBy as needed
      );
    }
    
    if(applicationStatus.trim() !== '' && applicationStatus !== 'All'){
      q = query(
        usersCollectionRef,
        where('application_status', '>=', applicationStatus), // Start at applicationStatus
        where('application_status', '<=', applicationStatus + '\uf8ff'), // End at keyword + '\uf8ff' to match any suffix
        orderBy('application_status') // You can adjust the orderBy as needed
      );
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const usersData = [];

      querySnapshot.forEach((userDoc) => {
        const userData = userDoc.data();
        if(userData.usertype === 'Agent'){
          const newObj = {
            id: userDoc.id,
            img: userData.img ? userData.img : '',
            Name: userData.fname + " " + userData.lname,
            Country: userData.country,
            [userData.country === 'US' ? "Zip Code" : "Post Code"] : userData.postcode,
            'User Type': userData.usertype,
            typehierarchy: userData.typehierarchy,
            Status: userData.application_status,
            View: 'approveagent',
            'Approve': userData.application_status !== "Pending" ? '' : () => handleApprove(userDoc.id),
            'Deny': userData.application_status !== "Pending" ? '' : () => handleDeny(userDoc.id),
          };

          usersData.push(newObj);
        }
      });

      // Call the onDataReceived function and pass the array of usersData
      onDataReceived(usersData);
    });

    return unsubscribe; // Return the unsubscribe function
  } catch (error) {
    console.error("Error fetching users:", error);
    return null;
  }
};


export const changeStatus = async (uid, newStatus) => {
  try {
    await updateDoc(doc(collection(db, "users"), uid), {
      isDelete: newStatus
    });
  } catch (error) {
    console.log("Error updating details:", error);
  }
};

export const acceptPrivacy = async (uid, newStatus) => {
  try {
    await updateDoc(doc(collection(db, "users"), uid), {
      privacy_accepted: newStatus
    });
    console.log("Details updated successfully.");
  } catch (error) {
    console.log("Error updating details:", error);
  }
};

export const updateApplicationStatus = async (uid, newStatus) => {
  try {

    var message = "Congratulations! You’ve been approved and you can now bid on listings!";

    if(newStatus === 'Denied'){
      message = "Unfortunately after reviewing your information it isn’t enough for us to approve your account. If you want to appeal this then please message us more information to help with approve your account.";
    }

    // notify agent
    let notifObject = {
      body: message,
      icon: '../public/logo.png',
      data: {
        url: '/',
        img: newStatus === 'Approved' ? 'https://i.pinimg.com/originals/07/03/48/0703483f8e3100d87497817030fb903f.gif' : null,
        action: newStatus === 'Approved' ? 'Start Bidding' : 'Create An Account'
      }
    };

    await createNotification(notifObject, uid);

    await updateDoc(doc(collection(db, "users"), uid), {
      application_status: newStatus
    });

    console.log("Details updated successfully.");
  } catch (error) {
    console.log("Error updating details:", error);
  }
};

export const getPendingAgents = async () => {
  try{
    const q = query(
      collection(db, "users"),
      where("usertype", "==", "Agent"),
      where("application_status", "==", "Pending"),
      limit(5)
    );
    const querySnapshot = await getDocs(q);

    const topObj = [];

    querySnapshot.forEach((doc) => {
      const newObj = {
        id: doc.id,
        name: doc.data().fname + " " + doc.data().lname,
        postcode: doc.data().postcode,
      };
      topObj.push(newObj);
    });

    return topObj;
  }catch(error) {
    console.log("Error getting details:", error);
  }
}

export const getNewUsers = async () => {
  try{
    const q = query(
      collection(db, "users"),
      where("usertype", "==", "Seller"),
      limit(5)
    );
    const querySnapshot = await getDocs(q);

    const topObj = [];

    querySnapshot.forEach((doc) => {
      const newObj = {
        id: doc.id,
        name: doc.data().fname,
        postcode: doc.data().postcode,
      };
      topObj.push(newObj);
    });

    return topObj;
  }catch(error) {
    console.log("Error getting details:", error);
  }
}

export const getTopAgents = async () => {
  try {
    const q = query(
      collection(db, "users"),
      where("usertype", "==", "Agent"),
      orderBy("genie_score", "desc"),
      limit(4)
    );
    const querySnapshot = await getDocs(q);

    const topObj = [];

    querySnapshot.forEach((doc) => {
      const newObj = {
        id: doc.id,
        name: doc.data().username,
        genie_score: doc.data().genie_score
      };
      topObj.push(newObj);
    });

    return topObj;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const rateAgent = async (agentId, sellerId, ratingScore, ratingDesc, dealId) => {
  try{
    const userRef = doc(db, "users", agentId);
    const rateRef = collection(userRef, "ratings");

    // add rating
    await addDoc(rateRef, {
      seller_id: sellerId,
      rating_score: ratingScore,
      message: ratingDesc,
      timestamp: serverTimestamp(),
    });

    const averageRating = await getAverageAgentRating(agentId);

    // change rating average
    await updateDoc(userRef, {
      genie_score: averageRating
    });

    // update isRated in deal listings
    await updateDoc(doc(collection(db, "deal_listings"), dealId), {
      isRated: true
    });

    return true;

  }catch(error){
    console.log(error);
    return null;
  }
}

export const getReviews = async (agentId) => {
  try {
    const userRef = doc(db, "users", agentId);
    const rateRef = collection(userRef, "ratings");

    const querySnapshot = await getDocs(rateRef);

    const reviewsPromises = [];

    querySnapshot.forEach((doc) => {
      const reviewData = doc.data();
      const sellerPromise = getUser(reviewData.seller_id).then((seller) => {
        return {
          id: doc.id,
          name: seller.username,
          percent: reviewData.rating_score,
          review: reviewData.message
        };
      });
      reviewsPromises.push(sellerPromise);
    });

    const reviews = await Promise.all(reviewsPromises);

    return reviews;
  } catch (error) {
    console.error("Error fetching reviews:", error);
    return null;
  }
};

export const changeUsername = async () => {
  try{
     // Get all user documents from the 'users' collection
     const usersCollection = collection(db, 'users');
     const usersSnapshot = await getDocs(usersCollection);
 
     // Update usernames for all users to 'Anonymous Genie'
     usersSnapshot.forEach(async (userDoc) => {
       const userRef = doc(db, 'users', userDoc.id);
       await updateDoc(userRef, { username: 'Anonymous Genie' });
     });

  } catch (error) {
    console.log(error)
  }
}
