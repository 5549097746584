import React, { useEffect, useState } from 'react'
import { COLORS } from '../../components/variables'
import styled from 'styled-components'
import Sidebar from '../../components/Navbar/Sidebar'
import InputwithIcon from '../../components/Forms/InputwithIcon'
import InputField from '../../components/Forms/InputField'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import DropdownField from '../../components/Forms/DropdownField'
import Pagination from '../../components/Pagination/Pagination'
import { getPortalLinks } from '../../backend-functions/Listings'

const PortalLinks = () => {
  // const filterOptions = [
  //   { value: 'All', label: 'All' },
  //   { value: 'Bidding Ongoing', label: 'Bidding Ongoing' },
  //   { value: 'List Ended', label: 'List Ended' },
  //   { value: 'Deal Ongoing', label: 'Deal Ongoing' },
  //   { value: 'Property Sold', label: 'Property Sold' },
  // ];

  const header = { seller: 'Seller', agent: 'Agent', code: 'Post Code', bid: 'Bid', offer: 'Offer Price', sold: 'Sold Price', type: 'header' };

  const [ list, setList ] = useState([]);
  const [ isNull, setIsNull ] = useState(true);

  const [ keyword, setKeyword ] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const fetchedListings = await getPortalLinks(keyword);
      setList(fetchedListings);

      if(fetchedListings.length < 1){
        setIsNull(!isNull);
      }
    }
    fetchData()
  }, [keyword]);

  return (
    <Page>
      <Sidebar portalLinks={true} />
      <Content>
      <h2>Portal Links</h2>   
      {/* Search and Filter */}
      <SpaceBetween>
        <Flex>
          <InputwithIcon icon={faSearch} placeholder='Search by postcode' value={keyword} onChange={(e) => {setKeyword(e.target.value)}} />
        </Flex> 
        {/* <Flex>
          <p>Filter:</p>
          <DropdownField options={filterOptions} setSelectedOption={setSelectedFilter} />
        </Flex> */}
      </SpaceBetween>
      <br></br>
      {/* Data Table */}
      <Pagination data={list} header={header} type='portal' isNull={isNull}/>
      </Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`;

const Content = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex-grow: 1;
  border-radius: 10px;
`;

const SpaceBetween = styled.div`
  display flex;
  justify-content: space-between;
  width: 50%;
  gap: 20px;

  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Flex = styled.div`
  display flex;
  width: 50%;
  align-items: center;
  gap: 5px;
`;

export default PortalLinks
