import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'
import HomeBG from '../components/Background/HomeBG'
import TopNavbar from '../components/Navbar/Navbar'
import HomePageSeller from './HomePageSeller'
import HomePageAgent from './HomePageAgent'
import Footer from '../components/Navbar/Footer'
import { AuthContext } from '../context/AuthContext'

const HomePage = () => {
  const { userDetails } = useContext(AuthContext);
  const uType = userDetails.usertype;

  return (
    <Main>
      <Content>
        <Container>
          <TopNavbar bgColor={COLORS.blue}></TopNavbar>
          {/* <HomeBG uType={uType}></HomeBG> */}
        </Container>

        { uType == "Seller" ? <HomePageSeller uid={userDetails.uid}/> : <HomePageAgent uid={userDetails.uid}/>}
      </Content>
      {/* <Footer /> */}
    </Main>
  );
}

const Content = styled.div`
  min-height: 70vh;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 100%;
    // margin-bottom: 50px;

    @media only screen and (max-width: 780px) {
      // margin-bottom: 70px;
    }
`;

const Main = styled.div`
// background: pink;
`;

export default HomePage
