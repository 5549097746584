import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import InputField from './InputField'

const Date = ({ setDay, setMonth, setYear }) => {
  return (
    <DateContainer>
      <DateCont>
        <InputField
          label="DD"
          placeholder="01"
          type="number"
          onChange={(e) => setDay(e.target.value)}
        />
      </DateCont>
      <DateCont style={{ width: '20px' }}>
        <Line />
      </DateCont>
      <DateCont>
        <InputField
          label="MM"
          placeholder="01"
          type="number"
          onChange={(e) => setMonth(e.target.value)}
        />
      </DateCont>
      <DateCont style={{ width: '20px' }}>
        <Line />
      </DateCont>
      <DateCont>
        <InputField
          label="YYYY"
          placeholder="2024"
          type="number"
          onChange={(e) => setYear(e.target.value)}
        />
      </DateCont>
    </DateContainer>
  );
}

const DateContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
`;

const DateCont = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    margin: 0px 2px;
    width: 20%;
`;

const Line = styled.div`
    width: 100%;
    height: 24px;
    border-top: 2px solid ${COLORS.blue};
`;

export default Date
