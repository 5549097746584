import React, { useContext, useEffect, useState } from 'react'
import { COLORS, UserTypes } from '../variables'
import styled from 'styled-components'
import BigButton from '../Buttons/BigButton'
import InputField from './InputField'
import ImgUpload from './ImgUpload'
import { usernames } from '../../usernames'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { register } from '../../backend-functions/Auth'
import Error from './Error'
import { cleanUKPostCode, fileUpload, generateRandomString, random } from '../../backend-functions/custom-functions'
import { addAgentDetails } from '../../backend-functions/Users'
import DropdownField from './DropdownField'

const RegisterAgent = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [fullpostcode, setfullPostCode] = useState("");
  const [postcode, setPostCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [accept, setAccept] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [validIdError, setValidIdError] = useState("");
  const [selfieError, setSelfieError] = useState("");
  const [country, setCountry] = useState("UK");

  // valid id
  const [selectedValidId, setSelectedValidId] = useState(null);
  const [selectedValidIdFileName, setSelectedValidIdFileName] = useState("");

  // selfie
  const [selectedSelfie, setSelectedSelfie] = useState(null);
  const [selectedSelfieFileName, setSelectedSelfieFileName] = useState("");

  const usertype = "Agent";
  const countryOptions = [
    { value: "UK", label: "United Kingdom" },
    { value: "US", label: "United States" },
  ];

  const navigate = useNavigate();
  const {dispatch} = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem('agentName', fname + " " + lname);
  }, [fname, lname])

  const userObj = {
    fname: fname,
    lname: lname,
    email: email,
    phone: phone,
    street: street,
    city: city,
    fullpostcode: fullpostcode.toUpperCase(),
    postcode: postcode.toUpperCase(),
    usertype: usertype,
    typehierarchy: UserTypes.Agent,
    password: password,
    confirmpassword: confirmpassword,
    accept: accept,
    country: country
  }



  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading("Please wait...");
    setError("");
    setValidIdError("");
    setSelfieError("");

    // Check if valid ID and selfie images are selected
    // if(!selectedValidId || !selectedSelfie){
    //   if (!selectedValidId) {
    //     setValidIdError("Please select a valid ID image.");
    //   }
    //   if (!selectedSelfie) {
    //     setSelfieError("Please select a selfie image.");
    //   }
    //   return;
    // }

    const user = await register(userObj, setError, setLoading);

    if (user) {
      // upload valid id and selfie to storage
      const validIdFile = new Date().getTime() + user.uid;
      const selfieFile = new Date().getTime() + user.uid;

      const validIdRef = selectedValidId ? await fileUpload(selectedValidId, "valid_id/" + validIdFile) : '';
      const selfieRef = selectedSelfie ? await fileUpload(selectedSelfie, "selfie/" + selfieFile) : '';

      // if user is created
      // add additional details for agent
      const details = {
        company: company,
        valid_id: validIdRef,
        selfie: selfieRef,
        name: fname + " " + lname,
        email: email,
        referralToken: user.uid + generateRandomString(10)
      }

      addAgentDetails(details, user.uid)
        .then(() => {
          navigate("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  
  return (
    <Form onSubmit={handleRegister}>
      {loading && <Loading>{loading}</Loading>}
      {error && <Error error={error} />}

      <BigContainer>
        <Container>
          <FlexCol stop="yes">
              <InputField required type="text" label="First Name" value={fname} onChange={(e)=>{setFname(e.target.value)}} placeholder="First Name" width="50%"/>
              <InputField required type="text" label="Last Name" value={lname} onChange={(e)=>{setLname(e.target.value)}} placeholder="Last Name" width="50%"/>
          </FlexCol>
          <FlexCol stop="yes">
            <InputField required type="email" label="Email Address" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email Address" width="50%"/>
            <InputField required type="tel" label="Phone Number" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder={country === 'UK' ? '07xxxxxxxxx' : 'Phone Number'} width="50%"/>
          </FlexCol>
          {/* <FlexCol stop="yes">
            <DropdownField options={countryOptions} setSelectedOption={setCountry} label="Country" />
          </FlexCol> */}
          <FlexCol stop="yes">
            <InputField required type="text" label="Company Name" value={company} onChange={(e)=>setCompany(e.target.value)} placeholder="Company Name" width="100%"/>
          </FlexCol>
          {/* <FlexCol stop="yes">
            <InputField required type="text" label="Street" value={street} onChange={(e)=>setStreet(e.target.value)} placeholder="Street" width="33%"/>
            <InputField required type="text" label="City" value={city} onChange={(e)=>setCity(e.target.value)} placeholder="City" width="33%"/>
            <InputField required type="text" label={country === 'UK' ? 'Post Code' : 'Zip Code'} value={fullpostcode} onChange={(e)=>{setPostCode(cleanUKPostCode(e.target.value, country)); setfullPostCode(e.target.value)}} placeholder={country === 'UK' ? 'Post Code' : 'Zip Code'}width="33%"/>
          </FlexCol> */}
          <FlexCol stop="yes">
            <InputField required type="password" label="Password" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password" width="100%"/>
          </FlexCol>
          <FlexCol stop="yes">
            <InputField required type="password" label="Confirm Password" value={confirmpassword} onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Confirm Password" width="100%"/>
          </FlexCol>
        </Container>
        
        {/* <Container>
          <FlexCol>
            <Container>
              Please upload {country === 'UK' ? 'any valid ID' : 'your Real Estate License'}
              <ImgUpload
                id="valid_id"
                setError={setValidIdError}
                selectedImage={selectedValidId}
                selectedFileName={selectedValidIdFileName}
                setSelectedImage={setSelectedValidId}
                setSelectedFileName={setSelectedValidIdFileName}
              />
              {validIdError && <Error error={validIdError} />}
            </Container>
            <br />
            <Container>
              Please upload a selfie
              <ImgUpload
                id="selfie"
                setError={setSelfieError}
                selectedImage={selectedSelfie}
                selectedFileName={selectedSelfieFileName}
                setSelectedImage={setSelectedSelfie}
                setSelectedFileName={setSelectedSelfieFileName}
              />
              {selfieError && <Error error={selfieError} />}
            </Container>
          </FlexCol>
        </Container> */}
      </BigContainer>

      <br/>
      <FlexCol stop='yes'>
            <input type='checkbox' checked={accept} onChange={(e)=>setAccept(!accept)} id='accept'></input>
            <label htmlFor='accept'>I accept the <a href='/terms-of-use' target='_blank' style={{textDecoration: 'underline'}}>Terms and Conditions</a> and <a href='/referral-agreement' target='_blank' style={{textDecoration: 'underline'}}>Referral Agreement</a></label>
      </FlexCol>

      <br/>
      <FlexCol stop="yes" style={{ justifyContent: "center" }}>
        <BigButton
          text="&nbsp; Register &nbsp;"
          type="submit"
          color={COLORS.white}
          bgColor={COLORS.blue}
        ></BigButton>
      </FlexCol>
      <br></br>
      {/* <FlexCol
        stop="yes"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Line></Line>
        <span style={{ margin: "0px 20px" }}>or</span>
        <Line></Line>
      </FlexCol>
      <br></br>
      <FlexCol stop="yes" style={{ justifyContent: "space-between" }}>
        <ButtonwithIcon
          text="Register with Google"
          width="45%"
          color={COLORS.blue}
          bgColor={COLORS.white}
        />
        <ButtonwithIcon
          text="Register with Facebook"
          width="45%"
          color={COLORS.blue}
          bgColor={COLORS.white}
        />
      </FlexCol> */}
    </Form>
  );
}

const Line = styled.span`
  background: ${COLORS.blue};
  height: 1px;
  width: 100%;
`;

const Form = styled.form`
  margin-top: 0px;
  width: 100%;

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: ${props => props.height};
    width: 100%;
    box-sizing: border-box;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
    // justify-content: space-between;

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => (props.stop ? 'row' : 'column')};
        margin-top: ${props => (props.marginTop ? '0' : props.marginTop)};
    }
`;


const BigContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    @media only screen and (max-width: 780px) {
      flex-direction: column;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: ${props => props.width ? props.width : '100%'};
    // margin: 0px 5px 15px;
    @media only screen and (max-width: 780px) {
      width: 100%;
    }
`;

const Loading = styled.p`
  font-style: italic;
  color: ${COLORS.blue};
`;

export default RegisterAgent
