import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'
import TopNavbar from '../components/Navbar/Navbar'
import { useNavigate, useSearchParams } from 'react-router-dom'
import bg from '../img/bg.jpeg'
import USbg from '../img/USbg.jpg'
import InputField from '../components/Forms/InputField'
import BigButton from '../components/Buttons/BigButton'
import Date from '../components/Forms/Date'
import { useState } from 'react'
import Modal from '../components/Modal/Modal'
import { AuthContext } from '../context/AuthContext'
import Error from '../components/Forms/Error'
import { calculateListingDate, checkDate, cleanUKPostCode, formatDate } from '../backend-functions/custom-functions'
import { createListing, getMostRecentListing } from '../backend-functions/Listings'
import { emailVerification, logout, resetEmail } from '../backend-functions/Auth'
import States from '../components/UKpostcodes'
import Zipcodes from '../components/USpostcodes'
import DropdownField from '../components/Forms/DropdownField'
import { auth } from '../firebase'

const CreateListing = () => {
  const { authUser, userDetails } = useContext(AuthContext);

  const [code] = useSearchParams();
  const postcode = code.get('postcode');

  const [ postCode, setPostCode ] = useState(postcode);
  const [ days, setDays ] = useState(1);
  const [ country, setCountry] = useState(userDetails && userDetails.country ? userDetails.country : "UK");

  const [error, setError] = useState('');

  // const [isUnverified, setIsUnverified] = useState(!user.emailVerified);
  const [isUnregistered, setIsUnregistered] = useState(!authUser == null);
  const [isUnverified, setIsUnverified] = useState(authUser && !authUser.emailVerified)
  const [isOngoing, setIsOngoing] = useState(false);
  const navigate = useNavigate();
  const [userID, setUserID] = useState(authUser && authUser.uid ? authUser.uid : '0');
  const [hasItem, setHasItem] = useState(false);
  

  const filterOptions = [
    { value: "1", label: "1" },
    { value: "3", label: "3" },
    { value: "5", label: "5" },
    { value: "7", label: "7" },
  ];

  const countryOptions = [
    { value: "UK", label: "United Kingdom" },
    { value: "US", label: "United States" },
  ];

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('savedObj'));
    if (items) {
      setHasItem(true);
      if(!authUser || !userDetails) 
        setCountry(items.country);
        setPostCode(items.postcode);
        setDays(items.days);
      if(authUser && authUser.emailVerified)
        localStorage.removeItem('savedObj')
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const listing = await getMostRecentListing(userID);

        if (listing && listing.status !== 'Sold Property')
          setIsOngoing(true);

      } catch (error) {
        console.error('Error:', error);
      }
    };

    if(authUser && userDetails)
      fetchData();
  }, [userID]);

  const handlesetDays = async (value, label) =>{
    console.log(value, label)
    value = parseFloat(label);
    setDays(value)
  }

  const handleCreate = async (e) => {
    e.preventDefault();

    if(!authUser || !userDetails){
      const savedObj = {
        postcode: postCode,
        country: country,
        days: days,
      }
      localStorage.setItem('savedObj', JSON.stringify(savedObj))
      setIsUnregistered(!isUnregistered);
      return;
    }

    if(!auth.currentUser.emailVerified){
      setIsUnverified(!auth.currentUser.emailVerified);
      return;
    }

    setError("Please Wait...");
        
    let formattedCode = postCode.toUpperCase();
    formattedCode = cleanUKPostCode(formattedCode, userDetails.country);

    if(userDetails.country === "UK" && !States[formattedCode]){
        setError(formattedCode + " is not a valid post code. Please use a different one.")
        return;
    }

    if(userDetails.country === "US" && !Zipcodes[formattedCode]){
      setError(formattedCode + " is not a valid zip code. Please use a different one.")
      return;
    }

    const date = calculateListingDate(days)
    const listingObj = {
        fullpostcode: postCode,
        postcode: formattedCode,
        owner_id: userID,
        country: userDetails.country,
        ends_on: date,
        status: "Bidding Ongoing",
    }

    try {
        const listing = await createListing(listingObj);
        if (listing) {
          navigate("/");
        } else {
          // Handle the case where listing creation fails
          console.log("Listing creation failed");
        }
    } catch (error) {
        console.error(error);
    }
      
    return;
  };

  const handleEmailVerification = async (e) => {
    const response = await emailVerification();

    if(response){
      // refresh the page
      window.location.reload();
    }
  }

  return (
    <div>
        <Container>
            <TopNavbar bgColor={COLORS.blue} btncolor={COLORS.yellow}></TopNavbar>
        </Container>
        <Cont>
            <LeftCont width='30%' display='none'>
                <Image src={country === 'US' ? USbg : bg} />
                <Overlay />
            </LeftCont>
            <RightCont width='70%'>
                <Form onSubmit={handleCreate}>
                    <Title>Create Listing</Title>
                    <Description>Please enter the first part of the {country === 'US' ? 'zipcode' : 'postcode'}.</Description>

                    <Error error={error} />

                    {!authUser && !hasItem && <DropdownField options={countryOptions} setSelectedOption={setCountry} defaultValue={country} label="Country" />}
                    <br/>
                    <InputField label={country === 'US' ? 'Zip Code' : 'Post Code'} value={postCode} placeholder={ country === 'US' ? '32118' : 'CF62'} setValue={setPostCode} />
                    <br />
                    <Description>Listing Ends On</Description>
                    <Flex>
                        <StyledDropdown options={filterOptions} selectedValue={days} setSelectedOption={handlesetDays} />
                        <p>day/s</p>
                    </Flex>
                    
                    <br />
                    <BigButton bgColor={COLORS.blue} color={COLORS.white} text='Create Listing' type='submit' />
                </Form>
            </RightCont>
        </Cont>

        {/* MODAL FOR UNVERIFIED ACCOUNTS */}
        <Modal isOpen={isUnverified}>
            <h2>Your email address is not yet verified</h2>
            <p>Please verify your email address before creating a listing</p>
            <br/><br/>
            <BigButton bgColor={COLORS.blue} color={COLORS.white} text='Verify Account' onClick={handleEmailVerification} />
        </Modal>

        {/* MODAL FOR UNREGISTERED ACCOUNTS */}
        <Modal isOpen={isUnregistered}>
            <h2>Creating a Listing Needs Account Registration</h2>
            <p>Please register an account before creating a listing</p>
            <br/><br/>
            <BigButton bgColor={COLORS.blue} color={COLORS.white} text='Register Now' onClick={()=>{navigate('/register?next=create')}} />
        </Modal>

        {/* MODAL FOR ONGOING LISTING */}
        <Modal isOpen={isOngoing}>
            <h2>You have an ongoing listing</h2>
            <p>Only one ongoing listing at a time is allowed</p>
            <br/><br/>
            <BigButton bgColor={COLORS.blue} color={COLORS.white} text='Back' onClick={()=>{navigate('/')}} />
        </Modal>
    </div>
  )
}

const Container = styled.div`
    position: relative;
    height: ${ props => props.height};
    padding-bottom: 0;
`;

const Cont = styled.div`
    display: flex;

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

const LeftCont = styled.div`
    width: ${props => props.width};
    position: relative;
    height: 100vh;

    @media only screen and (max-width: 780px) {
        width: 100%;
        height: ${props => (props.display ? '20vh' : '50vh')};
    }
`;

const RightCont = styled.div`
    width: ${props => props.width};
    padding: 100px 50px;
    box-sizing: border-box;

    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: 30px 20px;
    }
`;

const Form = styled.form`
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    
    @media only screen and (max-width: 780px) {
        width: 100%;
    }
`;

const Title = styled.h1`
`;

const Description = styled.p`
`;

const Image = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: blue;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.blue_faded}; 
`;

const Flex = styled.div`
    display: flex;
    width: 110px;
`;

const StyledDropdown = styled(DropdownField)`
    height: 100%;
    width: 60px;
`

export default CreateListing