import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { fileUpload, handleErrorMessage } from "./custom-functions";
import States from "../components/UKpostcodes";
import Zipcodes from "../components/USpostcodes";
import parsePhoneNumber from 'libphonenumber-js';
import { createNotification } from "./Notifications";

export const register = async (userObj, setError, setLoading) => {
  if (!userObj.accept) {
    setError("Please accept the Terms and Condition to continue");
    setLoading("");
    return;
  }

  let parsed_phone = parsePhoneNumber(userObj.phone, userObj.country === 'UK' ? 'GB' : 'US');
  if(!parsed_phone.isValid()) {
    setError("Invalid phone format.");
    setLoading("");
    return;
  }else{
    console.log(parsed_phone.number);
    userObj.phone = parsed_phone.number;
  }

  // let validPostcode = false;
  // if (userObj.country === "UK") {
  //   validPostcode = States[userObj.postcode];
  // }

  // if(userObj.country === "US"){
  //   validPostcode = Zipcodes[userObj.postcode];
  // }

  // if (!validPostcode) {
  //   const error = userObj.country === "UK" ? "Invalid Postcode" : "Invalid Zipcode";
  //   setError(error);
  //   setLoading("");
  //   return;
  // }

  if (userObj.password !== userObj.confirmpassword) {
    setError("Passwords don't match");
    setLoading("");
    return;
  }

  try {
    const user = await createUserWithEmailAndPassword(
      auth,
      userObj.email,
      userObj.password
    ).catch((error) => {
      console.log(error.message);
      setError(handleErrorMessage(error.message));
      setLoading("");
      return;
    });

    if (user) {
      await updateProfile(user.user, {
        displayName: userObj.fname + " " + userObj.lname,
      });

      // create a new user in the users collection in the database
      await setDoc(doc(db, "users", user.user.uid), {
        uid: user.user.uid,
        fname: userObj.fname,
        lname: userObj.lname,
        email: userObj.email,
        phone: userObj.phone,
        street: userObj.street,
        city: userObj.city,
        fullpostcode: userObj.fullpostcode,
        postcode: userObj.postcode,
        usertype: userObj.usertype,
        typehierarchy: userObj.typehierarchy,
        country: userObj.country,
        privacy_accepted: false,
        isDelete: false,
        timestamp: serverTimestamp(),
      });

      // alert superadmin
      const notifObject = {
        body: 'A new '+ userObj.usertype +' has registered ('+ userObj.fname + ' ' + userObj.lname + ')',
        icon: '../public/logo.png',
        data: {
          url: userObj.usertype === 'Seller' ? '/users' : '/agents',
          action: 'View User'
        }
      }
      await createNotification(notifObject, process.env.REACT_APP_SUPERADMIN_UID);
    }
    return user.user;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const logout = async () => {
  try {
    await auth.signOut();
    return true;
  } catch (error) {
    console.log(error.message);
    // Handle error during logout
    return false
  }
}

export const emailVerification = async () => {
  const user = auth.currentUser;

  if (user) {
    try {
      await sendEmailVerification(user);
      alert('Email Verification has been sent to your email');
      return true;
    } catch (error) {
      alert('Sorry, there has been an error: ' + error);
      return false; // Return false to indicate that the verification email was not sent
    }
  }
}

export const editProfile = async (user) => {
  const uid = auth.currentUser.uid
  if(uid){
    try {
      const userRef = doc(db, "users", uid);

      // if new profile, add it to the storage first
      if(user.img){
        const fileRef = await fileUpload(user.img, "profile_pictures/" + new Date().getTime() + uid);
        user.img = fileRef;
      }

      // Update the profile
      await setDoc(userRef, user, { merge: true });

      return true;
    } catch (error) {
      alert('Sorry, there has been an error: ' + error);
      return false;
    }
  }
}

export const getReferralAgreement = async (referralID) => {
  try {
    // Use Firestore query to get users with the matching referralToken
    const usersRef = collection(db, "users")
    const q = query(usersRef, where("referralToken", "==", referralID))
    const querySnapshot = await getDocs(q)

    // Process the results
    let user = ''
    querySnapshot.forEach((doc) => {
      user = doc.data().fname + " " + doc.data().lname
    });


    return user;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};