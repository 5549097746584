import React, { useEffect, useState } from 'react'
import { COLORS } from '../../components/variables'
import styled from 'styled-components'
import Sidebar from '../../components/Navbar/Sidebar'
import InputwithIcon from '../../components/Forms/InputwithIcon'
import InputField from '../../components/Forms/InputField'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import DropdownField from '../../components/Forms/DropdownField'
import Pagination from '../../components/Pagination/Pagination'
import { getListings } from '../../backend-functions/Listings'

const Listings = () => {
  const filterOptions = [
    { value: 'All', label: 'All' },
    { value: 'Bidding Ongoing', label: 'Bidding Ongoing' },
    { value: 'List Ended', label: 'List Ended' },
    { value: 'Deal Ongoing', label: 'Deal Ongoing' },
    { value: 'Property Sold', label: 'Property Sold' },
  ];

  const header = { seller: 'Seller', code: 'Post Code', no_of_bid: 'No. of Bids', topbid: 'Top Bidding', ends: 'Ends On', status: 'Status', type: 'header' };

  const [ list, setList ] = useState([]);
  const [ isNull, setIsNull ] = useState(true);

  const [ selectedFilter, setSelectedFilter ] = useState('');
  const [ keyword, setKeyword ] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setList([]);
      const fetchedListings = await getListings({keyword: keyword, limitVal: 0, type: selectedFilter, country: 'All'});
      console.log(fetchedListings)
      setList(fetchedListings);

      if(fetchedListings.length < 1){
        setIsNull(!isNull);
      }
    }
    fetchData()
  }, [keyword, selectedFilter]);

  return (
    <Page>
      <Sidebar browseListings={true} />
      <Content>
      <h2>Browse Listings</h2>   
      {/* Search and Filter */}
      <SpaceBetween>
        <Flex>
          <InputwithIcon icon={faSearch} value={keyword} onChange={(e) => {setKeyword(e.target.value)}} placeholder='Search by postcode' />
        </Flex> 
        <Flex>
          <p>Filter:</p>
          <DropdownField options={filterOptions} selectedValue={selectedFilter} setSelectedOption={setSelectedFilter} />
        </Flex>
      </SpaceBetween>
      <br></br>
      {/* Data Table */}
      <Pagination data={list} header={header} type='listing' isNull={isNull}/>
      </Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`;

const Content = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex-grow: 1;
  border-radius: 10px;
`;

const SpaceBetween = styled.div`
  display flex;
  justify-content: space-between;
  width: 50%;
  gap: 20px;

  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Flex = styled.div`
  display flex;
  width: 50%;
  align-items: center;
  gap: 5px;
`;

export default Listings
