import React, { useState } from 'react';
import styled from 'styled-components';

const RatingScore = ({setRating}) => {

  const handleStarClick = (value) => {
    setRating(value);
  };

  return (
    <Wrapper>
      <div className="rate">
        {[5, 4, 3, 2, 1].map((value) => (
          <>
            <StarInput
                key={value}
                type="radio"
                id={`star${value}`}
                name="rate"
                value={value}
                onClick={() => handleStarClick(value)}
            />
            <StarLabel htmlFor={`star${value}`} title="text">
                {value} stars
            </StarLabel>
          </>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
  }
  .rate {
    float: left;
    height: 46px;
    padding: 0 10px;
  }
`;

const StarInput = styled.input`
  position: absolute;
  top: -9999px;
`;

const StarLabel = styled.label`
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;

  &:before {
    content: '★ ';
  }

  /* Color changes on hover and click */
  &:hover,
  &:hover ~ label,
  input:checked ~ & {
    color: #deb217;
  }

  input:checked ~ &:before {
    color: #ffc700;
  }
`;

export default RatingScore;