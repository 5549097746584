import React, { useEffect, useState } from 'react';
import PaginationButtons from './PaginationButtons';
import Carousel from './../Carousel/Carousel';
import styled from 'styled-components';

const Pagination = ({data, header, type, isNull, showBid, ...props}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [subset, setSubset] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  const itemsPerPage = 5; // Number of items to display per page
  const totalItems = data.length; // Total number of items in the list
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / itemsPerPage));
    setStartIndex((currentPage - 1) * itemsPerPage);
    setEndIndex(startIndex + itemsPerPage);
    setSubset(data.slice(startIndex, endIndex));
  }, [data]);

  return (
    <Container>
      <Carousel listings={subset} header={header} type={type} page='yes' showBid={showBid} isNull={isNull} {...props} />
      {totalPages > 1 &&
      <PaginationButtons
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export default Pagination;