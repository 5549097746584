import React, { useEffect } from 'react'
import { createNotification } from '../backend-functions/Notifications';
const TestPage = () => {
    var newStatus = 'Denied';
    var message = "Congratulations! You’ve been approved and you can now bid on listings!";

    if(newStatus === 'Denied'){
      message = "Unfortunately after reviewing your information it isn’t enough for us to approve your account. If you want to appeal this then please message us more information to help with approve your account.";
    }

    // notify agent
    let notifObject = {
      body: 'We noticed that you had a typo in your email address, and we wanted to let you know that we have fixed it for you. Please use this email address: mel.clough@gmail.com and password: sKghUD83Fvj to log in. We advise you to reset your password using the button below.',
      icon: '../public/logo.png',
      data: {
        url: '/forgot-password',
        action: 'Reset Password',
      }
    };

    useEffect(() => {
        // send notification
        // createNotification(notifObject, '');
        }, []);

  return (
    <div>
      <div>
      </div>
    </div>
  )
}

export default TestPage
