import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { usernames } from "../usernames";
import { auth, db, storage } from "../firebase";
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, list, ref, uploadBytesResumable } from 'firebase/storage'
import States from "../components/UKpostcodes";
import { changeListingStatus } from "./Listings";
import { createNotification } from "./Notifications";

export function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
}

function removeNonLetters(str) {
    return str.replace(/[^a-zA-Z\s]/g, "");
}

export async function updateListingStatus() {
  const listingsRef = collection(db, 'listings');
  const q = query(listingsRef, where('status', '==', 'Bidding Ongoing'));
  
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const listingData = doc.data();
      const endsOn = listingData.ends_on.toDate(); // Convert Firestore timestamp to JavaScript Date object
      const now = new Date();

      if (now > endsOn) {
        await changeListingStatus(doc.id, "List Ended")

        // notify seller
        const notifObject = {
          body: 'Congratulations! Your listing(' + listingData.postcode + ') has already ended. You can now choose an agent to deal with.',
          icon: '../public/logo.png',
          data: {
            url: '/',
            action: 'Choose Agent'
          }
        };
        await createNotification(notifObject, listingData.owner_id);

        if(listingData.biddings){

          listingData.biddings.forEach(async (bidInfo, index) => {
            // notify the winning bidder(one with the least bid)
            const agentId = bidInfo.agentId
            const postcode = listingData.postcode
            const place = parseInt(index + 1) + getNumberSuffix(parseInt(index + 1))

            let message = 'The bidding of ' + postcode + ' has already ended and you won ' + place + ' place'

            if(index == 0){
              message = 'Congratulations! You have won the listing (' + postcode + '). Please wait for the seller to contact you to start dealing.'
            }

            const notifObject = {
              body: message,
              icon: '../public/logo.png',
              data: {
                url: '/',
                action: 'View Listing'
              }
            };
        
            // Create a notification for the winning agent
            await createNotification(notifObject, agentId);
          })
        }
      }
    });
  } catch (error) {
    console.error('Error updating listing status:', error);
  }
}

export function cleanUKPostCode(str, country = 'UK') {
  if (country !== 'UK') {
    return str;
  }

  if(str.length > 4){
    str = str.slice(0, -3);
  }

  // Check if the last character of the trimmed string is a number
  const lastCharacter = str.slice(-1);

  if (!isNaN(lastCharacter) && lastCharacter !== ' ') {
    str = str;
  } else {
    // If the last character is not a number, remove it
    str = str.slice(0, -1);
  }

  return str;
}

export async function assignUKCountry(){
  try{
    const usersRef = collection(db, 'users');
    const querySnapshot = await getDocs(query(usersRef, where('typehierarchy', '==', 3)));

    querySnapshot.forEach(async (userDoc) => {
      try {
        await updateDoc(doc(usersRef, userDoc.id), { country: 'UK' });
      } catch (updateError) {
        console.error(`Error updating user ${userDoc.id}:`, updateError);
      }
    });
  } catch(error){
    console.log(error)
  }
}

export async function assignCountryToCollection(collectionName, countryValue) {
  try {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);

    querySnapshot.forEach(async (doc) => {
      try {
        await updateDoc(doc.ref, { country: countryValue });
      } catch (updateError) {
        console.error(`Error updating document ${doc.id} in ${collectionName} collection:`, updateError);
      }
    });
  } catch (error) {
    console.error(`Error fetching documents from ${collectionName} collection:`, error);
  }
}

export function handleErrorMessage(error){
    const substring = error.substring(error.lastIndexOf("/") + 1);
    const result = substring.replace(/-/g, " ");
    return removeNonLetters(capitalizeWords(result));
}

export function random(){
    return Math.floor(Math.random() * (usernames.length - 1));
}

export function calculatePercentRating(percent){
  // Ensure the rating is within the range of 1 to 5
  percent = Math.max(1, Math.min(5, percent));

  // Calculate the percentage rating
  const percentRating = ((percent - 1) * 25).toFixed(2);

  return percentRating;
}

export const fileUpload = async (file, filepath) => {
  const maxSize = 15 * 1024 * 1024; // 15MB in bytes
  if (file.size > maxSize) {
    throw new Error('File size exceeds the limit.');
  }


  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, filepath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");

        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      async () => {
        // Upload completed successfully, get the download URL
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      }
    );
  });
};

  // export const formatDate = (day, month, year) => {
  //   const months = [
  //     'January',
  //     'February',
  //     'March',
  //     'April',
  //     'May',
  //     'June',
  //     'July',
  //     'August',
  //     'September',
  //     'October',
  //     'November',
  //     'December'
  //   ];

  //   if (day && month && year) {
  //     const monthName = months[parseInt(month, 10) - 1];
  //     const formattedDate = `${parseInt(day, 10)} ${monthName} ${year}`;
  //     return formattedDate;
  //   }

  //   return null;
  // };

export const formatDate = (day, month, year) => {
  // Parse the day, month, and year values into a Date object
  const date = new Date(`${month} ${day}, ${year}`);
  // Create a Firestore Timestamp from the Date object
  const timestamp = Timestamp.fromDate(date);

  return timestamp;
};

export const checkDate = (day, month, year, setError) => {
  const isValidDate = (year, month, day) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === parseInt(year, 10) &&
      date.getMonth() === parseInt(month, 10) - 1 &&
      date.getDate() === parseInt(day, 10)
    );
  };

  if (isValidDate(year, month, day)) {
    const currentDate = new Date();
    const selectedDate = new Date(year, month - 1, day);

    if (selectedDate > currentDate) {
      return true;
    } else {
      setError(day + "/" + month + "/" + year + " is not a future date.");
    }
  } else {
    setError(day + "/" + month + "/" + year + " is not a valid date.");
  }
};

export function calculateListingDate(days) {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const currentSecond = currentDate.getSeconds();
  const currentMillisecond = currentDate.getMilliseconds();

  // Add the specified number of days to the date
  currentDate.setDate(currentDate.getDate() + parseInt(days));

  // Set the time back to the current time
  currentDate.setHours(currentHour, currentMinute, currentSecond, currentMillisecond);

  return currentDate;
}

export function formatFirestoreTimestamp(timestamp) {
  const dateObject = timestamp.toDate();
  const formattedDate = dateObject.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
}
  
export function getNumberSuffix(number) {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = number % 100;
  const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
  return suffix;
}

export const calculateRemainingTime = async (targetDate) => {
  const now = new Date(); // Current time
  const target = new Date(targetDate.seconds * 1000 + Math.floor(targetDate.nanoseconds / 1000000)); // Target date

  const remainingTime = target - now; // Difference in milliseconds

  // Convert remaining time to days, hours, minutes, seconds
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export const calculateSpentTime = async (startDate) => {
  // Convert the timestamp to a Date object
  const target = convertFirestoreTimestampToJSDate(startDate);

  const now = new Date(); // Current time
  const spentTime = now - target; // Difference in milliseconds

  // Convert remaining time to days, hours, minutes, seconds
  const days = Math.floor(spentTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((spentTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((spentTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((spentTime % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export function convertFirestoreTimestampToJSDate(timestamp) {
  const seconds = timestamp.seconds;
  const nanoseconds = timestamp.nanoseconds;
  const milliseconds = seconds * 1000 + nanoseconds / 1e6;
  return new Date(milliseconds);
}

export const getAverageAgentRating = async (agentId) => {
  try {
    const ratingsRef = collection(db, 'users', agentId, 'ratings');
    const q = query(ratingsRef);
    const querySnapshot = await getDocs(q);

    let totalRating = 0;
    let ratingCount = 0;

    querySnapshot.forEach((doc) => {
      const ratingData = doc.data();
      const ratingScore = ratingData.rating_score;
      totalRating += ratingScore;
      ratingCount += 1;
    });

    if (ratingCount === 0) {
      return 0; // No ratings, so the average is 0.
    }

    const averageRating = totalRating / ratingCount;
    console.log(totalRating + " / " + ratingCount + " = " + averageRating);
    
    return averageRating;
  } catch (error) {
    console.error('Error fetching and calculating average rating:', error);
    throw error;
  }
};

export const generateRandomString = (length) => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_"; // Define the character set
  let result = "";
  const charsetLength = charset.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charsetLength);
    result += charset.charAt(randomIndex);
  }

  return result;
}

export const calculateDistance = (coord1, coord2) => {
  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;

  // Convert latitude and longitude from degrees to radians
  const degToRad = (degrees) => degrees * (Math.PI / 180);
  const radLat1 = degToRad(lat1);
  const radLon1 = degToRad(lon1);
  const radLat2 = degToRad(lat2);
  const radLon2 = degToRad(lon2);

  // Earth's radius in kilometers
  const radius = 6371;

  // Haversine formula
  const dLat = radLat2 - radLat1;
  const dLon = radLon2 - radLon1;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(radLat1) * Math.cos(radLat2) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = radius * c;

  return distance;
}

export const calculateDistanceBetweenPostcodes = (postcode1, postcode2) => {
  // Retrieve latitude and longitude coordinates for both postcodes
  const coordinates1 = States[postcode1];
  const coordinates2 = States[postcode2];
  
  if (!coordinates1 || !coordinates2) {
    return Infinity; // Return a large value if coordinates are not found
  }

  // Calculate the distance using calculateDistance function
  return calculateDistance(coordinates1, coordinates2);
}