import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../variables';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  width: 300px;
  background-color: ${COLORS.gray};
  color: ${COLORS.blue};
  font-size: 16px;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: ${props =>
    props.mobilePosition === 'top' ? (props.isMobile ? '-120px' : '-5px') : props.isMobile ? '50px' : '-5px'};
  right: ${props =>
    props.mobilePosition === 'top' ? (props.isMobile ? '-80px' : '160px') : props.isMobile ? '0px' : '160px'};
  opacity: ${props => (props.show ? '1' : '0')};
  transition: opacity 0.3s;
  span { 
    display: block;
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
`;

const Content = styled.div`
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
`;

const StatusToolTip = ({ text, children, mobilePosition }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const description = {
    'Bidding Ongoing' : 'The bidding period for this listing is still ongoing. You can change your bid to compete with the other agents.',
    'Waiting for Seller' : 'Congratulations for winning the bidding! Please wait for the seller to contact you to start the deal.',
    'List Ended' : 'The bidding period for this listing has ended. Please wait for the seller to contact you to start the deal.',
    'Deal Ongoing' : 'The seller has chose another agent to deal with this listing. You can bid for another listing.',
    'Won the Listing' : 'Congratulations! The seller has chose you to be the agent to deal their listing with. Click <b>\'Go to Portal\'</b> to start.',
    'Sold Property' : 'The property has already been sold. Bid for another property.',
    'Sold Price': 'Add the price your property sold for.',
    'Offer Accepted': 'Add the offer price you have accepted.'
  }

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <TooltipContainer>
      <Content
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
        </svg>

        <span>{children}</span>
      </Content>
      <TooltipText show={showTooltip} isMobile={isMobile} mobilePosition={mobilePosition}>
      <span dangerouslySetInnerHTML={{ __html: description[text] }}></span>
        {/* {description[text]} */}
      </TooltipText>
    </TooltipContainer>
  );
};

export default StatusToolTip;
