import React, { useEffect, useState } from 'react'
import { COLORS } from '../../components/variables'
import styled from 'styled-components'
import Sidebar from '../../components/Navbar/Sidebar'
import { useParams } from 'react-router-dom'
import SmallButton from '../../components/Buttons/SmallButton'
import { changeStatus, getUser } from '../../backend-functions/Users'
import { formatFirestoreTimestamp } from '../../backend-functions/custom-functions'
import Modal from '../../components/Modal/Modal'

const User = () => {
  const { id } = useParams();

  const [ user, setUser ] = useState(null);
  const [ action, setAction ] = useState('');
  const [ confirmModal, setConfirmModal ] =  useState(false);
  const [ responseMsg, setResponseMsg ] = useState('Please Wait...');
  const [ isDisabled, setIsDisabled ] = useState(false);

  const handleAction = async (actionbtn) => {
    setIsDisabled(true);
    setAction(actionbtn);
    setConfirmModal(true);
    setIsDisabled(false);
  }

  const handleConfirmation = async () => {
    const newStatus = action === "block" ? true : false;

    await changeStatus(id, newStatus);
    setConfirmModal(false)
  }

  useEffect(() => {
    const fetchedData = async () => {
        const userData = await getUser(id);

        if(userData){
          setUser(userData)
        }else{
          setResponseMsg('User Not Found');
        }
    }

    fetchedData();
  }, [handleConfirmation]);

  return (
    <Page>
      {/* CONFIRMATION MODAL */}
      <Modal isOpen={confirmModal} setOpen={setConfirmModal}>
        <h4>Are you sure you want to <i>{action}</i> account?</h4>
        <Flex>
          <SmallButton text='Yes' bgColor={COLORS.green} color={COLORS.blue} disabled={isDisabled} onClick={handleConfirmation} />
          <SmallButton text='No' bgColor={COLORS.red} color={COLORS.white} disabled={isDisabled} onClick={() => {setConfirmModal(false)}} />
        </Flex>
      </Modal>

      <Sidebar userAccounts={true} />
        <Content>
        { user ?
        <>
        <h2>User Information</h2>
        <Flex >
          {/* USER INFO */}
          <Flex width='70%'>
            <Flex >
              <Image />
            </Flex>
            <Flex width='100%' direction='column'>
              <Flex width='100%'>
                <BigText>{user.fname + " " + user.lname} • {user.usertype}</BigText>
              </Flex>
              <Flex width='100%'>{user.username}</Flex>
            </Flex>
          </Flex>

          {/* BUTTONS */}
          <Flex width='30%'>
            {user.isDelete ? 
            
            <Flex  width='100%' justify='center'>
              <SmallButton text='Restore' bgColor={COLORS.green} disabled={isDisabled} onClick={() => handleAction('restore')} />
            </Flex>
            :
            <Flex width='100%' justify='center'>
              <SmallButton text='Block' bgColor={COLORS.red} disabled={isDisabled} color={COLORS.white} onClick={() => handleAction('block')} />
            </Flex>}
          </Flex>
        </Flex>

        <br/>

        {/* MORE INFO */}
        <Flex width='100%'>
            <Flex direction='column' width='50%'>
                {/* User ID */}
                <Flex width='100%'>
                    <Text weight='bold'>User ID: </Text>
                    <Text>{user.uid}</Text>
                </Flex>
                {/* Address */}
                <Flex width='100%'>
                    <Text weight='bold'>Address: </Text>
                    <Text>{user.street + ", " + user.city}</Text>
                </Flex>
                {/* Post Code */}
                <Flex width='100%'>
                    <Text weight='bold'>{user.country === 'US' ? 'Zip Code' : 'Post Code'}: </Text>
                    <Text>{user.postcode}</Text>
                </Flex>
                {/* Email Address */}
                <Flex width='100%'>
                    <Text weight='bold'>Email Address: </Text>
                    <Text>{user.email}</Text>
                </Flex>
            </Flex>
            <Flex direction='column' width='50%'>
                {/* Phone Number */}
                <Flex width='100%'>
                    <Text weight='bold'>Phone Number: </Text>
                    <Text>{user.phone}</Text>
                </Flex>
                {/* Company Name */}
                {user.usertype === "Agent" &&
                  <Flex width='100%'>
                      <Text weight='bold'>Company Name: </Text>
                      <Text>{user.company}</Text>
                  </Flex>
                }
                {/* Joined On */}
                <Flex width='100%'>
                    <Text weight='bold'>Joined On: </Text>
                    <Text>{formatFirestoreTimestamp(user.timestamp)}</Text>
                </Flex>
            </Flex>
        </Flex>

        <br/><br/>

        {/* SUPPORTING DOCUMENTS */}
        {user.usertype === "Agent" &&
        <>
          <Text weight='bold'>Supporting Documents: </Text>

          <br/>

          <Flex >
            <Flex width='50%' direction='column'>
              <Text>Government ID</Text>
              <Document src={user.valid_id} />
            </Flex>
            <Flex width='50%' direction='column'>
              <Text>Selfie</Text>
              <Document src={user.selfie}/>
            </Flex>
          </Flex>
        </>
        }
        </>
        :
        <BigText>{responseMsg}</BigText>
      }
      </Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`;

const Content = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

const Flex = styled.div`
  background: ${props => props.bg};
  display: flex;
  flex-direction: ${props => props.direction};
  width: ${props => props.width};
  justify-content: ${props => props.justify};
  align-items: start;
  gap: 15px;

  @media only screen and (max-width: 1080px) {
    width: ${props => !props.stop && '100%'};
    flex-direction: ${props => !props.stop && 'column'};
  }
`;

const Image = styled.img`
  width: 128px;
  height: 128px;
  background: gray;
  border-radius: 50%;
`;

const BigText = styled.p`
    font-weight: bold;
    font-size: 24px;
    margin: 0;
`;

const Text = styled.p`
    // width: 100%;
    font-weight: ${props => props.weight};
    margin: 0;
`;

const Document = styled.img`
  background: gray;
  width: 500px;
  max-width: 100%; /* Add this line */
  height: 500px;
  object-fit: contain;

  @media only screen and (max-width: 1080px) {
    height: 250px;
    width: 300px;
  }
`;

export default User