import React, { useContext, useState } from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import bg from '../../img/bg.jpeg'
import USbg from '../../img/USbg.jpg'
import TitleLogo from './TitleLogo'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'
import { logout } from '../../backend-functions/Auth';

const Sidebar = (props) => {
    const navigate = useNavigate();
  const [ toggle, setToggle ] = useState(true);
  const { userDetails, dispatch } = useContext(AuthContext);

  const handleLogout = async () => {
    const response = await logout();
    if(response){
      dispatch({type: "LOGOUT"});
      alert("You have successfully logout!");
      navigate('/');
    }
  }

  return (
    <Container toggle={toggle}>
      <Image src={userDetails.country === 'US' ? USbg : bg} />
      <Overlay toggle={toggle}>
        {/* TITLE */}
        <SpaceBetween>
            <HideonSmallScreen toggle={toggle}>
                <TitleLogo color={COLORS.white} padding='0'/>
            </HideonSmallScreen>
            <Menu onClick={() => {setToggle(!toggle)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </Menu>
        </SpaceBetween>

        {/* LINKS */}
        {/* Dasboard */}
        <LinkContainer active={props.dashboard} toggle={toggle}  href='/dashboard'>
            <Icon active={props.dashboard}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                    <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/>
                </svg>
            </Icon>
            
            <HideonSmallScreen toggle={toggle} marginLeft='10px'>
                <Text active={props.dashboard}>Dashboard</Text>
            </HideonSmallScreen>
        </LinkContainer>
        
        <Line/>

        <HideonSmallScreen toggle={toggle}>
            <LinkContainer  toggle={toggle}>
                <Text bold='bold'>Users</Text>
            </LinkContainer>
        </HideonSmallScreen>

        {/* User Accounts */}
        <LinkContainer active={props.userAccounts} toggle={toggle}  href='/users'>
            <Icon active={props.userAccounts}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
            </svg>
            </Icon>
            
            <HideonSmallScreen toggle={toggle} marginLeft='10px'>
                <Text active={props.userAccounts}>User Accounts</Text>
            </HideonSmallScreen>
        </LinkContainer>

        {/* Approve Agents */}
        <LinkContainer active={props.approveAgents} toggle={toggle}  href='/agents'>
            <Icon active={props.approveAgents}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-check" viewBox="0 0 16 16">
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>
            </svg>
            </Icon>
            
            <HideonSmallScreen toggle={toggle} marginLeft='10px'>
                <Text active={props.approveAgents}>Approve Agents</Text>
            </HideonSmallScreen>
        </LinkContainer>

        <Line/>

        <HideonSmallScreen toggle={toggle}>
            <LinkContainer  toggle={toggle}>
                <Text bold='bold'>Listings</Text>
            </LinkContainer>
        </HideonSmallScreen>

        {/* Browse Listings */}
        <LinkContainer active={props.browseListings} toggle={toggle}  href='/listings'>
            <Icon active={props.browseListings}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-houses" viewBox="0 0 16 16">
                <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708L5.793 1Zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207l-4.5-4.5Z"/>
            </svg>
            </Icon>
            
            <HideonSmallScreen toggle={toggle} marginLeft='10px'>
                <Text active={props.browseListings}>Browse Listings</Text>
            </HideonSmallScreen>
        </LinkContainer>

        {/* Portal Links */}
        <LinkContainer active={props.portalLinks} toggle={toggle}  href='/portals'>
            <Icon active={props.portalLinks}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
            </svg>
            </Icon>
            
            <HideonSmallScreen toggle={toggle} marginLeft='10px'>
                <Text active={props.portalLinks}>Portal Links</Text>
            </HideonSmallScreen>
        </LinkContainer>

        <Line/>

        <HideonSmallScreen toggle={toggle}>
            <LinkContainer  toggle={toggle}>
                <Text bold='bold'>Invoices</Text>
            </LinkContainer>
        </HideonSmallScreen>

        {/* Invoices */}
        <LinkContainer active={props.invoices} toggle={toggle}  href='/invoices'>
            <Icon active={props.invoices}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-credit-card-2-front" viewBox="0 0 16 16">
                <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"/>
                <path d="M2 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
            </svg>
            </Icon>
            
            <HideonSmallScreen toggle={toggle} marginLeft='10px'>
                <Text active={props.invoices}>Invoices</Text>
            </HideonSmallScreen>
        </LinkContainer>

        <Line/>

        {/* Logout */}
        <LinkContainer onClick={handleLogout} toggle={toggle}>
            <HideonSmallScreen toggle={toggle} marginLeft='10px'>
                <Text>Log Out</Text>
            </HideonSmallScreen>
        </LinkContainer>

      </Overlay>
    </Container>
  )
}

const Container = styled.div`
    background: yellow;
    min-height: 100vh;
    position: relative;
    width: ${props => props.toggle ? '20%' : '50px'};
    @media only screen and (max-width: 1080px) {
        width: 10%;
    }
`;

const HideonSmallScreen = styled.span`
    // background: red;
    margin-left: ${props => props.marginLeft};
    display: ${props => props.toggle ? 'block' : 'none'};
    @media only screen and (max-width: 1080px) {
        display: none;
    }
`;

const Image = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: blue;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: ${props => props.toggle ? '5%' : '10%'};
    box-sizing: border-box;
    background-color: ${COLORS.blue_faded};
    color: ${COLORS.white}; 
`;

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1080px) {
        justify-content: center;
    }
`;

const Menu = styled.button`
    color: ${COLORS.white};
    background: transparent;
    border: none;
    cursor: pointer;
    @media only screen and (max-width: 1080px) {
        display: none;
    }
`;

const LinkContainer = styled.a`
    background: ${props => props.active ? COLORS.white : 'transparent'};
    cursor: pointer;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0px;
    justify-content: ${props => props.toggle ? 'start' : 'center'};
    align-items: center;

    @media only screen and (max-width: 1080px) {
        justify-content: center;
        align-items: center;
    }
`;

const Icon = styled.div`
    // background: grey;
    color: ${props => props.active ? COLORS.blue : COLORS.white};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Text = styled.p`
    // background: brown;
    color: ${props => props.active ? COLORS.blue : COLORS.white};
    margin: 0;
    font-weight: ${props => props.bold ? 'bold' : ''};
    font-size: 18px;
`;

const Line = styled.div`
    background: ${COLORS.white};
    height: 1px;
`;

export default Sidebar
