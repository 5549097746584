import React from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'

const ShowFiles = ({selectedFiles, setSelectedFiles}) => {
  return (
    <Cont height='10%' width='100%'>
        {selectedFiles.map((file, index) => (
            <File key={index}> {file.name} </File>
        ))}
        <span style={{margin: '0px 10px', cursor: 'pointer'}} onClick={() => setSelectedFiles([])}>x</span>
    </Cont>
  )
}

const Cont = styled.div`
    height: ${props => props.height ? props.height : '100%'};
    width: ${props => props.width ? props.width : '100%'};
    display: flex;
    align-items: center;
    justify-content: ${props => props.justify ? props.justify : 'start'};;
    padding: ${props => props.padding};
    box-sizing: border-box;
    position: absolute;
    bottom: 10%;
    overflow-x: auto;
    background: white;
    border: 1px ${COLORS.gray} solid;

    @media only screen and (max-width: 780px) {
        display: ${props => props.hide ? 'none': 'flex'};
    }
`;

const File = styled.div`
    background: white;
    margin: 0px 10px;
    width: fit-content;
    border: 1px ${COLORS.blue} solid;
    padding: 4px 8px;
    border-radius: 8px;
    white-space: nowrap;
`;

export default ShowFiles
