import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const InputwithIcon = ({icon, ...props}) => {
  return (
    <InputContainer>
      <Input {...props} />
      <Icon icon={icon} />
    </InputContainer>
  )
}

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  border: 1px solid ${COLORS.blue_faded};
  border-radius: 4px;
  padding: 10px;
  padding-left: 40px;
  width: 100%;
  box-sizing:border-box;
  color: ${COLORS.blue_faded}
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: ${COLORS.blue_faded};
`;

export default InputwithIcon
