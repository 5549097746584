import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore"
import { db } from "../firebase"
import { COLORS } from "../components/variables";
import { getUser } from "./Users";
import axios from 'axios';

const sendMessage = async (message, link, sender, receiver) => {

  const accountSid = 'AC740dc74537b880722f125c60a2648bda';
  const authToken = 'beeb349d16859ae5cadcaf9ddb15b880';
      
  const data = new URLSearchParams();
  link = 'theagentgenie.com'+link;
  data.append('To', receiver);
  data.append('From', sender);
  data.append('Body', message + ' Click here: ' + link);
      
      axios.post('https://api.twilio.com/2010-04-01/Accounts/' + accountSid + '/Messages.json', data, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          auth: {
              username: accountSid,
              password: authToken,
          },
      })
      .then(response => {
          console.log('Message sent successfully:', response.data);
      })
      .catch(error => {
          console.error('Error sending message:', error);
      });
}

export const createNotification = async (data, userId) => {
    try {
      const userDocRef = doc(db, "notifications", userId);
      const notifRef = await addDoc(collection(userDocRef, "notifications"), {
        data: data,
        viewed: false,
        notified: false,
        timestamp: serverTimestamp(),
      });

      const userDoc = await getUser(userId);
      const userEmail = userDoc.email;
      const userPhone = userDoc.phone;
      const user = userDoc.fname;
      var img = userDoc.country === 'US' ? 'https://firebasestorage.googleapis.com/v0/b/theagentgenie-eecd2.appspot.com/o/assets%2FUSheader.png?alt=media&token=0c26a336-a57b-46b9-a196-28125e0f9aa6' : 'https://firebasestorage.googleapis.com/v0/b/theagentgenie-eecd2.appspot.com/o/assets%2FUKheader.png?alt=media&token=ae4bf756-c2de-4f65-a909-d77150dae848';
      var sender = userDoc.country === 'US' ? '+15642322898' : '+447700195347';

      if(data.data.img){
        img = data.data.img;
      }

      var messageBody = '<table width="100%" border="0" cellspacing="0" cellpadding="0" style="height: 100vh; box-sizing: content-box;">' +
                            '<tr>' +
                                '<td align="center" style="background-color: '+ COLORS.blue +'; padding: 25px; color: '+ COLORS.blue +'">' +
                                    '<div style="margin-bottom: 10px;">' +
                                        '<img src="https://firebasestorage.googleapis.com/v0/b/theagentgenie-eecd2.appspot.com/o/assets%2Flogowithname.png?alt=media&token=866b229b-0faa-4908-bca7-8e2426788564" alt="Confetti" style="width: 300px; height: 50px; object-fit: cover;">' +
                                    '</div>' +
                                    '<div align="left" style="background-color: white; position: relative; width: 500px; padding: 25px; height: 90%; box-sizing: border-box;">' +
                                        '<div>' +
                                            '<img src="'+ img +'" style="width: 100%; height: 100px; object-fit: cover;">' +
                                        '</div>' +
                                        '<h1>Hi, '+ user +'</h1>' +
                                        '<h3 style="text-align: justify;">'+ data.body +'</h3>' +
                                        '<br/><br/>' +
                                        '<div align="center">' +
                                            '<a href="https://theagentgenie.com'+ data.data.url +'" style="text-decoration: none; color: white; background-color: '+ COLORS.blue +'; padding: 10px 20px; border-radius: 5px;">'+ data.data.action +'</a>' +
                                        '</div>' +
                                        '<br/><br/><br/><br/><br/><br/>' +
                                        '<p style="text-align: center; position: absolute; bottom: 10%; width: 90%; box-sizing: content-box;">If you have any questions, please don’t hesitate to contact us at <a href="mailto:support@theagentgenie.com">support@theagentgenie.com</a></p>' +
                                    '</div>' +
                                '</td>' +
                            '</tr>' +
                        '</table>'
    
      // send email for that notification
      window.Email.send({
        Host : "smtp.elasticemail.com",
        Username : process.env.REACT_APP_USERNAME,
        Password : process.env.REACT_APP_PASSWORD,
        To : userEmail,
        From : process.env.REACT_APP_USERNAME,
        Subject : "The Agent Genie Notification",
        Body : messageBody
      }).then(
        console.log("success")
      ).catch(
        function (error) {
          console.log(error);
          console.error("error ", error); // Log the error if sending fails
        }
      );

      // send text message for that notification
      sendMessage(data.body, data.data.url, sender, userPhone);

      return notifRef;
    } catch (error) {
      console.error(error);
    }
};

export const listenNotifications = async (userId) => {
    try {
      // Reference the subcollection for the specific user
      const userNotificationsCollection = collection(db, "notifications", userId, "notifications");
  
      // Set up a listener for added documents
      onSnapshot(userNotificationsCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            // Handle the new document here
            const newNotificationData = change.doc.data();

            if(!newNotificationData.notified && Notification.permission === 'granted'){
                const notification = new Notification('Congratulations', newNotificationData.data);
                changeStatus(userId, change.doc.id, true, 'notified')

                notification.onclick = (event) => {
                    event.preventDefault(); // Prevent the browser's default behavior
                  
                    // Access the URL from the notification's data property
                    const url = notification.data.url;
                    // Open the URL in a new tab or navigate to it
                    if (url) {
                      window.open(url, '_blank'); // Opens the URL in a new tab
                      changeStatus(userId, change.doc.id, true, 'viewed')
                    }
                  };
            }    
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  };
  
  export const changeStatus = async (uid, notifid, newStatus, field) => {
    try {
      // Construct the correct collection and document references
      const notificationsCollectionRef = collection(db, "notifications", uid, 'notifications');
      const notificationDocRef = doc(notificationsCollectionRef, notifid);

      // Update the specified field in the notification document
      await updateDoc(notificationDocRef, {
        [field]: newStatus,
      });
  
    } catch (error) {
      console.error("Error updating details:", error);
    }
  };

  export const getLatestNotificationsForUser = async (userId, setIsUnread) => {
    try {
      const notificationsCollectionRef = collection(db, "notifications", userId, "notifications");
  
      const q = query(
        notificationsCollectionRef,
        orderBy("timestamp", "desc"), // Sort by timestamp in descending order
        limit(5) // Limit the result to the latest 5 notifications
      );
  
      // Execute the query and retrieve the documents
      const querySnapshot = await getDocs(q);
  
      // Iterate through the querySnapshot to access the latest notifications
      const notifications = [];
      querySnapshot.forEach((doc) => {
        if(!doc.data().viewed){
            setIsUnread(true)
        }
        notifications.push({id: doc.id, viewed: doc.data().viewed, ...doc.data().data});
      });
  
      return notifications;
    } catch (error) {
      console.error("Error getting latest notifications:", error);
      return [];
    }
  };
  
  
  