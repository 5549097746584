import React from 'react'
import styled from 'styled-components'

const SmallButton = (props) => {
  return (
    <Button bgColor={ props.bgColor } onClick={props.onClick} disabled={props.disabled} {...props}>
      <Text  color={ props.color }>
        {props.text}
      </Text>
    </Button>
  )
}

SmallButton.defaultProps = {
  text: 'Continue',
}

const Button = styled.button`
    font-weight: bold;
    text-align: center;
    background: ${props => props.bgColor};
    border: 0;
    padding: 0px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    @media only screen and (max-width: 780px) {
      width: 100%;
  }
`;

const Text = styled.p`
    color: ${props => props.color};
    font-weight: bold;
    font-size: 20;
    font-family: Poppins;
    margin: 5px;
`;

export default SmallButton
