import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from '../components/Pagination/Pagination';
import { COLORS } from '../components/variables';
import TopNavbar from '../components/Navbar/Navbar';
import InputwithIcon from '../components/Forms/InputwithIcon';
import CheckboxwithLabel from '../components/Forms/CheckboxwithLabel';
import { AuthContext } from '../context/AuthContext';
import { getListings, getListingsofAgent } from '../backend-functions/Listings';
import DropdownField from '../components/Forms/DropdownField';
import States from '../components/UKpostcodes';
import BigButton from '../components/Buttons/BigButton';
import Confetti from '../components/Background/Confetti';

const BrowseListings = () => {
  const bidnotif = new URLSearchParams(window.location.search).get('bid');

  const filterOptions = [
    { value: 'All', label: 'All' },
    { value: 'Bidding Ongoing', label: 'Bidding Ongoing' },
    { value: 'List Ended', label: 'List Ended' },
    { value: 'Deal Ongoing', label: 'Deal Ongoing' },
    { value: 'Property Sold', label: 'Property Sold' },
  ];

  const [type] = useSearchParams();
  const { userDetails } = useContext(AuthContext);
  const [listings, setListings] = useState([]);
  const [ confetti, setConfetti ] = useState(bidnotif && bidnotif === 'successful');

  const [ search, setSearch ] = useState('');
  const [ selectedFilter, setSelectedFilter ] = useState('');
  const seller = (userDetails.usertype === 'Seller') ? userDetails.uid : '';
  
  const isAgent = userDetails.usertype === 'Agent';

  const [ isNull, setIsNull ] = useState(true);
  let text = ((type.get('type') === 'yourbiddings' && isAgent) || seller !== '') ? 'Your Listings' : 'Available Listings';

  useEffect(() => {
    const fetchData = async () => {
      const fetchedListings = isAgent && type.get('type') === 'yourbiddings'
        ? await getListingsofAgent({agent: userDetails.uid, keyword: search,  postcode: userDetails.postcode})
        : await getListings({keyword: search, type: selectedFilter, postcode: userDetails.postcode, seller: seller, active: true, country: userDetails.country});
      // Create a new array of listings with 'seller' key removed
      const filteredListings = fetchedListings.map(({ Seller, ...rest }) => rest);
      setListings(filteredListings);

      if(filteredListings.length < 1){
        setIsNull(!isNull);
      }
    };
    fetchData();
  }, [type, isAgent, userDetails.uid, search, selectedFilter]);

  const headerText = type.get('type') !== 'yourbiddings'
    ? { code: [userDetails.country === 'US' ? 'Zip Code' : 'Post Code'], no_of_bid: 'No. of Bids', topbid: 'Top Bidding', 'Ends On': 'Ends On', status: 'Status', type: 'header', button: '' }
    : { code: [userDetails.country === 'US' ? 'Zip Code' : 'Post Code'], no_of_bid: 'No. of Bids', topbid: 'Top Bidding', yourBid: 'Your Bidding', 'Ends On': 'Ends On', status: 'Status', type: 'header', button: '' };

  let showBid = '';
  if(userDetails.usertype === "Agent"){
    showBid = type.get('type') !== 'yourbiddings' ? 'Submit Bid' : 'Edit Bid';
  }

  return (
    <div>
      {confetti && <Confetti setConfetti={setConfetti} />}
      
      <Container height="10vh">
        <TopNavbar bgColor={COLORS.blue} btncolor={COLORS.yellow}></TopNavbar>
      </Container>
      <Flex>
        <FlexCont width="70%">
          <Text weight="bold" size="18px">{text}</Text>
          <br/>
          <SpaceBetween>
            <Flex width='50%'>
              <P>Search:</P>
              <Input icon={faSearch} placeholder="Search by Postcode" value={search} onChange={(e) => setSearch(e.target.value)}/>
            </Flex>
            {type.get('type') !== 'yourbiddings' &&
            <Flex width='50%'>
              <P>Filter:</P>
              <DropdownField options={filterOptions} setSelectedOption={setSelectedFilter} />
            </Flex>}
            {userDetails.usertype === "Seller" &&
            <Flex width='50%'>
              <Link to={'/create?postcode='}>
                <BigButton text="Create Listing" color={COLORS.white}  bgColor={COLORS.blue}/>
              </Link>
            </Flex>}
          </SpaceBetween>
        </FlexCont>

        {/* LIST OF LISTING VARY BASED ON QUERY */}
        
        <FlexCont width="100%" style={{ padding: '10px 0px' }}>
          <Margin>
            <Pagination data={listings} header={headerText} showBid={showBid} isNull={isNull}/>
          </Margin>
        </FlexCont>
      </Flex>
    </div>
  );
};

const Container = styled.div`
  position: relative;
  height: ${(props) => props.height};
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};

  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const P = styled.p`
  margin: 2px 0px;
`;

const FlexCont = styled.div`
  width: ${(props) => props.width};
  background: ${(props) => props.bg};
  color: ${COLORS.blue};
  padding: 10px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 780px) {
    width: 100%;
    padding: 10px 20px;
  }
`;

const Text = styled.p`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  margin: auto;
`;

const Input = styled(InputwithIcon)`
  width: 100%;
`;

const SpaceBetween = styled.div`
  display flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  gap: 20px;

  @media only screen and (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }
`;

const Form = styled.form``;

const Margin = styled.div`
  padding: 0px 50px;
  @media only screen and (max-width: 780px) {
    padding: 0px 20px;
  }
`;

export default BrowseListings;
