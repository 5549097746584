import React, { useState } from 'react'
import { COLORS } from '../../components/variables'
import styled from 'styled-components'
import Sidebar from '../../components/Navbar/Sidebar'
import Carousel from '../../components/Carousel/Carousel'
import UserLists from '../../components/Carousel/UserLists'
import { countListingsBasedOnField, getListings, getMonthlyRevenue, getPortalLinks } from '../../backend-functions/Listings'
import Loading from '../../components/Modal/Loading'
import { getNewUsers, getPendingAgents } from '../../backend-functions/Users'

const Dashboard = () => {
  const Oheader = {seller: 'Seller', agent: 'Agent', code: 'Post Code', bid: 'Bid', offer_price: 'Offer Price', sold_price: 'Sold Price', type: 'header'};

  const [ totalOngoing, setTotalOngoing ] = useState('');
  const [ totalSold, setTotalSold ] = useState('');
  const [ monthlyRevenue, setMonthlyRevenue ] = useState('');
  const [ listings, setListings ] = useState([]);
  const [ agents, setAgents ] = useState(null);
  const [ sellers, setSellers ] = useState(null);

  const fetchData = async () => {
    setTotalOngoing(await countListingsBasedOnField("listings", "status", "!=", "Sold Property"));
    setTotalSold(await countListingsBasedOnField("listings", "status", "==", "Sold Property"));
    setMonthlyRevenue(await getMonthlyRevenue());
    setListings(await getPortalLinks('', 5));
    setAgents(await getPendingAgents());
    setSellers(await getNewUsers());
  }

  fetchData();

  return (
    <Page>
      <Sidebar dashboard={true} />
      <Content>
        <h2>Overview</h2>
        <Container>
          {/* Ongoing Listings */}
          <Box area='listings'>
            <SpaceBetween>
              Ongoing Listings
              <CircleIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-houses" viewBox="0 0 16 16">
                  <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708L5.793 1Zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207l-4.5-4.5Z"/>
                </svg>
              </CircleIcon>
            </SpaceBetween>
            <BigText>
              {totalOngoing !== '' ? totalOngoing : <Loading/>}
            </BigText>
          </Box>
          {/* Sold Properties */}
          <Box area='sold'>
            <SpaceBetween>
              Sold Properties
              <CircleIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-house-check" viewBox="0 0 16 16">
                <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708L7.293 1.5Z"/>
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.707l.547.547 1.17-1.951a.5.5 0 1 1 .858.514Z"/>
              </svg>
              </CircleIcon>
            </SpaceBetween>
            <BigText>
              {totalSold !== '' ? totalSold : <Loading/>}
            </BigText>
          </Box>
          {/* Monthly Revenue */}
          <Box area='monthly'>
            <SpaceBetween>
              Monthly Revenue
              <CircleIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-currency-pound" viewBox="0 0 16 16">
                <path d="M4 8.585h1.969c.115.465.186.939.186 1.43 0 1.385-.736 2.496-2.075 2.771V14H12v-1.24H6.492v-.129c.825-.525 1.135-1.446 1.135-2.694 0-.465-.07-.913-.168-1.352h3.29v-.972H7.22c-.186-.723-.372-1.455-.372-2.247 0-1.274 1.047-2.066 2.58-2.066a5.32 5.32 0 0 1 2.103.465V2.456A5.629 5.629 0 0 0 9.348 2C6.865 2 5.322 3.291 5.322 5.366c0 .775.195 1.515.399 2.247H4v.972z"/>
              </svg>
              </CircleIcon>
            </SpaceBetween>
            <BigText>{monthlyRevenue !== '' ? monthlyRevenue : <Loading/>}</BigText>
          </Box>
          {/* To Be Approved Agents */}
          <Box area='agents'>
            To Be Approved Agents
            { agents ? <UserLists users={agents} url='/view/' baseUrl='/users/' /> : <Loading/> }
          </Box>
          {/* Users Chart */}
          <Box area='users'>
            Users
          </Box>
          {/* Yearly Revenue Chart */}
          <Box area='yearly'>
            Yearly Revenue
          </Box>
          {/* New Sellers */}
          <Box area='sellers'>
            New Sellers
            { sellers ? <UserLists users={sellers} url='/approveagent/' baseUrl='/agents/' /> : <Loading/> }
          </Box>
          {/* Ongoing Listings Table */}
          <Box area='listingsTable'>
            Ongoing Listings
            <Carousel header={Oheader} listings={listings} shadow='none' />
          </Box>
        </Container>
      </Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`;

const Content = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  padding: 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  display: grid;
  gap: 15px;
  grid-template-areas:
    'listings listings sold sold monthly monthly agents'
    'users users users yearly yearly yearly agents'
    'users users users yearly yearly yearly agents'
    'users users users yearly yearly yearly sellers'
    'listingsTable listingsTable listingsTable listingsTable listingsTable listingsTable sellers'
    'listingsTable listingsTable listingsTable listingsTable listingsTable listingsTable sellers';
  grid-template-rows: 160px 100px 100px 100px 240px 240px;

  @media only screen and (max-width: 1080px) {
    grid-template-areas:
      'listings listings'
      'sold sold'
      'monthly monthly'
      'yearly yearly'
      'users users'
      'listingsTable listingsTable'
      'agents agents'
      'sellers sellers';
    // grid-template-rows: 160px 160px 160px 160px 220px 350px 350px 350px;
    grid-template-rows: repeat(8, minmax(160px, auto));
  }
`;

const Box = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  grid-area: ${props => props.area};
  border-radius: 15px;
  padding: 15px;
  position: relative;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CircleIcon = styled.div`
  background: ${COLORS.green};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
`;

const BigText = styled.p`
  position: relative;
  font-size: 32px;
  font-weight: bold;
`;

export default Dashboard
