import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import SmallButton from '../Buttons/SmallButton'

const InstallPWA = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [ close, setClose ] = useState(false);
  const isIOS = /iPad|iPhone|iPod|Mac OS/.test(navigator.userAgent) && !window.MSStream;

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevent the default prompt
      setDeferredPrompt(e);
    };

    // Check for the beforeinstallprompt event
    const isPWAInstalled = window.matchMedia('(display-mode: standalone)').matches;

    if (!isPWAInstalled) {
      // PWA is not installed; add the event listener
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (!isPWAInstalled) {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      }
    };
  }, []);

  useEffect(() => {
    const checkPWAInstalled = async () => {
      if ('getInstalledRelatedApps' in navigator) {
        navigator.getInstalledRelatedApps()
          .then(relatedApps => {
            console.log(relatedApps)
            if (relatedApps.length > 0) {
              // The PWA is installed as a related app
              console.log('Your PWA is installed as a related app.');
            } else {
              // The PWA is not installed as a related app
              console.log('Your PWA is not installed as a related app.');
            }
          })
          .catch(error => {
            console.error('Error checking for installed related apps:', error);
          });
      } else {
        // The method is not supported by the browser
        console.log('getInstalledRelatedApps is not supported.');
      }      
    }

    checkPWAInstalled();
  }, []);

  // Render the component only if PWA is not installed
  if (window.matchMedia('(display-mode: standalone)').matches || close) {
    return null; // PWA is already installed, don't render this component
  }

  const handleInstall = () => {
    if (deferredPrompt) {
        deferredPrompt.prompt(); // Show the installation prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {

            // create a cookie to not show the install prompt for a week
            document.cookie = "hidePrompt = true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
          }
          setDeferredPrompt(null); // Reset the deferredPrompt
        });
    }
  }

  const handleClose = () => {
    setClose(true);

    // create a cookie to not show the install prompt for a week
    document.cookie = "hidePrompt = true; expires=" + new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toUTCString();
  }

  return (
    !isIOS ? (
      <Container>
        <svg onClick={handleClose} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
  
        <div style={{ display: 'inline-flex' }}>
          <Text>Install for a better experience.</Text>
          <SmallButton text="Install" bgColor={COLORS.blue} color={COLORS.white} onClick={handleInstall} />
        </div>
      </Container>
    ) : (
      <TooltipContainer>
        <TooltipContent>
          <svg onClick={handleClose} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
          <p>
            To install the app: Press &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z" />
              <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z" />
            </svg>
            &nbsp;
            and tap "Add to Home Screen"
          </p>
        </TooltipContent>
      </TooltipContainer>
    )
  );
}

const Container = styled.div`
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Text = styled.p`
    font-size: 18px;
    white-space: nowrap;

    @media only screen and (max-width: 780px) {
        font-size: 12px;
    }
`;

const TooltipContainer = styled.div`
  position: fixed;
  bottom: 20px; /* Adjust the desired distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 95%;
  z-index: 1000;
`;


const TooltipContent = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  p {
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  ol {
    list-style-type: decimal;
    padding-left: 20px;
  }
`;

const CloseButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 0 0 4px 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ShareIcon = styled.i`
  font-size: 16px;
  margin-right: 5px;
  color: #007BFF;
`;

export default InstallPWA
