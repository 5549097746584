import React, { useRef } from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'

const FileInput = ({setSelectedFiles}) => {
  const fileInputRef = useRef(null);

//   const handleFileSelect = () => {
//     fileInputRef.current.click();
//   };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setSelectedFiles(selectedFiles);
  };

  return (
    <Label padding='3px' width='55px' justify='center' htmlFor='file-input'>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
        </svg>
        <input
            type="file"
            id="file-input"
            accept=".svg, .png, .jpg, .jpeg, .pdf, .doc, .docx"
            multiple
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
        />
    </Label>
  )
}

const Label = styled.label`
    height: ${props => props.height ? props.height : '100%'};
    width: ${props => props.width ? props.width : '100%'};
    display: flex;
    align-items: center;
    justify-content: ${props => props.justify ? props.justify : 'start'};;
    padding: ${props => props.padding};
    box-sizing: border-box;

    @media only screen and (max-width: 780px) {
        display: ${props => props.hide ? 'none': 'flex'};
    }
`;

export default FileInput
