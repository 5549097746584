import React from 'react'
import { COLORS } from '../variables'
import styled, { keyframes } from 'styled-components'


const Loading = () => {
  return (
    <Container>
      <LoadingCont>
        <SpinnerContainer />
      </LoadingCont>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;

const LoadingCont = styled.div`
    background-color: ${COLORS.blue};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 8px;
`;

// Keyframes for the rotating animation
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component for the loading spinner
const SpinnerContainer = styled.div`
  display: inline-block;
  width: ${props => props.size || '40px'};
  height: ${props => props.size || '40px'};
  border: 8px solid ${COLORS.white};
  border-radius: 50%;
  border-top: 8px solid ${COLORS.red};
  animation: ${rotate} 1s linear infinite;
`;

export default Loading
