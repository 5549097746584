import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import TitleLogo from './TitleLogo';
import { AuthContext } from '../../context/AuthContext';

const Footer = () => {
  const { userDetails } = useContext(AuthContext)

  return (
    <Main>
        <Container>
        <Cont style={{padding: '20px 0px'}}>
            <TitleLogo size='30px'></TitleLogo>
        </Cont>
        <Cont>
            <BigText>Useful Links</BigText>
            <br></br>
            <FlexRow>
                <Link href='/account'>Your Account</Link>
                <Link href='/terms-of-use'>Terms of Use</Link>
                <Link href='/privacy-policy'>Privacy Policy</Link>
                {userDetails && userDetails.usertype && userDetails.usertype === 'Agent' && <Link href={`/referral-agreement/${userDetails.referralToken}`}>Referral Agreement</Link>}
            </FlexRow>
        </Cont>
        <Cont>
            <BigText>Contact</BigText>
            <br></br>
            <FlexRow>
                <Link href='/'>
                    <Icon xmlns="http://www.w3.org/2000/svg" width="24" heigfirebasht="24" fill={COLORS.white} class="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                    </Icon>
                    support@theagentgenie.com
                </Link>
            </FlexRow>
        </Cont>
        </Container>
        <div style={{display:'flex', gap:'4px'}}>
            <Link style={{marginTop: '16px'}}>All Right Reserved 2024 ©</Link><Link style={{marginTop: '16px'}} href='/privacy-policy'>Privacy Policy</Link>
        </div>
    </Main>
  );
}

const Main = styled.footer`
    background: ${COLORS.blue};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    width: 100%;

    @media only screen and (max-width: 780px) {
        margin-top: 0px;
    }
`;

const Container = styled.div`
    background: ${COLORS.blue};
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

const Cont = styled.div`
    width: 33%;
    background: ${props => props.bg};
    color: ${COLORS.white};
    padding: 20px;
    box-sizing:border-box;

    @media only screen and (max-width: 780px) {
        width: 100%;
    }
`;

const BigText = styled.h2`
    color: ${COLORS.white};
    margin: 0;
`;

const Highlight = styled.span`
    color: ${COLORS.yellow};
`;

const Justify = styled.p`
  text-align: justify;
  text-justify: inter-word;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: column;
`;

const Link = styled.a`
    color: ${COLORS.white};
    margin: 5px 0px;
    display: flex;
    align-items: center;
`;

const Icon = styled.svg`
    margin-right: 20px;
`;

export default Footer
