import { QuerySnapshot, addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { fileUpload } from "./custom-functions";
import { DefaultProPic } from "../components/variables";
import { getUser } from "./Users";

export const sendMessage = async (dealId, senderId, message) => {
    try{
        const dealRef = doc(db, "deal_listings", dealId);
        const messageRef = collection(dealRef, "messages");

        await addDoc(messageRef, {
            sender_id: senderId,
            message: message,
            timestamp: serverTimestamp(),
        });
    }catch(error){
        console.error(error);
    }
}

export const sendFile = async (dealId, senderId, file) => {
    try{
        const dealRef = doc(db, "deal_listings", dealId);
        const messageRef = collection(dealRef, "messages");

        // upload file to storage
        const fileRef = await fileUpload(file, "deal_listings/" + dealId + "/messages/" + file.name);

        // decide whether the file is an image or not
        const fileType = (file.type.split("/")[0] === "image") ? "imgUrl" : "fileUrl";

        await addDoc(messageRef, {
            sender_id: senderId,
            [fileType]: fileRef,
            ...(fileType === "fileUrl" && { fileName: file.name }),
            timestamp: serverTimestamp(),
        });

    }catch(error){
        console.error(error);
    }
}

export const getMessages = async (dealId, onDataReceived) => {
    try {
      const dealRef = doc(db, "deal_listings", dealId);
      const messagesRef = collection(dealRef, "messages");
  
      const msgQuery = query(messagesRef, orderBy("timestamp"));
  
      const unsubscribe = onSnapshot(msgQuery, async (querySnapshot) => {
        const messagesPromises = [];
  
        querySnapshot.forEach((msgDoc) => {
          const msg = msgDoc.data();
          const senderPromise = getUser(msg.sender_id).then((sender) => ({
            sender_id: msg.sender_id,
            message: msg.message,
            img: sender && sender.img ? sender.img : DefaultProPic,
            fileName: msg.fileName,
            fileUrl: msg.fileUrl,
            imgUrl: msg.imgUrl
          }));
          messagesPromises.push(senderPromise);
        });
  
        const messages = await Promise.all(messagesPromises);
  
        onDataReceived(messages);
      });
  
      return unsubscribe;
    } catch (error) {
      console.error(error);
      return null;
    }
  };  