import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS, DefaultProPic } from '../components/variables'
import TopNavbar from '../components/Navbar/Navbar'
import AgentNumbers from '../components/Containers/AgentNumbers'
import ProfilePicture from '../components/Containers/ProfilePicture'
import GenieScore from '../components/Containers/GenieScore'
import { useParams } from 'react-router-dom'
import { getReviews, getUser } from '../backend-functions/Users'
import Loading from '../components/Modal/Loading'

const AgentProfile = () => {
    const { id } = useParams();
    const [ agent, setAgent ] = useState(null);
    const [ reviews, setReviews ] = useState(null);
    const [ isNull, setIsNull ] = useState(!agent || !reviews || !id );

    useEffect(() => {
        const fetchData = async () => {
        try{
            const user = await getUser(id);
            setAgent(user);

            const reviews = await getReviews(id);
            setReviews(reviews);

            if(!user){
                setIsNull(!isNull);
            }

        } catch(error){
            console.error("Error fetching data: ", error);
        }
        };

        fetchData();
    }, [id]);


  const image = agent && agent.img ? agent.img : DefaultProPic;
  return (
    <div>
      <Container height="10vh">
        <TopNavbar bgColor={COLORS.blue} btncolor={COLORS.yellow}></TopNavbar>
      </Container>

        <div style={{position: 'relative'}}>
            {!agent || !reviews || !id  ? 
            isNull ?
            <><br/><br/><br/><br/><br/><br/><br/><br/>
            <Loading/></> :
            <h1 style={{padding: '15%'}}>No Agent found</h1> 
            :
            <>
            {agent && <FlexCol>
                <FlexRow col='yes' gap='15px'>
                    <FlexRow col='yes' width='75%'>
                        <Cont width='50%'>
                            <FlexRow col='yes' gap='15px'>
                                <Cont width='200px' align='center'>
                                    <ImgContainer>  
                                        <ProfilePicture image={image}/>
                                    </ImgContainer>
                                </Cont>
                                <Cont width='55%' align='center' gap='10px'>
                                    <h2 style={{margin: '0'}}>{agent.fname + " " + agent.lname}</h2>
                                    <p style={{margin: '0'}}>{agent.company}</p>
                                    <GenieScore percent={agent.genie_score} />
                                </Cont>
                            </FlexRow>
                        </Cont>
                        <Cont width='50%'>
                            <AgentNumbers id={id} />
                        </Cont>
                    </FlexRow>
                </FlexRow>

                <br/>
                {reviews && <>
                <FlexRow>
                    <Cont width='75%'>
                        What other sellers say...
                    </Cont>
                </FlexRow>
                <br/>

                {reviews.map(data => {
                    return (
                        <FlexRow style={{marginBottom: '10px'}}>
                            <Cont width='75%'>
                                <Box>
                                    <p>{data.name}</p>
                                    <GenieScore percent={data.percent} />
                                    <p>{data.review}</p>
                                </Box>
                            </Cont>
                        </FlexRow>
                    );
                })}
                </>}
                
            </FlexCol>}
            </>}
        </div>
    </div>
  )
}

const Container = styled.div`
    position: relative;
    height: ${ props => props.height};
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5% 10%;
    box-sizing: border-box;
    justify-contents: center;
`;

const FlexRow = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: ${props => props.width ? props.width : '100%'};
    gap: ${props => props.gap};
    @media only screen and (max-width: 780px) {
        flex-direction: ${props => props.col === 'yes' ? 'column' : 'row'};
        width: 100%;
    }
`;

const Cont = styled.div`
    width: ${props => props.width};
    display: flex;
    flex-direction: column;
    gap: ${props => props.gap};

    @media only screen and (max-width: 780px) {
        width: 100%;
        margin-bottom: 10px;
        margin: 0 auto;
        padding: 0;
        align-items: ${props => props.align ? 'center' : 'start'};
    }
`;

const ImgContainer = styled.div`
    height: 200px;
    width: 200px;

    @media only screen and (max-width: 780px) {
        height: 100px;
        width: 100px;
    }
`;

const Box = styled.div`
    width: 100%;
    min-height: 200px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export default AgentProfile
