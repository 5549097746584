import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import { calculateRemainingTime } from '../../backend-functions/custom-functions';
import { changeListingStatus } from '../../backend-functions/Listings';

const BigItem = ({listing, border}) => {
  const exclusions = ['id', 'type', 'place'];
  const [endTime, setEndTime] = useState('');
  const [type, setType] = useState('default');
  const [countdownTimer, setCountdownTimer] = useState(null);

  useEffect(() => {
    // Cleanup function to clear the interval when component is unmounted
    return () => {
      if (countdownTimer) {
        clearInterval(countdownTimer);
      }
    };
  });

  useEffect(() => {
    if (listing) {
      const interval = setInterval(() => {
        setType('default');
        calculateRemainingTime(listing.endDate)
          .then((remainingTime) => {
            let string = '';
  
            if (remainingTime.days > 0) {
              string += remainingTime.days + 'd ';
            }
            if (remainingTime.hours > 0) {
              string += remainingTime.hours + 'h ';
            }
            if (remainingTime.minutes > 0) {
              string += remainingTime.minutes + 'm ';
            }
            if (remainingTime.seconds > 0) {
              string += remainingTime.seconds + 's ';
            }
            if (remainingTime.days <= 0) {
              setType('danger');
              if(Object.keys(listing.biddings).length > 0){
                setType('green')
              }
            }
        
            setEndTime(string + ` left <br/> (${listing.ends_on})`);
            if (
              remainingTime.days <= 0 &&
              remainingTime.hours <= 0 &&
              remainingTime.minutes <= 0 &&
              remainingTime.seconds <= 0
            ) {
              clearInterval(interval); // Stop the interval when countdown reaches 0
              setEndTime('List Ended');
              if (listing.status === 'Bidding Ongoing') {
                changeListingStatus(listing.id, 'List Ended');
              }
            }
          })
          .catch((error) => {
            console.error(error); // Log any errors that occur during the promise chain
            clearInterval(interval); // Stop the interval in case of an error
          });
      }, 1000); // Recurring every 1 second
  
      return () => {
        clearInterval(interval); // Clear the interval when component is unmounted or when `listing` changes
      };
    }
  }, [listing]);  

  const data = {
    id: listing.id,
    [listing.country === 'US' ? 'Zip Code' : 'Post Code']: listing.postcode,
    'No. of Bids': Object.keys(listing.biddings).length || 0,
    'Ends On': `${endTime}`,
    type: type,
  }; 

  const filteredKeys = Object.keys(data).filter((key) => !exclusions.includes(key));
  let keyCount = filteredKeys.length;
  let width = 100 / keyCount;

  return (
    <Container border={border}>
        <Label type={data.type}></Label>
        <Cont type={data.type}>
            {Object.keys(data).map((key) => {
                if(key != "id" && key != "type" && key != "place"){
                    return (key !== 'Ends On') ? (
                        <Text type={data.type} width={width} border={border}>
                            <TinyText>{key}</TinyText>
                            {data[key]}
                        </Text>) : 
                        (
                        <Text type={data.type} width={width} border={border}>
                          <TinyText>{key}</TinyText>
                          <span dangerouslySetInnerHTML={{ __html: data[key] }}></span>
                        </Text>
                        )};
                }
            )}
        </Cont>
    </Container>
  )
}

const Container = styled.a`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
`;

const Cont = styled.div`
    padding: 20px;
    display: flex;
    width: 100%;

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

const Label = styled.div`
    background-color: ${props => (props.type === "danger" ? COLORS.red : props.type === "default" ? COLORS.blue : props.type === 'green' ? COLORS.green : 'transparent')};
    width: 20px;
`;

const Text =  styled.p`
    display: flex;
    flex-direction: column;
    font-size: 24px;
    margin: 0;
    width: ${props => props.width + '%'};
    color: ${props => (props.type === "danger" ? COLORS.red : COLORS.blue)};

    @media only screen and (max-width: 780px) {
        font-size: 18px;
        width: 100%;
    }
`;

const TinyText = styled.span`
    margin: 0px 5px;
    font-size: 16px;
    font-weight: bold;

    @media only screen and (max-width: 780px) {
        font-size: 12px;
    }
`;

export default BigItem
