import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'
import Carousel from '../components/Carousel/Carousel'
import Pagination from '../components/Pagination/Pagination'
import { AuthContext } from '../context/AuthContext'
import { getListings, getListingsofAgent, countWinningBids } from '../backend-functions/Listings'
import Loading from '../components/Modal/Loading'
import Confetti from '../components/Background/Confetti'

const HomePageAgent = () => {
  const bidnotif = new URLSearchParams(window.location.search).get('bid');

  // temporary array
  const [listings, setListings] = useState(null);
  const [topbid, setTopbid] = useState(null);

  const { userDetails } = useContext(AuthContext);
    
  const Fheader = {code: [userDetails.country === 'US' ? 'Zip Code' : 'Post Code'], no_of_bid: 'No. of Bids', topbid: 'Top Bidding', 'Ends On': 'Ends On', button: '', type: 'header'};
  const Bheader = {code: [userDetails.country === 'US' ? 'Zip Code' : 'Post Code'], no_of_bid: 'No. of Bids', topbid: 'Top Bidding', yourBid: 'Your Bidding', 'Ends On': 'Ends On', status: 'Status', button: '', type: 'header'};
  const [ confetti, setConfetti ] = useState(bidnotif && bidnotif === 'successful');

  

  useEffect(() => {
    if (userDetails && userDetails.uid) {
      getListings({ limitVal: 5, type: 'Bidding Ongoing', active: true, postcode: userDetails.postcode, country: userDetails.country })
        .then((listings) => {
          const filteredListings = listings.map(({ Seller, Status, ...rest }) => ({ ...rest, hideStatus: Status }));
          setListings(filteredListings);
        })
        .catch((error) => {
          console.error('Error fetching getListings:', error);
        });

      getListingsofAgent({agent: userDetails.uid, limitVal: 5})
        .then((listings) => {
          const filteredListings = listings.map(({ Seller, ...rest }) => ({ ...rest }));;
          setTopbid(filteredListings);
        })
        .catch((error) => {
          console.error('Error fetching agent listings:', error);
        });

      // count how many winning listing an agent has
      countWinningBids(userDetails.uid)
      .then((wonListings) => {
        if(wonListings > 0){
          setConfetti(true)
        }
      })
    } else {
      console.warn('userDetails.uid is null or undefined');
    }
  }, [userDetails]); // Update the dependency array based on when userDetails might change
    
  return (
    <div>
      {confetti && <Confetti setConfetti={setConfetti} />}
      <Container>
        <br/><br/><br/>
        <p>All details will be <u>anonymous</u> until you have won a listing.</p>
      </Container>
      
      {listings ? <span>
      <FlexCol
        stop="yes"
        style={{ justifyContent: "space-between", alignItems: "center"}}
      >
        <Line width='35%'></Line>
        <Text>Featured <HighLight>Listings</HighLight> Near You</Text>
        <Line width='35%'></Line>
      </FlexCol>

      <Container>
        <Pagination data={listings} header={Fheader} showBid='Submit Bid'/>
      </Container>
      </span>
      :
      <FlexCol style={{ justifyContent: "space-between", alignItems: "center", marginTop: '5%', position: 'relative' }}>
        <Loading/>
      </FlexCol>
      
      }

      {topbid && userDetails.usertype === 'Agent' && <span>
        <br/>
      <FlexCol
        stop="yes"
        style={{ justifyContent: "space-between", alignItems: "center"}}
      >
        <Line width='42%'></Line>
        <Text>Your <HighLight>Biddings</HighLight></Text>
        <Line width='42%'></Line>
      </FlexCol>

      <Container>
        <Pagination data={topbid} header={Bheader} showBid='Edit Bid'/>
      </Container>
      <br/>
      <br/>
      </span>
      }
    </div>
  )
}

const Text = styled.h1`
  color: ${COLORS.blue};
  flex-shrink: 0;
  // margin: 0px 10px;
  @media only screen and (max-width: 780px) {
    font-size: 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  padding: 0px 150px;
  justify-content: ${props => props.align};
  @media only screen and (max-width: 780px) {
    padding: 0px 25px;
  }
`;

const HighLight = styled.span`
    color: ${COLORS.yellow};
    text-decoration: underline;
    text-decoration-color: ${COLORS.blue};
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.height};
    width: 100%;
    box-sizing: border-box;
    gap: 10px;

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => (props.stop ? 'row' : 'column')};
    }
`;

const Line = styled.span`
  background: ${COLORS.blue};
  height: 2px;
  // width: ${props => props.width};
  flex-grow: 1;
`;

const Container = styled.div`
    padding: 0px 50px;
    @media only screen and (max-width: 780px) {
      padding: 0px 20px;
    }
`;

export default HomePageAgent
