import React, { useContext } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import GenieScore from './GenieScore'
import { AuthContext } from '../../context/AuthContext'

const AnonymousBidders = ({data, bgColor}) => {
  const { userDetails} = useContext(AuthContext);

  return (
    <Container bgColor={bgColor}>
      <IconContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" fill={COLORS.blue_faded} class="bi bi-incognito" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="m4.736 1.968-.892 3.269-.014.058C2.113 5.568 1 6.006 1 6.5 1 7.328 4.134 8 8 8s7-.672 7-1.5c0-.494-1.113-.932-2.83-1.205a1.032 1.032 0 0 0-.014-.058l-.892-3.27c-.146-.533-.698-.849-1.239-.734C9.411 1.363 8.62 1.5 8 1.5c-.62 0-1.411-.136-2.025-.267-.541-.115-1.093.2-1.239.735Zm.015 3.867a.25.25 0 0 1 .274-.224c.9.092 1.91.143 2.975.143a29.58 29.58 0 0 0 2.975-.143.25.25 0 0 1 .05.498c-.918.093-1.944.145-3.025.145s-2.107-.052-3.025-.145a.25.25 0 0 1-.224-.274ZM3.5 10h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Zm-1.5.5c0-.175.03-.344.085-.5H2a.5.5 0 0 1 0-1h3.5a1.5 1.5 0 0 1 1.488 1.312 3.5 3.5 0 0 1 2.024 0A1.5 1.5 0 0 1 10.5 9H14a.5.5 0 0 1 0 1h-.085c.055.156.085.325.085.5v1a2.5 2.5 0 0 1-5 0v-.14l-.21-.07a2.5 2.5 0 0 0-1.58 0l-.21.07v.14a2.5 2.5 0 0 1-5 0v-1Zm8.5-.5h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Z"/>
            </svg>
        </IconContainer>
        <Text size='20px'>{userDetails.uid === data.id ? 'You' : data.Name}</Text>
        {bgColor === "white" ? (
            <GenieScore percent={data['Genie Score']} />
        ) : (
            <Text size='20px' weight='bold'>Winner</Text>
        )}
        <Text size='14px'>Bid: {data.Bid}</Text>
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.bgColor};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: ${props => (props.height ? props.height : '300px')};
    width: ${props => (props.width ? props.width : '280px')};
    position: relative;
    display: flex;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    transition: transform .2s;
    cursor: pointer;
    margin: 5px 0px;
    flex-direction: column;

    &:hover {
        transform: scale(1.05);
    }

    @media only screen and (max-width: 780px) {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }
`

const IconContainer = styled.div`
    border: 2px solid ${COLORS.blue_faded};
    height: 86px;
    width: 86px;
    padding: 5px;
    border-radius: 50%;
    margin-bottom: 10px;

    @media only screen and (max-width: 780px) {
        display: none;

        svg {
            display: none;
        }
    }
`;

const Text = styled.div`
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    color: ${COLORS.blue};
    margin: 5px 0px;
`;

export default AnonymousBidders
