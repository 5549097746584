export const COLORS = {
    blue: "#121851",
    yellow: "#EAC753",
    white: "#FFFFFF",
    red: "#DC1B1B",
    green: "#1BDC51",
    light_blue: "#0AAFF3",
    gray: "#D9D9D9",
    blue_faded: "rgba(18,24,81,0.8)",
    black_faded: "rgba(0,0,0,0.4)"
};

export const UserTypes = {
    "Superadmin": 0,
    "Admin": 1,
    "Manager": 2,
    "Agent": 3,
    "Seller": 3
};

export const ListingStatus = {
    "Bidding Ongoing": 0,
    "List Ended": 1,
    "Deal Ongoing": 2,
    "Property Sold": 3,
};

export const DefaultProPic = 'https://firebasestorage.googleapis.com/v0/b/theagentgenie-eecd2.appspot.com/o/profile_pictures%2F58512eb4e598b5ea4e2414e3c115bef9.jpg?alt=media&token=a1caff10-648f-44f8-8185-1dbb3f123d23';
