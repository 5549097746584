import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import { countAgentListings } from '../../backend-functions/Listings';
import Loading from '../Modal/Loading';

const AgentNumbers = ({id}) => {
  const [ data, setData ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try{
        const count = await countAgentListings(id);
        setData(count);
        
      } catch(error){
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
    {data ? <Container>
      <Cont height="15%" bgColor={COLORS.blue}></Cont>
      <Cont height="85%">
        <Flex>
            {Object.keys(data).map((key) => (
                <Flex>
                    <FlexCont>
                        <SmallText>{key}</SmallText>
                    </FlexCont>
                </Flex>
            ))}
        </Flex>
        <Flex>
            {Object.keys(data).map((key) => (
                <Flex>
                    <FlexCont>
                        <BigText>{data[key]}</BigText>
                    </FlexCont>
                </Flex>
            ))}
        </Flex>
      </Cont>
    </Container>
    :
    <Container style={{position: 'relative'}}>
      <Loading/>
    </Container>
    }
    </>
  )
}

const Container = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;

    // @media only screen and (max-width: 780px) {
    //     width: 80%;
    // }
`;

const Cont = styled.div`
    height: ${props => props.height};
    background: ${props => props.bgColor};
    width: 100%;
    position:
`;

const Flex = styled.div`
    display: flex;
    height: ${props => props.height};
    width: 100%;
`;

const FlexCont = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
`;

const SmallText = styled.p`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-align: center;
`;

const BigText = styled.p`
    font-size: 50px;
    font-weight: bold;
    color: ${COLORS.red};
    margin: 0;
`;

export default AgentNumbers
