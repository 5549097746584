import React from 'react'
import { COLORS } from '../components/variables'
import styled from 'styled-components'
import Navbar from '../components/Navbar/Navbar'
import Background from '../components/Background/Background'

const LandingPageAgent = () => {
  const utype = 'Agent';
  return (
    <div>
        <Container>
          <Navbar type='login' uType={utype}></Navbar>
          <Background uType={utype}></Background>
        </Container>
          <h3 style={{ paddingLeft: '50px', margin: '0', marginTop: '15px', marginBottom: '5px', color: COLORS.black_faded }}>
            Win Listings with ease...
          </h3>
          <FlexCol>
            <FlexBox width='15%'>
              <Row>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={COLORS.black_faded} class="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
              </Row>
              <Row >
                <Text>
                  Browse Listings
                </Text>
              </Row>
            </FlexBox>

            <FlexBox width='15%'>
              <Row>
                <Line></Line>
              </Row>
            </FlexBox>

            <FlexBox width='15%'>
              <Row>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={COLORS.black_faded} class="bi bi-cash" viewBox="0 0 16 16">
                  <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                  <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z"/>
                </svg>
              </Row>
              <Row >
                <Text>
                  Bid Commission
                </Text>
              </Row>
            </FlexBox>

            <FlexBox width='15%'>
              <Row>
                <Line></Line>
              </Row>
            </FlexBox>

            <FlexBox width='15%'>
              <Row>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={COLORS.black_faded} class="bi bi-trophy-fill" viewBox="0 0 16 16">
                  <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z"/>
                </svg>
              </Row>
              <Row >
                <Text>
                  Win Bidding
                </Text>
              </Row>
            </FlexBox>

            <FlexBox width='15%'>
              <Row>
                <Line></Line>
              </Row>
            </FlexBox>

            <FlexBox width='15%'>
              <Row>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={COLORS.black_faded} class="bi bi-house-check-fill" viewBox="0 0 16 16">
                  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
                  <path d="m8 3.293 4.712 4.712A4.5 4.5 0 0 0 8.758 15H3.5A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.707l.547.547 1.17-1.951a.5.5 0 1 1 .858.514Z"/>
                </svg>
              </Row>
              <Row >
                <Text>
                  Sell Property
                </Text>
              </Row>
            </FlexBox>
          </FlexCol>
    </div>
  )
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    @media only screen and (max-width: 780px) {
      // flex-direction: column;
      height: auto;
      padding: 5px;
      box-sizing: border-box;
    }
`;  

const FlexBox = styled.div`
    background: ${props => props.color};
    width: ${props => props.width};
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 780px) {
      width: 10%;
    }
`;

const Row = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  color: ${ COLORS.black_faded };
  text-align: center;

  @media only screen and (max-width: 780px) {
    font-size: 10px;
    text-align: center;
  }
`;

const Line = styled.div`
  height: 3px;
  width: 100%;
  background: ${ COLORS.black_faded };
  border-radius: 10px;
  @media only screen and (max-width: 780px) {
    // display: none;
  }
`;

export default LandingPageAgent
