import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'

const PrivacyPolicy = () => {
  return (
    <Page>
      <Container>
      <p><span style={{fontSize:'32px'}}><b>Privacy Policy</b></span></p>
<p><span style={{fontSize:'24px'}}>Last updated - 1st February 2024</span></p>
<p></p>
<p><span style={{fontSize:'24px'}}>This privacy policy applies between you, the User of this Website and Social Impact Marketing Ltd, the owner and provider of this Website. Social Impact Marketing Ltd takes the privacy of your information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website.</span></p>
<p></p>
<p><b>Definitions and interpretation</b></p>
<p><span style={{fontSize:'16px'}}>In this privacy policy, the following definitions are used:</span></p>
<p></p>
<p><b>Data</b></p>
<p><span style={{fontSize:'16px'}}>Collectively all information that you submit to The Agent Genie via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Act 1998 and General Data Protection Regulation (EU) 2016;</span></p>
<p></p>
<p><b>Cookies</b></p>
<p><span style={{fontSize:'16px'}}>a small text file placed on your computer by this Website when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in the clause below ("Cookies");</span></p>
<p></p>
<p><b>The Agent Genie</b></p>
<p><span style={{fontSize:'16px'}}>"we" or "us" or "The Agent Genie", Social Impact Marketing Ltd, a company incorporated in England and Wales with registered number 12816194 whose registered office is at 19 St. Brides Road, Wick, Cowbridge, Wales, CF71 7QB;</span></p>
<p></p>
<p><b>UK and EU Cookie Law</b></p>
<p><span style={{fontSize:'16px'}}>the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011;</span></p>
<p></p>
<p><b>GDPR</b></p>
<p><span style={{fontSize:'16px'}}>the General Data Protection Regulation (EU) 2016 which governs our use of personal Data;</span></p>
<p></p>
<p><b>DPA</b></p>
<p><span style={{fontSize:'16px'}}>the Data Protection Act 1998;</span></p>
<p></p>
<p><b>User or you</b></p>
<p><span style={{fontSize:'16px'}}>any third party that accesses the Website and is not either (i) employed by The Agent Genie and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to The Agent Genie and accessing the Website in connection with the provision of such services; and</span></p>
<p><b>Website</b></p>
<p><span style={{fontSize:'16px'}}>the website that you are currently using, ww​w.theagentgenie.com, and any sub-domains of this site unless expressly excluded by their own terms and conditions.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>In this privacy policy, unless the context requires a different interpretation:</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>the singular includes the plural and vice versa;</span></p>
<p><span style={{fontSize:'16px'}}>references to sub-clauses, clauses, schedules or appendices are to</span></p>
<p><span style={{fontSize:'16px'}}>sub-clauses, clauses, schedules or appendices of this privacy policy;</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>a reference to a person includes firms, companies, government</span></p>
<p><span style={{fontSize:'16px'}}>entities, trusts and partnerships;</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>"including" is understood to mean "including without limitation";</span></p>
<p><span style={{fontSize:'16px'}}>reference to any statutory provision includes any modification or</span></p>
<p><span style={{fontSize:'16px'}}>amendment of it;</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>the headings and subheadings do not form part of this Privacy Policy.</span></p>
<p><span style={{fontSize:'16px'}}>Scope of this Privacy Policy</span></p>
<p><span style={{fontSize:'16px'}}>This privacy policy applies only to the actions of The Agent Genie and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.</span></p>
<p></p>
<p><b>Data collected</b></p>
<p><span style={{fontSize:'16px'}}>We may collect the following Data, which includes personal Data, from you:</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Name</span></p>
<p><span style={{fontSize:'16px'}}>Contact Information such as email addresses and telephone numbers;</span></p>
<p><span style={{fontSize:'16px'}}>Property Address</span></p>
<p><span style={{fontSize:'16px'}}>IP address (automatically collected);</span></p>
<p><span style={{fontSize:'16px'}}>Web browser type and version (automatically collected);</span></p>
<p><span style={{fontSize:'16px'}}>Operating system (automatically collected);</span></p>
<p><span style={{fontSize:'16px'}}>in each case, in accordance with this privacy policy.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Our use of Data</span></p>
<p><span style={{fontSize:'16px'}}>For purposes of the DPA and the GDPR, The Agent Genie is the "Data controller".</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie's lawful basis for processing Data on homeowners is based on Article 6(1)(f) of the GDPR (Legitimate interest). A Legitimate Interests Assessment has been carried out.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie's lawful basis for processing Data on estate agents is based on Article 6(1)(b) of the GDPR (Contract).</span></p>      
<p><span style={{fontSize:'16px'}}>The Agent Genie will retain any Data you submit for 36 months, although you have the right to request deletion before that date. For more details on your rights, see the clause below (Your rights).</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Unless The Agent Genie is obliged or permitted by law to do so, and subject to any third party disclosures specifically set out in this policy, your Data will not be disclosed to third parties.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>All personal Data is stored securely in accordance with the principles of the DPA and the GDPR. For more details on security see the clause below (Security).</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Any or all of the above Data may be required by The Agent Genie from time to time in order to provide you with the best possible service and experience when using our Website. Specifically, Data may be used by us The Agent Genie for the following reasons:</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>internal record keeping;</span></p>
<p><span style={{fontSize:'16px'}}>improvement of our products / services;</span></p>
<p><span style={{fontSize:'16px'}}>in each case, in accordance with this privacy policy.</span></p>
<p></p>
<p><b>Sharing your data with third parties</b></p>
<p><span style={{fontSize:'16px'}}>Homeowner data is shared with an estate agent when the homeowner requests a valuation from that estate agent, either on the The Agent Genie website or when speaking to a member of the The Agent Genie customer service team. The data shared with the estate agent comprises the homeseller's name, address and contact details.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>If the homeseller does not request a valuation, no homeseller personal data will be shared with the estate agent.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie works with several ancillary providers. The homeseller's personal information will only be shared with an ancillary provider if the homeseller specifically requests more information about the ancillary service, either online or on the phone with the The Agent Genie customer service team.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>If a homeseller subsequently asks The Agent Genie to remove their personal data, any third party who has received the homeseller's data (as detailed above) may retain the homeseller's data.</span></p>
<p></p>
<p><b>Third party websites and services</b></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie may, from time to time, employ the services of other parties for dealing with certain processes necessary for the operation of the Website. The providers of such services have access to certain personal Data provided by Users of this Website.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Any Data used by such parties is used only to the extent required by them to perform the services that we request. Any use for other purposes is strictly prohibited. Furthermore, any Data that is processed by third parties will be processed within the terms of this privacy policy and in accordance with the DPA and the GDPR.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Some third party providers may be incorporated outside of the EU and/or may process Data outside of the EU. In these cases, The Agent Genie will ensure that any Data passed to the provider is processed in accordance with the DPA and the GDPR. Generally this will be in the form of a Data Processing Agreement with the third party.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Third-party providers The Agent Genie currently uses:</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Active Campaign</span></p>
<p><span style={{fontSize:'16px'}}>JustCall</span></p>
<p><span style={{fontSize:'16px'}}>Amazon Web Services</span></p>
<p><span style={{fontSize:'16px'}}>Google</span></p>
<p><span style={{fontSize:'16px'}}>Facebook</span></p>
<p></p>
<p><b>Links to other websites</b></p>
<p><span style={{fontSize:'16px'}}>This Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites. This privacy policy does not extend to your use of such websites. You are advised to read the privacy policy or statement of other websites prior to using them.</span></p>
<p></p>
<p><b>Changes of business ownership and control</b></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of The Agent Genie. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this privacy policy, be permitted to use the Data for the purposes for which it was originally supplied to us.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>We may also disclose Data to a prospective purchaser of our business or any part of it.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>In the above instances, we will take steps with the aim of ensuring your privacy is protected.</span></p>
<p></p>
<p><b>Controlling use of your Data</b></p>
<p><span style={{fontSize:'16px'}}>Wherever you are required to submit Data, you will be given options to restrict our use of that Data. This may include the following:</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>use of Data for direct marketing purposes; and</span></p>
<p><span style={{fontSize:'16px'}}>sharing Data with third parties.</span></p>
<p><span style={{fontSize:'16px'}}>Functionality of the Website</span></p>
<p><span style={{fontSize:'16px'}}>To use all features and functions available on the Website, you may be required to submit certain Data.</span></p>
<p><span style={{fontSize:'16px'}}>You may restrict your internet browser's use of Cookies. For more information see the clause below (Cookies).</span></p>
<p></p>
<p><b>Your Rights</b></p>
<p><span style={{fontSize:'16px'}}>You have certain rights under the GDPR:</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Right to be informed - this Privacy Policy outlines</span></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie's purposes for processing your personal Data, The Agent Genie's retention periods for that personal Data, and who it will be shared with.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Right of access - you can request a copy of your</span></p>
<p><span style={{fontSize:'16px'}}>personal Data that The Agent Genie holds. Please email your request to support@theagentgenie.com</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Right to rectification - if any of your personal Data</span></p>
<p><span style={{fontSize:'16px'}}>that The Agent Genie holds is incorrect, you can ask The Agent Genie to correct the data. Please email your request to support@theagentgenie.com</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Right to erasure - you can request The Agent Genie delete</span></p>
<p><span style={{fontSize:'16px'}}>your personal Data. This request will not be unreasonably refused. Please email your request to support@theagentgenie.com</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Right to restrict processing - you can request that</span></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie pauses processing your personal Data. Your Data will be archived until you choose to permit The Agent Genie to resume processing. Please email your request to support@theagentgenie.com</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Right to object - you can object to The Agent Genie</span></p>
<p><span style={{fontSize:'16px'}}>processing your Data (please also see Right to Erasure, above). Please email your request to support@theagentgenie.com.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Rights that do not apply:</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Right to Data portability - this right does not apply</span></p>
<p><span style={{fontSize:'16px'}}>to homeowners as it only applies in cases "where the processing is based on the individual&rsquo;s consent or for the performance of a contract". The Agent Genie's lawful basis for processing homeowner personal Data is based on Legitimate Interest. However the Right of Access (see above) still applies and may be relevant.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Rights related to automated decision making including profiling - The Agent Genie does not make automated decisions or profile using</span></p>
<p><span style={{fontSize:'16px'}}>personal Data that have legal or similarly significant effects.</span></p>
<p></p>
<p><b>Security</b></p>
<p><span style={{fontSize:'16px'}}>Data security is of great importance to The Agent Genie and to protect your Data we have put in place suitable physical, electronic and managerial procedures to safeguard and secure Data collected via this Website. Your personal Data is encrypted in transit (via TLS) and encrypted at rest within The Agent Genie's database systems.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>If password access is required for certain parts of the Website, you are responsible for keeping this password confidential.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>We endeavor to do our best to protect your personal Data. However, transmission of information over the internet is not entirely secure and is done at your own risk. We cannot ensure the security of your Data transmitted to the Website.</span></p>
<p></p>
<p><b>Cookies</b></p>
<p><span style={{fontSize:'16px'}}>This Website may place and access certain Cookies on your computer. The Agent Genie uses Cookies to improve your experience of using the Website and to improve our range of services. The Agent Genie has carefully chosen these Cookies and has taken steps to ensure that your privacy is protected and respected at all times.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>You can choose to delete Cookies at any time; however you may lose any information that enables you to access the Website more quickly and efficiently including, but not limited to, personalisation settings.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy settings.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Transfers outside the European Economic Area</span></p>
<p><span style={{fontSize:'16px'}}>Data which we collect from you may be stored and processed in and transferred to countries outside of the European Economic Area (EEA). For example, this could occur if our servers are located in a country outside the EEA or one of our service providers is situated in a country outside the EEA. These countries may not have Data protection laws equivalent to those in force in the EEA.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>If we transfer Data outside the EEA in this way, we will take steps with the aim of ensuring that your privacy rights continue to be protected as outlined in this privacy policy. You expressly agree to such transfers of Data.</span></p>
<p></p>
<p><b>General</b></p>
<p><span style={{fontSize:'16px'}}>You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights under this privacy policy where we reasonably believe your rights will not be affected.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>If any court or competent authority finds that any provision of this privacy policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this privacy policy will not be affected.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.</span></p>
<p></p>
<p><b>Changes to this privacy policy</b></p>
<p><span style={{fontSize:'16px'}}>The Agent Genie reserves the right to change this privacy policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the privacy policy on your first use of the Website following the alterations.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>You may contact The Agent Genie by email at support@theagentgenie.com.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Attribution</span></p>
<p><span style={{fontSize:'16px'}}>These terms and conditions were based on a document from Rocket Lawyer (subsequently amended).</span></p>
<p></p>
<p><b>Minors</b></p>
<p></p>
<p><span style={{fontSize:'16px'}}>We do not sell the personal data of consumers we know to be less than 16 years of age, unless we receive affirmative authorization (the &ldquo;Right to Opt In&rdquo;) from either the minor who is between 13 and 16 years of age, or the parent or guardian of a minor less than 13 years of age.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>If you are under the age of 18 and you want to remove your name or comments from our website or publicly displayed content, please contact us directly at support@theagentgenie.com. We may not be able to modify or delete your information in all circumstances.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>If you wish to submit a privacy request on behalf of your minor child in accordance with applicable jurisdictional laws, you must provide sufficient information to allow us to reasonably verify your child is the person about whom we collected personal information and you are authorized to submit the request on your child&rsquo;s behalf (i.e., you are the child&rsquo;s legal guardian or authorized representative).</span></p>
<p><b>California-Specific Disclosures</b></p>
<p></p>
<p><span style={{fontSize:'16px'}}>The following disclosures only apply to residents of the State of California.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>California Categories of Personal Data</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>California law requires we provide disclosures to you about what personal data we collect by reference to the enumerated categories of personal data set forth within California law. To address this obligation, we have identified the relevant enumerated California personal data category for the personal data, sources and purposes described in the &ldquo;Our Collection and Use of Personal Data&rdquo; section of our Privacy Notice below:&nbsp;</span></p>
<p></p>
<p><b>Identifiers</b><span style={{fontSize:'16px'}}>, including Account Registration and Profile Information, information related to your feedback, inquiries, and communications, credit and identity information relating to data used to identify you, full name, IP address, and government identification number (including Social Security number, passport number, and driver&rsquo;s license number).</span></p>
<p><b>Customer Records</b><span style={{fontSize:'16px'}}>, including Account Registration and Profile Information, Security/Authentication Information, and Payment Information.</span></p>
<p><b>Protected Classification Characteristics</b><span style={{fontSize:'16px'}}>, including age, date of birth, gender, and sex.&nbsp;</span></p>
<p><b>Commercial Information</b><span style={{fontSize:'16px'}}>, including Account Registration and Profile Information relating to your purchases, Profile Information relating to your transactions, preferences, and interests, and Feedback Information.</span></p>
<p><b>Internet/Network Information</b><span style={{fontSize:'16px'}}>, including information about how you access and use the services, Log Data, and Analytics Data.</span></p>
<p><b>Geolocation Data</b><span style={{fontSize:'16px'}}>, including general geographic location or more precise location with your consent or when permitted by law.</span></p>
<p><b>Sensory Information</b><span style={{fontSize:'16px'}}>, including, where permitted by law, recordings of phone calls between us and individuals, and image and video recordings of visitors to our offices or events.</span></p>
<p><b>Profession/Employment Information</b><span style={{fontSize:'16px'}}>, including the business or organization you represent, your title with that business or organization, and information relating to your role with the business or organization.</span></p>
<p><b>Sensitive Information</b><span style={{fontSize:'16px'}}>, including Social Security number, driver&rsquo;s license number, passport number, credit/debit card number plus expiration date and security code (CVV), financial account number and routing number, username and password, and precise geolocation.</span></p>
<p><b>Other Personal Data</b><span style={{fontSize:'16px'}}>, including any information you provide us in connection with signing up for newsletters, email communications, and surveys, personal data you permit us to see when interacting with us through social media, and personal data you provide us in relation to a question, request, inquiry, survey, contest, or promotion.</span></p>
<p><b>Inferences</b><span style={{fontSize:'16px'}}>, including our predictions about interests and preferences and related Service Profile Information.</span></p>
<p><span style={{fontSize:'16px'}}>We collect this data from the following sources: directly from you, from our business partners and affiliates, from your browser or device when you visit our mobile app(s) or use our Services, or from third parties that you permit to share information with us. Please see &ldquo;Our Collection and Use of Personal Data&rdquo; section of our Privacy Notice for more information about the sources of personal data we collect. We disclose all of these categories of personal data for a business purpose to service providers or other third parties at the consumer&rsquo;s direction, as outlined in the &ldquo;Our Disclosure of Personal Data&rdquo; section of our Privacy Notice.</span></p>
<p></p>
<p><i><span style={{fontSize:'16px'}}>Retention of Personal Data&nbsp;</span></i></p>
<p></p>
<p><span style={{fontSize:'16px'}}>We strive to retain your personal data only for as long as is reasonably necessary to fulfill the purpose for which it was collected. However, if necessary, we may retain your personal data for longer periods of time, until set retention periods and deadlines expire, for instance where we are required to do so in accordance with legal, tax and/or accounting requirements set by a legislature, regulator, or other government authority.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>To determine the appropriate duration of the retention of personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of personal data and if we can attain our objectives by other means, as well as our legal, regulatory, tax, accounting, and other applicable obligations.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Therefore, we retain personal data for as long as you use our services for the purposes explained in our Privacy Notice, including maintaining a user account. When you discontinue the use of our services, we will retain your personal data for as long as necessary to comply with our legal obligations, to resolve disputes and defend claims, as well as for any additional purpose based on the choices you have made, such as to receive marketing communications.</span></p>
<p></p>
<p><span style={{fontSize:'16px'}}>Once retention of your personal data is no longer necessary for the purposes outlined above, we will either delete or de-identify the personal data or, if this is not possible (for example, because personal data has been stored in backup archives), then we will securely store your personal data and isolate it from further processing until deletion or deidentification is possible.</span></p>
<p></p>
<p><i><span style={{fontSize:'16px'}}>&ldquo;Shine the Light&rdquo; Disclosures</span></i></p>
<p></p>
<p><span style={{fontSize:'16px'}}>The California &ldquo;Shine the Light&rdquo; law gives residents of California the right under certain circumstances to request information from us regarding the manner in which we share certain categories of personal information (as defined in the Shine the Light law) with third parties for their direct marketing purposes. To opt out of this type of sharing, please email us at support@theagentgenie.com.</span></p>      
      </Container>
    </Page>
  )
}

const Page = styled.div`
    background: ${COLORS.blue};
    padding: 5% 15%;
`;

const Container = styled.div`
    background: ${COLORS.white};
    border: 2px solid ${COLORS.gray};
    padding: 20px;
    border-radius: 5px;
`;

export default PrivacyPolicy
