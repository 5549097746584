import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables';

const InputField = ({width, setValue, value, ...props}) => {
  return (
      <InputContainer width={width}>
        {props.label}
        <Input onChange={(e) => setValue(e.target.value)}  value={value} {...props} />
      </InputContainer>
  )
}

const InputContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  // margin: 5px 3px;
  // background: yellow;
  width: 100%;
  text-align: left;
`;

const Input = styled.input`
  border: ${props => props.borderless ? '1px solid transparent' : `1px solid ${COLORS.blue_faded}`};
  border-radius: ${props => props.borderless ? '0' : '4px' };
  padding: 5px;
  width: 100%;
  box-sizing:border-box;
  text-align: left;
  color: ${COLORS.blue_faded}
`;

export default InputField
