import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import BigButton from '../Buttons/BigButton';
import Error from './Error';
import { addBidding } from '../../backend-functions/Biddings';
import { AuthContext } from '../../context/AuthContext';
import Modal from '../Modal/Modal';
import { emailVerification, logout } from '../../backend-functions/Auth';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../backend-functions/Users';

const AddBidding = ({agentId, listingId, sellerId, postcode, width}) => {
  const { userDetails, authUser, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const [bid, setBid] = useState(0);
  const [error, setError] = useState('');
  const [isUnverified, setIsUnverified] = useState(false);
  const [ user, setUser ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getUser(agentId);
        setUser(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [agentId]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    // make sure the bid is between 0.1 - 100
    if(bid > 100 || bid <= 0){
      alert('Bid must be in the range of 0.1 - 100');
      return;
  }

    if(!authUser.emailVerified){
      setIsUnverified(true);
      return;
    }

    if(userDetails.application_status !== "Approved"){
      setError('Your information still needs to be approved by the Support Team. Please wait till we approve this so you can submit a bid. We will let you know.');
      return;
    }

    if(user.isDelete){
      setError('Your account is currently restricted. Contact an admin for any inquiries.');
      return;
    }

    setError('Please Wait...');
    await addBidding(listingId, agentId, bid, sellerId, postcode).then(() => {
        window.location.href = '?bid=successful';
    });
  }

  const handleEmailVerification = async (e) => {
    const response = await emailVerification();

    if(response){
      // refresh the page
      window.location.reload();
    }
  }

  return (
    <FlexCol width={width}>
      {/* MODAL FOR UNVERIFIED EMAIL */}
      <Modal isOpen={isUnverified}>
            <h2>Your email address is not yet verified</h2>
            <p>Please verify your email address before bidding</p>
            <br/><br/>
            <BigButton bgColor={COLORS.blue} color={COLORS.white} text='Verify Account' onClick={handleEmailVerification} />
      </Modal>

      <BlurText>Want to sell this property?</BlurText>
      {user && <Form onSubmit={handleSubmit}>
        <Error error={error} />
        <SpaceBetween>
          <label>Input Bid %:</label>
          <Input type='number' value={bid} onChange={(e)=>setBid(e.target.value)} />
          <BigButton
            bgColor={COLORS.blue}
            color={COLORS.white}
            text="Confirm"
            type="submit"
          />
        </SpaceBetween>
      </Form>}
    </FlexCol>
  );
}

const FlexCol = styled.div`
 width: ${props => props.width};
`;

const Form = styled.form`
  width: 30%;
  margin: 20px 0px;

  @media only screen and (max-width: 780px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

const Input = styled.input`
  border: 1px solid ${COLORS.blue_faded};
  border-radius: 4px;
  padding: 10px;
  width: 25%;
  box-sizing:border-box;
  color: ${COLORS.blue_faded}
`;

const BlurText = styled.p`
  color: ${COLORS.blue_faded};
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export default AddBidding
