import React from 'react'
import styled from 'styled-components'
import BigButton from '../Buttons/BigButton'
import { COLORS } from '../variables';

const Title = styled.div`
    color: white;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 3%;

    @media only screen and (max-width: 860px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      position: absolute;
      top: 50px;
      left: 0px;
      display: ${props => (props.isShown ? "flex" : "none")};
      background: ${props => (props.color == COLORS.blue ? COLORS.white : COLORS.blue)};
    }
`;

const Link = styled.a`
    color: ${props => (props.color ? props.color : COLORS.white)};
    text-decoration: none;
    padding-right: 50px;

    @media only screen and (max-width: 860px) {
      padding: 10px 20px
    }
`;

const LoginLinks = (props) => {
  const btnText = (props.uType == "Agent") ? "Become a Seller" : "Become an Agent";
  const btnLink = (props.uType == "Agent") ? "/become-a-seller" : "/become-an-agent";

  return (
    <Title isShown = {props.isShown} color={props.color}>
        <Link href='/login' color={props.color}> Login </Link>
      <Link href={btnLink}><BigButton bgColor={ COLORS.blue } color={ COLORS.white } text={ btnText } type='small'></BigButton></Link>
    </Title>
  )
}

export default LoginLinks
