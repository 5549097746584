import React, { useState } from 'react'
import styled from 'styled-components'
import { COLORS, UserTypes } from '../../components/variables'
import Sidebar from '../../components/Navbar/Sidebar'
import Error from '../../components/Forms/Error'
import InputField from '../../components/Forms/InputField'
import BigButton from '../../components/Buttons/BigButton'
import { register } from '../../backend-functions/Auth'
import { useNavigate } from 'react-router-dom'
import DropdownField from '../../components/Forms/DropdownField'
import { cleanUKPostCode } from '../../backend-functions/custom-functions'

const AddUser = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostCode] = useState("");
  const [fullpostcode, setfullPostCode] = useState("");
  const [country, setCountry] = useState("UK");
 
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const [usertype, setUserType] = useState("");
  const [typehierarchy, setTypeHierarchy] = useState("")

  const filterOptions = Object.entries(UserTypes).filter(([label, value]) => value < 3).map(([label, value]) => ({
    label,
    value: value.toString()
  }));
  
  const countryOptions = [
    { value: "UK", label: "United Kingdom" },
    { value: "US", label: "United States" },
  ];

  const navigate = useNavigate();
  const userObj = {
    fname: fname,
    lname: lname,
    email: email,
    phone: phone,
    street: street,
    city: city,
    fullpostcode: fullpostcode.toUpperCase(),
    postcode: postcode.toUpperCase(),
    usertype: usertype,
    username: usertype,
    typehierarchy: typehierarchy,
    password: "asdasd",
    confirmpassword: "asdasd",
    accept: true,
    country: country,
  }

  const setSelectedOption = (selectedValue, selectedLabel) => {
    setUserType(selectedLabel);
    setTypeHierarchy(selectedValue);
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading("Please wait...");
    setError("");

    const user = await register(userObj, setError, setLoading);

    if (user) {
      navigate("/users?success=User Added Successfully");
    }
  }

  return (
    <Page>
      <Sidebar userAccounts={true} />
      <Content>
      <h2>Add User</h2>   
      <Form onSubmit={handleRegister}>
        <Loading>{loading}</Loading>
        <Error error={error} />
        
        <FlexCol>
            <InputField required type="text" label="First Name" value={fname} onChange={(e)=>setFname(e.target.value)} placeholder="First Name" width="49%"/>
            <InputField required type="text" label="Last Name" value={lname} onChange={(e)=>setLname(e.target.value)} placeholder="Last Name" width="49%"/>
        </FlexCol>
        <FlexCol>
            <InputField required type="email" label="Email Address" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email Address" width="49%"/>
            <InputField required type="tel" label="Phone Number" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder="Phone Number" width="49%"/>
        </FlexCol>
        <FlexCol stop="yes">
          <DropdownField options={countryOptions} setSelectedOption={setCountry} label="Country" />
        </FlexCol>
        <FlexCol>
            <InputField required type="text" label="Street" value={street} onChange={(e)=>setStreet(e.target.value)} placeholder="Street" width="32%"/>
            <InputField required type="text" label="City" value={city} onChange={(e)=>setCity(e.target.value)} placeholder="City" width="32%"/>
            <InputField required type="text" label={country === 'UK' ? 'Post Code' : 'Zip Code'} value={fullpostcode} onChange={(e)=>{setPostCode(cleanUKPostCode(e.target.value, country)); setfullPostCode(e.target.value)}} placeholder={country === 'UK' ? 'Post Code' : 'Zip Code'} width="32%"/>
        </FlexCol>
        <FlexCol style={{flexDirection: 'column'}}>
            <p>User Type:</p>
            <DropdownField options={filterOptions} setSelectedOption={setSelectedOption}/>
        </FlexCol>
        <br/>
        <FlexCol style={{ justifyContent: "center" }}>
            <BigButton
            text="&nbsp; Register &nbsp;"
            color={COLORS.white}
            bgColor={COLORS.blue}
            type="submit"
            ></BigButton>
        </FlexCol>
        </Form>
      </Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`;

const Content = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

const Form = styled.form`
  width: 70%;

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.height};
    width: 100%;
    box-sizing: border-box;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
    gap: 5px;
    // justify-content: space-between;

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => (props.stop ? 'row' : 'column')};
        margin-top: ${props => (props.marginTop ? '0' : props.marginTop)};
    }
`;

const Loading = styled.p`
  font-style: italic;
  color: ${COLORS.blue};
`;

export default AddUser
