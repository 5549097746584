import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { auth, db } from "../firebase"
import { functions } from "firebase/functions";
import { stripe } from "../stripe";
import { getUser } from "./Users";

export const savePaymentMethod = async (paymentMethod) => {
    try {
        const uid = auth.currentUser.uid

        if(uid){
            await updateDoc(doc(collection(db, "users"), uid), {
                payment_method: paymentMethod
            });

            const userRef = doc(db, "users", uid);
            const paymentMethodRef = collection(userRef, "paymentMethods");

            // add payment method
            await addDoc(paymentMethodRef, {
              paymentMethod
            })
        }
    } catch (error) {
        console.log(error)
    }
} 

export const attachPaymentMethod = async (paymentID, customerID) => {
  try {
    await stripe.paymentMethods.attach(paymentID, {
      customer: customerID,
    });
  } catch (error) {
    console.error('Error attaching PaymentMethod:', error);
  }  
}

export const deletePaymentMethod = async (methodID) => {
  try {
    await stripe.paymentMethods.detach(methodID);
  } catch (error) {
    // Handle error
    console.error('Error deleting payment method:', error);
    throw error; // Re-throw the error to be caught by the calling function
  }
};

export const processPayment = async (data, listingID, country) => {
    try {
      const paymentMethodId = data.paymentMethodId;
      const amount = data.amount; // The amount to be charged
      const customerId = data.id;
      const customerEmail = data.email;
  
      // Create a PaymentIntent with the currency
      const paymentIntent = await stripe.paymentIntents.create({
        amount: amount,
        currency: country === 'US' ? 'usd' : 'gbp', // Specify the currency code
        payment_method: paymentMethodId,
        confirm: true,
        customer: customerId,
        description: 'TheAgentGenie: Listing #' + listingID,
        receipt_email: customerEmail,
        automatic_payment_methods: {
          enabled: true, // Enable automatic payment methods
          allow_redirects: 'never', // Set to 'never' to disable full page redirects
        },
      });
  
      // Return the Payment Intent status
      return paymentIntent;
    } catch (error) {
      // Handle payment failure
      console.error(error);
      return { status: 'failed', error: error.message };
    }
  };

  export const createPrice = async (amount, dealId) => {
    try {
      const price = await stripe.prices.create({
        unit_amount: amount * 100, // Amount in cents (e.g., $10.00)
        currency: 'gbp',   // Currency code (e.g., 'gbp' for British Pounds)
        product_data: {
          name: `TAG: Deal Listing #${dealId}`,
        },
      });

      return price;
    } catch (error) {
      console.error('Error creating Price:', error);
    }
  }

  export const getInvoice = async (dealID, agentID) => {
    try {
      const q = query(
        collection(db, "invoices"),
        where("deal_ID", "==", dealID),
        where("agent_ID", "==", agentID),
      )

      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
      } else {
        return null; // Return null or any default value to indicate no invoice found
      }

    } catch (error) {
      console.error('Error getting invoice: ', error);
    }
  }

  export const getInvoiceById = async (id) => {
    try {
      const invoiceDoc = await getDoc(doc(db, "invoices", id))

      if(invoiceDoc.exists()){
        return invoiceDoc.data();
      }else{
        return null;
      }
    } catch (error) {
      console.error('Error getting invoice: ', error);
    }
  }

  export const changeStatusofInvoice = async (id, newStatus, paymentID) => {
    try {
      await updateDoc(doc(collection(db, "invoices"), id), {
        payment_status: newStatus,
        paymentID: paymentID
      });
      return true;
    } catch (error) {
      console.log("Error updating details:", error);
    }
  };

  export const getPaymentMethods = async (id) => {
    const paymentMethods = await stripe.customers.listPaymentMethods(id, {
      type: 'card'
    });

    return paymentMethods.data;
  }

  export const getInvoices = async (keyword, selectedFilter) => {
    const invoices = [];
  
    let q = collection(db, "invoices");

    if (keyword) {
      q = query(
        collection(db, "invoices"),
        where("postcode", "==", keyword),
      );
    }

    if(selectedFilter !== 'All'){
      q = query(
        collection(db, "invoices"),
        where("payment_status", "==", selectedFilter),
      );
    }
  
    const querySnapshot = await getDocs(q);
  
    for (const docu of querySnapshot.docs) {
      const invoiceData = docu.data();

      const agent = await getUser(invoiceData.agent_ID);
  
      // Get the dealListingID from invoiceData
      const dealListingID = invoiceData.deal_ID;

      // Retrieve the listing document using the dealListingID
      const dealListingDocRef = doc(db, "deal_listings", dealListingID);
      const dealListingDoc = await getDoc(dealListingDocRef);
      const dealListingData = dealListingDoc.data();
 
      const listingDocRef = doc(db, "listings", dealListingData.listing_id);
      const listingDoc = await getDoc(listingDocRef);
      const listingData = listingDoc.data();

      // Get the postcode from the listingData
      const postcode = listingData.postcode;

      const object = {
        id: docu.id,
        Agent: agent.fname + ' ' + agent.lname,
        [dealListingData.country === 'US' ? 'Zip Code' : 'Post Code']: postcode, // Use the postcode from the listing
        "Total Amount": parseFloat(invoiceData.total_amount).toLocaleString('en-US', {
          style: 'currency',
          currency: dealListingData.country === 'US' ? 'USD' : 'GBP'
        }),
        Status: invoiceData.payment_status,
        StripeAdmin: invoiceData.payment_status === 'PAID' && invoiceData.paymentID ? invoiceData.paymentID : ''
      };
  
      invoices.push(object);
    }
  
    return invoices;
  };
  