import React, { useEffect, useState } from 'react'
import { COLORS, UserTypes } from '../../components/variables'
import styled from 'styled-components'
import Sidebar from '../../components/Navbar/Sidebar'
import InputwithIcon from '../../components/Forms/InputwithIcon'
import InputField from '../../components/Forms/InputField'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import DropdownField from '../../components/Forms/DropdownField'
import Pagination from '../../components/Pagination/Pagination'
import { changeStatus, getUsers } from '../../backend-functions/Users'
import Modal from '../../components/Modal/Modal'
import SmallButton from '../../components/Buttons/SmallButton'
import BigButton from '../../components/Buttons/SmallButton'

const UserAccounts = () => {
  const filterOptions = Object.entries(UserTypes).map(([label]) => ({
    label,
    value: label
  }));

  // Add an option for "All" at the beginning
  filterOptions.unshift({ label: "All", value: "" });

  // restore and block will be replaced with buttons
  const header = {img: '', name: 'Name', country: 'Country', code: 'Post Code', usertype: 'Type', type: 'header', status: 'Status', button1: '', button2: ''};

  const [ list, setList ] = useState([]);
  const [ confirmModal, setConfirmModal ] = useState(false);
  const [ action, setAction ] = useState('');
  const [ selectedID, setSelectedID] = useState('');
  const [ selectedFilter, setSelectedFilter ] = useState('');
  const [ keyword, setKeyword ] = useState('');

  const [ isNull, setIsNull ] = useState(true);
  const [ isDisabled, setIsDisabled ] = useState(false);

  const handleRestore = async (id) =>{
    setConfirmModal(true);
    setAction('restore');
    setSelectedID(id);
  }

  const handleBlock = async (id) =>{
    setConfirmModal(true);
    setAction('block');
    setSelectedID(id);
  }

  const handleConfirmation = async () => {
    const newStatus = action === "block" ? true : false;
    setIsDisabled(true);
    await changeStatus(selectedID, newStatus);
    setConfirmModal(false)
    setIsDisabled(false);
  }
    
  getUsers(handleRestore, handleBlock, keyword, selectedFilter, (usersData) => {
      setList(usersData);

      if(usersData.length < 1){
        setIsNull(false);
      }
  });

  useEffect(() => {
    // Filter list based on keyword and selectedFilter
    list.filter(user => {
      // Filter by keyword
      const isMatchingKeyword = user.Name.toLowerCase().includes(keyword.toLowerCase())

      // Filter by selectedFilter (if it's not 'All')
      const isMatchingFilter = selectedFilter === 'All' || user.usertype === selectedFilter
  
      return isMatchingFilter && isMatchingKeyword;
    });
  }, [keyword, selectedFilter])
  
  // [keyword, selectedFilter, handleRestore, handleBlock]);

  return (
    <Page>
      {/* CONFIRMATION MODAL */}
      <Modal isOpen={confirmModal} setOpen={setConfirmModal}>
        <h4>Are you sure you want to <i>{action}</i> account?</h4>
        <Flex>
          <SmallButton text='Yes' bgColor={COLORS.green} color={COLORS.blue} disabled={isDisabled} onClick={handleConfirmation} />
          <SmallButton text='No' bgColor={COLORS.red} color={COLORS.white} disabled={isDisabled} onClick={() => {setConfirmModal(false)}} />
        </Flex>
      </Modal>

      <Sidebar userAccounts={true} />
      <Content>
      <h2>User Accounts</h2>   
      {/* Search and Filter */}
      <SpaceBetween direction='column'>
        <SpaceBetween width='50%'>
          <Flex>
            <InputwithIcon icon={faSearch} placeholder='Search by Name' value={keyword} onChange={(e) => {setKeyword(e.target.value)}} />
          </Flex> 
          <Flex>
            <p>Filter:</p>
            <DropdownField options={filterOptions} selectedValue={selectedFilter} setSelectedOption={setSelectedFilter} />
          </Flex>
        </SpaceBetween>
        <SpaceBetween width='50%' style={{justifyContent: 'end'}}> 
          <Flex>
            <Link href='/adduser'>
              <BigButton text='+ Add User' bgColor={COLORS.blue} color={COLORS.white} />
            </Link>
          </Flex>
        </SpaceBetween>
      </SpaceBetween>
      <br></br>
      {/* Data Table */}
      <Pagination data={list} header={header} isNull={isNull}/>
      </Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`;

const Content = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex-grow: 1;
  border-radius: 10px;
`;

const SpaceBetween = styled.div`
  display flex;
  justify-content: space-between;
  width: ${props => props.width ? props.width: '100%'};
  gap: 20px;

  @media only screen and (max-width: 1080px) {
    width: 100%;
    flex-direction: ${props => props.direction}
  }
`;

const Flex = styled.div`
  display flex;
  width: 50%;
  align-items: center;
  gap: 5px;

  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Link = styled.a`
  width: 100%
`;

export default UserAccounts
