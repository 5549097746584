import React from 'react'
import Carousel from 'react-elastic-carousel'
import Image from './Image';

const CarouselImg = ({data}) => {
  const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 4 },
        { width: 1200, itemsToShow: 5 },
      ];
  return (
    <Carousel breakPoints={breakPoints}>
        {data.map(img => {
            return <Image image={img}/>
        })}
    </Carousel>
  )
}

export default CarouselImg
