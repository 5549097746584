import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'

const TermsOfUse = () => {
  return (
    <Page>
      <Container>
        <p><strong><span style={{fontSize:"13.999999999999998pt"}}>Terms of Use</span></strong></p>
        <p><span style={{fontSize:"13.999999999999998pt"}}>These terms of use apply to all who use the The Agent Genie website and describe how you can use the The Agent Genie website. The Agent Genie privacy policy sets out how we use data relating to you and it forms a part of these terms of use. Our privacy policy can be found <a href='/privacy-policy' style={{textDecoration: 'underline'}}>here</a>.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Summary</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>This constitutes a legal agreement between us and you, please read it carefully.</span></p>
        <p><span style={{fontSize:"11pt"}}>These terms of use describe the process by which offers for the sale of your property are made on our website.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>You must act appropriately when using our website.</span></p>
        <p><span style={{fontSize:"11pt"}}>The website is provided on an &lsquo;as is&rsquo; basis, with minimal warranties.</span></p>
        <p><span style={{fontSize:"11pt"}}>These terms of use may change from time to time.</span></p>
        <p><span style={{fontSize:"11pt"}}>About the website and The Agent Genie Terms of Use</span></p>
        <p><span style={{fontSize:"11pt"}}>theagentgenie.com (the &ldquo;Website&rdquo;) is an online service owned and operated by Social Impact Marketing Ltd (&ldquo;The Agent Genie&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&quot;), which enables individuals to easily compare commission bids from Estate Agents.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>These terms of use apply to all who use Website and form a legal agreement between you and The Agent Genie.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>By using the Website you are confirming that you understand and accept (and are able to understand and accept) these terms of use and that you agree to be bound by them.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>You must not use the Website if:</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>You don&rsquo;t agree to these terms of use</span></p>
        <p><span style={{fontSize:"11pt"}}>You are under the age of 18</span></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie may make alterations to these terms of use from time to time. If you are not happy with any alteration, you must stop using the Website. If you keep using the Website, this will indicate your acceptance of these terms of use as altered.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>We may notify you of key changes but you should review these terms of use from time to time to ensure you are aware of any changes. Where appropriate we may notify you of changes by email or by putting a notice on the Website.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Listing your property and receiving commission bids from Estate Agents</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>We do not sell property. We enable Estate Agents to provide you with commission offers for the sale of your property through the Website (&ldquo;Bids&rdquo;). If you decide to instruct one of the Estate Agents that bid for the right to sell your property, you will be entering an agreement with that Estate Agent. We are not a party to that agreement.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>By creating a listing for your property through our website, you are not committing to using the agent that wins the bid.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Bids are received by the following process:</span></strong></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>You post on the Website information about your property; the address and number of bedrooms.</span></p>
        <p><span style={{fontSize:"11pt"}}>Using this information, but keeping your name anonymous we allow agents to bid for the right to sell your property based on their commission.</span></p>
        <p><span style={{fontSize:"11pt"}}>Estate Agents provide a percentage of the sale of your property for their amount of commission they want to bid.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>You consider the bid and if you are interested, request a valuation visit.</span></p>
        <p><span style={{fontSize:"11pt"}}>If after the valuation visit, you agree to instruct that Estate Agent you will enter into an agreement with that Estate Agent. We are not a party to that agreement.</span></p>
        <p><span style={{fontSize:"11pt"}}>We cannot guarantee that you will receive any Quotes, or when these Quotes will be sent.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>We are not obliged to review Quotes but may do so as part of our ongoing commitment to customer service or if you ask us to.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>You must ensure that all information about you that is held by us is true, complete, not misleading and up to date.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Rights of use and intellectual property rights</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>The Website is made available for your private, non-commercial, personal use only. This does not give you any rights of ownership in the Website.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>Unless otherwise specified, all copyright, design rights, database rights, patent rights, trademark and trade dress rights and other intellectual property rights in the Website belong to and vest in The Agent Genie, or are licensed to The Agent Genie.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>All third party trade names and trademarks are the property of their respective owners and The Agent Genie makes no warranty or representation in relation to them. Unless stated otherwise, we do not endorse and are not affiliated with any of the holders of any such rights and as such we cannot grant any license to exercise such rights.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie and our logo are trademarks belonging to us. We give no permission for the use of these trademarks, and such use may constitute an infringement of our rights.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Restrictions and obligations</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>You agree to comply with these terms of use and all rules applicable to the use of the Website.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>You will not:</span></strong></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>Hack, modify, reverse engineer or create derivative works of the Website or any part of it</span></p>
        <p><span style={{fontSize:"11pt"}}>Gain unauthorized access to any restricted part of the Website</span></p>
        <p><span style={{fontSize:"11pt"}}>Remove, modify or obscure any copyright, trademark or other proprietary notices on the Website</span></p>
        <p><span style={{fontSize:"11pt"}}>Create software which replicates or mimics the data or functionality in the Website</span></p>
        <p><span style={{fontSize:"11pt"}}>Collect data from the Website by systematic or automated means</span></p>
        <p><span style={{fontSize:"11pt"}}>Use your access to the Website for the sending of marketing communications</span></p>
        <p><span style={{fontSize:"11pt"}}>Use any computer virus or malicious code of any nature in connection with the Website</span></p>
        <p><span style={{fontSize:"11pt"}}>Make any part of the Website available to a third party who does not agree to these terms of use</span></p>
        <p><span style={{fontSize:"11pt"}}>Copy or exploit any part of the Website or the content it contains</span></p>
        <p><span style={{fontSize:"11pt"}}>Use the Website or any part of it unfairly or for any illegal or immoral purpose; or</span></p>
        <p><span style={{fontSize:"11pt"}}>Attempt to do any of the acts listed above.</span></p>
        <p><span style={{fontSize:"11pt"}}>Without prejudice to any of our other rights or remedies, if we reasonably suspect that you have breached any of these terms of use we can suspend or terminate your access to the Website or take any other action we consider necessary to defend or promote any of our rights or interests.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Accounts</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>You may need to submit personal information and will be given an account name (&ldquo;Username&rdquo;), while you create a password (&ldquo;Password&rdquo;) and use a valid email address in order to create an account on the Website (&quot;Account&quot;) or otherwise use the Website.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>If so, the Username and Password will be allocated to you if you successfully apply for registration as a Customer. Only we can decide whether applications will be successful. Your Username and Password are personal to you and you may only hold one Account.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>We are entitled to assume that any use of your Account is made by you. You are solely responsible and liable for any use of the Website under your Account or any other use of your Username and Password.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>Do not share your password with any other person or allow any other person to use your account. We are not liable for any improper use of your password or account or any use of your password or account by any other person. If you think any other person knows your password, or that your account has been used by any other person, you must inform us immediately.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Communications</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>The Website enables the display of third party content (&ldquo;User Content&rdquo;) without review or moderation. The Website is therefore merely a conduit of User Content.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>The views expressed in any User Content are the views of the individual authors and not those of The Agent Genie unless we specify otherwise.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>We disclaim all liability in respect of any comments, views or remarks expressed in any User Content.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>By using the Website you acknowledge that we have no responsibility to review any User Content and that all User Content is made available on the basis that we are not required to exercise any control or judgment over it. However we may remove any User Content for any reason.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>If you post any User Content to the Website, or otherwise publish or distribute User Content using the Website, you grant us a non-exclusive, transferable, sublicensable, royalty-free, irrevocable, perpetual worldwide license to use that User Content for any purpose.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Any User Content you post or otherwise publish or distribute using the Website must:</span></strong></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>Not be libelous or maliciously false</span></p>
        <p><span style={{fontSize:"11pt"}}>Not be deceptive, offensive, harassing, inappropriate, obscene, indecent, violent, racist or bigoted</span></p>
        <p><span style={{fontSize:"11pt"}}>Infringe any rights of another person, including any intellectual property right, right of confidence or privacy, or any rights under data protection legislation</span></p>
        <p><span style={{fontSize:"11pt"}}>Be in compliance with applicable law, regulations or court orders; or</span></p>
        <p><span style={{fontSize:"11pt"}}>Not be untrue, false, inaccurate or misleading.</span></p>
        <p><span style={{fontSize:"11pt"}}>It is a known risk of internet usage that people are not necessarily who they say they are. People may provide information or behave in a way that is unreliable, misleading, unlawful or illegal. We have no way of telling if statements made by other users are true. You should therefore exercise some degree of caution when reading user content on any website. By using the website you accept that you use the website at your own risk.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Liability</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie provides and maintains the Website on an &ldquo;as is&rdquo; basis and is liable only to provide its services with reasonable skill and care.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>External Sites have not been verified or reviewed by The Agent Genie and all use and access of External Sites is made at your own risk. &ldquo;External Sites&rdquo; means third party websites and online services to which the Website links.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie gives no warranty in respect of any offers or other information contained on the website and excludes all liability for any incorrect or inaccurate information or material the website contains.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie gives no other warranty in connection with the Website and to the maximum extent permitted by law, The Agent Genie excludes liability for:</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>the acts or omissions of any Estate Agent;</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>any loss or damage of any kind howsoever arising, including any direct, indirect, special, punitive or consequential loss whether or not that loss arises out of something of which The Agent Genie has been made aware</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>any interruptions to or delays in updating the Website</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>the infringement by any other person of any copyright or other intellectual property rights of any third party through any use of the Website</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>the availability, quality, content or nature of External Sites</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>any transaction taking place on External Sites</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>any transaction with a third party retailer taking place on the Website</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>any amount or kind of loss or damage due to viruses or other malicious software that may infect a user&apos;s computer equipment, software, data or other property caused by any other person accessing, using or downloading the Website; and</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>all representations, warranties, conditions and other terms and conditions which but for this notice would have effect.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie does not warrant that the operation of the Website will be uninterrupted or error free.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie will not be liable in any amount for failure to perform any obligation under these terms of use if that failure is caused by the occurrence of an event beyond its reasonable control. Except as provided above there are no other warranties, conditions or other terms and conditions, express or implied, statutory or otherwise, and all of those terms and conditions are hereby excluded to the maximum extent permitted by law.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>You agree not to use the Website in any way which is:</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Unlawful;</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>may give rise to civil or criminal liability for The Agent Genie; or</span></p>
        <p><span style={{fontSize:"11pt"}}>which might call The Agent Genie into disrepute.</span></p>
        <p><span style={{fontSize:"11pt"}}>These terms of use are subject to your statutory and common law consumer rights and will not limit any rights you might have that cannot be excluded under applicable law. These terms of use will not exclude or limit The Agent Genie&rsquo;s liability for death or personal injury resulting from its negligence nor any fraudulent acts, representations or misstatements.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>Indemnity</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>You hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute on the advice of our legal advisers) incurred or suffered by us arising out of any breach by you of any provision of these terms of use, or arising out of any claim that you have breached any provision of these terms of use.</span></p>
        <p><br/></p>
        <p><strong><span style={{fontSize:"11pt"}}>General</span></strong></p>
        <p><span style={{fontSize:"11pt"}}>These terms of use, together with our privacy policy, constitute the entire agreement between you and The Agent Genie relating to your use of the Website, to the exclusion of any other terms of use.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>Failure to enforce any term does not constitute a waiver of that term.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>If any part of these terms of use is found to be unenforceable, it will be amended to the minimum extent necessary to make it enforceable and the remainder of the provisions will remain in full force and effect.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>No representation or warranty is made as to whether the Website complies with the laws of any other country other than the United Kingdom.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>These terms of use are subject to the laws of England and the parties submit to the exclusive jurisdiction of the English courts.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>The Agent Genie will be entitled to assign and otherwise transfer the agreement covered by these terms of use by giving you reasonable notice, which may include notice given via the Website.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>All questions, comments or enquiries should be directed to The Agent Genie. The Agent Genie will try to respond to you within 48 hours.</span></p>
      </Container>
    </Page>
  )
}

const Page = styled.div`
    background: ${COLORS.blue};
    padding: 5% 15%;
`;

const Container = styled.div`
    background: ${COLORS.white};
    border: 2px solid ${COLORS.gray};
    padding: 20px;
    border-radius: 5px;
`;

export default TermsOfUse
