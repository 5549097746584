import React, { useContext, useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { COLORS, UserTypes } from '../variables'
import styled from 'styled-components'
import BigButton from '../Buttons/BigButton'
import InputField from './InputField'
import { usernames } from '../../usernames'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Error from './Error'
import { register } from '../../backend-functions/Auth'
import { cleanUKPostCode, random } from '../../backend-functions/custom-functions'
import DropdownField from './DropdownField'
import CompleteRegistrationPixelCode from '../CompleteRegistrationPixelCode'

const RegisterSeller = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostCode] = useState("");
  const [fullpostcode, setfullPostCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [accept, setAccept] = useState(false);
  const [country, setCountry] = useState("UK");

  const [urlParam] = useSearchParams();
  const next = urlParam.get('next');
 
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const usertype = "Seller";

  const countryOptions = [
    { value: "UK", label: "United Kingdom" },
    { value: "US", label: "United States" },
  ];

  const navigate = useNavigate();
  const userObj = {
    fname: fname,
    lname: lname,
    email: email,
    phone: phone,
    street: street,
    city: city,
    fullpostcode: fullpostcode.toUpperCase(),
    postcode: postcode.toUpperCase(),
    usertype: usertype,
    typehierarchy: UserTypes.Seller,
    password: password,
    confirmpassword: confirmpassword,
    accept: accept,
    country: country,
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading("Please wait...");
    setError("");

    const user = await register(userObj, setError, setLoading);
    
    if (user) {
      const registrationPixelContainer = document.createElement('div');
      ReactDOM.render(<CompleteRegistrationPixelCode />, registrationPixelContainer);
      document.getElementById('root').appendChild(registrationPixelContainer);

      if(next)
        navigate("/login?next=create");
      else
        navigate("/login");
    }
  }

  return (
    <Form onSubmit={handleRegister}>
      {loading && <Loading>{loading}</Loading>}
      {error && <Error error={error} />}
      
      <FlexCol stop="yes">
        <InputField required type="text" label="First Name" value={fname} onChange={(e)=>setFname(e.target.value)} placeholder="First Name" width="49%"/>
        <InputField required type="text" label="Last Name" value={lname} onChange={(e)=>setLname(e.target.value)} placeholder="Last Name" width="49%"/>
      </FlexCol>
      <FlexCol stop="yes">
        <InputField required type="email" label="Email Address" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email Address" width="49%"/>
        <InputField required type="tel" label="Phone Number" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder={country === 'UK' ? '07xxxxxxxxx' : 'Phone Number'} width="49%"/>
      </FlexCol>
      {/* <FlexCol stop="yes">
        <DropdownField options={countryOptions} setSelectedOption={setCountry} label="Country" />
      </FlexCol>
      <FlexCol stop="yes">
        <InputField required type="text" label="Street" value={street} onChange={(e)=>setStreet(e.target.value)} placeholder="Street" width="32%"/>
        <InputField required type="text" label="City" value={city} onChange={(e)=>setCity(e.target.value)} placeholder="City" width="32%"/>
        <InputField required type="text" label={country === 'UK' ? 'Post Code' : 'Zip Code'} value={fullpostcode} onChange={(e)=>{setPostCode(cleanUKPostCode(e.target.value, country)); setfullPostCode(e.target.value)}} placeholder={country === 'UK' ? 'Post Code' : 'Zip Code'} width="32%"/>
      </FlexCol> */}
      <FlexCol stop="yes">
        <InputField required type="password" label="Password" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password" width="49%"/>
        <InputField required type="password" label="Confirm Password" value={confirmpassword} onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Confirm Password" width="49%"/>
      </FlexCol>
      <br />
      <FlexCol stop='yes'>
            <input type='checkbox' id='accept' checked={accept} onChange={(e)=>setAccept(!accept)}></input>
            <label htmlFor='accept'>I accept the <a href='/terms-of-use' style={{textDecoration: 'underline'}}>Terms and Conditions</a></label>
      </FlexCol>
      <br></br>
      <FlexCol stop="yes" style={{ justifyContent: "center" }}>
        <BigButton
          text="&nbsp; Register &nbsp;"
          color={COLORS.white}
          bgColor={COLORS.blue}
          type="submit"
        ></BigButton>
      </FlexCol>
      <br></br>
      {/* <FlexCol
        stop="yes"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Line></Line>
        <span style={{ margin: "0px 20px" }}>or</span>
        <Line></Line>
      </FlexCol>
      <br></br>
      <FlexCol stop="yes" style={{ justifyContent: "space-between" }}>
        <ButtonwithIcon
          text="Register with Google"
          width="45%"
          color={COLORS.blue}
          bgColor={COLORS.white}
        />
        <ButtonwithIcon
          text="Register with Facebook"
          width="45%"
          color={COLORS.blue}
          bgColor={COLORS.white}
        />
      </FlexCol> */}
    </Form>
  );
}

const Line = styled.span`
  background: ${COLORS.blue};
  height: 1px;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: ${props => props.height};
    width: 100%;
    box-sizing: border-box;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
    // justify-content: space-between;

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => (props.stop ? 'row' : 'column')};
        margin-top: ${props => (props.marginTop ? '0' : props.marginTop)};
    }
`;

const Loading = styled.p`
  font-style: italic;
  color: ${COLORS.blue};
`;

const ForgotPass = styled.div`
  margin: 10px 0px;
  text-align: end;
  width: 100%;
  font-size: 12px;
  color: ${COLORS.blue};

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

export default RegisterSeller
