import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'

const Error = ({error}) => {
  return (
    <ErrorCont>
      { error }
    </ErrorCont>
  )
}

const ErrorCont = styled.p`
  font-style: italic;
  color: ${COLORS.red};
  margin: 0;
`;

export default Error
