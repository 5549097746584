import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import BigButton from '../Buttons/BigButton';
import Error from './Error';
import { addBidding } from '../../backend-functions/Biddings';
import { AuthContext } from '../../context/AuthContext';
import InputField from './InputField';
import Modal from '../Modal/Modal';
import { emailVerification, logout } from '../../backend-functions/Auth';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../backend-functions/Users';
import SmallButton from '../Buttons/SmallButton';

const SmallAddBidding = ({data, showBid}) => {
  const { userDetails, authUser, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const [bid, setBid] = useState(data.yourbid ? data.yourbid : 0);
  const [error, setError] = useState('');
  const [isUnverified, setIsUnverified] = useState(false);
  const [ user, setUser ] = useState(null);
  const [ isDisabled, setIsDisabled ] = useState(false);

  const topBid = !isNaN(data['Top Bidding'].slice(0, -1)) ? data['Top Bidding'].slice(0, -1) : 100;
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getUser(userDetails.uid);
        setUser(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userDetails.uid]);



  const handleSubmit = async () => {
    setError('');
    setIsDisabled(true);
    // make sure the bid is between 0.1 - current top bid
    if(bid >= topBid|| bid <= 0){
        alert('Bid must be smaller than the current top bid and greater than 0');
        setIsDisabled(false);
        return;
    }

    if(!authUser.emailVerified){
      setIsUnverified(true);
      setIsDisabled(false);
      return;
    }

    if(userDetails.application_status !== "Approved"){
      alert('Your information still needs to be approved by the Support Team. Please wait till we approve this so you can submit a bid. We will let you know.');
      setIsDisabled(false);
      return;
    }

    if(user.isDelete){
      alert('Your account is currently restricted. Contact an admin for any inquiries.');
      setIsDisabled(false);
      return;
    }

    setError('Please Wait...');
    const code = data['Post Code'] ? data['Post Code'] : data['Zip Code'];
    await addBidding(data.id, userDetails.uid, bid, data.sellerid, code).then(() => {
        window.location.href = '?bid=successful';
        setIsDisabled(false);
    });
  }

  const handleEmailVerification = async (e) => {
    const response = await emailVerification();

    if(response){
      // refresh the page
      window.location.reload();
    }
  }

  return (
    <Container>
    {/* MODAL FOR UNVERIFIED EMAIL */}
    <Modal isOpen={isUnverified} setOpen={setIsUnverified}>
            <h2>Your email address is not yet verified</h2>
            <p>Please verify your email address before bidding</p>
            <br/><br/>
            <BigButton bgColor={COLORS.blue} color={COLORS.white} text='Verify Account' onClick={handleEmailVerification} />
    </Modal>
    <Error error={error} />
    <Text>
      <InputContainer>
                <InputField setValue={setBid} type='number' value={bid} borderless={true} />
                %
      </InputContainer>
      <SmallButton bgColor={COLORS.light_blue} color={COLORS.white} text={showBid} onClick={handleSubmit} disabled={isDisabled}></SmallButton>
    </Text>
    </Container>
    
  );
}

const Container = styled.div`
    width: 100%;
`;

const Text =  styled.div`
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 100%;
`;

const InputContainer = styled.div`
    width: 80px;
    border: 1px solid ${COLORS.blue};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
`;

const BidBtn = styled.button`
    background: ${COLORS.light_blue};
    color: ${COLORS.white};
    right: 10px;
    padding: 5px 0px;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    // display: none;

    @media only screen and (max-width: 780px) {
        display: block;
    }
`;

export default SmallAddBidding
