import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../variables';

const ProfilePicture = (props) => {
  // const imageUrl = require(`../../img/${props.image}`);
  return (
    <Container image={props.image}>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: gray;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export default ProfilePicture;
