var Zipcodes = new Array();
Zipcodes['00601'] = [18.180555, -66.749961];
Zipcodes['00602'] = [18.361945, -67.175597];
Zipcodes['00603'] = [18.457399, -67.124867];
Zipcodes['00606'] = [18.158327, -66.932928];
Zipcodes['00610'] = [18.29396, -67.127182];
Zipcodes['00611'] = [18.276316, -66.807165];
Zipcodes['00612'] = [18.416727, -66.70009];
Zipcodes['00616'] = [18.420412, -66.671979];
Zipcodes['00617'] = [18.446889, -66.561154];
Zipcodes['00622'] = [17.988103, -67.160357];
Zipcodes['00623'] = [18.084371, -67.158765];
Zipcodes['00624'] = [18.051808, -66.721711];
Zipcodes['00627'] = [18.412507, -66.863811];
Zipcodes['00631'] = [18.184294, -66.830795];
Zipcodes['00636'] = [18.1636, -67.080097];
Zipcodes['00637'] = [18.080103, -66.947975];
Zipcodes['00638'] = [18.280188, -66.513111];
Zipcodes['00641'] = [18.268074, -66.701785];
Zipcodes['00646'] = [18.432897, -66.286499];
Zipcodes['00647'] = [17.963613, -66.947127];
Zipcodes['00650'] = [18.349103, -66.578175];
Zipcodes['00652'] = [18.44844, -66.59411];
Zipcodes['00653'] = [17.988642, -66.90169];
Zipcodes['00656'] = [18.040426, -66.789672];
Zipcodes['00659'] = [18.406773, -66.809288];
Zipcodes['00660'] = [18.134695, -67.116199];
Zipcodes['00662'] = [18.468301, -67.015749];
Zipcodes['00664'] = [18.21033, -66.591616];
Zipcodes['00667'] = [18.003422, -67.03581];
Zipcodes['00669'] = [18.276925, -66.869587];
Zipcodes['00670'] = [18.233585, -66.987585];
Zipcodes['00674'] = [18.424145, -66.487895];
Zipcodes['00676'] = [18.381597, -67.080884];
Zipcodes['00677'] = [18.332568, -67.227022];
Zipcodes['00678'] = [18.430503, -66.930479];
Zipcodes['00680'] = [18.183493, -67.133054];
Zipcodes['00682'] = [18.224864, -67.153891];
Zipcodes['00683'] = [18.1078, -67.037263];
Zipcodes['00685'] = [18.336887, -66.972929];
Zipcodes['00687'] = [18.318017, -66.420392];
Zipcodes['00688'] = [18.382311, -66.626416];
Zipcodes['00690'] = [18.493543, -67.096915];
Zipcodes['00692'] = [18.410652, -66.336202];
Zipcodes['00693'] = [18.425961, -66.398098];
Zipcodes['00694'] = [18.483127, -66.39236];
Zipcodes['00698'] = [18.072916, -66.85119];
Zipcodes['00703'] = [18.248401, -66.130662];
Zipcodes['00704'] = [17.973168, -66.229011];
Zipcodes['00705'] = [18.12896, -66.266691];
Zipcodes['00707'] = [18.014055, -65.920751];
Zipcodes['00714'] = [17.995867, -66.055529];
Zipcodes['00715'] = [17.990485, -66.566143];
Zipcodes['00716'] = [17.985014, -66.606721];
Zipcodes['00717'] = [18.002639, -66.611087];
Zipcodes['00718'] = [18.23201, -65.756036];
Zipcodes['00719'] = [18.28992, -66.25344];
Zipcodes['00720'] = [18.216608, -66.423682];
Zipcodes['00723'] = [18.019337, -66.001862];
Zipcodes['00725'] = [18.220689, -66.03958];
Zipcodes['00727'] = [18.211921, -66.073355];
Zipcodes['00728'] = [17.991071, -66.662968];
Zipcodes['00729'] = [18.334918, -65.887078];
Zipcodes['00730'] = [18.030882, -66.611928];
Zipcodes['00731'] = [18.109947, -66.635622];
Zipcodes['00735'] = [18.251772, -65.666882];
Zipcodes['00736'] = [18.103624, -66.151667];
Zipcodes['00738'] = [18.304458, -65.698711];
Zipcodes['00739'] = [18.175543, -66.162102];
Zipcodes['00740'] = [18.333961, -65.631674];
Zipcodes['00741'] = [18.163855, -65.754042];
Zipcodes['00745'] = [18.352176, -65.810929];
Zipcodes['00751'] = [18.003779, -66.262905];
Zipcodes['00754'] = [18.144582, -65.972253];
Zipcodes['00757'] = [17.991428, -66.402337];
Zipcodes['00765'] = [18.124207, -65.42307];
Zipcodes['00766'] = [18.130718, -66.472244];
Zipcodes['00767'] = [18.069489, -65.896029];
Zipcodes['00769'] = [18.096641, -66.358335];
Zipcodes['00771'] = [18.187148, -65.871189];
Zipcodes['00772'] = [18.438944, -65.90522];
Zipcodes['00773'] = [18.342888, -65.723109];
Zipcodes['00775'] = [18.326608, -65.307769];
Zipcodes['00777'] = [18.224327, -65.909085];
Zipcodes['00778'] = [18.264076, -65.97851];
Zipcodes['00780'] = [18.094076, -66.565781];
Zipcodes['00782'] = [18.224878, -66.224482];
Zipcodes['00783'] = [18.300221, -66.328927];
Zipcodes['00784'] = [18.010714, -66.133615];
Zipcodes['00786'] = [18.135725, -66.228698];
Zipcodes['00791'] = [18.142603, -65.812812];
Zipcodes['00794'] = [18.200018, -66.309282];
Zipcodes['00795'] = [18.04645, -66.495142];
Zipcodes['00802'] = [18.342939, -64.925102];
Zipcodes['00820'] = [17.736627, -64.708215];
Zipcodes['00830'] = [18.338559, -64.73653];
Zipcodes['00840'] = [17.72471, -64.848522];
Zipcodes['00850'] = [17.726822, -64.792245];
Zipcodes['00851'] = [17.747525, -64.787439];
Zipcodes['00901'] = [18.466594, -66.107982];
Zipcodes['00906'] = [18.46446, -66.094995];
Zipcodes['00907'] = [18.452271, -66.087625];
Zipcodes['00909'] = [18.441246, -66.068766];
Zipcodes['00911'] = [18.453738, -66.055689];
Zipcodes['00912'] = [18.445067, -66.059942];
Zipcodes['00913'] = [18.464629, -66.04459];
Zipcodes['00915'] = [18.437179, -66.045571];
Zipcodes['00917'] = [18.420674, -66.050105];
Zipcodes['00918'] = [18.422249, -66.069081];
Zipcodes['00920'] = [18.422439, -66.09214];
Zipcodes['00921'] = [18.392088, -66.087109];
Zipcodes['00923'] = [18.410804, -66.037358];
Zipcodes['00924'] = [18.400422, -66.012567];
Zipcodes['00925'] = [18.400271, -66.05059];
Zipcodes['00926'] = [18.348601, -66.053929];
Zipcodes['00927'] = [18.388202, -66.070033];
Zipcodes['00934'] = [18.413, -66.119829];
Zipcodes['00949'] = [18.409879, -66.227441];
Zipcodes['00950'] = [18.467225, -66.237929];
Zipcodes['00951'] = [18.42805, -66.259772];
Zipcodes['00952'] = [18.428724, -66.188931];
Zipcodes['00953'] = [18.360729, -66.251527];
Zipcodes['00956'] = [18.321137, -66.170419];
Zipcodes['00957'] = [18.366147, -66.188962];
Zipcodes['00959'] = [18.385785, -66.155654];
Zipcodes['00960'] = [18.416918, -66.142693];
Zipcodes['00961'] = [18.409907, -66.163361];
Zipcodes['00962'] = [18.439988, -66.1355];
Zipcodes['00965'] = [18.430508, -66.110917];
Zipcodes['00966'] = [18.397875, -66.113935];
Zipcodes['00968'] = [18.406063, -66.101232];
Zipcodes['00969'] = [18.366226, -66.110047];
Zipcodes['00971'] = [18.318884, -66.11919];
Zipcodes['00976'] = [18.33618, -65.994101];
Zipcodes['00979'] = [18.438359, -65.999169];
Zipcodes['00982'] = [18.408744, -65.991273];
Zipcodes['00983'] = [18.41722, -65.981317];
Zipcodes['00985'] = [18.410774, -65.947927];
Zipcodes['00987'] = [18.343988, -65.943754];
Zipcodes['01001'] = [42.062368, -72.625754];
Zipcodes['01002'] = [42.377212, -72.464795];
Zipcodes['01003'] = [42.391883, -72.523584];
Zipcodes['01005'] = [42.418884, -72.112077];
Zipcodes['01007'] = [42.283935, -72.400409];
Zipcodes['01008'] = [42.192024, -72.964076];
Zipcodes['01009'] = [42.211386, -72.341809];
Zipcodes['01010'] = [42.128176, -72.205352];
Zipcodes['01011'] = [42.290128, -72.966516];
Zipcodes['01012'] = [42.375425, -72.858192];
Zipcodes['01013'] = [42.142009, -72.595496];
Zipcodes['01020'] = [42.172611, -72.56192];
Zipcodes['01022'] = [42.197187, -72.541872];
Zipcodes['01026'] = [42.470032, -72.925707];
Zipcodes['01027'] = [42.295008, -72.751877];
Zipcodes['01028'] = [42.062378, -72.498111];
Zipcodes['01029'] = [42.176271, -73.035292];
Zipcodes['01030'] = [42.072925, -72.686972];
Zipcodes['01031'] = [42.327809, -72.191968];
Zipcodes['01032'] = [42.457613, -72.81478];
Zipcodes['01033'] = [42.244949, -72.500143];
Zipcodes['01034'] = [42.093912, -72.961103];
Zipcodes['01035'] = [42.356491, -72.568632];
Zipcodes['01036'] = [42.071188, -72.417718];
Zipcodes['01037'] = [42.376036, -72.197364];
Zipcodes['01038'] = [42.379925, -72.604045];
Zipcodes['01039'] = [42.411304, -72.690951];
Zipcodes['01040'] = [42.211658, -72.642448];
Zipcodes['01050'] = [42.291259, -72.874803];
Zipcodes['01053'] = [42.356301, -72.713584];
Zipcodes['01054'] = [42.468898, -72.484579];
Zipcodes['01056'] = [42.183577, -72.457812];
Zipcodes['01057'] = [42.093596, -72.321494];
Zipcodes['01060'] = [42.321251, -72.630395];
Zipcodes['01062'] = [42.323033, -72.701789];
Zipcodes['01063'] = [42.319584, -72.639539];
Zipcodes['01066'] = [42.413039, -72.623639];
Zipcodes['01068'] = [42.353887, -72.060685];
Zipcodes['01069'] = [42.188862, -72.30648];
Zipcodes['01070'] = [42.519909, -72.925354];
Zipcodes['01071'] = [42.160064, -72.87384];
Zipcodes['01072'] = [42.456968, -72.417244];
Zipcodes['01073'] = [42.226949, -72.741588];
Zipcodes['01074'] = [42.380823, -72.103895];
Zipcodes['01075'] = [42.256532, -72.580248];
Zipcodes['01077'] = [42.05257, -72.777353];
Zipcodes['01079'] = [42.185745, -72.33246];
Zipcodes['01080'] = [42.185727, -72.361558];
Zipcodes['01081'] = [42.061313, -72.234411];
Zipcodes['01082'] = [42.299749, -72.279164];
Zipcodes['01083'] = [42.20684, -72.198619];
Zipcodes['01084'] = [42.361724, -72.874987];
Zipcodes['01085'] = [42.153503, -72.771602];
Zipcodes['01088'] = [42.3827, -72.640361];
Zipcodes['01089'] = [42.125451, -72.649734];
Zipcodes['01092'] = [42.191868, -72.235108];
Zipcodes['01093'] = [42.445258, -72.645058];
Zipcodes['01094'] = [42.369762, -72.139283];
Zipcodes['01095'] = [42.138527, -72.420703];
Zipcodes['01096'] = [42.410549, -72.765765];
Zipcodes['01097'] = [42.165868, -72.830985];
Zipcodes['01098'] = [42.397559, -72.943392];
Zipcodes['01103'] = [42.104418, -72.59008];
Zipcodes['01104'] = [42.132772, -72.567411];
Zipcodes['01105'] = [42.095246, -72.577603];
Zipcodes['01106'] = [42.045695, -72.563323];
Zipcodes['01107'] = [42.12106, -72.607068];
Zipcodes['01108'] = [42.082947, -72.55951];
Zipcodes['01109'] = [42.119674, -72.549726];
Zipcodes['01118'] = [42.094587, -72.525219];
Zipcodes['01119'] = [42.124978, -72.51121];
Zipcodes['01128'] = [42.092296, -72.489135];
Zipcodes['01129'] = [42.118671, -72.488203];
Zipcodes['01144'] = [42.101894, -72.590907];
Zipcodes['01151'] = [42.15186, -72.509131];
Zipcodes['01201'] = [42.454302, -73.282074];
Zipcodes['01220'] = [42.62556, -73.119828];
Zipcodes['01222'] = [42.062743, -73.317498];
Zipcodes['01223'] = [42.304286, -73.111494];
Zipcodes['01224'] = [42.503728, -73.201636];
Zipcodes['01225'] = [42.565107, -73.149142];
Zipcodes['01226'] = [42.479819, -73.153776];
Zipcodes['01229'] = [42.29008, -73.354218];
Zipcodes['01230'] = [42.173196, -73.32429];
Zipcodes['01235'] = [42.425677, -73.063028];
Zipcodes['01236'] = [42.274499, -73.386155];
Zipcodes['01237'] = [42.549804, -73.261144];
Zipcodes['01238'] = [42.300457, -73.231006];
Zipcodes['01240'] = [42.382693, -73.266632];
Zipcodes['01242'] = [42.333734, -73.249199];
Zipcodes['01243'] = [42.367907, -73.054094];
Zipcodes['01244'] = [42.117456, -73.278635];
Zipcodes['01245'] = [42.193307, -73.218499];
Zipcodes['01247'] = [42.695699, -73.093852];
Zipcodes['01253'] = [42.206683, -73.113214];
Zipcodes['01254'] = [42.382019, -73.365492];
Zipcodes['01255'] = [42.1029, -73.125991];
Zipcodes['01256'] = [42.594166, -73.02232];
Zipcodes['01257'] = [42.085244, -73.367483];
Zipcodes['01258'] = [42.09092, -73.470197];
Zipcodes['01259'] = [42.06926, -73.228949];
Zipcodes['01260'] = [42.261673, -73.27516];
Zipcodes['01262'] = [42.310055, -73.324468];
Zipcodes['01264'] = [42.234822, -73.197083];
Zipcodes['01266'] = [42.312354, -73.388044];
Zipcodes['01267'] = [42.671617, -73.246981];
Zipcodes['01270'] = [42.519837, -73.04969];
Zipcodes['01301'] = [42.626761, -72.60153];
Zipcodes['01330'] = [42.513947, -72.821517];
Zipcodes['01331'] = [42.570237, -72.182903];
Zipcodes['01337'] = [42.689307, -72.582289];
Zipcodes['01338'] = [42.575889, -72.80665];
Zipcodes['01339'] = [42.61045, -72.887735];
Zipcodes['01340'] = [42.68553, -72.731135];
Zipcodes['01341'] = [42.507177, -72.701525];
Zipcodes['01342'] = [42.543889, -72.609458];
Zipcodes['01343'] = [42.64773, -72.969767];
Zipcodes['01344'] = [42.613418, -72.426428];
Zipcodes['01346'] = [42.658496, -72.806057];
Zipcodes['01347'] = [42.563223, -72.509775];
Zipcodes['01349'] = [42.56746, -72.467542];
Zipcodes['01350'] = [42.727772, -72.985346];
Zipcodes['01351'] = [42.53988, -72.521689];
Zipcodes['01354'] = [42.624101, -72.508607];
Zipcodes['01355'] = [42.458551, -72.327191];
Zipcodes['01360'] = [42.677091, -72.453876];
Zipcodes['01364'] = [42.605437, -72.292074];
Zipcodes['01366'] = [42.491583, -72.180876];
Zipcodes['01367'] = [42.692882, -72.919244];
Zipcodes['01368'] = [42.676687, -72.184052];
Zipcodes['01370'] = [42.593376, -72.726928];
Zipcodes['01373'] = [42.475481, -72.615403];
Zipcodes['01375'] = [42.466691, -72.546751];
Zipcodes['01376'] = [42.591441, -72.544674];
Zipcodes['01378'] = [42.679971, -72.339882];
Zipcodes['01379'] = [42.527853, -72.398403];
Zipcodes['01380'] = [42.588496, -72.406298];
Zipcodes['01420'] = [42.584925, -71.816862];
Zipcodes['01430'] = [42.673052, -71.920335];
Zipcodes['01431'] = [42.676293, -71.832523];
Zipcodes['01432'] = [42.566588, -71.575126];
Zipcodes['01434'] = [42.53206, -71.61374];
Zipcodes['01436'] = [42.602059, -72.087015];
Zipcodes['01438'] = [42.561088, -72.036074];
Zipcodes['01440'] = [42.588337, -71.986069];
Zipcodes['01450'] = [42.611636, -71.564743];
Zipcodes['01451'] = [42.501908, -71.568201];
Zipcodes['01452'] = [42.495111, -72.002339];
Zipcodes['01453'] = [42.519967, -71.763319];
Zipcodes['01460'] = [42.535931, -71.490569];
Zipcodes['01462'] = [42.582607, -71.720464];
Zipcodes['01463'] = [42.670643, -71.602858];
Zipcodes['01464'] = [42.573654, -71.640986];
Zipcodes['01468'] = [42.542767, -72.068345];
Zipcodes['01469'] = [42.664794, -71.695055];
Zipcodes['01473'] = [42.5546, -71.90544];
Zipcodes['01474'] = [42.673143, -71.748378];
Zipcodes['01475'] = [42.66816, -72.055644];
Zipcodes['01501'] = [42.198867, -71.845722];
Zipcodes['01503'] = [42.384929, -71.633889];
Zipcodes['01504'] = [42.039991, -71.532406];
Zipcodes['01505'] = [42.355049, -71.716157];
Zipcodes['01506'] = [42.180807, -72.107888];
Zipcodes['01507'] = [42.133935, -71.968025];
Zipcodes['01510'] = [42.411887, -71.690005];
Zipcodes['01515'] = [42.209682, -72.040178];
Zipcodes['01516'] = [42.053072, -71.752241];
Zipcodes['01518'] = [42.108325, -72.113692];
Zipcodes['01519'] = [42.203404, -71.679739];
Zipcodes['01520'] = [42.334177, -71.854277];
Zipcodes['01521'] = [42.046783, -72.180212];
Zipcodes['01522'] = [42.375634, -71.867382];
Zipcodes['01523'] = [42.483569, -71.675585];
Zipcodes['01524'] = [42.246029, -71.917566];
Zipcodes['01525'] = [42.098995, -71.642964];
Zipcodes['01527'] = [42.192246, -71.777649];
Zipcodes['01529'] = [42.036642, -71.578779];
Zipcodes['01531'] = [42.326183, -72.133431];
Zipcodes['01532'] = [42.323342, -71.646236];
Zipcodes['01534'] = [42.138896, -71.645003];
Zipcodes['01535'] = [42.267564, -72.066876];
Zipcodes['01536'] = [42.231309, -71.692642];
Zipcodes['01537'] = [42.158966, -71.897051];
Zipcodes['01540'] = [42.113727, -71.86085];
Zipcodes['01541'] = [42.454125, -71.87754];
Zipcodes['01542'] = [42.204251, -71.908907];
Zipcodes['01543'] = [42.388285, -71.969865];
Zipcodes['01545'] = [42.284767, -71.714228];
Zipcodes['01550'] = [42.059737, -72.033908];
Zipcodes['01560'] = [42.175395, -71.674223];
Zipcodes['01561'] = [42.445825, -71.688484];
Zipcodes['01562'] = [42.247211, -71.991867];
Zipcodes['01564'] = [42.447924, -71.776045];
Zipcodes['01566'] = [42.103214, -72.079545];
Zipcodes['01568'] = [42.177529, -71.606322];
Zipcodes['01569'] = [42.055921, -71.631128];
Zipcodes['01570'] = [42.049253, -71.846219];
Zipcodes['01571'] = [42.053778, -71.935075];
Zipcodes['01581'] = [42.268426, -71.613309];
Zipcodes['01583'] = [42.375357, -71.789363];
Zipcodes['01585'] = [42.236908, -72.162221];
Zipcodes['01588'] = [42.116108, -71.664614];
Zipcodes['01590'] = [42.132051, -71.750318];
Zipcodes['01602'] = [42.269189, -71.850728];
Zipcodes['01603'] = [42.243826, -71.843569];
Zipcodes['01604'] = [42.253485, -71.767932];
Zipcodes['01605'] = [42.289683, -71.787793];
Zipcodes['01606'] = [42.313887, -71.794185];
Zipcodes['01607'] = [42.225974, -71.788894];
Zipcodes['01608'] = [42.26209, -71.801428];
Zipcodes['01609'] = [42.285114, -71.829987];
Zipcodes['01610'] = [42.247049, -71.808366];
Zipcodes['01611'] = [42.240977, -71.877035];
Zipcodes['01612'] = [42.296798, -71.928991];
Zipcodes['01701'] = [42.319587, -71.4428];
Zipcodes['01702'] = [42.282379, -71.436623];
Zipcodes['01718'] = [42.522816, -71.430271];
Zipcodes['01719'] = [42.485985, -71.520985];
Zipcodes['01720'] = [42.483953, -71.438495];
Zipcodes['01721'] = [42.257755, -71.473526];
Zipcodes['01730'] = [42.499332, -71.281901];
Zipcodes['01731'] = [42.464648, -71.28483];
Zipcodes['01740'] = [42.439933, -71.601885];
Zipcodes['01741'] = [42.536807, -71.361324];
Zipcodes['01742'] = [42.462911, -71.364496];
Zipcodes['01745'] = [42.292114, -71.499197];
Zipcodes['01746'] = [42.195951, -71.45343];
Zipcodes['01747'] = [42.123007, -71.531404];
Zipcodes['01748'] = [42.224096, -71.540489];
Zipcodes['01749'] = [42.389071, -71.545864];
Zipcodes['01752'] = [42.349617, -71.547214];
Zipcodes['01754'] = [42.425955, -71.456256];
Zipcodes['01756'] = [42.09387, -71.544519];
Zipcodes['01757'] = [42.158692, -71.521419];
Zipcodes['01760'] = [42.284822, -71.348811];
Zipcodes['01770'] = [42.231947, -71.372963];
Zipcodes['01772'] = [42.302877, -71.530828];
Zipcodes['01773'] = [42.425506, -71.310812];
Zipcodes['01775'] = [42.429688, -71.512514];
Zipcodes['01776'] = [42.383367, -71.42107];
Zipcodes['01778'] = [42.356275, -71.361942];
Zipcodes['01801'] = [42.488703, -71.154383];
Zipcodes['01803'] = [42.503227, -71.201713];
Zipcodes['01810'] = [42.648044, -71.161751];
Zipcodes['01821'] = [42.54933, -71.251725];
Zipcodes['01824'] = [42.59079, -71.355182];
Zipcodes['01826'] = [42.679723, -71.30068];
Zipcodes['01827'] = [42.676149, -71.499772];
Zipcodes['01830'] = [42.796313, -71.053436];
Zipcodes['01832'] = [42.791114, -71.132859];
Zipcodes['01833'] = [42.726226, -70.983318];
Zipcodes['01834'] = [42.7539, -71.015935];
Zipcodes['01835'] = [42.751327, -71.094421];
Zipcodes['01840'] = [42.706763, -71.160403];
Zipcodes['01841'] = [42.715079, -71.1483];
Zipcodes['01843'] = [42.689988, -71.16039];
Zipcodes['01844'] = [42.742641, -71.178651];
Zipcodes['01845'] = [42.673909, -71.091334];
Zipcodes['01850'] = [42.656045, -71.303309];
Zipcodes['01851'] = [42.627999, -71.336757];
Zipcodes['01852'] = [42.632978, -71.295616];
Zipcodes['01854'] = [42.649481, -71.348229];
Zipcodes['01860'] = [42.838603, -71.011997];
Zipcodes['01862'] = [42.578543, -71.295592];
Zipcodes['01863'] = [42.630484, -71.397895];
Zipcodes['01864'] = [42.578401, -71.084448];
Zipcodes['01867'] = [42.535183, -71.105423];
Zipcodes['01876'] = [42.611893, -71.227226];
Zipcodes['01879'] = [42.667762, -71.42882];
Zipcodes['01880'] = [42.504361, -71.064061];
Zipcodes['01886'] = [42.585541, -71.44025];
Zipcodes['01887'] = [42.564647, -71.164516];
Zipcodes['01890'] = [42.452752, -71.144319];
Zipcodes['01901'] = [42.460419, -70.946379];
Zipcodes['01902'] = [42.471039, -70.941535];
Zipcodes['01904'] = [42.491447, -70.971441];
Zipcodes['01905'] = [42.462378, -70.972343];
Zipcodes['01906'] = [42.469675, -71.012785];
Zipcodes['01907'] = [42.474145, -70.906597];
Zipcodes['01908'] = [42.428234, -70.926071];
Zipcodes['01913'] = [42.851277, -70.955792];
Zipcodes['01915'] = [42.570442, -70.867693];
Zipcodes['01921'] = [42.683108, -71.027188];
Zipcodes['01922'] = [42.757313, -70.914241];
Zipcodes['01923'] = [42.574174, -70.950516];
Zipcodes['01929'] = [42.640451, -70.771165];
Zipcodes['01930'] = [42.620291, -70.677956];
Zipcodes['01937'] = [42.585723, -70.984051];
Zipcodes['01938'] = [42.686464, -70.839594];
Zipcodes['01940'] = [42.534146, -71.038333];
Zipcodes['01944'] = [42.576636, -70.767154];
Zipcodes['01945'] = [42.504317, -70.851542];
Zipcodes['01949'] = [42.610322, -71.006295];
Zipcodes['01950'] = [42.812358, -70.891095];
Zipcodes['01951'] = [42.773351, -70.850211];
Zipcodes['01952'] = [42.844794, -70.841499];
Zipcodes['01960'] = [42.53431, -70.969443];
Zipcodes['01965'] = [42.564101, -70.824723];
Zipcodes['01966'] = [42.640699, -70.62025];
Zipcodes['01969'] = [42.721495, -70.886637];
Zipcodes['01970'] = [42.529248, -70.868017];
Zipcodes['01982'] = [42.626223, -70.85723];
Zipcodes['01983'] = [42.641387, -70.943383];
Zipcodes['01984'] = [42.600668, -70.88326];
Zipcodes['01985'] = [42.799565, -70.964428];
Zipcodes['02019'] = [42.076682, -71.47449];
Zipcodes['02021'] = [42.175737, -71.125385];
Zipcodes['02025'] = [42.236444, -70.814309];
Zipcodes['02026'] = [42.246872, -71.179462];
Zipcodes['02030'] = [42.23416, -71.291167];
Zipcodes['02032'] = [42.156882, -71.216187];
Zipcodes['02035'] = [42.061338, -71.245802];
Zipcodes['02038'] = [42.084858, -71.410571];
Zipcodes['02043'] = [42.216098, -70.88127];
Zipcodes['02045'] = [42.28587, -70.879517];
Zipcodes['02047'] = [42.145534, -70.69505];
Zipcodes['02048'] = [42.017302, -71.21642];
Zipcodes['02050'] = [42.115139, -70.710331];
Zipcodes['02052'] = [42.184599, -71.305307];
Zipcodes['02053'] = [42.156203, -71.430345];
Zipcodes['02054'] = [42.173103, -71.363756];
Zipcodes['02056'] = [42.116244, -71.329274];
Zipcodes['02061'] = [42.164569, -70.818844];
Zipcodes['02062'] = [42.187389, -71.192477];
Zipcodes['02066'] = [42.202273, -70.758184];
Zipcodes['02067'] = [42.1076, -71.181828];
Zipcodes['02071'] = [42.106425, -71.268951];
Zipcodes['02072'] = [42.119006, -71.10365];
Zipcodes['02081'] = [42.147403, -71.257972];
Zipcodes['02090'] = [42.219645, -71.216768];
Zipcodes['02093'] = [42.052671, -71.356858];
Zipcodes['02108'] = [42.355097, -71.065737];
Zipcodes['02109'] = [42.366919, -71.050416];
Zipcodes['02110'] = [42.358254, -71.051927];
Zipcodes['02111'] = [42.35068, -71.060527];
Zipcodes['02113'] = [42.365264, -71.055222];
Zipcodes['02114'] = [42.363651, -71.066888];
Zipcodes['02115'] = [42.339422, -71.094531];
Zipcodes['02116'] = [42.350979, -71.076591];
Zipcodes['02118'] = [42.33786, -71.069805];
Zipcodes['02119'] = [42.324066, -71.085188];
Zipcodes['02120'] = [42.332134, -71.096606];
Zipcodes['02121'] = [42.305403, -71.086971];
Zipcodes['02122'] = [42.295088, -71.052533];
Zipcodes['02124'] = [42.285922, -71.075046];
Zipcodes['02125'] = [42.315682, -71.055555];
Zipcodes['02126'] = [42.277436, -71.089968];
Zipcodes['02127'] = [42.335301, -71.038216];
Zipcodes['02128'] = [42.361519, -71.007393];
Zipcodes['02129'] = [42.379657, -71.061487];
Zipcodes['02130'] = [42.308175, -71.113471];
Zipcodes['02131'] = [42.282323, -71.124746];
Zipcodes['02132'] = [42.279082, -71.160449];
Zipcodes['02133'] = [42.358352, -71.063858];
Zipcodes['02134'] = [42.358786, -71.129581];
Zipcodes['02135'] = [42.349688, -71.153964];
Zipcodes['02136'] = [42.254749, -71.127072];
Zipcodes['02138'] = [42.379637, -71.135152];
Zipcodes['02139'] = [42.362391, -71.102336];
Zipcodes['02140'] = [42.392157, -71.133996];
Zipcodes['02141'] = [42.3703, -71.08256];
Zipcodes['02142'] = [42.362505, -71.08096];
Zipcodes['02143'] = [42.3814, -71.09672];
Zipcodes['02144'] = [42.400077, -71.123088];
Zipcodes['02145'] = [42.391577, -71.08991];
Zipcodes['02148'] = [42.42938, -71.058706];
Zipcodes['02149'] = [42.405938, -71.054649];
Zipcodes['02150'] = [42.396824, -71.031348];
Zipcodes['02151'] = [42.41829, -71.001251];
Zipcodes['02152'] = [42.377382, -70.977026];
Zipcodes['02155'] = [42.42384, -71.107673];
Zipcodes['02163'] = [42.367226, -71.125092];
Zipcodes['02169'] = [42.240016, -71.015205];
Zipcodes['02170'] = [42.266415, -71.015576];
Zipcodes['02171'] = [42.29147, -71.025822];
Zipcodes['02176'] = [42.455723, -71.059019];
Zipcodes['02180'] = [42.474208, -71.097665];
Zipcodes['02184'] = [42.206188, -71.00232];
Zipcodes['02186'] = [42.241559, -71.082437];
Zipcodes['02188'] = [42.204662, -70.957633];
Zipcodes['02189'] = [42.211968, -70.929639];
Zipcodes['02190'] = [42.166731, -70.952363];
Zipcodes['02191'] = [42.245077, -70.946528];
Zipcodes['02199'] = [42.347476, -71.082033];
Zipcodes['02203'] = [42.361445, -71.059453];
Zipcodes['02205'] = [42.349403, -71.055621];
Zipcodes['02210'] = [42.348036, -71.039283];
Zipcodes['02215'] = [42.347635, -71.103082];
Zipcodes['02301'] = [42.078371, -71.042304];
Zipcodes['02302'] = [42.088907, -70.998375];
Zipcodes['02322'] = [42.132461, -71.054013];
Zipcodes['02324'] = [41.972376, -70.978773];
Zipcodes['02325'] = [41.990101, -70.962408];
Zipcodes['02330'] = [41.875179, -70.746715];
Zipcodes['02332'] = [42.044407, -70.70606];
Zipcodes['02333'] = [42.037116, -70.942178];
Zipcodes['02338'] = [42.002634, -70.863706];
Zipcodes['02339'] = [42.122956, -70.85631];
Zipcodes['02341'] = [42.05024, -70.867359];
Zipcodes['02343'] = [42.140346, -70.997861];
Zipcodes['02346'] = [41.878004, -70.869267];
Zipcodes['02347'] = [41.834384, -70.956826];
Zipcodes['02350'] = [42.021013, -70.845791];
Zipcodes['02351'] = [42.115764, -70.956597];
Zipcodes['02356'] = [42.060539, -71.119671];
Zipcodes['02357'] = [42.063974, -71.077368];
Zipcodes['02359'] = [42.067804, -70.805713];
Zipcodes['02360'] = [41.882062, -70.631289];
Zipcodes['02364'] = [41.987196, -70.741942];
Zipcodes['02366'] = [41.848753, -70.661176];
Zipcodes['02367'] = [41.9591, -70.802753];
Zipcodes['02368'] = [42.178993, -71.052654];
Zipcodes['02370'] = [42.129251, -70.908736];
Zipcodes['02375'] = [42.00569, -71.078041];
Zipcodes['02379'] = [42.021617, -71.026717];
Zipcodes['02382'] = [42.078974, -70.93939];
Zipcodes['02420'] = [42.459061, -71.217728];
Zipcodes['02421'] = [42.438547, -71.239573];
Zipcodes['02445'] = [42.325483, -71.135045];
Zipcodes['02446'] = [42.343499, -71.122244];
Zipcodes['02451'] = [42.40153, -71.252997];
Zipcodes['02452'] = [42.395609, -71.219947];
Zipcodes['02453'] = [42.368685, -71.241103];
Zipcodes['02457'] = [42.298309, -71.27302];
Zipcodes['02458'] = [42.353585, -71.188192];
Zipcodes['02459'] = [42.314779, -71.192017];
Zipcodes['02460'] = [42.351824, -71.20849];
Zipcodes['02461'] = [42.317362, -71.206508];
Zipcodes['02462'] = [42.331675, -71.255947];
Zipcodes['02464'] = [42.312975, -71.218882];
Zipcodes['02465'] = [42.349533, -71.227339];
Zipcodes['02466'] = [42.345514, -71.247661];
Zipcodes['02467'] = [42.314321, -71.152778];
Zipcodes['02468'] = [42.329175, -71.231583];
Zipcodes['02472'] = [42.369451, -71.177925];
Zipcodes['02474'] = [42.420949, -71.15637];
Zipcodes['02476'] = [42.41648, -71.173487];
Zipcodes['02478'] = [42.395317, -71.180284];
Zipcodes['02481'] = [42.310514, -71.277769];
Zipcodes['02482'] = [42.293274, -71.296442];
Zipcodes['02492'] = [42.276483, -71.243714];
Zipcodes['02493'] = [42.360502, -71.303433];
Zipcodes['02494'] = [42.299563, -71.231215];
Zipcodes['02532'] = [41.749522, -70.594928];
Zipcodes['02534'] = [41.669004, -70.61528];
Zipcodes['02535'] = [41.337961, -70.761674];
Zipcodes['02536'] = [41.596295, -70.567471];
Zipcodes['02537'] = [41.728421, -70.43581];
Zipcodes['02538'] = [41.777378, -70.642447];
Zipcodes['02539'] = [41.377655, -70.52208];
Zipcodes['02540'] = [41.569466, -70.627202];
Zipcodes['02542'] = [41.707839, -70.54552];
Zipcodes['02543'] = [41.472422, -70.785049];
Zipcodes['02553'] = [41.713138, -70.622283];
Zipcodes['02554'] = [41.290114, -70.096294];
Zipcodes['02556'] = [41.639447, -70.624515];
Zipcodes['02557'] = [41.446403, -70.561761];
Zipcodes['02558'] = [41.746275, -70.651689];
Zipcodes['02559'] = [41.690243, -70.616283];
Zipcodes['02561'] = [41.767811, -70.532854];
Zipcodes['02562'] = [41.787984, -70.527592];
Zipcodes['02563'] = [41.751133, -70.502518];
Zipcodes['02564'] = [41.271898, -69.984545];
Zipcodes['02568'] = [41.449404, -70.61636];
Zipcodes['02571'] = [41.75143, -70.699352];
Zipcodes['02575'] = [41.394406, -70.656447];
Zipcodes['02576'] = [41.772221, -70.762948];
Zipcodes['02584'] = [41.257578, -70.005294];
Zipcodes['02601'] = [41.661161, -70.293197];
Zipcodes['02630'] = [41.706756, -70.312489];
Zipcodes['02631'] = [41.747513, -70.066223];
Zipcodes['02632'] = [41.659097, -70.346877];
Zipcodes['02633'] = [41.687361, -69.98016];
Zipcodes['02635'] = [41.624054, -70.439397];
Zipcodes['02637'] = [41.705726, -70.270928];
Zipcodes['02638'] = [41.730245, -70.197392];
Zipcodes['02639'] = [41.668067, -70.136979];
Zipcodes['02641'] = [41.753951, -70.155393];
Zipcodes['02642'] = [41.8416, -69.974973];
Zipcodes['02643'] = [41.75354, -69.934661];
Zipcodes['02644'] = [41.683488, -70.510786];
Zipcodes['02645'] = [41.701286, -70.061663];
Zipcodes['02646'] = [41.670596, -70.071449];
Zipcodes['02647'] = [41.630127, -70.310144];
Zipcodes['02648'] = [41.670375, -70.412035];
Zipcodes['02649'] = [41.617623, -70.492713];
Zipcodes['02650'] = [41.701947, -69.961453];
Zipcodes['02652'] = [42.049534, -70.094987];
Zipcodes['02653'] = [41.769125, -69.97358];
Zipcodes['02655'] = [41.627984, -70.392013];
Zipcodes['02657'] = [42.059829, -70.200407];
Zipcodes['02659'] = [41.681474, -70.023529];
Zipcodes['02660'] = [41.708526, -70.156582];
Zipcodes['02663'] = [41.89754, -69.969907];
Zipcodes['02664'] = [41.674776, -70.194279];
Zipcodes['02666'] = [41.985387, -70.037739];
Zipcodes['02667'] = [41.923389, -70.021044];
Zipcodes['02668'] = [41.714328, -70.356];
Zipcodes['02670'] = [41.660103, -70.170681];
Zipcodes['02671'] = [41.670336, -70.111068];
Zipcodes['02672'] = [41.6357, -70.313634];
Zipcodes['02673'] = [41.652948, -70.247383];
Zipcodes['02675'] = [41.70395, -70.231552];
Zipcodes['02702'] = [41.785113, -71.059047];
Zipcodes['02703'] = [41.931653, -71.294503];
Zipcodes['02713'] = [41.427083, -70.885662];
Zipcodes['02715'] = [41.805594, -71.157707];
Zipcodes['02717'] = [41.758629, -70.987625];
Zipcodes['02718'] = [41.862486, -71.01169];
Zipcodes['02719'] = [41.633667, -70.871362];
Zipcodes['02720'] = [41.727137, -71.109858];
Zipcodes['02721'] = [41.674429, -71.148532];
Zipcodes['02723'] = [41.692208, -71.132132];
Zipcodes['02724'] = [41.683068, -71.177888];
Zipcodes['02725'] = [41.720043, -71.188272];
Zipcodes['02726'] = [41.7597, -71.144592];
Zipcodes['02738'] = [41.706162, -70.750164];
Zipcodes['02739'] = [41.665644, -70.815876];
Zipcodes['02740'] = [41.638171, -70.938398];
Zipcodes['02743'] = [41.709599, -70.898111];
Zipcodes['02744'] = [41.606252, -70.913632];
Zipcodes['02745'] = [41.702696, -70.947057];
Zipcodes['02746'] = [41.663913, -70.94757];
Zipcodes['02747'] = [41.658, -71.01346];
Zipcodes['02748'] = [41.553084, -70.971453];
Zipcodes['02760'] = [41.972567, -71.334375];
Zipcodes['02762'] = [42.012838, -71.336188];
Zipcodes['02763'] = [41.966866, -71.308574];
Zipcodes['02764'] = [41.851929, -71.153598];
Zipcodes['02766'] = [41.965341, -71.18178];
Zipcodes['02767'] = [41.940996, -71.048499];
Zipcodes['02769'] = [41.846878, -71.24487];
Zipcodes['02770'] = [41.760864, -70.838956];
Zipcodes['02771'] = [41.839798, -71.318047];
Zipcodes['02777'] = [41.758163, -71.214181];
Zipcodes['02779'] = [41.838061, -71.077615];
Zipcodes['02780'] = [41.909112, -71.118346];
Zipcodes['02790'] = [41.599176, -71.082371];
Zipcodes['02791'] = [41.530554, -71.084996];
Zipcodes['02802'] = [41.95213, -71.456233];
Zipcodes['02804'] = [41.433033, -71.770061];
Zipcodes['02806'] = [41.733037, -71.319676];
Zipcodes['02807'] = [41.176783, -71.577054];
Zipcodes['02808'] = [41.410654, -71.73851];
Zipcodes['02809'] = [41.67573, -71.273331];
Zipcodes['02812'] = [41.477712, -71.658167];
Zipcodes['02813'] = [41.394216, -71.669822];
Zipcodes['02814'] = [41.895784, -71.705238];
Zipcodes['02815'] = [41.778591, -71.66239];
Zipcodes['02816'] = [41.696499, -71.622662];
Zipcodes['02817'] = [41.628605, -71.658874];
Zipcodes['02818'] = [41.642919, -71.485719];
Zipcodes['02822'] = [41.575313, -71.587124];
Zipcodes['02823'] = [41.733362, -71.543668];
Zipcodes['02824'] = [41.999441, -71.562125];
Zipcodes['02825'] = [41.782067, -71.726833];
Zipcodes['02826'] = [41.987043, -71.648018];
Zipcodes['02827'] = [41.698748, -71.739118];
Zipcodes['02828'] = [41.877692, -71.560911];
Zipcodes['02829'] = [41.893373, -71.589036];
Zipcodes['02830'] = [41.975622, -71.656388];
Zipcodes['02831'] = [41.746418, -71.584915];
Zipcodes['02832'] = [41.506767, -71.728006];
Zipcodes['02833'] = [41.485769, -71.785311];
Zipcodes['02835'] = [41.510088, -71.378366];
Zipcodes['02836'] = [41.454039, -71.620233];
Zipcodes['02837'] = [41.518769, -71.167247];
Zipcodes['02838'] = [41.965413, -71.479359];
Zipcodes['02839'] = [41.945128, -71.642556];
Zipcodes['02840'] = [41.476681, -71.319627];
Zipcodes['02841'] = [41.507459, -71.330024];
Zipcodes['02842'] = [41.517086, -71.273419];
Zipcodes['02852'] = [41.588565, -71.459811];
Zipcodes['02857'] = [41.822761, -71.633605];
Zipcodes['02858'] = [41.964154, -71.650109];
Zipcodes['02859'] = [41.959073, -71.757323];
Zipcodes['02860'] = [41.870562, -71.388681];
Zipcodes['02861'] = [41.878603, -71.353131];
Zipcodes['02863'] = [41.890064, -71.393481];
Zipcodes['02864'] = [41.96746, -71.420572];
Zipcodes['02865'] = [41.915455, -71.449815];
Zipcodes['02871'] = [41.586609, -71.259211];
Zipcodes['02872'] = [41.596965, -71.320932];
Zipcodes['02873'] = [41.533129, -71.780545];
Zipcodes['02874'] = [41.509056, -71.474417];
Zipcodes['02875'] = [41.45937, -71.637829];
Zipcodes['02876'] = [41.997213, -71.584405];
Zipcodes['02878'] = [41.606983, -71.182634];
Zipcodes['02879'] = [41.428836, -71.538376];
Zipcodes['02881'] = [41.482429, -71.52651];
Zipcodes['02882'] = [41.417088, -71.47227];
Zipcodes['02885'] = [41.726404, -71.259027];
Zipcodes['02886'] = [41.701615, -71.455217];
Zipcodes['02888'] = [41.748233, -71.407942];
Zipcodes['02889'] = [41.701286, -71.377971];
Zipcodes['02891'] = [41.357774, -71.789616];
Zipcodes['02892'] = [41.499451, -71.601574];
Zipcodes['02893'] = [41.679601, -71.521114];
Zipcodes['02894'] = [41.44425, -71.699304];
Zipcodes['02895'] = [42.001708, -71.499947];
Zipcodes['02896'] = [41.975186, -71.544069];
Zipcodes['02898'] = [41.51764, -71.668521];
Zipcodes['02903'] = [41.819233, -71.410245];
Zipcodes['02904'] = [41.858334, -71.436294];
Zipcodes['02905'] = [41.785273, -71.396227];
Zipcodes['02906'] = [41.839468, -71.389175];
Zipcodes['02907'] = [41.798593, -71.42463];
Zipcodes['02908'] = [41.839025, -71.43925];
Zipcodes['02909'] = [41.818122, -71.451776];
Zipcodes['02910'] = [41.774999, -71.435594];
Zipcodes['02911'] = [41.854888, -71.472812];
Zipcodes['02912'] = [41.825524, -71.403275];
Zipcodes['02914'] = [41.813577, -71.366468];
Zipcodes['02915'] = [41.772177, -71.354407];
Zipcodes['02916'] = [41.842661, -71.352438];
Zipcodes['02917'] = [41.906288, -71.523811];
Zipcodes['02918'] = [41.843839, -71.435018];
Zipcodes['02919'] = [41.82744, -71.519879];
Zipcodes['02920'] = [41.767344, -71.465508];
Zipcodes['02921'] = [41.762906, -71.514814];
Zipcodes['03031'] = [42.874824, -71.600533];
Zipcodes['03032'] = [42.993501, -71.363966];
Zipcodes['03033'] = [42.749263, -71.67567];
Zipcodes['03034'] = [43.056895, -71.340635];
Zipcodes['03036'] = [42.98453, -71.255104];
Zipcodes['03037'] = [43.136321, -71.255077];
Zipcodes['03038'] = [42.892761, -71.276763];
Zipcodes['03042'] = [43.051027, -71.088254];
Zipcodes['03043'] = [42.994135, -71.816098];
Zipcodes['03044'] = [43.000369, -71.119162];
Zipcodes['03045'] = [43.021515, -71.563462];
Zipcodes['03046'] = [43.112473, -71.607479];
Zipcodes['03047'] = [42.93874, -71.879424];
Zipcodes['03048'] = [42.742874, -71.761755];
Zipcodes['03049'] = [42.749624, -71.585402];
Zipcodes['03051'] = [42.760497, -71.409393];
Zipcodes['03052'] = [42.846081, -71.468283];
Zipcodes['03053'] = [42.868588, -71.387247];
Zipcodes['03054'] = [42.852034, -71.519876];
Zipcodes['03055'] = [42.818645, -71.673354];
Zipcodes['03057'] = [42.908885, -71.694199];
Zipcodes['03060'] = [42.741087, -71.458266];
Zipcodes['03062'] = [42.722323, -71.501176];
Zipcodes['03063'] = [42.7821, -71.518366];
Zipcodes['03064'] = [42.77938, -71.47492];
Zipcodes['03070'] = [42.976408, -71.686737];
Zipcodes['03071'] = [42.746257, -71.874434];
Zipcodes['03076'] = [42.730992, -71.337075];
Zipcodes['03077'] = [43.032583, -71.203144];
Zipcodes['03079'] = [42.788267, -71.221725];
Zipcodes['03082'] = [42.896409, -71.768331];
Zipcodes['03084'] = [42.826723, -71.875416];
Zipcodes['03086'] = [42.832231, -71.759615];
Zipcodes['03087'] = [42.811092, -71.302688];
Zipcodes['03101'] = [42.989027, -71.466111];
Zipcodes['03102'] = [43.011907, -71.491063];
Zipcodes['03103'] = [42.956116, -71.442385];
Zipcodes['03104'] = [43.009552, -71.439782];
Zipcodes['03106'] = [43.081242, -71.446616];
Zipcodes['03109'] = [42.963734, -71.40035];
Zipcodes['03110'] = [42.935584, -71.536871];
Zipcodes['03215'] = [44.069466, -71.420016];
Zipcodes['03216'] = [43.450724, -71.7906];
Zipcodes['03217'] = [43.725967, -71.642579];
Zipcodes['03218'] = [43.336884, -71.273836];
Zipcodes['03220'] = [43.474455, -71.482208];
Zipcodes['03221'] = [43.258129, -71.960236];
Zipcodes['03222'] = [43.643715, -71.799277];
Zipcodes['03223'] = [43.85544, -71.685492];
Zipcodes['03224'] = [43.350746, -71.547517];
Zipcodes['03225'] = [43.363816, -71.24173];
Zipcodes['03226'] = [43.702775, -71.510443];
Zipcodes['03227'] = [43.817193, -71.460578];
Zipcodes['03229'] = [43.202191, -71.696789];
Zipcodes['03230'] = [43.52812, -71.864467];
Zipcodes['03231'] = [43.450897, -71.754096];
Zipcodes['03233'] = [43.424004, -71.931192];
Zipcodes['03234'] = [43.204812, -71.348563];
Zipcodes['03235'] = [43.448973, -71.674166];
Zipcodes['03237'] = [43.431581, -71.387657];
Zipcodes['03238'] = [43.98164, -71.887014];
Zipcodes['03240'] = [43.574691, -71.951919];
Zipcodes['03241'] = [43.707854, -71.814686];
Zipcodes['03242'] = [43.169407, -71.831201];
Zipcodes['03243'] = [43.528306, -71.769192];
Zipcodes['03244'] = [43.119926, -71.921889];
Zipcodes['03245'] = [43.761761, -71.582529];
Zipcodes['03246'] = [43.576535, -71.482263];
Zipcodes['03249'] = [43.519499, -71.375838];
Zipcodes['03251'] = [44.094813, -71.631436];
Zipcodes['03253'] = [43.631211, -71.498585];
Zipcodes['03254'] = [43.71695, -71.365972];
Zipcodes['03255'] = [43.314026, -72.024551];
Zipcodes['03256'] = [43.623436, -71.632547];
Zipcodes['03257'] = [43.417721, -71.990609];
Zipcodes['03258'] = [43.265259, -71.408835];
Zipcodes['03259'] = [43.878172, -71.499151];
Zipcodes['03260'] = [43.368032, -71.917724];
Zipcodes['03261'] = [43.211856, -71.223393];
Zipcodes['03262'] = [43.999889, -71.714447];
Zipcodes['03263'] = [43.288501, -71.309368];
Zipcodes['03264'] = [43.738775, -71.702625];
Zipcodes['03266'] = [43.782873, -71.884739];
Zipcodes['03268'] = [43.386099, -71.772917];
Zipcodes['03269'] = [43.529459, -71.600507];
Zipcodes['03273'] = [43.301982, -71.926953];
Zipcodes['03275'] = [43.170019, -71.420707];
Zipcodes['03276'] = [43.431031, -71.575998];
Zipcodes['03278'] = [43.30893, -71.835506];
Zipcodes['03279'] = [43.965803, -71.859117];
Zipcodes['03280'] = [43.190051, -72.093871];
Zipcodes['03281'] = [43.078287, -71.703508];
Zipcodes['03282'] = [43.858721, -71.923718];
Zipcodes['03284'] = [43.472936, -72.013121];
Zipcodes['03285'] = [43.947877, -71.635785];
Zipcodes['03287'] = [43.443284, -71.922446];
Zipcodes['03290'] = [43.129847, -71.131859];
Zipcodes['03291'] = [43.179773, -71.14197];
Zipcodes['03293'] = [43.979296, -71.672741];
Zipcodes['03301'] = [43.238596, -71.556261];
Zipcodes['03303'] = [43.311717, -71.665381];
Zipcodes['03304'] = [43.133177, -71.537242];
Zipcodes['03307'] = [43.333988, -71.446658];
Zipcodes['03431'] = [42.969069, -72.301965];
Zipcodes['03440'] = [43.063603, -71.978209];
Zipcodes['03441'] = [42.780686, -72.447028];
Zipcodes['03442'] = [43.020284, -71.903705];
Zipcodes['03443'] = [42.869403, -72.466172];
Zipcodes['03444'] = [42.889279, -72.069849];
Zipcodes['03445'] = [43.020616, -72.214374];
Zipcodes['03446'] = [42.858271, -72.29636];
Zipcodes['03447'] = [42.753205, -72.153028];
Zipcodes['03448'] = [43.041227, -72.262369];
Zipcodes['03449'] = [42.983841, -72.001124];
Zipcodes['03450'] = [42.948536, -72.068986];
Zipcodes['03451'] = [42.809298, -72.504822];
Zipcodes['03452'] = [42.829254, -72.059901];
Zipcodes['03455'] = [42.901767, -72.172914];
Zipcodes['03456'] = [43.138544, -72.208769];
Zipcodes['03457'] = [42.999482, -72.121703];
Zipcodes['03458'] = [42.868369, -71.940102];
Zipcodes['03461'] = [42.756663, -72.013247];
Zipcodes['03462'] = [42.892609, -72.390167];
Zipcodes['03464'] = [43.080924, -72.11013];
Zipcodes['03465'] = [42.828798, -72.188943];
Zipcodes['03466'] = [42.881907, -72.508281];
Zipcodes['03467'] = [42.968075, -72.430856];
Zipcodes['03470'] = [42.771338, -72.354224];
Zipcodes['03561'] = [44.343351, -71.795118];
Zipcodes['03570'] = [44.511426, -71.197822];
Zipcodes['03574'] = [44.260435, -71.609203];
Zipcodes['03575'] = [44.313668, -71.402828];
Zipcodes['03576'] = [44.896332, -71.378109];
Zipcodes['03579'] = [44.936589, -71.04518];
Zipcodes['03580'] = [44.163446, -71.693295];
Zipcodes['03581'] = [44.367172, -71.087115];
Zipcodes['03582'] = [44.565047, -71.442984];
Zipcodes['03583'] = [44.4028, -71.456598];
Zipcodes['03584'] = [44.49028, -71.547516];
Zipcodes['03585'] = [44.225276, -71.90269];
Zipcodes['03586'] = [44.21841, -71.80024];
Zipcodes['03588'] = [44.607509, -71.237069];
Zipcodes['03590'] = [44.715053, -71.52754];
Zipcodes['03592'] = [45.116236, -71.26111];
Zipcodes['03593'] = [44.301396, -71.299834];
Zipcodes['03595'] = [44.305266, -71.497376];
Zipcodes['03597'] = [44.744941, -71.381331];
Zipcodes['03598'] = [44.378946, -71.628862];
Zipcodes['03601'] = [43.245774, -72.312958];
Zipcodes['03602'] = [43.140833, -72.347571];
Zipcodes['03603'] = [43.247906, -72.373709];
Zipcodes['03604'] = [43.132288, -72.389804];
Zipcodes['03605'] = [43.236564, -72.184869];
Zipcodes['03607'] = [43.19806, -72.262227];
Zipcodes['03608'] = [43.076442, -72.395967];
Zipcodes['03609'] = [43.143935, -72.446448];
Zipcodes['03740'] = [44.182955, -71.987011];
Zipcodes['03741'] = [43.676857, -72.007091];
Zipcodes['03743'] = [43.360727, -72.326929];
Zipcodes['03745'] = [43.472988, -72.311749];
Zipcodes['03748'] = [43.603314, -72.118598];
Zipcodes['03750'] = [43.721023, -72.185207];
Zipcodes['03751'] = [43.4413, -72.081635];
Zipcodes['03752'] = [43.272092, -72.101033];
Zipcodes['03753'] = [43.51659, -72.149093];
Zipcodes['03755'] = [43.732434, -72.139155];
Zipcodes['03765'] = [44.035225, -72.050198];
Zipcodes['03766'] = [43.632568, -72.234816];
Zipcodes['03768'] = [43.816634, -72.094295];
Zipcodes['03770'] = [43.531294, -72.258811];
Zipcodes['03771'] = [44.296605, -71.980684];
Zipcodes['03773'] = [43.371345, -72.202789];
Zipcodes['03774'] = [44.099753, -71.985475];
Zipcodes['03777'] = [43.883333, -72.033845];
Zipcodes['03779'] = [43.961851, -72.001293];
Zipcodes['03780'] = [44.041804, -71.990934];
Zipcodes['03781'] = [43.560058, -72.299348];
Zipcodes['03782'] = [43.390095, -72.093136];
Zipcodes['03784'] = [43.638325, -72.30649];
Zipcodes['03785'] = [44.035925, -71.846557];
Zipcodes['03801'] = [43.074812, -70.805443];
Zipcodes['03809'] = [43.46473, -71.200177];
Zipcodes['03810'] = [43.51256, -71.294893];
Zipcodes['03811'] = [42.839113, -71.167192];
Zipcodes['03812'] = [44.08113, -71.297237];
Zipcodes['03813'] = [44.127498, -71.060691];
Zipcodes['03814'] = [43.770937, -71.181505];
Zipcodes['03816'] = [43.698285, -71.243027];
Zipcodes['03817'] = [43.888984, -71.240584];
Zipcodes['03818'] = [43.95945, -71.274251];
Zipcodes['03819'] = [42.944016, -71.132306];
Zipcodes['03820'] = [43.18636, -70.883623];
Zipcodes['03823'] = [43.174607, -70.941453];
Zipcodes['03824'] = [43.117061, -70.918807];
Zipcodes['03825'] = [43.215566, -71.03898];
Zipcodes['03826'] = [42.88808, -71.129845];
Zipcodes['03827'] = [42.903677, -70.99555];
Zipcodes['03830'] = [43.614118, -70.993555];
Zipcodes['03832'] = [43.90696, -71.04752];
Zipcodes['03833'] = [42.97878, -70.97968];
Zipcodes['03835'] = [43.366924, -71.062503];
Zipcodes['03836'] = [43.842036, -71.075161];
Zipcodes['03837'] = [43.422251, -71.336087];
Zipcodes['03838'] = [44.110671, -71.223276];
Zipcodes['03839'] = [43.268772, -70.985508];
Zipcodes['03840'] = [43.039732, -70.845622];
Zipcodes['03841'] = [42.878739, -71.178272];
Zipcodes['03842'] = [42.939601, -70.836728];
Zipcodes['03844'] = [42.924811, -70.886242];
Zipcodes['03845'] = [44.105358, -71.115819];
Zipcodes['03846'] = [44.18762, -71.155763];
Zipcodes['03847'] = [44.07198, -71.123943];
Zipcodes['03848'] = [42.913731, -71.073595];
Zipcodes['03849'] = [43.89008, -71.132913];
Zipcodes['03850'] = [43.698034, -71.302598];
Zipcodes['03851'] = [43.437283, -71.025251];
Zipcodes['03852'] = [43.495003, -70.986034];
Zipcodes['03853'] = [43.638609, -71.29438];
Zipcodes['03854'] = [43.062147, -70.717071];
Zipcodes['03855'] = [43.463687, -71.15214];
Zipcodes['03856'] = [43.038509, -70.967954];
Zipcodes['03857'] = [43.070913, -70.946939];
Zipcodes['03858'] = [42.870369, -71.045915];
Zipcodes['03860'] = [44.042077, -71.122992];
Zipcodes['03861'] = [43.119202, -71.007082];
Zipcodes['03862'] = [42.979934, -70.829441];
Zipcodes['03864'] = [43.689932, -71.10344];
Zipcodes['03865'] = [42.845597, -71.09326];
Zipcodes['03867'] = [43.301679, -70.991769];
Zipcodes['03868'] = [43.324241, -70.940178];
Zipcodes['03869'] = [43.219815, -70.843107];
Zipcodes['03870'] = [43.019572, -70.760523];
Zipcodes['03871'] = [42.980948, -70.777362];
Zipcodes['03872'] = [43.570526, -71.043346];
Zipcodes['03873'] = [42.934018, -71.183605];
Zipcodes['03874'] = [42.883123, -70.860823];
Zipcodes['03875'] = [43.903632, -71.192066];
Zipcodes['03878'] = [43.253476, -70.887944];
Zipcodes['03882'] = [43.746733, -71.04191];
Zipcodes['03883'] = [43.808767, -71.320045];
Zipcodes['03884'] = [43.275384, -71.169956];
Zipcodes['03885'] = [43.014993, -70.902602];
Zipcodes['03886'] = [43.864038, -71.296068];
Zipcodes['03887'] = [43.483007, -71.070641];
Zipcodes['03890'] = [43.81501, -71.209375];
Zipcodes['03894'] = [43.610385, -71.177826];
Zipcodes['03897'] = [43.906901, -71.327623];
Zipcodes['03901'] = [43.29932, -70.842062];
Zipcodes['03902'] = [43.214916, -70.628806];
Zipcodes['03903'] = [43.146551, -70.774365];
Zipcodes['03904'] = [43.109963, -70.733833];
Zipcodes['03905'] = [43.004852, -70.604355];
Zipcodes['03906'] = [43.352283, -70.779445];
Zipcodes['03907'] = [43.256404, -70.612837];
Zipcodes['03908'] = [43.230612, -70.752424];
Zipcodes['03909'] = [43.16646, -70.679004];
Zipcodes['03910'] = [43.172952, -70.609686];
Zipcodes['03911'] = [43.135668, -70.637836];
Zipcodes['04001'] = [43.536651, -70.940212];
Zipcodes['04002'] = [43.489069, -70.661906];
Zipcodes['04003'] = [43.733675, -69.971397];
Zipcodes['04005'] = [43.492102, -70.486566];
Zipcodes['04006'] = [43.443994, -70.342944];
Zipcodes['04008'] = [44.044224, -69.863781];
Zipcodes['04009'] = [44.047513, -70.743319];
Zipcodes['04010'] = [43.930799, -70.918584];
Zipcodes['04011'] = [43.896431, -69.973487];
Zipcodes['04015'] = [43.951979, -70.513228];
Zipcodes['04017'] = [43.73532, -70.081008];
Zipcodes['04019'] = [43.69042, -70.110321];
Zipcodes['04020'] = [43.767186, -70.808863];
Zipcodes['04021'] = [43.791796, -70.264762];
Zipcodes['04022'] = [43.996209, -70.813737];
Zipcodes['04024'] = [43.83632, -70.685245];
Zipcodes['04027'] = [43.399909, -70.912785];
Zipcodes['04029'] = [43.887546, -70.655358];
Zipcodes['04030'] = [43.589061, -70.696276];
Zipcodes['04032'] = [43.844826, -70.089953];
Zipcodes['04037'] = [44.101677, -70.942769];
Zipcodes['04038'] = [43.701353, -70.462982];
Zipcodes['04039'] = [43.90492, -70.367727];
Zipcodes['04040'] = [44.142935, -70.824936];
Zipcodes['04041'] = [43.848997, -70.830764];
Zipcodes['04042'] = [43.631019, -70.614369];
Zipcodes['04043'] = [43.395947, -70.570221];
Zipcodes['04046'] = [43.429822, -70.474142];
Zipcodes['04047'] = [43.733357, -70.894042];
Zipcodes['04048'] = [43.688481, -70.781883];
Zipcodes['04049'] = [43.745798, -70.6998];
Zipcodes['04050'] = [43.687321, -70.153766];
Zipcodes['04051'] = [44.192471, -70.885803];
Zipcodes['04055'] = [43.975769, -70.639298];
Zipcodes['04056'] = [43.66059, -70.858688];
Zipcodes['04057'] = [44.0956, -70.701768];
Zipcodes['04061'] = [43.634661, -70.743574];
Zipcodes['04062'] = [43.794689, -70.405329];
Zipcodes['04063'] = [43.500389, -70.395326];
Zipcodes['04064'] = [43.52506, -70.388252];
Zipcodes['04066'] = [43.765658, -69.966888];
Zipcodes['04068'] = [43.837898, -70.946568];
Zipcodes['04069'] = [43.903914, -70.182018];
Zipcodes['04071'] = [43.891232, -70.497102];
Zipcodes['04072'] = [43.550411, -70.466341];
Zipcodes['04073'] = [43.411936, -70.737326];
Zipcodes['04074'] = [43.589627, -70.366191];
Zipcodes['04076'] = [43.554017, -70.832796];
Zipcodes['04079'] = [43.790756, -69.981037];
Zipcodes['04083'] = [43.464119, -70.814287];
Zipcodes['04084'] = [43.762147, -70.566686];
Zipcodes['04085'] = [43.77855, -70.627634];
Zipcodes['04086'] = [43.964977, -69.955624];
Zipcodes['04087'] = [43.56861, -70.744281];
Zipcodes['04088'] = [44.202838, -70.722272];
Zipcodes['04090'] = [43.331432, -70.645586];
Zipcodes['04091'] = [43.839321, -70.744838];
Zipcodes['04092'] = [43.70792, -70.352602];
Zipcodes['04093'] = [43.63626, -70.538842];
Zipcodes['04095'] = [43.62861, -70.913904];
Zipcodes['04096'] = [43.788137, -70.156478];
Zipcodes['04097'] = [43.845606, -70.241251];
Zipcodes['04101'] = [43.662607, -70.257949];
Zipcodes['04102'] = [43.654353, -70.297611];
Zipcodes['04103'] = [43.695422, -70.288652];
Zipcodes['04105'] = [43.740472, -70.277172];
Zipcodes['04106'] = [43.631402, -70.285989];
Zipcodes['04107'] = [43.587173, -70.236476];
Zipcodes['04108'] = [43.636765, -70.186808];
Zipcodes['04109'] = [43.663285, -70.222003];
Zipcodes['04110'] = [43.741369, -70.18137];
Zipcodes['04210'] = [44.084537, -70.249649];
Zipcodes['04216'] = [44.679659, -70.846517];
Zipcodes['04217'] = [44.393037, -70.837425];
Zipcodes['04219'] = [44.400747, -70.55811];
Zipcodes['04220'] = [44.34262, -70.34744];
Zipcodes['04221'] = [44.490587, -70.294547];
Zipcodes['04222'] = [43.956021, -70.132418];
Zipcodes['04224'] = [44.561357, -70.413727];
Zipcodes['04226'] = [44.625548, -70.708226];
Zipcodes['04227'] = [44.568652, -70.294791];
Zipcodes['04231'] = [44.263058, -70.875477];
Zipcodes['04234'] = [44.616266, -70.193834];
Zipcodes['04236'] = [44.189754, -70.145088];
Zipcodes['04237'] = [44.4945, -70.734421];
Zipcodes['04238'] = [44.190757, -70.384306];
Zipcodes['04239'] = [44.516897, -70.202187];
Zipcodes['04240'] = [44.089513, -70.172095];
Zipcodes['04250'] = [44.022179, -70.117387];
Zipcodes['04252'] = [44.036688, -70.058799];
Zipcodes['04253'] = [44.411638, -70.216186];
Zipcodes['04254'] = [44.445337, -70.138076];
Zipcodes['04255'] = [44.332714, -70.660557];
Zipcodes['04256'] = [44.104897, -70.40468];
Zipcodes['04257'] = [44.55316, -70.495015];
Zipcodes['04258'] = [44.13794, -70.336334];
Zipcodes['04259'] = [44.233505, -70.014723];
Zipcodes['04260'] = [43.957508, -70.296011];
Zipcodes['04261'] = [44.565568, -70.962661];
Zipcodes['04263'] = [44.295351, -70.133074];
Zipcodes['04265'] = [44.280563, -70.037314];
Zipcodes['04267'] = [44.224773, -70.770864];
Zipcodes['04268'] = [44.225867, -70.610021];
Zipcodes['04270'] = [44.117335, -70.527478];
Zipcodes['04271'] = [44.260967, -70.498732];
Zipcodes['04274'] = [44.048106, -70.393563];
Zipcodes['04275'] = [44.702596, -70.647585];
Zipcodes['04276'] = [44.55992, -70.625264];
Zipcodes['04280'] = [44.116831, -70.062582];
Zipcodes['04281'] = [44.248001, -70.490816];
Zipcodes['04282'] = [44.267103, -70.246528];
Zipcodes['04284'] = [44.339898, -70.047246];
Zipcodes['04285'] = [44.688598, -70.454056];
Zipcodes['04286'] = [44.403191, -70.860193];
Zipcodes['04287'] = [44.073433, -69.970748];
Zipcodes['04289'] = [44.319278, -70.522647];
Zipcodes['04290'] = [44.460915, -70.451923];
Zipcodes['04292'] = [44.380796, -70.448594];
Zipcodes['04294'] = [44.637914, -70.275434];
Zipcodes['04330'] = [44.35191, -69.747615];
Zipcodes['04341'] = [44.259968, -69.554864];
Zipcodes['04342'] = [44.086173, -69.736988];
Zipcodes['04344'] = [44.260548, -69.827805];
Zipcodes['04345'] = [44.195091, -69.793771];
Zipcodes['04346'] = [44.237286, -69.751247];
Zipcodes['04347'] = [44.287241, -69.81804];
Zipcodes['04348'] = [44.210864, -69.499152];
Zipcodes['04349'] = [44.434007, -70.067034];
Zipcodes['04350'] = [44.172142, -69.9345];
Zipcodes['04351'] = [44.342427, -69.861935];
Zipcodes['04352'] = [44.466488, -69.96045];
Zipcodes['04353'] = [44.198014, -69.619689];
Zipcodes['04354'] = [44.396114, -69.43063];
Zipcodes['04355'] = [44.384388, -69.9502];
Zipcodes['04357'] = [44.116833, -69.829607];
Zipcodes['04358'] = [44.414473, -69.535271];
Zipcodes['04359'] = [44.179541, -69.761284];
Zipcodes['04360'] = [44.554819, -69.999683];
Zipcodes['04363'] = [44.315485, -69.571186];
Zipcodes['04364'] = [44.309991, -69.963093];
Zipcodes['04401'] = [44.848517, -68.850405];
Zipcodes['04406'] = [45.229183, -69.596521];
Zipcodes['04408'] = [44.986935, -68.209958];
Zipcodes['04410'] = [45.08826, -68.906681];
Zipcodes['04411'] = [44.89448, -68.546752];
Zipcodes['04412'] = [44.784461, -68.734559];
Zipcodes['04413'] = [45.565868, -67.815407];
Zipcodes['04414'] = [45.412909, -69.089801];
Zipcodes['04415'] = [45.352922, -69.058231];
Zipcodes['04416'] = [44.623798, -68.749742];
Zipcodes['04417'] = [45.214319, -68.388895];
Zipcodes['04418'] = [45.116253, -68.50918];
Zipcodes['04419'] = [44.800454, -69.003117];
Zipcodes['04421'] = [44.409006, -68.815533];
Zipcodes['04422'] = [45.077652, -69.031432];
Zipcodes['04424'] = [45.669238, -67.862669];
Zipcodes['04426'] = [45.186261, -69.145532];
Zipcodes['04427'] = [44.980473, -69.010748];
Zipcodes['04428'] = [44.8058, -68.554003];
Zipcodes['04429'] = [44.721239, -68.617075];
Zipcodes['04430'] = [45.65364, -68.59734];
Zipcodes['04431'] = [44.564528, -68.667723];
Zipcodes['04434'] = [44.763866, -69.128068];
Zipcodes['04435'] = [44.963225, -69.125005];
Zipcodes['04438'] = [44.598927, -68.92833];
Zipcodes['04441'] = [45.687909, -69.308516];
Zipcodes['04442'] = [45.473244, -69.696479];
Zipcodes['04443'] = [45.238115, -69.401935];
Zipcodes['04444'] = [44.72898, -68.949964];
Zipcodes['04448'] = [45.315895, -68.739564];
Zipcodes['04449'] = [44.992636, -68.888475];
Zipcodes['04450'] = [44.915921, -68.927942];
Zipcodes['04451'] = [45.57744, -68.211789];
Zipcodes['04453'] = [45.191408, -68.787687];
Zipcodes['04454'] = [45.515606, -67.533996];
Zipcodes['04455'] = [45.299422, -68.259742];
Zipcodes['04456'] = [44.876816, -68.996651];
Zipcodes['04457'] = [45.445526, -68.534454];
Zipcodes['04459'] = [45.588735, -68.339985];
Zipcodes['04460'] = [45.640874, -68.524835];
Zipcodes['04461'] = [44.993378, -68.575945];
Zipcodes['04462'] = [45.995839, -68.928348];
Zipcodes['04463'] = [45.286832, -68.895109];
Zipcodes['04464'] = [45.297218, -69.499149];
Zipcodes['04468'] = [45.028344, -68.719249];
Zipcodes['04469'] = [44.902505, -68.666208];
Zipcodes['04471'] = [45.916545, -67.856824];
Zipcodes['04472'] = [44.57632, -68.669915];
Zipcodes['04473'] = [44.88168, -68.738642];
Zipcodes['04474'] = [44.688778, -68.762805];
Zipcodes['04475'] = [45.192986, -68.594777];
Zipcodes['04476'] = [44.482703, -68.705997];
Zipcodes['04478'] = [45.728188, -69.768032];
Zipcodes['04479'] = [45.124208, -69.287058];
Zipcodes['04481'] = [45.242742, -69.10464];
Zipcodes['04485'] = [45.34797, -69.60251];
Zipcodes['04487'] = [45.387737, -68.104471];
Zipcodes['04488'] = [44.87164, -69.113926];
Zipcodes['04489'] = [44.91075, -68.691209];
Zipcodes['04490'] = [45.394222, -67.828259];
Zipcodes['04491'] = [45.562301, -67.521056];
Zipcodes['04492'] = [45.336791, -67.66879];
Zipcodes['04493'] = [45.278143, -68.535627];
Zipcodes['04495'] = [45.477433, -68.33898];
Zipcodes['04496'] = [44.650751, -68.908918];
Zipcodes['04497'] = [45.726568, -68.058358];
Zipcodes['04530'] = [43.887982, -69.826723];
Zipcodes['04535'] = [44.098468, -69.629176];
Zipcodes['04537'] = [43.902263, -69.627303];
Zipcodes['04538'] = [43.847464, -69.629626];
Zipcodes['04539'] = [43.973542, -69.501972];
Zipcodes['04541'] = [43.886691, -69.484076];
Zipcodes['04543'] = [44.022351, -69.490756];
Zipcodes['04544'] = [43.81989, -69.591972];
Zipcodes['04547'] = [43.978605, -69.332584];
Zipcodes['04548'] = [43.833341, -69.749066];
Zipcodes['04551'] = [43.984533, -69.435489];
Zipcodes['04553'] = [44.052277, -69.567522];
Zipcodes['04554'] = [43.862733, -69.512324];
Zipcodes['04555'] = [44.101248, -69.482658];
Zipcodes['04556'] = [43.975989, -69.610952];
Zipcodes['04558'] = [43.888194, -69.519131];
Zipcodes['04562'] = [43.770829, -69.819164];
Zipcodes['04563'] = [44.013922, -69.258706];
Zipcodes['04564'] = [43.928794, -69.469747];
Zipcodes['04568'] = [43.886152, -69.558041];
Zipcodes['04570'] = [43.808546, -69.630765];
Zipcodes['04571'] = [43.896656, -69.67417];
Zipcodes['04572'] = [44.122085, -69.383353];
Zipcodes['04573'] = [43.951357, -69.55233];
Zipcodes['04574'] = [44.260639, -69.391524];
Zipcodes['04575'] = [43.85215, -69.662201];
Zipcodes['04576'] = [43.813339, -69.662868];
Zipcodes['04578'] = [43.982445, -69.692754];
Zipcodes['04579'] = [43.959656, -69.769351];
Zipcodes['04605'] = [44.685803, -68.394798];
Zipcodes['04606'] = [44.540726, -67.712364];
Zipcodes['04607'] = [44.467048, -68.086866];
Zipcodes['04609'] = [44.39747, -68.263808];
Zipcodes['04611'] = [44.463625, -67.622031];
Zipcodes['04612'] = [44.261725, -68.381404];
Zipcodes['04613'] = [44.368835, -68.027614];
Zipcodes['04614'] = [44.400549, -68.520047];
Zipcodes['04616'] = [44.275705, -68.534676];
Zipcodes['04617'] = [44.365013, -68.72709];
Zipcodes['04619'] = [45.142952, -67.217529];
Zipcodes['04622'] = [44.751766, -67.964777];
Zipcodes['04623'] = [44.699244, -67.713619];
Zipcodes['04624'] = [44.423951, -67.985876];
Zipcodes['04625'] = [44.250726, -68.209723];
Zipcodes['04626'] = [44.669023, -67.22199];
Zipcodes['04627'] = [44.230534, -68.791797];
Zipcodes['04628'] = [44.87399, -67.274401];
Zipcodes['04630'] = [44.866496, -67.474781];
Zipcodes['04631'] = [44.910939, -67.009163];
Zipcodes['04634'] = [44.619991, -68.220437];
Zipcodes['04635'] = [44.112753, -68.357532];
Zipcodes['04640'] = [44.524572, -68.28525];
Zipcodes['04642'] = [44.330584, -68.809947];
Zipcodes['04643'] = [44.539862, -67.826998];
Zipcodes['04645'] = [44.064664, -68.62081];
Zipcodes['04646'] = [44.259993, -68.228607];
Zipcodes['04648'] = [44.654537, -67.631543];
Zipcodes['04649'] = [44.559882, -67.504242];
Zipcodes['04650'] = [44.296718, -68.715318];
Zipcodes['04652'] = [44.819713, -67.062328];
Zipcodes['04653'] = [44.201439, -68.327441];
Zipcodes['04654'] = [44.689769, -67.500124];
Zipcodes['04655'] = [44.655762, -67.378971];
Zipcodes['04657'] = [44.982106, -67.443459];
Zipcodes['04658'] = [44.507249, -67.877817];
Zipcodes['04660'] = [44.335753, -68.345729];
Zipcodes['04662'] = [44.306926, -68.287236];
Zipcodes['04664'] = [44.543669, -68.135604];
Zipcodes['04666'] = [44.961077, -67.220627];
Zipcodes['04667'] = [44.983542, -67.101382];
Zipcodes['04668'] = [45.195566, -67.694117];
Zipcodes['04669'] = [44.411663, -67.998309];
Zipcodes['04671'] = [45.067341, -67.149953];
Zipcodes['04673'] = [44.296423, -68.667657];
Zipcodes['04674'] = [44.257024, -68.458219];
Zipcodes['04675'] = [44.300258, -68.245826];
Zipcodes['04676'] = [44.34688, -68.634365];
Zipcodes['04677'] = [44.472889, -68.180863];
Zipcodes['04679'] = [44.2797, -68.318243];
Zipcodes['04680'] = [44.478414, -67.947062];
Zipcodes['04681'] = [44.150464, -68.645327];
Zipcodes['04683'] = [44.235863, -68.823941];
Zipcodes['04684'] = [44.467462, -68.507329];
Zipcodes['04685'] = [44.157979, -68.441976];
Zipcodes['04686'] = [44.996537, -67.694027];
Zipcodes['04691'] = [44.760322, -67.255047];
Zipcodes['04693'] = [44.376372, -68.093638];
Zipcodes['04694'] = [45.115083, -67.477541];
Zipcodes['04730'] = [46.191023, -67.860968];
Zipcodes['04732'] = [46.571018, -68.491485];
Zipcodes['04733'] = [45.743133, -68.410914];
Zipcodes['04734'] = [46.485401, -67.922885];
Zipcodes['04735'] = [46.378842, -68.026941];
Zipcodes['04736'] = [46.911495, -68.029189];
Zipcodes['04737'] = [46.616369, -69.521899];
Zipcodes['04739'] = [46.977255, -68.599017];
Zipcodes['04740'] = [46.633304, -67.847623];
Zipcodes['04742'] = [46.776241, -67.859197];
Zipcodes['04743'] = [47.160789, -68.731119];
Zipcodes['04745'] = [47.274118, -68.39823];
Zipcodes['04746'] = [47.251015, -68.142962];
Zipcodes['04747'] = [45.967691, -68.208404];
Zipcodes['04750'] = [46.954967, -67.857437];
Zipcodes['04756'] = [47.321708, -68.298213];
Zipcodes['04757'] = [46.572049, -68.199139];
Zipcodes['04758'] = [46.559272, -67.853225];
Zipcodes['04760'] = [46.333559, -67.91755];
Zipcodes['04761'] = [46.117239, -67.975572];
Zipcodes['04762'] = [46.976391, -68.114962];
Zipcodes['04763'] = [46.07385, -68.113532];
Zipcodes['04764'] = [46.366887, -68.34101];
Zipcodes['04765'] = [46.077239, -68.671353];
Zipcodes['04766'] = [46.892463, -68.21578];
Zipcodes['04768'] = [46.796816, -69.054521];
Zipcodes['04769'] = [46.688952, -67.991914];
Zipcodes['04772'] = [47.235732, -68.317305];
Zipcodes['04773'] = [47.267434, -68.223039];
Zipcodes['04774'] = [47.124038, -69.014592];
Zipcodes['04776'] = [45.818415, -68.311139];
Zipcodes['04777'] = [45.844459, -68.472574];
Zipcodes['04779'] = [47.116172, -68.307655];
Zipcodes['04780'] = [46.170677, -68.267045];
Zipcodes['04781'] = [47.150199, -68.613495];
Zipcodes['04783'] = [47.035682, -68.187133];
Zipcodes['04785'] = [47.120057, -68.035813];
Zipcodes['04786'] = [46.794921, -68.192051];
Zipcodes['04787'] = [46.533465, -67.966076];
Zipcodes['04841'] = [44.134088, -69.132629];
Zipcodes['04843'] = [44.238406, -69.064039];
Zipcodes['04847'] = [44.241178, -69.188513];
Zipcodes['04848'] = [44.298888, -68.915242];
Zipcodes['04849'] = [44.316402, -69.026412];
Zipcodes['04851'] = [43.859145, -68.879348];
Zipcodes['04852'] = [43.767426, -69.310059];
Zipcodes['04853'] = [44.136346, -68.866756];
Zipcodes['04854'] = [44.046257, -69.092537];
Zipcodes['04855'] = [43.931932, -69.287741];
Zipcodes['04856'] = [44.180604, -69.122109];
Zipcodes['04858'] = [44.046511, -69.154059];
Zipcodes['04859'] = [43.996564, -69.159454];
Zipcodes['04860'] = [43.944324, -69.243196];
Zipcodes['04861'] = [44.107714, -69.171425];
Zipcodes['04862'] = [44.262706, -69.277723];
Zipcodes['04863'] = [44.068815, -68.853314];
Zipcodes['04864'] = [44.12801, -69.243885];
Zipcodes['04901'] = [44.541078, -69.573081];
Zipcodes['04910'] = [44.508426, -69.445189];
Zipcodes['04911'] = [44.779702, -69.970678];
Zipcodes['04912'] = [44.96526, -69.666935];
Zipcodes['04915'] = [44.463502, -69.037571];
Zipcodes['04917'] = [44.487731, -69.838639];
Zipcodes['04918'] = [44.55034, -69.866468];
Zipcodes['04920'] = [45.105646, -69.805224];
Zipcodes['04921'] = [44.581023, -69.143576];
Zipcodes['04922'] = [44.673999, -69.38646];
Zipcodes['04923'] = [45.044494, -69.449898];
Zipcodes['04924'] = [44.782176, -69.546316];
Zipcodes['04925'] = [45.212387, -69.903429];
Zipcodes['04926'] = [44.480319, -69.515208];
Zipcodes['04927'] = [44.665474, -69.540766];
Zipcodes['04928'] = [44.946982, -69.258861];
Zipcodes['04929'] = [44.773091, -69.301537];
Zipcodes['04930'] = [45.028226, -69.326494];
Zipcodes['04932'] = [44.697571, -69.143703];
Zipcodes['04936'] = [45.355011, -70.65743];
Zipcodes['04937'] = [44.651655, -69.661275];
Zipcodes['04938'] = [44.661856, -70.103104];
Zipcodes['04939'] = [45.077258, -69.158771];
Zipcodes['04940'] = [44.617983, -70.078802];
Zipcodes['04941'] = [44.470053, -69.284182];
Zipcodes['04942'] = [45.042514, -69.593256];
Zipcodes['04943'] = [44.879823, -69.5205];
Zipcodes['04944'] = [44.686891, -69.643928];
Zipcodes['04945'] = [45.576628, -70.277907];
Zipcodes['04947'] = [45.046803, -70.269258];
Zipcodes['04949'] = [44.369093, -69.336];
Zipcodes['04950'] = [44.826354, -69.799713];
Zipcodes['04951'] = [44.59806, -69.058489];
Zipcodes['04952'] = [44.412796, -69.154791];
Zipcodes['04953'] = [44.867214, -69.218836];
Zipcodes['04955'] = [44.647207, -69.993337];
Zipcodes['04956'] = [44.798568, -70.096757];
Zipcodes['04957'] = [44.714264, -69.863539];
Zipcodes['04958'] = [44.909057, -69.927233];
Zipcodes['04961'] = [45.151993, -70.160662];
Zipcodes['04963'] = [44.589005, -69.887304];
Zipcodes['04964'] = [44.94362, -70.826469];
Zipcodes['04965'] = [44.843151, -69.368356];
Zipcodes['04966'] = [44.785649, -70.535355];
Zipcodes['04967'] = [44.769837, -69.433753];
Zipcodes['04969'] = [44.785282, -69.236277];
Zipcodes['04970'] = [45.007913, -70.618162];
Zipcodes['04971'] = [44.938018, -69.397198];
Zipcodes['04973'] = [44.368252, -69.186292];
Zipcodes['04974'] = [44.470906, -68.928662];
Zipcodes['04975'] = [44.625378, -69.588327];
Zipcodes['04976'] = [44.789271, -69.667734];
Zipcodes['04978'] = [44.641819, -69.79989];
Zipcodes['04979'] = [44.93938, -69.808142];
Zipcodes['04981'] = [44.514019, -68.83905];
Zipcodes['04982'] = [45.107462, -70.393952];
Zipcodes['04983'] = [44.925633, -70.286451];
Zipcodes['04984'] = [44.696586, -70.267203];
Zipcodes['04985'] = [45.377118, -69.898984];
Zipcodes['04986'] = [44.54946, -69.211292];
Zipcodes['04987'] = [44.674148, -69.251844];
Zipcodes['04988'] = [44.570148, -69.362441];
Zipcodes['04989'] = [44.429335, -69.648294];
Zipcodes['04992'] = [44.659798, -70.156068];
Zipcodes['05001'] = [43.683726, -72.376002];
Zipcodes['05009'] = [43.647933, -72.343999];
Zipcodes['05030'] = [43.39914, -72.408007];
Zipcodes['05031'] = [43.721064, -72.58047];
Zipcodes['05032'] = [43.801549, -72.657747];
Zipcodes['05033'] = [43.996652, -72.175828];
Zipcodes['05034'] = [43.591594, -72.619742];
Zipcodes['05035'] = [43.611552, -72.686042];
Zipcodes['05036'] = [44.023908, -72.587891];
Zipcodes['05037'] = [43.457608, -72.484184];
Zipcodes['05038'] = [43.991158, -72.457292];
Zipcodes['05039'] = [44.063272, -72.313076];
Zipcodes['05040'] = [44.069889, -72.198637];
Zipcodes['05041'] = [43.956573, -72.538613];
Zipcodes['05042'] = [44.244001, -72.110803];
Zipcodes['05043'] = [43.805933, -72.212129];
Zipcodes['05045'] = [43.91798, -72.180212];
Zipcodes['05046'] = [44.232453, -72.257516];
Zipcodes['05048'] = [43.580615, -72.427711];
Zipcodes['05050'] = [44.264058, -72.063843];
Zipcodes['05051'] = [44.081968, -72.116868];
Zipcodes['05052'] = [43.596986, -72.359722];
Zipcodes['05053'] = [43.713657, -72.48491];
Zipcodes['05055'] = [43.777633, -72.324007];
Zipcodes['05056'] = [43.508847, -72.693634];
Zipcodes['05058'] = [43.881581, -72.272307];
Zipcodes['05059'] = [43.638175, -72.430399];
Zipcodes['05060'] = [43.967072, -72.708604];
Zipcodes['05061'] = [43.928435, -72.573175];
Zipcodes['05062'] = [43.503098, -72.579519];
Zipcodes['05065'] = [43.790882, -72.416502];
Zipcodes['05067'] = [43.684641, -72.540071];
Zipcodes['05068'] = [43.780804, -72.548104];
Zipcodes['05069'] = [44.169638, -72.142554];
Zipcodes['05070'] = [43.851602, -72.343286];
Zipcodes['05071'] = [43.563714, -72.56482];
Zipcodes['05072'] = [43.883435, -72.386962];
Zipcodes['05073'] = [43.628412, -72.461574];
Zipcodes['05075'] = [43.845413, -72.286056];
Zipcodes['05076'] = [44.144967, -72.223957];
Zipcodes['05077'] = [43.908796, -72.459306];
Zipcodes['05079'] = [43.956765, -72.326539];
Zipcodes['05081'] = [44.137207, -72.090153];
Zipcodes['05083'] = [43.918433, -72.267806];
Zipcodes['05084'] = [43.729655, -72.451522];
Zipcodes['05086'] = [44.108949, -72.30073];
Zipcodes['05088'] = [43.675387, -72.30674];
Zipcodes['05089'] = [43.495601, -72.442254];
Zipcodes['05091'] = [43.648253, -72.55055];
Zipcodes['05101'] = [43.161506, -72.485605];
Zipcodes['05141'] = [43.158836, -72.560237];
Zipcodes['05142'] = [43.39758, -72.580345];
Zipcodes['05143'] = [43.252694, -72.643102];
Zipcodes['05146'] = [43.184942, -72.633977];
Zipcodes['05148'] = [43.244927, -72.81059];
Zipcodes['05149'] = [43.401856, -72.699211];
Zipcodes['05150'] = [43.33715, -72.529077];
Zipcodes['05151'] = [43.395317, -72.496973];
Zipcodes['05152'] = [43.232886, -72.937383];
Zipcodes['05153'] = [43.439639, -72.636542];
Zipcodes['05154'] = [43.133846, -72.5286];
Zipcodes['05155'] = [43.175084, -72.823352];
Zipcodes['05156'] = [43.310627, -72.461623];
Zipcodes['05158'] = [43.109004, -72.473476];
Zipcodes['05161'] = [43.321441, -72.802903];
Zipcodes['05201'] = [42.869216, -73.128532];
Zipcodes['05250'] = [43.103623, -73.173204];
Zipcodes['05251'] = [43.265371, -73.081661];
Zipcodes['05252'] = [43.081946, -73.066773];
Zipcodes['05253'] = [43.261773, -72.997972];
Zipcodes['05254'] = [43.160037, -73.067098];
Zipcodes['05255'] = [43.137766, -73.023673];
Zipcodes['05257'] = [42.969542, -73.26273];
Zipcodes['05260'] = [42.811616, -73.26265];
Zipcodes['05261'] = [42.768893, -73.211264];
Zipcodes['05262'] = [42.973719, -73.075343];
Zipcodes['05301'] = [42.838685, -72.659985];
Zipcodes['05340'] = [43.16888, -72.972731];
Zipcodes['05341'] = [42.974544, -72.801051];
Zipcodes['05342'] = [42.777497, -72.794821];
Zipcodes['05343'] = [43.102673, -72.820311];
Zipcodes['05344'] = [42.838938, -72.732683];
Zipcodes['05345'] = [42.987484, -72.664676];
Zipcodes['05346'] = [43.03772, -72.536529];
Zipcodes['05350'] = [42.790943, -72.97264];
Zipcodes['05351'] = [42.930206, -72.726973];
Zipcodes['05352'] = [42.80825, -73.076331];
Zipcodes['05353'] = [43.069273, -72.68046];
Zipcodes['05354'] = [42.764133, -72.522086];
Zipcodes['05355'] = [43.019478, -72.812148];
Zipcodes['05356'] = [42.975906, -72.864609];
Zipcodes['05358'] = [42.776387, -72.729629];
Zipcodes['05359'] = [43.150712, -72.724798];
Zipcodes['05360'] = [43.04185, -72.950605];
Zipcodes['05361'] = [42.787251, -72.87783];
Zipcodes['05362'] = [42.935221, -72.660424];
Zipcodes['05363'] = [42.884428, -72.900891];
Zipcodes['05401'] = [44.475181, -73.217857];
Zipcodes['05403'] = [44.444866, -73.173468];
Zipcodes['05404'] = [44.495587, -73.184857];
Zipcodes['05405'] = [44.474157, -73.196366];
Zipcodes['05408'] = [44.505238, -73.272419];
Zipcodes['05439'] = [44.495652, -73.165216];
Zipcodes['05440'] = [44.957882, -73.291974];
Zipcodes['05441'] = [44.773789, -72.787344];
Zipcodes['05442'] = [44.772519, -72.690391];
Zipcodes['05443'] = [44.139548, -73.039822];
Zipcodes['05444'] = [44.620499, -72.887348];
Zipcodes['05445'] = [44.305737, -73.235553];
Zipcodes['05446'] = [44.554142, -73.225492];
Zipcodes['05447'] = [44.94219, -72.703176];
Zipcodes['05448'] = [44.757432, -72.87831];
Zipcodes['05450'] = [44.894578, -72.795246];
Zipcodes['05452'] = [44.527171, -73.064704];
Zipcodes['05454'] = [44.710289, -73.028877];
Zipcodes['05455'] = [44.801687, -72.967393];
Zipcodes['05456'] = [44.21389, -73.262094];
Zipcodes['05457'] = [44.958722, -72.921995];
Zipcodes['05458'] = [44.716287, -73.299627];
Zipcodes['05459'] = [44.956529, -73.0172];
Zipcodes['05461'] = [44.322332, -73.094511];
Zipcodes['05462'] = [44.299412, -72.954889];
Zipcodes['05463'] = [44.871325, -73.3492];
Zipcodes['05464'] = [44.677814, -72.814778];
Zipcodes['05465'] = [44.466436, -72.919828];
Zipcodes['05468'] = [44.645908, -73.159221];
Zipcodes['05471'] = [44.828013, -72.577719];
Zipcodes['05472'] = [44.112977, -73.158362];
Zipcodes['05473'] = [44.241845, -73.19471];
Zipcodes['05474'] = [44.835081, -73.225658];
Zipcodes['05476'] = [44.955228, -72.643138];
Zipcodes['05477'] = [44.381972, -72.979626];
Zipcodes['05478'] = [44.791567, -73.117763];
Zipcodes['05481'] = [44.808335, -73.139602];
Zipcodes['05482'] = [44.392674, -73.243434];
Zipcodes['05483'] = [44.88972, -72.980935];
Zipcodes['05485'] = [44.904048, -72.97568];
Zipcodes['05486'] = [44.627092, -73.309226];
Zipcodes['05487'] = [44.210332, -72.993373];
Zipcodes['05488'] = [44.923364, -73.144286];
Zipcodes['05489'] = [44.567695, -72.902763];
Zipcodes['05491'] = [44.119779, -73.326924];
Zipcodes['05492'] = [44.737917, -72.762322];
Zipcodes['05494'] = [44.603324, -73.015994];
Zipcodes['05495'] = [44.429166, -73.09631];
Zipcodes['05602'] = [44.29264, -72.624688];
Zipcodes['05640'] = [44.340334, -72.497461];
Zipcodes['05641'] = [44.18548, -72.453593];
Zipcodes['05647'] = [44.409107, -72.293692];
Zipcodes['05648'] = [44.383292, -72.492477];
Zipcodes['05649'] = [44.138925, -72.370594];
Zipcodes['05650'] = [44.400382, -72.438165];
Zipcodes['05651'] = [44.282307, -72.489262];
Zipcodes['05652'] = [44.723893, -72.610411];
Zipcodes['05653'] = [44.714927, -72.472701];
Zipcodes['05654'] = [44.138351, -72.486668];
Zipcodes['05655'] = [44.624262, -72.570417];
Zipcodes['05656'] = [44.648124, -72.68884];
Zipcodes['05658'] = [44.364975, -72.354905];
Zipcodes['05660'] = [44.261643, -72.765399];
Zipcodes['05661'] = [44.532336, -72.620258];
Zipcodes['05663'] = [44.147616, -72.691229];
Zipcodes['05664'] = [44.167124, -72.653179];
Zipcodes['05666'] = [44.338194, -72.438856];
Zipcodes['05667'] = [44.278653, -72.402403];
Zipcodes['05669'] = [44.053298, -72.755586];
Zipcodes['05670'] = [44.177237, -72.506059];
Zipcodes['05672'] = [44.491806, -72.712611];
Zipcodes['05673'] = [44.199052, -72.840932];
Zipcodes['05674'] = [44.112526, -72.87403];
Zipcodes['05675'] = [44.076165, -72.422633];
Zipcodes['05676'] = [44.299184, -72.847232];
Zipcodes['05677'] = [44.401904, -72.698172];
Zipcodes['05678'] = [44.157314, -72.477642];
Zipcodes['05679'] = [44.108944, -72.531912];
Zipcodes['05680'] = [44.541146, -72.472944];
Zipcodes['05681'] = [44.458085, -72.379129];
Zipcodes['05682'] = [44.393305, -72.56349];
Zipcodes['05701'] = [43.624582, -72.910192];
Zipcodes['05730'] = [43.38845, -72.785262];
Zipcodes['05731'] = [43.752116, -73.294096];
Zipcodes['05732'] = [43.646595, -73.21144];
Zipcodes['05733'] = [43.810474, -73.09028];
Zipcodes['05734'] = [43.981724, -73.331937];
Zipcodes['05735'] = [43.652757, -73.161222];
Zipcodes['05736'] = [43.608152, -73.014368];
Zipcodes['05737'] = [43.777668, -72.89685];
Zipcodes['05738'] = [43.523441, -72.860171];
Zipcodes['05739'] = [43.344936, -73.028738];
Zipcodes['05742'] = [43.420081, -72.910801];
Zipcodes['05743'] = [43.6776, -73.304843];
Zipcodes['05744'] = [43.694013, -73.080457];
Zipcodes['05746'] = [43.718925, -72.790075];
Zipcodes['05747'] = [44.003669, -72.858203];
Zipcodes['05748'] = [43.905404, -72.918236];
Zipcodes['05751'] = [43.657594, -72.784406];
Zipcodes['05753'] = [43.997783, -73.178295];
Zipcodes['05757'] = [43.482727, -73.124025];
Zipcodes['05758'] = [43.451487, -72.793361];
Zipcodes['05759'] = [43.53211, -72.9648];
Zipcodes['05760'] = [43.800615, -73.289701];
Zipcodes['05761'] = [43.334617, -73.13932];
Zipcodes['05762'] = [43.80423, -72.836897];
Zipcodes['05763'] = [43.730162, -72.995256];
Zipcodes['05764'] = [43.528324, -73.194344];
Zipcodes['05765'] = [43.648333, -73.034445];
Zipcodes['05766'] = [43.984937, -72.996775];
Zipcodes['05767'] = [43.879408, -72.831685];
Zipcodes['05768'] = [43.296861, -73.219222];
Zipcodes['05769'] = [43.932071, -73.119822];
Zipcodes['05770'] = [43.890204, -73.329851];
Zipcodes['05772'] = [43.76435, -72.739204];
Zipcodes['05773'] = [43.427094, -72.949941];
Zipcodes['05774'] = [43.428719, -73.170257];
Zipcodes['05775'] = [43.354273, -73.222094];
Zipcodes['05776'] = [43.252854, -73.197348];
Zipcodes['05777'] = [43.556432, -73.048858];
Zipcodes['05778'] = [43.876397, -73.212573];
Zipcodes['05819'] = [44.404723, -71.983074];
Zipcodes['05820'] = [44.763154, -72.375244];
Zipcodes['05821'] = [44.318773, -72.108914];
Zipcodes['05822'] = [44.754263, -72.15968];
Zipcodes['05824'] = [44.439085, -71.826104];
Zipcodes['05825'] = [44.858403, -72.25285];
Zipcodes['05826'] = [44.634876, -72.406149];
Zipcodes['05827'] = [44.676706, -72.362574];
Zipcodes['05828'] = [44.437504, -72.124822];
Zipcodes['05829'] = [44.967287, -72.068061];
Zipcodes['05830'] = [44.9721, -71.986969];
Zipcodes['05832'] = [44.594146, -71.898695];
Zipcodes['05833'] = [44.837389, -71.977498];
Zipcodes['05836'] = [44.518599, -72.230774];
Zipcodes['05837'] = [44.663758, -71.80518];
Zipcodes['05839'] = [44.673683, -72.207014];
Zipcodes['05840'] = [44.598729, -71.747173];
Zipcodes['05841'] = [44.617792, -72.289292];
Zipcodes['05842'] = [44.590054, -72.245075];
Zipcodes['05843'] = [44.503373, -72.341593];
Zipcodes['05845'] = [44.800059, -72.331931];
Zipcodes['05846'] = [44.816691, -71.835318];
Zipcodes['05847'] = [44.793437, -72.458451];
Zipcodes['05850'] = [44.563804, -72.035658];
Zipcodes['05851'] = [44.546295, -72.048869];
Zipcodes['05853'] = [44.896128, -71.941243];
Zipcodes['05855'] = [44.927008, -72.19244];
Zipcodes['05857'] = [44.928911, -72.3127];
Zipcodes['05858'] = [44.538162, -71.800799];
Zipcodes['05859'] = [44.95827, -72.438189];
Zipcodes['05860'] = [44.80266, -72.103996];
Zipcodes['05862'] = [44.318185, -72.216116];
Zipcodes['05866'] = [44.640739, -72.110885];
Zipcodes['05867'] = [44.665071, -72.035973];
Zipcodes['05868'] = [44.942293, -72.354214];
Zipcodes['05871'] = [44.682332, -71.952739];
Zipcodes['05872'] = [44.870982, -72.025024];
Zipcodes['05873'] = [44.405064, -72.216725];
Zipcodes['05874'] = [44.883477, -72.484927];
Zipcodes['05875'] = [44.705368, -72.270894];
Zipcodes['05901'] = [44.940719, -71.654624];
Zipcodes['05902'] = [45.009587, -71.487927];
Zipcodes['05903'] = [44.93502, -71.588126];
Zipcodes['05904'] = [44.416182, -71.706874];
Zipcodes['05905'] = [44.702779, -71.677771];
Zipcodes['05906'] = [44.483097, -71.720449];
Zipcodes['05907'] = [44.954734, -71.82734];
Zipcodes['06001'] = [41.79404, -72.85398];
Zipcodes['06002'] = [41.846675, -72.736981];
Zipcodes['06010'] = [41.681578, -72.940749];
Zipcodes['06013'] = [41.749079, -72.948657];
Zipcodes['06016'] = [41.901899, -72.547432];
Zipcodes['06018'] = [42.036731, -73.311594];
Zipcodes['06019'] = [41.857509, -72.902766];
Zipcodes['06020'] = [41.862788, -72.909934];
Zipcodes['06021'] = [42.005797, -73.064184];
Zipcodes['06022'] = [41.865935, -72.927452];
Zipcodes['06023'] = [41.612592, -72.720093];
Zipcodes['06024'] = [42.008206, -73.283732];
Zipcodes['06026'] = [41.929719, -72.745378];
Zipcodes['06027'] = [42.00493, -72.9157];
Zipcodes['06029'] = [41.914149, -72.445568];
Zipcodes['06031'] = [41.955257, -73.316785];
Zipcodes['06032'] = [41.731051, -72.857096];
Zipcodes['06033'] = [41.707463, -72.538932];
Zipcodes['06035'] = [41.960563, -72.804593];
Zipcodes['06037'] = [41.605451, -72.778497];
Zipcodes['06039'] = [41.961998, -73.444375];
Zipcodes['06040'] = [41.761415, -72.525608];
Zipcodes['06042'] = [41.802645, -72.521039];
Zipcodes['06043'] = [41.764589, -72.437575];
Zipcodes['06051'] = [41.665179, -72.769865];
Zipcodes['06052'] = [41.655606, -72.803134];
Zipcodes['06053'] = [41.690145, -72.79194];
Zipcodes['06057'] = [41.841053, -72.998664];
Zipcodes['06058'] = [41.969773, -73.178439];
Zipcodes['06059'] = [41.89053, -72.888298];
Zipcodes['06060'] = [42.014741, -72.85926];
Zipcodes['06061'] = [41.875207, -72.967042];
Zipcodes['06062'] = [41.673639, -72.859729];
Zipcodes['06063'] = [41.935708, -72.972921];
Zipcodes['06065'] = [41.974681, -73.007663];
Zipcodes['06066'] = [41.837561, -72.45904];
Zipcodes['06067'] = [41.656624, -72.661779];
Zipcodes['06068'] = [42.007238, -73.422228];
Zipcodes['06069'] = [41.860763, -73.449215];
Zipcodes['06070'] = [41.874682, -72.813825];
Zipcodes['06071'] = [41.992633, -72.453725];
Zipcodes['06072'] = [41.984664, -72.487589];
Zipcodes['06073'] = [41.660572, -72.568477];
Zipcodes['06074'] = [41.83809, -72.577836];
Zipcodes['06076'] = [41.984442, -72.263453];
Zipcodes['06078'] = [41.991585, -72.651762];
Zipcodes['06079'] = [42.041768, -73.397479];
Zipcodes['06080'] = [41.950485, -72.635178];
Zipcodes['06081'] = [41.907559, -72.76818];
Zipcodes['06082'] = [41.983993, -72.555553];
Zipcodes['06084'] = [41.883019, -72.358869];
Zipcodes['06085'] = [41.7489, -72.889435];
Zipcodes['06088'] = [41.917911, -72.583371];
Zipcodes['06089'] = [41.835348, -72.824272];
Zipcodes['06090'] = [41.946863, -72.864044];
Zipcodes['06091'] = [42.026452, -72.980213];
Zipcodes['06092'] = [41.873452, -72.860524];
Zipcodes['06093'] = [42.013241, -72.717313];
Zipcodes['06095'] = [41.873832, -72.676805];
Zipcodes['06096'] = [41.92779, -72.651616];
Zipcodes['06098'] = [41.927213, -73.109336];
Zipcodes['06103'] = [41.765103, -72.670756];
Zipcodes['06105'] = [41.773262, -72.704433];
Zipcodes['06106'] = [41.748587, -72.696006];
Zipcodes['06107'] = [41.75202, -72.759365];
Zipcodes['06108'] = [41.780375, -72.623945];
Zipcodes['06109'] = [41.702484, -72.669301];
Zipcodes['06110'] = [41.732455, -72.73375];
Zipcodes['06111'] = [41.686764, -72.730593];
Zipcodes['06112'] = [41.793336, -72.695823];
Zipcodes['06114'] = [41.740854, -72.670695];
Zipcodes['06117'] = [41.789101, -72.763127];
Zipcodes['06118'] = [41.748711, -72.609519];
Zipcodes['06119'] = [41.763521, -72.727113];
Zipcodes['06120'] = [41.787198, -72.664837];
Zipcodes['06226'] = [41.708239, -72.208548];
Zipcodes['06231'] = [41.632936, -72.367347];
Zipcodes['06232'] = [41.732982, -72.374658];
Zipcodes['06234'] = [41.793886, -71.953265];
Zipcodes['06235'] = [41.79089, -72.12954];
Zipcodes['06237'] = [41.693707, -72.305671];
Zipcodes['06238'] = [41.780212, -72.343758];
Zipcodes['06239'] = [41.79404, -71.854535];
Zipcodes['06241'] = [41.853901, -71.847371];
Zipcodes['06242'] = [41.89559, -72.093304];
Zipcodes['06243'] = [41.84487, -71.805029];
Zipcodes['06247'] = [41.767801, -72.075026];
Zipcodes['06248'] = [41.688585, -72.408671];
Zipcodes['06249'] = [41.632955, -72.24009];
Zipcodes['06250'] = [41.772955, -72.198446];
Zipcodes['06254'] = [41.621212, -72.142609];
Zipcodes['06255'] = [41.991087, -71.901952];
Zipcodes['06256'] = [41.732152, -72.157361];
Zipcodes['06258'] = [41.88566, -71.963527];
Zipcodes['06259'] = [41.871502, -71.987646];
Zipcodes['06260'] = [41.908027, -71.870452];
Zipcodes['06262'] = [42.019311, -71.948384];
Zipcodes['06263'] = [41.837285, -71.904243];
Zipcodes['06264'] = [41.698634, -72.104795];
Zipcodes['06266'] = [41.673661, -72.172813];
Zipcodes['06268'] = [41.798793, -72.248368];
Zipcodes['06269'] = [41.808938, -72.251617];
Zipcodes['06277'] = [41.958388, -71.84392];
Zipcodes['06278'] = [41.897123, -72.171427];
Zipcodes['06279'] = [41.897267, -72.251848];
Zipcodes['06280'] = [41.690986, -72.130094];
Zipcodes['06281'] = [41.973476, -72.014137];
Zipcodes['06282'] = [41.94887, -72.08482];
Zipcodes['06320'] = [41.348837, -72.102029];
Zipcodes['06330'] = [41.63557, -72.077527];
Zipcodes['06331'] = [41.69396, -72.009118];
Zipcodes['06332'] = [41.722809, -71.902416];
Zipcodes['06333'] = [41.386032, -72.229092];
Zipcodes['06334'] = [41.544925, -72.173849];
Zipcodes['06335'] = [41.434707, -72.058533];
Zipcodes['06336'] = [41.580577, -72.195867];
Zipcodes['06338'] = [41.464584, -71.972828];
Zipcodes['06339'] = [41.441939, -71.990545];
Zipcodes['06340'] = [41.354409, -72.040266];
Zipcodes['06350'] = [41.648256, -72.069227];
Zipcodes['06351'] = [41.589788, -71.948385];
Zipcodes['06353'] = [41.455998, -72.138986];
Zipcodes['06354'] = [41.708766, -71.855386];
Zipcodes['06355'] = [41.365673, -71.97196];
Zipcodes['06357'] = [41.317659, -72.21856];
Zipcodes['06359'] = [41.470969, -71.872525];
Zipcodes['06360'] = [41.548006, -72.089454];
Zipcodes['06365'] = [41.518652, -72.006597];
Zipcodes['06370'] = [41.467094, -72.189397];
Zipcodes['06371'] = [41.362386, -72.323754];
Zipcodes['06373'] = [41.685382, -71.805355];
Zipcodes['06374'] = [41.679654, -71.912168];
Zipcodes['06375'] = [41.406338, -72.123236];
Zipcodes['06376'] = [41.294494, -72.252858];
Zipcodes['06377'] = [41.730545, -71.819313];
Zipcodes['06378'] = [41.383211, -71.908408];
Zipcodes['06379'] = [41.363472, -71.853197];
Zipcodes['06380'] = [41.563417, -72.051631];
Zipcodes['06382'] = [41.462643, -72.124869];
Zipcodes['06384'] = [41.579192, -71.831228];
Zipcodes['06385'] = [41.36173, -72.156576];
Zipcodes['06387'] = [41.744107, -71.909865];
Zipcodes['06389'] = [41.560396, -72.123465];
Zipcodes['06390'] = [41.287945, -71.932551];
Zipcodes['06401'] = [41.344249, -73.069825];
Zipcodes['06403'] = [41.443685, -73.051925];
Zipcodes['06405'] = [41.279949, -72.78595];
Zipcodes['06409'] = [41.348139, -72.42936];
Zipcodes['06410'] = [41.511827, -72.903617];
Zipcodes['06412'] = [41.411728, -72.487146];
Zipcodes['06413'] = [41.29561, -72.529125];
Zipcodes['06414'] = [41.577044, -72.552334];
Zipcodes['06415'] = [41.550291, -72.348616];
Zipcodes['06416'] = [41.616609, -72.66156];
Zipcodes['06417'] = [41.367819, -72.477442];
Zipcodes['06418'] = [41.326124, -73.082574];
Zipcodes['06419'] = [41.374419, -72.578328];
Zipcodes['06420'] = [41.489351, -72.25838];
Zipcodes['06422'] = [41.468922, -72.684525];
Zipcodes['06423'] = [41.456609, -72.391351];
Zipcodes['06424'] = [41.560158, -72.502765];
Zipcodes['06426'] = [41.351136, -72.397164];
Zipcodes['06437'] = [41.331777, -72.696816];
Zipcodes['06438'] = [41.450777, -72.509946];
Zipcodes['06439'] = [41.41568, -72.399699];
Zipcodes['06441'] = [41.465625, -72.582638];
Zipcodes['06442'] = [41.342069, -72.43407];
Zipcodes['06443'] = [41.344479, -72.624543];
Zipcodes['06444'] = [41.563395, -72.930341];
Zipcodes['06447'] = [41.636439, -72.454002];
Zipcodes['06450'] = [41.535812, -72.775686];
Zipcodes['06451'] = [41.541906, -72.823409];
Zipcodes['06455'] = [41.514163, -72.717844];
Zipcodes['06456'] = [41.539735, -72.548288];
Zipcodes['06457'] = [41.544209, -72.657501];
Zipcodes['06459'] = [41.549892, -72.661536];
Zipcodes['06460'] = [41.218799, -73.045626];
Zipcodes['06461'] = [41.239865, -73.075394];
Zipcodes['06467'] = [41.565463, -72.900239];
Zipcodes['06468'] = [41.339236, -73.222828];
Zipcodes['06469'] = [41.506893, -72.448279];
Zipcodes['06470'] = [41.395083, -73.317663];
Zipcodes['06471'] = [41.333791, -72.771853];
Zipcodes['06472'] = [41.393435, -72.771944];
Zipcodes['06473'] = [41.381465, -72.856371];
Zipcodes['06475'] = [41.299897, -72.382734];
Zipcodes['06477'] = [41.284951, -73.024637];
Zipcodes['06478'] = [41.444033, -73.148235];
Zipcodes['06479'] = [41.57473, -72.911945];
Zipcodes['06480'] = [41.598834, -72.589071];
Zipcodes['06481'] = [41.536737, -72.697938];
Zipcodes['06482'] = [41.409, -73.24263];
Zipcodes['06483'] = [41.385466, -73.083641];
Zipcodes['06484'] = [41.304577, -73.139215];
Zipcodes['06488'] = [41.475986, -73.229692];
Zipcodes['06489'] = [41.614168, -72.869915];
Zipcodes['06492'] = [41.462725, -72.805069];
Zipcodes['06498'] = [41.303695, -72.478017];
Zipcodes['06510'] = [41.306193, -72.926613];
Zipcodes['06511'] = [41.31727, -72.926373];
Zipcodes['06512'] = [41.278267, -72.875251];
Zipcodes['06513'] = [41.318943, -72.869021];
Zipcodes['06514'] = [41.376803, -72.942752];
Zipcodes['06515'] = [41.328039, -72.97084];
Zipcodes['06516'] = [41.272573, -72.964967];
Zipcodes['06517'] = [41.34944, -72.907149];
Zipcodes['06518'] = [41.430879, -72.91199];
Zipcodes['06519'] = [41.293122, -72.932102];
Zipcodes['06524'] = [41.422964, -72.994187];
Zipcodes['06525'] = [41.363536, -73.003891];
Zipcodes['06604'] = [41.15438, -73.210932];
Zipcodes['06605'] = [41.161599, -73.217622];
Zipcodes['06606'] = [41.21211, -73.206673];
Zipcodes['06607'] = [41.170744, -73.168038];
Zipcodes['06608'] = [41.186475, -73.181251];
Zipcodes['06610'] = [41.209221, -73.164656];
Zipcodes['06611'] = [41.272891, -73.211899];
Zipcodes['06612'] = [41.264945, -73.300479];
Zipcodes['06614'] = [41.228477, -73.129081];
Zipcodes['06615'] = [41.163027, -73.128108];
Zipcodes['06702'] = [41.556461, -73.045886];
Zipcodes['06704'] = [41.588739, -73.035267];
Zipcodes['06705'] = [41.549741, -72.993435];
Zipcodes['06706'] = [41.532209, -73.025052];
Zipcodes['06708'] = [41.550619, -73.071234];
Zipcodes['06710'] = [41.569601, -73.04596];
Zipcodes['06712'] = [41.499247, -72.975634];
Zipcodes['06716'] = [41.59503, -72.968514];
Zipcodes['06750'] = [41.723861, -73.26286];
Zipcodes['06751'] = [41.635759, -73.211422];
Zipcodes['06752'] = [41.520294, -73.361093];
Zipcodes['06753'] = [41.825193, -73.344628];
Zipcodes['06754'] = [41.754627, -73.368216];
Zipcodes['06755'] = [41.649317, -73.480094];
Zipcodes['06756'] = [41.847077, -73.237799];
Zipcodes['06757'] = [41.739665, -73.467273];
Zipcodes['06758'] = [41.676348, -73.227358];
Zipcodes['06759'] = [41.760643, -73.219153];
Zipcodes['06762'] = [41.524691, -73.123016];
Zipcodes['06763'] = [41.694323, -73.209998];
Zipcodes['06770'] = [41.488649, -73.051751];
Zipcodes['06776'] = [41.601267, -73.422807];
Zipcodes['06777'] = [41.691307, -73.334403];
Zipcodes['06778'] = [41.718478, -73.104452];
Zipcodes['06779'] = [41.596057, -73.081442];
Zipcodes['06782'] = [41.642653, -73.041508];
Zipcodes['06783'] = [41.555532, -73.296026];
Zipcodes['06784'] = [41.579418, -73.49769];
Zipcodes['06785'] = [41.708536, -73.449546];
Zipcodes['06786'] = [41.672727, -73.018042];
Zipcodes['06787'] = [41.67108, -73.085046];
Zipcodes['06790'] = [41.8342, -73.13147];
Zipcodes['06791'] = [41.761345, -73.060443];
Zipcodes['06793'] = [41.633712, -73.288916];
Zipcodes['06794'] = [41.649315, -73.32513];
Zipcodes['06795'] = [41.623892, -73.126195];
Zipcodes['06796'] = [41.873953, -73.333633];
Zipcodes['06798'] = [41.561632, -73.206096];
Zipcodes['06801'] = [41.369778, -73.389503];
Zipcodes['06804'] = [41.463218, -73.393311];
Zipcodes['06807'] = [41.056732, -73.592023];
Zipcodes['06810'] = [41.377849, -73.457463];
Zipcodes['06811'] = [41.422581, -73.478352];
Zipcodes['06812'] = [41.480318, -73.486863];
Zipcodes['06820'] = [41.076202, -73.48008];
Zipcodes['06824'] = [41.173039, -73.280818];
Zipcodes['06825'] = [41.196583, -73.243254];
Zipcodes['06830'] = [41.037603, -73.624339];
Zipcodes['06831'] = [41.088724, -73.658838];
Zipcodes['06840'] = [41.160393, -73.500124];
Zipcodes['06850'] = [41.127423, -73.445264];
Zipcodes['06851'] = [41.138233, -73.401526];
Zipcodes['06853'] = [41.066116, -73.438002];
Zipcodes['06854'] = [41.090896, -73.428788];
Zipcodes['06855'] = [41.07174, -73.390002];
Zipcodes['06870'] = [41.024446, -73.57115];
Zipcodes['06877'] = [41.317675, -73.499837];
Zipcodes['06878'] = [41.030093, -73.583719];
Zipcodes['06880'] = [41.142752, -73.343614];
Zipcodes['06883'] = [41.227413, -73.367061];
Zipcodes['06890'] = [41.148565, -73.28778];
Zipcodes['06896'] = [41.305415, -73.392584];
Zipcodes['06897'] = [41.211277, -73.440977];
Zipcodes['06901'] = [41.053635, -73.538437];
Zipcodes['06902'] = [41.059347, -73.544384];
Zipcodes['06903'] = [41.136018, -73.571142];
Zipcodes['06905'] = [41.087781, -73.543575];
Zipcodes['06906'] = [41.072342, -73.522189];
Zipcodes['06907'] = [41.101431, -73.519581];
Zipcodes['07001'] = [40.583961, -74.269704];
Zipcodes['07002'] = [40.662495, -74.110201];
Zipcodes['07003'] = [40.809141, -74.187142];
Zipcodes['07004'] = [40.882508, -74.304593];
Zipcodes['07005'] = [40.93035, -74.411123];
Zipcodes['07006'] = [40.851187, -74.282865];
Zipcodes['07008'] = [40.58379, -74.227457];
Zipcodes['07009'] = [40.858017, -74.229784];
Zipcodes['07010'] = [40.822052, -73.987938];
Zipcodes['07011'] = [40.880427, -74.143246];
Zipcodes['07012'] = [40.846899, -74.160537];
Zipcodes['07013'] = [40.869405, -74.173062];
Zipcodes['07014'] = [40.831339, -74.135419];
Zipcodes['07016'] = [40.65755, -74.303632];
Zipcodes['07017'] = [40.771918, -74.207082];
Zipcodes['07018'] = [40.755733, -74.217917];
Zipcodes['07020'] = [40.82465, -73.973793];
Zipcodes['07021'] = [40.828102, -74.276226];
Zipcodes['07022'] = [40.82111, -74.003032];
Zipcodes['07023'] = [40.641701, -74.385692];
Zipcodes['07024'] = [40.85064, -73.971007];
Zipcodes['07026'] = [40.879797, -74.10825];
Zipcodes['07027'] = [40.651331, -74.323152];
Zipcodes['07028'] = [40.804798, -74.204569];
Zipcodes['07029'] = [40.74362, -74.154901];
Zipcodes['07030'] = [40.745251, -74.027926];
Zipcodes['07031'] = [40.786256, -74.12622];
Zipcodes['07032'] = [40.752332, -74.123093];
Zipcodes['07033'] = [40.678089, -74.288114];
Zipcodes['07034'] = [40.87948, -74.380104];
Zipcodes['07035'] = [40.927501, -74.308334];
Zipcodes['07036'] = [40.624711, -74.239362];
Zipcodes['07039'] = [40.785828, -74.3291];
Zipcodes['07040'] = [40.733607, -74.271159];
Zipcodes['07041'] = [40.722448, -74.301546];
Zipcodes['07042'] = [40.813487, -74.219011];
Zipcodes['07043'] = [40.844837, -74.200502];
Zipcodes['07044'] = [40.832468, -74.242863];
Zipcodes['07045'] = [40.906942, -74.365608];
Zipcodes['07046'] = [40.890853, -74.442032];
Zipcodes['07047'] = [40.793644, -74.025027];
Zipcodes['07050'] = [40.76804, -74.235692];
Zipcodes['07052'] = [40.785755, -74.265058];
Zipcodes['07054'] = [40.853767, -74.401574];
Zipcodes['07055'] = [40.857552, -74.129089];
Zipcodes['07057'] = [40.853084, -74.106323];
Zipcodes['07058'] = [40.869901, -74.344241];
Zipcodes['07059'] = [40.634588, -74.519044];
Zipcodes['07060'] = [40.616667, -74.422042];
Zipcodes['07062'] = [40.631253, -74.403884];
Zipcodes['07063'] = [40.605118, -74.444522];
Zipcodes['07064'] = [40.567422, -74.244933];
Zipcodes['07065'] = [40.607152, -74.280531];
Zipcodes['07066'] = [40.62065, -74.309862];
Zipcodes['07067'] = [40.589329, -74.311415];
Zipcodes['07068'] = [40.821737, -74.310147];
Zipcodes['07069'] = [40.642845, -74.436233];
Zipcodes['07070'] = [40.820314, -74.106041];
Zipcodes['07071'] = [40.797113, -74.11298];
Zipcodes['07072'] = [40.826431, -74.062335];
Zipcodes['07073'] = [40.817097, -74.085024];
Zipcodes['07074'] = [40.838144, -74.055077];
Zipcodes['07075'] = [40.850183, -74.087068];
Zipcodes['07076'] = [40.633029, -74.372899];
Zipcodes['07077'] = [40.554147, -74.25306];
Zipcodes['07078'] = [40.742264, -74.327574];
Zipcodes['07079'] = [40.748811, -74.261512];
Zipcodes['07080'] = [40.574413, -74.4148];
Zipcodes['07081'] = [40.697966, -74.334436];
Zipcodes['07082'] = [40.926724, -74.345512];
Zipcodes['07083'] = [40.695266, -74.269078];
Zipcodes['07086'] = [40.76801, -74.017008];
Zipcodes['07087'] = [40.767224, -74.032429];
Zipcodes['07088'] = [40.718342, -74.283764];
Zipcodes['07090'] = [40.651644, -74.343447];
Zipcodes['07092'] = [40.680722, -74.360292];
Zipcodes['07093'] = [40.786704, -74.008909];
Zipcodes['07094'] = [40.781958, -74.067649];
Zipcodes['07095'] = [40.552857, -74.286939];
Zipcodes['07102'] = [40.735659, -74.173605];
Zipcodes['07103'] = [40.738852, -74.195264];
Zipcodes['07104'] = [40.767713, -74.16835];
Zipcodes['07105'] = [40.723066, -74.138636];
Zipcodes['07106'] = [40.741768, -74.230452];
Zipcodes['07107'] = [40.762918, -74.186559];
Zipcodes['07108'] = [40.723104, -74.200386];
Zipcodes['07109'] = [40.793481, -74.161159];
Zipcodes['07110'] = [40.820616, -74.15625];
Zipcodes['07111'] = [40.723859, -74.232522];
Zipcodes['07112'] = [40.711391, -74.211683];
Zipcodes['07114'] = [40.697812, -74.17081];
Zipcodes['07201'] = [40.671272, -74.177358];
Zipcodes['07202'] = [40.652464, -74.216853];
Zipcodes['07203'] = [40.652211, -74.260158];
Zipcodes['07204'] = [40.665312, -74.266555];
Zipcodes['07205'] = [40.695889, -74.2288];
Zipcodes['07206'] = [40.652083, -74.183756];
Zipcodes['07208'] = [40.672913, -74.224047];
Zipcodes['07302'] = [40.718808, -74.044634];
Zipcodes['07304'] = [40.717303, -74.076352];
Zipcodes['07305'] = [40.693004, -74.06965];
Zipcodes['07306'] = [40.736294, -74.071128];
Zipcodes['07307'] = [40.751842, -74.053976];
Zipcodes['07310'] = [40.72847, -74.033684];
Zipcodes['07311'] = [40.719076, -74.032619];
Zipcodes['07401'] = [41.032669, -74.133826];
Zipcodes['07403'] = [41.020753, -74.332842];
Zipcodes['07405'] = [40.987841, -74.378188];
Zipcodes['07407'] = [40.904526, -74.119514];
Zipcodes['07410'] = [40.93583, -74.117505];
Zipcodes['07416'] = [41.108997, -74.588641];
Zipcodes['07417'] = [41.007526, -74.205653];
Zipcodes['07418'] = [41.242216, -74.486108];
Zipcodes['07419'] = [41.154156, -74.57001];
Zipcodes['07420'] = [41.029333, -74.302841];
Zipcodes['07421'] = [41.17374, -74.352613];
Zipcodes['07422'] = [41.173751, -74.446963];
Zipcodes['07423'] = [40.9995, -74.096583];
Zipcodes['07424'] = [40.882319, -74.205876];
Zipcodes['07430'] = [41.082067, -74.183061];
Zipcodes['07432'] = [40.995809, -74.141262];
Zipcodes['07435'] = [41.040778, -74.44191];
Zipcodes['07436'] = [41.031022, -74.240502];
Zipcodes['07438'] = [41.029821, -74.521772];
Zipcodes['07439'] = [41.08009, -74.597626];
Zipcodes['07440'] = [40.946468, -74.29304];
Zipcodes['07442'] = [41.002734, -74.286742];
Zipcodes['07444'] = [40.967407, -74.306967];
Zipcodes['07446'] = [41.059136, -74.145931];
Zipcodes['07450'] = [40.981591, -74.113506];
Zipcodes['07452'] = [40.959471, -74.125202];
Zipcodes['07456'] = [41.097039, -74.262921];
Zipcodes['07457'] = [40.995886, -74.314532];
Zipcodes['07458'] = [41.046658, -74.096482];
Zipcodes['07460'] = [41.114264, -74.493844];
Zipcodes['07461'] = [41.247022, -74.610522];
Zipcodes['07462'] = [41.198457, -74.495575];
Zipcodes['07463'] = [41.013367, -74.125217];
Zipcodes['07465'] = [41.04927, -74.292337];
Zipcodes['07470'] = [40.945855, -74.245077];
Zipcodes['07480'] = [41.08587, -74.37839];
Zipcodes['07481'] = [40.999093, -74.168849];
Zipcodes['07501'] = [40.911998, -74.170965];
Zipcodes['07502'] = [40.919659, -74.195028];
Zipcodes['07503'] = [40.897548, -74.154121];
Zipcodes['07504'] = [40.912433, -74.140689];
Zipcodes['07505'] = [40.917008, -74.171936];
Zipcodes['07506'] = [40.956957, -74.158561];
Zipcodes['07508'] = [40.955021, -74.183692];
Zipcodes['07512'] = [40.903415, -74.219779];
Zipcodes['07513'] = [40.904592, -74.148966];
Zipcodes['07514'] = [40.924786, -74.144542];
Zipcodes['07522'] = [40.923569, -74.179225];
Zipcodes['07524'] = [40.931711, -74.15687];
Zipcodes['07601'] = [40.889398, -74.045698];
Zipcodes['07603'] = [40.874293, -74.029737];
Zipcodes['07604'] = [40.861513, -74.073114];
Zipcodes['07605'] = [40.863391, -73.988466];
Zipcodes['07606'] = [40.864801, -74.048115];
Zipcodes['07607'] = [40.902885, -74.063457];
Zipcodes['07608'] = [40.849609, -74.061893];
Zipcodes['07620'] = [40.968782, -73.916896];
Zipcodes['07621'] = [40.922334, -73.998001];
Zipcodes['07624'] = [40.97289, -73.960315];
Zipcodes['07626'] = [40.940018, -73.958313];
Zipcodes['07627'] = [40.954103, -73.954921];
Zipcodes['07628'] = [40.945239, -73.992428];
Zipcodes['07630'] = [40.97499, -74.023248];
Zipcodes['07631'] = [40.891197, -73.972515];
Zipcodes['07632'] = [40.884634, -73.944654];
Zipcodes['07640'] = [40.991681, -73.980202];
Zipcodes['07641'] = [40.962737, -73.99747];
Zipcodes['07642'] = [41.007127, -74.045119];
Zipcodes['07643'] = [40.843431, -74.034239];
Zipcodes['07644'] = [40.877915, -74.0825];
Zipcodes['07645'] = [41.054594, -74.047298];
Zipcodes['07646'] = [40.934161, -74.019453];
Zipcodes['07647'] = [41.009421, -73.941984];
Zipcodes['07648'] = [40.992046, -73.950551];
Zipcodes['07649'] = [40.956653, -74.032862];
Zipcodes['07650'] = [40.847017, -73.997061];
Zipcodes['07652'] = [40.947299, -74.070169];
Zipcodes['07656'] = [41.036183, -74.044293];
Zipcodes['07657'] = [40.832677, -74.014088];
Zipcodes['07660'] = [40.854705, -74.019926];
Zipcodes['07661'] = [40.927354, -74.039611];
Zipcodes['07662'] = [40.906496, -74.078005];
Zipcodes['07663'] = [40.903099, -74.093591];
Zipcodes['07666'] = [40.890317, -74.011478];
Zipcodes['07670'] = [40.918309, -73.950523];
Zipcodes['07675'] = [41.003484, -74.001753];
Zipcodes['07676'] = [40.988306, -74.064693];
Zipcodes['07677'] = [41.025977, -74.061061];
Zipcodes['07701'] = [40.360509, -74.078952];
Zipcodes['07702'] = [40.32645, -74.057373];
Zipcodes['07703'] = [40.314341, -74.046746];
Zipcodes['07704'] = [40.36194, -74.038775];
Zipcodes['07711'] = [40.237603, -74.006722];
Zipcodes['07712'] = [40.246895, -74.054502];
Zipcodes['07716'] = [40.402402, -74.036306];
Zipcodes['07717'] = [40.191418, -74.015105];
Zipcodes['07718'] = [40.419871, -74.085174];
Zipcodes['07719'] = [40.168908, -74.073226];
Zipcodes['07720'] = [40.201604, -74.012056];
Zipcodes['07721'] = [40.435334, -74.235966];
Zipcodes['07722'] = [40.287799, -74.166807];
Zipcodes['07723'] = [40.250486, -74.002164];
Zipcodes['07724'] = [40.298625, -74.074178];
Zipcodes['07726'] = [40.286506, -74.342547];
Zipcodes['07727'] = [40.205491, -74.157535];
Zipcodes['07728'] = [40.225793, -74.285785];
Zipcodes['07730'] = [40.424164, -74.173732];
Zipcodes['07731'] = [40.149537, -74.203987];
Zipcodes['07732'] = [40.430451, -73.990424];
Zipcodes['07733'] = [40.374964, -74.173849];
Zipcodes['07734'] = [40.444152, -74.136812];
Zipcodes['07735'] = [40.450022, -74.2235];
Zipcodes['07737'] = [40.406738, -74.063828];
Zipcodes['07738'] = [40.335361, -74.132723];
Zipcodes['07739'] = [40.336952, -74.034535];
Zipcodes['07740'] = [40.295372, -73.989899];
Zipcodes['07746'] = [40.32349, -74.254572];
Zipcodes['07747'] = [40.41325, -74.257674];
Zipcodes['07748'] = [40.3963, -74.115274];
Zipcodes['07750'] = [40.335895, -73.985608];
Zipcodes['07751'] = [40.35965, -74.261462];
Zipcodes['07753'] = [40.210761, -74.074499];
Zipcodes['07755'] = [40.26347, -74.023623];
Zipcodes['07756'] = [40.21182, -74.006944];
Zipcodes['07757'] = [40.315996, -74.017637];
Zipcodes['07758'] = [40.434236, -74.100665];
Zipcodes['07760'] = [40.371452, -74.003922];
Zipcodes['07762'] = [40.153409, -74.033732];
Zipcodes['07764'] = [40.287904, -74.020019];
Zipcodes['07801'] = [40.901125, -74.564783];
Zipcodes['07803'] = [40.878088, -74.601176];
Zipcodes['07820'] = [40.928674, -74.798862];
Zipcodes['07821'] = [40.966037, -74.754687];
Zipcodes['07822'] = [41.138775, -74.708337];
Zipcodes['07823'] = [40.823262, -75.046014];
Zipcodes['07825'] = [40.982962, -74.961996];
Zipcodes['07826'] = [41.163459, -74.815409];
Zipcodes['07827'] = [41.280958, -74.730511];
Zipcodes['07828'] = [40.884959, -74.755657];
Zipcodes['07830'] = [40.719283, -74.794021];
Zipcodes['07831'] = [40.739211, -74.946813];
Zipcodes['07832'] = [40.944578, -75.067346];
Zipcodes['07833'] = [40.892819, -75.069436];
Zipcodes['07834'] = [40.88646, -74.487584];
Zipcodes['07836'] = [40.843893, -74.709761];
Zipcodes['07838'] = [40.88795, -74.911692];
Zipcodes['07840'] = [40.860045, -74.831795];
Zipcodes['07842'] = [40.944622, -74.500876];
Zipcodes['07843'] = [40.939227, -74.659506];
Zipcodes['07844'] = [40.908616, -74.969576];
Zipcodes['07846'] = [40.966823, -74.876834];
Zipcodes['07847'] = [40.887434, -74.619738];
Zipcodes['07848'] = [41.097903, -74.680163];
Zipcodes['07849'] = [40.964142, -74.612574];
Zipcodes['07850'] = [40.906766, -74.659352];
Zipcodes['07851'] = [41.200384, -74.856406];
Zipcodes['07852'] = [40.883268, -74.668029];
Zipcodes['07853'] = [40.787399, -74.78213];
Zipcodes['07856'] = [40.916422, -74.626763];
Zipcodes['07857'] = [40.897942, -74.693366];
Zipcodes['07860'] = [41.057287, -74.802737];
Zipcodes['07863'] = [40.807571, -74.957451];
Zipcodes['07865'] = [40.793806, -74.898347];
Zipcodes['07866'] = [40.954989, -74.488006];
Zipcodes['07869'] = [40.84164, -74.578522];
Zipcodes['07870'] = [40.804243, -74.820672];
Zipcodes['07871'] = [41.046696, -74.627989];
Zipcodes['07874'] = [40.928908, -74.709458];
Zipcodes['07876'] = [40.85447, -74.657357];
Zipcodes['07878'] = [40.871598, -74.47824];
Zipcodes['07880'] = [40.880001, -74.876253];
Zipcodes['07881'] = [41.123325, -74.934412];
Zipcodes['07882'] = [40.75543, -75.01087];
Zipcodes['07885'] = [40.910409, -74.599964];
Zipcodes['07901'] = [40.714923, -74.366372];
Zipcodes['07920'] = [40.682268, -74.554143];
Zipcodes['07921'] = [40.656019, -74.678657];
Zipcodes['07922'] = [40.67659, -74.427022];
Zipcodes['07924'] = [40.72577, -74.591213];
Zipcodes['07926'] = [40.801262, -74.570122];
Zipcodes['07927'] = [40.823725, -74.456354];
Zipcodes['07928'] = [40.724971, -74.415086];
Zipcodes['07930'] = [40.781309, -74.68359];
Zipcodes['07931'] = [40.681417, -74.616536];
Zipcodes['07932'] = [40.777461, -74.392845];
Zipcodes['07933'] = [40.695179, -74.456871];
Zipcodes['07934'] = [40.729737, -74.666509];
Zipcodes['07935'] = [40.735441, -74.451312];
Zipcodes['07936'] = [40.818553, -74.363742];
Zipcodes['07939'] = [40.667279, -74.552164];
Zipcodes['07940'] = [40.760535, -74.41948];
Zipcodes['07945'] = [40.780656, -74.601816];
Zipcodes['07946'] = [40.677709, -74.508538];
Zipcodes['07950'] = [40.846026, -74.482314];
Zipcodes['07960'] = [40.779235, -74.502462];
Zipcodes['07961'] = [40.781113, -74.435151];
Zipcodes['07970'] = [40.809387, -74.571554];
Zipcodes['07974'] = [40.696919, -74.403853];
Zipcodes['07976'] = [40.72806, -74.479344];
Zipcodes['07977'] = [40.711342, -74.651625];
Zipcodes['07979'] = [40.711205, -74.730643];
Zipcodes['07980'] = [40.676166, -74.494548];
Zipcodes['07981'] = [40.824413, -74.426241];
Zipcodes['08001'] = [39.54788, -75.346384];
Zipcodes['08002'] = [39.934605, -75.025389];
Zipcodes['08003'] = [39.885852, -74.971145];
Zipcodes['08004'] = [39.755333, -74.830512];
Zipcodes['08005'] = [39.755802, -74.306609];
Zipcodes['08006'] = [39.753505, -74.104953];
Zipcodes['08007'] = [39.865083, -75.053762];
Zipcodes['08008'] = [39.623538, -74.206089];
Zipcodes['08009'] = [39.760656, -74.93286];
Zipcodes['08010'] = [40.053506, -74.918438];
Zipcodes['08011'] = [39.975279, -74.713802];
Zipcodes['08012'] = [39.783815, -75.056068];
Zipcodes['08014'] = [39.812923, -75.36731];
Zipcodes['08015'] = [39.931536, -74.54372];
Zipcodes['08016'] = [40.068064, -74.832904];
Zipcodes['08019'] = [39.765587, -74.530053];
Zipcodes['08020'] = [39.794221, -75.222232];
Zipcodes['08021'] = [39.806156, -75.000012];
Zipcodes['08022'] = [40.06759, -74.705741];
Zipcodes['08023'] = [39.688108, -75.496899];
Zipcodes['08026'] = [39.833646, -74.969001];
Zipcodes['08027'] = [39.828521, -75.294384];
Zipcodes['08028'] = [39.692129, -75.124675];
Zipcodes['08029'] = [39.840937, -75.067211];
Zipcodes['08030'] = [39.889564, -75.118494];
Zipcodes['08031'] = [39.866356, -75.09467];
Zipcodes['08032'] = [39.780545, -75.059612];
Zipcodes['08033'] = [39.892421, -75.036651];
Zipcodes['08034'] = [39.9061, -75.000375];
Zipcodes['08035'] = [39.879127, -75.065918];
Zipcodes['08036'] = [39.977956, -74.836334];
Zipcodes['08037'] = [39.631681, -74.773893];
Zipcodes['08038'] = [39.470004, -75.490291];
Zipcodes['08039'] = [39.685659, -75.277309];
Zipcodes['08041'] = [40.038525, -74.684101];
Zipcodes['08042'] = [40.013657, -74.662029];
Zipcodes['08043'] = [39.84857, -74.952843];
Zipcodes['08045'] = [39.867211, -75.029156];
Zipcodes['08046'] = [40.02795, -74.886984];
Zipcodes['08048'] = [39.95385, -74.803026];
Zipcodes['08049'] = [39.854575, -75.038137];
Zipcodes['08050'] = [39.712132, -74.231888];
Zipcodes['08051'] = [39.784025, -75.175979];
Zipcodes['08052'] = [39.952381, -74.994896];
Zipcodes['08053'] = [39.856677, -74.90081];
Zipcodes['08054'] = [39.948992, -74.900247];
Zipcodes['08055'] = [39.862695, -74.822311];
Zipcodes['08056'] = [39.783683, -75.255302];
Zipcodes['08057'] = [39.979658, -74.941308];
Zipcodes['08059'] = [39.884825, -75.092667];
Zipcodes['08060'] = [40.010005, -74.792207];
Zipcodes['08061'] = [39.79887, -75.205365];
Zipcodes['08062'] = [39.716095, -75.219267];
Zipcodes['08063'] = [39.867297, -75.185621];
Zipcodes['08064'] = [39.93735, -74.616269];
Zipcodes['08065'] = [40.002615, -75.035273];
Zipcodes['08066'] = [39.835173, -75.233532];
Zipcodes['08067'] = [39.739193, -75.411406];
Zipcodes['08068'] = [39.961394, -74.665922];
Zipcodes['08069'] = [39.703142, -75.450676];
Zipcodes['08070'] = [39.619125, -75.516069];
Zipcodes['08071'] = [39.731654, -75.134257];
Zipcodes['08072'] = [39.544683, -75.412568];
Zipcodes['08073'] = [40.009508, -74.866845];
Zipcodes['08074'] = [39.716166, -75.16344];
Zipcodes['08075'] = [40.029676, -74.948997];
Zipcodes['08077'] = [40.000162, -74.991632];
Zipcodes['08078'] = [39.853411, -75.075502];
Zipcodes['08079'] = [39.542408, -75.431684];
Zipcodes['08080'] = [39.757353, -75.122043];
Zipcodes['08081'] = [39.732942, -74.976657];
Zipcodes['08083'] = [39.841307, -75.028613];
Zipcodes['08084'] = [39.82899, -75.015536];
Zipcodes['08085'] = [39.753766, -75.33104];
Zipcodes['08086'] = [39.851212, -75.185663];
Zipcodes['08087'] = [39.603959, -74.382181];
Zipcodes['08088'] = [39.837315, -74.695267];
Zipcodes['08089'] = [39.713093, -74.831034];
Zipcodes['08090'] = [39.797986, -75.148526];
Zipcodes['08091'] = [39.807116, -74.924178];
Zipcodes['08092'] = [39.647182, -74.284599];
Zipcodes['08093'] = [39.861571, -75.132975];
Zipcodes['08094'] = [39.650601, -74.959311];
Zipcodes['08095'] = [39.649298, -74.858006];
Zipcodes['08096'] = [39.829402, -75.129032];
Zipcodes['08097'] = [39.81329, -75.15065];
Zipcodes['08098'] = [39.639301, -75.329801];
Zipcodes['08102'] = [39.952116, -75.121149];
Zipcodes['08103'] = [39.935202, -75.112703];
Zipcodes['08104'] = [39.916459, -75.111355];
Zipcodes['08105'] = [39.95034, -75.096278];
Zipcodes['08106'] = [39.890992, -75.073329];
Zipcodes['08107'] = [39.908665, -75.084445];
Zipcodes['08108'] = [39.915048, -75.062244];
Zipcodes['08109'] = [39.94933, -75.04953];
Zipcodes['08110'] = [39.971893, -75.057876];
Zipcodes['08201'] = [39.423563, -74.493025];
Zipcodes['08202'] = [39.09664, -74.739757];
Zipcodes['08203'] = [39.421713, -74.392873];
Zipcodes['08204'] = [38.971748, -74.916127];
Zipcodes['08205'] = [39.482, -74.452637];
Zipcodes['08210'] = [39.119187, -74.823188];
Zipcodes['08212'] = [38.940414, -74.964284];
Zipcodes['08215'] = [39.59012, -74.581951];
Zipcodes['08217'] = [39.577032, -74.718816];
Zipcodes['08221'] = [39.339936, -74.56447];
Zipcodes['08223'] = [39.26952, -74.657118];
Zipcodes['08224'] = [39.599334, -74.467435];
Zipcodes['08225'] = [39.35834, -74.536312];
Zipcodes['08226'] = [39.263784, -74.607753];
Zipcodes['08230'] = [39.201062, -74.706753];
Zipcodes['08232'] = [39.387525, -74.515196];
Zipcodes['08234'] = [39.388393, -74.620728];
Zipcodes['08240'] = [39.488059, -74.533973];
Zipcodes['08241'] = [39.534798, -74.476099];
Zipcodes['08242'] = [39.01738, -74.886908];
Zipcodes['08243'] = [39.151013, -74.696319];
Zipcodes['08244'] = [39.315511, -74.598278];
Zipcodes['08245'] = [39.176509, -74.819028];
Zipcodes['08246'] = [39.180965, -74.765806];
Zipcodes['08247'] = [39.048975, -74.786219];
Zipcodes['08248'] = [39.194963, -74.657546];
Zipcodes['08251'] = [39.028823, -74.928921];
Zipcodes['08260'] = [38.998347, -74.843302];
Zipcodes['08270'] = [39.261356, -74.809092];
Zipcodes['08302'] = [39.438613, -75.260646];
Zipcodes['08310'] = [39.531437, -74.89681];
Zipcodes['08311'] = [39.327866, -75.204035];
Zipcodes['08312'] = [39.661422, -75.083899];
Zipcodes['08314'] = [39.216102, -74.93774];
Zipcodes['08316'] = [39.280176, -74.937365];
Zipcodes['08317'] = [39.400688, -74.829634];
Zipcodes['08318'] = [39.557653, -75.177717];
Zipcodes['08319'] = [39.370409, -74.810816];
Zipcodes['08320'] = [39.38508, -75.1606];
Zipcodes['08321'] = [39.223596, -75.143169];
Zipcodes['08322'] = [39.611102, -75.048771];
Zipcodes['08323'] = [39.396273, -75.367551];
Zipcodes['08324'] = [39.225767, -75.004014];
Zipcodes['08326'] = [39.538415, -74.927717];
Zipcodes['08327'] = [39.255595, -74.949509];
Zipcodes['08328'] = [39.579668, -75.056375];
Zipcodes['08329'] = [39.286852, -75.005708];
Zipcodes['08330'] = [39.469337, -74.747162];
Zipcodes['08332'] = [39.366217, -75.026853];
Zipcodes['08340'] = [39.3933, -74.907257];
Zipcodes['08341'] = [39.521486, -74.958651];
Zipcodes['08343'] = [39.636348, -75.16756];
Zipcodes['08344'] = [39.561745, -74.986037];
Zipcodes['08345'] = [39.288457, -75.143586];
Zipcodes['08346'] = [39.569548, -74.852086];
Zipcodes['08348'] = [39.309933, -74.971365];
Zipcodes['08349'] = [39.260936, -75.059259];
Zipcodes['08350'] = [39.4907, -74.873641];
Zipcodes['08352'] = [39.475445, -75.127242];
Zipcodes['08353'] = [39.462418, -75.29244];
Zipcodes['08360'] = [39.492354, -75.003447];
Zipcodes['08361'] = [39.449369, -74.958827];
Zipcodes['08401'] = [39.377337, -74.45107];
Zipcodes['08402'] = [39.326385, -74.504473];
Zipcodes['08403'] = [39.318497, -74.542405];
Zipcodes['08406'] = [39.342046, -74.482626];
Zipcodes['08501'] = [40.166258, -74.552543];
Zipcodes['08502'] = [40.448217, -74.653244];
Zipcodes['08505'] = [40.135589, -74.707255];
Zipcodes['08510'] = [40.187207, -74.425013];
Zipcodes['08511'] = [40.043738, -74.547654];
Zipcodes['08512'] = [40.324357, -74.525526];
Zipcodes['08514'] = [40.134245, -74.491732];
Zipcodes['08515'] = [40.119177, -74.662894];
Zipcodes['08518'] = [40.115503, -74.804028];
Zipcodes['08520'] = [40.258126, -74.53931];
Zipcodes['08525'] = [40.397716, -74.77952];
Zipcodes['08527'] = [40.108665, -74.358602];
Zipcodes['08528'] = [40.387108, -74.618915];
Zipcodes['08530'] = [40.37002, -74.910161];
Zipcodes['08533'] = [40.015589, -74.485083];
Zipcodes['08534'] = [40.329422, -74.792789];
Zipcodes['08535'] = [40.231908, -74.432033];
Zipcodes['08536'] = [40.333076, -74.586628];
Zipcodes['08540'] = [40.364728, -74.656314];
Zipcodes['08542'] = [40.354517, -74.66056];
Zipcodes['08544'] = [40.346492, -74.657848];
Zipcodes['08550'] = [40.279953, -74.622116];
Zipcodes['08551'] = [40.447111, -74.837285];
Zipcodes['08553'] = [40.400347, -74.638922];
Zipcodes['08554'] = [40.112984, -74.781093];
Zipcodes['08555'] = [40.220742, -74.470155];
Zipcodes['08558'] = [40.413411, -74.703745];
Zipcodes['08559'] = [40.438986, -74.983625];
Zipcodes['08560'] = [40.315492, -74.857016];
Zipcodes['08561'] = [40.247399, -74.581329];
Zipcodes['08562'] = [40.061957, -74.591878];
Zipcodes['08608'] = [40.219081, -74.766843];
Zipcodes['08609'] = [40.225747, -74.740906];
Zipcodes['08610'] = [40.193643, -74.715963];
Zipcodes['08611'] = [40.183615, -74.740319];
Zipcodes['08618'] = [40.24934, -74.789968];
Zipcodes['08619'] = [40.242301, -74.696408];
Zipcodes['08620'] = [40.170087, -74.651309];
Zipcodes['08628'] = [40.264418, -74.827047];
Zipcodes['08629'] = [40.220644, -74.731246];
Zipcodes['08638'] = [40.254018, -74.763385];
Zipcodes['08640'] = [40.013969, -74.575016];
Zipcodes['08641'] = [40.027985, -74.587819];
Zipcodes['08648'] = [40.28449, -74.717487];
Zipcodes['08690'] = [40.225833, -74.660096];
Zipcodes['08691'] = [40.208319, -74.600928];
Zipcodes['08701'] = [40.077069, -74.19851];
Zipcodes['08720'] = [40.144999, -74.125502];
Zipcodes['08721'] = [39.907407, -74.184817];
Zipcodes['08722'] = [39.928405, -74.202189];
Zipcodes['08723'] = [40.038584, -74.1116];
Zipcodes['08724'] = [40.091032, -74.114395];
Zipcodes['08730'] = [40.10841, -74.06217];
Zipcodes['08731'] = [39.859886, -74.292741];
Zipcodes['08732'] = [39.941804, -74.143842];
Zipcodes['08733'] = [40.026868, -74.363674];
Zipcodes['08734'] = [39.862916, -74.166413];
Zipcodes['08735'] = [39.996988, -74.077635];
Zipcodes['08736'] = [40.120018, -74.053257];
Zipcodes['08738'] = [40.024765, -74.059612];
Zipcodes['08740'] = [39.928358, -74.127773];
Zipcodes['08741'] = [39.936339, -74.167247];
Zipcodes['08742'] = [40.080812, -74.059117];
Zipcodes['08750'] = [40.133372, -74.041501];
Zipcodes['08751'] = [39.949634, -74.079516];
Zipcodes['08752'] = [39.83664, -74.090181];
Zipcodes['08753'] = [39.979668, -74.150739];
Zipcodes['08755'] = [40.008534, -74.221768];
Zipcodes['08757'] = [39.960591, -74.260843];
Zipcodes['08758'] = [39.794543, -74.253337];
Zipcodes['08759'] = [39.961826, -74.359828];
Zipcodes['08801'] = [40.629609, -74.89049];
Zipcodes['08802'] = [40.684264, -75.023286];
Zipcodes['08804'] = [40.646656, -75.09576];
Zipcodes['08805'] = [40.570639, -74.537551];
Zipcodes['08807'] = [40.592434, -74.61884];
Zipcodes['08809'] = [40.646036, -74.924609];
Zipcodes['08810'] = [40.368285, -74.490999];
Zipcodes['08812'] = [40.598731, -74.478793];
Zipcodes['08816'] = [40.429119, -74.416287];
Zipcodes['08817'] = [40.514655, -74.393104];
Zipcodes['08820'] = [40.576806, -74.36575];
Zipcodes['08821'] = [40.520721, -74.688338];
Zipcodes['08822'] = [40.521995, -74.866644];
Zipcodes['08823'] = [40.438609, -74.565211];
Zipcodes['08824'] = [40.420727, -74.553458];
Zipcodes['08825'] = [40.514189, -75.027157];
Zipcodes['08826'] = [40.716574, -74.912125];
Zipcodes['08827'] = [40.668424, -74.968377];
Zipcodes['08828'] = [40.378417, -74.423384];
Zipcodes['08829'] = [40.670111, -74.890212];
Zipcodes['08830'] = [40.570142, -74.316371];
Zipcodes['08831'] = [40.334341, -74.432502];
Zipcodes['08832'] = [40.511399, -74.318496];
Zipcodes['08833'] = [40.645542, -74.816261];
Zipcodes['08835'] = [40.541791, -74.588588];
Zipcodes['08836'] = [40.596376, -74.55732];
Zipcodes['08837'] = [40.514281, -74.343859];
Zipcodes['08840'] = [40.543139, -74.358981];
Zipcodes['08844'] = [40.497692, -74.670505];
Zipcodes['08846'] = [40.574627, -74.498259];
Zipcodes['08848'] = [40.594266, -75.096659];
Zipcodes['08850'] = [40.449151, -74.440313];
Zipcodes['08852'] = [40.389909, -74.544087];
Zipcodes['08853'] = [40.527314, -74.742043];
Zipcodes['08854'] = [40.539658, -74.462791];
Zipcodes['08857'] = [40.392789, -74.330397];
Zipcodes['08858'] = [40.682681, -74.737038];
Zipcodes['08859'] = [40.458172, -74.303799];
Zipcodes['08861'] = [40.520494, -74.272943];
Zipcodes['08863'] = [40.537238, -74.312222];
Zipcodes['08865'] = [40.708226, -75.147073];
Zipcodes['08867'] = [40.574456, -74.966101];
Zipcodes['08869'] = [40.571344, -74.646026];
Zipcodes['08872'] = [40.455736, -74.351468];
Zipcodes['08873'] = [40.499667, -74.531844];
Zipcodes['08876'] = [40.589554, -74.685411];
Zipcodes['08879'] = [40.46641, -74.278625];
Zipcodes['08880'] = [40.553947, -74.527455];
Zipcodes['08882'] = [40.445652, -74.378459];
Zipcodes['08884'] = [40.393613, -74.392737];
Zipcodes['08886'] = [40.68764, -75.100726];
Zipcodes['08887'] = [40.521516, -74.795943];
Zipcodes['08889'] = [40.609021, -74.754216];
Zipcodes['08890'] = [40.539047, -74.575409];
Zipcodes['08901'] = [40.483638, -74.44246];
Zipcodes['08902'] = [40.441513, -74.491214];
Zipcodes['08904'] = [40.500795, -74.427911];
Zipcodes['10001'] = [40.750649, -73.997298];
Zipcodes['10002'] = [40.715969, -73.986895];
Zipcodes['10003'] = [40.731865, -73.989124];
Zipcodes['10004'] = [40.689805, -74.017389];
Zipcodes['10005'] = [40.706215, -74.008687];
Zipcodes['10006'] = [40.70949, -74.013054];
Zipcodes['10007'] = [40.713868, -74.007866];
Zipcodes['10009'] = [40.726392, -73.978604];
Zipcodes['10010'] = [40.739045, -73.982616];
Zipcodes['10011'] = [40.74184, -74.000528];
Zipcodes['10012'] = [40.725625, -73.997936];
Zipcodes['10013'] = [40.720051, -74.004809];
Zipcodes['10014'] = [40.734145, -74.006026];
Zipcodes['10016'] = [40.745182, -73.978053];
Zipcodes['10017'] = [40.752387, -73.972678];
Zipcodes['10018'] = [40.755327, -73.992696];
Zipcodes['10019'] = [40.765735, -73.985065];
Zipcodes['10020'] = [40.759174, -73.980715];
Zipcodes['10021'] = [40.76964, -73.959018];
Zipcodes['10022'] = [40.75857, -73.96793];
Zipcodes['10023'] = [40.774567, -73.979016];
Zipcodes['10024'] = [40.78566, -73.971269];
Zipcodes['10025'] = [40.798251, -73.968336];
Zipcodes['10026'] = [40.802978, -73.953529];
Zipcodes['10027'] = [40.812657, -73.954983];
Zipcodes['10028'] = [40.776447, -73.953383];
Zipcodes['10029'] = [40.792249, -73.947333];
Zipcodes['10030'] = [40.818247, -73.942834];
Zipcodes['10031'] = [40.824804, -73.950206];
Zipcodes['10032'] = [40.839041, -73.942812];
Zipcodes['10033'] = [40.847476, -73.93551];
Zipcodes['10034'] = [40.867076, -73.924312];
Zipcodes['10035'] = [40.795251, -73.930418];
Zipcodes['10036'] = [40.759792, -73.990685];
Zipcodes['10037'] = [40.813027, -73.937714];
Zipcodes['10038'] = [40.709264, -74.002903];
Zipcodes['10039'] = [40.826287, -73.937526];
Zipcodes['10040'] = [40.858097, -73.9296];
Zipcodes['10044'] = [40.761907, -73.949967];
Zipcodes['10065'] = [40.764689, -73.963264];
Zipcodes['10069'] = [40.777382, -73.98883];
Zipcodes['10075'] = [40.773413, -73.956291];
Zipcodes['10103'] = [40.76078, -73.97767];
Zipcodes['10110'] = [40.754499, -73.982256];
Zipcodes['10111'] = [40.759531, -73.978585];
Zipcodes['10112'] = [40.758904, -73.979044];
Zipcodes['10115'] = [40.810852, -73.963744];
Zipcodes['10119'] = [40.750644, -73.992733];
Zipcodes['10128'] = [40.781498, -73.950199];
Zipcodes['10152'] = [40.758404, -73.972031];
Zipcodes['10153'] = [40.763622, -73.972439];
Zipcodes['10154'] = [40.757779, -73.972487];
Zipcodes['10162'] = [40.769267, -73.949795];
Zipcodes['10165'] = [40.752131, -73.978722];
Zipcodes['10167'] = [40.754648, -73.974771];
Zipcodes['10168'] = [40.751448, -73.977103];
Zipcodes['10169'] = [40.754515, -73.976393];
Zipcodes['10170'] = [40.752625, -73.975877];
Zipcodes['10171'] = [40.755899, -73.973858];
Zipcodes['10172'] = [40.755273, -73.974315];
Zipcodes['10173'] = [40.754131, -73.979364];
Zipcodes['10174'] = [40.751441, -73.975003];
Zipcodes['10177'] = [40.755139, -73.975934];
Zipcodes['10199'] = [40.751383, -73.997152];
Zipcodes['10271'] = [40.708236, -74.010541];
Zipcodes['10278'] = [40.715138, -74.003717];
Zipcodes['10279'] = [40.712626, -74.008669];
Zipcodes['10280'] = [40.709705, -74.016586];
Zipcodes['10282'] = [40.716381, -74.015004];
Zipcodes['10301'] = [40.627505, -74.094377];
Zipcodes['10302'] = [40.630806, -74.137664];
Zipcodes['10303'] = [40.629885, -74.17413];
Zipcodes['10304'] = [40.605965, -74.093535];
Zipcodes['10305'] = [40.596549, -74.075779];
Zipcodes['10306'] = [40.571714, -74.124264];
Zipcodes['10307'] = [40.507753, -74.242091];
Zipcodes['10308'] = [40.552828, -74.151525];
Zipcodes['10309'] = [40.529842, -74.219777];
Zipcodes['10310'] = [40.632612, -74.116148];
Zipcodes['10311'] = [40.605231, -74.179534];
Zipcodes['10312'] = [40.545237, -74.180443];
Zipcodes['10314'] = [40.600699, -74.164314];
Zipcodes['10451'] = [40.822168, -73.922616];
Zipcodes['10452'] = [40.837791, -73.923339];
Zipcodes['10453'] = [40.852508, -73.913291];
Zipcodes['10454'] = [40.805583, -73.916595];
Zipcodes['10455'] = [40.814798, -73.908601];
Zipcodes['10456'] = [40.830669, -73.908161];
Zipcodes['10457'] = [40.845707, -73.897923];
Zipcodes['10458'] = [40.862486, -73.886448];
Zipcodes['10459'] = [40.825692, -73.892998];
Zipcodes['10460'] = [40.842344, -73.879062];
Zipcodes['10461'] = [40.846741, -73.840788];
Zipcodes['10462'] = [40.843267, -73.860417];
Zipcodes['10463'] = [40.886345, -73.901547];
Zipcodes['10464'] = [40.869594, -73.795848];
Zipcodes['10465'] = [40.827329, -73.82217];
Zipcodes['10466'] = [40.890964, -73.846239];
Zipcodes['10467'] = [40.875262, -73.872027];
Zipcodes['10468'] = [40.86894, -73.899995];
Zipcodes['10469'] = [40.868509, -73.846691];
Zipcodes['10470'] = [40.889527, -73.872585];
Zipcodes['10471'] = [40.899984, -73.906751];
Zipcodes['10472'] = [40.829685, -73.869373];
Zipcodes['10473'] = [40.81869, -73.858474];
Zipcodes['10474'] = [40.81134, -73.885173];
Zipcodes['10475'] = [40.875635, -73.826934];
Zipcodes['10501'] = [41.295591, -73.759425];
Zipcodes['10502'] = [41.011282, -73.840846];
Zipcodes['10503'] = [41.026556, -73.87531];
Zipcodes['10504'] = [41.128468, -73.707521];
Zipcodes['10505'] = [41.342072, -73.747803];
Zipcodes['10506'] = [41.189652, -73.638509];
Zipcodes['10507'] = [41.232188, -73.691689];
Zipcodes['10509'] = [41.410855, -73.59434];
Zipcodes['10510'] = [41.139472, -73.835704];
Zipcodes['10511'] = [41.263168, -73.944966];
Zipcodes['10512'] = [41.447747, -73.709884];
Zipcodes['10514'] = [41.172119, -73.769217];
Zipcodes['10516'] = [41.461973, -73.874913];
Zipcodes['10517'] = [41.298466, -73.86014];
Zipcodes['10518'] = [41.266143, -73.588296];
Zipcodes['10519'] = [41.347163, -73.663362];
Zipcodes['10520'] = [41.226601, -73.867641];
Zipcodes['10522'] = [41.009696, -73.863304];
Zipcodes['10523'] = [41.059498, -73.819254];
Zipcodes['10524'] = [41.375325, -73.926217];
Zipcodes['10526'] = [41.294095, -73.6594];
Zipcodes['10527'] = [41.321169, -73.76994];
Zipcodes['10528'] = [40.975047, -73.724926];
Zipcodes['10530'] = [41.023712, -73.81281];
Zipcodes['10532'] = [41.100261, -73.80073];
Zipcodes['10533'] = [41.036927, -73.854864];
Zipcodes['10535'] = [41.335169, -73.793919];
Zipcodes['10536'] = [41.269182, -73.688726];
Zipcodes['10537'] = [41.344088, -73.881002];
Zipcodes['10538'] = [40.937752, -73.756473];
Zipcodes['10540'] = [41.325982, -73.727712];
Zipcodes['10541'] = [41.382046, -73.751818];
Zipcodes['10542'] = [41.371147, -73.764367];
Zipcodes['10543'] = [40.952693, -73.73604];
Zipcodes['10545'] = [41.178573, -73.835465];
Zipcodes['10546'] = [41.196605, -73.800569];
Zipcodes['10547'] = [41.313334, -73.846039];
Zipcodes['10548'] = [41.244987, -73.938738];
Zipcodes['10549'] = [41.200584, -73.723498];
Zipcodes['10550'] = [40.905448, -73.835253];
Zipcodes['10552'] = [40.923972, -73.825555];
Zipcodes['10553'] = [40.908607, -73.821566];
Zipcodes['10560'] = [41.340527, -73.597609];
Zipcodes['10562'] = [41.194423, -73.825285];
Zipcodes['10566'] = [41.289483, -73.916847];
Zipcodes['10567'] = [41.288405, -73.895008];
Zipcodes['10570'] = [41.128068, -73.78922];
Zipcodes['10573'] = [41.015742, -73.677303];
Zipcodes['10576'] = [41.221577, -73.572332];
Zipcodes['10577'] = [41.038225, -73.710736];
Zipcodes['10578'] = [41.314083, -73.677051];
Zipcodes['10579'] = [41.395364, -73.839053];
Zipcodes['10580'] = [40.979049, -73.693202];
Zipcodes['10583'] = [40.988647, -73.788879];
Zipcodes['10588'] = [41.331483, -73.828476];
Zipcodes['10589'] = [41.332433, -73.692669];
Zipcodes['10590'] = [41.256234, -73.53786];
Zipcodes['10591'] = [41.082659, -73.848578];
Zipcodes['10594'] = [41.115794, -73.770977];
Zipcodes['10595'] = [41.087378, -73.780369];
Zipcodes['10596'] = [41.255981, -73.959468];
Zipcodes['10597'] = [41.293021, -73.596568];
Zipcodes['10598'] = [41.282372, -73.796206];
Zipcodes['10601'] = [41.03289, -73.764783];
Zipcodes['10603'] = [41.054219, -73.780227];
Zipcodes['10604'] = [41.071512, -73.747093];
Zipcodes['10605'] = [41.010566, -73.745129];
Zipcodes['10606'] = [41.020695, -73.775877];
Zipcodes['10607'] = [41.039089, -73.811368];
Zipcodes['10701'] = [40.946966, -73.88042];
Zipcodes['10703'] = [40.957649, -73.881135];
Zipcodes['10704'] = [40.919729, -73.862651];
Zipcodes['10705'] = [40.919339, -73.889335];
Zipcodes['10706'] = [40.990503, -73.869502];
Zipcodes['10707'] = [40.959633, -73.82294];
Zipcodes['10708'] = [40.938267, -73.829922];
Zipcodes['10709'] = [40.954635, -73.808184];
Zipcodes['10710'] = [40.969635, -73.845691];
Zipcodes['10801'] = [40.91757, -73.784858];
Zipcodes['10803'] = [40.899532, -73.805883];
Zipcodes['10804'] = [40.946859, -73.788035];
Zipcodes['10805'] = [40.88637, -73.757674];
Zipcodes['10901'] = [41.155398, -74.129008];
Zipcodes['10910'] = [41.28157, -74.137957];
Zipcodes['10911'] = [41.308426, -73.995965];
Zipcodes['10913'] = [41.068872, -73.956016];
Zipcodes['10914'] = [41.417717, -74.200026];
Zipcodes['10915'] = [41.54018, -74.356996];
Zipcodes['10916'] = [41.449335, -74.244749];
Zipcodes['10917'] = [41.321824, -74.108011];
Zipcodes['10918'] = [41.344192, -74.262177];
Zipcodes['10919'] = [41.525959, -74.387618];
Zipcodes['10920'] = [41.156495, -73.938239];
Zipcodes['10921'] = [41.332065, -74.363422];
Zipcodes['10922'] = [41.336897, -73.987572];
Zipcodes['10923'] = [41.203116, -74.002718];
Zipcodes['10924'] = [41.381156, -74.352485];
Zipcodes['10925'] = [41.185855, -74.288878];
Zipcodes['10926'] = [41.255799, -74.127856];
Zipcodes['10927'] = [41.191052, -73.967562];
Zipcodes['10928'] = [41.34897, -73.998495];
Zipcodes['10930'] = [41.382395, -74.114404];
Zipcodes['10931'] = [41.127177, -74.170503];
Zipcodes['10932'] = [41.48216, -74.464285];
Zipcodes['10933'] = [41.368021, -74.512999];
Zipcodes['10940'] = [41.446544, -74.478741];
Zipcodes['10941'] = [41.490413, -74.345272];
Zipcodes['10950'] = [41.317346, -74.199881];
Zipcodes['10952'] = [41.111118, -74.07854];
Zipcodes['10953'] = [41.405855, -74.077917];
Zipcodes['10954'] = [41.099198, -74.013021];
Zipcodes['10956'] = [41.154436, -73.994072];
Zipcodes['10958'] = [41.379275, -74.423769];
Zipcodes['10960'] = [41.088841, -73.926471];
Zipcodes['10962'] = [41.049007, -73.962103];
Zipcodes['10963'] = [41.466587, -74.540707];
Zipcodes['10964'] = [41.016357, -73.914268];
Zipcodes['10965'] = [41.061561, -74.007832];
Zipcodes['10968'] = [41.037017, -73.921259];
Zipcodes['10969'] = [41.294858, -74.488006];
Zipcodes['10970'] = [41.18436, -74.062727];
Zipcodes['10973'] = [41.380516, -74.480462];
Zipcodes['10974'] = [41.159484, -74.189903];
Zipcodes['10975'] = [41.257008, -74.167732];
Zipcodes['10976'] = [41.02296, -73.928488];
Zipcodes['10977'] = [41.118914, -74.048154];
Zipcodes['10979'] = [41.18237, -74.318814];
Zipcodes['10980'] = [41.239234, -74.056261];
Zipcodes['10983'] = [41.031088, -73.947703];
Zipcodes['10984'] = [41.203123, -74.022156];
Zipcodes['10985'] = [41.577032, -74.358035];
Zipcodes['10986'] = [41.285209, -73.998672];
Zipcodes['10987'] = [41.199212, -74.224545];
Zipcodes['10988'] = [41.301494, -74.562447];
Zipcodes['10989'] = [41.122955, -73.938031];
Zipcodes['10990'] = [41.267448, -74.364011];
Zipcodes['10992'] = [41.426089, -74.164664];
Zipcodes['10993'] = [41.209904, -73.973872];
Zipcodes['10994'] = [41.098072, -73.972649];
Zipcodes['10996'] = [41.3937, -73.972175];
Zipcodes['10998'] = [41.324076, -74.541179];
Zipcodes['11001'] = [40.723317, -73.704949];
Zipcodes['11003'] = [40.699176, -73.706166];
Zipcodes['11004'] = [40.746191, -73.711507];
Zipcodes['11005'] = [40.756643, -73.71424];
Zipcodes['11010'] = [40.700653, -73.675133];
Zipcodes['11020'] = [40.771442, -73.714819];
Zipcodes['11021'] = [40.784319, -73.731488];
Zipcodes['11023'] = [40.798909, -73.733653];
Zipcodes['11024'] = [40.816251, -73.742872];
Zipcodes['11030'] = [40.793409, -73.688549];
Zipcodes['11040'] = [40.745347, -73.680292];
Zipcodes['11042'] = [40.758542, -73.697451];
Zipcodes['11050'] = [40.839895, -73.693123];
Zipcodes['11096'] = [40.621346, -73.75699];
Zipcodes['11101'] = [40.747113, -73.939708];
Zipcodes['11102'] = [40.7716, -73.925603];
Zipcodes['11103'] = [40.762596, -73.913282];
Zipcodes['11104'] = [40.744845, -73.920468];
Zipcodes['11105'] = [40.779087, -73.906522];
Zipcodes['11106'] = [40.762211, -73.931528];
Zipcodes['11109'] = [40.746616, -73.957009];
Zipcodes['11201'] = [40.6937, -73.989859];
Zipcodes['11203'] = [40.649569, -73.934327];
Zipcodes['11204'] = [40.618815, -73.984942];
Zipcodes['11205'] = [40.696891, -73.968074];
Zipcodes['11206'] = [40.70187, -73.942552];
Zipcodes['11207'] = [40.670157, -73.893835];
Zipcodes['11208'] = [40.674847, -73.87248];
Zipcodes['11209'] = [40.621803, -74.03015];
Zipcodes['11210'] = [40.62806, -73.946309];
Zipcodes['11211'] = [40.714084, -73.945168];
Zipcodes['11212'] = [40.662905, -73.913071];
Zipcodes['11213'] = [40.671155, -73.936266];
Zipcodes['11214'] = [40.59969, -73.996372];
Zipcodes['11215'] = [40.662688, -73.98674];
Zipcodes['11216'] = [40.680844, -73.949222];
Zipcodes['11217'] = [40.681671, -73.978529];
Zipcodes['11218'] = [40.64312, -73.976446];
Zipcodes['11219'] = [40.63262, -73.99677];
Zipcodes['11220'] = [40.641026, -74.016688];
Zipcodes['11221'] = [40.691426, -73.927626];
Zipcodes['11222'] = [40.72779, -73.947605];
Zipcodes['11223'] = [40.597091, -73.973469];
Zipcodes['11224'] = [40.577372, -73.988706];
Zipcodes['11225'] = [40.663119, -73.953867];
Zipcodes['11226'] = [40.646409, -73.956663];
Zipcodes['11228'] = [40.616725, -74.013432];
Zipcodes['11229'] = [40.601293, -73.944493];
Zipcodes['11230'] = [40.622089, -73.96519];
Zipcodes['11231'] = [40.677916, -74.005154];
Zipcodes['11232'] = [40.656541, -74.005182];
Zipcodes['11233'] = [40.678301, -73.919932];
Zipcodes['11234'] = [40.607723, -73.910649];
Zipcodes['11235'] = [40.58422, -73.943167];
Zipcodes['11236'] = [40.639413, -73.900664];
Zipcodes['11237'] = [40.70416, -73.921139];
Zipcodes['11238'] = [40.671331, -73.965937];
Zipcodes['11239'] = [40.64689, -73.864014];
Zipcodes['11249'] = [40.704501, -73.966002];
Zipcodes['11354'] = [40.768213, -73.827392];
Zipcodes['11355'] = [40.751435, -73.821227];
Zipcodes['11356'] = [40.784871, -73.841528];
Zipcodes['11357'] = [40.785129, -73.810037];
Zipcodes['11358'] = [40.760363, -73.796525];
Zipcodes['11359'] = [40.791605, -73.776724];
Zipcodes['11360'] = [40.780419, -73.781838];
Zipcodes['11361'] = [40.764191, -73.772775];
Zipcodes['11362'] = [40.760204, -73.732417];
Zipcodes['11363'] = [40.772348, -73.746292];
Zipcodes['11364'] = [40.746251, -73.756062];
Zipcodes['11365'] = [40.739634, -73.79449];
Zipcodes['11366'] = [40.731121, -73.780458];
Zipcodes['11367'] = [40.730474, -73.822749];
Zipcodes['11368'] = [40.749737, -73.853045];
Zipcodes['11369'] = [40.763491, -73.87222];
Zipcodes['11370'] = [40.765393, -73.894216];
Zipcodes['11371'] = [40.774411, -73.873272];
Zipcodes['11372'] = [40.751719, -73.88367];
Zipcodes['11373'] = [40.738945, -73.878561];
Zipcodes['11374'] = [40.726457, -73.861459];
Zipcodes['11375'] = [40.722975, -73.843179];
Zipcodes['11377'] = [40.744775, -73.90516];
Zipcodes['11378'] = [40.724744, -73.909639];
Zipcodes['11379'] = [40.717061, -73.880519];
Zipcodes['11385'] = [40.700647, -73.889423];
Zipcodes['11411'] = [40.694064, -73.736265];
Zipcodes['11412'] = [40.69802, -73.759081];
Zipcodes['11413'] = [40.67117, -73.752063];
Zipcodes['11414'] = [40.657604, -73.844804];
Zipcodes['11415'] = [40.708085, -73.828044];
Zipcodes['11416'] = [40.684586, -73.849713];
Zipcodes['11417'] = [40.676406, -73.844595];
Zipcodes['11418'] = [40.700315, -73.836019];
Zipcodes['11419'] = [40.688612, -73.823062];
Zipcodes['11420'] = [40.673502, -73.817702];
Zipcodes['11421'] = [40.694035, -73.858659];
Zipcodes['11422'] = [40.660787, -73.73757];
Zipcodes['11423'] = [40.715669, -73.768299];
Zipcodes['11424'] = [40.713968, -73.828087];
Zipcodes['11426'] = [40.736407, -73.722357];
Zipcodes['11427'] = [40.730942, -73.745716];
Zipcodes['11428'] = [40.720993, -73.742203];
Zipcodes['11429'] = [40.709556, -73.73848];
Zipcodes['11430'] = [40.646965, -73.787295];
Zipcodes['11432'] = [40.714934, -73.792955];
Zipcodes['11433'] = [40.69809, -73.786752];
Zipcodes['11434'] = [40.677857, -73.777073];
Zipcodes['11435'] = [40.70108, -73.80964];
Zipcodes['11436'] = [40.675864, -73.796647];
Zipcodes['11439'] = [40.721935, -73.794587];
Zipcodes['11451'] = [40.701188, -73.795778];
Zipcodes['11501'] = [40.74633, -73.639178];
Zipcodes['11507'] = [40.770829, -73.652103];
Zipcodes['11509'] = [40.587963, -73.728528];
Zipcodes['11510'] = [40.650127, -73.607709];
Zipcodes['11514'] = [40.749778, -73.61291];
Zipcodes['11516'] = [40.626223, -73.726779];
Zipcodes['11518'] = [40.637472, -73.666807];
Zipcodes['11520'] = [40.649401, -73.582951];
Zipcodes['11530'] = [40.726854, -73.637009];
Zipcodes['11542'] = [40.872605, -73.628622];
Zipcodes['11545'] = [40.826321, -73.589365];
Zipcodes['11547'] = [40.829066, -73.644718];
Zipcodes['11548'] = [40.811078, -73.613879];
Zipcodes['11549'] = [40.719009, -73.599677];
Zipcodes['11550'] = [40.701475, -73.621108];
Zipcodes['11552'] = [40.691752, -73.654204];
Zipcodes['11553'] = [40.705272, -73.592328];
Zipcodes['11554'] = [40.720115, -73.558861];
Zipcodes['11556'] = [40.719939, -73.583402];
Zipcodes['11557'] = [40.636083, -73.693921];
Zipcodes['11558'] = [40.605357, -73.649046];
Zipcodes['11559'] = [40.602924, -73.714434];
Zipcodes['11560'] = [40.880757, -73.588724];
Zipcodes['11561'] = [40.590904, -73.644668];
Zipcodes['11563'] = [40.657434, -73.674104];
Zipcodes['11565'] = [40.67502, -73.671722];
Zipcodes['11566'] = [40.662291, -73.556443];
Zipcodes['11568'] = [40.786614, -73.597169];
Zipcodes['11569'] = [40.590374, -73.580415];
Zipcodes['11570'] = [40.665341, -73.638921];
Zipcodes['11572'] = [40.631772, -73.636624];
Zipcodes['11575'] = [40.680422, -73.584877];
Zipcodes['11576'] = [40.796569, -73.646885];
Zipcodes['11577'] = [40.782867, -73.639109];
Zipcodes['11579'] = [40.844018, -73.644089];
Zipcodes['11580'] = [40.6749, -73.702154];
Zipcodes['11581'] = [40.651028, -73.715325];
Zipcodes['11590'] = [40.755182, -73.574338];
Zipcodes['11596'] = [40.759718, -73.64222];
Zipcodes['11598'] = [40.630935, -73.712339];
Zipcodes['11691'] = [40.602096, -73.761794];
Zipcodes['11692'] = [40.594154, -73.791986];
Zipcodes['11693'] = [40.591662, -73.809668];
Zipcodes['11694'] = [40.575314, -73.851235];
Zipcodes['11697'] = [40.557132, -73.913722];
Zipcodes['11701'] = [40.682451, -73.414382];
Zipcodes['11702'] = [40.636151, -73.383775];
Zipcodes['11703'] = [40.732676, -73.325003];
Zipcodes['11704'] = [40.715775, -73.359257];
Zipcodes['11705'] = [40.743264, -73.05585];
Zipcodes['11706'] = [40.710632, -73.242913];
Zipcodes['11709'] = [40.906313, -73.558244];
Zipcodes['11710'] = [40.672203, -73.534825];
Zipcodes['11713'] = [40.778396, -72.942761];
Zipcodes['11714'] = [40.742649, -73.486164];
Zipcodes['11715'] = [40.750968, -73.034924];
Zipcodes['11716'] = [40.777011, -73.13509];
Zipcodes['11717'] = [40.784072, -73.252235];
Zipcodes['11718'] = [40.716435, -73.261514];
Zipcodes['11719'] = [40.776671, -72.907833];
Zipcodes['11720'] = [40.87024, -73.082046];
Zipcodes['11721'] = [40.902425, -73.371427];
Zipcodes['11722'] = [40.78358, -73.196618];
Zipcodes['11724'] = [40.864322, -73.456406];
Zipcodes['11725'] = [40.840584, -73.280817];
Zipcodes['11726'] = [40.678993, -73.394445];
Zipcodes['11727'] = [40.880174, -73.003691];
Zipcodes['11729'] = [40.761136, -73.32526];
Zipcodes['11730'] = [40.727603, -73.18495];
Zipcodes['11731'] = [40.862614, -73.31704];
Zipcodes['11732'] = [40.842406, -73.539303];
Zipcodes['11733'] = [40.940235, -73.11278];
Zipcodes['11735'] = [40.732747, -73.432789];
Zipcodes['11738'] = [40.837767, -73.037682];
Zipcodes['11739'] = [40.710579, -73.165716];
Zipcodes['11740'] = [40.865347, -73.361205];
Zipcodes['11741'] = [40.794727, -73.070427];
Zipcodes['11742'] = [40.810354, -73.041875];
Zipcodes['11743'] = [40.883412, -73.423563];
Zipcodes['11746'] = [40.814268, -73.362276];
Zipcodes['11747'] = [40.783762, -73.409295];
Zipcodes['11749'] = [40.806766, -73.170802];
Zipcodes['11751'] = [40.730486, -73.213924];
Zipcodes['11752'] = [40.758346, -73.174234];
Zipcodes['11753'] = [40.789721, -73.541036];
Zipcodes['11754'] = [40.885566, -73.249901];
Zipcodes['11755'] = [40.857817, -73.116844];
Zipcodes['11756'] = [40.724512, -73.516326];
Zipcodes['11757'] = [40.689009, -73.37335];
Zipcodes['11758'] = [40.653822, -73.457076];
Zipcodes['11762'] = [40.682744, -73.446664];
Zipcodes['11763'] = [40.825275, -72.982436];
Zipcodes['11764'] = [40.92986, -72.973063];
Zipcodes['11765'] = [40.882132, -73.558968];
Zipcodes['11766'] = [40.939219, -73.018666];
Zipcodes['11767'] = [40.847754, -73.145703];
Zipcodes['11768'] = [40.923631, -73.339309];
Zipcodes['11769'] = [40.734504, -73.125222];
Zipcodes['11770'] = [40.649645, -73.140324];
Zipcodes['11771'] = [40.87373, -73.5253];
Zipcodes['11772'] = [40.76178, -72.987407];
Zipcodes['11776'] = [40.913456, -73.045674];
Zipcodes['11777'] = [40.956068, -73.066659];
Zipcodes['11778'] = [40.948046, -72.937093];
Zipcodes['11779'] = [40.812958, -73.11449];
Zipcodes['11780'] = [40.911113, -73.172509];
Zipcodes['11782'] = [40.736729, -73.079999];
Zipcodes['11783'] = [40.677612, -73.490014];
Zipcodes['11784'] = [40.868615, -73.042202];
Zipcodes['11786'] = [40.951398, -72.888417];
Zipcodes['11787'] = [40.852924, -73.21126];
Zipcodes['11788'] = [40.818085, -73.21319];
Zipcodes['11789'] = [40.962028, -72.971008];
Zipcodes['11790'] = [40.907804, -73.127479];
Zipcodes['11791'] = [40.827771, -73.502767];
Zipcodes['11792'] = [40.955033, -72.825569];
Zipcodes['11793'] = [40.647228, -73.51146];
Zipcodes['11794'] = [40.91919, -73.125015];
Zipcodes['11795'] = [40.709005, -73.296337];
Zipcodes['11796'] = [40.731247, -73.099419];
Zipcodes['11797'] = [40.820208, -73.47245];
Zipcodes['11798'] = [40.752632, -73.378372];
Zipcodes['11801'] = [40.762304, -73.524452];
Zipcodes['11803'] = [40.781771, -73.473118];
Zipcodes['11804'] = [40.758116, -73.456946];
Zipcodes['11901'] = [40.928102, -72.648249];
Zipcodes['11930'] = [40.991419, -72.114455];
Zipcodes['11931'] = [40.932628, -72.622259];
Zipcodes['11932'] = [40.9265, -72.305037];
Zipcodes['11933'] = [40.942231, -72.765594];
Zipcodes['11934'] = [40.802046, -72.795166];
Zipcodes['11935'] = [41.021223, -72.486958];
Zipcodes['11937'] = [40.994548, -72.198754];
Zipcodes['11939'] = [41.138956, -72.318687];
Zipcodes['11940'] = [40.808772, -72.756601];
Zipcodes['11941'] = [40.829543, -72.723395];
Zipcodes['11942'] = [40.848578, -72.572159];
Zipcodes['11944'] = [41.102475, -72.373228];
Zipcodes['11946'] = [40.864652, -72.518104];
Zipcodes['11947'] = [40.94301, -72.581883];
Zipcodes['11948'] = [40.960725, -72.54988];
Zipcodes['11949'] = [40.866503, -72.804643];
Zipcodes['11950'] = [40.806142, -72.847182];
Zipcodes['11951'] = [40.765722, -72.836722];
Zipcodes['11952'] = [41.001648, -72.547599];
Zipcodes['11953'] = [40.892509, -72.951175];
Zipcodes['11954'] = [41.046357, -71.948827];
Zipcodes['11955'] = [40.813511, -72.823823];
Zipcodes['11956'] = [40.991089, -72.472124];
Zipcodes['11957'] = [41.202981, -72.117081];
Zipcodes['11958'] = [41.039067, -72.464419];
Zipcodes['11959'] = [40.83029, -72.60976];
Zipcodes['11960'] = [40.810615, -72.706062];
Zipcodes['11961'] = [40.903814, -72.88588];
Zipcodes['11962'] = [40.930895, -72.268719];
Zipcodes['11963'] = [41.001696, -72.31962];
Zipcodes['11964'] = [41.049483, -72.302316];
Zipcodes['11965'] = [41.082501, -72.303379];
Zipcodes['11967'] = [40.801566, -72.878028];
Zipcodes['11968'] = [40.900359, -72.428605];
Zipcodes['11970'] = [40.941972, -72.572477];
Zipcodes['11971'] = [41.064714, -72.42906];
Zipcodes['11972'] = [40.822663, -72.700008];
Zipcodes['11973'] = [40.865846, -72.887206];
Zipcodes['11975'] = [40.948268, -72.249625];
Zipcodes['11976'] = [40.929237, -72.34741];
Zipcodes['11977'] = [40.828481, -72.679186];
Zipcodes['11978'] = [40.825621, -72.637502];
Zipcodes['11980'] = [40.831662, -72.924805];
Zipcodes['12007'] = [42.505651, -73.93531];
Zipcodes['12008'] = [42.853488, -73.906535];
Zipcodes['12009'] = [42.697723, -74.03535];
Zipcodes['12010'] = [42.937632, -74.173325];
Zipcodes['12015'] = [42.292243, -73.821532];
Zipcodes['12017'] = [42.32111, -73.455997];
Zipcodes['12018'] = [42.628833, -73.520771];
Zipcodes['12019'] = [42.934351, -73.881808];
Zipcodes['12020'] = [43.001663, -73.870089];
Zipcodes['12022'] = [42.666547, -73.333967];
Zipcodes['12023'] = [42.598558, -74.183022];
Zipcodes['12024'] = [42.481337, -73.534211];
Zipcodes['12025'] = [43.091396, -74.121889];
Zipcodes['12027'] = [42.921339, -73.907787];
Zipcodes['12028'] = [42.944934, -73.440913];
Zipcodes['12029'] = [42.41595, -73.424961];
Zipcodes['12031'] = [42.765752, -74.466129];
Zipcodes['12032'] = [43.287999, -74.591429];
Zipcodes['12033'] = [42.533097, -73.700086];
Zipcodes['12035'] = [42.733746, -74.371881];
Zipcodes['12036'] = [42.532547, -74.689392];
Zipcodes['12037'] = [42.338765, -73.565764];
Zipcodes['12040'] = [42.630519, -73.359094];
Zipcodes['12041'] = [42.543675, -73.979677];
Zipcodes['12042'] = [42.352516, -73.883817];
Zipcodes['12043'] = [42.69397, -74.530506];
Zipcodes['12045'] = [42.47966, -73.796833];
Zipcodes['12046'] = [42.50913, -73.917246];
Zipcodes['12047'] = [42.784054, -73.726219];
Zipcodes['12051'] = [42.346369, -73.838401];
Zipcodes['12052'] = [42.757369, -73.480124];
Zipcodes['12053'] = [42.75747, -74.191095];
Zipcodes['12054'] = [42.604548, -73.866239];
Zipcodes['12056'] = [42.764859, -74.100303];
Zipcodes['12057'] = [42.971284, -73.336406];
Zipcodes['12058'] = [42.344418, -73.917633];
Zipcodes['12059'] = [42.619223, -74.060881];
Zipcodes['12060'] = [42.410631, -73.505286];
Zipcodes['12061'] = [42.599312, -73.657175];
Zipcodes['12062'] = [42.538976, -73.504976];
Zipcodes['12063'] = [42.567308, -73.639545];
Zipcodes['12064'] = [42.57943, -74.646481];
Zipcodes['12065'] = [42.852284, -73.785597];
Zipcodes['12066'] = [42.781106, -74.303881];
Zipcodes['12067'] = [42.557461, -73.920294];
Zipcodes['12068'] = [42.956069, -74.401829];
Zipcodes['12069'] = [42.946036, -74.279938];
Zipcodes['12070'] = [42.986022, -74.26301];
Zipcodes['12071'] = [42.569706, -74.41519];
Zipcodes['12072'] = [42.884893, -74.356737];
Zipcodes['12074'] = [43.050577, -74.052937];
Zipcodes['12075'] = [42.304843, -73.641096];
Zipcodes['12076'] = [42.399716, -74.390523];
Zipcodes['12077'] = [42.58817, -73.777693];
Zipcodes['12078'] = [43.134351, -74.339632];
Zipcodes['12082'] = [42.769967, -73.446682];
Zipcodes['12083'] = [42.424859, -74.026509];
Zipcodes['12084'] = [42.704669, -73.899364];
Zipcodes['12085'] = [42.700531, -73.966639];
Zipcodes['12086'] = [42.996145, -74.1086];
Zipcodes['12087'] = [42.43225, -73.892894];
Zipcodes['12090'] = [42.876802, -73.351525];
Zipcodes['12092'] = [42.705938, -74.379565];
Zipcodes['12093'] = [42.497791, -74.628105];
Zipcodes['12094'] = [42.889977, -73.485945];
Zipcodes['12095'] = [43.034887, -74.423556];
Zipcodes['12106'] = [42.38851, -73.713796];
Zipcodes['12108'] = [43.467735, -74.435462];
Zipcodes['12110'] = [42.750565, -73.776095];
Zipcodes['12115'] = [42.467606, -73.602221];
Zipcodes['12116'] = [42.538795, -74.911164];
Zipcodes['12117'] = [43.167143, -74.261237];
Zipcodes['12118'] = [42.920932, -73.717031];
Zipcodes['12120'] = [42.465026, -74.135365];
Zipcodes['12121'] = [42.839913, -73.60738];
Zipcodes['12122'] = [42.54202, -74.326145];
Zipcodes['12123'] = [42.52214, -73.606447];
Zipcodes['12124'] = [42.448502, -73.788651];
Zipcodes['12125'] = [42.484748, -73.399844];
Zipcodes['12130'] = [42.441653, -73.658349];
Zipcodes['12131'] = [42.453371, -74.463913];
Zipcodes['12132'] = [42.471013, -73.633667];
Zipcodes['12134'] = [43.262075, -74.228016];
Zipcodes['12136'] = [42.420121, -73.565107];
Zipcodes['12137'] = [42.860344, -74.133085];
Zipcodes['12138'] = [42.748836, -73.370379];
Zipcodes['12139'] = [43.500221, -74.579544];
Zipcodes['12140'] = [42.68195, -73.571973];
Zipcodes['12143'] = [42.49156, -73.84266];
Zipcodes['12144'] = [42.627836, -73.717842];
Zipcodes['12147'] = [42.518611, -74.15884];
Zipcodes['12148'] = [42.827793, -73.848164];
Zipcodes['12149'] = [42.617973, -74.570557];
Zipcodes['12150'] = [42.880523, -74.052689];
Zipcodes['12151'] = [42.922667, -73.780649];
Zipcodes['12153'] = [42.63222, -73.4722];
Zipcodes['12154'] = [42.937482, -73.605806];
Zipcodes['12155'] = [42.596357, -74.827655];
Zipcodes['12156'] = [42.483504, -73.747254];
Zipcodes['12157'] = [42.669798, -74.286077];
Zipcodes['12158'] = [42.53301, -73.813578];
Zipcodes['12159'] = [42.651428, -73.885894];
Zipcodes['12160'] = [42.763359, -74.363539];
Zipcodes['12161'] = [42.5337, -73.846164];
Zipcodes['12164'] = [43.648293, -74.45158];
Zipcodes['12165'] = [42.315193, -73.507601];
Zipcodes['12166'] = [42.844631, -74.460191];
Zipcodes['12167'] = [42.423174, -74.598846];
Zipcodes['12168'] = [42.573634, -73.370724];
Zipcodes['12169'] = [42.592887, -73.448036];
Zipcodes['12170'] = [43.001956, -73.667271];
Zipcodes['12172'] = [42.289129, -73.738949];
Zipcodes['12173'] = [42.395485, -73.755478];
Zipcodes['12174'] = [42.353199, -73.72802];
Zipcodes['12175'] = [42.559492, -74.560411];
Zipcodes['12176'] = [42.384523, -73.966117];
Zipcodes['12177'] = [42.950858, -74.287087];
Zipcodes['12180'] = [42.748588, -73.599536];
Zipcodes['12182'] = [42.800534, -73.627434];
Zipcodes['12183'] = [42.747904, -73.692466];
Zipcodes['12184'] = [42.419893, -73.653359];
Zipcodes['12185'] = [42.862521, -73.554112];
Zipcodes['12186'] = [42.633112, -73.977172];
Zipcodes['12187'] = [42.628169, -74.464285];
Zipcodes['12188'] = [42.821216, -73.695525];
Zipcodes['12189'] = [42.736342, -73.717521];
Zipcodes['12190'] = [43.475631, -74.28541];
Zipcodes['12192'] = [42.400523, -73.825058];
Zipcodes['12193'] = [42.52276, -74.043268];
Zipcodes['12194'] = [42.538337, -74.454298];
Zipcodes['12195'] = [42.478801, -73.465002];
Zipcodes['12196'] = [42.631786, -73.613946];
Zipcodes['12197'] = [42.609999, -74.724749];
Zipcodes['12198'] = [42.672768, -73.633574];
Zipcodes['12202'] = [42.635166, -73.760028];
Zipcodes['12203'] = [42.682899, -73.849361];
Zipcodes['12204'] = [42.691516, -73.733751];
Zipcodes['12205'] = [42.718696, -73.824087];
Zipcodes['12206'] = [42.67419, -73.782047];
Zipcodes['12207'] = [42.655489, -73.747618];
Zipcodes['12208'] = [42.653031, -73.809978];
Zipcodes['12209'] = [42.638686, -73.790469];
Zipcodes['12210'] = [42.655709, -73.754868];
Zipcodes['12211'] = [42.70973, -73.763079];
Zipcodes['12222'] = [42.685261, -73.82323];
Zipcodes['12226'] = [42.679296, -73.80987];
Zipcodes['12302'] = [42.878182, -73.982505];
Zipcodes['12303'] = [42.748985, -73.929694];
Zipcodes['12304'] = [42.77469, -73.898297];
Zipcodes['12305'] = [42.812011, -73.950342];
Zipcodes['12306'] = [42.805429, -74.044227];
Zipcodes['12307'] = [42.804981, -73.932822];
Zipcodes['12308'] = [42.82134, -73.92093];
Zipcodes['12309'] = [42.79987, -73.864965];
Zipcodes['12401'] = [41.987541, -74.010273];
Zipcodes['12404'] = [41.818958, -74.236082];
Zipcodes['12405'] = [42.306958, -74.10097];
Zipcodes['12406'] = [42.094825, -74.576038];
Zipcodes['12407'] = [42.296731, -74.362067];
Zipcodes['12409'] = [42.055483, -74.165388];
Zipcodes['12410'] = [42.06578, -74.424757];
Zipcodes['12411'] = [41.879005, -74.039774];
Zipcodes['12412'] = [42.012194, -74.276989];
Zipcodes['12413'] = [42.308296, -74.025401];
Zipcodes['12414'] = [42.235006, -73.924019];
Zipcodes['12416'] = [42.095928, -74.279125];
Zipcodes['12417'] = [41.906422, -73.990723];
Zipcodes['12418'] = [42.358618, -74.160884];
Zipcodes['12419'] = [41.861119, -74.102017];
Zipcodes['12420'] = [41.675289, -74.393678];
Zipcodes['12421'] = [42.257055, -74.535228];
Zipcodes['12422'] = [42.391829, -74.213288];
Zipcodes['12423'] = [42.379743, -74.111368];
Zipcodes['12424'] = [42.247617, -74.115847];
Zipcodes['12427'] = [42.150748, -74.12407];
Zipcodes['12428'] = [41.734369, -74.457205];
Zipcodes['12429'] = [41.83327, -73.961001];
Zipcodes['12430'] = [42.200758, -74.49916];
Zipcodes['12431'] = [42.362023, -74.023533];
Zipcodes['12432'] = [42.047059, -73.94395];
Zipcodes['12433'] = [42.005335, -74.15527];
Zipcodes['12434'] = [42.362378, -74.508698];
Zipcodes['12435'] = [41.72174, -74.502139];
Zipcodes['12436'] = [42.213077, -74.078284];
Zipcodes['12438'] = [42.2103, -74.601029];
Zipcodes['12439'] = [42.277817, -74.21039];
Zipcodes['12440'] = [41.781151, -74.172877];
Zipcodes['12441'] = [42.150709, -74.504477];
Zipcodes['12442'] = [42.226884, -74.244054];
Zipcodes['12443'] = [41.934991, -74.084925];
Zipcodes['12444'] = [42.247485, -74.290618];
Zipcodes['12446'] = [41.789893, -74.313533];
Zipcodes['12448'] = [42.079556, -74.200687];
Zipcodes['12449'] = [41.992378, -73.993774];
Zipcodes['12450'] = [42.142232, -74.223318];
Zipcodes['12451'] = [42.304893, -73.948176];
Zipcodes['12452'] = [42.237405, -74.359025];
Zipcodes['12453'] = [42.092947, -73.936225];
Zipcodes['12454'] = [42.279821, -74.142025];
Zipcodes['12455'] = [42.149301, -74.661616];
Zipcodes['12456'] = [42.032705, -73.997133];
Zipcodes['12457'] = [42.03999, -74.246589];
Zipcodes['12458'] = [41.822918, -74.43208];
Zipcodes['12459'] = [42.240382, -74.678893];
Zipcodes['12460'] = [42.429272, -74.169025];
Zipcodes['12461'] = [41.908566, -74.247857];
Zipcodes['12463'] = [42.191625, -74.009276];
Zipcodes['12464'] = [42.06509, -74.346334];
Zipcodes['12465'] = [42.157153, -74.460016];
Zipcodes['12466'] = [41.906849, -73.977827];
Zipcodes['12468'] = [42.299033, -74.400732];
Zipcodes['12469'] = [42.448595, -74.246097];
Zipcodes['12470'] = [42.284999, -74.078273];
Zipcodes['12471'] = [41.837924, -74.04193];
Zipcodes['12472'] = [41.848783, -74.067404];
Zipcodes['12473'] = [42.260675, -74.031311];
Zipcodes['12474'] = [42.285102, -74.588008];
Zipcodes['12475'] = [42.013988, -74.013305];
Zipcodes['12477'] = [42.092591, -73.98811];
Zipcodes['12480'] = [42.135381, -74.391274];
Zipcodes['12481'] = [41.976695, -74.227962];
Zipcodes['12482'] = [42.268399, -73.955913];
Zipcodes['12483'] = [41.67459, -74.454933];
Zipcodes['12484'] = [41.86194, -74.176524];
Zipcodes['12485'] = [42.207224, -74.162231];
Zipcodes['12486'] = [41.832417, -74.063984];
Zipcodes['12487'] = [41.869399, -73.998805];
Zipcodes['12489'] = [41.754008, -74.35221];
Zipcodes['12490'] = [42.122854, -73.924476];
Zipcodes['12491'] = [41.965192, -74.141459];
Zipcodes['12492'] = [42.187583, -74.334525];
Zipcodes['12493'] = [41.794731, -73.971819];
Zipcodes['12494'] = [41.948566, -74.301701];
Zipcodes['12495'] = [42.082674, -74.245663];
Zipcodes['12496'] = [42.338036, -74.270087];
Zipcodes['12498'] = [42.045663, -74.109618];
Zipcodes['12501'] = [41.853415, -73.557726];
Zipcodes['12502'] = [42.086568, -73.664724];
Zipcodes['12503'] = [42.035959, -73.580146];
Zipcodes['12504'] = [42.023418, -73.909349];
Zipcodes['12507'] = [42.009241, -73.917141];
Zipcodes['12508'] = [41.495996, -73.953632];
Zipcodes['12512'] = [41.555184, -73.962452];
Zipcodes['12513'] = [42.219307, -73.720481];
Zipcodes['12514'] = [41.877409, -73.764423];
Zipcodes['12515'] = [41.679567, -74.065058];
Zipcodes['12516'] = [42.108104, -73.551361];
Zipcodes['12517'] = [42.128263, -73.508622];
Zipcodes['12518'] = [41.416157, -74.043064];
Zipcodes['12520'] = [41.428017, -73.996195];
Zipcodes['12521'] = [42.171335, -73.650487];
Zipcodes['12522'] = [41.719979, -73.598384];
Zipcodes['12523'] = [42.08687, -73.759509];
Zipcodes['12524'] = [41.533367, -73.884911];
Zipcodes['12525'] = [41.686274, -74.192293];
Zipcodes['12526'] = [42.122234, -73.858769];
Zipcodes['12527'] = [41.519348, -73.936846];
Zipcodes['12528'] = [41.713899, -73.99509];
Zipcodes['12529'] = [42.195932, -73.543215];
Zipcodes['12530'] = [42.206908, -73.68415];
Zipcodes['12531'] = [41.540534, -73.671592];
Zipcodes['12533'] = [41.559772, -73.787099];
Zipcodes['12534'] = [42.226412, -73.753552];
Zipcodes['12538'] = [41.79223, -73.893767];
Zipcodes['12540'] = [41.671202, -73.724891];
Zipcodes['12542'] = [41.613876, -74.015014];
Zipcodes['12543'] = [41.485945, -74.223538];
Zipcodes['12544'] = [42.261204, -73.668154];
Zipcodes['12545'] = [41.791481, -73.677166];
Zipcodes['12546'] = [41.948, -73.523572];
Zipcodes['12547'] = [41.660687, -73.986567];
Zipcodes['12548'] = [41.657925, -74.101503];
Zipcodes['12549'] = [41.528031, -74.259365];
Zipcodes['12550'] = [41.539574, -74.057223];
Zipcodes['12553'] = [41.460412, -74.071917];
Zipcodes['12561'] = [41.76404, -74.089495];
Zipcodes['12563'] = [41.499189, -73.587226];
Zipcodes['12564'] = [41.582614, -73.582577];
Zipcodes['12565'] = [42.249004, -73.646902];
Zipcodes['12566'] = [41.633519, -74.319661];
Zipcodes['12567'] = [41.985205, -73.653984];
Zipcodes['12569'] = [41.734556, -73.792835];
Zipcodes['12570'] = [41.634064, -73.672481];
Zipcodes['12571'] = [42.003049, -73.809806];
Zipcodes['12572'] = [41.924885, -73.863664];
Zipcodes['12574'] = [41.917615, -73.945701];
Zipcodes['12575'] = [41.486109, -74.158896];
Zipcodes['12577'] = [41.421483, -74.116815];
Zipcodes['12578'] = [41.806348, -73.797584];
Zipcodes['12580'] = [41.863647, -73.871536];
Zipcodes['12581'] = [41.901615, -73.700592];
Zipcodes['12582'] = [41.543465, -73.724951];
Zipcodes['12583'] = [42.05959, -73.883622];
Zipcodes['12585'] = [41.726404, -73.69151];
Zipcodes['12586'] = [41.565134, -74.171721];
Zipcodes['12589'] = [41.623791, -74.158771];
Zipcodes['12590'] = [41.596424, -73.888844];
Zipcodes['12592'] = [41.783874, -73.548857];
Zipcodes['12594'] = [41.658399, -73.573493];
Zipcodes['12601'] = [41.707792, -73.910601];
Zipcodes['12603'] = [41.675868, -73.864484];
Zipcodes['12604'] = [41.688427, -73.894464];
Zipcodes['12701'] = [41.650059, -74.698803];
Zipcodes['12719'] = [41.485553, -74.892326];
Zipcodes['12720'] = [41.645922, -74.900851];
Zipcodes['12721'] = [41.567327, -74.427273];
Zipcodes['12722'] = [41.59043, -74.37488];
Zipcodes['12723'] = [41.7531, -74.987694];
Zipcodes['12724'] = [41.859973, -74.936777];
Zipcodes['12725'] = [41.983183, -74.570105];
Zipcodes['12726'] = [41.695085, -74.966495];
Zipcodes['12729'] = [41.489701, -74.613104];
Zipcodes['12732'] = [41.554808, -74.873913];
Zipcodes['12733'] = [41.738991, -74.613862];
Zipcodes['12734'] = [41.734585, -74.75046];
Zipcodes['12736'] = [41.853249, -75.019224];
Zipcodes['12737'] = [41.501481, -74.798632];
Zipcodes['12738'] = [41.66569, -74.596987];
Zipcodes['12740'] = [41.938832, -74.434026];
Zipcodes['12741'] = [41.839141, -75.077725];
Zipcodes['12742'] = [41.722976, -74.716303];
Zipcodes['12743'] = [41.535072, -74.840382];
Zipcodes['12745'] = [41.78502, -75.026358];
Zipcodes['12746'] = [41.439355, -74.652385];
Zipcodes['12747'] = [41.751382, -74.641917];
Zipcodes['12748'] = [41.774776, -74.915179];
Zipcodes['12749'] = [41.695218, -74.83142];
Zipcodes['12750'] = [41.739083, -74.96733];
Zipcodes['12751'] = [41.687756, -74.662069];
Zipcodes['12752'] = [41.681031, -74.985485];
Zipcodes['12754'] = [41.798195, -74.736889];
Zipcodes['12758'] = [41.940283, -74.739118];
Zipcodes['12759'] = [41.786481, -74.660721];
Zipcodes['12760'] = [41.903935, -75.107847];
Zipcodes['12762'] = [41.660537, -74.791529];
Zipcodes['12763'] = [41.682429, -74.509779];
Zipcodes['12764'] = [41.591553, -74.995556];
Zipcodes['12765'] = [41.852341, -74.621165];
Zipcodes['12766'] = [41.815208, -74.978854];
Zipcodes['12767'] = [41.832509, -75.003384];
Zipcodes['12768'] = [41.864443, -74.730022];
Zipcodes['12769'] = [41.659255, -74.470685];
Zipcodes['12770'] = [41.447019, -74.841017];
Zipcodes['12771'] = [41.375285, -74.631515];
Zipcodes['12775'] = [41.621456, -74.581765];
Zipcodes['12776'] = [41.96339, -74.957793];
Zipcodes['12777'] = [41.561783, -74.709708];
Zipcodes['12778'] = [41.661739, -74.814205];
Zipcodes['12779'] = [41.690801, -74.62442];
Zipcodes['12780'] = [41.440146, -74.72735];
Zipcodes['12781'] = [41.614496, -74.472066];
Zipcodes['12783'] = [41.738633, -74.833724];
Zipcodes['12784'] = [41.6672, -74.611776];
Zipcodes['12785'] = [41.54996, -74.586143];
Zipcodes['12786'] = [41.63871, -74.857609];
Zipcodes['12787'] = [41.795394, -74.845029];
Zipcodes['12788'] = [41.795101, -74.590896];
Zipcodes['12789'] = [41.718402, -74.579965];
Zipcodes['12790'] = [41.563429, -74.527401];
Zipcodes['12791'] = [41.817275, -74.89287];
Zipcodes['12792'] = [41.51392, -74.95982];
Zipcodes['12801'] = [43.31116, -73.645286];
Zipcodes['12803'] = [43.288444, -73.631773];
Zipcodes['12804'] = [43.337295, -73.682664];
Zipcodes['12808'] = [43.749724, -73.747233];
Zipcodes['12809'] = [43.241628, -73.471797];
Zipcodes['12810'] = [43.491968, -73.985954];
Zipcodes['12811'] = [43.558108, -74.077663];
Zipcodes['12812'] = [43.875794, -74.389767];
Zipcodes['12814'] = [43.631894, -73.632551];
Zipcodes['12815'] = [43.701044, -73.691928];
Zipcodes['12816'] = [43.044399, -73.38195];
Zipcodes['12817'] = [43.639585, -73.823628];
Zipcodes['12819'] = [43.591007, -73.46881];
Zipcodes['12820'] = [43.460074, -73.640713];
Zipcodes['12821'] = [43.453952, -73.423436];
Zipcodes['12822'] = [43.228593, -73.922212];
Zipcodes['12823'] = [43.179961, -73.409117];
Zipcodes['12824'] = [43.524468, -73.711288];
Zipcodes['12827'] = [43.441986, -73.53086];
Zipcodes['12828'] = [43.254759, -73.559347];
Zipcodes['12831'] = [43.195329, -73.691811];
Zipcodes['12832'] = [43.358467, -73.330271];
Zipcodes['12833'] = [43.15, -73.840896];
Zipcodes['12834'] = [43.097971, -73.505306];
Zipcodes['12835'] = [43.332787, -74.006311];
Zipcodes['12836'] = [43.712347, -73.605597];
Zipcodes['12837'] = [43.476445, -73.255601];
Zipcodes['12838'] = [43.32986, -73.406378];
Zipcodes['12839'] = [43.354486, -73.553953];
Zipcodes['12841'] = [43.577274, -73.508701];
Zipcodes['12842'] = [43.719634, -74.279891];
Zipcodes['12843'] = [43.59448, -73.981571];
Zipcodes['12844'] = [43.485236, -73.653991];
Zipcodes['12845'] = [43.427874, -73.71105];
Zipcodes['12846'] = [43.346108, -73.789495];
Zipcodes['12847'] = [43.965763, -74.634962];
Zipcodes['12849'] = [43.441613, -73.300072];
Zipcodes['12850'] = [43.102616, -73.981441];
Zipcodes['12851'] = [43.855332, -74.118359];
Zipcodes['12852'] = [43.987997, -74.133336];
Zipcodes['12853'] = [43.662075, -74.064334];
Zipcodes['12854'] = [43.450009, -73.327576];
Zipcodes['12855'] = [44.02701, -73.758053];
Zipcodes['12856'] = [43.66594, -74.142961];
Zipcodes['12857'] = [43.833191, -73.946808];
Zipcodes['12858'] = [43.877982, -73.703373];
Zipcodes['12859'] = [43.155477, -73.904837];
Zipcodes['12860'] = [43.722132, -73.814178];
Zipcodes['12861'] = [43.746887, -73.417527];
Zipcodes['12862'] = [43.688347, -73.931958];
Zipcodes['12863'] = [43.060305, -73.931602];
Zipcodes['12864'] = [43.727403, -74.305353];
Zipcodes['12865'] = [43.214159, -73.347451];
Zipcodes['12866'] = [43.073715, -73.740236];
Zipcodes['12870'] = [43.839222, -73.765598];
Zipcodes['12871'] = [43.090571, -73.60425];
Zipcodes['12872'] = [43.883923, -73.722977];
Zipcodes['12873'] = [43.117451, -73.311646];
Zipcodes['12874'] = [43.690561, -73.549743];
Zipcodes['12878'] = [43.438184, -74.064361];
Zipcodes['12883'] = [43.83636, -73.553222];
Zipcodes['12884'] = [43.088495, -73.592146];
Zipcodes['12885'] = [43.52943, -73.835546];
Zipcodes['12886'] = [43.635164, -73.931497];
Zipcodes['12887'] = [43.53676, -73.365798];
Zipcodes['12901'] = [44.709098, -73.473547];
Zipcodes['12903'] = [44.656927, -73.46311];
Zipcodes['12910'] = [44.857044, -73.607875];
Zipcodes['12911'] = [44.535492, -73.468293];
Zipcodes['12912'] = [44.480941, -73.772482];
Zipcodes['12913'] = [44.430097, -74.002212];
Zipcodes['12914'] = [44.934151, -74.593651];
Zipcodes['12915'] = [44.853444, -74.034955];
Zipcodes['12916'] = [44.838194, -74.524672];
Zipcodes['12917'] = [44.924652, -74.173821];
Zipcodes['12918'] = [44.691158, -73.675119];
Zipcodes['12919'] = [44.96026, -73.44996];
Zipcodes['12920'] = [44.890975, -74.056569];
Zipcodes['12921'] = [44.894014, -73.432451];
Zipcodes['12922'] = [44.367987, -74.814407];
Zipcodes['12923'] = [44.945466, -73.958013];
Zipcodes['12924'] = [44.480055, -73.582645];
Zipcodes['12926'] = [44.950821, -74.329];
Zipcodes['12927'] = [44.20725, -74.809746];
Zipcodes['12928'] = [43.963837, -73.583249];
Zipcodes['12929'] = [44.719713, -73.719082];
Zipcodes['12930'] = [44.728144, -74.5397];
Zipcodes['12932'] = [44.214405, -73.608948];
Zipcodes['12933'] = [44.890994, -73.845341];
Zipcodes['12934'] = [44.811802, -73.864913];
Zipcodes['12935'] = [44.844657, -73.796933];
Zipcodes['12936'] = [44.285062, -73.40145];
Zipcodes['12937'] = [44.955224, -74.488146];
Zipcodes['12939'] = [44.432877, -74.16073];
Zipcodes['12941'] = [44.35493, -73.700216];
Zipcodes['12942'] = [44.261252, -73.796612];
Zipcodes['12943'] = [44.122772, -73.8835];
Zipcodes['12944'] = [44.433107, -73.533246];
Zipcodes['12945'] = [44.309091, -74.24044];
Zipcodes['12946'] = [44.206567, -74.046051];
Zipcodes['12950'] = [44.318369, -73.581246];
Zipcodes['12952'] = [44.710805, -73.903855];
Zipcodes['12953'] = [44.745352, -74.260037];
Zipcodes['12955'] = [44.780377, -73.944242];
Zipcodes['12956'] = [44.097612, -73.481333];
Zipcodes['12957'] = [44.853516, -74.577063];
Zipcodes['12958'] = [44.953539, -73.572924];
Zipcodes['12959'] = [44.952351, -73.719912];
Zipcodes['12960'] = [44.015756, -73.572348];
Zipcodes['12961'] = [44.061246, -73.587569];
Zipcodes['12962'] = [44.709232, -73.577065];
Zipcodes['12964'] = [44.131639, -73.633787];
Zipcodes['12965'] = [44.705892, -74.683958];
Zipcodes['12966'] = [44.793279, -74.425131];
Zipcodes['12967'] = [44.760957, -74.660517];
Zipcodes['12969'] = [44.710897, -74.09815];
Zipcodes['12970'] = [44.44625, -74.336722];
Zipcodes['12972'] = [44.561741, -73.559657];
Zipcodes['12973'] = [44.2841, -74.568534];
Zipcodes['12974'] = [44.060043, -73.464841];
Zipcodes['12975'] = [44.529758, -73.431095];
Zipcodes['12976'] = [44.503733, -74.230251];
Zipcodes['12977'] = [44.296612, -74.09891];
Zipcodes['12978'] = [44.61294, -73.789918];
Zipcodes['12979'] = [44.989281, -73.372178];
Zipcodes['12980'] = [44.563851, -74.524079];
Zipcodes['12981'] = [44.627099, -73.845851];
Zipcodes['12983'] = [44.348425, -74.143066];
Zipcodes['12985'] = [44.560709, -73.738967];
Zipcodes['12986'] = [44.224043, -74.433457];
Zipcodes['12987'] = [44.364183, -73.759382];
Zipcodes['12989'] = [44.538805, -74.060987];
Zipcodes['12992'] = [44.818238, -73.515569];
Zipcodes['12993'] = [44.193835, -73.488545];
Zipcodes['12996'] = [44.355266, -73.448055];
Zipcodes['12997'] = [44.372798, -73.892043];
Zipcodes['12998'] = [44.091296, -73.603669];
Zipcodes['13020'] = [42.816424, -76.074504];
Zipcodes['13021'] = [42.917704, -76.554611];
Zipcodes['13024'] = [42.934596, -76.574232];
Zipcodes['13026'] = [42.744043, -76.652266];
Zipcodes['13027'] = [43.171636, -76.35623];
Zipcodes['13028'] = [43.3028, -75.933212];
Zipcodes['13029'] = [43.225942, -76.151343];
Zipcodes['13030'] = [43.159236, -75.963913];
Zipcodes['13031'] = [43.045456, -76.306671];
Zipcodes['13032'] = [43.081617, -75.766018];
Zipcodes['13033'] = [43.187542, -76.573279];
Zipcodes['13034'] = [42.95678, -76.707909];
Zipcodes['13035'] = [42.940849, -75.830103];
Zipcodes['13036'] = [43.323525, -76.188961];
Zipcodes['13037'] = [43.067797, -75.854933];
Zipcodes['13039'] = [43.172142, -76.056249];
Zipcodes['13040'] = [42.559649, -75.930761];
Zipcodes['13041'] = [43.192089, -76.192302];
Zipcodes['13042'] = [43.26045, -75.85258];
Zipcodes['13044'] = [43.304757, -75.993273];
Zipcodes['13045'] = [42.584512, -76.190686];
Zipcodes['13051'] = [42.883189, -75.910112];
Zipcodes['13052'] = [42.73449, -75.869152];
Zipcodes['13053'] = [42.479575, -76.267918];
Zipcodes['13054'] = [43.169352, -75.666823];
Zipcodes['13057'] = [43.101955, -76.037845];
Zipcodes['13060'] = [43.022862, -76.411872];
Zipcodes['13061'] = [42.858748, -75.745465];
Zipcodes['13062'] = [42.481368, -76.386027];
Zipcodes['13063'] = [42.847861, -75.977956];
Zipcodes['13065'] = [42.818812, -76.815768];
Zipcodes['13066'] = [43.032344, -76.000553];
Zipcodes['13068'] = [42.492411, -76.359185];
Zipcodes['13069'] = [43.329276, -76.380655];
Zipcodes['13071'] = [42.675114, -76.542579];
Zipcodes['13072'] = [42.761342, -75.751696];
Zipcodes['13073'] = [42.58367, -76.387923];
Zipcodes['13074'] = [43.310917, -76.549843];
Zipcodes['13076'] = [43.353715, -76.149309];
Zipcodes['13077'] = [42.727973, -76.211042];
Zipcodes['13078'] = [42.958469, -76.06117];
Zipcodes['13080'] = [43.09321, -76.485258];
Zipcodes['13081'] = [42.673617, -76.627152];
Zipcodes['13082'] = [43.10273, -75.959888];
Zipcodes['13083'] = [43.645775, -75.98168];
Zipcodes['13084'] = [42.881106, -76.124074];
Zipcodes['13087'] = [42.696836, -76.169764];
Zipcodes['13088'] = [43.107862, -76.190473];
Zipcodes['13090'] = [43.153084, -76.212402];
Zipcodes['13092'] = [42.651791, -76.426069];
Zipcodes['13101'] = [42.613308, -76.066405];
Zipcodes['13102'] = [42.550304, -76.292002];
Zipcodes['13103'] = [43.326604, -76.104944];
Zipcodes['13104'] = [42.96303, -75.948439];
Zipcodes['13108'] = [42.964845, -76.331113];
Zipcodes['13110'] = [42.888747, -76.290192];
Zipcodes['13111'] = [43.258488, -76.614264];
Zipcodes['13112'] = [43.106266, -76.417239];
Zipcodes['13113'] = [43.164221, -76.534883];
Zipcodes['13114'] = [43.465743, -76.242836];
Zipcodes['13115'] = [43.393725, -76.474679];
Zipcodes['13116'] = [43.074532, -76.007925];
Zipcodes['13117'] = [43.008063, -76.707683];
Zipcodes['13118'] = [42.754959, -76.401439];
Zipcodes['13119'] = [42.976556, -76.444902];
Zipcodes['13120'] = [42.945178, -76.164321];
Zipcodes['13121'] = [43.484889, -76.309694];
Zipcodes['13122'] = [42.837754, -75.86152];
Zipcodes['13123'] = [43.227964, -75.75321];
Zipcodes['13124'] = [42.661074, -75.823604];
Zipcodes['13126'] = [43.438798, -76.457454];
Zipcodes['13131'] = [43.423243, -76.091802];
Zipcodes['13132'] = [43.271918, -76.251052];
Zipcodes['13134'] = [42.967368, -75.684678];
Zipcodes['13135'] = [43.253687, -76.314375];
Zipcodes['13136'] = [42.607066, -75.853761];
Zipcodes['13138'] = [42.899118, -76.01468];
Zipcodes['13140'] = [43.060381, -76.650274];
Zipcodes['13141'] = [42.752495, -76.185697];
Zipcodes['13142'] = [43.556184, -76.138199];
Zipcodes['13143'] = [43.229857, -76.716549];
Zipcodes['13144'] = [43.568755, -75.977019];
Zipcodes['13145'] = [43.651893, -76.123188];
Zipcodes['13146'] = [43.100853, -76.756859];
Zipcodes['13147'] = [42.775406, -76.569608];
Zipcodes['13148'] = [42.921458, -76.778324];
Zipcodes['13152'] = [42.905241, -76.381517];
Zipcodes['13153'] = [42.994629, -76.454808];
Zipcodes['13154'] = [43.133795, -76.761877];
Zipcodes['13155'] = [42.674546, -75.776726];
Zipcodes['13156'] = [43.32599, -76.661306];
Zipcodes['13157'] = [43.202419, -75.725183];
Zipcodes['13158'] = [42.717351, -75.973408];
Zipcodes['13159'] = [42.800989, -76.118111];
Zipcodes['13160'] = [42.827598, -76.648904];
Zipcodes['13162'] = [43.185787, -75.721032];
Zipcodes['13163'] = [43.078917, -75.706892];
Zipcodes['13164'] = [43.096855, -76.31264];
Zipcodes['13165'] = [42.910746, -76.878565];
Zipcodes['13166'] = [43.078935, -76.565379];
Zipcodes['13167'] = [43.326421, -76.06026];
Zipcodes['13202'] = [43.043513, -76.150663];
Zipcodes['13203'] = [43.060978, -76.134868];
Zipcodes['13204'] = [43.051756, -76.178];
Zipcodes['13205'] = [43.004691, -76.141596];
Zipcodes['13206'] = [43.073525, -76.105813];
Zipcodes['13207'] = [43.012893, -76.163281];
Zipcodes['13208'] = [43.078747, -76.145223];
Zipcodes['13209'] = [43.081013, -76.236271];
Zipcodes['13210'] = [43.03142, -76.125816];
Zipcodes['13211'] = [43.10332, -76.119545];
Zipcodes['13212'] = [43.128583, -76.129694];
Zipcodes['13214'] = [43.040531, -76.076762];
Zipcodes['13215'] = [42.980793, -76.223386];
Zipcodes['13219'] = [43.039094, -76.22332];
Zipcodes['13224'] = [43.038917, -76.102603];
Zipcodes['13244'] = [43.02304, -76.123331];
Zipcodes['13290'] = [43.069136, -76.173017];
Zipcodes['13301'] = [43.422874, -75.215877];
Zipcodes['13302'] = [43.498155, -75.965146];
Zipcodes['13303'] = [43.367414, -75.468212];
Zipcodes['13304'] = [43.240367, -75.157043];
Zipcodes['13305'] = [43.895228, -75.430765];
Zipcodes['13308'] = [43.251404, -75.6578];
Zipcodes['13309'] = [43.478607, -75.339026];
Zipcodes['13310'] = [42.88962, -75.571545];
Zipcodes['13312'] = [43.691814, -75.20452];
Zipcodes['13313'] = [42.879135, -75.245341];
Zipcodes['13314'] = [42.827552, -75.301026];
Zipcodes['13315'] = [42.739121, -75.136119];
Zipcodes['13316'] = [43.418917, -75.73974];
Zipcodes['13317'] = [42.850257, -74.586055];
Zipcodes['13318'] = [42.921536, -75.260063];
Zipcodes['13319'] = [43.02709, -75.265991];
Zipcodes['13320'] = [42.777805, -74.740798];
Zipcodes['13321'] = [43.08896, -75.372252];
Zipcodes['13322'] = [42.977305, -75.198364];
Zipcodes['13323'] = [43.039981, -75.379353];
Zipcodes['13324'] = [43.319228, -74.916822];
Zipcodes['13325'] = [43.582505, -75.54406];
Zipcodes['13326'] = [42.722511, -74.895371];
Zipcodes['13327'] = [43.972873, -75.262642];
Zipcodes['13328'] = [42.98251, -75.428351];
Zipcodes['13329'] = [43.107462, -74.708431];
Zipcodes['13331'] = [43.821769, -75.040114];
Zipcodes['13332'] = [42.764159, -75.581736];
Zipcodes['13333'] = [42.825234, -74.827936];
Zipcodes['13334'] = [42.83071, -75.662889];
Zipcodes['13335'] = [42.70226, -75.248224];
Zipcodes['13337'] = [42.757804, -74.999545];
Zipcodes['13338'] = [43.528032, -74.987121];
Zipcodes['13339'] = [42.936895, -74.658658];
Zipcodes['13340'] = [43.061642, -75.12857];
Zipcodes['13342'] = [42.644136, -75.191592];
Zipcodes['13343'] = [43.751155, -75.309368];
Zipcodes['13345'] = [43.687077, -75.32333];
Zipcodes['13346'] = [42.820785, -75.546393];
Zipcodes['13348'] = [42.707256, -75.057189];
Zipcodes['13350'] = [43.061013, -74.986356];
Zipcodes['13352'] = [43.308948, -75.126654];
Zipcodes['13353'] = [43.447279, -74.694629];
Zipcodes['13354'] = [43.264185, -75.278559];
Zipcodes['13355'] = [42.81045, -75.421645];
Zipcodes['13357'] = [42.971061, -75.081209];
Zipcodes['13360'] = [43.722272, -74.714466];
Zipcodes['13361'] = [42.898111, -74.865747];
Zipcodes['13363'] = [43.337328, -75.512822];
Zipcodes['13364'] = [42.804969, -75.261098];
Zipcodes['13365'] = [43.098217, -74.848379];
Zipcodes['13367'] = [43.733064, -75.537];
Zipcodes['13368'] = [43.643649, -75.333839];
Zipcodes['13402'] = [42.901621, -75.509748];
Zipcodes['13403'] = [43.164533, -75.267645];
Zipcodes['13404'] = [43.738437, -75.480939];
Zipcodes['13406'] = [43.141426, -74.918203];
Zipcodes['13407'] = [42.965377, -74.944407];
Zipcodes['13408'] = [42.91902, -75.664315];
Zipcodes['13409'] = [42.976021, -75.591288];
Zipcodes['13410'] = [42.928889, -74.611014];
Zipcodes['13411'] = [42.633789, -75.306457];
Zipcodes['13413'] = [43.06156, -75.276199];
Zipcodes['13415'] = [42.597126, -75.197316];
Zipcodes['13416'] = [43.187371, -74.970659];
Zipcodes['13417'] = [43.100039, -75.293915];
Zipcodes['13418'] = [42.845704, -75.38046];
Zipcodes['13420'] = [43.654518, -75.013441];
Zipcodes['13421'] = [43.061915, -75.660092];
Zipcodes['13424'] = [43.152276, -75.362931];
Zipcodes['13425'] = [42.963463, -75.486698];
Zipcodes['13428'] = [42.92189, -74.529374];
Zipcodes['13431'] = [43.209081, -75.073326];
Zipcodes['13433'] = [43.594911, -75.248869];
Zipcodes['13435'] = [43.302212, -75.152526];
Zipcodes['13436'] = [43.780383, -74.6591];
Zipcodes['13437'] = [43.580319, -75.803835];
Zipcodes['13438'] = [43.354138, -75.159613];
Zipcodes['13439'] = [42.855311, -74.997219];
Zipcodes['13440'] = [43.217939, -75.459191];
Zipcodes['13441'] = [43.2271, -75.407607];
Zipcodes['13450'] = [42.702585, -74.812602];
Zipcodes['13452'] = [43.043056, -74.63047];
Zipcodes['13454'] = [43.170329, -74.75841];
Zipcodes['13455'] = [42.917938, -75.376567];
Zipcodes['13456'] = [43.001459, -75.258555];
Zipcodes['13457'] = [42.775248, -75.036865];
Zipcodes['13459'] = [42.775008, -74.588678];
Zipcodes['13460'] = [42.687707, -75.44229];
Zipcodes['13461'] = [43.071174, -75.598623];
Zipcodes['13464'] = [42.687973, -75.615788];
Zipcodes['13468'] = [42.84962, -74.873605];
Zipcodes['13469'] = [43.214828, -75.302367];
Zipcodes['13470'] = [43.209225, -74.666463];
Zipcodes['13471'] = [43.378027, -75.610579];
Zipcodes['13472'] = [43.697447, -75.068069];
Zipcodes['13473'] = [43.644146, -75.445192];
Zipcodes['13475'] = [42.887908, -74.811902];
Zipcodes['13476'] = [43.087503, -75.509514];
Zipcodes['13477'] = [43.03635, -75.514428];
Zipcodes['13478'] = [43.144012, -75.581897];
Zipcodes['13480'] = [42.917854, -75.363169];
Zipcodes['13482'] = [42.695183, -75.168175];
Zipcodes['13483'] = [43.406118, -75.814663];
Zipcodes['13484'] = [42.854205, -75.655448];
Zipcodes['13485'] = [42.787528, -75.315224];
Zipcodes['13486'] = [43.357311, -75.336896];
Zipcodes['13488'] = [42.674472, -74.76798];
Zipcodes['13489'] = [43.466569, -75.522386];
Zipcodes['13490'] = [43.106158, -75.429918];
Zipcodes['13491'] = [42.864135, -75.168133];
Zipcodes['13492'] = [43.114604, -75.33768];
Zipcodes['13493'] = [43.432843, -75.897509];
Zipcodes['13494'] = [43.5345, -75.152109];
Zipcodes['13495'] = [43.110927, -75.277596];
Zipcodes['13501'] = [43.081283, -75.225833];
Zipcodes['13502'] = [43.141264, -75.154707];
Zipcodes['13601'] = [43.969033, -75.910451];
Zipcodes['13602'] = [44.049565, -75.74891];
Zipcodes['13603'] = [44.029919, -75.803567];
Zipcodes['13605'] = [43.807377, -76.050124];
Zipcodes['13606'] = [43.869772, -76.016098];
Zipcodes['13607'] = [44.331832, -75.909506];
Zipcodes['13608'] = [44.26094, -75.613698];
Zipcodes['13611'] = [43.770091, -76.131182];
Zipcodes['13612'] = [43.985237, -75.76937];
Zipcodes['13613'] = [44.843198, -74.749024];
Zipcodes['13614'] = [44.530819, -75.691959];
Zipcodes['13615'] = [44.005307, -75.98307];
Zipcodes['13616'] = [44.030078, -75.857573];
Zipcodes['13617'] = [44.580531, -75.142767];
Zipcodes['13618'] = [44.114766, -76.290981];
Zipcodes['13619'] = [43.977357, -75.592254];
Zipcodes['13620'] = [43.908782, -75.448325];
Zipcodes['13621'] = [44.839402, -75.061698];
Zipcodes['13622'] = [44.095693, -76.115168];
Zipcodes['13623'] = [44.448693, -75.75305];
Zipcodes['13624'] = [44.229421, -76.093817];
Zipcodes['13625'] = [44.542508, -74.910389];
Zipcodes['13626'] = [43.853872, -75.718531];
Zipcodes['13628'] = [44.03904, -75.682765];
Zipcodes['13630'] = [44.498619, -75.311532];
Zipcodes['13632'] = [44.126946, -76.068232];
Zipcodes['13633'] = [44.484318, -75.499185];
Zipcodes['13634'] = [44.015036, -76.071765];
Zipcodes['13635'] = [44.286952, -75.281419];
Zipcodes['13636'] = [43.741087, -76.127692];
Zipcodes['13637'] = [44.104235, -75.842942];
Zipcodes['13638'] = [44.0196, -75.753501];
Zipcodes['13639'] = [44.263676, -75.150529];
Zipcodes['13640'] = [44.332661, -75.990153];
Zipcodes['13641'] = [44.27035, -76.006427];
Zipcodes['13642'] = [44.326672, -75.467588];
Zipcodes['13643'] = [44.032212, -75.717601];
Zipcodes['13645'] = [44.30878, -75.446555];
Zipcodes['13646'] = [44.443945, -75.691973];
Zipcodes['13647'] = [44.601656, -74.992702];
Zipcodes['13648'] = [44.150796, -75.304321];
Zipcodes['13649'] = [44.912408, -74.699785];
Zipcodes['13650'] = [43.81131, -76.216097];
Zipcodes['13651'] = [43.866158, -76.176966];
Zipcodes['13652'] = [44.434622, -75.213569];
Zipcodes['13654'] = [44.571772, -75.488098];
Zipcodes['13655'] = [44.978707, -74.643075];
Zipcodes['13656'] = [44.193265, -75.954645];
Zipcodes['13658'] = [44.747782, -75.271203];
Zipcodes['13659'] = [43.738005, -75.86338];
Zipcodes['13660'] = [44.773868, -75.160958];
Zipcodes['13661'] = [43.70962, -76.098396];
Zipcodes['13662'] = [44.937303, -74.894161];
Zipcodes['13664'] = [44.584013, -75.64546];
Zipcodes['13665'] = [44.04846, -75.434366];
Zipcodes['13666'] = [44.219176, -74.947563];
Zipcodes['13667'] = [44.802907, -75.020475];
Zipcodes['13668'] = [44.75482, -74.986306];
Zipcodes['13669'] = [44.659238, -75.491627];
Zipcodes['13670'] = [44.18679, -75.099813];
Zipcodes['13672'] = [44.470543, -74.660706];
Zipcodes['13673'] = [44.180845, -75.726044];
Zipcodes['13674'] = [43.737328, -76.051881];
Zipcodes['13675'] = [44.277033, -75.843656];
Zipcodes['13676'] = [44.655468, -74.910189];
Zipcodes['13678'] = [44.831635, -74.967796];
Zipcodes['13679'] = [44.326117, -75.765192];
Zipcodes['13680'] = [44.593601, -75.329147];
Zipcodes['13681'] = [44.428054, -75.373151];
Zipcodes['13682'] = [43.843312, -75.901905];
Zipcodes['13683'] = [44.973878, -74.735483];
Zipcodes['13684'] = [44.356596, -75.013141];
Zipcodes['13685'] = [43.925469, -76.085527];
Zipcodes['13687'] = [44.439018, -74.838177];
Zipcodes['13690'] = [44.136508, -75.01441];
Zipcodes['13691'] = [44.231562, -75.770537];
Zipcodes['13692'] = [44.288621, -76.02574];
Zipcodes['13693'] = [43.985747, -76.246105];
Zipcodes['13694'] = [44.859437, -75.170668];
Zipcodes['13695'] = [44.102929, -74.922258];
Zipcodes['13696'] = [44.70411, -74.889639];
Zipcodes['13697'] = [44.754714, -74.810347];
Zipcodes['13699'] = [44.663703, -74.998445];
Zipcodes['13730'] = [42.231996, -75.532645];
Zipcodes['13731'] = [42.12963, -74.787804];
Zipcodes['13732'] = [42.049447, -76.167535];
Zipcodes['13733'] = [42.301807, -75.479756];
Zipcodes['13734'] = [42.069955, -76.409653];
Zipcodes['13736'] = [42.323529, -76.192941];
Zipcodes['13738'] = [42.562317, -76.119762];
Zipcodes['13739'] = [42.349124, -74.789149];
Zipcodes['13740'] = [42.273812, -74.756997];
Zipcodes['13743'] = [42.211894, -76.338222];
Zipcodes['13744'] = [42.243494, -75.907999];
Zipcodes['13746'] = [42.237222, -75.862775];
Zipcodes['13747'] = [42.48997, -74.982049];
Zipcodes['13748'] = [42.042575, -75.822509];
Zipcodes['13750'] = [42.477535, -74.835779];
Zipcodes['13751'] = [42.45823, -74.875158];
Zipcodes['13752'] = [42.172981, -74.903366];
Zipcodes['13753'] = [42.30696, -74.926915];
Zipcodes['13754'] = [42.088461, -75.447935];
Zipcodes['13755'] = [42.071439, -75.023923];
Zipcodes['13756'] = [42.004099, -75.10067];
Zipcodes['13757'] = [42.43209, -74.837828];
Zipcodes['13760'] = [42.133332, -76.084622];
Zipcodes['13774'] = [41.958737, -75.184604];
Zipcodes['13775'] = [42.327909, -75.134022];
Zipcodes['13776'] = [42.471138, -75.34124];
Zipcodes['13777'] = [42.258939, -75.986621];
Zipcodes['13778'] = [42.359214, -75.759499];
Zipcodes['13780'] = [42.422485, -75.476583];
Zipcodes['13782'] = [42.170115, -74.991251];
Zipcodes['13783'] = [41.986818, -75.271017];
Zipcodes['13784'] = [42.435289, -76.217749];
Zipcodes['13786'] = [42.445214, -74.702862];
Zipcodes['13787'] = [42.2184, -75.676696];
Zipcodes['13788'] = [42.339007, -74.666257];
Zipcodes['13790'] = [42.165361, -76.001942];
Zipcodes['13795'] = [42.056466, -75.779234];
Zipcodes['13796'] = [42.557188, -75.13213];
Zipcodes['13797'] = [42.333665, -76.040401];
Zipcodes['13801'] = [42.505085, -75.77697];
Zipcodes['13802'] = [42.242134, -76.041266];
Zipcodes['13803'] = [42.457979, -76.053971];
Zipcodes['13804'] = [42.225722, -75.384222];
Zipcodes['13806'] = [42.380639, -74.976099];
Zipcodes['13807'] = [42.61747, -74.97282];
Zipcodes['13808'] = [42.525233, -75.2622];
Zipcodes['13809'] = [42.408479, -75.391387];
Zipcodes['13810'] = [42.606817, -75.108363];
Zipcodes['13811'] = [42.234926, -76.164054];
Zipcodes['13812'] = [42.03006, -76.354752];
Zipcodes['13813'] = [42.180591, -75.550314];
Zipcodes['13814'] = [42.616514, -75.525978];
Zipcodes['13815'] = [42.547416, -75.533555];
Zipcodes['13820'] = [42.483412, -75.036892];
Zipcodes['13825'] = [42.435577, -75.204553];
Zipcodes['13826'] = [42.091802, -75.633696];
Zipcodes['13827'] = [42.117827, -76.249166];
Zipcodes['13830'] = [42.442748, -75.644652];
Zipcodes['13832'] = [42.654592, -75.662548];
Zipcodes['13833'] = [42.19801, -75.761634];
Zipcodes['13834'] = [42.55051, -74.967342];
Zipcodes['13835'] = [42.383214, -76.178996];
Zipcodes['13838'] = [42.288548, -75.396089];
Zipcodes['13839'] = [42.240354, -75.259059];
Zipcodes['13841'] = [42.40746, -75.840256];
Zipcodes['13842'] = [42.367883, -74.721761];
Zipcodes['13843'] = [42.556073, -75.349014];
Zipcodes['13844'] = [42.605699, -75.689554];
Zipcodes['13846'] = [42.363766, -75.054796];
Zipcodes['13847'] = [42.204496, -75.277712];
Zipcodes['13849'] = [42.341173, -75.321965];
Zipcodes['13850'] = [42.049395, -76.017594];
Zipcodes['13856'] = [42.171041, -75.183316];
Zipcodes['13859'] = [42.371214, -75.249146];
Zipcodes['13860'] = [42.458443, -74.945534];
Zipcodes['13861'] = [42.504527, -75.138979];
Zipcodes['13862'] = [42.33092, -75.931125];
Zipcodes['13863'] = [42.435402, -75.906243];
Zipcodes['13864'] = [42.274969, -76.390479];
Zipcodes['13865'] = [42.058561, -75.62477];
Zipcodes['13901'] = [42.183203, -75.876048];
Zipcodes['13902'] = [42.088824, -75.968883];
Zipcodes['13903'] = [42.04187, -75.889077];
Zipcodes['13904'] = [42.134123, -75.820206];
Zipcodes['13905'] = [42.170436, -75.943434];
Zipcodes['14001'] = [43.036573, -78.510831];
Zipcodes['14004'] = [42.891377, -78.503698];
Zipcodes['14005'] = [42.920586, -78.250065];
Zipcodes['14006'] = [42.63313, -79.021745];
Zipcodes['14008'] = [43.312726, -78.623166];
Zipcodes['14009'] = [42.593154, -78.397461];
Zipcodes['14011'] = [42.830674, -78.299709];
Zipcodes['14012'] = [43.332173, -78.532622];
Zipcodes['14013'] = [43.07623, -78.39429];
Zipcodes['14020'] = [42.99619, -78.213412];
Zipcodes['14024'] = [42.581363, -78.245468];
Zipcodes['14025'] = [42.623195, -78.726064];
Zipcodes['14026'] = [42.942195, -78.687972];
Zipcodes['14027'] = [42.594092, -79.020662];
Zipcodes['14028'] = [43.314596, -78.717056];
Zipcodes['14029'] = [42.472913, -78.263441];
Zipcodes['14030'] = [42.559702, -78.490061];
Zipcodes['14031'] = [42.983128, -78.614258];
Zipcodes['14032'] = [43.045872, -78.631896];
Zipcodes['14033'] = [42.660303, -78.684251];
Zipcodes['14034'] = [42.500569, -78.865375];
Zipcodes['14035'] = [42.490983, -78.859431];
Zipcodes['14036'] = [42.973851, -78.389527];
Zipcodes['14037'] = [42.802789, -78.453328];
Zipcodes['14039'] = [42.85148, -78.177691];
Zipcodes['14040'] = [42.882168, -78.377535];
Zipcodes['14041'] = [42.400393, -78.976646];
Zipcodes['14042'] = [42.475564, -78.488807];
Zipcodes['14043'] = [42.900724, -78.704522];
Zipcodes['14047'] = [42.687367, -78.986636];
Zipcodes['14048'] = [42.486763, -79.313622];
Zipcodes['14051'] = [43.042842, -78.699413];
Zipcodes['14052'] = [42.772531, -78.584321];
Zipcodes['14054'] = [42.908974, -78.127841];
Zipcodes['14055'] = [42.56288, -78.600125];
Zipcodes['14056'] = [42.991805, -78.306603];
Zipcodes['14057'] = [42.646574, -78.874782];
Zipcodes['14058'] = [43.102049, -78.168857];
Zipcodes['14059'] = [42.833552, -78.634046];
Zipcodes['14060'] = [42.441626, -78.316063];
Zipcodes['14061'] = [42.5911, -79.089486];
Zipcodes['14062'] = [42.450893, -79.152595];
Zipcodes['14063'] = [42.40869, -79.331304];
Zipcodes['14065'] = [42.484905, -78.312811];
Zipcodes['14066'] = [42.629054, -78.185536];
Zipcodes['14067'] = [43.21216, -78.556274];
Zipcodes['14068'] = [43.02877, -78.758797];
Zipcodes['14069'] = [42.600306, -78.638389];
Zipcodes['14070'] = [42.419658, -78.917725];
Zipcodes['14072'] = [43.017901, -78.962657];
Zipcodes['14075'] = [42.713375, -78.830255];
Zipcodes['14080'] = [42.634728, -78.560834];
Zipcodes['14081'] = [42.563203, -79.067834];
Zipcodes['14082'] = [42.656563, -78.385184];
Zipcodes['14083'] = [42.679203, -78.434411];
Zipcodes['14085'] = [42.714292, -78.927674];
Zipcodes['14086'] = [42.905511, -78.629121];
Zipcodes['14091'] = [42.538233, -78.893008];
Zipcodes['14092'] = [43.173245, -78.993068];
Zipcodes['14094'] = [43.157758, -78.701995];
Zipcodes['14098'] = [43.334633, -78.378309];
Zipcodes['14101'] = [42.392651, -78.538203];
Zipcodes['14102'] = [42.842726, -78.554821];
Zipcodes['14103'] = [43.211161, -78.37677];
Zipcodes['14105'] = [43.180186, -78.486578];
Zipcodes['14108'] = [43.263678, -78.727477];
Zipcodes['14109'] = [43.137212, -79.035127];
Zipcodes['14111'] = [42.584777, -78.895112];
Zipcodes['14112'] = [42.698262, -78.939582];
Zipcodes['14113'] = [42.660593, -78.322567];
Zipcodes['14120'] = [43.079176, -78.842777];
Zipcodes['14125'] = [43.084919, -78.274609];
Zipcodes['14126'] = [43.335884, -78.72858];
Zipcodes['14127'] = [42.752803, -78.739697];
Zipcodes['14129'] = [42.481182, -79.017384];
Zipcodes['14130'] = [42.553172, -78.149542];
Zipcodes['14131'] = [43.238603, -78.899823];
Zipcodes['14132'] = [43.147873, -78.878565];
Zipcodes['14134'] = [42.53055, -78.517385];
Zipcodes['14135'] = [42.484894, -79.243699];
Zipcodes['14136'] = [42.517379, -79.174998];
Zipcodes['14138'] = [42.383178, -79.05262];
Zipcodes['14139'] = [42.718405, -78.541402];
Zipcodes['14141'] = [42.524967, -78.712076];
Zipcodes['14143'] = [42.977044, -78.0758];
Zipcodes['14145'] = [42.734036, -78.428432];
Zipcodes['14150'] = [42.998084, -78.878256];
Zipcodes['14166'] = [42.450971, -79.416054];
Zipcodes['14167'] = [42.74841, -78.323002];
Zipcodes['14168'] = [42.511876, -78.987407];
Zipcodes['14169'] = [42.764514, -78.52935];
Zipcodes['14170'] = [42.700245, -78.674307];
Zipcodes['14171'] = [42.424674, -78.649832];
Zipcodes['14172'] = [43.272672, -78.812943];
Zipcodes['14173'] = [42.525944, -78.473112];
Zipcodes['14174'] = [43.249139, -78.998325];
Zipcodes['14201'] = [42.89763, -78.887897];
Zipcodes['14202'] = [42.881493, -78.877484];
Zipcodes['14203'] = [42.861134, -78.863606];
Zipcodes['14204'] = [42.881789, -78.861904];
Zipcodes['14206'] = [42.88081, -78.810156];
Zipcodes['14207'] = [42.951932, -78.898883];
Zipcodes['14208'] = [42.914615, -78.851459];
Zipcodes['14209'] = [42.91367, -78.866209];
Zipcodes['14210'] = [42.862657, -78.828768];
Zipcodes['14211'] = [42.906731, -78.819891];
Zipcodes['14212'] = [42.895001, -78.819515];
Zipcodes['14213'] = [42.917239, -78.891814];
Zipcodes['14214'] = [42.939793, -78.841077];
Zipcodes['14215'] = [42.934545, -78.810223];
Zipcodes['14216'] = [42.949629, -78.862483];
Zipcodes['14217'] = [42.971927, -78.876759];
Zipcodes['14218'] = [42.818798, -78.82904];
Zipcodes['14219'] = [42.788675, -78.826431];
Zipcodes['14220'] = [42.845738, -78.822076];
Zipcodes['14221'] = [42.984481, -78.722761];
Zipcodes['14222'] = [42.920027, -78.877108];
Zipcodes['14223'] = [42.973459, -78.846106];
Zipcodes['14224'] = [42.837759, -78.747821];
Zipcodes['14225'] = [42.928949, -78.750273];
Zipcodes['14226'] = [42.970345, -78.798005];
Zipcodes['14227'] = [42.883995, -78.746668];
Zipcodes['14228'] = [43.044371, -78.777335];
Zipcodes['14260'] = [43.001682, -78.790638];
Zipcodes['14261'] = [42.997126, -78.792129];
Zipcodes['14301'] = [43.097013, -79.037322];
Zipcodes['14302'] = [43.093896, -79.049193];
Zipcodes['14303'] = [43.084183, -79.036746];
Zipcodes['14304'] = [43.104953, -78.94805];
Zipcodes['14305'] = [43.122831, -79.023218];
Zipcodes['14411'] = [43.235972, -78.216477];
Zipcodes['14413'] = [43.222891, -76.970649];
Zipcodes['14414'] = [42.890961, -77.743757];
Zipcodes['14415'] = [42.755894, -77.016502];
Zipcodes['14416'] = [43.082693, -77.980115];
Zipcodes['14418'] = [42.60443, -77.216428];
Zipcodes['14420'] = [43.21444, -77.929197];
Zipcodes['14422'] = [43.084761, -78.066965];
Zipcodes['14423'] = [42.937674, -77.835232];
Zipcodes['14424'] = [42.856357, -77.303497];
Zipcodes['14425'] = [42.98961, -77.337344];
Zipcodes['14427'] = [42.614856, -78.051232];
Zipcodes['14428'] = [43.080517, -77.853568];
Zipcodes['14432'] = [42.955669, -77.140753];
Zipcodes['14433'] = [43.077171, -76.8787];
Zipcodes['14435'] = [42.712552, -77.663312];
Zipcodes['14437'] = [42.570141, -77.733764];
Zipcodes['14441'] = [42.686444, -76.958456];
Zipcodes['14445'] = [43.113062, -77.490306];
Zipcodes['14450'] = [43.092445, -77.420338];
Zipcodes['14453'] = [43.016056, -77.473575];
Zipcodes['14454'] = [42.802701, -77.767162];
Zipcodes['14456'] = [42.847695, -76.998972];
Zipcodes['14462'] = [42.686511, -77.751115];
Zipcodes['14463'] = [42.795434, -77.068892];
Zipcodes['14464'] = [43.325801, -77.939474];
Zipcodes['14466'] = [42.776194, -77.577488];
Zipcodes['14467'] = [43.036374, -77.612048];
Zipcodes['14468'] = [43.293956, -77.802049];
Zipcodes['14469'] = [42.877919, -77.471014];
Zipcodes['14470'] = [43.205212, -78.057685];
Zipcodes['14471'] = [42.743071, -77.491175];
Zipcodes['14472'] = [42.965247, -77.583045];
Zipcodes['14475'] = [42.936829, -77.497884];
Zipcodes['14476'] = [43.329379, -78.0428];
Zipcodes['14477'] = [43.335707, -78.133393];
Zipcodes['14478'] = [42.580088, -77.127889];
Zipcodes['14479'] = [43.236298, -78.304495];
Zipcodes['14480'] = [42.838209, -77.707442];
Zipcodes['14481'] = [42.771735, -77.916407];
Zipcodes['14482'] = [42.967367, -77.971924];
Zipcodes['14485'] = [42.882635, -77.601026];
Zipcodes['14486'] = [42.892916, -77.922957];
Zipcodes['14487'] = [42.808205, -77.640012];
Zipcodes['14489'] = [43.089845, -76.994319];
Zipcodes['14502'] = [43.096594, -77.335388];
Zipcodes['14504'] = [42.968432, -77.232698];
Zipcodes['14505'] = [43.158564, -77.174327];
Zipcodes['14506'] = [43.002223, -77.505265];
Zipcodes['14507'] = [42.684916, -77.254886];
Zipcodes['14510'] = [42.696029, -77.866903];
Zipcodes['14511'] = [42.999374, -77.89179];
Zipcodes['14512'] = [42.645223, -77.39977];
Zipcodes['14513'] = [43.075907, -77.100601];
Zipcodes['14514'] = [43.078872, -77.81083];
Zipcodes['14515'] = [43.252837, -77.731642];
Zipcodes['14516'] = [43.19786, -76.911334];
Zipcodes['14517'] = [42.592188, -77.897084];
Zipcodes['14519'] = [43.233972, -77.314631];
Zipcodes['14521'] = [42.676642, -76.805003];
Zipcodes['14522'] = [43.059829, -77.220283];
Zipcodes['14525'] = [42.877513, -78.013345];
Zipcodes['14526'] = [43.151464, -77.444396];
Zipcodes['14527'] = [42.666152, -77.063252];
Zipcodes['14529'] = [42.537549, -77.644143];
Zipcodes['14530'] = [42.73991, -77.998746];
Zipcodes['14532'] = [42.964101, -77.032088];
Zipcodes['14533'] = [42.847473, -77.883014];
Zipcodes['14534'] = [43.05689, -77.521042];
Zipcodes['14536'] = [42.537621, -78.07872];
Zipcodes['14537'] = [43.035317, -77.155992];
Zipcodes['14539'] = [42.834075, -77.875281];
Zipcodes['14541'] = [42.765059, -76.848967];
Zipcodes['14542'] = [43.153106, -76.875842];
Zipcodes['14543'] = [42.983831, -77.678535];
Zipcodes['14544'] = [42.756082, -77.240018];
Zipcodes['14545'] = [42.66163, -77.710568];
Zipcodes['14546'] = [43.032728, -77.78011];
Zipcodes['14548'] = [42.975396, -77.243463];
Zipcodes['14549'] = [42.696731, -78.022097];
Zipcodes['14550'] = [42.679902, -78.093487];
Zipcodes['14551'] = [43.218114, -77.049402];
Zipcodes['14555'] = [43.268001, -76.977887];
Zipcodes['14556'] = [42.679036, -77.832218];
Zipcodes['14557'] = [43.043449, -78.068195];
Zipcodes['14559'] = [43.18998, -77.819052];
Zipcodes['14560'] = [42.690702, -77.582963];
Zipcodes['14561'] = [42.822162, -77.131349];
Zipcodes['14564'] = [42.986733, -77.434439];
Zipcodes['14568'] = [43.143826, -77.286397];
Zipcodes['14569'] = [42.750485, -78.169399];
Zipcodes['14571'] = [43.341652, -78.251742];
Zipcodes['14572'] = [42.564925, -77.562598];
Zipcodes['14580'] = [43.217283, -77.444534];
Zipcodes['14585'] = [42.905821, -77.552937];
Zipcodes['14586'] = [43.041675, -77.689904];
Zipcodes['14588'] = [42.679746, -76.870399];
Zipcodes['14589'] = [43.239092, -77.169093];
Zipcodes['14590'] = [43.245357, -76.832127];
Zipcodes['14591'] = [42.832085, -78.107386];
Zipcodes['14592'] = [42.868786, -77.890565];
Zipcodes['14604'] = [43.156696, -77.605248];
Zipcodes['14605'] = [43.168418, -77.600394];
Zipcodes['14606'] = [43.171047, -77.697953];
Zipcodes['14607'] = [43.150932, -77.586809];
Zipcodes['14608'] = [43.154099, -77.62293];
Zipcodes['14609'] = [43.177125, -77.551821];
Zipcodes['14610'] = [43.140967, -77.544661];
Zipcodes['14611'] = [43.148316, -77.646987];
Zipcodes['14612'] = [43.263641, -77.672599];
Zipcodes['14613'] = [43.182171, -77.640175];
Zipcodes['14614'] = [43.157614, -77.614742];
Zipcodes['14615'] = [43.201207, -77.654749];
Zipcodes['14616'] = [43.234333, -77.658213];
Zipcodes['14617'] = [43.225816, -77.586572];
Zipcodes['14618'] = [43.114542, -77.554976];
Zipcodes['14619'] = [43.136456, -77.649477];
Zipcodes['14620'] = [43.128342, -77.604424];
Zipcodes['14621'] = [43.189939, -77.603649];
Zipcodes['14622'] = [43.214044, -77.550946];
Zipcodes['14623'] = [43.087349, -77.641862];
Zipcodes['14624'] = [43.1283, -77.731411];
Zipcodes['14625'] = [43.150045, -77.505526];
Zipcodes['14626'] = [43.213733, -77.712294];
Zipcodes['14627'] = [43.128552, -77.630071];
Zipcodes['14642'] = [43.122834, -77.625114];
Zipcodes['14701'] = [42.077769, -79.259072];
Zipcodes['14706'] = [42.106183, -78.528294];
Zipcodes['14707'] = [42.082618, -78.061983];
Zipcodes['14708'] = [42.019219, -78.063617];
Zipcodes['14709'] = [42.356494, -77.982064];
Zipcodes['14710'] = [42.087902, -79.419847];
Zipcodes['14711'] = [42.318416, -78.127907];
Zipcodes['14712'] = [42.183745, -79.361923];
Zipcodes['14714'] = [42.289988, -78.241131];
Zipcodes['14715'] = [42.07653, -78.14475];
Zipcodes['14716'] = [42.3811, -79.437249];
Zipcodes['14717'] = [42.361827, -78.179534];
Zipcodes['14718'] = [42.345879, -79.287596];
Zipcodes['14719'] = [42.341958, -78.875953];
Zipcodes['14720'] = [42.108569, -79.279928];
Zipcodes['14721'] = [42.016554, -78.268048];
Zipcodes['14722'] = [42.209608, -79.468799];
Zipcodes['14723'] = [42.317615, -79.157121];
Zipcodes['14724'] = [42.046328, -79.66983];
Zipcodes['14726'] = [42.253585, -79.020279];
Zipcodes['14727'] = [42.213577, -78.293095];
Zipcodes['14728'] = [42.272615, -79.410541];
Zipcodes['14729'] = [42.408377, -78.743584];
Zipcodes['14731'] = [42.307351, -78.655503];
Zipcodes['14732'] = [42.218904, -79.107166];
Zipcodes['14733'] = [42.16022, -79.172137];
Zipcodes['14735'] = [42.45269, -78.096169];
Zipcodes['14736'] = [42.137107, -79.746404];
Zipcodes['14737'] = [42.32905, -78.428611];
Zipcodes['14738'] = [42.052922, -79.057633];
Zipcodes['14739'] = [42.186661, -78.156823];
Zipcodes['14740'] = [42.223203, -79.181435];
Zipcodes['14741'] = [42.219966, -78.611624];
Zipcodes['14742'] = [42.119453, -79.308881];
Zipcodes['14743'] = [42.21157, -78.410443];
Zipcodes['14744'] = [42.4281, -78.206654];
Zipcodes['14747'] = [42.153715, -79.094518];
Zipcodes['14748'] = [42.157106, -78.643074];
Zipcodes['14750'] = [42.079366, -79.327543];
Zipcodes['14752'] = [42.351735, -79.319805];
Zipcodes['14753'] = [42.043502, -78.636095];
Zipcodes['14754'] = [42.021908, -78.201569];
Zipcodes['14755'] = [42.228815, -78.815928];
Zipcodes['14756'] = [42.197034, -79.423654];
Zipcodes['14757'] = [42.237507, -79.505285];
Zipcodes['14760'] = [42.073087, -78.417553];
Zipcodes['14767'] = [42.057352, -79.514135];
Zipcodes['14769'] = [42.385908, -79.472681];
Zipcodes['14770'] = [42.037929, -78.300296];
Zipcodes['14772'] = [42.144376, -78.954425];
Zipcodes['14774'] = [42.088784, -78.146662];
Zipcodes['14775'] = [42.228753, -79.699933];
Zipcodes['14777'] = [42.38608, -78.273772];
Zipcodes['14778'] = [42.077618, -78.482745];
Zipcodes['14779'] = [42.106257, -78.769659];
Zipcodes['14781'] = [42.166709, -79.608737];
Zipcodes['14782'] = [42.266572, -79.251727];
Zipcodes['14783'] = [42.067192, -78.92861];
Zipcodes['14784'] = [42.311143, -79.385369];
Zipcodes['14785'] = [42.155777, -79.403387];
Zipcodes['14787'] = [42.317521, -79.569503];
Zipcodes['14788'] = [42.061465, -78.380575];
Zipcodes['14801'] = [42.104056, -77.292968];
Zipcodes['14802'] = [42.253779, -77.789892];
Zipcodes['14803'] = [42.233277, -77.795082];
Zipcodes['14804'] = [42.314417, -77.851435];
Zipcodes['14805'] = [42.350109, -76.719369];
Zipcodes['14806'] = [42.158771, -77.787955];
Zipcodes['14807'] = [42.419611, -77.720511];
Zipcodes['14808'] = [42.55807, -77.468832];
Zipcodes['14809'] = [42.424318, -77.448755];
Zipcodes['14810'] = [42.347484, -77.346851];
Zipcodes['14812'] = [42.300727, -77.005249];
Zipcodes['14813'] = [42.252647, -77.995104];
Zipcodes['14814'] = [42.155636, -76.9593];
Zipcodes['14815'] = [42.374287, -77.086786];
Zipcodes['14816'] = [42.197734, -76.735603];
Zipcodes['14817'] = [42.357625, -76.335167];
Zipcodes['14818'] = [42.454933, -76.818927];
Zipcodes['14819'] = [42.224194, -77.441939];
Zipcodes['14820'] = [42.195309, -77.370388];
Zipcodes['14821'] = [42.241574, -77.219254];
Zipcodes['14822'] = [42.439046, -77.846855];
Zipcodes['14823'] = [42.2421, -77.529481];
Zipcodes['14824'] = [42.265778, -76.69308];
Zipcodes['14825'] = [42.077665, -76.613219];
Zipcodes['14826'] = [42.496244, -77.4925];
Zipcodes['14827'] = [42.180651, -77.142885];
Zipcodes['14830'] = [42.128266, -77.026279];
Zipcodes['14836'] = [42.53301, -77.869746];
Zipcodes['14837'] = [42.500223, -77.019376];
Zipcodes['14838'] = [42.189364, -76.667087];
Zipcodes['14839'] = [42.147524, -77.627811];
Zipcodes['14840'] = [42.441653, -77.194396];
Zipcodes['14841'] = [42.527, -76.842484];
Zipcodes['14842'] = [42.60062, -76.981464];
Zipcodes['14843'] = [42.334255, -77.635279];
Zipcodes['14845'] = [42.203208, -76.83934];
Zipcodes['14846'] = [42.528543, -78.004367];
Zipcodes['14847'] = [42.60849, -76.734238];
Zipcodes['14850'] = [42.431831, -76.500228];
Zipcodes['14853'] = [42.446404, -76.481231];
Zipcodes['14854'] = [42.511524, -76.607446];
Zipcodes['14855'] = [42.1457, -77.497396];
Zipcodes['14856'] = [42.377324, -77.369404];
Zipcodes['14858'] = [42.02979, -77.134956];
Zipcodes['14859'] = [42.124099, -76.536873];
Zipcodes['14860'] = [42.591759, -76.842356];
Zipcodes['14861'] = [42.088212, -76.686236];
Zipcodes['14864'] = [42.286689, -76.844478];
Zipcodes['14865'] = [42.352721, -76.816924];
Zipcodes['14867'] = [42.33714, -76.610245];
Zipcodes['14869'] = [42.36232, -76.768965];
Zipcodes['14870'] = [42.160375, -77.127609];
Zipcodes['14871'] = [42.039591, -76.911352];
Zipcodes['14872'] = [42.24134, -76.870803];
Zipcodes['14873'] = [42.522912, -77.298359];
Zipcodes['14874'] = [42.529349, -77.164465];
Zipcodes['14877'] = [42.060625, -77.687308];
Zipcodes['14878'] = [42.449442, -76.94403];
Zipcodes['14879'] = [42.304935, -77.196963];
Zipcodes['14880'] = [42.174064, -77.97535];
Zipcodes['14881'] = [42.399919, -76.358882];
Zipcodes['14882'] = [42.585625, -76.579264];
Zipcodes['14883'] = [42.237682, -76.475575];
Zipcodes['14884'] = [42.479522, -77.893852];
Zipcodes['14885'] = [42.04793, -77.571205];
Zipcodes['14886'] = [42.500727, -76.690271];
Zipcodes['14889'] = [42.234191, -76.579111];
Zipcodes['14891'] = [42.377325, -76.945021];
Zipcodes['14892'] = [42.046025, -76.529114];
Zipcodes['14893'] = [42.469472, -77.111769];
Zipcodes['14894'] = [42.019072, -76.777083];
Zipcodes['14895'] = [42.08144, -77.940922];
Zipcodes['14897'] = [42.020695, -77.792636];
Zipcodes['14898'] = [42.053364, -77.4295];
Zipcodes['14901'] = [42.073505, -76.73953];
Zipcodes['14903'] = [42.117267, -76.878487];
Zipcodes['14904'] = [42.070022, -76.808203];
Zipcodes['14905'] = [42.087725, -76.843282];
Zipcodes['15001'] = [40.595359, -80.322827];
Zipcodes['15003'] = [40.60364, -80.216429];
Zipcodes['15004'] = [40.342219, -80.382854];
Zipcodes['15005'] = [40.643656, -80.186297];
Zipcodes['15006'] = [40.633651, -79.876829];
Zipcodes['15007'] = [40.651253, -79.931616];
Zipcodes['15009'] = [40.697912, -80.365277];
Zipcodes['15010'] = [40.770856, -80.351417];
Zipcodes['15012'] = [40.155521, -79.812981];
Zipcodes['15014'] = [40.608597, -79.741512];
Zipcodes['15015'] = [40.636282, -80.08158];
Zipcodes['15017'] = [40.346829, -80.132565];
Zipcodes['15018'] = [40.280412, -79.799805];
Zipcodes['15019'] = [40.406974, -80.328574];
Zipcodes['15020'] = [40.22754, -79.954104];
Zipcodes['15021'] = [40.388565, -80.441527];
Zipcodes['15022'] = [40.124908, -79.939333];
Zipcodes['15024'] = [40.584969, -79.847315];
Zipcodes['15025'] = [40.29737, -79.920967];
Zipcodes['15026'] = [40.50581, -80.357346];
Zipcodes['15027'] = [40.666338, -80.240179];
Zipcodes['15028'] = [40.302875, -79.802557];
Zipcodes['15030'] = [40.592312, -79.783101];
Zipcodes['15031'] = [40.348052, -80.162213];
Zipcodes['15033'] = [40.180136, -79.864813];
Zipcodes['15034'] = [40.350648, -79.890176];
Zipcodes['15035'] = [40.384884, -79.807338];
Zipcodes['15037'] = [40.258036, -79.85259];
Zipcodes['15038'] = [40.250206, -79.926042];
Zipcodes['15042'] = [40.690315, -80.204823];
Zipcodes['15043'] = [40.551695, -80.487856];
Zipcodes['15044'] = [40.637988, -79.947933];
Zipcodes['15045'] = [40.325814, -79.885985];
Zipcodes['15046'] = [40.555653, -80.228561];
Zipcodes['15047'] = [40.313453, -79.796224];
Zipcodes['15049'] = [40.555534, -79.803608];
Zipcodes['15050'] = [40.562142, -80.443812];
Zipcodes['15051'] = [40.568163, -79.860785];
Zipcodes['15052'] = [40.665807, -80.427024];
Zipcodes['15053'] = [40.380542, -80.359796];
Zipcodes['15054'] = [40.362659, -80.397184];
Zipcodes['15055'] = [40.306293, -80.123516];
Zipcodes['15056'] = [40.56389, -80.216015];
Zipcodes['15057'] = [40.358656, -80.243904];
Zipcodes['15059'] = [40.673732, -80.508937];
Zipcodes['15060'] = [40.370151, -80.28885];
Zipcodes['15061'] = [40.663745, -80.30837];
Zipcodes['15062'] = [40.151966, -79.883008];
Zipcodes['15063'] = [40.191166, -79.922476];
Zipcodes['15064'] = [40.355253, -80.148391];
Zipcodes['15065'] = [40.644174, -79.725395];
Zipcodes['15066'] = [40.747074, -80.256143];
Zipcodes['15067'] = [40.20727, -79.962433];
Zipcodes['15068'] = [40.557182, -79.725823];
Zipcodes['15071'] = [40.414854, -80.189832];
Zipcodes['15072'] = [40.139324, -79.856063];
Zipcodes['15074'] = [40.734035, -80.208354];
Zipcodes['15075'] = [40.586508, -79.827063];
Zipcodes['15076'] = [40.609486, -79.837348];
Zipcodes['15077'] = [40.625093, -80.417391];
Zipcodes['15078'] = [40.359497, -80.385056];
Zipcodes['15081'] = [40.575072, -80.236104];
Zipcodes['15082'] = [40.380317, -80.21059];
Zipcodes['15083'] = [40.259661, -79.786199];
Zipcodes['15084'] = [40.62859, -79.803264];
Zipcodes['15085'] = [40.384965, -79.725433];
Zipcodes['15086'] = [40.674947, -80.108515];
Zipcodes['15087'] = [40.192436, -79.850726];
Zipcodes['15088'] = [40.270567, -79.896214];
Zipcodes['15089'] = [40.225233, -79.748806];
Zipcodes['15090'] = [40.625035, -80.067068];
Zipcodes['15101'] = [40.580328, -79.955241];
Zipcodes['15102'] = [40.321232, -80.037054];
Zipcodes['15104'] = [40.404037, -79.8624];
Zipcodes['15106'] = [40.408805, -80.108676];
Zipcodes['15108'] = [40.500472, -80.201802];
Zipcodes['15110'] = [40.373461, -79.850162];
Zipcodes['15112'] = [40.404583, -79.837367];
Zipcodes['15116'] = [40.525942, -79.93393];
Zipcodes['15120'] = [40.395872, -79.907511];
Zipcodes['15122'] = [40.361206, -79.895641];
Zipcodes['15123'] = [40.348186, -79.934828];
Zipcodes['15126'] = [40.460217, -80.287327];
Zipcodes['15129'] = [40.293457, -79.99522];
Zipcodes['15131'] = [40.338791, -79.796071];
Zipcodes['15132'] = [40.339767, -79.842782];
Zipcodes['15133'] = [40.326705, -79.866805];
Zipcodes['15135'] = [40.299719, -79.812169];
Zipcodes['15136'] = [40.463474, -80.106694];
Zipcodes['15137'] = [40.375736, -79.808765];
Zipcodes['15139'] = [40.522823, -79.836255];
Zipcodes['15140'] = [40.407198, -79.778254];
Zipcodes['15142'] = [40.384773, -80.122319];
Zipcodes['15143'] = [40.568138, -80.146807];
Zipcodes['15144'] = [40.547244, -79.782565];
Zipcodes['15145'] = [40.414512, -79.823506];
Zipcodes['15146'] = [40.428211, -79.761076];
Zipcodes['15147'] = [40.497431, -79.831096];
Zipcodes['15148'] = [40.393473, -79.795152];
Zipcodes['15201'] = [40.482322, -79.9415];
Zipcodes['15202'] = [40.506862, -80.071737];
Zipcodes['15203'] = [40.425424, -79.975758];
Zipcodes['15204'] = [40.456867, -80.06063];
Zipcodes['15205'] = [40.438107, -80.105533];
Zipcodes['15206'] = [40.472272, -79.913156];
Zipcodes['15207'] = [40.399268, -79.934801];
Zipcodes['15208'] = [40.453891, -79.898794];
Zipcodes['15209'] = [40.498846, -79.969725];
Zipcodes['15210'] = [40.406954, -79.985004];
Zipcodes['15211'] = [40.43022, -80.01538];
Zipcodes['15212'] = [40.45712, -79.994555];
Zipcodes['15213'] = [40.435563, -79.964078];
Zipcodes['15214'] = [40.486209, -80.012771];
Zipcodes['15215'] = [40.501392, -79.91267];
Zipcodes['15216'] = [40.402773, -80.034798];
Zipcodes['15217'] = [40.430821, -79.920089];
Zipcodes['15218'] = [40.42361, -79.889803];
Zipcodes['15219'] = [40.442774, -79.984077];
Zipcodes['15220'] = [40.418809, -80.04899];
Zipcodes['15221'] = [40.435477, -79.8658];
Zipcodes['15222'] = [40.449644, -79.990714];
Zipcodes['15223'] = [40.50424, -79.950973];
Zipcodes['15224'] = [40.467376, -79.946266];
Zipcodes['15225'] = [40.50668, -80.113844];
Zipcodes['15226'] = [40.395222, -80.013788];
Zipcodes['15227'] = [40.374972, -79.970778];
Zipcodes['15228'] = [40.370747, -80.043981];
Zipcodes['15229'] = [40.519897, -80.038656];
Zipcodes['15232'] = [40.452474, -79.931957];
Zipcodes['15233'] = [40.460275, -80.032597];
Zipcodes['15234'] = [40.368073, -80.017812];
Zipcodes['15235'] = [40.459625, -79.821992];
Zipcodes['15236'] = [40.347587, -79.976476];
Zipcodes['15237'] = [40.548408, -80.046196];
Zipcodes['15238'] = [40.537912, -79.879419];
Zipcodes['15239'] = [40.484415, -79.737599];
Zipcodes['15241'] = [40.332932, -80.082908];
Zipcodes['15243'] = [40.376497, -80.071389];
Zipcodes['15260'] = [40.44319, -79.953094];
Zipcodes['15275'] = [40.452443, -80.184114];
Zipcodes['15282'] = [40.436016, -79.990515];
Zipcodes['15290'] = [40.45733, -80.019291];
Zipcodes['15301'] = [40.164274, -80.252356];
Zipcodes['15310'] = [39.792537, -80.481074];
Zipcodes['15311'] = [40.046081, -80.185911];
Zipcodes['15312'] = [40.251528, -80.440702];
Zipcodes['15313'] = [40.063248, -80.029391];
Zipcodes['15314'] = [40.146306, -80.016809];
Zipcodes['15315'] = [39.75743, -79.983181];
Zipcodes['15316'] = [39.763513, -80.267735];
Zipcodes['15317'] = [40.276322, -80.171662];
Zipcodes['15320'] = [39.869225, -79.989277];
Zipcodes['15321'] = [40.321722, -80.187182];
Zipcodes['15322'] = [39.98823, -80.053284];
Zipcodes['15323'] = [40.103153, -80.417436];
Zipcodes['15324'] = [40.100705, -80.065386];
Zipcodes['15325'] = [39.949256, -79.963512];
Zipcodes['15327'] = [39.754872, -79.969127];
Zipcodes['15329'] = [40.027715, -80.279928];
Zipcodes['15330'] = [40.179368, -80.089973];
Zipcodes['15331'] = [40.105341, -80.022613];
Zipcodes['15332'] = [40.240209, -79.993477];
Zipcodes['15333'] = [40.030786, -80.01321];
Zipcodes['15334'] = [39.812914, -79.967235];
Zipcodes['15337'] = [39.952088, -80.378324];
Zipcodes['15338'] = [39.840909, -79.983792];
Zipcodes['15340'] = [40.286547, -80.322631];
Zipcodes['15341'] = [39.8242, -80.343863];
Zipcodes['15342'] = [40.245354, -80.220585];
Zipcodes['15344'] = [39.922901, -80.050723];
Zipcodes['15345'] = [40.021743, -80.107431];
Zipcodes['15346'] = [39.935584, -80.074474];
Zipcodes['15347'] = [40.217986, -80.224595];
Zipcodes['15348'] = [39.985733, -79.996203];
Zipcodes['15349'] = [39.761375, -80.07323];
Zipcodes['15350'] = [40.293789, -80.200647];
Zipcodes['15351'] = [39.878508, -79.93059];
Zipcodes['15352'] = [39.754934, -80.402579];
Zipcodes['15353'] = [39.962227, -80.311151];
Zipcodes['15357'] = [39.941608, -79.980409];
Zipcodes['15358'] = [40.054956, -80.003108];
Zipcodes['15359'] = [39.876616, -80.275632];
Zipcodes['15360'] = [40.088462, -80.081472];
Zipcodes['15361'] = [40.332798, -80.262324];
Zipcodes['15362'] = [39.757355, -80.214428];
Zipcodes['15363'] = [40.251564, -80.197734];
Zipcodes['15364'] = [39.954249, -80.283863];
Zipcodes['15365'] = [40.155676, -80.38545];
Zipcodes['15366'] = [40.159736, -79.973851];
Zipcodes['15367'] = [40.261092, -80.055668];
Zipcodes['15368'] = [40.013872, -79.989284];
Zipcodes['15370'] = [39.873868, -80.186106];
Zipcodes['15376'] = [40.108883, -80.47905];
Zipcodes['15377'] = [39.988535, -80.449029];
Zipcodes['15378'] = [40.277789, -80.274385];
Zipcodes['15379'] = [40.243194, -80.425176];
Zipcodes['15380'] = [39.878425, -80.467323];
Zipcodes['15401'] = [39.900708, -79.747923];
Zipcodes['15410'] = [39.908458, -79.906042];
Zipcodes['15411'] = [39.747331, -79.343516];
Zipcodes['15412'] = [40.100867, -79.853088];
Zipcodes['15413'] = [39.984949, -79.871403];
Zipcodes['15416'] = [39.859065, -79.722806];
Zipcodes['15417'] = [40.005611, -79.921185];
Zipcodes['15419'] = [40.056614, -79.890461];
Zipcodes['15420'] = [39.961153, -79.864333];
Zipcodes['15421'] = [39.845249, -79.597386];
Zipcodes['15422'] = [39.978967, -79.80924];
Zipcodes['15423'] = [40.093759, -79.928213];
Zipcodes['15424'] = [39.826852, -79.356323];
Zipcodes['15425'] = [40.029726, -79.548821];
Zipcodes['15427'] = [40.067457, -79.970701];
Zipcodes['15428'] = [40.084267, -79.682026];
Zipcodes['15429'] = [40.008301, -79.942513];
Zipcodes['15430'] = [40.039418, -79.654371];
Zipcodes['15431'] = [39.931349, -79.572521];
Zipcodes['15432'] = [40.109575, -79.858756];
Zipcodes['15433'] = [39.982683, -79.948732];
Zipcodes['15434'] = [40.081239, -79.890788];
Zipcodes['15435'] = [39.943193, -79.847283];
Zipcodes['15436'] = [39.818456, -79.725456];
Zipcodes['15437'] = [39.794455, -79.590384];
Zipcodes['15438'] = [40.072886, -79.859286];
Zipcodes['15440'] = [39.737724, -79.643632];
Zipcodes['15442'] = [40.019171, -79.839788];
Zipcodes['15443'] = [39.919239, -79.884503];
Zipcodes['15444'] = [40.013407, -79.908458];
Zipcodes['15445'] = [39.876113, -79.667849];
Zipcodes['15446'] = [40.026316, -79.386487];
Zipcodes['15447'] = [39.951695, -79.932369];
Zipcodes['15448'] = [40.136048, -79.732527];
Zipcodes['15449'] = [39.972659, -79.780603];
Zipcodes['15450'] = [40.008664, -79.975019];
Zipcodes['15451'] = [39.736891, -79.83578];
Zipcodes['15454'] = [39.860368, -79.865503];
Zipcodes['15455'] = [40.006114, -79.646071];
Zipcodes['15456'] = [39.926647, -79.652106];
Zipcodes['15458'] = [39.887158, -79.850967];
Zipcodes['15459'] = [39.765218, -79.464533];
Zipcodes['15460'] = [39.807134, -79.90761];
Zipcodes['15461'] = [39.839993, -79.900013];
Zipcodes['15462'] = [40.061342, -79.381134];
Zipcodes['15463'] = [39.962896, -79.899153];
Zipcodes['15464'] = [39.930139, -79.436198];
Zipcodes['15465'] = [39.93886, -79.64128];
Zipcodes['15466'] = [40.07802, -79.888889];
Zipcodes['15467'] = [39.782542, -79.898746];
Zipcodes['15468'] = [39.950112, -79.83257];
Zipcodes['15469'] = [40.013641, -79.418581];
Zipcodes['15470'] = [39.852796, -79.531691];
Zipcodes['15472'] = [39.919407, -79.716834];
Zipcodes['15473'] = [40.075054, -79.767881];
Zipcodes['15474'] = [39.752931, -79.902677];
Zipcodes['15475'] = [39.958156, -79.880625];
Zipcodes['15476'] = [39.867997, -79.922808];
Zipcodes['15477'] = [40.078773, -79.86294];
Zipcodes['15478'] = [39.787462, -79.804657];
Zipcodes['15479'] = [40.151237, -79.707539];
Zipcodes['15480'] = [39.985608, -79.773558];
Zipcodes['15482'] = [40.060711, -79.766471];
Zipcodes['15483'] = [40.083084, -79.850793];
Zipcodes['15484'] = [39.892355, -79.778644];
Zipcodes['15486'] = [40.025886, -79.711164];
Zipcodes['15488'] = [39.977508, -79.759736];
Zipcodes['15489'] = [39.955252, -79.697548];
Zipcodes['15490'] = [40.076685, -79.481513];
Zipcodes['15492'] = [40.119795, -79.763063];
Zipcodes['15501'] = [40.039742, -79.12982];
Zipcodes['15502'] = [40.027236, -79.244025];
Zipcodes['15510'] = [39.964326, -79.039955];
Zipcodes['15520'] = [40.106288, -79.060245];
Zipcodes['15521'] = [40.199817, -78.630167];
Zipcodes['15522'] = [39.941733, -78.548408];
Zipcodes['15530'] = [39.940809, -78.924027];
Zipcodes['15531'] = [40.183936, -79.082097];
Zipcodes['15532'] = [39.762264, -79.060773];
Zipcodes['15533'] = [39.979572, -78.258667];
Zipcodes['15534'] = [39.899265, -78.691197];
Zipcodes['15535'] = [39.831046, -78.443913];
Zipcodes['15536'] = [39.937562, -78.206377];
Zipcodes['15537'] = [39.99068, -78.374336];
Zipcodes['15538'] = [39.863715, -78.834458];
Zipcodes['15539'] = [40.128381, -78.59338];
Zipcodes['15540'] = [39.797439, -79.239272];
Zipcodes['15541'] = [40.04821, -78.980902];
Zipcodes['15542'] = [39.86741, -79.078543];
Zipcodes['15544'] = [40.132537, -79.09651];
Zipcodes['15545'] = [39.80216, -78.752463];
Zipcodes['15546'] = [40.137105, -79.047558];
Zipcodes['15547'] = [40.162911, -79.070287];
Zipcodes['15550'] = [39.9946, -78.653116];
Zipcodes['15551'] = [39.868154, -79.284798];
Zipcodes['15552'] = [39.790334, -78.987716];
Zipcodes['15554'] = [40.122918, -78.621785];
Zipcodes['15555'] = [40.09751, -79.081368];
Zipcodes['15557'] = [39.916078, -79.205332];
Zipcodes['15558'] = [39.76235, -79.079881];
Zipcodes['15559'] = [40.060248, -78.691065];
Zipcodes['15560'] = [40.016912, -78.906652];
Zipcodes['15561'] = [40.093771, -79.090687];
Zipcodes['15562'] = [39.740592, -79.107556];
Zipcodes['15563'] = [40.090355, -78.936657];
Zipcodes['15564'] = [39.729714, -78.845443];
Zipcodes['15565'] = [39.759046, -79.115599];
Zipcodes['15601'] = [40.313271, -79.536379];
Zipcodes['15610'] = [40.131269, -79.415343];
Zipcodes['15611'] = [40.306554, -79.652674];
Zipcodes['15612'] = [40.137911, -79.599339];
Zipcodes['15613'] = [40.546577, -79.561663];
Zipcodes['15615'] = [40.361254, -79.733977];
Zipcodes['15616'] = [40.235062, -79.550908];
Zipcodes['15617'] = [40.268182, -79.655373];
Zipcodes['15618'] = [40.571026, -79.439869];
Zipcodes['15619'] = [40.32059, -79.497083];
Zipcodes['15620'] = [40.323357, -79.338834];
Zipcodes['15622'] = [40.038146, -79.319711];
Zipcodes['15623'] = [40.368858, -79.62269];
Zipcodes['15624'] = [40.366379, -79.471261];
Zipcodes['15625'] = [40.27049, -79.679242];
Zipcodes['15626'] = [40.408673, -79.575945];
Zipcodes['15627'] = [40.351642, -79.302551];
Zipcodes['15628'] = [40.100108, -79.370169];
Zipcodes['15629'] = [40.597437, -79.562908];
Zipcodes['15631'] = [40.089664, -79.585343];
Zipcodes['15632'] = [40.435719, -79.605521];
Zipcodes['15633'] = [40.359348, -79.517061];
Zipcodes['15634'] = [40.325272, -79.605972];
Zipcodes['15635'] = [40.347904, -79.501557];
Zipcodes['15636'] = [40.36476, -79.656116];
Zipcodes['15637'] = [40.261247, -79.712053];
Zipcodes['15638'] = [40.264648, -79.401638];
Zipcodes['15639'] = [40.203588, -79.593819];
Zipcodes['15640'] = [40.223124, -79.732499];
Zipcodes['15641'] = [40.631576, -79.588973];
Zipcodes['15642'] = [40.319284, -79.723697];
Zipcodes['15644'] = [40.346798, -79.612811];
Zipcodes['15646'] = [40.095153, -79.344493];
Zipcodes['15647'] = [40.342641, -79.720034];
Zipcodes['15650'] = [40.277177, -79.392906];
Zipcodes['15655'] = [40.197815, -79.177819];
Zipcodes['15656'] = [40.641222, -79.617811];
Zipcodes['15658'] = [40.268832, -79.216333];
Zipcodes['15660'] = [40.246724, -79.77302];
Zipcodes['15661'] = [40.321924, -79.359943];
Zipcodes['15662'] = [40.338912, -79.479745];
Zipcodes['15663'] = [40.252332, -79.679611];
Zipcodes['15665'] = [40.339357, -79.663493];
Zipcodes['15666'] = [40.161024, -79.509814];
Zipcodes['15668'] = [40.464826, -79.671391];
Zipcodes['15670'] = [40.407268, -79.443692];
Zipcodes['15671'] = [40.360293, -79.321431];
Zipcodes['15672'] = [40.24379, -79.621587];
Zipcodes['15673'] = [40.593808, -79.556616];
Zipcodes['15674'] = [40.206798, -79.496381];
Zipcodes['15675'] = [40.333605, -79.638737];
Zipcodes['15676'] = [40.243019, -79.459103];
Zipcodes['15677'] = [40.150411, -79.231115];
Zipcodes['15678'] = [40.284478, -79.725789];
Zipcodes['15679'] = [40.187654, -79.655809];
Zipcodes['15680'] = [40.521066, -79.496354];
Zipcodes['15681'] = [40.502305, -79.439025];
Zipcodes['15682'] = [40.681552, -79.632781];
Zipcodes['15683'] = [40.107158, -79.60822];
Zipcodes['15684'] = [40.462888, -79.506388];
Zipcodes['15686'] = [40.615013, -79.437107];
Zipcodes['15687'] = [40.136223, -79.316448];
Zipcodes['15688'] = [40.17352, -79.586074];
Zipcodes['15689'] = [40.225268, -79.491661];
Zipcodes['15690'] = [40.64135, -79.549422];
Zipcodes['15691'] = [40.296609, -79.683992];
Zipcodes['15692'] = [40.3308, -79.67861];
Zipcodes['15693'] = [40.249388, -79.405909];
Zipcodes['15695'] = [40.196984, -79.692834];
Zipcodes['15696'] = [40.279783, -79.365884];
Zipcodes['15697'] = [40.239867, -79.5806];
Zipcodes['15698'] = [40.214407, -79.688503];
Zipcodes['15701'] = [40.629099, -79.150059];
Zipcodes['15705'] = [40.615743, -79.159936];
Zipcodes['15710'] = [40.642463, -78.87167];
Zipcodes['15711'] = [41.018425, -78.95562];
Zipcodes['15712'] = [40.793174, -78.846905];
Zipcodes['15713'] = [40.569793, -79.261842];
Zipcodes['15714'] = [40.643946, -78.83337];
Zipcodes['15715'] = [40.970657, -78.877426];
Zipcodes['15716'] = [40.469136, -79.188058];
Zipcodes['15717'] = [40.457206, -79.243213];
Zipcodes['15720'] = [40.538944, -79.06576];
Zipcodes['15721'] = [40.81977, -78.783865];
Zipcodes['15722'] = [40.601067, -78.720514];
Zipcodes['15723'] = [40.698747, -79.158676];
Zipcodes['15724'] = [40.735096, -78.813634];
Zipcodes['15725'] = [40.518948, -79.348779];
Zipcodes['15727'] = [40.565021, -79.314942];
Zipcodes['15728'] = [40.676415, -78.966784];
Zipcodes['15729'] = [40.706445, -78.915125];
Zipcodes['15730'] = [41.026533, -79.082706];
Zipcodes['15731'] = [40.501216, -79.172943];
Zipcodes['15732'] = [40.73273, -79.216471];
Zipcodes['15733'] = [40.988862, -78.959295];
Zipcodes['15734'] = [40.719731, -79.009041];
Zipcodes['15736'] = [40.699417, -79.369284];
Zipcodes['15737'] = [40.598755, -78.753568];
Zipcodes['15738'] = [40.690378, -78.781719];
Zipcodes['15739'] = [40.67872, -79.164571];
Zipcodes['15741'] = [40.802488, -78.891033];
Zipcodes['15742'] = [40.810515, -78.870088];
Zipcodes['15744'] = [40.919441, -79.072979];
Zipcodes['15745'] = [40.622615, -78.913493];
Zipcodes['15746'] = [40.753891, -78.873164];
Zipcodes['15747'] = [40.764093, -79.143307];
Zipcodes['15748'] = [40.523638, -79.08415];
Zipcodes['15750'] = [40.48379, -79.182622];
Zipcodes['15752'] = [40.540915, -79.281757];
Zipcodes['15753'] = [40.783321, -78.630901];
Zipcodes['15754'] = [40.55667, -79.151511];
Zipcodes['15756'] = [40.569827, -79.302292];
Zipcodes['15757'] = [40.902975, -78.731671];
Zipcodes['15759'] = [40.773017, -79.031699];
Zipcodes['15760'] = [40.645828, -78.805073];
Zipcodes['15761'] = [40.63405, -78.890103];
Zipcodes['15762'] = [40.594306, -78.829302];
Zipcodes['15763'] = [40.907143, -79.128005];
Zipcodes['15764'] = [40.994569, -79.02696];
Zipcodes['15765'] = [40.592635, -79.000291];
Zipcodes['15767'] = [40.965616, -78.975453];
Zipcodes['15770'] = [41.009097, -79.136749];
Zipcodes['15771'] = [40.831556, -78.989145];
Zipcodes['15772'] = [40.870222, -78.898677];
Zipcodes['15773'] = [40.630731, -78.730313];
Zipcodes['15774'] = [40.65469, -79.323835];
Zipcodes['15775'] = [40.634811, -78.783992];
Zipcodes['15776'] = [41.010988, -79.111592];
Zipcodes['15777'] = [40.697385, -78.962591];
Zipcodes['15778'] = [40.968969, -79.198434];
Zipcodes['15779'] = [40.40011, -79.218444];
Zipcodes['15780'] = [40.92093, -79.043298];
Zipcodes['15781'] = [40.963747, -78.986856];
Zipcodes['15783'] = [40.602966, -79.345018];
Zipcodes['15784'] = [41.024489, -79.141065];
Zipcodes['15801'] = [41.129961, -78.728348];
Zipcodes['15821'] = [41.371766, -78.39709];
Zipcodes['15823'] = [41.259876, -78.71568];
Zipcodes['15824'] = [41.246173, -78.837488];
Zipcodes['15825'] = [41.169993, -79.040345];
Zipcodes['15827'] = [41.294709, -78.50579];
Zipcodes['15828'] = [41.385437, -79.12333];
Zipcodes['15829'] = [41.209546, -79.244037];
Zipcodes['15831'] = [41.353064, -78.604278];
Zipcodes['15832'] = [41.356513, -78.197279];
Zipcodes['15834'] = [41.50076, -78.322783];
Zipcodes['15840'] = [41.171972, -78.822649];
Zipcodes['15841'] = [41.257905, -78.504358];
Zipcodes['15845'] = [41.508371, -78.706975];
Zipcodes['15846'] = [41.339978, -78.608516];
Zipcodes['15847'] = [41.085923, -79.029677];
Zipcodes['15848'] = [41.030154, -78.71058];
Zipcodes['15849'] = [41.187082, -78.573007];
Zipcodes['15851'] = [41.102906, -78.910416];
Zipcodes['15853'] = [41.392343, -78.791631];
Zipcodes['15856'] = [41.082255, -78.614302];
Zipcodes['15857'] = [41.455038, -78.52019];
Zipcodes['15860'] = [41.345562, -79.036837];
Zipcodes['15861'] = [41.352795, -78.036198];
Zipcodes['15863'] = [41.015727, -78.838473];
Zipcodes['15864'] = [41.102233, -79.190133];
Zipcodes['15865'] = [41.050335, -78.813877];
Zipcodes['15866'] = [41.029383, -78.787682];
Zipcodes['15868'] = [41.300528, -78.408847];
Zipcodes['15870'] = [41.582792, -78.582366];
Zipcodes['15901'] = [40.328162, -78.911913];
Zipcodes['15902'] = [40.319169, -78.868818];
Zipcodes['15904'] = [40.312663, -78.83957];
Zipcodes['15905'] = [40.290709, -78.969945];
Zipcodes['15906'] = [40.384556, -78.934058];
Zipcodes['15909'] = [40.409742, -78.869741];
Zipcodes['15920'] = [40.468027, -79.046847];
Zipcodes['15921'] = [40.31643, -78.697865];
Zipcodes['15922'] = [40.5185, -78.878018];
Zipcodes['15923'] = [40.35522, -79.158504];
Zipcodes['15924'] = [40.103423, -78.762858];
Zipcodes['15925'] = [40.408556, -78.640712];
Zipcodes['15926'] = [40.026799, -78.816836];
Zipcodes['15927'] = [40.541487, -78.780181];
Zipcodes['15928'] = [40.239333, -78.918675];
Zipcodes['15929'] = [40.480233, -79.011209];
Zipcodes['15930'] = [40.294672, -78.717499];
Zipcodes['15931'] = [40.51485, -78.783636];
Zipcodes['15934'] = [40.28096, -78.796162];
Zipcodes['15935'] = [40.19794, -78.967164];
Zipcodes['15936'] = [40.160887, -78.892633];
Zipcodes['15937'] = [40.214616, -78.981041];
Zipcodes['15938'] = [40.415496, -78.597216];
Zipcodes['15940'] = [40.526533, -78.643563];
Zipcodes['15942'] = [40.398324, -78.811023];
Zipcodes['15943'] = [40.474825, -78.835526];
Zipcodes['15944'] = [40.363889, -79.079158];
Zipcodes['15945'] = [40.359077, -78.868714];
Zipcodes['15946'] = [40.370105, -78.618107];
Zipcodes['15948'] = [40.489733, -78.767045];
Zipcodes['15949'] = [40.406525, -79.135235];
Zipcodes['15951'] = [40.333185, -78.770688];
Zipcodes['15952'] = [40.303506, -78.776084];
Zipcodes['15953'] = [40.208407, -78.8849];
Zipcodes['15954'] = [40.417823, -78.99995];
Zipcodes['15955'] = [40.308872, -78.693186];
Zipcodes['15956'] = [40.350739, -78.796048];
Zipcodes['15957'] = [40.546257, -78.899995];
Zipcodes['15958'] = [40.387495, -78.730751];
Zipcodes['15960'] = [40.498446, -78.851794];
Zipcodes['15961'] = [40.466443, -78.943367];
Zipcodes['15962'] = [40.389404, -78.716371];
Zipcodes['15963'] = [40.219638, -78.785278];
Zipcodes['16001'] = [40.910025, -79.945191];
Zipcodes['16002'] = [40.815104, -79.855349];
Zipcodes['16020'] = [41.116493, -79.896534];
Zipcodes['16022'] = [41.054553, -79.733232];
Zipcodes['16023'] = [40.784971, -79.749208];
Zipcodes['16024'] = [40.73975, -80.037228];
Zipcodes['16025'] = [40.939936, -79.771294];
Zipcodes['16027'] = [40.818332, -80.015411];
Zipcodes['16028'] = [40.951294, -79.635593];
Zipcodes['16029'] = [40.87994, -79.845998];
Zipcodes['16030'] = [41.136202, -79.796907];
Zipcodes['16033'] = [40.796445, -80.048964];
Zipcodes['16034'] = [40.857866, -79.714977];
Zipcodes['16035'] = [41.108993, -80.014526];
Zipcodes['16036'] = [41.134163, -79.668713];
Zipcodes['16037'] = [40.842611, -80.133751];
Zipcodes['16038'] = [41.164486, -79.953346];
Zipcodes['16040'] = [41.093869, -79.841337];
Zipcodes['16041'] = [41.006953, -79.713925];
Zipcodes['16045'] = [40.852766, -79.916305];
Zipcodes['16046'] = [40.702612, -80.023973];
Zipcodes['16048'] = [41.052185, -79.80349];
Zipcodes['16049'] = [41.094067, -79.671823];
Zipcodes['16050'] = [41.044024, -79.761496];
Zipcodes['16051'] = [40.942678, -80.138832];
Zipcodes['16052'] = [40.899334, -80.061602];
Zipcodes['16053'] = [40.811954, -79.982575];
Zipcodes['16054'] = [41.151962, -79.661156];
Zipcodes['16055'] = [40.716859, -79.749717];
Zipcodes['16056'] = [40.728403, -79.841812];
Zipcodes['16057'] = [41.034074, -80.0603];
Zipcodes['16059'] = [40.707082, -79.927088];
Zipcodes['16061'] = [41.005133, -79.884697];
Zipcodes['16063'] = [40.756232, -80.131076];
Zipcodes['16066'] = [40.709967, -80.105642];
Zipcodes['16101'] = [40.983987, -80.29024];
Zipcodes['16102'] = [40.962242, -80.415441];
Zipcodes['16105'] = [41.05404, -80.339414];
Zipcodes['16110'] = [41.494656, -80.387377];
Zipcodes['16111'] = [41.518516, -80.283532];
Zipcodes['16112'] = [40.960467, -80.498397];
Zipcodes['16113'] = [41.280826, -80.424148];
Zipcodes['16114'] = [41.404303, -80.187679];
Zipcodes['16115'] = [40.796494, -80.463523];
Zipcodes['16116'] = [41.044195, -80.449588];
Zipcodes['16117'] = [40.871835, -80.256207];
Zipcodes['16120'] = [40.885261, -80.479165];
Zipcodes['16121'] = [41.211094, -80.497338];
Zipcodes['16123'] = [40.821932, -80.196513];
Zipcodes['16124'] = [41.333371, -80.264847];
Zipcodes['16125'] = [41.406506, -80.369729];
Zipcodes['16127'] = [41.173009, -80.072998];
Zipcodes['16130'] = [41.444638, -80.222959];
Zipcodes['16131'] = [41.548571, -80.376918];
Zipcodes['16132'] = [41.005848, -80.505148];
Zipcodes['16133'] = [41.267778, -80.114451];
Zipcodes['16134'] = [41.526836, -80.490189];
Zipcodes['16136'] = [40.834932, -80.323771];
Zipcodes['16137'] = [41.231515, -80.239942];
Zipcodes['16140'] = [41.093646, -80.510732];
Zipcodes['16141'] = [40.880265, -80.399156];
Zipcodes['16142'] = [41.135233, -80.332802];
Zipcodes['16143'] = [41.105081, -80.454418];
Zipcodes['16145'] = [41.380199, -80.078624];
Zipcodes['16146'] = [41.234788, -80.499379];
Zipcodes['16148'] = [41.228478, -80.417623];
Zipcodes['16150'] = [41.281804, -80.449145];
Zipcodes['16151'] = [41.442851, -80.20567];
Zipcodes['16153'] = [41.32224, -80.086586];
Zipcodes['16154'] = [41.3259, -80.420111];
Zipcodes['16155'] = [41.067732, -80.510731];
Zipcodes['16156'] = [41.097996, -80.227214];
Zipcodes['16157'] = [40.888212, -80.333383];
Zipcodes['16159'] = [41.153413, -80.475263];
Zipcodes['16160'] = [40.928358, -80.362385];
Zipcodes['16161'] = [41.202311, -80.492827];
Zipcodes['16172'] = [41.117708, -80.331045];
Zipcodes['16201'] = [40.812096, -79.4725];
Zipcodes['16210'] = [40.901015, -79.516619];
Zipcodes['16211'] = [40.794334, -79.202375];
Zipcodes['16212'] = [40.752466, -79.577167];
Zipcodes['16213'] = [41.125548, -79.558302];
Zipcodes['16214'] = [41.198441, -79.354159];
Zipcodes['16217'] = [41.346142, -79.181312];
Zipcodes['16218'] = [40.92612, -79.592645];
Zipcodes['16220'] = [41.418091, -79.26819];
Zipcodes['16222'] = [40.873698, -79.256993];
Zipcodes['16223'] = [40.986973, -79.353821];
Zipcodes['16224'] = [41.066542, -79.290454];
Zipcodes['16226'] = [40.714719, -79.487423];
Zipcodes['16228'] = [40.760813, -79.535765];
Zipcodes['16229'] = [40.711759, -79.656252];
Zipcodes['16230'] = [41.020482, -79.283246];
Zipcodes['16232'] = [41.213584, -79.551244];
Zipcodes['16233'] = [41.367965, -79.285336];
Zipcodes['16235'] = [41.30579, -79.347387];
Zipcodes['16236'] = [40.781222, -79.523713];
Zipcodes['16238'] = [40.787653, -79.520411];
Zipcodes['16239'] = [41.496972, -79.137779];
Zipcodes['16240'] = [41.036013, -79.225];
Zipcodes['16242'] = [40.98542, -79.372346];
Zipcodes['16244'] = [40.804985, -79.279083];
Zipcodes['16245'] = [41.001559, -79.293138];
Zipcodes['16246'] = [40.791717, -79.182161];
Zipcodes['16248'] = [41.034876, -79.504761];
Zipcodes['16249'] = [40.759381, -79.326416];
Zipcodes['16250'] = [40.775652, -79.237832];
Zipcodes['16253'] = [40.956694, -79.343547];
Zipcodes['16254'] = [41.255013, -79.450807];
Zipcodes['16255'] = [41.128813, -79.466757];
Zipcodes['16256'] = [40.855267, -79.134458];
Zipcodes['16258'] = [41.230105, -79.272223];
Zipcodes['16259'] = [40.925711, -79.451859];
Zipcodes['16260'] = [41.410882, -79.239273];
Zipcodes['16262'] = [40.837675, -79.652691];
Zipcodes['16263'] = [40.801168, -79.341109];
Zipcodes['16301'] = [41.453285, -79.622427];
Zipcodes['16311'] = [41.462171, -80.042364];
Zipcodes['16312'] = [41.932135, -79.303214];
Zipcodes['16313'] = [41.714594, -79.145177];
Zipcodes['16314'] = [41.520675, -80.07707];
Zipcodes['16316'] = [41.595311, -80.284483];
Zipcodes['16317'] = [41.532335, -79.85262];
Zipcodes['16319'] = [41.324204, -79.623458];
Zipcodes['16321'] = [41.568325, -79.400859];
Zipcodes['16322'] = [41.605636, -79.384341];
Zipcodes['16323'] = [41.417265, -79.830556];
Zipcodes['16326'] = [41.376158, -79.43174];
Zipcodes['16327'] = [41.637082, -79.959566];
Zipcodes['16328'] = [41.650983, -79.72712];
Zipcodes['16329'] = [41.852731, -79.26496];
Zipcodes['16331'] = [41.287515, -79.559279];
Zipcodes['16332'] = [41.356717, -79.369545];
Zipcodes['16333'] = [41.749226, -78.932294];
Zipcodes['16334'] = [41.304495, -79.44628];
Zipcodes['16335'] = [41.630635, -80.153636];
Zipcodes['16340'] = [41.798615, -79.417505];
Zipcodes['16341'] = [41.562876, -79.547033];
Zipcodes['16342'] = [41.314848, -79.928008];
Zipcodes['16343'] = [41.425982, -79.752686];
Zipcodes['16344'] = [41.470477, -79.683991];
Zipcodes['16345'] = [41.944404, -79.082916];
Zipcodes['16346'] = [41.378475, -79.672744];
Zipcodes['16347'] = [41.660063, -79.070267];
Zipcodes['16350'] = [41.956923, -79.334404];
Zipcodes['16351'] = [41.675353, -79.370344];
Zipcodes['16352'] = [41.766135, -79.036428];
Zipcodes['16353'] = [41.492112, -79.389517];
Zipcodes['16354'] = [41.614736, -79.706434];
Zipcodes['16360'] = [41.679673, -79.890349];
Zipcodes['16361'] = [41.391037, -79.340239];
Zipcodes['16362'] = [41.450497, -79.965179];
Zipcodes['16364'] = [41.351416, -79.527305];
Zipcodes['16365'] = [41.840178, -79.157366];
Zipcodes['16370'] = [41.557054, -79.449018];
Zipcodes['16371'] = [41.867526, -79.318692];
Zipcodes['16372'] = [41.201025, -79.874512];
Zipcodes['16373'] = [41.208469, -79.698409];
Zipcodes['16374'] = [41.263498, -79.815085];
Zipcodes['16401'] = [41.878476, -80.40516];
Zipcodes['16402'] = [41.961016, -79.469793];
Zipcodes['16403'] = [41.792998, -80.014121];
Zipcodes['16404'] = [41.725553, -79.789045];
Zipcodes['16405'] = [41.937995, -79.533511];
Zipcodes['16406'] = [41.750993, -80.363549];
Zipcodes['16407'] = [41.915795, -79.68467];
Zipcodes['16410'] = [41.922751, -80.308509];
Zipcodes['16411'] = [41.978036, -80.447108];
Zipcodes['16412'] = [41.882087, -80.159697];
Zipcodes['16415'] = [42.037572, -80.230286];
Zipcodes['16416'] = [41.826056, -79.473138];
Zipcodes['16417'] = [41.969286, -80.310636];
Zipcodes['16420'] = [41.694782, -79.545454];
Zipcodes['16421'] = [42.169579, -79.939918];
Zipcodes['16422'] = [41.667548, -80.310188];
Zipcodes['16423'] = [42.021099, -80.340132];
Zipcodes['16424'] = [41.661717, -80.448219];
Zipcodes['16426'] = [41.984082, -80.125891];
Zipcodes['16427'] = [41.874637, -79.971279];
Zipcodes['16428'] = [42.171464, -79.831488];
Zipcodes['16430'] = [42.001124, -80.41894];
Zipcodes['16433'] = [41.737079, -80.140914];
Zipcodes['16434'] = [41.79672, -79.668697];
Zipcodes['16435'] = [41.822381, -80.382821];
Zipcodes['16436'] = [41.862447, -79.506491];
Zipcodes['16438'] = [41.89124, -79.847468];
Zipcodes['16440'] = [41.782549, -80.126018];
Zipcodes['16441'] = [41.95849, -79.983551];
Zipcodes['16442'] = [42.048573, -79.825747];
Zipcodes['16443'] = [41.940114, -80.47184];
Zipcodes['16444'] = [41.871224, -80.120756];
Zipcodes['16475'] = [41.876432, -80.361052];
Zipcodes['16501'] = [42.121053, -80.089435];
Zipcodes['16502'] = [42.110735, -80.101294];
Zipcodes['16503'] = [42.127155, -80.061023];
Zipcodes['16504'] = [42.109409, -80.048842];
Zipcodes['16505'] = [42.11914, -80.127883];
Zipcodes['16506'] = [42.063038, -80.156034];
Zipcodes['16507'] = [42.139495, -80.084963];
Zipcodes['16508'] = [42.097774, -80.091462];
Zipcodes['16509'] = [42.060488, -80.045846];
Zipcodes['16510'] = [42.108991, -79.954821];
Zipcodes['16511'] = [42.167495, -80.005796];
Zipcodes['16546'] = [42.103873, -80.053348];
Zipcodes['16550'] = [42.133783, -80.084949];
Zipcodes['16563'] = [42.117759, -79.98577];
Zipcodes['16601'] = [40.552519, -78.372994];
Zipcodes['16602'] = [40.484994, -78.397114];
Zipcodes['16611'] = [40.571045, -78.091199];
Zipcodes['16613'] = [40.537222, -78.532129];
Zipcodes['16616'] = [40.769816, -78.458443];
Zipcodes['16617'] = [40.603389, -78.329834];
Zipcodes['16619'] = [40.683688, -78.415842];
Zipcodes['16620'] = [40.838548, -78.351235];
Zipcodes['16621'] = [40.258409, -78.108868];
Zipcodes['16622'] = [40.328538, -78.069211];
Zipcodes['16623'] = [40.27623, -78.045563];
Zipcodes['16624'] = [40.565126, -78.606652];
Zipcodes['16625'] = [40.282385, -78.519082];
Zipcodes['16627'] = [40.752924, -78.504133];
Zipcodes['16630'] = [40.462586, -78.580523];
Zipcodes['16631'] = [40.27141, -78.347488];
Zipcodes['16633'] = [40.159722, -78.232513];
Zipcodes['16634'] = [40.210519, -78.173071];
Zipcodes['16635'] = [40.407743, -78.477386];
Zipcodes['16636'] = [40.600253, -78.502014];
Zipcodes['16637'] = [40.342127, -78.470774];
Zipcodes['16638'] = [40.338208, -78.20095];
Zipcodes['16639'] = [40.681716, -78.463519];
Zipcodes['16640'] = [40.708001, -78.572338];
Zipcodes['16641'] = [40.507723, -78.571434];
Zipcodes['16645'] = [40.803608, -78.500731];
Zipcodes['16646'] = [40.689663, -78.726013];
Zipcodes['16647'] = [40.380477, -78.113284];
Zipcodes['16648'] = [40.441691, -78.33231];
Zipcodes['16650'] = [40.112053, -78.291955];
Zipcodes['16651'] = [40.851464, -78.380424];
Zipcodes['16652'] = [40.516022, -77.950096];
Zipcodes['16654'] = [40.491883, -78.028557];
Zipcodes['16655'] = [40.227952, -78.502963];
Zipcodes['16656'] = [40.807227, -78.555986];
Zipcodes['16657'] = [40.338382, -78.160584];
Zipcodes['16659'] = [40.160029, -78.388322];
Zipcodes['16661'] = [40.831088, -78.460928];
Zipcodes['16662'] = [40.295116, -78.297418];
Zipcodes['16664'] = [40.177862, -78.420196];
Zipcodes['16665'] = [40.389037, -78.434914];
Zipcodes['16666'] = [40.867714, -78.313969];
Zipcodes['16667'] = [40.182751, -78.52519];
Zipcodes['16668'] = [40.653348, -78.622324];
Zipcodes['16669'] = [40.655953, -77.906687];
Zipcodes['16670'] = [40.259035, -78.507702];
Zipcodes['16671'] = [40.787627, -78.39824];
Zipcodes['16672'] = [40.185501, -78.237228];
Zipcodes['16673'] = [40.305163, -78.388972];
Zipcodes['16674'] = [40.186476, -78.079259];
Zipcodes['16675'] = [40.687866, -78.678982];
Zipcodes['16677'] = [40.815095, -78.245636];
Zipcodes['16678'] = [40.225832, -78.2325];
Zipcodes['16679'] = [40.155253, -78.194384];
Zipcodes['16680'] = [40.743336, -78.377926];
Zipcodes['16682'] = [40.271993, -78.456469];
Zipcodes['16683'] = [40.663867, -78.065558];
Zipcodes['16684'] = [40.635431, -78.296894];
Zipcodes['16685'] = [40.280149, -78.080636];
Zipcodes['16686'] = [40.670411, -78.240506];
Zipcodes['16689'] = [40.075057, -78.084313];
Zipcodes['16691'] = [40.07745, -78.147309];
Zipcodes['16692'] = [40.750874, -78.710529];
Zipcodes['16693'] = [40.465298, -78.224595];
Zipcodes['16694'] = [40.189981, -78.1343];
Zipcodes['16695'] = [40.204653, -78.336929];
Zipcodes['16699'] = [40.447211, -78.560505];
Zipcodes['16701'] = [41.918671, -78.750365];
Zipcodes['16720'] = [41.603181, -78.009756];
Zipcodes['16724'] = [41.746136, -78.389766];
Zipcodes['16725'] = [41.899312, -78.661955];
Zipcodes['16726'] = [41.802392, -78.575869];
Zipcodes['16727'] = [41.98504, -78.533797];
Zipcodes['16729'] = [41.969668, -78.481818];
Zipcodes['16730'] = [41.817823, -78.422791];
Zipcodes['16731'] = [41.951416, -78.360051];
Zipcodes['16732'] = [41.8592, -78.608394];
Zipcodes['16733'] = [41.700389, -78.572341];
Zipcodes['16734'] = [41.615684, -78.847378];
Zipcodes['16735'] = [41.628758, -78.806743];
Zipcodes['16738'] = [41.806868, -78.732143];
Zipcodes['16740'] = [41.652656, -78.509599];
Zipcodes['16743'] = [41.767638, -78.232953];
Zipcodes['16744'] = [41.875647, -78.569451];
Zipcodes['16745'] = [41.925551, -78.489736];
Zipcodes['16746'] = [41.793875, -78.122491];
Zipcodes['16748'] = [41.942013, -78.144807];
Zipcodes['16749'] = [41.759754, -78.420964];
Zipcodes['16750'] = [41.901571, -78.291724];
Zipcodes['16801'] = [40.78521, -77.842297];
Zipcodes['16802'] = [40.798586, -77.864859];
Zipcodes['16803'] = [40.808517, -77.894436];
Zipcodes['16820'] = [40.919984, -77.386928];
Zipcodes['16821'] = [40.957848, -78.194577];
Zipcodes['16822'] = [41.137258, -77.707713];
Zipcodes['16823'] = [40.931203, -77.759053];
Zipcodes['16825'] = [40.98763, -78.31155];
Zipcodes['16826'] = [41.062067, -77.586019];
Zipcodes['16827'] = [40.76784, -77.772638];
Zipcodes['16828'] = [40.804311, -77.695937];
Zipcodes['16829'] = [41.084949, -77.887495];
Zipcodes['16830'] = [41.090593, -78.438945];
Zipcodes['16832'] = [40.84277, -77.425943];
Zipcodes['16833'] = [40.943955, -78.572434];
Zipcodes['16834'] = [41.051702, -78.087218];
Zipcodes['16835'] = [40.906681, -77.877341];
Zipcodes['16836'] = [41.145458, -78.26483];
Zipcodes['16837'] = [40.94544, -78.474451];
Zipcodes['16838'] = [41.005831, -78.651483];
Zipcodes['16839'] = [41.003239, -78.106435];
Zipcodes['16840'] = [40.924257, -78.20308];
Zipcodes['16841'] = [41.035527, -77.690859];
Zipcodes['16843'] = [41.011798, -78.46194];
Zipcodes['16844'] = [40.912809, -77.93263];
Zipcodes['16845'] = [41.113099, -77.927734];
Zipcodes['16847'] = [40.999285, -78.165498];
Zipcodes['16848'] = [41.011313, -77.533288];
Zipcodes['16849'] = [40.961123, -78.115673];
Zipcodes['16851'] = [40.812401, -77.80154];
Zipcodes['16852'] = [40.936383, -77.538555];
Zipcodes['16853'] = [40.940357, -77.786143];
Zipcodes['16854'] = [40.896976, -77.472324];
Zipcodes['16855'] = [41.005278, -78.353963];
Zipcodes['16856'] = [40.928771, -77.650034];
Zipcodes['16858'] = [41.003352, -78.209655];
Zipcodes['16859'] = [41.046594, -78.041808];
Zipcodes['16860'] = [40.937991, -78.179764];
Zipcodes['16861'] = [40.873405, -78.519324];
Zipcodes['16863'] = [40.907843, -78.474635];
Zipcodes['16864'] = [41.154254, -77.798547];
Zipcodes['16865'] = [40.724005, -77.955973];
Zipcodes['16866'] = [40.867022, -78.165573];
Zipcodes['16868'] = [40.727271, -77.89064];
Zipcodes['16870'] = [40.801279, -78.049352];
Zipcodes['16871'] = [41.191089, -78.035921];
Zipcodes['16872'] = [40.967214, -77.359431];
Zipcodes['16874'] = [40.985042, -77.951515];
Zipcodes['16875'] = [40.840418, -77.587993];
Zipcodes['16876'] = [40.965638, -78.287646];
Zipcodes['16877'] = [40.733197, -78.067952];
Zipcodes['16878'] = [40.940342, -78.351798];
Zipcodes['16879'] = [40.964382, -78.140732];
Zipcodes['16881'] = [41.02678, -78.320437];
Zipcodes['16882'] = [40.907077, -77.338465];
Zipcodes['16901'] = [41.734791, -77.315756];
Zipcodes['16910'] = [41.7046, -76.831056];
Zipcodes['16911'] = [41.676937, -77.101712];
Zipcodes['16912'] = [41.666056, -77.067212];
Zipcodes['16914'] = [41.854233, -76.783676];
Zipcodes['16915'] = [41.774893, -77.96139];
Zipcodes['16917'] = [41.722079, -77.056714];
Zipcodes['16920'] = [41.973761, -77.283566];
Zipcodes['16921'] = [41.723136, -77.522427];
Zipcodes['16922'] = [41.680658, -77.7107];
Zipcodes['16923'] = [41.950555, -77.870564];
Zipcodes['16925'] = [41.948567, -76.78381];
Zipcodes['16926'] = [41.715735, -76.700292];
Zipcodes['16927'] = [41.965439, -77.659294];
Zipcodes['16928'] = [41.952024, -77.433603];
Zipcodes['16929'] = [41.963406, -77.134908];
Zipcodes['16930'] = [41.583782, -77.138826];
Zipcodes['16932'] = [41.779128, -76.939472];
Zipcodes['16933'] = [41.819424, -77.062502];
Zipcodes['16935'] = [41.869179, -77.333696];
Zipcodes['16936'] = [41.951125, -76.967113];
Zipcodes['16937'] = [41.967896, -77.710941];
Zipcodes['16938'] = [41.591145, -77.370357];
Zipcodes['16939'] = [41.680994, -77.014099];
Zipcodes['16940'] = [41.977481, -77.233718];
Zipcodes['16941'] = [41.988296, -77.758047];
Zipcodes['16942'] = [41.965466, -77.354219];
Zipcodes['16943'] = [41.848117, -77.616217];
Zipcodes['16946'] = [41.915816, -77.142243];
Zipcodes['16947'] = [41.759502, -76.804051];
Zipcodes['16948'] = [41.854715, -77.759215];
Zipcodes['16950'] = [41.895764, -77.543654];
Zipcodes['17002'] = [40.46087, -77.869294];
Zipcodes['17003'] = [40.356692, -76.552401];
Zipcodes['17004'] = [40.601046, -77.730437];
Zipcodes['17005'] = [40.603061, -76.810622];
Zipcodes['17006'] = [40.335644, -77.492146];
Zipcodes['17007'] = [40.139031, -77.118466];
Zipcodes['17009'] = [40.636112, -77.565287];
Zipcodes['17010'] = [40.278122, -76.583335];
Zipcodes['17011'] = [40.23483, -76.928846];
Zipcodes['17013'] = [40.241159, -77.183824];
Zipcodes['17014'] = [40.6596, -77.193604];
Zipcodes['17015'] = [40.183404, -77.232115];
Zipcodes['17016'] = [40.273106, -76.406884];
Zipcodes['17017'] = [40.627036, -76.899583];
Zipcodes['17018'] = [40.422061, -76.826124];
Zipcodes['17019'] = [40.094021, -77.022075];
Zipcodes['17020'] = [40.414171, -77.041503];
Zipcodes['17021'] = [40.330526, -77.668192];
Zipcodes['17022'] = [40.167037, -76.608892];
Zipcodes['17023'] = [40.581304, -76.811737];
Zipcodes['17024'] = [40.406934, -77.306825];
Zipcodes['17025'] = [40.294091, -76.974475];
Zipcodes['17026'] = [40.473584, -76.437195];
Zipcodes['17027'] = [40.157418, -76.982115];
Zipcodes['17028'] = [40.402526, -76.675121];
Zipcodes['17029'] = [40.558017, -77.616997];
Zipcodes['17030'] = [40.608291, -76.744193];
Zipcodes['17032'] = [40.493937, -76.813511];
Zipcodes['17033'] = [40.269174, -76.628239];
Zipcodes['17034'] = [40.20861, -76.785533];
Zipcodes['17035'] = [40.403786, -77.581315];
Zipcodes['17036'] = [40.258186, -76.720181];
Zipcodes['17037'] = [40.432466, -77.427756];
Zipcodes['17038'] = [40.467819, -76.538202];
Zipcodes['17039'] = [40.292709, -76.240707];
Zipcodes['17040'] = [40.296219, -77.340009];
Zipcodes['17041'] = [40.216634, -76.539605];
Zipcodes['17042'] = [40.296378, -76.421124];
Zipcodes['17043'] = [40.247611, -76.900134];
Zipcodes['17044'] = [40.582082, -77.590269];
Zipcodes['17045'] = [40.597093, -77.000928];
Zipcodes['17046'] = [40.381655, -76.429383];
Zipcodes['17047'] = [40.371068, -77.411986];
Zipcodes['17048'] = [40.582253, -76.691987];
Zipcodes['17049'] = [40.651715, -77.259458];
Zipcodes['17050'] = [40.247547, -77.030441];
Zipcodes['17051'] = [40.459362, -77.764129];
Zipcodes['17052'] = [40.276899, -77.986339];
Zipcodes['17053'] = [40.32504, -77.026474];
Zipcodes['17054'] = [40.496849, -77.727253];
Zipcodes['17055'] = [40.177673, -76.997703];
Zipcodes['17056'] = [40.533518, -77.355554];
Zipcodes['17057'] = [40.19435, -76.726246];
Zipcodes['17058'] = [40.501043, -77.548014];
Zipcodes['17059'] = [40.589128, -77.390558];
Zipcodes['17060'] = [40.512957, -77.860364];
Zipcodes['17061'] = [40.563855, -76.900146];
Zipcodes['17062'] = [40.546773, -77.187656];
Zipcodes['17063'] = [40.761026, -77.484947];
Zipcodes['17064'] = [40.241695, -76.475272];
Zipcodes['17065'] = [40.070864, -77.232432];
Zipcodes['17066'] = [40.359304, -77.854619];
Zipcodes['17067'] = [40.390423, -76.315048];
Zipcodes['17068'] = [40.399127, -77.182392];
Zipcodes['17069'] = [40.458213, -76.98063];
Zipcodes['17070'] = [40.199561, -76.854444];
Zipcodes['17071'] = [40.295755, -77.614282];
Zipcodes['17073'] = [40.302838, -76.255161];
Zipcodes['17074'] = [40.479283, -77.148375];
Zipcodes['17075'] = [40.39366, -77.834499];
Zipcodes['17076'] = [40.620926, -77.307498];
Zipcodes['17077'] = [40.402378, -76.536464];
Zipcodes['17078'] = [40.287647, -76.580858];
Zipcodes['17080'] = [40.640543, -76.802909];
Zipcodes['17081'] = [40.201172, -77.283279];
Zipcodes['17082'] = [40.495064, -77.44839];
Zipcodes['17084'] = [40.675663, -77.626501];
Zipcodes['17086'] = [40.690624, -77.122007];
Zipcodes['17087'] = [40.404009, -76.268426];
Zipcodes['17088'] = [40.300443, -76.295163];
Zipcodes['17090'] = [40.318207, -77.181043];
Zipcodes['17093'] = [40.310323, -76.927888];
Zipcodes['17094'] = [40.585896, -77.222303];
Zipcodes['17097'] = [40.574148, -76.676881];
Zipcodes['17098'] = [40.589993, -76.630709];
Zipcodes['17099'] = [40.63673, -77.580106];
Zipcodes['17101'] = [40.258414, -76.89291];
Zipcodes['17102'] = [40.267798, -76.902414];
Zipcodes['17103'] = [40.274984, -76.863341];
Zipcodes['17104'] = [40.255017, -76.8672];
Zipcodes['17109'] = [40.289842, -76.824112];
Zipcodes['17110'] = [40.323487, -76.888179];
Zipcodes['17111'] = [40.270309, -76.780575];
Zipcodes['17112'] = [40.373989, -76.770206];
Zipcodes['17113'] = [40.230191, -76.821173];
Zipcodes['17120'] = [40.265123, -76.882763];
Zipcodes['17201'] = [39.96007, -77.657179];
Zipcodes['17202'] = [39.923181, -77.690468];
Zipcodes['17210'] = [40.191357, -77.661053];
Zipcodes['17211'] = [39.754707, -78.414571];
Zipcodes['17212'] = [39.812634, -78.064287];
Zipcodes['17213'] = [40.261245, -77.764539];
Zipcodes['17214'] = [39.748134, -77.470274];
Zipcodes['17215'] = [40.083767, -77.878633];
Zipcodes['17217'] = [40.238223, -77.722346];
Zipcodes['17219'] = [40.223951, -77.69282];
Zipcodes['17220'] = [40.174575, -77.73861];
Zipcodes['17221'] = [40.049213, -77.822182];
Zipcodes['17222'] = [39.895963, -77.493997];
Zipcodes['17223'] = [40.064649, -77.941989];
Zipcodes['17224'] = [39.979215, -77.884511];
Zipcodes['17225'] = [39.783506, -77.76287];
Zipcodes['17228'] = [39.995102, -78.098342];
Zipcodes['17229'] = [40.084004, -78.010139];
Zipcodes['17232'] = [40.094631, -77.633439];
Zipcodes['17233'] = [39.975605, -77.98716];
Zipcodes['17235'] = [39.857018, -77.697535];
Zipcodes['17236'] = [39.800185, -77.947396];
Zipcodes['17237'] = [39.837041, -77.540851];
Zipcodes['17238'] = [39.861119, -78.130033];
Zipcodes['17239'] = [40.139771, -77.830069];
Zipcodes['17240'] = [40.150871, -77.579686];
Zipcodes['17241'] = [40.181041, -77.406793];
Zipcodes['17243'] = [40.283629, -77.815059];
Zipcodes['17244'] = [40.090333, -77.672277];
Zipcodes['17246'] = [40.060726, -77.675777];
Zipcodes['17247'] = [39.798776, -77.579352];
Zipcodes['17249'] = [40.241883, -77.900179];
Zipcodes['17250'] = [39.736238, -77.522871];
Zipcodes['17251'] = [40.12287, -77.666668];
Zipcodes['17252'] = [39.912329, -77.82691];
Zipcodes['17253'] = [40.212399, -78.006251];
Zipcodes['17254'] = [39.96848, -77.58932];
Zipcodes['17255'] = [40.14924, -77.866728];
Zipcodes['17257'] = [40.055951, -77.496665];
Zipcodes['17260'] = [40.296045, -77.893701];
Zipcodes['17261'] = [39.859807, -77.511706];
Zipcodes['17262'] = [40.127086, -77.767903];
Zipcodes['17263'] = [39.724078, -77.717245];
Zipcodes['17264'] = [40.179907, -77.993335];
Zipcodes['17265'] = [40.032178, -77.774993];
Zipcodes['17266'] = [40.088568, -77.412933];
Zipcodes['17267'] = [39.807955, -78.239657];
Zipcodes['17268'] = [39.774913, -77.575622];
Zipcodes['17271'] = [40.101875, -77.803623];
Zipcodes['17301'] = [39.895227, -76.978982];
Zipcodes['17302'] = [39.81726, -76.41368];
Zipcodes['17303'] = [39.92609, -77.29762];
Zipcodes['17304'] = [39.977245, -77.232742];
Zipcodes['17306'] = [39.983798, -77.249695];
Zipcodes['17307'] = [39.956679, -77.315185];
Zipcodes['17309'] = [39.87358, -76.448883];
Zipcodes['17311'] = [39.816966, -76.841834];
Zipcodes['17313'] = [39.884759, -76.657175];
Zipcodes['17314'] = [39.749243, -76.325673];
Zipcodes['17315'] = [40.013828, -76.868711];
Zipcodes['17316'] = [39.965466, -77.007959];
Zipcodes['17317'] = [39.971309, -76.522617];
Zipcodes['17318'] = [40.020192, -76.726062];
Zipcodes['17319'] = [40.160489, -76.793975];
Zipcodes['17320'] = [39.770206, -77.387568];
Zipcodes['17321'] = [39.749675, -76.444345];
Zipcodes['17322'] = [39.855457, -76.535188];
Zipcodes['17323'] = [40.075225, -77.026693];
Zipcodes['17324'] = [40.030395, -77.236846];
Zipcodes['17325'] = [39.826258, -77.227606];
Zipcodes['17327'] = [39.779298, -76.753816];
Zipcodes['17329'] = [39.758637, -76.852508];
Zipcodes['17331'] = [39.791218, -76.980323];
Zipcodes['17339'] = [40.134907, -76.876143];
Zipcodes['17340'] = [39.755221, -77.116774];
Zipcodes['17342'] = [39.852798, -76.707836];
Zipcodes['17343'] = [39.868625, -77.329616];
Zipcodes['17344'] = [39.805322, -77.018876];
Zipcodes['17345'] = [40.072987, -76.727551];
Zipcodes['17347'] = [40.046858, -76.675341];
Zipcodes['17349'] = [39.745102, -76.65516];
Zipcodes['17350'] = [39.890088, -77.079399];
Zipcodes['17352'] = [39.761946, -76.500063];
Zipcodes['17353'] = [39.887165, -77.393094];
Zipcodes['17355'] = [39.75982, -76.696024];
Zipcodes['17356'] = [39.898326, -76.583705];
Zipcodes['17360'] = [39.851962, -76.753723];
Zipcodes['17361'] = [39.766082, -76.676983];
Zipcodes['17362'] = [39.847679, -76.868704];
Zipcodes['17363'] = [39.767343, -76.58501];
Zipcodes['17364'] = [39.92987, -76.900444];
Zipcodes['17365'] = [40.061392, -76.940309];
Zipcodes['17366'] = [39.93279, -76.556703];
Zipcodes['17368'] = [39.984559, -76.519611];
Zipcodes['17370'] = [40.118209, -76.782812];
Zipcodes['17371'] = [39.902613, -76.787991];
Zipcodes['17372'] = [39.992447, -77.107953];
Zipcodes['17401'] = [39.958779, -76.733219];
Zipcodes['17402'] = [39.956996, -76.659977];
Zipcodes['17403'] = [39.919821, -76.711182];
Zipcodes['17404'] = [40.002636, -76.773535];
Zipcodes['17406'] = [40.014709, -76.640873];
Zipcodes['17407'] = [39.887079, -76.71275];
Zipcodes['17408'] = [39.93282, -76.799214];
Zipcodes['17501'] = [40.156733, -76.20608];
Zipcodes['17502'] = [40.100904, -76.660147];
Zipcodes['17504'] = [40.024472, -76.328494];
Zipcodes['17505'] = [40.063501, -76.192436];
Zipcodes['17506'] = [40.117172, -76.052736];
Zipcodes['17507'] = [40.198053, -76.01624];
Zipcodes['17508'] = [40.124577, -76.213904];
Zipcodes['17509'] = [39.906919, -76.029457];
Zipcodes['17512'] = [40.040069, -76.486021];
Zipcodes['17516'] = [39.926859, -76.384989];
Zipcodes['17517'] = [40.244751, -76.132881];
Zipcodes['17518'] = [39.80893, -76.253014];
Zipcodes['17519'] = [40.141438, -76.023423];
Zipcodes['17520'] = [40.097518, -76.349193];
Zipcodes['17522'] = [40.173688, -76.170494];
Zipcodes['17527'] = [40.016431, -75.994145];
Zipcodes['17529'] = [40.041933, -76.097142];
Zipcodes['17532'] = [39.858222, -76.287916];
Zipcodes['17534'] = [40.036612, -76.112164];
Zipcodes['17535'] = [40.012085, -76.036127];
Zipcodes['17536'] = [39.848034, -76.073774];
Zipcodes['17538'] = [40.082629, -76.414548];
Zipcodes['17540'] = [40.097608, -76.189987];
Zipcodes['17543'] = [40.181032, -76.29538];
Zipcodes['17545'] = [40.172979, -76.422168];
Zipcodes['17547'] = [40.070915, -76.584858];
Zipcodes['17550'] = [40.074909, -76.583516];
Zipcodes['17551'] = [39.978498, -76.372983];
Zipcodes['17552'] = [40.108352, -76.51092];
Zipcodes['17554'] = [40.040303, -76.424176];
Zipcodes['17555'] = [40.122377, -75.959392];
Zipcodes['17557'] = [40.102865, -76.075182];
Zipcodes['17560'] = [39.912675, -76.227681];
Zipcodes['17562'] = [39.969976, -76.091632];
Zipcodes['17563'] = [39.760748, -76.193306];
Zipcodes['17565'] = [39.890772, -76.335311];
Zipcodes['17566'] = [39.867186, -76.147513];
Zipcodes['17568'] = [39.948019, -76.234129];
Zipcodes['17569'] = [40.271433, -76.096577];
Zipcodes['17570'] = [40.12568, -76.570628];
Zipcodes['17572'] = [40.005624, -76.15216];
Zipcodes['17576'] = [40.037111, -76.197806];
Zipcodes['17578'] = [40.229906, -76.166163];
Zipcodes['17579'] = [39.960406, -76.180752];
Zipcodes['17581'] = [40.155776, -76.054995];
Zipcodes['17582'] = [39.988249, -76.454665];
Zipcodes['17584'] = [39.959837, -76.265024];
Zipcodes['17601'] = [40.072957, -76.313729];
Zipcodes['17602'] = [40.014623, -76.246351];
Zipcodes['17603'] = [40.030839, -76.330432];
Zipcodes['17606'] = [40.112734, -76.304715];
Zipcodes['17701'] = [41.345044, -76.857249];
Zipcodes['17702'] = [41.183488, -77.077271];
Zipcodes['17720'] = [41.179084, -77.202589];
Zipcodes['17721'] = [41.181568, -77.319403];
Zipcodes['17723'] = [41.435297, -77.471356];
Zipcodes['17724'] = [41.6377, -76.800688];
Zipcodes['17727'] = [41.529261, -77.464763];
Zipcodes['17728'] = [41.334774, -77.078567];
Zipcodes['17729'] = [41.511239, -77.736103];
Zipcodes['17730'] = [41.106905, -76.875924];
Zipcodes['17731'] = [41.42941, -76.578672];
Zipcodes['17737'] = [41.285628, -76.683033];
Zipcodes['17739'] = [41.416293, -77.393562];
Zipcodes['17740'] = [41.235613, -77.256778];
Zipcodes['17742'] = [41.239052, -76.579668];
Zipcodes['17744'] = [41.24097, -77.153274];
Zipcodes['17745'] = [41.285072, -77.471166];
Zipcodes['17747'] = [41.032551, -77.331831];
Zipcodes['17748'] = [41.147811, -77.340152];
Zipcodes['17749'] = [41.072303, -76.81353];
Zipcodes['17750'] = [41.057866, -77.482253];
Zipcodes['17751'] = [41.129525, -77.521238];
Zipcodes['17752'] = [41.183141, -76.91983];
Zipcodes['17754'] = [41.311612, -76.889212];
Zipcodes['17756'] = [41.204764, -76.729928];
Zipcodes['17758'] = [41.350305, -76.544903];
Zipcodes['17760'] = [41.413576, -77.657357];
Zipcodes['17762'] = [41.284336, -76.699941];
Zipcodes['17763'] = [41.502453, -76.912176];
Zipcodes['17764'] = [41.342778, -77.826495];
Zipcodes['17765'] = [41.581828, -76.982013];
Zipcodes['17768'] = [41.558688, -76.755993];
Zipcodes['17769'] = [41.510689, -77.496445];
Zipcodes['17771'] = [41.437339, -77.03554];
Zipcodes['17772'] = [41.125057, -76.713033];
Zipcodes['17774'] = [41.251867, -76.546733];
Zipcodes['17776'] = [41.41464, -77.292868];
Zipcodes['17777'] = [41.106517, -76.821696];
Zipcodes['17778'] = [41.296316, -77.979525];
Zipcodes['17779'] = [41.208075, -77.37699];
Zipcodes['17801'] = [40.837399, -76.760701];
Zipcodes['17810'] = [41.100951, -77.03905];
Zipcodes['17812'] = [40.733279, -77.23524];
Zipcodes['17813'] = [40.786898, -77.184303];
Zipcodes['17814'] = [41.258909, -76.371219];
Zipcodes['17815'] = [41.022328, -76.429517];
Zipcodes['17820'] = [40.895639, -76.403739];
Zipcodes['17821'] = [40.990147, -76.644205];
Zipcodes['17822'] = [40.968662, -76.603296];
Zipcodes['17823'] = [40.744977, -76.777978];
Zipcodes['17824'] = [40.867611, -76.492771];
Zipcodes['17827'] = [40.75286, -76.968032];
Zipcodes['17829'] = [40.900568, -77.1554];
Zipcodes['17830'] = [40.686062, -76.805198];
Zipcodes['17832'] = [40.805568, -76.455006];
Zipcodes['17834'] = [40.78021, -76.466875];
Zipcodes['17835'] = [40.883523, -77.197994];
Zipcodes['17836'] = [40.714342, -76.606462];
Zipcodes['17837'] = [40.974977, -76.94515];
Zipcodes['17840'] = [40.774382, -76.426873];
Zipcodes['17841'] = [40.72538, -77.363424];
Zipcodes['17842'] = [40.801316, -77.043454];
Zipcodes['17844'] = [40.959839, -77.085195];
Zipcodes['17845'] = [40.88437, -77.21157];
Zipcodes['17846'] = [41.141745, -76.517122];
Zipcodes['17847'] = [41.007985, -76.810949];
Zipcodes['17850'] = [40.967179, -76.858577];
Zipcodes['17851'] = [40.8028, -76.424701];
Zipcodes['17853'] = [40.687984, -77.010671];
Zipcodes['17855'] = [40.889993, -76.967853];
Zipcodes['17856'] = [41.045019, -77.008116];
Zipcodes['17857'] = [40.932136, -76.772972];
Zipcodes['17858'] = [40.84241, -76.423278];
Zipcodes['17859'] = [41.116755, -76.398537];
Zipcodes['17860'] = [40.839179, -76.603574];
Zipcodes['17861'] = [40.77463, -77.076157];
Zipcodes['17862'] = [40.860506, -77.056395];
Zipcodes['17864'] = [40.704727, -76.905819];
Zipcodes['17865'] = [40.986673, -76.789771];
Zipcodes['17866'] = [40.771113, -76.596493];
Zipcodes['17867'] = [40.714661, -76.690322];
Zipcodes['17868'] = [40.955445, -76.630962];
Zipcodes['17870'] = [40.80804, -76.887755];
Zipcodes['17872'] = [40.758168, -76.688372];
Zipcodes['17876'] = [40.858162, -76.825783];
Zipcodes['17878'] = [41.176018, -76.323488];
Zipcodes['17880'] = [40.891097, -77.123864];
Zipcodes['17881'] = [40.780997, -76.686347];
Zipcodes['17884'] = [41.051986, -76.674978];
Zipcodes['17885'] = [40.866345, -77.314017];
Zipcodes['17886'] = [41.018188, -76.876117];
Zipcodes['17887'] = [41.07531, -76.895759];
Zipcodes['17888'] = [40.818676, -76.378947];
Zipcodes['17889'] = [40.877739, -76.921282];
Zipcodes['17901'] = [40.694711, -76.274753];
Zipcodes['17920'] = [40.808425, -76.32783];
Zipcodes['17921'] = [40.751575, -76.360586];
Zipcodes['17922'] = [40.582473, -76.110632];
Zipcodes['17923'] = [40.661402, -76.318735];
Zipcodes['17925'] = [40.754797, -76.072504];
Zipcodes['17929'] = [40.631054, -76.192766];
Zipcodes['17930'] = [40.708675, -76.115346];
Zipcodes['17931'] = [40.786491, -76.214575];
Zipcodes['17933'] = [40.603688, -76.242608];
Zipcodes['17934'] = [40.799168, -76.213444];
Zipcodes['17935'] = [40.794324, -76.280037];
Zipcodes['17936'] = [40.750402, -76.340065];
Zipcodes['17938'] = [40.654247, -76.501236];
Zipcodes['17941'] = [40.686254, -76.613215];
Zipcodes['17943'] = [40.761663, -76.379394];
Zipcodes['17944'] = [40.66048, -76.289032];
Zipcodes['17945'] = [40.785343, -76.375466];
Zipcodes['17946'] = [40.810364, -76.256372];
Zipcodes['17948'] = [40.820393, -76.144031];
Zipcodes['17949'] = [40.796066, -76.236125];
Zipcodes['17951'] = [40.679171, -76.244185];
Zipcodes['17952'] = [40.764362, -76.060032];
Zipcodes['17953'] = [40.746816, -76.100452];
Zipcodes['17954'] = [40.690459, -76.259866];
Zipcodes['17957'] = [40.593575, -76.516637];
Zipcodes['17959'] = [40.739797, -76.164668];
Zipcodes['17960'] = [40.697241, -75.947044];
Zipcodes['17961'] = [40.649699, -76.060949];
Zipcodes['17963'] = [40.559863, -76.394119];
Zipcodes['17964'] = [40.702954, -76.514515];
Zipcodes['17965'] = [40.699246, -76.165356];
Zipcodes['17967'] = [40.869818, -76.207085];
Zipcodes['17968'] = [40.641705, -76.626286];
Zipcodes['17970'] = [40.720663, -76.190544];
Zipcodes['17972'] = [40.579336, -76.227913];
Zipcodes['17974'] = [40.695924, -76.236127];
Zipcodes['17976'] = [40.819899, -76.210503];
Zipcodes['17978'] = [40.616479, -76.652263];
Zipcodes['17979'] = [40.567253, -76.202053];
Zipcodes['17980'] = [40.518684, -76.644559];
Zipcodes['17981'] = [40.630984, -76.392688];
Zipcodes['17982'] = [40.768001, -76.038084];
Zipcodes['17983'] = [40.643994, -76.55038];
Zipcodes['17985'] = [40.918455, -76.23871];
Zipcodes['18011'] = [40.477013, -75.646129];
Zipcodes['18012'] = [40.811314, -75.591615];
Zipcodes['18013'] = [40.853468, -75.165323];
Zipcodes['18014'] = [40.76715, -75.413067];
Zipcodes['18015'] = [40.587439, -75.366758];
Zipcodes['18017'] = [40.659957, -75.387306];
Zipcodes['18018'] = [40.627594, -75.395584];
Zipcodes['18020'] = [40.672385, -75.327459];
Zipcodes['18030'] = [40.804067, -75.663487];
Zipcodes['18031'] = [40.559553, -75.648875];
Zipcodes['18032'] = [40.656191, -75.467788];
Zipcodes['18034'] = [40.541387, -75.41898];
Zipcodes['18035'] = [40.751946, -75.537984];
Zipcodes['18036'] = [40.509206, -75.388539];
Zipcodes['18037'] = [40.683132, -75.547957];
Zipcodes['18038'] = [40.794842, -75.486224];
Zipcodes['18040'] = [40.745941, -75.225667];
Zipcodes['18041'] = [40.420404, -75.50972];
Zipcodes['18042'] = [40.643635, -75.226627];
Zipcodes['18045'] = [40.691748, -75.274923];
Zipcodes['18046'] = [40.548009, -75.560346];
Zipcodes['18049'] = [40.522207, -75.490269];
Zipcodes['18051'] = [40.59561, -75.666932];
Zipcodes['18052'] = [40.657428, -75.504255];
Zipcodes['18053'] = [40.71923, -75.704117];
Zipcodes['18054'] = [40.352369, -75.438736];
Zipcodes['18055'] = [40.591583, -75.304353];
Zipcodes['18056'] = [40.447051, -75.547229];
Zipcodes['18058'] = [40.888739, -75.49429];
Zipcodes['18059'] = [40.723499, -75.543084];
Zipcodes['18062'] = [40.505829, -75.579888];
Zipcodes['18063'] = [40.782777, -75.181792];
Zipcodes['18064'] = [40.757008, -75.315962];
Zipcodes['18065'] = [40.699331, -75.612269];
Zipcodes['18066'] = [40.664462, -75.7401];
Zipcodes['18067'] = [40.717001, -75.482335];
Zipcodes['18069'] = [40.625246, -75.615098];
Zipcodes['18070'] = [40.430437, -75.539196];
Zipcodes['18071'] = [40.834214, -75.555278];
Zipcodes['18072'] = [40.84414, -75.259135];
Zipcodes['18073'] = [40.386499, -75.47443];
Zipcodes['18074'] = [40.316737, -75.515686];
Zipcodes['18076'] = [40.373755, -75.481545];
Zipcodes['18077'] = [40.570065, -75.237115];
Zipcodes['18078'] = [40.670888, -75.620714];
Zipcodes['18079'] = [40.744052, -75.656866];
Zipcodes['18080'] = [40.730808, -75.638089];
Zipcodes['18081'] = [40.563698, -75.281584];
Zipcodes['18083'] = [40.755137, -75.264548];
Zipcodes['18085'] = [40.741702, -75.254287];
Zipcodes['18086'] = [40.739162, -75.548368];
Zipcodes['18087'] = [40.556831, -75.593645];
Zipcodes['18088'] = [40.765692, -75.557509];
Zipcodes['18091'] = [40.824846, -75.325651];
Zipcodes['18092'] = [40.469697, -75.51314];
Zipcodes['18101'] = [40.603231, -75.469505];
Zipcodes['18102'] = [40.607938, -75.476203];
Zipcodes['18103'] = [40.570308, -75.488926];
Zipcodes['18104'] = [40.611658, -75.546853];
Zipcodes['18106'] = [40.563735, -75.565198];
Zipcodes['18109'] = [40.635486, -75.441922];
Zipcodes['18195'] = [40.584272, -75.624789];
Zipcodes['18201'] = [40.951974, -75.953925];
Zipcodes['18202'] = [40.954433, -76.045987];
Zipcodes['18210'] = [41.001862, -75.59105];
Zipcodes['18211'] = [40.744079, -75.830407];
Zipcodes['18212'] = [40.772951, -75.718831];
Zipcodes['18214'] = [40.805771, -76.088524];
Zipcodes['18216'] = [40.942664, -75.889032];
Zipcodes['18218'] = [40.81974, -75.916102];
Zipcodes['18219'] = [40.991045, -76.058262];
Zipcodes['18220'] = [40.837322, -76.081715];
Zipcodes['18221'] = [41.001462, -75.918954];
Zipcodes['18222'] = [41.035227, -76.003468];
Zipcodes['18223'] = [40.98226, -75.950004];
Zipcodes['18224'] = [41.024571, -75.889265];
Zipcodes['18225'] = [40.981311, -75.971311];
Zipcodes['18229'] = [40.931517, -75.682343];
Zipcodes['18230'] = [40.920118, -75.930569];
Zipcodes['18231'] = [40.900734, -76.004552];
Zipcodes['18232'] = [40.837221, -75.873962];
Zipcodes['18234'] = [40.992592, -75.964513];
Zipcodes['18235'] = [40.835018, -75.691474];
Zipcodes['18237'] = [40.878534, -76.050441];
Zipcodes['18239'] = [40.989676, -75.981362];
Zipcodes['18240'] = [40.862917, -75.842359];
Zipcodes['18241'] = [40.944364, -76.164982];
Zipcodes['18242'] = [40.90602, -76.123495];
Zipcodes['18244'] = [40.823663, -75.66874];
Zipcodes['18245'] = [40.85279, -76.030107];
Zipcodes['18246'] = [40.95744, -76.195028];
Zipcodes['18248'] = [40.900868, -76.094503];
Zipcodes['18249'] = [40.987794, -76.116117];
Zipcodes['18250'] = [40.812659, -75.858686];
Zipcodes['18252'] = [40.781606, -75.972444];
Zipcodes['18254'] = [40.915913, -75.965651];
Zipcodes['18255'] = [40.933491, -75.829695];
Zipcodes['18256'] = [40.940396, -76.14732];
Zipcodes['18301'] = [41.04236, -75.175818];
Zipcodes['18302'] = [41.096574, -75.111116];
Zipcodes['18321'] = [41.026293, -75.282886];
Zipcodes['18322'] = [40.918523, -75.393279];
Zipcodes['18323'] = [41.195599, -75.272693];
Zipcodes['18324'] = [41.158143, -75.00069];
Zipcodes['18325'] = [41.26195, -75.266692];
Zipcodes['18326'] = [41.154998, -75.247188];
Zipcodes['18327'] = [40.983375, -75.146645];
Zipcodes['18328'] = [41.2285, -74.973985];
Zipcodes['18330'] = [40.962546, -75.449916];
Zipcodes['18331'] = [40.908412, -75.440228];
Zipcodes['18332'] = [41.094747, -75.261341];
Zipcodes['18333'] = [40.898344, -75.490978];
Zipcodes['18334'] = [41.072851, -75.441764];
Zipcodes['18335'] = [41.058231, -75.107671];
Zipcodes['18336'] = [41.373373, -74.73975];
Zipcodes['18337'] = [41.32911, -74.871787];
Zipcodes['18340'] = [41.417768, -74.765898];
Zipcodes['18342'] = [41.170421, -75.304875];
Zipcodes['18343'] = [40.889898, -75.109437];
Zipcodes['18344'] = [41.121414, -75.334054];
Zipcodes['18346'] = [41.142754, -75.421191];
Zipcodes['18347'] = [41.154683, -75.542841];
Zipcodes['18349'] = [41.099516, -75.368194];
Zipcodes['18350'] = [41.114427, -75.458202];
Zipcodes['18351'] = [40.923109, -75.099884];
Zipcodes['18353'] = [40.895341, -75.357667];
Zipcodes['18354'] = [40.922603, -75.322095];
Zipcodes['18355'] = [41.076955, -75.362899];
Zipcodes['18356'] = [41.01578, -75.111962];
Zipcodes['18357'] = [41.231653, -75.210713];
Zipcodes['18360'] = [40.967289, -75.288004];
Zipcodes['18370'] = [41.088357, -75.336595];
Zipcodes['18371'] = [41.156225, -75.036657];
Zipcodes['18372'] = [41.044043, -75.334289];
Zipcodes['18403'] = [41.508998, -75.534936];
Zipcodes['18405'] = [41.599854, -75.103874];
Zipcodes['18407'] = [41.591754, -75.534853];
Zipcodes['18411'] = [41.459175, -75.746872];
Zipcodes['18413'] = [41.647649, -75.532704];
Zipcodes['18414'] = [41.541941, -75.745401];
Zipcodes['18415'] = [41.755374, -75.082464];
Zipcodes['18417'] = [41.809703, -75.201115];
Zipcodes['18419'] = [41.577245, -75.79753];
Zipcodes['18420'] = [41.60768, -75.699862];
Zipcodes['18421'] = [41.664189, -75.492862];
Zipcodes['18424'] = [41.244183, -75.517295];
Zipcodes['18425'] = [41.438455, -75.043136];
Zipcodes['18426'] = [41.325507, -75.239061];
Zipcodes['18428'] = [41.450408, -75.109392];
Zipcodes['18430'] = [41.740828, -75.474185];
Zipcodes['18431'] = [41.61647, -75.257711];
Zipcodes['18433'] = [41.573307, -75.58133];
Zipcodes['18434'] = [41.452222, -75.54128];
Zipcodes['18435'] = [41.490583, -75.015021];
Zipcodes['18436'] = [41.442792, -75.393451];
Zipcodes['18437'] = [41.875251, -75.285748];
Zipcodes['18438'] = [41.432453, -75.245288];
Zipcodes['18439'] = [41.825503, -75.348966];
Zipcodes['18440'] = [41.559335, -75.765121];
Zipcodes['18441'] = [41.663281, -75.619249];
Zipcodes['18443'] = [41.662927, -75.102094];
Zipcodes['18444'] = [41.340521, -75.551731];
Zipcodes['18445'] = [41.297908, -75.359362];
Zipcodes['18446'] = [41.656175, -75.759143];
Zipcodes['18447'] = [41.510737, -75.62048];
Zipcodes['18451'] = [41.396999, -75.217929];
Zipcodes['18452'] = [41.483875, -75.589585];
Zipcodes['18453'] = [41.745101, -75.367184];
Zipcodes['18454'] = [41.821496, -75.432908];
Zipcodes['18455'] = [41.893001, -75.359193];
Zipcodes['18456'] = [41.620762, -75.336858];
Zipcodes['18457'] = [41.472094, -75.049165];
Zipcodes['18458'] = [41.395767, -74.919277];
Zipcodes['18460'] = [41.268824, -75.344812];
Zipcodes['18461'] = [41.929003, -75.327674];
Zipcodes['18462'] = [41.899549, -75.43553];
Zipcodes['18463'] = [41.346946, -75.380412];
Zipcodes['18464'] = [41.40379, -75.177744];
Zipcodes['18465'] = [41.831299, -75.516849];
Zipcodes['18466'] = [41.195722, -75.376895];
Zipcodes['18469'] = [41.702468, -75.151986];
Zipcodes['18470'] = [41.735054, -75.52909];
Zipcodes['18471'] = [41.529124, -75.697314];
Zipcodes['18472'] = [41.588123, -75.399611];
Zipcodes['18473'] = [41.530249, -75.206824];
Zipcodes['18503'] = [41.41008, -75.667703];
Zipcodes['18504'] = [41.42347, -75.705416];
Zipcodes['18505'] = [41.387986, -75.648355];
Zipcodes['18507'] = [41.359075, -75.681879];
Zipcodes['18508'] = [41.455072, -75.658252];
Zipcodes['18509'] = [41.430641, -75.643228];
Zipcodes['18510'] = [41.407325, -75.643713];
Zipcodes['18512'] = [41.426455, -75.59293];
Zipcodes['18517'] = [41.395238, -75.714626];
Zipcodes['18518'] = [41.374961, -75.739617];
Zipcodes['18519'] = [41.462045, -75.630622];
Zipcodes['18602'] = [41.186018, -75.753988];
Zipcodes['18603'] = [41.082935, -76.247853];
Zipcodes['18610'] = [41.058966, -75.533827];
Zipcodes['18612'] = [41.349715, -75.980488];
Zipcodes['18614'] = [41.483404, -76.356396];
Zipcodes['18615'] = [41.457799, -75.858674];
Zipcodes['18616'] = [41.519364, -76.640201];
Zipcodes['18617'] = [41.17054, -76.077533];
Zipcodes['18618'] = [41.380285, -76.038202];
Zipcodes['18619'] = [41.431411, -76.713084];
Zipcodes['18621'] = [41.255347, -76.091065];
Zipcodes['18622'] = [41.201232, -76.273515];
Zipcodes['18623'] = [41.664801, -76.151984];
Zipcodes['18624'] = [41.060706, -75.64656];
Zipcodes['18625'] = [41.515032, -75.850156];
Zipcodes['18626'] = [41.413567, -76.454466];
Zipcodes['18627'] = [41.315591, -76.022356];
Zipcodes['18628'] = [41.430143, -76.313808];
Zipcodes['18629'] = [41.517604, -76.141339];
Zipcodes['18630'] = [41.662144, -76.023636];
Zipcodes['18631'] = [41.030711, -76.302779];
Zipcodes['18632'] = [41.45936, -76.37747];
Zipcodes['18634'] = [41.185511, -76.022618];
Zipcodes['18635'] = [41.02924, -76.201347];
Zipcodes['18636'] = [41.399204, -76.081856];
Zipcodes['18640'] = [41.297358, -75.740248];
Zipcodes['18641'] = [41.336398, -75.728394];
Zipcodes['18642'] = [41.356348, -75.773181];
Zipcodes['18643'] = [41.361912, -75.838549];
Zipcodes['18644'] = [41.327989, -75.881964];
Zipcodes['18651'] = [41.254051, -75.959827];
Zipcodes['18655'] = [41.187724, -76.203214];
Zipcodes['18656'] = [41.332543, -76.185438];
Zipcodes['18657'] = [41.52251, -75.959485];
Zipcodes['18660'] = [41.098662, -76.062912];
Zipcodes['18661'] = [41.076392, -75.740795];
Zipcodes['18701'] = [41.243237, -75.885302];
Zipcodes['18702'] = [41.230478, -75.757439];
Zipcodes['18704'] = [41.276189, -75.895218];
Zipcodes['18705'] = [41.271848, -75.842772];
Zipcodes['18706'] = [41.203289, -75.914803];
Zipcodes['18707'] = [41.133883, -75.918347];
Zipcodes['18708'] = [41.295848, -75.963463];
Zipcodes['18709'] = [41.286326, -75.89673];
Zipcodes['18801'] = [41.826389, -75.939711];
Zipcodes['18810'] = [41.931063, -76.501007];
Zipcodes['18812'] = [41.973202, -75.964751];
Zipcodes['18814'] = [41.783946, -76.600757];
Zipcodes['18816'] = [41.739764, -75.909739];
Zipcodes['18817'] = [41.87785, -76.62082];
Zipcodes['18818'] = [41.914932, -76.050887];
Zipcodes['18820'] = [41.777665, -75.650371];
Zipcodes['18821'] = [41.98982, -75.722697];
Zipcodes['18822'] = [41.940124, -75.794962];
Zipcodes['18823'] = [41.773948, -75.691554];
Zipcodes['18824'] = [41.697366, -75.787183];
Zipcodes['18825'] = [41.825618, -75.607459];
Zipcodes['18826'] = [41.748621, -75.727481];
Zipcodes['18828'] = [41.797443, -76.110303];
Zipcodes['18829'] = [41.837644, -76.173693];
Zipcodes['18830'] = [41.959087, -76.114452];
Zipcodes['18831'] = [41.894223, -76.598118];
Zipcodes['18832'] = [41.678216, -76.568867];
Zipcodes['18833'] = [41.59067, -76.46939];
Zipcodes['18834'] = [41.830967, -75.720934];
Zipcodes['18837'] = [41.915072, -76.30286];
Zipcodes['18840'] = [41.973067, -76.530371];
Zipcodes['18842'] = [41.768961, -75.612955];
Zipcodes['18843'] = [41.804449, -75.89414];
Zipcodes['18844'] = [41.69097, -75.905239];
Zipcodes['18845'] = [41.787336, -76.18212];
Zipcodes['18846'] = [41.621411, -76.229986];
Zipcodes['18847'] = [41.928841, -75.579051];
Zipcodes['18848'] = [41.753437, -76.457497];
Zipcodes['18850'] = [41.871983, -76.472515];
Zipcodes['18851'] = [41.936675, -76.179694];
Zipcodes['18853'] = [41.721414, -76.276925];
Zipcodes['18854'] = [41.790666, -76.355714];
Zipcodes['18901'] = [40.304772, -75.150789];
Zipcodes['18902'] = [40.353296, -75.097808];
Zipcodes['18912'] = [40.315655, -75.063721];
Zipcodes['18913'] = [40.372212, -75.056125];
Zipcodes['18914'] = [40.289488, -75.2107];
Zipcodes['18915'] = [40.272505, -75.256209];
Zipcodes['18917'] = [40.369133, -75.200018];
Zipcodes['18920'] = [40.493003, -75.086697];
Zipcodes['18921'] = [40.529626, -75.171066];
Zipcodes['18923'] = [40.353617, -75.166702];
Zipcodes['18925'] = [40.283671, -75.062906];
Zipcodes['18927'] = [40.324302, -75.240948];
Zipcodes['18929'] = [40.258108, -75.079119];
Zipcodes['18930'] = [40.532189, -75.212624];
Zipcodes['18932'] = [40.291585, -75.250486];
Zipcodes['18933'] = [40.402463, -75.039241];
Zipcodes['18935'] = [40.432826, -75.394995];
Zipcodes['18936'] = [40.226493, -75.228091];
Zipcodes['18938'] = [40.351048, -74.998049];
Zipcodes['18940'] = [40.260594, -74.93707];
Zipcodes['18942'] = [40.474101, -75.166632];
Zipcodes['18944'] = [40.396234, -75.231167];
Zipcodes['18946'] = [40.286153, -75.005153];
Zipcodes['18947'] = [40.422899, -75.117839];
Zipcodes['18950'] = [40.434635, -75.066443];
Zipcodes['18951'] = [40.459182, -75.347403];
Zipcodes['18954'] = [40.225413, -74.992957];
Zipcodes['18955'] = [40.482445, -75.311319];
Zipcodes['18960'] = [40.366295, -75.325895];
Zipcodes['18962'] = [40.344604, -75.270454];
Zipcodes['18964'] = [40.30347, -75.337889];
Zipcodes['18966'] = [40.188468, -75.009898];
Zipcodes['18969'] = [40.326304, -75.368422];
Zipcodes['18970'] = [40.412019, -75.381111];
Zipcodes['18972'] = [40.52732, -75.124666];
Zipcodes['18974'] = [40.217062, -75.072803];
Zipcodes['18976'] = [40.248821, -75.143522];
Zipcodes['18977'] = [40.284173, -74.87417];
Zipcodes['18980'] = [40.27276, -75.010943];
Zipcodes['19001'] = [40.12623, -75.126511];
Zipcodes['19002'] = [40.187753, -75.215847];
Zipcodes['19003'] = [40.002113, -75.298395];
Zipcodes['19004'] = [40.009753, -75.231508];
Zipcodes['19006'] = [40.128573, -75.059719];
Zipcodes['19007'] = [40.116388, -74.857078];
Zipcodes['19008'] = [39.974219, -75.361244];
Zipcodes['19009'] = [40.137798, -75.063121];
Zipcodes['19010'] = [40.023764, -75.330471];
Zipcodes['19012'] = [40.059691, -75.105874];
Zipcodes['19013'] = [39.845414, -75.374817];
Zipcodes['19014'] = [39.864907, -75.432958];
Zipcodes['19015'] = [39.86653, -75.391496];
Zipcodes['19017'] = [39.890842, -75.462953];
Zipcodes['19018'] = [39.923146, -75.298322];
Zipcodes['19020'] = [40.105952, -74.936276];
Zipcodes['19021'] = [40.088944, -74.894079];
Zipcodes['19022'] = [39.857445, -75.336965];
Zipcodes['19023'] = [39.91724, -75.267087];
Zipcodes['19025'] = [40.145584, -75.163224];
Zipcodes['19026'] = [39.950354, -75.304047];
Zipcodes['19027'] = [40.073118, -75.124431];
Zipcodes['19029'] = [39.862699, -75.292734];
Zipcodes['19030'] = [40.182098, -74.8346];
Zipcodes['19031'] = [40.10825, -75.217468];
Zipcodes['19032'] = [39.888246, -75.275774];
Zipcodes['19033'] = [39.890804, -75.32885];
Zipcodes['19034'] = [40.133515, -75.209509];
Zipcodes['19035'] = [40.051349, -75.277642];
Zipcodes['19036'] = [39.903712, -75.293236];
Zipcodes['19038'] = [40.10521, -75.169812];
Zipcodes['19040'] = [40.177294, -75.10619];
Zipcodes['19041'] = [40.004484, -75.320002];
Zipcodes['19043'] = [39.900483, -75.308894];
Zipcodes['19044'] = [40.188988, -75.152483];
Zipcodes['19046'] = [40.101341, -75.105577];
Zipcodes['19047'] = [40.180448, -74.912451];
Zipcodes['19050'] = [39.937646, -75.263526];
Zipcodes['19052'] = [39.897179, -75.445336];
Zipcodes['19053'] = [40.153918, -74.974991];
Zipcodes['19054'] = [40.170692, -74.816584];
Zipcodes['19055'] = [40.150307, -74.839218];
Zipcodes['19056'] = [40.149508, -74.884744];
Zipcodes['19057'] = [40.14089, -74.858362];
Zipcodes['19060'] = [39.850699, -75.491965];
Zipcodes['19061'] = [39.827818, -75.431926];
Zipcodes['19063'] = [39.922229, -75.414058];
Zipcodes['19064'] = [39.932078, -75.338962];
Zipcodes['19066'] = [40.002176, -75.248899];
Zipcodes['19067'] = [40.229997, -74.85935];
Zipcodes['19070'] = [39.906316, -75.324539];
Zipcodes['19072'] = [40.023891, -75.257741];
Zipcodes['19073'] = [39.983253, -75.429218];
Zipcodes['19074'] = [39.88599, -75.295756];
Zipcodes['19075'] = [40.112298, -75.186672];
Zipcodes['19076'] = [39.886097, -75.30737];
Zipcodes['19078'] = [39.873641, -75.321331];
Zipcodes['19079'] = [39.902263, -75.265718];
Zipcodes['19081'] = [39.898604, -75.34751];
Zipcodes['19082'] = [39.960428, -75.27032];
Zipcodes['19083'] = [39.977153, -75.311947];
Zipcodes['19085'] = [40.036973, -75.349913];
Zipcodes['19086'] = [39.890943, -75.37016];
Zipcodes['19087'] = [40.062099, -75.400394];
Zipcodes['19090'] = [40.148072, -75.124082];
Zipcodes['19094'] = [39.875033, -75.346694];
Zipcodes['19095'] = [40.085875, -75.151366];
Zipcodes['19096'] = [39.998147, -75.273908];
Zipcodes['19102'] = [39.952983, -75.165474];
Zipcodes['19103'] = [39.953372, -75.174187];
Zipcodes['19104'] = [39.958518, -75.198856];
Zipcodes['19106'] = [39.946649, -75.142232];
Zipcodes['19107'] = [39.951764, -75.158667];
Zipcodes['19108'] = [39.959865, -75.160829];
Zipcodes['19109'] = [39.949652, -75.163654];
Zipcodes['19111'] = [40.060612, -75.080176];
Zipcodes['19112'] = [39.889857, -75.16939];
Zipcodes['19113'] = [39.871363, -75.272947];
Zipcodes['19114'] = [40.069399, -75.000162];
Zipcodes['19115'] = [40.090698, -75.042868];
Zipcodes['19116'] = [40.117418, -75.015101];
Zipcodes['19118'] = [40.073872, -75.211217];
Zipcodes['19119'] = [40.052811, -75.191167];
Zipcodes['19120'] = [40.034147, -75.119198];
Zipcodes['19121'] = [39.982071, -75.179825];
Zipcodes['19122'] = [39.97771, -75.145722];
Zipcodes['19123'] = [39.963734, -75.145482];
Zipcodes['19124'] = [40.017257, -75.095261];
Zipcodes['19125'] = [39.976249, -75.125105];
Zipcodes['19126'] = [40.056119, -75.136564];
Zipcodes['19127'] = [40.029238, -75.226914];
Zipcodes['19128'] = [40.049525, -75.230253];
Zipcodes['19129'] = [40.013014, -75.185402];
Zipcodes['19130'] = [39.967502, -75.174671];
Zipcodes['19131'] = [39.986937, -75.219246];
Zipcodes['19132'] = [39.996864, -75.170871];
Zipcodes['19133'] = [39.993007, -75.141016];
Zipcodes['19134'] = [39.989223, -75.108756];
Zipcodes['19135'] = [40.021728, -75.050671];
Zipcodes['19136'] = [40.039406, -75.018555];
Zipcodes['19137'] = [39.993, -75.072052];
Zipcodes['19138'] = [40.055957, -75.159485];
Zipcodes['19139'] = [39.961575, -75.231146];
Zipcodes['19140'] = [40.012279, -75.145084];
Zipcodes['19141'] = [40.037921, -75.145377];
Zipcodes['19142'] = [39.921752, -75.233279];
Zipcodes['19143'] = [39.943806, -75.226551];
Zipcodes['19144'] = [40.031929, -75.176099];
Zipcodes['19145'] = [39.914501, -75.191217];
Zipcodes['19146'] = [39.939496, -75.184146];
Zipcodes['19147'] = [39.936404, -75.154729];
Zipcodes['19148'] = [39.911612, -75.151475];
Zipcodes['19149'] = [40.03783, -75.065542];
Zipcodes['19150'] = [40.072512, -75.171694];
Zipcodes['19151'] = [39.979613, -75.257043];
Zipcodes['19152'] = [40.061595, -75.046385];
Zipcodes['19153'] = [39.879986, -75.237908];
Zipcodes['19154'] = [40.096483, -74.983217];
Zipcodes['19301'] = [40.040279, -75.480341];
Zipcodes['19310'] = [39.935094, -75.972833];
Zipcodes['19311'] = [39.821095, -75.771347];
Zipcodes['19312'] = [40.032159, -75.451354];
Zipcodes['19316'] = [40.054083, -75.833836];
Zipcodes['19317'] = [39.862461, -75.602787];
Zipcodes['19319'] = [39.926816, -75.524481];
Zipcodes['19320'] = [39.962991, -75.832026];
Zipcodes['19330'] = [39.868292, -75.913785];
Zipcodes['19333'] = [40.041872, -75.423597];
Zipcodes['19335'] = [40.022618, -75.721218];
Zipcodes['19341'] = [40.039967, -75.640752];
Zipcodes['19342'] = [39.907638, -75.500025];
Zipcodes['19343'] = [40.100772, -75.754945];
Zipcodes['19344'] = [40.083577, -75.881364];
Zipcodes['19345'] = [40.029699, -75.569519];
Zipcodes['19348'] = [39.866024, -75.720243];
Zipcodes['19350'] = [39.760276, -75.796353];
Zipcodes['19352'] = [39.783773, -75.888327];
Zipcodes['19355'] = [40.045497, -75.527903];
Zipcodes['19358'] = [39.964404, -75.807075];
Zipcodes['19362'] = [39.750743, -76.070383];
Zipcodes['19363'] = [39.787821, -75.968115];
Zipcodes['19365'] = [39.966371, -75.925129];
Zipcodes['19367'] = [39.962894, -75.883869];
Zipcodes['19369'] = [39.98069, -75.894734];
Zipcodes['19372'] = [39.999056, -75.757445];
Zipcodes['19373'] = [39.901103, -75.533499];
Zipcodes['19374'] = [39.829318, -75.7512];
Zipcodes['19375'] = [39.900053, -75.73698];
Zipcodes['19380'] = [39.987218, -75.603138];
Zipcodes['19382'] = [39.927665, -75.613166];
Zipcodes['19383'] = [39.936617, -75.606001];
Zipcodes['19390'] = [39.836097, -75.842359];
Zipcodes['19401'] = [40.13018, -75.331629];
Zipcodes['19403'] = [40.148523, -75.37908];
Zipcodes['19405'] = [40.099644, -75.334292];
Zipcodes['19406'] = [40.094879, -75.385685];
Zipcodes['19422'] = [40.15681, -75.279393];
Zipcodes['19425'] = [40.104954, -75.643665];
Zipcodes['19426'] = [40.189235, -75.438102];
Zipcodes['19428'] = [40.080216, -75.302504];
Zipcodes['19435'] = [40.327903, -75.565205];
Zipcodes['19436'] = [40.202502, -75.248805];
Zipcodes['19437'] = [40.180101, -75.260459];
Zipcodes['19438'] = [40.269439, -75.390006];
Zipcodes['19440'] = [40.285137, -75.290907];
Zipcodes['19442'] = [40.1327, -75.578788];
Zipcodes['19444'] = [40.086857, -75.254274];
Zipcodes['19446'] = [40.23068, -75.303647];
Zipcodes['19453'] = [40.138397, -75.502225];
Zipcodes['19454'] = [40.226113, -75.243786];
Zipcodes['19456'] = [40.136643, -75.459637];
Zipcodes['19457'] = [40.206881, -75.585751];
Zipcodes['19460'] = [40.126329, -75.529946];
Zipcodes['19462'] = [40.115345, -75.28199];
Zipcodes['19464'] = [40.258928, -75.615861];
Zipcodes['19465'] = [40.190386, -75.68457];
Zipcodes['19468'] = [40.207638, -75.532067];
Zipcodes['19472'] = [40.340446, -75.581887];
Zipcodes['19473'] = [40.256168, -75.482534];
Zipcodes['19474'] = [40.224264, -75.404995];
Zipcodes['19475'] = [40.171654, -75.598754];
Zipcodes['19477'] = [40.184453, -75.235796];
Zipcodes['19490'] = [40.196723, -75.354004];
Zipcodes['19492'] = [40.282294, -75.493445];
Zipcodes['19501'] = [40.244477, -76.064701];
Zipcodes['19503'] = [40.408009, -75.571478];
Zipcodes['19504'] = [40.416215, -75.588554];
Zipcodes['19505'] = [40.379095, -75.62242];
Zipcodes['19506'] = [40.454218, -76.12869];
Zipcodes['19507'] = [40.505167, -76.270312];
Zipcodes['19508'] = [40.260389, -75.832122];
Zipcodes['19510'] = [40.445918, -75.882015];
Zipcodes['19511'] = [40.485917, -75.74323];
Zipcodes['19512'] = [40.350632, -75.679126];
Zipcodes['19516'] = [40.486855, -76.005264];
Zipcodes['19518'] = [40.271635, -75.750052];
Zipcodes['19520'] = [40.157484, -75.80073];
Zipcodes['19522'] = [40.449419, -75.816732];
Zipcodes['19523'] = [40.200867, -75.850772];
Zipcodes['19525'] = [40.308839, -75.586107];
Zipcodes['19526'] = [40.547096, -75.996638];
Zipcodes['19529'] = [40.64112, -75.858751];
Zipcodes['19530'] = [40.539073, -75.781444];
Zipcodes['19533'] = [40.425173, -75.992673];
Zipcodes['19534'] = [40.582788, -75.879334];
Zipcodes['19535'] = [40.339729, -75.794304];
Zipcodes['19536'] = [40.480578, -75.758948];
Zipcodes['19538'] = [40.547577, -75.702623];
Zipcodes['19539'] = [40.496313, -75.683558];
Zipcodes['19540'] = [40.236277, -75.96661];
Zipcodes['19541'] = [40.478321, -76.017793];
Zipcodes['19543'] = [40.175621, -75.894981];
Zipcodes['19544'] = [40.416876, -76.296566];
Zipcodes['19545'] = [40.34051, -75.630476];
Zipcodes['19547'] = [40.380716, -75.772948];
Zipcodes['19549'] = [40.583253, -76.030814];
Zipcodes['19550'] = [40.454939, -76.249432];
Zipcodes['19551'] = [40.361782, -76.137324];
Zipcodes['19554'] = [40.512879, -76.103733];
Zipcodes['19555'] = [40.494381, -75.953921];
Zipcodes['19559'] = [40.493171, -76.185127];
Zipcodes['19560'] = [40.403867, -75.880401];
Zipcodes['19562'] = [40.502989, -75.701767];
Zipcodes['19564'] = [40.518015, -75.872059];
Zipcodes['19565'] = [40.342507, -76.088241];
Zipcodes['19567'] = [40.39907, -76.209365];
Zipcodes['19601'] = [40.360982, -75.936935];
Zipcodes['19602'] = [40.327563, -75.916724];
Zipcodes['19604'] = [40.358299, -75.90947];
Zipcodes['19605'] = [40.39857, -75.946123];
Zipcodes['19606'] = [40.338916, -75.857079];
Zipcodes['19607'] = [40.291483, -75.944791];
Zipcodes['19608'] = [40.314491, -76.035213];
Zipcodes['19609'] = [40.327682, -75.996519];
Zipcodes['19610'] = [40.341535, -75.974921];
Zipcodes['19611'] = [40.324674, -75.942857];
Zipcodes['19701'] = [39.583895, -75.696995];
Zipcodes['19702'] = [39.6182, -75.729724];
Zipcodes['19703'] = [39.801514, -75.453853];
Zipcodes['19706'] = [39.574954, -75.590676];
Zipcodes['19707'] = [39.785594, -75.68364];
Zipcodes['19709'] = [39.494369, -75.67747];
Zipcodes['19710'] = [39.789573, -75.584684];
Zipcodes['19711'] = [39.713781, -75.740826];
Zipcodes['19713'] = [39.670751, -75.710123];
Zipcodes['19716'] = [39.667959, -75.746184];
Zipcodes['19717'] = [39.678689, -75.749962];
Zipcodes['19720'] = [39.645467, -75.604496];
Zipcodes['19730'] = [39.454954, -75.661037];
Zipcodes['19731'] = [39.509434, -75.601291];
Zipcodes['19732'] = [39.800296, -75.574599];
Zipcodes['19733'] = [39.559085, -75.651523];
Zipcodes['19734'] = [39.381644, -75.653322];
Zipcodes['19735'] = [39.806643, -75.597331];
Zipcodes['19736'] = [39.780331, -75.642813];
Zipcodes['19801'] = [39.723588, -75.541208];
Zipcodes['19802'] = [39.755042, -75.526462];
Zipcodes['19803'] = [39.799872, -75.542232];
Zipcodes['19804'] = [39.71876, -75.621866];
Zipcodes['19805'] = [39.74521, -75.593712];
Zipcodes['19806'] = [39.762542, -75.563298];
Zipcodes['19807'] = [39.7965, -75.61284];
Zipcodes['19808'] = [39.737615, -75.667057];
Zipcodes['19809'] = [39.756674, -75.501035];
Zipcodes['19810'] = [39.818682, -75.506183];
Zipcodes['19901'] = [39.181542, -75.47352];
Zipcodes['19902'] = [39.127656, -75.466911];
Zipcodes['19904'] = [39.171279, -75.587619];
Zipcodes['19930'] = [38.552863, -75.069397];
Zipcodes['19931'] = [38.569298, -75.610451];
Zipcodes['19933'] = [38.733018, -75.603361];
Zipcodes['19934'] = [39.084727, -75.627355];
Zipcodes['19936'] = [39.218635, -75.585114];
Zipcodes['19938'] = [39.256465, -75.698822];
Zipcodes['19939'] = [38.568957, -75.207538];
Zipcodes['19940'] = [38.477562, -75.564931];
Zipcodes['19941'] = [38.793658, -75.426062];
Zipcodes['19943'] = [39.004436, -75.606986];
Zipcodes['19944'] = [38.477868, -75.053083];
Zipcodes['19945'] = [38.497894, -75.229033];
Zipcodes['19946'] = [39.034802, -75.449585];
Zipcodes['19947'] = [38.670266, -75.404663];
Zipcodes['19950'] = [38.818451, -75.596743];
Zipcodes['19951'] = [38.685257, -75.255153];
Zipcodes['19952'] = [38.916699, -75.612104];
Zipcodes['19953'] = [39.152487, -75.694133];
Zipcodes['19954'] = [38.89701, -75.520457];
Zipcodes['19955'] = [39.226969, -75.664724];
Zipcodes['19956'] = [38.547645, -75.538186];
Zipcodes['19958'] = [38.728714, -75.168507];
Zipcodes['19960'] = [38.850913, -75.408716];
Zipcodes['19962'] = [39.068808, -75.488388];
Zipcodes['19963'] = [38.943253, -75.362567];
Zipcodes['19964'] = [39.096027, -75.725463];
Zipcodes['19966'] = [38.579422, -75.277196];
Zipcodes['19967'] = [38.539706, -75.120863];
Zipcodes['19968'] = [38.78292, -75.279802];
Zipcodes['19970'] = [38.554973, -75.099405];
Zipcodes['19971'] = [38.694504, -75.0997];
Zipcodes['19973'] = [38.634462, -75.623572];
Zipcodes['19975'] = [38.468327, -75.16688];
Zipcodes['19977'] = [39.298946, -75.546122];
Zipcodes['19979'] = [39.050367, -75.576462];
Zipcodes['19980'] = [39.073522, -75.570169];
Zipcodes['20001'] = [38.910353, -77.017739];
Zipcodes['20002'] = [38.905026, -76.983607];
Zipcodes['20003'] = [38.88184, -76.99094];
Zipcodes['20004'] = [38.895093, -77.026584];
Zipcodes['20005'] = [38.904438, -77.031597];
Zipcodes['20006'] = [38.898482, -77.041205];
Zipcodes['20007'] = [38.917716, -77.090635];
Zipcodes['20008'] = [38.934815, -77.05894];
Zipcodes['20009'] = [38.919144, -77.037381];
Zipcodes['20010'] = [38.932825, -77.031798];
Zipcodes['20011'] = [38.952514, -77.022991];
Zipcodes['20012'] = [38.979021, -77.030801];
Zipcodes['20015'] = [38.966889, -77.058323];
Zipcodes['20016'] = [38.936909, -77.090037];
Zipcodes['20017'] = [38.938154, -76.992909];
Zipcodes['20018'] = [38.926576, -76.974446];
Zipcodes['20019'] = [38.892246, -76.941772];
Zipcodes['20020'] = [38.860382, -76.978961];
Zipcodes['20024'] = [38.870543, -77.026826];
Zipcodes['20032'] = [38.831882, -77.016556];
Zipcodes['20036'] = [38.907034, -77.041677];
Zipcodes['20037'] = [38.892459, -77.053811];
Zipcodes['20045'] = [38.896731, -77.030798];
Zipcodes['20052'] = [38.89879, -77.047787];
Zipcodes['20057'] = [38.908971, -77.075171];
Zipcodes['20059'] = [38.92246, -77.020042];
Zipcodes['20064'] = [38.936371, -76.99921];
Zipcodes['20105'] = [38.953169, -77.603594];
Zipcodes['20106'] = [38.699346, -78.002209];
Zipcodes['20109'] = [38.793898, -77.530962];
Zipcodes['20110'] = [38.746071, -77.487673];
Zipcodes['20111'] = [38.748968, -77.427873];
Zipcodes['20112'] = [38.670807, -77.430106];
Zipcodes['20115'] = [38.816307, -77.88957];
Zipcodes['20117'] = [38.998822, -77.733937];
Zipcodes['20119'] = [38.62063, -77.625662];
Zipcodes['20120'] = [38.856974, -77.484219];
Zipcodes['20121'] = [38.811033, -77.462944];
Zipcodes['20124'] = [38.781332, -77.391333];
Zipcodes['20129'] = [39.163358, -77.593204];
Zipcodes['20130'] = [39.039676, -77.941872];
Zipcodes['20132'] = [39.165816, -77.726945];
Zipcodes['20135'] = [39.086313, -77.887226];
Zipcodes['20136'] = [38.729834, -77.544163];
Zipcodes['20137'] = [38.806579, -77.722437];
Zipcodes['20141'] = [39.121311, -77.790923];
Zipcodes['20143'] = [38.875302, -77.562249];
Zipcodes['20144'] = [38.920726, -77.935434];
Zipcodes['20147'] = [39.041947, -77.47813];
Zipcodes['20148'] = [38.993905, -77.532196];
Zipcodes['20151'] = [38.897187, -77.443874];
Zipcodes['20152'] = [38.899353, -77.518706];
Zipcodes['20155'] = [38.806662, -77.621357];
Zipcodes['20158'] = [39.139647, -77.658859];
Zipcodes['20164'] = [39.011103, -77.396849];
Zipcodes['20165'] = [39.057538, -77.392009];
Zipcodes['20166'] = [38.970032, -77.464002];
Zipcodes['20169'] = [38.872078, -77.641383];
Zipcodes['20170'] = [38.979842, -77.37975];
Zipcodes['20171'] = [38.926874, -77.396775];
Zipcodes['20175'] = [39.064398, -77.603768];
Zipcodes['20176'] = [39.180323, -77.533459];
Zipcodes['20180'] = [39.268591, -77.637636];
Zipcodes['20181'] = [38.66911, -77.549571];
Zipcodes['20184'] = [39.001419, -77.878577];
Zipcodes['20186'] = [38.687799, -77.839607];
Zipcodes['20187'] = [38.722131, -77.736919];
Zipcodes['20190'] = [38.959187, -77.34107];
Zipcodes['20191'] = [38.933315, -77.350118];
Zipcodes['20194'] = [38.981041, -77.340785];
Zipcodes['20197'] = [39.19521, -77.62801];
Zipcodes['20198'] = [38.884142, -77.744559];
Zipcodes['20204'] = [38.885559, -77.014429];
Zipcodes['20220'] = [38.897117, -77.034359];
Zipcodes['20230'] = [38.893744, -77.035673];
Zipcodes['20240'] = [38.894456, -77.042605];
Zipcodes['20245'] = [38.893583, -77.04504];
Zipcodes['20250'] = [38.885607, -77.032805];
Zipcodes['20260'] = [38.883544, -77.025032];
Zipcodes['20317'] = [38.934841, -77.014387];
Zipcodes['20319'] = [38.866947, -77.016965];
Zipcodes['20373'] = [38.854523, -77.013071];
Zipcodes['20388'] = [38.872429, -76.9939];
Zipcodes['20390'] = [38.879019, -76.993695];
Zipcodes['20408'] = [38.892845, -77.023011];
Zipcodes['20415'] = [38.894933, -77.044173];
Zipcodes['20418'] = [38.8928, -77.047764];
Zipcodes['20422'] = [38.927128, -77.010316];
Zipcodes['20427'] = [38.902057, -77.047558];
Zipcodes['20431'] = [38.89895, -77.044186];
Zipcodes['20510'] = [38.89278, -77.00689];
Zipcodes['20515'] = [38.886816, -77.009012];
Zipcodes['20520'] = [38.8947, -77.048481];
Zipcodes['20530'] = [38.893124, -77.025023];
Zipcodes['20535'] = [38.895136, -77.024933];
Zipcodes['20540'] = [38.887929, -77.004713];
Zipcodes['20542'] = [38.941356, -77.02801];
Zipcodes['20551'] = [38.892802, -77.0458];
Zipcodes['20560'] = [38.890126, -77.027245];
Zipcodes['20565'] = [38.89013, -77.019487];
Zipcodes['20566'] = [38.895752, -77.055634];
Zipcodes['20591'] = [38.886946, -77.022968];
Zipcodes['20601'] = [38.619558, -76.856042];
Zipcodes['20602'] = [38.583248, -76.894703];
Zipcodes['20603'] = [38.62913, -76.975586];
Zipcodes['20606'] = [38.258011, -76.743306];
Zipcodes['20607'] = [38.673313, -77.02684];
Zipcodes['20608'] = [38.582405, -76.700998];
Zipcodes['20609'] = [38.281215, -76.748472];
Zipcodes['20611'] = [38.454964, -76.987618];
Zipcodes['20612'] = [38.509514, -76.679329];
Zipcodes['20613'] = [38.671168, -76.805304];
Zipcodes['20615'] = [38.411433, -76.548803];
Zipcodes['20616'] = [38.661948, -77.098458];
Zipcodes['20617'] = [38.543841, -76.852511];
Zipcodes['20618'] = [38.276012, -76.799881];
Zipcodes['20619'] = [38.292531, -76.527662];
Zipcodes['20620'] = [38.233122, -76.52183];
Zipcodes['20621'] = [38.32615, -76.801054];
Zipcodes['20622'] = [38.417355, -76.878012];
Zipcodes['20623'] = [38.741615, -76.842456];
Zipcodes['20624'] = [38.33808, -76.733499];
Zipcodes['20625'] = [38.257481, -76.84453];
Zipcodes['20626'] = [38.236528, -76.763481];
Zipcodes['20628'] = [38.152998, -76.339859];
Zipcodes['20629'] = [38.344109, -76.456029];
Zipcodes['20630'] = [38.160103, -76.463456];
Zipcodes['20632'] = [38.422801, -76.95482];
Zipcodes['20634'] = [38.241711, -76.498277];
Zipcodes['20636'] = [38.352141, -76.567187];
Zipcodes['20637'] = [38.525112, -76.755989];
Zipcodes['20639'] = [38.601241, -76.605476];
Zipcodes['20640'] = [38.526733, -77.201604];
Zipcodes['20645'] = [38.297219, -76.916184];
Zipcodes['20646'] = [38.520653, -76.999915];
Zipcodes['20650'] = [38.25993, -76.63242];
Zipcodes['20653'] = [38.237089, -76.428692];
Zipcodes['20656'] = [38.338457, -76.681318];
Zipcodes['20657'] = [38.384195, -76.440057];
Zipcodes['20658'] = [38.57071, -77.158356];
Zipcodes['20659'] = [38.407894, -76.732045];
Zipcodes['20660'] = [38.3693, -76.70731];
Zipcodes['20661'] = [38.263698, -76.848986];
Zipcodes['20662'] = [38.428507, -77.205325];
Zipcodes['20664'] = [38.342701, -76.910242];
Zipcodes['20667'] = [38.215237, -76.447756];
Zipcodes['20670'] = [38.284848, -76.407661];
Zipcodes['20674'] = [38.147123, -76.535249];
Zipcodes['20675'] = [38.583482, -77.015238];
Zipcodes['20676'] = [38.487361, -76.545326];
Zipcodes['20677'] = [38.495253, -77.036717];
Zipcodes['20678'] = [38.525112, -76.5954];
Zipcodes['20680'] = [38.10913, -76.385886];
Zipcodes['20684'] = [38.125487, -76.420544];
Zipcodes['20685'] = [38.434378, -76.528681];
Zipcodes['20686'] = [38.187079, -76.432826];
Zipcodes['20687'] = [38.063698, -76.339689];
Zipcodes['20688'] = [38.327259, -76.464324];
Zipcodes['20689'] = [38.670275, -76.585916];
Zipcodes['20690'] = [38.158366, -76.53478];
Zipcodes['20692'] = [38.180353, -76.499612];
Zipcodes['20693'] = [38.459058, -77.087352];
Zipcodes['20695'] = [38.587767, -76.9788];
Zipcodes['20701'] = [39.132522, -76.788264];
Zipcodes['20705'] = [39.047104, -76.898079];
Zipcodes['20706'] = [38.96588, -76.851092];
Zipcodes['20707'] = [39.095593, -76.883877];
Zipcodes['20708'] = [39.042551, -76.826485];
Zipcodes['20710'] = [38.94168, -76.925876];
Zipcodes['20711'] = [38.801059, -76.645107];
Zipcodes['20712'] = [38.942361, -76.964578];
Zipcodes['20714'] = [38.703075, -76.532853];
Zipcodes['20715'] = [38.986806, -76.736266];
Zipcodes['20716'] = [38.926428, -76.715028];
Zipcodes['20720'] = [38.996376, -76.783377];
Zipcodes['20721'] = [38.915222, -76.785129];
Zipcodes['20722'] = [38.934557, -76.950596];
Zipcodes['20723'] = [39.137678, -76.867942];
Zipcodes['20724'] = [39.08027, -76.790561];
Zipcodes['20732'] = [38.65514, -76.542571];
Zipcodes['20733'] = [38.805653, -76.532229];
Zipcodes['20735'] = [38.750917, -76.902669];
Zipcodes['20736'] = [38.689913, -76.617273];
Zipcodes['20737'] = [38.962373, -76.915191];
Zipcodes['20740'] = [39.002743, -76.930804];
Zipcodes['20742'] = [38.994183, -76.94344];
Zipcodes['20743'] = [38.883854, -76.894232];
Zipcodes['20744'] = [38.745892, -76.983201];
Zipcodes['20745'] = [38.805571, -76.994654];
Zipcodes['20746'] = [38.836403, -76.918265];
Zipcodes['20747'] = [38.853309, -76.883028];
Zipcodes['20748'] = [38.814868, -76.933447];
Zipcodes['20751'] = [38.794437, -76.561199];
Zipcodes['20754'] = [38.731807, -76.65658];
Zipcodes['20755'] = [39.098497, -76.745479];
Zipcodes['20758'] = [38.734005, -76.589616];
Zipcodes['20759'] = [39.153977, -76.931171];
Zipcodes['20762'] = [38.809803, -76.869158];
Zipcodes['20763'] = [39.137262, -76.820091];
Zipcodes['20764'] = [38.831941, -76.504324];
Zipcodes['20765'] = [38.846396, -76.547617];
Zipcodes['20769'] = [38.991875, -76.809211];
Zipcodes['20770'] = [38.999726, -76.889112];
Zipcodes['20771'] = [39.008593, -76.855976];
Zipcodes['20772'] = [38.780274, -76.76699];
Zipcodes['20774'] = [38.876255, -76.769603];
Zipcodes['20776'] = [38.878351, -76.592005];
Zipcodes['20777'] = [39.174938, -76.969085];
Zipcodes['20778'] = [38.828701, -76.572747];
Zipcodes['20779'] = [38.75859, -76.569497];
Zipcodes['20781'] = [38.95286, -76.94629];
Zipcodes['20782'] = [38.965877, -76.966076];
Zipcodes['20783'] = [38.997067, -76.966783];
Zipcodes['20784'] = [38.950549, -76.89172];
Zipcodes['20785'] = [38.918291, -76.881948];
Zipcodes['20794'] = [39.151475, -76.794703];
Zipcodes['20812'] = [38.967041, -77.143881];
Zipcodes['20814'] = [39.004839, -77.103004];
Zipcodes['20815'] = [38.983386, -77.079337];
Zipcodes['20816'] = [38.95695, -77.120006];
Zipcodes['20817'] = [38.997279, -77.151816];
Zipcodes['20818'] = [38.973486, -77.162389];
Zipcodes['20832'] = [39.150349, -77.078251];
Zipcodes['20833'] = [39.210355, -77.061622];
Zipcodes['20837'] = [39.116506, -77.407115];
Zipcodes['20838'] = [39.22409, -77.370287];
Zipcodes['20839'] = [39.181539, -77.430796];
Zipcodes['20841'] = [39.181961, -77.32247];
Zipcodes['20842'] = [39.199879, -77.415266];
Zipcodes['20850'] = [39.089211, -77.180501];
Zipcodes['20851'] = [39.07807, -77.120838];
Zipcodes['20852'] = [39.051511, -77.123973];
Zipcodes['20853'] = [39.101747, -77.094602];
Zipcodes['20854'] = [39.032772, -77.22083];
Zipcodes['20855'] = [39.136974, -77.131801];
Zipcodes['20860'] = [39.150964, -77.034962];
Zipcodes['20861'] = [39.148685, -76.999085];
Zipcodes['20862'] = [39.1808, -77.017395];
Zipcodes['20866'] = [39.10928, -76.933948];
Zipcodes['20868'] = [39.125872, -76.961413];
Zipcodes['20871'] = [39.258885, -77.281829];
Zipcodes['20872'] = [39.299203, -77.206561];
Zipcodes['20874'] = [39.133056, -77.301828];
Zipcodes['20876'] = [39.206734, -77.233675];
Zipcodes['20877'] = [39.140601, -77.19206];
Zipcodes['20878'] = [39.112637, -77.250436];
Zipcodes['20879'] = [39.167298, -77.164147];
Zipcodes['20880'] = [39.140699, -77.174533];
Zipcodes['20882'] = [39.228682, -77.162652];
Zipcodes['20886'] = [39.188512, -77.205143];
Zipcodes['20889'] = [39.003756, -77.086964];
Zipcodes['20892'] = [39.000635, -77.103711];
Zipcodes['20894'] = [38.99586, -77.102139];
Zipcodes['20895'] = [39.027048, -77.07759];
Zipcodes['20896'] = [39.035252, -77.095044];
Zipcodes['20899'] = [39.1444, -77.216175];
Zipcodes['20901'] = [39.021495, -77.009779];
Zipcodes['20902'] = [39.043755, -77.042369];
Zipcodes['20903'] = [39.021433, -76.980774];
Zipcodes['20904'] = [39.070379, -76.977379];
Zipcodes['20905'] = [39.109866, -76.988077];
Zipcodes['20906'] = [39.087235, -77.054952];
Zipcodes['20910'] = [39.00286, -77.036646];
Zipcodes['20912'] = [38.981601, -77.001159];
Zipcodes['21001'] = [39.504488, -76.201049];
Zipcodes['21005'] = [39.472632, -76.110666];
Zipcodes['21009'] = [39.471468, -76.284636];
Zipcodes['21010'] = [39.390932, -76.292629];
Zipcodes['21012'] = [39.046562, -76.495626];
Zipcodes['21013'] = [39.51149, -76.4908];
Zipcodes['21014'] = [39.53584, -76.35161];
Zipcodes['21015'] = [39.544176, -76.292469];
Zipcodes['21017'] = [39.474831, -76.234575];
Zipcodes['21023'] = [39.523198, -76.740196];
Zipcodes['21028'] = [39.565965, -76.249527];
Zipcodes['21029'] = [39.21356, -76.959392];
Zipcodes['21030'] = [39.51582, -76.698751];
Zipcodes['21031'] = [39.487392, -76.657938];
Zipcodes['21032'] = [39.028343, -76.604145];
Zipcodes['21034'] = [39.640582, -76.223104];
Zipcodes['21035'] = [38.9383, -76.626588];
Zipcodes['21036'] = [39.234644, -77.006739];
Zipcodes['21037'] = [38.919253, -76.543492];
Zipcodes['21040'] = [39.429886, -76.28361];
Zipcodes['21042'] = [39.277181, -76.894602];
Zipcodes['21043'] = [39.255836, -76.801491];
Zipcodes['21044'] = [39.205274, -76.878737];
Zipcodes['21045'] = [39.206042, -76.828234];
Zipcodes['21046'] = [39.174058, -76.835354];
Zipcodes['21047'] = [39.526941, -76.438078];
Zipcodes['21048'] = [39.500375, -76.924538];
Zipcodes['21050'] = [39.585343, -76.392164];
Zipcodes['21051'] = [39.472691, -76.455841];
Zipcodes['21052'] = [39.205023, -76.439629];
Zipcodes['21053'] = [39.696792, -76.714672];
Zipcodes['21054'] = [39.055593, -76.6905];
Zipcodes['21056'] = [39.078951, -76.436246];
Zipcodes['21057'] = [39.448749, -76.512474];
Zipcodes['21060'] = [39.17364, -76.577642];
Zipcodes['21061'] = [39.160897, -76.632112];
Zipcodes['21071'] = [39.483808, -76.81036];
Zipcodes['21074'] = [39.621599, -76.839931];
Zipcodes['21075'] = [39.203083, -76.750721];
Zipcodes['21076'] = [39.171402, -76.723341];
Zipcodes['21077'] = [39.158365, -76.699539];
Zipcodes['21078'] = [39.559229, -76.141248];
Zipcodes['21082'] = [39.478111, -76.473472];
Zipcodes['21084'] = [39.613561, -76.466441];
Zipcodes['21085'] = [39.44588, -76.357015];
Zipcodes['21087'] = [39.449314, -76.41324];
Zipcodes['21090'] = [39.209567, -76.664741];
Zipcodes['21093'] = [39.437973, -76.642173];
Zipcodes['21102'] = [39.692165, -76.847645];
Zipcodes['21104'] = [39.346904, -76.912757];
Zipcodes['21105'] = [39.715129, -76.652282];
Zipcodes['21108'] = [39.090307, -76.621263];
Zipcodes['21111'] = [39.575535, -76.589691];
Zipcodes['21113'] = [39.062685, -76.712417];
Zipcodes['21114'] = [39.009687, -76.68386];
Zipcodes['21117'] = [39.427569, -76.775759];
Zipcodes['21120'] = [39.646204, -76.674854];
Zipcodes['21122'] = [39.114283, -76.487394];
Zipcodes['21128'] = [39.40467, -76.448223];
Zipcodes['21130'] = [39.474769, -76.189397];
Zipcodes['21131'] = [39.504492, -76.589196];
Zipcodes['21132'] = [39.695537, -76.422201];
Zipcodes['21133'] = [39.378361, -76.811336];
Zipcodes['21136'] = [39.439136, -76.844548];
Zipcodes['21140'] = [38.950842, -76.586726];
Zipcodes['21144'] = [39.12057, -76.677621];
Zipcodes['21146'] = [39.079136, -76.558699];
Zipcodes['21152'] = [39.555195, -76.685924];
Zipcodes['21153'] = [39.417844, -76.70052];
Zipcodes['21154'] = [39.657449, -76.360475];
Zipcodes['21155'] = [39.573619, -76.806079];
Zipcodes['21156'] = [39.429159, -76.403873];
Zipcodes['21157'] = [39.548569, -76.983558];
Zipcodes['21158'] = [39.653717, -77.035127];
Zipcodes['21160'] = [39.70025, -76.306543];
Zipcodes['21161'] = [39.659903, -76.56506];
Zipcodes['21162'] = [39.390116, -76.405582];
Zipcodes['21163'] = [39.337527, -76.848247];
Zipcodes['21201'] = [39.294961, -76.622341];
Zipcodes['21202'] = [39.297425, -76.607722];
Zipcodes['21204'] = [39.406389, -76.626072];
Zipcodes['21205'] = [39.30224, -76.564078];
Zipcodes['21206'] = [39.338428, -76.538877];
Zipcodes['21207'] = [39.324167, -76.719484];
Zipcodes['21208'] = [39.383959, -76.724261];
Zipcodes['21209'] = [39.375252, -76.662617];
Zipcodes['21210'] = [39.357633, -76.634986];
Zipcodes['21211'] = [39.328746, -76.639281];
Zipcodes['21212'] = [39.367306, -76.614586];
Zipcodes['21213'] = [39.315031, -76.577429];
Zipcodes['21214'] = [39.352143, -76.564332];
Zipcodes['21215'] = [39.345947, -76.682206];
Zipcodes['21216'] = [39.310595, -76.671717];
Zipcodes['21217'] = [39.30704, -76.638328];
Zipcodes['21218'] = [39.330107, -76.601451];
Zipcodes['21219'] = [39.230192, -76.436077];
Zipcodes['21220'] = [39.343079, -76.390883];
Zipcodes['21221'] = [39.292125, -76.436995];
Zipcodes['21222'] = [39.264602, -76.495557];
Zipcodes['21223'] = [39.282951, -76.653877];
Zipcodes['21224'] = [39.275909, -76.541928];
Zipcodes['21225'] = [39.226117, -76.615735];
Zipcodes['21226'] = [39.208888, -76.562926];
Zipcodes['21227'] = [39.23997, -76.67945];
Zipcodes['21228'] = [39.272857, -76.747741];
Zipcodes['21229'] = [39.284239, -76.691403];
Zipcodes['21230'] = [39.268123, -76.626716];
Zipcodes['21231'] = [39.288507, -76.592024];
Zipcodes['21233'] = [39.292463, -76.604958];
Zipcodes['21234'] = [39.393254, -76.531822];
Zipcodes['21236'] = [39.388231, -76.483685];
Zipcodes['21237'] = [39.341537, -76.49527];
Zipcodes['21239'] = [39.365159, -76.587101];
Zipcodes['21240'] = [39.174827, -76.671802];
Zipcodes['21244'] = [39.335005, -76.776706];
Zipcodes['21250'] = [39.255611, -76.711179];
Zipcodes['21251'] = [39.349922, -76.580231];
Zipcodes['21252'] = [39.394116, -76.611383];
Zipcodes['21285'] = [39.38749, -76.617005];
Zipcodes['21286'] = [39.411836, -76.573235];
Zipcodes['21287'] = [39.29916, -76.588327];
Zipcodes['21401'] = [38.987941, -76.552716];
Zipcodes['21402'] = [38.988393, -76.470681];
Zipcodes['21403'] = [38.942123, -76.489628];
Zipcodes['21405'] = [39.028145, -76.548016];
Zipcodes['21409'] = [39.018435, -76.442533];
Zipcodes['21502'] = [39.641477, -78.756088];
Zipcodes['21520'] = [39.636619, -79.307843];
Zipcodes['21521'] = [39.549674, -79.046462];
Zipcodes['21523'] = [39.481331, -79.07851];
Zipcodes['21524'] = [39.706666, -78.802058];
Zipcodes['21529'] = [39.713709, -78.776181];
Zipcodes['21530'] = [39.67964, -78.546122];
Zipcodes['21531'] = [39.643045, -79.428086];
Zipcodes['21532'] = [39.649077, -78.984732];
Zipcodes['21536'] = [39.65696, -79.165566];
Zipcodes['21538'] = [39.409932, -79.22429];
Zipcodes['21539'] = [39.595218, -79.009519];
Zipcodes['21540'] = [39.478781, -79.058903];
Zipcodes['21541'] = [39.541471, -79.380028];
Zipcodes['21542'] = [39.591216, -78.950784];
Zipcodes['21543'] = [39.643622, -78.961006];
Zipcodes['21545'] = [39.704066, -78.854511];
Zipcodes['21550'] = [39.391733, -79.392385];
Zipcodes['21555'] = [39.57514, -78.562574];
Zipcodes['21557'] = [39.522914, -78.927734];
Zipcodes['21560'] = [39.565048, -78.706213];
Zipcodes['21561'] = [39.491589, -79.194401];
Zipcodes['21562'] = [39.513851, -79.03938];
Zipcodes['21601'] = [38.792467, -76.087881];
Zipcodes['21607'] = [39.133987, -75.856435];
Zipcodes['21610'] = [39.367115, -76.072496];
Zipcodes['21612'] = [38.75217, -76.266789];
Zipcodes['21613'] = [38.502789, -76.078875];
Zipcodes['21617'] = [39.049357, -76.044545];
Zipcodes['21619'] = [38.942447, -76.265742];
Zipcodes['21620'] = [39.208022, -76.082748];
Zipcodes['21622'] = [38.414465, -76.159253];
Zipcodes['21623'] = [39.124035, -75.966748];
Zipcodes['21624'] = [38.834413, -76.275743];
Zipcodes['21625'] = [38.864116, -75.981494];
Zipcodes['21626'] = [38.342712, -76.067945];
Zipcodes['21627'] = [38.233114, -76.046404];
Zipcodes['21628'] = [39.233174, -75.922049];
Zipcodes['21629'] = [38.858672, -75.825079];
Zipcodes['21631'] = [38.582391, -75.938981];
Zipcodes['21632'] = [38.731398, -75.772428];
Zipcodes['21634'] = [38.285946, -76.194292];
Zipcodes['21635'] = [39.334791, -75.846624];
Zipcodes['21636'] = [39.014984, -75.795778];
Zipcodes['21638'] = [38.940201, -76.207604];
Zipcodes['21639'] = [38.958732, -75.782499];
Zipcodes['21640'] = [39.068141, -75.820948];
Zipcodes['21641'] = [38.917043, -75.941779];
Zipcodes['21643'] = [38.645582, -75.869896];
Zipcodes['21644'] = [39.117239, -75.874393];
Zipcodes['21645'] = [39.315819, -75.956165];
Zipcodes['21647'] = [38.796108, -76.284812];
Zipcodes['21648'] = [38.5088, -76.239792];
Zipcodes['21649'] = [39.145558, -75.78419];
Zipcodes['21650'] = [39.310581, -75.808633];
Zipcodes['21651'] = [39.256052, -75.858394];
Zipcodes['21652'] = [38.718952, -76.281023];
Zipcodes['21653'] = [38.744016, -76.175776];
Zipcodes['21654'] = [38.690105, -76.131594];
Zipcodes['21655'] = [38.744968, -75.912768];
Zipcodes['21657'] = [38.938607, -75.981087];
Zipcodes['21658'] = [38.944268, -76.143938];
Zipcodes['21659'] = [38.575046, -75.80042];
Zipcodes['21660'] = [38.95622, -75.888948];
Zipcodes['21661'] = [39.096121, -76.219488];
Zipcodes['21662'] = [38.714248, -76.20068];
Zipcodes['21663'] = [38.788029, -76.231183];
Zipcodes['21664'] = [38.607572, -75.947113];
Zipcodes['21665'] = [38.754862, -76.368111];
Zipcodes['21666'] = [38.940139, -76.33579];
Zipcodes['21667'] = [39.343735, -76.051873];
Zipcodes['21668'] = [39.190554, -75.850527];
Zipcodes['21669'] = [38.461963, -76.295171];
Zipcodes['21671'] = [38.695574, -76.335128];
Zipcodes['21672'] = [38.271699, -76.052704];
Zipcodes['21673'] = [38.642285, -76.043492];
Zipcodes['21675'] = [38.288979, -76.0896];
Zipcodes['21676'] = [38.787435, -76.301138];
Zipcodes['21677'] = [38.472182, -76.173844];
Zipcodes['21678'] = [39.3136, -76.113348];
Zipcodes['21679'] = [38.920478, -76.094115];
Zipcodes['21701'] = [39.443584, -77.332891];
Zipcodes['21702'] = [39.479251, -77.443194];
Zipcodes['21703'] = [39.367031, -77.473482];
Zipcodes['21704'] = [39.354883, -77.375902];
Zipcodes['21705'] = [39.40886, -77.410008];
Zipcodes['21710'] = [39.307904, -77.432061];
Zipcodes['21711'] = [39.668396, -78.019569];
Zipcodes['21713'] = [39.525079, -77.66965];
Zipcodes['21714'] = [39.410591, -77.510051];
Zipcodes['21715'] = [39.37876, -77.660722];
Zipcodes['21716'] = [39.317799, -77.626706];
Zipcodes['21717'] = [39.338627, -77.431527];
Zipcodes['21718'] = [39.393497, -77.627788];
Zipcodes['21719'] = [39.704543, -77.500455];
Zipcodes['21722'] = [39.664505, -77.913452];
Zipcodes['21723'] = [39.329107, -77.002611];
Zipcodes['21727'] = [39.688615, -77.329281];
Zipcodes['21733'] = [39.550014, -77.758355];
Zipcodes['21734'] = [39.605788, -77.703842];
Zipcodes['21737'] = [39.24826, -77.027125];
Zipcodes['21738'] = [39.279844, -77.026706];
Zipcodes['21740'] = [39.631905, -77.743908];
Zipcodes['21742'] = [39.678219, -77.65272];
Zipcodes['21746'] = [39.565909, -77.714139];
Zipcodes['21750'] = [39.662262, -78.235975];
Zipcodes['21754'] = [39.332114, -77.316213];
Zipcodes['21755'] = [39.359655, -77.568128];
Zipcodes['21756'] = [39.459836, -77.693503];
Zipcodes['21757'] = [39.593861, -77.258139];
Zipcodes['21758'] = [39.353205, -77.656813];
Zipcodes['21762'] = [39.48059, -77.248289];
Zipcodes['21766'] = [39.670471, -78.38789];
Zipcodes['21767'] = [39.697793, -77.746536];
Zipcodes['21769'] = [39.442464, -77.568908];
Zipcodes['21770'] = [39.350189, -77.25624];
Zipcodes['21771'] = [39.394937, -77.158193];
Zipcodes['21773'] = [39.542655, -77.551543];
Zipcodes['21774'] = [39.410168, -77.274193];
Zipcodes['21776'] = [39.518006, -77.099709];
Zipcodes['21777'] = [39.279296, -77.528727];
Zipcodes['21778'] = [39.615806, -77.328421];
Zipcodes['21779'] = [39.424484, -77.653994];
Zipcodes['21780'] = [39.672151, -77.471618];
Zipcodes['21781'] = [39.571253, -77.759805];
Zipcodes['21782'] = [39.455471, -77.760235];
Zipcodes['21783'] = [39.651955, -77.552583];
Zipcodes['21784'] = [39.401084, -76.973736];
Zipcodes['21787'] = [39.67763, -77.172591];
Zipcodes['21788'] = [39.589979, -77.413861];
Zipcodes['21790'] = [39.262737, -77.502746];
Zipcodes['21791'] = [39.533053, -77.18856];
Zipcodes['21793'] = [39.489155, -77.344216];
Zipcodes['21794'] = [39.298422, -76.966129];
Zipcodes['21795'] = [39.580609, -77.823698];
Zipcodes['21797'] = [39.331175, -77.068606];
Zipcodes['21798'] = [39.53775, -77.295078];
Zipcodes['21801'] = [38.349417, -75.67847];
Zipcodes['21804'] = [38.314082, -75.532106];
Zipcodes['21810'] = [38.290675, -75.689266];
Zipcodes['21811'] = [38.321068, -75.222208];
Zipcodes['21813'] = [38.422799, -75.179686];
Zipcodes['21814'] = [38.293076, -75.899296];
Zipcodes['21817'] = [37.981025, -75.836069];
Zipcodes['21821'] = [38.164595, -75.91666];
Zipcodes['21822'] = [38.288379, -75.660963];
Zipcodes['21824'] = [37.984532, -76.037389];
Zipcodes['21826'] = [38.320393, -75.626502];
Zipcodes['21829'] = [38.097659, -75.371456];
Zipcodes['21830'] = [38.397363, -75.734768];
Zipcodes['21835'] = [38.534209, -75.936035];
Zipcodes['21837'] = [38.454586, -75.766294];
Zipcodes['21838'] = [38.017905, -75.73792];
Zipcodes['21840'] = [38.248736, -75.900009];
Zipcodes['21841'] = [38.223771, -75.281087];
Zipcodes['21842'] = [38.363088, -75.085032];
Zipcodes['21849'] = [38.364688, -75.456326];
Zipcodes['21850'] = [38.361731, -75.397078];
Zipcodes['21851'] = [38.07765, -75.543516];
Zipcodes['21853'] = [38.20354, -75.720887];
Zipcodes['21856'] = [38.330729, -75.794885];
Zipcodes['21861'] = [38.53644, -75.727352];
Zipcodes['21862'] = [38.395818, -75.212931];
Zipcodes['21863'] = [38.187913, -75.399041];
Zipcodes['21864'] = [38.031572, -75.405812];
Zipcodes['21865'] = [38.282757, -75.826995];
Zipcodes['21866'] = [37.967128, -76.021365];
Zipcodes['21867'] = [38.110276, -75.793973];
Zipcodes['21869'] = [38.386801, -75.915994];
Zipcodes['21871'] = [38.1068, -75.741522];
Zipcodes['21872'] = [38.415131, -75.29926];
Zipcodes['21874'] = [38.393841, -75.352002];
Zipcodes['21875'] = [38.439999, -75.544695];
Zipcodes['21890'] = [38.158081, -75.700905];
Zipcodes['21901'] = [39.583057, -75.9668];
Zipcodes['21902'] = [39.552352, -76.063758];
Zipcodes['21903'] = [39.574107, -76.045237];
Zipcodes['21904'] = [39.61549, -76.067988];
Zipcodes['21911'] = [39.695057, -76.031812];
Zipcodes['21912'] = [39.419414, -75.818631];
Zipcodes['21913'] = [39.414022, -75.869924];
Zipcodes['21914'] = [39.575123, -75.984569];
Zipcodes['21915'] = [39.496147, -75.848168];
Zipcodes['21917'] = [39.671632, -76.097397];
Zipcodes['21918'] = [39.678194, -76.175888];
Zipcodes['21919'] = [39.418553, -75.933709];
Zipcodes['21920'] = [39.656246, -75.841007];
Zipcodes['21921'] = [39.627145, -75.859891];
Zipcodes['21930'] = [39.37013, -75.887336];
Zipcodes['22003'] = [38.830236, -77.214725];
Zipcodes['22015'] = [38.786024, -77.280015];
Zipcodes['22025'] = [38.591896, -77.349496];
Zipcodes['22026'] = [38.560068, -77.296211];
Zipcodes['22027'] = [38.894853, -77.223048];
Zipcodes['22030'] = [38.837185, -77.34048];
Zipcodes['22031'] = [38.85892, -77.260273];
Zipcodes['22032'] = [38.817673, -77.290222];
Zipcodes['22033'] = [38.875218, -77.384713];
Zipcodes['22035'] = [38.856315, -77.362851];
Zipcodes['22039'] = [38.748577, -77.314493];
Zipcodes['22041'] = [38.849321, -77.141472];
Zipcodes['22042'] = [38.865143, -77.193913];
Zipcodes['22043'] = [38.901488, -77.197424];
Zipcodes['22044'] = [38.862044, -77.154372];
Zipcodes['22046'] = [38.886334, -77.180975];
Zipcodes['22060'] = [38.699353, -77.157497];
Zipcodes['22066'] = [39.012357, -77.301942];
Zipcodes['22079'] = [38.675735, -77.20384];
Zipcodes['22101'] = [38.940203, -77.164882];
Zipcodes['22102'] = [38.950951, -77.229553];
Zipcodes['22124'] = [38.894871, -77.329515];
Zipcodes['22125'] = [38.681729, -77.261861];
Zipcodes['22134'] = [38.532506, -77.405024];
Zipcodes['22150'] = [38.772863, -77.186523];
Zipcodes['22151'] = [38.804093, -77.207804];
Zipcodes['22152'] = [38.774493, -77.232029];
Zipcodes['22153'] = [38.744969, -77.23564];
Zipcodes['22172'] = [38.578957, -77.399914];
Zipcodes['22180'] = [38.895439, -77.256133];
Zipcodes['22181'] = [38.905545, -77.289778];
Zipcodes['22182'] = [38.938421, -77.27552];
Zipcodes['22191'] = [38.622914, -77.262938];
Zipcodes['22192'] = [38.684218, -77.317942];
Zipcodes['22193'] = [38.644065, -77.350545];
Zipcodes['22201'] = [38.886804, -77.094992];
Zipcodes['22202'] = [38.856854, -77.051495];
Zipcodes['22203'] = [38.873777, -77.116418];
Zipcodes['22204'] = [38.860742, -77.098955];
Zipcodes['22205'] = [38.883371, -77.139537];
Zipcodes['22206'] = [38.844026, -77.087233];
Zipcodes['22207'] = [38.906665, -77.124238];
Zipcodes['22209'] = [38.89443, -77.073406];
Zipcodes['22211'] = [38.880114, -77.074791];
Zipcodes['22213'] = [38.894645, -77.162396];
Zipcodes['22214'] = [38.874906, -77.06505];
Zipcodes['22301'] = [38.819957, -77.059285];
Zipcodes['22302'] = [38.827888, -77.083132];
Zipcodes['22303'] = [38.793999, -77.081843];
Zipcodes['22304'] = [38.813204, -77.112268];
Zipcodes['22305'] = [38.836463, -77.062098];
Zipcodes['22306'] = [38.74979, -77.1074];
Zipcodes['22307'] = [38.77036, -77.059083];
Zipcodes['22308'] = [38.729282, -77.060994];
Zipcodes['22309'] = [38.720336, -77.106155];
Zipcodes['22310'] = [38.784656, -77.122182];
Zipcodes['22311'] = [38.833991, -77.125511];
Zipcodes['22312'] = [38.817531, -77.153866];
Zipcodes['22314'] = [38.806853, -77.056374];
Zipcodes['22315'] = [38.756764, -77.147432];
Zipcodes['22401'] = [38.299272, -77.486658];
Zipcodes['22405'] = [38.313604, -77.403928];
Zipcodes['22406'] = [38.399721, -77.54673];
Zipcodes['22407'] = [38.28492, -77.580598];
Zipcodes['22408'] = [38.211478, -77.452502];
Zipcodes['22427'] = [38.072345, -77.28859];
Zipcodes['22432'] = [37.860855, -76.355437];
Zipcodes['22433'] = [38.367057, -77.859386];
Zipcodes['22435'] = [37.966791, -76.572106];
Zipcodes['22436'] = [38.084168, -77.117456];
Zipcodes['22437'] = [37.780353, -76.811767];
Zipcodes['22438'] = [38.042972, -76.995776];
Zipcodes['22443'] = [38.169514, -76.986805];
Zipcodes['22448'] = [38.33631, -77.028454];
Zipcodes['22454'] = [37.832662, -76.845107];
Zipcodes['22460'] = [37.869283, -76.607359];
Zipcodes['22469'] = [38.063577, -76.643481];
Zipcodes['22472'] = [37.958833, -76.672509];
Zipcodes['22473'] = [37.881713, -76.411838];
Zipcodes['22476'] = [38.038045, -77.067419];
Zipcodes['22480'] = [37.662553, -76.412226];
Zipcodes['22482'] = [37.736613, -76.34665];
Zipcodes['22485'] = [38.27718, -77.162636];
Zipcodes['22488'] = [38.049455, -76.587481];
Zipcodes['22503'] = [37.750474, -76.515338];
Zipcodes['22504'] = [37.768922, -76.720684];
Zipcodes['22508'] = [38.313187, -77.798472];
Zipcodes['22509'] = [38.106151, -77.073566];
Zipcodes['22511'] = [37.994449, -76.504382];
Zipcodes['22514'] = [38.008001, -77.32938];
Zipcodes['22520'] = [38.118583, -76.812419];
Zipcodes['22529'] = [38.011551, -76.689548];
Zipcodes['22534'] = [38.087247, -77.668986];
Zipcodes['22535'] = [38.118181, -77.196631];
Zipcodes['22538'] = [38.192794, -77.27549];
Zipcodes['22539'] = [37.860613, -76.286036];
Zipcodes['22542'] = [38.293155, -77.892539];
Zipcodes['22546'] = [37.944471, -77.446105];
Zipcodes['22548'] = [37.827225, -76.701525];
Zipcodes['22551'] = [38.187032, -77.700356];
Zipcodes['22553'] = [38.271762, -77.640048];
Zipcodes['22554'] = [38.42068, -77.37468];
Zipcodes['22556'] = [38.488667, -77.515136];
Zipcodes['22560'] = [37.91585, -76.961086];
Zipcodes['22567'] = [38.237857, -77.927575];
Zipcodes['22572'] = [37.965301, -76.771067];
Zipcodes['22576'] = [37.68743, -76.440681];
Zipcodes['22578'] = [37.636571, -76.356134];
Zipcodes['22580'] = [38.112561, -77.43676];
Zipcodes['22601'] = [39.174203, -78.174289];
Zipcodes['22602'] = [39.144249, -78.284807];
Zipcodes['22603'] = [39.28096, -78.206522];
Zipcodes['22610'] = [38.824656, -78.279143];
Zipcodes['22611'] = [39.163192, -77.985448];
Zipcodes['22620'] = [39.060451, -78.034213];
Zipcodes['22623'] = [38.848927, -78.140664];
Zipcodes['22624'] = [39.26121, -78.096283];
Zipcodes['22625'] = [39.376805, -78.309326];
Zipcodes['22627'] = [38.772295, -78.148771];
Zipcodes['22630'] = [38.930914, -78.17648];
Zipcodes['22637'] = [39.25674, -78.352778];
Zipcodes['22639'] = [38.845038, -78.034518];
Zipcodes['22640'] = [38.811028, -78.14057];
Zipcodes['22641'] = [39.085212, -78.393228];
Zipcodes['22642'] = [38.948118, -78.027722];
Zipcodes['22643'] = [38.892758, -78.012721];
Zipcodes['22644'] = [38.965785, -78.545389];
Zipcodes['22645'] = [39.019181, -78.274706];
Zipcodes['22646'] = [39.072518, -78.050929];
Zipcodes['22650'] = [38.765084, -78.364632];
Zipcodes['22652'] = [38.840091, -78.431748];
Zipcodes['22654'] = [39.066258, -78.463997];
Zipcodes['22655'] = [39.066335, -78.209173];
Zipcodes['22656'] = [39.219483, -78.100685];
Zipcodes['22657'] = [39.001087, -78.370608];
Zipcodes['22660'] = [38.954941, -78.440236];
Zipcodes['22663'] = [39.052545, -78.11232];
Zipcodes['22664'] = [38.888919, -78.532453];
Zipcodes['22701'] = [38.426381, -78.010127];
Zipcodes['22709'] = [38.328731, -78.213737];
Zipcodes['22711'] = [38.464504, -78.278863];
Zipcodes['22712'] = [38.554149, -77.751298];
Zipcodes['22713'] = [38.533494, -78.135946];
Zipcodes['22714'] = [38.510253, -77.890506];
Zipcodes['22715'] = [38.413954, -78.184695];
Zipcodes['22716'] = [38.62999, -78.103016];
Zipcodes['22718'] = [38.459898, -77.827949];
Zipcodes['22719'] = [38.52823, -78.281341];
Zipcodes['22720'] = [38.480496, -77.648214];
Zipcodes['22722'] = [38.46625, -78.227059];
Zipcodes['22723'] = [38.334605, -78.380361];
Zipcodes['22724'] = [38.631729, -77.903995];
Zipcodes['22725'] = [38.44051, -78.156075];
Zipcodes['22726'] = [38.3982, -77.816535];
Zipcodes['22727'] = [38.407594, -78.295292];
Zipcodes['22728'] = [38.575006, -77.680261];
Zipcodes['22729'] = [38.352682, -78.017859];
Zipcodes['22730'] = [38.362889, -78.16386];
Zipcodes['22731'] = [38.344129, -78.261453];
Zipcodes['22732'] = [38.308877, -78.19153];
Zipcodes['22733'] = [38.321109, -78.03975];
Zipcodes['22734'] = [38.528534, -77.809735];
Zipcodes['22735'] = [38.480865, -78.184506];
Zipcodes['22736'] = [38.398297, -77.694463];
Zipcodes['22737'] = [38.59205, -78.001812];
Zipcodes['22738'] = [38.285571, -78.288404];
Zipcodes['22740'] = [38.64569, -78.286953];
Zipcodes['22741'] = [38.449608, -77.863964];
Zipcodes['22742'] = [38.464605, -77.708333];
Zipcodes['22743'] = [38.54004, -78.381412];
Zipcodes['22746'] = [38.626432, -78.021061];
Zipcodes['22747'] = [38.709056, -78.165674];
Zipcodes['22749'] = [38.619494, -78.184253];
Zipcodes['22801'] = [38.405147, -78.880723];
Zipcodes['22802'] = [38.494489, -78.86316];
Zipcodes['22807'] = [38.43459, -78.86887];
Zipcodes['22810'] = [38.833949, -78.801308];
Zipcodes['22811'] = [38.811097, -78.991521];
Zipcodes['22812'] = [38.378077, -79.02717];
Zipcodes['22815'] = [38.712509, -78.841209];
Zipcodes['22820'] = [38.744829, -78.983243];
Zipcodes['22821'] = [38.48053, -79.128405];
Zipcodes['22824'] = [38.846712, -78.628319];
Zipcodes['22827'] = [38.392549, -78.614901];
Zipcodes['22830'] = [38.667244, -78.975023];
Zipcodes['22831'] = [38.559898, -79.122141];
Zipcodes['22832'] = [38.458216, -78.75725];
Zipcodes['22834'] = [38.559622, -78.854678];
Zipcodes['22835'] = [38.670431, -78.455301];
Zipcodes['22840'] = [38.392398, -78.744139];
Zipcodes['22841'] = [38.341793, -78.892489];
Zipcodes['22842'] = [38.778048, -78.699661];
Zipcodes['22843'] = [38.368428, -79.146789];
Zipcodes['22844'] = [38.661561, -78.658119];
Zipcodes['22845'] = [38.776665, -78.845149];
Zipcodes['22846'] = [38.384894, -78.796195];
Zipcodes['22847'] = [38.717338, -78.714964];
Zipcodes['22849'] = [38.525324, -78.629627];
Zipcodes['22850'] = [38.564704, -78.917823];
Zipcodes['22851'] = [38.54379, -78.503015];
Zipcodes['22853'] = [38.651705, -78.758708];
Zipcodes['22901'] = [38.087705, -78.552744];
Zipcodes['22902'] = [37.910276, -78.52212];
Zipcodes['22903'] = [38.006972, -78.59506];
Zipcodes['22904'] = [38.035436, -78.511249];
Zipcodes['22908'] = [38.030979, -78.496585];
Zipcodes['22911'] = [38.097005, -78.410147];
Zipcodes['22920'] = [37.968714, -78.805539];
Zipcodes['22922'] = [37.685755, -78.921503];
Zipcodes['22923'] = [38.189313, -78.31668];
Zipcodes['22931'] = [37.898931, -78.727429];
Zipcodes['22932'] = [38.147507, -78.696458];
Zipcodes['22935'] = [38.253962, -78.562656];
Zipcodes['22936'] = [38.159998, -78.499128];
Zipcodes['22937'] = [37.814467, -78.612075];
Zipcodes['22938'] = [37.860653, -78.775381];
Zipcodes['22939'] = [38.103454, -78.971468];
Zipcodes['22940'] = [38.210538, -78.594617];
Zipcodes['22942'] = [38.101443, -78.202129];
Zipcodes['22943'] = [38.040171, -78.778343];
Zipcodes['22946'] = [37.847883, -78.563525];
Zipcodes['22947'] = [38.043989, -78.324721];
Zipcodes['22948'] = [38.363165, -78.128968];
Zipcodes['22949'] = [37.789079, -78.88344];
Zipcodes['22952'] = [37.934219, -79.014248];
Zipcodes['22957'] = [38.229674, -78.191677];
Zipcodes['22958'] = [37.892769, -78.894292];
Zipcodes['22959'] = [37.937283, -78.656583];
Zipcodes['22960'] = [38.219521, -78.06326];
Zipcodes['22963'] = [37.846661, -78.293974];
Zipcodes['22964'] = [37.723829, -78.991072];
Zipcodes['22967'] = [37.797305, -79.014082];
Zipcodes['22968'] = [38.241722, -78.393753];
Zipcodes['22969'] = [37.7975, -78.693372];
Zipcodes['22971'] = [37.747785, -78.800276];
Zipcodes['22972'] = [38.217008, -78.233485];
Zipcodes['22973'] = [38.335462, -78.467868];
Zipcodes['22974'] = [37.966327, -78.267358];
Zipcodes['22976'] = [37.848859, -79.045839];
Zipcodes['22980'] = [38.099837, -78.88055];
Zipcodes['22989'] = [38.29321, -78.11901];
Zipcodes['23002'] = [37.349684, -77.959361];
Zipcodes['23004'] = [37.688288, -78.399202];
Zipcodes['23005'] = [37.760037, -77.48366];
Zipcodes['23009'] = [37.811987, -77.175001];
Zipcodes['23011'] = [37.477354, -76.812452];
Zipcodes['23015'] = [37.929642, -77.642802];
Zipcodes['23021'] = [37.400964, -76.371839];
Zipcodes['23022'] = [37.737052, -78.26416];
Zipcodes['23023'] = [37.768958, -76.963729];
Zipcodes['23024'] = [37.927752, -77.779274];
Zipcodes['23025'] = [37.41516, -76.362416];
Zipcodes['23027'] = [37.646476, -78.130174];
Zipcodes['23030'] = [37.345958, -77.055314];
Zipcodes['23032'] = [37.655493, -76.681949];
Zipcodes['23035'] = [37.50014, -76.382103];
Zipcodes['23038'] = [37.753464, -78.148789];
Zipcodes['23039'] = [37.65288, -77.801578];
Zipcodes['23040'] = [37.520189, -78.252836];
Zipcodes['23043'] = [37.557588, -76.336434];
Zipcodes['23045'] = [37.425348, -76.268808];
Zipcodes['23047'] = [37.846207, -77.486531];
Zipcodes['23050'] = [37.487752, -76.419298];
Zipcodes['23055'] = [37.768214, -78.217011];
Zipcodes['23056'] = [37.394328, -76.383867];
Zipcodes['23059'] = [37.702252, -77.573995];
Zipcodes['23060'] = [37.659664, -77.533827];
Zipcodes['23061'] = [37.427731, -76.537584];
Zipcodes['23062'] = [37.262931, -76.507489];
Zipcodes['23063'] = [37.705919, -77.980663];
Zipcodes['23065'] = [37.805959, -77.932428];
Zipcodes['23066'] = [37.500146, -76.292613];
Zipcodes['23068'] = [37.48976, -76.333362];
Zipcodes['23069'] = [37.782686, -77.343002];
Zipcodes['23070'] = [37.55205, -76.383517];
Zipcodes['23071'] = [37.545377, -76.448792];
Zipcodes['23072'] = [37.290444, -76.44779];
Zipcodes['23075'] = [37.558215, -77.316877];
Zipcodes['23076'] = [37.472417, -76.306619];
Zipcodes['23079'] = [37.740275, -76.677511];
Zipcodes['23081'] = [37.19357, -76.752508];
Zipcodes['23083'] = [37.310661, -78.104834];
Zipcodes['23084'] = [37.884931, -78.115047];
Zipcodes['23085'] = [37.688954, -76.825645];
Zipcodes['23086'] = [37.668573, -77.054414];
Zipcodes['23089'] = [37.477156, -76.914756];
Zipcodes['23091'] = [37.656264, -76.802492];
Zipcodes['23092'] = [37.606769, -76.514748];
Zipcodes['23093'] = [38.004167, -78.040057];
Zipcodes['23102'] = [37.708119, -77.828654];
Zipcodes['23103'] = [37.648034, -77.72364];
Zipcodes['23106'] = [37.715627, -77.20717];
Zipcodes['23108'] = [37.656133, -76.720486];
Zipcodes['23109'] = [37.432779, -76.325576];
Zipcodes['23110'] = [37.570081, -76.770193];
Zipcodes['23111'] = [37.613551, -77.243019];
Zipcodes['23112'] = [37.432553, -77.655051];
Zipcodes['23113'] = [37.539809, -77.679743];
Zipcodes['23114'] = [37.48301, -77.660433];
Zipcodes['23116'] = [37.664436, -77.325493];
Zipcodes['23117'] = [37.981999, -77.873976];
Zipcodes['23119'] = [37.443765, -76.276751];
Zipcodes['23120'] = [37.42208, -77.78137];
Zipcodes['23123'] = [37.651834, -78.301142];
Zipcodes['23124'] = [37.55095, -77.044042];
Zipcodes['23126'] = [37.923461, -77.144316];
Zipcodes['23128'] = [37.447198, -76.429276];
Zipcodes['23129'] = [37.703624, -77.777648];
Zipcodes['23130'] = [37.387286, -76.259439];
Zipcodes['23138'] = [37.342651, -76.304324];
Zipcodes['23139'] = [37.545988, -77.936728];
Zipcodes['23140'] = [37.438755, -77.049272];
Zipcodes['23141'] = [37.535229, -77.15726];
Zipcodes['23146'] = [37.723428, -77.710398];
Zipcodes['23147'] = [37.369636, -77.039114];
Zipcodes['23148'] = [37.839647, -77.058668];
Zipcodes['23149'] = [37.575671, -76.609283];
Zipcodes['23150'] = [37.501141, -77.252581];
Zipcodes['23153'] = [37.779727, -77.955874];
Zipcodes['23156'] = [37.51922, -76.726031];
Zipcodes['23160'] = [37.629084, -77.840754];
Zipcodes['23161'] = [37.715706, -76.935229];
Zipcodes['23163'] = [37.357073, -76.297334];
Zipcodes['23168'] = [37.395757, -76.82511];
Zipcodes['23169'] = [37.603451, -76.457239];
Zipcodes['23173'] = [37.575763, -77.541537];
Zipcodes['23175'] = [37.654869, -76.624357];
Zipcodes['23176'] = [37.572941, -76.414976];
Zipcodes['23177'] = [37.729243, -77.006032];
Zipcodes['23180'] = [37.702428, -76.615265];
Zipcodes['23181'] = [37.597491, -76.88906];
Zipcodes['23185'] = [37.239658, -76.701436];
Zipcodes['23188'] = [37.341605, -76.747782];
Zipcodes['23192'] = [37.820363, -77.680814];
Zipcodes['23219'] = [37.540604, -77.434733];
Zipcodes['23220'] = [37.549349, -77.460606];
Zipcodes['23221'] = [37.549995, -77.490978];
Zipcodes['23222'] = [37.582968, -77.418907];
Zipcodes['23223'] = [37.557767, -77.378886];
Zipcodes['23224'] = [37.497603, -77.467429];
Zipcodes['23225'] = [37.519727, -77.510561];
Zipcodes['23226'] = [37.579356, -77.519937];
Zipcodes['23227'] = [37.611812, -77.435864];
Zipcodes['23228'] = [37.626617, -77.492834];
Zipcodes['23229'] = [37.588045, -77.570196];
Zipcodes['23230'] = [37.586639, -77.489122];
Zipcodes['23231'] = [37.442204, -77.314931];
Zipcodes['23233'] = [37.643812, -77.629395];
Zipcodes['23234'] = [37.452821, -77.466503];
Zipcodes['23235'] = [37.51457, -77.564758];
Zipcodes['23236'] = [37.47581, -77.585285];
Zipcodes['23237'] = [37.400683, -77.456626];
Zipcodes['23238'] = [37.592514, -77.641686];
Zipcodes['23250'] = [37.50466, -77.32086];
Zipcodes['23284'] = [37.549028, -77.451696];
Zipcodes['23294'] = [37.631281, -77.542534];
Zipcodes['23298'] = [37.541707, -77.42953];
Zipcodes['23301'] = [37.669851, -75.643661];
Zipcodes['23302'] = [37.854188, -75.517623];
Zipcodes['23303'] = [37.903366, -75.506367];
Zipcodes['23304'] = [36.996135, -76.572107];
Zipcodes['23306'] = [37.566478, -75.885459];
Zipcodes['23307'] = [37.432039, -75.871157];
Zipcodes['23308'] = [37.827651, -75.632377];
Zipcodes['23310'] = [37.258993, -75.963657];
Zipcodes['23314'] = [36.959049, -76.52428];
Zipcodes['23315'] = [36.73739, -76.840511];
Zipcodes['23316'] = [37.294354, -75.965519];
Zipcodes['23320'] = [36.751734, -76.217637];
Zipcodes['23321'] = [36.800398, -76.421152];
Zipcodes['23322'] = [36.625988, -76.267768];
Zipcodes['23323'] = [36.696975, -76.379925];
Zipcodes['23324'] = [36.799721, -76.273447];
Zipcodes['23325'] = [36.813625, -76.239857];
Zipcodes['23336'] = [37.949432, -75.338084];
Zipcodes['23337'] = [37.940106, -75.466025];
Zipcodes['23347'] = [37.356586, -75.978263];
Zipcodes['23350'] = [37.512705, -75.862593];
Zipcodes['23354'] = [37.479183, -75.902456];
Zipcodes['23356'] = [38.010003, -75.385368];
Zipcodes['23357'] = [37.755646, -75.676028];
Zipcodes['23358'] = [37.652659, -75.867953];
Zipcodes['23359'] = [37.887733, -75.603949];
Zipcodes['23389'] = [37.655265, -75.833086];
Zipcodes['23395'] = [37.969673, -75.448236];
Zipcodes['23398'] = [37.520559, -75.939732];
Zipcodes['23401'] = [37.627758, -75.778349];
Zipcodes['23405'] = [37.411724, -75.926597];
Zipcodes['23407'] = [37.847103, -75.554449];
Zipcodes['23408'] = [37.452913, -75.843474];
Zipcodes['23409'] = [37.887621, -75.662907];
Zipcodes['23410'] = [37.638996, -75.745065];
Zipcodes['23413'] = [37.46237, -75.8495];
Zipcodes['23414'] = [37.813754, -75.58691];
Zipcodes['23415'] = [37.975153, -75.535603];
Zipcodes['23416'] = [37.937326, -75.561481];
Zipcodes['23417'] = [37.732134, -75.761995];
Zipcodes['23418'] = [37.641378, -75.668394];
Zipcodes['23420'] = [37.59907, -75.817482];
Zipcodes['23421'] = [37.76478, -75.611768];
Zipcodes['23422'] = [37.635389, -75.822735];
Zipcodes['23423'] = [37.516077, -75.741043];
Zipcodes['23426'] = [37.925149, -75.6918];
Zipcodes['23427'] = [37.899775, -75.718243];
Zipcodes['23430'] = [36.999354, -76.660764];
Zipcodes['23432'] = [36.861812, -76.55156];
Zipcodes['23433'] = [36.916127, -76.484469];
Zipcodes['23434'] = [36.703003, -76.592105];
Zipcodes['23435'] = [36.848602, -76.472899];
Zipcodes['23436'] = [36.893756, -76.506917];
Zipcodes['23437'] = [36.627115, -76.803197];
Zipcodes['23438'] = [36.581192, -76.697189];
Zipcodes['23440'] = [37.825183, -75.99022];
Zipcodes['23441'] = [37.712102, -75.699413];
Zipcodes['23442'] = [37.894214, -75.559926];
Zipcodes['23451'] = [36.872358, -76.004319];
Zipcodes['23452'] = [36.845812, -76.091964];
Zipcodes['23453'] = [36.78349, -76.071207];
Zipcodes['23454'] = [36.864599, -76.055124];
Zipcodes['23455'] = [36.883814, -76.143237];
Zipcodes['23456'] = [36.736954, -76.035987];
Zipcodes['23457'] = [36.617383, -76.015339];
Zipcodes['23459'] = [36.91681, -76.171748];
Zipcodes['23460'] = [36.818488, -76.0316];
Zipcodes['23461'] = [36.770602, -75.963772];
Zipcodes['23462'] = [36.836242, -76.145948];
Zipcodes['23464'] = [36.796917, -76.187631];
Zipcodes['23480'] = [37.618681, -75.690659];
Zipcodes['23486'] = [37.517888, -75.806853];
Zipcodes['23487'] = [36.847368, -76.725048];
Zipcodes['23488'] = [37.947613, -75.598459];
Zipcodes['23502'] = [36.860941, -76.204908];
Zipcodes['23503'] = [36.948786, -76.266459];
Zipcodes['23504'] = [36.857302, -76.265612];
Zipcodes['23505'] = [36.912882, -76.299596];
Zipcodes['23507'] = [36.864564, -76.303218];
Zipcodes['23508'] = [36.884888, -76.31027];
Zipcodes['23509'] = [36.882413, -76.26396];
Zipcodes['23510'] = [36.852414, -76.29148];
Zipcodes['23511'] = [36.93724, -76.309215];
Zipcodes['23513'] = [36.889005, -76.237684];
Zipcodes['23517'] = [36.869238, -76.292055];
Zipcodes['23518'] = [36.916118, -76.215375];
Zipcodes['23523'] = [36.832389, -76.272082];
Zipcodes['23551'] = [36.923536, -76.292547];
Zipcodes['23601'] = [37.037855, -76.479809];
Zipcodes['23602'] = [37.113416, -76.517947];
Zipcodes['23603'] = [37.191057, -76.564535];
Zipcodes['23604'] = [37.119148, -76.593183];
Zipcodes['23605'] = [37.01867, -76.436702];
Zipcodes['23606'] = [37.065696, -76.516665];
Zipcodes['23607'] = [36.970515, -76.424339];
Zipcodes['23608'] = [37.147813, -76.543036];
Zipcodes['23651'] = [37.001772, -76.309871];
Zipcodes['23661'] = [37.000868, -76.369988];
Zipcodes['23662'] = [37.133319, -76.353027];
Zipcodes['23663'] = [37.015912, -76.322526];
Zipcodes['23664'] = [37.073116, -76.295078];
Zipcodes['23665'] = [37.083918, -76.360125];
Zipcodes['23666'] = [37.057009, -76.408083];
Zipcodes['23667'] = [37.02201, -76.329688];
Zipcodes['23668'] = [37.020264, -76.338549];
Zipcodes['23669'] = [37.050708, -76.339274];
Zipcodes['23681'] = [37.096813, -76.383535];
Zipcodes['23690'] = [37.220286, -76.518336];
Zipcodes['23691'] = [37.259622, -76.572884];
Zipcodes['23692'] = [37.212226, -76.421272];
Zipcodes['23693'] = [37.131179, -76.444819];
Zipcodes['23696'] = [37.189119, -76.421079];
Zipcodes['23701'] = [36.811975, -76.369378];
Zipcodes['23702'] = [36.803271, -76.326721];
Zipcodes['23703'] = [36.893339, -76.373292];
Zipcodes['23704'] = [36.826697, -76.312032];
Zipcodes['23707'] = [36.841794, -76.340331];
Zipcodes['23708'] = [36.847681, -76.304081];
Zipcodes['23709'] = [36.817203, -76.298856];
Zipcodes['23801'] = [37.23613, -77.335804];
Zipcodes['23803'] = [37.215079, -77.470382];
Zipcodes['23805'] = [37.132177, -77.39424];
Zipcodes['23821'] = [36.8832, -77.912944];
Zipcodes['23824'] = [37.088778, -77.958041];
Zipcodes['23827'] = [36.619049, -77.200186];
Zipcodes['23828'] = [36.580854, -77.266933];
Zipcodes['23829'] = [36.727211, -77.228224];
Zipcodes['23830'] = [37.024337, -77.397215];
Zipcodes['23831'] = [37.346253, -77.450566];
Zipcodes['23832'] = [37.38785, -77.592108];
Zipcodes['23833'] = [37.209429, -77.668917];
Zipcodes['23834'] = [37.290074, -77.40078];
Zipcodes['23836'] = [37.350997, -77.330501];
Zipcodes['23837'] = [36.757091, -77.09002];
Zipcodes['23838'] = [37.317516, -77.633869];
Zipcodes['23839'] = [37.085921, -76.918457];
Zipcodes['23840'] = [37.051048, -77.671109];
Zipcodes['23841'] = [37.061934, -77.550208];
Zipcodes['23842'] = [37.13718, -77.217028];
Zipcodes['23843'] = [36.850366, -77.811813];
Zipcodes['23844'] = [36.680032, -77.326525];
Zipcodes['23845'] = [36.578823, -77.977561];
Zipcodes['23846'] = [37.066603, -76.83402];
Zipcodes['23847'] = [36.664183, -77.55164];
Zipcodes['23850'] = [37.163011, -77.742547];
Zipcodes['23851'] = [36.652023, -76.957141];
Zipcodes['23856'] = [36.794321, -77.703117];
Zipcodes['23857'] = [36.584651, -77.883799];
Zipcodes['23860'] = [37.274749, -77.227407];
Zipcodes['23866'] = [36.923676, -76.862401];
Zipcodes['23867'] = [36.820372, -77.488594];
Zipcodes['23868'] = [36.709281, -77.795078];
Zipcodes['23870'] = [36.752475, -77.500167];
Zipcodes['23872'] = [36.982977, -77.726757];
Zipcodes['23874'] = [36.584733, -77.070769];
Zipcodes['23875'] = [37.229942, -77.255727];
Zipcodes['23876'] = [36.955753, -77.83361];
Zipcodes['23878'] = [36.824772, -77.025432];
Zipcodes['23879'] = [36.592275, -77.596839];
Zipcodes['23881'] = [37.180741, -76.977829];
Zipcodes['23882'] = [36.926173, -77.421687];
Zipcodes['23883'] = [37.135962, -76.735892];
Zipcodes['23884'] = [36.917319, -77.280089];
Zipcodes['23885'] = [37.178849, -77.572911];
Zipcodes['23887'] = [36.573362, -77.816917];
Zipcodes['23888'] = [36.9543, -76.979206];
Zipcodes['23889'] = [36.902933, -77.747246];
Zipcodes['23890'] = [37.009176, -77.118319];
Zipcodes['23891'] = [37.047636, -77.203719];
Zipcodes['23893'] = [36.624444, -77.936561];
Zipcodes['23894'] = [37.137093, -77.824648];
Zipcodes['23897'] = [36.83731, -77.284758];
Zipcodes['23898'] = [36.838263, -76.86187];
Zipcodes['23899'] = [37.234417, -76.974083];
Zipcodes['23901'] = [37.320056, -78.409678];
Zipcodes['23909'] = [37.298993, -78.396307];
Zipcodes['23915'] = [36.69314, -78.287418];
Zipcodes['23917'] = [36.625554, -78.344856];
Zipcodes['23919'] = [36.580366, -78.134851];
Zipcodes['23920'] = [36.724517, -77.975253];
Zipcodes['23921'] = [37.586321, -78.623535];
Zipcodes['23922'] = [37.174829, -78.221738];
Zipcodes['23923'] = [37.087148, -78.649692];
Zipcodes['23924'] = [36.822186, -78.425844];
Zipcodes['23927'] = [36.591659, -78.561035];
Zipcodes['23930'] = [37.171003, -78.099611];
Zipcodes['23934'] = [37.177152, -78.626705];
Zipcodes['23936'] = [37.524599, -78.476522];
Zipcodes['23937'] = [36.945141, -78.512503];
Zipcodes['23938'] = [36.904221, -78.006223];
Zipcodes['23942'] = [37.129727, -78.298581];
Zipcodes['23943'] = [37.24321, -78.462039];
Zipcodes['23944'] = [36.915939, -78.138611];
Zipcodes['23947'] = [37.017152, -78.447748];
Zipcodes['23950'] = [36.658762, -78.075874];
Zipcodes['23952'] = [36.917525, -78.279219];
Zipcodes['23954'] = [37.106333, -78.380108];
Zipcodes['23955'] = [37.112362, -78.069873];
Zipcodes['23958'] = [37.273961, -78.656941];
Zipcodes['23959'] = [37.105192, -78.794749];
Zipcodes['23960'] = [37.320962, -78.552064];
Zipcodes['23962'] = [36.950633, -78.692389];
Zipcodes['23963'] = [37.200973, -78.798547];
Zipcodes['23964'] = [36.782735, -78.633141];
Zipcodes['23966'] = [37.300262, -78.259116];
Zipcodes['23967'] = [36.922423, -78.630037];
Zipcodes['23968'] = [36.716868, -78.510232];
Zipcodes['23970'] = [36.73911, -78.182448];
Zipcodes['23974'] = [36.969138, -78.242447];
Zipcodes['23976'] = [36.855538, -78.5791];
Zipcodes['24011'] = [37.270198, -79.941523];
Zipcodes['24012'] = [37.31765, -79.896769];
Zipcodes['24013'] = [37.267389, -79.921908];
Zipcodes['24014'] = [37.22286, -79.915191];
Zipcodes['24015'] = [37.255203, -79.982124];
Zipcodes['24016'] = [37.272053, -79.954991];
Zipcodes['24017'] = [37.297642, -79.990973];
Zipcodes['24018'] = [37.213055, -80.041739];
Zipcodes['24019'] = [37.343945, -79.954546];
Zipcodes['24020'] = [37.35612, -79.943194];
Zipcodes['24022'] = [37.276862, -79.934277];
Zipcodes['24053'] = [36.607227, -80.534076];
Zipcodes['24054'] = [36.682046, -79.704568];
Zipcodes['24055'] = [36.762073, -80.000609];
Zipcodes['24058'] = [37.176663, -80.622252];
Zipcodes['24059'] = [37.155906, -80.131958];
Zipcodes['24060'] = [37.260526, -80.416722];
Zipcodes['24061'] = [37.22582, -80.426238];
Zipcodes['24064'] = [37.376311, -79.770395];
Zipcodes['24065'] = [37.117118, -79.997108];
Zipcodes['24066'] = [37.536095, -79.678578];
Zipcodes['24067'] = [37.032354, -80.06032];
Zipcodes['24069'] = [36.576893, -79.642569];
Zipcodes['24070'] = [37.372087, -80.232822];
Zipcodes['24072'] = [37.04199, -80.242381];
Zipcodes['24073'] = [37.13465, -80.423065];
Zipcodes['24076'] = [36.605192, -80.421039];
Zipcodes['24077'] = [37.36106, -79.907203];
Zipcodes['24078'] = [36.724265, -79.913147];
Zipcodes['24079'] = [37.053355, -80.150755];
Zipcodes['24082'] = [36.633271, -80.131234];
Zipcodes['24083'] = [37.405171, -79.920188];
Zipcodes['24084'] = [37.167585, -80.717608];
Zipcodes['24085'] = [37.68393, -79.857319];
Zipcodes['24086'] = [37.27558, -80.632246];
Zipcodes['24087'] = [37.221511, -80.23893];
Zipcodes['24088'] = [36.887904, -80.077927];
Zipcodes['24089'] = [36.703913, -79.960775];
Zipcodes['24090'] = [37.530182, -79.895504];
Zipcodes['24091'] = [36.919838, -80.321295];
Zipcodes['24092'] = [37.012031, -79.757851];
Zipcodes['24093'] = [37.385139, -80.868737];
Zipcodes['24095'] = [37.22321, -79.737268];
Zipcodes['24101'] = [37.173555, -79.796387];
Zipcodes['24102'] = [36.844404, -80.002271];
Zipcodes['24104'] = [37.133611, -79.488635];
Zipcodes['24105'] = [36.911577, -80.603687];
Zipcodes['24112'] = [36.72117, -79.846927];
Zipcodes['24120'] = [36.691636, -80.413917];
Zipcodes['24121'] = [37.168916, -79.647411];
Zipcodes['24122'] = [37.426197, -79.69213];
Zipcodes['24124'] = [37.305928, -80.866008];
Zipcodes['24127'] = [37.473603, -80.231051];
Zipcodes['24128'] = [37.339994, -80.482544];
Zipcodes['24129'] = [37.139573, -80.591735];
Zipcodes['24131'] = [37.570649, -80.23287];
Zipcodes['24132'] = [37.203776, -80.659247];
Zipcodes['24133'] = [36.675633, -80.127964];
Zipcodes['24134'] = [37.247923, -80.777561];
Zipcodes['24136'] = [37.316097, -80.612791];
Zipcodes['24137'] = [36.927552, -79.657504];
Zipcodes['24138'] = [37.051815, -80.31735];
Zipcodes['24139'] = [37.014888, -79.452893];
Zipcodes['24141'] = [37.107159, -80.561888];
Zipcodes['24142'] = [37.138226, -80.550786];
Zipcodes['24147'] = [37.40151, -80.823626];
Zipcodes['24148'] = [36.581493, -79.876803];
Zipcodes['24149'] = [37.018582, -80.433581];
Zipcodes['24150'] = [37.401049, -80.598275];
Zipcodes['24151'] = [36.961444, -79.866431];
Zipcodes['24153'] = [37.302506, -80.114325];
Zipcodes['24161'] = [36.978085, -79.529113];
Zipcodes['24162'] = [37.129449, -80.249715];
Zipcodes['24165'] = [36.594266, -80.060586];
Zipcodes['24167'] = [37.255259, -80.724536];
Zipcodes['24168'] = [36.736039, -79.948755];
Zipcodes['24171'] = [36.696639, -80.220825];
Zipcodes['24174'] = [37.34972, -79.661897];
Zipcodes['24175'] = [37.4577, -80.029375];
Zipcodes['24176'] = [37.005545, -79.692495];
Zipcodes['24179'] = [37.282958, -79.793851];
Zipcodes['24184'] = [37.087129, -79.773642];
Zipcodes['24185'] = [36.808689, -80.275573];
Zipcodes['24201'] = [36.611602, -82.168628];
Zipcodes['24202'] = [36.65631, -82.212219];
Zipcodes['24210'] = [36.768935, -82.027531];
Zipcodes['24211'] = [36.654088, -81.95507];
Zipcodes['24216'] = [36.943624, -82.782796];
Zipcodes['24217'] = [37.102112, -82.170381];
Zipcodes['24219'] = [36.83435, -82.749105];
Zipcodes['24220'] = [37.121879, -82.223096];
Zipcodes['24221'] = [36.625312, -82.99876];
Zipcodes['24224'] = [36.861431, -82.271754];
Zipcodes['24225'] = [36.991715, -82.141851];
Zipcodes['24226'] = [37.136902, -82.322989];
Zipcodes['24228'] = [37.166862, -82.456257];
Zipcodes['24230'] = [36.968669, -82.451369];
Zipcodes['24236'] = [36.638506, -81.7245];
Zipcodes['24237'] = [37.040636, -82.261885];
Zipcodes['24239'] = [37.113037, -82.147305];
Zipcodes['24243'] = [36.784268, -82.919771];
Zipcodes['24244'] = [36.707695, -82.803484];
Zipcodes['24245'] = [36.838011, -82.512802];
Zipcodes['24246'] = [36.866168, -82.743949];
Zipcodes['24248'] = [36.609636, -83.509791];
Zipcodes['24250'] = [36.756595, -82.587663];
Zipcodes['24251'] = [36.657147, -82.599414];
Zipcodes['24256'] = [37.223339, -82.283532];
Zipcodes['24258'] = [36.649232, -82.408651];
Zipcodes['24260'] = [37.021059, -82.001115];
Zipcodes['24263'] = [36.668225, -83.167128];
Zipcodes['24265'] = [36.832663, -82.946461];
Zipcodes['24266'] = [36.86802, -82.101925];
Zipcodes['24269'] = [37.072772, -82.380679];
Zipcodes['24270'] = [36.72961, -82.250673];
Zipcodes['24271'] = [36.748813, -82.417187];
Zipcodes['24272'] = [37.017789, -82.333362];
Zipcodes['24273'] = [36.964701, -82.66148];
Zipcodes['24277'] = [36.749458, -83.019532];
Zipcodes['24279'] = [37.077735, -82.627393];
Zipcodes['24280'] = [36.954813, -81.943423];
Zipcodes['24281'] = [36.637323, -83.340226];
Zipcodes['24282'] = [36.818761, -83.05431];
Zipcodes['24283'] = [36.939782, -82.332939];
Zipcodes['24290'] = [36.616105, -82.561337];
Zipcodes['24292'] = [36.624744, -81.595528];
Zipcodes['24293'] = [36.998037, -82.566165];
Zipcodes['24301'] = [37.06503, -80.818267];
Zipcodes['24311'] = [36.873143, -81.402872];
Zipcodes['24312'] = [36.833015, -80.887018];
Zipcodes['24313'] = [36.910809, -80.796966];
Zipcodes['24314'] = [37.157244, -81.210414];
Zipcodes['24315'] = [37.133631, -81.063229];
Zipcodes['24316'] = [36.955705, -81.668988];
Zipcodes['24317'] = [36.601166, -80.671347];
Zipcodes['24318'] = [36.995958, -81.387124];
Zipcodes['24319'] = [36.761741, -81.65692];
Zipcodes['24322'] = [36.799014, -81.113662];
Zipcodes['24323'] = [36.86577, -81.204727];
Zipcodes['24324'] = [36.974112, -80.776478];
Zipcodes['24325'] = [36.810406, -80.587633];
Zipcodes['24326'] = [36.722732, -81.190257];
Zipcodes['24327'] = [36.773635, -81.829717];
Zipcodes['24328'] = [36.645356, -80.700836];
Zipcodes['24330'] = [36.715854, -81.026821];
Zipcodes['24333'] = [36.639975, -80.934776];
Zipcodes['24340'] = [36.753252, -81.767904];
Zipcodes['24343'] = [36.762633, -80.698401];
Zipcodes['24347'] = [36.960871, -80.652471];
Zipcodes['24348'] = [36.652229, -81.215324];
Zipcodes['24350'] = [36.813301, -80.994182];
Zipcodes['24351'] = [36.579431, -80.765127];
Zipcodes['24352'] = [36.709011, -80.531193];
Zipcodes['24354'] = [36.821272, -81.540457];
Zipcodes['24360'] = [36.940631, -80.909054];
Zipcodes['24361'] = [36.767336, -81.84496];
Zipcodes['24363'] = [36.61663, -81.405048];
Zipcodes['24366'] = [37.264041, -81.104131];
Zipcodes['24368'] = [36.892877, -81.301358];
Zipcodes['24370'] = [36.911415, -81.689789];
Zipcodes['24374'] = [36.783782, -81.22036];
Zipcodes['24375'] = [36.771672, -81.392247];
Zipcodes['24377'] = [37.002115, -81.568654];
Zipcodes['24378'] = [36.694311, -81.419636];
Zipcodes['24380'] = [36.859701, -80.508764];
Zipcodes['24381'] = [36.722284, -80.833241];
Zipcodes['24382'] = [36.95331, -81.110754];
Zipcodes['24401'] = [38.134387, -79.085564];
Zipcodes['24411'] = [38.118186, -79.330583];
Zipcodes['24412'] = [38.053722, -79.818136];
Zipcodes['24413'] = [38.505863, -79.556099];
Zipcodes['24415'] = [37.908591, -79.307709];
Zipcodes['24416'] = [37.728717, -79.354893];
Zipcodes['24421'] = [38.244651, -79.245145];
Zipcodes['24422'] = [37.832856, -79.750441];
Zipcodes['24426'] = [37.759304, -80.049967];
Zipcodes['24430'] = [38.077977, -79.34309];
Zipcodes['24431'] = [38.170036, -78.842885];
Zipcodes['24432'] = [38.148062, -79.44336];
Zipcodes['24433'] = [38.424017, -79.424832];
Zipcodes['24435'] = [37.857657, -79.306951];
Zipcodes['24437'] = [38.21248, -78.933724];
Zipcodes['24439'] = [38.017124, -79.482011];
Zipcodes['24440'] = [37.999357, -79.165401];
Zipcodes['24441'] = [38.222233, -78.809436];
Zipcodes['24442'] = [38.37253, -79.384617];
Zipcodes['24445'] = [37.96003, -79.871803];
Zipcodes['24448'] = [37.79711, -79.788524];
Zipcodes['24450'] = [37.779751, -79.545136];
Zipcodes['24457'] = [37.795845, -79.865211];
Zipcodes['24458'] = [38.32643, -79.510303];
Zipcodes['24459'] = [38.039279, -79.279523];
Zipcodes['24460'] = [38.016947, -79.666635];
Zipcodes['24464'] = [37.860625, -79.109957];
Zipcodes['24465'] = [38.397583, -79.64689];
Zipcodes['24467'] = [38.254993, -78.971323];
Zipcodes['24469'] = [38.200975, -78.908253];
Zipcodes['24471'] = [38.31321, -78.78418];
Zipcodes['24472'] = [37.939731, -79.220606];
Zipcodes['24473'] = [37.934747, -79.415358];
Zipcodes['24474'] = [37.805299, -79.847564];
Zipcodes['24476'] = [37.974466, -79.227361];
Zipcodes['24477'] = [38.004878, -79.042232];
Zipcodes['24479'] = [38.163944, -79.239044];
Zipcodes['24482'] = [38.203307, -78.998125];
Zipcodes['24483'] = [37.812641, -79.235174];
Zipcodes['24484'] = [38.183081, -79.799492];
Zipcodes['24485'] = [38.270936, -79.32998];
Zipcodes['24486'] = [38.296885, -78.924533];
Zipcodes['24487'] = [38.199727, -79.588455];
Zipcodes['24501'] = [37.359741, -79.141272];
Zipcodes['24502'] = [37.355154, -79.21854];
Zipcodes['24503'] = [37.454259, -79.241531];
Zipcodes['24504'] = [37.365249, -79.048192];
Zipcodes['24515'] = [37.353195, -79.179018];
Zipcodes['24517'] = [37.141398, -79.237443];
Zipcodes['24520'] = [36.586589, -79.029748];
Zipcodes['24521'] = [37.633291, -79.10084];
Zipcodes['24522'] = [37.376791, -78.778773];
Zipcodes['24523'] = [37.336882, -79.525726];
Zipcodes['24526'] = [37.556164, -79.397558];
Zipcodes['24527'] = [36.732729, -79.345561];
Zipcodes['24528'] = [37.079686, -78.876229];
Zipcodes['24529'] = [36.617245, -78.629806];
Zipcodes['24530'] = [36.807067, -79.619501];
Zipcodes['24531'] = [36.840665, -79.438102];
Zipcodes['24534'] = [36.883302, -78.770379];
Zipcodes['24536'] = [37.490862, -79.32367];
Zipcodes['24538'] = [37.379013, -78.97006];
Zipcodes['24539'] = [36.840575, -78.931411];
Zipcodes['24540'] = [36.634508, -79.426758];
Zipcodes['24541'] = [36.588812, -79.515049];
Zipcodes['24549'] = [36.70666, -79.504475];
Zipcodes['24550'] = [37.238258, -79.272021];
Zipcodes['24551'] = [37.370068, -79.331364];
Zipcodes['24553'] = [37.545504, -78.81001];
Zipcodes['24554'] = [37.120045, -79.071619];
Zipcodes['24555'] = [37.666966, -79.468292];
Zipcodes['24556'] = [37.375825, -79.399305];
Zipcodes['24557'] = [36.969859, -79.306424];
Zipcodes['24558'] = [36.780645, -78.956102];
Zipcodes['24562'] = [37.703198, -78.617799];
Zipcodes['24563'] = [37.059343, -79.27262];
Zipcodes['24565'] = [36.85272, -79.189501];
Zipcodes['24566'] = [36.731828, -79.252639];
Zipcodes['24569'] = [37.042552, -79.125855];
Zipcodes['24570'] = [37.34859, -79.427169];
Zipcodes['24571'] = [37.132961, -79.372748];
Zipcodes['24572'] = [37.464909, -79.095265];
Zipcodes['24574'] = [37.576927, -79.245595];
Zipcodes['24577'] = [36.943933, -78.982674];
Zipcodes['24578'] = [37.659882, -79.56228];
Zipcodes['24579'] = [37.585803, -79.502698];
Zipcodes['24580'] = [36.579054, -78.673121];
Zipcodes['24581'] = [37.655216, -78.805765];
Zipcodes['24586'] = [36.607044, -79.276609];
Zipcodes['24588'] = [37.262152, -79.096234];
Zipcodes['24589'] = [36.757223, -78.753722];
Zipcodes['24590'] = [37.792821, -78.474856];
Zipcodes['24592'] = [36.681952, -78.9527];
Zipcodes['24593'] = [37.334225, -78.914279];
Zipcodes['24594'] = [36.635983, -79.186729];
Zipcodes['24595'] = [37.56071, -79.081021];
Zipcodes['24597'] = [36.782411, -79.110858];
Zipcodes['24598'] = [36.60467, -78.788004];
Zipcodes['24599'] = [37.623562, -78.72389];
Zipcodes['24601'] = [37.190597, -81.640566];
Zipcodes['24602'] = [37.183213, -81.65851];
Zipcodes['24603'] = [37.347416, -82.203222];
Zipcodes['24604'] = [37.211983, -81.539669];
Zipcodes['24605'] = [37.2351, -81.374905];
Zipcodes['24606'] = [37.289353, -81.391205];
Zipcodes['24607'] = [37.29548, -82.270862];
Zipcodes['24609'] = [37.016058, -81.791386];
Zipcodes['24612'] = [37.101469, -81.849825];
Zipcodes['24613'] = [37.265157, -81.336881];
Zipcodes['24614'] = [37.31307, -82.088914];
Zipcodes['24620'] = [37.431352, -82.011757];
Zipcodes['24622'] = [37.214674, -81.789853];
Zipcodes['24630'] = [37.176845, -81.475447];
Zipcodes['24631'] = [37.221713, -82.001735];
Zipcodes['24634'] = [37.267827, -81.902751];
Zipcodes['24635'] = [37.3079, -81.348777];
Zipcodes['24637'] = [37.061082, -81.713894];
Zipcodes['24639'] = [37.172836, -81.897958];
Zipcodes['24641'] = [37.111813, -81.802446];
Zipcodes['24646'] = [37.144821, -82.024209];
Zipcodes['24649'] = [37.090347, -81.921695];
Zipcodes['24651'] = [37.075529, -81.503095];
Zipcodes['24656'] = [37.175055, -82.124994];
Zipcodes['24657'] = [37.227814, -81.848776];
Zipcodes['24701'] = [37.305951, -81.204941];
Zipcodes['24712'] = [37.479645, -81.034708];
Zipcodes['24714'] = [37.46865, -81.178346];
Zipcodes['24715'] = [37.335227, -81.333435];
Zipcodes['24716'] = [37.533692, -81.376265];
Zipcodes['24719'] = [37.487241, -81.326586];
Zipcodes['24724'] = [37.337525, -81.317972];
Zipcodes['24726'] = [37.487564, -81.29968];
Zipcodes['24729'] = [37.449885, -81.260028];
Zipcodes['24731'] = [37.397354, -81.151631];
Zipcodes['24733'] = [37.450148, -81.201096];
Zipcodes['24736'] = [37.446778, -81.281225];
Zipcodes['24737'] = [37.352962, -81.26579];
Zipcodes['24738'] = [37.303215, -81.3201];
Zipcodes['24739'] = [37.373457, -81.004791];
Zipcodes['24740'] = [37.403448, -81.106456];
Zipcodes['24747'] = [37.411496, -81.21655];
Zipcodes['24801'] = [37.468587, -81.553126];
Zipcodes['24808'] = [37.330026, -81.422403];
Zipcodes['24811'] = [37.402181, -81.748496];
Zipcodes['24813'] = [37.357412, -81.720113];
Zipcodes['24815'] = [37.220042, -81.616211];
Zipcodes['24816'] = [37.461102, -81.705939];
Zipcodes['24817'] = [37.341159, -81.821588];
Zipcodes['24818'] = [37.624704, -81.620327];
Zipcodes['24822'] = [37.647027, -81.697268];
Zipcodes['24823'] = [37.67197, -81.751948];
Zipcodes['24826'] = [37.293267, -81.623592];
Zipcodes['24827'] = [37.768854, -81.636893];
Zipcodes['24828'] = [37.497807, -81.671316];
Zipcodes['24830'] = [37.333875, -81.558614];
Zipcodes['24831'] = [37.388942, -81.451138];
Zipcodes['24834'] = [37.523608, -81.686328];
Zipcodes['24836'] = [37.381162, -81.551174];
Zipcodes['24839'] = [37.578406, -81.757328];
Zipcodes['24843'] = [37.483784, -81.709229];
Zipcodes['24844'] = [37.489556, -81.792555];
Zipcodes['24845'] = [37.526411, -81.800383];
Zipcodes['24846'] = [37.525781, -81.886606];
Zipcodes['24847'] = [37.570719, -81.409406];
Zipcodes['24848'] = [37.280144, -81.434355];
Zipcodes['24849'] = [37.686953, -81.561622];
Zipcodes['24850'] = [37.378059, -81.885155];
Zipcodes['24851'] = [37.601371, -81.833685];
Zipcodes['24853'] = [37.448145, -81.530203];
Zipcodes['24854'] = [37.749562, -81.576507];
Zipcodes['24855'] = [37.408711, -81.425011];
Zipcodes['24857'] = [37.687392, -81.675978];
Zipcodes['24860'] = [37.662564, -81.589795];
Zipcodes['24861'] = [37.357282, -81.357337];
Zipcodes['24862'] = [37.478806, -81.963644];
Zipcodes['24866'] = [37.25006, -81.580509];
Zipcodes['24867'] = [37.605306, -81.457338];
Zipcodes['24868'] = [37.43845, -81.389063];
Zipcodes['24869'] = [37.553376, -81.824438];
Zipcodes['24870'] = [37.736479, -81.532083];
Zipcodes['24871'] = [37.323125, -81.506122];
Zipcodes['24872'] = [37.449367, -81.9205];
Zipcodes['24873'] = [37.351462, -81.880066];
Zipcodes['24874'] = [37.601764, -81.530968];
Zipcodes['24878'] = [37.439362, -81.625418];
Zipcodes['24879'] = [37.311992, -81.767655];
Zipcodes['24880'] = [37.649635, -81.532011];
Zipcodes['24881'] = [37.442844, -81.669945];
Zipcodes['24882'] = [37.625693, -81.765994];
Zipcodes['24884'] = [37.27312, -81.551387];
Zipcodes['24887'] = [37.378041, -81.386601];
Zipcodes['24888'] = [37.357632, -81.483351];
Zipcodes['24892'] = [37.316739, -81.696824];
Zipcodes['24894'] = [37.244303, -81.688138];
Zipcodes['24895'] = [37.3763, -81.582936];
Zipcodes['24898'] = [37.587407, -81.568403];
Zipcodes['24901'] = [37.853926, -80.484563];
Zipcodes['24910'] = [37.742663, -80.668419];
Zipcodes['24915'] = [38.469293, -79.786674];
Zipcodes['24916'] = [37.828615, -80.580988];
Zipcodes['24918'] = [37.52231, -80.745495];
Zipcodes['24920'] = [38.632836, -79.696155];
Zipcodes['24924'] = [38.201301, -80.164874];
Zipcodes['24925'] = [37.730837, -80.379891];
Zipcodes['24927'] = [38.340242, -80.037582];
Zipcodes['24931'] = [37.911583, -80.599696];
Zipcodes['24934'] = [38.333625, -79.891045];
Zipcodes['24935'] = [37.566111, -80.80565];
Zipcodes['24938'] = [37.932796, -80.370385];
Zipcodes['24941'] = [37.586293, -80.351035];
Zipcodes['24943'] = [37.838254, -80.733835];
Zipcodes['24944'] = [38.384639, -79.769328];
Zipcodes['24945'] = [37.516448, -80.70316];
Zipcodes['24946'] = [38.228574, -80.319783];
Zipcodes['24951'] = [37.49523, -80.624125];
Zipcodes['24954'] = [38.224907, -80.056049];
Zipcodes['24957'] = [37.896072, -80.396796];
Zipcodes['24962'] = [37.662404, -80.718357];
Zipcodes['24963'] = [37.436403, -80.758472];
Zipcodes['24966'] = [38.056828, -80.385133];
Zipcodes['24970'] = [37.728112, -80.474462];
Zipcodes['24974'] = [37.641769, -80.448356];
Zipcodes['24976'] = [37.656959, -80.51171];
Zipcodes['24977'] = [37.897887, -80.685023];
Zipcodes['24981'] = [37.632868, -80.735662];
Zipcodes['24983'] = [37.596051, -80.506452];
Zipcodes['24984'] = [37.493887, -80.407698];
Zipcodes['24985'] = [37.604171, -80.712621];
Zipcodes['24986'] = [37.92109, -80.145606];
Zipcodes['24991'] = [38.001294, -80.480567];
Zipcodes['24993'] = [37.660986, -80.635179];
Zipcodes['25002'] = [38.130879, -81.254724];
Zipcodes['25003'] = [38.248491, -81.784858];
Zipcodes['25005'] = [38.606199, -81.257079];
Zipcodes['25007'] = [37.82484, -81.430458];
Zipcodes['25008'] = [37.948787, -81.344599];
Zipcodes['25009'] = [38.202305, -81.684573];
Zipcodes['25011'] = [38.514838, -81.832739];
Zipcodes['25015'] = [38.246134, -81.497258];
Zipcodes['25019'] = [38.350663, -81.092215];
Zipcodes['25021'] = [37.917841, -81.683934];
Zipcodes['25022'] = [37.824584, -81.776158];
Zipcodes['25024'] = [38.159081, -81.623722];
Zipcodes['25025'] = [38.298414, -81.403141];
Zipcodes['25028'] = [37.947147, -81.725572];
Zipcodes['25030'] = [38.453317, -81.225847];
Zipcodes['25031'] = [38.148749, -81.27478];
Zipcodes['25033'] = [38.612061, -81.924382];
Zipcodes['25035'] = [38.165239, -81.52099];
Zipcodes['25036'] = [38.182341, -81.271114];
Zipcodes['25039'] = [38.234956, -81.363261];
Zipcodes['25040'] = [38.136354, -81.247095];
Zipcodes['25043'] = [38.425757, -81.003154];
Zipcodes['25044'] = [37.915805, -81.309364];
Zipcodes['25045'] = [38.447483, -81.329322];
Zipcodes['25047'] = [37.920527, -81.767625];
Zipcodes['25048'] = [37.947329, -81.430769];
Zipcodes['25049'] = [38.115286, -81.558258];
Zipcodes['25051'] = [38.156947, -81.709791];
Zipcodes['25053'] = [38.041333, -81.88156];
Zipcodes['25054'] = [38.120523, -81.451884];
Zipcodes['25057'] = [38.109263, -81.223428];
Zipcodes['25059'] = [38.244588, -81.223691];
Zipcodes['25060'] = [37.966455, -81.473963];
Zipcodes['25061'] = [38.174553, -81.439835];
Zipcodes['25062'] = [37.879656, -81.433199];
Zipcodes['25063'] = [38.576127, -80.922962];
Zipcodes['25064'] = [38.377039, -81.749365];
Zipcodes['25067'] = [38.213406, -81.444998];
Zipcodes['25070'] = [38.545893, -81.931647];
Zipcodes['25071'] = [38.477798, -81.466553];
Zipcodes['25075'] = [38.060148, -81.405123];
Zipcodes['25076'] = [37.868918, -81.937017];
Zipcodes['25081'] = [38.078076, -81.768414];
Zipcodes['25082'] = [38.604207, -82.031043];
Zipcodes['25083'] = [38.069872, -81.367702];
Zipcodes['25085'] = [38.161716, -81.163262];
Zipcodes['25086'] = [38.218276, -81.414583];
Zipcodes['25088'] = [38.398602, -81.208823];
Zipcodes['25090'] = [38.15499, -81.221063];
Zipcodes['25093'] = [37.951253, -81.632674];
Zipcodes['25102'] = [38.165711, -81.352056];
Zipcodes['25103'] = [38.180605, -81.380862];
Zipcodes['25106'] = [38.766038, -82.084836];
Zipcodes['25107'] = [38.237957, -81.676172];
Zipcodes['25108'] = [37.962356, -81.878069];
Zipcodes['25109'] = [38.531302, -81.860557];
Zipcodes['25110'] = [38.205084, -81.337228];
Zipcodes['25111'] = [38.350171, -81.123351];
Zipcodes['25112'] = [38.381037, -81.766295];
Zipcodes['25113'] = [38.553289, -81.046273];
Zipcodes['25114'] = [37.987318, -81.786336];
Zipcodes['25115'] = [38.122179, -81.182241];
Zipcodes['25118'] = [38.118868, -81.322966];
Zipcodes['25119'] = [38.014973, -81.262872];
Zipcodes['25121'] = [37.910177, -81.886743];
Zipcodes['25123'] = [38.730175, -81.900044];
Zipcodes['25124'] = [38.621093, -81.764505];
Zipcodes['25125'] = [38.311679, -81.21523];
Zipcodes['25126'] = [38.190554, -81.357264];
Zipcodes['25130'] = [38.032993, -81.752257];
Zipcodes['25132'] = [38.293275, -81.334405];
Zipcodes['25133'] = [38.474596, -81.101457];
Zipcodes['25134'] = [38.143244, -81.473035];
Zipcodes['25136'] = [38.154438, -81.314794];
Zipcodes['25139'] = [38.105991, -81.282831];
Zipcodes['25140'] = [37.839893, -81.498515];
Zipcodes['25141'] = [38.630175, -81.024705];
Zipcodes['25142'] = [38.143056, -81.737664];
Zipcodes['25143'] = [38.421336, -81.823619];
Zipcodes['25148'] = [38.025489, -81.525735];
Zipcodes['25149'] = [37.949155, -81.771997];
Zipcodes['25152'] = [38.03274, -81.24036];
Zipcodes['25154'] = [38.120576, -81.713325];
Zipcodes['25156'] = [38.411803, -81.482194];
Zipcodes['25159'] = [38.523611, -81.788899];
Zipcodes['25160'] = [38.324269, -81.268444];
Zipcodes['25161'] = [38.052784, -81.318726];
Zipcodes['25162'] = [38.207656, -81.382999];
Zipcodes['25164'] = [38.509184, -81.204946];
Zipcodes['25165'] = [38.155709, -81.653541];
Zipcodes['25168'] = [38.560349, -81.87985];
Zipcodes['25169'] = [38.129249, -81.763459];
Zipcodes['25173'] = [38.073781, -81.21041];
Zipcodes['25174'] = [37.865661, -81.410962];
Zipcodes['25177'] = [38.369399, -81.850811];
Zipcodes['25180'] = [37.79026, -81.436267];
Zipcodes['25181'] = [38.04929, -81.6412];
Zipcodes['25183'] = [37.891759, -81.799522];
Zipcodes['25185'] = [38.217189, -81.238013];
Zipcodes['25186'] = [38.173963, -81.29751];
Zipcodes['25187'] = [38.731665, -82.014332];
Zipcodes['25193'] = [38.016778, -81.550923];
Zipcodes['25201'] = [38.347703, -81.491336];
Zipcodes['25202'] = [38.316375, -81.859088];
Zipcodes['25203'] = [38.021238, -81.881629];
Zipcodes['25204'] = [37.927373, -81.638962];
Zipcodes['25205'] = [38.019214, -81.793394];
Zipcodes['25206'] = [37.956911, -81.682919];
Zipcodes['25208'] = [37.836513, -81.639765];
Zipcodes['25209'] = [37.959553, -81.522269];
Zipcodes['25211'] = [38.46869, -80.88841];
Zipcodes['25213'] = [38.512665, -81.913304];
Zipcodes['25214'] = [38.185826, -81.542914];
Zipcodes['25231'] = [38.613149, -81.594671];
Zipcodes['25234'] = [38.808318, -81.133343];
Zipcodes['25235'] = [38.669527, -81.0895];
Zipcodes['25239'] = [38.850285, -81.84485];
Zipcodes['25241'] = [38.775273, -81.800166];
Zipcodes['25243'] = [38.676181, -81.493644];
Zipcodes['25244'] = [38.763279, -81.539024];
Zipcodes['25245'] = [38.708806, -81.736288];
Zipcodes['25247'] = [39.001989, -81.989654];
Zipcodes['25248'] = [38.644268, -81.613862];
Zipcodes['25251'] = [38.621193, -81.231754];
Zipcodes['25252'] = [38.878578, -81.534861];
Zipcodes['25253'] = [38.921064, -81.96149];
Zipcodes['25259'] = [38.674671, -81.248355];
Zipcodes['25260'] = [39.021012, -82.019094];
Zipcodes['25261'] = [38.83338, -81.090148];
Zipcodes['25262'] = [38.909198, -81.836263];
Zipcodes['25264'] = [38.859864, -81.893884];
Zipcodes['25265'] = [38.973645, -81.982558];
Zipcodes['25266'] = [38.602239, -81.158898];
Zipcodes['25267'] = [38.85751, -80.951487];
Zipcodes['25268'] = [38.731224, -81.123509];
Zipcodes['25270'] = [38.877167, -81.457837];
Zipcodes['25271'] = [38.78851, -81.692539];
Zipcodes['25275'] = [38.926731, -81.619021];
Zipcodes['25276'] = [38.78208, -81.328692];
Zipcodes['25285'] = [38.539656, -81.101556];
Zipcodes['25286'] = [38.620979, -81.397287];
Zipcodes['25287'] = [38.960863, -82.051333];
Zipcodes['25301'] = [38.351327, -81.627994];
Zipcodes['25302'] = [38.393184, -81.59547];
Zipcodes['25303'] = [38.359561, -81.68613];
Zipcodes['25304'] = [38.301561, -81.596701];
Zipcodes['25305'] = [38.336819, -81.613139];
Zipcodes['25306'] = [38.312523, -81.49944];
Zipcodes['25309'] = [38.311899, -81.75717];
Zipcodes['25311'] = [38.366863, -81.557035];
Zipcodes['25312'] = [38.452523, -81.645726];
Zipcodes['25313'] = [38.408669, -81.743527];
Zipcodes['25314'] = [38.289161, -81.660153];
Zipcodes['25315'] = [38.21493, -81.584148];
Zipcodes['25320'] = [38.539791, -81.625733];
Zipcodes['25387'] = [38.384701, -81.670603];
Zipcodes['25401'] = [39.457168, -77.972428];
Zipcodes['25403'] = [39.47485, -78.01164];
Zipcodes['25404'] = [39.489919, -77.896363];
Zipcodes['25405'] = [39.40892, -77.962369];
Zipcodes['25411'] = [39.548748, -78.22147];
Zipcodes['25413'] = [39.310588, -78.055948];
Zipcodes['25414'] = [39.241794, -77.864849];
Zipcodes['25419'] = [39.58287, -77.885069];
Zipcodes['25420'] = [39.380952, -78.119679];
Zipcodes['25421'] = [39.378211, -78.163825];
Zipcodes['25422'] = [39.544988, -78.354289];
Zipcodes['25425'] = [39.306662, -77.773034];
Zipcodes['25427'] = [39.520419, -78.099121];
Zipcodes['25428'] = [39.380635, -78.02221];
Zipcodes['25430'] = [39.327184, -77.937916];
Zipcodes['25431'] = [39.483762, -78.574312];
Zipcodes['25432'] = [39.295447, -77.786803];
Zipcodes['25434'] = [39.439747, -78.461107];
Zipcodes['25437'] = [39.423666, -78.579298];
Zipcodes['25438'] = [39.323441, -77.864008];
Zipcodes['25442'] = [39.372847, -77.832749];
Zipcodes['25443'] = [39.438227, -77.814691];
Zipcodes['25444'] = [39.430835, -78.513474];
Zipcodes['25446'] = [39.240166, -77.957047];
Zipcodes['25501'] = [38.161533, -81.957254];
Zipcodes['25502'] = [38.665771, -82.116359];
Zipcodes['25503'] = [38.608945, -82.115017];
Zipcodes['25504'] = [38.37643, -82.270031];
Zipcodes['25505'] = [38.019684, -82.030755];
Zipcodes['25506'] = [38.209278, -82.198907];
Zipcodes['25507'] = [38.397073, -82.54935];
Zipcodes['25508'] = [37.962192, -82.006933];
Zipcodes['25510'] = [38.382296, -82.082999];
Zipcodes['25511'] = [38.051218, -82.33503];
Zipcodes['25512'] = [38.178424, -82.304109];
Zipcodes['25514'] = [38.07779, -82.525248];
Zipcodes['25515'] = [38.756623, -82.146875];
Zipcodes['25517'] = [38.109821, -82.453679];
Zipcodes['25520'] = [38.557515, -82.177897];
Zipcodes['25521'] = [38.226926, -81.982429];
Zipcodes['25523'] = [38.280064, -82.065304];
Zipcodes['25524'] = [38.021624, -82.117351];
Zipcodes['25526'] = [38.394449, -81.991307];
Zipcodes['25529'] = [38.159811, -81.818707];
Zipcodes['25530'] = [38.357487, -82.579994];
Zipcodes['25534'] = [38.08441, -82.275024];
Zipcodes['25535'] = [38.308556, -82.434844];
Zipcodes['25537'] = [38.533383, -82.281971];
Zipcodes['25540'] = [38.157054, -82.139109];
Zipcodes['25541'] = [38.480587, -82.130164];
Zipcodes['25544'] = [38.230777, -82.110192];
Zipcodes['25545'] = [38.459122, -82.227154];
Zipcodes['25547'] = [37.919787, -81.94458];
Zipcodes['25550'] = [38.863326, -82.067437];
Zipcodes['25555'] = [38.213895, -82.549634];
Zipcodes['25557'] = [38.100424, -82.153689];
Zipcodes['25559'] = [38.324948, -82.229139];
Zipcodes['25560'] = [38.446838, -81.898852];
Zipcodes['25564'] = [38.272173, -81.900988];
Zipcodes['25565'] = [38.121388, -81.973032];
Zipcodes['25567'] = [38.208254, -81.894447];
Zipcodes['25570'] = [38.227092, -82.402183];
Zipcodes['25571'] = [38.313487, -82.155816];
Zipcodes['25573'] = [38.232184, -81.941102];
Zipcodes['25601'] = [37.862762, -82.009263];
Zipcodes['25606'] = [37.764964, -81.820417];
Zipcodes['25607'] = [37.783698, -81.771867];
Zipcodes['25608'] = [37.575067, -81.89162];
Zipcodes['25611'] = [37.685046, -81.871695];
Zipcodes['25612'] = [37.763573, -81.979043];
Zipcodes['25617'] = [37.741457, -81.807343];
Zipcodes['25621'] = [37.649915, -81.88558];
Zipcodes['25624'] = [37.899691, -81.981116];
Zipcodes['25625'] = [37.819366, -82.081222];
Zipcodes['25628'] = [37.768114, -81.862466];
Zipcodes['25630'] = [37.78837, -81.693967];
Zipcodes['25632'] = [37.742027, -81.947597];
Zipcodes['25634'] = [37.727581, -81.81829];
Zipcodes['25635'] = [37.718937, -81.872401];
Zipcodes['25637'] = [37.851838, -82.026753];
Zipcodes['25638'] = [37.737547, -82.054367];
Zipcodes['25639'] = [37.881407, -81.955067];
Zipcodes['25644'] = [37.704742, -82.022096];
Zipcodes['25646'] = [37.847377, -81.884159];
Zipcodes['25647'] = [37.78681, -81.984432];
Zipcodes['25649'] = [37.875404, -82.09308];
Zipcodes['25650'] = [37.683569, -81.787746];
Zipcodes['25651'] = [37.585894, -81.956801];
Zipcodes['25652'] = [37.790733, -82.045124];
Zipcodes['25653'] = [37.823634, -81.991248];
Zipcodes['25654'] = [37.795626, -81.873719];
Zipcodes['25661'] = [37.710519, -82.253717];
Zipcodes['25666'] = [37.920311, -82.294292];
Zipcodes['25667'] = [37.706189, -82.273768];
Zipcodes['25669'] = [37.935498, -82.468453];
Zipcodes['25670'] = [37.738474, -82.1559];
Zipcodes['25671'] = [37.904067, -82.202798];
Zipcodes['25672'] = [37.570103, -82.100397];
Zipcodes['25674'] = [37.871079, -82.346703];
Zipcodes['25676'] = [37.837733, -82.193056];
Zipcodes['25678'] = [37.611952, -82.135327];
Zipcodes['25685'] = [37.795802, -82.314334];
Zipcodes['25688'] = [37.639548, -82.15821];
Zipcodes['25690'] = [37.691251, -82.155024];
Zipcodes['25692'] = [37.618039, -82.073753];
Zipcodes['25696'] = [37.6629, -82.055459];
Zipcodes['25699'] = [37.95875, -82.304028];
Zipcodes['25701'] = [38.365689, -82.40525];
Zipcodes['25702'] = [38.434723, -82.314416];
Zipcodes['25703'] = [38.422076, -82.418206];
Zipcodes['25704'] = [38.30761, -82.487363];
Zipcodes['25705'] = [38.406345, -82.362542];
Zipcodes['25755'] = [38.423592, -82.427923];
Zipcodes['25801'] = [37.840601, -81.270723];
Zipcodes['25810'] = [37.583494, -81.349947];
Zipcodes['25811'] = [37.590131, -81.29126];
Zipcodes['25812'] = [38.138983, -81.1262];
Zipcodes['25813'] = [37.784227, -81.099625];
Zipcodes['25817'] = [37.764458, -81.424855];
Zipcodes['25818'] = [37.86028, -81.191992];
Zipcodes['25820'] = [37.51519, -81.163849];
Zipcodes['25823'] = [37.666336, -81.182804];
Zipcodes['25825'] = [37.644279, -81.082973];
Zipcodes['25826'] = [37.573029, -81.370193];
Zipcodes['25827'] = [37.739101, -81.254768];
Zipcodes['25831'] = [37.959313, -80.930791];
Zipcodes['25832'] = [37.715324, -81.118819];
Zipcodes['25836'] = [37.785563, -81.28784];
Zipcodes['25837'] = [38.058226, -81.038259];
Zipcodes['25839'] = [37.778207, -81.387207];
Zipcodes['25840'] = [38.037842, -81.111641];
Zipcodes['25841'] = [37.560789, -81.092546];
Zipcodes['25843'] = [37.623873, -81.129885];
Zipcodes['25844'] = [37.807973, -81.358069];
Zipcodes['25845'] = [37.714972, -81.510784];
Zipcodes['25846'] = [37.926114, -81.168206];
Zipcodes['25848'] = [37.72151, -81.424697];
Zipcodes['25849'] = [37.731726, -81.278007];
Zipcodes['25851'] = [37.80255, -81.259402];
Zipcodes['25853'] = [37.644491, -81.282312];
Zipcodes['25854'] = [38.127547, -80.976434];
Zipcodes['25855'] = [37.940492, -81.153049];
Zipcodes['25857'] = [37.609736, -81.237553];
Zipcodes['25862'] = [38.088168, -81.040489];
Zipcodes['25864'] = [37.885488, -81.03777];
Zipcodes['25865'] = [37.71154, -81.362406];
Zipcodes['25866'] = [38.007772, -81.16148];
Zipcodes['25868'] = [38.070497, -80.967248];
Zipcodes['25870'] = [37.63824, -81.37296];
Zipcodes['25871'] = [37.76917, -81.211735];
Zipcodes['25873'] = [37.750066, -81.213823];
Zipcodes['25875'] = [37.665585, -81.451237];
Zipcodes['25876'] = [37.631101, -81.449139];
Zipcodes['25878'] = [37.705199, -81.222051];
Zipcodes['25879'] = [37.98226, -81.105668];
Zipcodes['25880'] = [37.879494, -81.199471];
Zipcodes['25882'] = [37.612277, -81.403666];
Zipcodes['25901'] = [37.939872, -81.102848];
Zipcodes['25902'] = [37.569827, -81.217459];
Zipcodes['25904'] = [37.915556, -81.245817];
Zipcodes['25907'] = [37.856018, -81.086217];
Zipcodes['25908'] = [37.667767, -81.244349];
Zipcodes['25909'] = [37.838952, -81.202842];
Zipcodes['25911'] = [37.751761, -81.180434];
Zipcodes['25913'] = [37.707827, -81.472973];
Zipcodes['25915'] = [37.61807, -81.291454];
Zipcodes['25916'] = [37.678176, -81.490278];
Zipcodes['25917'] = [37.983649, -81.252324];
Zipcodes['25918'] = [37.739998, -81.035597];
Zipcodes['25920'] = [37.686426, -81.336322];
Zipcodes['25921'] = [37.682817, -81.266249];
Zipcodes['25922'] = [37.471975, -81.102777];
Zipcodes['25928'] = [37.541308, -81.339851];
Zipcodes['25932'] = [37.752011, -81.299569];
Zipcodes['25936'] = [37.926383, -81.033813];
Zipcodes['25938'] = [38.162883, -81.030457];
Zipcodes['25942'] = [38.017861, -80.985353];
Zipcodes['25943'] = [37.596615, -81.349191];
Zipcodes['25951'] = [37.655201, -80.875502];
Zipcodes['25958'] = [38.004072, -80.766597];
Zipcodes['25962'] = [37.986095, -80.798917];
Zipcodes['25966'] = [37.805617, -80.756187];
Zipcodes['25969'] = [37.611155, -81.005215];
Zipcodes['25971'] = [37.477419, -80.977446];
Zipcodes['25972'] = [38.032122, -80.706902];
Zipcodes['25976'] = [37.840546, -80.866718];
Zipcodes['25977'] = [37.797837, -80.906563];
Zipcodes['25978'] = [37.633, -80.941205];
Zipcodes['25979'] = [37.510942, -80.913482];
Zipcodes['25981'] = [38.070912, -80.69787];
Zipcodes['25984'] = [38.015846, -80.607361];
Zipcodes['25985'] = [37.756694, -80.833257];
Zipcodes['25986'] = [37.899792, -80.823727];
Zipcodes['25989'] = [37.678587, -81.036977];
Zipcodes['26003'] = [40.069182, -80.650807];
Zipcodes['26030'] = [40.223251, -80.657935];
Zipcodes['26031'] = [39.999074, -80.706014];
Zipcodes['26032'] = [40.183687, -80.553234];
Zipcodes['26033'] = [39.828256, -80.579475];
Zipcodes['26034'] = [40.598473, -80.552334];
Zipcodes['26035'] = [40.3449, -80.553539];
Zipcodes['26036'] = [39.97772, -80.554299];
Zipcodes['26037'] = [40.335049, -80.585918];
Zipcodes['26038'] = [39.96459, -80.724103];
Zipcodes['26039'] = [39.799369, -80.674066];
Zipcodes['26040'] = [39.983582, -80.731377];
Zipcodes['26041'] = [39.887247, -80.705419];
Zipcodes['26047'] = [40.532075, -80.585773];
Zipcodes['26050'] = [40.608265, -80.620785];
Zipcodes['26055'] = [39.762275, -80.779828];
Zipcodes['26056'] = [40.516958, -80.570164];
Zipcodes['26058'] = [40.187138, -80.661917];
Zipcodes['26059'] = [40.057338, -80.605252];
Zipcodes['26060'] = [40.105481, -80.544835];
Zipcodes['26062'] = [40.421372, -80.557726];
Zipcodes['26070'] = [40.259827, -80.580029];
Zipcodes['26074'] = [40.166606, -80.595338];
Zipcodes['26075'] = [40.196426, -80.650027];
Zipcodes['26101'] = [39.225842, -81.584316];
Zipcodes['26104'] = [39.277972, -81.475975];
Zipcodes['26105'] = [39.329574, -81.514619];
Zipcodes['26133'] = [39.115389, -81.663161];
Zipcodes['26134'] = [39.381465, -81.273534];
Zipcodes['26136'] = [38.961365, -81.118094];
Zipcodes['26137'] = [38.995583, -81.0494];
Zipcodes['26138'] = [39.027944, -81.146269];
Zipcodes['26141'] = [38.933503, -81.246147];
Zipcodes['26142'] = [39.214984, -81.454579];
Zipcodes['26143'] = [39.056366, -81.372736];
Zipcodes['26146'] = [39.457065, -81.036844];
Zipcodes['26147'] = [38.913569, -81.076188];
Zipcodes['26148'] = [39.058499, -81.182808];
Zipcodes['26149'] = [39.472168, -80.88585];
Zipcodes['26150'] = [39.134707, -81.535127];
Zipcodes['26151'] = [38.894572, -81.182077];
Zipcodes['26152'] = [38.979139, -81.177705];
Zipcodes['26155'] = [39.630056, -80.763963];
Zipcodes['26159'] = [39.607357, -80.932238];
Zipcodes['26160'] = [38.981202, -81.412057];
Zipcodes['26161'] = [39.159713, -81.246033];
Zipcodes['26162'] = [39.568702, -80.766701];
Zipcodes['26164'] = [39.013355, -81.694848];
Zipcodes['26167'] = [39.532341, -80.722809];
Zipcodes['26169'] = [39.072207, -81.571589];
Zipcodes['26170'] = [39.362023, -81.177959];
Zipcodes['26175'] = [39.537188, -80.974755];
Zipcodes['26178'] = [39.062813, -81.027429];
Zipcodes['26180'] = [39.194913, -81.3663];
Zipcodes['26181'] = [39.209325, -81.664463];
Zipcodes['26184'] = [39.294993, -81.35272];
Zipcodes['26187'] = [39.366289, -81.446609];
Zipcodes['26201'] = [39.001947, -80.197409];
Zipcodes['26202'] = [38.231628, -80.622135];
Zipcodes['26203'] = [38.548476, -80.58844];
Zipcodes['26205'] = [38.321233, -80.646241];
Zipcodes['26206'] = [38.320088, -80.413219];
Zipcodes['26208'] = [38.349934, -80.583767];
Zipcodes['26209'] = [38.375593, -80.07997];
Zipcodes['26210'] = [38.903148, -80.277888];
Zipcodes['26215'] = [38.721568, -80.364888];
Zipcodes['26217'] = [38.611008, -80.446813];
Zipcodes['26218'] = [38.845023, -80.267943];
Zipcodes['26222'] = [38.660184, -80.343304];
Zipcodes['26224'] = [38.744844, -80.174726];
Zipcodes['26228'] = [38.754356, -80.370544];
Zipcodes['26230'] = [38.626966, -80.206432];
Zipcodes['26234'] = [38.766166, -80.308326];
Zipcodes['26236'] = [38.717581, -80.230872];
Zipcodes['26237'] = [38.847711, -80.151475];
Zipcodes['26238'] = [39.101783, -80.158407];
Zipcodes['26241'] = [38.916258, -79.792226];
Zipcodes['26250'] = [39.029586, -79.970377];
Zipcodes['26253'] = [38.806126, -79.843118];
Zipcodes['26254'] = [38.962179, -79.630146];
Zipcodes['26257'] = [38.92257, -80.012384];
Zipcodes['26259'] = [38.825363, -79.91532];
Zipcodes['26260'] = [39.082926, -79.412495];
Zipcodes['26261'] = [38.189002, -80.500577];
Zipcodes['26263'] = [38.957044, -79.43582];
Zipcodes['26264'] = [38.589152, -79.822853];
Zipcodes['26266'] = [38.437566, -80.497397];
Zipcodes['26267'] = [38.925527, -80.080708];
Zipcodes['26268'] = [38.714502, -79.822856];
Zipcodes['26269'] = [39.116684, -79.652235];
Zipcodes['26270'] = [38.812253, -79.600451];
Zipcodes['26271'] = [39.02377, -79.503895];
Zipcodes['26273'] = [38.634493, -79.961209];
Zipcodes['26275'] = [38.986933, -79.949988];
Zipcodes['26276'] = [39.033666, -79.732665];
Zipcodes['26278'] = [38.825708, -80.025489];
Zipcodes['26280'] = [38.717353, -80.03781];
Zipcodes['26282'] = [38.520163, -80.146217];
Zipcodes['26283'] = [39.05844, -79.823145];
Zipcodes['26285'] = [38.910179, -79.949448];
Zipcodes['26287'] = [39.166016, -79.687509];
Zipcodes['26288'] = [38.514958, -80.346597];
Zipcodes['26289'] = [38.989709, -79.512691];
Zipcodes['26291'] = [38.441098, -80.086311];
Zipcodes['26292'] = [39.141915, -79.533672];
Zipcodes['26293'] = [38.782355, -79.936517];
Zipcodes['26294'] = [38.534431, -80.023084];
Zipcodes['26296'] = [38.764498, -79.553568];
Zipcodes['26298'] = [38.456206, -80.286154];
Zipcodes['26301'] = [39.286434, -80.372691];
Zipcodes['26320'] = [39.404133, -80.812146];
Zipcodes['26321'] = [39.052245, -80.694146];
Zipcodes['26323'] = [39.262277, -80.290552];
Zipcodes['26325'] = [39.098947, -80.904803];
Zipcodes['26327'] = [39.122886, -80.94277];
Zipcodes['26330'] = [39.28879, -80.221567];
Zipcodes['26335'] = [38.843539, -80.684125];
Zipcodes['26337'] = [39.230232, -81.160993];
Zipcodes['26338'] = [39.091209, -80.628201];
Zipcodes['26339'] = [39.422207, -80.613976];
Zipcodes['26342'] = [39.033601, -80.85483];
Zipcodes['26343'] = [38.82222, -80.402438];
Zipcodes['26346'] = [39.289995, -81.060075];
Zipcodes['26347'] = [39.263696, -80.126108];
Zipcodes['26348'] = [39.462424, -80.545248];
Zipcodes['26349'] = [39.227475, -80.110826];
Zipcodes['26351'] = [38.926246, -80.837559];
Zipcodes['26354'] = [39.354044, -80.018749];
Zipcodes['26361'] = [39.365117, -80.31774];
Zipcodes['26362'] = [39.15538, -81.054218];
Zipcodes['26366'] = [39.382655, -80.334782];
Zipcodes['26369'] = [39.335246, -80.331184];
Zipcodes['26372'] = [38.959136, -80.346572];
Zipcodes['26374'] = [39.452313, -79.875295];
Zipcodes['26376'] = [38.775766, -80.468769];
Zipcodes['26377'] = [39.496261, -80.647037];
Zipcodes['26378'] = [39.123666, -80.447515];
Zipcodes['26384'] = [38.975373, -80.721954];
Zipcodes['26385'] = [39.159526, -80.35195];
Zipcodes['26386'] = [39.40247, -80.393241];
Zipcodes['26404'] = [39.335835, -80.306514];
Zipcodes['26405'] = [39.221652, -79.899756];
Zipcodes['26408'] = [39.196594, -80.306444];
Zipcodes['26410'] = [39.404974, -79.808333];
Zipcodes['26411'] = [39.177467, -80.723034];
Zipcodes['26412'] = [38.896606, -80.583001];
Zipcodes['26415'] = [39.288298, -80.939195];
Zipcodes['26416'] = [39.157139, -80.023725];
Zipcodes['26419'] = [39.59818, -80.635399];
Zipcodes['26421'] = [39.181913, -80.924246];
Zipcodes['26422'] = [39.297983, -80.433226];
Zipcodes['26424'] = [39.281141, -80.172683];
Zipcodes['26425'] = [39.299902, -79.691594];
Zipcodes['26426'] = [39.287048, -80.564406];
Zipcodes['26430'] = [38.895493, -80.758903];
Zipcodes['26431'] = [39.391461, -80.280868];
Zipcodes['26435'] = [39.267149, -80.085325];
Zipcodes['26436'] = [39.297278, -80.719427];
Zipcodes['26437'] = [39.525975, -80.50893];
Zipcodes['26438'] = [39.343177, -80.316362];
Zipcodes['26440'] = [39.321682, -79.918718];
Zipcodes['26443'] = [39.075943, -80.75316];
Zipcodes['26444'] = [39.375298, -79.770038];
Zipcodes['26447'] = [38.911775, -80.479076];
Zipcodes['26448'] = [39.403007, -80.491296];
Zipcodes['26451'] = [39.204406, -80.403427];
Zipcodes['26452'] = [39.037172, -80.5282];
Zipcodes['26456'] = [39.26294, -80.774047];
Zipcodes['26463'] = [39.437444, -80.346081];
Zipcodes['26501'] = [39.634091, -80.036839];
Zipcodes['26505'] = [39.653049, -79.945502];
Zipcodes['26506'] = [39.646781, -79.970152];
Zipcodes['26508'] = [39.601132, -79.902507];
Zipcodes['26519'] = [39.569166, -79.627057];
Zipcodes['26520'] = [39.501994, -79.824305];
Zipcodes['26521'] = [39.713283, -80.228143];
Zipcodes['26524'] = [39.540768, -79.807202];
Zipcodes['26525'] = [39.654026, -79.62581];
Zipcodes['26534'] = [39.648779, -79.999178];
Zipcodes['26537'] = [39.488133, -79.709884];
Zipcodes['26541'] = [39.695015, -80.125243];
Zipcodes['26542'] = [39.574651, -79.782809];
Zipcodes['26543'] = [39.664624, -79.999859];
Zipcodes['26544'] = [39.711623, -80.170951];
Zipcodes['26546'] = [39.662312, -80.023922];
Zipcodes['26547'] = [39.510714, -79.805798];
Zipcodes['26554'] = [39.465392, -80.109845];
Zipcodes['26559'] = [39.514926, -80.176661];
Zipcodes['26560'] = [39.540869, -80.143768];
Zipcodes['26562'] = [39.642435, -80.44378];
Zipcodes['26563'] = [39.481033, -80.273771];
Zipcodes['26568'] = [39.422153, -80.27807];
Zipcodes['26570'] = [39.636149, -80.243256];
Zipcodes['26571'] = [39.526563, -80.262027];
Zipcodes['26572'] = [39.486458, -80.316163];
Zipcodes['26574'] = [39.560818, -80.177899];
Zipcodes['26575'] = [39.692992, -80.424459];
Zipcodes['26576'] = [39.492501, -80.254748];
Zipcodes['26581'] = [39.670284, -80.574729];
Zipcodes['26582'] = [39.528793, -80.380088];
Zipcodes['26585'] = [39.600778, -80.435913];
Zipcodes['26586'] = [39.526547, -80.109518];
Zipcodes['26587'] = [39.516659, -80.299402];
Zipcodes['26588'] = [39.578871, -80.143989];
Zipcodes['26590'] = [39.669082, -80.312306];
Zipcodes['26591'] = [39.451607, -80.296284];
Zipcodes['26601'] = [38.617423, -80.622737];
Zipcodes['26610'] = [38.467568, -80.705302];
Zipcodes['26611'] = [38.853471, -80.823404];
Zipcodes['26615'] = [38.826274, -80.734588];
Zipcodes['26617'] = [38.515558, -80.840058];
Zipcodes['26619'] = [38.751656, -80.705437];
Zipcodes['26621'] = [38.690412, -80.506278];
Zipcodes['26623'] = [38.655505, -80.871607];
Zipcodes['26624'] = [38.720715, -80.819464];
Zipcodes['26627'] = [38.752473, -80.585064];
Zipcodes['26629'] = [38.56367, -80.704268];
Zipcodes['26631'] = [38.807313, -80.567864];
Zipcodes['26636'] = [38.743911, -80.95825];
Zipcodes['26638'] = [38.768006, -80.999466];
Zipcodes['26651'] = [38.265344, -80.993515];
Zipcodes['26656'] = [38.25017, -81.176887];
Zipcodes['26660'] = [38.356051, -80.7051];
Zipcodes['26662'] = [38.257951, -80.772136];
Zipcodes['26667'] = [38.244547, -80.999505];
Zipcodes['26671'] = [38.334344, -80.887038];
Zipcodes['26676'] = [38.160011, -80.647778];
Zipcodes['26678'] = [38.178882, -80.916062];
Zipcodes['26679'] = [38.173997, -80.805128];
Zipcodes['26680'] = [38.093333, -80.872973];
Zipcodes['26681'] = [38.233199, -80.731681];
Zipcodes['26684'] = [38.158218, -80.853278];
Zipcodes['26690'] = [38.175264, -81.089305];
Zipcodes['26691'] = [38.393664, -80.670852];
Zipcodes['26704'] = [39.286402, -78.601752];
Zipcodes['26705'] = [39.323023, -79.56777];
Zipcodes['26707'] = [39.2088, -79.402816];
Zipcodes['26710'] = [39.301755, -78.967602];
Zipcodes['26711'] = [39.322255, -78.450785];
Zipcodes['26714'] = [39.178491, -78.645187];
Zipcodes['26716'] = [39.25274, -79.529492];
Zipcodes['26717'] = [39.334155, -79.182508];
Zipcodes['26719'] = [39.497679, -78.767448];
Zipcodes['26720'] = [39.277648, -79.331243];
Zipcodes['26722'] = [39.496906, -78.630427];
Zipcodes['26726'] = [39.402846, -78.985446];
Zipcodes['26731'] = [39.149865, -79.064169];
Zipcodes['26739'] = [39.214649, -79.260285];
Zipcodes['26743'] = [39.293949, -79.075293];
Zipcodes['26750'] = [39.477082, -79.045448];
Zipcodes['26753'] = [39.561351, -78.767849];
Zipcodes['26755'] = [39.170356, -78.744478];
Zipcodes['26757'] = [39.320663, -78.767933];
Zipcodes['26761'] = [39.271397, -78.695001];
Zipcodes['26763'] = [39.473037, -78.699175];
Zipcodes['26764'] = [39.446512, -79.563923];
Zipcodes['26767'] = [39.615876, -78.756699];
Zipcodes['26801'] = [39.059339, -78.794453];
Zipcodes['26802'] = [38.65051, -79.191452];
Zipcodes['26804'] = [38.68187, -79.530284];
Zipcodes['26807'] = [38.638573, -79.354653];
Zipcodes['26808'] = [39.153475, -78.469265];
Zipcodes['26810'] = [38.99506, -78.754392];
Zipcodes['26812'] = [38.885776, -78.878013];
Zipcodes['26814'] = [38.781086, -79.475798];
Zipcodes['26815'] = [38.493516, -79.364475];
Zipcodes['26817'] = [39.373773, -78.401579];
Zipcodes['26818'] = [39.087625, -79.016499];
Zipcodes['26823'] = [39.128133, -78.495221];
Zipcodes['26833'] = [39.099326, -79.201694];
Zipcodes['26836'] = [38.99824, -78.975114];
Zipcodes['26838'] = [38.808793, -79.058414];
Zipcodes['26845'] = [39.164519, -78.964204];
Zipcodes['26847'] = [38.955186, -79.150889];
Zipcodes['26851'] = [39.047109, -78.611022];
Zipcodes['26852'] = [39.245363, -78.910599];
Zipcodes['26855'] = [38.975009, -79.220717];
Zipcodes['26865'] = [39.205938, -78.512135];
Zipcodes['26866'] = [38.80407, -79.24863];
Zipcodes['26884'] = [38.846405, -79.385291];
Zipcodes['26886'] = [38.904902, -79.395514];
Zipcodes['27006'] = [35.939004, -80.440264];
Zipcodes['27007'] = [36.38972, -80.588467];
Zipcodes['27009'] = [36.230922, -80.078651];
Zipcodes['27011'] = [36.220692, -80.692638];
Zipcodes['27012'] = [36.013155, -80.377628];
Zipcodes['27013'] = [35.750492, -80.700075];
Zipcodes['27014'] = [35.810049, -80.555744];
Zipcodes['27016'] = [36.447462, -80.218816];
Zipcodes['27017'] = [36.382016, -80.756218];
Zipcodes['27018'] = [36.197203, -80.518144];
Zipcodes['27019'] = [36.286886, -80.237725];
Zipcodes['27020'] = [36.112663, -80.811277];
Zipcodes['27021'] = [36.320319, -80.344721];
Zipcodes['27022'] = [36.512166, -80.215604];
Zipcodes['27023'] = [36.093825, -80.450175];
Zipcodes['27024'] = [36.519237, -80.841179];
Zipcodes['27025'] = [36.377985, -79.968222];
Zipcodes['27027'] = [36.441759, -79.988878];
Zipcodes['27028'] = [35.926999, -80.571086];
Zipcodes['27030'] = [36.489814, -80.629214];
Zipcodes['27040'] = [36.169312, -80.393045];
Zipcodes['27041'] = [36.415263, -80.484587];
Zipcodes['27042'] = [36.352819, -80.064203];
Zipcodes['27043'] = [36.338509, -80.448522];
Zipcodes['27045'] = [36.234398, -80.29607];
Zipcodes['27046'] = [36.502219, -80.078264];
Zipcodes['27047'] = [36.314134, -80.569112];
Zipcodes['27048'] = [36.482514, -79.917678];
Zipcodes['27050'] = [36.226442, -80.409707];
Zipcodes['27051'] = [36.195944, -80.153403];
Zipcodes['27052'] = [36.320755, -80.150453];
Zipcodes['27053'] = [36.475813, -80.3618];
Zipcodes['27054'] = [35.79254, -80.597888];
Zipcodes['27055'] = [36.113907, -80.631194];
Zipcodes['27101'] = [36.11085, -80.20043];
Zipcodes['27103'] = [36.05823, -80.321496];
Zipcodes['27104'] = [36.090721, -80.331545];
Zipcodes['27105'] = [36.161886, -80.23431];
Zipcodes['27106'] = [36.14391, -80.323157];
Zipcodes['27107'] = [36.013158, -80.175491];
Zipcodes['27109'] = [36.133731, -80.275408];
Zipcodes['27110'] = [36.089246, -80.224832];
Zipcodes['27127'] = [36.020708, -80.279817];
Zipcodes['27201'] = [36.034172, -79.484829];
Zipcodes['27202'] = [36.18558, -79.506028];
Zipcodes['27203'] = [35.728462, -79.78653];
Zipcodes['27205'] = [35.638259, -79.850866];
Zipcodes['27207'] = [35.607613, -79.390517];
Zipcodes['27208'] = [35.561896, -79.542943];
Zipcodes['27209'] = [35.342999, -79.753291];
Zipcodes['27212'] = [36.480967, -79.279856];
Zipcodes['27213'] = [35.649255, -79.40828];
Zipcodes['27214'] = [36.206055, -79.671126];
Zipcodes['27215'] = [36.019137, -79.5137];
Zipcodes['27217'] = [36.190945, -79.380185];
Zipcodes['27229'] = [35.239746, -79.791592];
Zipcodes['27231'] = [36.195182, -79.174669];
Zipcodes['27233'] = [35.892512, -79.70498];
Zipcodes['27235'] = [36.100372, -80.007443];
Zipcodes['27239'] = [35.601229, -80.09028];
Zipcodes['27242'] = [35.329657, -79.648955];
Zipcodes['27243'] = [36.060588, -79.201831];
Zipcodes['27244'] = [36.215823, -79.4866];
Zipcodes['27247'] = [35.460914, -79.787864];
Zipcodes['27248'] = [35.783296, -79.716331];
Zipcodes['27249'] = [36.162677, -79.582432];
Zipcodes['27252'] = [35.560325, -79.354984];
Zipcodes['27253'] = [35.966166, -79.347361];
Zipcodes['27256'] = [35.560484, -79.27462];
Zipcodes['27258'] = [36.083422, -79.351288];
Zipcodes['27259'] = [35.491435, -79.526237];
Zipcodes['27260'] = [35.954222, -79.987055];
Zipcodes['27262'] = [35.96313, -80.041667];
Zipcodes['27263'] = [35.911554, -79.936803];
Zipcodes['27265'] = [36.009229, -80.032388];
Zipcodes['27268'] = [35.971399, -79.994696];
Zipcodes['27278'] = [36.090195, -79.086929];
Zipcodes['27281'] = [35.187442, -79.625115];
Zipcodes['27282'] = [35.996346, -79.931842];
Zipcodes['27283'] = [35.951474, -79.635418];
Zipcodes['27284'] = [36.116121, -80.081865];
Zipcodes['27288'] = [36.497645, -79.750015];
Zipcodes['27291'] = [36.418316, -79.168428];
Zipcodes['27292'] = [35.738325, -80.207547];
Zipcodes['27295'] = [35.867644, -80.317083];
Zipcodes['27298'] = [35.885115, -79.567545];
Zipcodes['27299'] = [35.753068, -80.377451];
Zipcodes['27301'] = [36.11351, -79.665656];
Zipcodes['27302'] = [36.135763, -79.263348];
Zipcodes['27305'] = [36.51577, -79.216717];
Zipcodes['27306'] = [35.225229, -79.980186];
Zipcodes['27310'] = [36.174072, -79.992233];
Zipcodes['27311'] = [36.479651, -79.472563];
Zipcodes['27312'] = [35.756405, -79.209789];
Zipcodes['27313'] = [35.931448, -79.758055];
Zipcodes['27314'] = [36.31036, -79.19625];
Zipcodes['27315'] = [36.510175, -79.403503];
Zipcodes['27316'] = [35.697013, -79.625793];
Zipcodes['27317'] = [35.840737, -79.802499];
Zipcodes['27320'] = [36.341488, -79.658824];
Zipcodes['27325'] = [35.463598, -79.566313];
Zipcodes['27326'] = [36.464116, -79.556845];
Zipcodes['27330'] = [35.517067, -79.18321];
Zipcodes['27332'] = [35.372219, -79.148794];
Zipcodes['27340'] = [35.945481, -79.310108];
Zipcodes['27341'] = [35.523759, -79.695909];
Zipcodes['27342'] = [36.06636, -79.618597];
Zipcodes['27343'] = [36.504089, -79.075873];
Zipcodes['27344'] = [35.722316, -79.433692];
Zipcodes['27349'] = [35.895086, -79.418086];
Zipcodes['27350'] = [35.808253, -79.888213];
Zipcodes['27351'] = [35.659775, -80.267892];
Zipcodes['27355'] = [35.786858, -79.567836];
Zipcodes['27356'] = [35.436865, -79.804272];
Zipcodes['27357'] = [36.2666, -79.970625];
Zipcodes['27358'] = [36.228232, -79.880061];
Zipcodes['27360'] = [35.86058, -80.099288];
Zipcodes['27370'] = [35.809298, -79.97798];
Zipcodes['27371'] = [35.40575, -79.948344];
Zipcodes['27374'] = [35.91758, -80.257749];
Zipcodes['27376'] = [35.261099, -79.521811];
Zipcodes['27377'] = [36.038036, -79.599395];
Zipcodes['27379'] = [36.361127, -79.347762];
Zipcodes['27401'] = [36.070822, -79.767692];
Zipcodes['27403'] = [36.064078, -79.825413];
Zipcodes['27405'] = [36.115379, -79.734641];
Zipcodes['27406'] = [35.99795, -79.766734];
Zipcodes['27407'] = [36.013367, -79.871391];
Zipcodes['27408'] = [36.103667, -79.813712];
Zipcodes['27409'] = [36.071751, -79.964858];
Zipcodes['27410'] = [36.118615, -79.894851];
Zipcodes['27411'] = [36.076615, -79.771513];
Zipcodes['27412'] = [36.068277, -79.810061];
Zipcodes['27455'] = [36.187426, -79.81744];
Zipcodes['27501'] = [35.484941, -78.68457];
Zipcodes['27502'] = [35.719615, -78.907172];
Zipcodes['27503'] = [36.152977, -78.884452];
Zipcodes['27504'] = [35.400317, -78.518271];
Zipcodes['27505'] = [35.406599, -79.004422];
Zipcodes['27506'] = [35.408583, -78.739274];
Zipcodes['27507'] = [36.513808, -78.552466];
Zipcodes['27508'] = [35.959012, -78.25091];
Zipcodes['27509'] = [36.131468, -78.773709];
Zipcodes['27510'] = [35.909835, -79.081253];
Zipcodes['27511'] = [35.763313, -78.787832];
Zipcodes['27513'] = [35.80198, -78.802573];
Zipcodes['27514'] = [35.966756, -79.052136];
Zipcodes['27516'] = [35.906197, -79.154855];
Zipcodes['27517'] = [35.842663, -79.032015];
Zipcodes['27518'] = [35.731139, -78.773206];
Zipcodes['27519'] = [35.808392, -78.886546];
Zipcodes['27520'] = [35.611762, -78.47639];
Zipcodes['27521'] = [35.412121, -78.655865];
Zipcodes['27522'] = [36.105618, -78.675202];
Zipcodes['27523'] = [35.773618, -78.956926];
Zipcodes['27524'] = [35.391825, -78.378763];
Zipcodes['27525'] = [36.146064, -78.474143];
Zipcodes['27526'] = [35.543991, -78.829796];
Zipcodes['27527'] = [35.653948, -78.381715];
Zipcodes['27529'] = [35.64118, -78.584349];
Zipcodes['27530'] = [35.382697, -78.061142];
Zipcodes['27531'] = [35.343617, -77.967369];
Zipcodes['27533'] = [35.38746, -78.038964];
Zipcodes['27534'] = [35.369273, -77.886872];
Zipcodes['27536'] = [36.325908, -78.406772];
Zipcodes['27537'] = [36.358726, -78.398384];
Zipcodes['27539'] = [35.676533, -78.813447];
Zipcodes['27540'] = [35.620169, -78.865047];
Zipcodes['27541'] = [36.264862, -79.083217];
Zipcodes['27542'] = [35.618293, -78.136838];
Zipcodes['27544'] = [36.191235, -78.440476];
Zipcodes['27545'] = [35.783679, -78.483355];
Zipcodes['27546'] = [35.385317, -78.862107];
Zipcodes['27549'] = [36.099641, -78.251246];
Zipcodes['27551'] = [36.432843, -78.05017];
Zipcodes['27553'] = [36.486498, -78.302798];
Zipcodes['27555'] = [35.562379, -78.203517];
Zipcodes['27556'] = [36.396988, -78.309433];
Zipcodes['27557'] = [35.779135, -78.198512];
Zipcodes['27559'] = [35.628213, -79.102818];
Zipcodes['27560'] = [35.8576, -78.827114];
Zipcodes['27562'] = [35.641978, -78.975581];
Zipcodes['27563'] = [36.421449, -78.221394];
Zipcodes['27565'] = [36.361651, -78.648952];
Zipcodes['27568'] = [35.511274, -78.24291];
Zipcodes['27569'] = [35.429475, -78.172122];
Zipcodes['27570'] = [36.438631, -78.236608];
Zipcodes['27571'] = [35.9246, -78.461452];
Zipcodes['27572'] = [36.255106, -78.877494];
Zipcodes['27573'] = [36.399017, -78.97513];
Zipcodes['27574'] = [36.475265, -78.842101];
Zipcodes['27576'] = [35.577524, -78.261311];
Zipcodes['27577'] = [35.491935, -78.344614];
Zipcodes['27581'] = [36.203077, -78.736103];
Zipcodes['27582'] = [36.447352, -78.569089];
Zipcodes['27583'] = [36.309693, -78.930583];
Zipcodes['27584'] = [36.493383, -78.445998];
Zipcodes['27587'] = [35.980838, -78.55105];
Zipcodes['27589'] = [36.322541, -78.133484];
Zipcodes['27591'] = [35.78417, -78.388984];
Zipcodes['27592'] = [35.55738, -78.67227];
Zipcodes['27593'] = [35.585803, -78.362338];
Zipcodes['27594'] = [36.472959, -78.166215];
Zipcodes['27596'] = [35.99965, -78.434962];
Zipcodes['27597'] = [35.842593, -78.301044];
Zipcodes['27599'] = [35.904045, -79.052134];
Zipcodes['27601'] = [35.773661, -78.634563];
Zipcodes['27603'] = [35.664796, -78.653677];
Zipcodes['27604'] = [35.822932, -78.562057];
Zipcodes['27605'] = [35.790408, -78.654564];
Zipcodes['27606'] = [35.742323, -78.715478];
Zipcodes['27607'] = [35.817784, -78.727514];
Zipcodes['27608'] = [35.8103, -78.648658];
Zipcodes['27609'] = [35.843765, -78.633643];
Zipcodes['27610'] = [35.7444, -78.546278];
Zipcodes['27612'] = [35.850277, -78.703864];
Zipcodes['27613'] = [35.926102, -78.711414];
Zipcodes['27614'] = [35.94593, -78.619494];
Zipcodes['27615'] = [35.898462, -78.635558];
Zipcodes['27616'] = [35.866514, -78.541313];
Zipcodes['27617'] = [35.908845, -78.772396];
Zipcodes['27695'] = [35.785489, -78.67893];
Zipcodes['27697'] = [35.778803, -78.622967];
Zipcodes['27701'] = [35.99924, -78.897944];
Zipcodes['27703'] = [35.961286, -78.805228];
Zipcodes['27704'] = [36.042446, -78.828771];
Zipcodes['27705'] = [36.025112, -78.982067];
Zipcodes['27707'] = [35.954525, -78.953317];
Zipcodes['27708'] = [36.0001, -78.943102];
Zipcodes['27709'] = [35.920066, -78.867067];
Zipcodes['27712'] = [36.095484, -78.904075];
Zipcodes['27713'] = [35.883522, -78.935541];
Zipcodes['27801'] = [35.913446, -77.725699];
Zipcodes['27803'] = [35.891404, -77.84949];
Zipcodes['27804'] = [35.989169, -77.842709];
Zipcodes['27805'] = [36.208571, -77.088582];
Zipcodes['27806'] = [35.304999, -76.756067];
Zipcodes['27807'] = [35.811382, -78.085982];
Zipcodes['27808'] = [35.467089, -76.759752];
Zipcodes['27809'] = [36.016777, -77.74162];
Zipcodes['27810'] = [35.549649, -76.512464];
Zipcodes['27812'] = [35.805032, -77.376162];
Zipcodes['27813'] = [35.634739, -77.937516];
Zipcodes['27814'] = [35.380118, -76.932428];
Zipcodes['27815'] = [35.924689, -77.693027];
Zipcodes['27816'] = [36.108768, -78.072959];
Zipcodes['27817'] = [35.451821, -77.081218];
Zipcodes['27818'] = [36.499725, -77.018505];
Zipcodes['27819'] = [35.814154, -77.457328];
Zipcodes['27820'] = [36.421515, -77.241567];
Zipcodes['27821'] = [35.322066, -76.874087];
Zipcodes['27822'] = [35.807012, -77.840457];
Zipcodes['27823'] = [36.209653, -77.713733];
Zipcodes['27824'] = [35.551546, -76.005215];
Zipcodes['27825'] = [35.835279, -77.171481];
Zipcodes['27826'] = [35.59534, -76.243592];
Zipcodes['27827'] = [35.698211, -77.512233];
Zipcodes['27828'] = [35.585151, -77.577415];
Zipcodes['27829'] = [35.699996, -77.605652];
Zipcodes['27830'] = [35.564168, -77.950309];
Zipcodes['27831'] = [36.463087, -77.570553];
Zipcodes['27832'] = [36.519809, -77.740128];
Zipcodes['27834'] = [35.65677, -77.379527];
Zipcodes['27837'] = [35.513865, -77.2046];
Zipcodes['27839'] = [36.284912, -77.55688];
Zipcodes['27840'] = [35.929708, -77.238886];
Zipcodes['27841'] = [35.908579, -77.27636];
Zipcodes['27842'] = [36.526749, -77.851988];
Zipcodes['27843'] = [35.995534, -77.400001];
Zipcodes['27844'] = [36.242713, -77.946425];
Zipcodes['27845'] = [36.371267, -77.43999];
Zipcodes['27846'] = [35.758568, -76.897527];
Zipcodes['27847'] = [36.178202, -77.201851];
Zipcodes['27849'] = [36.077306, -77.215025];
Zipcodes['27850'] = [36.412391, -77.880191];
Zipcodes['27851'] = [35.640083, -78.030678];
Zipcodes['27852'] = [35.750459, -77.636931];
Zipcodes['27853'] = [36.515237, -77.307178];
Zipcodes['27855'] = [36.415557, -77.069339];
Zipcodes['27856'] = [36.01368, -77.980574];
Zipcodes['27857'] = [35.970826, -77.261068];
Zipcodes['27858'] = [35.532451, -77.282787];
Zipcodes['27860'] = [35.664163, -76.635302];
Zipcodes['27861'] = [35.817205, -77.319014];
Zipcodes['27862'] = [36.490175, -77.188291];
Zipcodes['27863'] = [35.488165, -77.971796];
Zipcodes['27864'] = [35.801141, -77.649403];
Zipcodes['27865'] = [35.664024, -76.899975];
Zipcodes['27866'] = [36.519869, -77.514565];
Zipcodes['27869'] = [36.267105, -77.313433];
Zipcodes['27870'] = [36.425216, -77.695139];
Zipcodes['27871'] = [35.802069, -77.260565];
Zipcodes['27872'] = [36.200954, -77.246027];
Zipcodes['27873'] = [35.653316, -77.776044];
Zipcodes['27874'] = [36.129011, -77.410692];
Zipcodes['27875'] = [35.455706, -76.504667];
Zipcodes['27876'] = [36.474414, -77.405107];
Zipcodes['27877'] = [36.51881, -77.192219];
Zipcodes['27878'] = [35.866118, -77.830602];
Zipcodes['27879'] = [35.574488, -77.279651];
Zipcodes['27880'] = [35.730736, -78.080589];
Zipcodes['27881'] = [35.97022, -77.442266];
Zipcodes['27882'] = [35.942383, -78.118404];
Zipcodes['27883'] = [35.592351, -77.804401];
Zipcodes['27884'] = [35.70352, -77.266635];
Zipcodes['27885'] = [35.420015, -76.247278];
Zipcodes['27886'] = [35.904662, -77.511738];
Zipcodes['27888'] = [35.595826, -77.702269];
Zipcodes['27889'] = [35.580432, -77.025019];
Zipcodes['27890'] = [36.41418, -77.587745];
Zipcodes['27891'] = [36.097735, -77.742789];
Zipcodes['27892'] = [35.819171, -77.063472];
Zipcodes['27893'] = [35.693766, -77.904137];
Zipcodes['27896'] = [35.791, -77.97639];
Zipcodes['27897'] = [36.324597, -77.20968];
Zipcodes['27909'] = [36.288605, -76.268734];
Zipcodes['27910'] = [36.281756, -76.993403];
Zipcodes['27915'] = [35.336654, -75.512063];
Zipcodes['27916'] = [36.329315, -75.920051];
Zipcodes['27917'] = [36.355136, -75.997463];
Zipcodes['27919'] = [36.308171, -76.504283];
Zipcodes['27920'] = [35.253294, -75.538313];
Zipcodes['27921'] = [36.3298, -76.147832];
Zipcodes['27922'] = [36.316755, -76.84221];
Zipcodes['27923'] = [36.368236, -75.930826];
Zipcodes['27924'] = [36.168447, -76.832521];
Zipcodes['27925'] = [35.841184, -76.173324];
Zipcodes['27926'] = [36.505115, -76.604925];
Zipcodes['27927'] = [36.331206, -75.851203];
Zipcodes['27928'] = [35.808824, -76.437748];
Zipcodes['27929'] = [36.439195, -76.016487];
Zipcodes['27932'] = [36.09419, -76.588045];
Zipcodes['27935'] = [36.431079, -76.868092];
Zipcodes['27936'] = [35.247856, -75.59873];
Zipcodes['27937'] = [36.512279, -76.773947];
Zipcodes['27938'] = [36.384296, -76.716];
Zipcodes['27939'] = [36.235206, -75.879817];
Zipcodes['27941'] = [36.092315, -75.822829];
Zipcodes['27942'] = [36.297139, -76.750854];
Zipcodes['27943'] = [35.207826, -75.717187];
Zipcodes['27944'] = [36.180897, -76.403244];
Zipcodes['27946'] = [36.364231, -76.590231];
Zipcodes['27947'] = [36.167137, -75.853521];
Zipcodes['27948'] = [36.019229, -75.692172];
Zipcodes['27949'] = [36.109859, -75.745433];
Zipcodes['27950'] = [36.500551, -75.973664];
Zipcodes['27953'] = [35.803985, -75.875404];
Zipcodes['27954'] = [35.91271, -75.685335];
Zipcodes['27956'] = [36.399985, -76.001115];
Zipcodes['27957'] = [36.066297, -76.759059];
Zipcodes['27958'] = [36.501198, -76.17333];
Zipcodes['27959'] = [35.891936, -75.603436];
Zipcodes['27960'] = [35.073231, -75.998527];
Zipcodes['27962'] = [35.762002, -76.776452];
Zipcodes['27964'] = [36.127053, -75.804257];
Zipcodes['27965'] = [36.292401, -75.932948];
Zipcodes['27966'] = [36.125626, -75.848248];
Zipcodes['27967'] = [36.222319, -76.908397];
Zipcodes['27968'] = [35.658631, -75.489189];
Zipcodes['27969'] = [36.463307, -76.802587];
Zipcodes['27970'] = [35.908747, -76.620202];
Zipcodes['27972'] = [35.552458, -75.471299];
Zipcodes['27973'] = [36.371209, -76.055514];
Zipcodes['27974'] = [36.243057, -75.989559];
Zipcodes['27976'] = [36.47861, -76.336271];
Zipcodes['27978'] = [35.672202, -75.773128];
Zipcodes['27979'] = [36.436768, -76.560679];
Zipcodes['27980'] = [36.245324, -76.619385];
Zipcodes['27981'] = [35.852855, -75.637424];
Zipcodes['27982'] = [35.5659, -75.465481];
Zipcodes['27983'] = [35.96203, -76.935766];
Zipcodes['27985'] = [36.218808, -76.473719];
Zipcodes['27986'] = [36.388979, -76.918295];
Zipcodes['28001'] = [35.338552, -80.208179];
Zipcodes['28006'] = [35.410436, -81.092004];
Zipcodes['28007'] = [35.121694, -80.130521];
Zipcodes['28009'] = [35.407846, -80.107195];
Zipcodes['28012'] = [35.211504, -81.037865];
Zipcodes['28016'] = [35.314932, -81.283292];
Zipcodes['28017'] = [35.247082, -81.670682];
Zipcodes['28018'] = [35.48898, -81.803385];
Zipcodes['28019'] = [35.283896, -81.793877];
Zipcodes['28020'] = [35.524091, -81.643245];
Zipcodes['28021'] = [35.400774, -81.403346];
Zipcodes['28023'] = [35.568585, -80.602542];
Zipcodes['28024'] = [35.24331, -81.770575];
Zipcodes['28025'] = [35.382565, -80.523443];
Zipcodes['28027'] = [35.413317, -80.67547];
Zipcodes['28031'] = [35.475193, -80.893685];
Zipcodes['28032'] = [35.233033, -81.079513];
Zipcodes['28033'] = [35.419107, -81.328427];
Zipcodes['28034'] = [35.353664, -81.178943];
Zipcodes['28036'] = [35.482087, -80.793351];
Zipcodes['28037'] = [35.495486, -81.018484];
Zipcodes['28039'] = [35.680389, -80.436238];
Zipcodes['28040'] = [35.389977, -81.752184];
Zipcodes['28041'] = [35.587422, -80.462535];
Zipcodes['28042'] = [35.422984, -81.507477];
Zipcodes['28043'] = [35.312387, -81.870315];
Zipcodes['28052'] = [35.227964, -81.231691];
Zipcodes['28054'] = [35.261363, -81.15015];
Zipcodes['28056'] = [35.216936, -81.125003];
Zipcodes['28071'] = [35.517515, -80.315893];
Zipcodes['28072'] = [35.613219, -80.442328];
Zipcodes['28073'] = [35.198574, -81.483785];
Zipcodes['28074'] = [35.239358, -81.878193];
Zipcodes['28075'] = [35.30191, -80.639269];
Zipcodes['28076'] = [35.256446, -81.77952];
Zipcodes['28077'] = [35.404802, -81.20277];
Zipcodes['28078'] = [35.404779, -80.866541];
Zipcodes['28079'] = [35.114666, -80.600597];
Zipcodes['28080'] = [35.456746, -81.105685];
Zipcodes['28081'] = [35.502816, -80.670173];
Zipcodes['28083'] = [35.491833, -80.582207];
Zipcodes['28086'] = [35.244164, -81.377912];
Zipcodes['28088'] = [35.544382, -80.61463];
Zipcodes['28089'] = [35.320272, -81.66069];
Zipcodes['28090'] = [35.454659, -81.559555];
Zipcodes['28091'] = [34.987392, -79.933002];
Zipcodes['28092'] = [35.483239, -81.253054];
Zipcodes['28097'] = [35.296733, -80.39332];
Zipcodes['28098'] = [35.271135, -81.100787];
Zipcodes['28101'] = [35.255802, -81.075416];
Zipcodes['28102'] = [34.814818, -79.976632];
Zipcodes['28103'] = [34.998974, -80.351455];
Zipcodes['28104'] = [35.057978, -80.697771];
Zipcodes['28105'] = [35.119788, -80.709693];
Zipcodes['28107'] = [35.252973, -80.51803];
Zipcodes['28108'] = [34.933185, -80.679471];
Zipcodes['28109'] = [35.48289, -80.279892];
Zipcodes['28110'] = [35.066044, -80.509089];
Zipcodes['28112'] = [34.889925, -80.549548];
Zipcodes['28114'] = [35.230441, -81.747702];
Zipcodes['28115'] = [35.575398, -80.774914];
Zipcodes['28117'] = [35.574965, -80.894155];
Zipcodes['28119'] = [34.847539, -80.013943];
Zipcodes['28120'] = [35.328586, -81.026424];
Zipcodes['28124'] = [35.400074, -80.408289];
Zipcodes['28125'] = [35.659025, -80.698613];
Zipcodes['28127'] = [35.450168, -80.167234];
Zipcodes['28128'] = [35.210699, -80.154524];
Zipcodes['28129'] = [35.232252, -80.327397];
Zipcodes['28133'] = [34.844926, -80.265681];
Zipcodes['28134'] = [35.084481, -80.891536];
Zipcodes['28135'] = [35.02977, -80.217264];
Zipcodes['28136'] = [35.420501, -81.642411];
Zipcodes['28137'] = [35.490898, -80.263638];
Zipcodes['28138'] = [35.514441, -80.437354];
Zipcodes['28139'] = [35.348363, -81.994737];
Zipcodes['28144'] = [35.715746, -80.458946];
Zipcodes['28146'] = [35.621144, -80.395596];
Zipcodes['28147'] = [35.681053, -80.562965];
Zipcodes['28150'] = [35.342703, -81.576248];
Zipcodes['28152'] = [35.240769, -81.591391];
Zipcodes['28159'] = [35.694166, -80.435209];
Zipcodes['28160'] = [35.360671, -81.922286];
Zipcodes['28163'] = [35.20702, -80.428498];
Zipcodes['28164'] = [35.389444, -81.037135];
Zipcodes['28166'] = [35.677222, -80.867951];
Zipcodes['28167'] = [35.506425, -81.959442];
Zipcodes['28168'] = [35.55236, -81.425071];
Zipcodes['28169'] = [35.358117, -81.430602];
Zipcodes['28170'] = [34.998714, -80.098784];
Zipcodes['28173'] = [34.919177, -80.730565];
Zipcodes['28174'] = [34.969035, -80.43958];
Zipcodes['28202'] = [35.227271, -80.844194];
Zipcodes['28203'] = [35.209006, -80.857571];
Zipcodes['28204'] = [35.2146, -80.826754];
Zipcodes['28205'] = [35.218774, -80.789616];
Zipcodes['28206'] = [35.255423, -80.821516];
Zipcodes['28207'] = [35.194148, -80.824585];
Zipcodes['28208'] = [35.232005, -80.904989];
Zipcodes['28209'] = [35.178714, -80.853985];
Zipcodes['28210'] = [35.130509, -80.856685];
Zipcodes['28211'] = [35.166342, -80.797285];
Zipcodes['28212'] = [35.187703, -80.744556];
Zipcodes['28213'] = [35.286229, -80.735231];
Zipcodes['28214'] = [35.272034, -80.968109];
Zipcodes['28215'] = [35.24557, -80.69893];
Zipcodes['28216'] = [35.31019, -80.889636];
Zipcodes['28217'] = [35.168745, -80.908364];
Zipcodes['28223'] = [35.308875, -80.732499];
Zipcodes['28226'] = [35.102697, -80.822129];
Zipcodes['28227'] = [35.189567, -80.645408];
Zipcodes['28244'] = [35.224605, -80.843094];
Zipcodes['28262'] = [35.322061, -80.739972];
Zipcodes['28269'] = [35.337313, -80.803472];
Zipcodes['28270'] = [35.112035, -80.764605];
Zipcodes['28273'] = [35.123659, -80.948455];
Zipcodes['28274'] = [35.189104, -80.83384];
Zipcodes['28277'] = [35.049856, -80.818019];
Zipcodes['28278'] = [35.133373, -81.008837];
Zipcodes['28280'] = [35.226332, -80.843091];
Zipcodes['28282'] = [35.224815, -80.845277];
Zipcodes['28301'] = [35.0748, -78.884257];
Zipcodes['28303'] = [35.085631, -78.959361];
Zipcodes['28304'] = [35.025528, -78.989604];
Zipcodes['28305'] = [35.052609, -78.907244];
Zipcodes['28306'] = [34.958495, -78.896848];
Zipcodes['28307'] = [35.139602, -78.993577];
Zipcodes['28308'] = [35.172036, -79.014963];
Zipcodes['28310'] = [35.124541, -79.04339];
Zipcodes['28311'] = [35.167769, -78.888697];
Zipcodes['28312'] = [34.947752, -78.738133];
Zipcodes['28314'] = [35.053208, -79.01547];
Zipcodes['28315'] = [35.115044, -79.448548];
Zipcodes['28318'] = [35.010768, -78.62373];
Zipcodes['28320'] = [34.558122, -78.772715];
Zipcodes['28323'] = [35.314791, -78.834376];
Zipcodes['28325'] = [35.15337, -78.10422];
Zipcodes['28326'] = [35.269044, -79.15469];
Zipcodes['28327'] = [35.358148, -79.422784];
Zipcodes['28328'] = [35.00513, -78.334419];
Zipcodes['28330'] = [34.910673, -79.823315];
Zipcodes['28331'] = [35.196432, -78.648539];
Zipcodes['28332'] = [34.655389, -78.699284];
Zipcodes['28333'] = [35.286131, -78.017373];
Zipcodes['28334'] = [35.272208, -78.568104];
Zipcodes['28337'] = [34.655856, -78.571951];
Zipcodes['28338'] = [35.098899, -79.744237];
Zipcodes['28339'] = [35.31054, -78.732747];
Zipcodes['28340'] = [34.471354, -79.134422];
Zipcodes['28341'] = [35.119876, -78.174437];
Zipcodes['28342'] = [35.18567, -78.651615];
Zipcodes['28343'] = [34.757362, -79.564875];
Zipcodes['28344'] = [35.167989, -78.622725];
Zipcodes['28345'] = [34.870288, -79.66564];
Zipcodes['28347'] = [35.052588, -79.558842];
Zipcodes['28348'] = [34.914973, -78.927021];
Zipcodes['28349'] = [34.981159, -77.927059];
Zipcodes['28350'] = [35.246712, -79.314996];
Zipcodes['28351'] = [34.848096, -79.576652];
Zipcodes['28352'] = [34.768395, -79.453559];
Zipcodes['28355'] = [35.390535, -79.19515];
Zipcodes['28356'] = [35.23702, -78.790396];
Zipcodes['28357'] = [34.902094, -79.083013];
Zipcodes['28358'] = [34.607857, -78.934772];
Zipcodes['28359'] = [34.58685, -79.054744];
Zipcodes['28360'] = [34.670328, -79.07494];
Zipcodes['28362'] = [34.369609, -79.128839];
Zipcodes['28363'] = [34.948668, -79.529934];
Zipcodes['28364'] = [34.707928, -79.322676];
Zipcodes['28365'] = [35.17233, -78.052287];
Zipcodes['28366'] = [35.230228, -78.362014];
Zipcodes['28367'] = [35.169804, -79.723229];
Zipcodes['28368'] = [35.369444, -79.113313];
Zipcodes['28369'] = [34.423373, -79.029416];
Zipcodes['28371'] = [34.91065, -78.978348];
Zipcodes['28372'] = [34.699915, -79.178622];
Zipcodes['28373'] = [35.09347, -79.485597];
Zipcodes['28374'] = [35.18928, -79.459071];
Zipcodes['28375'] = [34.477245, -79.043363];
Zipcodes['28376'] = [35.04681, -79.241396];
Zipcodes['28377'] = [34.836294, -79.210785];
Zipcodes['28378'] = [34.858772, -79.050603];
Zipcodes['28379'] = [34.929217, -79.786856];
Zipcodes['28382'] = [34.926629, -78.560381];
Zipcodes['28383'] = [34.577871, -79.26909];
Zipcodes['28384'] = [34.79156, -78.94141];
Zipcodes['28385'] = [35.048, -78.499648];
Zipcodes['28386'] = [34.862965, -79.128433];
Zipcodes['28387'] = [35.180486, -79.375695];
Zipcodes['28390'] = [35.220889, -78.953309];
Zipcodes['28391'] = [35.03417, -78.698638];
Zipcodes['28392'] = [34.734741, -78.795411];
Zipcodes['28393'] = [34.981087, -78.18948];
Zipcodes['28394'] = [35.217455, -79.241537];
Zipcodes['28395'] = [35.130166, -78.727024];
Zipcodes['28396'] = [34.955073, -79.413862];
Zipcodes['28398'] = [34.98978, -78.064173];
Zipcodes['28399'] = [34.769386, -78.719612];
Zipcodes['28401'] = [34.291985, -77.972365];
Zipcodes['28403'] = [34.221692, -77.880633];
Zipcodes['28405'] = [34.264179, -77.868315];
Zipcodes['28409'] = [34.150625, -77.86031];
Zipcodes['28411'] = [34.301829, -77.793066];
Zipcodes['28412'] = [34.136589, -77.920579];
Zipcodes['28420'] = [34.054572, -78.468698];
Zipcodes['28421'] = [34.505123, -78.167047];
Zipcodes['28422'] = [34.049233, -78.211058];
Zipcodes['28423'] = [34.270145, -78.404519];
Zipcodes['28424'] = [34.290349, -78.699676];
Zipcodes['28425'] = [34.560672, -77.900427];
Zipcodes['28428'] = [34.038615, -77.905263];
Zipcodes['28429'] = [34.341211, -77.900216];
Zipcodes['28430'] = [34.284007, -78.932772];
Zipcodes['28431'] = [34.317376, -78.844536];
Zipcodes['28432'] = [34.168033, -78.758709];
Zipcodes['28433'] = [34.488505, -78.616673];
Zipcodes['28434'] = [34.47245, -78.470809];
Zipcodes['28435'] = [34.405955, -78.109229];
Zipcodes['28436'] = [34.246636, -78.283897];
Zipcodes['28438'] = [34.420395, -78.91372];
Zipcodes['28439'] = [34.286816, -79.001382];
Zipcodes['28441'] = [34.811459, -78.427416];
Zipcodes['28442'] = [34.310175, -78.601091];
Zipcodes['28443'] = [34.481214, -77.692847];
Zipcodes['28444'] = [34.688313, -78.319957];
Zipcodes['28445'] = [34.528849, -77.535773];
Zipcodes['28447'] = [34.618651, -78.278106];
Zipcodes['28448'] = [34.518305, -78.359652];
Zipcodes['28449'] = [33.973269, -77.941888];
Zipcodes['28450'] = [34.309178, -78.496605];
Zipcodes['28451'] = [34.236096, -78.119072];
Zipcodes['28452'] = [34.005054, -78.555914];
Zipcodes['28453'] = [34.866406, -78.076448];
Zipcodes['28454'] = [34.670298, -77.653954];
Zipcodes['28455'] = [34.111657, -78.631796];
Zipcodes['28456'] = [34.374973, -78.287201];
Zipcodes['28457'] = [34.456198, -77.871525];
Zipcodes['28458'] = [34.819856, -78.081105];
Zipcodes['28460'] = [34.52332, -77.417074];
Zipcodes['28461'] = [33.966618, -78.057598];
Zipcodes['28462'] = [33.999721, -78.311045];
Zipcodes['28463'] = [34.109764, -78.776463];
Zipcodes['28464'] = [34.782225, -78.02108];
Zipcodes['28465'] = [33.912502, -78.100479];
Zipcodes['28466'] = [34.74077, -77.875702];
Zipcodes['28467'] = [33.917986, -78.592036];
Zipcodes['28468'] = [33.899652, -78.519214];
Zipcodes['28469'] = [33.93383, -78.473218];
Zipcodes['28470'] = [33.96697, -78.395923];
Zipcodes['28472'] = [34.299016, -78.691182];
Zipcodes['28478'] = [34.64284, -78.042039];
Zipcodes['28479'] = [34.100688, -78.016511];
Zipcodes['28480'] = [34.214753, -77.800506];
Zipcodes['28501'] = [35.239084, -77.527194];
Zipcodes['28504'] = [35.227366, -77.635888];
Zipcodes['28508'] = [35.099668, -77.824788];
Zipcodes['28509'] = [35.145759, -76.805927];
Zipcodes['28510'] = [34.987612, -76.804461];
Zipcodes['28511'] = [34.901116, -76.345763];
Zipcodes['28512'] = [34.701567, -76.806529];
Zipcodes['28513'] = [35.442763, -77.389638];
Zipcodes['28515'] = [35.191601, -76.689195];
Zipcodes['28516'] = [34.85641, -76.619618];
Zipcodes['28518'] = [34.894823, -77.747493];
Zipcodes['28519'] = [35.127835, -77.012553];
Zipcodes['28520'] = [34.99595, -76.466803];
Zipcodes['28521'] = [34.817902, -77.736618];
Zipcodes['28523'] = [35.208786, -77.299531];
Zipcodes['28524'] = [34.79445, -76.463848];
Zipcodes['28525'] = [35.132291, -77.686672];
Zipcodes['28526'] = [35.244705, -77.408429];
Zipcodes['28527'] = [35.258207, -77.017502];
Zipcodes['28528'] = [34.73211, -76.538617];
Zipcodes['28529'] = [35.103344, -76.878629];
Zipcodes['28530'] = [35.373976, -77.415522];
Zipcodes['28531'] = [34.698038, -76.555547];
Zipcodes['28532'] = [34.905022, -76.886672];
Zipcodes['28533'] = [34.901925, -76.900858];
Zipcodes['28537'] = [35.256616, -76.55864];
Zipcodes['28538'] = [35.416645, -77.565077];
Zipcodes['28539'] = [34.694212, -77.232818];
Zipcodes['28540'] = [34.744324, -77.499048];
Zipcodes['28542'] = [34.729932, -77.461943];
Zipcodes['28543'] = [34.734804, -77.39805];
Zipcodes['28544'] = [34.696042, -77.290709];
Zipcodes['28546'] = [34.827304, -77.391062];
Zipcodes['28547'] = [34.631368, -77.32856];
Zipcodes['28551'] = [35.321323, -77.780856];
Zipcodes['28552'] = [35.2982, -76.555928];
Zipcodes['28553'] = [34.739158, -76.510068];
Zipcodes['28554'] = [35.475702, -77.592596];
Zipcodes['28555'] = [34.867968, -77.248397];
Zipcodes['28556'] = [35.132807, -76.637805];
Zipcodes['28557'] = [34.737849, -76.750308];
Zipcodes['28560'] = [35.126695, -76.977628];
Zipcodes['28562'] = [35.080979, -77.125033];
Zipcodes['28570'] = [34.776989, -76.880505];
Zipcodes['28571'] = [35.046007, -76.703845];
Zipcodes['28572'] = [35.019918, -77.723833];
Zipcodes['28573'] = [34.979917, -77.197521];
Zipcodes['28574'] = [34.878886, -77.589666];
Zipcodes['28575'] = [34.688187, -76.880939];
Zipcodes['28577'] = [34.906777, -76.404402];
Zipcodes['28578'] = [35.196952, -77.856972];
Zipcodes['28579'] = [34.812858, -76.505058];
Zipcodes['28580'] = [35.453274, -77.679538];
Zipcodes['28581'] = [34.844258, -76.435553];
Zipcodes['28582'] = [34.748284, -77.150455];
Zipcodes['28584'] = [34.72401, -77.100543];
Zipcodes['28585'] = [35.049763, -77.41758];
Zipcodes['28586'] = [35.32415, -77.177411];
Zipcodes['28587'] = [35.186789, -76.666791];
Zipcodes['28589'] = [34.796151, -76.494019];
Zipcodes['28590'] = [35.520683, -77.416661];
Zipcodes['28594'] = [34.701105, -76.973912];
Zipcodes['28601'] = [35.772393, -81.326659];
Zipcodes['28602'] = [35.673522, -81.382627];
Zipcodes['28604'] = [36.179336, -81.850289];
Zipcodes['28605'] = [36.12962, -81.694915];
Zipcodes['28606'] = [36.051784, -81.306878];
Zipcodes['28607'] = [36.214937, -81.64675];
Zipcodes['28609'] = [35.678451, -81.057418];
Zipcodes['28610'] = [35.729217, -81.145335];
Zipcodes['28611'] = [36.00604, -81.736971];
Zipcodes['28612'] = [35.650391, -81.53756];
Zipcodes['28613'] = [35.737648, -81.207675];
Zipcodes['28615'] = [36.474894, -81.670259];
Zipcodes['28616'] = [36.021459, -81.930176];
Zipcodes['28617'] = [36.480702, -81.361979];
Zipcodes['28618'] = [36.213548, -81.516925];
Zipcodes['28619'] = [35.760135, -81.606807];
Zipcodes['28621'] = [36.275593, -80.785654];
Zipcodes['28622'] = [36.19496, -81.948911];
Zipcodes['28623'] = [36.529076, -80.973414];
Zipcodes['28624'] = [36.119696, -81.413245];
Zipcodes['28625'] = [35.869581, -80.889653];
Zipcodes['28626'] = [36.292577, -81.517632];
Zipcodes['28627'] = [36.453586, -81.014299];
Zipcodes['28628'] = [35.729174, -81.778268];
Zipcodes['28629'] = [36.347483, -81.359161];
Zipcodes['28630'] = [35.831975, -81.42017];
Zipcodes['28631'] = [36.547476, -81.410356];
Zipcodes['28634'] = [35.972783, -80.757232];
Zipcodes['28635'] = [36.332961, -81.126011];
Zipcodes['28636'] = [35.939426, -81.063433];
Zipcodes['28637'] = [35.717672, -81.419384];
Zipcodes['28638'] = [35.845093, -81.477938];
Zipcodes['28640'] = [36.404873, -81.399397];
Zipcodes['28641'] = [35.97546, -81.894868];
Zipcodes['28642'] = [36.225065, -80.815479];
Zipcodes['28643'] = [36.529059, -81.540879];
Zipcodes['28644'] = [36.426159, -81.277823];
Zipcodes['28645'] = [35.97883, -81.547779];
Zipcodes['28646'] = [36.073108, -81.846965];
Zipcodes['28649'] = [36.335979, -81.212043];
Zipcodes['28650'] = [35.571426, -81.15839];
Zipcodes['28651'] = [36.306214, -81.308783];
Zipcodes['28652'] = [36.089687, -81.986535];
Zipcodes['28653'] = [36.066028, -81.901025];
Zipcodes['28654'] = [36.058516, -81.159305];
Zipcodes['28655'] = [35.763266, -81.749862];
Zipcodes['28657'] = [36.021993, -81.942425];
Zipcodes['28658'] = [35.637616, -81.235853];
Zipcodes['28659'] = [36.215959, -81.148169];
Zipcodes['28660'] = [35.968615, -80.856248];
Zipcodes['28662'] = [36.02249, -81.891707];
Zipcodes['28663'] = [36.537202, -81.289931];
Zipcodes['28664'] = [36.04244, -82.005917];
Zipcodes['28665'] = [36.209682, -81.373878];
Zipcodes['28666'] = [35.718637, -81.470616];
Zipcodes['28667'] = [35.774539, -81.425089];
Zipcodes['28668'] = [36.435252, -80.982432];
Zipcodes['28669'] = [36.223445, -80.989818];
Zipcodes['28670'] = [36.194308, -80.912494];
Zipcodes['28671'] = [35.750203, -81.526631];
Zipcodes['28672'] = [36.483327, -81.32672];
Zipcodes['28673'] = [35.617717, -80.998758];
Zipcodes['28675'] = [36.506342, -81.136105];
Zipcodes['28676'] = [36.33186, -80.85534];
Zipcodes['28677'] = [35.738099, -80.926089];
Zipcodes['28678'] = [35.832604, -81.071143];
Zipcodes['28679'] = [36.260195, -81.840258];
Zipcodes['28681'] = [35.922678, -81.223058];
Zipcodes['28682'] = [35.588254, -80.965153];
Zipcodes['28683'] = [36.390439, -80.911007];
Zipcodes['28684'] = [36.33446, -81.606769];
Zipcodes['28685'] = [36.347273, -81.040959];
Zipcodes['28689'] = [36.043125, -80.933205];
Zipcodes['28690'] = [35.732735, -81.57423];
Zipcodes['28692'] = [36.314565, -81.837539];
Zipcodes['28693'] = [36.471773, -81.559009];
Zipcodes['28694'] = [36.366337, -81.468016];
Zipcodes['28697'] = [36.136665, -81.162721];
Zipcodes['28698'] = [36.314036, -81.760871];
Zipcodes['28701'] = [35.705098, -82.636467];
Zipcodes['28702'] = [35.379976, -83.641979];
Zipcodes['28704'] = [35.461429, -82.570815];
Zipcodes['28705'] = [36.035191, -82.15147];
Zipcodes['28707'] = [35.398716, -83.066194];
Zipcodes['28708'] = [35.271025, -82.859823];
Zipcodes['28709'] = [35.771399, -82.379244];
Zipcodes['28710'] = [35.456921, -82.279711];
Zipcodes['28711'] = [35.600604, -82.269687];
Zipcodes['28712'] = [35.173309, -82.770828];
Zipcodes['28713'] = [35.3657, -83.511664];
Zipcodes['28714'] = [35.878311, -82.304894];
Zipcodes['28715'] = [35.511966, -82.711701];
Zipcodes['28716'] = [35.439341, -82.847925];
Zipcodes['28717'] = [35.049543, -83.093326];
Zipcodes['28718'] = [35.159132, -82.634981];
Zipcodes['28719'] = [35.510891, -83.520266];
Zipcodes['28720'] = [35.447724, -82.262195];
Zipcodes['28721'] = [35.633768, -82.930786];
Zipcodes['28722'] = [35.208479, -82.061];
Zipcodes['28723'] = [35.314077, -83.013475];
Zipcodes['28725'] = [35.382223, -83.268112];
Zipcodes['28726'] = [35.280999, -82.416555];
Zipcodes['28729'] = [35.319556, -82.602883];
Zipcodes['28730'] = [35.5314, -82.359785];
Zipcodes['28731'] = [35.293544, -82.380326];
Zipcodes['28732'] = [35.451748, -82.445743];
Zipcodes['28733'] = [35.427397, -83.776971];
Zipcodes['28734'] = [35.179313, -83.436973];
Zipcodes['28735'] = [35.475405, -82.348507];
Zipcodes['28736'] = [35.175658, -83.086467];
Zipcodes['28739'] = [35.273876, -82.528602];
Zipcodes['28740'] = [36.025664, -82.305281];
Zipcodes['28741'] = [35.066425, -83.210613];
Zipcodes['28742'] = [35.38276, -82.651275];
Zipcodes['28743'] = [35.800283, -82.881224];
Zipcodes['28745'] = [35.522124, -82.973977];
Zipcodes['28746'] = [35.477191, -82.161693];
Zipcodes['28747'] = [35.145558, -82.917326];
Zipcodes['28748'] = [35.654923, -82.760267];
Zipcodes['28749'] = [35.841043, -82.097874];
Zipcodes['28751'] = [35.505476, -83.119563];
Zipcodes['28752'] = [35.709599, -82.035573];
Zipcodes['28753'] = [35.864206, -82.712631];
Zipcodes['28754'] = [35.876857, -82.514362];
Zipcodes['28755'] = [35.908203, -82.217538];
Zipcodes['28756'] = [35.355885, -82.208566];
Zipcodes['28757'] = [35.64804, -82.299164];
Zipcodes['28758'] = [35.374886, -82.495691];
Zipcodes['28759'] = [35.38135, -82.588899];
Zipcodes['28761'] = [35.690316, -81.906845];
Zipcodes['28762'] = [35.635334, -82.189772];
Zipcodes['28763'] = [35.028708, -83.465932];
Zipcodes['28766'] = [35.249443, -82.619253];
Zipcodes['28768'] = [35.325018, -82.707184];
Zipcodes['28770'] = [35.621106, -82.278167];
Zipcodes['28771'] = [35.342948, -83.854495];
Zipcodes['28772'] = [35.139069, -82.836919];
Zipcodes['28773'] = [35.261092, -82.29799];
Zipcodes['28774'] = [35.093536, -82.994977];
Zipcodes['28775'] = [35.019153, -83.321072];
Zipcodes['28777'] = [35.907661, -82.065327];
Zipcodes['28778'] = [35.614196, -82.39767];
Zipcodes['28779'] = [35.355752, -83.209682];
Zipcodes['28781'] = [35.224056, -83.635479];
Zipcodes['28782'] = [35.234277, -82.147383];
Zipcodes['28783'] = [35.242329, -83.021121];
Zipcodes['28785'] = [35.65327, -83.139633];
Zipcodes['28786'] = [35.462034, -82.988724];
Zipcodes['28787'] = [35.741533, -82.51564];
Zipcodes['28788'] = [35.35056, -83.22267];
Zipcodes['28789'] = [35.407057, -83.316777];
Zipcodes['28790'] = [35.198206, -82.495237];
Zipcodes['28791'] = [35.356985, -82.506995];
Zipcodes['28792'] = [35.394424, -82.368428];
Zipcodes['28801'] = [35.594539, -82.557972];
Zipcodes['28803'] = [35.531703, -82.523021];
Zipcodes['28804'] = [35.645008, -82.557723];
Zipcodes['28805'] = [35.613854, -82.479705];
Zipcodes['28806'] = [35.569645, -82.616787];
Zipcodes['28901'] = [35.201287, -83.805074];
Zipcodes['28902'] = [35.022948, -83.958294];
Zipcodes['28904'] = [35.06863, -83.733593];
Zipcodes['28905'] = [35.163064, -83.940936];
Zipcodes['28906'] = [35.087244, -84.136635];
Zipcodes['28909'] = [35.001138, -83.903263];
Zipcodes['29001'] = [33.786644, -80.174065];
Zipcodes['29003'] = [33.241221, -81.023815];
Zipcodes['29006'] = [33.869101, -81.551078];
Zipcodes['29009'] = [34.442983, -80.364579];
Zipcodes['29010'] = [34.223617, -80.272258];
Zipcodes['29014'] = [34.523382, -81.114513];
Zipcodes['29015'] = [34.44537, -81.337781];
Zipcodes['29016'] = [34.204016, -80.992765];
Zipcodes['29018'] = [33.348796, -80.639177];
Zipcodes['29020'] = [34.313938, -80.573806];
Zipcodes['29030'] = [33.576312, -80.647573];
Zipcodes['29031'] = [34.60681, -81.42897];
Zipcodes['29032'] = [34.337003, -80.444323];
Zipcodes['29033'] = [33.953093, -81.055204];
Zipcodes['29036'] = [34.134001, -81.339907];
Zipcodes['29037'] = [34.189935, -81.867694];
Zipcodes['29038'] = [33.38095, -80.981269];
Zipcodes['29039'] = [33.420441, -80.89215];
Zipcodes['29040'] = [34.060703, -80.437779];
Zipcodes['29042'] = [33.30556, -81.147853];
Zipcodes['29044'] = [33.92571, -80.695204];
Zipcodes['29045'] = [34.185078, -80.804984];
Zipcodes['29046'] = [34.112507, -80.161301];
Zipcodes['29047'] = [33.532178, -80.580231];
Zipcodes['29048'] = [33.390563, -80.295285];
Zipcodes['29051'] = [33.861626, -80.127828];
Zipcodes['29052'] = [33.798313, -80.741302];
Zipcodes['29053'] = [33.82754, -81.090895];
Zipcodes['29054'] = [33.947908, -81.382842];
Zipcodes['29055'] = [34.574244, -80.914866];
Zipcodes['29056'] = [33.608638, -80.005987];
Zipcodes['29058'] = [34.554283, -80.749684];
Zipcodes['29059'] = [33.329159, -80.419059];
Zipcodes['29061'] = [33.897493, -80.850604];
Zipcodes['29062'] = [34.004286, -80.605521];
Zipcodes['29063'] = [34.137162, -81.202313];
Zipcodes['29065'] = [34.298342, -81.317271];
Zipcodes['29067'] = [34.544166, -80.537094];
Zipcodes['29069'] = [34.180119, -80.094644];
Zipcodes['29070'] = [33.916667, -81.449233];
Zipcodes['29072'] = [33.998858, -81.272865];
Zipcodes['29073'] = [33.888025, -81.238637];
Zipcodes['29074'] = [34.441127, -80.818402];
Zipcodes['29075'] = [34.200659, -81.377808];
Zipcodes['29078'] = [34.181636, -80.699401];
Zipcodes['29079'] = [34.289086, -80.113167];
Zipcodes['29080'] = [34.012164, -80.085577];
Zipcodes['29081'] = [33.08908, -81.047373];
Zipcodes['29082'] = [33.038215, -80.939141];
Zipcodes['29101'] = [34.481778, -80.244607];
Zipcodes['29102'] = [33.654194, -80.202339];
Zipcodes['29104'] = [33.994689, -80.211764];
Zipcodes['29105'] = [33.806395, -81.593579];
Zipcodes['29107'] = [33.523387, -81.117556];
Zipcodes['29108'] = [34.305515, -81.627348];
Zipcodes['29111'] = [33.778496, -80.020582];
Zipcodes['29112'] = [33.625111, -81.08166];
Zipcodes['29113'] = [33.432442, -81.135333];
Zipcodes['29114'] = [33.954301, -79.932279];
Zipcodes['29115'] = [33.477717, -80.844199];
Zipcodes['29117'] = [33.498276, -80.850086];
Zipcodes['29118'] = [33.569394, -80.888558];
Zipcodes['29122'] = [34.238792, -81.328296];
Zipcodes['29123'] = [33.757417, -81.261261];
Zipcodes['29125'] = [33.695901, -80.474856];
Zipcodes['29126'] = [34.328933, -81.419482];
Zipcodes['29127'] = [34.153252, -81.512463];
Zipcodes['29128'] = [34.095702, -80.532082];
Zipcodes['29129'] = [33.789189, -81.672617];
Zipcodes['29130'] = [34.331606, -80.88041];
Zipcodes['29133'] = [33.358911, -80.803127];
Zipcodes['29135'] = [33.701789, -80.751062];
Zipcodes['29137'] = [33.596191, -81.321416];
Zipcodes['29138'] = [34.042392, -81.776152];
Zipcodes['29142'] = [33.465154, -80.522456];
Zipcodes['29145'] = [34.216762, -81.806759];
Zipcodes['29146'] = [33.508249, -81.295412];
Zipcodes['29148'] = [33.534926, -80.335816];
Zipcodes['29150'] = [33.818941, -80.316867];
Zipcodes['29152'] = [33.972657, -80.467319];
Zipcodes['29153'] = [33.970597, -80.305765];
Zipcodes['29154'] = [33.815261, -80.435274];
Zipcodes['29160'] = [33.746888, -81.035516];
Zipcodes['29161'] = [34.10365, -79.943426];
Zipcodes['29162'] = [33.889725, -80.008189];
Zipcodes['29163'] = [33.417579, -80.44645];
Zipcodes['29164'] = [33.658486, -81.430835];
Zipcodes['29166'] = [33.897095, -81.712255];
Zipcodes['29168'] = [33.848449, -80.540767];
Zipcodes['29169'] = [33.997503, -81.097406];
Zipcodes['29170'] = [33.939106, -81.144562];
Zipcodes['29172'] = [33.905765, -81.075502];
Zipcodes['29175'] = [34.442656, -80.606602];
Zipcodes['29177'] = [34.140505, -81.267506];
Zipcodes['29178'] = [34.495839, -81.589728];
Zipcodes['29180'] = [34.365471, -81.098531];
Zipcodes['29201'] = [33.982478, -81.028094];
Zipcodes['29203'] = [34.100612, -81.040698];
Zipcodes['29204'] = [34.029028, -80.999809];
Zipcodes['29205'] = [33.990015, -80.99663];
Zipcodes['29206'] = [34.028886, -80.960979];
Zipcodes['29207'] = [34.042447, -80.845986];
Zipcodes['29208'] = [33.997515, -81.026796];
Zipcodes['29209'] = [33.926751, -80.950364];
Zipcodes['29210'] = [34.045458, -81.110262];
Zipcodes['29212'] = [34.077553, -81.202336];
Zipcodes['29223'] = [34.092634, -80.919271];
Zipcodes['29225'] = [33.995404, -81.030051];
Zipcodes['29229'] = [34.145968, -80.894404];
Zipcodes['29301'] = [34.935234, -82.014769];
Zipcodes['29302'] = [34.884446, -81.8436];
Zipcodes['29303'] = [34.995296, -81.968368];
Zipcodes['29306'] = [34.892633, -81.921504];
Zipcodes['29307'] = [34.982716, -81.831555];
Zipcodes['29316'] = [35.043045, -81.975107];
Zipcodes['29320'] = [34.958567, -81.992583];
Zipcodes['29321'] = [34.714655, -81.741066];
Zipcodes['29322'] = [35.121094, -82.132432];
Zipcodes['29323'] = [35.126341, -81.907463];
Zipcodes['29324'] = [34.991295, -81.824132];
Zipcodes['29325'] = [34.468182, -81.845225];
Zipcodes['29329'] = [34.992453, -81.835984];
Zipcodes['29330'] = [35.052577, -81.80432];
Zipcodes['29331'] = [34.644241, -81.858033];
Zipcodes['29332'] = [34.278682, -81.970379];
Zipcodes['29333'] = [34.97095, -81.910754];
Zipcodes['29334'] = [34.905551, -82.124414];
Zipcodes['29335'] = [34.659271, -81.906657];
Zipcodes['29338'] = [35.134468, -82.001787];
Zipcodes['29340'] = [34.972313, -81.585917];
Zipcodes['29341'] = [35.119654, -81.712593];
Zipcodes['29346'] = [34.945184, -81.836208];
Zipcodes['29349'] = [35.067335, -82.068095];
Zipcodes['29351'] = [34.421036, -81.808045];
Zipcodes['29353'] = [34.830741, -81.648604];
Zipcodes['29355'] = [34.363443, -81.747608];
Zipcodes['29356'] = [35.146893, -82.260805];
Zipcodes['29360'] = [34.488003, -82.045762];
Zipcodes['29364'] = [34.788287, -81.462342];
Zipcodes['29365'] = [34.981062, -82.171924];
Zipcodes['29368'] = [35.082619, -81.862846];
Zipcodes['29369'] = [34.86562, -82.019463];
Zipcodes['29370'] = [34.373026, -81.977361];
Zipcodes['29372'] = [34.902124, -81.711842];
Zipcodes['29373'] = [34.92447, -81.743043];
Zipcodes['29374'] = [34.789284, -81.845267];
Zipcodes['29375'] = [34.860536, -82.113828];
Zipcodes['29376'] = [34.811382, -81.944219];
Zipcodes['29377'] = [34.930737, -82.096839];
Zipcodes['29378'] = [34.966792, -81.968697];
Zipcodes['29379'] = [34.687626, -81.601568];
Zipcodes['29384'] = [34.340252, -82.090181];
Zipcodes['29385'] = [34.972731, -82.101433];
Zipcodes['29388'] = [34.762969, -82.045689];
Zipcodes['29401'] = [32.77718, -79.932];
Zipcodes['29403'] = [32.803349, -79.945734];
Zipcodes['29404'] = [32.897841, -80.052445];
Zipcodes['29405'] = [32.852297, -79.980016];
Zipcodes['29406'] = [32.937294, -80.035355];
Zipcodes['29407'] = [32.79893, -79.997803];
Zipcodes['29409'] = [32.796655, -79.960706];
Zipcodes['29410'] = [32.938216, -80.014936];
Zipcodes['29412'] = [32.711961, -79.952187];
Zipcodes['29414'] = [32.842866, -80.104559];
Zipcodes['29418'] = [32.909495, -80.09566];
Zipcodes['29420'] = [32.932463, -80.100063];
Zipcodes['29424'] = [32.784887, -79.937725];
Zipcodes['29425'] = [32.78527, -79.948392];
Zipcodes['29426'] = [32.772145, -80.374085];
Zipcodes['29429'] = [32.988642, -79.696156];
Zipcodes['29431'] = [33.271438, -79.881694];
Zipcodes['29432'] = [33.228489, -80.813864];
Zipcodes['29434'] = [33.140888, -79.843725];
Zipcodes['29435'] = [32.953108, -80.466101];
Zipcodes['29436'] = [33.315358, -80.18725];
Zipcodes['29437'] = [33.135176, -80.417764];
Zipcodes['29438'] = [32.565933, -80.32173];
Zipcodes['29439'] = [32.652591, -79.95248];
Zipcodes['29440'] = [33.38111, -79.32976];
Zipcodes['29445'] = [32.997022, -79.966603];
Zipcodes['29446'] = [32.588889, -80.510785];
Zipcodes['29448'] = [33.255537, -80.452774];
Zipcodes['29449'] = [32.699809, -80.280925];
Zipcodes['29450'] = [33.087378, -79.771543];
Zipcodes['29451'] = [32.828009, -79.750481];
Zipcodes['29452'] = [32.717609, -80.470698];
Zipcodes['29453'] = [33.222884, -79.60735];
Zipcodes['29455'] = [32.684168, -80.091953];
Zipcodes['29456'] = [32.984201, -80.113273];
Zipcodes['29458'] = [33.119492, -79.486193];
Zipcodes['29461'] = [33.170981, -80.008578];
Zipcodes['29464'] = [32.819473, -79.857027];
Zipcodes['29466'] = [32.87555, -79.783739];
Zipcodes['29468'] = [33.427314, -80.079775];
Zipcodes['29469'] = [33.243672, -80.131055];
Zipcodes['29470'] = [32.814197, -80.210254];
Zipcodes['29471'] = [33.192654, -80.670479];
Zipcodes['29472'] = [33.054643, -80.325327];
Zipcodes['29474'] = [32.902724, -80.524038];
Zipcodes['29475'] = [32.966876, -80.819388];
Zipcodes['29477'] = [33.163169, -80.56521];
Zipcodes['29479'] = [33.380601, -79.890563];
Zipcodes['29481'] = [33.120379, -80.793077];
Zipcodes['29482'] = [32.770949, -79.831136];
Zipcodes['29483'] = [33.030187, -80.233763];
Zipcodes['29485'] = [32.933189, -80.207197];
Zipcodes['29486'] = [33.103211, -80.166653];
Zipcodes['29487'] = [32.657682, -80.183948];
Zipcodes['29488'] = [32.906763, -80.672836];
Zipcodes['29492'] = [32.909558, -79.879847];
Zipcodes['29493'] = [33.045319, -80.842769];
Zipcodes['29501'] = [34.204581, -79.82697];
Zipcodes['29505'] = [34.130066, -79.688893];
Zipcodes['29506'] = [34.210452, -79.649896];
Zipcodes['29510'] = [33.454312, -79.613121];
Zipcodes['29511'] = [33.975634, -79.122163];
Zipcodes['29512'] = [34.619033, -79.718041];
Zipcodes['29516'] = [34.442926, -79.614801];
Zipcodes['29518'] = [33.794814, -79.846611];
Zipcodes['29519'] = [34.016181, -79.360465];
Zipcodes['29520'] = [34.687998, -79.929523];
Zipcodes['29525'] = [34.563074, -79.515487];
Zipcodes['29526'] = [33.867767, -78.976526];
Zipcodes['29527'] = [33.785083, -79.140176];
Zipcodes['29530'] = [33.996055, -79.740075];
Zipcodes['29532'] = [34.282779, -79.850175];
Zipcodes['29536'] = [34.418937, -79.371955];
Zipcodes['29540'] = [34.383832, -79.850462];
Zipcodes['29541'] = [34.064949, -79.74075];
Zipcodes['29543'] = [34.2876, -79.265925];
Zipcodes['29544'] = [34.001578, -79.220764];
Zipcodes['29545'] = [34.16366, -78.966477];
Zipcodes['29546'] = [33.883845, -79.349409];
Zipcodes['29547'] = [34.488143, -79.32805];
Zipcodes['29550'] = [34.39327, -80.077031];
Zipcodes['29554'] = [33.70264, -79.392499];
Zipcodes['29555'] = [33.840906, -79.457601];
Zipcodes['29556'] = [33.668227, -79.763139];
Zipcodes['29560'] = [33.838702, -79.749876];
Zipcodes['29563'] = [34.35334, -79.208671];
Zipcodes['29564'] = [33.475902, -79.856124];
Zipcodes['29565'] = [34.358052, -79.499289];
Zipcodes['29566'] = [33.888951, -78.659429];
Zipcodes['29567'] = [34.560972, -79.432683];
Zipcodes['29568'] = [33.913616, -78.750292];
Zipcodes['29569'] = [34.03263, -78.911584];
Zipcodes['29570'] = [34.667866, -79.562707];
Zipcodes['29571'] = [34.135077, -79.425051];
Zipcodes['29572'] = [33.769526, -78.792285];
Zipcodes['29574'] = [34.165413, -79.260831];
Zipcodes['29575'] = [33.629139, -78.970404];
Zipcodes['29576'] = [33.569819, -79.053506];
Zipcodes['29577'] = [33.697638, -78.906484];
Zipcodes['29579'] = [33.76636, -78.913478];
Zipcodes['29580'] = [33.653101, -79.551975];
Zipcodes['29581'] = [34.194863, -79.114239];
Zipcodes['29582'] = [33.832049, -78.654824];
Zipcodes['29583'] = [33.977851, -79.571077];
Zipcodes['29584'] = [34.576481, -80.053649];
Zipcodes['29585'] = [33.556464, -79.088953];
Zipcodes['29588'] = [33.670794, -79.023125];
Zipcodes['29589'] = [34.100602, -79.320161];
Zipcodes['29590'] = [33.56892, -79.848696];
Zipcodes['29591'] = [33.937999, -79.76229];
Zipcodes['29592'] = [34.245086, -79.454441];
Zipcodes['29593'] = [34.493107, -79.856964];
Zipcodes['29594'] = [34.640233, -79.57736];
Zipcodes['29596'] = [34.736119, -79.851195];
Zipcodes['29601'] = [34.847311, -82.402121];
Zipcodes['29605'] = [34.778047, -82.380192];
Zipcodes['29607'] = [34.809603, -82.328865];
Zipcodes['29609'] = [34.912592, -82.38817];
Zipcodes['29611'] = [34.830934, -82.458497];
Zipcodes['29613'] = [34.924255, -82.440488];
Zipcodes['29614'] = [34.873714, -82.363011];
Zipcodes['29615'] = [34.856825, -82.296139];
Zipcodes['29617'] = [34.914439, -82.466074];
Zipcodes['29620'] = [34.182407, -82.425607];
Zipcodes['29621'] = [34.506294, -82.603598];
Zipcodes['29624'] = [34.435943, -82.624115];
Zipcodes['29625'] = [34.555519, -82.765621];
Zipcodes['29626'] = [34.460802, -82.756514];
Zipcodes['29627'] = [34.518591, -82.480284];
Zipcodes['29628'] = [34.08847, -82.564499];
Zipcodes['29630'] = [34.738673, -82.799161];
Zipcodes['29631'] = [34.681593, -82.817762];
Zipcodes['29634'] = [34.6756, -82.835245];
Zipcodes['29635'] = [35.07649, -82.634765];
Zipcodes['29638'] = [34.363867, -82.338204];
Zipcodes['29639'] = [34.303669, -82.428794];
Zipcodes['29640'] = [34.87771, -82.575421];
Zipcodes['29642'] = [34.776737, -82.563612];
Zipcodes['29643'] = [34.507566, -82.975315];
Zipcodes['29644'] = [34.666107, -82.195445];
Zipcodes['29645'] = [34.587727, -82.127358];
Zipcodes['29646'] = [34.136053, -82.14847];
Zipcodes['29649'] = [34.238919, -82.145019];
Zipcodes['29650'] = [34.897991, -82.258012];
Zipcodes['29651'] = [35.02383, -82.279564];
Zipcodes['29653'] = [34.306003, -82.232469];
Zipcodes['29654'] = [34.455541, -82.370225];
Zipcodes['29655'] = [34.26525, -82.632434];
Zipcodes['29657'] = [34.767066, -82.686594];
Zipcodes['29658'] = [34.771546, -83.303341];
Zipcodes['29659'] = [34.211685, -82.64531];
Zipcodes['29661'] = [35.057496, -82.554666];
Zipcodes['29662'] = [34.779208, -82.30308];
Zipcodes['29664'] = [34.860888, -83.157553];
Zipcodes['29665'] = [34.724825, -82.914632];
Zipcodes['29666'] = [34.113326, -81.979552];
Zipcodes['29667'] = [34.764327, -82.756529];
Zipcodes['29669'] = [34.638704, -82.412542];
Zipcodes['29670'] = [34.643979, -82.723237];
Zipcodes['29671'] = [34.943796, -82.72112];
Zipcodes['29672'] = [34.751685, -82.932051];
Zipcodes['29673'] = [34.720191, -82.455248];
Zipcodes['29676'] = [34.953666, -82.984956];
Zipcodes['29678'] = [34.63447, -82.937301];
Zipcodes['29680'] = [34.684858, -82.293791];
Zipcodes['29681'] = [34.767679, -82.225003];
Zipcodes['29682'] = [34.836191, -82.853397];
Zipcodes['29683'] = [35.030088, -82.492939];
Zipcodes['29684'] = [34.379432, -82.718704];
Zipcodes['29685'] = [34.983435, -82.844869];
Zipcodes['29686'] = [34.958205, -83.053088];
Zipcodes['29687'] = [34.986636, -82.327451];
Zipcodes['29689'] = [34.5367, -82.87594];
Zipcodes['29690'] = [35.059806, -82.417198];
Zipcodes['29691'] = [34.783623, -83.076927];
Zipcodes['29692'] = [34.411805, -82.219468];
Zipcodes['29693'] = [34.641751, -83.154596];
Zipcodes['29696'] = [34.781122, -83.008462];
Zipcodes['29697'] = [34.625593, -82.548639];
Zipcodes['29702'] = [35.116068, -81.473073];
Zipcodes['29704'] = [34.83212, -80.909792];
Zipcodes['29706'] = [34.715558, -81.23453];
Zipcodes['29707'] = [34.987552, -80.858188];
Zipcodes['29708'] = [35.048227, -80.986659];
Zipcodes['29709'] = [34.718509, -80.098942];
Zipcodes['29710'] = [35.106284, -81.221471];
Zipcodes['29712'] = [34.797966, -80.975531];
Zipcodes['29714'] = [34.711779, -80.913775];
Zipcodes['29715'] = [35.011342, -80.927133];
Zipcodes['29717'] = [34.95073, -81.448855];
Zipcodes['29718'] = [34.629803, -80.357614];
Zipcodes['29720'] = [34.745312, -80.743312];
Zipcodes['29724'] = [34.775005, -81.012539];
Zipcodes['29726'] = [34.860853, -81.236148];
Zipcodes['29727'] = [34.719265, -80.264601];
Zipcodes['29728'] = [34.764953, -80.400558];
Zipcodes['29729'] = [34.689134, -81.00676];
Zipcodes['29730'] = [34.884936, -81.02115];
Zipcodes['29732'] = [34.970716, -81.081364];
Zipcodes['29733'] = [34.939453, -81.031719];
Zipcodes['29741'] = [34.725288, -80.195526];
Zipcodes['29742'] = [34.869981, -81.392261];
Zipcodes['29743'] = [35.026136, -81.423067];
Zipcodes['29745'] = [34.990875, -81.214056];
Zipcodes['29801'] = [33.585864, -81.691726];
Zipcodes['29803'] = [33.453473, -81.701926];
Zipcodes['29805'] = [33.641872, -81.614053];
Zipcodes['29809'] = [33.416161, -81.689293];
Zipcodes['29810'] = [32.986368, -81.37811];
Zipcodes['29812'] = [33.214437, -81.383394];
Zipcodes['29816'] = [33.502122, -81.872627];
Zipcodes['29817'] = [33.36951, -81.270761];
Zipcodes['29819'] = [34.044227, -82.227378];
Zipcodes['29821'] = [33.636618, -82.115231];
Zipcodes['29824'] = [33.814545, -81.993868];
Zipcodes['29826'] = [33.385382, -81.359584];
Zipcodes['29827'] = [32.970088, -81.242285];
Zipcodes['29828'] = [33.523428, -81.82963];
Zipcodes['29829'] = [33.57472, -81.857718];
Zipcodes['29831'] = [33.338725, -81.864111];
Zipcodes['29832'] = [33.839003, -81.803671];
Zipcodes['29834'] = [33.517756, -81.843006];
Zipcodes['29835'] = [33.920581, -82.29452];
Zipcodes['29836'] = [33.085199, -81.496035];
Zipcodes['29838'] = [33.747491, -82.15107];
Zipcodes['29840'] = [33.996543, -82.542575];
Zipcodes['29841'] = [33.526407, -81.939337];
Zipcodes['29842'] = [33.457043, -81.86071];
Zipcodes['29843'] = [33.182069, -81.179772];
Zipcodes['29844'] = [33.785183, -82.214131];
Zipcodes['29845'] = [33.825859, -82.213392];
Zipcodes['29847'] = [33.704138, -81.847277];
Zipcodes['29848'] = [33.972317, -82.060396];
Zipcodes['29849'] = [33.103626, -81.238347];
Zipcodes['29851'] = [33.511092, -81.815851];
Zipcodes['29853'] = [33.399205, -81.431756];
Zipcodes['29856'] = [33.485256, -81.511831];
Zipcodes['29860'] = [33.614688, -81.984428];
Zipcodes['29899'] = [33.928023, -82.250602];
Zipcodes['29902'] = [32.317597, -80.682213];
Zipcodes['29904'] = [32.478848, -80.721951];
Zipcodes['29905'] = [32.355371, -80.700252];
Zipcodes['29906'] = [32.445112, -80.752875];
Zipcodes['29907'] = [32.474692, -80.599222];
Zipcodes['29909'] = [32.336992, -80.848074];
Zipcodes['29910'] = [32.217824, -80.894994];
Zipcodes['29911'] = [32.928833, -81.155189];
Zipcodes['29912'] = [32.584548, -80.928494];
Zipcodes['29915'] = [32.118706, -80.861178];
Zipcodes['29916'] = [32.728963, -80.958706];
Zipcodes['29918'] = [32.744462, -81.247365];
Zipcodes['29920'] = [32.36422, -80.55361];
Zipcodes['29921'] = [32.686639, -81.180144];
Zipcodes['29922'] = [32.588331, -81.280061];
Zipcodes['29923'] = [32.848248, -81.238594];
Zipcodes['29924'] = [32.876878, -81.111255];
Zipcodes['29926'] = [32.237257, -80.734276];
Zipcodes['29927'] = [32.24664, -81.057508];
Zipcodes['29928'] = [32.167062, -80.753591];
Zipcodes['29929'] = [32.955198, -80.923822];
Zipcodes['29932'] = [32.833497, -81.344326];
Zipcodes['29934'] = [32.595031, -81.112502];
Zipcodes['29935'] = [32.382648, -80.695597];
Zipcodes['29936'] = [32.483364, -80.966253];
Zipcodes['29939'] = [32.681259, -81.245338];
Zipcodes['29940'] = [32.552743, -80.677693];
Zipcodes['29941'] = [32.534804, -80.819692];
Zipcodes['29943'] = [32.491142, -81.146707];
Zipcodes['29944'] = [32.795699, -81.034521];
Zipcodes['29945'] = [32.681465, -80.795863];
Zipcodes['30002'] = [33.773335, -84.261667];
Zipcodes['30004'] = [34.145635, -84.291881];
Zipcodes['30005'] = [34.08905, -84.217019];
Zipcodes['30008'] = [33.900567, -84.590924];
Zipcodes['30009'] = [34.078136, -84.303424];
Zipcodes['30011'] = [34.019859, -83.837163];
Zipcodes['30012'] = [33.717806, -84.002515];
Zipcodes['30013'] = [33.642603, -83.971853];
Zipcodes['30014'] = [33.587193, -83.822173];
Zipcodes['30016'] = [33.517433, -83.928703];
Zipcodes['30017'] = [33.888722, -83.963687];
Zipcodes['30018'] = [33.71689, -83.804251];
Zipcodes['30019'] = [33.975634, -83.883769];
Zipcodes['30021'] = [33.807995, -84.238975];
Zipcodes['30022'] = [34.026085, -84.247433];
Zipcodes['30024'] = [34.063485, -84.0911];
Zipcodes['30025'] = [33.655983, -83.695765];
Zipcodes['30028'] = [34.293159, -84.175907];
Zipcodes['30030'] = [33.772712, -84.291005];
Zipcodes['30032'] = [33.740668, -84.264466];
Zipcodes['30033'] = [33.813689, -84.284377];
Zipcodes['30034'] = [33.690867, -84.248622];
Zipcodes['30035'] = [33.724452, -84.204244];
Zipcodes['30038'] = [33.66646, -84.139855];
Zipcodes['30039'] = [33.799244, -84.034408];
Zipcodes['30040'] = [34.223434, -84.17644];
Zipcodes['30041'] = [34.196222, -84.096676];
Zipcodes['30043'] = [34.010038, -84.005043];
Zipcodes['30044'] = [33.921502, -84.068873];
Zipcodes['30045'] = [33.936518, -83.927669];
Zipcodes['30046'] = [33.949702, -83.993451];
Zipcodes['30047'] = [33.870125, -84.112322];
Zipcodes['30052'] = [33.817047, -83.897315];
Zipcodes['30054'] = [33.678353, -83.88296];
Zipcodes['30055'] = [33.497411, -83.749784];
Zipcodes['30056'] = [33.491974, -83.650144];
Zipcodes['30058'] = [33.73835, -84.107376];
Zipcodes['30060'] = [33.920062, -84.5431];
Zipcodes['30062'] = [34.006497, -84.472974];
Zipcodes['30064'] = [33.940455, -84.609708];
Zipcodes['30066'] = [34.032388, -84.505154];
Zipcodes['30067'] = [33.934278, -84.462185];
Zipcodes['30068'] = [33.968116, -84.431704];
Zipcodes['30070'] = [33.573282, -83.895589];
Zipcodes['30071'] = [33.940652, -84.206734];
Zipcodes['30072'] = [33.79141, -84.204844];
Zipcodes['30075'] = [34.052688, -84.391587];
Zipcodes['30076'] = [34.028568, -84.311368];
Zipcodes['30078'] = [33.861019, -84.017993];
Zipcodes['30079'] = [33.791739, -84.260747];
Zipcodes['30080'] = [33.877818, -84.501703];
Zipcodes['30082'] = [33.854654, -84.536437];
Zipcodes['30083'] = [33.796597, -84.192603];
Zipcodes['30084'] = [33.854097, -84.216155];
Zipcodes['30087'] = [33.791402, -84.098277];
Zipcodes['30088'] = [33.756052, -84.182363];
Zipcodes['30090'] = [33.953141, -84.548159];
Zipcodes['30092'] = [33.972386, -84.231986];
Zipcodes['30093'] = [33.908911, -84.17924];
Zipcodes['30094'] = [33.612505, -84.051523];
Zipcodes['30096'] = [33.974302, -84.145384];
Zipcodes['30097'] = [34.026335, -84.145677];
Zipcodes['30101'] = [34.034515, -84.707349];
Zipcodes['30102'] = [34.101625, -84.645168];
Zipcodes['30103'] = [34.363242, -84.920192];
Zipcodes['30104'] = [34.088874, -85.070382];
Zipcodes['30105'] = [34.432548, -85.172442];
Zipcodes['30106'] = [33.83288, -84.628055];
Zipcodes['30107'] = [34.336098, -84.345464];
Zipcodes['30108'] = [33.5317, -85.262996];
Zipcodes['30110'] = [33.739243, -85.135434];
Zipcodes['30111'] = [33.831784, -84.65504];
Zipcodes['30113'] = [33.850746, -85.205308];
Zipcodes['30114'] = [34.243544, -84.535815];
Zipcodes['30115'] = [34.215519, -84.402426];
Zipcodes['30116'] = [33.539118, -84.99561];
Zipcodes['30117'] = [33.581851, -85.132439];
Zipcodes['30118'] = [33.572902, -85.099437];
Zipcodes['30120'] = [34.175229, -84.860036];
Zipcodes['30121'] = [34.211854, -84.77693];
Zipcodes['30122'] = [33.766909, -84.641356];
Zipcodes['30124'] = [34.133736, -85.345827];
Zipcodes['30125'] = [34.000642, -85.276805];
Zipcodes['30126'] = [33.815023, -84.553798];
Zipcodes['30127'] = [33.875084, -84.69934];
Zipcodes['30132'] = [33.977608, -84.851541];
Zipcodes['30134'] = [33.77752, -84.78139];
Zipcodes['30135'] = [33.672278, -84.733011];
Zipcodes['30137'] = [34.116557, -84.7657];
Zipcodes['30139'] = [34.432341, -84.705495];
Zipcodes['30141'] = [33.860444, -84.769846];
Zipcodes['30143'] = [34.462756, -84.425227];
Zipcodes['30144'] = [34.036845, -84.591033];
Zipcodes['30145'] = [34.24566, -84.985878];
Zipcodes['30147'] = [34.13494, -85.22206];
Zipcodes['30148'] = [34.46049, -84.265701];
Zipcodes['30149'] = [34.316789, -85.220289];
Zipcodes['30152'] = [33.991564, -84.64687];
Zipcodes['30153'] = [33.973822, -85.052856];
Zipcodes['30157'] = [33.884746, -84.871949];
Zipcodes['30161'] = [34.23623, -85.173993];
Zipcodes['30165'] = [34.315788, -85.295122];
Zipcodes['30168'] = [33.783542, -84.588106];
Zipcodes['30170'] = [33.432792, -85.164674];
Zipcodes['30171'] = [34.363353, -84.727435];
Zipcodes['30173'] = [34.133121, -85.153505];
Zipcodes['30175'] = [34.534915, -84.526711];
Zipcodes['30176'] = [33.771314, -85.30439];
Zipcodes['30177'] = [34.407132, -84.373997];
Zipcodes['30178'] = [34.095453, -84.971074];
Zipcodes['30179'] = [33.781553, -85.014782];
Zipcodes['30180'] = [33.718878, -84.918801];
Zipcodes['30182'] = [33.655819, -85.248832];
Zipcodes['30183'] = [34.338804, -84.602425];
Zipcodes['30184'] = [34.252062, -84.741056];
Zipcodes['30185'] = [33.519802, -84.918098];
Zipcodes['30187'] = [33.652761, -84.856249];
Zipcodes['30188'] = [34.123533, -84.457654];
Zipcodes['30189'] = [34.124224, -84.570679];
Zipcodes['30204'] = [33.056208, -84.121725];
Zipcodes['30205'] = [33.267427, -84.475116];
Zipcodes['30206'] = [33.096064, -84.458259];
Zipcodes['30213'] = [33.586742, -84.624426];
Zipcodes['30214'] = [33.490464, -84.485997];
Zipcodes['30215'] = [33.391613, -84.456432];
Zipcodes['30216'] = [33.228834, -83.884863];
Zipcodes['30217'] = [33.291347, -85.137886];
Zipcodes['30218'] = [33.120297, -84.587455];
Zipcodes['30220'] = [33.230839, -84.82486];
Zipcodes['30222'] = [33.037153, -84.73467];
Zipcodes['30223'] = [33.288794, -84.279919];
Zipcodes['30224'] = [33.204432, -84.239735];
Zipcodes['30228'] = [33.400608, -84.307728];
Zipcodes['30229'] = [33.227934, -84.570341];
Zipcodes['30230'] = [33.166737, -84.920497];
Zipcodes['30233'] = [33.294709, -83.975561];
Zipcodes['30234'] = [33.332567, -84.025298];
Zipcodes['30236'] = [33.519927, -84.325187];
Zipcodes['30238'] = [33.501846, -84.382236];
Zipcodes['30240'] = [33.036764, -85.120092];
Zipcodes['30241'] = [33.021712, -84.95623];
Zipcodes['30248'] = [33.35046, -84.109119];
Zipcodes['30250'] = [33.434846, -84.306078];
Zipcodes['30251'] = [33.198174, -84.698112];
Zipcodes['30252'] = [33.473512, -84.057165];
Zipcodes['30253'] = [33.453601, -84.187167];
Zipcodes['30256'] = [33.007484, -84.3099];
Zipcodes['30257'] = [33.143414, -84.18622];
Zipcodes['30258'] = [32.990854, -84.470041];
Zipcodes['30259'] = [33.265699, -84.741743];
Zipcodes['30260'] = [33.587165, -84.334307];
Zipcodes['30263'] = [33.388415, -84.858937];
Zipcodes['30265'] = [33.418686, -84.713164];
Zipcodes['30268'] = [33.54643, -84.722632];
Zipcodes['30269'] = [33.393891, -84.569301];
Zipcodes['30272'] = [33.623122, -84.501756];
Zipcodes['30273'] = [33.583798, -84.271027];
Zipcodes['30274'] = [33.557264, -84.400508];
Zipcodes['30275'] = [33.430654, -84.867864];
Zipcodes['30276'] = [33.263314, -84.583456];
Zipcodes['30277'] = [33.375308, -84.649196];
Zipcodes['30281'] = [33.556897, -84.195159];
Zipcodes['30284'] = [33.340288, -84.288571];
Zipcodes['30285'] = [32.987673, -84.258191];
Zipcodes['30286'] = [32.881118, -84.333168];
Zipcodes['30288'] = [33.652897, -84.327107];
Zipcodes['30289'] = [33.323596, -84.633698];
Zipcodes['30290'] = [33.476407, -84.589704];
Zipcodes['30291'] = [33.575883, -84.543771];
Zipcodes['30292'] = [33.168489, -84.393225];
Zipcodes['30293'] = [32.979078, -84.596302];
Zipcodes['30294'] = [33.636692, -84.264986];
Zipcodes['30295'] = [33.090342, -84.317583];
Zipcodes['30296'] = [33.56316, -84.441157];
Zipcodes['30297'] = [33.614988, -84.371672];
Zipcodes['30303'] = [33.752845, -84.390226];
Zipcodes['30305'] = [33.834063, -84.392063];
Zipcodes['30306'] = [33.78814, -84.352625];
Zipcodes['30307'] = [33.771079, -84.3336];
Zipcodes['30308'] = [33.770959, -84.377736];
Zipcodes['30309'] = [33.799851, -84.385837];
Zipcodes['30310'] = [33.726889, -84.428926];
Zipcodes['30311'] = [33.723262, -84.475994];
Zipcodes['30312'] = [33.744616, -84.375814];
Zipcodes['30313'] = [33.763613, -84.39767];
Zipcodes['30314'] = [33.757861, -84.431963];
Zipcodes['30315'] = [33.702741, -84.382853];
Zipcodes['30316'] = [33.716794, -84.331343];
Zipcodes['30317'] = [33.747989, -84.315586];
Zipcodes['30318'] = [33.79266, -84.44801];
Zipcodes['30319'] = [33.875954, -84.334763];
Zipcodes['30322'] = [33.794593, -84.325236];
Zipcodes['30324'] = [33.818405, -84.358175];
Zipcodes['30326'] = [33.849615, -84.363508];
Zipcodes['30327'] = [33.869694, -84.4173];
Zipcodes['30328'] = [33.933259, -84.386656];
Zipcodes['30329'] = [33.827245, -84.322148];
Zipcodes['30331'] = [33.705434, -84.544076];
Zipcodes['30332'] = [33.777618, -84.404348];
Zipcodes['30334'] = [33.748652, -84.387894];
Zipcodes['30336'] = [33.735444, -84.568978];
Zipcodes['30337'] = [33.640147, -84.450081];
Zipcodes['30338'] = [33.946218, -84.318119];
Zipcodes['30339'] = [33.86757, -84.4646];
Zipcodes['30340'] = [33.897873, -84.252804];
Zipcodes['30341'] = [33.888505, -84.289532];
Zipcodes['30342'] = [33.88435, -84.374964];
Zipcodes['30344'] = [33.676636, -84.461601];
Zipcodes['30345'] = [33.851652, -84.283685];
Zipcodes['30346'] = [33.924277, -84.338809];
Zipcodes['30349'] = [33.622488, -84.523908];
Zipcodes['30350'] = [33.980254, -84.330016];
Zipcodes['30354'] = [33.660361, -84.386502];
Zipcodes['30360'] = [33.933885, -84.272034];
Zipcodes['30363'] = [33.791478, -84.398892];
Zipcodes['30401'] = [32.60473, -82.347998];
Zipcodes['30410'] = [32.188589, -82.51347];
Zipcodes['30411'] = [32.124102, -82.793957];
Zipcodes['30412'] = [32.080344, -82.486087];
Zipcodes['30413'] = [32.884655, -82.513102];
Zipcodes['30414'] = [32.157001, -81.989953];
Zipcodes['30415'] = [32.324484, -81.603497];
Zipcodes['30417'] = [32.170468, -81.920992];
Zipcodes['30420'] = [32.306648, -82.130889];
Zipcodes['30421'] = [32.185377, -82.110912];
Zipcodes['30423'] = [32.146336, -81.824114];
Zipcodes['30425'] = [32.682022, -82.094572];
Zipcodes['30426'] = [33.0473, -81.648759];
Zipcodes['30427'] = [31.922905, -81.962446];
Zipcodes['30428'] = [32.224545, -82.71513];
Zipcodes['30429'] = [32.156771, -81.934857];
Zipcodes['30434'] = [33.02457, -82.366309];
Zipcodes['30436'] = [32.154894, -82.293036];
Zipcodes['30439'] = [32.416717, -82.070375];
Zipcodes['30441'] = [32.815558, -82.237151];
Zipcodes['30442'] = [32.816174, -81.969847];
Zipcodes['30445'] = [32.165598, -82.580717];
Zipcodes['30446'] = [32.563486, -81.463016];
Zipcodes['30448'] = [32.48824, -82.363384];
Zipcodes['30449'] = [32.525339, -81.534514];
Zipcodes['30450'] = [32.555993, -81.927363];
Zipcodes['30451'] = [32.393273, -81.956218];
Zipcodes['30452'] = [32.31611, -81.891855];
Zipcodes['30453'] = [32.026236, -82.111225];
Zipcodes['30454'] = [32.436077, -82.730358];
Zipcodes['30455'] = [32.69833, -81.809862];
Zipcodes['30456'] = [32.980717, -81.79614];
Zipcodes['30457'] = [32.399851, -82.56089];
Zipcodes['30458'] = [32.399447, -81.82894];
Zipcodes['30460'] = [32.427122, -81.783189];
Zipcodes['30461'] = [32.496955, -81.723101];
Zipcodes['30464'] = [32.437229, -82.224961];
Zipcodes['30467'] = [32.753793, -81.603534];
Zipcodes['30470'] = [32.358929, -82.497531];
Zipcodes['30471'] = [32.543263, -82.18091];
Zipcodes['30473'] = [32.015476, -82.473052];
Zipcodes['30474'] = [32.223996, -82.413685];
Zipcodes['30477'] = [32.862554, -82.400822];
Zipcodes['30501'] = [34.319043, -83.814422];
Zipcodes['30504'] = [34.266502, -83.888646];
Zipcodes['30506'] = [34.346938, -83.895627];
Zipcodes['30507'] = [34.264685, -83.769391];
Zipcodes['30510'] = [34.444872, -83.570707];
Zipcodes['30511'] = [34.449686, -83.486693];
Zipcodes['30512'] = [34.856753, -83.967998];
Zipcodes['30513'] = [34.821043, -84.322383];
Zipcodes['30516'] = [34.380913, -83.030697];
Zipcodes['30517'] = [34.130296, -83.797289];
Zipcodes['30518'] = [34.133822, -84.022599];
Zipcodes['30519'] = [34.08741, -83.945059];
Zipcodes['30520'] = [34.339924, -83.082702];
Zipcodes['30521'] = [34.362448, -83.292322];
Zipcodes['30522'] = [34.78368, -84.34373];
Zipcodes['30523'] = [34.713154, -83.551309];
Zipcodes['30525'] = [34.907223, -83.369347];
Zipcodes['30527'] = [34.480907, -83.781838];
Zipcodes['30528'] = [34.609088, -83.775997];
Zipcodes['30529'] = [34.217179, -83.482653];
Zipcodes['30530'] = [34.222921, -83.393799];
Zipcodes['30531'] = [34.512602, -83.592206];
Zipcodes['30533'] = [34.570958, -84.013764];
Zipcodes['30534'] = [34.4311, -84.144329];
Zipcodes['30535'] = [34.573655, -83.574769];
Zipcodes['30536'] = [34.652002, -84.359442];
Zipcodes['30537'] = [34.97907, -83.331499];
Zipcodes['30538'] = [34.500986, -83.263844];
Zipcodes['30539'] = [34.680607, -84.470127];
Zipcodes['30540'] = [34.728127, -84.543528];
Zipcodes['30541'] = [34.917258, -84.517744];
Zipcodes['30542'] = [34.179847, -83.910081];
Zipcodes['30543'] = [34.301301, -83.653747];
Zipcodes['30545'] = [34.762318, -83.762118];
Zipcodes['30546'] = [34.908448, -83.717767];
Zipcodes['30547'] = [34.362021, -83.455617];
Zipcodes['30548'] = [34.089396, -83.763599];
Zipcodes['30549'] = [34.107959, -83.577478];
Zipcodes['30552'] = [34.773831, -83.470714];
Zipcodes['30553'] = [34.443759, -83.084067];
Zipcodes['30554'] = [34.398088, -83.661905];
Zipcodes['30555'] = [34.969398, -84.419515];
Zipcodes['30557'] = [34.479752, -83.184905];
Zipcodes['30558'] = [34.264049, -83.566829];
Zipcodes['30559'] = [34.95786, -84.274335];
Zipcodes['30560'] = [34.876771, -84.204706];
Zipcodes['30562'] = [34.918996, -83.38368];
Zipcodes['30563'] = [34.55896, -83.461828];
Zipcodes['30564'] = [34.463965, -83.889312];
Zipcodes['30565'] = [34.086204, -83.411482];
Zipcodes['30566'] = [34.238652, -83.896845];
Zipcodes['30567'] = [34.180798, -83.676554];
Zipcodes['30568'] = [34.954844, -83.411111];
Zipcodes['30571'] = [34.71628, -83.68215];
Zipcodes['30572'] = [34.735793, -84.072507];
Zipcodes['30573'] = [34.741262, -83.393476];
Zipcodes['30575'] = [34.207594, -83.713441];
Zipcodes['30576'] = [34.834589, -83.452139];
Zipcodes['30577'] = [34.556243, -83.328905];
Zipcodes['30581'] = [34.796301, -83.424477];
Zipcodes['30582'] = [34.956451, -83.909789];
Zipcodes['30597'] = [34.527577, -83.984589];
Zipcodes['30598'] = [34.5966, -83.354162];
Zipcodes['30601'] = [33.999151, -83.346993];
Zipcodes['30602'] = [33.943919, -83.373855];
Zipcodes['30605'] = [33.904289, -83.325592];
Zipcodes['30606'] = [33.940299, -83.433583];
Zipcodes['30607'] = [34.017305, -83.447551];
Zipcodes['30609'] = [33.933102, -83.372322];
Zipcodes['30619'] = [33.855797, -83.244711];
Zipcodes['30620'] = [33.929472, -83.758457];
Zipcodes['30621'] = [33.796813, -83.471175];
Zipcodes['30622'] = [33.921282, -83.53083];
Zipcodes['30623'] = [33.728681, -83.513005];
Zipcodes['30624'] = [34.181024, -83.037941];
Zipcodes['30625'] = [33.520284, -83.33353];
Zipcodes['30627'] = [33.972176, -82.963748];
Zipcodes['30628'] = [34.032089, -83.215974];
Zipcodes['30629'] = [34.077983, -83.110367];
Zipcodes['30630'] = [33.90036, -83.145329];
Zipcodes['30631'] = [33.558262, -82.866495];
Zipcodes['30633'] = [34.180903, -83.249172];
Zipcodes['30634'] = [34.202177, -82.942226];
Zipcodes['30635'] = [34.105769, -82.793148];
Zipcodes['30639'] = [34.282737, -83.152397];
Zipcodes['30641'] = [33.769567, -83.56475];
Zipcodes['30642'] = [33.544289, -83.183971];
Zipcodes['30643'] = [34.365452, -82.911872];
Zipcodes['30646'] = [34.085682, -83.302513];
Zipcodes['30648'] = [33.870769, -83.038175];
Zipcodes['30650'] = [33.590645, -83.467554];
Zipcodes['30655'] = [33.780196, -83.692512];
Zipcodes['30656'] = [33.862137, -83.716175];
Zipcodes['30660'] = [33.782663, -82.931267];
Zipcodes['30662'] = [34.266966, -83.137564];
Zipcodes['30663'] = [33.615505, -83.600295];
Zipcodes['30664'] = [33.556259, -82.798319];
Zipcodes['30665'] = [33.537349, -83.073991];
Zipcodes['30666'] = [33.962087, -83.584602];
Zipcodes['30667'] = [33.796889, -83.175513];
Zipcodes['30668'] = [33.895747, -82.697208];
Zipcodes['30669'] = [33.676431, -83.118117];
Zipcodes['30673'] = [33.72809, -82.716244];
Zipcodes['30677'] = [33.783202, -83.373342];
Zipcodes['30678'] = [33.453017, -83.065918];
Zipcodes['30680'] = [33.995173, -83.697488];
Zipcodes['30683'] = [33.946841, -83.257109];
Zipcodes['30701'] = [34.494395, -84.954177];
Zipcodes['30705'] = [34.747542, -84.748401];
Zipcodes['30707'] = [34.778911, -85.3653];
Zipcodes['30708'] = [34.939899, -84.667565];
Zipcodes['30710'] = [34.938823, -84.917315];
Zipcodes['30711'] = [34.931766, -84.768643];
Zipcodes['30720'] = [34.733645, -85.000824];
Zipcodes['30721'] = [34.792977, -84.908686];
Zipcodes['30724'] = [34.825178, -84.757885];
Zipcodes['30725'] = [34.924777, -85.352675];
Zipcodes['30726'] = [34.974872, -85.141048];
Zipcodes['30728'] = [34.684875, -85.22579];
Zipcodes['30730'] = [34.36377, -85.416023];
Zipcodes['30731'] = [34.564178, -85.471885];
Zipcodes['30733'] = [34.414593, -85.050554];
Zipcodes['30734'] = [34.532804, -84.714916];
Zipcodes['30735'] = [34.602883, -84.882661];
Zipcodes['30736'] = [34.902401, -85.134056];
Zipcodes['30738'] = [34.796552, -85.480936];
Zipcodes['30739'] = [34.805906, -85.215958];
Zipcodes['30740'] = [34.757368, -85.072012];
Zipcodes['30741'] = [34.952695, -85.281295];
Zipcodes['30742'] = [34.935597, -85.244842];
Zipcodes['30746'] = [34.592095, -85.032872];
Zipcodes['30747'] = [34.504087, -85.292786];
Zipcodes['30750'] = [34.936814, -85.388204];
Zipcodes['30751'] = [34.984504, -84.73269];
Zipcodes['30752'] = [34.914982, -85.537961];
Zipcodes['30753'] = [34.58228, -85.2858];
Zipcodes['30755'] = [34.869366, -85.040664];
Zipcodes['30756'] = [34.901053, -84.97459];
Zipcodes['30757'] = [34.935801, -85.441516];
Zipcodes['30802'] = [33.601366, -82.299936];
Zipcodes['30803'] = [33.133462, -82.549151];
Zipcodes['30805'] = [33.279569, -82.191791];
Zipcodes['30807'] = [33.457408, -82.648621];
Zipcodes['30808'] = [33.384114, -82.391923];
Zipcodes['30809'] = [33.558527, -82.171185];
Zipcodes['30810'] = [33.232044, -82.587508];
Zipcodes['30812'] = [33.367452, -82.029067];
Zipcodes['30813'] = [33.466744, -82.228209];
Zipcodes['30814'] = [33.431091, -82.318849];
Zipcodes['30815'] = [33.284927, -82.072213];
Zipcodes['30816'] = [33.163379, -82.176208];
Zipcodes['30817'] = [33.778388, -82.430194];
Zipcodes['30818'] = [33.26956, -82.326081];
Zipcodes['30820'] = [33.222359, -82.724536];
Zipcodes['30821'] = [33.492107, -82.737994];
Zipcodes['30822'] = [32.919257, -81.944353];
Zipcodes['30823'] = [33.222042, -82.456142];
Zipcodes['30824'] = [33.512953, -82.514855];
Zipcodes['30828'] = [33.368038, -82.656312];
Zipcodes['30830'] = [33.085111, -81.978661];
Zipcodes['30833'] = [33.19125, -82.353132];
Zipcodes['30901'] = [33.437213, -81.956156];
Zipcodes['30904'] = [33.478289, -82.014102];
Zipcodes['30905'] = [33.411494, -82.149106];
Zipcodes['30906'] = [33.346732, -81.967703];
Zipcodes['30907'] = [33.523712, -82.085665];
Zipcodes['30909'] = [33.470481, -82.083045];
Zipcodes['30912'] = [33.4702, -81.987917];
Zipcodes['31001'] = [31.976256, -83.339665];
Zipcodes['31002'] = [32.554049, -82.595322];
Zipcodes['31003'] = [32.596881, -83.222928];
Zipcodes['31004'] = [32.951665, -83.805316];
Zipcodes['31005'] = [32.544622, -83.593676];
Zipcodes['31006'] = [32.58072, -84.250738];
Zipcodes['31007'] = [32.188514, -83.927321];
Zipcodes['31008'] = [32.665355, -83.786087];
Zipcodes['31009'] = [32.270181, -83.000516];
Zipcodes['31011'] = [32.130626, -83.05227];
Zipcodes['31012'] = [32.394344, -83.173715];
Zipcodes['31014'] = [32.415886, -83.349077];
Zipcodes['31015'] = [31.943011, -83.778608];
Zipcodes['31016'] = [32.829319, -84.091558];
Zipcodes['31017'] = [32.621094, -83.23222];
Zipcodes['31018'] = [32.97649, -82.616752];
Zipcodes['31019'] = [32.424244, -83.051342];
Zipcodes['31020'] = [32.654482, -83.498453];
Zipcodes['31021'] = [32.489539, -82.934985];
Zipcodes['31022'] = [32.51154, -83.116631];
Zipcodes['31023'] = [32.183174, -83.199476];
Zipcodes['31024'] = [33.322978, -83.370015];
Zipcodes['31025'] = [32.335397, -83.740875];
Zipcodes['31027'] = [32.571946, -82.822614];
Zipcodes['31028'] = [32.63284, -83.68393];
Zipcodes['31029'] = [33.043571, -83.93535];
Zipcodes['31030'] = [32.568973, -83.89188];
Zipcodes['31031'] = [32.866286, -83.32834];
Zipcodes['31032'] = [33.011996, -83.573289];
Zipcodes['31033'] = [33.079307, -83.434007];
Zipcodes['31034'] = [33.054623, -83.225513];
Zipcodes['31035'] = [32.854123, -82.707822];
Zipcodes['31036'] = [32.270061, -83.492626];
Zipcodes['31037'] = [32.121561, -82.93628];
Zipcodes['31038'] = [33.142498, -83.621399];
Zipcodes['31039'] = [32.604098, -84.379104];
Zipcodes['31041'] = [32.382361, -84.167801];
Zipcodes['31042'] = [32.743643, -83.189114];
Zipcodes['31044'] = [32.656173, -83.390884];
Zipcodes['31045'] = [33.282948, -82.794508];
Zipcodes['31046'] = [33.054336, -83.796088];
Zipcodes['31047'] = [32.466866, -83.580995];
Zipcodes['31049'] = [32.678289, -82.523385];
Zipcodes['31050'] = [32.721537, -83.937295];
Zipcodes['31051'] = [32.143126, -83.8794];
Zipcodes['31052'] = [32.789015, -83.840075];
Zipcodes['31054'] = [32.889861, -83.214067];
Zipcodes['31055'] = [31.985895, -82.883139];
Zipcodes['31057'] = [32.429781, -83.944276];
Zipcodes['31058'] = [32.488651, -84.410396];
Zipcodes['31060'] = [31.986445, -83.05635];
Zipcodes['31061'] = [33.059054, -83.226413];
Zipcodes['31062'] = [33.043372, -83.215801];
Zipcodes['31063'] = [32.288966, -83.96326];
Zipcodes['31064'] = [33.286454, -83.702299];
Zipcodes['31065'] = [32.564115, -83.188491];
Zipcodes['31066'] = [32.80241, -83.97815];
Zipcodes['31067'] = [32.852296, -82.961654];
Zipcodes['31068'] = [32.332742, -84.111143];
Zipcodes['31069'] = [32.440068, -83.748603];
Zipcodes['31070'] = [32.18658, -83.789771];
Zipcodes['31071'] = [32.129447, -83.540846];
Zipcodes['31072'] = [31.974465, -83.56684];
Zipcodes['31075'] = [32.360956, -82.954646];
Zipcodes['31076'] = [32.549678, -84.104974];
Zipcodes['31077'] = [31.942307, -83.169758];
Zipcodes['31078'] = [32.694814, -84.075661];
Zipcodes['31079'] = [31.942527, -83.46341];
Zipcodes['31081'] = [32.412399, -84.284047];
Zipcodes['31082'] = [32.996417, -82.903117];
Zipcodes['31083'] = [32.044134, -82.822001];
Zipcodes['31084'] = [31.96152, -83.602557];
Zipcodes['31085'] = [33.427349, -83.6277];
Zipcodes['31087'] = [33.251362, -83.001031];
Zipcodes['31088'] = [32.586171, -83.655082];
Zipcodes['31089'] = [32.850766, -82.860314];
Zipcodes['31090'] = [32.793582, -83.048446];
Zipcodes['31091'] = [32.237927, -83.704527];
Zipcodes['31092'] = [32.089661, -83.782665];
Zipcodes['31093'] = [32.647873, -83.657206];
Zipcodes['31094'] = [33.113614, -82.795759];
Zipcodes['31096'] = [32.723291, -82.707266];
Zipcodes['31097'] = [32.890411, -84.166895];
Zipcodes['31098'] = [32.614825, -83.578435];
Zipcodes['31201'] = [32.806321, -83.61786];
Zipcodes['31204'] = [32.848511, -83.674745];
Zipcodes['31206'] = [32.793366, -83.684573];
Zipcodes['31207'] = [32.828602, -83.649299];
Zipcodes['31210'] = [32.907633, -83.735052];
Zipcodes['31211'] = [32.904267, -83.598526];
Zipcodes['31213'] = [32.840081, -83.63935];
Zipcodes['31216'] = [32.732373, -83.671449];
Zipcodes['31217'] = [32.844147, -83.500044];
Zipcodes['31220'] = [32.884462, -83.818148];
Zipcodes['31301'] = [31.758253, -81.605053];
Zipcodes['31302'] = [32.125813, -81.340394];
Zipcodes['31303'] = [32.505422, -81.313427];
Zipcodes['31305'] = [31.367579, -81.378193];
Zipcodes['31307'] = [32.17641, -81.39761];
Zipcodes['31308'] = [32.180316, -81.47129];
Zipcodes['31309'] = [31.88045, -81.441172];
Zipcodes['31312'] = [32.318644, -81.400955];
Zipcodes['31313'] = [31.836598, -81.616774];
Zipcodes['31314'] = [31.906922, -81.617511];
Zipcodes['31315'] = [31.887679, -81.606009];
Zipcodes['31316'] = [31.719283, -81.733272];
Zipcodes['31318'] = [32.134838, -81.372263];
Zipcodes['31320'] = [31.765504, -81.368411];
Zipcodes['31321'] = [32.185062, -81.659794];
Zipcodes['31322'] = [32.101989, -81.255379];
Zipcodes['31323'] = [31.668385, -81.454187];
Zipcodes['31324'] = [31.850203, -81.264824];
Zipcodes['31326'] = [32.296613, -81.222653];
Zipcodes['31327'] = [31.423165, -81.290082];
Zipcodes['31328'] = [32.00433, -80.890787];
Zipcodes['31329'] = [32.427261, -81.350003];
Zipcodes['31331'] = [31.490245, -81.421511];
Zipcodes['31333'] = [31.767828, -81.627458];
Zipcodes['31401'] = [32.068296, -81.092835];
Zipcodes['31404'] = [32.05075, -81.052961];
Zipcodes['31405'] = [32.039402, -81.179112];
Zipcodes['31406'] = [31.980404, -81.085184];
Zipcodes['31407'] = [32.183218, -81.197935];
Zipcodes['31408'] = [32.103956, -81.180597];
Zipcodes['31409'] = [32.015561, -81.14483];
Zipcodes['31410'] = [32.014884, -80.988234];
Zipcodes['31411'] = [31.937542, -81.034252];
Zipcodes['31415'] = [32.08064, -81.127217];
Zipcodes['31419'] = [31.984562, -81.23695];
Zipcodes['31421'] = [32.086966, -81.078188];
Zipcodes['31501'] = [31.227596, -82.347944];
Zipcodes['31503'] = [31.176879, -82.401293];
Zipcodes['31510'] = [31.546546, -82.433529];
Zipcodes['31512'] = [31.545696, -83.018356];
Zipcodes['31513'] = [31.777421, -82.346004];
Zipcodes['31516'] = [31.309371, -82.25326];
Zipcodes['31518'] = [31.512607, -82.185093];
Zipcodes['31519'] = [31.668124, -82.869034];
Zipcodes['31520'] = [31.178078, -81.487563];
Zipcodes['31522'] = [31.257252, -81.342026];
Zipcodes['31523'] = [31.212645, -81.616811];
Zipcodes['31524'] = [31.240597, -81.473387];
Zipcodes['31525'] = [31.320999, -81.52644];
Zipcodes['31527'] = [31.075118, -81.416005];
Zipcodes['31532'] = [31.726415, -82.745735];
Zipcodes['31533'] = [31.562738, -82.82902];
Zipcodes['31535'] = [31.459541, -82.867327];
Zipcodes['31537'] = [30.926545, -82.176473];
Zipcodes['31539'] = [31.813918, -82.602233];
Zipcodes['31542'] = [31.142701, -82.119407];
Zipcodes['31543'] = [31.326469, -81.869695];
Zipcodes['31544'] = [31.840482, -82.963883];
Zipcodes['31545'] = [31.57256, -81.990861];
Zipcodes['31546'] = [31.530265, -81.794871];
Zipcodes['31547'] = [30.790347, -81.535288];
Zipcodes['31548'] = [30.79584, -81.712363];
Zipcodes['31549'] = [31.921441, -82.729933];
Zipcodes['31550'] = [31.14708, -82.595623];
Zipcodes['31551'] = [31.488281, -82.266768];
Zipcodes['31552'] = [31.289898, -82.599146];
Zipcodes['31553'] = [31.166379, -81.982418];
Zipcodes['31554'] = [31.485797, -82.620517];
Zipcodes['31555'] = [31.703058, -82.069441];
Zipcodes['31556'] = [31.40505, -82.115244];
Zipcodes['31557'] = [31.375274, -82.098837];
Zipcodes['31558'] = [30.802289, -81.507674];
Zipcodes['31560'] = [31.505609, -82.029604];
Zipcodes['31561'] = [31.199121, -81.328637];
Zipcodes['31562'] = [30.47534, -82.103897];
Zipcodes['31563'] = [31.738186, -82.177843];
Zipcodes['31564'] = [31.248716, -82.470948];
Zipcodes['31565'] = [31.070708, -81.643418];
Zipcodes['31566'] = [31.182755, -81.805594];
Zipcodes['31567'] = [31.630886, -82.706228];
Zipcodes['31568'] = [31.01339, -81.819102];
Zipcodes['31569'] = [30.93378, -81.737076];
Zipcodes['31599'] = [31.562748, -81.901734];
Zipcodes['31601'] = [30.775522, -83.3336];
Zipcodes['31602'] = [30.86816, -83.34235];
Zipcodes['31605'] = [30.932951, -83.221938];
Zipcodes['31606'] = [30.798662, -83.179881];
Zipcodes['31620'] = [31.117296, -83.434236];
Zipcodes['31622'] = [31.372347, -83.199344];
Zipcodes['31623'] = [31.071366, -82.64404];
Zipcodes['31624'] = [31.296189, -82.730338];
Zipcodes['31625'] = [30.989342, -83.52251];
Zipcodes['31626'] = [30.77561, -83.803086];
Zipcodes['31627'] = [31.039606, -83.392113];
Zipcodes['31629'] = [30.816844, -83.689394];
Zipcodes['31630'] = [30.924212, -82.860039];
Zipcodes['31631'] = [30.708217, -82.644358];
Zipcodes['31632'] = [30.98626, -83.3549];
Zipcodes['31634'] = [31.03051, -82.732846];
Zipcodes['31635'] = [31.096894, -83.048473];
Zipcodes['31636'] = [30.730986, -83.111372];
Zipcodes['31637'] = [31.295961, -83.440219];
Zipcodes['31638'] = [30.906417, -83.512186];
Zipcodes['31639'] = [31.213259, -83.190862];
Zipcodes['31641'] = [30.921011, -83.093576];
Zipcodes['31642'] = [31.262789, -82.883197];
Zipcodes['31643'] = [30.773089, -83.556898];
Zipcodes['31645'] = [31.060719, -83.218688];
Zipcodes['31647'] = [31.211229, -83.43169];
Zipcodes['31648'] = [30.708359, -83.00837];
Zipcodes['31649'] = [30.9405, -83.013889];
Zipcodes['31650'] = [31.370002, -83.018941];
Zipcodes['31698'] = [30.848992, -83.289627];
Zipcodes['31699'] = [30.969613, -83.194305];
Zipcodes['31701'] = [31.573054, -84.154783];
Zipcodes['31704'] = [31.549679, -84.055507];
Zipcodes['31705'] = [31.51689, -84.042942];
Zipcodes['31707'] = [31.586919, -84.206431];
Zipcodes['31709'] = [32.046416, -84.149578];
Zipcodes['31711'] = [32.187712, -84.122162];
Zipcodes['31712'] = [31.819358, -83.719208];
Zipcodes['31714'] = [31.708107, -83.678402];
Zipcodes['31716'] = [31.366109, -84.110732];
Zipcodes['31719'] = [32.090308, -84.310747];
Zipcodes['31720'] = [30.878154, -83.724572];
Zipcodes['31721'] = [31.52526, -84.301465];
Zipcodes['31722'] = [31.048275, -83.610855];
Zipcodes['31727'] = [31.417779, -83.387396];
Zipcodes['31730'] = [31.210871, -84.280647];
Zipcodes['31733'] = [31.59319, -83.489763];
Zipcodes['31735'] = [31.969555, -83.973306];
Zipcodes['31738'] = [31.00815, -83.879727];
Zipcodes['31743'] = [31.896489, -84.00748];
Zipcodes['31744'] = [31.340289, -83.903131];
Zipcodes['31747'] = [31.174618, -83.584923];
Zipcodes['31749'] = [31.420536, -83.33012];
Zipcodes['31750'] = [31.736465, -83.215037];
Zipcodes['31753'] = [31.200446, -83.872846];
Zipcodes['31756'] = [31.176891, -83.962264];
Zipcodes['31757'] = [30.871395, -83.903125];
Zipcodes['31763'] = [31.736137, -84.148186];
Zipcodes['31764'] = [31.976312, -84.088839];
Zipcodes['31765'] = [31.087551, -84.027154];
Zipcodes['31768'] = [31.189103, -83.841107];
Zipcodes['31771'] = [31.248142, -83.654152];
Zipcodes['31772'] = [31.724154, -83.961027];
Zipcodes['31773'] = [30.977698, -84.045849];
Zipcodes['31774'] = [31.565466, -83.263747];
Zipcodes['31775'] = [31.334299, -83.598864];
Zipcodes['31778'] = [30.974992, -83.700075];
Zipcodes['31779'] = [31.090181, -84.191684];
Zipcodes['31780'] = [32.027683, -84.391222];
Zipcodes['31781'] = [31.560566, -83.796317];
Zipcodes['31783'] = [31.758976, -83.470125];
Zipcodes['31784'] = [31.25245, -84.048457];
Zipcodes['31787'] = [31.895613, -84.248185];
Zipcodes['31788'] = [31.111533, -83.683892];
Zipcodes['31789'] = [31.483306, -83.733939];
Zipcodes['31790'] = [31.652476, -83.577968];
Zipcodes['31791'] = [31.528949, -83.894253];
Zipcodes['31792'] = [30.765554, -84.043154];
Zipcodes['31793'] = [31.461884, -83.58121];
Zipcodes['31794'] = [31.445872, -83.452667];
Zipcodes['31795'] = [31.434566, -83.639822];
Zipcodes['31796'] = [31.771231, -83.875011];
Zipcodes['31798'] = [31.644434, -83.047304];
Zipcodes['31801'] = [32.519338, -84.594755];
Zipcodes['31803'] = [32.307436, -84.517208];
Zipcodes['31804'] = [32.664402, -84.878409];
Zipcodes['31805'] = [32.263056, -84.739509];
Zipcodes['31806'] = [32.260168, -84.299506];
Zipcodes['31807'] = [32.644701, -84.804532];
Zipcodes['31808'] = [32.63161, -85.01055];
Zipcodes['31810'] = [32.568261, -84.524881];
Zipcodes['31811'] = [32.737356, -84.937436];
Zipcodes['31812'] = [32.634887, -84.42509];
Zipcodes['31814'] = [32.19736, -84.864974];
Zipcodes['31815'] = [32.041804, -84.856054];
Zipcodes['31816'] = [32.878917, -84.601311];
Zipcodes['31820'] = [32.575774, -84.829099];
Zipcodes['31821'] = [32.142468, -84.974694];
Zipcodes['31822'] = [32.864979, -84.910348];
Zipcodes['31823'] = [32.797846, -84.811418];
Zipcodes['31824'] = [32.05791, -84.520625];
Zipcodes['31825'] = [32.116195, -84.6709];
Zipcodes['31826'] = [32.800237, -84.703065];
Zipcodes['31827'] = [32.695522, -84.503751];
Zipcodes['31829'] = [32.566221, -84.725442];
Zipcodes['31830'] = [32.900708, -84.729063];
Zipcodes['31831'] = [32.666859, -84.704799];
Zipcodes['31832'] = [31.958169, -84.614896];
Zipcodes['31833'] = [32.8429, -85.091845];
Zipcodes['31836'] = [32.796402, -84.53509];
Zipcodes['31901'] = [32.46435, -84.980144];
Zipcodes['31903'] = [32.416037, -84.952525];
Zipcodes['31904'] = [32.55434, -85.009469];
Zipcodes['31905'] = [32.372789, -84.843361];
Zipcodes['31906'] = [32.467811, -84.950173];
Zipcodes['31907'] = [32.484404, -84.899945];
Zipcodes['31909'] = [32.548807, -84.924106];
Zipcodes['32003'] = [30.095584, -81.710162];
Zipcodes['32008'] = [29.90792, -82.897465];
Zipcodes['32009'] = [30.422502, -81.973824];
Zipcodes['32011'] = [30.569778, -81.8405];
Zipcodes['32013'] = [30.193666, -83.291204];
Zipcodes['32024'] = [30.091975, -82.73112];
Zipcodes['32025'] = [30.104026, -82.566168];
Zipcodes['32026'] = [30.059065, -82.190472];
Zipcodes['32033'] = [29.787856, -81.446744];
Zipcodes['32034'] = [30.601794, -81.481799];
Zipcodes['32038'] = [29.934936, -82.692234];
Zipcodes['32040'] = [30.247032, -82.23533];
Zipcodes['32043'] = [29.926711, -81.743631];
Zipcodes['32044'] = [29.857895, -82.178708];
Zipcodes['32046'] = [30.701152, -81.916587];
Zipcodes['32052'] = [30.49054, -82.944838];
Zipcodes['32053'] = [30.56884, -83.15187];
Zipcodes['32054'] = [30.033807, -82.394253];
Zipcodes['32055'] = [30.270103, -82.62413];
Zipcodes['32058'] = [30.107546, -82.106933];
Zipcodes['32059'] = [30.384943, -83.25597];
Zipcodes['32060'] = [30.274413, -83.036329];
Zipcodes['32061'] = [30.083085, -82.516655];
Zipcodes['32062'] = [30.137457, -82.978338];
Zipcodes['32063'] = [30.270901, -82.120398];
Zipcodes['32064'] = [30.293277, -82.991953];
Zipcodes['32065'] = [30.175071, -81.871668];
Zipcodes['32066'] = [30.039764, -83.205806];
Zipcodes['32068'] = [30.082951, -81.884022];
Zipcodes['32071'] = [30.058101, -82.955828];
Zipcodes['32072'] = [30.184037, -82.423425];
Zipcodes['32073'] = [30.167711, -81.739816];
Zipcodes['32079'] = [29.977993, -81.807021];
Zipcodes['32080'] = [29.816985, -81.265123];
Zipcodes['32081'] = [30.119606, -81.426613];
Zipcodes['32082'] = [30.111553, -81.371747];
Zipcodes['32083'] = [30.114597, -82.232781];
Zipcodes['32084'] = [29.913733, -81.365292];
Zipcodes['32086'] = [29.740948, -81.30016];
Zipcodes['32087'] = [30.364971, -82.298674];
Zipcodes['32091'] = [29.933025, -82.113403];
Zipcodes['32092'] = [29.929252, -81.521904];
Zipcodes['32094'] = [30.186943, -82.82182];
Zipcodes['32095'] = [30.027547, -81.436962];
Zipcodes['32096'] = [30.53438, -82.626965];
Zipcodes['32097'] = [30.662149, -81.648914];
Zipcodes['32102'] = [29.13842, -81.523078];
Zipcodes['32110'] = [29.444708, -81.352315];
Zipcodes['32112'] = [29.431746, -81.565573];
Zipcodes['32113'] = [29.409977, -82.089218];
Zipcodes['32114'] = [29.192391, -81.04676];
Zipcodes['32117'] = [29.232739, -81.068471];
Zipcodes['32118'] = [29.209639, -81.002321];
Zipcodes['32119'] = [29.160962, -81.025593];
Zipcodes['32124'] = [29.159517, -81.158803];
Zipcodes['32127'] = [29.108464, -80.975738];
Zipcodes['32128'] = [29.100739, -81.071829];
Zipcodes['32129'] = [29.136974, -81.023957];
Zipcodes['32130'] = [29.148342, -81.341043];
Zipcodes['32131'] = [29.677863, -81.563722];
Zipcodes['32132'] = [28.980108, -80.920894];
Zipcodes['32133'] = [29.01214, -81.914706];
Zipcodes['32134'] = [29.377165, -81.827585];
Zipcodes['32136'] = [29.463763, -81.147395];
Zipcodes['32137'] = [29.575688, -81.223832];
Zipcodes['32139'] = [29.377178, -81.613019];
Zipcodes['32140'] = [29.762604, -81.866657];
Zipcodes['32141'] = [28.928812, -80.919083];
Zipcodes['32145'] = [29.677558, -81.421332];
Zipcodes['32147'] = [29.634671, -81.787036];
Zipcodes['32148'] = [29.601157, -81.882919];
Zipcodes['32157'] = [29.461528, -81.587765];
Zipcodes['32159'] = [28.918072, -81.897655];
Zipcodes['32162'] = [28.922305, -81.999287];
Zipcodes['32163'] = [28.841403, -81.959215];
Zipcodes['32164'] = [29.480607, -81.217791];
Zipcodes['32168'] = [28.994646, -81.024439];
Zipcodes['32169'] = [28.975536, -80.867376];
Zipcodes['32174'] = [29.292191, -81.161446];
Zipcodes['32176'] = [29.33538, -81.064459];
Zipcodes['32177'] = [29.697699, -81.703239];
Zipcodes['32179'] = [29.095193, -81.899934];
Zipcodes['32180'] = [29.240997, -81.442807];
Zipcodes['32181'] = [29.507981, -81.601987];
Zipcodes['32182'] = [29.509246, -81.941044];
Zipcodes['32187'] = [29.578946, -81.550038];
Zipcodes['32189'] = [29.54984, -81.647696];
Zipcodes['32190'] = [29.337688, -81.492643];
Zipcodes['32192'] = [29.350183, -82.111066];
Zipcodes['32193'] = [29.477372, -81.663606];
Zipcodes['32195'] = [28.98808, -81.886336];
Zipcodes['32202'] = [30.32462, -81.647232];
Zipcodes['32203'] = [30.33702, -81.671293];
Zipcodes['32204'] = [30.317018, -81.680832];
Zipcodes['32205'] = [30.302454, -81.723043];
Zipcodes['32206'] = [30.351115, -81.63801];
Zipcodes['32207'] = [30.289637, -81.640966];
Zipcodes['32208'] = [30.39309, -81.682946];
Zipcodes['32209'] = [30.361015, -81.696254];
Zipcodes['32210'] = [30.265455, -81.748096];
Zipcodes['32211'] = [30.33152, -81.582697];
Zipcodes['32212'] = [30.223367, -81.672965];
Zipcodes['32214'] = [30.207684, -81.683226];
Zipcodes['32216'] = [30.281083, -81.581668];
Zipcodes['32217'] = [30.22918, -81.621158];
Zipcodes['32218'] = [30.487842, -81.667693];
Zipcodes['32219'] = [30.426654, -81.815005];
Zipcodes['32220'] = [30.335003, -81.855324];
Zipcodes['32221'] = [30.258573, -81.878191];
Zipcodes['32222'] = [30.208918, -81.831013];
Zipcodes['32223'] = [30.156574, -81.649405];
Zipcodes['32224'] = [30.269971, -81.460939];
Zipcodes['32225'] = [30.357756, -81.505829];
Zipcodes['32226'] = [30.481248, -81.506157];
Zipcodes['32227'] = [30.389189, -81.404408];
Zipcodes['32228'] = [30.39186, -81.400074];
Zipcodes['32233'] = [30.357338, -81.416992];
Zipcodes['32234'] = [30.226305, -82.004356];
Zipcodes['32244'] = [30.217099, -81.752604];
Zipcodes['32246'] = [30.291014, -81.512086];
Zipcodes['32250'] = [30.28049, -81.412038];
Zipcodes['32254'] = [30.340961, -81.734788];
Zipcodes['32256'] = [30.175327, -81.471951];
Zipcodes['32257'] = [30.189355, -81.612466];
Zipcodes['32258'] = [30.140712, -81.551712];
Zipcodes['32259'] = [30.074317, -81.585207];
Zipcodes['32266'] = [30.317386, -81.409066];
Zipcodes['32277'] = [30.37745, -81.593947];
Zipcodes['32301'] = [30.427618, -84.257753];
Zipcodes['32303'] = [30.512386, -84.344877];
Zipcodes['32304'] = [30.451981, -84.352682];
Zipcodes['32305'] = [30.349872, -84.300765];
Zipcodes['32306'] = [30.442319, -84.294482];
Zipcodes['32307'] = [30.42172, -84.286851];
Zipcodes['32308'] = [30.480911, -84.219746];
Zipcodes['32309'] = [30.58125, -84.104073];
Zipcodes['32310'] = [30.433038, -84.391845];
Zipcodes['32311'] = [30.39005, -84.181035];
Zipcodes['32312'] = [30.604278, -84.243286];
Zipcodes['32313'] = [30.443147, -84.300736];
Zipcodes['32317'] = [30.470481, -84.116694];
Zipcodes['32320'] = [29.731534, -85.054224];
Zipcodes['32321'] = [30.26275, -84.99775];
Zipcodes['32322'] = [29.864047, -84.631996];
Zipcodes['32323'] = [29.891511, -84.613733];
Zipcodes['32324'] = [30.654759, -84.783247];
Zipcodes['32327'] = [30.181534, -84.302685];
Zipcodes['32328'] = [29.80043, -84.896825];
Zipcodes['32330'] = [30.578634, -84.747993];
Zipcodes['32331'] = [30.454744, -83.577769];
Zipcodes['32332'] = [30.610125, -84.66511];
Zipcodes['32333'] = [30.607743, -84.417592];
Zipcodes['32334'] = [30.259321, -84.758416];
Zipcodes['32336'] = [30.263057, -83.865499];
Zipcodes['32337'] = [30.483148, -84.021121];
Zipcodes['32340'] = [30.448953, -83.434802];
Zipcodes['32343'] = [30.478331, -84.504689];
Zipcodes['32344'] = [30.444549, -83.925998];
Zipcodes['32346'] = [29.982274, -84.380578];
Zipcodes['32347'] = [30.150653, -83.640744];
Zipcodes['32348'] = [29.942417, -83.556926];
Zipcodes['32350'] = [30.574972, -83.325794];
Zipcodes['32351'] = [30.520125, -84.675081];
Zipcodes['32352'] = [30.662261, -84.591627];
Zipcodes['32355'] = [30.16907, -84.209318];
Zipcodes['32356'] = [29.859235, -83.415003];
Zipcodes['32358'] = [30.091044, -84.540694];
Zipcodes['32359'] = [29.650039, -83.376036];
Zipcodes['32360'] = [30.365008, -84.812364];
Zipcodes['32361'] = [30.357645, -83.98866];
Zipcodes['32399'] = [30.383268, -84.231203];
Zipcodes['32401'] = [30.158374, -85.637996];
Zipcodes['32403'] = [30.048562, -85.553231];
Zipcodes['32404'] = [30.185025, -85.507385];
Zipcodes['32405'] = [30.199378, -85.668605];
Zipcodes['32407'] = [30.198324, -85.790694];
Zipcodes['32408'] = [30.156773, -85.754657];
Zipcodes['32409'] = [30.34127, -85.712606];
Zipcodes['32410'] = [29.951994, -85.428336];
Zipcodes['32413'] = [30.305727, -85.886321];
Zipcodes['32420'] = [30.635631, -85.381706];
Zipcodes['32421'] = [30.521467, -85.205731];
Zipcodes['32423'] = [30.943777, -85.048909];
Zipcodes['32424'] = [30.390602, -85.081807];
Zipcodes['32425'] = [30.853184, -85.720492];
Zipcodes['32426'] = [30.953978, -85.367431];
Zipcodes['32427'] = [30.710487, -85.792309];
Zipcodes['32428'] = [30.61575, -85.572945];
Zipcodes['32430'] = [30.418696, -85.225596];
Zipcodes['32431'] = [30.787482, -85.410816];
Zipcodes['32432'] = [30.714114, -85.077237];
Zipcodes['32433'] = [30.851178, -86.200838];
Zipcodes['32435'] = [30.648635, -86.130197];
Zipcodes['32437'] = [30.436248, -85.909221];
Zipcodes['32438'] = [30.511651, -85.414931];
Zipcodes['32439'] = [30.483268, -86.130423];
Zipcodes['32440'] = [30.922426, -85.52619];
Zipcodes['32442'] = [30.686518, -85.008627];
Zipcodes['32443'] = [30.872521, -85.10942];
Zipcodes['32444'] = [30.238451, -85.651586];
Zipcodes['32445'] = [30.97052, -85.197338];
Zipcodes['32446'] = [30.84817, -85.231368];
Zipcodes['32448'] = [30.671012, -85.223312];
Zipcodes['32449'] = [30.268296, -85.218138];
Zipcodes['32455'] = [30.638626, -85.962701];
Zipcodes['32456'] = [29.814542, -85.306423];
Zipcodes['32459'] = [30.360577, -86.185591];
Zipcodes['32460'] = [30.764089, -84.954234];
Zipcodes['32461'] = [30.308898, -86.054011];
Zipcodes['32462'] = [30.561812, -85.82373];
Zipcodes['32463'] = [30.628041, -85.58734];
Zipcodes['32464'] = [30.881353, -85.955991];
Zipcodes['32465'] = [30.007868, -85.181889];
Zipcodes['32466'] = [30.388401, -85.465062];
Zipcodes['32501'] = [30.428781, -87.222515];
Zipcodes['32502'] = [30.408929, -87.226813];
Zipcodes['32503'] = [30.459667, -87.213643];
Zipcodes['32504'] = [30.473121, -87.186316];
Zipcodes['32505'] = [30.454624, -87.26065];
Zipcodes['32506'] = [30.391889, -87.371994];
Zipcodes['32507'] = [30.339189, -87.378231];
Zipcodes['32508'] = [30.350614, -87.316902];
Zipcodes['32509'] = [30.469744, -87.340363];
Zipcodes['32511'] = [30.40635, -87.291321];
Zipcodes['32512'] = [30.398995, -87.299246];
Zipcodes['32514'] = [30.53218, -87.222539];
Zipcodes['32526'] = [30.499226, -87.365573];
Zipcodes['32530'] = [30.597113, -87.020019];
Zipcodes['32531'] = [30.898091, -86.693071];
Zipcodes['32533'] = [30.612555, -87.324338];
Zipcodes['32534'] = [30.528304, -87.286197];
Zipcodes['32535'] = [30.963698, -87.346175];
Zipcodes['32536'] = [30.750432, -86.597598];
Zipcodes['32539'] = [30.778999, -86.477169];
Zipcodes['32541'] = [30.395159, -86.478784];
Zipcodes['32542'] = [30.507766, -86.541629];
Zipcodes['32544'] = [30.426372, -86.719287];
Zipcodes['32547'] = [30.466178, -86.652985];
Zipcodes['32548'] = [30.413213, -86.589259];
Zipcodes['32550'] = [30.381433, -86.345668];
Zipcodes['32561'] = [30.340667, -87.116593];
Zipcodes['32563'] = [30.390852, -87.024785];
Zipcodes['32564'] = [30.728808, -86.781915];
Zipcodes['32565'] = [30.89438, -87.145454];
Zipcodes['32566'] = [30.425302, -86.892346];
Zipcodes['32567'] = [30.921061, -86.431503];
Zipcodes['32568'] = [30.877835, -87.47486];
Zipcodes['32569'] = [30.41138, -86.728401];
Zipcodes['32570'] = [30.791549, -86.982974];
Zipcodes['32571'] = [30.673287, -87.19585];
Zipcodes['32577'] = [30.740981, -87.379367];
Zipcodes['32578'] = [30.507855, -86.448368];
Zipcodes['32579'] = [30.445672, -86.574308];
Zipcodes['32580'] = [30.506542, -86.51469];
Zipcodes['32583'] = [30.604882, -86.972179];
Zipcodes['32601'] = [29.6487, -82.325412];
Zipcodes['32603'] = [29.653082, -82.350386];
Zipcodes['32605'] = [29.67918, -82.372822];
Zipcodes['32606'] = [29.682064, -82.444115];
Zipcodes['32607'] = [29.647333, -82.419155];
Zipcodes['32608'] = [29.59023, -82.396199];
Zipcodes['32609'] = [29.772187, -82.283422];
Zipcodes['32610'] = [29.64076, -82.343353];
Zipcodes['32612'] = [29.64312, -82.353845];
Zipcodes['32615'] = [29.81917, -82.486164];
Zipcodes['32616'] = [29.782044, -82.499316];
Zipcodes['32617'] = [29.308388, -82.074366];
Zipcodes['32618'] = [29.539756, -82.521492];
Zipcodes['32619'] = [29.756031, -82.86825];
Zipcodes['32621'] = [29.428967, -82.630916];
Zipcodes['32622'] = [29.900067, -82.307034];
Zipcodes['32625'] = [29.214738, -82.995429];
Zipcodes['32626'] = [29.423864, -82.899323];
Zipcodes['32628'] = [29.653159, -83.162979];
Zipcodes['32631'] = [29.728502, -82.113414];
Zipcodes['32634'] = [29.36466, -82.254275];
Zipcodes['32639'] = [29.232242, -82.721668];
Zipcodes['32640'] = [29.557729, -82.070774];
Zipcodes['32641'] = [29.647501, -82.239482];
Zipcodes['32643'] = [29.817981, -82.636103];
Zipcodes['32648'] = [29.500642, -83.238615];
Zipcodes['32653'] = [29.743875, -82.402776];
Zipcodes['32656'] = [29.827107, -81.945168];
Zipcodes['32658'] = [29.851858, -82.416554];
Zipcodes['32664'] = [29.449347, -82.221854];
Zipcodes['32666'] = [29.714185, -81.992515];
Zipcodes['32667'] = [29.489515, -82.304316];
Zipcodes['32668'] = [29.257242, -82.474721];
Zipcodes['32669'] = [29.632156, -82.589919];
Zipcodes['32680'] = [29.574911, -83.074406];
Zipcodes['32681'] = [29.426939, -82.215214];
Zipcodes['32683'] = [29.274237, -82.769519];
Zipcodes['32686'] = [29.355545, -82.275017];
Zipcodes['32692'] = [29.329319, -83.172494];
Zipcodes['32693'] = [29.626485, -82.785375];
Zipcodes['32694'] = [29.808061, -82.144376];
Zipcodes['32696'] = [29.400068, -82.469276];
Zipcodes['32697'] = [29.92816, -82.425529];
Zipcodes['32701'] = [28.665624, -81.370356];
Zipcodes['32702'] = [29.044189, -81.627473];
Zipcodes['32703'] = [28.653636, -81.520744];
Zipcodes['32707'] = [28.664104, -81.314908];
Zipcodes['32708'] = [28.688064, -81.269962];
Zipcodes['32709'] = [28.502489, -80.95883];
Zipcodes['32712'] = [28.736081, -81.547624];
Zipcodes['32713'] = [28.881398, -81.32427];
Zipcodes['32714'] = [28.662487, -81.411607];
Zipcodes['32720'] = [29.033304, -81.374753];
Zipcodes['32723'] = [29.034536, -81.304522];
Zipcodes['32724'] = [29.057205, -81.225576];
Zipcodes['32725'] = [28.886189, -81.251417];
Zipcodes['32726'] = [28.854136, -81.679937];
Zipcodes['32730'] = [28.652057, -81.34348];
Zipcodes['32732'] = [28.751037, -81.102119];
Zipcodes['32735'] = [28.890423, -81.739039];
Zipcodes['32736'] = [28.916675, -81.489093];
Zipcodes['32738'] = [28.904744, -81.188232];
Zipcodes['32744'] = [28.985234, -81.219583];
Zipcodes['32746'] = [28.771373, -81.353086];
Zipcodes['32750'] = [28.70865, -81.350482];
Zipcodes['32751'] = [28.6304, -81.365046];
Zipcodes['32754'] = [28.687109, -80.919193];
Zipcodes['32757'] = [28.776422, -81.631228];
Zipcodes['32759'] = [28.825537, -80.902691];
Zipcodes['32763'] = [28.942166, -81.306122];
Zipcodes['32764'] = [28.836668, -81.063065];
Zipcodes['32765'] = [28.665112, -81.19629];
Zipcodes['32766'] = [28.647675, -81.044691];
Zipcodes['32767'] = [29.026591, -81.534474];
Zipcodes['32768'] = [28.695755, -81.557944];
Zipcodes['32771'] = [28.814656, -81.317583];
Zipcodes['32773'] = [28.755093, -81.247488];
Zipcodes['32775'] = [28.767044, -80.876699];
Zipcodes['32776'] = [28.802306, -81.483683];
Zipcodes['32778'] = [28.773431, -81.73023];
Zipcodes['32779'] = [28.726884, -81.414795];
Zipcodes['32780'] = [28.528169, -80.779838];
Zipcodes['32784'] = [28.974313, -81.716763];
Zipcodes['32789'] = [28.59917, -81.352238];
Zipcodes['32792'] = [28.608352, -81.300566];
Zipcodes['32796'] = [28.625289, -80.845855];
Zipcodes['32798'] = [28.723731, -81.58772];
Zipcodes['32801'] = [28.541769, -81.374357];
Zipcodes['32803'] = [28.555617, -81.348447];
Zipcodes['32804'] = [28.579038, -81.394201];
Zipcodes['32805'] = [28.529974, -81.406402];
Zipcodes['32806'] = [28.512357, -81.360269];
Zipcodes['32807'] = [28.553677, -81.300656];
Zipcodes['32808'] = [28.58011, -81.4443];
Zipcodes['32809'] = [28.462218, -81.385219];
Zipcodes['32810'] = [28.621595, -81.428706];
Zipcodes['32811'] = [28.521039, -81.447731];
Zipcodes['32812'] = [28.484039, -81.324313];
Zipcodes['32814'] = [28.56947, -81.326469];
Zipcodes['32816'] = [28.600084, -81.195983];
Zipcodes['32817'] = [28.587848, -81.244103];
Zipcodes['32818'] = [28.59006, -81.493848];
Zipcodes['32819'] = [28.453705, -81.472373];
Zipcodes['32820'] = [28.576926, -81.139837];
Zipcodes['32821'] = [28.381347, -81.480092];
Zipcodes['32822'] = [28.489898, -81.290154];
Zipcodes['32824'] = [28.388751, -81.344535];
Zipcodes['32825'] = [28.536725, -81.242932];
Zipcodes['32826'] = [28.579663, -81.18864];
Zipcodes['32827'] = [28.431169, -81.323825];
Zipcodes['32828'] = [28.530179, -81.169271];
Zipcodes['32829'] = [28.477164, -81.223883];
Zipcodes['32830'] = [28.391693, -81.567239];
Zipcodes['32831'] = [28.486537, -81.146405];
Zipcodes['32832'] = [28.400248, -81.176562];
Zipcodes['32833'] = [28.494294, -81.083265];
Zipcodes['32835'] = [28.521975, -81.485967];
Zipcodes['32836'] = [28.405681, -81.516437];
Zipcodes['32837'] = [28.38224, -81.41446];
Zipcodes['32839'] = [28.488069, -81.407208];
Zipcodes['32899'] = [28.570183, -80.627676];
Zipcodes['32901'] = [28.079378, -80.622302];
Zipcodes['32903'] = [28.102377, -80.58217];
Zipcodes['32904'] = [28.032879, -80.81605];
Zipcodes['32905'] = [28.031366, -80.600414];
Zipcodes['32907'] = [28.020947, -80.679785];
Zipcodes['32908'] = [27.956179, -80.698269];
Zipcodes['32909'] = [27.899533, -80.659568];
Zipcodes['32920'] = [28.441757, -80.587945];
Zipcodes['32922'] = [28.372888, -80.741608];
Zipcodes['32925'] = [28.25163, -80.610008];
Zipcodes['32926'] = [28.382901, -80.819312];
Zipcodes['32927'] = [28.463766, -80.806711];
Zipcodes['32931'] = [28.320489, -80.625805];
Zipcodes['32934'] = [28.134548, -80.713887];
Zipcodes['32935'] = [28.145532, -80.649962];
Zipcodes['32937'] = [28.175952, -80.605279];
Zipcodes['32940'] = [28.210691, -80.750596];
Zipcodes['32948'] = [27.759557, -80.629194];
Zipcodes['32949'] = [27.909779, -80.560567];
Zipcodes['32950'] = [27.980527, -80.569574];
Zipcodes['32951'] = [27.944027, -80.503427];
Zipcodes['32952'] = [28.320397, -80.659178];
Zipcodes['32953'] = [28.463513, -80.687731];
Zipcodes['32955'] = [28.288759, -80.791442];
Zipcodes['32958'] = [27.794384, -80.49146];
Zipcodes['32959'] = [28.445127, -80.763703];
Zipcodes['32960'] = [27.640722, -80.403592];
Zipcodes['32962'] = [27.583462, -80.383467];
Zipcodes['32963'] = [27.718239, -80.393276];
Zipcodes['32966'] = [27.686911, -80.715786];
Zipcodes['32967'] = [27.71549, -80.454289];
Zipcodes['32968'] = [27.593204, -80.508685];
Zipcodes['32970'] = [27.754026, -80.450493];
Zipcodes['32976'] = [27.846262, -80.554114];
Zipcodes['33001'] = [24.835499, -80.801568];
Zipcodes['33004'] = [26.061046, -80.135859];
Zipcodes['33009'] = [25.984406, -80.145605];
Zipcodes['33010'] = [25.833202, -80.27872];
Zipcodes['33012'] = [25.866106, -80.301115];
Zipcodes['33013'] = [25.86219, -80.269677];
Zipcodes['33014'] = [25.904642, -80.30208];
Zipcodes['33015'] = [25.940571, -80.317442];
Zipcodes['33016'] = [25.893769, -80.330087];
Zipcodes['33018'] = [25.912395, -80.393093];
Zipcodes['33019'] = [26.027614, -80.120197];
Zipcodes['33020'] = [26.019191, -80.152944];
Zipcodes['33021'] = [26.02313, -80.186539];
Zipcodes['33022'] = [26.013848, -80.144066];
Zipcodes['33023'] = [25.988484, -80.212462];
Zipcodes['33024'] = [26.026358, -80.244633];
Zipcodes['33025'] = [25.988183, -80.281394];
Zipcodes['33026'] = [26.026198, -80.295994];
Zipcodes['33027'] = [25.98811, -80.340442];
Zipcodes['33028'] = [26.014716, -80.340171];
Zipcodes['33029'] = [25.992293, -80.394197];
Zipcodes['33030'] = [25.485755, -80.508192];
Zipcodes['33031'] = [25.524991, -80.500876];
Zipcodes['33032'] = [25.531145, -80.391233];
Zipcodes['33033'] = [25.4789, -80.409102];
Zipcodes['33034'] = [25.472158, -80.773252];
Zipcodes['33035'] = [25.422344, -80.399874];
Zipcodes['33036'] = [24.901896, -80.698998];
Zipcodes['33037'] = [25.225842, -80.414337];
Zipcodes['33039'] = [25.497969, -80.398172];
Zipcodes['33040'] = [24.54461, -81.921737];
Zipcodes['33042'] = [24.665275, -81.503095];
Zipcodes['33043'] = [24.669652, -81.260781];
Zipcodes['33050'] = [24.749924, -80.986567];
Zipcodes['33051'] = [24.721256, -81.021325];
Zipcodes['33054'] = [25.907995, -80.257509];
Zipcodes['33055'] = [25.948468, -80.278855];
Zipcodes['33056'] = [25.948892, -80.243585];
Zipcodes['33060'] = [26.234723, -80.121087];
Zipcodes['33062'] = [26.241077, -80.091233];
Zipcodes['33063'] = [26.251909, -80.208929];
Zipcodes['33064'] = [26.277754, -80.115325];
Zipcodes['33065'] = [26.274498, -80.2604];
Zipcodes['33066'] = [26.252926, -80.17038];
Zipcodes['33067'] = [26.305853, -80.226402];
Zipcodes['33068'] = [26.216395, -80.213958];
Zipcodes['33069'] = [26.23583, -80.1571];
Zipcodes['33070'] = [25.01103, -80.529313];
Zipcodes['33071'] = [26.245781, -80.267366];
Zipcodes['33073'] = [26.298166, -80.182101];
Zipcodes['33076'] = [26.319325, -80.271793];
Zipcodes['33101'] = [25.779764, -80.198763];
Zipcodes['33109'] = [25.761184, -80.130777];
Zipcodes['33122'] = [25.805005, -80.315071];
Zipcodes['33125'] = [25.783834, -80.236333];
Zipcodes['33126'] = [25.779492, -80.299573];
Zipcodes['33127'] = [25.813529, -80.204358];
Zipcodes['33128'] = [25.776769, -80.203433];
Zipcodes['33129'] = [25.754889, -80.198479];
Zipcodes['33130'] = [25.768524, -80.203359];
Zipcodes['33131'] = [25.765524, -80.184349];
Zipcodes['33132'] = [25.776585, -80.173242];
Zipcodes['33133'] = [25.731479, -80.23384];
Zipcodes['33134'] = [25.753332, -80.270379];
Zipcodes['33135'] = [25.766612, -80.235036];
Zipcodes['33136'] = [25.787247, -80.205296];
Zipcodes['33137'] = [25.8109, -80.166944];
Zipcodes['33138'] = [25.853764, -80.178006];
Zipcodes['33139'] = [25.779391, -80.151566];
Zipcodes['33140'] = [25.816916, -80.13566];
Zipcodes['33141'] = [25.85098, -80.138556];
Zipcodes['33142'] = [25.80631, -80.255409];
Zipcodes['33143'] = [25.702492, -80.2986];
Zipcodes['33144'] = [25.763458, -80.312667];
Zipcodes['33145'] = [25.753247, -80.234512];
Zipcodes['33146'] = [25.72085, -80.272571];
Zipcodes['33147'] = [25.851208, -80.23885];
Zipcodes['33149'] = [25.714412, -80.163587];
Zipcodes['33150'] = [25.85219, -80.207174];
Zipcodes['33154'] = [25.885908, -80.132227];
Zipcodes['33155'] = [25.737789, -80.311193];
Zipcodes['33156'] = [25.668203, -80.29701];
Zipcodes['33157'] = [25.607529, -80.343349];
Zipcodes['33158'] = [25.63884, -80.311316];
Zipcodes['33160'] = [25.933455, -80.135342];
Zipcodes['33161'] = [25.893543, -80.183272];
Zipcodes['33162'] = [25.928618, -80.179211];
Zipcodes['33165'] = [25.734345, -80.358271];
Zipcodes['33166'] = [25.824907, -80.310665];
Zipcodes['33167'] = [25.884528, -80.236019];
Zipcodes['33168'] = [25.892856, -80.209124];
Zipcodes['33169'] = [25.943117, -80.214686];
Zipcodes['33170'] = [25.558264, -80.449634];
Zipcodes['33172'] = [25.786716, -80.364872];
Zipcodes['33173'] = [25.70232, -80.357503];
Zipcodes['33174'] = [25.762048, -80.3595];
Zipcodes['33175'] = [25.736612, -80.40683];
Zipcodes['33176'] = [25.658379, -80.360893];
Zipcodes['33177'] = [25.598817, -80.406503];
Zipcodes['33178'] = [25.835497, -80.422481];
Zipcodes['33179'] = [25.95762, -80.179998];
Zipcodes['33180'] = [25.962149, -80.140904];
Zipcodes['33181'] = [25.89766, -80.151112];
Zipcodes['33182'] = [25.782549, -80.401385];
Zipcodes['33183'] = [25.700602, -80.405729];
Zipcodes['33184'] = [25.759711, -80.406473];
Zipcodes['33185'] = [25.730485, -80.454283];
Zipcodes['33186'] = [25.65442, -80.412154];
Zipcodes['33187'] = [25.52743, -80.599826];
Zipcodes['33189'] = [25.573102, -80.338974];
Zipcodes['33190'] = [25.556885, -80.349914];
Zipcodes['33193'] = [25.705415, -80.470847];
Zipcodes['33194'] = [25.71406, -80.583523];
Zipcodes['33196'] = [25.648447, -80.55467];
Zipcodes['33301'] = [26.121323, -80.127909];
Zipcodes['33304'] = [26.14134, -80.119169];
Zipcodes['33305'] = [26.153446, -80.122627];
Zipcodes['33306'] = [26.165508, -80.114094];
Zipcodes['33308'] = [26.18851, -80.104988];
Zipcodes['33309'] = [26.18599, -80.172721];
Zipcodes['33311'] = [26.144208, -80.172785];
Zipcodes['33312'] = [26.08817, -80.181783];
Zipcodes['33313'] = [26.15152, -80.227397];
Zipcodes['33314'] = [26.06789, -80.22416];
Zipcodes['33315'] = [26.087005, -80.152976];
Zipcodes['33316'] = [26.101566, -80.120955];
Zipcodes['33317'] = [26.1126, -80.227875];
Zipcodes['33319'] = [26.181382, -80.225802];
Zipcodes['33321'] = [26.212501, -80.270158];
Zipcodes['33322'] = [26.149977, -80.275707];
Zipcodes['33323'] = [26.151759, -80.320939];
Zipcodes['33324'] = [26.112485, -80.27494];
Zipcodes['33325'] = [26.110546, -80.324093];
Zipcodes['33326'] = [26.110469, -80.374015];
Zipcodes['33327'] = [26.226029, -80.367607];
Zipcodes['33328'] = [26.070471, -80.272864];
Zipcodes['33330'] = [26.060566, -80.330284];
Zipcodes['33331'] = [26.060462, -80.369595];
Zipcodes['33332'] = [26.006605, -80.460176];
Zipcodes['33334'] = [26.183753, -80.134055];
Zipcodes['33351'] = [26.179581, -80.27504];
Zipcodes['33388'] = [26.117118, -80.254165];
Zipcodes['33401'] = [26.715793, -80.066777];
Zipcodes['33403'] = [26.803774, -80.076385];
Zipcodes['33404'] = [26.781099, -80.063244];
Zipcodes['33405'] = [26.669649, -80.058452];
Zipcodes['33406'] = [26.664083, -80.092373];
Zipcodes['33407'] = [26.757692, -80.091172];
Zipcodes['33408'] = [26.840895, -80.052098];
Zipcodes['33409'] = [26.715601, -80.095886];
Zipcodes['33410'] = [26.845662, -80.08735];
Zipcodes['33411'] = [26.715201, -80.203797];
Zipcodes['33412'] = [26.803302, -80.217319];
Zipcodes['33413'] = [26.663715, -80.151341];
Zipcodes['33414'] = [26.646721, -80.250311];
Zipcodes['33415'] = [26.660205, -80.126573];
Zipcodes['33417'] = [26.720445, -80.124016];
Zipcodes['33418'] = [26.872713, -80.165712];
Zipcodes['33426'] = [26.533736, -80.083152];
Zipcodes['33428'] = [26.351283, -80.229122];
Zipcodes['33430'] = [26.602653, -80.579271];
Zipcodes['33431'] = [26.37459, -80.10661];
Zipcodes['33432'] = [26.343798, -80.08221];
Zipcodes['33433'] = [26.347076, -80.159189];
Zipcodes['33434'] = [26.383168, -80.167688];
Zipcodes['33435'] = [26.525785, -80.061367];
Zipcodes['33436'] = [26.522954, -80.107263];
Zipcodes['33437'] = [26.509389, -80.148474];
Zipcodes['33438'] = [26.976388, -80.523513];
Zipcodes['33440'] = [26.537946, -81.044144];
Zipcodes['33441'] = [26.311098, -80.097874];
Zipcodes['33442'] = [26.309716, -80.144905];
Zipcodes['33444'] = [26.457074, -80.079598];
Zipcodes['33445'] = [26.455873, -80.107935];
Zipcodes['33446'] = [26.457431, -80.183425];
Zipcodes['33449'] = [26.592718, -80.233877];
Zipcodes['33455'] = [27.053677, -80.16232];
Zipcodes['33458'] = [26.935806, -80.128441];
Zipcodes['33460'] = [26.618426, -80.055762];
Zipcodes['33461'] = [26.618582, -80.093216];
Zipcodes['33462'] = [26.576183, -80.072313];
Zipcodes['33463'] = [26.593249, -80.1289];
Zipcodes['33467'] = [26.596362, -80.175602];
Zipcodes['33469'] = [26.989347, -80.108549];
Zipcodes['33470'] = [26.783861, -80.380968];
Zipcodes['33471'] = [26.917498, -81.198156];
Zipcodes['33472'] = [26.539753, -80.18903];
Zipcodes['33473'] = [26.503291, -80.192245];
Zipcodes['33476'] = [26.800973, -80.65306];
Zipcodes['33477'] = [26.918903, -80.07606];
Zipcodes['33478'] = [26.939847, -80.261144];
Zipcodes['33480'] = [26.68522, -80.037632];
Zipcodes['33483'] = [26.462605, -80.061762];
Zipcodes['33484'] = [26.454311, -80.134344];
Zipcodes['33486'] = [26.347543, -80.114805];
Zipcodes['33487'] = [26.411793, -80.089007];
Zipcodes['33493'] = [26.475636, -80.706002];
Zipcodes['33496'] = [26.409046, -80.166139];
Zipcodes['33498'] = [26.395299, -80.219811];
Zipcodes['33503'] = [27.763831, -82.276174];
Zipcodes['33510'] = [27.955306, -82.299483];
Zipcodes['33511'] = [27.909778, -82.295738];
Zipcodes['33513'] = [28.659903, -82.16917];
Zipcodes['33514'] = [28.660841, -81.99678];
Zipcodes['33521'] = [28.79792, -82.075174];
Zipcodes['33523'] = [28.421657, -82.265759];
Zipcodes['33525'] = [28.330809, -82.183329];
Zipcodes['33527'] = [27.973749, -82.212806];
Zipcodes['33530'] = [27.9077, -82.172352];
Zipcodes['33534'] = [27.817981, -82.372248];
Zipcodes['33538'] = [28.849949, -82.18511];
Zipcodes['33540'] = [28.261577, -82.133112];
Zipcodes['33541'] = [28.234002, -82.22136];
Zipcodes['33542'] = [28.23578, -82.177617];
Zipcodes['33543'] = [28.205247, -82.317108];
Zipcodes['33544'] = [28.248334, -82.370909];
Zipcodes['33545'] = [28.268104, -82.291232];
Zipcodes['33547'] = [27.789984, -82.135805];
Zipcodes['33548'] = [28.146279, -82.482303];
Zipcodes['33549'] = [28.140689, -82.44972];
Zipcodes['33550'] = [27.979504, -82.307012];
Zipcodes['33556'] = [28.165897, -82.601578];
Zipcodes['33558'] = [28.165344, -82.510685];
Zipcodes['33559'] = [28.160954, -82.414663];
Zipcodes['33563'] = [28.017072, -82.125766];
Zipcodes['33565'] = [28.085906, -82.148114];
Zipcodes['33566'] = [27.977964, -82.095709];
Zipcodes['33567'] = [27.920609, -82.116772];
Zipcodes['33569'] = [27.848771, -82.295848];
Zipcodes['33570'] = [27.693944, -82.458577];
Zipcodes['33572'] = [27.766168, -82.400285];
Zipcodes['33573'] = [27.720216, -82.361796];
Zipcodes['33574'] = [28.338027, -82.257098];
Zipcodes['33576'] = [28.302956, -82.30376];
Zipcodes['33578'] = [27.861107, -82.35506];
Zipcodes['33579'] = [27.798135, -82.280616];
Zipcodes['33584'] = [28.005455, -82.294337];
Zipcodes['33585'] = [28.727849, -82.032335];
Zipcodes['33592'] = [28.101871, -82.283236];
Zipcodes['33594'] = [27.940934, -82.242479];
Zipcodes['33596'] = [27.8872, -82.225852];
Zipcodes['33597'] = [28.528762, -82.085255];
Zipcodes['33598'] = [27.693423, -82.284659];
Zipcodes['33602'] = [27.953704, -82.45631];
Zipcodes['33603'] = [27.985618, -82.464439];
Zipcodes['33604'] = [28.017154, -82.45535];
Zipcodes['33605'] = [27.951052, -82.429635];
Zipcodes['33606'] = [27.944035, -82.473954];
Zipcodes['33607'] = [27.965234, -82.519051];
Zipcodes['33609'] = [27.942562, -82.515044];
Zipcodes['33610'] = [27.998214, -82.378016];
Zipcodes['33611'] = [27.891345, -82.505665];
Zipcodes['33612'] = [28.050827, -82.449573];
Zipcodes['33613'] = [28.083694, -82.447872];
Zipcodes['33614'] = [28.004691, -82.506135];
Zipcodes['33615'] = [28.001166, -82.582227];
Zipcodes['33616'] = [27.865139, -82.535398];
Zipcodes['33617'] = [28.039315, -82.392428];
Zipcodes['33618'] = [28.074825, -82.498432];
Zipcodes['33619'] = [27.935372, -82.37869];
Zipcodes['33620'] = [28.061368, -82.412801];
Zipcodes['33621'] = [27.849178, -82.510781];
Zipcodes['33624'] = [28.080255, -82.528248];
Zipcodes['33625'] = [28.066997, -82.557957];
Zipcodes['33626'] = [28.064407, -82.596741];
Zipcodes['33629'] = [27.921536, -82.509043];
Zipcodes['33634'] = [28.009826, -82.547789];
Zipcodes['33635'] = [28.02054, -82.617948];
Zipcodes['33637'] = [28.045249, -82.362861];
Zipcodes['33647'] = [28.151887, -82.318179];
Zipcodes['33701'] = [27.770793, -82.632806];
Zipcodes['33702'] = [27.846603, -82.63059];
Zipcodes['33703'] = [27.817056, -82.61975];
Zipcodes['33704'] = [27.796331, -82.628723];
Zipcodes['33705'] = [27.73397, -82.640314];
Zipcodes['33706'] = [27.741501, -82.752062];
Zipcodes['33707'] = [27.743466, -82.724374];
Zipcodes['33708'] = [27.815056, -82.79779];
Zipcodes['33709'] = [27.816844, -82.731621];
Zipcodes['33710'] = [27.788642, -82.729628];
Zipcodes['33711'] = [27.739365, -82.687234];
Zipcodes['33712'] = [27.735324, -82.667105];
Zipcodes['33713'] = [27.788266, -82.677461];
Zipcodes['33714'] = [27.815251, -82.677709];
Zipcodes['33715'] = [27.665801, -82.722722];
Zipcodes['33716'] = [27.879858, -82.652797];
Zipcodes['33744'] = [27.807285, -82.773051];
Zipcodes['33755'] = [27.979868, -82.782744];
Zipcodes['33756'] = [27.945591, -82.793988];
Zipcodes['33759'] = [27.975567, -82.712224];
Zipcodes['33760'] = [27.905787, -82.713989];
Zipcodes['33761'] = [28.033061, -82.724374];
Zipcodes['33762'] = [27.907509, -82.677494];
Zipcodes['33763'] = [28.0029, -82.743429];
Zipcodes['33764'] = [27.937207, -82.740585];
Zipcodes['33765'] = [27.974876, -82.745122];
Zipcodes['33767'] = [27.974748, -82.826777];
Zipcodes['33770'] = [27.915109, -82.804026];
Zipcodes['33771'] = [27.905836, -82.758774];
Zipcodes['33772'] = [27.843593, -82.796683];
Zipcodes['33773'] = [27.881507, -82.7553];
Zipcodes['33774'] = [27.883819, -82.827877];
Zipcodes['33776'] = [27.849564, -82.825827];
Zipcodes['33777'] = [27.853364, -82.760823];
Zipcodes['33778'] = [27.888621, -82.79854];
Zipcodes['33781'] = [27.838871, -82.714995];
Zipcodes['33782'] = [27.859631, -82.708145];
Zipcodes['33785'] = [27.891841, -82.849979];
Zipcodes['33786'] = [27.932667, -82.8339];
Zipcodes['33801'] = [28.035979, -81.899636];
Zipcodes['33803'] = [28.009928, -81.936496];
Zipcodes['33805'] = [28.095476, -81.917942];
Zipcodes['33809'] = [28.202419, -81.955621];
Zipcodes['33810'] = [28.130235, -82.038987];
Zipcodes['33811'] = [27.978876, -82.015892];
Zipcodes['33812'] = [27.971442, -81.894476];
Zipcodes['33813'] = [27.947248, -81.926108];
Zipcodes['33815'] = [28.041258, -81.99759];
Zipcodes['33823'] = [28.087193, -81.81294];
Zipcodes['33825'] = [27.620252, -81.458826];
Zipcodes['33827'] = [27.817937, -81.516767];
Zipcodes['33830'] = [27.883187, -81.810993];
Zipcodes['33834'] = [27.631303, -81.912581];
Zipcodes['33835'] = [27.788246, -81.972591];
Zipcodes['33837'] = [28.194263, -81.587805];
Zipcodes['33838'] = [28.016968, -81.610021];
Zipcodes['33839'] = [27.97427, -81.755395];
Zipcodes['33840'] = [28.009157, -81.905049];
Zipcodes['33841'] = [27.737973, -81.768094];
Zipcodes['33843'] = [27.731789, -81.5127];
Zipcodes['33844'] = [28.089578, -81.589383];
Zipcodes['33847'] = [27.802851, -81.822059];
Zipcodes['33848'] = [28.275975, -81.50822];
Zipcodes['33849'] = [28.226068, -82.068886];
Zipcodes['33850'] = [28.124707, -81.731098];
Zipcodes['33851'] = [28.04481, -81.626341];
Zipcodes['33852'] = [27.258703, -81.378113];
Zipcodes['33853'] = [27.900772, -81.58567];
Zipcodes['33854'] = [27.856274, -81.40993];
Zipcodes['33855'] = [27.791034, -81.359714];
Zipcodes['33856'] = [27.854747, -81.429024];
Zipcodes['33857'] = [27.35915, -81.166596];
Zipcodes['33858'] = [28.245093, -81.558313];
Zipcodes['33859'] = [27.885222, -81.628206];
Zipcodes['33860'] = [27.818504, -82.005783];
Zipcodes['33865'] = [27.433787, -81.963257];
Zipcodes['33867'] = [27.781088, -81.20204];
Zipcodes['33868'] = [28.267639, -81.853797];
Zipcodes['33870'] = [27.503346, -81.367739];
Zipcodes['33872'] = [27.505242, -81.546028];
Zipcodes['33873'] = [27.570917, -81.781173];
Zipcodes['33875'] = [27.423426, -81.500649];
Zipcodes['33876'] = [27.425161, -81.33921];
Zipcodes['33877'] = [27.984232, -81.618797];
Zipcodes['33880'] = [27.994018, -81.777505];
Zipcodes['33881'] = [28.050996, -81.702534];
Zipcodes['33884'] = [27.982788, -81.681745];
Zipcodes['33890'] = [27.455205, -81.724602];
Zipcodes['33896'] = [28.250175, -81.58077];
Zipcodes['33897'] = [28.285129, -81.691024];
Zipcodes['33898'] = [27.880552, -81.317534];
Zipcodes['33901'] = [26.623381, -81.883191];
Zipcodes['33903'] = [26.682165, -81.905776];
Zipcodes['33904'] = [26.575403, -81.94486];
Zipcodes['33905'] = [26.667732, -81.76395];
Zipcodes['33907'] = [26.563891, -81.870266];
Zipcodes['33908'] = [26.510332, -81.979563];
Zipcodes['33909'] = [26.720347, -81.965502];
Zipcodes['33912'] = [26.534987, -81.825763];
Zipcodes['33913'] = [26.514239, -81.696796];
Zipcodes['33914'] = [26.55944, -82.024218];
Zipcodes['33916'] = [26.639352, -81.841388];
Zipcodes['33917'] = [26.748095, -81.846151];
Zipcodes['33919'] = [26.555891, -81.902855];
Zipcodes['33920'] = [26.742298, -81.63605];
Zipcodes['33921'] = [26.772225, -82.252804];
Zipcodes['33922'] = [26.651679, -82.176814];
Zipcodes['33924'] = [26.541274, -82.19652];
Zipcodes['33928'] = [26.435629, -81.697137];
Zipcodes['33930'] = [26.567129, -81.406707];
Zipcodes['33931'] = [26.430176, -81.91404];
Zipcodes['33935'] = [26.756778, -81.417649];
Zipcodes['33936'] = [26.618028, -81.559247];
Zipcodes['33944'] = [26.955129, -81.450021];
Zipcodes['33946'] = [26.852415, -82.282045];
Zipcodes['33947'] = [26.88512, -82.277881];
Zipcodes['33948'] = [26.985889, -82.151742];
Zipcodes['33950'] = [26.915655, -82.044296];
Zipcodes['33952'] = [26.986134, -82.097042];
Zipcodes['33953'] = [26.999702, -82.212275];
Zipcodes['33954'] = [27.02495, -82.121451];
Zipcodes['33955'] = [26.816433, -81.993808];
Zipcodes['33956'] = [26.537104, -82.121707];
Zipcodes['33957'] = [26.460477, -82.100381];
Zipcodes['33960'] = [27.079063, -81.412221];
Zipcodes['33965'] = [26.46768, -81.763678];
Zipcodes['33966'] = [26.583269, -81.834152];
Zipcodes['33967'] = [26.471507, -81.81224];
Zipcodes['33971'] = [26.638858, -81.700343];
Zipcodes['33972'] = [26.649272, -81.61771];
Zipcodes['33973'] = [26.600661, -81.730435];
Zipcodes['33974'] = [26.558935, -81.601239];
Zipcodes['33976'] = [26.591277, -81.68613];
Zipcodes['33980'] = [26.977203, -82.049732];
Zipcodes['33981'] = [26.87617, -82.210575];
Zipcodes['33982'] = [26.85294, -81.669581];
Zipcodes['33983'] = [27.005904, -82.01337];
Zipcodes['33990'] = [26.62855, -81.948755];
Zipcodes['33991'] = [26.633654, -82.009842];
Zipcodes['33993'] = [26.67263, -82.051131];
Zipcodes['34101'] = [26.050185, -81.780621];
Zipcodes['34102'] = [26.137498, -81.799105];
Zipcodes['34103'] = [26.191352, -81.803331];
Zipcodes['34104'] = [26.150429, -81.740837];
Zipcodes['34105'] = [26.190046, -81.764777];
Zipcodes['34108'] = [26.246308, -81.808323];
Zipcodes['34109'] = [26.240842, -81.763823];
Zipcodes['34110'] = [26.303284, -81.788171];
Zipcodes['34112'] = [26.121422, -81.73903];
Zipcodes['34113'] = [26.043649, -81.732424];
Zipcodes['34114'] = [25.954218, -81.573387];
Zipcodes['34116'] = [26.189294, -81.710803];
Zipcodes['34117'] = [26.118102, -81.54893];
Zipcodes['34119'] = [26.263933, -81.717462];
Zipcodes['34120'] = [26.312992, -81.582396];
Zipcodes['34134'] = [26.361381, -81.831936];
Zipcodes['34135'] = [26.367713, -81.735659];
Zipcodes['34137'] = [26.040535, -81.391301];
Zipcodes['34138'] = [25.813921, -81.363212];
Zipcodes['34139'] = [25.849912, -81.389953];
Zipcodes['34140'] = [25.925119, -81.662613];
Zipcodes['34141'] = [26.022815, -81.081248];
Zipcodes['34142'] = [26.313791, -81.286265];
Zipcodes['34145'] = [25.909779, -81.695125];
Zipcodes['34201'] = [27.400662, -82.465793];
Zipcodes['34202'] = [27.402531, -82.394394];
Zipcodes['34203'] = [27.440337, -82.509384];
Zipcodes['34205'] = [27.484084, -82.581821];
Zipcodes['34207'] = [27.438677, -82.579674];
Zipcodes['34208'] = [27.487486, -82.515907];
Zipcodes['34209'] = [27.50473, -82.654669];
Zipcodes['34210'] = [27.437548, -82.630178];
Zipcodes['34211'] = [27.454365, -82.383383];
Zipcodes['34212'] = [27.493816, -82.406103];
Zipcodes['34215'] = [27.474427, -82.685724];
Zipcodes['34216'] = [27.534798, -82.735123];
Zipcodes['34217'] = [27.491178, -82.705018];
Zipcodes['34219'] = [27.576201, -82.263457];
Zipcodes['34221'] = [27.581152, -82.551825];
Zipcodes['34222'] = [27.538955, -82.507027];
Zipcodes['34223'] = [26.983677, -82.337666];
Zipcodes['34224'] = [26.922945, -82.308301];
Zipcodes['34228'] = [27.400457, -82.644175];
Zipcodes['34229'] = [27.191211, -82.484136];
Zipcodes['34231'] = [27.265984, -82.516583];
Zipcodes['34232'] = [27.324987, -82.471714];
Zipcodes['34233'] = [27.280018, -82.476632];
Zipcodes['34234'] = [27.368976, -82.556536];
Zipcodes['34235'] = [27.369416, -82.47682];
Zipcodes['34236'] = [27.325788, -82.559997];
Zipcodes['34237'] = [27.338802, -82.514742];
Zipcodes['34238'] = [27.235792, -82.470536];
Zipcodes['34239'] = [27.310893, -82.521168];
Zipcodes['34240'] = [27.344716, -82.354156];
Zipcodes['34241'] = [27.251039, -82.373785];
Zipcodes['34242'] = [27.26022, -82.540908];
Zipcodes['34243'] = [27.400992, -82.527943];
Zipcodes['34250'] = [27.569606, -82.585138];
Zipcodes['34251'] = [27.377729, -82.17154];
Zipcodes['34266'] = [27.202037, -81.802188];
Zipcodes['34267'] = [27.089306, -81.954297];
Zipcodes['34268'] = [27.132262, -81.927421];
Zipcodes['34269'] = [27.089943, -81.96148];
Zipcodes['34275'] = [27.151753, -82.422567];
Zipcodes['34285'] = [27.094564, -82.440895];
Zipcodes['34286'] = [27.107469, -82.10668];
Zipcodes['34287'] = [27.057797, -82.249277];
Zipcodes['34288'] = [27.0536, -82.113335];
Zipcodes['34289'] = [27.088812, -82.139203];
Zipcodes['34291'] = [27.11647, -82.215206];
Zipcodes['34292'] = [27.103873, -82.335474];
Zipcodes['34293'] = [27.037392, -82.358528];
Zipcodes['34420'] = [29.053114, -82.03873];
Zipcodes['34428'] = [28.956702, -82.647585];
Zipcodes['34429'] = [28.867281, -82.662032];
Zipcodes['34431'] = [29.140237, -82.530551];
Zipcodes['34432'] = [29.064898, -82.374559];
Zipcodes['34433'] = [28.992502, -82.528869];
Zipcodes['34434'] = [28.99503, -82.437972];
Zipcodes['34436'] = [28.733692, -82.293898];
Zipcodes['34442'] = [28.920909, -82.382325];
Zipcodes['34445'] = [28.971387, -82.42406];
Zipcodes['34446'] = [28.749019, -82.52346];
Zipcodes['34448'] = [28.767011, -82.625191];
Zipcodes['34449'] = [29.106972, -82.69054];
Zipcodes['34450'] = [28.830041, -82.266009];
Zipcodes['34452'] = [28.761187, -82.375709];
Zipcodes['34453'] = [28.895824, -82.329534];
Zipcodes['34461'] = [28.813818, -82.483064];
Zipcodes['34465'] = [28.928693, -82.490909];
Zipcodes['34470'] = [29.201008, -82.082072];
Zipcodes['34471'] = [29.161789, -82.126817];
Zipcodes['34472'] = [29.109474, -81.997364];
Zipcodes['34473'] = [28.99763, -82.18778];
Zipcodes['34474'] = [29.154472, -82.211485];
Zipcodes['34475'] = [29.25268, -82.161978];
Zipcodes['34476'] = [29.088605, -82.201598];
Zipcodes['34479'] = [29.255724, -82.109112];
Zipcodes['34480'] = [29.0942, -82.094208];
Zipcodes['34481'] = [29.125538, -82.309895];
Zipcodes['34482'] = [29.244387, -82.275029];
Zipcodes['34484'] = [28.945019, -82.097528];
Zipcodes['34487'] = [28.781477, -82.612719];
Zipcodes['34488'] = [29.219283, -81.941004];
Zipcodes['34491'] = [28.998477, -82.039708];
Zipcodes['34498'] = [29.031552, -82.736088];
Zipcodes['34601'] = [28.58845, -82.371337];
Zipcodes['34602'] = [28.502482, -82.282351];
Zipcodes['34604'] = [28.477875, -82.432707];
Zipcodes['34606'] = [28.468477, -82.595728];
Zipcodes['34607'] = [28.594975, -82.615947];
Zipcodes['34608'] = [28.482826, -82.552854];
Zipcodes['34609'] = [28.479476, -82.507906];
Zipcodes['34610'] = [28.369903, -82.510748];
Zipcodes['34613'] = [28.558922, -82.542335];
Zipcodes['34614'] = [28.646538, -82.51364];
Zipcodes['34637'] = [28.307711, -82.464832];
Zipcodes['34638'] = [28.274188, -82.529523];
Zipcodes['34639'] = [28.26543, -82.423239];
Zipcodes['34652'] = [28.240555, -82.744353];
Zipcodes['34653'] = [28.24408, -82.688476];
Zipcodes['34654'] = [28.297909, -82.629344];
Zipcodes['34655'] = [28.211353, -82.655286];
Zipcodes['34661'] = [28.620835, -82.261099];
Zipcodes['34667'] = [28.385867, -82.665627];
Zipcodes['34668'] = [28.293335, -82.710564];
Zipcodes['34669'] = [28.356025, -82.610167];
Zipcodes['34677'] = [28.044517, -82.674272];
Zipcodes['34679'] = [28.422752, -82.674162];
Zipcodes['34681'] = [28.090702, -82.777914];
Zipcodes['34683'] = [28.087706, -82.761134];
Zipcodes['34684'] = [28.080744, -82.726829];
Zipcodes['34685'] = [28.093255, -82.689402];
Zipcodes['34688'] = [28.143226, -82.683943];
Zipcodes['34689'] = [28.149526, -82.761831];
Zipcodes['34690'] = [28.193745, -82.725706];
Zipcodes['34691'] = [28.192577, -82.770421];
Zipcodes['34695'] = [28.008161, -82.688367];
Zipcodes['34698'] = [28.036272, -82.791427];
Zipcodes['34705'] = [28.693805, -81.710847];
Zipcodes['34711'] = [28.52078, -81.753851];
Zipcodes['34714'] = [28.399468, -81.812869];
Zipcodes['34715'] = [28.631041, -81.726074];
Zipcodes['34729'] = [28.617449, -81.700797];
Zipcodes['34731'] = [28.867636, -81.896543];
Zipcodes['34734'] = [28.537003, -81.515454];
Zipcodes['34736'] = [28.599487, -81.862203];
Zipcodes['34737'] = [28.697138, -81.803369];
Zipcodes['34739'] = [27.932962, -81.098849];
Zipcodes['34741'] = [28.306299, -81.426933];
Zipcodes['34743'] = [28.329321, -81.355148];
Zipcodes['34744'] = [28.295842, -81.348621];
Zipcodes['34746'] = [28.237471, -81.441898];
Zipcodes['34747'] = [28.308991, -81.591266];
Zipcodes['34748'] = [28.773001, -81.882622];
Zipcodes['34753'] = [28.604341, -81.905549];
Zipcodes['34756'] = [28.586734, -81.679482];
Zipcodes['34758'] = [28.192063, -81.48542];
Zipcodes['34759'] = [28.103895, -81.419373];
Zipcodes['34760'] = [28.557053, -81.62924];
Zipcodes['34761'] = [28.578577, -81.534116];
Zipcodes['34762'] = [28.757247, -81.920516];
Zipcodes['34769'] = [28.243666, -81.294191];
Zipcodes['34771'] = [28.268298, -81.128403];
Zipcodes['34772'] = [28.160499, -81.273639];
Zipcodes['34773'] = [28.160667, -80.998092];
Zipcodes['34785'] = [28.846318, -82.061648];
Zipcodes['34786'] = [28.482847, -81.538656];
Zipcodes['34787'] = [28.485174, -81.619415];
Zipcodes['34788'] = [28.880322, -81.790033];
Zipcodes['34797'] = [28.730468, -81.826624];
Zipcodes['34945'] = [27.457933, -80.551744];
Zipcodes['34946'] = [27.503294, -80.36048];
Zipcodes['34947'] = [27.453848, -80.373737];
Zipcodes['34949'] = [27.467258, -80.299808];
Zipcodes['34950'] = [27.443788, -80.330974];
Zipcodes['34951'] = [27.532134, -80.418954];
Zipcodes['34952'] = [27.297204, -80.29801];
Zipcodes['34953'] = [27.24968, -80.383236];
Zipcodes['34956'] = [27.098264, -80.499613];
Zipcodes['34957'] = [27.274577, -80.243022];
Zipcodes['34972'] = [27.477985, -80.908736];
Zipcodes['34974'] = [27.148849, -80.914061];
Zipcodes['34981'] = [27.393278, -80.372054];
Zipcodes['34982'] = [27.375286, -80.311514];
Zipcodes['34983'] = [27.323951, -80.352566];
Zipcodes['34984'] = [27.249005, -80.330553];
Zipcodes['34986'] = [27.327734, -80.401911];
Zipcodes['34987'] = [27.290285, -80.49712];
Zipcodes['34990'] = [27.147946, -80.362294];
Zipcodes['34994'] = [27.199535, -80.255952];
Zipcodes['34996'] = [27.188173, -80.1878];
Zipcodes['34997'] = [27.050481, -80.271203];
Zipcodes['35004'] = [33.603429, -86.493786];
Zipcodes['35005'] = [33.605877, -86.993731];
Zipcodes['35006'] = [33.422751, -87.209751];
Zipcodes['35007'] = [33.221352, -86.787652];
Zipcodes['35010'] = [32.916719, -85.940589];
Zipcodes['35013'] = [33.902164, -86.517774];
Zipcodes['35014'] = [33.355851, -86.261678];
Zipcodes['35016'] = [34.324867, -86.504289];
Zipcodes['35019'] = [34.302078, -86.634094];
Zipcodes['35020'] = [33.404832, -86.953972];
Zipcodes['35022'] = [33.331782, -86.959067];
Zipcodes['35023'] = [33.467843, -87.088194];
Zipcodes['35031'] = [34.097505, -86.555035];
Zipcodes['35032'] = [33.263126, -86.334052];
Zipcodes['35033'] = [33.921678, -87.010585];
Zipcodes['35034'] = [32.897994, -87.281501];
Zipcodes['35035'] = [33.043682, -86.968442];
Zipcodes['35036'] = [33.640529, -86.916404];
Zipcodes['35040'] = [33.102967, -86.728894];
Zipcodes['35042'] = [32.930993, -87.127915];
Zipcodes['35043'] = [33.310561, -86.662905];
Zipcodes['35044'] = [33.267244, -86.366505];
Zipcodes['35045'] = [32.811605, -86.673249];
Zipcodes['35046'] = [32.921346, -86.530466];
Zipcodes['35049'] = [33.961861, -86.612114];
Zipcodes['35051'] = [33.219388, -86.600824];
Zipcodes['35052'] = [33.590682, -86.409897];
Zipcodes['35053'] = [34.065536, -87.066361];
Zipcodes['35054'] = [33.502019, -86.337925];
Zipcodes['35055'] = [34.151162, -86.758748];
Zipcodes['35057'] = [34.18031, -86.93428];
Zipcodes['35058'] = [34.236831, -86.730183];
Zipcodes['35060'] = [33.557586, -86.928164];
Zipcodes['35061'] = [33.475321, -86.94678];
Zipcodes['35062'] = [33.719033, -87.017744];
Zipcodes['35063'] = [33.818224, -87.020631];
Zipcodes['35064'] = [33.474825, -86.919575];
Zipcodes['35068'] = [33.609343, -86.831744];
Zipcodes['35070'] = [34.01366, -86.742656];
Zipcodes['35071'] = [33.695133, -86.848962];
Zipcodes['35072'] = [33.093531, -86.06686];
Zipcodes['35073'] = [33.651263, -86.986993];
Zipcodes['35074'] = [33.22236, -87.124251];
Zipcodes['35077'] = [34.021165, -86.832668];
Zipcodes['35078'] = [33.32442, -86.444966];
Zipcodes['35079'] = [33.938494, -86.75701];
Zipcodes['35080'] = [33.249278, -86.914775];
Zipcodes['35082'] = [33.158024, -86.153863];
Zipcodes['35083'] = [34.203802, -86.59526];
Zipcodes['35085'] = [32.976498, -86.739181];
Zipcodes['35087'] = [34.306453, -86.584296];
Zipcodes['35089'] = [32.945782, -86.081832];
Zipcodes['35091'] = [33.783484, -86.789474];
Zipcodes['35094'] = [33.513549, -86.571182];
Zipcodes['35096'] = [33.620336, -86.107668];
Zipcodes['35097'] = [33.892188, -86.627133];
Zipcodes['35098'] = [34.139446, -87.03884];
Zipcodes['35111'] = [33.280948, -87.08981];
Zipcodes['35112'] = [33.694069, -86.474733];
Zipcodes['35114'] = [33.222515, -86.866567];
Zipcodes['35115'] = [33.142677, -86.891559];
Zipcodes['35116'] = [33.731298, -86.769981];
Zipcodes['35117'] = [33.667778, -86.892599];
Zipcodes['35118'] = [33.527311, -87.034644];
Zipcodes['35119'] = [33.638022, -86.778579];
Zipcodes['35120'] = [33.668642, -86.421654];
Zipcodes['35121'] = [33.941104, -86.45057];
Zipcodes['35124'] = [33.32183, -86.742197];
Zipcodes['35125'] = [33.633384, -86.288692];
Zipcodes['35126'] = [33.730926, -86.654972];
Zipcodes['35127'] = [33.491167, -86.975791];
Zipcodes['35128'] = [33.5524, -86.337819];
Zipcodes['35130'] = [33.633156, -87.136688];
Zipcodes['35131'] = [33.714269, -86.192523];
Zipcodes['35133'] = [33.828926, -86.595478];
Zipcodes['35135'] = [33.628093, -86.195731];
Zipcodes['35136'] = [32.879065, -86.287018];
Zipcodes['35143'] = [33.090189, -86.556119];
Zipcodes['35146'] = [33.79507, -86.453279];
Zipcodes['35147'] = [33.415884, -86.530992];
Zipcodes['35148'] = [33.756099, -87.04561];
Zipcodes['35149'] = [33.239597, -86.209926];
Zipcodes['35150'] = [33.190239, -86.244559];
Zipcodes['35151'] = [33.119817, -86.339033];
Zipcodes['35160'] = [33.363351, -86.110732];
Zipcodes['35171'] = [32.871411, -86.746309];
Zipcodes['35172'] = [33.845155, -86.695313];
Zipcodes['35173'] = [33.655233, -86.563239];
Zipcodes['35175'] = [34.435679, -86.511374];
Zipcodes['35176'] = [33.48179, -86.502441];
Zipcodes['35178'] = [33.415933, -86.411756];
Zipcodes['35179'] = [34.273307, -86.96897];
Zipcodes['35180'] = [33.832693, -86.84882];
Zipcodes['35181'] = [33.626438, -86.879972];
Zipcodes['35183'] = [33.006438, -86.357576];
Zipcodes['35184'] = [33.114345, -87.136714];
Zipcodes['35185'] = [33.348974, -86.538784];
Zipcodes['35186'] = [33.256733, -86.510291];
Zipcodes['35188'] = [33.170123, -87.172884];
Zipcodes['35203'] = [33.518381, -86.810067];
Zipcodes['35204'] = [33.522049, -86.840656];
Zipcodes['35205'] = [33.495139, -86.808245];
Zipcodes['35206'] = [33.570264, -86.71314];
Zipcodes['35207'] = [33.567685, -86.82502];
Zipcodes['35208'] = [33.497188, -86.879059];
Zipcodes['35209'] = [33.465306, -86.811022];
Zipcodes['35210'] = [33.541806, -86.669036];
Zipcodes['35211'] = [33.456481, -86.868488];
Zipcodes['35212'] = [33.54767, -86.75272];
Zipcodes['35213'] = [33.506674, -86.744347];
Zipcodes['35214'] = [33.570931, -86.892259];
Zipcodes['35215'] = [33.64986, -86.703762];
Zipcodes['35216'] = [33.419246, -86.789433];
Zipcodes['35217'] = [33.606723, -86.763005];
Zipcodes['35218'] = [33.507698, -86.893956];
Zipcodes['35221'] = [33.449719, -86.899014];
Zipcodes['35222'] = [33.523712, -86.770115];
Zipcodes['35223'] = [33.491488, -86.73447];
Zipcodes['35224'] = [33.52765, -86.945239];
Zipcodes['35226'] = [33.398798, -86.841794];
Zipcodes['35228'] = [33.456044, -86.921648];
Zipcodes['35229'] = [33.464365, -86.79398];
Zipcodes['35233'] = [33.509151, -86.801398];
Zipcodes['35234'] = [33.53999, -86.803793];
Zipcodes['35235'] = [33.615287, -86.645447];
Zipcodes['35242'] = [33.424611, -86.670867];
Zipcodes['35243'] = [33.440988, -86.741547];
Zipcodes['35244'] = [33.352918, -86.825333];
Zipcodes['35249'] = [33.506573, -86.80316];
Zipcodes['35254'] = [33.513705, -86.852874];
Zipcodes['35401'] = [33.179127, -87.615273];
Zipcodes['35404'] = [33.215157, -87.483406];
Zipcodes['35405'] = [33.120192, -87.539873];
Zipcodes['35406'] = [33.338842, -87.467554];
Zipcodes['35441'] = [32.857248, -87.731274];
Zipcodes['35442'] = [33.053605, -88.186094];
Zipcodes['35443'] = [32.76859, -88.011476];
Zipcodes['35444'] = [33.328718, -87.292882];
Zipcodes['35446'] = [33.209239, -87.749667];
Zipcodes['35447'] = [33.277932, -88.16795];
Zipcodes['35452'] = [33.280692, -87.689023];
Zipcodes['35453'] = [33.161675, -87.373572];
Zipcodes['35456'] = [33.063694, -87.41475];
Zipcodes['35457'] = [33.304137, -87.773144];
Zipcodes['35458'] = [33.402444, -87.809614];
Zipcodes['35459'] = [32.791636, -88.275851];
Zipcodes['35460'] = [32.721884, -88.195782];
Zipcodes['35461'] = [33.417374, -88.213613];
Zipcodes['35462'] = [32.931849, -87.938502];
Zipcodes['35463'] = [33.054133, -87.682064];
Zipcodes['35464'] = [32.784706, -88.144786];
Zipcodes['35466'] = [33.248527, -87.919484];
Zipcodes['35469'] = [33.004086, -87.798738];
Zipcodes['35470'] = [32.56175, -88.066558];
Zipcodes['35473'] = [33.269296, -87.585458];
Zipcodes['35474'] = [32.940566, -87.5912];
Zipcodes['35475'] = [33.438828, -87.561779];
Zipcodes['35476'] = [33.23778, -87.579808];
Zipcodes['35477'] = [32.956131, -88.240953];
Zipcodes['35480'] = [33.097877, -87.799147];
Zipcodes['35481'] = [33.404741, -88.046619];
Zipcodes['35487'] = [33.213827, -87.547643];
Zipcodes['35490'] = [33.218419, -87.238215];
Zipcodes['35491'] = [32.93662, -88.097773];
Zipcodes['35501'] = [33.821205, -87.296618];
Zipcodes['35503'] = [33.936688, -87.305301];
Zipcodes['35504'] = [33.894135, -87.170812];
Zipcodes['35540'] = [34.218557, -87.192847];
Zipcodes['35541'] = [34.081325, -87.174349];
Zipcodes['35542'] = [33.703711, -87.681105];
Zipcodes['35543'] = [34.211155, -87.758435];
Zipcodes['35544'] = [33.959985, -88.017137];
Zipcodes['35545'] = [33.642127, -87.93145];
Zipcodes['35546'] = [33.604963, -87.545388];
Zipcodes['35548'] = [34.056419, -87.750485];
Zipcodes['35549'] = [33.822862, -87.562989];
Zipcodes['35550'] = [33.745429, -87.160466];
Zipcodes['35552'] = [34.073094, -88.142693];
Zipcodes['35553'] = [34.126813, -87.383078];
Zipcodes['35554'] = [33.885727, -87.671678];
Zipcodes['35555'] = [33.670942, -87.822623];
Zipcodes['35559'] = [33.911636, -87.735336];
Zipcodes['35563'] = [33.996537, -87.906754];
Zipcodes['35564'] = [34.258833, -87.84526];
Zipcodes['35565'] = [34.23696, -87.563402];
Zipcodes['35570'] = [34.163756, -87.993159];
Zipcodes['35571'] = [34.368708, -87.944108];
Zipcodes['35572'] = [34.20127, -87.28258];
Zipcodes['35573'] = [33.901324, -87.549994];
Zipcodes['35574'] = [33.555441, -87.968983];
Zipcodes['35575'] = [34.036179, -87.588153];
Zipcodes['35576'] = [33.588397, -88.142987];
Zipcodes['35577'] = [34.091668, -87.599289];
Zipcodes['35578'] = [33.980483, -87.466391];
Zipcodes['35579'] = [33.625137, -87.331008];
Zipcodes['35580'] = [33.700754, -87.270788];
Zipcodes['35581'] = [34.359912, -87.713097];
Zipcodes['35582'] = [34.485744, -88.095035];
Zipcodes['35584'] = [33.819268, -87.087923];
Zipcodes['35585'] = [34.419353, -87.828938];
Zipcodes['35586'] = [33.874497, -88.140445];
Zipcodes['35587'] = [33.795541, -87.456888];
Zipcodes['35592'] = [33.742726, -88.09739];
Zipcodes['35593'] = [34.353682, -88.090198];
Zipcodes['35594'] = [33.920072, -87.77866];
Zipcodes['35601'] = [34.605711, -87.008324];
Zipcodes['35603'] = [34.535452, -86.971226];
Zipcodes['35610'] = [34.955261, -87.232018];
Zipcodes['35611'] = [34.773833, -87.084145];
Zipcodes['35613'] = [34.820163, -86.882161];
Zipcodes['35614'] = [34.858596, -87.080244];
Zipcodes['35615'] = [34.662322, -86.875974];
Zipcodes['35616'] = [34.739504, -88.019381];
Zipcodes['35618'] = [34.641403, -87.304983];
Zipcodes['35619'] = [34.382723, -87.152105];
Zipcodes['35620'] = [34.930812, -87.020185];
Zipcodes['35621'] = [34.335031, -86.727056];
Zipcodes['35622'] = [34.34659, -86.898961];
Zipcodes['35630'] = [34.825167, -87.659843];
Zipcodes['35632'] = [34.80768, -87.680572];
Zipcodes['35633'] = [34.875584, -87.796396];
Zipcodes['35634'] = [34.923665, -87.608101];
Zipcodes['35640'] = [34.440708, -86.943683];
Zipcodes['35643'] = [34.672577, -87.201931];
Zipcodes['35645'] = [34.894087, -87.489341];
Zipcodes['35646'] = [34.680476, -87.537602];
Zipcodes['35647'] = [34.967655, -87.117034];
Zipcodes['35648'] = [34.959239, -87.379419];
Zipcodes['35649'] = [34.625164, -86.871585];
Zipcodes['35650'] = [34.447233, -87.308593];
Zipcodes['35651'] = [34.460909, -87.480151];
Zipcodes['35652'] = [34.846744, -87.310208];
Zipcodes['35653'] = [34.533442, -87.863699];
Zipcodes['35654'] = [34.495391, -87.620541];
Zipcodes['35660'] = [34.758412, -87.696542];
Zipcodes['35661'] = [34.768526, -87.562802];
Zipcodes['35670'] = [34.463808, -86.729363];
Zipcodes['35671'] = [34.66899, -86.950044];
Zipcodes['35672'] = [34.648533, -87.406487];
Zipcodes['35673'] = [34.585942, -87.138453];
Zipcodes['35674'] = [34.670183, -87.758541];
Zipcodes['35677'] = [34.952582, -88.024682];
Zipcodes['35739'] = [34.968586, -86.791036];
Zipcodes['35740'] = [34.965286, -85.777448];
Zipcodes['35741'] = [34.729593, -86.463305];
Zipcodes['35744'] = [34.612686, -85.904504];
Zipcodes['35745'] = [34.943416, -86.180179];
Zipcodes['35746'] = [34.828619, -86.005957];
Zipcodes['35747'] = [34.494944, -86.298989];
Zipcodes['35748'] = [34.729465, -86.394951];
Zipcodes['35749'] = [34.822212, -86.757871];
Zipcodes['35750'] = [34.956179, -86.589482];
Zipcodes['35751'] = [34.811157, -86.27646];
Zipcodes['35752'] = [34.764114, -85.980056];
Zipcodes['35754'] = [34.514575, -86.615669];
Zipcodes['35755'] = [34.509624, -86.117547];
Zipcodes['35756'] = [34.643705, -86.818149];
Zipcodes['35757'] = [34.784545, -86.744299];
Zipcodes['35758'] = [34.71142, -86.746508];
Zipcodes['35759'] = [34.868419, -86.546114];
Zipcodes['35760'] = [34.543906, -86.383856];
Zipcodes['35761'] = [34.911819, -86.404827];
Zipcodes['35763'] = [34.611654, -86.472965];
Zipcodes['35764'] = [34.733206, -86.312664];
Zipcodes['35765'] = [34.695629, -85.819999];
Zipcodes['35766'] = [34.867485, -86.299884];
Zipcodes['35768'] = [34.78388, -86.102201];
Zipcodes['35769'] = [34.597638, -86.105106];
Zipcodes['35771'] = [34.538598, -86.005423];
Zipcodes['35772'] = [34.937034, -85.948355];
Zipcodes['35773'] = [34.891069, -86.707941];
Zipcodes['35774'] = [34.765246, -86.226525];
Zipcodes['35775'] = [34.542934, -86.705959];
Zipcodes['35776'] = [34.661195, -86.252272];
Zipcodes['35801'] = [34.724611, -86.565541];
Zipcodes['35802'] = [34.676464, -86.554568];
Zipcodes['35803'] = [34.563248, -86.519513];
Zipcodes['35805'] = [34.707121, -86.621685];
Zipcodes['35806'] = [34.766424, -86.683594];
Zipcodes['35808'] = [34.632929, -86.655842];
Zipcodes['35810'] = [34.80302, -86.60348];
Zipcodes['35811'] = [34.798812, -86.503887];
Zipcodes['35816'] = [34.738322, -86.631806];
Zipcodes['35824'] = [34.644929, -86.752061];
Zipcodes['35896'] = [34.75297, -86.651594];
Zipcodes['35901'] = [34.047586, -85.933265];
Zipcodes['35903'] = [34.025715, -85.845076];
Zipcodes['35904'] = [34.07056, -85.990098];
Zipcodes['35905'] = [33.9614, -85.922422];
Zipcodes['35906'] = [33.927149, -86.095113];
Zipcodes['35907'] = [33.890841, -86.01802];
Zipcodes['35950'] = [34.249767, -86.261112];
Zipcodes['35951'] = [34.346362, -86.146243];
Zipcodes['35952'] = [34.052385, -86.310738];
Zipcodes['35953'] = [33.809425, -86.173009];
Zipcodes['35954'] = [34.089754, -86.057449];
Zipcodes['35956'] = [34.146146, -86.146749];
Zipcodes['35957'] = [34.196159, -86.196898];
Zipcodes['35958'] = [34.916005, -85.645957];
Zipcodes['35959'] = [34.245174, -85.605509];
Zipcodes['35960'] = [34.128624, -85.566051];
Zipcodes['35961'] = [34.304305, -85.846009];
Zipcodes['35962'] = [34.299346, -86.036639];
Zipcodes['35963'] = [34.359787, -85.9272];
Zipcodes['35966'] = [34.794954, -85.70328];
Zipcodes['35967'] = [34.393786, -85.697972];
Zipcodes['35968'] = [34.476011, -85.790143];
Zipcodes['35971'] = [34.45603, -85.946629];
Zipcodes['35972'] = [33.991272, -86.224209];
Zipcodes['35973'] = [34.344016, -85.551513];
Zipcodes['35974'] = [34.362751, -86.010291];
Zipcodes['35975'] = [34.440412, -86.072425];
Zipcodes['35976'] = [34.341284, -86.322195];
Zipcodes['35978'] = [34.642798, -85.728979];
Zipcodes['35979'] = [34.829959, -85.612557];
Zipcodes['35980'] = [34.172088, -86.389617];
Zipcodes['35981'] = [34.722175, -85.640692];
Zipcodes['35983'] = [34.172844, -85.766155];
Zipcodes['35984'] = [34.518576, -85.557781];
Zipcodes['35986'] = [34.504775, -85.840326];
Zipcodes['35987'] = [33.91337, -86.242208];
Zipcodes['35988'] = [34.557889, -85.796884];
Zipcodes['35989'] = [34.598464, -85.635086];
Zipcodes['35990'] = [34.066728, -86.305243];
Zipcodes['36003'] = [32.444277, -86.730043];
Zipcodes['36005'] = [31.887588, -85.736039];
Zipcodes['36006'] = [32.641456, -86.727738];
Zipcodes['36008'] = [32.496116, -86.565398];
Zipcodes['36009'] = [31.597774, -86.328835];
Zipcodes['36010'] = [31.659895, -85.812896];
Zipcodes['36013'] = [32.296841, -85.979455];
Zipcodes['36016'] = [31.850223, -85.414706];
Zipcodes['36017'] = [31.679443, -85.55772];
Zipcodes['36020'] = [32.497937, -86.316387];
Zipcodes['36022'] = [32.623414, -86.405592];
Zipcodes['36024'] = [32.663489, -86.036358];
Zipcodes['36025'] = [32.545369, -86.340906];
Zipcodes['36026'] = [32.77548, -86.130993];
Zipcodes['36027'] = [31.977787, -85.183909];
Zipcodes['36028'] = [31.484716, -86.384837];
Zipcodes['36029'] = [32.181872, -85.933154];
Zipcodes['36030'] = [31.848788, -86.861639];
Zipcodes['36031'] = [32.247749, -85.769534];
Zipcodes['36032'] = [31.998301, -86.571084];
Zipcodes['36033'] = [31.671714, -86.770613];
Zipcodes['36034'] = [31.621045, -86.111209];
Zipcodes['36035'] = [31.788705, -86.126215];
Zipcodes['36036'] = [31.97394, -86.161676];
Zipcodes['36037'] = [31.76841, -86.617768];
Zipcodes['36038'] = [31.40789, -86.486923];
Zipcodes['36039'] = [32.285675, -85.821328];
Zipcodes['36040'] = [32.184019, -86.670033];
Zipcodes['36041'] = [31.931374, -86.327951];
Zipcodes['36042'] = [31.868619, -86.459989];
Zipcodes['36043'] = [32.185049, -86.43375];
Zipcodes['36046'] = [31.946468, -86.383706];
Zipcodes['36047'] = [32.086732, -86.519618];
Zipcodes['36048'] = [31.804214, -85.592024];
Zipcodes['36049'] = [31.768143, -86.277257];
Zipcodes['36051'] = [32.686698, -86.478759];
Zipcodes['36052'] = [32.182705, -86.042825];
Zipcodes['36053'] = [32.0425, -85.448435];
Zipcodes['36054'] = [32.466369, -86.359546];
Zipcodes['36057'] = [32.374662, -86.084602];
Zipcodes['36064'] = [32.304173, -86.089919];
Zipcodes['36066'] = [32.4797, -86.42401];
Zipcodes['36067'] = [32.516518, -86.561483];
Zipcodes['36069'] = [32.097979, -86.16545];
Zipcodes['36071'] = [31.706685, -86.397754];
Zipcodes['36075'] = [32.378389, -85.9237];
Zipcodes['36078'] = [32.546979, -85.942735];
Zipcodes['36079'] = [31.766709, -85.998035];
Zipcodes['36080'] = [32.711806, -86.284022];
Zipcodes['36081'] = [31.911215, -85.898114];
Zipcodes['36082'] = [31.802725, -85.955086];
Zipcodes['36083'] = [32.386729, -85.684361];
Zipcodes['36088'] = [32.419729, -85.717085];
Zipcodes['36089'] = [32.156702, -85.701532];
Zipcodes['36091'] = [32.749508, -86.519285];
Zipcodes['36092'] = [32.614454, -86.197243];
Zipcodes['36093'] = [32.494564, -86.133634];
Zipcodes['36101'] = [32.381774, -86.297649];
Zipcodes['36104'] = [32.401942, -86.318456];
Zipcodes['36105'] = [32.214802, -86.277339];
Zipcodes['36106'] = [32.353431, -86.256963];
Zipcodes['36107'] = [32.384322, -86.279489];
Zipcodes['36108'] = [32.3391, -86.401107];
Zipcodes['36109'] = [32.385846, -86.237918];
Zipcodes['36110'] = [32.446582, -86.255772];
Zipcodes['36111'] = [32.336579, -86.271324];
Zipcodes['36112'] = [32.380944, -86.363749];
Zipcodes['36113'] = [32.380166, -86.342025];
Zipcodes['36114'] = [32.404834, -86.245723];
Zipcodes['36115'] = [32.406859, -86.246748];
Zipcodes['36116'] = [32.271565, -86.21925];
Zipcodes['36117'] = [32.377903, -86.148907];
Zipcodes['36130'] = [32.377478, -86.299405];
Zipcodes['36201'] = [33.647488, -85.884129];
Zipcodes['36203'] = [33.583049, -85.834485];
Zipcodes['36205'] = [33.699568, -85.787428];
Zipcodes['36206'] = [33.732839, -85.817957];
Zipcodes['36207'] = [33.684121, -85.705195];
Zipcodes['36250'] = [33.764709, -85.893939];
Zipcodes['36251'] = [33.226513, -85.856735];
Zipcodes['36255'] = [33.182489, -85.737301];
Zipcodes['36256'] = [33.046308, -85.704818];
Zipcodes['36258'] = [33.477734, -85.717338];
Zipcodes['36260'] = [33.587905, -86.001061];
Zipcodes['36262'] = [33.793448, -85.468276];
Zipcodes['36263'] = [33.467247, -85.360162];
Zipcodes['36264'] = [33.596783, -85.551483];
Zipcodes['36265'] = [33.847655, -85.784916];
Zipcodes['36266'] = [33.35391, -85.749287];
Zipcodes['36267'] = [33.195092, -85.946532];
Zipcodes['36268'] = [33.494805, -85.929469];
Zipcodes['36269'] = [33.739797, -85.383876];
Zipcodes['36271'] = [33.78123, -86.01869];
Zipcodes['36272'] = [33.947203, -85.601118];
Zipcodes['36273'] = [33.545031, -85.378048];
Zipcodes['36274'] = [33.179317, -85.3603];
Zipcodes['36276'] = [33.125833, -85.574216];
Zipcodes['36277'] = [33.761331, -85.81521];
Zipcodes['36278'] = [33.311046, -85.52685];
Zipcodes['36279'] = [33.872332, -85.889664];
Zipcodes['36280'] = [33.386366, -85.392468];
Zipcodes['36301'] = [31.140459, -85.409097];
Zipcodes['36303'] = [31.264692, -85.388814];
Zipcodes['36305'] = [31.204572, -85.496004];
Zipcodes['36310'] = [31.595139, -85.208847];
Zipcodes['36311'] = [31.599512, -85.686806];
Zipcodes['36312'] = [31.162583, -85.235238];
Zipcodes['36313'] = [31.180207, -85.793716];
Zipcodes['36314'] = [31.028478, -85.776732];
Zipcodes['36316'] = [31.175691, -85.863975];
Zipcodes['36317'] = [31.634584, -85.389501];
Zipcodes['36318'] = [31.169524, -85.950531];
Zipcodes['36319'] = [31.331937, -85.148874];
Zipcodes['36320'] = [31.049898, -85.319224];
Zipcodes['36321'] = [31.210866, -85.299218];
Zipcodes['36322'] = [31.257605, -85.735807];
Zipcodes['36323'] = [31.428104, -86.072471];
Zipcodes['36330'] = [31.307863, -85.858011];
Zipcodes['36340'] = [31.056273, -85.894124];
Zipcodes['36343'] = [31.060704, -85.120765];
Zipcodes['36344'] = [31.101043, -85.710255];
Zipcodes['36345'] = [31.366306, -85.310372];
Zipcodes['36346'] = [31.55724, -85.939193];
Zipcodes['36350'] = [31.350214, -85.495521];
Zipcodes['36351'] = [31.425502, -85.9066];
Zipcodes['36352'] = [31.264999, -85.609699];
Zipcodes['36353'] = [31.47853, -85.348626];
Zipcodes['36360'] = [31.467469, -85.61703];
Zipcodes['36362'] = [31.350932, -85.679787];
Zipcodes['36370'] = [31.135275, -85.16173];
Zipcodes['36371'] = [31.329961, -85.546258];
Zipcodes['36373'] = [31.51865, -85.089056];
Zipcodes['36374'] = [31.572274, -85.489563];
Zipcodes['36375'] = [31.078473, -85.540441];
Zipcodes['36376'] = [31.250679, -85.269772];
Zipcodes['36401'] = [31.46897, -86.950426];
Zipcodes['36420'] = [31.177149, -86.551502];
Zipcodes['36421'] = [31.328718, -86.508335];
Zipcodes['36425'] = [31.76474, -87.195168];
Zipcodes['36426'] = [31.145173, -87.075489];
Zipcodes['36432'] = [31.301073, -87.037298];
Zipcodes['36435'] = [31.870102, -87.451564];
Zipcodes['36436'] = [31.766333, -87.661411];
Zipcodes['36439'] = [31.419287, -87.345189];
Zipcodes['36441'] = [31.039365, -87.255045];
Zipcodes['36442'] = [31.084458, -86.357041];
Zipcodes['36444'] = [31.662875, -87.463884];
Zipcodes['36445'] = [31.444585, -87.453392];
Zipcodes['36446'] = [31.791513, -87.724483];
Zipcodes['36451'] = [31.685521, -87.818624];
Zipcodes['36453'] = [31.198847, -86.132955];
Zipcodes['36454'] = [31.35295, -87.203008];
Zipcodes['36455'] = [31.012604, -86.353816];
Zipcodes['36456'] = [31.570731, -86.829905];
Zipcodes['36460'] = [31.525162, -87.308122];
Zipcodes['36467'] = [31.277952, -86.255483];
Zipcodes['36470'] = [31.515188, -87.565908];
Zipcodes['36471'] = [31.631915, -87.231792];
Zipcodes['36473'] = [31.320414, -87.225842];
Zipcodes['36474'] = [31.42969, -86.626037];
Zipcodes['36475'] = [31.389055, -87.219999];
Zipcodes['36476'] = [31.354575, -86.548397];
Zipcodes['36477'] = [31.087039, -86.067187];
Zipcodes['36480'] = [31.341999, -87.638416];
Zipcodes['36481'] = [31.771512, -87.382559];
Zipcodes['36482'] = [31.639318, -87.6369];
Zipcodes['36483'] = [31.047796, -86.674187];
Zipcodes['36502'] = [31.147733, -87.494821];
Zipcodes['36503'] = [31.156758, -87.465398];
Zipcodes['36505'] = [30.951186, -88.013469];
Zipcodes['36507'] = [30.858609, -87.742025];
Zipcodes['36509'] = [30.406338, -88.24881];
Zipcodes['36511'] = [30.305228, -87.742637];
Zipcodes['36512'] = [30.998808, -88.018409];
Zipcodes['36513'] = [31.154973, -87.991737];
Zipcodes['36518'] = [31.470773, -88.266741];
Zipcodes['36521'] = [30.967765, -88.186809];
Zipcodes['36522'] = [31.083982, -88.275016];
Zipcodes['36523'] = [30.370798, -88.190791];
Zipcodes['36524'] = [31.802618, -88.010863];
Zipcodes['36525'] = [30.89126, -88.021015];
Zipcodes['36526'] = [30.609941, -87.863812];
Zipcodes['36527'] = [30.702075, -87.881866];
Zipcodes['36528'] = [30.240424, -88.238319];
Zipcodes['36529'] = [31.277445, -88.278821];
Zipcodes['36530'] = [30.40821, -87.555585];
Zipcodes['36532'] = [30.480713, -87.861307];
Zipcodes['36535'] = [30.383999, -87.727359];
Zipcodes['36538'] = [31.657053, -88.143271];
Zipcodes['36539'] = [31.348624, -88.394107];
Zipcodes['36540'] = [31.448949, -87.675843];
Zipcodes['36541'] = [30.478285, -88.346047];
Zipcodes['36542'] = [30.26965, -87.750627];
Zipcodes['36543'] = [31.221534, -87.466165];
Zipcodes['36544'] = [30.474931, -88.239604];
Zipcodes['36545'] = [31.488511, -87.871704];
Zipcodes['36547'] = [30.252206, -87.654504];
Zipcodes['36548'] = [31.480372, -87.970958];
Zipcodes['36549'] = [30.411162, -87.451119];
Zipcodes['36550'] = [31.24943, -87.730436];
Zipcodes['36551'] = [30.636677, -87.74703];
Zipcodes['36553'] = [31.233904, -88.091567];
Zipcodes['36555'] = [30.399667, -87.773019];
Zipcodes['36556'] = [31.205186, -87.963791];
Zipcodes['36558'] = [31.621199, -88.327956];
Zipcodes['36559'] = [30.56904, -87.903933];
Zipcodes['36560'] = [31.085195, -88.049045];
Zipcodes['36561'] = [30.287622, -87.571267];
Zipcodes['36562'] = [31.037057, -87.670416];
Zipcodes['36564'] = [30.469787, -87.914526];
Zipcodes['36567'] = [30.623536, -87.55696];
Zipcodes['36568'] = [30.511814, -88.272373];
Zipcodes['36569'] = [31.547672, -88.088037];
Zipcodes['36571'] = [30.854566, -88.109842];
Zipcodes['36572'] = [30.85604, -88.059194];
Zipcodes['36574'] = [30.500977, -87.478432];
Zipcodes['36575'] = [30.76766, -88.272656];
Zipcodes['36576'] = [30.522939, -87.763842];
Zipcodes['36578'] = [30.737389, -87.771888];
Zipcodes['36579'] = [31.110476, -87.786871];
Zipcodes['36580'] = [30.478898, -87.703414];
Zipcodes['36581'] = [31.354632, -88.019104];
Zipcodes['36582'] = [30.51791, -88.17275];
Zipcodes['36583'] = [31.367319, -88.195002];
Zipcodes['36584'] = [31.199096, -88.388739];
Zipcodes['36585'] = [31.423523, -88.084456];
Zipcodes['36587'] = [30.836453, -88.349925];
Zipcodes['36602'] = [30.697537, -88.04028];
Zipcodes['36603'] = [30.684162, -88.052756];
Zipcodes['36604'] = [30.682299, -88.068365];
Zipcodes['36605'] = [30.602446, -88.091303];
Zipcodes['36606'] = [30.668786, -88.105874];
Zipcodes['36607'] = [30.69907, -88.105512];
Zipcodes['36608'] = [30.681503, -88.300213];
Zipcodes['36609'] = [30.660248, -88.163479];
Zipcodes['36610'] = [30.737085, -88.073172];
Zipcodes['36611'] = [30.769985, -88.076672];
Zipcodes['36612'] = [30.752882, -88.113135];
Zipcodes['36613'] = [30.809841, -88.187451];
Zipcodes['36615'] = [30.630196, -88.069943];
Zipcodes['36617'] = [30.715328, -88.094821];
Zipcodes['36618'] = [30.7369, -88.169796];
Zipcodes['36619'] = [30.596087, -88.196637];
Zipcodes['36652'] = [30.695925, -88.080342];
Zipcodes['36663'] = [30.736854, -88.108849];
Zipcodes['36688'] = [30.695541, -88.187867];
Zipcodes['36693'] = [30.62577, -88.149686];
Zipcodes['36695'] = [30.631258, -88.280593];
Zipcodes['36701'] = [32.496332, -87.057391];
Zipcodes['36703'] = [32.427349, -86.912858];
Zipcodes['36720'] = [32.164988, -87.346996];
Zipcodes['36722'] = [32.082439, -87.571494];
Zipcodes['36723'] = [32.068375, -87.294343];
Zipcodes['36726'] = [31.960453, -87.299484];
Zipcodes['36727'] = [31.910985, -88.03391];
Zipcodes['36728'] = [32.169012, -87.483394];
Zipcodes['36732'] = [32.417456, -87.892213];
Zipcodes['36736'] = [32.084706, -87.761188];
Zipcodes['36738'] = [32.42097, -87.624965];
Zipcodes['36740'] = [32.626783, -87.836571];
Zipcodes['36741'] = [32.030712, -87.012476];
Zipcodes['36742'] = [32.456156, -87.752226];
Zipcodes['36744'] = [32.684719, -87.598773];
Zipcodes['36748'] = [32.267275, -87.780901];
Zipcodes['36749'] = [32.550967, -86.849827];
Zipcodes['36750'] = [32.789616, -86.849377];
Zipcodes['36751'] = [31.803944, -87.576925];
Zipcodes['36752'] = [32.298669, -86.631995];
Zipcodes['36753'] = [31.836973, -87.107572];
Zipcodes['36754'] = [32.157313, -87.70238];
Zipcodes['36756'] = [32.692315, -87.299514];
Zipcodes['36758'] = [32.638892, -86.88991];
Zipcodes['36759'] = [32.404536, -87.290023];
Zipcodes['36761'] = [32.074107, -86.941109];
Zipcodes['36763'] = [32.276802, -87.980704];
Zipcodes['36764'] = [32.115184, -87.985264];
Zipcodes['36765'] = [32.588164, -87.522245];
Zipcodes['36766'] = [31.955047, -87.086673];
Zipcodes['36767'] = [32.245371, -87.204508];
Zipcodes['36768'] = [31.885394, -87.012859];
Zipcodes['36769'] = [31.992777, -87.551501];
Zipcodes['36773'] = [32.291124, -87.38522];
Zipcodes['36775'] = [32.19271, -86.954805];
Zipcodes['36776'] = [32.735606, -87.741416];
Zipcodes['36782'] = [32.10303, -87.913924];
Zipcodes['36783'] = [32.217047, -87.611655];
Zipcodes['36784'] = [31.919846, -87.834121];
Zipcodes['36785'] = [32.277617, -86.823481];
Zipcodes['36786'] = [32.430994, -87.482151];
Zipcodes['36790'] = [32.736428, -86.908481];
Zipcodes['36792'] = [32.918022, -86.91568];
Zipcodes['36793'] = [32.822856, -87.006438];
Zipcodes['36801'] = [32.702498, -85.385475];
Zipcodes['36804'] = [32.533973, -85.347519];
Zipcodes['36830'] = [32.534872, -85.493755];
Zipcodes['36832'] = [32.582936, -85.585938];
Zipcodes['36849'] = [32.59914, -85.48927];
Zipcodes['36850'] = [32.786919, -85.655757];
Zipcodes['36851'] = [32.150424, -85.080888];
Zipcodes['36852'] = [32.756611, -85.278908];
Zipcodes['36853'] = [32.804816, -85.768148];
Zipcodes['36854'] = [32.751613, -85.189484];
Zipcodes['36855'] = [33.028908, -85.308151];
Zipcodes['36856'] = [32.260957, -84.995401];
Zipcodes['36858'] = [32.323162, -85.307813];
Zipcodes['36860'] = [32.274537, -85.424439];
Zipcodes['36861'] = [32.890893, -85.849191];
Zipcodes['36862'] = [32.903965, -85.455157];
Zipcodes['36863'] = [32.889494, -85.26375];
Zipcodes['36865'] = [32.602618, -85.589334];
Zipcodes['36866'] = [32.565305, -85.712082];
Zipcodes['36867'] = [32.498054, -85.02359];
Zipcodes['36869'] = [32.419698, -85.06456];
Zipcodes['36870'] = [32.482781, -85.119859];
Zipcodes['36871'] = [32.158169, -85.156733];
Zipcodes['36874'] = [32.564252, -85.198549];
Zipcodes['36875'] = [32.31858, -85.167161];
Zipcodes['36877'] = [32.573153, -85.101369];
Zipcodes['36879'] = [32.731317, -85.544644];
Zipcodes['36901'] = [32.421636, -88.14733];
Zipcodes['36904'] = [32.100714, -88.260849];
Zipcodes['36907'] = [32.438096, -88.348591];
Zipcodes['36908'] = [31.872768, -88.237076];
Zipcodes['36910'] = [32.230807, -88.159321];
Zipcodes['36912'] = [32.242349, -88.305689];
Zipcodes['36913'] = [31.960734, -88.446676];
Zipcodes['36915'] = [31.958969, -88.360194];
Zipcodes['36916'] = [32.241505, -88.049184];
Zipcodes['36919'] = [31.769052, -88.282862];
Zipcodes['36921'] = [31.93843, -88.18278];
Zipcodes['36922'] = [32.297498, -88.149295];
Zipcodes['36925'] = [32.432254, -88.183591];
Zipcodes['37010'] = [36.571781, -87.108705];
Zipcodes['37012'] = [36.07111, -86.005649];
Zipcodes['37013'] = [36.051845, -86.630396];
Zipcodes['37014'] = [35.873015, -86.632923];
Zipcodes['37015'] = [36.297457, -87.091738];
Zipcodes['37016'] = [35.963556, -86.113618];
Zipcodes['37018'] = [35.6314, -86.210955];
Zipcodes['37019'] = [35.390902, -86.709331];
Zipcodes['37020'] = [35.634389, -86.407093];
Zipcodes['37022'] = [36.514582, -86.317038];
Zipcodes['37023'] = [36.592456, -87.782744];
Zipcodes['37025'] = [35.952659, -87.308467];
Zipcodes['37026'] = [35.69578, -86.107456];
Zipcodes['37027'] = [35.999288, -86.785062];
Zipcodes['37028'] = [36.628017, -87.852264];
Zipcodes['37029'] = [36.043084, -87.268786];
Zipcodes['37030'] = [36.271671, -85.942372];
Zipcodes['37031'] = [36.377463, -86.301172];
Zipcodes['37032'] = [36.525153, -87.016524];
Zipcodes['37033'] = [35.744615, -87.557839];
Zipcodes['37034'] = [35.637125, -86.694765];
Zipcodes['37035'] = [36.381108, -87.133983];
Zipcodes['37036'] = [36.234982, -87.261263];
Zipcodes['37037'] = [35.703083, -86.351238];
Zipcodes['37040'] = [36.522319, -87.327933];
Zipcodes['37042'] = [36.569339, -87.411845];
Zipcodes['37043'] = [36.490572, -87.240797];
Zipcodes['37046'] = [35.781852, -86.70075];
Zipcodes['37047'] = [35.312921, -86.831904];
Zipcodes['37048'] = [36.49042, -86.591085];
Zipcodes['37049'] = [36.561975, -86.682138];
Zipcodes['37050'] = [36.374244, -87.630844];
Zipcodes['37051'] = [36.304272, -87.414042];
Zipcodes['37052'] = [36.37258, -87.407441];
Zipcodes['37055'] = [36.077209, -87.442803];
Zipcodes['37057'] = [36.418109, -86.029944];
Zipcodes['37058'] = [36.444038, -87.876678];
Zipcodes['37059'] = [35.984992, -85.90307];
Zipcodes['37060'] = [35.749229, -86.634283];
Zipcodes['37061'] = [36.276545, -87.66302];
Zipcodes['37062'] = [35.990294, -87.135646];
Zipcodes['37064'] = [35.884862, -86.954138];
Zipcodes['37066'] = [36.398757, -86.45554];
Zipcodes['37067'] = [35.912204, -86.768843];
Zipcodes['37069'] = [35.984319, -86.902292];
Zipcodes['37072'] = [36.35214, -86.746651];
Zipcodes['37073'] = [36.432642, -86.817152];
Zipcodes['37074'] = [36.426041, -86.1529];
Zipcodes['37075'] = [36.339969, -86.607922];
Zipcodes['37076'] = [36.145176, -86.576636];
Zipcodes['37078'] = [35.903693, -87.774514];
Zipcodes['37079'] = [36.464769, -87.648093];
Zipcodes['37080'] = [36.332761, -86.916886];
Zipcodes['37082'] = [36.094872, -87.115266];
Zipcodes['37083'] = [36.537757, -86.00096];
Zipcodes['37085'] = [35.953078, -86.279107];
Zipcodes['37086'] = [36.022237, -86.559384];
Zipcodes['37087'] = [36.263852, -86.269244];
Zipcodes['37090'] = [36.115439, -86.297637];
Zipcodes['37091'] = [35.489244, -86.767472];
Zipcodes['37095'] = [35.965928, -85.961077];
Zipcodes['37096'] = [35.612943, -87.87101];
Zipcodes['37097'] = [35.779941, -87.823417];
Zipcodes['37098'] = [35.871459, -87.307846];
Zipcodes['37101'] = [36.078905, -87.62675];
Zipcodes['37110'] = [35.64509, -85.741252];
Zipcodes['37115'] = [36.255279, -86.698667];
Zipcodes['37118'] = [35.925673, -86.189014];
Zipcodes['37122'] = [36.178348, -86.48658];
Zipcodes['37127'] = [35.779478, -86.327234];
Zipcodes['37128'] = [35.803224, -86.474647];
Zipcodes['37129'] = [35.929834, -86.450822];
Zipcodes['37130'] = [35.881689, -86.318747];
Zipcodes['37132'] = [35.848732, -86.362829];
Zipcodes['37134'] = [35.992741, -87.948093];
Zipcodes['37135'] = [35.915789, -86.676587];
Zipcodes['37137'] = [35.884173, -87.505349];
Zipcodes['37138'] = [36.237708, -86.60703];
Zipcodes['37140'] = [35.863435, -87.652441];
Zipcodes['37141'] = [36.614476, -86.698935];
Zipcodes['37142'] = [36.410966, -87.509666];
Zipcodes['37143'] = [36.122529, -87.027133];
Zipcodes['37144'] = [35.305107, -86.655879];
Zipcodes['37145'] = [36.380909, -85.917012];
Zipcodes['37146'] = [36.404614, -87.032372];
Zipcodes['37148'] = [36.576658, -86.497813];
Zipcodes['37149'] = [35.808727, -86.193158];
Zipcodes['37150'] = [36.541483, -85.81041];
Zipcodes['37151'] = [36.309201, -86.04302];
Zipcodes['37153'] = [35.764477, -86.55531];
Zipcodes['37160'] = [35.455746, -86.48479];
Zipcodes['37165'] = [36.29113, -87.475439];
Zipcodes['37166'] = [35.917978, -85.786903];
Zipcodes['37167'] = [35.962828, -86.529684];
Zipcodes['37171'] = [36.369418, -87.310858];
Zipcodes['37172'] = [36.548385, -86.849451];
Zipcodes['37174'] = [35.719255, -86.898188];
Zipcodes['37175'] = [36.32426, -87.892851];
Zipcodes['37178'] = [36.323791, -87.790436];
Zipcodes['37179'] = [35.812608, -86.910077];
Zipcodes['37180'] = [35.621483, -86.575353];
Zipcodes['37181'] = [36.244378, -87.505893];
Zipcodes['37183'] = [35.505256, -86.287376];
Zipcodes['37184'] = [36.086788, -86.145212];
Zipcodes['37185'] = [36.062922, -87.865036];
Zipcodes['37186'] = [36.596738, -86.221711];
Zipcodes['37187'] = [36.144148, -87.197886];
Zipcodes['37188'] = [36.480492, -86.680971];
Zipcodes['37189'] = [36.278598, -86.831464];
Zipcodes['37190'] = [35.823091, -86.042159];
Zipcodes['37191'] = [36.505841, -87.530104];
Zipcodes['37201'] = [36.166986, -86.77832];
Zipcodes['37203'] = [36.149775, -86.789146];
Zipcodes['37204'] = [36.106676, -86.774575];
Zipcodes['37205'] = [36.113187, -86.870443];
Zipcodes['37206'] = [36.179319, -86.731083];
Zipcodes['37207'] = [36.232473, -86.777342];
Zipcodes['37208'] = [36.177546, -86.809518];
Zipcodes['37209'] = [36.150656, -86.959839];
Zipcodes['37210'] = [36.149248, -86.733016];
Zipcodes['37211'] = [36.067017, -86.723419];
Zipcodes['37212'] = [36.133773, -86.801688];
Zipcodes['37213'] = [36.167221, -86.768181];
Zipcodes['37214'] = [36.169038, -86.662565];
Zipcodes['37215'] = [36.081955, -86.834904];
Zipcodes['37216'] = [36.215017, -86.726911];
Zipcodes['37217'] = [36.108666, -86.656722];
Zipcodes['37218'] = [36.171353, -86.918161];
Zipcodes['37219'] = [36.166282, -86.782951];
Zipcodes['37220'] = [36.068447, -86.793127];
Zipcodes['37221'] = [36.059241, -86.972312];
Zipcodes['37228'] = [36.19693, -86.803373];
Zipcodes['37232'] = [36.141117, -86.802888];
Zipcodes['37238'] = [36.165697, -86.779462];
Zipcodes['37301'] = [35.417244, -85.795212];
Zipcodes['37302'] = [35.015703, -85.021748];
Zipcodes['37303'] = [35.442594, -84.639736];
Zipcodes['37305'] = [35.457963, -85.702038];
Zipcodes['37306'] = [35.096807, -86.191801];
Zipcodes['37307'] = [35.164958, -84.626256];
Zipcodes['37308'] = [35.342635, -84.993756];
Zipcodes['37309'] = [35.301834, -84.741456];
Zipcodes['37310'] = [35.252525, -84.710066];
Zipcodes['37311'] = [35.104016, -84.920828];
Zipcodes['37312'] = [35.226009, -84.874479];
Zipcodes['37313'] = [35.376389, -85.695496];
Zipcodes['37315'] = [35.047214, -85.051644];
Zipcodes['37316'] = [35.001661, -84.729673];
Zipcodes['37317'] = [35.031001, -84.443056];
Zipcodes['37318'] = [35.178719, -85.980014];
Zipcodes['37321'] = [35.502103, -85.008024];
Zipcodes['37322'] = [35.501959, -84.825071];
Zipcodes['37323'] = [35.096183, -84.816533];
Zipcodes['37324'] = [35.239754, -85.987165];
Zipcodes['37325'] = [35.253065, -84.604887];
Zipcodes['37326'] = [35.040252, -84.383286];
Zipcodes['37327'] = [35.416267, -85.394408];
Zipcodes['37328'] = [35.036942, -86.357374];
Zipcodes['37329'] = [35.391992, -84.460149];
Zipcodes['37330'] = [35.279637, -86.130603];
Zipcodes['37331'] = [35.31617, -84.535776];
Zipcodes['37332'] = [35.60059, -84.949548];
Zipcodes['37333'] = [35.148414, -84.35978];
Zipcodes['37334'] = [35.165417, -86.5796];
Zipcodes['37335'] = [35.068898, -86.403992];
Zipcodes['37336'] = [35.308843, -84.931512];
Zipcodes['37337'] = [35.787056, -84.850664];
Zipcodes['37338'] = [35.429787, -85.209519];
Zipcodes['37339'] = [35.373851, -85.632211];
Zipcodes['37340'] = [35.021988, -85.519895];
Zipcodes['37341'] = [35.219931, -85.073002];
Zipcodes['37342'] = [35.383549, -85.946858];
Zipcodes['37343'] = [35.166587, -85.212257];
Zipcodes['37345'] = [35.016829, -86.203041];
Zipcodes['37347'] = [35.063468, -85.613418];
Zipcodes['37348'] = [35.121827, -86.430786];
Zipcodes['37350'] = [34.994485, -85.351577];
Zipcodes['37351'] = [35.100285, -85.260363];
Zipcodes['37352'] = [35.27752, -86.353027];
Zipcodes['37353'] = [35.12286, -84.985492];
Zipcodes['37354'] = [35.506343, -84.347404];
Zipcodes['37355'] = [35.509287, -86.079287];
Zipcodes['37356'] = [35.228878, -85.823694];
Zipcodes['37357'] = [35.593909, -85.918342];
Zipcodes['37359'] = [35.204342, -86.403866];
Zipcodes['37360'] = [35.437578, -86.254328];
Zipcodes['37361'] = [35.087864, -84.656749];
Zipcodes['37362'] = [35.031284, -84.680857];
Zipcodes['37363'] = [35.121041, -85.0556];
Zipcodes['37365'] = [35.368696, -85.516325];
Zipcodes['37366'] = [35.338856, -85.830003];
Zipcodes['37367'] = [35.619509, -85.2263];
Zipcodes['37369'] = [35.205589, -84.464366];
Zipcodes['37370'] = [35.359048, -84.700606];
Zipcodes['37373'] = [35.407459, -85.092816];
Zipcodes['37374'] = [35.164983, -85.661818];
Zipcodes['37375'] = [35.150451, -85.890419];
Zipcodes['37376'] = [35.047392, -85.95836];
Zipcodes['37377'] = [35.212136, -85.362333];
Zipcodes['37379'] = [35.284581, -85.194872];
Zipcodes['37380'] = [35.070937, -85.754605];
Zipcodes['37381'] = [35.686974, -84.877368];
Zipcodes['37383'] = [35.211405, -85.913222];
Zipcodes['37385'] = [35.336793, -84.228286];
Zipcodes['37387'] = [35.27425, -85.720584];
Zipcodes['37388'] = [35.351487, -86.198185];
Zipcodes['37391'] = [35.093235, -84.35392];
Zipcodes['37394'] = [35.539186, -85.860646];
Zipcodes['37396'] = [35.001888, -85.503458];
Zipcodes['37397'] = [35.207609, -85.517909];
Zipcodes['37398'] = [35.181164, -86.13836];
Zipcodes['37402'] = [35.046805, -85.316289];
Zipcodes['37403'] = [35.048259, -85.294362];
Zipcodes['37404'] = [35.028234, -85.273258];
Zipcodes['37405'] = [35.129933, -85.382836];
Zipcodes['37406'] = [35.073134, -85.245571];
Zipcodes['37407'] = [35.001943, -85.289724];
Zipcodes['37408'] = [35.030816, -85.311638];
Zipcodes['37409'] = [35.010262, -85.335905];
Zipcodes['37410'] = [35.001899, -85.31422];
Zipcodes['37411'] = [35.026581, -85.230237];
Zipcodes['37412'] = [34.997437, -85.227199];
Zipcodes['37415'] = [35.124725, -85.281199];
Zipcodes['37416'] = [35.098865, -85.157005];
Zipcodes['37419'] = [35.051568, -85.421089];
Zipcodes['37421'] = [35.027473, -85.153604];
Zipcodes['37450'] = [35.048966, -85.312169];
Zipcodes['37601'] = [36.33825, -82.318084];
Zipcodes['37604'] = [36.299242, -82.383044];
Zipcodes['37614'] = [36.300978, -82.367398];
Zipcodes['37615'] = [36.398218, -82.452386];
Zipcodes['37616'] = [36.222507, -82.7516];
Zipcodes['37617'] = [36.52449, -82.365651];
Zipcodes['37618'] = [36.460339, -82.208744];
Zipcodes['37620'] = [36.549613, -82.09404];
Zipcodes['37640'] = [36.326824, -81.974686];
Zipcodes['37641'] = [36.199887, -82.663513];
Zipcodes['37642'] = [36.53133, -82.72694];
Zipcodes['37643'] = [36.370513, -82.122288];
Zipcodes['37645'] = [36.562766, -82.662455];
Zipcodes['37650'] = [36.087789, -82.468573];
Zipcodes['37656'] = [36.399971, -82.630786];
Zipcodes['37657'] = [36.017238, -82.560518];
Zipcodes['37658'] = [36.260442, -82.151596];
Zipcodes['37659'] = [36.279849, -82.506119];
Zipcodes['37660'] = [36.52686, -82.571968];
Zipcodes['37663'] = [36.463015, -82.488724];
Zipcodes['37664'] = [36.508113, -82.510198];
Zipcodes['37665'] = [36.579483, -82.573101];
Zipcodes['37680'] = [36.574631, -81.725538];
Zipcodes['37681'] = [36.258014, -82.6206];
Zipcodes['37682'] = [36.300321, -82.291672];
Zipcodes['37683'] = [36.453006, -81.822364];
Zipcodes['37686'] = [36.440209, -82.348811];
Zipcodes['37687'] = [36.178723, -82.097005];
Zipcodes['37688'] = [36.545444, -81.882879];
Zipcodes['37690'] = [36.25121, -82.559009];
Zipcodes['37691'] = [36.36791, -81.755247];
Zipcodes['37692'] = [36.184591, -82.30414];
Zipcodes['37694'] = [36.383788, -82.278722];
Zipcodes['37701'] = [35.791709, -83.987225];
Zipcodes['37705'] = [36.22688, -84.009714];
Zipcodes['37708'] = [36.330558, -83.317142];
Zipcodes['37709'] = [36.166501, -83.665];
Zipcodes['37710'] = [36.158033, -84.317712];
Zipcodes['37711'] = [36.281355, -83.045426];
Zipcodes['37713'] = [36.081457, -83.134285];
Zipcodes['37714'] = [36.230864, -84.254168];
Zipcodes['37715'] = [36.553208, -83.958942];
Zipcodes['37716'] = [36.100883, -84.168691];
Zipcodes['37719'] = [36.021862, -84.428085];
Zipcodes['37721'] = [36.130591, -83.814869];
Zipcodes['37722'] = [35.815156, -83.201096];
Zipcodes['37723'] = [35.97043, -84.813656];
Zipcodes['37724'] = [36.548535, -83.710257];
Zipcodes['37725'] = [35.997639, -83.393303];
Zipcodes['37726'] = [36.188121, -84.810269];
Zipcodes['37727'] = [35.885101, -83.022989];
Zipcodes['37729'] = [36.514122, -84.032754];
Zipcodes['37730'] = [36.56839, -83.973561];
Zipcodes['37731'] = [36.548667, -83.009063];
Zipcodes['37732'] = [36.327959, -84.628235];
Zipcodes['37733'] = [36.356736, -84.695035];
Zipcodes['37737'] = [35.761416, -84.120173];
Zipcodes['37738'] = [35.671291, -83.476474];
Zipcodes['37742'] = [35.675844, -84.179246];
Zipcodes['37743'] = [36.068327, -82.855697];
Zipcodes['37745'] = [36.276299, -82.831197];
Zipcodes['37748'] = [35.945373, -84.516366];
Zipcodes['37752'] = [36.575959, -83.547391];
Zipcodes['37753'] = [35.822812, -83.098];
Zipcodes['37754'] = [36.139099, -84.029801];
Zipcodes['37755'] = [36.422783, -84.532704];
Zipcodes['37756'] = [36.301631, -84.407038];
Zipcodes['37757'] = [36.261396, -84.105815];
Zipcodes['37760'] = [36.103764, -83.466311];
Zipcodes['37762'] = [36.576416, -84.119247];
Zipcodes['37763'] = [35.823448, -84.488177];
Zipcodes['37764'] = [35.970346, -83.616642];
Zipcodes['37765'] = [36.572344, -83.058614];
Zipcodes['37766'] = [36.398375, -84.073032];
Zipcodes['37769'] = [36.21785, -84.155468];
Zipcodes['37770'] = [36.152416, -84.641827];
Zipcodes['37771'] = [35.838499, -84.316382];
Zipcodes['37772'] = [35.793377, -84.212106];
Zipcodes['37774'] = [35.72913, -84.358961];
Zipcodes['37777'] = [35.822632, -84.052409];
Zipcodes['37779'] = [36.210942, -83.757913];
Zipcodes['37801'] = [35.671704, -84.080473];
Zipcodes['37803'] = [35.660842, -83.981389];
Zipcodes['37804'] = [35.792278, -83.893149];
Zipcodes['37806'] = [36.084693, -83.727986];
Zipcodes['37807'] = [36.259656, -83.82599];
Zipcodes['37809'] = [36.156693, -83.038092];
Zipcodes['37810'] = [36.177664, -83.10196];
Zipcodes['37811'] = [36.345957, -83.212268];
Zipcodes['37813'] = [36.175136, -83.257024];
Zipcodes['37814'] = [36.233341, -83.337912];
Zipcodes['37818'] = [36.185587, -82.973049];
Zipcodes['37819'] = [36.568255, -84.205753];
Zipcodes['37820'] = [36.085171, -83.566057];
Zipcodes['37821'] = [35.97508, -83.195503];
Zipcodes['37825'] = [36.415883, -83.679108];
Zipcodes['37826'] = [35.558226, -84.589828];
Zipcodes['37828'] = [36.197826, -84.06886];
Zipcodes['37829'] = [36.00102, -84.617381];
Zipcodes['37830'] = [35.966483, -84.290549];
Zipcodes['37840'] = [36.067831, -84.369634];
Zipcodes['37841'] = [36.50871, -84.543828];
Zipcodes['37843'] = [36.010713, -83.04836];
Zipcodes['37845'] = [36.103708, -84.450161];
Zipcodes['37846'] = [35.679251, -84.481817];
Zipcodes['37847'] = [36.431554, -84.273435];
Zipcodes['37848'] = [36.237034, -83.681748];
Zipcodes['37849'] = [36.05405, -84.048488];
Zipcodes['37851'] = [36.56559, -83.792466];
Zipcodes['37852'] = [36.324146, -84.579513];
Zipcodes['37853'] = [35.836766, -83.91081];
Zipcodes['37854'] = [35.886081, -84.709146];
Zipcodes['37857'] = [36.424289, -82.9414];
Zipcodes['37860'] = [36.240151, -83.179967];
Zipcodes['37861'] = [36.240743, -83.513713];
Zipcodes['37862'] = [35.736359, -83.672368];
Zipcodes['37863'] = [35.786377, -83.563687];
Zipcodes['37865'] = [35.851151, -83.734888];
Zipcodes['37866'] = [36.347281, -83.843304];
Zipcodes['37869'] = [36.514755, -83.244996];
Zipcodes['37870'] = [36.462895, -83.833801];
Zipcodes['37871'] = [36.046975, -83.681338];
Zipcodes['37872'] = [36.268699, -84.681205];
Zipcodes['37873'] = [36.52627, -82.843047];
Zipcodes['37874'] = [35.59833, -84.456484];
Zipcodes['37876'] = [35.865101, -83.47897];
Zipcodes['37877'] = [36.15113, -83.406846];
Zipcodes['37878'] = [35.596591, -83.981296];
Zipcodes['37879'] = [36.473348, -83.513314];
Zipcodes['37880'] = [35.700908, -84.660106];
Zipcodes['37881'] = [36.398144, -83.362463];
Zipcodes['37882'] = [35.604444, -83.819391];
Zipcodes['37885'] = [35.4989, -84.137703];
Zipcodes['37886'] = [35.741482, -83.807486];
Zipcodes['37887'] = [36.070712, -84.61413];
Zipcodes['37888'] = [36.329877, -83.579136];
Zipcodes['37890'] = [36.080387, -83.293078];
Zipcodes['37891'] = [36.292068, -83.138109];
Zipcodes['37892'] = [36.555696, -84.357644];
Zipcodes['37902'] = [35.962, -83.919622];
Zipcodes['37909'] = [35.947161, -84.022715];
Zipcodes['37912'] = [36.008487, -83.984687];
Zipcodes['37914'] = [35.983165, -83.795796];
Zipcodes['37915'] = [35.970813, -83.900432];
Zipcodes['37916'] = [35.953078, -83.932588];
Zipcodes['37917'] = [36.001581, -83.91265];
Zipcodes['37918'] = [36.056875, -83.917352];
Zipcodes['37919'] = [35.914748, -84.000169];
Zipcodes['37920'] = [35.911567, -83.857782];
Zipcodes['37921'] = [35.980971, -83.996905];
Zipcodes['37922'] = [35.860496, -84.100535];
Zipcodes['37923'] = [35.927527, -84.080687];
Zipcodes['37924'] = [36.02619, -83.811875];
Zipcodes['37929'] = [35.962404, -83.915969];
Zipcodes['37931'] = [35.976321, -84.126349];
Zipcodes['37932'] = [35.917299, -84.198787];
Zipcodes['37934'] = [35.87618, -84.180016];
Zipcodes['37938'] = [36.125008, -83.938175];
Zipcodes['38001'] = [35.816186, -89.149391];
Zipcodes['38002'] = [35.291995, -89.702318];
Zipcodes['38004'] = [35.412241, -89.759212];
Zipcodes['38006'] = [35.693723, -89.089657];
Zipcodes['38007'] = [36.164233, -89.439192];
Zipcodes['38008'] = [35.243353, -89.000379];
Zipcodes['38011'] = [35.473872, -89.714429];
Zipcodes['38012'] = [35.610021, -89.270574];
Zipcodes['38015'] = [35.559364, -89.824229];
Zipcodes['38016'] = [35.18086, -89.759838];
Zipcodes['38017'] = [35.066953, -89.654078];
Zipcodes['38018'] = [35.138613, -89.766424];
Zipcodes['38019'] = [35.561088, -89.631953];
Zipcodes['38021'] = [35.870442, -89.184078];
Zipcodes['38023'] = [35.474501, -89.964131];
Zipcodes['38024'] = [36.036919, -89.384751];
Zipcodes['38028'] = [35.183099, -89.6322];
Zipcodes['38029'] = [35.242098, -89.823522];
Zipcodes['38030'] = [35.985502, -89.61783];
Zipcodes['38034'] = [35.914103, -89.232081];
Zipcodes['38036'] = [35.327248, -89.619386];
Zipcodes['38037'] = [35.797359, -89.398895];
Zipcodes['38039'] = [35.0572, -89.186231];
Zipcodes['38040'] = [35.879109, -89.482941];
Zipcodes['38041'] = [35.652486, -89.675132];
Zipcodes['38042'] = [35.152981, -89.136662];
Zipcodes['38044'] = [35.215364, -88.807847];
Zipcodes['38046'] = [35.046002, -89.244092];
Zipcodes['38047'] = [36.065651, -89.547142];
Zipcodes['38049'] = [35.390333, -89.53821];
Zipcodes['38050'] = [35.816301, -89.227092];
Zipcodes['38052'] = [35.090253, -88.9178];
Zipcodes['38053'] = [35.328122, -89.940824];
Zipcodes['38054'] = [35.335804, -89.874415];
Zipcodes['38057'] = [35.052435, -89.358978];
Zipcodes['38058'] = [35.457857, -89.816192];
Zipcodes['38059'] = [36.115718, -89.239733];
Zipcodes['38060'] = [35.209347, -89.505468];
Zipcodes['38061'] = [35.06839, -88.770907];
Zipcodes['38063'] = [35.746419, -89.62939];
Zipcodes['38066'] = [35.076505, -89.534306];
Zipcodes['38067'] = [35.081609, -89.041788];
Zipcodes['38068'] = [35.25837, -89.335239];
Zipcodes['38069'] = [35.455618, -89.369888];
Zipcodes['38070'] = [35.940437, -89.249173];
Zipcodes['38075'] = [35.382302, -89.168055];
Zipcodes['38076'] = [35.123773, -89.466428];
Zipcodes['38077'] = [36.328517, -89.474404];
Zipcodes['38079'] = [36.413305, -89.469247];
Zipcodes['38080'] = [36.207086, -89.519208];
Zipcodes['38103'] = [35.156214, -90.055462];
Zipcodes['38104'] = [35.132559, -90.004289];
Zipcodes['38105'] = [35.151419, -90.035468];
Zipcodes['38106'] = [35.100623, -90.037407];
Zipcodes['38107'] = [35.170165, -90.023442];
Zipcodes['38108'] = [35.1769, -89.963974];
Zipcodes['38109'] = [35.037886, -90.154242];
Zipcodes['38111'] = [35.110521, -89.943501];
Zipcodes['38112'] = [35.147662, -89.976392];
Zipcodes['38113'] = [35.103782, -90.140824];
Zipcodes['38114'] = [35.09648, -89.985957];
Zipcodes['38115'] = [35.053175, -89.862635];
Zipcodes['38116'] = [35.032339, -90.008916];
Zipcodes['38117'] = [35.115008, -89.905517];
Zipcodes['38118'] = [35.035097, -89.927158];
Zipcodes['38119'] = [35.079511, -89.845385];
Zipcodes['38120'] = [35.123633, -89.852645];
Zipcodes['38122'] = [35.157102, -89.922444];
Zipcodes['38125'] = [35.025426, -89.78934];
Zipcodes['38126'] = [35.126977, -90.043696];
Zipcodes['38127'] = [35.242751, -90.02679];
Zipcodes['38128'] = [35.224574, -89.926689];
Zipcodes['38131'] = [35.066198, -89.992631];
Zipcodes['38132'] = [35.070634, -90.000393];
Zipcodes['38133'] = [35.21294, -89.794712];
Zipcodes['38134'] = [35.17454, -89.860777];
Zipcodes['38135'] = [35.238915, -89.848589];
Zipcodes['38138'] = [35.084786, -89.799272];
Zipcodes['38139'] = [35.082886, -89.754836];
Zipcodes['38141'] = [35.01603, -89.854765];
Zipcodes['38152'] = [35.118273, -89.93745];
Zipcodes['38201'] = [36.122634, -88.530194];
Zipcodes['38220'] = [35.993659, -88.670883];
Zipcodes['38221'] = [36.244904, -88.042202];
Zipcodes['38222'] = [36.445536, -88.157903];
Zipcodes['38224'] = [36.387007, -88.492179];
Zipcodes['38225'] = [36.31885, -88.67074];
Zipcodes['38226'] = [36.474282, -88.657034];
Zipcodes['38229'] = [36.219487, -88.610129];
Zipcodes['38230'] = [36.155177, -88.774665];
Zipcodes['38231'] = [36.213058, -88.426137];
Zipcodes['38232'] = [36.35901, -89.324181];
Zipcodes['38233'] = [36.203649, -89.043102];
Zipcodes['38235'] = [35.991104, -88.58126];
Zipcodes['38236'] = [36.178011, -88.275798];
Zipcodes['38237'] = [36.362563, -88.833542];
Zipcodes['38240'] = [36.245091, -89.318119];
Zipcodes['38241'] = [36.427772, -88.591844];
Zipcodes['38242'] = [36.306875, -88.337468];
Zipcodes['38251'] = [36.459872, -88.355856];
Zipcodes['38253'] = [36.308088, -89.059119];
Zipcodes['38254'] = [36.374442, -89.346612];
Zipcodes['38255'] = [36.240718, -88.85913];
Zipcodes['38256'] = [36.31754, -88.124676];
Zipcodes['38257'] = [36.463999, -88.886965];
Zipcodes['38258'] = [36.017941, -88.612596];
Zipcodes['38259'] = [36.202153, -89.18405];
Zipcodes['38260'] = [36.375274, -89.212672];
Zipcodes['38261'] = [36.42886, -89.113506];
Zipcodes['38301'] = [35.588164, -88.853395];
Zipcodes['38305'] = [35.701654, -88.768058];
Zipcodes['38310'] = [35.261655, -88.40859];
Zipcodes['38311'] = [35.435844, -88.097796];
Zipcodes['38313'] = [35.610339, -88.619827];
Zipcodes['38315'] = [35.268299, -88.615671];
Zipcodes['38316'] = [36.069868, -88.816869];
Zipcodes['38317'] = [36.057937, -88.26381];
Zipcodes['38318'] = [35.947414, -88.242002];
Zipcodes['38320'] = [36.045977, -88.109864];
Zipcodes['38321'] = [35.839302, -88.529314];
Zipcodes['38326'] = [35.045783, -88.278279];
Zipcodes['38327'] = [35.22405, -88.30206];
Zipcodes['38328'] = [35.668697, -88.208588];
Zipcodes['38329'] = [35.537576, -88.10779];
Zipcodes['38330'] = [36.070503, -89.034414];
Zipcodes['38332'] = [35.419764, -88.429352];
Zipcodes['38333'] = [36.128104, -87.95446];
Zipcodes['38334'] = [35.349837, -88.586353];
Zipcodes['38337'] = [35.778321, -89.028763];
Zipcodes['38338'] = [35.874646, -88.84878];
Zipcodes['38339'] = [35.052376, -88.514705];
Zipcodes['38340'] = [35.404805, -88.679411];
Zipcodes['38341'] = [35.877577, -88.083895];
Zipcodes['38342'] = [36.07174, -88.288871];
Zipcodes['38343'] = [35.82696, -88.928388];
Zipcodes['38344'] = [35.978284, -88.434633];
Zipcodes['38345'] = [35.595297, -88.507532];
Zipcodes['38347'] = [35.476299, -88.498514];
Zipcodes['38348'] = [35.872744, -88.650963];
Zipcodes['38351'] = [35.661918, -88.40479];
Zipcodes['38352'] = [35.529461, -88.533849];
Zipcodes['38355'] = [35.784009, -88.775061];
Zipcodes['38356'] = [35.438397, -88.893792];
Zipcodes['38357'] = [35.051488, -88.413921];
Zipcodes['38358'] = [35.916675, -88.758664];
Zipcodes['38359'] = [35.372232, -88.351119];
Zipcodes['38361'] = [35.311301, -88.299924];
Zipcodes['38362'] = [35.72694, -88.785157];
Zipcodes['38363'] = [35.687839, -88.110728];
Zipcodes['38365'] = [35.057656, -88.237619];
Zipcodes['38366'] = [35.479, -88.741273];
Zipcodes['38367'] = [35.053048, -88.623242];
Zipcodes['38368'] = [35.496063, -88.358896];
Zipcodes['38369'] = [36.131922, -88.942666];
Zipcodes['38370'] = [35.391477, -88.247962];
Zipcodes['38371'] = [35.423968, -88.295293];
Zipcodes['38372'] = [35.143564, -88.156874];
Zipcodes['38374'] = [35.52377, -88.238816];
Zipcodes['38375'] = [35.159956, -88.587028];
Zipcodes['38376'] = [35.130665, -88.347373];
Zipcodes['38379'] = [35.164694, -88.430564];
Zipcodes['38380'] = [35.779631, -88.039214];
Zipcodes['38381'] = [35.349277, -88.959499];
Zipcodes['38382'] = [35.968379, -88.984461];
Zipcodes['38387'] = [35.87292, -88.277462];
Zipcodes['38388'] = [35.781609, -88.309654];
Zipcodes['38389'] = [36.103118, -89.121406];
Zipcodes['38390'] = [35.838457, -88.377949];
Zipcodes['38391'] = [35.546571, -88.992177];
Zipcodes['38392'] = [35.457967, -89.028052];
Zipcodes['38401'] = [35.629164, -87.021526];
Zipcodes['38425'] = [35.416033, -87.969678];
Zipcodes['38449'] = [35.043063, -86.831074];
Zipcodes['38450'] = [35.203881, -87.669227];
Zipcodes['38451'] = [35.460534, -86.992239];
Zipcodes['38452'] = [35.078891, -87.792396];
Zipcodes['38453'] = [35.119204, -86.814744];
Zipcodes['38454'] = [35.728171, -87.345316];
Zipcodes['38455'] = [35.061981, -86.893642];
Zipcodes['38456'] = [35.33467, -87.265611];
Zipcodes['38457'] = [35.029667, -87.28484];
Zipcodes['38459'] = [35.191334, -86.802196];
Zipcodes['38460'] = [35.101157, -87.159372];
Zipcodes['38461'] = [35.595141, -87.339879];
Zipcodes['38462'] = [35.52745, -87.543456];
Zipcodes['38463'] = [35.070595, -87.645115];
Zipcodes['38464'] = [35.289918, -87.433746];
Zipcodes['38468'] = [35.135146, -87.291063];
Zipcodes['38469'] = [35.074631, -87.428316];
Zipcodes['38471'] = [35.083901, -87.932397];
Zipcodes['38472'] = [35.377324, -87.038742];
Zipcodes['38473'] = [35.021246, -87.171674];
Zipcodes['38474'] = [35.518299, -87.231551];
Zipcodes['38475'] = [35.262384, -88.023391];
Zipcodes['38476'] = [35.843364, -87.209904];
Zipcodes['38477'] = [35.059762, -87.018871];
Zipcodes['38478'] = [35.232879, -87.005872];
Zipcodes['38481'] = [35.028051, -87.488656];
Zipcodes['38482'] = [35.768825, -87.149896];
Zipcodes['38483'] = [35.440765, -87.333659];
Zipcodes['38485'] = [35.356672, -87.782248];
Zipcodes['38486'] = [35.173101, -87.540249];
Zipcodes['38487'] = [35.718387, -87.23346];
Zipcodes['38488'] = [35.028524, -86.686496];
Zipcodes['38501'] = [36.226045, -85.533285];
Zipcodes['38503'] = [36.16318, -85.505716];
Zipcodes['38504'] = [36.393141, -84.735299];
Zipcodes['38505'] = [36.17318, -85.505637];
Zipcodes['38506'] = [36.187036, -85.433518];
Zipcodes['38541'] = [36.538018, -85.335604];
Zipcodes['38542'] = [36.31578, -85.20116];
Zipcodes['38543'] = [36.350088, -85.150003];
Zipcodes['38544'] = [36.109883, -85.652321];
Zipcodes['38545'] = [36.231332, -85.659651];
Zipcodes['38547'] = [36.154655, -86.00947];
Zipcodes['38548'] = [36.174831, -85.791953];
Zipcodes['38549'] = [36.562107, -85.149059];
Zipcodes['38550'] = [35.7698, -85.632721];
Zipcodes['38551'] = [36.55805, -85.487178];
Zipcodes['38552'] = [36.217685, -85.813769];
Zipcodes['38553'] = [36.203218, -85.004427];
Zipcodes['38554'] = [36.251325, -85.160968];
Zipcodes['38555'] = [35.887881, -84.989016];
Zipcodes['38556'] = [36.40758, -84.927605];
Zipcodes['38558'] = [36.009851, -84.866557];
Zipcodes['38559'] = [35.808437, -85.508014];
Zipcodes['38560'] = [36.221926, -85.855639];
Zipcodes['38562'] = [36.351641, -85.659335];
Zipcodes['38563'] = [36.208803, -86.020716];
Zipcodes['38564'] = [36.256912, -85.754534];
Zipcodes['38565'] = [36.262955, -85.009854];
Zipcodes['38567'] = [36.132827, -85.925669];
Zipcodes['38568'] = [36.438492, -85.473057];
Zipcodes['38569'] = [36.118716, -85.859247];
Zipcodes['38570'] = [36.376292, -85.326413];
Zipcodes['38571'] = [36.051604, -85.012804];
Zipcodes['38572'] = [35.849059, -85.12896];
Zipcodes['38573'] = [36.493416, -85.223882];
Zipcodes['38574'] = [36.143643, -85.229288];
Zipcodes['38575'] = [36.553225, -85.629502];
Zipcodes['38577'] = [36.562156, -84.953088];
Zipcodes['38578'] = [35.980308, -85.196306];
Zipcodes['38579'] = [35.835867, -85.548177];
Zipcodes['38580'] = [36.277282, -85.295259];
Zipcodes['38581'] = [35.74104, -85.620827];
Zipcodes['38582'] = [36.099444, -85.764549];
Zipcodes['38583'] = [35.953136, -85.430418];
Zipcodes['38585'] = [35.688126, -85.414797];
Zipcodes['38587'] = [35.864761, -85.613998];
Zipcodes['38588'] = [36.446386, -85.730671];
Zipcodes['38589'] = [36.28549, -85.076214];
Zipcodes['38601'] = [34.478541, -89.451271];
Zipcodes['38603'] = [34.84841, -89.14226];
Zipcodes['38606'] = [34.303285, -89.95225];
Zipcodes['38610'] = [34.651787, -89.025549];
Zipcodes['38611'] = [34.856233, -89.67602];
Zipcodes['38614'] = [34.159567, -90.608353];
Zipcodes['38617'] = [34.36167, -90.490578];
Zipcodes['38618'] = [34.716282, -89.937848];
Zipcodes['38619'] = [34.51528, -89.823801];
Zipcodes['38620'] = [34.236735, -89.916136];
Zipcodes['38621'] = [34.447192, -90.170459];
Zipcodes['38622'] = [34.169492, -90.134577];
Zipcodes['38623'] = [34.354003, -90.309813];
Zipcodes['38625'] = [34.614317, -88.835904];
Zipcodes['38626'] = [34.51998, -90.433941];
Zipcodes['38627'] = [34.454558, -89.23804];
Zipcodes['38629'] = [34.868465, -88.990462];
Zipcodes['38631'] = [34.361, -90.625543];
Zipcodes['38632'] = [34.795914, -90.015068];
Zipcodes['38633'] = [34.647337, -89.194971];
Zipcodes['38635'] = [34.766919, -89.48633];
Zipcodes['38637'] = [34.950824, -90.048695];
Zipcodes['38639'] = [34.309083, -90.428534];
Zipcodes['38641'] = [34.91122, -90.169506];
Zipcodes['38642'] = [34.911338, -89.34139];
Zipcodes['38643'] = [34.148487, -90.260865];
Zipcodes['38644'] = [34.45889, -90.475777];
Zipcodes['38645'] = [34.247827, -90.47638];
Zipcodes['38646'] = [34.287169, -90.298573];
Zipcodes['38647'] = [34.963506, -89.224108];
Zipcodes['38650'] = [34.532061, -89.142466];
Zipcodes['38651'] = [34.893416, -90.002219];
Zipcodes['38652'] = [34.486088, -88.996739];
Zipcodes['38654'] = [34.933929, -89.81628];
Zipcodes['38655'] = [34.329772, -89.487118];
Zipcodes['38658'] = [34.188341, -89.900997];
Zipcodes['38659'] = [34.621505, -89.331511];
Zipcodes['38661'] = [34.882735, -89.556577];
Zipcodes['38663'] = [34.738947, -88.902893];
Zipcodes['38664'] = [34.811462, -90.329253];
Zipcodes['38665'] = [34.583273, -90.166012];
Zipcodes['38666'] = [34.418127, -89.928772];
Zipcodes['38668'] = [34.590668, -89.917818];
Zipcodes['38670'] = [34.433025, -90.28595];
Zipcodes['38671'] = [34.96626, -89.998152];
Zipcodes['38672'] = [34.9114, -89.927894];
Zipcodes['38673'] = [34.293443, -89.635806];
Zipcodes['38674'] = [34.86837, -88.892398];
Zipcodes['38676'] = [34.674208, -90.366823];
Zipcodes['38677'] = [34.361968, -89.538578];
Zipcodes['38680'] = [34.957753, -90.145108];
Zipcodes['38683'] = [34.935325, -88.854938];
Zipcodes['38685'] = [34.593072, -89.488486];
Zipcodes['38701'] = [33.302578, -91.028375];
Zipcodes['38703'] = [33.475662, -91.052834];
Zipcodes['38720'] = [34.108397, -90.788009];
Zipcodes['38721'] = [32.970368, -90.752127];
Zipcodes['38722'] = [33.260304, -90.883939];
Zipcodes['38723'] = [33.229676, -91.038462];
Zipcodes['38725'] = [33.635451, -91.060082];
Zipcodes['38726'] = [33.753407, -90.977334];
Zipcodes['38730'] = [33.680878, -90.804903];
Zipcodes['38731'] = [33.123508, -91.130809];
Zipcodes['38732'] = [33.758855, -90.725595];
Zipcodes['38733'] = [33.744626, -90.735133];
Zipcodes['38736'] = [33.647759, -90.543171];
Zipcodes['38737'] = [33.861002, -90.49643];
Zipcodes['38738'] = [33.93292, -90.534414];
Zipcodes['38740'] = [34.049925, -90.805229];
Zipcodes['38744'] = [33.029689, -91.051318];
Zipcodes['38745'] = [32.974211, -91.039242];
Zipcodes['38746'] = [33.961591, -90.954433];
Zipcodes['38748'] = [33.155088, -90.875976];
Zipcodes['38751'] = [33.453305, -90.674979];
Zipcodes['38753'] = [33.349405, -90.584439];
Zipcodes['38754'] = [33.249944, -90.622535];
Zipcodes['38756'] = [33.389575, -90.86025];
Zipcodes['38759'] = [33.829126, -90.737388];
Zipcodes['38760'] = [33.453544, -90.993986];
Zipcodes['38761'] = [33.429275, -90.469962];
Zipcodes['38762'] = [33.896439, -90.725653];
Zipcodes['38764'] = [33.799139, -90.88412];
Zipcodes['38765'] = [33.049293, -90.889553];
Zipcodes['38767'] = [34.128071, -90.805499];
Zipcodes['38768'] = [33.970883, -90.477488];
Zipcodes['38769'] = [33.84241, -90.978141];
Zipcodes['38771'] = [33.739586, -90.523058];
Zipcodes['38772'] = [33.562525, -91.087659];
Zipcodes['38773'] = [33.604991, -90.773389];
Zipcodes['38774'] = [33.965722, -90.767497];
Zipcodes['38776'] = [33.424672, -90.927454];
Zipcodes['38778'] = [33.55399, -90.514164];
Zipcodes['38781'] = [33.908716, -90.756821];
Zipcodes['38801'] = [34.222629, -88.774588];
Zipcodes['38804'] = [34.281378, -88.672873];
Zipcodes['38821'] = [33.973786, -88.437683];
Zipcodes['38824'] = [34.519299, -88.641004];
Zipcodes['38826'] = [34.302823, -88.848271];
Zipcodes['38827'] = [34.511227, -88.242369];
Zipcodes['38828'] = [34.423833, -88.864862];
Zipcodes['38829'] = [34.652493, -88.536769];
Zipcodes['38833'] = [34.833917, -88.342912];
Zipcodes['38834'] = [34.917816, -88.559666];
Zipcodes['38838'] = [34.546433, -88.255741];
Zipcodes['38839'] = [33.855572, -89.284062];
Zipcodes['38841'] = [34.348077, -89.021483];
Zipcodes['38843'] = [34.262054, -88.376344];
Zipcodes['38844'] = [33.83904, -88.255872];
Zipcodes['38846'] = [34.85172, -88.385389];
Zipcodes['38847'] = [34.41254, -88.23296];
Zipcodes['38848'] = [33.936684, -88.282673];
Zipcodes['38849'] = [34.460957, -88.696049];
Zipcodes['38850'] = [34.058891, -89.057937];
Zipcodes['38851'] = [33.910353, -88.967727];
Zipcodes['38852'] = [34.844249, -88.216912];
Zipcodes['38855'] = [34.323342, -88.485408];
Zipcodes['38856'] = [34.467115, -88.455149];
Zipcodes['38857'] = [34.281521, -88.574989];
Zipcodes['38858'] = [34.095829, -88.531015];
Zipcodes['38859'] = [34.515197, -88.368607];
Zipcodes['38860'] = [33.976696, -88.759696];
Zipcodes['38862'] = [34.168139, -88.623239];
Zipcodes['38863'] = [34.217021, -89.027469];
Zipcodes['38864'] = [34.157795, -89.207824];
Zipcodes['38865'] = [34.788031, -88.595406];
Zipcodes['38866'] = [34.36762, -88.680078];
Zipcodes['38868'] = [34.12565, -88.742951];
Zipcodes['38869'] = [34.356295, -88.83632];
Zipcodes['38870'] = [34.070018, -88.339264];
Zipcodes['38871'] = [34.33457, -89.206816];
Zipcodes['38873'] = [34.66487, -88.251348];
Zipcodes['38876'] = [34.21359, -88.230292];
Zipcodes['38878'] = [33.880346, -89.190093];
Zipcodes['38879'] = [34.195185, -88.712124];
Zipcodes['38901'] = [33.761689, -89.799115];
Zipcodes['38913'] = [34.138881, -89.384872];
Zipcodes['38914'] = [33.871651, -89.47047];
Zipcodes['38915'] = [34.03764, -89.388855];
Zipcodes['38916'] = [33.829912, -89.351138];
Zipcodes['38917'] = [33.538822, -89.981966];
Zipcodes['38920'] = [33.879609, -90.037111];
Zipcodes['38921'] = [33.982149, -90.144184];
Zipcodes['38922'] = [33.932659, -89.6509];
Zipcodes['38923'] = [33.377769, -89.992074];
Zipcodes['38924'] = [33.289691, -90.215676];
Zipcodes['38925'] = [33.630282, -89.618211];
Zipcodes['38927'] = [34.142879, -90.026843];
Zipcodes['38928'] = [33.869471, -90.291899];
Zipcodes['38929'] = [33.738853, -89.523096];
Zipcodes['38930'] = [33.527674, -90.138656];
Zipcodes['38940'] = [33.74686, -90.026036];
Zipcodes['38941'] = [33.484573, -90.358231];
Zipcodes['38943'] = [33.533304, -89.839447];
Zipcodes['38944'] = [33.765008, -90.341539];
Zipcodes['38946'] = [33.377039, -90.355874];
Zipcodes['38947'] = [33.517185, -89.921182];
Zipcodes['38948'] = [34.076148, -89.894126];
Zipcodes['38949'] = [34.179756, -89.428097];
Zipcodes['38950'] = [33.733058, -90.200045];
Zipcodes['38951'] = [33.973783, -89.29049];
Zipcodes['38952'] = [33.616802, -90.333902];
Zipcodes['38953'] = [33.919298, -89.932198];
Zipcodes['38954'] = [33.407969, -90.219581];
Zipcodes['38957'] = [33.987487, -90.372958];
Zipcodes['38961'] = [33.962265, -89.884233];
Zipcodes['38962'] = [33.872154, -90.185241];
Zipcodes['38963'] = [34.049355, -90.313518];
Zipcodes['38964'] = [34.112894, -90.387903];
Zipcodes['38965'] = [34.134989, -89.590925];
Zipcodes['38966'] = [33.920072, -90.317632];
Zipcodes['38967'] = [33.459525, -89.718167];
Zipcodes['39038'] = [33.201606, -90.474455];
Zipcodes['39039'] = [32.816556, -90.22886];
Zipcodes['39040'] = [32.629706, -90.417476];
Zipcodes['39041'] = [32.404769, -90.468863];
Zipcodes['39042'] = [32.197671, -89.894896];
Zipcodes['39044'] = [32.027132, -89.971611];
Zipcodes['39045'] = [32.819535, -89.784552];
Zipcodes['39046'] = [32.629563, -89.993488];
Zipcodes['39047'] = [32.452378, -89.949364];
Zipcodes['39051'] = [32.780342, -89.513458];
Zipcodes['39054'] = [32.798549, -90.935253];
Zipcodes['39056'] = [32.373552, -90.350592];
Zipcodes['39057'] = [32.490854, -89.284427];
Zipcodes['39058'] = [32.335712, -90.331549];
Zipcodes['39059'] = [31.982611, -90.350491];
Zipcodes['39061'] = [33.088405, -90.804386];
Zipcodes['39062'] = [31.984861, -89.898316];
Zipcodes['39063'] = [33.116674, -89.897489];
Zipcodes['39066'] = [32.296627, -90.606153];
Zipcodes['39067'] = [33.114946, -89.450465];
Zipcodes['39069'] = [31.68616, -91.043249];
Zipcodes['39071'] = [32.546094, -90.319187];
Zipcodes['39073'] = [32.102431, -90.135817];
Zipcodes['39074'] = [32.385998, -89.467337];
Zipcodes['39078'] = [31.878048, -90.188204];
Zipcodes['39079'] = [32.930988, -89.88886];
Zipcodes['39082'] = [31.941844, -90.120075];
Zipcodes['39083'] = [31.835325, -90.467283];
Zipcodes['39086'] = [31.962556, -90.763661];
Zipcodes['39088'] = [32.740748, -90.746856];
Zipcodes['39090'] = [33.012936, -89.543606];
Zipcodes['39092'] = [32.319465, -89.363441];
Zipcodes['39094'] = [32.575409, -89.675262];
Zipcodes['39095'] = [33.13191, -90.117243];
Zipcodes['39096'] = [31.817628, -91.120695];
Zipcodes['39097'] = [32.989067, -90.607666];
Zipcodes['39108'] = [33.162117, -89.327734];
Zipcodes['39110'] = [32.505332, -90.137593];
Zipcodes['39111'] = [31.896635, -89.718092];
Zipcodes['39113'] = [32.761006, -91.117861];
Zipcodes['39114'] = [31.951836, -89.824587];
Zipcodes['39115'] = [33.047869, -90.560389];
Zipcodes['39116'] = [31.896441, -89.538254];
Zipcodes['39117'] = [32.309634, -89.664992];
Zipcodes['39119'] = [31.751549, -89.670777];
Zipcodes['39120'] = [31.508801, -91.357371];
Zipcodes['39140'] = [31.749249, -90.020207];
Zipcodes['39144'] = [31.821213, -90.792161];
Zipcodes['39145'] = [32.344663, -89.794359];
Zipcodes['39146'] = [32.875897, -89.99491];
Zipcodes['39148'] = [32.061734, -89.995248];
Zipcodes['39149'] = [31.831965, -90.010649];
Zipcodes['39150'] = [31.987233, -90.985084];
Zipcodes['39152'] = [32.207475, -89.585474];
Zipcodes['39153'] = [32.045133, -89.490046];
Zipcodes['39154'] = [32.225111, -90.444671];
Zipcodes['39156'] = [32.546541, -90.77759];
Zipcodes['39157'] = [32.425376, -90.165592];
Zipcodes['39159'] = [32.822724, -90.911519];
Zipcodes['39160'] = [33.003124, -89.74716];
Zipcodes['39162'] = [32.618799, -90.624417];
Zipcodes['39166'] = [33.055718, -90.481544];
Zipcodes['39167'] = [32.09309, -90.040036];
Zipcodes['39168'] = [31.831878, -89.411823];
Zipcodes['39169'] = [33.215516, -90.245118];
Zipcodes['39170'] = [32.11177, -90.330653];
Zipcodes['39173'] = [32.742416, -90.472172];
Zipcodes['39174'] = [32.401019, -90.160257];
Zipcodes['39175'] = [32.089225, -90.622579];
Zipcodes['39176'] = [33.299719, -89.752909];
Zipcodes['39177'] = [32.606211, -90.823228];
Zipcodes['39179'] = [32.80244, -90.096935];
Zipcodes['39180'] = [32.23866, -90.893728];
Zipcodes['39183'] = [32.462618, -90.823208];
Zipcodes['39189'] = [32.608003, -89.400149];
Zipcodes['39191'] = [31.715655, -90.408869];
Zipcodes['39192'] = [33.194769, -89.746172];
Zipcodes['39193'] = [32.243461, -90.069892];
Zipcodes['39194'] = [32.842991, -90.515099];
Zipcodes['39201'] = [32.289606, -90.184324];
Zipcodes['39202'] = [32.311054, -90.16979];
Zipcodes['39203'] = [32.309223, -90.20038];
Zipcodes['39204'] = [32.283134, -90.236896];
Zipcodes['39206'] = [32.37234, -90.172688];
Zipcodes['39208'] = [32.25973, -90.091888];
Zipcodes['39209'] = [32.395519, -90.293173];
Zipcodes['39210'] = [32.32248, -90.179991];
Zipcodes['39211'] = [32.372138, -90.122743];
Zipcodes['39212'] = [32.246199, -90.259645];
Zipcodes['39213'] = [32.394736, -90.22496];
Zipcodes['39216'] = [32.335241, -90.161417];
Zipcodes['39217'] = [32.297555, -90.209639];
Zipcodes['39218'] = [32.226071, -90.156494];
Zipcodes['39232'] = [32.336997, -90.085883];
Zipcodes['39269'] = [32.30114, -90.188903];
Zipcodes['39272'] = [32.188422, -90.262445];
Zipcodes['39301'] = [32.276115, -88.592851];
Zipcodes['39305'] = [32.457923, -88.727353];
Zipcodes['39307'] = [32.335033, -88.789959];
Zipcodes['39309'] = [32.552243, -88.55089];
Zipcodes['39320'] = [32.564451, -88.748337];
Zipcodes['39322'] = [31.535554, -88.503823];
Zipcodes['39323'] = [32.352256, -88.923758];
Zipcodes['39324'] = [31.580375, -88.697438];
Zipcodes['39325'] = [32.561981, -88.877459];
Zipcodes['39326'] = [32.602744, -88.656165];
Zipcodes['39327'] = [32.445808, -89.122279];
Zipcodes['39328'] = [32.743509, -88.698611];
Zipcodes['39330'] = [32.189753, -88.857568];
Zipcodes['39332'] = [32.3043, -88.998857];
Zipcodes['39335'] = [32.533136, -88.49109];
Zipcodes['39336'] = [32.300601, -89.2622];
Zipcodes['39337'] = [32.51847, -88.996283];
Zipcodes['39338'] = [32.106743, -89.230936];
Zipcodes['39339'] = [33.111225, -89.008793];
Zipcodes['39341'] = [33.088581, -88.556133];
Zipcodes['39342'] = [32.424999, -88.64987];
Zipcodes['39345'] = [32.282232, -89.122113];
Zipcodes['39346'] = [32.965035, -89.095535];
Zipcodes['39347'] = [32.060443, -88.942004];
Zipcodes['39348'] = [31.990133, -89.041426];
Zipcodes['39350'] = [32.796469, -89.125241];
Zipcodes['39352'] = [32.674323, -88.481726];
Zipcodes['39354'] = [32.875326, -88.840419];
Zipcodes['39355'] = [32.05882, -88.61903];
Zipcodes['39356'] = [32.13298, -89.036385];
Zipcodes['39358'] = [32.831033, -88.453711];
Zipcodes['39359'] = [32.568531, -89.33207];
Zipcodes['39360'] = [31.864814, -88.754017];
Zipcodes['39361'] = [32.995472, -88.596378];
Zipcodes['39362'] = [31.39096, -88.541129];
Zipcodes['39363'] = [32.097188, -88.781868];
Zipcodes['39364'] = [32.405281, -88.486617];
Zipcodes['39365'] = [32.608635, -89.120184];
Zipcodes['39366'] = [31.941109, -88.900216];
Zipcodes['39367'] = [31.684373, -88.668296];
Zipcodes['39401'] = [31.234112, -89.265885];
Zipcodes['39402'] = [31.333168, -89.414072];
Zipcodes['39406'] = [31.328937, -89.335063];
Zipcodes['39421'] = [31.492362, -89.721243];
Zipcodes['39422'] = [31.955709, -89.265911];
Zipcodes['39423'] = [31.177551, -88.911995];
Zipcodes['39425'] = [31.069402, -89.060504];
Zipcodes['39426'] = [30.655773, -89.656862];
Zipcodes['39427'] = [31.489296, -89.82901];
Zipcodes['39428'] = [31.651051, -89.572839];
Zipcodes['39429'] = [31.24203, -89.779433];
Zipcodes['39437'] = [31.579926, -89.218571];
Zipcodes['39439'] = [31.863128, -89.001401];
Zipcodes['39440'] = [31.693183, -89.148281];
Zipcodes['39443'] = [31.716721, -89.078154];
Zipcodes['39451'] = [31.166512, -88.623419];
Zipcodes['39452'] = [30.886863, -88.592987];
Zipcodes['39455'] = [31.011564, -89.470558];
Zipcodes['39456'] = [31.030633, -88.827676];
Zipcodes['39459'] = [31.489786, -89.304575];
Zipcodes['39461'] = [31.20579, -88.732706];
Zipcodes['39462'] = [31.234012, -89.056621];
Zipcodes['39464'] = [31.492814, -89.061626];
Zipcodes['39465'] = [31.339572, -89.18817];
Zipcodes['39466'] = [30.53472, -89.617988];
Zipcodes['39470'] = [30.822939, -89.568872];
Zipcodes['39474'] = [31.621243, -89.854275];
Zipcodes['39475'] = [31.155087, -89.413507];
Zipcodes['39476'] = [31.386969, -88.866929];
Zipcodes['39477'] = [31.795734, -89.038321];
Zipcodes['39478'] = [31.054177, -89.85681];
Zipcodes['39479'] = [31.542107, -89.46421];
Zipcodes['39480'] = [31.704964, -89.316216];
Zipcodes['39481'] = [31.868389, -89.238201];
Zipcodes['39482'] = [31.372022, -89.579154];
Zipcodes['39483'] = [31.242774, -89.932348];
Zipcodes['39501'] = [30.387191, -89.099244];
Zipcodes['39503'] = [30.472894, -89.143324];
Zipcodes['39507'] = [30.400484, -89.034466];
Zipcodes['39520'] = [30.268896, -89.480787];
Zipcodes['39525'] = [30.381932, -89.370784];
Zipcodes['39529'] = [30.378163, -89.600625];
Zipcodes['39530'] = [30.401521, -88.89171];
Zipcodes['39531'] = [30.403994, -88.966133];
Zipcodes['39532'] = [30.501031, -88.975603];
Zipcodes['39534'] = [30.408572, -88.921273];
Zipcodes['39540'] = [30.442815, -88.897306];
Zipcodes['39553'] = [30.415444, -88.645744];
Zipcodes['39556'] = [30.441244, -89.427883];
Zipcodes['39560'] = [30.374244, -89.170776];
Zipcodes['39561'] = [30.707671, -89.154823];
Zipcodes['39562'] = [30.546682, -88.489452];
Zipcodes['39563'] = [30.425914, -88.532733];
Zipcodes['39564'] = [30.40477, -88.771229];
Zipcodes['39565'] = [30.611822, -88.761584];
Zipcodes['39567'] = [30.359723, -88.558539];
Zipcodes['39571'] = [30.420066, -89.275043];
Zipcodes['39572'] = [30.250826, -89.604803];
Zipcodes['39573'] = [30.730994, -89.093398];
Zipcodes['39574'] = [30.608496, -89.106093];
Zipcodes['39576'] = [30.28981, -89.383391];
Zipcodes['39577'] = [30.904729, -89.086488];
Zipcodes['39581'] = [30.350539, -88.493571];
Zipcodes['39595'] = [30.334078, -88.578536];
Zipcodes['39601'] = [31.570297, -90.475516];
Zipcodes['39629'] = [31.436637, -90.45685];
Zipcodes['39630'] = [31.459306, -90.84535];
Zipcodes['39631'] = [31.067208, -91.134006];
Zipcodes['39633'] = [31.292805, -91.167671];
Zipcodes['39635'] = [31.184305, -90.448574];
Zipcodes['39638'] = [31.225004, -90.995924];
Zipcodes['39641'] = [31.356403, -90.181851];
Zipcodes['39643'] = [31.246549, -90.014258];
Zipcodes['39645'] = [31.135943, -90.79399];
Zipcodes['39647'] = [31.526087, -90.711567];
Zipcodes['39648'] = [31.177896, -90.385212];
Zipcodes['39652'] = [31.111061, -90.465322];
Zipcodes['39653'] = [31.46121, -90.872092];
Zipcodes['39654'] = [31.54103, -90.148993];
Zipcodes['39656'] = [31.443901, -89.931798];
Zipcodes['39657'] = [31.040324, -90.506268];
Zipcodes['39661'] = [31.484447, -91.083468];
Zipcodes['39662'] = [31.386372, -90.285394];
Zipcodes['39663'] = [31.594893, -90.038161];
Zipcodes['39664'] = [31.32597, -90.674153];
Zipcodes['39665'] = [31.641253, -90.214036];
Zipcodes['39666'] = [31.287691, -90.494997];
Zipcodes['39667'] = [31.124737, -90.126164];
Zipcodes['39668'] = [31.691275, -90.791257];
Zipcodes['39669'] = [31.150673, -91.362519];
Zipcodes['39701'] = [33.393934, -88.50472];
Zipcodes['39702'] = [33.4317, -88.345532];
Zipcodes['39705'] = [33.587945, -88.441102];
Zipcodes['39730'] = [33.843635, -88.565615];
Zipcodes['39735'] = [33.344573, -89.186133];
Zipcodes['39736'] = [33.416861, -88.641787];
Zipcodes['39737'] = [33.650545, -89.336013];
Zipcodes['39739'] = [33.238369, -88.578745];
Zipcodes['39740'] = [33.715002, -88.315745];
Zipcodes['39741'] = [33.636028, -88.854675];
Zipcodes['39743'] = [33.336759, -88.61892];
Zipcodes['39744'] = [33.580161, -89.300323];
Zipcodes['39745'] = [33.298333, -89.46959];
Zipcodes['39746'] = [33.74904, -88.414965];
Zipcodes['39747'] = [33.424217, -89.535944];
Zipcodes['39750'] = [33.568951, -89.044787];
Zipcodes['39751'] = [33.695332, -89.093208];
Zipcodes['39752'] = [33.522607, -89.150748];
Zipcodes['39753'] = [33.48448, -88.630491];
Zipcodes['39755'] = [33.607825, -88.964378];
Zipcodes['39756'] = [33.756717, -88.741085];
Zipcodes['39759'] = [33.437924, -88.825142];
Zipcodes['39762'] = [33.446509, -88.795826];
Zipcodes['39766'] = [33.58657, -88.321105];
Zipcodes['39767'] = [33.467999, -89.452318];
Zipcodes['39769'] = [33.321925, -89.017837];
Zipcodes['39771'] = [33.606669, -89.279109];
Zipcodes['39772'] = [33.295108, -89.304307];
Zipcodes['39773'] = [33.639913, -88.672716];
Zipcodes['39776'] = [33.785325, -89.031378];
Zipcodes['39813'] = [31.423156, -84.684305];
Zipcodes['39815'] = [30.73885, -84.490698];
Zipcodes['39817'] = [30.947881, -84.593427];
Zipcodes['39819'] = [30.785401, -84.645153];
Zipcodes['39823'] = [31.333568, -84.947033];
Zipcodes['39824'] = [31.528358, -84.864795];
Zipcodes['39825'] = [30.930767, -84.740763];
Zipcodes['39826'] = [31.818496, -84.321144];
Zipcodes['39827'] = [30.947615, -84.210378];
Zipcodes['39828'] = [30.79859, -84.226723];
Zipcodes['39832'] = [31.19106, -85.027892];
Zipcodes['39834'] = [30.894291, -84.431155];
Zipcodes['39836'] = [31.666007, -84.868633];
Zipcodes['39837'] = [31.152104, -84.677706];
Zipcodes['39840'] = [31.7813, -84.764124];
Zipcodes['39841'] = [31.292866, -84.685044];
Zipcodes['39842'] = [31.754312, -84.435527];
Zipcodes['39845'] = [30.95145, -84.892724];
Zipcodes['39846'] = [31.568955, -84.745986];
Zipcodes['39851'] = [31.619831, -84.992583];
Zipcodes['39854'] = [31.869218, -85.052557];
Zipcodes['39859'] = [30.987638, -84.812759];
Zipcodes['39861'] = [31.13535, -84.987248];
Zipcodes['39862'] = [31.459371, -84.517381];
Zipcodes['39866'] = [31.56093, -84.603184];
Zipcodes['39867'] = [31.842843, -84.93075];
Zipcodes['39870'] = [31.322028, -84.414137];
Zipcodes['39877'] = [31.915159, -84.511576];
Zipcodes['39885'] = [31.721896, -84.343026];
Zipcodes['39886'] = [31.732531, -84.617228];
Zipcodes['39897'] = [30.911205, -84.329813];
Zipcodes['40003'] = [38.276697, -85.04382];
Zipcodes['40004'] = [37.802403, -85.469858];
Zipcodes['40006'] = [38.596216, -85.333041];
Zipcodes['40007'] = [38.458345, -84.99915];
Zipcodes['40008'] = [37.908948, -85.275003];
Zipcodes['40009'] = [37.48372, -85.086423];
Zipcodes['40010'] = [38.372519, -85.459505];
Zipcodes['40011'] = [38.527774, -85.170076];
Zipcodes['40012'] = [37.912954, -85.16734];
Zipcodes['40013'] = [37.94168, -85.482175];
Zipcodes['40014'] = [38.340798, -85.43293];
Zipcodes['40019'] = [38.372641, -85.175051];
Zipcodes['40020'] = [37.934784, -85.387416];
Zipcodes['40022'] = [38.151401, -85.342927];
Zipcodes['40023'] = [38.163984, -85.423936];
Zipcodes['40025'] = [38.301619, -85.652127];
Zipcodes['40026'] = [38.421287, -85.516845];
Zipcodes['40031'] = [38.429416, -85.400814];
Zipcodes['40033'] = [37.544245, -85.225566];
Zipcodes['40036'] = [38.415116, -84.953309];
Zipcodes['40037'] = [37.660639, -85.417518];
Zipcodes['40040'] = [37.777043, -85.069286];
Zipcodes['40041'] = [38.256127, -85.664767];
Zipcodes['40045'] = [38.693585, -85.354607];
Zipcodes['40046'] = [38.023931, -85.169791];
Zipcodes['40047'] = [38.035955, -85.555524];
Zipcodes['40049'] = [37.663524, -85.395025];
Zipcodes['40050'] = [38.455933, -85.177958];
Zipcodes['40051'] = [37.652226, -85.579583];
Zipcodes['40052'] = [37.604126, -85.487942];
Zipcodes['40055'] = [38.495202, -85.326025];
Zipcodes['40056'] = [38.308077, -85.487219];
Zipcodes['40057'] = [38.382361, -85.055394];
Zipcodes['40058'] = [38.432649, -85.170003];
Zipcodes['40059'] = [38.355611, -85.581963];
Zipcodes['40060'] = [37.542332, -85.466267];
Zipcodes['40061'] = [37.70974, -85.264093];
Zipcodes['40062'] = [37.607001, -85.441352];
Zipcodes['40063'] = [37.56954, -85.344634];
Zipcodes['40065'] = [38.212858, -85.208553];
Zipcodes['40067'] = [38.228416, -85.367465];
Zipcodes['40068'] = [38.388706, -85.286313];
Zipcodes['40069'] = [37.723853, -85.207683];
Zipcodes['40070'] = [38.472028, -85.235026];
Zipcodes['40071'] = [38.042869, -85.378753];
Zipcodes['40075'] = [38.568134, -85.11267];
Zipcodes['40076'] = [38.113729, -85.086124];
Zipcodes['40077'] = [38.495455, -85.444636];
Zipcodes['40078'] = [37.831112, -85.144771];
Zipcodes['40104'] = [38.085529, -86.33285];
Zipcodes['40107'] = [37.725327, -85.598827];
Zipcodes['40108'] = [37.96982, -86.17287];
Zipcodes['40109'] = [38.064495, -85.750373];
Zipcodes['40110'] = [37.94005, -85.651234];
Zipcodes['40111'] = [37.785507, -86.619578];
Zipcodes['40115'] = [37.750296, -86.230107];
Zipcodes['40117'] = [37.896583, -86.124289];
Zipcodes['40118'] = [38.096337, -85.757854];
Zipcodes['40119'] = [37.608155, -86.553108];
Zipcodes['40121'] = [37.926895, -85.95981];
Zipcodes['40140'] = [37.730408, -86.285434];
Zipcodes['40142'] = [37.893218, -86.204086];
Zipcodes['40143'] = [37.785077, -86.499925];
Zipcodes['40144'] = [37.757039, -86.384146];
Zipcodes['40145'] = [37.654153, -86.314307];
Zipcodes['40146'] = [37.836527, -86.30994];
Zipcodes['40150'] = [37.87859, -85.676516];
Zipcodes['40152'] = [37.601942, -86.448546];
Zipcodes['40155'] = [37.948316, -85.99416];
Zipcodes['40157'] = [38.023398, -86.375494];
Zipcodes['40160'] = [37.813011, -85.931679];
Zipcodes['40161'] = [38.008168, -86.401197];
Zipcodes['40162'] = [37.746355, -86.040685];
Zipcodes['40165'] = [37.992776, -85.695936];
Zipcodes['40170'] = [37.96655, -86.487398];
Zipcodes['40171'] = [37.972084, -86.436384];
Zipcodes['40175'] = [37.819002, -86.089891];
Zipcodes['40176'] = [37.907696, -86.351447];
Zipcodes['40177'] = [38.002446, -85.923439];
Zipcodes['40178'] = [37.681237, -86.422431];
Zipcodes['40202'] = [38.252811, -85.751946];
Zipcodes['40203'] = [38.252157, -85.765194];
Zipcodes['40204'] = [38.239517, -85.722286];
Zipcodes['40205'] = [38.223001, -85.683261];
Zipcodes['40206'] = [38.256501, -85.701621];
Zipcodes['40207'] = [38.263466, -85.656647];
Zipcodes['40208'] = [38.217471, -85.76585];
Zipcodes['40209'] = [38.182383, -85.735186];
Zipcodes['40210'] = [38.231567, -85.785981];
Zipcodes['40211'] = [38.235823, -85.824579];
Zipcodes['40212'] = [38.267967, -85.802494];
Zipcodes['40213'] = [38.176954, -85.717023];
Zipcodes['40214'] = [38.148491, -85.779843];
Zipcodes['40215'] = [38.190476, -85.785734];
Zipcodes['40216'] = [38.18614, -85.83878];
Zipcodes['40217'] = [38.216712, -85.737515];
Zipcodes['40218'] = [38.189532, -85.657624];
Zipcodes['40219'] = [38.137909, -85.692693];
Zipcodes['40220'] = [38.215375, -85.618675];
Zipcodes['40221'] = [38.186503, -85.753072];
Zipcodes['40222'] = [38.264716, -85.611811];
Zipcodes['40223'] = [38.258467, -85.539772];
Zipcodes['40228'] = [38.140048, -85.635813];
Zipcodes['40229'] = [38.08841, -85.660363];
Zipcodes['40231'] = [38.1955, -85.695227];
Zipcodes['40241'] = [38.299974, -85.575689];
Zipcodes['40242'] = [38.276223, -85.587103];
Zipcodes['40243'] = [38.240228, -85.532614];
Zipcodes['40245'] = [38.267561, -85.446172];
Zipcodes['40258'] = [38.148592, -85.871182];
Zipcodes['40272'] = [38.078458, -85.853119];
Zipcodes['40280'] = [38.247682, -85.686881];
Zipcodes['40291'] = [38.12607, -85.579552];
Zipcodes['40299'] = [38.151687, -85.517172];
Zipcodes['40310'] = [37.750044, -84.772759];
Zipcodes['40311'] = [38.332048, -83.997104];
Zipcodes['40312'] = [37.8443, -83.932894];
Zipcodes['40313'] = [38.137482, -83.402595];
Zipcodes['40316'] = [37.93661, -83.52052];
Zipcodes['40322'] = [37.935145, -83.633039];
Zipcodes['40324'] = [38.245796, -84.550626];
Zipcodes['40328'] = [37.581879, -85.012813];
Zipcodes['40330'] = [37.787723, -84.888056];
Zipcodes['40334'] = [38.025073, -83.745044];
Zipcodes['40336'] = [37.682978, -83.990952];
Zipcodes['40337'] = [37.942341, -83.854646];
Zipcodes['40339'] = [37.942173, -84.642798];
Zipcodes['40342'] = [38.005503, -84.967386];
Zipcodes['40346'] = [37.987118, -83.743879];
Zipcodes['40347'] = [38.158286, -84.728351];
Zipcodes['40348'] = [38.302972, -84.146105];
Zipcodes['40350'] = [38.304672, -83.873854];
Zipcodes['40351'] = [38.204266, -83.428079];
Zipcodes['40353'] = [38.065465, -83.948822];
Zipcodes['40355'] = [38.601903, -84.86816];
Zipcodes['40356'] = [37.873764, -84.565257];
Zipcodes['40357'] = [38.145187, -84.108414];
Zipcodes['40358'] = [38.057558, -83.684089];
Zipcodes['40359'] = [38.476322, -84.814863];
Zipcodes['40360'] = [38.167285, -83.767744];
Zipcodes['40361'] = [38.202562, -84.20986];
Zipcodes['40363'] = [38.514407, -85.009763];
Zipcodes['40370'] = [38.394547, -84.530175];
Zipcodes['40371'] = [38.082997, -83.595129];
Zipcodes['40372'] = [37.911756, -84.888114];
Zipcodes['40374'] = [38.214854, -83.902149];
Zipcodes['40376'] = [37.79294, -83.714047];
Zipcodes['40379'] = [38.308222, -84.690353];
Zipcodes['40380'] = [37.813728, -83.797132];
Zipcodes['40383'] = [38.006622, -84.739332];
Zipcodes['40385'] = [37.738202, -84.133667];
Zipcodes['40387'] = [37.940782, -83.487418];
Zipcodes['40390'] = [37.85776, -84.676831];
Zipcodes['40391'] = [37.970314, -84.145115];
Zipcodes['40402'] = [37.291039, -83.976505];
Zipcodes['40403'] = [37.570691, -84.263754];
Zipcodes['40404'] = [37.573157, -84.290332];
Zipcodes['40409'] = [37.369158, -84.434908];
Zipcodes['40419'] = [37.437328, -84.490234];
Zipcodes['40422'] = [37.644519, -84.806497];
Zipcodes['40434'] = [37.393495, -83.938776];
Zipcodes['40437'] = [37.457036, -84.844025];
Zipcodes['40440'] = [37.57921, -84.840423];
Zipcodes['40442'] = [37.338919, -84.741295];
Zipcodes['40444'] = [37.666248, -84.57803];
Zipcodes['40445'] = [37.320324, -84.214065];
Zipcodes['40447'] = [37.440508, -84.046726];
Zipcodes['40448'] = [37.457088, -84.757625];
Zipcodes['40456'] = [37.353471, -84.319508];
Zipcodes['40460'] = [37.391078, -84.248831];
Zipcodes['40461'] = [37.58253, -84.406];
Zipcodes['40464'] = [37.572274, -84.92506];
Zipcodes['40468'] = [37.651388, -84.982532];
Zipcodes['40472'] = [37.732128, -83.859667];
Zipcodes['40475'] = [37.76237, -84.304577];
Zipcodes['40481'] = [37.438559, -84.082757];
Zipcodes['40484'] = [37.52058, -84.673931];
Zipcodes['40486'] = [37.365328, -83.862799];
Zipcodes['40489'] = [37.360761, -84.649773];
Zipcodes['40502'] = [38.010953, -84.483191];
Zipcodes['40503'] = [38.005828, -84.533469];
Zipcodes['40504'] = [38.042413, -84.544016];
Zipcodes['40505'] = [38.060653, -84.457238];
Zipcodes['40507'] = [38.046898, -84.496262];
Zipcodes['40508'] = [38.060945, -84.511395];
Zipcodes['40509'] = [37.997347, -84.377477];
Zipcodes['40510'] = [38.071199, -84.583831];
Zipcodes['40511'] = [38.141016, -84.461618];
Zipcodes['40513'] = [38.016586, -84.605028];
Zipcodes['40514'] = [37.983376, -84.565224];
Zipcodes['40515'] = [37.915615, -84.412155];
Zipcodes['40516'] = [38.071804, -84.364002];
Zipcodes['40517'] = [37.982958, -84.489425];
Zipcodes['40526'] = [38.029736, -84.501378];
Zipcodes['40536'] = [38.029809, -84.507281];
Zipcodes['40601'] = [38.234919, -84.868786];
Zipcodes['40701'] = [36.913613, -84.168147];
Zipcodes['40729'] = [37.248042, -84.152709];
Zipcodes['40734'] = [36.929945, -83.975035];
Zipcodes['40737'] = [37.011038, -84.147979];
Zipcodes['40740'] = [37.020582, -84.037313];
Zipcodes['40741'] = [37.133373, -84.119167];
Zipcodes['40744'] = [37.03187, -84.109932];
Zipcodes['40759'] = [36.812972, -84.057124];
Zipcodes['40763'] = [36.689229, -83.925596];
Zipcodes['40769'] = [36.704354, -84.189734];
Zipcodes['40771'] = [36.862499, -84.037627];
Zipcodes['40801'] = [36.857008, -83.254924];
Zipcodes['40803'] = [37.045821, -83.422606];
Zipcodes['40806'] = [36.891225, -83.297006];
Zipcodes['40807'] = [36.96402, -82.955591];
Zipcodes['40808'] = [37.010211, -83.290841];
Zipcodes['40810'] = [36.936402, -83.280644];
Zipcodes['40813'] = [36.710199, -83.592254];
Zipcodes['40815'] = [36.79156, -83.232515];
Zipcodes['40816'] = [37.01327, -83.367212];
Zipcodes['40818'] = [36.821595, -83.243138];
Zipcodes['40819'] = [36.802316, -83.441646];
Zipcodes['40820'] = [36.797864, -83.158766];
Zipcodes['40823'] = [36.958653, -82.990992];
Zipcodes['40824'] = [36.825297, -83.375312];
Zipcodes['40826'] = [37.042414, -82.770095];
Zipcodes['40827'] = [37.037395, -83.512783];
Zipcodes['40828'] = [36.871821, -83.152587];
Zipcodes['40829'] = [36.796114, -83.302343];
Zipcodes['40830'] = [36.776364, -83.331299];
Zipcodes['40831'] = [36.760179, -83.328572];
Zipcodes['40840'] = [36.944631, -83.418974];
Zipcodes['40843'] = [36.894771, -82.931593];
Zipcodes['40844'] = [37.057797, -83.341426];
Zipcodes['40845'] = [36.784729, -83.503517];
Zipcodes['40847'] = [36.852435, -83.157059];
Zipcodes['40849'] = [36.899375, -83.135503];
Zipcodes['40854'] = [36.845327, -83.361744];
Zipcodes['40855'] = [36.929858, -82.885329];
Zipcodes['40856'] = [36.72316, -83.527522];
Zipcodes['40858'] = [37.012556, -83.409184];
Zipcodes['40862'] = [37.017552, -82.856157];
Zipcodes['40863'] = [36.727176, -83.430901];
Zipcodes['40865'] = [36.920061, -83.211161];
Zipcodes['40868'] = [37.094789, -83.426873];
Zipcodes['40870'] = [36.942023, -83.152968];
Zipcodes['40873'] = [36.810909, -83.400029];
Zipcodes['40874'] = [36.989139, -83.472521];
Zipcodes['40902'] = [36.861497, -83.602232];
Zipcodes['40903'] = [36.8029, -83.800941];
Zipcodes['40906'] = [36.889185, -83.875636];
Zipcodes['40913'] = [36.936127, -83.558902];
Zipcodes['40914'] = [37.092697, -83.572412];
Zipcodes['40915'] = [36.890409, -83.812553];
Zipcodes['40921'] = [36.791717, -83.90164];
Zipcodes['40923'] = [36.919323, -83.845446];
Zipcodes['40927'] = [36.900113, -83.012983];
Zipcodes['40935'] = [36.958171, -83.752819];
Zipcodes['40939'] = [36.810418, -83.720679];
Zipcodes['40940'] = [36.602546, -83.939044];
Zipcodes['40941'] = [37.130055, -83.745555];
Zipcodes['40943'] = [36.956284, -83.847458];
Zipcodes['40946'] = [36.957598, -83.824614];
Zipcodes['40949'] = [36.901144, -83.868345];
Zipcodes['40953'] = [36.937968, -83.801341];
Zipcodes['40958'] = [36.809878, -83.596918];
Zipcodes['40962'] = [37.164273, -83.715454];
Zipcodes['40964'] = [36.782637, -83.337726];
Zipcodes['40965'] = [36.619993, -83.731847];
Zipcodes['40972'] = [37.240525, -83.587899];
Zipcodes['40977'] = [36.724042, -83.729182];
Zipcodes['40979'] = [37.009382, -83.493067];
Zipcodes['40981'] = [37.277868, -83.485324];
Zipcodes['40982'] = [36.932952, -83.687166];
Zipcodes['40983'] = [37.347846, -83.731846];
Zipcodes['40988'] = [36.869781, -83.522693];
Zipcodes['40995'] = [36.75268, -83.823241];
Zipcodes['40997'] = [36.894542, -83.662034];
Zipcodes['41001'] = [38.910854, -84.400667];
Zipcodes['41002'] = [38.742052, -83.973538];
Zipcodes['41003'] = [38.52964, -84.390246];
Zipcodes['41004'] = [38.644125, -84.087983];
Zipcodes['41005'] = [39.006759, -84.760116];
Zipcodes['41006'] = [38.78443, -84.345639];
Zipcodes['41007'] = [38.892579, -84.308941];
Zipcodes['41008'] = [38.65112, -85.17622];
Zipcodes['41010'] = [38.499633, -84.608194];
Zipcodes['41011'] = [39.06713, -84.532674];
Zipcodes['41014'] = [39.064811, -84.504526];
Zipcodes['41015'] = [38.979711, -84.478594];
Zipcodes['41016'] = [39.088749, -84.548476];
Zipcodes['41017'] = [39.029195, -84.562134];
Zipcodes['41018'] = [39.014901, -84.602186];
Zipcodes['41030'] = [38.784431, -84.587261];
Zipcodes['41031'] = [38.413134, -84.297476];
Zipcodes['41033'] = [38.769157, -84.475477];
Zipcodes['41034'] = [38.687149, -83.900216];
Zipcodes['41035'] = [38.719224, -84.654354];
Zipcodes['41039'] = [38.414304, -83.871425];
Zipcodes['41040'] = [38.650767, -84.331159];
Zipcodes['41041'] = [38.405046, -83.723117];
Zipcodes['41042'] = [38.987219, -84.647016];
Zipcodes['41043'] = [38.761688, -84.20495];
Zipcodes['41044'] = [38.610431, -83.972434];
Zipcodes['41045'] = [38.721963, -85.021636];
Zipcodes['41046'] = [38.708767, -84.800539];
Zipcodes['41048'] = [39.087014, -84.703829];
Zipcodes['41049'] = [38.268275, -83.644163];
Zipcodes['41051'] = [38.93382, -84.540398];
Zipcodes['41052'] = [38.656441, -84.775333];
Zipcodes['41055'] = [38.525392, -83.866464];
Zipcodes['41056'] = [38.601939, -83.777402];
Zipcodes['41059'] = [38.998125, -84.345146];
Zipcodes['41063'] = [38.857033, -84.505345];
Zipcodes['41064'] = [38.513473, -84.06423];
Zipcodes['41071'] = [39.074304, -84.480561];
Zipcodes['41073'] = [39.101248, -84.477492];
Zipcodes['41074'] = [39.109225, -84.460305];
Zipcodes['41075'] = [39.082436, -84.457111];
Zipcodes['41076'] = [39.017527, -84.440935];
Zipcodes['41080'] = [39.054694, -84.824189];
Zipcodes['41083'] = [38.670772, -84.996374];
Zipcodes['41085'] = [39.034976, -84.393912];
Zipcodes['41086'] = [38.695366, -84.88102];
Zipcodes['41091'] = [38.910267, -84.736327];
Zipcodes['41092'] = [38.815614, -84.688213];
Zipcodes['41093'] = [38.378138, -83.55318];
Zipcodes['41094'] = [38.882754, -84.624108];
Zipcodes['41095'] = [38.773683, -84.747471];
Zipcodes['41097'] = [38.621179, -84.581444];
Zipcodes['41098'] = [38.597857, -84.987577];
Zipcodes['41101'] = [38.47752, -82.650548];
Zipcodes['41102'] = [38.427628, -82.730185];
Zipcodes['41121'] = [38.439405, -82.833674];
Zipcodes['41124'] = [38.019783, -82.858405];
Zipcodes['41129'] = [38.320926, -82.656257];
Zipcodes['41132'] = [38.256843, -82.823182];
Zipcodes['41135'] = [38.362748, -83.270796];
Zipcodes['41139'] = [38.508682, -82.734011];
Zipcodes['41141'] = [38.566748, -83.13882];
Zipcodes['41142'] = [38.27773, -83.073052];
Zipcodes['41143'] = [38.334937, -82.97524];
Zipcodes['41144'] = [38.518848, -82.929216];
Zipcodes['41146'] = [38.279178, -82.896024];
Zipcodes['41149'] = [38.099252, -83.076514];
Zipcodes['41159'] = [38.010413, -82.975065];
Zipcodes['41164'] = [38.288431, -83.157168];
Zipcodes['41166'] = [38.626388, -83.102358];
Zipcodes['41168'] = [38.307054, -82.75056];
Zipcodes['41169'] = [38.532644, -82.736651];
Zipcodes['41171'] = [38.045518, -83.178959];
Zipcodes['41174'] = [38.706272, -83.00708];
Zipcodes['41175'] = [38.648866, -82.972077];
Zipcodes['41179'] = [38.535047, -83.370143];
Zipcodes['41180'] = [38.150798, -82.884476];
Zipcodes['41181'] = [38.209151, -82.872923];
Zipcodes['41183'] = [38.55033, -82.736653];
Zipcodes['41189'] = [38.547639, -83.562888];
Zipcodes['41201'] = [38.062355, -82.762329];
Zipcodes['41203'] = [37.852415, -82.461108];
Zipcodes['41204'] = [37.806639, -82.671273];
Zipcodes['41214'] = [37.827633, -82.577239];
Zipcodes['41216'] = [37.732493, -82.803752];
Zipcodes['41219'] = [37.928718, -82.919913];
Zipcodes['41222'] = [37.755891, -82.858454];
Zipcodes['41224'] = [37.810746, -82.55249];
Zipcodes['41226'] = [37.98017, -82.954983];
Zipcodes['41230'] = [38.063274, -82.668441];
Zipcodes['41231'] = [37.792524, -82.379563];
Zipcodes['41232'] = [37.941018, -82.729719];
Zipcodes['41234'] = [37.802161, -82.746874];
Zipcodes['41238'] = [37.834882, -82.950158];
Zipcodes['41240'] = [37.82029, -82.783724];
Zipcodes['41250'] = [37.710799, -82.447931];
Zipcodes['41254'] = [37.887302, -82.715298];
Zipcodes['41255'] = [37.919982, -82.825799];
Zipcodes['41256'] = [37.820873, -82.876102];
Zipcodes['41257'] = [37.913105, -82.798879];
Zipcodes['41260'] = [37.825569, -82.752785];
Zipcodes['41262'] = [37.845096, -82.630093];
Zipcodes['41263'] = [37.856743, -82.764651];
Zipcodes['41264'] = [37.92491, -82.681454];
Zipcodes['41265'] = [37.759949, -82.718318];
Zipcodes['41267'] = [37.881714, -82.438842];
Zipcodes['41268'] = [37.788502, -82.785236];
Zipcodes['41271'] = [37.827751, -82.72454];
Zipcodes['41274'] = [37.86416, -82.810705];
Zipcodes['41301'] = [37.715351, -83.493392];
Zipcodes['41310'] = [37.640672, -83.251494];
Zipcodes['41311'] = [37.575085, -83.723171];
Zipcodes['41314'] = [37.439746, -83.637508];
Zipcodes['41317'] = [37.465638, -83.211598];
Zipcodes['41332'] = [37.807378, -83.367278];
Zipcodes['41339'] = [37.516193, -83.269114];
Zipcodes['41348'] = [37.409385, -83.301027];
Zipcodes['41351'] = [37.336072, -83.585919];
Zipcodes['41352'] = [37.826023, -83.336133];
Zipcodes['41360'] = [37.801375, -83.614634];
Zipcodes['41364'] = [37.36847, -83.660665];
Zipcodes['41365'] = [37.706784, -83.628873];
Zipcodes['41366'] = [37.624716, -83.21962];
Zipcodes['41367'] = [37.415011, -83.227816];
Zipcodes['41385'] = [37.663798, -83.314769];
Zipcodes['41386'] = [37.48224, -83.795846];
Zipcodes['41390'] = [37.410075, -83.380732];
Zipcodes['41397'] = [37.672915, -83.661539];
Zipcodes['41408'] = [37.802104, -83.287042];
Zipcodes['41421'] = [37.992048, -83.194702];
Zipcodes['41425'] = [37.887751, -83.417712];
Zipcodes['41464'] = [37.649636, -82.953298];
Zipcodes['41465'] = [37.729098, -83.09181];
Zipcodes['41472'] = [37.944009, -83.247331];
Zipcodes['41501'] = [37.507592, -82.518773];
Zipcodes['41503'] = [37.66069, -82.284318];
Zipcodes['41512'] = [37.252442, -82.477729];
Zipcodes['41513'] = [37.352867, -82.343306];
Zipcodes['41514'] = [37.66891, -82.318529];
Zipcodes['41517'] = [37.189643, -82.59976];
Zipcodes['41519'] = [37.583551, -82.323421];
Zipcodes['41522'] = [37.322137, -82.399515];
Zipcodes['41524'] = [37.433426, -82.234446];
Zipcodes['41526'] = [37.433707, -82.512566];
Zipcodes['41527'] = [37.631833, -82.293814];
Zipcodes['41528'] = [37.554537, -82.139854];
Zipcodes['41531'] = [37.595067, -82.231322];
Zipcodes['41534'] = [37.277479, -82.496509];
Zipcodes['41535'] = [37.593691, -82.284236];
Zipcodes['41537'] = [37.222622, -82.613738];
Zipcodes['41538'] = [37.303386, -82.588917];
Zipcodes['41539'] = [37.50263, -82.322298];
Zipcodes['41540'] = [37.394388, -82.342184];
Zipcodes['41543'] = [37.548341, -82.282267];
Zipcodes['41544'] = [37.595895, -82.173678];
Zipcodes['41547'] = [37.53812, -82.094543];
Zipcodes['41548'] = [37.374655, -82.250947];
Zipcodes['41553'] = [37.48058, -82.160286];
Zipcodes['41554'] = [37.430506, -82.335639];
Zipcodes['41555'] = [37.538125, -82.258921];
Zipcodes['41557'] = [37.486031, -82.410912];
Zipcodes['41558'] = [37.539514, -82.208198];
Zipcodes['41559'] = [37.380019, -82.390157];
Zipcodes['41560'] = [37.394197, -82.578727];
Zipcodes['41562'] = [37.401617, -82.473829];
Zipcodes['41563'] = [37.223732, -82.538565];
Zipcodes['41564'] = [37.604774, -82.366743];
Zipcodes['41566'] = [37.408303, -82.192085];
Zipcodes['41567'] = [37.56893, -82.286224];
Zipcodes['41568'] = [37.502786, -82.044361];
Zipcodes['41571'] = [37.626523, -82.44435];
Zipcodes['41572'] = [37.313398, -82.643705];
Zipcodes['41601'] = [37.608968, -82.718073];
Zipcodes['41602'] = [37.745936, -82.753242];
Zipcodes['41603'] = [37.571497, -82.704621];
Zipcodes['41604'] = [37.385069, -82.659826];
Zipcodes['41605'] = [37.555443, -82.630063];
Zipcodes['41606'] = [37.338065, -82.7347];
Zipcodes['41607'] = [37.625065, -82.856903];
Zipcodes['41612'] = [37.354529, -82.722194];
Zipcodes['41615'] = [37.537448, -82.692527];
Zipcodes['41616'] = [37.566331, -82.880016];
Zipcodes['41619'] = [37.479335, -82.733485];
Zipcodes['41621'] = [37.625962, -82.725484];
Zipcodes['41622'] = [37.51304, -82.809658];
Zipcodes['41630'] = [37.44794, -82.889072];
Zipcodes['41631'] = [37.468653, -82.653904];
Zipcodes['41632'] = [37.564932, -82.951718];
Zipcodes['41635'] = [37.4845, -82.626118];
Zipcodes['41636'] = [37.400794, -82.730609];
Zipcodes['41640'] = [37.472735, -82.91998];
Zipcodes['41642'] = [37.592239, -82.648393];
Zipcodes['41643'] = [37.434912, -82.844348];
Zipcodes['41645'] = [37.528243, -82.79783];
Zipcodes['41647'] = [37.432215, -82.726384];
Zipcodes['41649'] = [37.570994, -82.776686];
Zipcodes['41650'] = [37.341244, -82.675612];
Zipcodes['41651'] = [37.467118, -82.755289];
Zipcodes['41653'] = [37.657381, -82.758826];
Zipcodes['41655'] = [37.512878, -82.73637];
Zipcodes['41659'] = [37.587555, -82.591557];
Zipcodes['41660'] = [37.414099, -82.631726];
Zipcodes['41663'] = [37.567286, -82.663407];
Zipcodes['41666'] = [37.440783, -82.806291];
Zipcodes['41667'] = [37.310678, -82.692251];
Zipcodes['41669'] = [37.333771, -82.714703];
Zipcodes['41701'] = [37.295501, -83.182614];
Zipcodes['41712'] = [37.37172, -83.138426];
Zipcodes['41713'] = [37.22693, -83.278037];
Zipcodes['41714'] = [37.163187, -83.505763];
Zipcodes['41719'] = [37.296553, -83.247785];
Zipcodes['41721'] = [37.324942, -83.489843];
Zipcodes['41722'] = [37.408787, -83.09903];
Zipcodes['41723'] = [37.261705, -83.307253];
Zipcodes['41725'] = [37.317044, -83.033551];
Zipcodes['41727'] = [37.377305, -83.278803];
Zipcodes['41729'] = [37.269213, -83.215709];
Zipcodes['41731'] = [37.094134, -83.079389];
Zipcodes['41735'] = [37.01501, -83.1029];
Zipcodes['41739'] = [37.320611, -83.128075];
Zipcodes['41740'] = [37.356177, -83.047437];
Zipcodes['41745'] = [37.34021, -83.433003];
Zipcodes['41746'] = [37.213907, -83.0921];
Zipcodes['41749'] = [37.217226, -83.429208];
Zipcodes['41751'] = [37.218084, -83.128726];
Zipcodes['41754'] = [37.304449, -83.328175];
Zipcodes['41759'] = [37.231869, -83.026707];
Zipcodes['41760'] = [37.194137, -83.086065];
Zipcodes['41762'] = [37.207381, -83.500794];
Zipcodes['41763'] = [37.054631, -83.120677];
Zipcodes['41764'] = [37.119365, -83.254218];
Zipcodes['41766'] = [37.180487, -83.445314];
Zipcodes['41772'] = [37.407217, -83.014705];
Zipcodes['41773'] = [37.26099, -82.992024];
Zipcodes['41774'] = [37.15432, -83.145775];
Zipcodes['41775'] = [37.100896, -83.344953];
Zipcodes['41776'] = [37.185106, -83.308542];
Zipcodes['41777'] = [37.051473, -83.227916];
Zipcodes['41778'] = [37.266138, -83.322327];
Zipcodes['41804'] = [37.1515, -82.998642];
Zipcodes['41810'] = [37.184896, -82.672768];
Zipcodes['41812'] = [37.251153, -82.742744];
Zipcodes['41815'] = [37.153385, -82.802793];
Zipcodes['41817'] = [37.368632, -82.884173];
Zipcodes['41819'] = [36.987114, -83.107019];
Zipcodes['41821'] = [37.059106, -83.01476];
Zipcodes['41822'] = [37.31906, -82.982748];
Zipcodes['41824'] = [37.186158, -82.876558];
Zipcodes['41825'] = [37.231031, -82.705616];
Zipcodes['41826'] = [37.188067, -82.921645];
Zipcodes['41828'] = [37.288168, -82.787874];
Zipcodes['41831'] = [37.387165, -82.953376];
Zipcodes['41832'] = [37.169764, -82.96075];
Zipcodes['41833'] = [37.026016, -82.985811];
Zipcodes['41834'] = [37.252198, -82.955302];
Zipcodes['41835'] = [37.221077, -82.667983];
Zipcodes['41836'] = [37.258814, -82.9023];
Zipcodes['41837'] = [37.125158, -82.758338];
Zipcodes['41838'] = [37.203755, -82.756691];
Zipcodes['41839'] = [37.430792, -82.886539];
Zipcodes['41840'] = [37.198999, -82.720875];
Zipcodes['41843'] = [37.275262, -82.857834];
Zipcodes['41844'] = [37.314352, -82.872327];
Zipcodes['41845'] = [37.141394, -82.901327];
Zipcodes['41847'] = [37.202514, -82.96827];
Zipcodes['41848'] = [37.116337, -82.937277];
Zipcodes['41849'] = [37.173991, -82.732572];
Zipcodes['41855'] = [37.174972, -82.782632];
Zipcodes['41858'] = [37.136052, -82.854851];
Zipcodes['41859'] = [37.387909, -82.772025];
Zipcodes['41861'] = [37.38501, -82.818227];
Zipcodes['41862'] = [37.342734, -82.771495];
Zipcodes['42001'] = [37.030572, -88.7094];
Zipcodes['42003'] = [37.004848, -88.577941];
Zipcodes['42020'] = [36.686797, -88.286523];
Zipcodes['42021'] = [36.805684, -88.993595];
Zipcodes['42022'] = [37.144988, -88.944769];
Zipcodes['42023'] = [36.872349, -88.990733];
Zipcodes['42024'] = [37.077408, -89.050311];
Zipcodes['42025'] = [36.859674, -88.334676];
Zipcodes['42027'] = [36.919427, -88.635505];
Zipcodes['42028'] = [37.238913, -88.33685];
Zipcodes['42029'] = [37.012043, -88.384483];
Zipcodes['42031'] = [36.685748, -88.991397];
Zipcodes['42032'] = [36.742358, -89.110499];
Zipcodes['42035'] = [36.90774, -88.836453];
Zipcodes['42036'] = [36.712626, -88.234611];
Zipcodes['42037'] = [37.158305, -88.184495];
Zipcodes['42038'] = [37.047413, -88.027647];
Zipcodes['42039'] = [36.788709, -88.825434];
Zipcodes['42040'] = [36.607718, -88.501047];
Zipcodes['42041'] = [36.550373, -88.877352];
Zipcodes['42044'] = [36.957582, -88.263203];
Zipcodes['42045'] = [37.074727, -88.267863];
Zipcodes['42047'] = [37.29024, -88.399879];
Zipcodes['42048'] = [36.752393, -88.298921];
Zipcodes['42049'] = [36.517494, -88.319795];
Zipcodes['42050'] = [36.536643, -89.192952];
Zipcodes['42051'] = [36.859764, -88.644264];
Zipcodes['42053'] = [37.112648, -88.877823];
Zipcodes['42054'] = [36.722236, -88.432638];
Zipcodes['42055'] = [37.075222, -88.174049];
Zipcodes['42056'] = [37.107275, -88.986746];
Zipcodes['42058'] = [37.058337, -88.465707];
Zipcodes['42060'] = [36.965854, -88.82813];
Zipcodes['42061'] = [36.882838, -88.766366];
Zipcodes['42064'] = [37.344976, -88.095296];
Zipcodes['42066'] = [36.732532, -88.639882];
Zipcodes['42069'] = [36.918791, -88.756493];
Zipcodes['42071'] = [36.619748, -88.280465];
Zipcodes['42076'] = [36.571455, -88.106951];
Zipcodes['42078'] = [37.277501, -88.286608];
Zipcodes['42079'] = [36.541253, -88.576393];
Zipcodes['42081'] = [37.235071, -88.390523];
Zipcodes['42082'] = [36.917397, -88.495282];
Zipcodes['42083'] = [37.161785, -88.288333];
Zipcodes['42085'] = [36.569596, -88.800966];
Zipcodes['42086'] = [37.082764, -88.77271];
Zipcodes['42087'] = [36.973093, -88.994838];
Zipcodes['42088'] = [36.616199, -88.753917];
Zipcodes['42101'] = [37.058097, -86.463061];
Zipcodes['42103'] = [36.965139, -86.324599];
Zipcodes['42104'] = [36.876864, -86.45119];
Zipcodes['42120'] = [36.675927, -86.282585];
Zipcodes['42122'] = [36.85707, -86.353679];
Zipcodes['42123'] = [36.820844, -86.000351];
Zipcodes['42124'] = [36.86374, -85.652139];
Zipcodes['42127'] = [37.12258, -85.927046];
Zipcodes['42129'] = [36.989145, -85.585467];
Zipcodes['42130'] = [36.925865, -85.786306];
Zipcodes['42131'] = [36.829776, -85.909054];
Zipcodes['42133'] = [36.726047, -85.956233];
Zipcodes['42134'] = [36.726733, -86.563183];
Zipcodes['42140'] = [36.656541, -85.828893];
Zipcodes['42141'] = [36.939496, -85.903352];
Zipcodes['42151'] = [36.659928, -85.544839];
Zipcodes['42153'] = [36.688944, -86.046827];
Zipcodes['42154'] = [37.041978, -85.726199];
Zipcodes['42156'] = [36.86448, -86.047071];
Zipcodes['42157'] = [36.781385, -85.81974];
Zipcodes['42159'] = [36.996749, -86.249255];
Zipcodes['42160'] = [37.11053, -86.090455];
Zipcodes['42163'] = [37.079683, -86.134702];
Zipcodes['42164'] = [36.770089, -86.180721];
Zipcodes['42166'] = [36.873725, -85.726714];
Zipcodes['42167'] = [36.71845, -85.674728];
Zipcodes['42170'] = [36.856531, -86.569712];
Zipcodes['42171'] = [37.036369, -86.174441];
Zipcodes['42202'] = [36.682889, -86.844423];
Zipcodes['42204'] = [36.69589, -87.088999];
Zipcodes['42206'] = [36.886676, -86.743011];
Zipcodes['42207'] = [37.293995, -86.266888];
Zipcodes['42210'] = [37.214136, -86.308114];
Zipcodes['42211'] = [36.817748, -87.833699];
Zipcodes['42214'] = [37.127313, -85.700574];
Zipcodes['42215'] = [36.993627, -87.676432];
Zipcodes['42217'] = [37.046301, -87.472653];
Zipcodes['42220'] = [36.907339, -87.189361];
Zipcodes['42223'] = [36.639356, -87.473311];
Zipcodes['42232'] = [36.855418, -87.66123];
Zipcodes['42234'] = [36.721574, -87.178582];
Zipcodes['42236'] = [36.71208, -87.613526];
Zipcodes['42240'] = [36.880212, -87.46306];
Zipcodes['42252'] = [37.2321, -86.498984];
Zipcodes['42254'] = [36.661062, -87.643916];
Zipcodes['42256'] = [37.01173, -86.949626];
Zipcodes['42259'] = [37.229691, -86.164707];
Zipcodes['42261'] = [37.218484, -86.648351];
Zipcodes['42262'] = [36.690347, -87.448181];
Zipcodes['42265'] = [36.739223, -87.004306];
Zipcodes['42266'] = [36.744341, -87.357244];
Zipcodes['42273'] = [37.204585, -86.86165];
Zipcodes['42274'] = [36.950235, -86.598782];
Zipcodes['42275'] = [37.233183, -86.45416];
Zipcodes['42276'] = [36.859607, -86.881288];
Zipcodes['42280'] = [36.956981, -87.099164];
Zipcodes['42285'] = [37.264181, -86.297573];
Zipcodes['42286'] = [36.727261, -87.269899];
Zipcodes['42301'] = [37.735778, -87.249397];
Zipcodes['42303'] = [37.75704, -87.052531];
Zipcodes['42320'] = [37.332728, -86.867017];
Zipcodes['42321'] = [37.181837, -87.05036];
Zipcodes['42322'] = [37.62126, -87.387256];
Zipcodes['42323'] = [37.173258, -87.034023];
Zipcodes['42324'] = [37.141721, -87.013083];
Zipcodes['42325'] = [37.316867, -87.269397];
Zipcodes['42326'] = [37.235712, -87.010898];
Zipcodes['42327'] = [37.58053, -87.297311];
Zipcodes['42328'] = [37.431016, -87.04523];
Zipcodes['42330'] = [37.314737, -87.121852];
Zipcodes['42332'] = [37.250429, -87.088665];
Zipcodes['42333'] = [37.381485, -86.758958];
Zipcodes['42337'] = [37.200767, -86.949309];
Zipcodes['42338'] = [37.554573, -86.786988];
Zipcodes['42339'] = [37.121697, -86.96];
Zipcodes['42343'] = [37.652848, -86.693878];
Zipcodes['42344'] = [37.270857, -87.317209];
Zipcodes['42345'] = [37.144971, -87.199195];
Zipcodes['42347'] = [37.523846, -86.890108];
Zipcodes['42348'] = [37.830925, -86.774914];
Zipcodes['42349'] = [37.43031, -86.677192];
Zipcodes['42350'] = [37.455012, -87.186913];
Zipcodes['42351'] = [37.898577, -86.866633];
Zipcodes['42352'] = [37.514811, -87.0859];
Zipcodes['42354'] = [37.377978, -86.930568];
Zipcodes['42355'] = [37.85631, -86.993641];
Zipcodes['42356'] = [37.693614, -87.322974];
Zipcodes['42361'] = [37.50831, -86.70712];
Zipcodes['42366'] = [37.722748, -86.938515];
Zipcodes['42367'] = [37.244222, -87.158878];
Zipcodes['42368'] = [37.713326, -86.753064];
Zipcodes['42369'] = [37.337839, -86.988984];
Zipcodes['42370'] = [37.449977, -86.741129];
Zipcodes['42371'] = [37.510021, -87.289647];
Zipcodes['42372'] = [37.405167, -87.277591];
Zipcodes['42374'] = [37.332691, -87.139599];
Zipcodes['42376'] = [37.617755, -87.085957];
Zipcodes['42378'] = [37.6547, -86.874074];
Zipcodes['42404'] = [37.481982, -87.837134];
Zipcodes['42406'] = [37.705965, -87.689478];
Zipcodes['42408'] = [37.179537, -87.692699];
Zipcodes['42409'] = [37.538637, -87.692934];
Zipcodes['42410'] = [37.27637, -87.510675];
Zipcodes['42411'] = [37.198292, -88.010813];
Zipcodes['42413'] = [37.438482, -87.463909];
Zipcodes['42420'] = [37.799192, -87.516119];
Zipcodes['42431'] = [37.33757, -87.475195];
Zipcodes['42436'] = [37.416086, -87.564358];
Zipcodes['42437'] = [37.649263, -87.915228];
Zipcodes['42440'] = [37.238676, -87.470011];
Zipcodes['42441'] = [37.364757, -87.655014];
Zipcodes['42442'] = [37.180096, -87.484898];
Zipcodes['42444'] = [37.641551, -87.645554];
Zipcodes['42445'] = [37.128177, -87.857943];
Zipcodes['42450'] = [37.389214, -87.776989];
Zipcodes['42451'] = [37.884533, -87.390707];
Zipcodes['42452'] = [37.682717, -87.540273];
Zipcodes['42453'] = [37.157856, -87.590712];
Zipcodes['42455'] = [37.583729, -87.533404];
Zipcodes['42456'] = [37.508419, -87.510948];
Zipcodes['42458'] = [37.853465, -87.430617];
Zipcodes['42459'] = [37.566806, -88.019048];
Zipcodes['42461'] = [37.797397, -87.889216];
Zipcodes['42462'] = [37.738452, -87.792946];
Zipcodes['42463'] = [37.47262, -87.869452];
Zipcodes['42464'] = [37.181253, -87.356159];
Zipcodes['42501'] = [37.056193, -84.450902];
Zipcodes['42503'] = [37.158036, -84.519735];
Zipcodes['42516'] = [37.221659, -84.784892];
Zipcodes['42518'] = [36.905952, -84.639677];
Zipcodes['42519'] = [36.955307, -84.53504];
Zipcodes['42528'] = [37.181802, -85.023368];
Zipcodes['42533'] = [37.072195, -84.592355];
Zipcodes['42539'] = [37.309082, -84.950978];
Zipcodes['42541'] = [37.369065, -84.807993];
Zipcodes['42544'] = [37.029948, -84.815125];
Zipcodes['42553'] = [37.1803, -84.697071];
Zipcodes['42565'] = [37.141549, -84.906192];
Zipcodes['42566'] = [37.278532, -84.783172];
Zipcodes['42567'] = [37.265935, -84.604696];
Zipcodes['42602'] = [36.725956, -85.121543];
Zipcodes['42603'] = [36.783616, -85.014101];
Zipcodes['42629'] = [36.912644, -85.138072];
Zipcodes['42631'] = [36.740393, -84.480989];
Zipcodes['42633'] = [36.800772, -84.82659];
Zipcodes['42634'] = [36.89317, -84.422337];
Zipcodes['42635'] = [36.676247, -84.403232];
Zipcodes['42638'] = [36.674414, -84.469919];
Zipcodes['42642'] = [37.058681, -85.035151];
Zipcodes['42647'] = [36.681253, -84.627337];
Zipcodes['42649'] = [36.626483, -84.415013];
Zipcodes['42653'] = [36.782112, -84.454722];
Zipcodes['42701'] = [37.703239, -85.839548];
Zipcodes['42712'] = [37.55923, -86.157535];
Zipcodes['42713'] = [37.375146, -85.872711];
Zipcodes['42715'] = [36.939751, -85.405186];
Zipcodes['42716'] = [37.492233, -85.675203];
Zipcodes['42717'] = [36.782372, -85.388484];
Zipcodes['42718'] = [37.390302, -85.380067];
Zipcodes['42721'] = [37.42298, -86.499732];
Zipcodes['42722'] = [37.276586, -85.716132];
Zipcodes['42724'] = [37.675918, -86.070835];
Zipcodes['42726'] = [37.419922, -86.152108];
Zipcodes['42728'] = [37.117031, -85.29007];
Zipcodes['42729'] = [37.306467, -86.096497];
Zipcodes['42732'] = [37.613509, -86.101848];
Zipcodes['42733'] = [37.343603, -85.18643];
Zipcodes['42740'] = [37.586356, -85.948741];
Zipcodes['42741'] = [36.975229, -85.255401];
Zipcodes['42742'] = [37.076439, -85.473074];
Zipcodes['42743'] = [37.228307, -85.532268];
Zipcodes['42746'] = [37.212381, -85.732393];
Zipcodes['42748'] = [37.566719, -85.694069];
Zipcodes['42749'] = [37.183834, -85.877046];
Zipcodes['42753'] = [37.235091, -85.158997];
Zipcodes['42754'] = [37.478269, -86.314913];
Zipcodes['42757'] = [37.410389, -85.74099];
Zipcodes['42758'] = [37.374757, -85.201006];
Zipcodes['42759'] = [36.828162, -85.51444];
Zipcodes['42762'] = [37.455344, -86.39315];
Zipcodes['42764'] = [37.429579, -85.620541];
Zipcodes['42765'] = [37.306668, -85.934608];
Zipcodes['42776'] = [37.514506, -85.92709];
Zipcodes['42782'] = [37.336817, -85.643034];
Zipcodes['42784'] = [37.451992, -85.932394];
Zipcodes['42788'] = [37.529664, -86.027886];
Zipcodes['43001'] = [40.088034, -82.61393];
Zipcodes['43002'] = [40.057262, -83.168467];
Zipcodes['43003'] = [40.41354, -82.969322];
Zipcodes['43004'] = [40.015374, -82.804489];
Zipcodes['43005'] = [40.281612, -82.269007];
Zipcodes['43006'] = [40.459754, -82.149323];
Zipcodes['43007'] = [40.330881, -83.401894];
Zipcodes['43008'] = [39.933044, -82.483203];
Zipcodes['43009'] = [40.171458, -83.639322];
Zipcodes['43010'] = [39.999627, -83.621903];
Zipcodes['43011'] = [40.303039, -82.679642];
Zipcodes['43013'] = [40.230616, -82.68937];
Zipcodes['43014'] = [40.46989, -82.249623];
Zipcodes['43015'] = [40.297783, -83.060168];
Zipcodes['43016'] = [40.097462, -83.150204];
Zipcodes['43017'] = [40.11868, -83.133129];
Zipcodes['43018'] = [39.953801, -82.676136];
Zipcodes['43019'] = [40.501987, -82.593233];
Zipcodes['43021'] = [40.205042, -82.876523];
Zipcodes['43022'] = [40.345042, -82.339812];
Zipcodes['43023'] = [40.075537, -82.529776];
Zipcodes['43025'] = [39.970889, -82.517382];
Zipcodes['43026'] = [40.020519, -83.177502];
Zipcodes['43027'] = [40.253735, -82.522816];
Zipcodes['43028'] = [40.401712, -82.297266];
Zipcodes['43029'] = [40.097477, -83.440642];
Zipcodes['43030'] = [39.969139, -82.40575];
Zipcodes['43031'] = [40.162786, -82.663517];
Zipcodes['43032'] = [40.328595, -82.958098];
Zipcodes['43033'] = [39.961234, -82.599664];
Zipcodes['43035'] = [40.18597, -82.996343];
Zipcodes['43036'] = [40.352136, -83.263182];
Zipcodes['43037'] = [40.284431, -82.32319];
Zipcodes['43040'] = [40.261305, -83.358138];
Zipcodes['43044'] = [40.054878, -83.550817];
Zipcodes['43045'] = [40.168303, -83.458762];
Zipcodes['43046'] = [39.901754, -82.540412];
Zipcodes['43047'] = [40.207958, -83.637975];
Zipcodes['43050'] = [40.373976, -82.492721];
Zipcodes['43054'] = [40.070261, -82.802113];
Zipcodes['43055'] = [40.110508, -82.378699];
Zipcodes['43056'] = [40.009034, -82.331779];
Zipcodes['43060'] = [40.222358, -83.571509];
Zipcodes['43061'] = [40.289506, -83.203052];
Zipcodes['43062'] = [40.008131, -82.685267];
Zipcodes['43064'] = [40.098492, -83.288172];
Zipcodes['43065'] = [40.177567, -83.094156];
Zipcodes['43066'] = [40.402576, -83.172895];
Zipcodes['43067'] = [40.343049, -83.48386];
Zipcodes['43068'] = [39.956185, -82.784665];
Zipcodes['43070'] = [40.220585, -83.956489];
Zipcodes['43071'] = [40.180695, -82.359463];
Zipcodes['43072'] = [40.115162, -83.954818];
Zipcodes['43074'] = [40.269531, -82.850655];
Zipcodes['43076'] = [39.896193, -82.391194];
Zipcodes['43077'] = [40.13705, -83.341267];
Zipcodes['43078'] = [40.117636, -83.783785];
Zipcodes['43080'] = [40.240719, -82.429257];
Zipcodes['43081'] = [40.110443, -82.890646];
Zipcodes['43082'] = [40.155571, -82.885108];
Zipcodes['43084'] = [40.150137, -83.557264];
Zipcodes['43085'] = [40.100924, -83.013402];
Zipcodes['43101'] = [39.464497, -82.746107];
Zipcodes['43102'] = [39.64092, -82.764982];
Zipcodes['43103'] = [39.732375, -82.935708];
Zipcodes['43105'] = [39.86676, -82.612369];
Zipcodes['43106'] = [39.644806, -83.418536];
Zipcodes['43107'] = [39.692825, -82.414529];
Zipcodes['43109'] = [39.917249, -82.831886];
Zipcodes['43110'] = [39.826682, -82.800074];
Zipcodes['43111'] = [39.502067, -82.2427];
Zipcodes['43112'] = [39.79278, -82.698409];
Zipcodes['43113'] = [39.58659, -82.962245];
Zipcodes['43115'] = [39.489578, -83.168956];
Zipcodes['43116'] = [39.772755, -83.062758];
Zipcodes['43117'] = [39.768736, -83.206074];
Zipcodes['43119'] = [39.940928, -83.205719];
Zipcodes['43123'] = [39.866721, -83.115561];
Zipcodes['43125'] = [39.838052, -82.887941];
Zipcodes['43126'] = [39.810726, -83.168258];
Zipcodes['43127'] = [39.475597, -82.322298];
Zipcodes['43128'] = [39.659641, -83.593633];
Zipcodes['43130'] = [39.696463, -82.614717];
Zipcodes['43135'] = [39.472913, -82.713001];
Zipcodes['43136'] = [39.801631, -82.815387];
Zipcodes['43137'] = [39.8089, -82.987294];
Zipcodes['43138'] = [39.51834, -82.414097];
Zipcodes['43140'] = [39.880108, -83.428512];
Zipcodes['43142'] = [39.592923, -83.587788];
Zipcodes['43143'] = [39.712438, -83.288721];
Zipcodes['43144'] = [39.52988, -82.195044];
Zipcodes['43145'] = [39.56226, -83.260058];
Zipcodes['43146'] = [39.773521, -83.136925];
Zipcodes['43147'] = [39.897782, -82.744035];
Zipcodes['43148'] = [39.820316, -82.499992];
Zipcodes['43149'] = [39.542974, -82.577874];
Zipcodes['43150'] = [39.777096, -82.409813];
Zipcodes['43151'] = [39.733173, -83.476623];
Zipcodes['43152'] = [39.394275, -82.615932];
Zipcodes['43153'] = [39.743989, -83.556692];
Zipcodes['43154'] = [39.60391, -82.829648];
Zipcodes['43155'] = [39.627314, -82.535203];
Zipcodes['43156'] = [39.553806, -82.776293];
Zipcodes['43157'] = [39.843016, -82.546824];
Zipcodes['43158'] = [39.453047, -82.351192];
Zipcodes['43160'] = [39.52874, -83.439186];
Zipcodes['43162'] = [39.9494, -83.308423];
Zipcodes['43164'] = [39.583733, -83.119834];
Zipcodes['43201'] = [39.990881, -83.00116];
Zipcodes['43202'] = [40.019403, -83.014471];
Zipcodes['43203'] = [39.972934, -82.969167];
Zipcodes['43204'] = [39.960662, -83.082542];
Zipcodes['43205'] = [39.957342, -82.962019];
Zipcodes['43206'] = [39.942452, -82.974175];
Zipcodes['43207'] = [39.895414, -82.963853];
Zipcodes['43209'] = [39.953588, -82.929524];
Zipcodes['43210'] = [40.006425, -83.02212];
Zipcodes['43211'] = [40.011814, -82.970532];
Zipcodes['43212'] = [39.986564, -83.043198];
Zipcodes['43213'] = [39.971201, -82.863958];
Zipcodes['43214'] = [40.051734, -83.016269];
Zipcodes['43215'] = [39.966856, -83.01296];
Zipcodes['43217'] = [39.816946, -82.934089];
Zipcodes['43218'] = [40.017566, -82.923723];
Zipcodes['43219'] = [40.007373, -82.920654];
Zipcodes['43220'] = [40.048905, -83.072189];
Zipcodes['43221'] = [40.023112, -83.076127];
Zipcodes['43222'] = [39.961192, -83.035484];
Zipcodes['43223'] = [39.926237, -83.03305];
Zipcodes['43224'] = [40.042251, -82.964434];
Zipcodes['43227'] = [39.943213, -82.887646];
Zipcodes['43228'] = [39.964019, -83.127747];
Zipcodes['43229'] = [40.08587, -82.978197];
Zipcodes['43230'] = [40.038143, -82.872725];
Zipcodes['43231'] = [40.079392, -82.936858];
Zipcodes['43232'] = [39.920977, -82.870372];
Zipcodes['43235'] = [40.078722, -83.082473];
Zipcodes['43240'] = [40.145121, -82.98282];
Zipcodes['43302'] = [40.599237, -83.130298];
Zipcodes['43310'] = [40.532533, -83.779913];
Zipcodes['43311'] = [40.36441, -83.758426];
Zipcodes['43314'] = [40.647409, -82.961225];
Zipcodes['43315'] = [40.483968, -82.874359];
Zipcodes['43316'] = [40.951821, -83.384958];
Zipcodes['43317'] = [40.475145, -82.680931];
Zipcodes['43318'] = [40.309647, -83.913182];
Zipcodes['43319'] = [40.302682, -83.57512];
Zipcodes['43320'] = [40.588576, -82.899922];
Zipcodes['43321'] = [40.463053, -82.829033];
Zipcodes['43322'] = [40.532546, -83.208117];
Zipcodes['43323'] = [40.732133, -83.254734];
Zipcodes['43324'] = [40.444543, -83.813798];
Zipcodes['43325'] = [40.668063, -82.831265];
Zipcodes['43326'] = [40.64144, -83.614776];
Zipcodes['43330'] = [40.813493, -83.419408];
Zipcodes['43331'] = [40.517943, -83.921643];
Zipcodes['43332'] = [40.590903, -83.371544];
Zipcodes['43333'] = [40.432461, -83.923361];
Zipcodes['43334'] = [40.397947, -82.797304];
Zipcodes['43336'] = [40.283079, -83.576613];
Zipcodes['43337'] = [40.681312, -83.266637];
Zipcodes['43338'] = [40.564167, -82.760333];
Zipcodes['43340'] = [40.53031, -83.497923];
Zipcodes['43341'] = [40.595756, -83.315445];
Zipcodes['43342'] = [40.471236, -83.18398];
Zipcodes['43343'] = [40.305092, -83.995255];
Zipcodes['43344'] = [40.430934, -83.344615];
Zipcodes['43345'] = [40.508496, -83.586161];
Zipcodes['43346'] = [40.576822, -83.845209];
Zipcodes['43347'] = [40.477715, -83.658043];
Zipcodes['43348'] = [40.461198, -83.891401];
Zipcodes['43350'] = [40.395564, -82.699799];
Zipcodes['43351'] = [40.827604, -83.314046];
Zipcodes['43356'] = [40.45373, -83.041951];
Zipcodes['43357'] = [40.253523, -83.746774];
Zipcodes['43358'] = [40.41459, -83.537606];
Zipcodes['43359'] = [40.869767, -83.45318];
Zipcodes['43360'] = [40.333139, -83.643525];
Zipcodes['43402'] = [41.4097, -83.653452];
Zipcodes['43403'] = [41.377844, -83.636555];
Zipcodes['43406'] = [41.335764, -83.438605];
Zipcodes['43407'] = [41.277159, -83.249945];
Zipcodes['43408'] = [41.571834, -83.363479];
Zipcodes['43410'] = [41.31174, -82.95793];
Zipcodes['43412'] = [41.631291, -83.321889];
Zipcodes['43413'] = [41.247677, -83.660498];
Zipcodes['43414'] = [41.451403, -83.617162];
Zipcodes['43416'] = [41.483937, -83.267229];
Zipcodes['43420'] = [41.357927, -83.110237];
Zipcodes['43430'] = [41.524117, -83.375186];
Zipcodes['43431'] = [41.391809, -83.343718];
Zipcodes['43432'] = [41.563259, -83.252471];
Zipcodes['43433'] = [41.504621, -82.880336];
Zipcodes['43434'] = [41.693283, -83.444751];
Zipcodes['43435'] = [41.326332, -83.312052];
Zipcodes['43436'] = [41.713539, -82.826002];
Zipcodes['43437'] = [41.253957, -83.601779];
Zipcodes['43438'] = [41.602607, -82.706506];
Zipcodes['43439'] = [41.517587, -83.042752];
Zipcodes['43440'] = [41.515143, -82.771086];
Zipcodes['43442'] = [41.425041, -83.215825];
Zipcodes['43443'] = [41.461331, -83.470237];
Zipcodes['43445'] = [41.556616, -83.308937];
Zipcodes['43446'] = [41.673836, -82.80989];
Zipcodes['43447'] = [41.567683, -83.433162];
Zipcodes['43449'] = [41.534376, -83.13557];
Zipcodes['43450'] = [41.400871, -83.491254];
Zipcodes['43451'] = [41.318294, -83.618453];
Zipcodes['43452'] = [41.506464, -82.953329];
Zipcodes['43456'] = [41.650592, -82.826828];
Zipcodes['43457'] = [41.267878, -83.425065];
Zipcodes['43458'] = [41.530446, -83.213365];
Zipcodes['43460'] = [41.604817, -83.561032];
Zipcodes['43462'] = [41.283785, -83.722865];
Zipcodes['43463'] = [41.511458, -83.505088];
Zipcodes['43464'] = [41.415984, -82.922143];
Zipcodes['43465'] = [41.565472, -83.500302];
Zipcodes['43466'] = [41.29233, -83.513933];
Zipcodes['43467'] = [41.243263, -83.490878];
Zipcodes['43468'] = [41.599737, -83.34662];
Zipcodes['43469'] = [41.460369, -83.36455];
Zipcodes['43501'] = [41.672655, -84.461796];
Zipcodes['43502'] = [41.532649, -84.303124];
Zipcodes['43504'] = [41.697526, -83.834178];
Zipcodes['43505'] = [41.524228, -84.730909];
Zipcodes['43506'] = [41.466211, -84.557133];
Zipcodes['43510'] = [41.464838, -83.9471];
Zipcodes['43511'] = [41.2638, -83.823732];
Zipcodes['43512'] = [41.303378, -84.358279];
Zipcodes['43515'] = [41.586912, -84.010042];
Zipcodes['43516'] = [41.214903, -83.915242];
Zipcodes['43517'] = [41.433945, -84.72855];
Zipcodes['43518'] = [41.596488, -84.757748];
Zipcodes['43519'] = [41.419465, -84.406872];
Zipcodes['43520'] = [41.380047, -84.635669];
Zipcodes['43521'] = [41.660489, -84.297994];
Zipcodes['43522'] = [41.42031, -83.832402];
Zipcodes['43523'] = [41.338748, -83.998652];
Zipcodes['43524'] = [41.227059, -84.038511];
Zipcodes['43525'] = [41.466681, -83.701137];
Zipcodes['43526'] = [41.312072, -84.730847];
Zipcodes['43527'] = [41.246109, -84.157502];
Zipcodes['43528'] = [41.628855, -83.749575];
Zipcodes['43529'] = [41.1904, -83.78491];
Zipcodes['43530'] = [41.327921, -84.289447];
Zipcodes['43531'] = [41.635044, -84.495666];
Zipcodes['43532'] = [41.449074, -83.964344];
Zipcodes['43533'] = [41.691874, -84.081534];
Zipcodes['43534'] = [41.349611, -83.926981];
Zipcodes['43535'] = [41.305899, -84.024153];
Zipcodes['43536'] = [41.313445, -84.634004];
Zipcodes['43537'] = [41.574823, -83.686622];
Zipcodes['43540'] = [41.68871, -83.944492];
Zipcodes['43541'] = [41.300739, -83.829661];
Zipcodes['43542'] = [41.566408, -83.765308];
Zipcodes['43543'] = [41.606489, -84.645346];
Zipcodes['43545'] = [41.392249, -84.128288];
Zipcodes['43547'] = [41.49216, -83.876316];
Zipcodes['43548'] = [41.188665, -84.172959];
Zipcodes['43549'] = [41.369314, -84.531144];
Zipcodes['43551'] = [41.518596, -83.579118];
Zipcodes['43553'] = [41.530662, -84.217902];
Zipcodes['43554'] = [41.658421, -84.563491];
Zipcodes['43555'] = [41.437778, -84.25579];
Zipcodes['43556'] = [41.303304, -84.563729];
Zipcodes['43557'] = [41.487398, -84.392482];
Zipcodes['43558'] = [41.593972, -83.878653];
Zipcodes['43560'] = [41.700591, -83.738852];
Zipcodes['43565'] = [41.420445, -83.740106];
Zipcodes['43566'] = [41.491623, -83.756052];
Zipcodes['43567'] = [41.580345, -84.161065];
Zipcodes['43569'] = [41.349767, -83.789426];
Zipcodes['43570'] = [41.586056, -84.441036];
Zipcodes['43571'] = [41.514181, -83.813649];
Zipcodes['43604'] = [41.652389, -83.538897];
Zipcodes['43605'] = [41.648026, -83.506863];
Zipcodes['43606'] = [41.673136, -83.611414];
Zipcodes['43607'] = [41.648634, -83.604132];
Zipcodes['43608'] = [41.679872, -83.528913];
Zipcodes['43609'] = [41.628256, -83.57868];
Zipcodes['43610'] = [41.677783, -83.561453];
Zipcodes['43611'] = [41.703247, -83.486642];
Zipcodes['43612'] = [41.715635, -83.550946];
Zipcodes['43613'] = [41.706228, -83.603714];
Zipcodes['43614'] = [41.60446, -83.627515];
Zipcodes['43615'] = [41.649728, -83.671959];
Zipcodes['43616'] = [41.658372, -83.408909];
Zipcodes['43617'] = [41.664195, -83.728053];
Zipcodes['43619'] = [41.603711, -83.47049];
Zipcodes['43620'] = [41.665178, -83.554722];
Zipcodes['43623'] = [41.702956, -83.650077];
Zipcodes['43701'] = [39.963961, -81.995711];
Zipcodes['43711'] = [39.841865, -81.577973];
Zipcodes['43713'] = [39.989862, -81.173715];
Zipcodes['43716'] = [39.829224, -81.011909];
Zipcodes['43717'] = [39.789327, -81.554711];
Zipcodes['43718'] = [40.015007, -80.998096];
Zipcodes['43719'] = [39.99551, -81.09328];
Zipcodes['43720'] = [39.796093, -81.876456];
Zipcodes['43721'] = [39.949259, -82.260167];
Zipcodes['43722'] = [39.919311, -81.519962];
Zipcodes['43723'] = [39.960762, -81.541728];
Zipcodes['43724'] = [39.720894, -81.498682];
Zipcodes['43725'] = [40.039607, -81.601202];
Zipcodes['43727'] = [39.875689, -81.800241];
Zipcodes['43728'] = [39.480162, -81.895132];
Zipcodes['43730'] = [39.639168, -82.092226];
Zipcodes['43731'] = [39.733139, -82.058314];
Zipcodes['43732'] = [39.842747, -81.659465];
Zipcodes['43733'] = [39.927387, -81.538035];
Zipcodes['43734'] = [39.882489, -81.913656];
Zipcodes['43735'] = [39.857167, -82.114141];
Zipcodes['43736'] = [40.057683, -81.236607];
Zipcodes['43738'] = [39.855347, -82.140488];
Zipcodes['43739'] = [39.903136, -82.28631];
Zipcodes['43740'] = [39.951429, -82.217201];
Zipcodes['43746'] = [39.965416, -82.183858];
Zipcodes['43747'] = [39.860809, -81.124896];
Zipcodes['43748'] = [39.692383, -82.316294];
Zipcodes['43749'] = [40.155641, -81.555888];
Zipcodes['43750'] = [39.996873, -81.513176];
Zipcodes['43754'] = [39.753956, -81.243275];
Zipcodes['43755'] = [40.053294, -81.432363];
Zipcodes['43756'] = [39.691749, -81.795492];
Zipcodes['43758'] = [39.627856, -81.94544];
Zipcodes['43759'] = [40.063059, -81.070519];
Zipcodes['43760'] = [39.888265, -82.193224];
Zipcodes['43761'] = [39.66833, -82.148252];
Zipcodes['43762'] = [40.029956, -81.739992];
Zipcodes['43764'] = [39.713305, -82.180583];
Zipcodes['43766'] = [39.593199, -82.252838];
Zipcodes['43767'] = [40.014251, -81.80783];
Zipcodes['43768'] = [40.037761, -81.444709];
Zipcodes['43771'] = [39.835562, -81.945402];
Zipcodes['43772'] = [39.88606, -81.526625];
Zipcodes['43773'] = [39.982044, -81.298757];
Zipcodes['43777'] = [39.829116, -82.104103];
Zipcodes['43778'] = [40.010159, -81.368797];
Zipcodes['43779'] = [39.81957, -81.427766];
Zipcodes['43780'] = [39.908693, -81.437952];
Zipcodes['43782'] = [39.621795, -82.212467];
Zipcodes['43783'] = [39.80448, -82.278581];
Zipcodes['43786'] = [39.710868, -81.277046];
Zipcodes['43787'] = [39.523964, -81.801822];
Zipcodes['43788'] = [39.805526, -81.333948];
Zipcodes['43791'] = [39.877616, -82.08858];
Zipcodes['43793'] = [39.745149, -81.096102];
Zipcodes['43802'] = [40.091161, -81.852057];
Zipcodes['43804'] = [40.450845, -81.740914];
Zipcodes['43805'] = [40.389985, -81.971796];
Zipcodes['43811'] = [40.192157, -81.943009];
Zipcodes['43812'] = [40.263839, -81.880915];
Zipcodes['43821'] = [40.136752, -82.000512];
Zipcodes['43822'] = [40.181432, -82.158408];
Zipcodes['43824'] = [40.36391, -81.758449];
Zipcodes['43830'] = [40.06282, -82.137472];
Zipcodes['43832'] = [40.27335, -81.578178];
Zipcodes['43836'] = [40.208699, -81.718579];
Zipcodes['43837'] = [40.298402, -81.47793];
Zipcodes['43840'] = [40.403581, -81.603858];
Zipcodes['43842'] = [40.137444, -82.011816];
Zipcodes['43843'] = [40.349011, -82.17561];
Zipcodes['43844'] = [40.333655, -82.051059];
Zipcodes['43845'] = [40.264908, -81.731279];
Zipcodes['43901'] = [40.216723, -80.866199];
Zipcodes['43902'] = [39.88285, -80.951993];
Zipcodes['43903'] = [40.472134, -80.973297];
Zipcodes['43905'] = [40.11417, -80.833284];
Zipcodes['43906'] = [40.011095, -80.808694];
Zipcodes['43907'] = [40.248449, -81.019659];
Zipcodes['43908'] = [40.506269, -80.874019];
Zipcodes['43909'] = [40.067659, -80.814932];
Zipcodes['43910'] = [40.372682, -80.826332];
Zipcodes['43912'] = [40.090804, -80.79258];
Zipcodes['43913'] = [40.26823, -80.637584];
Zipcodes['43914'] = [39.775225, -80.952806];
Zipcodes['43915'] = [39.745732, -80.931569];
Zipcodes['43917'] = [40.215338, -80.792808];
Zipcodes['43920'] = [40.677227, -80.594299];
Zipcodes['43925'] = [40.445981, -80.862313];
Zipcodes['43926'] = [40.509103, -80.621739];
Zipcodes['43928'] = [40.013155, -80.882866];
Zipcodes['43930'] = [40.563211, -80.762834];
Zipcodes['43931'] = [39.672931, -80.872071];
Zipcodes['43932'] = [40.528597, -80.746253];
Zipcodes['43933'] = [39.935227, -80.8958];
Zipcodes['43934'] = [40.076093, -80.793756];
Zipcodes['43935'] = [40.120281, -80.739832];
Zipcodes['43938'] = [40.298534, -80.674994];
Zipcodes['43939'] = [40.16717, -80.80002];
Zipcodes['43940'] = [40.028974, -80.827299];
Zipcodes['43942'] = [39.861027, -80.854883];
Zipcodes['43943'] = [40.208931, -80.732289];
Zipcodes['43944'] = [40.436037, -80.771727];
Zipcodes['43945'] = [40.631988, -80.850107];
Zipcodes['43946'] = [39.648277, -80.974823];
Zipcodes['43947'] = [39.955025, -80.791533];
Zipcodes['43948'] = [40.268346, -80.779965];
Zipcodes['43950'] = [40.096948, -80.924704];
Zipcodes['43951'] = [40.114361, -81.011812];
Zipcodes['43952'] = [40.409127, -80.665297];
Zipcodes['43953'] = [40.352415, -80.702489];
Zipcodes['43961'] = [40.523512, -80.630385];
Zipcodes['43962'] = [40.673011, -80.889208];
Zipcodes['43963'] = [40.171369, -80.696907];
Zipcodes['43964'] = [40.480902, -80.662475];
Zipcodes['43967'] = [40.02653, -80.946227];
Zipcodes['43968'] = [40.632407, -80.683847];
Zipcodes['43971'] = [40.159103, -80.713361];
Zipcodes['43972'] = [40.101754, -80.973719];
Zipcodes['43973'] = [40.190266, -81.276328];
Zipcodes['43974'] = [40.182205, -80.887645];
Zipcodes['43976'] = [40.378004, -80.904709];
Zipcodes['43977'] = [40.148386, -81.114083];
Zipcodes['43981'] = [40.18442, -80.994283];
Zipcodes['43983'] = [40.137138, -81.199038];
Zipcodes['43985'] = [40.161779, -81.13209];
Zipcodes['43986'] = [40.383265, -80.990452];
Zipcodes['43988'] = [40.404394, -81.098229];
Zipcodes['44001'] = [41.366331, -82.259924];
Zipcodes['44003'] = [41.615736, -80.585942];
Zipcodes['44004'] = [41.855938, -80.790536];
Zipcodes['44010'] = [41.765276, -80.847485];
Zipcodes['44011'] = [41.4451, -82.005109];
Zipcodes['44012'] = [41.49727, -82.017462];
Zipcodes['44017'] = [41.370548, -81.861757];
Zipcodes['44021'] = [41.44326, -81.144465];
Zipcodes['44022'] = [41.446249, -81.402969];
Zipcodes['44023'] = [41.384807, -81.285741];
Zipcodes['44024'] = [41.578209, -81.19126];
Zipcodes['44026'] = [41.528147, -81.324706];
Zipcodes['44028'] = [41.300523, -81.937418];
Zipcodes['44030'] = [41.903318, -80.579879];
Zipcodes['44032'] = [41.669245, -80.67011];
Zipcodes['44035'] = [41.370046, -82.135976];
Zipcodes['44039'] = [41.383524, -82.020667];
Zipcodes['44040'] = [41.530992, -81.411153];
Zipcodes['44041'] = [41.7769, -80.949898];
Zipcodes['44044'] = [41.266284, -82.04179];
Zipcodes['44045'] = [41.743895, -81.286268];
Zipcodes['44046'] = [41.541516, -81.080263];
Zipcodes['44047'] = [41.727931, -80.735874];
Zipcodes['44048'] = [41.850255, -80.639309];
Zipcodes['44049'] = [41.266529, -82.305788];
Zipcodes['44050'] = [41.248733, -82.1282];
Zipcodes['44052'] = [41.460687, -82.167946];
Zipcodes['44053'] = [41.430125, -82.222205];
Zipcodes['44054'] = [41.4716, -82.090338];
Zipcodes['44055'] = [41.434058, -82.134095];
Zipcodes['44056'] = [41.314919, -81.501633];
Zipcodes['44057'] = [41.760117, -81.060305];
Zipcodes['44060'] = [41.67653, -81.328167];
Zipcodes['44062'] = [41.451056, -81.034637];
Zipcodes['44064'] = [41.598063, -81.032323];
Zipcodes['44065'] = [41.454775, -81.22918];
Zipcodes['44067'] = [41.313073, -81.543821];
Zipcodes['44068'] = [41.90621, -80.678814];
Zipcodes['44070'] = [41.414929, -81.919131];
Zipcodes['44072'] = [41.471152, -81.32492];
Zipcodes['44074'] = [41.289038, -82.235963];
Zipcodes['44076'] = [41.528147, -80.81475];
Zipcodes['44077'] = [41.697563, -81.20974];
Zipcodes['44080'] = [41.368405, -81.057809];
Zipcodes['44081'] = [41.763938, -81.143104];
Zipcodes['44082'] = [41.761873, -80.567535];
Zipcodes['44084'] = [41.671377, -80.899161];
Zipcodes['44085'] = [41.603272, -80.874544];
Zipcodes['44086'] = [41.675726, -81.058597];
Zipcodes['44087'] = [41.317111, -81.443521];
Zipcodes['44089'] = [41.392084, -82.377455];
Zipcodes['44090'] = [41.167482, -82.22838];
Zipcodes['44092'] = [41.596188, -81.470954];
Zipcodes['44093'] = [41.536156, -80.613263];
Zipcodes['44094'] = [41.610724, -81.379324];
Zipcodes['44095'] = [41.652326, -81.441721];
Zipcodes['44099'] = [41.549205, -80.983305];
Zipcodes['44101'] = [41.489929, -81.671842];
Zipcodes['44102'] = [41.479281, -81.736378];
Zipcodes['44103'] = [41.516323, -81.642908];
Zipcodes['44104'] = [41.482409, -81.627043];
Zipcodes['44105'] = [41.449858, -81.632098];
Zipcodes['44106'] = [41.505341, -81.605432];
Zipcodes['44107'] = [41.486189, -81.801047];
Zipcodes['44108'] = [41.54148, -81.612482];
Zipcodes['44109'] = [41.444141, -81.698357];
Zipcodes['44110'] = [41.568628, -81.566326];
Zipcodes['44111'] = [41.457782, -81.789892];
Zipcodes['44112'] = [41.535841, -81.574143];
Zipcodes['44113'] = [41.483241, -81.697166];
Zipcodes['44114'] = [41.52025, -81.678617];
Zipcodes['44115'] = [41.493539, -81.671188];
Zipcodes['44116'] = [41.473072, -81.853825];
Zipcodes['44117'] = [41.570651, -81.526412];
Zipcodes['44118'] = [41.502337, -81.556571];
Zipcodes['44119'] = [41.588256, -81.548746];
Zipcodes['44120'] = [41.473288, -81.58088];
Zipcodes['44121'] = [41.526243, -81.532433];
Zipcodes['44122'] = [41.469472, -81.512415];
Zipcodes['44123'] = [41.604699, -81.524123];
Zipcodes['44124'] = [41.49888, -81.470711];
Zipcodes['44125'] = [41.405777, -81.609715];
Zipcodes['44126'] = [41.442665, -81.852734];
Zipcodes['44127'] = [41.471013, -81.648805];
Zipcodes['44128'] = [41.439887, -81.538531];
Zipcodes['44129'] = [41.390459, -81.7353];
Zipcodes['44130'] = [41.376642, -81.787317];
Zipcodes['44131'] = [41.383276, -81.65469];
Zipcodes['44132'] = [41.606424, -81.497217];
Zipcodes['44133'] = [41.315857, -81.74301];
Zipcodes['44134'] = [41.38341, -81.702612];
Zipcodes['44135'] = [41.423588, -81.82593];
Zipcodes['44136'] = [41.312466, -81.809769];
Zipcodes['44137'] = [41.409027, -81.562762];
Zipcodes['44138'] = [41.373671, -81.922993];
Zipcodes['44139'] = [41.383262, -81.444249];
Zipcodes['44140'] = [41.487771, -81.928891];
Zipcodes['44141'] = [41.299668, -81.616506];
Zipcodes['44142'] = [41.398832, -81.828543];
Zipcodes['44143'] = [41.553196, -81.474346];
Zipcodes['44144'] = [41.437286, -81.739814];
Zipcodes['44145'] = [41.449531, -81.930162];
Zipcodes['44146'] = [41.381223, -81.529226];
Zipcodes['44147'] = [41.318156, -81.679262];
Zipcodes['44149'] = [41.311458, -81.853087];
Zipcodes['44201'] = [41.02866, -81.184283];
Zipcodes['44202'] = [41.316634, -81.342178];
Zipcodes['44203'] = [41.020135, -81.63068];
Zipcodes['44212'] = [41.244488, -81.828842];
Zipcodes['44214'] = [40.951923, -81.999753];
Zipcodes['44215'] = [41.071986, -81.89922];
Zipcodes['44216'] = [40.945779, -81.581744];
Zipcodes['44217'] = [40.952216, -81.913482];
Zipcodes['44221'] = [41.139266, -81.474873];
Zipcodes['44223'] = [41.169665, -81.531057];
Zipcodes['44224'] = [41.176261, -81.436523];
Zipcodes['44230'] = [40.962103, -81.690327];
Zipcodes['44231'] = [41.302963, -81.070474];
Zipcodes['44232'] = [40.932541, -81.463521];
Zipcodes['44233'] = [41.249087, -81.737867];
Zipcodes['44234'] = [41.325883, -81.152253];
Zipcodes['44235'] = [41.031108, -82.116704];
Zipcodes['44236'] = [41.246089, -81.44909];
Zipcodes['44240'] = [41.132956, -81.342432];
Zipcodes['44241'] = [41.242586, -81.347721];
Zipcodes['44243'] = [41.147842, -81.340464];
Zipcodes['44250'] = [41.020952, -81.433138];
Zipcodes['44251'] = [41.02819, -81.927641];
Zipcodes['44253'] = [41.162775, -82.033342];
Zipcodes['44254'] = [41.045359, -82.01245];
Zipcodes['44255'] = [41.286688, -81.229104];
Zipcodes['44256'] = [41.141354, -81.859522];
Zipcodes['44260'] = [41.035186, -81.338442];
Zipcodes['44262'] = [41.139576, -81.436058];
Zipcodes['44264'] = [41.229771, -81.540489];
Zipcodes['44266'] = [41.16897, -81.196962];
Zipcodes['44270'] = [40.960943, -81.774537];
Zipcodes['44272'] = [41.089759, -81.180523];
Zipcodes['44273'] = [41.027999, -81.877935];
Zipcodes['44274'] = [41.099446, -81.728537];
Zipcodes['44275'] = [41.097181, -82.105641];
Zipcodes['44276'] = [40.93973, -81.836018];
Zipcodes['44278'] = [41.092626, -81.42001];
Zipcodes['44280'] = [41.240261, -81.927779];
Zipcodes['44281'] = [41.0598, -81.740386];
Zipcodes['44285'] = [41.16264, -81.059593];
Zipcodes['44286'] = [41.233132, -81.643941];
Zipcodes['44287'] = [40.940215, -82.104486];
Zipcodes['44288'] = [41.239078, -81.076039];
Zipcodes['44301'] = [41.04349, -81.524017];
Zipcodes['44302'] = [41.08945, -81.538489];
Zipcodes['44303'] = [41.10447, -81.537358];
Zipcodes['44304'] = [41.085888, -81.509129];
Zipcodes['44305'] = [41.076637, -81.462675];
Zipcodes['44306'] = [41.039215, -81.483159];
Zipcodes['44307'] = [41.069221, -81.546525];
Zipcodes['44308'] = [41.081805, -81.517451];
Zipcodes['44310'] = [41.109156, -81.499983];
Zipcodes['44311'] = [41.064227, -81.520446];
Zipcodes['44312'] = [41.006513, -81.438547];
Zipcodes['44313'] = [41.130094, -81.573169];
Zipcodes['44314'] = [41.040756, -81.557908];
Zipcodes['44319'] = [40.986326, -81.531138];
Zipcodes['44320'] = [41.07314, -81.582414];
Zipcodes['44321'] = [41.100689, -81.647946];
Zipcodes['44325'] = [41.076124, -81.512285];
Zipcodes['44333'] = [41.164952, -81.633826];
Zipcodes['44401'] = [41.027176, -80.938225];
Zipcodes['44402'] = [41.397995, -80.852844];
Zipcodes['44403'] = [41.240521, -80.582656];
Zipcodes['44404'] = [41.324106, -80.556627];
Zipcodes['44405'] = [41.077984, -80.592359];
Zipcodes['44406'] = [41.01088, -80.770396];
Zipcodes['44408'] = [40.884987, -80.68794];
Zipcodes['44410'] = [41.346299, -80.727712];
Zipcodes['44411'] = [41.047698, -81.059303];
Zipcodes['44412'] = [41.09417, -81.023487];
Zipcodes['44413'] = [40.844757, -80.548689];
Zipcodes['44415'] = [40.770656, -80.704549];
Zipcodes['44417'] = [41.43316, -80.663287];
Zipcodes['44418'] = [41.306912, -80.60462];
Zipcodes['44420'] = [41.169929, -80.671496];
Zipcodes['44423'] = [40.74852, -80.895947];
Zipcodes['44424'] = [41.322011, -80.58324];
Zipcodes['44425'] = [41.168852, -80.578164];
Zipcodes['44427'] = [40.713736, -80.961667];
Zipcodes['44428'] = [41.430846, -80.585193];
Zipcodes['44429'] = [41.095128, -80.974159];
Zipcodes['44430'] = [41.237896, -80.910764];
Zipcodes['44431'] = [40.837609, -80.751064];
Zipcodes['44432'] = [40.749809, -80.749876];
Zipcodes['44436'] = [41.058684, -80.535073];
Zipcodes['44437'] = [41.15594, -80.729884];
Zipcodes['44438'] = [41.239654, -80.53066];
Zipcodes['44439'] = [41.454357, -80.955505];
Zipcodes['44440'] = [41.136876, -80.806286];
Zipcodes['44441'] = [40.755333, -80.548808];
Zipcodes['44442'] = [40.966173, -80.546111];
Zipcodes['44443'] = [40.921002, -80.590583];
Zipcodes['44444'] = [41.175636, -80.977665];
Zipcodes['44445'] = [40.84777, -80.617109];
Zipcodes['44446'] = [41.189531, -80.747534];
Zipcodes['44449'] = [40.974814, -81.022475];
Zipcodes['44450'] = [41.445707, -80.81962];
Zipcodes['44451'] = [41.079368, -80.868193];
Zipcodes['44452'] = [40.947372, -80.664007];
Zipcodes['44454'] = [40.923729, -80.545358];
Zipcodes['44455'] = [40.7723, -80.610166];
Zipcodes['44460'] = [40.902815, -80.868743];
Zipcodes['44470'] = [41.30031, -80.972747];
Zipcodes['44471'] = [41.051053, -80.590566];
Zipcodes['44473'] = [41.246554, -80.662661];
Zipcodes['44481'] = [41.176592, -80.902499];
Zipcodes['44483'] = [41.26359, -80.817044];
Zipcodes['44484'] = [41.235541, -80.74975];
Zipcodes['44485'] = [41.240379, -80.847801];
Zipcodes['44490'] = [40.900361, -80.768289];
Zipcodes['44491'] = [41.369969, -80.96365];
Zipcodes['44493'] = [40.82192, -80.888597];
Zipcodes['44502'] = [41.082788, -80.639487];
Zipcodes['44503'] = [41.099326, -80.649074];
Zipcodes['44504'] = [41.123512, -80.654651];
Zipcodes['44505'] = [41.12792, -80.617554];
Zipcodes['44506'] = [41.093003, -80.626397];
Zipcodes['44507'] = [41.074284, -80.655445];
Zipcodes['44509'] = [41.110908, -80.697158];
Zipcodes['44510'] = [41.122254, -80.673433];
Zipcodes['44511'] = [41.069085, -80.696703];
Zipcodes['44512'] = [41.025937, -80.666955];
Zipcodes['44514'] = [40.995891, -80.608267];
Zipcodes['44515'] = [41.102896, -80.761275];
Zipcodes['44555'] = [41.10726, -80.648749];
Zipcodes['44601'] = [40.918593, -81.128745];
Zipcodes['44606'] = [40.734706, -81.800147];
Zipcodes['44607'] = [40.695908, -81.026873];
Zipcodes['44608'] = [40.656404, -81.592795];
Zipcodes['44609'] = [40.912311, -80.989812];
Zipcodes['44610'] = [40.560865, -81.800867];
Zipcodes['44611'] = [40.610102, -82.074983];
Zipcodes['44612'] = [40.633088, -81.476036];
Zipcodes['44613'] = [40.711517, -81.607777];
Zipcodes['44614'] = [40.888221, -81.587803];
Zipcodes['44615'] = [40.565805, -81.078601];
Zipcodes['44618'] = [40.770383, -81.681101];
Zipcodes['44619'] = [40.905254, -80.954455];
Zipcodes['44620'] = [40.585628, -81.227832];
Zipcodes['44621'] = [40.432127, -81.288171];
Zipcodes['44622'] = [40.551338, -81.483514];
Zipcodes['44624'] = [40.622225, -81.659302];
Zipcodes['44625'] = [40.714017, -81.022561];
Zipcodes['44626'] = [40.693933, -81.389306];
Zipcodes['44627'] = [40.664104, -81.83643];
Zipcodes['44628'] = [40.544491, -82.148485];
Zipcodes['44629'] = [40.349496, -81.453226];
Zipcodes['44630'] = [40.92694, -81.404064];
Zipcodes['44632'] = [40.963037, -81.305397];
Zipcodes['44633'] = [40.629759, -81.935268];
Zipcodes['44634'] = [40.837991, -81.041397];
Zipcodes['44637'] = [40.480346, -82.029657];
Zipcodes['44638'] = [40.647134, -82.132629];
Zipcodes['44639'] = [40.451729, -81.20975];
Zipcodes['44640'] = [40.985543, -81.150705];
Zipcodes['44641'] = [40.858219, -81.245344];
Zipcodes['44643'] = [40.638346, -81.308871];
Zipcodes['44644'] = [40.687649, -81.178209];
Zipcodes['44645'] = [40.90974, -81.718168];
Zipcodes['44646'] = [40.814381, -81.497182];
Zipcodes['44647'] = [40.794393, -81.56498];
Zipcodes['44651'] = [40.621287, -80.96177];
Zipcodes['44652'] = [40.9008, -81.330166];
Zipcodes['44653'] = [40.437293, -81.371353];
Zipcodes['44654'] = [40.537937, -81.870867];
Zipcodes['44656'] = [40.568393, -81.327664];
Zipcodes['44657'] = [40.744724, -81.094903];
Zipcodes['44659'] = [40.6948, -81.701825];
Zipcodes['44660'] = [40.624212, -81.772588];
Zipcodes['44661'] = [40.585377, -82.117401];
Zipcodes['44662'] = [40.71099, -81.555052];
Zipcodes['44663'] = [40.46048, -81.44958];
Zipcodes['44665'] = [40.835554, -80.987051];
Zipcodes['44666'] = [40.841878, -81.639934];
Zipcodes['44667'] = [40.833793, -81.765184];
Zipcodes['44669'] = [40.788312, -81.150684];
Zipcodes['44670'] = [40.763006, -81.187916];
Zipcodes['44671'] = [40.645174, -81.36568];
Zipcodes['44672'] = [40.920003, -81.025268];
Zipcodes['44675'] = [40.503967, -81.250464];
Zipcodes['44676'] = [40.690775, -82.033802];
Zipcodes['44677'] = [40.874634, -81.858587];
Zipcodes['44678'] = [40.564797, -81.364165];
Zipcodes['44680'] = [40.598967, -81.544039];
Zipcodes['44681'] = [40.500822, -81.663619];
Zipcodes['44682'] = [40.397787, -81.403463];
Zipcodes['44683'] = [40.364772, -81.325071];
Zipcodes['44685'] = [40.955405, -81.424372];
Zipcodes['44687'] = [40.539465, -81.710347];
Zipcodes['44688'] = [40.683908, -81.255382];
Zipcodes['44689'] = [40.647624, -81.659231];
Zipcodes['44690'] = [40.613106, -81.693638];
Zipcodes['44691'] = [40.806029, -81.977588];
Zipcodes['44693'] = [40.297769, -81.188203];
Zipcodes['44695'] = [40.442011, -81.170166];
Zipcodes['44697'] = [40.60804, -81.407271];
Zipcodes['44699'] = [40.288793, -81.289004];
Zipcodes['44702'] = [40.799837, -81.376725];
Zipcodes['44703'] = [40.810948, -81.381347];
Zipcodes['44704'] = [40.800042, -81.343062];
Zipcodes['44705'] = [40.833287, -81.332021];
Zipcodes['44706'] = [40.753824, -81.419797];
Zipcodes['44707'] = [40.760321, -81.347116];
Zipcodes['44708'] = [40.816563, -81.435458];
Zipcodes['44709'] = [40.842205, -81.386331];
Zipcodes['44710'] = [40.789613, -81.426572];
Zipcodes['44714'] = [40.836317, -81.358279];
Zipcodes['44718'] = [40.8484, -81.450468];
Zipcodes['44720'] = [40.903454, -81.433282];
Zipcodes['44721'] = [40.893066, -81.318113];
Zipcodes['44730'] = [40.767017, -81.265784];
Zipcodes['44802'] = [41.045338, -83.41911];
Zipcodes['44804'] = [41.107053, -83.535376];
Zipcodes['44805'] = [40.87322, -82.322775];
Zipcodes['44807'] = [41.061226, -82.864336];
Zipcodes['44809'] = [41.132103, -83.293978];
Zipcodes['44811'] = [41.246317, -82.846599];
Zipcodes['44813'] = [40.599849, -82.529699];
Zipcodes['44814'] = [41.324106, -82.47216];
Zipcodes['44815'] = [41.24692, -83.23945];
Zipcodes['44817'] = [41.204134, -83.545038];
Zipcodes['44818'] = [40.998342, -82.989373];
Zipcodes['44820'] = [40.818352, -82.971722];
Zipcodes['44822'] = [40.556723, -82.398088];
Zipcodes['44824'] = [41.383272, -82.805888];
Zipcodes['44825'] = [40.954424, -82.941927];
Zipcodes['44826'] = [41.24234, -82.477643];
Zipcodes['44827'] = [40.822444, -82.75506];
Zipcodes['44828'] = [41.233963, -82.859475];
Zipcodes['44830'] = [41.167936, -83.398998];
Zipcodes['44833'] = [40.709559, -82.795815];
Zipcodes['44836'] = [41.237162, -83.062895];
Zipcodes['44837'] = [41.021221, -82.472683];
Zipcodes['44838'] = [40.771565, -82.256155];
Zipcodes['44839'] = [41.379796, -82.549616];
Zipcodes['44840'] = [40.791875, -82.173115];
Zipcodes['44841'] = [41.251253, -83.311346];
Zipcodes['44842'] = [40.649212, -82.224849];
Zipcodes['44843'] = [40.689497, -82.407602];
Zipcodes['44844'] = [40.992169, -83.258362];
Zipcodes['44846'] = [41.319407, -82.605833];
Zipcodes['44847'] = [41.230414, -82.712888];
Zipcodes['44849'] = [40.8086, -83.131857];
Zipcodes['44850'] = [41.035393, -82.684018];
Zipcodes['44851'] = [41.109176, -82.391571];
Zipcodes['44853'] = [41.055022, -83.303993];
Zipcodes['44854'] = [40.943647, -82.867843];
Zipcodes['44855'] = [41.108739, -82.574938];
Zipcodes['44856'] = [40.793984, -82.856263];
Zipcodes['44857'] = [41.216499, -82.577642];
Zipcodes['44859'] = [41.021963, -82.330724];
Zipcodes['44861'] = [41.24259, -83.14623];
Zipcodes['44864'] = [40.667625, -82.320628];
Zipcodes['44865'] = [40.991575, -82.689436];
Zipcodes['44866'] = [40.924, -82.197783];
Zipcodes['44867'] = [41.149035, -82.98222];
Zipcodes['44870'] = [41.41952, -82.713212];
Zipcodes['44874'] = [40.969379, -82.369629];
Zipcodes['44875'] = [40.889956, -82.651706];
Zipcodes['44878'] = [40.951007, -82.520651];
Zipcodes['44880'] = [41.033472, -82.218901];
Zipcodes['44881'] = [40.870304, -82.876744];
Zipcodes['44882'] = [40.946679, -83.14022];
Zipcodes['44883'] = [41.124173, -83.173639];
Zipcodes['44887'] = [40.916196, -82.790658];
Zipcodes['44889'] = [41.245882, -82.382433];
Zipcodes['44890'] = [41.085172, -82.710562];
Zipcodes['44901'] = [40.763934, -82.512509];
Zipcodes['44902'] = [40.758885, -82.510962];
Zipcodes['44903'] = [40.776223, -82.526824];
Zipcodes['44904'] = [40.663703, -82.614459];
Zipcodes['44905'] = [40.778593, -82.46738];
Zipcodes['44906'] = [40.764486, -82.574974];
Zipcodes['44907'] = [40.727662, -82.520406];
Zipcodes['45001'] = [39.138123, -84.712834];
Zipcodes['45002'] = [39.198531, -84.763113];
Zipcodes['45003'] = [39.586583, -84.78647];
Zipcodes['45005'] = [39.528928, -84.289524];
Zipcodes['45011'] = [39.426575, -84.497637];
Zipcodes['45013'] = [39.413632, -84.652679];
Zipcodes['45014'] = [39.325714, -84.55239];
Zipcodes['45015'] = [39.374319, -84.56116];
Zipcodes['45030'] = [39.257248, -84.763252];
Zipcodes['45032'] = [39.502575, -84.00757];
Zipcodes['45033'] = [39.179645, -84.764695];
Zipcodes['45034'] = [39.356535, -84.246301];
Zipcodes['45036'] = [39.443045, -84.216366];
Zipcodes['45039'] = [39.327409, -84.236695];
Zipcodes['45040'] = [39.350783, -84.313198];
Zipcodes['45041'] = [39.210865, -84.702469];
Zipcodes['45042'] = [39.556885, -84.420158];
Zipcodes['45044'] = [39.482404, -84.410243];
Zipcodes['45050'] = [39.444365, -84.365912];
Zipcodes['45051'] = [39.099843, -84.655127];
Zipcodes['45052'] = [39.144456, -84.779062];
Zipcodes['45053'] = [39.354057, -84.787824];
Zipcodes['45054'] = [39.438829, -84.085062];
Zipcodes['45055'] = [39.457057, -84.510899];
Zipcodes['45056'] = [39.487425, -84.737908];
Zipcodes['45062'] = [39.483783, -84.555516];
Zipcodes['45064'] = [39.570754, -84.610259];
Zipcodes['45065'] = [39.370555, -84.213952];
Zipcodes['45066'] = [39.547963, -84.217555];
Zipcodes['45067'] = [39.494819, -84.480997];
Zipcodes['45068'] = [39.529427, -84.040548];
Zipcodes['45069'] = [39.343522, -84.412333];
Zipcodes['45070'] = [39.589695, -84.558089];
Zipcodes['45101'] = [38.697011, -83.726747];
Zipcodes['45102'] = [39.01719, -84.204345];
Zipcodes['45103'] = [39.091617, -84.143715];
Zipcodes['45105'] = [38.750434, -83.612373];
Zipcodes['45106'] = [38.94502, -84.064915];
Zipcodes['45107'] = [39.287524, -83.97645];
Zipcodes['45111'] = [39.201067, -84.287019];
Zipcodes['45112'] = [38.794854, -84.140047];
Zipcodes['45113'] = [39.39992, -83.985441];
Zipcodes['45115'] = [38.808172, -83.707293];
Zipcodes['45118'] = [39.172764, -83.917149];
Zipcodes['45119'] = [38.878121, -83.999247];
Zipcodes['45120'] = [38.828417, -84.090026];
Zipcodes['45121'] = [38.866852, -83.908302];
Zipcodes['45122'] = [39.225626, -84.129368];
Zipcodes['45123'] = [39.348842, -83.388803];
Zipcodes['45130'] = [38.908827, -83.998997];
Zipcodes['45131'] = [38.789761, -83.966967];
Zipcodes['45132'] = [39.343936, -83.599545];
Zipcodes['45133'] = [39.159797, -83.571065];
Zipcodes['45135'] = [39.347615, -83.541912];
Zipcodes['45140'] = [39.254473, -84.24693];
Zipcodes['45142'] = [39.207984, -83.803451];
Zipcodes['45144'] = [38.704212, -83.615092];
Zipcodes['45146'] = [39.314119, -83.804201];
Zipcodes['45147'] = [39.210768, -84.302556];
Zipcodes['45148'] = [39.290218, -83.887266];
Zipcodes['45150'] = [39.166469, -84.230391];
Zipcodes['45152'] = [39.351487, -84.120225];
Zipcodes['45153'] = [38.862812, -84.189637];
Zipcodes['45154'] = [39.063432, -83.916821];
Zipcodes['45155'] = [39.042506, -83.752161];
Zipcodes['45156'] = [38.809892, -84.213589];
Zipcodes['45157'] = [38.953739, -84.23017];
Zipcodes['45158'] = [39.182278, -84.077712];
Zipcodes['45159'] = [39.336834, -83.691742];
Zipcodes['45160'] = [39.123667, -84.136971];
Zipcodes['45162'] = [39.269737, -84.074728];
Zipcodes['45164'] = [39.553223, -83.785515];
Zipcodes['45166'] = [39.476597, -83.687759];
Zipcodes['45167'] = [38.781664, -83.789733];
Zipcodes['45168'] = [38.849817, -83.770281];
Zipcodes['45169'] = [39.506479, -83.650882];
Zipcodes['45171'] = [39.013609, -83.796419];
Zipcodes['45172'] = [39.074472, -83.386883];
Zipcodes['45174'] = [39.15856, -84.312243];
Zipcodes['45176'] = [39.079262, -84.029729];
Zipcodes['45177'] = [39.46069, -83.844305];
Zipcodes['45202'] = [39.106735, -84.504181];
Zipcodes['45203'] = [39.105294, -84.533529];
Zipcodes['45204'] = [39.089567, -84.578107];
Zipcodes['45205'] = [39.110461, -84.575085];
Zipcodes['45206'] = [39.12732, -84.484568];
Zipcodes['45207'] = [39.141955, -84.471226];
Zipcodes['45208'] = [39.13523, -84.433911];
Zipcodes['45209'] = [39.153397, -84.42757];
Zipcodes['45211'] = [39.158667, -84.599661];
Zipcodes['45212'] = [39.164019, -84.451781];
Zipcodes['45213'] = [39.181181, -84.420046];
Zipcodes['45214'] = [39.115007, -84.534356];
Zipcodes['45215'] = [39.235399, -84.462189];
Zipcodes['45216'] = [39.200543, -84.4811];
Zipcodes['45217'] = [39.166056, -84.497807];
Zipcodes['45218'] = [39.266233, -84.519763];
Zipcodes['45219'] = [39.126624, -84.512017];
Zipcodes['45220'] = [39.146693, -84.520265];
Zipcodes['45223'] = [39.170996, -84.551359];
Zipcodes['45224'] = [39.20218, -84.53224];
Zipcodes['45225'] = [39.143842, -84.555469];
Zipcodes['45226'] = [39.111687, -84.421674];
Zipcodes['45227'] = [39.153237, -84.385795];
Zipcodes['45229'] = [39.153472, -84.486186];
Zipcodes['45230'] = [39.073748, -84.389281];
Zipcodes['45231'] = [39.247501, -84.534837];
Zipcodes['45232'] = [39.185714, -84.52027];
Zipcodes['45233'] = [39.116886, -84.672637];
Zipcodes['45236'] = [39.209678, -84.397222];
Zipcodes['45237'] = [39.19458, -84.451017];
Zipcodes['45238'] = [39.109225, -84.608841];
Zipcodes['45239'] = [39.203628, -84.580624];
Zipcodes['45240'] = [39.286209, -84.525958];
Zipcodes['45241'] = [39.276293, -84.399719];
Zipcodes['45242'] = [39.242803, -84.352996];
Zipcodes['45243'] = [39.188557, -84.336021];
Zipcodes['45244'] = [39.114121, -84.326346];
Zipcodes['45245'] = [39.059884, -84.280417];
Zipcodes['45246'] = [39.290213, -84.463826];
Zipcodes['45247'] = [39.216693, -84.661108];
Zipcodes['45248'] = [39.164388, -84.662306];
Zipcodes['45249'] = [39.275264, -84.326879];
Zipcodes['45251'] = [39.27464, -84.597838];
Zipcodes['45252'] = [39.270192, -84.628137];
Zipcodes['45255'] = [39.059554, -84.328333];
Zipcodes['45267'] = [39.138086, -84.503156];
Zipcodes['45301'] = [39.710981, -84.023014];
Zipcodes['45302'] = [40.40591, -84.20915];
Zipcodes['45303'] = [40.213971, -84.659636];
Zipcodes['45304'] = [39.98145, -84.525031];
Zipcodes['45305'] = [39.640797, -84.085678];
Zipcodes['45306'] = [40.459234, -84.187139];
Zipcodes['45307'] = [39.580612, -83.72337];
Zipcodes['45308'] = [40.129279, -84.457368];
Zipcodes['45309'] = [39.842677, -84.414725];
Zipcodes['45310'] = [40.352937, -84.642673];
Zipcodes['45311'] = [39.628776, -84.658891];
Zipcodes['45312'] = [40.052155, -84.093025];
Zipcodes['45314'] = [39.742042, -83.780962];
Zipcodes['45315'] = [39.857729, -84.338773];
Zipcodes['45316'] = [39.797545, -83.825228];
Zipcodes['45317'] = [40.178273, -84.018217];
Zipcodes['45318'] = [40.127006, -84.354408];
Zipcodes['45319'] = [39.91607, -83.943293];
Zipcodes['45320'] = [39.750658, -84.681309];
Zipcodes['45321'] = [39.865253, -84.686962];
Zipcodes['45322'] = [39.903342, -84.328333];
Zipcodes['45323'] = [39.851424, -83.92798];
Zipcodes['45324'] = [39.820725, -84.00029];
Zipcodes['45325'] = [39.692609, -84.430204];
Zipcodes['45326'] = [40.13991, -84.094457];
Zipcodes['45327'] = [39.62876, -84.403005];
Zipcodes['45328'] = [40.116253, -84.496755];
Zipcodes['45330'] = [39.648272, -84.528668];
Zipcodes['45331'] = [40.098606, -84.650546];
Zipcodes['45332'] = [40.000294, -84.775986];
Zipcodes['45333'] = [40.249464, -84.331774];
Zipcodes['45334'] = [40.443193, -84.047814];
Zipcodes['45335'] = [39.639814, -83.741198];
Zipcodes['45336'] = [40.442745, -84.257263];
Zipcodes['45337'] = [39.981474, -84.4226];
Zipcodes['45338'] = [39.85093, -84.543947];
Zipcodes['45339'] = [40.008963, -84.340718];
Zipcodes['45340'] = [40.368571, -84.052152];
Zipcodes['45341'] = [39.87286, -84.022857];
Zipcodes['45342'] = [39.629338, -84.275969];
Zipcodes['45344'] = [39.953828, -84.009674];
Zipcodes['45345'] = [39.739216, -84.399306];
Zipcodes['45346'] = [39.982115, -84.709956];
Zipcodes['45347'] = [39.88593, -84.763137];
Zipcodes['45348'] = [40.329969, -84.656386];
Zipcodes['45349'] = [39.989039, -83.940579];
Zipcodes['45350'] = [40.324724, -84.573602];
Zipcodes['45351'] = [40.339553, -84.495733];
Zipcodes['45352'] = [40.05012, -84.744314];
Zipcodes['45353'] = [40.292575, -84.042717];
Zipcodes['45354'] = [39.910374, -84.399831];
Zipcodes['45356'] = [40.16284, -84.22821];
Zipcodes['45358'] = [39.987264, -84.48778];
Zipcodes['45359'] = [40.04993, -84.350787];
Zipcodes['45360'] = [40.330159, -84.092195];
Zipcodes['45361'] = [39.963712, -84.414821];
Zipcodes['45362'] = [40.292481, -84.627731];
Zipcodes['45363'] = [40.239309, -84.400067];
Zipcodes['45365'] = [40.28258, -84.155811];
Zipcodes['45368'] = [39.844915, -83.647979];
Zipcodes['45369'] = [39.952673, -83.594162];
Zipcodes['45370'] = [39.608378, -84.033025];
Zipcodes['45371'] = [39.940927, -84.166021];
Zipcodes['45372'] = [40.013912, -83.83835];
Zipcodes['45373'] = [40.033003, -84.195649];
Zipcodes['45377'] = [39.904039, -84.218097];
Zipcodes['45378'] = [39.902945, -84.487797];
Zipcodes['45380'] = [40.241975, -84.508298];
Zipcodes['45381'] = [39.730685, -84.524354];
Zipcodes['45382'] = [39.901521, -84.611919];
Zipcodes['45383'] = [39.957266, -84.333053];
Zipcodes['45384'] = [39.715548, -83.889109];
Zipcodes['45385'] = [39.669076, -83.913955];
Zipcodes['45387'] = [39.799356, -83.887616];
Zipcodes['45388'] = [40.327999, -84.48022];
Zipcodes['45389'] = [40.056317, -84.025348];
Zipcodes['45390'] = [40.215198, -84.760541];
Zipcodes['45402'] = [39.760114, -84.207627];
Zipcodes['45403'] = [39.763363, -84.149693];
Zipcodes['45404'] = [39.788496, -84.152717];
Zipcodes['45405'] = [39.79216, -84.216028];
Zipcodes['45406'] = [39.785386, -84.242215];
Zipcodes['45409'] = [39.721305, -84.18797];
Zipcodes['45410'] = [39.747587, -84.159031];
Zipcodes['45414'] = [39.839394, -84.210719];
Zipcodes['45415'] = [39.835581, -84.257387];
Zipcodes['45416'] = [39.807522, -84.2578];
Zipcodes['45417'] = [39.733066, -84.283296];
Zipcodes['45419'] = [39.714311, -84.167597];
Zipcodes['45420'] = [39.718818, -84.129169];
Zipcodes['45423'] = [39.760399, -84.191116];
Zipcodes['45424'] = [39.851322, -84.102669];
Zipcodes['45426'] = [39.80079, -84.313501];
Zipcodes['45428'] = [39.742142, -84.259544];
Zipcodes['45429'] = [39.683588, -84.162737];
Zipcodes['45430'] = [39.718073, -84.077407];
Zipcodes['45431'] = [39.763835, -84.084204];
Zipcodes['45432'] = [39.739828, -84.086034];
Zipcodes['45433'] = [39.822834, -84.048504];
Zipcodes['45434'] = [39.721385, -84.031824];
Zipcodes['45435'] = [39.781088, -84.063113];
Zipcodes['45439'] = [39.698375, -84.229497];
Zipcodes['45440'] = [39.673379, -84.099895];
Zipcodes['45449'] = [39.664147, -84.237985];
Zipcodes['45458'] = [39.602396, -84.156357];
Zipcodes['45459'] = [39.646459, -84.166955];
Zipcodes['45469'] = [39.740327, -84.17911];
Zipcodes['45501'] = [39.926336, -83.807448];
Zipcodes['45502'] = [40.001239, -83.899983];
Zipcodes['45503'] = [39.965081, -83.767539];
Zipcodes['45504'] = [39.94697, -83.864897];
Zipcodes['45505'] = [39.901667, -83.757452];
Zipcodes['45506'] = [39.90802, -83.83667];
Zipcodes['45601'] = [39.31206, -82.957071];
Zipcodes['45612'] = [39.207005, -83.278842];
Zipcodes['45613'] = [39.034216, -82.8509];
Zipcodes['45614'] = [38.929682, -82.277557];
Zipcodes['45616'] = [38.73961, -83.286593];
Zipcodes['45617'] = [39.279642, -83.155586];
Zipcodes['45618'] = [38.885073, -83.616344];
Zipcodes['45619'] = [38.462544, -82.45018];
Zipcodes['45620'] = [38.955874, -82.147719];
Zipcodes['45621'] = [39.11137, -82.609935];
Zipcodes['45622'] = [39.352281, -82.487428];
Zipcodes['45623'] = [38.641686, -82.269786];
Zipcodes['45624'] = [39.173003, -83.348687];
Zipcodes['45628'] = [39.389145, -83.1938];
Zipcodes['45629'] = [38.657569, -82.815894];
Zipcodes['45630'] = [38.697389, -83.092891];
Zipcodes['45631'] = [38.821751, -82.264305];
Zipcodes['45634'] = [39.184362, -82.486496];
Zipcodes['45636'] = [38.581698, -82.816421];
Zipcodes['45638'] = [38.5558, -82.671915];
Zipcodes['45640'] = [39.019728, -82.656314];
Zipcodes['45642'] = [39.046916, -83.054118];
Zipcodes['45644'] = [39.447656, -82.834693];
Zipcodes['45645'] = [38.566362, -82.52701];
Zipcodes['45646'] = [39.07517, -83.322786];
Zipcodes['45647'] = [39.301238, -82.732413];
Zipcodes['45648'] = [38.918087, -83.007686];
Zipcodes['45650'] = [38.738293, -83.422328];
Zipcodes['45651'] = [39.28542, -82.483478];
Zipcodes['45652'] = [38.828197, -83.079769];
Zipcodes['45653'] = [38.889635, -82.821976];
Zipcodes['45654'] = [39.366468, -82.377742];
Zipcodes['45656'] = [38.876478, -82.591255];
Zipcodes['45657'] = [38.843669, -83.236462];
Zipcodes['45658'] = [38.762928, -82.411555];
Zipcodes['45659'] = [38.654972, -82.64113];
Zipcodes['45660'] = [38.988229, -83.356545];
Zipcodes['45661'] = [39.045633, -83.10822];
Zipcodes['45662'] = [38.792267, -82.911915];
Zipcodes['45663'] = [38.736204, -83.109841];
Zipcodes['45669'] = [38.498413, -82.358442];
Zipcodes['45671'] = [38.970254, -83.248012];
Zipcodes['45672'] = [39.221241, -82.665745];
Zipcodes['45673'] = [39.203982, -82.811523];
Zipcodes['45674'] = [38.877863, -82.380851];
Zipcodes['45677'] = [38.79599, -82.755727];
Zipcodes['45678'] = [38.610443, -82.379581];
Zipcodes['45679'] = [38.977837, -83.570978];
Zipcodes['45680'] = [38.467808, -82.546829];
Zipcodes['45681'] = [39.299738, -83.257847];
Zipcodes['45682'] = [38.806931, -82.694911];
Zipcodes['45684'] = [38.651433, -83.19063];
Zipcodes['45685'] = [38.931803, -82.456541];
Zipcodes['45686'] = [39.009319, -82.367941];
Zipcodes['45688'] = [38.73108, -82.54324];
Zipcodes['45690'] = [39.145655, -83.024997];
Zipcodes['45692'] = [39.115798, -82.542036];
Zipcodes['45693'] = [38.807713, -83.532368];
Zipcodes['45694'] = [38.743875, -82.780883];
Zipcodes['45695'] = [39.161543, -82.372654];
Zipcodes['45696'] = [38.601161, -82.452107];
Zipcodes['45697'] = [38.931585, -83.673684];
Zipcodes['45698'] = [39.294292, -82.400525];
Zipcodes['45699'] = [38.874131, -82.970465];
Zipcodes['45701'] = [39.311687, -82.073928];
Zipcodes['45710'] = [39.19594, -82.211062];
Zipcodes['45711'] = [39.428321, -81.931975];
Zipcodes['45714'] = [39.316984, -81.614345];
Zipcodes['45715'] = [39.610699, -81.627327];
Zipcodes['45716'] = [39.46297, -82.184187];
Zipcodes['45719'] = [39.400194, -82.128464];
Zipcodes['45720'] = [39.093601, -81.923103];
Zipcodes['45721'] = [39.565814, -81.58343];
Zipcodes['45723'] = [39.226262, -81.823473];
Zipcodes['45724'] = [39.383437, -81.800411];
Zipcodes['45727'] = [39.645643, -81.478612];
Zipcodes['45729'] = [39.418373, -81.604397];
Zipcodes['45732'] = [39.517019, -82.064004];
Zipcodes['45734'] = [39.641144, -81.195651];
Zipcodes['45735'] = [39.245036, -81.920415];
Zipcodes['45739'] = [39.19112, -81.746416];
Zipcodes['45740'] = [39.47703, -82.080095];
Zipcodes['45741'] = [39.075065, -82.247077];
Zipcodes['45742'] = [39.287076, -81.730393];
Zipcodes['45743'] = [39.075298, -81.834846];
Zipcodes['45744'] = [39.548772, -81.493979];
Zipcodes['45745'] = [39.606169, -81.329638];
Zipcodes['45746'] = [39.597408, -81.45095];
Zipcodes['45750'] = [39.429838, -81.432131];
Zipcodes['45760'] = [39.018527, -82.129729];
Zipcodes['45761'] = [39.425792, -82.075353];
Zipcodes['45764'] = [39.442492, -82.231499];
Zipcodes['45766'] = [39.319164, -82.262797];
Zipcodes['45767'] = [39.544917, -81.13838];
Zipcodes['45768'] = [39.415575, -81.25726];
Zipcodes['45769'] = [39.109284, -82.05104];
Zipcodes['45770'] = [38.98717, -81.807365];
Zipcodes['45771'] = [38.987538, -81.902514];
Zipcodes['45772'] = [39.148706, -81.819169];
Zipcodes['45773'] = [39.437542, -81.270726];
Zipcodes['45775'] = [39.087585, -82.155962];
Zipcodes['45776'] = [39.18141, -82.024066];
Zipcodes['45778'] = [39.353532, -81.893066];
Zipcodes['45779'] = [39.001232, -81.970716];
Zipcodes['45780'] = [39.370424, -82.130958];
Zipcodes['45782'] = [39.48587, -82.08165];
Zipcodes['45783'] = [39.175076, -81.839287];
Zipcodes['45784'] = [39.388308, -81.677855];
Zipcodes['45786'] = [39.502663, -81.655053];
Zipcodes['45788'] = [39.509259, -81.378514];
Zipcodes['45789'] = [39.551276, -81.255011];
Zipcodes['45801'] = [40.772863, -84.034098];
Zipcodes['45804'] = [40.709623, -84.061153];
Zipcodes['45805'] = [40.72777, -84.162772];
Zipcodes['45806'] = [40.67244, -84.126166];
Zipcodes['45807'] = [40.805996, -84.171653];
Zipcodes['45808'] = [40.831504, -83.973358];
Zipcodes['45809'] = [40.843049, -84.185096];
Zipcodes['45810'] = [40.784394, -83.813286];
Zipcodes['45812'] = [40.681862, -83.813832];
Zipcodes['45813'] = [41.192303, -84.733158];
Zipcodes['45814'] = [40.899099, -83.604267];
Zipcodes['45815'] = [41.156945, -83.942593];
Zipcodes['45816'] = [41.004537, -83.794005];
Zipcodes['45817'] = [40.878759, -83.883975];
Zipcodes['45819'] = [40.623958, -84.260391];
Zipcodes['45820'] = [40.830753, -84.084489];
Zipcodes['45821'] = [41.225764, -84.560946];
Zipcodes['45822'] = [40.555729, -84.598627];
Zipcodes['45826'] = [40.436217, -84.493693];
Zipcodes['45827'] = [40.998103, -84.298851];
Zipcodes['45828'] = [40.483642, -84.682889];
Zipcodes['45830'] = [40.90846, -84.095933];
Zipcodes['45831'] = [41.11376, -84.254987];
Zipcodes['45832'] = [40.926421, -84.730092];
Zipcodes['45833'] = [40.832782, -84.352902];
Zipcodes['45835'] = [40.754415, -83.699893];
Zipcodes['45836'] = [40.793407, -83.643202];
Zipcodes['45838'] = [40.740406, -84.477816];
Zipcodes['45840'] = [41.025894, -83.648894];
Zipcodes['45841'] = [40.881236, -83.731999];
Zipcodes['45843'] = [40.786339, -83.546244];
Zipcodes['45844'] = [40.913832, -84.287383];
Zipcodes['45845'] = [40.331338, -84.382544];
Zipcodes['45846'] = [40.412021, -84.74813];
Zipcodes['45848'] = [41.024493, -84.077088];
Zipcodes['45849'] = [41.013772, -84.446662];
Zipcodes['45850'] = [40.704713, -83.918297];
Zipcodes['45851'] = [41.032676, -84.602883];
Zipcodes['45853'] = [40.986494, -84.195186];
Zipcodes['45854'] = [40.760105, -83.952122];
Zipcodes['45855'] = [41.088192, -84.58349];
Zipcodes['45856'] = [41.111757, -83.999825];
Zipcodes['45858'] = [41.107169, -83.80211];
Zipcodes['45859'] = [40.693054, -83.785735];
Zipcodes['45860'] = [40.40033, -84.517378];
Zipcodes['45861'] = [41.085878, -84.425651];
Zipcodes['45862'] = [40.670523, -84.515236];
Zipcodes['45863'] = [40.910754, -84.452522];
Zipcodes['45864'] = [41.100214, -84.136736];
Zipcodes['45865'] = [40.394258, -84.372922];
Zipcodes['45866'] = [40.48878, -84.550493];
Zipcodes['45867'] = [40.895538, -83.538416];
Zipcodes['45868'] = [40.97097, -83.851238];
Zipcodes['45869'] = [40.456841, -84.390491];
Zipcodes['45870'] = [40.556255, -83.951444];
Zipcodes['45871'] = [40.489115, -84.294025];
Zipcodes['45872'] = [41.189167, -83.682423];
Zipcodes['45873'] = [41.117833, -84.390235];
Zipcodes['45874'] = [40.787757, -84.681229];
Zipcodes['45875'] = [41.01966, -84.058602];
Zipcodes['45876'] = [40.934176, -84.338664];
Zipcodes['45877'] = [40.959121, -83.933578];
Zipcodes['45879'] = [41.128322, -84.563105];
Zipcodes['45880'] = [41.067867, -84.741558];
Zipcodes['45881'] = [40.946247, -83.780047];
Zipcodes['45882'] = [40.683429, -84.677293];
Zipcodes['45883'] = [40.399494, -84.619593];
Zipcodes['45884'] = [40.546709, -84.084183];
Zipcodes['45885'] = [40.569863, -84.390644];
Zipcodes['45886'] = [40.9969, -84.620781];
Zipcodes['45887'] = [40.710853, -84.365128];
Zipcodes['45888'] = [40.602322, -84.085692];
Zipcodes['45889'] = [41.152611, -83.638601];
Zipcodes['45890'] = [40.979927, -83.494768];
Zipcodes['45891'] = [40.873982, -84.577107];
Zipcodes['45893'] = [40.881452, -84.145379];
Zipcodes['45894'] = [40.759426, -84.471117];
Zipcodes['45895'] = [40.563033, -84.145293];
Zipcodes['45896'] = [40.607869, -83.942108];
Zipcodes['45897'] = [40.84148, -83.656145];
Zipcodes['45898'] = [40.733947, -84.751052];
Zipcodes['45899'] = [40.802675, -84.772307];
Zipcodes['46001'] = [40.25141, -85.65806];
Zipcodes['46011'] = [40.130099, -85.760763];
Zipcodes['46012'] = [40.146095, -85.623061];
Zipcodes['46013'] = [40.051228, -85.685737];
Zipcodes['46016'] = [40.097821, -85.681485];
Zipcodes['46017'] = [40.069281, -85.607157];
Zipcodes['46030'] = [40.169794, -86.020635];
Zipcodes['46031'] = [40.203688, -86.009558];
Zipcodes['46032'] = [39.966569, -86.175131];
Zipcodes['46033'] = [39.979283, -86.085557];
Zipcodes['46034'] = [40.135385, -86.048472];
Zipcodes['46035'] = [40.18922, -86.679018];
Zipcodes['46036'] = [40.296875, -85.832902];
Zipcodes['46037'] = [39.962809, -85.943916];
Zipcodes['46038'] = [39.966704, -86.017173];
Zipcodes['46039'] = [40.370181, -86.311698];
Zipcodes['46040'] = [39.927284, -85.834273];
Zipcodes['46041'] = [40.295035, -86.493864];
Zipcodes['46044'] = [40.211777, -85.792705];
Zipcodes['46045'] = [40.28961, -86.148659];
Zipcodes['46047'] = [40.284181, -85.947381];
Zipcodes['46048'] = [39.954255, -85.807463];
Zipcodes['46049'] = [40.295013, -86.22179];
Zipcodes['46050'] = [40.205165, -86.346298];
Zipcodes['46051'] = [40.058556, -85.837927];
Zipcodes['46052'] = [40.044295, -86.460975];
Zipcodes['46055'] = [39.888415, -85.90221];
Zipcodes['46056'] = [39.97271, -85.606902];
Zipcodes['46057'] = [40.337951, -86.378006];
Zipcodes['46058'] = [40.359824, -86.647782];
Zipcodes['46060'] = [40.062575, -85.933198];
Zipcodes['46062'] = [40.06129, -86.055942];
Zipcodes['46063'] = [40.271794, -85.723961];
Zipcodes['46064'] = [39.981682, -85.738762];
Zipcodes['46065'] = [40.42133, -86.61002];
Zipcodes['46068'] = [40.375106, -86.11124];
Zipcodes['46069'] = [40.138748, -86.230316];
Zipcodes['46070'] = [40.346074, -85.66086];
Zipcodes['46071'] = [40.11046, -86.608042];
Zipcodes['46072'] = [40.28526, -86.068828];
Zipcodes['46074'] = [40.036077, -86.173657];
Zipcodes['46075'] = [40.020696, -86.3308];
Zipcodes['46076'] = [40.370669, -85.946072];
Zipcodes['46077'] = [39.98189, -86.278939];
Zipcodes['46102'] = [39.995552, -86.619922];
Zipcodes['46103'] = [39.689553, -86.61383];
Zipcodes['46104'] = [39.65847, -85.60183];
Zipcodes['46105'] = [39.764806, -86.813484];
Zipcodes['46106'] = [39.513173, -86.205119];
Zipcodes['46107'] = [39.716623, -86.091616];
Zipcodes['46110'] = [39.559379, -85.912843];
Zipcodes['46111'] = [39.541991, -86.367394];
Zipcodes['46112'] = [39.86391, -86.382425];
Zipcodes['46113'] = [39.636299, -86.302553];
Zipcodes['46115'] = [39.737677, -85.566877];
Zipcodes['46117'] = [39.814706, -85.615963];
Zipcodes['46118'] = [39.658691, -86.531342];
Zipcodes['46120'] = [39.511408, -86.789098];
Zipcodes['46121'] = [39.679042, -86.678783];
Zipcodes['46122'] = [39.773312, -86.551134];
Zipcodes['46123'] = [39.764437, -86.403388];
Zipcodes['46124'] = [39.371013, -85.947876];
Zipcodes['46125'] = [39.521866, -86.641816];
Zipcodes['46126'] = [39.618024, -85.88034];
Zipcodes['46127'] = [39.717665, -85.31583];
Zipcodes['46128'] = [39.658583, -86.742562];
Zipcodes['46130'] = [39.672748, -85.835022];
Zipcodes['46131'] = [39.473489, -86.043592];
Zipcodes['46133'] = [39.595055, -85.310832];
Zipcodes['46135'] = [39.654571, -86.893996];
Zipcodes['46140'] = [39.801973, -85.772071];
Zipcodes['46142'] = [39.62181, -86.175319];
Zipcodes['46143'] = [39.595658, -86.113954];
Zipcodes['46144'] = [39.665814, -85.647072];
Zipcodes['46146'] = [39.579642, -85.569555];
Zipcodes['46147'] = [39.971149, -86.622827];
Zipcodes['46148'] = [39.77989, -85.475163];
Zipcodes['46149'] = [39.883138, -86.553506];
Zipcodes['46150'] = [39.545464, -85.602908];
Zipcodes['46151'] = [39.46049, -86.418365];
Zipcodes['46154'] = [39.856378, -85.770398];
Zipcodes['46155'] = [39.743565, -85.429499];
Zipcodes['46156'] = [39.489361, -85.49445];
Zipcodes['46157'] = [39.55363, -86.528731];
Zipcodes['46158'] = [39.579356, -86.373602];
Zipcodes['46160'] = [39.351134, -86.268692];
Zipcodes['46161'] = [39.668477, -85.696892];
Zipcodes['46162'] = [39.555779, -85.953942];
Zipcodes['46163'] = [39.728582, -85.906051];
Zipcodes['46164'] = [39.302574, -86.121576];
Zipcodes['46165'] = [39.849817, -86.64015];
Zipcodes['46166'] = [39.4269, -86.590326];
Zipcodes['46167'] = [39.876973, -86.467801];
Zipcodes['46168'] = [39.68999, -86.392709];
Zipcodes['46170'] = [39.571624, -86.862651];
Zipcodes['46171'] = [39.525101, -86.960902];
Zipcodes['46172'] = [39.827835, -86.818464];
Zipcodes['46173'] = [39.600822, -85.4309];
Zipcodes['46175'] = [39.815724, -86.966359];
Zipcodes['46176'] = [39.526531, -85.773369];
Zipcodes['46180'] = [39.592189, -86.618525];
Zipcodes['46181'] = [39.372155, -86.171601];
Zipcodes['46182'] = [39.458578, -85.669424];
Zipcodes['46183'] = [39.653964, -86.28129];
Zipcodes['46184'] = [39.558123, -86.066946];
Zipcodes['46186'] = [39.894213, -85.644782];
Zipcodes['46201'] = [39.773669, -86.10956];
Zipcodes['46202'] = [39.7841, -86.161616];
Zipcodes['46203'] = [39.736808, -86.095848];
Zipcodes['46204'] = [39.770593, -86.157054];
Zipcodes['46205'] = [39.829437, -86.134381];
Zipcodes['46206'] = [39.760267, -86.161071];
Zipcodes['46208'] = [39.819071, -86.171083];
Zipcodes['46214'] = [39.792817, -86.291528];
Zipcodes['46216'] = [39.865958, -86.009333];
Zipcodes['46217'] = [39.674631, -86.191451];
Zipcodes['46218'] = [39.80713, -86.099554];
Zipcodes['46219'] = [39.78393, -86.044636];
Zipcodes['46220'] = [39.867114, -86.108848];
Zipcodes['46221'] = [39.695987, -86.238728];
Zipcodes['46222'] = [39.790968, -86.21526];
Zipcodes['46224'] = [39.795413, -86.25689];
Zipcodes['46225'] = [39.740314, -86.163255];
Zipcodes['46226'] = [39.839211, -86.048552];
Zipcodes['46227'] = [39.674763, -86.132676];
Zipcodes['46228'] = [39.848228, -86.200725];
Zipcodes['46229'] = [39.788237, -85.977015];
Zipcodes['46231'] = [39.723464, -86.330553];
Zipcodes['46234'] = [39.810574, -86.326281];
Zipcodes['46235'] = [39.836957, -85.974537];
Zipcodes['46236'] = [39.896639, -85.968156];
Zipcodes['46237'] = [39.67189, -86.074788];
Zipcodes['46239'] = [39.721517, -85.999033];
Zipcodes['46240'] = [39.906045, -86.124635];
Zipcodes['46241'] = [39.730422, -86.277774];
Zipcodes['46250'] = [39.900812, -86.063376];
Zipcodes['46254'] = [39.848326, -86.269687];
Zipcodes['46256'] = [39.908383, -86.013138];
Zipcodes['46259'] = [39.650921, -85.981462];
Zipcodes['46260'] = [39.896902, -86.178492];
Zipcodes['46268'] = [39.897442, -86.233327];
Zipcodes['46278'] = [39.892944, -86.298055];
Zipcodes['46280'] = [39.933838, -86.1352];
Zipcodes['46290'] = [39.93453, -86.161858];
Zipcodes['46301'] = [41.691837, -86.973274];
Zipcodes['46302'] = [41.364467, -87.123692];
Zipcodes['46303'] = [41.373627, -87.479284];
Zipcodes['46304'] = [41.614501, -87.050374];
Zipcodes['46307'] = [41.40206, -87.325371];
Zipcodes['46310'] = [41.179926, -87.2308];
Zipcodes['46311'] = [41.468381, -87.509184];
Zipcodes['46312'] = [41.650459, -87.451123];
Zipcodes['46319'] = [41.521837, -87.421897];
Zipcodes['46320'] = [41.608922, -87.497554];
Zipcodes['46321'] = [41.54751, -87.503268];
Zipcodes['46322'] = [41.546622, -87.457616];
Zipcodes['46323'] = [41.589434, -87.453729];
Zipcodes['46324'] = [41.583791, -87.501995];
Zipcodes['46327'] = [41.648591, -87.499255];
Zipcodes['46340'] = [41.382571, -86.770664];
Zipcodes['46341'] = [41.309672, -87.214561];
Zipcodes['46342'] = [41.518185, -87.244908];
Zipcodes['46345'] = [41.53036, -86.699931];
Zipcodes['46346'] = [41.478173, -86.69332];
Zipcodes['46347'] = [41.305001, -87.008573];
Zipcodes['46348'] = [41.309823, -86.862422];
Zipcodes['46349'] = [41.11198, -87.424918];
Zipcodes['46350'] = [41.607057, -86.721724];
Zipcodes['46356'] = [41.263745, -87.417152];
Zipcodes['46360'] = [41.689234, -86.86512];
Zipcodes['46365'] = [41.602287, -86.544223];
Zipcodes['46366'] = [41.21364, -86.768018];
Zipcodes['46368'] = [41.591612, -87.178939];
Zipcodes['46371'] = [41.678628, -86.593031];
Zipcodes['46373'] = [41.441816, -87.467779];
Zipcodes['46374'] = [41.205382, -86.899462];
Zipcodes['46375'] = [41.491453, -87.452867];
Zipcodes['46376'] = [41.184667, -87.47582];
Zipcodes['46377'] = [41.194282, -87.347795];
Zipcodes['46379'] = [41.156817, -87.430166];
Zipcodes['46381'] = [41.176323, -87.33446];
Zipcodes['46382'] = [41.461306, -86.761986];
Zipcodes['46383'] = [41.457895, -87.002008];
Zipcodes['46385'] = [41.464389, -87.124112];
Zipcodes['46390'] = [41.419466, -86.881518];
Zipcodes['46391'] = [41.554106, -86.918985];
Zipcodes['46392'] = [41.178499, -87.054975];
Zipcodes['46393'] = [41.513891, -87.168001];
Zipcodes['46394'] = [41.682008, -87.499922];
Zipcodes['46402'] = [41.61783, -87.337827];
Zipcodes['46403'] = [41.610527, -87.258152];
Zipcodes['46404'] = [41.584948, -87.374471];
Zipcodes['46405'] = [41.573828, -87.263247];
Zipcodes['46406'] = [41.606134, -87.408651];
Zipcodes['46407'] = [41.578413, -87.330432];
Zipcodes['46408'] = [41.545938, -87.368484];
Zipcodes['46409'] = [41.548258, -87.32423];
Zipcodes['46410'] = [41.482028, -87.334679];
Zipcodes['46501'] = [41.221645, -86.245803];
Zipcodes['46502'] = [41.261016, -85.976594];
Zipcodes['46504'] = [41.301217, -86.111595];
Zipcodes['46506'] = [41.462622, -86.1724];
Zipcodes['46507'] = [41.721042, -85.815643];
Zipcodes['46508'] = [41.155122, -85.969414];
Zipcodes['46510'] = [41.121661, -85.870707];
Zipcodes['46511'] = [41.220729, -86.428425];
Zipcodes['46513'] = [41.362876, -86.448101];
Zipcodes['46514'] = [41.722679, -85.974999];
Zipcodes['46516'] = [41.678907, -85.947966];
Zipcodes['46517'] = [41.62729, -85.997855];
Zipcodes['46524'] = [41.305641, -86.014116];
Zipcodes['46526'] = [41.558787, -85.87665];
Zipcodes['46528'] = [41.600525, -85.784864];
Zipcodes['46530'] = [41.740931, -86.126918];
Zipcodes['46531'] = [41.356985, -86.519382];
Zipcodes['46532'] = [41.411266, -86.630511];
Zipcodes['46534'] = [41.285345, -86.617818];
Zipcodes['46536'] = [41.514551, -86.298011];
Zipcodes['46537'] = [41.457313, -86.306584];
Zipcodes['46538'] = [41.330237, -85.823415];
Zipcodes['46539'] = [41.163149, -86.026511];
Zipcodes['46540'] = [41.671519, -85.696675];
Zipcodes['46542'] = [41.396628, -85.869691];
Zipcodes['46543'] = [41.533403, -85.671198];
Zipcodes['46544'] = [41.615165, -86.128266];
Zipcodes['46545'] = [41.693491, -86.146569];
Zipcodes['46550'] = [41.446944, -86.000648];
Zipcodes['46552'] = [41.706297, -86.481901];
Zipcodes['46553'] = [41.480038, -85.850932];
Zipcodes['46554'] = [41.557413, -86.416992];
Zipcodes['46555'] = [41.329174, -85.680113];
Zipcodes['46556'] = [41.701939, -86.244835];
Zipcodes['46561'] = [41.665758, -86.071515];
Zipcodes['46562'] = [41.208342, -85.684902];
Zipcodes['46563'] = [41.355358, -86.325957];
Zipcodes['46565'] = [41.691206, -85.583664];
Zipcodes['46567'] = [41.415775, -85.732336];
Zipcodes['46570'] = [41.207976, -86.120079];
Zipcodes['46571'] = [41.565539, -85.547025];
Zipcodes['46572'] = [41.40806, -86.393087];
Zipcodes['46573'] = [41.544364, -86.069668];
Zipcodes['46574'] = [41.481969, -86.486555];
Zipcodes['46580'] = [41.213832, -85.869547];
Zipcodes['46582'] = [41.279693, -85.851626];
Zipcodes['46590'] = [41.219142, -85.804586];
Zipcodes['46595'] = [41.526314, -86.163421];
Zipcodes['46601'] = [41.670274, -86.252661];
Zipcodes['46613'] = [41.65355, -86.266326];
Zipcodes['46614'] = [41.605661, -86.279601];
Zipcodes['46615'] = [41.673953, -86.21171];
Zipcodes['46616'] = [41.696914, -86.267];
Zipcodes['46617'] = [41.684553, -86.234758];
Zipcodes['46619'] = [41.660942, -86.353751];
Zipcodes['46624'] = [41.702511, -86.251633];
Zipcodes['46628'] = [41.720236, -86.32023];
Zipcodes['46635'] = [41.714357, -86.201743];
Zipcodes['46637'] = [41.733712, -86.242693];
Zipcodes['46701'] = [41.360791, -85.431238];
Zipcodes['46702'] = [40.820515, -85.622057];
Zipcodes['46703'] = [41.65494, -85.011464];
Zipcodes['46704'] = [41.10366, -85.292142];
Zipcodes['46705'] = [41.51869, -85.059603];
Zipcodes['46706'] = [41.341594, -85.032655];
Zipcodes['46710'] = [41.352187, -85.250487];
Zipcodes['46711'] = [40.661951, -84.926879];
Zipcodes['46714'] = [40.722652, -85.176526];
Zipcodes['46721'] = [41.423987, -84.872773];
Zipcodes['46723'] = [41.245823, -85.326752];
Zipcodes['46725'] = [41.153386, -85.47415];
Zipcodes['46730'] = [41.458442, -85.151597];
Zipcodes['46731'] = [40.793538, -85.099634];
Zipcodes['46732'] = [41.371989, -85.625681];
Zipcodes['46733'] = [40.83052, -84.939705];
Zipcodes['46737'] = [41.717155, -84.905186];
Zipcodes['46738'] = [41.318908, -85.148865];
Zipcodes['46740'] = [40.60428, -84.964762];
Zipcodes['46741'] = [41.216995, -84.951172];
Zipcodes['46742'] = [41.548594, -84.879092];
Zipcodes['46743'] = [41.215106, -84.853231];
Zipcodes['46745'] = [40.951938, -85.008819];
Zipcodes['46746'] = [41.725354, -85.344932];
Zipcodes['46747'] = [41.565693, -85.158031];
Zipcodes['46748'] = [41.248585, -85.161149];
Zipcodes['46750'] = [40.876437, -85.497441];
Zipcodes['46755'] = [41.449284, -85.275125];
Zipcodes['46759'] = [40.603927, -85.178498];
Zipcodes['46760'] = [41.345491, -85.552692];
Zipcodes['46761'] = [41.634364, -85.377512];
Zipcodes['46763'] = [41.297037, -85.249074];
Zipcodes['46764'] = [41.214362, -85.620243];
Zipcodes['46765'] = [41.233142, -85.0323];
Zipcodes['46766'] = [40.709035, -85.289416];
Zipcodes['46767'] = [41.46153, -85.574108];
Zipcodes['46770'] = [40.827045, -85.320637];
Zipcodes['46771'] = [41.694627, -85.296924];
Zipcodes['46772'] = [40.722143, -84.911604];
Zipcodes['46773'] = [40.984005, -84.875874];
Zipcodes['46774'] = [41.089207, -84.972367];
Zipcodes['46776'] = [41.728656, -85.166323];
Zipcodes['46777'] = [40.869136, -85.147577];
Zipcodes['46778'] = [40.603758, -85.162194];
Zipcodes['46779'] = [41.563988, -85.027468];
Zipcodes['46781'] = [40.63238, -85.26889];
Zipcodes['46783'] = [40.969261, -85.357314];
Zipcodes['46784'] = [41.500464, -85.388423];
Zipcodes['46785'] = [41.323197, -84.877756];
Zipcodes['46786'] = [41.531483, -85.272397];
Zipcodes['46787'] = [41.066251, -85.619724];
Zipcodes['46788'] = [41.272928, -84.908026];
Zipcodes['46789'] = [41.582671, -85.197403];
Zipcodes['46791'] = [40.83773, -85.251592];
Zipcodes['46792'] = [40.684316, -85.44333];
Zipcodes['46793'] = [41.452285, -85.01529];
Zipcodes['46794'] = [41.46219, -85.452201];
Zipcodes['46795'] = [41.558909, -85.327355];
Zipcodes['46796'] = [41.336656, -85.495795];
Zipcodes['46797'] = [41.128386, -84.858985];
Zipcodes['46798'] = [40.942551, -85.221115];
Zipcodes['46799'] = [40.915851, -85.279882];
Zipcodes['46802'] = [41.069304, -85.16516];
Zipcodes['46803'] = [41.07031, -85.086136];
Zipcodes['46804'] = [41.054212, -85.239056];
Zipcodes['46805'] = [41.10011, -85.117711];
Zipcodes['46806'] = [41.046472, -85.092193];
Zipcodes['46807'] = [41.044892, -85.147574];
Zipcodes['46808'] = [41.09775, -85.177129];
Zipcodes['46809'] = [40.999664, -85.218263];
Zipcodes['46814'] = [41.048682, -85.305902];
Zipcodes['46815'] = [41.10277, -85.058719];
Zipcodes['46816'] = [41.002832, -85.045897];
Zipcodes['46818'] = [41.142084, -85.25788];
Zipcodes['46819'] = [40.974587, -85.133593];
Zipcodes['46825'] = [41.15698, -85.128322];
Zipcodes['46835'] = [41.152961, -85.040749];
Zipcodes['46845'] = [41.212012, -85.108798];
Zipcodes['46901'] = [40.531266, -86.169537];
Zipcodes['46902'] = [40.437275, -86.094553];
Zipcodes['46910'] = [41.04384, -86.036345];
Zipcodes['46911'] = [40.62522, -85.942622];
Zipcodes['46913'] = [40.503634, -86.497425];
Zipcodes['46914'] = [40.628598, -86.094769];
Zipcodes['46915'] = [40.478325, -86.386373];
Zipcodes['46917'] = [40.626869, -86.475047];
Zipcodes['46919'] = [40.594162, -85.869637];
Zipcodes['46920'] = [40.464596, -86.495047];
Zipcodes['46922'] = [41.140995, -86.425631];
Zipcodes['46923'] = [40.607263, -86.642267];
Zipcodes['46926'] = [40.887938, -86.049986];
Zipcodes['46928'] = [40.405898, -85.682305];
Zipcodes['46929'] = [40.554748, -86.476387];
Zipcodes['46930'] = [40.40946, -85.572582];
Zipcodes['46931'] = [40.946907, -86.264552];
Zipcodes['46932'] = [40.595389, -86.268582];
Zipcodes['46933'] = [40.478849, -85.580823];
Zipcodes['46936'] = [40.484302, -85.927782];
Zipcodes['46937'] = [40.415348, -86.03693];
Zipcodes['46938'] = [40.430291, -85.593678];
Zipcodes['46939'] = [40.992876, -86.400545];
Zipcodes['46940'] = [40.690906, -85.689231];
Zipcodes['46941'] = [40.830123, -85.698576];
Zipcodes['46943'] = [40.972585, -85.839988];
Zipcodes['46945'] = [41.121096, -86.38516];
Zipcodes['46946'] = [41.039275, -85.730239];
Zipcodes['46947'] = [40.760974, -86.373099];
Zipcodes['46950'] = [40.888204, -86.364948];
Zipcodes['46951'] = [40.947573, -86.105392];
Zipcodes['46952'] = [40.599427, -85.629225];
Zipcodes['46953'] = [40.519407, -85.645053];
Zipcodes['46957'] = [40.388655, -85.499087];
Zipcodes['46958'] = [40.818129, -86.121097];
Zipcodes['46959'] = [40.612752, -86.112292];
Zipcodes['46960'] = [41.161035, -86.516056];
Zipcodes['46961'] = [40.767047, -86.192369];
Zipcodes['46962'] = [40.988512, -85.768369];
Zipcodes['46965'] = [40.415994, -86.101826];
Zipcodes['46967'] = [40.695045, -86.194834];
Zipcodes['46968'] = [41.170477, -86.551835];
Zipcodes['46970'] = [40.757804, -86.061165];
Zipcodes['46971'] = [40.66055, -86.161735];
Zipcodes['46974'] = [40.951908, -85.933516];
Zipcodes['46975'] = [41.075008, -86.251456];
Zipcodes['46978'] = [40.858704, -86.511134];
Zipcodes['46979'] = [40.429495, -86.275875];
Zipcodes['46980'] = [40.955958, -85.741521];
Zipcodes['46982'] = [41.051918, -85.877861];
Zipcodes['46984'] = [40.667737, -85.829789];
Zipcodes['46985'] = [40.959504, -86.580473];
Zipcodes['46986'] = [40.4948, -85.819273];
Zipcodes['46987'] = [40.568813, -85.765358];
Zipcodes['46988'] = [40.877117, -86.234743];
Zipcodes['46989'] = [40.448635, -85.481366];
Zipcodes['46990'] = [40.902353, -85.727646];
Zipcodes['46991'] = [40.628038, -85.51421];
Zipcodes['46992'] = [40.784517, -85.830509];
Zipcodes['46994'] = [40.667314, -86.254498];
Zipcodes['46996'] = [41.062011, -86.657974];
Zipcodes['46998'] = [40.575463, -86.350821];
Zipcodes['47001'] = [39.056506, -84.956061];
Zipcodes['47003'] = [39.559685, -84.834261];
Zipcodes['47006'] = [39.285873, -85.212713];
Zipcodes['47010'] = [39.501581, -84.848717];
Zipcodes['47011'] = [38.871078, -85.061603];
Zipcodes['47012'] = [39.427299, -84.983489];
Zipcodes['47016'] = [39.372219, -84.890262];
Zipcodes['47017'] = [38.940482, -85.181402];
Zipcodes['47018'] = [38.991857, -85.10417];
Zipcodes['47020'] = [38.817577, -84.923626];
Zipcodes['47021'] = [38.970151, -85.148555];
Zipcodes['47022'] = [39.207187, -84.950897];
Zipcodes['47023'] = [39.071078, -85.37685];
Zipcodes['47024'] = [39.476872, -85.199038];
Zipcodes['47025'] = [39.153346, -84.874001];
Zipcodes['47030'] = [39.424202, -85.132571];
Zipcodes['47031'] = [39.130564, -85.148398];
Zipcodes['47032'] = [39.080579, -85.055953];
Zipcodes['47033'] = [39.282202, -85.175385];
Zipcodes['47034'] = [39.204329, -85.327328];
Zipcodes['47035'] = [39.310128, -84.904094];
Zipcodes['47036'] = [39.385911, -85.230916];
Zipcodes['47037'] = [39.169482, -85.325656];
Zipcodes['47038'] = [38.850307, -84.849937];
Zipcodes['47040'] = [38.928447, -84.911343];
Zipcodes['47041'] = [39.234052, -85.083768];
Zipcodes['47042'] = [39.01944, -85.255113];
Zipcodes['47043'] = [38.792725, -85.103311];
Zipcodes['47060'] = [39.29202, -84.867911];
Zipcodes['47102'] = [38.755251, -85.797448];
Zipcodes['47104'] = [38.555878, -85.424338];
Zipcodes['47106'] = [38.4643, -85.89985];
Zipcodes['47108'] = [38.646188, -86.256953];
Zipcodes['47110'] = [38.095403, -86.207714];
Zipcodes['47111'] = [38.466285, -85.632485];
Zipcodes['47112'] = [38.186146, -86.145426];
Zipcodes['47114'] = [38.287515, -86.073418];
Zipcodes['47115'] = [38.344539, -86.216765];
Zipcodes['47116'] = [38.326573, -86.618248];
Zipcodes['47117'] = [38.106616, -85.967376];
Zipcodes['47118'] = [38.300022, -86.483135];
Zipcodes['47119'] = [38.371949, -85.881518];
Zipcodes['47120'] = [38.460364, -86.18872];
Zipcodes['47122'] = [38.30596, -85.984844];
Zipcodes['47123'] = [38.285576, -86.471806];
Zipcodes['47124'] = [38.365131, -86.004354];
Zipcodes['47125'] = [38.461689, -86.303872];
Zipcodes['47126'] = [38.547252, -85.776209];
Zipcodes['47129'] = [38.31313, -85.7686];
Zipcodes['47130'] = [38.332415, -85.696087];
Zipcodes['47135'] = [38.048816, -86.074351];
Zipcodes['47136'] = [38.234699, -85.965569];
Zipcodes['47137'] = [38.152529, -86.433713];
Zipcodes['47138'] = [38.682475, -85.584686];
Zipcodes['47140'] = [38.384299, -86.370695];
Zipcodes['47141'] = [38.547956, -85.612115];
Zipcodes['47142'] = [38.061628, -86.201419];
Zipcodes['47143'] = [38.468546, -85.775129];
Zipcodes['47145'] = [38.329877, -86.306583];
Zipcodes['47147'] = [38.599478, -85.533837];
Zipcodes['47150'] = [38.283804, -85.847721];
Zipcodes['47160'] = [38.157188, -86.05004];
Zipcodes['47161'] = [38.321795, -86.103592];
Zipcodes['47162'] = [38.548787, -85.491397];
Zipcodes['47163'] = [38.545232, -85.679349];
Zipcodes['47164'] = [38.413173, -86.095789];
Zipcodes['47165'] = [38.502214, -86.004786];
Zipcodes['47166'] = [38.306669, -86.169004];
Zipcodes['47167'] = [38.60699, -86.093464];
Zipcodes['47170'] = [38.685201, -85.844056];
Zipcodes['47172'] = [38.399505, -85.774266];
Zipcodes['47174'] = [38.227151, -86.481972];
Zipcodes['47175'] = [38.358221, -86.55472];
Zipcodes['47177'] = [38.589732, -85.79176];
Zipcodes['47201'] = [39.148221, -85.999407];
Zipcodes['47203'] = [39.230643, -85.83278];
Zipcodes['47220'] = [38.87789, -86.05498];
Zipcodes['47223'] = [39.055653, -85.479271];
Zipcodes['47224'] = [38.89044, -85.227992];
Zipcodes['47225'] = [39.435508, -85.344466];
Zipcodes['47226'] = [39.282638, -85.869051];
Zipcodes['47227'] = [38.868276, -85.655467];
Zipcodes['47229'] = [38.807113, -85.86484];
Zipcodes['47230'] = [38.79238, -85.625116];
Zipcodes['47231'] = [38.900912, -85.494614];
Zipcodes['47232'] = [39.110924, -85.790836];
Zipcodes['47234'] = [39.376089, -85.76842];
Zipcodes['47235'] = [39.01804, -86.138281];
Zipcodes['47236'] = [39.151743, -85.725277];
Zipcodes['47240'] = [39.306027, -85.478417];
Zipcodes['47243'] = [38.668701, -85.482235];
Zipcodes['47244'] = [39.217948, -85.70237];
Zipcodes['47246'] = [39.298183, -85.76413];
Zipcodes['47247'] = [39.05972, -85.88785];
Zipcodes['47250'] = [38.811483, -85.362137];
Zipcodes['47260'] = [38.836742, -86.197754];
Zipcodes['47263'] = [39.308848, -85.328478];
Zipcodes['47264'] = [38.979034, -86.250743];
Zipcodes['47265'] = [39.008555, -85.627378];
Zipcodes['47270'] = [38.839337, -85.722862];
Zipcodes['47272'] = [39.411013, -85.621407];
Zipcodes['47273'] = [39.07579, -85.736847];
Zipcodes['47274'] = [38.966218, -85.925728];
Zipcodes['47280'] = [39.296573, -85.950476];
Zipcodes['47281'] = [38.788527, -86.106342];
Zipcodes['47282'] = [38.985368, -85.610034];
Zipcodes['47283'] = [39.170024, -85.587896];
Zipcodes['47302'] = [40.129514, -85.37402];
Zipcodes['47303'] = [40.271643, -85.377772];
Zipcodes['47304'] = [40.236332, -85.459814];
Zipcodes['47305'] = [40.194099, -85.385939];
Zipcodes['47306'] = [40.20677, -85.407238];
Zipcodes['47320'] = [40.271703, -85.272799];
Zipcodes['47324'] = [39.741178, -84.851721];
Zipcodes['47325'] = [39.693573, -85.012798];
Zipcodes['47326'] = [40.54868, -84.967266];
Zipcodes['47327'] = [39.833542, -85.17883];
Zipcodes['47330'] = [39.786249, -85.022256];
Zipcodes['47331'] = [39.624084, -85.157338];
Zipcodes['47334'] = [40.10622, -85.523477];
Zipcodes['47335'] = [39.811527, -85.206596];
Zipcodes['47336'] = [40.397998, -85.212031];
Zipcodes['47337'] = [39.802754, -85.437285];
Zipcodes['47338'] = [40.342416, -85.337425];
Zipcodes['47339'] = [39.938777, -85.079129];
Zipcodes['47340'] = [40.180757, -85.133663];
Zipcodes['47341'] = [39.97232, -84.891734];
Zipcodes['47342'] = [40.32858, -85.507983];
Zipcodes['47344'] = [39.878548, -85.463972];
Zipcodes['47345'] = [39.893863, -85.06018];
Zipcodes['47346'] = [39.931638, -85.168192];
Zipcodes['47348'] = [40.466214, -85.340374];
Zipcodes['47351'] = [39.904488, -85.520037];
Zipcodes['47352'] = [39.795724, -85.367059];
Zipcodes['47353'] = [39.623094, -84.925145];
Zipcodes['47354'] = [40.040842, -85.192539];
Zipcodes['47355'] = [40.044174, -84.927052];
Zipcodes['47356'] = [40.031458, -85.507425];
Zipcodes['47357'] = [39.751359, -85.146917];
Zipcodes['47358'] = [40.057341, -85.112956];
Zipcodes['47359'] = [40.552162, -85.286474];
Zipcodes['47360'] = [40.011265, -85.258203];
Zipcodes['47361'] = [40.004184, -85.384931];
Zipcodes['47362'] = [39.932041, -85.369568];
Zipcodes['47366'] = [39.861237, -85.260631];
Zipcodes['47367'] = [40.083663, -85.392067];
Zipcodes['47368'] = [40.182196, -85.194189];
Zipcodes['47369'] = [40.507344, -85.142062];
Zipcodes['47370'] = [39.812813, -85.137775];
Zipcodes['47371'] = [40.419044, -84.962812];
Zipcodes['47373'] = [40.339583, -85.155806];
Zipcodes['47374'] = [39.833762, -84.889666];
Zipcodes['47380'] = [40.285442, -85.046074];
Zipcodes['47381'] = [40.386692, -84.864622];
Zipcodes['47382'] = [40.236883, -84.918433];
Zipcodes['47383'] = [40.156112, -85.267079];
Zipcodes['47384'] = [39.909241, -85.550892];
Zipcodes['47385'] = [39.832929, -85.446006];
Zipcodes['47386'] = [40.055073, -85.385654];
Zipcodes['47387'] = [39.838465, -85.298534];
Zipcodes['47388'] = [40.005637, -85.443437];
Zipcodes['47390'] = [40.205666, -84.846408];
Zipcodes['47392'] = [39.909974, -84.943965];
Zipcodes['47393'] = [39.962996, -85.003691];
Zipcodes['47394'] = [40.167974, -84.987308];
Zipcodes['47396'] = [40.189183, -85.517178];
Zipcodes['47401'] = [39.102302, -86.442812];
Zipcodes['47403'] = [39.085219, -86.61234];
Zipcodes['47404'] = [39.219531, -86.591137];
Zipcodes['47405'] = [39.167655, -86.521101];
Zipcodes['47406'] = [39.175146, -86.515136];
Zipcodes['47408'] = [39.229815, -86.467254];
Zipcodes['47420'] = [38.919213, -86.549412];
Zipcodes['47421'] = [38.863494, -86.45223];
Zipcodes['47424'] = [39.020048, -86.882428];
Zipcodes['47427'] = [39.236774, -87.007484];
Zipcodes['47429'] = [39.275805, -86.614456];
Zipcodes['47431'] = [39.245174, -86.915415];
Zipcodes['47432'] = [38.493129, -86.639282];
Zipcodes['47433'] = [39.355787, -86.641547];
Zipcodes['47434'] = [39.012767, -86.545689];
Zipcodes['47435'] = [39.265756, -86.295158];
Zipcodes['47436'] = [38.954149, -86.385977];
Zipcodes['47437'] = [38.713566, -86.676839];
Zipcodes['47438'] = [39.159701, -87.177259];
Zipcodes['47441'] = [39.039441, -87.174106];
Zipcodes['47443'] = [38.968443, -87.081567];
Zipcodes['47445'] = [39.112883, -87.189453];
Zipcodes['47446'] = [38.732679, -86.512274];
Zipcodes['47448'] = [39.17953, -86.235511];
Zipcodes['47449'] = [38.938277, -86.988463];
Zipcodes['47451'] = [38.891758, -86.525364];
Zipcodes['47452'] = [38.644139, -86.413677];
Zipcodes['47453'] = [38.939993, -86.771307];
Zipcodes['47454'] = [38.510025, -86.451821];
Zipcodes['47455'] = [39.312957, -86.957761];
Zipcodes['47456'] = [39.465239, -86.691333];
Zipcodes['47457'] = [38.911834, -86.904716];
Zipcodes['47458'] = [39.070233, -86.507118];
Zipcodes['47459'] = [39.113598, -86.750627];
Zipcodes['47460'] = [39.272063, -86.791838];
Zipcodes['47462'] = [38.958329, -86.647559];
Zipcodes['47464'] = [39.299786, -86.649954];
Zipcodes['47465'] = [39.061573, -87.037455];
Zipcodes['47467'] = [38.767697, -86.344469];
Zipcodes['47468'] = [39.285641, -86.392739];
Zipcodes['47469'] = [38.637574, -86.599158];
Zipcodes['47470'] = [38.816242, -86.644481];
Zipcodes['47471'] = [39.143295, -87.005175];
Zipcodes['47501'] = [38.66192, -87.174714];
Zipcodes['47512'] = [38.778961, -87.318902];
Zipcodes['47513'] = [38.304217, -86.714124];
Zipcodes['47514'] = [38.1486, -86.594016];
Zipcodes['47515'] = [38.183913, -86.703982];
Zipcodes['47516'] = [38.771006, -87.419265];
Zipcodes['47519'] = [38.590059, -86.983869];
Zipcodes['47520'] = [37.944948, -86.66826];
Zipcodes['47521'] = [38.408491, -86.721023];
Zipcodes['47522'] = [38.878527, -86.802044];
Zipcodes['47523'] = [38.178794, -87.025759];
Zipcodes['47524'] = [38.484092, -87.619006];
Zipcodes['47525'] = [38.020375, -86.559985];
Zipcodes['47527'] = [38.468858, -86.785924];
Zipcodes['47528'] = [38.8482, -87.258606];
Zipcodes['47529'] = [38.865509, -87.074515];
Zipcodes['47531'] = [38.03971, -86.851428];
Zipcodes['47532'] = [38.219608, -86.860182];
Zipcodes['47535'] = [38.862641, -87.312881];
Zipcodes['47537'] = [38.114468, -87.064713];
Zipcodes['47541'] = [38.235278, -87.046164];
Zipcodes['47542'] = [38.299143, -86.966966];
Zipcodes['47546'] = [38.418741, -86.9326];
Zipcodes['47550'] = [38.067184, -86.92131];
Zipcodes['47551'] = [38.114486, -86.569119];
Zipcodes['47552'] = [38.111636, -86.99415];
Zipcodes['47553'] = [38.655607, -86.91255];
Zipcodes['47557'] = [38.570098, -87.341805];
Zipcodes['47558'] = [38.638709, -87.049882];
Zipcodes['47561'] = [38.863906, -87.438312];
Zipcodes['47562'] = [38.833126, -86.97354];
Zipcodes['47564'] = [38.48226, -87.097762];
Zipcodes['47567'] = [38.462709, -87.305017];
Zipcodes['47568'] = [38.764086, -87.102429];
Zipcodes['47573'] = [38.744146, -87.322038];
Zipcodes['47574'] = [37.950036, -86.562127];
Zipcodes['47575'] = [38.325177, -86.80206];
Zipcodes['47576'] = [38.179382, -86.595169];
Zipcodes['47577'] = [38.149965, -86.808468];
Zipcodes['47578'] = [38.883346, -87.194338];
Zipcodes['47579'] = [38.11071, -86.913909];
Zipcodes['47580'] = [38.355203, -86.767156];
Zipcodes['47581'] = [38.66269, -86.758566];
Zipcodes['47584'] = [38.251069, -87.256759];
Zipcodes['47585'] = [38.266745, -87.161026];
Zipcodes['47586'] = [38.036352, -86.693738];
Zipcodes['47588'] = [38.02871, -86.804334];
Zipcodes['47590'] = [38.342723, -87.099028];
Zipcodes['47591'] = [38.628561, -87.501485];
Zipcodes['47596'] = [38.863159, -87.233824];
Zipcodes['47597'] = [38.641615, -87.295384];
Zipcodes['47598'] = [38.388004, -87.208264];
Zipcodes['47601'] = [38.051443, -87.254533];
Zipcodes['47610'] = [38.062125, -87.398791];
Zipcodes['47611'] = [38.027918, -87.057458];
Zipcodes['47612'] = [38.194602, -87.701537];
Zipcodes['47613'] = [38.150439, -87.424911];
Zipcodes['47615'] = [37.970761, -86.952275];
Zipcodes['47616'] = [38.233243, -87.909818];
Zipcodes['47619'] = [38.192445, -87.318429];
Zipcodes['47620'] = [37.931644, -87.904317];
Zipcodes['47630'] = [37.952897, -87.34647];
Zipcodes['47631'] = [38.109131, -87.901792];
Zipcodes['47633'] = [38.168768, -87.786342];
Zipcodes['47634'] = [37.939468, -87.182452];
Zipcodes['47635'] = [37.883458, -87.100911];
Zipcodes['47637'] = [38.141652, -87.15957];
Zipcodes['47638'] = [38.064381, -87.777636];
Zipcodes['47639'] = [38.188223, -87.566247];
Zipcodes['47640'] = [38.478176, -87.484032];
Zipcodes['47648'] = [38.248646, -87.550884];
Zipcodes['47649'] = [38.353484, -87.451209];
Zipcodes['47654'] = [38.252954, -87.39102];
Zipcodes['47660'] = [38.296067, -87.328127];
Zipcodes['47665'] = [38.283488, -87.734495];
Zipcodes['47666'] = [38.417797, -87.600469];
Zipcodes['47670'] = [38.346347, -87.57818];
Zipcodes['47683'] = [38.276844, -87.377333];
Zipcodes['47708'] = [37.974249, -87.574336];
Zipcodes['47710'] = [38.025505, -87.575809];
Zipcodes['47711'] = [38.015301, -87.536743];
Zipcodes['47712'] = [37.924358, -87.650203];
Zipcodes['47713'] = [37.948531, -87.555472];
Zipcodes['47714'] = [37.956614, -87.521291];
Zipcodes['47715'] = [37.972431, -87.479646];
Zipcodes['47720'] = [38.062897, -87.641573];
Zipcodes['47725'] = [38.103258, -87.527001];
Zipcodes['47802'] = [39.350856, -87.413513];
Zipcodes['47803'] = [39.466092, -87.307322];
Zipcodes['47804'] = [39.497817, -87.390537];
Zipcodes['47805'] = [39.546274, -87.326483];
Zipcodes['47807'] = [39.472935, -87.403423];
Zipcodes['47809'] = [39.471403, -87.409894];
Zipcodes['47831'] = [39.66549, -87.521766];
Zipcodes['47832'] = [39.880882, -87.258433];
Zipcodes['47833'] = [39.358615, -86.975261];
Zipcodes['47834'] = [39.523629, -87.123313];
Zipcodes['47836'] = [39.649184, -87.172324];
Zipcodes['47837'] = [39.627224, -87.114906];
Zipcodes['47838'] = [38.961773, -87.383679];
Zipcodes['47840'] = [39.407266, -87.061932];
Zipcodes['47841'] = [39.277102, -87.114659];
Zipcodes['47842'] = [39.678173, -87.453638];
Zipcodes['47845'] = [39.193666, -87.233879];
Zipcodes['47846'] = [39.367032, -87.19425];
Zipcodes['47847'] = [39.817433, -87.485619];
Zipcodes['47848'] = [39.042043, -87.261971];
Zipcodes['47849'] = [39.195304, -87.56374];
Zipcodes['47850'] = [39.237324, -87.435962];
Zipcodes['47851'] = [39.573584, -87.245956];
Zipcodes['47853'] = [39.537458, -87.072845];
Zipcodes['47854'] = [39.805799, -87.412455];
Zipcodes['47855'] = [39.188362, -87.302118];
Zipcodes['47857'] = [39.524034, -87.091112];
Zipcodes['47858'] = [39.262171, -87.224587];
Zipcodes['47859'] = [39.88184, -87.172196];
Zipcodes['47860'] = [39.722063, -87.330168];
Zipcodes['47861'] = [39.073459, -87.570355];
Zipcodes['47862'] = [39.776445, -87.34291];
Zipcodes['47863'] = [39.578471, -87.45925];
Zipcodes['47865'] = [39.019096, -87.392584];
Zipcodes['47866'] = [39.28303, -87.322142];
Zipcodes['47868'] = [39.411073, -86.902594];
Zipcodes['47869'] = [39.274994, -87.496599];
Zipcodes['47870'] = [39.370152, -87.473514];
Zipcodes['47871'] = [39.389709, -87.300219];
Zipcodes['47872'] = [39.749063, -87.165486];
Zipcodes['47874'] = [39.637492, -87.278534];
Zipcodes['47875'] = [39.71447, -87.522214];
Zipcodes['47876'] = [39.509958, -87.46107];
Zipcodes['47879'] = [39.19769, -87.378708];
Zipcodes['47880'] = [39.599748, -87.418844];
Zipcodes['47881'] = [39.487284, -87.190049];
Zipcodes['47882'] = [39.089471, -87.411406];
Zipcodes['47884'] = [39.623018, -87.453574];
Zipcodes['47885'] = [39.506258, -87.476142];
Zipcodes['47901'] = [40.417778, -86.889633];
Zipcodes['47904'] = [40.438641, -86.875329];
Zipcodes['47905'] = [40.421886, -86.763599];
Zipcodes['47906'] = [40.479649, -86.989218];
Zipcodes['47907'] = [40.422158, -86.913801];
Zipcodes['47909'] = [40.324563, -86.896302];
Zipcodes['47916'] = [39.983429, -87.055233];
Zipcodes['47917'] = [40.468803, -87.476284];
Zipcodes['47918'] = [40.298771, -87.209725];
Zipcodes['47920'] = [40.540759, -86.833864];
Zipcodes['47921'] = [40.478375, -87.374864];
Zipcodes['47922'] = [40.876948, -87.348911];
Zipcodes['47923'] = [40.610401, -86.940003];
Zipcodes['47924'] = [40.489843, -86.757837];
Zipcodes['47925'] = [40.882047, -86.744766];
Zipcodes['47926'] = [40.780384, -86.595516];
Zipcodes['47928'] = [39.921337, -87.472313];
Zipcodes['47929'] = [40.670159, -86.91276];
Zipcodes['47930'] = [40.237634, -86.741386];
Zipcodes['47932'] = [40.134328, -87.415242];
Zipcodes['47933'] = [40.037689, -86.902157];
Zipcodes['47940'] = [40.11748, -86.744711];
Zipcodes['47941'] = [40.375342, -86.771242];
Zipcodes['47942'] = [40.686285, -87.444303];
Zipcodes['47943'] = [41.064363, -87.28384];
Zipcodes['47944'] = [40.613739, -87.33619];
Zipcodes['47946'] = [40.984262, -86.87017];
Zipcodes['47948'] = [40.776379, -87.286788];
Zipcodes['47949'] = [40.06054, -87.137329];
Zipcodes['47950'] = [40.801719, -86.653534];
Zipcodes['47951'] = [40.790495, -87.443509];
Zipcodes['47952'] = [39.959649, -87.299119];
Zipcodes['47954'] = [39.899903, -86.818779];
Zipcodes['47955'] = [40.193474, -86.866696];
Zipcodes['47957'] = [41.089355, -86.877193];
Zipcodes['47958'] = [40.164523, -87.148628];
Zipcodes['47959'] = [40.862574, -86.900695];
Zipcodes['47960'] = [40.788336, -86.751934];
Zipcodes['47963'] = [40.968415, -87.439922];
Zipcodes['47964'] = [40.952296, -87.299596];
Zipcodes['47965'] = [39.954562, -86.919957];
Zipcodes['47966'] = [39.888495, -87.414389];
Zipcodes['47967'] = [40.194206, -86.998973];
Zipcodes['47968'] = [39.959518, -86.749957];
Zipcodes['47969'] = [40.203559, -87.148938];
Zipcodes['47970'] = [40.465457, -87.117975];
Zipcodes['47971'] = [40.525437, -87.235611];
Zipcodes['47974'] = [40.042921, -87.480363];
Zipcodes['47975'] = [40.444341, -87.253494];
Zipcodes['47977'] = [40.762554, -87.156605];
Zipcodes['47978'] = [40.986173, -87.112979];
Zipcodes['47980'] = [40.752294, -86.909348];
Zipcodes['47981'] = [40.251488, -86.944666];
Zipcodes['47982'] = [40.197262, -87.52689];
Zipcodes['47983'] = [40.286325, -86.77353];
Zipcodes['47986'] = [40.513775, -87.207189];
Zipcodes['47987'] = [40.121237, -87.234872];
Zipcodes['47989'] = [39.890345, -87.04904];
Zipcodes['47990'] = [40.061151, -87.059523];
Zipcodes['47991'] = [40.280136, -87.446185];
Zipcodes['47992'] = [40.319373, -87.048999];
Zipcodes['47993'] = [40.309014, -87.412707];
Zipcodes['47994'] = [40.182326, -87.0823];
Zipcodes['47995'] = [40.761189, -87.030109];
Zipcodes['47997'] = [40.667691, -86.723792];
Zipcodes['48001'] = [42.639195, -82.581664];
Zipcodes['48002'] = [42.935043, -82.916493];
Zipcodes['48003'] = [42.930235, -83.039992];
Zipcodes['48005'] = [42.855919, -82.912384];
Zipcodes['48006'] = [43.083019, -82.691035];
Zipcodes['48009'] = [42.544048, -83.217653];
Zipcodes['48014'] = [43.025719, -82.931553];
Zipcodes['48015'] = [42.480563, -83.027424];
Zipcodes['48017'] = [42.536689, -83.150389];
Zipcodes['48021'] = [42.46608, -82.946341];
Zipcodes['48022'] = [43.023396, -82.802404];
Zipcodes['48023'] = [42.704385, -82.658852];
Zipcodes['48025'] = [42.520357, -83.264824];
Zipcodes['48026'] = [42.537633, -82.946742];
Zipcodes['48027'] = [42.944289, -82.694041];
Zipcodes['48028'] = [42.580209, -82.61662];
Zipcodes['48030'] = [42.461926, -83.097693];
Zipcodes['48032'] = [43.133203, -82.596952];
Zipcodes['48033'] = [42.459322, -83.293205];
Zipcodes['48034'] = [42.49689, -83.291019];
Zipcodes['48035'] = [42.5566, -82.907589];
Zipcodes['48036'] = [42.597045, -82.913144];
Zipcodes['48038'] = [42.603611, -82.941283];
Zipcodes['48039'] = [42.692907, -82.546478];
Zipcodes['48040'] = [42.910313, -82.480603];
Zipcodes['48041'] = [42.937257, -82.809536];
Zipcodes['48042'] = [42.683619, -82.909486];
Zipcodes['48043'] = [42.598055, -82.881506];
Zipcodes['48044'] = [42.650226, -82.928905];
Zipcodes['48045'] = [42.587101, -82.817239];
Zipcodes['48047'] = [42.673906, -82.774086];
Zipcodes['48048'] = [42.738492, -82.798312];
Zipcodes['48049'] = [43.040497, -82.57108];
Zipcodes['48050'] = [42.787537, -82.799467];
Zipcodes['48051'] = [42.689938, -82.826099];
Zipcodes['48054'] = [42.770358, -82.528976];
Zipcodes['48059'] = [43.087276, -82.49449];
Zipcodes['48060'] = [42.983609, -82.458315];
Zipcodes['48062'] = [42.852495, -82.796471];
Zipcodes['48063'] = [42.859332, -82.683111];
Zipcodes['48064'] = [42.764617, -82.666777];
Zipcodes['48065'] = [42.845354, -83.039398];
Zipcodes['48066'] = [42.507586, -82.936582];
Zipcodes['48067'] = [42.490558, -83.137524];
Zipcodes['48069'] = [42.471516, -83.1445];
Zipcodes['48070'] = [42.481899, -83.168088];
Zipcodes['48071'] = [42.507259, -83.10337];
Zipcodes['48072'] = [42.497885, -83.185862];
Zipcodes['48073'] = [42.519181, -83.164361];
Zipcodes['48074'] = [42.950223, -82.561555];
Zipcodes['48075'] = [42.461778, -83.229942];
Zipcodes['48076'] = [42.497535, -83.230648];
Zipcodes['48079'] = [42.858849, -82.548404];
Zipcodes['48080'] = [42.465411, -82.897399];
Zipcodes['48081'] = [42.494669, -82.895039];
Zipcodes['48082'] = [42.528022, -82.887195];
Zipcodes['48083'] = [42.55701, -83.116767];
Zipcodes['48084'] = [42.560629, -83.175582];
Zipcodes['48085'] = [42.604032, -83.122111];
Zipcodes['48088'] = [42.515746, -82.982986];
Zipcodes['48089'] = [42.468075, -82.993829];
Zipcodes['48091'] = [42.46813, -83.058248];
Zipcodes['48092'] = [42.512535, -83.059507];
Zipcodes['48093'] = [42.51589, -83.016483];
Zipcodes['48094'] = [42.734556, -83.051541];
Zipcodes['48095'] = [42.778744, -83.048072];
Zipcodes['48096'] = [42.762151, -82.92047];
Zipcodes['48097'] = [43.121947, -82.827417];
Zipcodes['48098'] = [42.598433, -83.17845];
Zipcodes['48101'] = [42.259473, -83.21039];
Zipcodes['48103'] = [42.26135, -83.846458];
Zipcodes['48104'] = [42.262235, -83.716886];
Zipcodes['48105'] = [42.327634, -83.696444];
Zipcodes['48108'] = [42.221695, -83.732282];
Zipcodes['48109'] = [42.291245, -83.708195];
Zipcodes['48111'] = [42.183156, -83.489036];
Zipcodes['48114'] = [42.567939, -83.75629];
Zipcodes['48116'] = [42.505863, -83.781249];
Zipcodes['48117'] = [42.050259, -83.410188];
Zipcodes['48118'] = [42.312205, -84.0341];
Zipcodes['48120'] = [42.302912, -83.163229];
Zipcodes['48122'] = [42.278607, -83.182255];
Zipcodes['48124'] = [42.298036, -83.24761];
Zipcodes['48125'] = [42.277334, -83.264832];
Zipcodes['48126'] = [42.330326, -83.187133];
Zipcodes['48127'] = [42.335793, -83.283296];
Zipcodes['48128'] = [42.320411, -83.259969];
Zipcodes['48130'] = [42.364141, -83.90936];
Zipcodes['48131'] = [41.967241, -83.673201];
Zipcodes['48133'] = [41.782381, -83.486028];
Zipcodes['48134'] = [42.106695, -83.296148];
Zipcodes['48135'] = [42.324383, -83.341192];
Zipcodes['48137'] = [42.471975, -84.074565];
Zipcodes['48138'] = [42.131288, -83.155729];
Zipcodes['48139'] = [42.453024, -83.807909];
Zipcodes['48140'] = [41.882184, -83.580507];
Zipcodes['48141'] = [42.293945, -83.320298];
Zipcodes['48143'] = [42.457271, -83.852177];
Zipcodes['48144'] = [41.752485, -83.6287];
Zipcodes['48145'] = [41.844247, -83.45997];
Zipcodes['48146'] = [42.243291, -83.181258];
Zipcodes['48150'] = [42.371604, -83.376788];
Zipcodes['48152'] = [42.424935, -83.374264];
Zipcodes['48154'] = [42.398181, -83.372359];
Zipcodes['48157'] = [41.815464, -83.437467];
Zipcodes['48158'] = [42.155701, -84.029242];
Zipcodes['48159'] = [42.024779, -83.548907];
Zipcodes['48160'] = [42.082449, -83.679766];
Zipcodes['48161'] = [41.907183, -83.472489];
Zipcodes['48162'] = [41.960425, -83.429323];
Zipcodes['48164'] = [42.131745, -83.397257];
Zipcodes['48165'] = [42.497724, -83.618198];
Zipcodes['48166'] = [41.98821, -83.292421];
Zipcodes['48167'] = [42.432426, -83.52436];
Zipcodes['48168'] = [42.405262, -83.540498];
Zipcodes['48169'] = [42.461925, -83.947382];
Zipcodes['48170'] = [42.367689, -83.534306];
Zipcodes['48173'] = [42.073679, -83.212774];
Zipcodes['48174'] = [42.236686, -83.391656];
Zipcodes['48176'] = [42.136836, -83.82925];
Zipcodes['48177'] = [41.804838, -83.58159];
Zipcodes['48178'] = [42.443769, -83.656913];
Zipcodes['48179'] = [42.035095, -83.255444];
Zipcodes['48180'] = [42.225283, -83.267691];
Zipcodes['48182'] = [41.789858, -83.583849];
Zipcodes['48183'] = [42.131459, -83.217792];
Zipcodes['48184'] = [42.274474, -83.395633];
Zipcodes['48185'] = [42.333901, -83.384209];
Zipcodes['48186'] = [42.293764, -83.370739];
Zipcodes['48187'] = [42.325573, -83.483576];
Zipcodes['48188'] = [42.286081, -83.487072];
Zipcodes['48189'] = [42.40953, -83.782954];
Zipcodes['48190'] = [42.131617, -83.603327];
Zipcodes['48191'] = [42.121147, -83.565682];
Zipcodes['48192'] = [42.210674, -83.157208];
Zipcodes['48193'] = [42.174019, -83.21085];
Zipcodes['48195'] = [42.204707, -83.205685];
Zipcodes['48197'] = [42.194034, -83.639984];
Zipcodes['48198'] = [42.275075, -83.58634];
Zipcodes['48201'] = [42.34703, -83.060048];
Zipcodes['48202'] = [42.374597, -83.077652];
Zipcodes['48203'] = [42.421228, -83.105483];
Zipcodes['48204'] = [42.365825, -83.142801];
Zipcodes['48205'] = [42.433186, -82.981311];
Zipcodes['48206'] = [42.375168, -83.107831];
Zipcodes['48207'] = [42.348974, -83.012704];
Zipcodes['48208'] = [42.348486, -83.091781];
Zipcodes['48209'] = [42.306083, -83.117655];
Zipcodes['48210'] = [42.336418, -83.128741];
Zipcodes['48211'] = [42.381755, -83.046124];
Zipcodes['48212'] = [42.41, -83.055973];
Zipcodes['48213'] = [42.3979, -82.995397];
Zipcodes['48214'] = [42.365116, -82.987644];
Zipcodes['48215'] = [42.375217, -82.954301];
Zipcodes['48216'] = [42.326385, -83.07926];
Zipcodes['48217'] = [42.277651, -83.155078];
Zipcodes['48218'] = [42.270448, -83.131536];
Zipcodes['48219'] = [42.425328, -83.251428];
Zipcodes['48220'] = [42.458051, -83.135196];
Zipcodes['48221'] = [42.427187, -83.149528];
Zipcodes['48223'] = [42.393398, -83.246136];
Zipcodes['48224'] = [42.410699, -82.941456];
Zipcodes['48225'] = [42.438887, -82.929679];
Zipcodes['48226'] = [42.331815, -83.050354];
Zipcodes['48227'] = [42.387362, -83.192562];
Zipcodes['48228'] = [42.355145, -83.218208];
Zipcodes['48229'] = [42.249592, -83.140439];
Zipcodes['48230'] = [42.382395, -82.921842];
Zipcodes['48233'] = [42.323226, -83.063047];
Zipcodes['48234'] = [42.431184, -83.039509];
Zipcodes['48235'] = [42.427075, -83.19466];
Zipcodes['48236'] = [42.422583, -82.89285];
Zipcodes['48237'] = [42.464943, -83.18241];
Zipcodes['48238'] = [42.396277, -83.141316];
Zipcodes['48239'] = [42.376245, -83.286394];
Zipcodes['48240'] = [42.424483, -83.301271];
Zipcodes['48242'] = [42.213509, -83.356115];
Zipcodes['48243'] = [42.329948, -83.039531];
Zipcodes['48301'] = [42.5424, -83.282168];
Zipcodes['48302'] = [42.581851, -83.304703];
Zipcodes['48304'] = [42.58894, -83.233229];
Zipcodes['48306'] = [42.724326, -83.147312];
Zipcodes['48307'] = [42.659316, -83.122679];
Zipcodes['48309'] = [42.657213, -83.186577];
Zipcodes['48310'] = [42.566517, -83.066512];
Zipcodes['48312'] = [42.558228, -83.008926];
Zipcodes['48313'] = [42.599167, -82.997186];
Zipcodes['48314'] = [42.612074, -83.053489];
Zipcodes['48315'] = [42.671835, -82.997399];
Zipcodes['48316'] = [42.690184, -83.057237];
Zipcodes['48317'] = [42.644251, -83.051539];
Zipcodes['48320'] = [42.611935, -83.338092];
Zipcodes['48322'] = [42.537824, -83.384526];
Zipcodes['48323'] = [42.570804, -83.377909];
Zipcodes['48324'] = [42.594679, -83.393657];
Zipcodes['48326'] = [42.688246, -83.244075];
Zipcodes['48327'] = [42.6383, -83.410667];
Zipcodes['48328'] = [42.65016, -83.363182];
Zipcodes['48329'] = [42.687016, -83.388622];
Zipcodes['48331'] = [42.502486, -83.408936];
Zipcodes['48334'] = [42.504694, -83.349204];
Zipcodes['48335'] = [42.462863, -83.405029];
Zipcodes['48336'] = [42.463342, -83.348054];
Zipcodes['48340'] = [42.672141, -83.287938];
Zipcodes['48341'] = [42.626505, -83.291425];
Zipcodes['48342'] = [42.64441, -83.274561];
Zipcodes['48346'] = [42.717889, -83.428715];
Zipcodes['48348'] = [42.768579, -83.404438];
Zipcodes['48350'] = [42.739418, -83.531044];
Zipcodes['48353'] = [42.646151, -83.714412];
Zipcodes['48356'] = [42.654414, -83.592905];
Zipcodes['48357'] = [42.658574, -83.646557];
Zipcodes['48359'] = [42.720766, -83.275499];
Zipcodes['48360'] = [42.747358, -83.261];
Zipcodes['48362'] = [42.782351, -83.269423];
Zipcodes['48363'] = [42.772483, -83.162499];
Zipcodes['48367'] = [42.836232, -83.136902];
Zipcodes['48370'] = [42.840232, -83.191537];
Zipcodes['48371'] = [42.845874, -83.284608];
Zipcodes['48374'] = [42.473843, -83.519976];
Zipcodes['48375'] = [42.465798, -83.464035];
Zipcodes['48377'] = [42.505368, -83.473579];
Zipcodes['48380'] = [42.582994, -83.666836];
Zipcodes['48381'] = [42.558412, -83.591793];
Zipcodes['48382'] = [42.582368, -83.51143];
Zipcodes['48383'] = [42.65561, -83.525824];
Zipcodes['48386'] = [42.660183, -83.479969];
Zipcodes['48390'] = [42.550711, -83.474218];
Zipcodes['48393'] = [42.521838, -83.545088];
Zipcodes['48401'] = [43.347337, -82.661491];
Zipcodes['48410'] = [43.566289, -82.936223];
Zipcodes['48411'] = [42.934196, -83.529554];
Zipcodes['48412'] = [43.070433, -83.175549];
Zipcodes['48413'] = [43.802747, -82.996755];
Zipcodes['48414'] = [42.861694, -84.079847];
Zipcodes['48415'] = [43.271876, -83.808105];
Zipcodes['48416'] = [43.217071, -82.984954];
Zipcodes['48417'] = [43.261282, -83.939194];
Zipcodes['48418'] = [42.794295, -83.969891];
Zipcodes['48419'] = [43.435806, -82.660428];
Zipcodes['48420'] = [43.179103, -83.700508];
Zipcodes['48421'] = [43.155496, -83.393189];
Zipcodes['48422'] = [43.261472, -82.653704];
Zipcodes['48423'] = [43.04042, -83.51428];
Zipcodes['48426'] = [43.508618, -83.065131];
Zipcodes['48427'] = [43.535638, -82.725482];
Zipcodes['48428'] = [42.938195, -83.154454];
Zipcodes['48429'] = [42.902799, -84.004049];
Zipcodes['48430'] = [42.757217, -83.738279];
Zipcodes['48432'] = [43.901679, -82.974888];
Zipcodes['48433'] = [43.08221, -83.861039];
Zipcodes['48434'] = [43.659867, -82.612562];
Zipcodes['48435'] = [43.245379, -83.359338];
Zipcodes['48436'] = [42.87611, -83.879438];
Zipcodes['48437'] = [43.113049, -83.618412];
Zipcodes['48438'] = [42.913657, -83.484165];
Zipcodes['48439'] = [42.91714, -83.631336];
Zipcodes['48440'] = [42.955721, -83.407839];
Zipcodes['48441'] = [43.797892, -82.70863];
Zipcodes['48442'] = [42.798926, -83.595677];
Zipcodes['48444'] = [43.058491, -83.045197];
Zipcodes['48445'] = [43.950222, -82.990556];
Zipcodes['48446'] = [43.05374, -83.338704];
Zipcodes['48449'] = [42.991452, -83.944772];
Zipcodes['48450'] = [43.252306, -82.540855];
Zipcodes['48451'] = [42.805959, -83.815991];
Zipcodes['48453'] = [43.348144, -83.032572];
Zipcodes['48454'] = [43.195011, -82.812721];
Zipcodes['48455'] = [42.939796, -83.29103];
Zipcodes['48456'] = [43.666971, -82.762208];
Zipcodes['48457'] = [43.190051, -83.894218];
Zipcodes['48458'] = [43.1253, -83.68155];
Zipcodes['48460'] = [43.123857, -83.984481];
Zipcodes['48461'] = [43.193661, -83.206162];
Zipcodes['48462'] = [42.848445, -83.42125];
Zipcodes['48463'] = [43.165799, -83.519301];
Zipcodes['48464'] = [43.210907, -83.419044];
Zipcodes['48465'] = [43.615727, -82.705626];
Zipcodes['48466'] = [43.280507, -82.808877];
Zipcodes['48467'] = [44.015356, -82.982415];
Zipcodes['48468'] = [43.938412, -82.775181];
Zipcodes['48469'] = [43.441469, -82.558181];
Zipcodes['48470'] = [43.73618, -82.76352];
Zipcodes['48471'] = [43.41942, -82.852081];
Zipcodes['48472'] = [43.512323, -82.963212];
Zipcodes['48473'] = [42.937062, -83.820454];
Zipcodes['48475'] = [43.670124, -82.933407];
Zipcodes['48476'] = [42.938318, -84.03453];
Zipcodes['48502'] = [43.014645, -83.689159];
Zipcodes['48503'] = [42.998726, -83.710153];
Zipcodes['48504'] = [43.054725, -83.743745];
Zipcodes['48505'] = [43.06916, -83.692301];
Zipcodes['48506'] = [43.067472, -83.624392];
Zipcodes['48507'] = [42.966974, -83.71736];
Zipcodes['48509'] = [43.029903, -83.60068];
Zipcodes['48519'] = [42.981908, -83.604939];
Zipcodes['48529'] = [42.970338, -83.65859];
Zipcodes['48532'] = [43.011068, -83.794875];
Zipcodes['48601'] = [43.394997, -83.88928];
Zipcodes['48602'] = [43.420026, -83.974472];
Zipcodes['48603'] = [43.458786, -84.029471];
Zipcodes['48604'] = [43.499764, -83.969084];
Zipcodes['48607'] = [43.432412, -83.933828];
Zipcodes['48609'] = [43.389617, -84.08317];
Zipcodes['48610'] = [44.141691, -84.192191];
Zipcodes['48611'] = [43.634148, -84.097206];
Zipcodes['48612'] = [43.883024, -84.439434];
Zipcodes['48613'] = [43.947886, -84.155644];
Zipcodes['48614'] = [43.250991, -84.298042];
Zipcodes['48615'] = [43.422241, -84.474351];
Zipcodes['48616'] = [43.191074, -84.11346];
Zipcodes['48617'] = [43.851308, -84.717297];
Zipcodes['48618'] = [43.736779, -84.556707];
Zipcodes['48619'] = [44.819736, -84.020613];
Zipcodes['48620'] = [43.807628, -84.380417];
Zipcodes['48621'] = [44.721241, -84.012674];
Zipcodes['48622'] = [43.835257, -84.891309];
Zipcodes['48623'] = [43.519318, -84.133945];
Zipcodes['48624'] = [44.061093, -84.45737];
Zipcodes['48625'] = [44.052142, -84.837442];
Zipcodes['48626'] = [43.421368, -84.221438];
Zipcodes['48627'] = [44.461678, -84.748646];
Zipcodes['48628'] = [43.773578, -84.324041];
Zipcodes['48629'] = [44.312238, -84.784698];
Zipcodes['48630'] = [44.325497, -84.773269];
Zipcodes['48631'] = [43.688645, -83.995895];
Zipcodes['48632'] = [43.850411, -85.022782];
Zipcodes['48633'] = [43.948976, -84.93383];
Zipcodes['48634'] = [43.759621, -84.035837];
Zipcodes['48635'] = [44.387169, -83.99443];
Zipcodes['48636'] = [44.586019, -84.28586];
Zipcodes['48637'] = [43.413712, -84.33557];
Zipcodes['48638'] = [43.418925, -84.018242];
Zipcodes['48640'] = [43.582166, -84.337227];
Zipcodes['48642'] = [43.702468, -84.235252];
Zipcodes['48647'] = [44.650682, -84.086078];
Zipcodes['48649'] = [43.150461, -84.217431];
Zipcodes['48650'] = [43.849287, -84.013205];
Zipcodes['48651'] = [44.254775, -84.614813];
Zipcodes['48652'] = [43.848749, -84.216651];
Zipcodes['48653'] = [44.490344, -84.605805];
Zipcodes['48654'] = [44.476724, -84.11752];
Zipcodes['48655'] = [43.294547, -84.159596];
Zipcodes['48656'] = [44.354828, -84.461831];
Zipcodes['48657'] = [43.707391, -84.423081];
Zipcodes['48658'] = [43.975339, -83.936798];
Zipcodes['48659'] = [44.065548, -84.050783];
Zipcodes['48661'] = [44.318406, -84.236213];
Zipcodes['48662'] = [43.400996, -84.416668];
Zipcodes['48701'] = [43.586877, -83.557827];
Zipcodes['48703'] = [44.063642, -83.675914];
Zipcodes['48705'] = [44.695826, -83.656494];
Zipcodes['48706'] = [43.603805, -83.955411];
Zipcodes['48708'] = [43.55706, -83.84968];
Zipcodes['48710'] = [43.556513, -83.987669];
Zipcodes['48720'] = [43.831749, -83.393304];
Zipcodes['48721'] = [44.79677, -83.32924];
Zipcodes['48722'] = [43.346724, -83.844762];
Zipcodes['48723'] = [43.487517, -83.384739];
Zipcodes['48724'] = [43.457759, -83.926284];
Zipcodes['48725'] = [43.943956, -83.209593];
Zipcodes['48726'] = [43.607392, -83.168024];
Zipcodes['48727'] = [43.306497, -83.181103];
Zipcodes['48728'] = [44.743789, -83.822123];
Zipcodes['48729'] = [43.495939, -83.173779];
Zipcodes['48730'] = [44.349359, -83.487067];
Zipcodes['48731'] = [43.836579, -83.151845];
Zipcodes['48732'] = [43.612331, -83.789822];
Zipcodes['48733'] = [43.534102, -83.593113];
Zipcodes['48734'] = [43.357562, -83.74851];
Zipcodes['48735'] = [43.667593, -83.270883];
Zipcodes['48737'] = [44.549723, -83.712024];
Zipcodes['48738'] = [44.549602, -83.336162];
Zipcodes['48739'] = [44.370453, -83.844934];
Zipcodes['48740'] = [44.663889, -83.372024];
Zipcodes['48741'] = [43.402479, -83.179873];
Zipcodes['48742'] = [44.721621, -83.446145];
Zipcodes['48743'] = [44.441124, -83.863151];
Zipcodes['48744'] = [43.348042, -83.359255];
Zipcodes['48745'] = [44.5637, -83.484031];
Zipcodes['48746'] = [43.260277, -83.541049];
Zipcodes['48747'] = [43.518879, -83.764927];
Zipcodes['48748'] = [44.329236, -83.697641];
Zipcodes['48749'] = [44.053935, -83.900947];
Zipcodes['48750'] = [44.435199, -83.472879];
Zipcodes['48754'] = [43.733157, -83.242375];
Zipcodes['48755'] = [43.791501, -83.24562];
Zipcodes['48756'] = [44.233746, -83.973379];
Zipcodes['48757'] = [43.469859, -83.676311];
Zipcodes['48759'] = [43.730478, -83.385904];
Zipcodes['48760'] = [43.317658, -83.252582];
Zipcodes['48761'] = [44.510924, -83.89171];
Zipcodes['48762'] = [44.799958, -83.496914];
Zipcodes['48763'] = [44.246047, -83.613405];
Zipcodes['48765'] = [44.151556, -83.717952];
Zipcodes['48766'] = [44.110287, -83.856275];
Zipcodes['48767'] = [43.650518, -83.463154];
Zipcodes['48768'] = [43.370353, -83.576451];
Zipcodes['48770'] = [44.251183, -83.814184];
Zipcodes['48801'] = [43.378415, -84.676007];
Zipcodes['48806'] = [43.186204, -84.49531];
Zipcodes['48807'] = [43.150476, -84.402006];
Zipcodes['48808'] = [42.826809, -84.452707];
Zipcodes['48809'] = [43.075299, -85.257962];
Zipcodes['48811'] = [43.183922, -84.855988];
Zipcodes['48813'] = [42.573445, -84.845726];
Zipcodes['48815'] = [42.834371, -85.258585];
Zipcodes['48816'] = [42.765009, -83.955981];
Zipcodes['48817'] = [43.013949, -84.042884];
Zipcodes['48818'] = [43.272681, -84.897851];
Zipcodes['48819'] = [42.54423, -84.293281];
Zipcodes['48820'] = [42.863913, -84.592296];
Zipcodes['48821'] = [42.640388, -84.6513];
Zipcodes['48822'] = [42.835279, -84.763128];
Zipcodes['48823'] = [42.764057, -84.459921];
Zipcodes['48824'] = [42.721668, -84.475011];
Zipcodes['48825'] = [42.731621, -84.490348];
Zipcodes['48827'] = [42.508448, -84.661278];
Zipcodes['48829'] = [43.398205, -85.015378];
Zipcodes['48831'] = [43.096225, -84.372473];
Zipcodes['48832'] = [43.411883, -84.776582];
Zipcodes['48834'] = [43.128796, -85.031237];
Zipcodes['48835'] = [43.024903, -84.749057];
Zipcodes['48836'] = [42.669319, -84.076583];
Zipcodes['48837'] = [42.74701, -84.771033];
Zipcodes['48838'] = [43.182841, -85.258386];
Zipcodes['48840'] = [42.763138, -84.379361];
Zipcodes['48841'] = [43.112204, -84.239315];
Zipcodes['48842'] = [42.635311, -84.539319];
Zipcodes['48843'] = [42.575579, -83.923381];
Zipcodes['48845'] = [43.111475, -84.852555];
Zipcodes['48846'] = [42.986125, -85.057887];
Zipcodes['48847'] = [43.273232, -84.575579];
Zipcodes['48848'] = [42.881256, -84.356618];
Zipcodes['48849'] = [42.797413, -85.127567];
Zipcodes['48850'] = [43.435272, -85.246061];
Zipcodes['48851'] = [42.951055, -84.9364];
Zipcodes['48852'] = [43.352631, -85.046437];
Zipcodes['48853'] = [43.099962, -84.688515];
Zipcodes['48854'] = [42.581698, -84.451612];
Zipcodes['48855'] = [42.681274, -83.8972];
Zipcodes['48856'] = [43.19719, -84.733504];
Zipcodes['48857'] = [42.840946, -84.155615];
Zipcodes['48858'] = [43.616033, -84.795459];
Zipcodes['48859'] = [43.586849, -84.772768];
Zipcodes['48860'] = [43.052972, -84.92172];
Zipcodes['48861'] = [42.729428, -84.915601];
Zipcodes['48862'] = [43.249917, -84.548357];
Zipcodes['48864'] = [42.70417, -84.392139];
Zipcodes['48865'] = [43.090516, -85.104617];
Zipcodes['48866'] = [42.993433, -84.382609];
Zipcodes['48867'] = [43.005586, -84.187626];
Zipcodes['48870'] = [43.110264, -84.984823];
Zipcodes['48871'] = [43.156653, -84.673805];
Zipcodes['48872'] = [42.79865, -84.223099];
Zipcodes['48873'] = [43.007396, -84.828157];
Zipcodes['48874'] = [43.186449, -84.594937];
Zipcodes['48875'] = [42.860813, -84.941448];
Zipcodes['48876'] = [42.648423, -84.733104];
Zipcodes['48877'] = [43.403734, -84.840802];
Zipcodes['48878'] = [43.706095, -84.779858];
Zipcodes['48879'] = [43.006704, -84.579248];
Zipcodes['48880'] = [43.458864, -84.578359];
Zipcodes['48881'] = [42.932743, -85.204349];
Zipcodes['48883'] = [43.543727, -84.673886];
Zipcodes['48884'] = [43.209843, -85.049208];
Zipcodes['48885'] = [43.242912, -85.147939];
Zipcodes['48886'] = [43.415719, -85.159692];
Zipcodes['48888'] = [43.314815, -85.106068];
Zipcodes['48889'] = [43.285072, -84.8069];
Zipcodes['48890'] = [42.766873, -84.966363];
Zipcodes['48891'] = [43.395354, -84.911507];
Zipcodes['48892'] = [42.643735, -84.170035];
Zipcodes['48893'] = [43.686552, -84.989883];
Zipcodes['48894'] = [42.925563, -84.792902];
Zipcodes['48895'] = [42.688915, -84.279784];
Zipcodes['48896'] = [43.52125, -84.901015];
Zipcodes['48897'] = [42.703898, -85.130349];
Zipcodes['48906'] = [42.788027, -84.589435];
Zipcodes['48910'] = [42.697056, -84.52504];
Zipcodes['48911'] = [42.672858, -84.5734];
Zipcodes['48912'] = [42.738361, -84.524017];
Zipcodes['48915'] = [42.739959, -84.570887];
Zipcodes['48917'] = [42.725239, -84.639542];
Zipcodes['48933'] = [42.729373, -84.556242];
Zipcodes['49001'] = [42.264543, -85.561545];
Zipcodes['49002'] = [42.197202, -85.555543];
Zipcodes['49004'] = [42.349081, -85.558418];
Zipcodes['49006'] = [42.295385, -85.629061];
Zipcodes['49007'] = [42.302591, -85.588193];
Zipcodes['49008'] = [42.263643, -85.613247];
Zipcodes['49009'] = [42.303617, -85.698025];
Zipcodes['49010'] = [42.532965, -85.871134];
Zipcodes['49011'] = [42.099005, -85.225472];
Zipcodes['49012'] = [42.350751, -85.344597];
Zipcodes['49013'] = [42.302382, -86.100335];
Zipcodes['49014'] = [42.309994, -85.10697];
Zipcodes['49015'] = [42.278204, -85.229231];
Zipcodes['49017'] = [42.391685, -85.201106];
Zipcodes['49021'] = [42.460434, -85.054743];
Zipcodes['49022'] = [42.112289, -86.373776];
Zipcodes['49024'] = [42.206513, -85.617997];
Zipcodes['49026'] = [42.376862, -85.963435];
Zipcodes['49027'] = [42.350328, -86.067386];
Zipcodes['49028'] = [41.844036, -85.179964];
Zipcodes['49029'] = [42.140731, -85.089143];
Zipcodes['49030'] = [41.865948, -85.338879];
Zipcodes['49031'] = [41.903528, -85.976781];
Zipcodes['49032'] = [41.911488, -85.52287];
Zipcodes['49033'] = [42.227502, -85.088714];
Zipcodes['49034'] = [42.234273, -85.330521];
Zipcodes['49036'] = [41.90548, -85.031277];
Zipcodes['49037'] = [42.334561, -85.249172];
Zipcodes['49038'] = [42.202822, -86.320868];
Zipcodes['49040'] = [41.965926, -85.323248];
Zipcodes['49042'] = [41.859375, -85.669395];
Zipcodes['49043'] = [42.285992, -86.268503];
Zipcodes['49045'] = [42.102102, -85.999123];
Zipcodes['49046'] = [42.519178, -85.387984];
Zipcodes['49047'] = [42.004654, -86.120706];
Zipcodes['49048'] = [42.274268, -85.493081];
Zipcodes['49050'] = [42.504302, -85.245704];
Zipcodes['49051'] = [42.176669, -85.236608];
Zipcodes['49052'] = [42.121199, -85.315221];
Zipcodes['49053'] = [42.286125, -85.411396];
Zipcodes['49055'] = [42.368474, -85.857813];
Zipcodes['49056'] = [42.395243, -86.066041];
Zipcodes['49057'] = [42.184475, -86.18301];
Zipcodes['49058'] = [42.636422, -85.29969];
Zipcodes['49060'] = [42.424345, -85.40485];
Zipcodes['49061'] = [41.873542, -85.815243];
Zipcodes['49063'] = [42.409875, -86.139682];
Zipcodes['49064'] = [42.219819, -86.050128];
Zipcodes['49065'] = [42.138273, -85.842108];
Zipcodes['49066'] = [42.031263, -85.343516];
Zipcodes['49067'] = [42.024172, -85.798766];
Zipcodes['49068'] = [42.275694, -84.940816];
Zipcodes['49070'] = [42.53933, -85.62939];
Zipcodes['49071'] = [42.226439, -85.775518];
Zipcodes['49072'] = [42.012663, -85.457739];
Zipcodes['49073'] = [42.574928, -85.126192];
Zipcodes['49074'] = [42.320279, -85.540318];
Zipcodes['49075'] = [41.917513, -85.448603];
Zipcodes['49076'] = [42.428635, -84.88033];
Zipcodes['49078'] = [42.47251, -85.732849];
Zipcodes['49079'] = [42.240155, -85.908075];
Zipcodes['49080'] = [42.456426, -85.588337];
Zipcodes['49082'] = [41.940929, -84.87654];
Zipcodes['49083'] = [42.37811, -85.461128];
Zipcodes['49084'] = [42.180349, -86.385082];
Zipcodes['49085'] = [42.054277, -86.4626];
Zipcodes['49087'] = [42.12458, -85.676226];
Zipcodes['49088'] = [42.179736, -85.422562];
Zipcodes['49089'] = [42.001176, -85.234458];
Zipcodes['49090'] = [42.407427, -86.214544];
Zipcodes['49091'] = [41.828331, -85.447929];
Zipcodes['49092'] = [42.094284, -84.962983];
Zipcodes['49093'] = [41.975744, -85.64701];
Zipcodes['49094'] = [42.050304, -85.11357];
Zipcodes['49095'] = [41.906366, -85.885593];
Zipcodes['49096'] = [42.637069, -85.007259];
Zipcodes['49097'] = [42.121971, -85.487442];
Zipcodes['49098'] = [42.163885, -86.239986];
Zipcodes['49099'] = [41.793262, -85.671752];
Zipcodes['49101'] = [41.944405, -86.483407];
Zipcodes['49102'] = [41.949226, -86.252076];
Zipcodes['49103'] = [41.946299, -86.376474];
Zipcodes['49104'] = [41.963527, -86.358893];
Zipcodes['49106'] = [41.938283, -86.554765];
Zipcodes['49107'] = [41.844909, -86.417637];
Zipcodes['49111'] = [42.022699, -86.291132];
Zipcodes['49112'] = [41.796034, -86.03054];
Zipcodes['49113'] = [41.803262, -86.501365];
Zipcodes['49115'] = [41.875274, -86.633858];
Zipcodes['49116'] = [41.848108, -86.672121];
Zipcodes['49117'] = [41.781671, -86.726836];
Zipcodes['49119'] = [41.875946, -86.549234];
Zipcodes['49120'] = [41.831896, -86.230837];
Zipcodes['49125'] = [41.891897, -86.579415];
Zipcodes['49126'] = [42.032522, -86.371812];
Zipcodes['49127'] = [42.000088, -86.521741];
Zipcodes['49128'] = [41.820406, -86.600497];
Zipcodes['49129'] = [41.816107, -86.686432];
Zipcodes['49130'] = [41.774422, -85.860349];
Zipcodes['49201'] = [42.311764, -84.456172];
Zipcodes['49202'] = [42.267179, -84.41085];
Zipcodes['49203'] = [42.220698, -84.401859];
Zipcodes['49220'] = [41.998622, -84.337415];
Zipcodes['49221'] = [41.902354, -84.064698];
Zipcodes['49224'] = [42.279617, -84.746876];
Zipcodes['49227'] = [41.949776, -84.773057];
Zipcodes['49228'] = [41.809023, -83.875598];
Zipcodes['49229'] = [41.992104, -83.826482];
Zipcodes['49230'] = [42.090395, -84.2173];
Zipcodes['49232'] = [41.740366, -84.664597];
Zipcodes['49233'] = [42.061765, -84.367016];
Zipcodes['49234'] = [42.127037, -84.368713];
Zipcodes['49235'] = [41.863913, -84.20092];
Zipcodes['49236'] = [42.076033, -83.942349];
Zipcodes['49237'] = [42.173248, -84.648128];
Zipcodes['49238'] = [41.90223, -83.785596];
Zipcodes['49240'] = [42.271975, -84.179743];
Zipcodes['49241'] = [42.107843, -84.603335];
Zipcodes['49242'] = [41.886298, -84.622698];
Zipcodes['49245'] = [42.139366, -84.811584];
Zipcodes['49246'] = [42.122922, -84.503513];
Zipcodes['49247'] = [41.837616, -84.338261];
Zipcodes['49248'] = [41.756481, -84.011322];
Zipcodes['49249'] = [42.029334, -84.454755];
Zipcodes['49250'] = [42.021736, -84.626224];
Zipcodes['49251'] = [42.463459, -84.413194];
Zipcodes['49252'] = [42.035237, -84.765398];
Zipcodes['49253'] = [41.961543, -84.269284];
Zipcodes['49254'] = [42.225629, -84.323633];
Zipcodes['49255'] = [41.768259, -84.862872];
Zipcodes['49256'] = [41.746804, -84.214469];
Zipcodes['49258'] = [42.059989, -84.658414];
Zipcodes['49259'] = [42.382906, -84.255763];
Zipcodes['49261'] = [42.162684, -84.247];
Zipcodes['49262'] = [41.963612, -84.46829];
Zipcodes['49263'] = [42.158094, -84.182119];
Zipcodes['49264'] = [42.446232, -84.555061];
Zipcodes['49265'] = [42.006721, -84.175289];
Zipcodes['49266'] = [41.83975, -84.546789];
Zipcodes['49267'] = [41.757323, -83.719429];
Zipcodes['49268'] = [41.868704, -83.929015];
Zipcodes['49269'] = [42.288195, -84.580066];
Zipcodes['49270'] = [41.873675, -83.680783];
Zipcodes['49271'] = [41.831987, -84.453091];
Zipcodes['49272'] = [42.394756, -84.351271];
Zipcodes['49274'] = [41.846682, -84.759784];
Zipcodes['49276'] = [41.794583, -83.776666];
Zipcodes['49277'] = [42.388652, -84.469336];
Zipcodes['49279'] = [41.779648, -84.104066];
Zipcodes['49282'] = [42.03305, -84.400783];
Zipcodes['49283'] = [42.20139, -84.556087];
Zipcodes['49284'] = [42.382301, -84.690714];
Zipcodes['49285'] = [42.463242, -84.210049];
Zipcodes['49286'] = [42.009371, -83.930277];
Zipcodes['49287'] = [42.02303, -84.073485];
Zipcodes['49288'] = [41.733942, -84.429587];
Zipcodes['49289'] = [41.771403, -84.107074];
Zipcodes['49301'] = [42.973443, -85.476998];
Zipcodes['49302'] = [42.821539, -85.402238];
Zipcodes['49303'] = [43.271549, -85.860632];
Zipcodes['49304'] = [43.907821, -85.872637];
Zipcodes['49305'] = [43.752024, -85.151106];
Zipcodes['49306'] = [43.074829, -85.565582];
Zipcodes['49307'] = [43.697682, -85.482957];
Zipcodes['49309'] = [43.759789, -85.876901];
Zipcodes['49310'] = [43.513812, -85.04746];
Zipcodes['49312'] = [43.686004, -85.783517];
Zipcodes['49315'] = [42.80363, -85.741985];
Zipcodes['49316'] = [42.793767, -85.550467];
Zipcodes['49318'] = [43.227276, -85.820431];
Zipcodes['49319'] = [43.230087, -85.528037];
Zipcodes['49320'] = [43.755143, -85.287539];
Zipcodes['49321'] = [43.073608, -85.681875];
Zipcodes['49322'] = [43.355784, -85.351225];
Zipcodes['49323'] = [42.728548, -85.790642];
Zipcodes['49325'] = [42.760797, -85.301726];
Zipcodes['49326'] = [43.253633, -85.317352];
Zipcodes['49327'] = [43.323527, -85.845311];
Zipcodes['49328'] = [42.63368, -85.758168];
Zipcodes['49329'] = [43.4067, -85.494998];
Zipcodes['49330'] = [43.241536, -85.736679];
Zipcodes['49331'] = [42.950189, -85.351989];
Zipcodes['49332'] = [43.620936, -85.241642];
Zipcodes['49333'] = [42.695042, -85.463674];
Zipcodes['49335'] = [42.746252, -85.66907];
Zipcodes['49336'] = [43.508359, -85.435658];
Zipcodes['49337'] = [43.423029, -85.718784];
Zipcodes['49338'] = [43.771679, -85.602298];
Zipcodes['49339'] = [43.331355, -85.496459];
Zipcodes['49340'] = [43.61793, -85.110768];
Zipcodes['49341'] = [43.124135, -85.490928];
Zipcodes['49342'] = [43.690435, -85.315591];
Zipcodes['49343'] = [43.299716, -85.526074];
Zipcodes['49344'] = [42.589905, -85.591301];
Zipcodes['49345'] = [43.153922, -85.70523];
Zipcodes['49346'] = [43.594885, -85.421128];
Zipcodes['49347'] = [43.312299, -85.345236];
Zipcodes['49348'] = [42.685079, -85.610075];
Zipcodes['49349'] = [43.575337, -85.748732];
Zipcodes['49401'] = [42.977594, -85.958569];
Zipcodes['49402'] = [43.94098, -86.018022];
Zipcodes['49403'] = [43.140975, -85.857766];
Zipcodes['49404'] = [43.063438, -85.952185];
Zipcodes['49405'] = [43.907283, -86.189734];
Zipcodes['49406'] = [42.64076, -86.210618];
Zipcodes['49408'] = [42.571678, -86.116236];
Zipcodes['49409'] = [43.080755, -86.218375];
Zipcodes['49410'] = [44.027217, -86.148635];
Zipcodes['49411'] = [44.114566, -86.256829];
Zipcodes['49412'] = [43.470153, -85.946264];
Zipcodes['49415'] = [43.150964, -86.114307];
Zipcodes['49417'] = [43.009233, -86.152022];
Zipcodes['49418'] = [42.878123, -85.767925];
Zipcodes['49419'] = [42.676963, -85.979912];
Zipcodes['49420'] = [43.719985, -86.282313];
Zipcodes['49421'] = [43.599532, -86.069216];
Zipcodes['49423'] = [42.739168, -86.084793];
Zipcodes['49424'] = [42.832778, -86.130421];
Zipcodes['49425'] = [43.450442, -86.110575];
Zipcodes['49426'] = [42.863317, -85.887576];
Zipcodes['49428'] = [42.920207, -85.835883];
Zipcodes['49430'] = [43.012006, -85.900493];
Zipcodes['49431'] = [43.964611, -86.409251];
Zipcodes['49434'] = [42.770029, -86.20771];
Zipcodes['49435'] = [43.024453, -85.835175];
Zipcodes['49436'] = [43.687483, -86.46853];
Zipcodes['49437'] = [43.463596, -86.384631];
Zipcodes['49440'] = [43.23747, -86.253396];
Zipcodes['49441'] = [43.179962, -86.286824];
Zipcodes['49442'] = [43.241841, -86.135719];
Zipcodes['49443'] = [43.222007, -86.241585];
Zipcodes['49444'] = [43.177117, -86.199527];
Zipcodes['49445'] = [43.292537, -86.293883];
Zipcodes['49446'] = [43.539803, -86.378167];
Zipcodes['49448'] = [43.094383, -86.071643];
Zipcodes['49449'] = [43.793624, -86.388577];
Zipcodes['49450'] = [42.477969, -86.075032];
Zipcodes['49451'] = [43.213069, -85.967762];
Zipcodes['49452'] = [43.514595, -86.254788];
Zipcodes['49453'] = [42.661281, -86.178771];
Zipcodes['49454'] = [43.946348, -86.285058];
Zipcodes['49455'] = [43.610287, -86.378251];
Zipcodes['49456'] = [43.091147, -86.206467];
Zipcodes['49457'] = [43.351479, -86.145156];
Zipcodes['49458'] = [43.948125, -86.114888];
Zipcodes['49459'] = [43.736074, -86.095686];
Zipcodes['49460'] = [42.932375, -86.151244];
Zipcodes['49461'] = [43.37686, -86.331126];
Zipcodes['49464'] = [42.844796, -85.984799];
Zipcodes['49503'] = [42.96212, -85.659394];
Zipcodes['49504'] = [42.981579, -85.7107];
Zipcodes['49505'] = [42.996477, -85.637735];
Zipcodes['49506'] = [42.945748, -85.613839];
Zipcodes['49507'] = [42.930584, -85.655257];
Zipcodes['49508'] = [42.872994, -85.628015];
Zipcodes['49509'] = [42.897764, -85.693418];
Zipcodes['49512'] = [42.880121, -85.553203];
Zipcodes['49519'] = [42.895765, -85.71951];
Zipcodes['49525'] = [43.022168, -85.592115];
Zipcodes['49534'] = [42.964438, -85.787181];
Zipcodes['49544'] = [43.038932, -85.74657];
Zipcodes['49546'] = [42.93291, -85.535868];
Zipcodes['49548'] = [42.867826, -85.662972];
Zipcodes['49601'] = [44.242697, -85.539922];
Zipcodes['49611'] = [44.979986, -84.978744];
Zipcodes['49612'] = [44.863464, -85.224922];
Zipcodes['49613'] = [44.50612, -86.206892];
Zipcodes['49614'] = [44.445628, -86.111686];
Zipcodes['49615'] = [44.964551, -85.206629];
Zipcodes['49616'] = [44.579933, -86.090298];
Zipcodes['49617'] = [44.641515, -86.032239];
Zipcodes['49618'] = [44.302589, -85.590049];
Zipcodes['49619'] = [44.293058, -85.991212];
Zipcodes['49620'] = [44.516271, -85.686369];
Zipcodes['49621'] = [44.857781, -85.76991];
Zipcodes['49622'] = [45.075064, -85.267082];
Zipcodes['49623'] = [43.908889, -85.691816];
Zipcodes['49625'] = [44.43274, -85.858343];
Zipcodes['49626'] = [44.242823, -86.292548];
Zipcodes['49627'] = [45.112422, -85.358744];
Zipcodes['49628'] = [44.620998, -86.234097];
Zipcodes['49629'] = [44.918988, -85.390286];
Zipcodes['49630'] = [44.840524, -86.046492];
Zipcodes['49631'] = [43.916915, -85.259288];
Zipcodes['49632'] = [44.239007, -84.967889];
Zipcodes['49633'] = [44.562155, -85.185029];
Zipcodes['49634'] = [44.214721, -86.288768];
Zipcodes['49635'] = [44.625614, -86.193112];
Zipcodes['49636'] = [44.891248, -85.964596];
Zipcodes['49637'] = [44.636211, -85.71054];
Zipcodes['49638'] = [44.286973, -85.755675];
Zipcodes['49639'] = [43.84701, -85.408527];
Zipcodes['49640'] = [44.724471, -86.04736];
Zipcodes['49642'] = [43.904253, -85.783959];
Zipcodes['49643'] = [44.639738, -85.828944];
Zipcodes['49644'] = [44.111533, -85.917804];
Zipcodes['49645'] = [44.372119, -86.025418];
Zipcodes['49646'] = [44.719997, -85.036456];
Zipcodes['49648'] = [44.997686, -85.338808];
Zipcodes['49649'] = [44.563373, -85.525432];
Zipcodes['49650'] = [44.739836, -85.887151];
Zipcodes['49651'] = [44.398984, -85.106294];
Zipcodes['49653'] = [44.973631, -85.723949];
Zipcodes['49654'] = [45.009769, -86.136299];
Zipcodes['49655'] = [44.024932, -85.43205];
Zipcodes['49656'] = [44.055606, -85.697482];
Zipcodes['49657'] = [44.215267, -85.183625];
Zipcodes['49659'] = [44.905563, -85.034174];
Zipcodes['49660'] = [44.233751, -86.210649];
Zipcodes['49663'] = [44.42336, -85.393573];
Zipcodes['49664'] = [44.887981, -85.898639];
Zipcodes['49665'] = [44.100372, -85.132049];
Zipcodes['49667'] = [44.345956, -84.931109];
Zipcodes['49668'] = [44.40184, -85.713277];
Zipcodes['49670'] = [45.119283, -85.619724];
Zipcodes['49674'] = [45.047907, -85.591578];
Zipcodes['49675'] = [44.384164, -86.225376];
Zipcodes['49676'] = [44.843437, -85.294433];
Zipcodes['49677'] = [43.896335, -85.53535];
Zipcodes['49679'] = [43.878375, -85.158857];
Zipcodes['49680'] = [44.648125, -85.241466];
Zipcodes['49682'] = [44.988312, -85.647337];
Zipcodes['49683'] = [44.540521, -85.926894];
Zipcodes['49684'] = [44.762372, -85.685156];
Zipcodes['49685'] = [44.699632, -85.700684];
Zipcodes['49686'] = [44.849403, -85.542616];
Zipcodes['49688'] = [44.126516, -85.440211];
Zipcodes['49689'] = [44.211072, -85.910757];
Zipcodes['49690'] = [44.784314, -85.394673];
Zipcodes['49696'] = [44.674376, -85.487113];
Zipcodes['49701'] = [45.741789, -84.740904];
Zipcodes['49705'] = [45.348552, -84.463353];
Zipcodes['49706'] = [45.438274, -84.779397];
Zipcodes['49707'] = [45.094306, -83.476214];
Zipcodes['49709'] = [45.043661, -84.171382];
Zipcodes['49710'] = [46.276096, -84.176734];
Zipcodes['49712'] = [45.21121, -85.010975];
Zipcodes['49713'] = [45.220839, -84.845493];
Zipcodes['49715'] = [46.401119, -84.6925];
Zipcodes['49716'] = [45.509448, -84.749886];
Zipcodes['49717'] = [45.425791, -84.687367];
Zipcodes['49718'] = [45.716626, -84.843092];
Zipcodes['49719'] = [45.990618, -84.322321];
Zipcodes['49720'] = [45.280509, -85.245046];
Zipcodes['49721'] = [45.574179, -84.455446];
Zipcodes['49722'] = [45.420846, -84.865106];
Zipcodes['49724'] = [46.32764, -84.390112];
Zipcodes['49725'] = [45.985831, -83.989467];
Zipcodes['49726'] = [46.003738, -83.615219];
Zipcodes['49727'] = [45.137651, -85.108962];
Zipcodes['49728'] = [46.386704, -85.024763];
Zipcodes['49729'] = [45.163731, -85.292999];
Zipcodes['49730'] = [45.044034, -84.867529];
Zipcodes['49733'] = [44.794071, -84.730562];
Zipcodes['49735'] = [44.998087, -84.670164];
Zipcodes['49736'] = [46.087784, -84.138358];
Zipcodes['49738'] = [44.692289, -84.595113];
Zipcodes['49740'] = [45.521875, -84.990777];
Zipcodes['49743'] = [45.266958, -83.893364];
Zipcodes['49744'] = [44.993123, -83.657097];
Zipcodes['49745'] = [46.021302, -84.528114];
Zipcodes['49746'] = [45.062987, -83.951062];
Zipcodes['49747'] = [44.835571, -83.629725];
Zipcodes['49748'] = [46.372259, -85.16574];
Zipcodes['49749'] = [45.422081, -84.584616];
Zipcodes['49751'] = [44.982838, -84.430167];
Zipcodes['49752'] = [46.263933, -84.417596];
Zipcodes['49753'] = [45.017332, -83.777063];
Zipcodes['49755'] = [45.643275, -84.827509];
Zipcodes['49756'] = [44.814691, -84.275815];
Zipcodes['49757'] = [45.860184, -84.625834];
Zipcodes['49759'] = [45.417476, -84.096086];
Zipcodes['49760'] = [46.047891, -84.847153];
Zipcodes['49762'] = [46.116086, -85.28474];
Zipcodes['49764'] = [45.423537, -84.82824];
Zipcodes['49765'] = [45.338306, -84.25917];
Zipcodes['49766'] = [44.908297, -83.432043];
Zipcodes['49768'] = [46.639554, -85.118081];
Zipcodes['49769'] = [45.570812, -84.861754];
Zipcodes['49770'] = [45.331202, -84.897145];
Zipcodes['49774'] = [46.165835, -84.298661];
Zipcodes['49775'] = [45.770763, -84.477959];
Zipcodes['49776'] = [45.232202, -83.680373];
Zipcodes['49777'] = [45.285818, -83.479655];
Zipcodes['49779'] = [45.394382, -83.84441];
Zipcodes['49780'] = [46.209089, -84.686118];
Zipcodes['49781'] = [45.948137, -84.765522];
Zipcodes['49782'] = [45.618554, -85.543684];
Zipcodes['49783'] = [46.416901, -84.289054];
Zipcodes['49784'] = [46.257681, -84.454857];
Zipcodes['49788'] = [46.264324, -84.469852];
Zipcodes['49791'] = [45.481006, -84.599373];
Zipcodes['49793'] = [46.214575, -85.047394];
Zipcodes['49795'] = [45.160679, -84.579688];
Zipcodes['49796'] = [45.272213, -84.948286];
Zipcodes['49799'] = [45.262433, -84.580375];
Zipcodes['49801'] = [45.927171, -88.015827];
Zipcodes['49802'] = [45.800591, -88.082227];
Zipcodes['49805'] = [47.360611, -88.402488];
Zipcodes['49806'] = [46.452414, -86.901161];
Zipcodes['49807'] = [45.699232, -87.293231];
Zipcodes['49808'] = [46.823114, -87.85267];
Zipcodes['49812'] = [45.591632, -87.50312];
Zipcodes['49814'] = [46.568034, -87.953816];
Zipcodes['49815'] = [46.188604, -87.946546];
Zipcodes['49816'] = [46.278851, -86.909361];
Zipcodes['49817'] = [45.949333, -86.504514];
Zipcodes['49818'] = [45.982363, -87.349348];
Zipcodes['49820'] = [46.19009, -85.654163];
Zipcodes['49821'] = [45.520277, -87.619709];
Zipcodes['49822'] = [46.433521, -87.024586];
Zipcodes['49825'] = [46.357811, -86.972916];
Zipcodes['49826'] = [46.323306, -87.052838];
Zipcodes['49827'] = [46.180664, -85.530449];
Zipcodes['49829'] = [45.774101, -87.13629];
Zipcodes['49831'] = [46.056687, -87.729011];
Zipcodes['49833'] = [46.268336, -87.294628];
Zipcodes['49834'] = [45.969351, -87.705351];
Zipcodes['49835'] = [45.735776, -86.562598];
Zipcodes['49836'] = [46.201987, -85.903336];
Zipcodes['49837'] = [45.868679, -87.07914];
Zipcodes['49838'] = [46.031891, -85.737395];
Zipcodes['49839'] = [46.59846, -86.146442];
Zipcodes['49840'] = [46.174564, -86.054633];
Zipcodes['49841'] = [46.26711, -87.499647];
Zipcodes['49847'] = [45.704676, -87.636201];
Zipcodes['49848'] = [45.379216, -87.635741];
Zipcodes['49849'] = [46.42568, -87.771426];
Zipcodes['49852'] = [45.786249, -87.816777];
Zipcodes['49853'] = [46.368293, -85.750902];
Zipcodes['49854'] = [46.076172, -86.345803];
Zipcodes['49855'] = [46.561069, -87.451418];
Zipcodes['49858'] = [45.222109, -87.548174];
Zipcodes['49861'] = [46.568669, -88.1754];
Zipcodes['49862'] = [46.398696, -86.727185];
Zipcodes['49863'] = [45.61294, -87.551681];
Zipcodes['49864'] = [45.841522, -86.650397];
Zipcodes['49866'] = [46.415937, -87.584762];
Zipcodes['49868'] = [46.510988, -85.517552];
Zipcodes['49870'] = [45.867201, -87.878923];
Zipcodes['49871'] = [46.424714, -87.538923];
Zipcodes['49872'] = [46.018219, -87.082202];
Zipcodes['49873'] = [45.826699, -87.486662];
Zipcodes['49874'] = [45.753538, -87.468889];
Zipcodes['49876'] = [45.802164, -87.977383];
Zipcodes['49877'] = [46.158926, -87.712237];
Zipcodes['49878'] = [45.946742, -86.87753];
Zipcodes['49879'] = [46.340347, -88.064585];
Zipcodes['49880'] = [46.110993, -87.222358];
Zipcodes['49881'] = [46.07322, -88.047262];
Zipcodes['49883'] = [46.410608, -86.007868];
Zipcodes['49884'] = [46.41592, -86.356975];
Zipcodes['49885'] = [46.33805, -87.19832];
Zipcodes['49886'] = [45.6975, -87.494034];
Zipcodes['49887'] = [45.392007, -87.731587];
Zipcodes['49891'] = [46.217271, -87.015008];
Zipcodes['49892'] = [45.776825, -87.792363];
Zipcodes['49893'] = [45.297038, -87.605155];
Zipcodes['49894'] = [45.782901, -87.076346];
Zipcodes['49895'] = [46.171321, -86.661791];
Zipcodes['49896'] = [45.688568, -87.388865];
Zipcodes['49901'] = [47.292699, -88.392601];
Zipcodes['49902'] = [46.051168, -88.376162];
Zipcodes['49903'] = [46.337193, -88.482608];
Zipcodes['49905'] = [47.110078, -88.707654];
Zipcodes['49908'] = [46.758152, -88.563278];
Zipcodes['49910'] = [46.629711, -89.531354];
Zipcodes['49911'] = [46.498953, -90.057516];
Zipcodes['49912'] = [46.496716, -89.194224];
Zipcodes['49913'] = [47.238859, -88.451579];
Zipcodes['49915'] = [46.064638, -88.627095];
Zipcodes['49916'] = [46.984094, -88.586325];
Zipcodes['49917'] = [47.281359, -88.359132];
Zipcodes['49918'] = [47.439583, -87.829749];
Zipcodes['49919'] = [46.514745, -88.434927];
Zipcodes['49920'] = [46.138161, -88.30225];
Zipcodes['49921'] = [47.091218, -88.57971];
Zipcodes['49922'] = [47.124404, -88.489531];
Zipcodes['49925'] = [46.544419, -89.374684];
Zipcodes['49927'] = [46.03306, -88.547007];
Zipcodes['49929'] = [46.798725, -89.09564];
Zipcodes['49930'] = [47.202985, -88.576295];
Zipcodes['49931'] = [47.184793, -88.640831];
Zipcodes['49934'] = [47.165644, -88.439092];
Zipcodes['49935'] = [46.175587, -88.752156];
Zipcodes['49938'] = [46.525851, -90.130358];
Zipcodes['49942'] = [47.260732, -88.41655];
Zipcodes['49945'] = [47.148165, -88.337092];
Zipcodes['49946'] = [46.727817, -88.332527];
Zipcodes['49947'] = [46.412734, -89.606815];
Zipcodes['49948'] = [46.752182, -88.998266];
Zipcodes['49950'] = [47.386318, -88.133741];
Zipcodes['49952'] = [46.701362, -88.784945];
Zipcodes['49953'] = [46.785658, -89.42686];
Zipcodes['49955'] = [47.006294, -88.694822];
Zipcodes['49958'] = [46.815468, -88.662927];
Zipcodes['49959'] = [46.469886, -89.998077];
Zipcodes['49960'] = [46.711853, -89.258371];
Zipcodes['49961'] = [46.449553, -88.702147];
Zipcodes['49962'] = [46.839281, -88.131113];
Zipcodes['49963'] = [47.048942, -88.639995];
Zipcodes['49965'] = [46.955096, -88.859543];
Zipcodes['49967'] = [46.506508, -88.966362];
Zipcodes['49968'] = [46.502982, -89.889607];
Zipcodes['49969'] = [46.251655, -89.210723];
Zipcodes['49970'] = [46.522947, -88.600492];
Zipcodes['49971'] = [46.710437, -89.628683];
Zipcodes['50001'] = [41.353473, -93.438843];
Zipcodes['50002'] = [41.514826, -94.648337];
Zipcodes['50003'] = [41.611473, -94.043043];
Zipcodes['50005'] = [42.131988, -93.034602];
Zipcodes['50006'] = [42.508397, -93.401965];
Zipcodes['50007'] = [41.803653, -93.600427];
Zipcodes['50008'] = [40.672937, -93.386227];
Zipcodes['50009'] = [41.643423, -93.459703];
Zipcodes['50010'] = [42.030826, -93.5883];
Zipcodes['50012'] = [42.029706, -93.650496];
Zipcodes['50013'] = [42.014905, -93.657814];
Zipcodes['50014'] = [42.046724, -93.69462];
Zipcodes['50020'] = [41.426792, -94.749885];
Zipcodes['50021'] = [41.724292, -93.565308];
Zipcodes['50022'] = [41.416276, -95.006341];
Zipcodes['50023'] = [41.726385, -93.630506];
Zipcodes['50025'] = [41.756245, -94.920368];
Zipcodes['50026'] = [41.858226, -94.449565];
Zipcodes['50027'] = [41.482051, -92.469368];
Zipcodes['50028'] = [41.80691, -93.154284];
Zipcodes['50029'] = [41.828956, -94.555933];
Zipcodes['50031'] = [42.038807, -94.140029];
Zipcodes['50032'] = [41.665911, -93.542852];
Zipcodes['50033'] = [41.360409, -93.794244];
Zipcodes['50034'] = [42.543259, -93.646783];
Zipcodes['50035'] = [41.723893, -93.45792];
Zipcodes['50036'] = [42.085446, -93.849635];
Zipcodes['50038'] = [41.52484, -93.90306];
Zipcodes['50039'] = [41.829925, -94.014887];
Zipcodes['50040'] = [42.17363, -94.101233];
Zipcodes['50041'] = [42.634332, -93.246598];
Zipcodes['50042'] = [41.533745, -94.905323];
Zipcodes['50044'] = [41.203526, -92.905256];
Zipcodes['50046'] = [41.88822, -93.518498];
Zipcodes['50047'] = [41.475055, -93.467269];
Zipcodes['50048'] = [41.512457, -94.520058];
Zipcodes['50049'] = [41.034435, -93.295422];
Zipcodes['50050'] = [42.158973, -94.503593];
Zipcodes['50051'] = [42.135392, -93.141399];
Zipcodes['50052'] = [40.643287, -93.44438];
Zipcodes['50054'] = [41.688289, -93.231789];
Zipcodes['50055'] = [41.884636, -93.2801];
Zipcodes['50056'] = [42.020712, -93.296127];
Zipcodes['50057'] = [41.179914, -93.134256];
Zipcodes['50058'] = [41.855648, -94.691064];
Zipcodes['50060'] = [40.755224, -93.319639];
Zipcodes['50061'] = [41.475941, -93.79773];
Zipcodes['50062'] = [41.235464, -93.244965];
Zipcodes['50063'] = [41.695579, -93.940638];
Zipcodes['50064'] = [42.103878, -94.223102];
Zipcodes['50065'] = [40.611611, -93.774335];
Zipcodes['50066'] = [41.798081, -94.233396];
Zipcodes['50067'] = [40.692682, -93.882672];
Zipcodes['50068'] = [40.947993, -93.466251];
Zipcodes['50069'] = [41.521057, -94.02988];
Zipcodes['50070'] = [41.445537, -94.232632];
Zipcodes['50071'] = [42.64863, -93.504909];
Zipcodes['50072'] = [41.471219, -94.122983];
Zipcodes['50073'] = [41.792132, -93.523971];
Zipcodes['50074'] = [40.865931, -94.080133];
Zipcodes['50075'] = [42.325164, -93.564882];
Zipcodes['50076'] = [41.592177, -94.841945];
Zipcodes['50078'] = [41.938595, -92.862908];
Zipcodes['50101'] = [42.667138, -93.634216];
Zipcodes['50102'] = [42.245542, -93.400194];
Zipcodes['50103'] = [40.79512, -93.609144];
Zipcodes['50104'] = [41.494607, -92.388406];
Zipcodes['50105'] = [42.117512, -93.639111];
Zipcodes['50106'] = [41.881076, -92.805779];
Zipcodes['50107'] = [42.036497, -94.219014];
Zipcodes['50108'] = [40.824741, -93.965521];
Zipcodes['50109'] = [41.77088, -93.800631];
Zipcodes['50110'] = [41.844205, -94.986027];
Zipcodes['50111'] = [41.691918, -93.80184];
Zipcodes['50112'] = [41.7334, -92.712678];
Zipcodes['50115'] = [41.68324, -94.561556];
Zipcodes['50116'] = [41.178106, -92.982479];
Zipcodes['50117'] = [41.679966, -94.790568];
Zipcodes['50118'] = [41.459792, -93.378688];
Zipcodes['50119'] = [41.313063, -92.939705];
Zipcodes['50120'] = [41.933763, -92.982814];
Zipcodes['50122'] = [42.298821, -93.307758];
Zipcodes['50123'] = [40.833825, -93.513815];
Zipcodes['50124'] = [41.885132, -93.604447];
Zipcodes['50125'] = [41.335185, -93.584634];
Zipcodes['50126'] = [42.510523, -93.255315];
Zipcodes['50127'] = [41.781222, -93.211283];
Zipcodes['50128'] = [41.855589, -94.300236];
Zipcodes['50129'] = [42.013286, -94.376733];
Zipcodes['50130'] = [42.303386, -93.669228];
Zipcodes['50131'] = [41.696757, -93.720808];
Zipcodes['50132'] = [42.393932, -93.682569];
Zipcodes['50133'] = [40.718438, -94.081953];
Zipcodes['50134'] = [41.939909, -93.674312];
Zipcodes['50135'] = [41.745394, -92.896044];
Zipcodes['50136'] = [41.469501, -92.292764];
Zipcodes['50137'] = [41.609715, -92.921578];
Zipcodes['50138'] = [41.302889, -93.107738];
Zipcodes['50139'] = [41.194195, -93.358181];
Zipcodes['50140'] = [40.634105, -93.993343];
Zipcodes['50141'] = [41.867559, -92.949711];
Zipcodes['50142'] = [42.005555, -92.77582];
Zipcodes['50143'] = [41.341836, -92.804656];
Zipcodes['50144'] = [40.733083, -93.737338];
Zipcodes['50145'] = [41.203854, -93.485143];
Zipcodes['50146'] = [41.680415, -94.249054];
Zipcodes['50147'] = [40.617696, -93.54772];
Zipcodes['50148'] = [42.181458, -92.995665];
Zipcodes['50149'] = [41.149663, -94.095295];
Zipcodes['50150'] = [41.125508, -92.975192];
Zipcodes['50151'] = [41.078785, -93.488791];
Zipcodes['50152'] = [41.968734, -93.817643];
Zipcodes['50153'] = [41.573898, -92.80701];
Zipcodes['50154'] = [42.176263, -93.390727];
Zipcodes['50155'] = [41.211018, -94.199353];
Zipcodes['50156'] = [41.89831, -93.801235];
Zipcodes['50157'] = [41.740075, -92.563309];
Zipcodes['50158'] = [42.049806, -92.900739];
Zipcodes['50160'] = [41.373858, -93.738701];
Zipcodes['50161'] = [41.842298, -93.396907];
Zipcodes['50162'] = [41.928305, -93.062875];
Zipcodes['50163'] = [41.222284, -93.241041];
Zipcodes['50164'] = [41.499934, -94.433324];
Zipcodes['50165'] = [40.850789, -93.308499];
Zipcodes['50166'] = [41.282835, -93.431036];
Zipcodes['50167'] = [41.745257, -94.049388];
Zipcodes['50168'] = [41.788361, -93.278546];
Zipcodes['50169'] = [41.655261, -93.352352];
Zipcodes['50170'] = [41.529996, -93.116478];
Zipcodes['50171'] = [41.583936, -92.525423];
Zipcodes['50173'] = [41.964658, -92.713479];
Zipcodes['50174'] = [41.033707, -93.982304];
Zipcodes['50201'] = [42.033498, -93.444377];
Zipcodes['50206'] = [42.245665, -93.202336];
Zipcodes['50207'] = [41.431966, -92.63754];
Zipcodes['50208'] = [41.710803, -93.039775];
Zipcodes['50210'] = [41.195043, -93.698287];
Zipcodes['50211'] = [41.45531, -93.705269];
Zipcodes['50212'] = [42.036562, -94.066162];
Zipcodes['50213'] = [41.033119, -93.764729];
Zipcodes['50214'] = [41.469319, -93.055697];
Zipcodes['50216'] = [41.697256, -94.354853];
Zipcodes['50217'] = [42.183786, -94.251812];
Zipcodes['50218'] = [41.348429, -93.877528];
Zipcodes['50219'] = [41.420701, -92.896694];
Zipcodes['50220'] = [41.836423, -94.121285];
Zipcodes['50222'] = [41.215853, -93.959592];
Zipcodes['50223'] = [42.173721, -94.037815];
Zipcodes['50225'] = [41.381174, -93.274594];
Zipcodes['50226'] = [41.796243, -93.701973];
Zipcodes['50227'] = [42.593886, -93.428181];
Zipcodes['50228'] = [41.568861, -93.245318];
Zipcodes['50229'] = [41.379081, -93.775073];
Zipcodes['50230'] = [42.303248, -93.453417];
Zipcodes['50231'] = [42.237649, -93.602568];
Zipcodes['50232'] = [41.556725, -92.96972];
Zipcodes['50233'] = [41.612204, -94.210956];
Zipcodes['50234'] = [41.887962, -93.181347];
Zipcodes['50235'] = [41.931785, -94.224912];
Zipcodes['50236'] = [42.178399, -93.49039];
Zipcodes['50237'] = [41.545198, -93.370962];
Zipcodes['50238'] = [40.963784, -93.182607];
Zipcodes['50239'] = [42.142774, -93.202933];
Zipcodes['50240'] = [41.29709, -93.793028];
Zipcodes['50241'] = [41.307698, -93.733845];
Zipcodes['50242'] = [41.561039, -92.693756];
Zipcodes['50243'] = [41.864564, -93.696739];
Zipcodes['50244'] = [41.850801, -93.651217];
Zipcodes['50246'] = [42.288957, -93.781362];
Zipcodes['50247'] = [42.01809, -93.166386];
Zipcodes['50248'] = [42.191221, -93.614392];
Zipcodes['50249'] = [42.275278, -93.903159];
Zipcodes['50250'] = [41.482226, -94.347153];
Zipcodes['50251'] = [41.56694, -92.869363];
Zipcodes['50252'] = [41.456512, -93.319813];
Zipcodes['50254'] = [40.991218, -94.059403];
Zipcodes['50255'] = [41.455931, -92.332086];
Zipcodes['50256'] = [41.259706, -92.905272];
Zipcodes['50257'] = [41.183465, -93.862605];
Zipcodes['50258'] = [42.224136, -93.073131];
Zipcodes['50261'] = [41.474943, -93.921017];
Zipcodes['50262'] = [40.862401, -93.819055];
Zipcodes['50263'] = [41.604176, -93.879689];
Zipcodes['50264'] = [40.892216, -93.709914];
Zipcodes['50265'] = [41.542743, -93.751089];
Zipcodes['50266'] = [41.562678, -93.814626];
Zipcodes['50268'] = [41.406996, -92.361709];
Zipcodes['50269'] = [42.264454, -93.010632];
Zipcodes['50271'] = [42.484402, -93.544889];
Zipcodes['50272'] = [41.088096, -93.256842];
Zipcodes['50273'] = [41.326464, -94.049041];
Zipcodes['50274'] = [41.383348, -94.860975];
Zipcodes['50275'] = [41.043716, -93.588523];
Zipcodes['50276'] = [41.86006, -93.922601];
Zipcodes['50277'] = [41.779659, -94.341008];
Zipcodes['50278'] = [42.155575, -93.30116];
Zipcodes['50309'] = [41.584192, -93.621998];
Zipcodes['50310'] = [41.627582, -93.67266];
Zipcodes['50311'] = [41.60098, -93.673379];
Zipcodes['50312'] = [41.576832, -93.682094];
Zipcodes['50313'] = [41.656895, -93.62454];
Zipcodes['50314'] = [41.604964, -93.6312];
Zipcodes['50315'] = [41.545367, -93.619184];
Zipcodes['50316'] = [41.608629, -93.599579];
Zipcodes['50317'] = [41.618131, -93.549374];
Zipcodes['50319'] = [41.591141, -93.602996];
Zipcodes['50320'] = [41.531043, -93.572356];
Zipcodes['50321'] = [41.534555, -93.662444];
Zipcodes['50322'] = [41.634164, -93.73436];
Zipcodes['50323'] = [41.638924, -93.818482];
Zipcodes['50324'] = [41.604709, -93.712724];
Zipcodes['50325'] = [41.613586, -93.799124];
Zipcodes['50327'] = [41.586369, -93.487707];
Zipcodes['50401'] = [43.154082, -93.199836];
Zipcodes['50420'] = [42.806197, -93.45679];
Zipcodes['50421'] = [42.842924, -93.623061];
Zipcodes['50423'] = [43.118278, -93.814497];
Zipcodes['50424'] = [43.403289, -93.941777];
Zipcodes['50426'] = [43.415119, -93.017244];
Zipcodes['50427'] = [42.835528, -93.221176];
Zipcodes['50428'] = [43.14002, -93.390941];
Zipcodes['50430'] = [42.977287, -93.956552];
Zipcodes['50431'] = [42.736478, -93.369881];
Zipcodes['50432'] = [43.223981, -93.797899];
Zipcodes['50433'] = [42.928492, -93.047736];
Zipcodes['50434'] = [43.257488, -93.448583];
Zipcodes['50435'] = [43.157531, -92.76881];
Zipcodes['50436'] = [43.259173, -93.685778];
Zipcodes['50438'] = [43.111533, -93.618639];
Zipcodes['50439'] = [42.944717, -93.626339];
Zipcodes['50440'] = [43.332205, -93.068989];
Zipcodes['50441'] = [42.739747, -93.220628];
Zipcodes['50444'] = [43.297226, -93.38363];
Zipcodes['50446'] = [43.333798, -93.449821];
Zipcodes['50447'] = [42.931173, -93.798345];
Zipcodes['50448'] = [43.349938, -93.240534];
Zipcodes['50449'] = [43.002467, -93.547142];
Zipcodes['50450'] = [43.421201, -93.522214];
Zipcodes['50451'] = [43.377524, -94.091461];
Zipcodes['50452'] = [42.781073, -93.367342];
Zipcodes['50453'] = [43.363364, -93.64742];
Zipcodes['50454'] = [43.385059, -92.739334];
Zipcodes['50455'] = [43.456953, -92.649842];
Zipcodes['50456'] = [43.291262, -93.216049];
Zipcodes['50457'] = [42.914178, -93.492797];
Zipcodes['50458'] = [43.15877, -93.012028];
Zipcodes['50459'] = [43.457469, -93.250502];
Zipcodes['50460'] = [43.22594, -92.690218];
Zipcodes['50461'] = [43.306168, -92.818741];
Zipcodes['50464'] = [43.251357, -93.076507];
Zipcodes['50465'] = [43.487021, -93.910562];
Zipcodes['50466'] = [43.386853, -92.538439];
Zipcodes['50467'] = [43.209092, -93.066238];
Zipcodes['50468'] = [43.032234, -92.947134];
Zipcodes['50469'] = [42.999343, -93.197104];
Zipcodes['50470'] = [42.746528, -93.553756];
Zipcodes['50471'] = [43.164821, -92.878354];
Zipcodes['50472'] = [43.406746, -92.935316];
Zipcodes['50473'] = [43.465984, -93.666682];
Zipcodes['50475'] = [42.882208, -93.214017];
Zipcodes['50476'] = [43.446804, -92.764052];
Zipcodes['50477'] = [42.987396, -93.327325];
Zipcodes['50478'] = [43.407488, -93.783601];
Zipcodes['50479'] = [42.941186, -93.408191];
Zipcodes['50480'] = [43.250367, -94.058607];
Zipcodes['50482'] = [43.124799, -93.48465];
Zipcodes['50483'] = [43.111708, -93.997206];
Zipcodes['50484'] = [43.23826, -93.906492];
Zipcodes['50501'] = [42.49164, -94.187122];
Zipcodes['50510'] = [42.759296, -94.985603];
Zipcodes['50511'] = [43.07508, -94.219197];
Zipcodes['50514'] = [43.415156, -94.47241];
Zipcodes['50515'] = [43.017181, -94.886111];
Zipcodes['50516'] = [42.623973, -94.136853];
Zipcodes['50517'] = [43.305294, -94.234287];
Zipcodes['50518'] = [42.512127, -94.382397];
Zipcodes['50519'] = [42.903123, -94.265426];
Zipcodes['50520'] = [42.80919, -94.397929];
Zipcodes['50521'] = [42.346223, -94.102821];
Zipcodes['50522'] = [43.185624, -94.185759];
Zipcodes['50523'] = [42.367792, -94.293563];
Zipcodes['50524'] = [42.594247, -94.358612];
Zipcodes['50525'] = [42.736483, -93.747336];
Zipcodes['50527'] = [42.969211, -94.792264];
Zipcodes['50528'] = [43.137123, -94.540229];
Zipcodes['50529'] = [42.722185, -94.200983];
Zipcodes['50530'] = [42.270278, -94.039589];
Zipcodes['50531'] = [43.454085, -94.609753];
Zipcodes['50532'] = [42.460334, -94.000746];
Zipcodes['50533'] = [42.652495, -93.911097];
Zipcodes['50535'] = [42.452741, -95.164045];
Zipcodes['50536'] = [43.117413, -94.695427];
Zipcodes['50538'] = [42.283905, -94.431873];
Zipcodes['50539'] = [43.235365, -94.418488];
Zipcodes['50540'] = [42.59835, -94.839981];
Zipcodes['50541'] = [42.710228, -94.452387];
Zipcodes['50542'] = [42.782976, -93.942692];
Zipcodes['50543'] = [42.274325, -94.306668];
Zipcodes['50544'] = [42.253675, -94.170419];
Zipcodes['50545'] = [42.797396, -94.080863];
Zipcodes['50546'] = [42.843735, -94.708078];
Zipcodes['50548'] = [42.71368, -94.222776];
Zipcodes['50551'] = [42.482417, -94.754854];
Zipcodes['50552'] = [42.456379, -94.460691];
Zipcodes['50554'] = [42.845822, -94.83994];
Zipcodes['50556'] = [43.45522, -94.196646];
Zipcodes['50557'] = [42.360729, -94.043692];
Zipcodes['50558'] = [42.861277, -94.16377];
Zipcodes['50559'] = [43.209075, -94.324743];
Zipcodes['50560'] = [42.955794, -94.104296];
Zipcodes['50561'] = [42.417707, -94.850759];
Zipcodes['50562'] = [42.961574, -94.64173];
Zipcodes['50563'] = [42.527824, -94.525043];
Zipcodes['50565'] = [42.857398, -95.000842];
Zipcodes['50566'] = [42.43777, -94.323652];
Zipcodes['50567'] = [42.523753, -95.08084];
Zipcodes['50568'] = [42.632967, -95.003877];
Zipcodes['50569'] = [42.403518, -94.13447];
Zipcodes['50570'] = [42.895475, -94.367016];
Zipcodes['50571'] = [42.634857, -94.580585];
Zipcodes['50573'] = [42.876796, -94.622557];
Zipcodes['50574'] = [42.718938, -94.699661];
Zipcodes['50575'] = [42.558799, -94.70064];
Zipcodes['50576'] = [42.802696, -95.17725];
Zipcodes['50577'] = [42.857719, -93.979052];
Zipcodes['50578'] = [43.306502, -94.549559];
Zipcodes['50579'] = [42.393763, -94.634162];
Zipcodes['50581'] = [42.842996, -94.549778];
Zipcodes['50582'] = [42.792689, -94.294103];
Zipcodes['50583'] = [42.432773, -94.985626];
Zipcodes['50585'] = [42.91753, -95.141865];
Zipcodes['50586'] = [42.41186, -94.425556];
Zipcodes['50588'] = [42.654596, -95.161555];
Zipcodes['50590'] = [43.406735, -94.304136];
Zipcodes['50591'] = [42.676819, -94.054783];
Zipcodes['50592'] = [42.729257, -95.183407];
Zipcodes['50593'] = [42.657988, -94.898247];
Zipcodes['50594'] = [42.585595, -94.039503];
Zipcodes['50595'] = [42.461417, -93.822578];
Zipcodes['50597'] = [42.975304, -94.451718];
Zipcodes['50598'] = [43.080212, -94.418384];
Zipcodes['50599'] = [42.579322, -93.823217];
Zipcodes['50601'] = [42.567777, -93.060052];
Zipcodes['50602'] = [42.741575, -92.805699];
Zipcodes['50603'] = [43.188939, -92.458215];
Zipcodes['50604'] = [42.601831, -92.896477];
Zipcodes['50605'] = [42.835586, -93.032796];
Zipcodes['50606'] = [42.747329, -91.691214];
Zipcodes['50607'] = [42.602699, -91.749049];
Zipcodes['50608'] = [42.588138, -92.960793];
Zipcodes['50609'] = [42.228377, -92.809466];
Zipcodes['50611'] = [42.819267, -92.911112];
Zipcodes['50612'] = [42.285484, -92.386851];
Zipcodes['50613'] = [42.531411, -92.485861];
Zipcodes['50616'] = [43.080501, -92.658875];
Zipcodes['50619'] = [42.79933, -92.66324];
Zipcodes['50620'] = [43.157125, -92.591191];
Zipcodes['50621'] = [42.247399, -92.91904];
Zipcodes['50622'] = [42.673622, -92.333317];
Zipcodes['50623'] = [42.526923, -92.209673];
Zipcodes['50624'] = [42.468747, -92.658814];
Zipcodes['50625'] = [42.748399, -92.988397];
Zipcodes['50626'] = [42.584123, -92.17147];
Zipcodes['50627'] = [42.35043, -93.093942];
Zipcodes['50628'] = [43.27271, -92.394046];
Zipcodes['50629'] = [42.648669, -92.071537];
Zipcodes['50630'] = [42.95768, -92.213959];
Zipcodes['50631'] = [42.885148, -92.309518];
Zipcodes['50632'] = [42.075097, -92.696061];
Zipcodes['50633'] = [42.675296, -93.126684];
Zipcodes['50634'] = [42.418187, -92.214258];
Zipcodes['50635'] = [42.202425, -92.696618];
Zipcodes['50636'] = [42.893601, -92.808582];
Zipcodes['50638'] = [42.356537, -92.794961];
Zipcodes['50641'] = [42.591881, -91.919414];
Zipcodes['50642'] = [42.439542, -92.816432];
Zipcodes['50643'] = [42.357981, -92.464157];
Zipcodes['50644'] = [42.477485, -91.89775];
Zipcodes['50645'] = [43.019599, -92.443698];
Zipcodes['50647'] = [42.650931, -92.49703];
Zipcodes['50648'] = [42.453115, -92.09323];
Zipcodes['50649'] = [42.662488, -92.909726];
Zipcodes['50650'] = [42.618248, -91.65604];
Zipcodes['50651'] = [42.324572, -92.201653];
Zipcodes['50652'] = [42.264539, -92.692336];
Zipcodes['50653'] = [42.968576, -92.885635];
Zipcodes['50654'] = [42.407392, -91.574014];
Zipcodes['50655'] = [42.76561, -91.891729];
Zipcodes['50657'] = [42.344546, -92.674044];
Zipcodes['50658'] = [42.950432, -92.538095];
Zipcodes['50659'] = [43.095838, -92.329051];
Zipcodes['50660'] = [42.577494, -92.641802];
Zipcodes['50662'] = [42.688662, -91.937486];
Zipcodes['50664'] = [42.706961, -92.072256];
Zipcodes['50665'] = [42.580753, -92.771682];
Zipcodes['50666'] = [42.85442, -92.503231];
Zipcodes['50667'] = [42.470015, -92.225558];
Zipcodes['50668'] = [42.692925, -92.228888];
Zipcodes['50669'] = [42.339158, -92.614997];
Zipcodes['50670'] = [42.677637, -92.615911];
Zipcodes['50671'] = [42.650429, -91.795819];
Zipcodes['50672'] = [42.42604, -93.071556];
Zipcodes['50673'] = [42.52505, -92.711288];
Zipcodes['50674'] = [42.838035, -92.115326];
Zipcodes['50675'] = [42.19782, -92.512092];
Zipcodes['50676'] = [42.798888, -92.280177];
Zipcodes['50677'] = [42.766686, -92.43976];
Zipcodes['50680'] = [42.444591, -92.917174];
Zipcodes['50681'] = [42.777552, -92.009278];
Zipcodes['50682'] = [42.449804, -91.694685];
Zipcodes['50701'] = [42.417552, -92.344572];
Zipcodes['50702'] = [42.461502, -92.313745];
Zipcodes['50703'] = [42.548059, -92.286431];
Zipcodes['50707'] = [42.477381, -92.281742];
Zipcodes['50801'] = [41.078671, -94.401822];
Zipcodes['50830'] = [41.022578, -94.190473];
Zipcodes['50831'] = [40.950844, -94.222363];
Zipcodes['50833'] = [40.670545, -94.700888];
Zipcodes['50835'] = [40.706376, -94.375545];
Zipcodes['50836'] = [40.632285, -94.497484];
Zipcodes['50837'] = [41.219823, -94.691439];
Zipcodes['50839'] = [41.048763, -94.822031];
Zipcodes['50840'] = [40.79019, -94.482618];
Zipcodes['50841'] = [41.009059, -94.768784];
Zipcodes['50842'] = [41.039906, -94.461796];
Zipcodes['50843'] = [41.236842, -94.885233];
Zipcodes['50845'] = [40.815197, -94.352173];
Zipcodes['50846'] = [41.308993, -94.576117];
Zipcodes['50847'] = [41.141664, -94.983877];
Zipcodes['50848'] = [40.791748, -94.764282];
Zipcodes['50849'] = [41.294526, -94.405017];
Zipcodes['50851'] = [40.908584, -94.537032];
Zipcodes['50853'] = [41.236859, -94.767897];
Zipcodes['50854'] = [40.679375, -94.21277];
Zipcodes['50857'] = [40.934815, -94.879538];
Zipcodes['50858'] = [41.211108, -94.409596];
Zipcodes['50859'] = [41.067099, -94.597342];
Zipcodes['50860'] = [40.617459, -94.347944];
Zipcodes['50861'] = [40.908431, -94.255922];
Zipcodes['50862'] = [40.811604, -94.647731];
Zipcodes['50863'] = [40.847781, -94.175734];
Zipcodes['50864'] = [40.97037, -94.978111];
Zipcodes['51001'] = [42.840544, -96.546124];
Zipcodes['51002'] = [42.702052, -95.318922];
Zipcodes['51003'] = [42.989594, -95.985959];
Zipcodes['51004'] = [42.380122, -95.918206];
Zipcodes['51005'] = [42.723603, -95.418791];
Zipcodes['51006'] = [42.310269, -95.610728];
Zipcodes['51007'] = [42.40221, -96.18858];
Zipcodes['51008'] = [42.811254, -96.267221];
Zipcodes['51009'] = [42.945024, -95.551435];
Zipcodes['51010'] = [42.097526, -95.938189];
Zipcodes['51011'] = [42.915627, -96.513876];
Zipcodes['51012'] = [42.745429, -95.560413];
Zipcodes['51014'] = [42.78764, -95.707313];
Zipcodes['51015'] = [42.339375, -96.081085];
Zipcodes['51016'] = [42.471834, -95.805254];
Zipcodes['51018'] = [42.449661, -95.680771];
Zipcodes['51019'] = [42.276752, -95.71986];
Zipcodes['51020'] = [42.515038, -95.410213];
Zipcodes['51022'] = [42.978178, -95.847262];
Zipcodes['51023'] = [43.00674, -96.463475];
Zipcodes['51024'] = [42.626486, -96.262887];
Zipcodes['51025'] = [42.497628, -95.556141];
Zipcodes['51026'] = [42.25555, -96.068353];
Zipcodes['51027'] = [42.965721, -96.327908];
Zipcodes['51028'] = [42.596655, -95.98764];
Zipcodes['51029'] = [42.885476, -95.541381];
Zipcodes['51030'] = [42.497741, -96.188321];
Zipcodes['51031'] = [42.80324, -96.186428];
Zipcodes['51033'] = [42.919369, -95.252397];
Zipcodes['51034'] = [42.162829, -95.789595];
Zipcodes['51035'] = [42.780788, -95.800528];
Zipcodes['51036'] = [42.969703, -96.189514];
Zipcodes['51037'] = [42.825694, -95.642679];
Zipcodes['51038'] = [42.703191, -96.316537];
Zipcodes['51039'] = [42.449218, -96.04956];
Zipcodes['51040'] = [42.03254, -96.068714];
Zipcodes['51041'] = [43.024397, -96.077033];
Zipcodes['51044'] = [42.306174, -95.918122];
Zipcodes['51045'] = [42.820319, -96.057947];
Zipcodes['51046'] = [42.961834, -95.67802];
Zipcodes['51047'] = [42.942362, -95.36954];
Zipcodes['51048'] = [42.550952, -95.84131];
Zipcodes['51049'] = [42.630096, -95.644373];
Zipcodes['51050'] = [42.80044, -95.947854];
Zipcodes['51051'] = [42.206095, -95.964941];
Zipcodes['51052'] = [42.308234, -96.265987];
Zipcodes['51053'] = [42.495057, -95.286548];
Zipcodes['51054'] = [42.380877, -96.335045];
Zipcodes['51055'] = [42.208203, -96.260324];
Zipcodes['51056'] = [42.241293, -95.956396];
Zipcodes['51058'] = [42.989315, -95.476943];
Zipcodes['51060'] = [42.042386, -95.684387];
Zipcodes['51061'] = [42.579722, -95.725258];
Zipcodes['51062'] = [42.697865, -96.534926];
Zipcodes['51063'] = [42.132788, -96.190705];
Zipcodes['51101'] = [42.493688, -96.394184];
Zipcodes['51103'] = [42.519729, -96.449305];
Zipcodes['51104'] = [42.534533, -96.403167];
Zipcodes['51105'] = [42.507867, -96.366338];
Zipcodes['51106'] = [42.463175, -96.312909];
Zipcodes['51108'] = [42.565994, -96.352523];
Zipcodes['51109'] = [42.610335, -96.469977];
Zipcodes['51111'] = [42.4185, -96.393103];
Zipcodes['51201'] = [43.182369, -95.865607];
Zipcodes['51230'] = [43.356197, -96.322121];
Zipcodes['51231'] = [43.105665, -95.741308];
Zipcodes['51232'] = [43.29504, -95.809539];
Zipcodes['51234'] = [43.192205, -96.019335];
Zipcodes['51235'] = [43.294885, -96.215338];
Zipcodes['51237'] = [43.336066, -96.000764];
Zipcodes['51238'] = [43.074388, -95.892427];
Zipcodes['51239'] = [43.203917, -96.147481];
Zipcodes['51240'] = [43.309361, -96.458419];
Zipcodes['51241'] = [43.451232, -96.46175];
Zipcodes['51242'] = [43.432274, -96.338057];
Zipcodes['51243'] = [43.440585, -95.896644];
Zipcodes['51244'] = [43.244307, -95.934382];
Zipcodes['51245'] = [43.077819, -95.601445];
Zipcodes['51246'] = [43.428834, -96.164704];
Zipcodes['51247'] = [43.193752, -96.338127];
Zipcodes['51248'] = [43.204439, -95.659092];
Zipcodes['51249'] = [43.407372, -95.730959];
Zipcodes['51250'] = [43.090933, -96.205811];
Zipcodes['51301'] = [43.153092, -95.149793];
Zipcodes['51331'] = [43.35956, -95.13082];
Zipcodes['51333'] = [43.136582, -95.001158];
Zipcodes['51334'] = [43.403986, -94.803653];
Zipcodes['51338'] = [43.205366, -95.31875];
Zipcodes['51340'] = [43.240489, -95.156123];
Zipcodes['51341'] = [43.018185, -95.036];
Zipcodes['51342'] = [43.247877, -94.73688];
Zipcodes['51343'] = [43.017604, -95.127508];
Zipcodes['51345'] = [43.422367, -95.436696];
Zipcodes['51346'] = [43.191331, -95.453632];
Zipcodes['51347'] = [43.422425, -95.318451];
Zipcodes['51350'] = [43.307533, -95.598273];
Zipcodes['51351'] = [43.315064, -95.183646];
Zipcodes['51354'] = [43.412347, -95.543273];
Zipcodes['51355'] = [43.388681, -95.140456];
Zipcodes['51357'] = [43.062161, -95.287599];
Zipcodes['51358'] = [43.129897, -94.89371];
Zipcodes['51360'] = [43.43372, -95.0859];
Zipcodes['51363'] = [43.433676, -94.946745];
Zipcodes['51364'] = [43.29928, -94.962943];
Zipcodes['51365'] = [43.309619, -94.733283];
Zipcodes['51366'] = [42.960703, -95.002954];
Zipcodes['51401'] = [42.06492, -94.870021];
Zipcodes['51430'] = [42.0897, -95.026636];
Zipcodes['51431'] = [42.344418, -95.351451];
Zipcodes['51432'] = [41.910095, -95.137973];
Zipcodes['51433'] = [42.266648, -94.875329];
Zipcodes['51436'] = [42.189147, -95.004791];
Zipcodes['51439'] = [42.08284, -95.589058];
Zipcodes['51440'] = [41.911709, -94.81383];
Zipcodes['51441'] = [42.113316, -95.315424];
Zipcodes['51442'] = [42.03126, -95.356812];
Zipcodes['51443'] = [42.069895, -94.697048];
Zipcodes['51444'] = [42.012169, -94.973518];
Zipcodes['51445'] = [42.328061, -95.467708];
Zipcodes['51446'] = [41.776617, -95.192705];
Zipcodes['51447'] = [41.721032, -95.191945];
Zipcodes['51448'] = [42.205359, -95.309789];
Zipcodes['51449'] = [42.266, -94.734403];
Zipcodes['51450'] = [42.316392, -95.020732];
Zipcodes['51451'] = [42.185084, -94.695163];
Zipcodes['51452'] = [42.122421, -94.783642];
Zipcodes['51453'] = [42.26227, -94.545537];
Zipcodes['51454'] = [41.884818, -95.190586];
Zipcodes['51455'] = [41.907807, -95.056903];
Zipcodes['51458'] = [42.326375, -95.235308];
Zipcodes['51459'] = [42.044967, -94.637787];
Zipcodes['51460'] = [42.132415, -95.571034];
Zipcodes['51461'] = [42.17409, -95.474138];
Zipcodes['51462'] = [42.014403, -94.556511];
Zipcodes['51463'] = [41.907424, -94.912652];
Zipcodes['51465'] = [42.086931, -95.200635];
Zipcodes['51466'] = [42.247029, -95.099555];
Zipcodes['51467'] = [42.07192, -95.107346];
Zipcodes['51501'] = [41.227277, -95.878439];
Zipcodes['51503'] = [41.22322, -95.784481];
Zipcodes['51510'] = [41.288533, -95.916872];
Zipcodes['51520'] = [41.969195, -95.459299];
Zipcodes['51521'] = [41.485468, -95.344684];
Zipcodes['51523'] = [41.915211, -96.06771];
Zipcodes['51525'] = [41.234039, -95.420008];
Zipcodes['51526'] = [41.37389, -95.871784];
Zipcodes['51527'] = [41.837131, -95.337258];
Zipcodes['51528'] = [41.908916, -95.501172];
Zipcodes['51529'] = [41.860476, -95.643463];
Zipcodes['51530'] = [41.787456, -95.430482];
Zipcodes['51531'] = [41.5875, -95.088386];
Zipcodes['51532'] = [41.137981, -95.109116];
Zipcodes['51533'] = [41.039454, -95.378172];
Zipcodes['51534'] = [41.041259, -95.717828];
Zipcodes['51535'] = [41.226474, -95.126714];
Zipcodes['51536'] = [41.384331, -95.373214];
Zipcodes['51537'] = [41.650135, -95.297189];
Zipcodes['51540'] = [41.024322, -95.498233];
Zipcodes['51541'] = [41.139096, -95.41618];
Zipcodes['51542'] = [41.440364, -95.831845];
Zipcodes['51543'] = [41.657919, -95.073131];
Zipcodes['51544'] = [41.313903, -95.103393];
Zipcodes['51545'] = [41.850978, -96.014628];
Zipcodes['51546'] = [41.637077, -95.766074];
Zipcodes['51548'] = [41.317134, -95.635511];
Zipcodes['51549'] = [41.18583, -95.466707];
Zipcodes['51550'] = [41.692355, -95.873905];
Zipcodes['51551'] = [40.99082, -95.587501];
Zipcodes['51552'] = [41.486113, -95.114407];
Zipcodes['51553'] = [41.4146, -95.547066];
Zipcodes['51554'] = [41.145106, -95.681207];
Zipcodes['51555'] = [41.559503, -95.915257];
Zipcodes['51556'] = [41.64287, -96.040104];
Zipcodes['51557'] = [41.733644, -96.006365];
Zipcodes['51558'] = [41.915933, -95.880636];
Zipcodes['51559'] = [41.455393, -95.643037];
Zipcodes['51560'] = [41.313531, -95.400865];
Zipcodes['51561'] = [41.005051, -95.809552];
Zipcodes['51562'] = [41.725074, -95.493322];
Zipcodes['51563'] = [41.570675, -95.585585];
Zipcodes['51564'] = [41.80959, -95.922828];
Zipcodes['51565'] = [41.642892, -95.524498];
Zipcodes['51566'] = [41.015123, -95.229709];
Zipcodes['51570'] = [41.527724, -95.465477];
Zipcodes['51571'] = [41.14233, -95.616565];
Zipcodes['51572'] = [41.984413, -95.807762];
Zipcodes['51573'] = [40.979235, -95.094799];
Zipcodes['51575'] = [41.240315, -95.602468];
Zipcodes['51576'] = [41.382876, -95.714924];
Zipcodes['51577'] = [41.463613, -95.203768];
Zipcodes['51578'] = [41.720025, -95.39504];
Zipcodes['51579'] = [41.761721, -95.719127];
Zipcodes['51601'] = [40.735527, -95.346985];
Zipcodes['51630'] = [40.601029, -95.194274];
Zipcodes['51631'] = [40.591423, -95.009992];
Zipcodes['51632'] = [40.745841, -95.04658];
Zipcodes['51636'] = [40.667147, -95.235201];
Zipcodes['51637'] = [40.620441, -95.118338];
Zipcodes['51638'] = [40.837439, -95.266231];
Zipcodes['51639'] = [40.720105, -95.466467];
Zipcodes['51640'] = [40.626218, -95.661342];
Zipcodes['51645'] = [40.882063, -95.422875];
Zipcodes['51646'] = [40.747685, -94.874449];
Zipcodes['51647'] = [40.605426, -95.345104];
Zipcodes['51648'] = [40.739475, -95.800397];
Zipcodes['51649'] = [40.850406, -95.527041];
Zipcodes['51650'] = [40.678329, -95.550206];
Zipcodes['51651'] = [40.656567, -95.034694];
Zipcodes['51652'] = [40.772515, -95.623138];
Zipcodes['51653'] = [40.878432, -95.682322];
Zipcodes['51654'] = [40.834899, -95.78159];
Zipcodes['51656'] = [40.735257, -95.154337];
Zipcodes['52001'] = [42.548323, -90.69367];
Zipcodes['52002'] = [42.524766, -90.771903];
Zipcodes['52003'] = [42.430701, -90.669682];
Zipcodes['52030'] = [42.153194, -90.592277];
Zipcodes['52031'] = [42.252564, -90.486421];
Zipcodes['52032'] = [42.289646, -90.839534];
Zipcodes['52033'] = [42.279419, -91.001769];
Zipcodes['52035'] = [42.670738, -91.181213];
Zipcodes['52036'] = [42.472865, -91.340863];
Zipcodes['52037'] = [41.967208, -90.633725];
Zipcodes['52038'] = [42.586686, -91.561477];
Zipcodes['52039'] = [42.538097, -90.857411];
Zipcodes['52040'] = [42.503641, -91.14169];
Zipcodes['52041'] = [42.506068, -91.25505];
Zipcodes['52042'] = [42.678593, -91.36016];
Zipcodes['52043'] = [42.834004, -91.426289];
Zipcodes['52044'] = [42.768078, -91.323268];
Zipcodes['52045'] = [42.454871, -90.93113];
Zipcodes['52046'] = [42.440225, -91.011915];
Zipcodes['52047'] = [42.969286, -91.341757];
Zipcodes['52048'] = [42.737292, -91.255776];
Zipcodes['52049'] = [42.885565, -91.199378];
Zipcodes['52050'] = [42.610298, -91.326733];
Zipcodes['52052'] = [42.742443, -91.116701];
Zipcodes['52053'] = [42.606556, -90.963965];
Zipcodes['52054'] = [42.305599, -90.628157];
Zipcodes['52056'] = [42.604028, -91.072578];
Zipcodes['52057'] = [42.490877, -91.453192];
Zipcodes['52060'] = [42.101196, -90.680278];
Zipcodes['52064'] = [42.093574, -90.310826];
Zipcodes['52065'] = [42.583525, -91.102046];
Zipcodes['52066'] = [42.682033, -90.960141];
Zipcodes['52068'] = [42.423804, -90.811257];
Zipcodes['52069'] = [42.060515, -90.430017];
Zipcodes['52070'] = [42.084381, -90.208468];
Zipcodes['52071'] = [42.362383, -90.542345];
Zipcodes['52072'] = [42.92025, -91.380259];
Zipcodes['52073'] = [42.618868, -90.821643];
Zipcodes['52074'] = [42.110483, -90.462429];
Zipcodes['52075'] = [42.165739, -90.479174];
Zipcodes['52076'] = [42.697168, -91.510418];
Zipcodes['52077'] = [42.820999, -91.562518];
Zipcodes['52078'] = [42.395826, -91.115713];
Zipcodes['52079'] = [42.287754, -90.719099];
Zipcodes['52101'] = [43.366329, -91.772937];
Zipcodes['52132'] = [43.211162, -91.928137];
Zipcodes['52133'] = [43.108906, -91.660578];
Zipcodes['52134'] = [43.471562, -92.400791];
Zipcodes['52135'] = [43.02061, -91.662781];
Zipcodes['52136'] = [43.371394, -92.112398];
Zipcodes['52140'] = [43.445644, -91.534572];
Zipcodes['52141'] = [42.931188, -91.640929];
Zipcodes['52142'] = [42.825998, -91.7983];
Zipcodes['52144'] = [43.139611, -91.960711];
Zipcodes['52146'] = [43.175923, -91.206021];
Zipcodes['52147'] = [42.944781, -91.959293];
Zipcodes['52151'] = [43.346405, -91.264794];
Zipcodes['52154'] = [43.117985, -92.165058];
Zipcodes['52155'] = [43.412758, -92.295716];
Zipcodes['52156'] = [43.053113, -91.465349];
Zipcodes['52157'] = [43.021895, -91.240309];
Zipcodes['52158'] = [43.043301, -91.192423];
Zipcodes['52159'] = [43.078784, -91.365585];
Zipcodes['52160'] = [43.46905, -91.351235];
Zipcodes['52161'] = [43.127306, -91.756216];
Zipcodes['52162'] = [43.11063, -91.552658];
Zipcodes['52163'] = [43.219765, -92.103146];
Zipcodes['52164'] = [42.854706, -91.906611];
Zipcodes['52165'] = [43.308542, -91.994305];
Zipcodes['52166'] = [43.067951, -91.935511];
Zipcodes['52168'] = [43.203409, -91.953412];
Zipcodes['52169'] = [42.860708, -91.663069];
Zipcodes['52170'] = [43.228811, -91.304824];
Zipcodes['52171'] = [43.068229, -92.052943];
Zipcodes['52172'] = [43.271159, -91.48377];
Zipcodes['52175'] = [42.99367, -91.823872];
Zipcodes['52201'] = [41.32966, -91.547062];
Zipcodes['52202'] = [42.159208, -91.637843];
Zipcodes['52203'] = [41.813857, -91.89159];
Zipcodes['52205'] = [42.116219, -91.289568];
Zipcodes['52206'] = [41.991869, -91.887167];
Zipcodes['52207'] = [42.092794, -90.829985];
Zipcodes['52208'] = [41.876782, -92.25691];
Zipcodes['52209'] = [41.908972, -92.09756];
Zipcodes['52210'] = [42.334017, -91.992953];
Zipcodes['52211'] = [41.75389, -92.449753];
Zipcodes['52212'] = [42.108797, -91.090273];
Zipcodes['52213'] = [42.201851, -91.75965];
Zipcodes['52214'] = [42.187891, -91.509279];
Zipcodes['52215'] = [41.913536, -92.405305];
Zipcodes['52216'] = [41.883591, -91.024261];
Zipcodes['52217'] = [42.079487, -92.410663];
Zipcodes['52218'] = [42.295403, -91.538578];
Zipcodes['52219'] = [42.237527, -91.425728];
Zipcodes['52220'] = [41.726613, -91.998528];
Zipcodes['52221'] = [41.640929, -92.331699];
Zipcodes['52222'] = [41.576405, -92.34065];
Zipcodes['52223'] = [42.412626, -91.324396];
Zipcodes['52224'] = [42.157533, -92.293719];
Zipcodes['52225'] = [42.012913, -92.326336];
Zipcodes['52227'] = [41.894414, -91.57022];
Zipcodes['52228'] = [41.902291, -91.788703];
Zipcodes['52229'] = [42.144629, -92.169234];
Zipcodes['52231'] = [41.358831, -92.058629];
Zipcodes['52232'] = [41.804868, -92.347089];
Zipcodes['52233'] = [42.04922, -91.687328];
Zipcodes['52235'] = [41.578151, -91.531874];
Zipcodes['52236'] = [41.733399, -91.876355];
Zipcodes['52237'] = [42.340836, -91.251952];
Zipcodes['52240'] = [41.630753, -91.498781];
Zipcodes['52241'] = [41.697221, -91.597452];
Zipcodes['52242'] = [41.662939, -91.54355];
Zipcodes['52245'] = [41.672878, -91.512105];
Zipcodes['52246'] = [41.648386, -91.596793];
Zipcodes['52247'] = [41.518674, -91.72329];
Zipcodes['52248'] = [41.345772, -91.935346];
Zipcodes['52249'] = [42.012008, -92.205169];
Zipcodes['52251'] = [41.713615, -92.210074];
Zipcodes['52252'] = [42.197129, -91.227238];
Zipcodes['52253'] = [41.895347, -91.353373];
Zipcodes['52254'] = [41.952276, -90.805353];
Zipcodes['52255'] = [41.862918, -90.961266];
Zipcodes['52257'] = [41.914849, -92.172388];
Zipcodes['52301'] = [41.788471, -92.082542];
Zipcodes['52302'] = [42.065415, -91.560853];
Zipcodes['52305'] = [42.017962, -91.34211];
Zipcodes['52306'] = [41.887141, -91.254212];
Zipcodes['52307'] = [41.791523, -91.902448];
Zipcodes['52308'] = [41.573345, -92.159358];
Zipcodes['52309'] = [42.131684, -90.888642];
Zipcodes['52310'] = [42.220372, -91.195364];
Zipcodes['52312'] = [42.006168, -91.245263];
Zipcodes['52313'] = [42.257241, -92.096686];
Zipcodes['52314'] = [41.935711, -91.447883];
Zipcodes['52315'] = [42.001821, -91.969805];
Zipcodes['52316'] = [41.545573, -92.094822];
Zipcodes['52317'] = [41.767111, -91.634497];
Zipcodes['52318'] = [41.89006, -91.896534];
Zipcodes['52320'] = [41.999148, -91.14836];
Zipcodes['52321'] = [42.141694, -90.983093];
Zipcodes['52322'] = [41.692718, -91.762785];
Zipcodes['52323'] = [41.984429, -90.953333];
Zipcodes['52324'] = [42.06395, -91.806099];
Zipcodes['52325'] = [41.583907, -91.925884];
Zipcodes['52326'] = [42.394756, -91.75651];
Zipcodes['52327'] = [41.477136, -91.582989];
Zipcodes['52328'] = [42.071943, -91.666403];
Zipcodes['52329'] = [42.351929, -91.860116];
Zipcodes['52330'] = [42.352602, -91.50077];
Zipcodes['52332'] = [42.098053, -91.887293];
Zipcodes['52333'] = [41.80367, -91.487121];
Zipcodes['52334'] = [41.730797, -91.94354];
Zipcodes['52335'] = [41.467961, -92.053796];
Zipcodes['52336'] = [42.073784, -91.441835];
Zipcodes['52337'] = [41.88215, -91.142841];
Zipcodes['52338'] = [41.8179, -91.707501];
Zipcodes['52339'] = [41.927944, -92.587091];
Zipcodes['52340'] = [41.705564, -91.676678];
Zipcodes['52341'] = [42.102854, -91.726179];
Zipcodes['52342'] = [42.043495, -92.558548];
Zipcodes['52344'] = [42.292007, -91.683283];
Zipcodes['52345'] = [42.222424, -91.891704];
Zipcodes['52346'] = [42.012974, -92.081192];
Zipcodes['52347'] = [41.713462, -92.285895];
Zipcodes['52348'] = [41.9933, -92.385955];
Zipcodes['52349'] = [42.172181, -92.009259];
Zipcodes['52351'] = [41.874913, -91.835325];
Zipcodes['52352'] = [42.28913, -91.777966];
Zipcodes['52353'] = [41.298834, -91.715643];
Zipcodes['52354'] = [41.918839, -91.988595];
Zipcodes['52355'] = [41.460617, -92.191832];
Zipcodes['52356'] = [41.474907, -91.847686];
Zipcodes['52358'] = [41.6914, -91.318432];
Zipcodes['52359'] = [41.354312, -91.810076];
Zipcodes['52361'] = [41.640938, -92.015979];
Zipcodes['52362'] = [42.058411, -91.003185];
Zipcodes['52401'] = [41.975486, -91.659172];
Zipcodes['52402'] = [42.023172, -91.659613];
Zipcodes['52403'] = [41.965959, -91.566054];
Zipcodes['52404'] = [41.923046, -91.688501];
Zipcodes['52405'] = [41.983395, -91.746156];
Zipcodes['52411'] = [42.053819, -91.729266];
Zipcodes['52501'] = [41.044369, -92.42906];
Zipcodes['52530'] = [40.989078, -92.296206];
Zipcodes['52531'] = [41.025726, -92.819758];
Zipcodes['52533'] = [41.008386, -92.146425];
Zipcodes['52534'] = [41.273425, -92.680696];
Zipcodes['52535'] = [40.862943, -91.959573];
Zipcodes['52536'] = [40.957466, -92.60891];
Zipcodes['52537'] = [40.734974, -92.413637];
Zipcodes['52540'] = [41.15681, -91.823033];
Zipcodes['52542'] = [40.61985, -92.056237];
Zipcodes['52543'] = [41.201977, -92.512669];
Zipcodes['52544'] = [40.706642, -92.912272];
Zipcodes['52548'] = [41.08548, -92.53063];
Zipcodes['52549'] = [40.618642, -92.943113];
Zipcodes['52550'] = [41.313927, -92.356909];
Zipcodes['52551'] = [40.818884, -92.100387];
Zipcodes['52552'] = [40.827093, -92.548748];
Zipcodes['52553'] = [41.148432, -92.654929];
Zipcodes['52554'] = [40.92535, -92.235742];
Zipcodes['52555'] = [40.644315, -92.816227];
Zipcodes['52556'] = [41.024219, -91.927519];
Zipcodes['52557'] = [41.020067, -91.966364];
Zipcodes['52560'] = [40.854846, -92.249246];
Zipcodes['52561'] = [41.220289, -92.452261];
Zipcodes['52562'] = [41.265387, -92.248586];
Zipcodes['52563'] = [41.168346, -92.281811];
Zipcodes['52565'] = [40.742078, -91.934499];
Zipcodes['52566'] = [41.128897, -92.505496];
Zipcodes['52567'] = [40.93172, -92.072191];
Zipcodes['52568'] = [41.177898, -92.250771];
Zipcodes['52569'] = [40.980731, -93.049608];
Zipcodes['52570'] = [40.687408, -92.1462];
Zipcodes['52571'] = [40.873941, -92.832351];
Zipcodes['52572'] = [40.670536, -92.688414];
Zipcodes['52573'] = [40.627569, -91.928849];
Zipcodes['52574'] = [40.809808, -92.963294];
Zipcodes['52576'] = [41.215153, -92.109419];
Zipcodes['52577'] = [41.273342, -92.662306];
Zipcodes['52580'] = [41.105218, -92.090331];
Zipcodes['52581'] = [40.80122, -93.063949];
Zipcodes['52583'] = [40.804862, -93.146277];
Zipcodes['52584'] = [40.653474, -92.240759];
Zipcodes['52585'] = [41.189282, -91.986227];
Zipcodes['52586'] = [41.344968, -92.461377];
Zipcodes['52588'] = [40.857851, -92.160154];
Zipcodes['52590'] = [40.665711, -93.144643];
Zipcodes['52591'] = [41.316623, -92.191359];
Zipcodes['52593'] = [40.772293, -92.746226];
Zipcodes['52594'] = [40.836021, -92.652405];
Zipcodes['52595'] = [41.288471, -92.615052];
Zipcodes['52601'] = [40.85605, -91.129751];
Zipcodes['52619'] = [40.520145, -91.565172];
Zipcodes['52620'] = [40.702366, -91.796847];
Zipcodes['52621'] = [41.208542, -91.533755];
Zipcodes['52623'] = [40.849422, -91.337312];
Zipcodes['52624'] = [40.734794, -91.3361];
Zipcodes['52625'] = [40.660784, -91.574198];
Zipcodes['52626'] = [40.637455, -91.725591];
Zipcodes['52627'] = [40.658205, -91.356276];
Zipcodes['52630'] = [40.805616, -91.693581];
Zipcodes['52631'] = [40.783093, -91.612355];
Zipcodes['52632'] = [40.431939, -91.444612];
Zipcodes['52635'] = [40.991415, -91.761296];
Zipcodes['52637'] = [41.017547, -91.145888];
Zipcodes['52638'] = [40.801453, -91.242327];
Zipcodes['52639'] = [40.532566, -91.478323];
Zipcodes['52640'] = [41.101237, -91.280466];
Zipcodes['52641'] = [40.98772, -91.590166];
Zipcodes['52644'] = [41.039861, -91.41535];
Zipcodes['52645'] = [40.912281, -91.404298];
Zipcodes['52646'] = [41.069656, -91.016776];
Zipcodes['52647'] = [41.134185, -91.545051];
Zipcodes['52649'] = [40.83854, -91.612136];
Zipcodes['52650'] = [40.951205, -91.152034];
Zipcodes['52651'] = [40.864146, -91.81372];
Zipcodes['52652'] = [41.097935, -91.554226];
Zipcodes['52653'] = [41.16293, -91.153993];
Zipcodes['52654'] = [41.140693, -91.671749];
Zipcodes['52655'] = [40.847264, -91.213914];
Zipcodes['52656'] = [40.739814, -91.4555];
Zipcodes['52657'] = [40.767847, -91.516424];
Zipcodes['52658'] = [40.699404, -91.229654];
Zipcodes['52659'] = [41.132465, -91.436721];
Zipcodes['52660'] = [40.994596, -91.29937];
Zipcodes['52701'] = [41.981984, -90.249063];
Zipcodes['52720'] = [41.587724, -91.173386];
Zipcodes['52721'] = [41.755878, -90.963298];
Zipcodes['52722'] = [41.570155, -90.470122];
Zipcodes['52726'] = [41.496325, -90.780378];
Zipcodes['52727'] = [41.954788, -90.32594];
Zipcodes['52728'] = [41.458777, -90.72648];
Zipcodes['52729'] = [41.800183, -90.735691];
Zipcodes['52730'] = [41.775668, -90.335358];
Zipcodes['52731'] = [41.966353, -90.485523];
Zipcodes['52732'] = [41.891044, -90.253005];
Zipcodes['52737'] = [41.259178, -91.374738];
Zipcodes['52738'] = [41.273559, -91.377904];
Zipcodes['52739'] = [41.373418, -91.36748];
Zipcodes['52742'] = [41.838299, -90.519654];
Zipcodes['52745'] = [41.718602, -90.766483];
Zipcodes['52746'] = [41.716455, -90.670392];
Zipcodes['52747'] = [41.598084, -90.911712];
Zipcodes['52748'] = [41.665776, -90.554924];
Zipcodes['52749'] = [41.346036, -91.131145];
Zipcodes['52750'] = [41.937024, -90.397049];
Zipcodes['52751'] = [41.835204, -90.699664];
Zipcodes['52752'] = [41.274656, -91.191072];
Zipcodes['52753'] = [41.628548, -90.385928];
Zipcodes['52754'] = [41.338759, -91.24234];
Zipcodes['52755'] = [41.479344, -91.434347];
Zipcodes['52756'] = [41.729113, -90.54159];
Zipcodes['52757'] = [41.803385, -90.355187];
Zipcodes['52758'] = [41.742414, -90.437314];
Zipcodes['52759'] = [41.460439, -90.809851];
Zipcodes['52760'] = [41.552238, -91.068571];
Zipcodes['52761'] = [41.439931, -91.064259];
Zipcodes['52765'] = [41.720904, -90.869084];
Zipcodes['52766'] = [41.467543, -91.295421];
Zipcodes['52767'] = [41.573826, -90.424992];
Zipcodes['52768'] = [41.70068, -90.386997];
Zipcodes['52769'] = [41.605804, -90.847235];
Zipcodes['52771'] = [42.019615, -90.257053];
Zipcodes['52772'] = [41.741855, -91.154707];
Zipcodes['52773'] = [41.621544, -90.752751];
Zipcodes['52774'] = [41.907538, -90.595907];
Zipcodes['52776'] = [41.578835, -91.273449];
Zipcodes['52777'] = [41.847361, -90.855301];
Zipcodes['52778'] = [41.604586, -91.006403];
Zipcodes['52801'] = [41.521038, -90.574849];
Zipcodes['52802'] = [41.494514, -90.637187];
Zipcodes['52803'] = [41.538947, -90.555977];
Zipcodes['52804'] = [41.532477, -90.681245];
Zipcodes['52806'] = [41.589169, -90.625469];
Zipcodes['52807'] = [41.610645, -90.516298];
Zipcodes['53001'] = [43.607877, -88.055245];
Zipcodes['53002'] = [43.463972, -88.352057];
Zipcodes['53003'] = [43.212283, -88.519618];
Zipcodes['53004'] = [43.499517, -87.880511];
Zipcodes['53005'] = [43.062401, -88.098804];
Zipcodes['53006'] = [43.620098, -88.533555];
Zipcodes['53007'] = [43.108448, -88.072232];
Zipcodes['53010'] = [43.612534, -88.272017];
Zipcodes['53011'] = [43.659584, -88.091389];
Zipcodes['53012'] = [43.314732, -88.034639];
Zipcodes['53013'] = [43.565318, -87.846917];
Zipcodes['53014'] = [44.026076, -88.176312];
Zipcodes['53015'] = [43.907689, -87.785999];
Zipcodes['53016'] = [43.311063, -88.717679];
Zipcodes['53017'] = [43.201546, -88.252557];
Zipcodes['53018'] = [43.048156, -88.3905];
Zipcodes['53019'] = [43.696272, -88.302788];
Zipcodes['53020'] = [43.861777, -88.011415];
Zipcodes['53021'] = [43.49465, -88.009329];
Zipcodes['53022'] = [43.232179, -88.1158];
Zipcodes['53023'] = [43.780438, -88.10667];
Zipcodes['53024'] = [43.332043, -87.932727];
Zipcodes['53027'] = [43.316267, -88.371075];
Zipcodes['53029'] = [43.146603, -88.341];
Zipcodes['53031'] = [43.640401, -87.91782];
Zipcodes['53032'] = [43.446723, -88.623452];
Zipcodes['53033'] = [43.239613, -88.25398];
Zipcodes['53034'] = [43.330168, -88.618784];
Zipcodes['53035'] = [43.387774, -88.543422];
Zipcodes['53036'] = [43.179661, -88.575045];
Zipcodes['53037'] = [43.312701, -88.160386];
Zipcodes['53038'] = [43.084577, -88.790993];
Zipcodes['53039'] = [43.369786, -88.709466];
Zipcodes['53040'] = [43.526965, -88.191027];
Zipcodes['53042'] = [43.956873, -87.972329];
Zipcodes['53044'] = [43.738345, -87.783011];
Zipcodes['53045'] = [43.058889, -88.15252];
Zipcodes['53046'] = [43.15724, -88.158198];
Zipcodes['53047'] = [43.260504, -88.628678];
Zipcodes['53048'] = [43.573797, -88.457153];
Zipcodes['53049'] = [43.886116, -88.288605];
Zipcodes['53050'] = [43.510549, -88.547354];
Zipcodes['53051'] = [43.159411, -88.121548];
Zipcodes['53057'] = [43.794689, -88.236793];
Zipcodes['53058'] = [43.112352, -88.411579];
Zipcodes['53059'] = [43.288567, -88.525136];
Zipcodes['53061'] = [43.945427, -88.120654];
Zipcodes['53063'] = [43.9635, -87.802928];
Zipcodes['53065'] = [43.679772, -88.571625];
Zipcodes['53066'] = [43.113916, -88.489195];
Zipcodes['53069'] = [43.112665, -88.432986];
Zipcodes['53070'] = [43.620528, -87.806981];
Zipcodes['53072'] = [43.079955, -88.266595];
Zipcodes['53073'] = [43.751541, -87.996265];
Zipcodes['53074'] = [43.431139, -87.887322];
Zipcodes['53075'] = [43.564515, -87.990969];
Zipcodes['53076'] = [43.269659, -88.208999];
Zipcodes['53078'] = [43.317703, -88.466213];
Zipcodes['53079'] = [43.814532, -88.199468];
Zipcodes['53080'] = [43.405726, -87.986276];
Zipcodes['53081'] = [43.710329, -87.733076];
Zipcodes['53083'] = [43.815995, -87.764488];
Zipcodes['53085'] = [43.732838, -87.855452];
Zipcodes['53086'] = [43.329685, -88.273217];
Zipcodes['53088'] = [44.071746, -88.299592];
Zipcodes['53089'] = [43.144625, -88.233893];
Zipcodes['53090'] = [43.462267, -88.171686];
Zipcodes['53091'] = [43.499624, -88.434562];
Zipcodes['53092'] = [43.214964, -87.951218];
Zipcodes['53093'] = [43.650061, -87.93557];
Zipcodes['53094'] = [43.144017, -88.731994];
Zipcodes['53095'] = [43.387671, -88.158285];
Zipcodes['53097'] = [43.242049, -88.009823];
Zipcodes['53098'] = [43.253742, -88.710584];
Zipcodes['53099'] = [43.380446, -88.525704];
Zipcodes['53103'] = [42.880533, -88.217334];
Zipcodes['53104'] = [42.547457, -88.03928];
Zipcodes['53105'] = [42.6636, -88.280304];
Zipcodes['53108'] = [42.815148, -87.942986];
Zipcodes['53110'] = [42.950505, -87.860883];
Zipcodes['53114'] = [42.609352, -88.745886];
Zipcodes['53115'] = [42.656603, -88.668293];
Zipcodes['53118'] = [42.962495, -88.491539];
Zipcodes['53119'] = [42.89309, -88.485608];
Zipcodes['53120'] = [42.80275, -88.417448];
Zipcodes['53121'] = [42.719665, -88.534317];
Zipcodes['53122'] = [43.04752, -88.087029];
Zipcodes['53125'] = [42.545706, -88.561668];
Zipcodes['53126'] = [42.794482, -87.997744];
Zipcodes['53127'] = [42.956246, -88.380422];
Zipcodes['53128'] = [42.528607, -88.335232];
Zipcodes['53129'] = [42.939993, -88.000857];
Zipcodes['53130'] = [42.941231, -88.0495];
Zipcodes['53132'] = [42.886404, -88.012027];
Zipcodes['53137'] = [43.007883, -88.669078];
Zipcodes['53139'] = [42.685512, -88.120797];
Zipcodes['53140'] = [42.620224, -87.828347];
Zipcodes['53142'] = [42.561695, -87.891757];
Zipcodes['53143'] = [42.561565, -87.828956];
Zipcodes['53144'] = [42.622122, -87.920178];
Zipcodes['53146'] = [42.973705, -88.155385];
Zipcodes['53147'] = [42.558204, -88.451878];
Zipcodes['53149'] = [42.875371, -88.341581];
Zipcodes['53150'] = [42.871356, -88.13432];
Zipcodes['53151'] = [42.97393, -88.095566];
Zipcodes['53153'] = [42.940891, -88.402108];
Zipcodes['53154'] = [42.878693, -87.898676];
Zipcodes['53156'] = [42.889875, -88.588845];
Zipcodes['53157'] = [42.537694, -88.368555];
Zipcodes['53158'] = [42.52459, -87.885754];
Zipcodes['53167'] = [42.730069, -88.225404];
Zipcodes['53168'] = [42.575473, -88.124355];
Zipcodes['53170'] = [42.555548, -88.156246];
Zipcodes['53172'] = [42.911998, -87.859664];
Zipcodes['53176'] = [42.643045, -88.414458];
Zipcodes['53177'] = [42.704395, -87.933959];
Zipcodes['53178'] = [43.029545, -88.596788];
Zipcodes['53179'] = [42.513801, -88.130588];
Zipcodes['53181'] = [42.519845, -88.240997];
Zipcodes['53182'] = [42.697723, -88.040516];
Zipcodes['53183'] = [43.005837, -88.370357];
Zipcodes['53184'] = [42.520486, -88.613199];
Zipcodes['53185'] = [42.798205, -88.194451];
Zipcodes['53186'] = [43.022155, -88.204676];
Zipcodes['53188'] = [43.020011, -88.294411];
Zipcodes['53189'] = [42.944064, -88.290974];
Zipcodes['53190'] = [42.807774, -88.736204];
Zipcodes['53191'] = [42.577543, -88.545964];
Zipcodes['53192'] = [42.516451, -88.184286];
Zipcodes['53195'] = [42.513955, -88.490108];
Zipcodes['53202'] = [43.049673, -87.892384];
Zipcodes['53203'] = [43.03807, -87.914533];
Zipcodes['53204'] = [43.018587, -87.926053];
Zipcodes['53205'] = [43.053576, -87.933531];
Zipcodes['53206'] = [43.07523, -87.933521];
Zipcodes['53207'] = [42.975097, -87.8989];
Zipcodes['53208'] = [43.043979, -87.968096];
Zipcodes['53209'] = [43.127801, -87.949434];
Zipcodes['53210'] = [43.068886, -87.973815];
Zipcodes['53211'] = [43.083056, -87.883305];
Zipcodes['53212'] = [43.073857, -87.908604];
Zipcodes['53213'] = [43.048801, -88.001825];
Zipcodes['53214'] = [43.020328, -88.012866];
Zipcodes['53215'] = [42.999273, -87.943018];
Zipcodes['53216'] = [43.086333, -87.976321];
Zipcodes['53217'] = [43.158225, -87.913231];
Zipcodes['53218'] = [43.11372, -87.99298];
Zipcodes['53219'] = [42.995181, -87.993688];
Zipcodes['53220'] = [42.965172, -87.991328];
Zipcodes['53221'] = [42.953909, -87.943306];
Zipcodes['53222'] = [43.081401, -88.034966];
Zipcodes['53223'] = [43.161027, -87.988111];
Zipcodes['53224'] = [43.16333, -88.039699];
Zipcodes['53225'] = [43.114266, -88.042947];
Zipcodes['53226'] = [43.050631, -88.043161];
Zipcodes['53227'] = [42.995814, -88.042017];
Zipcodes['53228'] = [42.969046, -88.043224];
Zipcodes['53233'] = [43.036914, -87.933508];
Zipcodes['53235'] = [42.973107, -87.867895];
Zipcodes['53295'] = [43.02227, -87.976617];
Zipcodes['53402'] = [42.795888, -87.816532];
Zipcodes['53403'] = [42.684171, -87.815533];
Zipcodes['53404'] = [42.760834, -87.815278];
Zipcodes['53405'] = [42.726918, -87.830175];
Zipcodes['53406'] = [42.734536, -87.864893];
Zipcodes['53501'] = [42.603546, -89.075661];
Zipcodes['53502'] = [42.730406, -89.438069];
Zipcodes['53503'] = [43.142976, -89.957507];
Zipcodes['53504'] = [42.717524, -89.850503];
Zipcodes['53505'] = [42.661074, -88.820897];
Zipcodes['53506'] = [43.164816, -90.286348];
Zipcodes['53507'] = [42.984266, -89.914026];
Zipcodes['53508'] = [42.866188, -89.575582];
Zipcodes['53510'] = [42.742361, -90.322636];
Zipcodes['53511'] = [42.546428, -89.106715];
Zipcodes['53515'] = [43.115004, -89.739338];
Zipcodes['53516'] = [42.79685, -89.873566];
Zipcodes['53517'] = [43.056983, -89.832329];
Zipcodes['53518'] = [43.240989, -90.59513];
Zipcodes['53520'] = [42.600902, -89.361163];
Zipcodes['53521'] = [42.838105, -89.400302];
Zipcodes['53522'] = [42.556909, -89.78349];
Zipcodes['53523'] = [42.984409, -89.028839];
Zipcodes['53525'] = [42.544032, -88.846623];
Zipcodes['53526'] = [42.974318, -90.344407];
Zipcodes['53527'] = [43.051357, -89.192257];
Zipcodes['53528'] = [43.1183, -89.638014];
Zipcodes['53529'] = [43.243074, -89.531098];
Zipcodes['53530'] = [42.693554, -90.111658];
Zipcodes['53531'] = [43.05689, -89.099536];
Zipcodes['53532'] = [43.249682, -89.326691];
Zipcodes['53533'] = [42.99682, -90.162324];
Zipcodes['53534'] = [42.860823, -89.09331];
Zipcodes['53536'] = [42.761233, -89.267862];
Zipcodes['53537'] = [42.672679, -89.210601];
Zipcodes['53538'] = [42.908935, -88.871563];
Zipcodes['53540'] = [43.221829, -90.29361];
Zipcodes['53541'] = [42.569708, -90.029663];
Zipcodes['53543'] = [43.057568, -90.34546];
Zipcodes['53544'] = [42.87756, -89.905663];
Zipcodes['53545'] = [42.738735, -89.040224];
Zipcodes['53546'] = [42.652244, -88.948166];
Zipcodes['53548'] = [42.689322, -89.131287];
Zipcodes['53549'] = [42.984485, -88.765584];
Zipcodes['53550'] = [42.552842, -89.49393];
Zipcodes['53551'] = [43.079479, -88.916098];
Zipcodes['53553'] = [42.912324, -90.296958];
Zipcodes['53554'] = [42.908694, -90.440667];
Zipcodes['53555'] = [43.320154, -89.555784];
Zipcodes['53556'] = [43.238115, -90.245515];
Zipcodes['53557'] = [43.338949, -88.797661];
Zipcodes['53558'] = [42.997128, -89.275481];
Zipcodes['53559'] = [43.165573, -89.081901];
Zipcodes['53560'] = [43.192255, -89.737666];
Zipcodes['53561'] = [43.399407, -89.651691];
Zipcodes['53562'] = [43.115952, -89.543473];
Zipcodes['53563'] = [42.780889, -88.933538];
Zipcodes['53565'] = [42.837485, -90.166593];
Zipcodes['53566'] = [42.609146, -89.649429];
Zipcodes['53569'] = [42.988991, -90.438937];
Zipcodes['53570'] = [42.731054, -89.61958];
Zipcodes['53571'] = [43.277906, -89.353641];
Zipcodes['53572'] = [42.960808, -89.750087];
Zipcodes['53573'] = [43.180877, -90.459279];
Zipcodes['53574'] = [42.818517, -89.651825];
Zipcodes['53575'] = [42.931609, -89.387082];
Zipcodes['53576'] = [42.625738, -89.233212];
Zipcodes['53577'] = [43.311608, -90.064349];
Zipcodes['53578'] = [43.311092, -89.768881];
Zipcodes['53579'] = [43.295981, -88.867735];
Zipcodes['53580'] = [42.859439, -90.381301];
Zipcodes['53581'] = [43.376738, -90.419474];
Zipcodes['53582'] = [43.018374, -89.978797];
Zipcodes['53583'] = [43.251453, -89.813919];
Zipcodes['53584'] = [43.279367, -90.294675];
Zipcodes['53585'] = [42.530454, -88.720641];
Zipcodes['53586'] = [42.572731, -90.227024];
Zipcodes['53587'] = [42.580307, -89.904145];
Zipcodes['53588'] = [43.189163, -90.080481];
Zipcodes['53589'] = [42.928255, -89.215237];
Zipcodes['53590'] = [43.19989, -89.207148];
Zipcodes['53593'] = [42.985198, -89.579337];
Zipcodes['53594'] = [43.183316, -88.971228];
Zipcodes['53597'] = [43.18141, -89.457373];
Zipcodes['53598'] = [43.209737, -89.336532];
Zipcodes['53599'] = [42.647308, -89.857206];
Zipcodes['53703'] = [43.079607, -89.378423];
Zipcodes['53704'] = [43.135832, -89.34748];
Zipcodes['53705'] = [43.074014, -89.460509];
Zipcodes['53706'] = [43.075022, -89.409969];
Zipcodes['53711'] = [43.011281, -89.40931];
Zipcodes['53713'] = [43.036981, -89.393248];
Zipcodes['53714'] = [43.100348, -89.311966];
Zipcodes['53715'] = [43.05561, -89.387808];
Zipcodes['53716'] = [43.065149, -89.315015];
Zipcodes['53717'] = [43.067686, -89.521056];
Zipcodes['53718'] = [43.097019, -89.274603];
Zipcodes['53719'] = [43.047533, -89.506263];
Zipcodes['53726'] = [43.070699, -89.421896];
Zipcodes['53792'] = [43.077166, -89.430412];
Zipcodes['53801'] = [42.924833, -91.07456];
Zipcodes['53802'] = [42.805457, -90.893602];
Zipcodes['53803'] = [42.559139, -90.355078];
Zipcodes['53804'] = [42.876456, -90.907982];
Zipcodes['53805'] = [43.142976, -90.683004];
Zipcodes['53806'] = [42.729852, -90.919845];
Zipcodes['53807'] = [42.616835, -90.472701];
Zipcodes['53808'] = [42.637432, -90.59206];
Zipcodes['53809'] = [42.993622, -90.633235];
Zipcodes['53810'] = [42.819851, -90.997889];
Zipcodes['53811'] = [42.541192, -90.525122];
Zipcodes['53812'] = [42.580805, -90.602421];
Zipcodes['53813'] = [42.843965, -90.716982];
Zipcodes['53816'] = [42.977951, -90.857728];
Zipcodes['53817'] = [42.940288, -90.972343];
Zipcodes['53818'] = [42.7457, -90.492321];
Zipcodes['53820'] = [42.699772, -90.701468];
Zipcodes['53821'] = [43.049076, -91.057334];
Zipcodes['53825'] = [42.91834, -90.588504];
Zipcodes['53826'] = [43.113736, -90.924658];
Zipcodes['53827'] = [43.045865, -90.820473];
Zipcodes['53901'] = [43.560907, -89.483147];
Zipcodes['53910'] = [43.886678, -89.807748];
Zipcodes['53911'] = [43.325818, -89.367503];
Zipcodes['53913'] = [43.492773, -89.72873];
Zipcodes['53916'] = [43.455976, -88.848515];
Zipcodes['53919'] = [43.733861, -88.776014];
Zipcodes['53920'] = [43.670723, -89.604598];
Zipcodes['53922'] = [43.52093, -88.715147];
Zipcodes['53923'] = [43.574139, -89.138363];
Zipcodes['53924'] = [43.483411, -90.261729];
Zipcodes['53925'] = [43.326703, -89.057148];
Zipcodes['53926'] = [43.666882, -89.222839];
Zipcodes['53928'] = [43.418098, -89.155032];
Zipcodes['53929'] = [43.75661, -90.290996];
Zipcodes['53930'] = [43.685212, -89.498144];
Zipcodes['53931'] = [43.742542, -88.866939];
Zipcodes['53932'] = [43.422362, -89.055328];
Zipcodes['53933'] = [43.584046, -88.875214];
Zipcodes['53934'] = [43.979489, -89.823633];
Zipcodes['53935'] = [43.587632, -89.06555];
Zipcodes['53936'] = [43.868893, -89.707306];
Zipcodes['53937'] = [43.386885, -90.145424];
Zipcodes['53939'] = [43.690381, -89.138839];
Zipcodes['53940'] = [43.591278, -89.794431];
Zipcodes['53941'] = [43.580149, -90.135776];
Zipcodes['53942'] = [43.463955, -90.165331];
Zipcodes['53943'] = [43.386819, -90.035254];
Zipcodes['53944'] = [43.688416, -89.930181];
Zipcodes['53946'] = [43.719381, -89.021662];
Zipcodes['53947'] = [43.746768, -89.137671];
Zipcodes['53948'] = [43.776559, -90.040249];
Zipcodes['53949'] = [43.782615, -89.339644];
Zipcodes['53950'] = [43.901156, -90.137798];
Zipcodes['53951'] = [43.38793, -89.876247];
Zipcodes['53952'] = [43.784927, -89.58969];
Zipcodes['53953'] = [43.766096, -89.457916];
Zipcodes['53954'] = [43.55154, -89.313911];
Zipcodes['53955'] = [43.399878, -89.39869];
Zipcodes['53956'] = [43.542019, -89.017566];
Zipcodes['53959'] = [43.533502, -89.997349];
Zipcodes['53960'] = [43.426085, -89.235375];
Zipcodes['53961'] = [43.463686, -89.944341];
Zipcodes['53962'] = [43.68353, -90.264986];
Zipcodes['53963'] = [43.634671, -88.737589];
Zipcodes['53964'] = [43.912582, -89.512502];
Zipcodes['53965'] = [43.671422, -89.748134];
Zipcodes['53968'] = [43.635315, -90.235854];
Zipcodes['53969'] = [43.495662, -89.300282];
Zipcodes['54001'] = [45.33433, -92.386607];
Zipcodes['54002'] = [44.959627, -92.371857];
Zipcodes['54003'] = [44.783102, -92.446405];
Zipcodes['54004'] = [45.30222, -92.125177];
Zipcodes['54005'] = [45.228602, -92.230101];
Zipcodes['54006'] = [45.590505, -92.646687];
Zipcodes['54007'] = [45.190329, -92.35294];
Zipcodes['54009'] = [45.352521, -92.577819];
Zipcodes['54011'] = [44.718292, -92.464706];
Zipcodes['54013'] = [45.08295, -92.228166];
Zipcodes['54014'] = [44.648704, -92.572769];
Zipcodes['54015'] = [44.970815, -92.453163];
Zipcodes['54016'] = [44.981214, -92.694785];
Zipcodes['54017'] = [45.120019, -92.51979];
Zipcodes['54020'] = [45.27476, -92.662039];
Zipcodes['54021'] = [44.734183, -92.706307];
Zipcodes['54022'] = [44.846857, -92.611118];
Zipcodes['54023'] = [44.974227, -92.546904];
Zipcodes['54024'] = [45.5017, -92.640733];
Zipcodes['54025'] = [45.143751, -92.680358];
Zipcodes['54026'] = [45.227579, -92.53474];
Zipcodes['54027'] = [44.924026, -92.199858];
Zipcodes['54028'] = [44.954968, -92.283413];
Zipcodes['54082'] = [45.069563, -92.7511];
Zipcodes['54101'] = [44.785785, -88.075082];
Zipcodes['54102'] = [45.498029, -88.014598];
Zipcodes['54103'] = [45.63688, -88.516296];
Zipcodes['54104'] = [45.4344, -88.271131];
Zipcodes['54106'] = [44.485462, -88.456588];
Zipcodes['54107'] = [44.700663, -88.456942];
Zipcodes['54110'] = [44.184885, -88.0819];
Zipcodes['54111'] = [44.812102, -88.380018];
Zipcodes['54112'] = [45.054691, -88.05484];
Zipcodes['54113'] = [44.261995, -88.309534];
Zipcodes['54114'] = [45.245414, -88.108864];
Zipcodes['54115'] = [44.405368, -88.08935];
Zipcodes['54119'] = [45.61198, -88.196344];
Zipcodes['54120'] = [45.803454, -88.569329];
Zipcodes['54121'] = [45.865638, -88.326971];
Zipcodes['54123'] = [44.208779, -88.155184];
Zipcodes['54124'] = [44.908023, -88.345233];
Zipcodes['54125'] = [45.579138, -88.401622];
Zipcodes['54126'] = [44.288933, -88.032469];
Zipcodes['54127'] = [44.796922, -88.268174];
Zipcodes['54128'] = [44.864838, -88.808757];
Zipcodes['54129'] = [44.125112, -88.199405];
Zipcodes['54130'] = [44.311017, -88.244079];
Zipcodes['54135'] = [44.907717, -88.626589];
Zipcodes['54136'] = [44.268219, -88.338535];
Zipcodes['54137'] = [44.75843, -88.256104];
Zipcodes['54138'] = [45.333606, -88.409689];
Zipcodes['54139'] = [44.956844, -88.075505];
Zipcodes['54140'] = [44.288433, -88.310552];
Zipcodes['54141'] = [44.728584, -87.998318];
Zipcodes['54143'] = [45.076169, -87.698833];
Zipcodes['54149'] = [45.211171, -88.443513];
Zipcodes['54150'] = [44.969198, -88.788175];
Zipcodes['54151'] = [45.725441, -87.988217];
Zipcodes['54152'] = [44.566466, -88.470528];
Zipcodes['54153'] = [44.898069, -87.91574];
Zipcodes['54154'] = [44.87369, -88.182068];
Zipcodes['54155'] = [44.527495, -88.200548];
Zipcodes['54156'] = [45.610359, -87.944169];
Zipcodes['54157'] = [45.048231, -87.8112];
Zipcodes['54159'] = [45.193355, -87.838849];
Zipcodes['54160'] = [44.118481, -88.101704];
Zipcodes['54161'] = [45.135186, -88.150639];
Zipcodes['54162'] = [44.66254, -88.270904];
Zipcodes['54165'] = [44.521215, -88.316334];
Zipcodes['54166'] = [44.778797, -88.634549];
Zipcodes['54169'] = [44.176686, -88.280739];
Zipcodes['54170'] = [44.536096, -88.57693];
Zipcodes['54171'] = [44.715231, -88.096472];
Zipcodes['54173'] = [44.640749, -88.014886];
Zipcodes['54174'] = [45.03449, -88.437377];
Zipcodes['54175'] = [45.304391, -88.62536];
Zipcodes['54177'] = [45.366107, -87.913138];
Zipcodes['54180'] = [44.330219, -88.178304];
Zipcodes['54201'] = [44.626823, -87.486614];
Zipcodes['54202'] = [45.087307, -87.140061];
Zipcodes['54204'] = [44.755474, -87.605318];
Zipcodes['54205'] = [44.61075, -87.632443];
Zipcodes['54207'] = [44.080075, -87.982256];
Zipcodes['54208'] = [44.363565, -87.799595];
Zipcodes['54209'] = [45.017946, -87.270394];
Zipcodes['54210'] = [45.246247, -87.035915];
Zipcodes['54211'] = [45.160359, -87.166369];
Zipcodes['54212'] = [45.109775, -87.209029];
Zipcodes['54213'] = [44.695162, -87.528228];
Zipcodes['54214'] = [44.19893, -87.714444];
Zipcodes['54215'] = [44.226774, -87.803316];
Zipcodes['54216'] = [44.438598, -87.569911];
Zipcodes['54217'] = [44.548118, -87.717899];
Zipcodes['54220'] = [44.091177, -87.724511];
Zipcodes['54227'] = [44.284115, -87.804799];
Zipcodes['54228'] = [44.278161, -87.650284];
Zipcodes['54229'] = [44.560061, -87.815517];
Zipcodes['54230'] = [44.159839, -87.91504];
Zipcodes['54232'] = [44.007345, -87.924787];
Zipcodes['54234'] = [45.181963, -87.10151];
Zipcodes['54235'] = [44.843613, -87.37279];
Zipcodes['54241'] = [44.215871, -87.594706];
Zipcodes['54245'] = [44.030924, -87.898061];
Zipcodes['54246'] = [45.373209, -86.899374];
Zipcodes['54247'] = [44.199973, -87.790095];
Zipcodes['54301'] = [44.480778, -88.016063];
Zipcodes['54302'] = [44.506657, -87.976285];
Zipcodes['54303'] = [44.543349, -88.047176];
Zipcodes['54304'] = [44.49279, -88.067301];
Zipcodes['54307'] = [44.470458, -88.036594];
Zipcodes['54311'] = [44.480319, -87.892082];
Zipcodes['54313'] = [44.583773, -88.115037];
Zipcodes['54401'] = [44.966821, -89.709421];
Zipcodes['54403'] = [45.018505, -89.525117];
Zipcodes['54405'] = [44.96753, -90.282268];
Zipcodes['54406'] = [44.412217, -89.30493];
Zipcodes['54407'] = [44.532194, -89.281769];
Zipcodes['54408'] = [45.025581, -89.323997];
Zipcodes['54409'] = [45.122181, -89.170872];
Zipcodes['54410'] = [44.542291, -90.030599];
Zipcodes['54411'] = [45.06256, -90.033418];
Zipcodes['54412'] = [44.663265, -89.974225];
Zipcodes['54413'] = [44.291454, -90.148197];
Zipcodes['54414'] = [44.959673, -89.176857];
Zipcodes['54416'] = [44.884118, -88.94842];
Zipcodes['54417'] = [45.027187, -89.654128];
Zipcodes['54418'] = [45.211795, -88.983032];
Zipcodes['54420'] = [44.610077, -90.350683];
Zipcodes['54421'] = [44.895191, -90.288689];
Zipcodes['54422'] = [44.977342, -90.445395];
Zipcodes['54423'] = [44.578892, -89.425918];
Zipcodes['54424'] = [45.286662, -89.192334];
Zipcodes['54425'] = [45.006782, -90.341083];
Zipcodes['54426'] = [44.911498, -89.996824];
Zipcodes['54427'] = [44.824616, -89.257133];
Zipcodes['54428'] = [45.443489, -89.161449];
Zipcodes['54430'] = [45.13608, -88.885701];
Zipcodes['54433'] = [45.226674, -90.812628];
Zipcodes['54435'] = [45.360468, -89.438197];
Zipcodes['54436'] = [44.534563, -90.427162];
Zipcodes['54437'] = [44.777208, -90.629136];
Zipcodes['54440'] = [44.807094, -89.390975];
Zipcodes['54441'] = [44.642011, -90.10604];
Zipcodes['54442'] = [45.358854, -89.65898];
Zipcodes['54443'] = [44.608922, -89.751725];
Zipcodes['54446'] = [44.747785, -90.466411];
Zipcodes['54447'] = [45.106647, -90.72209];
Zipcodes['54448'] = [44.933287, -89.830213];
Zipcodes['54449'] = [44.637705, -90.196801];
Zipcodes['54450'] = [45.003663, -89.041732];
Zipcodes['54451'] = [45.19739, -90.407651];
Zipcodes['54452'] = [45.217729, -89.793643];
Zipcodes['54454'] = [44.601983, -89.883974];
Zipcodes['54455'] = [44.766821, -89.684474];
Zipcodes['54456'] = [44.544396, -90.632781];
Zipcodes['54457'] = [44.229258, -89.897501];
Zipcodes['54458'] = [44.497118, -89.306142];
Zipcodes['54459'] = [45.412588, -90.237432];
Zipcodes['54460'] = [44.953422, -90.532895];
Zipcodes['54462'] = [45.392002, -89.007393];
Zipcodes['54463'] = [45.498652, -89.232818];
Zipcodes['54464'] = [45.054774, -89.01748];
Zipcodes['54465'] = [45.396263, -88.875744];
Zipcodes['54466'] = [44.385459, -90.327046];
Zipcodes['54467'] = [44.413919, -89.538901];
Zipcodes['54469'] = [44.345149, -89.874087];
Zipcodes['54470'] = [45.291895, -90.136696];
Zipcodes['54471'] = [44.930719, -89.424316];
Zipcodes['54473'] = [44.650645, -89.352368];
Zipcodes['54474'] = [44.873714, -89.61975];
Zipcodes['54475'] = [44.484609, -89.793126];
Zipcodes['54476'] = [44.88996, -89.520725];
Zipcodes['54479'] = [44.750611, -90.331989];
Zipcodes['54480'] = [45.05431, -90.269289];
Zipcodes['54481'] = [44.522502, -89.646137];
Zipcodes['54482'] = [44.550416, -89.509449];
Zipcodes['54484'] = [44.800275, -90.081667];
Zipcodes['54485'] = [45.393438, -89.220286];
Zipcodes['54486'] = [44.718048, -89.045227];
Zipcodes['54487'] = [45.510949, -89.744184];
Zipcodes['54488'] = [44.843146, -90.346204];
Zipcodes['54489'] = [44.460777, -90.003987];
Zipcodes['54490'] = [45.32538, -90.408626];
Zipcodes['54491'] = [45.227481, -88.749006];
Zipcodes['54493'] = [44.69676, -90.81072];
Zipcodes['54494'] = [44.340807, -89.735011];
Zipcodes['54495'] = [44.391655, -89.958705];
Zipcodes['54498'] = [45.035112, -90.629022];
Zipcodes['54499'] = [44.789831, -89.176405];
Zipcodes['54501'] = [45.657615, -89.349239];
Zipcodes['54511'] = [45.737476, -88.823136];
Zipcodes['54512'] = [46.091151, -89.659025];
Zipcodes['54513'] = [45.578372, -90.141255];
Zipcodes['54514'] = [46.032511, -90.495824];
Zipcodes['54515'] = [45.512603, -90.491098];
Zipcodes['54517'] = [46.121051, -90.934042];
Zipcodes['54519'] = [46.054749, -89.263568];
Zipcodes['54520'] = [45.54124, -88.9329];
Zipcodes['54521'] = [45.916231, -89.254165];
Zipcodes['54524'] = [45.828177, -90.392415];
Zipcodes['54525'] = [46.434358, -90.225507];
Zipcodes['54526'] = [45.529271, -90.85718];
Zipcodes['54527'] = [46.126172, -90.640325];
Zipcodes['54529'] = [45.677711, -89.667165];
Zipcodes['54530'] = [45.529066, -90.715445];
Zipcodes['54531'] = [45.752823, -89.774652];
Zipcodes['54534'] = [46.352058, -90.22372];
Zipcodes['54536'] = [46.347698, -90.338071];
Zipcodes['54537'] = [45.533815, -90.602234];
Zipcodes['54538'] = [45.979627, -89.926581];
Zipcodes['54539'] = [45.809175, -89.577404];
Zipcodes['54540'] = [46.154791, -89.372738];
Zipcodes['54541'] = [45.553631, -88.662073];
Zipcodes['54542'] = [45.922608, -88.745342];
Zipcodes['54545'] = [46.133995, -89.854518];
Zipcodes['54546'] = [46.287313, -90.748955];
Zipcodes['54547'] = [46.21159, -90.10617];
Zipcodes['54548'] = [45.866014, -89.831735];
Zipcodes['54550'] = [46.347845, -90.259437];
Zipcodes['54552'] = [45.929782, -90.292821];
Zipcodes['54554'] = [46.060032, -89.064052];
Zipcodes['54555'] = [45.768047, -90.330476];
Zipcodes['54556'] = [45.53473, -90.317067];
Zipcodes['54557'] = [46.211775, -89.747843];
Zipcodes['54558'] = [45.925125, -89.499382];
Zipcodes['54559'] = [46.495208, -90.49123];
Zipcodes['54560'] = [46.036677, -89.552305];
Zipcodes['54561'] = [46.06915, -89.426527];
Zipcodes['54562'] = [45.802655, -89.079344];
Zipcodes['54563'] = [45.460162, -90.970548];
Zipcodes['54564'] = [45.604132, -89.961429];
Zipcodes['54565'] = [46.284828, -90.465513];
Zipcodes['54566'] = [45.428346, -88.630658];
Zipcodes['54568'] = [45.960879, -89.668172];
Zipcodes['54601'] = [43.806243, -91.140632];
Zipcodes['54603'] = [43.857742, -91.251775];
Zipcodes['54610'] = [44.365117, -91.831714];
Zipcodes['54611'] = [44.446225, -90.951582];
Zipcodes['54612'] = [44.249594, -91.501513];
Zipcodes['54613'] = [44.075585, -89.923744];
Zipcodes['54614'] = [43.904941, -90.952842];
Zipcodes['54615'] = [44.266352, -90.784217];
Zipcodes['54616'] = [44.311465, -91.213854];
Zipcodes['54618'] = [43.963629, -90.285854];
Zipcodes['54619'] = [43.7575, -90.774231];
Zipcodes['54621'] = [43.646508, -91.082311];
Zipcodes['54622'] = [44.273429, -91.773972];
Zipcodes['54623'] = [43.72924, -91.027212];
Zipcodes['54624'] = [43.461506, -91.131141];
Zipcodes['54625'] = [44.132865, -91.525052];
Zipcodes['54626'] = [43.219662, -91.054076];
Zipcodes['54627'] = [44.171454, -91.226764];
Zipcodes['54628'] = [43.372994, -91.026177];
Zipcodes['54629'] = [44.174054, -91.63934];
Zipcodes['54630'] = [44.122749, -91.373028];
Zipcodes['54631'] = [43.288563, -90.833269];
Zipcodes['54632'] = [43.561085, -91.146932];
Zipcodes['54634'] = [43.581681, -90.422391];
Zipcodes['54635'] = [44.402904, -91.045552];
Zipcodes['54636'] = [44.003164, -91.223953];
Zipcodes['54637'] = [43.878745, -90.270778];
Zipcodes['54638'] = [43.785642, -90.405061];
Zipcodes['54639'] = [43.610809, -90.599646];
Zipcodes['54642'] = [44.152076, -91.044944];
Zipcodes['54643'] = [44.18818, -90.63237];
Zipcodes['54644'] = [44.036084, -91.063408];
Zipcodes['54645'] = [43.320799, -90.929039];
Zipcodes['54646'] = [44.082822, -90.092968];
Zipcodes['54648'] = [43.836918, -90.6345];
Zipcodes['54650'] = [43.909405, -91.224829];
Zipcodes['54651'] = [43.72304, -90.566398];
Zipcodes['54652'] = [43.475689, -90.772333];
Zipcodes['54653'] = [43.821366, -90.926922];
Zipcodes['54654'] = [43.268539, -90.954198];
Zipcodes['54655'] = [43.370959, -90.759061];
Zipcodes['54656'] = [43.990087, -90.80319];
Zipcodes['54657'] = [43.199351, -90.909688];
Zipcodes['54658'] = [43.682596, -91.187099];
Zipcodes['54659'] = [44.28706, -91.096274];
Zipcodes['54660'] = [43.985009, -90.505437];
Zipcodes['54661'] = [44.057603, -91.467828];
Zipcodes['54664'] = [43.48833, -90.642904];
Zipcodes['54665'] = [43.531978, -90.928599];
Zipcodes['54666'] = [44.170133, -90.449668];
Zipcodes['54667'] = [43.666366, -90.864779];
Zipcodes['54669'] = [43.911169, -91.083953];
Zipcodes['54670'] = [43.837992, -90.482723];
Zipcodes['54701'] = [44.751984, -91.514998];
Zipcodes['54703'] = [44.834611, -91.520448];
Zipcodes['54720'] = [44.805042, -91.437019];
Zipcodes['54721'] = [44.633744, -92.082731];
Zipcodes['54722'] = [44.711812, -91.096003];
Zipcodes['54723'] = [44.610586, -92.419425];
Zipcodes['54724'] = [45.107813, -91.484409];
Zipcodes['54725'] = [45.073194, -91.990915];
Zipcodes['54726'] = [44.937235, -91.025904];
Zipcodes['54727'] = [44.953953, -91.159107];
Zipcodes['54728'] = [45.30795, -91.637762];
Zipcodes['54729'] = [44.946356, -91.394524];
Zipcodes['54730'] = [45.018518, -91.719608];
Zipcodes['54731'] = [45.349837, -91.090398];
Zipcodes['54732'] = [45.14463, -91.170488];
Zipcodes['54733'] = [45.27866, -91.850182];
Zipcodes['54734'] = [45.093283, -92.114129];
Zipcodes['54736'] = [44.612868, -91.924613];
Zipcodes['54737'] = [44.71841, -91.981626];
Zipcodes['54738'] = [44.588292, -91.488813];
Zipcodes['54739'] = [44.859783, -91.701162];
Zipcodes['54740'] = [44.751437, -92.166807];
Zipcodes['54741'] = [44.610234, -90.980075];
Zipcodes['54742'] = [44.762783, -91.272041];
Zipcodes['54745'] = [45.251132, -91.123401];
Zipcodes['54746'] = [44.547856, -90.86441];
Zipcodes['54747'] = [44.394958, -91.500452];
Zipcodes['54748'] = [45.075083, -91.26302];
Zipcodes['54749'] = [44.951199, -92.0991];
Zipcodes['54750'] = [44.635286, -92.284954];
Zipcodes['54751'] = [44.85063, -91.933502];
Zipcodes['54754'] = [44.426436, -90.773969];
Zipcodes['54755'] = [44.575754, -91.681293];
Zipcodes['54756'] = [44.468071, -91.939604];
Zipcodes['54757'] = [45.237197, -91.522349];
Zipcodes['54758'] = [44.557919, -91.216522];
Zipcodes['54759'] = [44.497878, -92.12093];
Zipcodes['54760'] = [44.424142, -91.206127];
Zipcodes['54761'] = [44.623236, -92.1783];
Zipcodes['54762'] = [45.256474, -91.996707];
Zipcodes['54763'] = [45.180248, -91.875269];
Zipcodes['54766'] = [45.340738, -90.857989];
Zipcodes['54767'] = [44.8299, -92.255237];
Zipcodes['54768'] = [44.96712, -90.927085];
Zipcodes['54769'] = [44.509543, -92.227439];
Zipcodes['54770'] = [44.55195, -91.373065];
Zipcodes['54771'] = [44.92394, -90.80674];
Zipcodes['54772'] = [45.081011, -91.887397];
Zipcodes['54773'] = [44.390016, -91.2964];
Zipcodes['54801'] = [45.863919, -91.937464];
Zipcodes['54805'] = [45.412059, -92.023537];
Zipcodes['54806'] = [46.551759, -90.845656];
Zipcodes['54810'] = [45.457218, -92.388369];
Zipcodes['54812'] = [45.392467, -91.882622];
Zipcodes['54813'] = [45.657437, -92.022462];
Zipcodes['54814'] = [46.86331, -90.901205];
Zipcodes['54817'] = [45.676433, -91.532367];
Zipcodes['54819'] = [45.475775, -91.302138];
Zipcodes['54820'] = [46.556557, -91.562013];
Zipcodes['54821'] = [46.216971, -91.166187];
Zipcodes['54822'] = [45.400937, -91.694413];
Zipcodes['54824'] = [45.484715, -92.539887];
Zipcodes['54826'] = [45.496504, -92.176817];
Zipcodes['54827'] = [46.801174, -91.111594];
Zipcodes['54828'] = [45.840978, -91.294249];
Zipcodes['54829'] = [45.566779, -92.075066];
Zipcodes['54830'] = [46.106014, -92.225683];
Zipcodes['54832'] = [46.319287, -91.343441];
Zipcodes['54835'] = [45.679838, -91.249937];
Zipcodes['54836'] = [46.454438, -92.195041];
Zipcodes['54837'] = [45.674284, -92.357709];
Zipcodes['54838'] = [46.249103, -91.7944];
Zipcodes['54839'] = [46.356374, -91.150715];
Zipcodes['54840'] = [45.751262, -92.692383];
Zipcodes['54841'] = [45.607593, -91.778588];
Zipcodes['54842'] = [46.490793, -91.871965];
Zipcodes['54843'] = [46.023604, -91.293027];
Zipcodes['54844'] = [46.781465, -91.215472];
Zipcodes['54845'] = [45.803543, -92.144504];
Zipcodes['54846'] = [46.378316, -90.720238];
Zipcodes['54847'] = [46.579326, -91.39985];
Zipcodes['54848'] = [45.516039, -91.103822];
Zipcodes['54849'] = [46.482245, -91.71671];
Zipcodes['54850'] = [46.940645, -90.57083];
Zipcodes['54853'] = [45.576404, -92.458671];
Zipcodes['54854'] = [46.65038, -91.667167];
Zipcodes['54855'] = [46.382631, -90.82752];
Zipcodes['54856'] = [46.432392, -91.109905];
Zipcodes['54857'] = [45.593407, -91.599298];
Zipcodes['54858'] = [45.519382, -92.457844];
Zipcodes['54859'] = [46.126055, -91.848834];
Zipcodes['54861'] = [46.598934, -90.643311];
Zipcodes['54862'] = [45.807575, -91.118161];
Zipcodes['54864'] = [46.602234, -91.801441];
Zipcodes['54865'] = [46.756, -91.374842];
Zipcodes['54867'] = [45.821701, -91.203121];
Zipcodes['54868'] = [45.527349, -91.716991];
Zipcodes['54870'] = [45.70926, -91.775273];
Zipcodes['54871'] = [45.749634, -92.002023];
Zipcodes['54872'] = [45.77231, -92.391514];
Zipcodes['54873'] = [46.346345, -91.702229];
Zipcodes['54874'] = [46.571798, -91.938747];
Zipcodes['54875'] = [45.973507, -91.693054];
Zipcodes['54876'] = [45.827511, -91.501096];
Zipcodes['54880'] = [46.590874, -92.12831];
Zipcodes['54888'] = [45.974807, -91.866892];
Zipcodes['54889'] = [45.415113, -92.170107];
Zipcodes['54891'] = [46.697766, -90.971168];
Zipcodes['54893'] = [45.878684, -92.325978];
Zipcodes['54895'] = [45.449432, -91.46076];
Zipcodes['54896'] = [45.806223, -90.883546];
Zipcodes['54901'] = [44.062759, -88.535773];
Zipcodes['54902'] = [43.946895, -88.540285];
Zipcodes['54904'] = [44.0234, -88.623839];
Zipcodes['54909'] = [44.280704, -89.367404];
Zipcodes['54911'] = [44.281533, -88.381003];
Zipcodes['54913'] = [44.335996, -88.39997];
Zipcodes['54914'] = [44.267634, -88.499046];
Zipcodes['54915'] = [44.242088, -88.361084];
Zipcodes['54921'] = [44.295741, -89.579301];
Zipcodes['54922'] = [44.548638, -88.742749];
Zipcodes['54923'] = [43.99772, -88.9668];
Zipcodes['54926'] = [44.615607, -89.020239];
Zipcodes['54927'] = [44.103128, -88.654369];
Zipcodes['54928'] = [44.752702, -88.879209];
Zipcodes['54929'] = [44.640938, -88.739982];
Zipcodes['54930'] = [44.03264, -89.554799];
Zipcodes['54931'] = [44.270225, -88.668244];
Zipcodes['54932'] = [43.840339, -88.61944];
Zipcodes['54933'] = [44.658611, -88.71404];
Zipcodes['54935'] = [43.775457, -88.432601];
Zipcodes['54937'] = [43.724107, -88.398163];
Zipcodes['54940'] = [44.22037, -88.842561];
Zipcodes['54941'] = [43.844703, -88.992882];
Zipcodes['54942'] = [44.299013, -88.542674];
Zipcodes['54943'] = [44.122562, -89.572665];
Zipcodes['54944'] = [44.324687, -88.626956];
Zipcodes['54945'] = [44.568636, -89.136826];
Zipcodes['54946'] = [44.337097, -89.145114];
Zipcodes['54947'] = [44.203594, -88.698749];
Zipcodes['54948'] = [44.776062, -88.885647];
Zipcodes['54949'] = [44.494046, -88.91261];
Zipcodes['54950'] = [44.657215, -88.918084];
Zipcodes['54952'] = [44.202041, -88.339418];
Zipcodes['54956'] = [44.182337, -88.527647];
Zipcodes['54960'] = [43.941733, -89.218999];
Zipcodes['54961'] = [44.410204, -88.761688];
Zipcodes['54962'] = [44.486188, -89.030971];
Zipcodes['54963'] = [44.045644, -88.76728];
Zipcodes['54964'] = [43.915614, -88.741945];
Zipcodes['54965'] = [44.167973, -89.043887];
Zipcodes['54966'] = [44.210102, -89.50478];
Zipcodes['54967'] = [44.12674, -88.975792];
Zipcodes['54968'] = [43.84693, -89.136064];
Zipcodes['54970'] = [44.072763, -89.098173];
Zipcodes['54971'] = [43.855118, -88.833583];
Zipcodes['54974'] = [43.781841, -88.652136];
Zipcodes['54977'] = [44.461176, -89.171211];
Zipcodes['54978'] = [44.794239, -88.914001];
Zipcodes['54979'] = [43.872535, -88.536399];
Zipcodes['54980'] = [43.986125, -88.765941];
Zipcodes['54981'] = [44.322675, -89.131481];
Zipcodes['54982'] = [44.069753, -89.292831];
Zipcodes['54983'] = [44.316862, -88.935165];
Zipcodes['54984'] = [44.190566, -89.223457];
Zipcodes['54985'] = [44.075638, -88.519717];
Zipcodes['54986'] = [44.117492, -88.747427];
Zipcodes['55001'] = [44.898776, -92.819108];
Zipcodes['55003'] = [45.007476, -92.779064];
Zipcodes['55005'] = [45.396107, -93.218464];
Zipcodes['55006'] = [45.738266, -93.198939];
Zipcodes['55007'] = [45.971293, -93.117849];
Zipcodes['55008'] = [45.569488, -93.276632];
Zipcodes['55009'] = [44.480564, -92.871085];
Zipcodes['55011'] = [45.344423, -93.252018];
Zipcodes['55012'] = [45.437981, -92.788354];
Zipcodes['55013'] = [45.351756, -92.8875];
Zipcodes['55014'] = [45.168349, -93.123752];
Zipcodes['55016'] = [44.816735, -92.93373];
Zipcodes['55017'] = [45.671279, -93.437683];
Zipcodes['55018'] = [44.414834, -92.984184];
Zipcodes['55019'] = [44.411004, -93.250262];
Zipcodes['55020'] = [44.578265, -93.374344];
Zipcodes['55021'] = [44.297513, -93.273914];
Zipcodes['55024'] = [44.628726, -93.135594];
Zipcodes['55025'] = [45.260352, -93.016529];
Zipcodes['55026'] = [44.521428, -92.336214];
Zipcodes['55027'] = [44.406342, -92.609391];
Zipcodes['55029'] = [45.635165, -93.20119];
Zipcodes['55030'] = [45.848274, -93.120168];
Zipcodes['55031'] = [44.612517, -92.962163];
Zipcodes['55032'] = [45.590313, -92.993037];
Zipcodes['55033'] = [44.711007, -92.858662];
Zipcodes['55036'] = [45.871291, -93.119702];
Zipcodes['55037'] = [46.007713, -92.79298];
Zipcodes['55038'] = [45.17339, -92.981181];
Zipcodes['55040'] = [45.468531, -93.277925];
Zipcodes['55041'] = [44.392813, -92.333885];
Zipcodes['55042'] = [44.9883, -92.892415];
Zipcodes['55043'] = [44.940575, -92.768851];
Zipcodes['55044'] = [44.642146, -93.276446];
Zipcodes['55045'] = [45.395738, -92.828189];
Zipcodes['55046'] = [44.442784, -93.410436];
Zipcodes['55047'] = [45.200429, -92.820729];
Zipcodes['55049'] = [44.17663, -93.238];
Zipcodes['55051'] = [45.936644, -93.339442];
Zipcodes['55052'] = [44.233327, -93.440444];
Zipcodes['55053'] = [44.349416, -93.065445];
Zipcodes['55054'] = [44.571531, -93.350679];
Zipcodes['55055'] = [44.874399, -92.998647];
Zipcodes['55056'] = [45.509277, -92.960967];
Zipcodes['55057'] = [44.472652, -93.179125];
Zipcodes['55060'] = [44.059703, -93.227333];
Zipcodes['55063'] = [45.838574, -92.902898];
Zipcodes['55065'] = [44.550722, -93.024936];
Zipcodes['55066'] = [44.511927, -92.539225];
Zipcodes['55068'] = [44.735478, -93.062774];
Zipcodes['55069'] = [45.700335, -92.972173];
Zipcodes['55070'] = [45.406224, -93.303323];
Zipcodes['55071'] = [44.821362, -92.99838];
Zipcodes['55072'] = [46.142704, -92.584273];
Zipcodes['55073'] = [45.276262, -92.830353];
Zipcodes['55074'] = [45.387778, -92.720049];
Zipcodes['55075'] = [44.88799, -93.041731];
Zipcodes['55076'] = [44.832866, -93.033244];
Zipcodes['55077'] = [44.81792, -93.077035];
Zipcodes['55079'] = [45.419467, -93.035393];
Zipcodes['55080'] = [45.66642, -93.222225];
Zipcodes['55082'] = [45.07442, -92.841577];
Zipcodes['55084'] = [45.462591, -92.716225];
Zipcodes['55085'] = [44.669691, -92.963426];
Zipcodes['55087'] = [44.236212, -93.409214];
Zipcodes['55088'] = [44.523586, -93.383455];
Zipcodes['55089'] = [44.59325, -92.709064];
Zipcodes['55090'] = [45.053731, -92.957987];
Zipcodes['55092'] = [45.334054, -93.099884];
Zipcodes['55101'] = [44.950764, -93.087571];
Zipcodes['55102'] = [44.931901, -93.121291];
Zipcodes['55103'] = [44.964258, -93.122627];
Zipcodes['55104'] = [44.953894, -93.164446];
Zipcodes['55105'] = [44.934707, -93.165751];
Zipcodes['55106'] = [44.957236, -93.047129];
Zipcodes['55107'] = [44.93107, -93.079172];
Zipcodes['55108'] = [44.982322, -93.174657];
Zipcodes['55109'] = [45.016634, -93.026716];
Zipcodes['55110'] = [45.089937, -93.004046];
Zipcodes['55111'] = [44.890094, -93.190164];
Zipcodes['55112'] = [45.080514, -93.19206];
Zipcodes['55113'] = [45.012196, -93.151186];
Zipcodes['55114'] = [44.96669, -93.195072];
Zipcodes['55115'] = [45.066802, -92.949458];
Zipcodes['55116'] = [44.910719, -93.169581];
Zipcodes['55117'] = [45.003562, -93.09128];
Zipcodes['55118'] = [44.894315, -93.100947];
Zipcodes['55119'] = [44.939808, -93.006254];
Zipcodes['55120'] = [44.875979, -93.150649];
Zipcodes['55121'] = [44.845873, -93.159565];
Zipcodes['55122'] = [44.800972, -93.195445];
Zipcodes['55123'] = [44.80239, -93.139126];
Zipcodes['55124'] = [44.745301, -93.199413];
Zipcodes['55125'] = [44.918031, -92.938277];
Zipcodes['55126'] = [45.082989, -93.140492];
Zipcodes['55127'] = [45.081572, -93.076928];
Zipcodes['55128'] = [44.987546, -92.963553];
Zipcodes['55129'] = [44.883474, -92.892768];
Zipcodes['55130'] = [44.973302, -93.08241];
Zipcodes['55150'] = [44.887411, -93.161007];
Zipcodes['55155'] = [44.951919, -93.099363];
Zipcodes['55301'] = [45.244364, -93.661168];
Zipcodes['55302'] = [45.246516, -94.117125];
Zipcodes['55303'] = [45.288412, -93.431529];
Zipcodes['55304'] = [45.245585, -93.259952];
Zipcodes['55305'] = [44.956346, -93.428092];
Zipcodes['55306'] = [44.730944, -93.291381];
Zipcodes['55307'] = [44.599706, -94.111216];
Zipcodes['55308'] = [45.413703, -93.835316];
Zipcodes['55309'] = [45.379166, -93.732936];
Zipcodes['55310'] = [44.755904, -94.876309];
Zipcodes['55311'] = [45.106403, -93.497029];
Zipcodes['55312'] = [44.70634, -94.35129];
Zipcodes['55313'] = [45.17409, -93.848952];
Zipcodes['55314'] = [44.776384, -94.607125];
Zipcodes['55315'] = [44.71469, -93.691863];
Zipcodes['55316'] = [45.172932, -93.386829];
Zipcodes['55317'] = [44.85671, -93.550567];
Zipcodes['55318'] = [44.810787, -93.636252];
Zipcodes['55319'] = [45.471792, -93.95229];
Zipcodes['55320'] = [45.396331, -94.086052];
Zipcodes['55321'] = [45.087568, -94.19314];
Zipcodes['55322'] = [44.771886, -93.787124];
Zipcodes['55324'] = [45.053776, -94.433007];
Zipcodes['55325'] = [45.084868, -94.316956];
Zipcodes['55327'] = [45.204315, -93.468761];
Zipcodes['55328'] = [45.033416, -93.813687];
Zipcodes['55329'] = [45.304542, -94.558937];
Zipcodes['55330'] = [45.332212, -93.566528];
Zipcodes['55331'] = [44.901254, -93.609953];
Zipcodes['55332'] = [44.520417, -94.699494];
Zipcodes['55333'] = [44.562955, -94.860631];
Zipcodes['55334'] = [44.535651, -94.188415];
Zipcodes['55335'] = [44.5311, -94.553136];
Zipcodes['55336'] = [44.765194, -94.190404];
Zipcodes['55337'] = [44.778472, -93.272269];
Zipcodes['55338'] = [44.674788, -94.000669];
Zipcodes['55339'] = [44.723428, -93.95696];
Zipcodes['55340'] = [45.078263, -93.572118];
Zipcodes['55341'] = [45.157755, -93.66275];
Zipcodes['55342'] = [44.74292, -94.739002];
Zipcodes['55343'] = [44.914372, -93.416254];
Zipcodes['55344'] = [44.863838, -93.430008];
Zipcodes['55345'] = [44.915442, -93.48443];
Zipcodes['55346'] = [44.878622, -93.481287];
Zipcodes['55347'] = [44.827986, -93.462382];
Zipcodes['55349'] = [45.06225, -94.074566];
Zipcodes['55350'] = [44.897378, -94.392695];
Zipcodes['55352'] = [44.651475, -93.592608];
Zipcodes['55353'] = [45.329098, -94.324629];
Zipcodes['55354'] = [44.886898, -94.064307];
Zipcodes['55355'] = [45.090934, -94.559835];
Zipcodes['55356'] = [44.995899, -93.585463];
Zipcodes['55357'] = [45.103722, -93.674218];
Zipcodes['55358'] = [45.244875, -93.997675];
Zipcodes['55359'] = [45.007347, -93.692613];
Zipcodes['55360'] = [44.91596, -93.919645];
Zipcodes['55362'] = [45.300334, -93.847481];
Zipcodes['55363'] = [45.03782, -93.91814];
Zipcodes['55364'] = [44.939325, -93.675835];
Zipcodes['55366'] = [44.67235, -94.234581];
Zipcodes['55367'] = [44.895242, -93.976028];
Zipcodes['55368'] = [44.740063, -93.899747];
Zipcodes['55369'] = [45.127366, -93.4492];
Zipcodes['55370'] = [44.78412, -94.0346];
Zipcodes['55371'] = [45.575584, -93.589164];
Zipcodes['55372'] = [44.682387, -93.413588];
Zipcodes['55373'] = [45.088208, -93.72159];
Zipcodes['55374'] = [45.175547, -93.575532];
Zipcodes['55375'] = [44.894592, -93.726038];
Zipcodes['55376'] = [45.210325, -93.682824];
Zipcodes['55378'] = [44.759057, -93.364213];
Zipcodes['55379'] = [44.743442, -93.510032];
Zipcodes['55381'] = [44.930963, -94.199979];
Zipcodes['55382'] = [45.300423, -94.199089];
Zipcodes['55384'] = [44.935926, -93.63011];
Zipcodes['55385'] = [44.736135, -94.486465];
Zipcodes['55386'] = [44.856644, -93.664138];
Zipcodes['55387'] = [44.861222, -93.784034];
Zipcodes['55388'] = [44.954831, -93.848665];
Zipcodes['55389'] = [45.297919, -94.442626];
Zipcodes['55390'] = [45.067871, -93.979016];
Zipcodes['55391'] = [44.964243, -93.543436];
Zipcodes['55395'] = [44.957797, -94.063408];
Zipcodes['55396'] = [44.544829, -94.3669];
Zipcodes['55397'] = [44.823801, -93.937964];
Zipcodes['55398'] = [45.458747, -93.589494];
Zipcodes['55401'] = [44.985367, -93.270208];
Zipcodes['55402'] = [44.976069, -93.271161];
Zipcodes['55403'] = [44.969923, -93.284253];
Zipcodes['55404'] = [44.961913, -93.261349];
Zipcodes['55405'] = [44.972674, -93.301878];
Zipcodes['55406'] = [44.938689, -93.221042];
Zipcodes['55407'] = [44.935036, -93.253029];
Zipcodes['55408'] = [44.943815, -93.293867];
Zipcodes['55409'] = [44.930178, -93.281459];
Zipcodes['55410'] = [44.913345, -93.316942];
Zipcodes['55411'] = [44.999752, -93.29798];
Zipcodes['55412'] = [45.027324, -93.303612];
Zipcodes['55413'] = [44.997814, -93.243546];
Zipcodes['55414'] = [44.978381, -93.222449];
Zipcodes['55415'] = [44.974644, -93.257815];
Zipcodes['55416'] = [44.949373, -93.340994];
Zipcodes['55417'] = [44.906117, -93.235387];
Zipcodes['55418'] = [45.021318, -93.242853];
Zipcodes['55419'] = [44.905799, -93.287653];
Zipcodes['55420'] = [44.835969, -93.277176];
Zipcodes['55421'] = [45.050574, -93.249937];
Zipcodes['55422'] = [45.009275, -93.341105];
Zipcodes['55423'] = [44.877193, -93.281359];
Zipcodes['55424'] = [44.905024, -93.344865];
Zipcodes['55425'] = [44.851169, -93.22608];
Zipcodes['55426'] = [44.956566, -93.381654];
Zipcodes['55427'] = [45.004595, -93.381351];
Zipcodes['55428'] = [45.064039, -93.383509];
Zipcodes['55429'] = [45.063659, -93.341175];
Zipcodes['55430'] = [45.063152, -93.299246];
Zipcodes['55431'] = [44.824534, -93.312602];
Zipcodes['55432'] = [45.096702, -93.253726];
Zipcodes['55433'] = [45.160411, -93.314839];
Zipcodes['55434'] = [45.163672, -93.250445];
Zipcodes['55435'] = [44.873981, -93.335255];
Zipcodes['55436'] = [44.907173, -93.375086];
Zipcodes['55437'] = [44.824117, -93.342225];
Zipcodes['55438'] = [44.823123, -93.386125];
Zipcodes['55439'] = [44.873551, -93.373048];
Zipcodes['55441'] = [45.008425, -93.430718];
Zipcodes['55442'] = [45.04892, -93.426212];
Zipcodes['55443'] = [45.119451, -93.338969];
Zipcodes['55444'] = [45.107682, -93.303205];
Zipcodes['55445'] = [45.122441, -93.379184];
Zipcodes['55446'] = [45.03914, -93.489701];
Zipcodes['55447'] = [45.001096, -93.489561];
Zipcodes['55448'] = [45.193603, -93.300445];
Zipcodes['55449'] = [45.175978, -93.184149];
Zipcodes['55450'] = [44.872084, -93.228993];
Zipcodes['55454'] = [44.973346, -93.243708];
Zipcodes['55455'] = [44.975487, -93.235337];
Zipcodes['55601'] = [47.235637, -91.365953];
Zipcodes['55602'] = [47.316208, -91.931835];
Zipcodes['55603'] = [47.550775, -91.206393];
Zipcodes['55604'] = [47.979098, -90.599609];
Zipcodes['55605'] = [47.952837, -89.660012];
Zipcodes['55606'] = [47.95979, -90.016378];
Zipcodes['55607'] = [47.621962, -91.500786];
Zipcodes['55609'] = [46.966501, -91.78876];
Zipcodes['55612'] = [47.787856, -90.705596];
Zipcodes['55613'] = [47.573365, -90.986359];
Zipcodes['55614'] = [47.345735, -91.382386];
Zipcodes['55615'] = [47.746544, -90.85118];
Zipcodes['55616'] = [47.251537, -91.691986];
Zipcodes['55702'] = [46.957364, -92.637569];
Zipcodes['55703'] = [47.73101, -92.750157];
Zipcodes['55704'] = [46.219085, -92.751667];
Zipcodes['55705'] = [47.47068, -92.267326];
Zipcodes['55706'] = [47.667051, -91.985485];
Zipcodes['55707'] = [46.551745, -92.636106];
Zipcodes['55708'] = [47.540312, -92.342174];
Zipcodes['55709'] = [47.402584, -93.372855];
Zipcodes['55710'] = [47.64984, -92.697364];
Zipcodes['55711'] = [46.818752, -92.690769];
Zipcodes['55712'] = [46.28175, -92.52058];
Zipcodes['55713'] = [47.487942, -92.76443];
Zipcodes['55716'] = [47.321718, -93.26434];
Zipcodes['55717'] = [47.068751, -92.387215];
Zipcodes['55718'] = [46.620931, -92.486403];
Zipcodes['55719'] = [47.541039, -92.86219];
Zipcodes['55720'] = [46.750977, -92.533373];
Zipcodes['55721'] = [47.22127, -93.683167];
Zipcodes['55722'] = [47.283261, -93.434435];
Zipcodes['55723'] = [47.840484, -92.857179];
Zipcodes['55724'] = [47.167253, -92.369033];
Zipcodes['55725'] = [48.350302, -92.530677];
Zipcodes['55726'] = [46.649595, -92.827156];
Zipcodes['55731'] = [47.996882, -91.753982];
Zipcodes['55732'] = [47.68792, -92.250653];
Zipcodes['55733'] = [46.713831, -92.360792];
Zipcodes['55734'] = [47.388596, -92.496846];
Zipcodes['55735'] = [46.210293, -93.010212];
Zipcodes['55736'] = [46.95686, -92.920606];
Zipcodes['55738'] = [47.26433, -92.660348];
Zipcodes['55741'] = [47.457221, -92.384961];
Zipcodes['55742'] = [47.171024, -93.144843];
Zipcodes['55744'] = [47.233766, -93.5126];
Zipcodes['55746'] = [47.335183, -92.954287];
Zipcodes['55748'] = [46.993656, -93.596353];
Zipcodes['55749'] = [46.439558, -92.443315];
Zipcodes['55750'] = [47.531432, -91.963808];
Zipcodes['55751'] = [47.389419, -92.67316];
Zipcodes['55752'] = [46.952155, -93.252996];
Zipcodes['55753'] = [47.396738, -93.080468];
Zipcodes['55756'] = [46.375388, -92.601355];
Zipcodes['55757'] = [46.526354, -92.947611];
Zipcodes['55758'] = [47.506036, -92.720175];
Zipcodes['55760'] = [46.642586, -93.257419];
Zipcodes['55763'] = [47.330498, -92.232393];
Zipcodes['55764'] = [47.332601, -93.291449];
Zipcodes['55765'] = [47.098901, -92.736191];
Zipcodes['55767'] = [46.448584, -92.773754];
Zipcodes['55768'] = [47.537842, -92.660603];
Zipcodes['55769'] = [47.479989, -93.18386];
Zipcodes['55771'] = [48.116756, -92.904198];
Zipcodes['55772'] = [48.110172, -93.100224];
Zipcodes['55775'] = [47.298619, -93.160503];
Zipcodes['55779'] = [46.908739, -92.449133];
Zipcodes['55780'] = [46.67613, -92.677707];
Zipcodes['55781'] = [47.680868, -93.065077];
Zipcodes['55782'] = [47.832953, -92.206005];
Zipcodes['55783'] = [46.412058, -92.917963];
Zipcodes['55784'] = [47.058391, -93.165281];
Zipcodes['55785'] = [46.921584, -93.731217];
Zipcodes['55786'] = [47.317896, -93.382848];
Zipcodes['55787'] = [46.721756, -93.108496];
Zipcodes['55790'] = [47.825233, -92.376129];
Zipcodes['55792'] = [47.597028, -92.479684];
Zipcodes['55793'] = [47.116305, -93.28397];
Zipcodes['55795'] = [46.298208, -92.882889];
Zipcodes['55796'] = [47.930019, -91.795004];
Zipcodes['55797'] = [46.5665, -92.362116];
Zipcodes['55798'] = [46.723206, -92.967784];
Zipcodes['55802'] = [46.764173, -92.082422];
Zipcodes['55803'] = [47.034891, -92.072208];
Zipcodes['55804'] = [46.931351, -91.936344];
Zipcodes['55805'] = [46.799685, -92.092366];
Zipcodes['55806'] = [46.768139, -92.127642];
Zipcodes['55807'] = [46.735485, -92.16794];
Zipcodes['55808'] = [46.678736, -92.237362];
Zipcodes['55810'] = [46.756745, -92.269437];
Zipcodes['55811'] = [46.83855, -92.222193];
Zipcodes['55812'] = [46.809362, -92.071786];
Zipcodes['55814'] = [46.835186, -92.197125];
Zipcodes['55901'] = [44.077657, -92.497063];
Zipcodes['55902'] = [43.971301, -92.507871];
Zipcodes['55903'] = [44.024073, -92.436529];
Zipcodes['55904'] = [43.95331, -92.401387];
Zipcodes['55905'] = [44.055948, -92.525906];
Zipcodes['55906'] = [44.099898, -92.408166];
Zipcodes['55909'] = [43.563125, -92.734624];
Zipcodes['55910'] = [44.12866, -91.971828];
Zipcodes['55912'] = [43.682607, -92.98845];
Zipcodes['55917'] = [43.88482, -93.075481];
Zipcodes['55918'] = [43.742365, -92.8467];
Zipcodes['55919'] = [43.620065, -91.299476];
Zipcodes['55920'] = [44.01242, -92.621101];
Zipcodes['55921'] = [43.620369, -91.475848];
Zipcodes['55922'] = [43.562105, -91.895571];
Zipcodes['55923'] = [43.849408, -92.183193];
Zipcodes['55924'] = [44.035571, -93.016111];
Zipcodes['55925'] = [43.912092, -91.458412];
Zipcodes['55926'] = [43.742744, -92.706901];
Zipcodes['55927'] = [44.033768, -92.873344];
Zipcodes['55929'] = [43.977606, -92.144093];
Zipcodes['55931'] = [43.508662, -91.385229];
Zipcodes['55932'] = [44.152205, -92.298336];
Zipcodes['55933'] = [43.647848, -92.685059];
Zipcodes['55934'] = [44.003839, -92.265751];
Zipcodes['55935'] = [43.747374, -92.127439];
Zipcodes['55936'] = [43.706444, -92.584793];
Zipcodes['55939'] = [43.546844, -92.08147];
Zipcodes['55940'] = [43.898614, -92.809489];
Zipcodes['55941'] = [43.724845, -91.349148];
Zipcodes['55943'] = [43.782961, -91.582681];
Zipcodes['55944'] = [43.989517, -92.718008];
Zipcodes['55945'] = [44.266873, -92.052504];
Zipcodes['55946'] = [44.262107, -92.950147];
Zipcodes['55947'] = [43.82289, -91.36636];
Zipcodes['55949'] = [43.702965, -91.929692];
Zipcodes['55950'] = [43.743455, -92.966283];
Zipcodes['55951'] = [43.529233, -92.482488];
Zipcodes['55952'] = [43.94393, -91.843354];
Zipcodes['55953'] = [43.527759, -92.965198];
Zipcodes['55954'] = [43.557061, -91.780106];
Zipcodes['55955'] = [44.08732, -92.74087];
Zipcodes['55956'] = [44.286019, -92.527603];
Zipcodes['55957'] = [44.253073, -92.296925];
Zipcodes['55959'] = [44.117944, -91.799533];
Zipcodes['55960'] = [44.140242, -92.535165];
Zipcodes['55961'] = [43.605396, -92.475415];
Zipcodes['55962'] = [43.762187, -91.845128];
Zipcodes['55963'] = [44.180829, -92.671135];
Zipcodes['55964'] = [44.160247, -92.158081];
Zipcodes['55965'] = [43.623194, -92.128187];
Zipcodes['55967'] = [43.793424, -92.513508];
Zipcodes['55968'] = [44.397037, -92.089941];
Zipcodes['55969'] = [44.082639, -91.876555];
Zipcodes['55970'] = [43.596762, -92.831223];
Zipcodes['55971'] = [43.819894, -91.75781];
Zipcodes['55972'] = [43.993925, -92.050909];
Zipcodes['55973'] = [43.807937, -92.771966];
Zipcodes['55974'] = [43.577137, -91.647987];
Zipcodes['55975'] = [43.677869, -92.364434];
Zipcodes['55976'] = [43.863814, -92.48404];
Zipcodes['55977'] = [43.553707, -92.647928];
Zipcodes['55979'] = [43.923054, -91.951337];
Zipcodes['55981'] = [44.35761, -92.049231];
Zipcodes['55982'] = [43.815788, -92.885837];
Zipcodes['55983'] = [44.291403, -92.806985];
Zipcodes['55985'] = [44.15822, -92.904395];
Zipcodes['55987'] = [43.994246, -91.625441];
Zipcodes['55990'] = [43.728463, -92.259118];
Zipcodes['55991'] = [44.261215, -92.418566];
Zipcodes['55992'] = [44.3228, -92.682884];
Zipcodes['56001'] = [44.128169, -93.978991];
Zipcodes['56003'] = [44.219273, -94.090329];
Zipcodes['56007'] = [43.660887, -93.324563];
Zipcodes['56009'] = [43.661833, -93.546405];
Zipcodes['56010'] = [43.886163, -94.179807];
Zipcodes['56011'] = [44.602461, -93.772619];
Zipcodes['56013'] = [43.62019, -94.103794];
Zipcodes['56014'] = [43.583158, -93.817898];
Zipcodes['56016'] = [43.762065, -93.343107];
Zipcodes['56017'] = [44.30535, -93.819535];
Zipcodes['56019'] = [44.115873, -94.888968];
Zipcodes['56020'] = [43.61458, -93.530173];
Zipcodes['56021'] = [44.289313, -94.316025];
Zipcodes['56022'] = [44.052625, -94.83861];
Zipcodes['56023'] = [43.771611, -94.012902];
Zipcodes['56024'] = [44.143552, -93.838858];
Zipcodes['56025'] = [43.753245, -93.917351];
Zipcodes['56026'] = [43.885765, -93.290995];
Zipcodes['56027'] = [43.516714, -94.116644];
Zipcodes['56028'] = [44.226257, -93.71742];
Zipcodes['56029'] = [43.517762, -93.536143];
Zipcodes['56030'] = [44.324821, -94.604835];
Zipcodes['56031'] = [43.626846, -94.462324];
Zipcodes['56032'] = [43.777124, -93.568742];
Zipcodes['56033'] = [43.56506, -93.938149];
Zipcodes['56034'] = [44.028598, -94.181962];
Zipcodes['56035'] = [43.819522, -93.272612];
Zipcodes['56036'] = [43.551882, -93.221023];
Zipcodes['56037'] = [44.011156, -94.038634];
Zipcodes['56039'] = [43.663418, -94.323586];
Zipcodes['56041'] = [44.161305, -94.533087];
Zipcodes['56042'] = [43.804093, -93.483859];
Zipcodes['56043'] = [43.649395, -93.222428];
Zipcodes['56044'] = [44.564707, -93.956838];
Zipcodes['56045'] = [43.759614, -93.209611];
Zipcodes['56046'] = [43.967814, -93.268871];
Zipcodes['56048'] = [44.094768, -93.713152];
Zipcodes['56050'] = [44.259143, -93.947348];
Zipcodes['56051'] = [43.536437, -93.70847];
Zipcodes['56052'] = [44.327066, -93.5587];
Zipcodes['56054'] = [44.435729, -94.370394];
Zipcodes['56055'] = [44.128492, -94.235283];
Zipcodes['56056'] = [44.07278, -94.579472];
Zipcodes['56057'] = [44.391251, -93.7221];
Zipcodes['56058'] = [44.44185, -93.914029];
Zipcodes['56060'] = [43.928735, -94.441065];
Zipcodes['56062'] = [44.048235, -94.413855];
Zipcodes['56063'] = [44.214892, -93.820513];
Zipcodes['56065'] = [43.93149, -93.908396];
Zipcodes['56068'] = [43.865306, -93.806142];
Zipcodes['56069'] = [44.412099, -93.542436];
Zipcodes['56071'] = [44.534963, -93.57945];
Zipcodes['56072'] = [43.899229, -93.517081];
Zipcodes['56073'] = [44.306837, -94.469485];
Zipcodes['56074'] = [44.326877, -94.196936];
Zipcodes['56075'] = [43.735161, -94.436621];
Zipcodes['56078'] = [44.005921, -93.760875];
Zipcodes['56080'] = [44.081722, -93.857876];
Zipcodes['56081'] = [43.992871, -94.634824];
Zipcodes['56082'] = [44.357287, -94.036965];
Zipcodes['56083'] = [44.208007, -95.134292];
Zipcodes['56085'] = [44.291376, -94.733572];
Zipcodes['56087'] = [44.240509, -94.975248];
Zipcodes['56088'] = [43.825427, -94.458131];
Zipcodes['56089'] = [43.567993, -93.457463];
Zipcodes['56090'] = [43.951314, -94.226965];
Zipcodes['56091'] = [43.914434, -93.686188];
Zipcodes['56093'] = [44.062566, -93.519762];
Zipcodes['56096'] = [44.244889, -93.603466];
Zipcodes['56097'] = [43.720968, -93.713282];
Zipcodes['56098'] = [43.793016, -94.181766];
Zipcodes['56101'] = [43.879726, -95.133825];
Zipcodes['56110'] = [43.622221, -95.945051];
Zipcodes['56111'] = [43.642681, -94.887167];
Zipcodes['56113'] = [44.404363, -96.1511];
Zipcodes['56114'] = [43.985864, -95.592498];
Zipcodes['56115'] = [44.209457, -95.905771];
Zipcodes['56116'] = [43.62379, -96.377012];
Zipcodes['56117'] = [43.523116, -95.687376];
Zipcodes['56118'] = [43.936028, -95.029368];
Zipcodes['56119'] = [43.713721, -95.462996];
Zipcodes['56120'] = [43.960117, -94.791747];
Zipcodes['56121'] = [43.534615, -94.584233];
Zipcodes['56122'] = [43.898445, -95.956];
Zipcodes['56123'] = [44.086937, -95.592414];
Zipcodes['56125'] = [44.054516, -95.547738];
Zipcodes['56127'] = [43.547487, -94.764716];
Zipcodes['56128'] = [43.881542, -96.110303];
Zipcodes['56129'] = [43.529203, -96.041896];
Zipcodes['56131'] = [43.855847, -95.572539];
Zipcodes['56132'] = [44.203451, -95.764941];
Zipcodes['56134'] = [43.789546, -96.231234];
Zipcodes['56136'] = [44.495189, -96.414125];
Zipcodes['56137'] = [43.822584, -95.30966];
Zipcodes['56138'] = [43.537043, -96.380224];
Zipcodes['56139'] = [44.103929, -96.179427];
Zipcodes['56140'] = [43.909432, -96.367168];
Zipcodes['56141'] = [43.886293, -95.803384];
Zipcodes['56142'] = [44.46764, -96.231042];
Zipcodes['56143'] = [43.627684, -95.002409];
Zipcodes['56144'] = [43.838593, -96.386803];
Zipcodes['56145'] = [44.055796, -95.173869];
Zipcodes['56146'] = [43.580062, -96.103432];
Zipcodes['56147'] = [43.757406, -96.057684];
Zipcodes['56149'] = [44.304157, -96.305564];
Zipcodes['56150'] = [43.628218, -95.197159];
Zipcodes['56151'] = [44.031451, -95.929422];
Zipcodes['56152'] = [44.227155, -95.281359];
Zipcodes['56153'] = [43.826383, -96.024232];
Zipcodes['56155'] = [43.765868, -95.946055];
Zipcodes['56156'] = [43.66151, -96.227229];
Zipcodes['56157'] = [44.404175, -95.948377];
Zipcodes['56158'] = [43.633815, -96.083931];
Zipcodes['56159'] = [43.943175, -94.937433];
Zipcodes['56160'] = [43.829148, -94.805151];
Zipcodes['56161'] = [43.707031, -95.324827];
Zipcodes['56162'] = [43.840329, -94.66735];
Zipcodes['56164'] = [44.053834, -96.333008];
Zipcodes['56165'] = [43.725343, -95.703057];
Zipcodes['56166'] = [44.203001, -95.380986];
Zipcodes['56167'] = [43.553927, -95.408728];
Zipcodes['56168'] = [43.609252, -95.799591];
Zipcodes['56169'] = [44.332055, -96.011097];
Zipcodes['56170'] = [44.16455, -96.095184];
Zipcodes['56171'] = [43.671285, -94.761789];
Zipcodes['56172'] = [44.014163, -95.775513];
Zipcodes['56173'] = [43.52506, -96.241864];
Zipcodes['56174'] = [44.016336, -95.305092];
Zipcodes['56175'] = [44.260701, -95.638506];
Zipcodes['56176'] = [43.783287, -94.720493];
Zipcodes['56178'] = [44.285664, -96.128091];
Zipcodes['56180'] = [44.246204, -95.459592];
Zipcodes['56181'] = [43.677795, -94.626602];
Zipcodes['56183'] = [44.029019, -95.431132];
Zipcodes['56185'] = [43.798534, -95.824238];
Zipcodes['56186'] = [44.025279, -96.077471];
Zipcodes['56187'] = [43.622795, -95.583202];
Zipcodes['56201'] = [45.102185, -95.041816];
Zipcodes['56207'] = [45.505918, -96.066571];
Zipcodes['56208'] = [45.246719, -96.00477];
Zipcodes['56209'] = [45.12725, -94.811967];
Zipcodes['56210'] = [45.557351, -96.559578];
Zipcodes['56211'] = [45.593133, -96.687979];
Zipcodes['56212'] = [45.130873, -96.323833];
Zipcodes['56214'] = [44.610477, -95.324368];
Zipcodes['56215'] = [45.317715, -95.571953];
Zipcodes['56216'] = [44.942501, -95.059379];
Zipcodes['56218'] = [44.826953, -95.940466];
Zipcodes['56219'] = [45.59729, -96.817874];
Zipcodes['56220'] = [44.726335, -96.291347];
Zipcodes['56221'] = [45.560013, -96.187081];
Zipcodes['56222'] = [44.985399, -95.356764];
Zipcodes['56223'] = [44.762144, -95.818773];
Zipcodes['56224'] = [44.375843, -95.068124];
Zipcodes['56225'] = [45.440973, -96.442682];
Zipcodes['56226'] = [45.40733, -95.678281];
Zipcodes['56227'] = [45.290841, -96.168828];
Zipcodes['56228'] = [44.951235, -94.685178];
Zipcodes['56229'] = [44.594036, -95.716384];
Zipcodes['56230'] = [44.792114, -95.088916];
Zipcodes['56231'] = [45.27951, -95.763212];
Zipcodes['56232'] = [44.92716, -96.03801];
Zipcodes['56235'] = [45.709727, -96.016509];
Zipcodes['56236'] = [45.664953, -96.396115];
Zipcodes['56237'] = [44.623589, -95.43109];
Zipcodes['56239'] = [44.504141, -95.910813];
Zipcodes['56240'] = [45.562563, -96.48365];
Zipcodes['56241'] = [44.797886, -95.572946];
Zipcodes['56243'] = [45.170236, -94.686485];
Zipcodes['56244'] = [45.477723, -95.780133];
Zipcodes['56245'] = [44.684045, -95.677295];
Zipcodes['56248'] = [45.78621, -96.135808];
Zipcodes['56249'] = [45.318731, -95.901564];
Zipcodes['56251'] = [45.143722, -94.920176];
Zipcodes['56252'] = [45.178253, -95.310123];
Zipcodes['56253'] = [44.95818, -94.866193];
Zipcodes['56255'] = [44.389516, -95.420958];
Zipcodes['56256'] = [45.016942, -96.212703];
Zipcodes['56257'] = [44.969258, -96.402151];
Zipcodes['56258'] = [44.436606, -95.746316];
Zipcodes['56260'] = [44.963275, -95.482997];
Zipcodes['56262'] = [45.115071, -95.869707];
Zipcodes['56263'] = [44.42149, -95.532478];
Zipcodes['56264'] = [44.586807, -95.981253];
Zipcodes['56265'] = [44.999823, -95.706482];
Zipcodes['56266'] = [44.421061, -94.914515];
Zipcodes['56267'] = [45.582463, -95.928793];
Zipcodes['56270'] = [44.559922, -94.965267];
Zipcodes['56271'] = [45.241158, -95.414434];
Zipcodes['56273'] = [45.317879, -94.979933];
Zipcodes['56274'] = [45.897893, -96.244534];
Zipcodes['56276'] = [45.240992, -96.307336];
Zipcodes['56277'] = [44.767422, -94.993117];
Zipcodes['56278'] = [45.348003, -96.379955];
Zipcodes['56279'] = [45.218831, -95.169843];
Zipcodes['56280'] = [44.632751, -96.165617];
Zipcodes['56281'] = [44.948323, -95.164811];
Zipcodes['56282'] = [45.02953, -95.223518];
Zipcodes['56283'] = [44.528006, -95.138872];
Zipcodes['56284'] = [44.773488, -95.212128];
Zipcodes['56285'] = [44.788327, -95.345197];
Zipcodes['56287'] = [44.475752, -95.326447];
Zipcodes['56288'] = [45.243115, -94.943643];
Zipcodes['56289'] = [45.333188, -95.224451];
Zipcodes['56291'] = [44.622612, -96.062063];
Zipcodes['56292'] = [44.502071, -95.456501];
Zipcodes['56293'] = [44.416511, -95.259903];
Zipcodes['56294'] = [44.314874, -95.213085];
Zipcodes['56295'] = [45.043139, -95.848874];
Zipcodes['56296'] = [45.82561, -96.477229];
Zipcodes['56297'] = [44.636795, -95.553607];
Zipcodes['56301'] = [45.487084, -94.242777];
Zipcodes['56303'] = [45.574017, -94.215271];
Zipcodes['56304'] = [45.53037, -94.057203];
Zipcodes['56307'] = [45.62681, -94.591029];
Zipcodes['56308'] = [45.867614, -95.394257];
Zipcodes['56309'] = [46.07926, -95.805971];
Zipcodes['56310'] = [45.633476, -94.451336];
Zipcodes['56311'] = [45.897453, -95.895579];
Zipcodes['56312'] = [45.4566, -94.969177];
Zipcodes['56313'] = [45.784022, -93.552745];
Zipcodes['56314'] = [45.814701, -94.432957];
Zipcodes['56315'] = [45.980142, -95.593376];
Zipcodes['56316'] = [45.505682, -95.142779];
Zipcodes['56318'] = [45.853038, -94.67453];
Zipcodes['56319'] = [46.010823, -95.215688];
Zipcodes['56320'] = [45.466777, -94.402803];
Zipcodes['56321'] = [45.579722, -94.391868];
Zipcodes['56323'] = [45.629632, -95.723921];
Zipcodes['56324'] = [46.16896, -95.904066];
Zipcodes['56325'] = [45.562902, -94.947199];
Zipcodes['56326'] = [46.039558, -95.670809];
Zipcodes['56327'] = [45.754477, -95.608226];
Zipcodes['56328'] = [45.951903, -94.521467];
Zipcodes['56329'] = [45.709067, -93.914815];
Zipcodes['56330'] = [45.753241, -93.756384];
Zipcodes['56331'] = [45.678998, -94.671722];
Zipcodes['56332'] = [45.967416, -95.506404];
Zipcodes['56334'] = [45.574381, -95.33678];
Zipcodes['56335'] = [45.60137, -94.859334];
Zipcodes['56336'] = [45.820725, -94.762557];
Zipcodes['56338'] = [46.002981, -93.834143];
Zipcodes['56339'] = [45.824978, -95.821158];
Zipcodes['56340'] = [45.759467, -94.429019];
Zipcodes['56342'] = [46.18381, -93.368969];
Zipcodes['56343'] = [45.796066, -95.713065];
Zipcodes['56345'] = [46.009996, -94.383791];
Zipcodes['56347'] = [45.970711, -94.860558];
Zipcodes['56349'] = [45.71822, -95.52014];
Zipcodes['56350'] = [46.305029, -93.230407];
Zipcodes['56352'] = [45.6418, -94.806359];
Zipcodes['56353'] = [45.803664, -93.629079];
Zipcodes['56354'] = [46.064044, -95.281737];
Zipcodes['56355'] = [45.941442, -95.245716];
Zipcodes['56356'] = [45.6336, -94.72762];
Zipcodes['56357'] = [45.691525, -93.798163];
Zipcodes['56358'] = [45.83798, -93.446139];
Zipcodes['56359'] = [46.099612, -93.66533];
Zipcodes['56360'] = [45.88734, -95.103613];
Zipcodes['56361'] = [46.160236, -95.332488];
Zipcodes['56362'] = [45.402031, -94.712237];
Zipcodes['56363'] = [45.69715, -93.652212];
Zipcodes['56364'] = [46.018654, -94.071943];
Zipcodes['56367'] = [45.763756, -94.182051];
Zipcodes['56368'] = [45.464267, -94.56116];
Zipcodes['56369'] = [45.469038, -94.335834];
Zipcodes['56371'] = [45.433334, -94.637167];
Zipcodes['56373'] = [45.85974, -94.230562];
Zipcodes['56374'] = [45.565877, -94.316571];
Zipcodes['56375'] = [45.701222, -94.274581];
Zipcodes['56376'] = [45.502461, -94.667468];
Zipcodes['56377'] = [45.64023, -94.226839];
Zipcodes['56378'] = [45.725034, -94.987283];
Zipcodes['56379'] = [45.639682, -94.082677];
Zipcodes['56381'] = [45.556817, -95.537775];
Zipcodes['56382'] = [45.907863, -94.598327];
Zipcodes['56384'] = [45.809944, -94.567527];
Zipcodes['56385'] = [45.68966, -95.21375];
Zipcodes['56386'] = [46.114439, -93.519502];
Zipcodes['56387'] = [45.520868, -94.242982];
Zipcodes['56389'] = [45.801276, -95.086381];
Zipcodes['56401'] = [46.322919, -94.12521];
Zipcodes['56425'] = [46.343935, -94.276015];
Zipcodes['56431'] = [46.49109, -93.635569];
Zipcodes['56433'] = [46.950669, -94.688275];
Zipcodes['56434'] = [46.337962, -94.934379];
Zipcodes['56435'] = [46.805864, -94.531858];
Zipcodes['56437'] = [46.245745, -95.058493];
Zipcodes['56438'] = [46.1386, -94.819179];
Zipcodes['56440'] = [46.141976, -94.955089];
Zipcodes['56441'] = [46.577763, -93.98148];
Zipcodes['56442'] = [46.678212, -94.102014];
Zipcodes['56443'] = [46.174026, -94.579512];
Zipcodes['56444'] = [46.435784, -93.888944];
Zipcodes['56446'] = [46.128838, -95.09749];
Zipcodes['56447'] = [46.731923, -93.883667];
Zipcodes['56448'] = [46.78812, -94.082154];
Zipcodes['56449'] = [46.178548, -94.261423];
Zipcodes['56450'] = [46.236874, -93.843027];
Zipcodes['56452'] = [46.957022, -94.448224];
Zipcodes['56453'] = [46.316002, -95.175374];
Zipcodes['56455'] = [46.440986, -94.000623];
Zipcodes['56458'] = [47.195795, -95.002022];
Zipcodes['56461'] = [47.232073, -94.853912];
Zipcodes['56464'] = [46.768824, -95.10417];
Zipcodes['56465'] = [46.537322, -94.11443];
Zipcodes['56466'] = [46.436002, -94.624955];
Zipcodes['56467'] = [46.974819, -94.830974];
Zipcodes['56468'] = [46.493254, -94.311291];
Zipcodes['56469'] = [46.781236, -93.566778];
Zipcodes['56470'] = [47.026894, -95.091886];
Zipcodes['56472'] = [46.582338, -94.340826];
Zipcodes['56473'] = [46.358254, -94.469836];
Zipcodes['56474'] = [46.723289, -94.396599];
Zipcodes['56475'] = [46.079719, -94.545992];
Zipcodes['56477'] = [46.644616, -95.000175];
Zipcodes['56479'] = [46.434608, -94.794542];
Zipcodes['56481'] = [46.463002, -94.93624];
Zipcodes['56482'] = [46.464284, -95.147694];
Zipcodes['56484'] = [47.111209, -94.482388];
Zipcodes['56501'] = [46.840111, -95.827361];
Zipcodes['56510'] = [47.35881, -96.524868];
Zipcodes['56511'] = [46.842476, -96.016346];
Zipcodes['56514'] = [46.64577, -96.431434];
Zipcodes['56515'] = [46.307322, -95.690808];
Zipcodes['56516'] = [47.444667, -95.971019];
Zipcodes['56517'] = [47.550084, -96.559409];
Zipcodes['56518'] = [46.490076, -95.250361];
Zipcodes['56519'] = [47.161941, -96.478978];
Zipcodes['56520'] = [46.28975, -96.497076];
Zipcodes['56521'] = [46.999299, -95.901808];
Zipcodes['56522'] = [46.134508, -96.389964];
Zipcodes['56523'] = [47.638349, -96.80865];
Zipcodes['56524'] = [46.216786, -95.615481];
Zipcodes['56525'] = [46.671573, -96.751935];
Zipcodes['56527'] = [46.372621, -95.314968];
Zipcodes['56528'] = [46.521354, -95.823814];
Zipcodes['56529'] = [46.878919, -96.696187];
Zipcodes['56531'] = [45.991594, -95.981567];
Zipcodes['56533'] = [46.398688, -96.1421];
Zipcodes['56534'] = [46.471585, -96.011412];
Zipcodes['56535'] = [47.677541, -96.041655];
Zipcodes['56536'] = [47.041467, -96.532866];
Zipcodes['56537'] = [46.276711, -96.091938];
Zipcodes['56540'] = [47.540347, -96.274227];
Zipcodes['56541'] = [47.17327, -96.141377];
Zipcodes['56542'] = [47.568077, -95.727783];
Zipcodes['56543'] = [46.285406, -96.336533];
Zipcodes['56544'] = [46.757786, -95.605437];
Zipcodes['56545'] = [47.397374, -96.243251];
Zipcodes['56546'] = [47.104877, -96.720074];
Zipcodes['56547'] = [46.889472, -96.553728];
Zipcodes['56548'] = [47.368272, -96.745362];
Zipcodes['56549'] = [46.847746, -96.312245];
Zipcodes['56550'] = [47.273933, -96.74306];
Zipcodes['56551'] = [46.318939, -95.454375];
Zipcodes['56552'] = [46.985199, -96.234112];
Zipcodes['56553'] = [46.429417, -96.617555];
Zipcodes['56554'] = [46.885536, -96.116088];
Zipcodes['56556'] = [47.662952, -95.879974];
Zipcodes['56557'] = [47.303709, -95.835947];
Zipcodes['56560'] = [46.835221, -96.734828];
Zipcodes['56562'] = [46.864506, -96.770166];
Zipcodes['56563'] = [46.866814, -96.755342];
Zipcodes['56565'] = [46.048626, -96.315132];
Zipcodes['56566'] = [47.245965, -95.604853];
Zipcodes['56567'] = [46.549791, -95.374425];
Zipcodes['56568'] = [47.535231, -96.783178];
Zipcodes['56569'] = [47.078388, -95.827042];
Zipcodes['56570'] = [46.917929, -95.367136];
Zipcodes['56571'] = [46.433809, -95.542709];
Zipcodes['56572'] = [46.605236, -96.084155];
Zipcodes['56573'] = [46.610235, -95.542244];
Zipcodes['56574'] = [47.198997, -96.705549];
Zipcodes['56575'] = [47.042407, -95.434841];
Zipcodes['56576'] = [46.475948, -95.739557];
Zipcodes['56578'] = [46.940894, -95.641496];
Zipcodes['56579'] = [46.463532, -96.338658];
Zipcodes['56580'] = [46.736062, -96.604014];
Zipcodes['56581'] = [47.462333, -96.761828];
Zipcodes['56583'] = [46.003328, -96.406589];
Zipcodes['56584'] = [47.223664, -96.237407];
Zipcodes['56585'] = [47.088342, -96.246308];
Zipcodes['56586'] = [46.318269, -95.8309];
Zipcodes['56587'] = [46.636631, -95.856523];
Zipcodes['56588'] = [46.209179, -95.496981];
Zipcodes['56589'] = [47.21196, -95.727525];
Zipcodes['56590'] = [46.028103, -96.152761];
Zipcodes['56591'] = [47.101357, -95.857318];
Zipcodes['56592'] = [47.531609, -96.007823];
Zipcodes['56594'] = [46.549021, -96.644934];
Zipcodes['56601'] = [47.510829, -94.82092];
Zipcodes['56621'] = [47.397973, -95.414062];
Zipcodes['56623'] = [48.596877, -94.600709];
Zipcodes['56626'] = [47.376691, -94.149463];
Zipcodes['56627'] = [48.194406, -94.047949];
Zipcodes['56628'] = [47.702088, -93.533741];
Zipcodes['56629'] = [48.512189, -94.165466];
Zipcodes['56630'] = [47.738959, -94.510324];
Zipcodes['56633'] = [47.318784, -94.50118];
Zipcodes['56634'] = [47.680725, -95.39813];
Zipcodes['56636'] = [47.39047, -93.84871];
Zipcodes['56637'] = [47.618205, -93.843619];
Zipcodes['56639'] = [47.918539, -93.536233];
Zipcodes['56641'] = [47.201891, -94.260143];
Zipcodes['56644'] = [47.778021, -95.512732];
Zipcodes['56646'] = [47.783068, -95.633256];
Zipcodes['56647'] = [47.689935, -94.618731];
Zipcodes['56649'] = [48.533133, -93.163134];
Zipcodes['56650'] = [47.998056, -94.557478];
Zipcodes['56651'] = [47.454496, -95.616723];
Zipcodes['56652'] = [47.708135, -95.22766];
Zipcodes['56653'] = [48.283385, -93.574938];
Zipcodes['56654'] = [48.431201, -93.964932];
Zipcodes['56655'] = [46.980791, -94.231476];
Zipcodes['56657'] = [47.57291, -93.63673];
Zipcodes['56658'] = [48.112487, -93.932981];
Zipcodes['56659'] = [47.551229, -94.048817];
Zipcodes['56660'] = [47.992586, -94.192822];
Zipcodes['56661'] = [47.835047, -94.125028];
Zipcodes['56662'] = [46.862207, -93.853011];
Zipcodes['56663'] = [47.505946, -94.44265];
Zipcodes['56666'] = [48.033237, -94.821241];
Zipcodes['56667'] = [47.722113, -94.968993];
Zipcodes['56668'] = [48.61505, -93.345358];
Zipcodes['56669'] = [48.371352, -93.210038];
Zipcodes['56670'] = [47.828897, -94.865169];
Zipcodes['56671'] = [47.943399, -95.338714];
Zipcodes['56672'] = [47.072504, -94.00184];
Zipcodes['56673'] = [48.774487, -95.149609];
Zipcodes['56676'] = [47.522354, -95.201072];
Zipcodes['56678'] = [47.43646, -95.115242];
Zipcodes['56680'] = [47.659009, -93.954669];
Zipcodes['56681'] = [47.553943, -94.264157];
Zipcodes['56683'] = [47.736271, -94.7509];
Zipcodes['56684'] = [47.870168, -95.699374];
Zipcodes['56685'] = [48.238688, -94.531271];
Zipcodes['56686'] = [48.784049, -94.911697];
Zipcodes['56688'] = [47.805467, -93.839563];
Zipcodes['56701'] = [48.120464, -96.200014];
Zipcodes['56710'] = [48.218086, -96.997682];
Zipcodes['56711'] = [48.984208, -95.058739];
Zipcodes['56713'] = [48.351138, -96.814456];
Zipcodes['56714'] = [48.788391, -96.005467];
Zipcodes['56715'] = [47.806422, -95.949087];
Zipcodes['56716'] = [47.743247, -96.555408];
Zipcodes['56720'] = [48.563465, -96.904542];
Zipcodes['56721'] = [47.970648, -96.949756];
Zipcodes['56722'] = [47.984776, -96.650566];
Zipcodes['56723'] = [47.804902, -96.805928];
Zipcodes['56724'] = [48.445299, -95.734759];
Zipcodes['56725'] = [48.143921, -95.711952];
Zipcodes['56726'] = [48.724517, -96.217151];
Zipcodes['56727'] = [48.34688, -95.389489];
Zipcodes['56728'] = [48.784278, -96.977461];
Zipcodes['56729'] = [48.669163, -96.593584];
Zipcodes['56731'] = [48.913017, -97.072561];
Zipcodes['56732'] = [48.584211, -96.46733];
Zipcodes['56733'] = [48.638028, -96.895705];
Zipcodes['56734'] = [48.769619, -96.603622];
Zipcodes['56735'] = [48.910175, -96.635315];
Zipcodes['56736'] = [47.706473, -96.211901];
Zipcodes['56737'] = [48.406856, -96.009116];
Zipcodes['56738'] = [48.347178, -96.337148];
Zipcodes['56742'] = [47.9306, -95.827932];
Zipcodes['56744'] = [48.232575, -97.117183];
Zipcodes['56748'] = [47.920629, -96.007445];
Zipcodes['56750'] = [47.88585, -96.297316];
Zipcodes['56751'] = [48.811428, -95.757474];
Zipcodes['56754'] = [47.983396, -96.187004];
Zipcodes['56755'] = [48.969716, -97.117923];
Zipcodes['56756'] = [48.900935, -95.560277];
Zipcodes['56757'] = [48.478088, -96.898134];
Zipcodes['56758'] = [48.470412, -96.54114];
Zipcodes['56759'] = [48.550867, -96.081018];
Zipcodes['56760'] = [48.240377, -96.445796];
Zipcodes['56761'] = [48.608619, -95.697081];
Zipcodes['56762'] = [48.168765, -96.74148];
Zipcodes['56763'] = [48.807304, -95.341393];
Zipcodes['57001'] = [43.004185, -96.657255];
Zipcodes['57002'] = [44.287826, -96.677529];
Zipcodes['57003'] = [43.746275, -96.755397];
Zipcodes['57004'] = [43.075775, -96.792476];
Zipcodes['57005'] = [43.593654, -96.587013];
Zipcodes['57006'] = [44.316217, -96.79436];
Zipcodes['57007'] = [44.319236, -96.783974];
Zipcodes['57010'] = [42.811905, -96.816221];
Zipcodes['57012'] = [43.593944, -97.278094];
Zipcodes['57013'] = [43.283105, -96.624962];
Zipcodes['57014'] = [43.111915, -96.95616];
Zipcodes['57015'] = [43.425185, -96.97361];
Zipcodes['57016'] = [43.878074, -96.957571];
Zipcodes['57017'] = [44.033164, -96.819173];
Zipcodes['57018'] = [43.797893, -96.981006];
Zipcodes['57020'] = [43.681278, -96.820156];
Zipcodes['57021'] = [43.274875, -96.980559];
Zipcodes['57022'] = [43.856284, -96.736592];
Zipcodes['57024'] = [43.985418, -96.681515];
Zipcodes['57025'] = [42.715058, -96.69453];
Zipcodes['57026'] = [44.247673, -96.495922];
Zipcodes['57027'] = [43.192125, -96.519558];
Zipcodes['57028'] = [44.061654, -96.599779];
Zipcodes['57029'] = [43.334745, -97.470784];
Zipcodes['57030'] = [43.735725, -96.54371];
Zipcodes['57031'] = [42.875524, -97.185859];
Zipcodes['57032'] = [43.424196, -96.678113];
Zipcodes['57033'] = [43.612706, -96.958466];
Zipcodes['57034'] = [43.120164, -96.538284];
Zipcodes['57035'] = [43.646374, -97.079759];
Zipcodes['57036'] = [43.266729, -97.145938];
Zipcodes['57037'] = [43.104465, -97.24922];
Zipcodes['57038'] = [42.577928, -96.576599];
Zipcodes['57039'] = [43.337838, -96.874855];
Zipcodes['57040'] = [43.061032, -97.58735];
Zipcodes['57041'] = [43.721837, -96.884158];
Zipcodes['57042'] = [43.971319, -97.144098];
Zipcodes['57043'] = [43.422611, -97.310061];
Zipcodes['57045'] = [43.205008, -97.528384];
Zipcodes['57046'] = [42.957636, -97.306648];
Zipcodes['57047'] = [43.517551, -97.215251];
Zipcodes['57048'] = [43.741984, -97.200336];
Zipcodes['57049'] = [42.523328, -96.509711];
Zipcodes['57050'] = [44.156906, -97.0201];
Zipcodes['57051'] = [44.217416, -97.350687];
Zipcodes['57052'] = [43.292356, -97.707966];
Zipcodes['57053'] = [43.409594, -97.1372];
Zipcodes['57054'] = [44.11673, -97.259525];
Zipcodes['57055'] = [43.66685, -96.743898];
Zipcodes['57057'] = [44.102232, -96.95925];
Zipcodes['57058'] = [43.743878, -97.401911];
Zipcodes['57059'] = [43.119205, -97.764306];
Zipcodes['57061'] = [44.244494, -97.044031];
Zipcodes['57062'] = [42.846199, -97.945247];
Zipcodes['57063'] = [42.930268, -97.684565];
Zipcodes['57064'] = [43.455629, -96.874889];
Zipcodes['57065'] = [43.900434, -96.606295];
Zipcodes['57066'] = [42.988653, -97.867208];
Zipcodes['57067'] = [43.045592, -97.469006];
Zipcodes['57068'] = [43.572833, -96.499455];
Zipcodes['57069'] = [42.844619, -96.986388];
Zipcodes['57070'] = [43.182034, -97.170165];
Zipcodes['57071'] = [44.272319, -96.962925];
Zipcodes['57072'] = [42.988979, -97.205028];
Zipcodes['57073'] = [43.005958, -97.055057];
Zipcodes['57075'] = [43.99616, -96.967302];
Zipcodes['57076'] = [43.971525, -97.359727];
Zipcodes['57077'] = [43.308778, -96.752405];
Zipcodes['57078'] = [42.915648, -97.446689];
Zipcodes['57103'] = [43.538037, -96.69203];
Zipcodes['57104'] = [43.603016, -96.704273];
Zipcodes['57105'] = [43.520296, -96.735607];
Zipcodes['57106'] = [43.510502, -96.834884];
Zipcodes['57107'] = [43.598741, -96.816958];
Zipcodes['57108'] = [43.477461, -96.704757];
Zipcodes['57110'] = [43.544679, -96.638642];
Zipcodes['57117'] = [43.531243, -96.754971];
Zipcodes['57197'] = [43.524551, -96.73861];
Zipcodes['57201'] = [44.936722, -97.092757];
Zipcodes['57212'] = [44.37853, -97.139459];
Zipcodes['57213'] = [44.57012, -96.536883];
Zipcodes['57214'] = [44.480491, -97.195146];
Zipcodes['57216'] = [45.30324, -96.529779];
Zipcodes['57217'] = [45.086733, -97.665461];
Zipcodes['57218'] = [44.652379, -96.592103];
Zipcodes['57219'] = [45.290641, -97.796148];
Zipcodes['57220'] = [44.45976, -96.917332];
Zipcodes['57221'] = [44.608917, -97.441378];
Zipcodes['57223'] = [44.712299, -96.994353];
Zipcodes['57224'] = [45.811883, -97.155275];
Zipcodes['57225'] = [44.883544, -97.728093];
Zipcodes['57226'] = [44.788077, -96.730646];
Zipcodes['57227'] = [45.375905, -96.728121];
Zipcodes['57231'] = [44.375816, -97.582277];
Zipcodes['57232'] = [45.623412, -97.399926];
Zipcodes['57233'] = [44.509922, -97.399324];
Zipcodes['57234'] = [44.610285, -96.894291];
Zipcodes['57235'] = [45.071967, -97.320432];
Zipcodes['57236'] = [44.985548, -97.585622];
Zipcodes['57237'] = [44.797699, -96.500497];
Zipcodes['57238'] = [44.873359, -96.860868];
Zipcodes['57239'] = [45.510884, -97.31312];
Zipcodes['57241'] = [44.695552, -97.216193];
Zipcodes['57242'] = [44.774131, -97.357985];
Zipcodes['57243'] = [44.912943, -97.44237];
Zipcodes['57245'] = [44.893654, -96.918338];
Zipcodes['57246'] = [45.054911, -96.66698];
Zipcodes['57247'] = [45.712201, -97.463181];
Zipcodes['57248'] = [44.583522, -97.18799];
Zipcodes['57249'] = [44.383398, -97.370502];
Zipcodes['57251'] = [45.276515, -96.934706];
Zipcodes['57252'] = [45.200406, -96.612559];
Zipcodes['57255'] = [45.851107, -96.947957];
Zipcodes['57256'] = [45.249962, -97.196982];
Zipcodes['57257'] = [45.49661, -97.030948];
Zipcodes['57258'] = [44.882722, -97.927448];
Zipcodes['57259'] = [45.002493, -96.563489];
Zipcodes['57260'] = [45.841733, -96.719612];
Zipcodes['57261'] = [45.545582, -97.49956];
Zipcodes['57262'] = [45.668703, -97.073638];
Zipcodes['57263'] = [45.140528, -97.00799];
Zipcodes['57264'] = [45.107946, -96.81089];
Zipcodes['57265'] = [44.992127, -96.781509];
Zipcodes['57266'] = [45.28982, -97.092722];
Zipcodes['57268'] = [44.567964, -96.689414];
Zipcodes['57269'] = [45.224257, -96.800243];
Zipcodes['57270'] = [45.848663, -97.330977];
Zipcodes['57271'] = [44.75847, -97.538215];
Zipcodes['57272'] = [45.108232, -97.4837];
Zipcodes['57273'] = [45.333152, -97.275195];
Zipcodes['57274'] = [45.31018, -97.555747];
Zipcodes['57276'] = [44.435942, -96.617911];
Zipcodes['57278'] = [44.648834, -97.681153];
Zipcodes['57279'] = [45.41575, -96.869201];
Zipcodes['57301'] = [43.710912, -98.040449];
Zipcodes['57311'] = [43.650512, -97.764648];
Zipcodes['57312'] = [44.193181, -98.38401];
Zipcodes['57313'] = [43.304789, -98.354755];
Zipcodes['57314'] = [44.046091, -97.981288];
Zipcodes['57315'] = [42.988388, -98.040294];
Zipcodes['57317'] = [43.143555, -98.966447];
Zipcodes['57319'] = [43.533018, -97.480025];
Zipcodes['57321'] = [43.870552, -97.54036];
Zipcodes['57322'] = [44.637971, -97.961977];
Zipcodes['57323'] = [44.1648, -97.72028];
Zipcodes['57324'] = [44.301451, -98.045408];
Zipcodes['57325'] = [43.834901, -99.31203];
Zipcodes['57328'] = [43.437622, -98.439006];
Zipcodes['57329'] = [42.980663, -98.145755];
Zipcodes['57330'] = [43.264542, -98.157694];
Zipcodes['57331'] = [43.474415, -98.044848];
Zipcodes['57332'] = [43.5505, -97.656776];
Zipcodes['57334'] = [43.535165, -97.996338];
Zipcodes['57335'] = [43.065138, -98.743972];
Zipcodes['57337'] = [44.00208, -97.781101];
Zipcodes['57339'] = [44.126676, -99.463922];
Zipcodes['57340'] = [43.792912, -97.852171];
Zipcodes['57341'] = [44.094667, -99.117978];
Zipcodes['57342'] = [43.244625, -98.701247];
Zipcodes['57344'] = [43.449198, -98.618863];
Zipcodes['57345'] = [44.578381, -99.444401];
Zipcodes['57346'] = [44.249138, -99.456674];
Zipcodes['57348'] = [44.625569, -98.37222];
Zipcodes['57349'] = [44.030097, -97.5808];
Zipcodes['57350'] = [44.392466, -98.201934];
Zipcodes['57353'] = [44.369382, -97.834605];
Zipcodes['57354'] = [43.193624, -97.844102];
Zipcodes['57355'] = [43.783607, -98.931444];
Zipcodes['57356'] = [43.145225, -98.513188];
Zipcodes['57358'] = [44.075188, -98.422024];
Zipcodes['57359'] = [43.902463, -98.154388];
Zipcodes['57361'] = [42.994574, -98.434602];
Zipcodes['57362'] = [44.469468, -99.071406];
Zipcodes['57363'] = [43.680487, -98.25552];
Zipcodes['57364'] = [43.434393, -98.617803];
Zipcodes['57365'] = [43.811327, -99.416428];
Zipcodes['57366'] = [43.391569, -97.946598];
Zipcodes['57367'] = [43.065703, -98.503];
Zipcodes['57368'] = [43.749509, -98.472097];
Zipcodes['57369'] = [43.420751, -98.953012];
Zipcodes['57370'] = [43.816777, -99.16819];
Zipcodes['57371'] = [44.437149, -99.221494];
Zipcodes['57373'] = [44.530648, -98.878286];
Zipcodes['57374'] = [43.753348, -97.599126];
Zipcodes['57375'] = [43.556414, -98.479919];
Zipcodes['57376'] = [43.219877, -97.944868];
Zipcodes['57379'] = [44.270105, -98.52918];
Zipcodes['57380'] = [43.030618, -98.29855];
Zipcodes['57381'] = [44.388019, -98.730903];
Zipcodes['57382'] = [44.055549, -98.679659];
Zipcodes['57383'] = [43.71733, -98.712725];
Zipcodes['57384'] = [44.416836, -98.485357];
Zipcodes['57385'] = [44.046746, -98.30107];
Zipcodes['57386'] = [44.506667, -97.996974];
Zipcodes['57401'] = [45.482335, -98.53254];
Zipcodes['57420'] = [45.281852, -100.142521];
Zipcodes['57421'] = [45.755478, -97.907063];
Zipcodes['57422'] = [45.424957, -97.927804];
Zipcodes['57424'] = [45.020492, -98.523182];
Zipcodes['57426'] = [45.73484, -98.493636];
Zipcodes['57427'] = [45.480547, -98.318576];
Zipcodes['57428'] = [45.452705, -99.67822];
Zipcodes['57429'] = [45.161167, -98.300506];
Zipcodes['57430'] = [45.834926, -97.719666];
Zipcodes['57432'] = [45.682867, -98.031046];
Zipcodes['57433'] = [45.638241, -98.320765];
Zipcodes['57434'] = [45.167107, -98.071969];
Zipcodes['57435'] = [45.153847, -98.914249];
Zipcodes['57436'] = [44.835547, -98.076968];
Zipcodes['57437'] = [45.789053, -99.624988];
Zipcodes['57438'] = [45.065476, -99.153564];
Zipcodes['57439'] = [45.33465, -98.100066];
Zipcodes['57440'] = [44.853426, -98.256687];
Zipcodes['57441'] = [45.826421, -98.513499];
Zipcodes['57442'] = [45.06175, -100.158139];
Zipcodes['57445'] = [45.465537, -98.114967];
Zipcodes['57446'] = [45.870828, -98.15267];
Zipcodes['57448'] = [45.612128, -99.433272];
Zipcodes['57449'] = [45.726515, -98.176162];
Zipcodes['57450'] = [45.20995, -99.82746];
Zipcodes['57451'] = [45.437923, -98.936584];
Zipcodes['57452'] = [45.529397, -99.862852];
Zipcodes['57454'] = [45.611554, -97.76148];
Zipcodes['57455'] = [45.00698, -99.683039];
Zipcodes['57456'] = [45.721001, -98.999797];
Zipcodes['57457'] = [45.877277, -99.154299];
Zipcodes['57460'] = [45.279575, -98.670681];
Zipcodes['57461'] = [45.164689, -98.442109];
Zipcodes['57465'] = [45.150666, -98.666504];
Zipcodes['57466'] = [45.196976, -99.458493];
Zipcodes['57467'] = [44.836772, -99.180338];
Zipcodes['57468'] = [45.49498, -97.743213];
Zipcodes['57469'] = [44.857384, -98.571778];
Zipcodes['57470'] = [44.883901, -98.894286];
Zipcodes['57471'] = [45.404536, -99.318265];
Zipcodes['57472'] = [45.427652, -100.110208];
Zipcodes['57473'] = [44.991451, -99.471014];
Zipcodes['57474'] = [45.292014, -98.278733];
Zipcodes['57475'] = [45.223553, -99.624634];
Zipcodes['57476'] = [44.706167, -98.615249];
Zipcodes['57477'] = [45.03382, -98.144343];
Zipcodes['57479'] = [45.304151, -98.43819];
Zipcodes['57481'] = [45.662592, -98.682958];
Zipcodes['57501'] = [44.527632, -100.309123];
Zipcodes['57520'] = [44.852798, -100.193658];
Zipcodes['57521'] = [43.792089, -101.182802];
Zipcodes['57522'] = [44.510544, -99.963858];
Zipcodes['57523'] = [43.247619, -99.23661];
Zipcodes['57528'] = [43.177211, -99.760346];
Zipcodes['57529'] = [43.230602, -99.563794];
Zipcodes['57531'] = [43.973671, -100.485912];
Zipcodes['57532'] = [44.364929, -100.565604];
Zipcodes['57533'] = [43.350367, -99.420272];
Zipcodes['57534'] = [43.561204, -99.651941];
Zipcodes['57536'] = [44.417429, -99.774218];
Zipcodes['57537'] = [44.578086, -101.067304];
Zipcodes['57538'] = [43.121162, -99.156625];
Zipcodes['57540'] = [44.461425, -99.607861];
Zipcodes['57541'] = [43.597722, -99.911895];
Zipcodes['57543'] = [43.747765, -101.521076];
Zipcodes['57544'] = [43.883449, -99.843986];
Zipcodes['57547'] = [43.489599, -101.423692];
Zipcodes['57548'] = [44.168374, -99.588372];
Zipcodes['57551'] = [43.191962, -101.708294];
Zipcodes['57552'] = [44.262437, -101.217154];
Zipcodes['57553'] = [44.474594, -101.645588];
Zipcodes['57555'] = [43.285887, -100.666559];
Zipcodes['57559'] = [43.931283, -100.722674];
Zipcodes['57560'] = [43.437137, -101.209565];
Zipcodes['57562'] = [43.897568, -100.958429];
Zipcodes['57563'] = [43.349949, -100.421261];
Zipcodes['57564'] = [44.740369, -100.014646];
Zipcodes['57566'] = [43.3087, -101.089361];
Zipcodes['57567'] = [44.133584, -101.707853];
Zipcodes['57568'] = [43.876471, -100.096054];
Zipcodes['57569'] = [43.859159, -99.554062];
Zipcodes['57570'] = [43.252173, -100.885154];
Zipcodes['57571'] = [43.041253, -99.090095];
Zipcodes['57572'] = [43.137209, -101.100455];
Zipcodes['57574'] = [43.125763, -101.385805];
Zipcodes['57576'] = [43.906086, -100.292316];
Zipcodes['57577'] = [43.523767, -101.655861];
Zipcodes['57579'] = [43.576177, -100.788909];
Zipcodes['57580'] = [43.289423, -100.090188];
Zipcodes['57584'] = [43.490154, -100.061799];
Zipcodes['57585'] = [43.558006, -100.385405];
Zipcodes['57601'] = [45.338976, -100.364733];
Zipcodes['57620'] = [45.377258, -102.540212];
Zipcodes['57621'] = [45.772788, -101.081389];
Zipcodes['57622'] = [44.682659, -101.566327];
Zipcodes['57623'] = [45.015928, -101.652998];
Zipcodes['57625'] = [44.979017, -101.025137];
Zipcodes['57626'] = [44.996783, -102.174187];
Zipcodes['57630'] = [45.333778, -100.844046];
Zipcodes['57631'] = [45.589095, -100.296128];
Zipcodes['57632'] = [45.833456, -100.044213];
Zipcodes['57633'] = [45.441592, -101.532383];
Zipcodes['57634'] = [45.773244, -101.865567];
Zipcodes['57636'] = [45.032464, -101.438087];
Zipcodes['57638'] = [45.869861, -102.23206];
Zipcodes['57639'] = [45.678661, -100.807036];
Zipcodes['57640'] = [45.768832, -102.704525];
Zipcodes['57641'] = [45.896186, -101.311106];
Zipcodes['57642'] = [45.790775, -100.71484];
Zipcodes['57644'] = [45.477538, -102.093672];
Zipcodes['57645'] = [45.907315, -101.716409];
Zipcodes['57646'] = [45.680479, -100.05824];
Zipcodes['57648'] = [45.929783, -100.319922];
Zipcodes['57649'] = [45.488704, -102.848892];
Zipcodes['57650'] = [45.830929, -103.034682];
Zipcodes['57651'] = [45.476863, -103.03443];
Zipcodes['57652'] = [45.136216, -100.683015];
Zipcodes['57656'] = [45.423044, -101.106687];
Zipcodes['57657'] = [45.518884, -100.792161];
Zipcodes['57658'] = [45.669439, -100.562316];
Zipcodes['57659'] = [45.890896, -101.094218];
Zipcodes['57660'] = [45.83209, -101.530815];
Zipcodes['57661'] = [45.26651, -100.977565];
Zipcodes['57701'] = [44.121777, -103.201222];
Zipcodes['57702'] = [44.032012, -103.415359];
Zipcodes['57703'] = [44.004564, -103.042628];
Zipcodes['57706'] = [44.158789, -103.101868];
Zipcodes['57714'] = [43.329195, -101.914725];
Zipcodes['57716'] = [43.091167, -102.196028];
Zipcodes['57717'] = [44.933879, -103.895745];
Zipcodes['57718'] = [44.170504, -103.369156];
Zipcodes['57719'] = [44.231201, -102.994377];
Zipcodes['57720'] = [45.537168, -103.586067];
Zipcodes['57722'] = [43.443214, -102.987369];
Zipcodes['57724'] = [45.573791, -104.001141];
Zipcodes['57725'] = [43.932032, -102.7919];
Zipcodes['57730'] = [43.726576, -103.648599];
Zipcodes['57732'] = [44.329012, -103.689034];
Zipcodes['57735'] = [43.287937, -103.85355];
Zipcodes['57737'] = [44.555435, -102.517339];
Zipcodes['57738'] = [43.684853, -103.158718];
Zipcodes['57741'] = [44.410498, -103.472882];
Zipcodes['57744'] = [43.793475, -103.033857];
Zipcodes['57745'] = [44.013264, -103.785659];
Zipcodes['57747'] = [43.37376, -103.519145];
Zipcodes['57748'] = [44.585172, -102.010628];
Zipcodes['57750'] = [43.5929, -101.945128];
Zipcodes['57751'] = [43.869135, -103.426932];
Zipcodes['57752'] = [43.429333, -102.201331];
Zipcodes['57754'] = [44.255434, -103.887569];
Zipcodes['57755'] = [45.868326, -103.347178];
Zipcodes['57756'] = [43.237288, -102.475437];
Zipcodes['57758'] = [44.994621, -102.737081];
Zipcodes['57759'] = [44.227818, -103.556798];
Zipcodes['57760'] = [44.894658, -103.334676];
Zipcodes['57761'] = [44.227284, -102.785434];
Zipcodes['57762'] = [44.753351, -103.619692];
Zipcodes['57763'] = [43.123511, -103.251157];
Zipcodes['57764'] = [43.257861, -102.771135];
Zipcodes['57766'] = [43.356096, -103.177535];
Zipcodes['57767'] = [44.136748, -102.571112];
Zipcodes['57769'] = [44.245301, -103.288634];
Zipcodes['57770'] = [43.084365, -102.654251];
Zipcodes['57772'] = [43.477183, -102.527554];
Zipcodes['57773'] = [43.594435, -103.592474];
Zipcodes['57775'] = [44.093016, -102.001651];
Zipcodes['57776'] = [45.236414, -103.507964];
Zipcodes['57779'] = [44.568767, -103.760725];
Zipcodes['57780'] = [43.767506, -102.479503];
Zipcodes['57782'] = [43.249431, -103.180503];
Zipcodes['57783'] = [44.456339, -103.925266];
Zipcodes['57785'] = [44.402472, -103.188819];
Zipcodes['57787'] = [44.667994, -102.657839];
Zipcodes['57788'] = [44.616964, -103.204941];
Zipcodes['57790'] = [44.104078, -102.200877];
Zipcodes['57791'] = [44.247026, -102.457512];
Zipcodes['57792'] = [44.59754, -102.367975];
Zipcodes['57793'] = [44.525287, -103.578811];
Zipcodes['57794'] = [43.120679, -102.407006];
Zipcodes['57799'] = [44.498152, -103.872505];
Zipcodes['58001'] = [46.448366, -96.740812];
Zipcodes['58002'] = [46.97109, -97.397242];
Zipcodes['58004'] = [47.026475, -97.270723];
Zipcodes['58005'] = [47.052614, -96.950996];
Zipcodes['58006'] = [47.096824, -97.203601];
Zipcodes['58007'] = [47.025192, -97.457242];
Zipcodes['58008'] = [46.308142, -97.002379];
Zipcodes['58009'] = [47.32658, -97.244403];
Zipcodes['58011'] = [46.927793, -97.544021];
Zipcodes['58012'] = [46.941686, -97.168331];
Zipcodes['58013'] = [46.057851, -97.353266];
Zipcodes['58015'] = [46.592204, -96.815431];
Zipcodes['58016'] = [47.366113, -97.462952];
Zipcodes['58017'] = [46.043361, -97.851471];
Zipcodes['58018'] = [46.434565, -96.888742];
Zipcodes['58021'] = [46.736154, -97.08213];
Zipcodes['58027'] = [46.633177, -97.630735];
Zipcodes['58029'] = [47.119024, -97.394066];
Zipcodes['58030'] = [46.030334, -96.668186];
Zipcodes['58031'] = [46.767684, -97.671491];
Zipcodes['58032'] = [46.07644, -97.654761];
Zipcodes['58033'] = [46.4347, -97.91956];
Zipcodes['58035'] = [47.253416, -97.430516];
Zipcodes['58036'] = [47.15463, -96.963263];
Zipcodes['58038'] = [47.245071, -96.994784];
Zipcodes['58040'] = [46.189495, -97.685];
Zipcodes['58041'] = [46.036544, -96.906425];
Zipcodes['58042'] = [46.970891, -96.956035];
Zipcodes['58043'] = [45.970214, -97.537917];
Zipcodes['58045'] = [47.388882, -97.016318];
Zipcodes['58046'] = [47.322086, -97.727721];
Zipcodes['58047'] = [46.713528, -96.872858];
Zipcodes['58048'] = [47.198821, -97.223655];
Zipcodes['58049'] = [46.670689, -97.991465];
Zipcodes['58051'] = [46.636833, -97.021501];
Zipcodes['58052'] = [46.63462, -97.279569];
Zipcodes['58053'] = [46.052505, -97.18054];
Zipcodes['58054'] = [46.416881, -97.668722];
Zipcodes['58056'] = [47.236875, -97.929491];
Zipcodes['58057'] = [46.425466, -97.267553];
Zipcodes['58058'] = [46.180584, -96.971607];
Zipcodes['58059'] = [46.832371, -97.143569];
Zipcodes['58060'] = [46.267715, -97.426686];
Zipcodes['58061'] = [46.274595, -96.894203];
Zipcodes['58062'] = [46.637537, -97.839516];
Zipcodes['58063'] = [46.959034, -97.797584];
Zipcodes['58064'] = [47.139612, -97.632489];
Zipcodes['58065'] = [47.200352, -97.766154];
Zipcodes['58067'] = [46.072878, -97.49869];
Zipcodes['58068'] = [46.550685, -97.416545];
Zipcodes['58069'] = [46.243089, -97.839604];
Zipcodes['58071'] = [46.941278, -97.682736];
Zipcodes['58072'] = [46.927034, -98.008336];
Zipcodes['58074'] = [46.218057, -96.87601];
Zipcodes['58075'] = [46.275536, -96.730394];
Zipcodes['58076'] = [46.274056, -96.607449];
Zipcodes['58077'] = [46.524583, -96.976013];
Zipcodes['58078'] = [46.867244, -96.942162];
Zipcodes['58079'] = [46.86844, -97.366681];
Zipcodes['58081'] = [46.313857, -97.143829];
Zipcodes['58102'] = [46.92721, -96.834819];
Zipcodes['58103'] = [46.856347, -96.82246];
Zipcodes['58104'] = [46.793179, -96.838434];
Zipcodes['58105'] = [46.895884, -96.803639];
Zipcodes['58201'] = [47.866638, -97.141144];
Zipcodes['58202'] = [47.92146, -97.075139];
Zipcodes['58203'] = [47.970477, -97.16549];
Zipcodes['58204'] = [47.960469, -97.370795];
Zipcodes['58205'] = [47.95475, -97.395254];
Zipcodes['58210'] = [48.405691, -98.111589];
Zipcodes['58212'] = [47.695166, -97.997378];
Zipcodes['58214'] = [47.926891, -97.4975];
Zipcodes['58216'] = [48.879383, -97.442869];
Zipcodes['58218'] = [47.592361, -97.059216];
Zipcodes['58219'] = [47.476068, -96.878197];
Zipcodes['58220'] = [48.797612, -97.742027];
Zipcodes['58222'] = [48.618537, -97.682198];
Zipcodes['58223'] = [47.519634, -97.016444];
Zipcodes['58224'] = [48.167781, -97.943082];
Zipcodes['58225'] = [48.611238, -97.222779];
Zipcodes['58227'] = [48.549096, -97.892709];
Zipcodes['58228'] = [47.868067, -97.345202];
Zipcodes['58229'] = [48.514016, -98.242608];
Zipcodes['58230'] = [47.492526, -97.821541];
Zipcodes['58231'] = [48.202238, -97.829398];
Zipcodes['58233'] = [48.201661, -97.546065];
Zipcodes['58235'] = [48.090058, -97.483082];
Zipcodes['58236'] = [48.718443, -97.504782];
Zipcodes['58237'] = [48.415203, -97.400931];
Zipcodes['58238'] = [48.779537, -97.397941];
Zipcodes['58239'] = [48.919596, -98.684906];
Zipcodes['58240'] = [47.635966, -97.48458];
Zipcodes['58241'] = [48.709462, -97.699433];
Zipcodes['58243'] = [48.514235, -97.679526];
Zipcodes['58244'] = [48.11034, -97.657525];
Zipcodes['58249'] = [48.822796, -98.326795];
Zipcodes['58250'] = [48.274343, -98.022295];
Zipcodes['58251'] = [47.954354, -97.699884];
Zipcodes['58254'] = [47.774007, -98.15];
Zipcodes['58255'] = [48.996767, -98.353905];
Zipcodes['58256'] = [48.084038, -97.200214];
Zipcodes['58257'] = [47.490249, -97.285041];
Zipcodes['58258'] = [48.011374, -97.3383];
Zipcodes['58259'] = [48.020465, -98.120838];
Zipcodes['58260'] = [48.637094, -98.048609];
Zipcodes['58261'] = [48.242266, -97.319417];
Zipcodes['58262'] = [48.681, -97.877567];
Zipcodes['58265'] = [48.956126, -97.590287];
Zipcodes['58266'] = [48.000939, -97.849975];
Zipcodes['58267'] = [47.755019, -97.629119];
Zipcodes['58269'] = [48.698177, -98.157538];
Zipcodes['58270'] = [48.394753, -97.791155];
Zipcodes['58271'] = [48.912895, -97.289694];
Zipcodes['58272'] = [48.007621, -97.984915];
Zipcodes['58273'] = [48.297483, -97.662764];
Zipcodes['58274'] = [47.491898, -97.488544];
Zipcodes['58275'] = [47.690471, -97.105092];
Zipcodes['58276'] = [48.624014, -97.456036];
Zipcodes['58277'] = [47.608381, -97.82331];
Zipcodes['58278'] = [47.781229, -97.121467];
Zipcodes['58281'] = [48.855839, -98.616489];
Zipcodes['58282'] = [48.898141, -97.94185];
Zipcodes['58301'] = [48.141836, -98.893438];
Zipcodes['58310'] = [48.566713, -99.553099];
Zipcodes['58311'] = [48.645227, -98.557163];
Zipcodes['58313'] = [48.166169, -100.036646];
Zipcodes['58316'] = [48.844632, -99.80279];
Zipcodes['58317'] = [48.626244, -99.403518];
Zipcodes['58318'] = [48.858083, -100.39742];
Zipcodes['58321'] = [48.202445, -98.314422];
Zipcodes['58323'] = [48.841487, -98.923128];
Zipcodes['58324'] = [48.479405, -99.220932];
Zipcodes['58325'] = [48.28948, -99.118266];
Zipcodes['58327'] = [48.104377, -98.568244];
Zipcodes['58329'] = [48.862438, -100.0721];
Zipcodes['58330'] = [48.43375, -98.478781];
Zipcodes['58331'] = [48.638515, -99.090041];
Zipcodes['58332'] = [48.044165, -99.772982];
Zipcodes['58335'] = [47.970499, -99.016593];
Zipcodes['58338'] = [48.526201, -98.627124];
Zipcodes['58339'] = [48.972517, -99.324612];
Zipcodes['58341'] = [47.767635, -99.876653];
Zipcodes['58343'] = [48.295858, -99.728761];
Zipcodes['58344'] = [48.016523, -98.343004];
Zipcodes['58345'] = [48.306617, -98.375144];
Zipcodes['58346'] = [48.289988, -99.427829];
Zipcodes['58348'] = [47.95344, -99.521094];
Zipcodes['58351'] = [48.09797, -99.314636];
Zipcodes['58352'] = [48.695589, -98.857768];
Zipcodes['58353'] = [48.64021, -99.59009];
Zipcodes['58355'] = [48.578444, -98.39595];
Zipcodes['58356'] = [47.671392, -99.072079];
Zipcodes['58357'] = [47.939783, -99.216787];
Zipcodes['58361'] = [47.752047, -98.375596];
Zipcodes['58362'] = [48.230989, -99.059859];
Zipcodes['58363'] = [48.769264, -99.465532];
Zipcodes['58365'] = [48.841341, -99.231706];
Zipcodes['58366'] = [48.653521, -99.874028];
Zipcodes['58367'] = [48.881881, -99.556133];
Zipcodes['58368'] = [48.239469, -100.031162];
Zipcodes['58369'] = [48.95891, -99.813735];
Zipcodes['58370'] = [47.985147, -98.86648];
Zipcodes['58372'] = [48.942452, -99.012007];
Zipcodes['58374'] = [47.824576, -99.095041];
Zipcodes['58377'] = [48.471646, -98.872965];
Zipcodes['58379'] = [47.896628, -98.821626];
Zipcodes['58380'] = [47.825153, -98.501854];
Zipcodes['58381'] = [47.825765, -98.748768];
Zipcodes['58382'] = [48.318001, -98.799048];
Zipcodes['58384'] = [48.595867, -100.26556];
Zipcodes['58385'] = [48.472729, -99.645585];
Zipcodes['58386'] = [48.318725, -99.598751];
Zipcodes['58401'] = [46.884312, -98.766742];
Zipcodes['58402'] = [46.881949, -98.687913];
Zipcodes['58405'] = [46.914592, -98.696736];
Zipcodes['58413'] = [45.985451, -99.332346];
Zipcodes['58415'] = [46.361573, -98.485453];
Zipcodes['58416'] = [47.551001, -98.361428];
Zipcodes['58418'] = [47.421423, -99.638932];
Zipcodes['58420'] = [47.061018, -98.85844];
Zipcodes['58421'] = [47.440419, -99.076593];
Zipcodes['58422'] = [47.593516, -99.405537];
Zipcodes['58423'] = [47.428453, -99.830534];
Zipcodes['58424'] = [46.878988, -99.094314];
Zipcodes['58425'] = [47.453704, -98.118284];
Zipcodes['58426'] = [47.218226, -98.579339];
Zipcodes['58428'] = [46.807934, -99.749171];
Zipcodes['58429'] = [47.204691, -98.17407];
Zipcodes['58430'] = [47.508825, -100.256876];
Zipcodes['58431'] = [46.508341, -98.503912];
Zipcodes['58433'] = [46.366412, -98.721914];
Zipcodes['58436'] = [46.076099, -98.579913];
Zipcodes['58438'] = [47.667399, -99.568378];
Zipcodes['58439'] = [45.968075, -98.830849];
Zipcodes['58440'] = [46.278297, -99.162138];
Zipcodes['58441'] = [46.175288, -98.368493];
Zipcodes['58442'] = [46.553679, -99.179594];
Zipcodes['58443'] = [47.464343, -98.681191];
Zipcodes['58444'] = [47.484024, -100.140744];
Zipcodes['58445'] = [47.557233, -98.78318];
Zipcodes['58448'] = [47.305788, -98.204932];
Zipcodes['58451'] = [47.450264, -99.971738];
Zipcodes['58452'] = [47.54019, -98.229147];
Zipcodes['58454'] = [46.584322, -98.951788];
Zipcodes['58455'] = [47.302513, -98.728706];
Zipcodes['58456'] = [46.251934, -98.953218];
Zipcodes['58458'] = [46.367944, -98.293849];
Zipcodes['58460'] = [46.309722, -99.32202];
Zipcodes['58461'] = [46.649509, -98.191611];
Zipcodes['58463'] = [47.491985, -100.473402];
Zipcodes['58464'] = [47.61293, -98.608539];
Zipcodes['58466'] = [46.609213, -98.369436];
Zipcodes['58467'] = [46.874843, -99.330452];
Zipcodes['58472'] = [46.638569, -98.650249];
Zipcodes['58474'] = [46.102707, -98.142267];
Zipcodes['58475'] = [47.146989, -99.534225];
Zipcodes['58476'] = [47.19385, -99.004507];
Zipcodes['58477'] = [47.220955, -100.520799];
Zipcodes['58478'] = [47.161778, -99.745442];
Zipcodes['58479'] = [47.088795, -98.20338];
Zipcodes['58480'] = [46.945029, -98.259337];
Zipcodes['58481'] = [46.934507, -98.423607];
Zipcodes['58482'] = [46.868757, -99.900895];
Zipcodes['58483'] = [46.668133, -99.432794];
Zipcodes['58484'] = [47.368583, -98.464276];
Zipcodes['58486'] = [47.396329, -99.3902];
Zipcodes['58487'] = [46.856829, -99.605753];
Zipcodes['58488'] = [47.17896, -100.025613];
Zipcodes['58490'] = [46.384285, -98.065943];
Zipcodes['58492'] = [47.161982, -98.424957];
Zipcodes['58494'] = [47.184899, -100.262087];
Zipcodes['58495'] = [46.271778, -99.601054];
Zipcodes['58496'] = [47.140886, -99.309026];
Zipcodes['58497'] = [46.768141, -98.508625];
Zipcodes['58501'] = [46.814932, -100.698195];
Zipcodes['58503'] = [46.906399, -100.752189];
Zipcodes['58504'] = [46.743148, -100.678745];
Zipcodes['58505'] = [46.820285, -100.781059];
Zipcodes['58520'] = [46.674232, -101.567958];
Zipcodes['58521'] = [47.027958, -100.673956];
Zipcodes['58523'] = [47.265005, -101.781567];
Zipcodes['58524'] = [46.622101, -100.073951];
Zipcodes['58528'] = [46.323996, -100.595218];
Zipcodes['58529'] = [46.304871, -101.580815];
Zipcodes['58530'] = [47.131904, -101.164206];
Zipcodes['58531'] = [47.578741, -101.235189];
Zipcodes['58532'] = [46.847398, -100.109279];
Zipcodes['58533'] = [46.394577, -101.796661];
Zipcodes['58535'] = [46.482107, -101.24899];
Zipcodes['58538'] = [46.081101, -100.659187];
Zipcodes['58540'] = [47.621778, -101.726395];
Zipcodes['58541'] = [47.339697, -102.044491];
Zipcodes['58542'] = [46.044646, -99.993945];
Zipcodes['58544'] = [46.512746, -100.372475];
Zipcodes['58545'] = [47.349585, -101.600309];
Zipcodes['58549'] = [46.463443, -99.937496];
Zipcodes['58552'] = [46.244129, -100.287175];
Zipcodes['58554'] = [46.755661, -100.948661];
Zipcodes['58558'] = [46.819776, -100.526091];
Zipcodes['58559'] = [47.471719, -100.694959];
Zipcodes['58560'] = [46.654902, -100.312936];
Zipcodes['58561'] = [46.492146, -99.663496];
Zipcodes['58562'] = [46.361322, -102.008606];
Zipcodes['58563'] = [46.898984, -101.439257];
Zipcodes['58564'] = [46.266095, -101.344234];
Zipcodes['58565'] = [47.496387, -101.371365];
Zipcodes['58566'] = [46.578368, -100.906689];
Zipcodes['58568'] = [46.097736, -100.998835];
Zipcodes['58569'] = [46.268099, -101.19937];
Zipcodes['58570'] = [46.375643, -100.872005];
Zipcodes['58571'] = [47.29236, -101.406757];
Zipcodes['58572'] = [46.868336, -100.339849];
Zipcodes['58573'] = [46.123803, -100.201889];
Zipcodes['58575'] = [47.551926, -100.89414];
Zipcodes['58576'] = [47.436851, -101.214174];
Zipcodes['58577'] = [47.317785, -101.052327];
Zipcodes['58579'] = [47.179421, -100.738366];
Zipcodes['58580'] = [47.267551, -101.943298];
Zipcodes['58581'] = [46.049857, -99.805692];
Zipcodes['58601'] = [46.908157, -102.826564];
Zipcodes['58620'] = [46.505983, -103.338434];
Zipcodes['58621'] = [47.121085, -103.944725];
Zipcodes['58622'] = [46.880016, -103.251986];
Zipcodes['58623'] = [46.160795, -103.423595];
Zipcodes['58625'] = [47.279664, -102.186143];
Zipcodes['58626'] = [47.377693, -102.602385];
Zipcodes['58627'] = [47.234399, -103.27265];
Zipcodes['58630'] = [46.926684, -102.57024];
Zipcodes['58631'] = [46.798625, -101.837035];
Zipcodes['58632'] = [46.672998, -103.965468];
Zipcodes['58634'] = [47.444717, -103.364216];
Zipcodes['58636'] = [47.393879, -102.345737];
Zipcodes['58638'] = [46.918358, -102.037572];
Zipcodes['58639'] = [46.066735, -102.650509];
Zipcodes['58640'] = [47.453697, -102.905423];
Zipcodes['58641'] = [46.66713, -102.486272];
Zipcodes['58642'] = [47.201319, -102.834401];
Zipcodes['58643'] = [46.216746, -103.959692];
Zipcodes['58644'] = [47.13237, -102.348714];
Zipcodes['58645'] = [46.951005, -103.549653];
Zipcodes['58646'] = [46.392913, -102.287643];
Zipcodes['58647'] = [46.518902, -102.936294];
Zipcodes['58649'] = [46.140068, -102.901957];
Zipcodes['58650'] = [46.437003, -102.620071];
Zipcodes['58651'] = [46.259288, -103.74136];
Zipcodes['58652'] = [46.905856, -102.244208];
Zipcodes['58653'] = [46.090443, -103.130019];
Zipcodes['58654'] = [46.784161, -103.823613];
Zipcodes['58655'] = [46.770815, -103.030896];
Zipcodes['58656'] = [46.955764, -102.464501];
Zipcodes['58701'] = [48.144826, -101.326016];
Zipcodes['58703'] = [48.321334, -101.304721];
Zipcodes['58704'] = [48.414329, -101.31841];
Zipcodes['58705'] = [48.416121, -101.351834];
Zipcodes['58707'] = [48.247706, -101.301268];
Zipcodes['58710'] = [47.868061, -100.231091];
Zipcodes['58711'] = [48.929604, -101.26715];
Zipcodes['58712'] = [47.970808, -100.553132];
Zipcodes['58713'] = [48.523646, -100.614056];
Zipcodes['58716'] = [47.804326, -101.067845];
Zipcodes['58718'] = [48.290906, -101.80355];
Zipcodes['58721'] = [48.821687, -102.254263];
Zipcodes['58722'] = [48.250002, -101.501006];
Zipcodes['58723'] = [47.745881, -100.596154];
Zipcodes['58725'] = [48.481063, -101.679896];
Zipcodes['58727'] = [48.857569, -102.804499];
Zipcodes['58730'] = [48.860175, -103.308172];
Zipcodes['58731'] = [48.448859, -100.983225];
Zipcodes['58733'] = [48.175009, -101.587881];
Zipcodes['58734'] = [48.51285, -101.957356];
Zipcodes['58735'] = [47.884246, -101.475674];
Zipcodes['58736'] = [47.930855, -100.396493];
Zipcodes['58737'] = [48.908015, -102.401421];
Zipcodes['58740'] = [48.466399, -101.222222];
Zipcodes['58741'] = [48.27684, -100.830768];
Zipcodes['58744'] = [48.120173, -100.591065];
Zipcodes['58746'] = [48.713443, -102.094053];
Zipcodes['58748'] = [48.66158, -100.635644];
Zipcodes['58750'] = [48.610954, -101.397529];
Zipcodes['58752'] = [48.821296, -102.598817];
Zipcodes['58755'] = [48.625964, -102.95095];
Zipcodes['58756'] = [47.970731, -101.814768];
Zipcodes['58757'] = [47.686282, -102.555817];
Zipcodes['58758'] = [47.768017, -100.090345];
Zipcodes['58759'] = [47.844676, -101.249698];
Zipcodes['58760'] = [48.697459, -101.135896];
Zipcodes['58761'] = [48.785989, -101.524267];
Zipcodes['58762'] = [48.673209, -100.940087];
Zipcodes['58763'] = [47.983958, -102.583404];
Zipcodes['58765'] = [48.873295, -103.042817];
Zipcodes['58768'] = [48.252046, -101.0238];
Zipcodes['58769'] = [48.305501, -102.235314];
Zipcodes['58770'] = [47.869389, -102.161202];
Zipcodes['58771'] = [48.088309, -101.976214];
Zipcodes['58772'] = [48.959575, -102.63371];
Zipcodes['58773'] = [48.58258, -102.657837];
Zipcodes['58775'] = [47.710059, -101.861201];
Zipcodes['58776'] = [48.247805, -102.623232];
Zipcodes['58778'] = [47.78649, -100.913005];
Zipcodes['58779'] = [47.85496, -101.753854];
Zipcodes['58781'] = [48.030114, -101.133973];
Zipcodes['58782'] = [48.944346, -101.70467];
Zipcodes['58783'] = [48.87979, -100.760464];
Zipcodes['58784'] = [48.359814, -102.423996];
Zipcodes['58785'] = [48.307069, -101.102285];
Zipcodes['58787'] = [48.756322, -101.813659];
Zipcodes['58788'] = [48.325232, -100.477506];
Zipcodes['58789'] = [48.553182, -100.808895];
Zipcodes['58790'] = [48.029571, -100.947714];
Zipcodes['58792'] = [47.971228, -100.746785];
Zipcodes['58793'] = [48.869225, -101.051028];
Zipcodes['58794'] = [48.269343, -102.791266];
Zipcodes['58795'] = [48.640339, -103.156669];
Zipcodes['58801'] = [48.238486, -103.769649];
Zipcodes['58830'] = [48.59734, -103.461192];
Zipcodes['58831'] = [47.854223, -103.652015];
Zipcodes['58833'] = [48.887153, -103.520333];
Zipcodes['58835'] = [47.736663, -103.449132];
Zipcodes['58838'] = [47.769991, -103.900837];
Zipcodes['58843'] = [48.23386, -103.37749];
Zipcodes['58844'] = [48.887526, -103.749952];
Zipcodes['58845'] = [48.641039, -103.917535];
Zipcodes['58847'] = [47.888875, -102.906185];
Zipcodes['58849'] = [48.307903, -103.163231];
Zipcodes['58852'] = [48.381568, -102.931933];
Zipcodes['58853'] = [48.061128, -103.72747];
Zipcodes['58854'] = [47.828372, -103.153255];
Zipcodes['58856'] = [48.595028, -103.732864];
Zipcodes['59001'] = [45.530159, -109.539994];
Zipcodes['59002'] = [46.02272, -108.66561];
Zipcodes['59003'] = [45.491982, -106.200861];
Zipcodes['59006'] = [45.92083, -108.08888];
Zipcodes['59007'] = [45.170774, -109.194932];
Zipcodes['59008'] = [45.079746, -109.07594];
Zipcodes['59010'] = [45.965205, -107.299311];
Zipcodes['59011'] = [45.924721, -109.948625];
Zipcodes['59012'] = [45.323605, -106.627201];
Zipcodes['59013'] = [45.461319, -109.080551];
Zipcodes['59014'] = [45.155169, -108.667654];
Zipcodes['59015'] = [46.085105, -108.828741];
Zipcodes['59016'] = [45.457702, -106.947988];
Zipcodes['59018'] = [45.850287, -110.623921];
Zipcodes['59019'] = [45.628692, -109.240732];
Zipcodes['59020'] = [45.108043, -110.10062];
Zipcodes['59022'] = [45.570481, -107.261652];
Zipcodes['59024'] = [46.278426, -107.807114];
Zipcodes['59025'] = [45.085978, -106.704232];
Zipcodes['59026'] = [45.48538, -108.784438];
Zipcodes['59027'] = [45.259227, -110.904444];
Zipcodes['59028'] = [45.30247, -109.713736];
Zipcodes['59029'] = [45.397384, -108.802501];
Zipcodes['59030'] = [45.086943, -110.596164];
Zipcodes['59031'] = [45.498166, -107.374537];
Zipcodes['59032'] = [47.024576, -108.70277];
Zipcodes['59033'] = [45.724181, -109.73519];
Zipcodes['59034'] = [45.700376, -107.783469];
Zipcodes['59035'] = [45.304795, -107.94896];
Zipcodes['59036'] = [46.459994, -109.88342];
Zipcodes['59037'] = [45.864795, -108.210799];
Zipcodes['59038'] = [46.221886, -107.208456];
Zipcodes['59039'] = [46.686774, -107.291122];
Zipcodes['59041'] = [45.501093, -108.968152];
Zipcodes['59043'] = [45.550795, -106.676407];
Zipcodes['59044'] = [45.643813, -108.732471];
Zipcodes['59046'] = [46.361298, -108.950437];
Zipcodes['59047'] = [45.577413, -110.55021];
Zipcodes['59050'] = [45.259182, -107.476204];
Zipcodes['59052'] = [45.465798, -110.145521];
Zipcodes['59053'] = [46.44153, -110.432354];
Zipcodes['59054'] = [46.668547, -107.772165];
Zipcodes['59055'] = [46.108998, -109.809893];
Zipcodes['59057'] = [45.853605, -108.953837];
Zipcodes['59058'] = [46.987662, -107.760242];
Zipcodes['59059'] = [46.511035, -108.073924];
Zipcodes['59061'] = [45.424609, -109.857145];
Zipcodes['59062'] = [45.151007, -106.218145];
Zipcodes['59063'] = [45.652232, -108.965717];
Zipcodes['59064'] = [45.879402, -107.871378];
Zipcodes['59065'] = [45.271112, -110.673496];
Zipcodes['59066'] = [45.335877, -108.510261];
Zipcodes['59067'] = [45.970297, -109.223835];
Zipcodes['59068'] = [45.087055, -109.558392];
Zipcodes['59069'] = [45.726695, -109.582747];
Zipcodes['59070'] = [45.364899, -109.181751];
Zipcodes['59071'] = [45.2462, -109.629564];
Zipcodes['59072'] = [46.525989, -108.553737];
Zipcodes['59074'] = [46.364496, -109.298528];
Zipcodes['59075'] = [45.406314, -107.848049];
Zipcodes['59076'] = [46.282518, -107.068474];
Zipcodes['59077'] = [47.244813, -107.592797];
Zipcodes['59078'] = [46.42268, -109.489288];
Zipcodes['59079'] = [46.062118, -108.363307];
Zipcodes['59081'] = [45.019784, -109.982939];
Zipcodes['59082'] = [45.849173, -110.290444];
Zipcodes['59083'] = [46.563108, -107.559493];
Zipcodes['59085'] = [46.323085, -110.187893];
Zipcodes['59086'] = [46.061326, -110.662681];
Zipcodes['59087'] = [47.141917, -108.226575];
Zipcodes['59088'] = [46.130285, -108.055419];
Zipcodes['59089'] = [45.091886, -107.315133];
Zipcodes['59101'] = [45.607108, -108.341517];
Zipcodes['59102'] = [45.774768, -108.581321];
Zipcodes['59105'] = [45.892879, -108.497876];
Zipcodes['59106'] = [45.813757, -108.7098];
Zipcodes['59201'] = [48.141944, -105.626831];
Zipcodes['59211'] = [48.698644, -104.349863];
Zipcodes['59212'] = [48.222386, -104.143067];
Zipcodes['59213'] = [48.188882, -104.886575];
Zipcodes['59214'] = [47.299772, -106.038986];
Zipcodes['59215'] = [47.516636, -105.769079];
Zipcodes['59217'] = [47.579432, -104.238602];
Zipcodes['59218'] = [48.075949, -104.491631];
Zipcodes['59219'] = [48.571433, -104.157731];
Zipcodes['59221'] = [47.899749, -104.164477];
Zipcodes['59222'] = [48.700902, -105.158539];
Zipcodes['59223'] = [47.912807, -106.206866];
Zipcodes['59225'] = [48.26964, -105.967526];
Zipcodes['59226'] = [48.347861, -104.530464];
Zipcodes['59230'] = [48.131171, -106.798317];
Zipcodes['59231'] = [48.403916, -106.544215];
Zipcodes['59240'] = [48.842241, -106.240509];
Zipcodes['59241'] = [48.458162, -107.050557];
Zipcodes['59242'] = [48.411173, -104.443382];
Zipcodes['59243'] = [47.757547, -104.679209];
Zipcodes['59244'] = [48.619958, -106.304401];
Zipcodes['59247'] = [48.516207, -104.523515];
Zipcodes['59248'] = [48.245397, -106.249241];
Zipcodes['59250'] = [48.844643, -106.623677];
Zipcodes['59252'] = [48.94671, -104.837622];
Zipcodes['59253'] = [48.736375, -105.858224];
Zipcodes['59254'] = [48.751679, -104.640564];
Zipcodes['59255'] = [48.236464, -105.158947];
Zipcodes['59256'] = [48.907561, -104.5528];
Zipcodes['59257'] = [48.753635, -104.942304];
Zipcodes['59258'] = [48.583548, -104.655046];
Zipcodes['59259'] = [47.680959, -105.099852];
Zipcodes['59260'] = [48.856867, -106.119737];
Zipcodes['59261'] = [48.529143, -107.422067];
Zipcodes['59262'] = [47.489669, -104.497165];
Zipcodes['59263'] = [48.791171, -105.514255];
Zipcodes['59270'] = [47.609447, -104.086114];
Zipcodes['59273'] = [48.306968, -106.942674];
Zipcodes['59274'] = [47.846151, -105.418762];
Zipcodes['59275'] = [48.877437, -104.156433];
Zipcodes['59276'] = [48.965743, -105.214128];
Zipcodes['59301'] = [46.360165, -105.867454];
Zipcodes['59311'] = [45.162865, -104.549317];
Zipcodes['59312'] = [47.037569, -106.263943];
Zipcodes['59313'] = [46.257762, -104.225586];
Zipcodes['59314'] = [45.067734, -105.433561];
Zipcodes['59315'] = [47.4523, -104.868428];
Zipcodes['59316'] = [45.347046, -105.071917];
Zipcodes['59317'] = [45.337829, -105.519039];
Zipcodes['59318'] = [47.501154, -107.51385];
Zipcodes['59319'] = [45.411852, -104.165377];
Zipcodes['59322'] = [47.050884, -106.688672];
Zipcodes['59323'] = [45.916835, -106.722011];
Zipcodes['59324'] = [45.768923, -104.56924];
Zipcodes['59326'] = [46.770357, -104.859403];
Zipcodes['59327'] = [46.236772, -106.682233];
Zipcodes['59330'] = [47.12112, -104.763541];
Zipcodes['59332'] = [45.389696, -104.698552];
Zipcodes['59333'] = [46.277146, -106.194636];
Zipcodes['59336'] = [46.348832, -104.954101];
Zipcodes['59337'] = [47.40652, -106.816654];
Zipcodes['59338'] = [46.640459, -105.640492];
Zipcodes['59339'] = [47.225333, -105.271248];
Zipcodes['59343'] = [45.662478, -105.426431];
Zipcodes['59344'] = [46.356859, -104.597278];
Zipcodes['59345'] = [45.860143, -105.212007];
Zipcodes['59347'] = [46.420675, -106.365853];
Zipcodes['59349'] = [46.812384, -105.503994];
Zipcodes['59351'] = [45.759265, -105.711281];
Zipcodes['59353'] = [46.962966, -104.274518];
Zipcodes['59354'] = [46.177031, -104.420639];
Zipcodes['59401'] = [47.511227, -111.27724];
Zipcodes['59404'] = [47.631766, -111.32887];
Zipcodes['59405'] = [47.288991, -111.300456];
Zipcodes['59410'] = [47.503548, -112.6469];
Zipcodes['59411'] = [48.892066, -113.398244];
Zipcodes['59412'] = [47.303324, -110.86368];
Zipcodes['59414'] = [47.527748, -111.259956];
Zipcodes['59416'] = [48.037972, -111.526826];
Zipcodes['59417'] = [48.552149, -113.001927];
Zipcodes['59418'] = [46.803651, -109.855632];
Zipcodes['59419'] = [47.966391, -112.316989];
Zipcodes['59420'] = [47.877548, -111.003871];
Zipcodes['59421'] = [47.074847, -111.688368];
Zipcodes['59422'] = [47.834255, -112.488851];
Zipcodes['59424'] = [47.353027, -110.108208];
Zipcodes['59425'] = [48.187163, -111.898576];
Zipcodes['59427'] = [48.740743, -112.666436];
Zipcodes['59430'] = [47.430977, -109.78019];
Zipcodes['59432'] = [48.156207, -112.546145];
Zipcodes['59433'] = [47.883621, -111.69203];
Zipcodes['59434'] = [48.698205, -113.507207];
Zipcodes['59435'] = [48.576987, -112.039245];
Zipcodes['59436'] = [47.616272, -112.11985];
Zipcodes['59440'] = [47.715998, -111.158569];
Zipcodes['59441'] = [46.827529, -109.069119];
Zipcodes['59442'] = [47.928944, -110.651689];
Zipcodes['59443'] = [47.563934, -111.828933];
Zipcodes['59444'] = [48.650917, -111.380414];
Zipcodes['59446'] = [47.65059, -110.152437];
Zipcodes['59447'] = [47.286249, -110.450024];
Zipcodes['59448'] = [48.263152, -112.849472];
Zipcodes['59450'] = [47.552457, -110.742814];
Zipcodes['59451'] = [47.390215, -109.390087];
Zipcodes['59452'] = [46.892567, -110.061256];
Zipcodes['59453'] = [46.648823, -109.681412];
Zipcodes['59454'] = [48.76895, -111.985097];
Zipcodes['59456'] = [48.27015, -111.36167];
Zipcodes['59457'] = [47.139731, -109.310887];
Zipcodes['59460'] = [48.074045, -110.506987];
Zipcodes['59461'] = [48.471199, -111.211268];
Zipcodes['59462'] = [47.105394, -109.893333];
Zipcodes['59463'] = [47.048919, -110.86667];
Zipcodes['59464'] = [46.83955, -109.616825];
Zipcodes['59465'] = [46.93772, -110.707881];
Zipcodes['59466'] = [48.718414, -111.774954];
Zipcodes['59467'] = [48.069718, -112.402314];
Zipcodes['59468'] = [47.727591, -111.634236];
Zipcodes['59469'] = [47.190808, -110.683742];
Zipcodes['59471'] = [47.426655, -108.772077];
Zipcodes['59472'] = [47.338846, -111.207122];
Zipcodes['59474'] = [48.529477, -111.690738];
Zipcodes['59477'] = [47.44284, -111.974484];
Zipcodes['59479'] = [47.032558, -110.30532];
Zipcodes['59480'] = [47.213659, -111.074636];
Zipcodes['59482'] = [48.863808, -111.851602];
Zipcodes['59483'] = [47.464924, -111.732896];
Zipcodes['59484'] = [48.959575, -111.538713];
Zipcodes['59485'] = [47.432255, -111.568631];
Zipcodes['59486'] = [48.340494, -112.377361];
Zipcodes['59487'] = [47.587366, -111.638996];
Zipcodes['59489'] = [47.624666, -109.230405];
Zipcodes['59501'] = [48.689867, -109.781085];
Zipcodes['59520'] = [48.038123, -109.931777];
Zipcodes['59521'] = [48.305039, -109.951289];
Zipcodes['59522'] = [48.556788, -110.976831];
Zipcodes['59523'] = [48.526003, -109.198574];
Zipcodes['59524'] = [48.138817, -108.402446];
Zipcodes['59525'] = [48.641724, -110.261511];
Zipcodes['59526'] = [48.452755, -108.701335];
Zipcodes['59527'] = [48.04166, -108.791566];
Zipcodes['59528'] = [48.617209, -110.43386];
Zipcodes['59529'] = [48.797048, -108.678763];
Zipcodes['59530'] = [48.600914, -110.695944];
Zipcodes['59531'] = [48.776638, -110.794634];
Zipcodes['59532'] = [48.593531, -110.080727];
Zipcodes['59535'] = [47.941002, -109.252867];
Zipcodes['59537'] = [48.843205, -107.988067];
Zipcodes['59538'] = [48.026235, -107.840361];
Zipcodes['59540'] = [48.604919, -110.563581];
Zipcodes['59542'] = [48.821862, -108.33575];
Zipcodes['59544'] = [48.803387, -107.555214];
Zipcodes['59545'] = [48.939152, -111.21972];
Zipcodes['59546'] = [47.899298, -108.539285];
Zipcodes['59547'] = [48.703466, -108.98602];
Zipcodes['59601'] = [46.53069, -112.199398];
Zipcodes['59602'] = [46.726915, -111.908265];
Zipcodes['59623'] = [46.589915, -112.040677];
Zipcodes['59625'] = [46.601439, -112.038815];
Zipcodes['59631'] = [46.290605, -112.388976];
Zipcodes['59632'] = [46.169453, -112.004042];
Zipcodes['59633'] = [46.818847, -112.342931];
Zipcodes['59634'] = [46.444934, -112.031499];
Zipcodes['59635'] = [46.555315, -111.814234];
Zipcodes['59636'] = [46.61614, -112.114018];
Zipcodes['59638'] = [46.357903, -112.027699];
Zipcodes['59639'] = [47.192032, -112.74729];
Zipcodes['59640'] = [46.768033, -112.367823];
Zipcodes['59642'] = [46.265344, -110.757381];
Zipcodes['59643'] = [46.134746, -111.579908];
Zipcodes['59644'] = [46.416678, -111.442641];
Zipcodes['59645'] = [46.687672, -111.054662];
Zipcodes['59647'] = [46.462548, -111.65395];
Zipcodes['59648'] = [47.077021, -112.195965];
Zipcodes['59701'] = [46.032709, -112.464159];
Zipcodes['59710'] = [45.054132, -112.081261];
Zipcodes['59711'] = [46.094672, -113.141624];
Zipcodes['59713'] = [46.659451, -112.61914];
Zipcodes['59714'] = [45.972611, -111.133999];
Zipcodes['59715'] = [45.693722, -110.906093];
Zipcodes['59716'] = [45.287116, -111.38874];
Zipcodes['59718'] = [45.639468, -111.171171];
Zipcodes['59720'] = [44.939235, -111.58923];
Zipcodes['59721'] = [45.852021, -111.863151];
Zipcodes['59722'] = [46.352854, -112.752885];
Zipcodes['59724'] = [44.661167, -112.804654];
Zipcodes['59725'] = [45.073828, -112.834673];
Zipcodes['59727'] = [45.799384, -112.830888];
Zipcodes['59728'] = [46.508912, -112.434837];
Zipcodes['59729'] = [45.324524, -111.651181];
Zipcodes['59730'] = [45.208226, -111.128685];
Zipcodes['59731'] = [46.579108, -112.761137];
Zipcodes['59732'] = [45.500048, -112.692418];
Zipcodes['59733'] = [46.544949, -112.958189];
Zipcodes['59735'] = [45.649764, -111.849852];
Zipcodes['59736'] = [45.344184, -113.342623];
Zipcodes['59739'] = [44.660246, -112.199789];
Zipcodes['59740'] = [45.503611, -111.814148];
Zipcodes['59741'] = [45.769824, -111.368344];
Zipcodes['59743'] = [45.601215, -112.764722];
Zipcodes['59745'] = [45.595195, -111.614802];
Zipcodes['59746'] = [45.384931, -113.059097];
Zipcodes['59747'] = [45.696862, -111.961765];
Zipcodes['59748'] = [46.046104, -112.696033];
Zipcodes['59749'] = [45.454116, -112.072389];
Zipcodes['59750'] = [45.916938, -112.684982];
Zipcodes['59751'] = [45.689965, -112.297556];
Zipcodes['59752'] = [45.916184, -111.526184];
Zipcodes['59754'] = [45.519283, -112.356711];
Zipcodes['59755'] = [45.270967, -111.943619];
Zipcodes['59756'] = [46.174143, -112.834586];
Zipcodes['59758'] = [44.799883, -111.19038];
Zipcodes['59759'] = [45.845245, -112.165946];
Zipcodes['59760'] = [45.755936, -111.592435];
Zipcodes['59761'] = [45.575969, -113.556204];
Zipcodes['59762'] = [45.948586, -113.333599];
Zipcodes['59801'] = [46.854689, -114.010437];
Zipcodes['59802'] = [46.903959, -113.91929];
Zipcodes['59803'] = [46.784478, -113.946271];
Zipcodes['59804'] = [46.878743, -114.264888];
Zipcodes['59808'] = [46.961731, -114.091961];
Zipcodes['59812'] = [46.861226, -113.984509];
Zipcodes['59820'] = [46.897452, -114.609123];
Zipcodes['59821'] = [47.2091, -114.022396];
Zipcodes['59823'] = [46.967846, -113.604667];
Zipcodes['59824'] = [47.413466, -114.207383];
Zipcodes['59825'] = [46.61446, -113.648701];
Zipcodes['59826'] = [47.462573, -113.704157];
Zipcodes['59827'] = [45.892583, -114.088429];
Zipcodes['59828'] = [46.326969, -113.958867];
Zipcodes['59829'] = [45.750336, -114.177836];
Zipcodes['59830'] = [47.434619, -115.45719];
Zipcodes['59831'] = [47.26012, -114.368312];
Zipcodes['59832'] = [46.688956, -113.252857];
Zipcodes['59833'] = [46.648016, -114.074173];
Zipcodes['59834'] = [47.079584, -114.221812];
Zipcodes['59837'] = [46.535047, -113.283235];
Zipcodes['59840'] = [46.166692, -114.105097];
Zipcodes['59841'] = [46.334211, -114.222998];
Zipcodes['59842'] = [47.33015, -115.43801];
Zipcodes['59843'] = [46.853556, -113.013789];
Zipcodes['59844'] = [48.000416, -115.939432];
Zipcodes['59845'] = [47.690256, -114.554685];
Zipcodes['59846'] = [47.151793, -114.513188];
Zipcodes['59847'] = [46.715693, -114.370083];
Zipcodes['59848'] = [47.710009, -114.689787];
Zipcodes['59851'] = [46.871545, -113.876577];
Zipcodes['59853'] = [48.042017, -115.790724];
Zipcodes['59854'] = [47.275618, -113.234187];
Zipcodes['59855'] = [47.600266, -114.117887];
Zipcodes['59856'] = [47.379863, -114.737432];
Zipcodes['59858'] = [46.285772, -113.467406];
Zipcodes['59859'] = [47.519781, -114.795216];
Zipcodes['59860'] = [47.715801, -114.18621];
Zipcodes['59863'] = [47.300207, -114.182582];
Zipcodes['59864'] = [47.531668, -114.119557];
Zipcodes['59865'] = [47.332182, -113.998307];
Zipcodes['59866'] = [47.342903, -115.120402];
Zipcodes['59867'] = [47.390653, -115.562589];
Zipcodes['59868'] = [47.221041, -113.54046];
Zipcodes['59870'] = [46.516308, -114.06345];
Zipcodes['59871'] = [45.89215, -113.816683];
Zipcodes['59872'] = [47.104767, -114.908845];
Zipcodes['59873'] = [47.662662, -115.273133];
Zipcodes['59874'] = [47.807052, -115.600358];
Zipcodes['59875'] = [46.420377, -114.315601];
Zipcodes['59901'] = [48.230565, -114.403313];
Zipcodes['59910'] = [47.820761, -114.235507];
Zipcodes['59911'] = [47.864278, -113.884598];
Zipcodes['59912'] = [48.436986, -114.168722];
Zipcodes['59913'] = [48.433503, -113.959142];
Zipcodes['59914'] = [47.876133, -114.303177];
Zipcodes['59915'] = [47.820334, -114.411263];
Zipcodes['59916'] = [48.306528, -113.657165];
Zipcodes['59917'] = [48.934642, -114.876703];
Zipcodes['59918'] = [48.781333, -114.758611];
Zipcodes['59919'] = [48.377418, -114.056424];
Zipcodes['59920'] = [48.002764, -114.608268];
Zipcodes['59922'] = [48.003043, -114.222136];
Zipcodes['59923'] = [48.270626, -115.313164];
Zipcodes['59925'] = [48.044436, -114.832615];
Zipcodes['59926'] = [48.352184, -113.995596];
Zipcodes['59927'] = [48.559744, -114.712763];
Zipcodes['59928'] = [48.797334, -114.292454];
Zipcodes['59929'] = [47.915932, -114.405183];
Zipcodes['59930'] = [48.866728, -115.133187];
Zipcodes['59931'] = [47.922117, -114.254551];
Zipcodes['59932'] = [48.05217, -114.242598];
Zipcodes['59933'] = [48.71944, -114.768386];
Zipcodes['59934'] = [48.636606, -114.931778];
Zipcodes['59935'] = [48.649762, -115.834835];
Zipcodes['59936'] = [48.189819, -113.473635];
Zipcodes['59937'] = [48.436974, -114.499016];
Zipcodes['60002'] = [42.467143, -88.094339];
Zipcodes['60004'] = [42.11278, -87.979542];
Zipcodes['60005'] = [42.064472, -87.98669];
Zipcodes['60007'] = [42.023977, -87.999779];
Zipcodes['60008'] = [42.070412, -88.022247];
Zipcodes['60010'] = [42.146498, -88.164654];
Zipcodes['60012'] = [42.272621, -88.31157];
Zipcodes['60013'] = [42.223514, -88.235896];
Zipcodes['60014'] = [42.230279, -88.329654];
Zipcodes['60015'] = [42.173681, -87.878362];
Zipcodes['60016'] = [42.052719, -87.892061];
Zipcodes['60018'] = [41.978872, -87.894342];
Zipcodes['60020'] = [42.394859, -88.174127];
Zipcodes['60021'] = [42.194762, -88.217181];
Zipcodes['60022'] = [42.136137, -87.760474];
Zipcodes['60025'] = [42.074632, -87.811581];
Zipcodes['60026'] = [42.092205, -87.837913];
Zipcodes['60029'] = [42.058549, -87.784941];
Zipcodes['60030'] = [42.335635, -88.046782];
Zipcodes['60031'] = [42.373189, -87.939664];
Zipcodes['60033'] = [42.423429, -88.610465];
Zipcodes['60034'] = [42.458664, -88.426643];
Zipcodes['60035'] = [42.185968, -87.805939];
Zipcodes['60040'] = [42.206525, -87.812818];
Zipcodes['60041'] = [42.36958, -88.153504];
Zipcodes['60042'] = [42.278327, -88.195495];
Zipcodes['60043'] = [42.088818, -87.714497];
Zipcodes['60044'] = [42.288124, -87.860081];
Zipcodes['60045'] = [42.235997, -87.864777];
Zipcodes['60046'] = [42.415962, -88.065351];
Zipcodes['60047'] = [42.201564, -88.046295];
Zipcodes['60048'] = [42.292606, -87.945317];
Zipcodes['60050'] = [42.329613, -88.293245];
Zipcodes['60051'] = [42.350262, -88.221054];
Zipcodes['60053'] = [42.042239, -87.788928];
Zipcodes['60056'] = [42.065378, -87.93471];
Zipcodes['60060'] = [42.270125, -88.039327];
Zipcodes['60061'] = [42.232851, -87.95893];
Zipcodes['60062'] = [42.126279, -87.845088];
Zipcodes['60064'] = [42.324058, -87.86157];
Zipcodes['60067'] = [42.108134, -88.064335];
Zipcodes['60068'] = [42.013588, -87.844655];
Zipcodes['60069'] = [42.196149, -87.923458];
Zipcodes['60070'] = [42.103663, -87.928972];
Zipcodes['60071'] = [42.46845, -88.315807];
Zipcodes['60072'] = [42.406458, -88.306351];
Zipcodes['60073'] = [42.354458, -88.102421];
Zipcodes['60074'] = [42.131462, -88.026329];
Zipcodes['60075'] = [42.492576, -87.908663];
Zipcodes['60076'] = [42.034133, -87.729445];
Zipcodes['60077'] = [42.036154, -87.756328];
Zipcodes['60081'] = [42.449091, -88.226705];
Zipcodes['60083'] = [42.436841, -87.949952];
Zipcodes['60084'] = [42.269002, -88.139094];
Zipcodes['60085'] = [42.354935, -87.860761];
Zipcodes['60087'] = [42.403838, -87.853589];
Zipcodes['60088'] = [42.309632, -87.844061];
Zipcodes['60089'] = [42.167231, -87.964063];
Zipcodes['60090'] = [42.125878, -87.911954];
Zipcodes['60091'] = [42.077788, -87.72057];
Zipcodes['60093'] = [42.104067, -87.753933];
Zipcodes['60096'] = [42.480614, -87.829557];
Zipcodes['60097'] = [42.396975, -88.372444];
Zipcodes['60098'] = [42.324645, -88.455561];
Zipcodes['60099'] = [42.459988, -87.865021];
Zipcodes['60101'] = [41.932119, -88.010781];
Zipcodes['60102'] = [42.163134, -88.310793];
Zipcodes['60103'] = [41.976545, -88.205689];
Zipcodes['60104'] = [41.882563, -87.876444];
Zipcodes['60106'] = [41.964374, -87.944129];
Zipcodes['60107'] = [42.020586, -88.177771];
Zipcodes['60108'] = [41.950304, -88.095497];
Zipcodes['60109'] = [42.054489, -88.540912];
Zipcodes['60110'] = [42.113432, -88.292251];
Zipcodes['60111'] = [42.00986, -88.835543];
Zipcodes['60112'] = [41.923732, -88.690217];
Zipcodes['60113'] = [41.931309, -88.96413];
Zipcodes['60115'] = [41.901263, -88.76155];
Zipcodes['60118'] = [42.106259, -88.304383];
Zipcodes['60119'] = [41.851532, -88.476451];
Zipcodes['60120'] = [42.032566, -88.240438];
Zipcodes['60123'] = [42.040644, -88.311296];
Zipcodes['60124'] = [42.020169, -88.400686];
Zipcodes['60126'] = [41.896843, -87.94124];
Zipcodes['60129'] = [42.008161, -88.974996];
Zipcodes['60130'] = [41.867031, -87.817934];
Zipcodes['60131'] = [41.937783, -87.883025];
Zipcodes['60133'] = [41.977842, -88.143199];
Zipcodes['60134'] = [41.878343, -88.342072];
Zipcodes['60135'] = [42.112505, -88.670815];
Zipcodes['60136'] = [42.105188, -88.380424];
Zipcodes['60137'] = [41.865206, -88.062783];
Zipcodes['60139'] = [41.918877, -88.078095];
Zipcodes['60140'] = [42.079051, -88.513098];
Zipcodes['60141'] = [41.858344, -87.83791];
Zipcodes['60142'] = [42.173915, -88.444659];
Zipcodes['60143'] = [41.973971, -88.021084];
Zipcodes['60144'] = [41.835076, -88.51986];
Zipcodes['60145'] = [42.084569, -88.77382];
Zipcodes['60146'] = [42.099982, -88.874061];
Zipcodes['60148'] = [41.876257, -88.018754];
Zipcodes['60150'] = [41.9144, -88.895775];
Zipcodes['60151'] = [41.918006, -88.577108];
Zipcodes['60152'] = [42.239365, -88.627304];
Zipcodes['60153'] = [41.879817, -87.84387];
Zipcodes['60154'] = [41.849234, -87.889849];
Zipcodes['60155'] = [41.857785, -87.856316];
Zipcodes['60156'] = [42.203063, -88.320474];
Zipcodes['60157'] = [41.975298, -88.056215];
Zipcodes['60160'] = [41.903491, -87.86177];
Zipcodes['60162'] = [41.867548, -87.901946];
Zipcodes['60163'] = [41.88793, -87.909467];
Zipcodes['60164'] = [41.91669, -87.901182];
Zipcodes['60165'] = [41.903234, -87.880732];
Zipcodes['60169'] = [42.05087, -88.116615];
Zipcodes['60171'] = [41.925059, -87.838334];
Zipcodes['60172'] = [41.980253, -88.088918];
Zipcodes['60173'] = [42.051556, -88.055522];
Zipcodes['60174'] = [41.930614, -88.298678];
Zipcodes['60175'] = [41.948188, -88.388701];
Zipcodes['60176'] = [41.958324, -87.869655];
Zipcodes['60177'] = [41.988481, -88.310167];
Zipcodes['60178'] = [42.008161, -88.660729];
Zipcodes['60180'] = [42.223377, -88.520207];
Zipcodes['60181'] = [41.876897, -87.976328];
Zipcodes['60183'] = [41.940234, -88.409141];
Zipcodes['60184'] = [41.951502, -88.25317];
Zipcodes['60185'] = [41.893111, -88.214998];
Zipcodes['60187'] = [41.87272, -88.112442];
Zipcodes['60188'] = [41.915737, -88.129434];
Zipcodes['60189'] = [41.840645, -88.122055];
Zipcodes['60190'] = [41.873621, -88.156243];
Zipcodes['60191'] = [41.964839, -87.981107];
Zipcodes['60192'] = [42.063272, -88.203662];
Zipcodes['60193'] = [42.009371, -88.096725];
Zipcodes['60194'] = [42.033424, -88.113562];
Zipcodes['60195'] = [42.065367, -88.086587];
Zipcodes['60201'] = [42.054745, -87.692382];
Zipcodes['60202'] = [42.02932, -87.68539];
Zipcodes['60203'] = [42.049035, -87.717455];
Zipcodes['60208'] = [42.053554, -87.670259];
Zipcodes['60301'] = [41.888654, -87.796538];
Zipcodes['60302'] = [41.894772, -87.789825];
Zipcodes['60304'] = [41.872468, -87.789331];
Zipcodes['60305'] = [41.894875, -87.819064];
Zipcodes['60401'] = [41.347143, -87.61413];
Zipcodes['60402'] = [41.83288, -87.790063];
Zipcodes['60403'] = [41.56819, -88.118732];
Zipcodes['60404'] = [41.513454, -88.224146];
Zipcodes['60406'] = [41.654945, -87.681952];
Zipcodes['60407'] = [41.234907, -88.261302];
Zipcodes['60408'] = [41.266034, -88.218537];
Zipcodes['60409'] = [41.613641, -87.552122];
Zipcodes['60410'] = [41.428379, -88.213202];
Zipcodes['60411'] = [41.507305, -87.589701];
Zipcodes['60415'] = [41.703438, -87.779542];
Zipcodes['60416'] = [41.293908, -88.283101];
Zipcodes['60417'] = [41.427585, -87.586149];
Zipcodes['60418'] = [41.645598, -87.740078];
Zipcodes['60419'] = [41.627271, -87.59839];
Zipcodes['60420'] = [41.087649, -88.420894];
Zipcodes['60421'] = [41.414206, -88.083462];
Zipcodes['60422'] = [41.538608, -87.684404];
Zipcodes['60423'] = [41.474458, -87.841151];
Zipcodes['60424'] = [41.164148, -88.327716];
Zipcodes['60425'] = [41.543427, -87.610754];
Zipcodes['60426'] = [41.610251, -87.653038];
Zipcodes['60428'] = [41.59981, -87.690617];
Zipcodes['60429'] = [41.574029, -87.683706];
Zipcodes['60430'] = [41.558114, -87.662807];
Zipcodes['60431'] = [41.509986, -88.190357];
Zipcodes['60432'] = [41.542255, -88.033845];
Zipcodes['60433'] = [41.496599, -88.042341];
Zipcodes['60435'] = [41.548642, -88.128501];
Zipcodes['60436'] = [41.48967, -88.12381];
Zipcodes['60437'] = [41.156902, -88.556083];
Zipcodes['60438'] = [41.566242, -87.550204];
Zipcodes['60439'] = [41.676605, -87.97709];
Zipcodes['60440'] = [41.7006, -88.075006];
Zipcodes['60441'] = [41.593049, -88.051567];
Zipcodes['60442'] = [41.391844, -87.960615];
Zipcodes['60443'] = [41.502887, -87.749571];
Zipcodes['60444'] = [41.243115, -88.402732];
Zipcodes['60445'] = [41.628327, -87.73537];
Zipcodes['60446'] = [41.633263, -88.099305];
Zipcodes['60447'] = [41.487239, -88.322166];
Zipcodes['60448'] = [41.53778, -87.892564];
Zipcodes['60449'] = [41.417643, -87.778586];
Zipcodes['60450'] = [41.370016, -88.434265];
Zipcodes['60451'] = [41.508158, -87.960979];
Zipcodes['60452'] = [41.608977, -87.754159];
Zipcodes['60453'] = [41.714116, -87.752771];
Zipcodes['60455'] = [41.740982, -87.806769];
Zipcodes['60456'] = [41.731248, -87.731134];
Zipcodes['60457'] = [41.723789, -87.829619];
Zipcodes['60458'] = [41.753401, -87.826941];
Zipcodes['60459'] = [41.74439, -87.768598];
Zipcodes['60460'] = [40.998574, -88.5366];
Zipcodes['60461'] = [41.521399, -87.686878];
Zipcodes['60462'] = [41.626852, -87.840615];
Zipcodes['60463'] = [41.662446, -87.789969];
Zipcodes['60464'] = [41.669034, -87.864299];
Zipcodes['60465'] = [41.700681, -87.825297];
Zipcodes['60466'] = [41.479162, -87.684598];
Zipcodes['60467'] = [41.597736, -87.886496];
Zipcodes['60468'] = [41.335283, -87.810291];
Zipcodes['60469'] = [41.628697, -87.686312];
Zipcodes['60470'] = [41.165888, -88.638723];
Zipcodes['60471'] = [41.479363, -87.729215];
Zipcodes['60472'] = [41.643075, -87.708082];
Zipcodes['60473'] = [41.597352, -87.601333];
Zipcodes['60474'] = [41.185566, -88.275234];
Zipcodes['60475'] = [41.471976, -87.628251];
Zipcodes['60476'] = [41.569483, -87.601709];
Zipcodes['60477'] = [41.573605, -87.786711];
Zipcodes['60478'] = [41.554179, -87.735525];
Zipcodes['60479'] = [41.232384, -88.526396];
Zipcodes['60480'] = [41.720306, -87.881345];
Zipcodes['60481'] = [41.285531, -88.110561];
Zipcodes['60482'] = [41.688211, -87.792577];
Zipcodes['60484'] = [41.443025, -87.710477];
Zipcodes['60487'] = [41.563837, -87.830349];
Zipcodes['60490'] = [41.676202, -88.140588];
Zipcodes['60491'] = [41.602698, -87.962605];
Zipcodes['60501'] = [41.779245, -87.823379];
Zipcodes['60502'] = [41.787522, -88.260017];
Zipcodes['60503'] = [41.707727, -88.257677];
Zipcodes['60504'] = [41.745439, -88.241209];
Zipcodes['60505'] = [41.765459, -88.296136];
Zipcodes['60506'] = [41.758878, -88.371865];
Zipcodes['60510'] = [41.843037, -88.311055];
Zipcodes['60511'] = [41.758993, -88.551993];
Zipcodes['60512'] = [41.701365, -88.43991];
Zipcodes['60513'] = [41.825059, -87.847546];
Zipcodes['60514'] = [41.795756, -87.96154];
Zipcodes['60515'] = [41.81107, -88.024525];
Zipcodes['60516'] = [41.761297, -88.013393];
Zipcodes['60517'] = [41.738621, -88.042518];
Zipcodes['60518'] = [41.598842, -88.919741];
Zipcodes['60519'] = [41.77748, -88.242553];
Zipcodes['60520'] = [41.787267, -88.667548];
Zipcodes['60521'] = [41.800683, -87.928172];
Zipcodes['60523'] = [41.836395, -87.953173];
Zipcodes['60525'] = [41.783546, -87.865993];
Zipcodes['60526'] = [41.831402, -87.8838];
Zipcodes['60527'] = [41.743074, -87.929214];
Zipcodes['60530'] = [41.79708, -88.952366];
Zipcodes['60531'] = [41.619207, -88.787498];
Zipcodes['60532'] = [41.789443, -88.083984];
Zipcodes['60534'] = [41.81239, -87.820036];
Zipcodes['60536'] = [41.598542, -88.552061];
Zipcodes['60537'] = [41.560477, -88.604103];
Zipcodes['60538'] = [41.727502, -88.336942];
Zipcodes['60539'] = [41.824632, -88.336198];
Zipcodes['60540'] = [41.764585, -88.144165];
Zipcodes['60541'] = [41.513344, -88.532398];
Zipcodes['60542'] = [41.808697, -88.34841];
Zipcodes['60543'] = [41.668264, -88.322786];
Zipcodes['60544'] = [41.613913, -88.218684];
Zipcodes['60545'] = [41.680353, -88.536308];
Zipcodes['60546'] = [41.837923, -87.821859];
Zipcodes['60548'] = [41.648667, -88.634691];
Zipcodes['60549'] = [41.502537, -88.739635];
Zipcodes['60550'] = [41.782197, -88.881393];
Zipcodes['60551'] = [41.525322, -88.687663];
Zipcodes['60552'] = [41.666888, -88.706507];
Zipcodes['60553'] = [41.826254, -89.040056];
Zipcodes['60554'] = [41.776668, -88.457048];
Zipcodes['60555'] = [41.823293, -88.188633];
Zipcodes['60556'] = [41.762915, -88.771125];
Zipcodes['60557'] = [41.445845, -88.767989];
Zipcodes['60558'] = [41.802072, -87.900752];
Zipcodes['60559'] = [41.794371, -87.97286];
Zipcodes['60560'] = [41.607605, -88.431708];
Zipcodes['60561'] = [41.745663, -87.981494];
Zipcodes['60563'] = [41.790064, -88.205593];
Zipcodes['60564'] = [41.706234, -88.195104];
Zipcodes['60565'] = [41.728864, -88.125668];
Zipcodes['60585'] = [41.657058, -88.225243];
Zipcodes['60586'] = [41.578191, -88.234492];
Zipcodes['60601'] = [41.885155, -87.621512];
Zipcodes['60602'] = [41.883041, -87.629079];
Zipcodes['60603'] = [41.880262, -87.627396];
Zipcodes['60604'] = [41.878113, -87.629071];
Zipcodes['60605'] = [41.867566, -87.617228];
Zipcodes['60606'] = [41.881259, -87.637452];
Zipcodes['60607'] = [41.874806, -87.654543];
Zipcodes['60608'] = [41.84688, -87.670664];
Zipcodes['60609'] = [41.81268, -87.656935];
Zipcodes['60610'] = [41.904718, -87.635891];
Zipcodes['60611'] = [41.894742, -87.615073];
Zipcodes['60612'] = [41.880511, -87.687867];
Zipcodes['60613'] = [41.956153, -87.652994];
Zipcodes['60614'] = [41.922747, -87.647153];
Zipcodes['60615'] = [41.803261, -87.597934];
Zipcodes['60616'] = [41.844883, -87.624032];
Zipcodes['60617'] = [41.714417, -87.555761];
Zipcodes['60618'] = [41.947568, -87.702647];
Zipcodes['60619'] = [41.743439, -87.605318];
Zipcodes['60620'] = [41.740497, -87.652558];
Zipcodes['60621'] = [41.776232, -87.639527];
Zipcodes['60622'] = [41.902172, -87.683337];
Zipcodes['60623'] = [41.847101, -87.717611];
Zipcodes['60624'] = [41.880504, -87.724444];
Zipcodes['60625'] = [41.973081, -87.699751];
Zipcodes['60626'] = [42.0088, -87.66705];
Zipcodes['60628'] = [41.691478, -87.618593];
Zipcodes['60629'] = [41.775414, -87.711487];
Zipcodes['60630'] = [41.972071, -87.756569];
Zipcodes['60631'] = [41.994769, -87.813104];
Zipcodes['60632'] = [41.810166, -87.713252];
Zipcodes['60633'] = [41.663783, -87.561314];
Zipcodes['60634'] = [41.946189, -87.806117];
Zipcodes['60636'] = [41.775789, -87.669071];
Zipcodes['60637'] = [41.782504, -87.602734];
Zipcodes['60638'] = [41.782468, -87.770753];
Zipcodes['60639'] = [41.920598, -87.755942];
Zipcodes['60640'] = [41.972872, -87.662604];
Zipcodes['60641'] = [41.946718, -87.746887];
Zipcodes['60642'] = [41.902042, -87.658544];
Zipcodes['60643'] = [41.700273, -87.663267];
Zipcodes['60644'] = [41.880084, -87.756373];
Zipcodes['60645'] = [42.008603, -87.694579];
Zipcodes['60646'] = [41.99365, -87.761325];
Zipcodes['60647'] = [41.921042, -87.701971];
Zipcodes['60649'] = [41.75934, -87.557025];
Zipcodes['60651'] = [41.902006, -87.741016];
Zipcodes['60652'] = [41.747432, -87.714403];
Zipcodes['60653'] = [41.819965, -87.605965];
Zipcodes['60654'] = [41.892158, -87.636324];
Zipcodes['60655'] = [41.694771, -87.703761];
Zipcodes['60656'] = [41.974257, -87.827052];
Zipcodes['60657'] = [41.939411, -87.650704];
Zipcodes['60659'] = [41.989578, -87.706572];
Zipcodes['60660'] = [41.99111, -87.663076];
Zipcodes['60661'] = [41.882997, -87.644114];
Zipcodes['60706'] = [41.963885, -87.816788];
Zipcodes['60707'] = [41.921752, -87.807437];
Zipcodes['60712'] = [42.00543, -87.732959];
Zipcodes['60714'] = [42.028351, -87.811304];
Zipcodes['60803'] = [41.674064, -87.736222];
Zipcodes['60804'] = [41.840164, -87.759916];
Zipcodes['60805'] = [41.722001, -87.702446];
Zipcodes['60827'] = [41.649434, -87.633572];
Zipcodes['60901'] = [41.109438, -87.902817];
Zipcodes['60910'] = [41.066399, -87.799584];
Zipcodes['60911'] = [40.880243, -87.976584];
Zipcodes['60912'] = [40.973619, -87.603476];
Zipcodes['60913'] = [41.148353, -88.062041];
Zipcodes['60914'] = [41.183001, -87.86121];
Zipcodes['60915'] = [41.146331, -87.860709];
Zipcodes['60917'] = [41.047059, -88.197386];
Zipcodes['60918'] = [40.594969, -88.025655];
Zipcodes['60919'] = [40.987876, -88.237518];
Zipcodes['60920'] = [41.030214, -88.30379];
Zipcodes['60921'] = [40.731543, -88.297211];
Zipcodes['60922'] = [41.010925, -87.91929];
Zipcodes['60924'] = [40.560659, -87.885621];
Zipcodes['60926'] = [40.569394, -87.812079];
Zipcodes['60927'] = [40.938489, -87.972776];
Zipcodes['60928'] = [40.745685, -87.85077];
Zipcodes['60929'] = [40.876008, -88.292405];
Zipcodes['60930'] = [40.831736, -88.006266];
Zipcodes['60931'] = [40.889327, -87.588141];
Zipcodes['60932'] = [40.465104, -87.800118];
Zipcodes['60933'] = [40.462578, -88.273801];
Zipcodes['60934'] = [40.957093, -88.345104];
Zipcodes['60935'] = [41.170462, -88.175879];
Zipcodes['60936'] = [40.46469, -88.36306];
Zipcodes['60938'] = [40.772258, -87.984392];
Zipcodes['60939'] = [40.567001, -87.777093];
Zipcodes['60940'] = [41.249777, -87.642858];
Zipcodes['60941'] = [41.038658, -88.085869];
Zipcodes['60942'] = [40.466569, -87.661605];
Zipcodes['60945'] = [40.835123, -87.596704];
Zipcodes['60946'] = [40.913073, -88.200712];
Zipcodes['60948'] = [40.52356, -88.07735];
Zipcodes['60949'] = [40.378337, -88.105151];
Zipcodes['60950'] = [41.252848, -87.883586];
Zipcodes['60951'] = [40.919046, -87.740751];
Zipcodes['60952'] = [40.562795, -88.250366];
Zipcodes['60953'] = [40.629003, -87.6902];
Zipcodes['60954'] = [41.147708, -87.622516];
Zipcodes['60955'] = [40.708464, -87.982949];
Zipcodes['60956'] = [40.967685, -87.716245];
Zipcodes['60957'] = [40.442962, -88.136204];
Zipcodes['60958'] = [41.065203, -87.591599];
Zipcodes['60959'] = [40.789482, -88.183175];
Zipcodes['60960'] = [40.438957, -87.890723];
Zipcodes['60961'] = [41.109567, -88.221504];
Zipcodes['60962'] = [40.626141, -88.187143];
Zipcodes['60963'] = [40.368495, -87.65107];
Zipcodes['60964'] = [41.027818, -87.742256];
Zipcodes['60966'] = [40.751413, -87.583242];
Zipcodes['60967'] = [40.624091, -87.581334];
Zipcodes['60968'] = [40.675461, -88.119734];
Zipcodes['60969'] = [41.110968, -88.149713];
Zipcodes['60970'] = [40.793923, -87.741344];
Zipcodes['60973'] = [40.53654, -87.662626];
Zipcodes['60974'] = [40.717984, -87.735903];
Zipcodes['61001'] = [42.465816, -90.110232];
Zipcodes['61006'] = [41.861762, -89.202281];
Zipcodes['61007'] = [42.199421, -89.591073];
Zipcodes['61008'] = [42.245929, -88.839859];
Zipcodes['61010'] = [42.134545, -89.272646];
Zipcodes['61011'] = [42.392262, -88.897124];
Zipcodes['61012'] = [42.401445, -88.764558];
Zipcodes['61013'] = [42.375397, -89.636374];
Zipcodes['61014'] = [41.974996, -89.886487];
Zipcodes['61015'] = [41.996752, -89.197077];
Zipcodes['61016'] = [42.1973, -88.947729];
Zipcodes['61018'] = [42.415969, -89.555551];
Zipcodes['61019'] = [42.439175, -89.410156];
Zipcodes['61020'] = [42.11034, -89.098088];
Zipcodes['61021'] = [41.829597, -89.477284];
Zipcodes['61024'] = [42.436415, -89.297114];
Zipcodes['61025'] = [42.471378, -90.555965];
Zipcodes['61027'] = [42.333273, -89.755547];
Zipcodes['61028'] = [42.298465, -90.175881];
Zipcodes['61030'] = [42.119648, -89.591622];
Zipcodes['61031'] = [41.829964, -89.321271];
Zipcodes['61032'] = [42.318865, -89.636977];
Zipcodes['61036'] = [42.413689, -90.375811];
Zipcodes['61037'] = [41.788389, -89.759781];
Zipcodes['61038'] = [42.265818, -88.737964];
Zipcodes['61039'] = [42.208034, -89.469314];
Zipcodes['61041'] = [42.273156, -90.311245];
Zipcodes['61042'] = [41.694051, -89.558745];
Zipcodes['61043'] = [42.066754, -89.104946];
Zipcodes['61044'] = [42.3234, -89.909937];
Zipcodes['61046'] = [42.104436, -89.812795];
Zipcodes['61047'] = [42.153044, -89.396012];
Zipcodes['61048'] = [42.382634, -89.841177];
Zipcodes['61049'] = [42.049626, -89.008914];
Zipcodes['61050'] = [42.439332, -89.7319];
Zipcodes['61051'] = [41.982849, -89.761848];
Zipcodes['61052'] = [42.111451, -89.000102];
Zipcodes['61053'] = [42.120819, -89.983297];
Zipcodes['61054'] = [42.055737, -89.445461];
Zipcodes['61057'] = [41.833568, -89.383952];
Zipcodes['61059'] = [42.454648, -89.940823];
Zipcodes['61060'] = [42.478784, -89.618152];
Zipcodes['61061'] = [41.997917, -89.341656];
Zipcodes['61062'] = [42.250675, -89.835758];
Zipcodes['61063'] = [42.319457, -89.340712];
Zipcodes['61064'] = [41.989306, -89.587249];
Zipcodes['61065'] = [42.400588, -88.836952];
Zipcodes['61067'] = [42.305472, -89.472083];
Zipcodes['61068'] = [41.950028, -89.06559];
Zipcodes['61070'] = [42.415681, -89.471727];
Zipcodes['61071'] = [41.724694, -89.698885];
Zipcodes['61072'] = [42.442354, -89.138457];
Zipcodes['61073'] = [42.423752, -88.993888];
Zipcodes['61074'] = [42.124371, -90.122436];
Zipcodes['61075'] = [42.46545, -90.264383];
Zipcodes['61077'] = [42.240128, -89.348043];
Zipcodes['61078'] = [42.167276, -89.730603];
Zipcodes['61079'] = [42.452934, -89.215121];
Zipcodes['61080'] = [42.480913, -88.983829];
Zipcodes['61081'] = [41.850006, -89.739377];
Zipcodes['61084'] = [42.123586, -89.190074];
Zipcodes['61085'] = [42.342651, -90.030768];
Zipcodes['61087'] = [42.480502, -89.993491];
Zipcodes['61088'] = [42.268117, -89.262717];
Zipcodes['61089'] = [42.473543, -89.823358];
Zipcodes['61091'] = [41.908183, -89.552363];
Zipcodes['61101'] = [42.346079, -89.146087];
Zipcodes['61102'] = [42.228769, -89.162524];
Zipcodes['61103'] = [42.340195, -89.086635];
Zipcodes['61104'] = [42.25172, -89.079945];
Zipcodes['61107'] = [42.285464, -89.001649];
Zipcodes['61108'] = [42.255157, -89.002313];
Zipcodes['61109'] = [42.191304, -89.055756];
Zipcodes['61111'] = [42.335505, -89.002749];
Zipcodes['61112'] = [42.24197, -88.975223];
Zipcodes['61114'] = [42.306605, -88.990894];
Zipcodes['61115'] = [42.362592, -89.024156];
Zipcodes['61201'] = [41.477559, -90.575628];
Zipcodes['61230'] = [41.734071, -90.212923];
Zipcodes['61231'] = [41.210699, -90.717768];
Zipcodes['61232'] = [41.431676, -90.727388];
Zipcodes['61233'] = [41.294676, -90.291318];
Zipcodes['61234'] = [41.417969, -89.914344];
Zipcodes['61235'] = [41.401291, -90.023568];
Zipcodes['61236'] = [41.514428, -90.355576];
Zipcodes['61237'] = [41.341768, -90.862181];
Zipcodes['61238'] = [41.282275, -90.172422];
Zipcodes['61239'] = [41.49477, -90.389711];
Zipcodes['61240'] = [41.423286, -90.43697];
Zipcodes['61241'] = [41.487328, -90.320356];
Zipcodes['61242'] = [41.708009, -90.285148];
Zipcodes['61243'] = [41.618468, -89.678408];
Zipcodes['61244'] = [41.522559, -90.390822];
Zipcodes['61250'] = [41.655923, -90.112354];
Zipcodes['61251'] = [41.729093, -90.072903];
Zipcodes['61252'] = [41.83685, -90.12531];
Zipcodes['61254'] = [41.467229, -90.149632];
Zipcodes['61256'] = [41.561084, -90.400585];
Zipcodes['61257'] = [41.593358, -90.221845];
Zipcodes['61258'] = [41.521849, -89.913968];
Zipcodes['61259'] = [41.385591, -90.924481];
Zipcodes['61260'] = [41.237949, -90.887362];
Zipcodes['61261'] = [41.736022, -89.910033];
Zipcodes['61262'] = [41.276069, -90.348286];
Zipcodes['61263'] = [41.266237, -90.596023];
Zipcodes['61264'] = [41.402799, -90.595864];
Zipcodes['61265'] = [41.480697, -90.487167];
Zipcodes['61270'] = [41.826598, -89.963451];
Zipcodes['61272'] = [41.251085, -91.011405];
Zipcodes['61273'] = [41.364242, -90.399093];
Zipcodes['61274'] = [41.370314, -90.276626];
Zipcodes['61275'] = [41.605575, -90.307048];
Zipcodes['61276'] = [41.316601, -90.576297];
Zipcodes['61277'] = [41.613582, -89.930578];
Zipcodes['61278'] = [41.579333, -90.334162];
Zipcodes['61279'] = [41.325731, -90.728612];
Zipcodes['61281'] = [41.29978, -90.520144];
Zipcodes['61282'] = [41.496716, -90.411691];
Zipcodes['61283'] = [41.590912, -89.788559];
Zipcodes['61284'] = [41.395104, -90.750033];
Zipcodes['61285'] = [41.993904, -90.082298];
Zipcodes['61301'] = [41.397423, -89.084264];
Zipcodes['61310'] = [41.702419, -89.35451];
Zipcodes['61311'] = [41.040152, -88.862201];
Zipcodes['61312'] = [41.434042, -89.225397];
Zipcodes['61313'] = [41.07237, -88.669963];
Zipcodes['61314'] = [41.300261, -89.676578];
Zipcodes['61315'] = [41.285326, -89.366593];
Zipcodes['61316'] = [41.257789, -89.127667];
Zipcodes['61317'] = [41.431965, -89.208181];
Zipcodes['61318'] = [41.711285, -89.078271];
Zipcodes['61319'] = [41.015375, -88.739908];
Zipcodes['61320'] = [41.355293, -89.170438];
Zipcodes['61321'] = [40.967587, -88.976213];
Zipcodes['61322'] = [41.312267, -89.307047];
Zipcodes['61323'] = [41.435484, -89.396058];
Zipcodes['61324'] = [41.769935, -89.413597];
Zipcodes['61325'] = [41.234953, -88.809633];
Zipcodes['61326'] = [41.246249, -89.229711];
Zipcodes['61327'] = [41.230466, -89.299146];
Zipcodes['61328'] = [41.509284, -89.458993];
Zipcodes['61329'] = [41.377047, -89.207846];
Zipcodes['61330'] = [41.535103, -89.277313];
Zipcodes['61331'] = [41.747703, -89.277542];
Zipcodes['61332'] = [41.188817, -88.984548];
Zipcodes['61333'] = [40.988451, -88.889294];
Zipcodes['61334'] = [41.147315, -89.099338];
Zipcodes['61335'] = [41.159239, -89.233918];
Zipcodes['61336'] = [41.111479, -89.212267];
Zipcodes['61337'] = [41.450551, -89.297789];
Zipcodes['61338'] = [41.470139, -89.66514];
Zipcodes['61340'] = [41.264025, -89.251281];
Zipcodes['61341'] = [41.352165, -88.693197];
Zipcodes['61342'] = [41.543677, -89.084088];
Zipcodes['61344'] = [41.412366, -89.834404];
Zipcodes['61345'] = [41.276085, -89.785366];
Zipcodes['61346'] = [41.506666, -89.719954];
Zipcodes['61348'] = [41.285723, -89.035197];
Zipcodes['61349'] = [41.548675, -89.437073];
Zipcodes['61350'] = [41.372095, -88.865127];
Zipcodes['61353'] = [41.704317, -88.991048];
Zipcodes['61354'] = [41.334165, -89.142245];
Zipcodes['61356'] = [41.389301, -89.439863];
Zipcodes['61358'] = [40.981674, -89.042991];
Zipcodes['61359'] = [41.362452, -89.271459];
Zipcodes['61360'] = [41.332589, -88.600301];
Zipcodes['61361'] = [41.412278, -89.773775];
Zipcodes['61362'] = [41.351426, -89.223736];
Zipcodes['61363'] = [41.254249, -89.182485];
Zipcodes['61364'] = [41.128479, -88.849898];
Zipcodes['61367'] = [41.621412, -89.259488];
Zipcodes['61368'] = [41.262126, -89.517493];
Zipcodes['61369'] = [40.98269, -89.153993];
Zipcodes['61370'] = [41.210728, -89.038814];
Zipcodes['61371'] = [41.499321, -89.019862];
Zipcodes['61372'] = [41.465822, -89.082396];
Zipcodes['61373'] = [41.407228, -88.995936];
Zipcodes['61374'] = [41.547302, -89.357589];
Zipcodes['61375'] = [41.035173, -89.243431];
Zipcodes['61376'] = [41.538404, -89.6018];
Zipcodes['61377'] = [41.065403, -89.042089];
Zipcodes['61378'] = [41.722671, -89.155402];
Zipcodes['61379'] = [41.380255, -89.606301];
Zipcodes['61401'] = [40.945179, -90.386567];
Zipcodes['61402'] = [40.939903, -90.406354];
Zipcodes['61410'] = [40.79488, -90.422478];
Zipcodes['61411'] = [40.400982, -90.502128];
Zipcodes['61412'] = [41.078398, -90.579715];
Zipcodes['61413'] = [41.186577, -90.3704];
Zipcodes['61414'] = [41.125934, -90.154913];
Zipcodes['61415'] = [40.649983, -90.418763];
Zipcodes['61416'] = [40.495917, -90.563071];
Zipcodes['61417'] = [40.772229, -90.54413];
Zipcodes['61418'] = [40.847173, -90.869221];
Zipcodes['61419'] = [41.199807, -90.117518];
Zipcodes['61420'] = [40.548785, -90.867256];
Zipcodes['61421'] = [41.187535, -89.647909];
Zipcodes['61422'] = [40.551405, -90.532911];
Zipcodes['61423'] = [40.885484, -90.502322];
Zipcodes['61424'] = [41.076634, -89.635092];
Zipcodes['61425'] = [40.759246, -91.040136];
Zipcodes['61426'] = [41.119635, -89.706009];
Zipcodes['61427'] = [40.497616, -90.194498];
Zipcodes['61428'] = [40.933329, -90.113563];
Zipcodes['61430'] = [40.943799, -90.311977];
Zipcodes['61431'] = [40.600024, -90.28854];
Zipcodes['61432'] = [40.648385, -90.153167];
Zipcodes['61433'] = [40.554375, -90.168989];
Zipcodes['61434'] = [41.172707, -90.03619];
Zipcodes['61435'] = [40.99561, -90.568639];
Zipcodes['61436'] = [40.860149, -90.223178];
Zipcodes['61437'] = [40.846983, -90.992111];
Zipcodes['61438'] = [40.585404, -90.649451];
Zipcodes['61439'] = [41.029203, -90.350207];
Zipcodes['61440'] = [40.316101, -90.592759];
Zipcodes['61441'] = [40.330188, -90.28171];
Zipcodes['61442'] = [41.103273, -90.923968];
Zipcodes['61443'] = [41.26369, -89.944924];
Zipcodes['61447'] = [40.8703, -90.763381];
Zipcodes['61448'] = [40.933819, -90.243765];
Zipcodes['61449'] = [41.095042, -89.975133];
Zipcodes['61450'] = [40.571726, -90.984257];
Zipcodes['61451'] = [40.938697, -89.939478];
Zipcodes['61452'] = [40.23836, -90.660225];
Zipcodes['61453'] = [41.013477, -90.76321];
Zipcodes['61454'] = [40.68193, -91.039891];
Zipcodes['61455'] = [40.440441, -90.637941];
Zipcodes['61458'] = [40.782213, -90.183159];
Zipcodes['61459'] = [40.510245, -90.419564];
Zipcodes['61460'] = [40.721616, -90.827733];
Zipcodes['61462'] = [40.909888, -90.651678];
Zipcodes['61465'] = [41.210673, -90.479667];
Zipcodes['61466'] = [41.111017, -90.482208];
Zipcodes['61467'] = [41.069432, -90.242389];
Zipcodes['61468'] = [41.256502, -90.387431];
Zipcodes['61469'] = [40.955983, -90.908233];
Zipcodes['61470'] = [40.606179, -90.506187];
Zipcodes['61471'] = [40.695781, -90.825065];
Zipcodes['61472'] = [41.103259, -90.383436];
Zipcodes['61473'] = [40.709145, -90.649735];
Zipcodes['61474'] = [40.742124, -90.394094];
Zipcodes['61475'] = [40.596886, -90.752701];
Zipcodes['61476'] = [41.089796, -90.829029];
Zipcodes['61477'] = [40.495994, -90.310552];
Zipcodes['61478'] = [40.756317, -90.760382];
Zipcodes['61479'] = [41.001916, -89.639391];
Zipcodes['61480'] = [40.727402, -90.913343];
Zipcodes['61482'] = [40.389845, -90.403943];
Zipcodes['61483'] = [41.081231, -89.873285];
Zipcodes['61484'] = [40.297674, -90.443219];
Zipcodes['61485'] = [41.022346, -90.0706];
Zipcodes['61486'] = [41.194794, -90.576436];
Zipcodes['61488'] = [41.030032, -90.320773];
Zipcodes['61489'] = [40.930759, -90.033093];
Zipcodes['61490'] = [41.185531, -90.258712];
Zipcodes['61491'] = [41.053825, -89.72078];
Zipcodes['61501'] = [40.228366, -90.311915];
Zipcodes['61516'] = [40.855551, -89.131967];
Zipcodes['61517'] = [40.818955, -89.846915];
Zipcodes['61519'] = [40.464644, -90.093078];
Zipcodes['61520'] = [40.543564, -90.024844];
Zipcodes['61523'] = [40.911951, -89.537757];
Zipcodes['61524'] = [40.491238, -90.034652];
Zipcodes['61525'] = [40.847755, -89.670466];
Zipcodes['61526'] = [40.922901, -89.619957];
Zipcodes['61528'] = [40.776901, -89.718271];
Zipcodes['61529'] = [40.782018, -89.937608];
Zipcodes['61530'] = [40.708694, -89.264946];
Zipcodes['61531'] = [40.682505, -90.036794];
Zipcodes['61532'] = [40.348491, -89.806913];
Zipcodes['61533'] = [40.586374, -89.842499];
Zipcodes['61534'] = [40.414961, -89.664661];
Zipcodes['61535'] = [40.578864, -89.516514];
Zipcodes['61536'] = [40.690822, -89.790062];
Zipcodes['61537'] = [41.119166, -89.471442];
Zipcodes['61539'] = [40.556359, -89.762845];
Zipcodes['61540'] = [41.0146, -89.366597];
Zipcodes['61541'] = [40.980378, -89.234952];
Zipcodes['61542'] = [40.376696, -90.131662];
Zipcodes['61543'] = [40.417859, -89.977542];
Zipcodes['61544'] = [40.700737, -90.234071];
Zipcodes['61545'] = [40.868374, -89.352495];
Zipcodes['61546'] = [40.448337, -89.809588];
Zipcodes['61547'] = [40.570008, -89.721846];
Zipcodes['61548'] = [40.814049, -89.420028];
Zipcodes['61550'] = [40.611423, -89.436378];
Zipcodes['61552'] = [40.821419, -89.564892];
Zipcodes['61553'] = [40.621388, -90.026814];
Zipcodes['61554'] = [40.543623, -89.620621];
Zipcodes['61559'] = [40.919017, -89.779205];
Zipcodes['61560'] = [41.187262, -89.41979];
Zipcodes['61561'] = [40.805582, -89.206921];
Zipcodes['61562'] = [40.880988, -89.501449];
Zipcodes['61563'] = [40.49317, -90.051134];
Zipcodes['61564'] = [40.498515, -89.656218];
Zipcodes['61565'] = [41.038714, -89.505036];
Zipcodes['61567'] = [40.373179, -89.901049];
Zipcodes['61568'] = [40.510242, -89.481372];
Zipcodes['61569'] = [40.683232, -89.900619];
Zipcodes['61570'] = [40.914177, -89.323379];
Zipcodes['61571'] = [40.704506, -89.424629];
Zipcodes['61572'] = [40.797187, -90.039721];
Zipcodes['61602'] = [40.679205, -89.605574];
Zipcodes['61603'] = [40.709838, -89.575458];
Zipcodes['61604'] = [40.705247, -89.652921];
Zipcodes['61605'] = [40.676526, -89.633135];
Zipcodes['61606'] = [40.70007, -89.61187];
Zipcodes['61607'] = [40.627132, -89.68513];
Zipcodes['61610'] = [40.642116, -89.59956];
Zipcodes['61611'] = [40.713327, -89.535743];
Zipcodes['61614'] = [40.759136, -89.604123];
Zipcodes['61615'] = [40.748929, -89.672275];
Zipcodes['61616'] = [40.746726, -89.571641];
Zipcodes['61625'] = [40.697698, -89.614572];
Zipcodes['61701'] = [40.475855, -88.99267];
Zipcodes['61704'] = [40.470052, -88.94152];
Zipcodes['61705'] = [40.523132, -89.07201];
Zipcodes['61720'] = [40.5503, -88.502122];
Zipcodes['61721'] = [40.350776, -89.323505];
Zipcodes['61722'] = [40.423434, -88.626319];
Zipcodes['61723'] = [40.264664, -89.276542];
Zipcodes['61724'] = [40.333787, -88.525202];
Zipcodes['61725'] = [40.616502, -89.121678];
Zipcodes['61726'] = [40.733399, -88.707734];
Zipcodes['61727'] = [40.146752, -88.953188];
Zipcodes['61728'] = [40.580622, -88.622264];
Zipcodes['61729'] = [40.628659, -89.221294];
Zipcodes['61730'] = [40.532622, -88.734397];
Zipcodes['61731'] = [40.612579, -88.483885];
Zipcodes['61732'] = [40.529638, -89.208063];
Zipcodes['61733'] = [40.623632, -89.330193];
Zipcodes['61734'] = [40.376442, -89.526224];
Zipcodes['61735'] = [40.205139, -88.80563];
Zipcodes['61736'] = [40.402284, -88.840374];
Zipcodes['61737'] = [40.455719, -88.733223];
Zipcodes['61738'] = [40.745101, -89.030052];
Zipcodes['61739'] = [40.727827, -88.521355];
Zipcodes['61740'] = [40.891933, -88.862761];
Zipcodes['61741'] = [40.762563, -88.396715];
Zipcodes['61742'] = [40.634613, -89.27252];
Zipcodes['61743'] = [40.878048, -88.781428];
Zipcodes['61744'] = [40.743776, -88.881206];
Zipcodes['61745'] = [40.320623, -88.967428];
Zipcodes['61747'] = [40.416122, -89.435257];
Zipcodes['61748'] = [40.622397, -88.996779];
Zipcodes['61749'] = [40.092849, -89.116222];
Zipcodes['61750'] = [40.1225, -88.855673];
Zipcodes['61751'] = [40.214556, -89.299252];
Zipcodes['61752'] = [40.337063, -88.76166];
Zipcodes['61753'] = [40.629744, -88.787974];
Zipcodes['61754'] = [40.328141, -89.161373];
Zipcodes['61755'] = [40.52609, -89.341761];
Zipcodes['61756'] = [40.033122, -88.963689];
Zipcodes['61759'] = [40.437316, -89.336053];
Zipcodes['61760'] = [40.885892, -89.032115];
Zipcodes['61761'] = [40.528032, -88.947633];
Zipcodes['61764'] = [40.883301, -88.625907];
Zipcodes['61769'] = [40.885492, -88.399452];
Zipcodes['61770'] = [40.439518, -88.51077];
Zipcodes['61771'] = [40.713112, -89.147976];
Zipcodes['61772'] = [40.38598, -89.063578];
Zipcodes['61773'] = [40.566429, -88.373678];
Zipcodes['61774'] = [40.432998, -89.195528];
Zipcodes['61775'] = [40.653696, -88.375962];
Zipcodes['61776'] = [40.572114, -88.870139];
Zipcodes['61777'] = [40.244063, -88.945663];
Zipcodes['61778'] = [40.225767, -89.091051];
Zipcodes['61801'] = [40.10952, -88.211031];
Zipcodes['61802'] = [40.123553, -88.153254];
Zipcodes['61810'] = [39.910131, -87.918408];
Zipcodes['61811'] = [40.295327, -87.621656];
Zipcodes['61812'] = [40.209777, -87.926661];
Zipcodes['61813'] = [39.910777, -88.565693];
Zipcodes['61814'] = [40.231924, -87.570194];
Zipcodes['61815'] = [40.110725, -88.367954];
Zipcodes['61816'] = [39.924446, -88.003968];
Zipcodes['61817'] = [40.035275, -87.716283];
Zipcodes['61818'] = [39.886702, -88.716473];
Zipcodes['61820'] = [40.11212, -88.244315];
Zipcodes['61821'] = [40.111099, -88.275533];
Zipcodes['61822'] = [40.133683, -88.302921];
Zipcodes['61830'] = [40.011061, -88.73034];
Zipcodes['61831'] = [40.226196, -87.797019];
Zipcodes['61832'] = [40.13236, -87.632069];
Zipcodes['61833'] = [40.099196, -87.64723];
Zipcodes['61834'] = [40.152546, -87.673298];
Zipcodes['61839'] = [40.131851, -88.636941];
Zipcodes['61840'] = [40.298873, -88.300263];
Zipcodes['61841'] = [40.034151, -87.817937];
Zipcodes['61842'] = [40.238774, -88.66522];
Zipcodes['61843'] = [40.317241, -88.373903];
Zipcodes['61844'] = [40.142967, -87.869207];
Zipcodes['61845'] = [40.373621, -88.396814];
Zipcodes['61846'] = [39.977252, -87.607538];
Zipcodes['61847'] = [40.30559, -88.009824];
Zipcodes['61848'] = [40.306942, -87.700962];
Zipcodes['61849'] = [40.014226, -87.96737];
Zipcodes['61850'] = [39.930087, -87.743452];
Zipcodes['61851'] = [39.96504, -88.435463];
Zipcodes['61852'] = [39.899498, -88.072753];
Zipcodes['61853'] = [40.229857, -88.421002];
Zipcodes['61854'] = [40.199872, -88.529328];
Zipcodes['61855'] = [39.924365, -88.660552];
Zipcodes['61856'] = [40.029305, -88.583656];
Zipcodes['61857'] = [40.11862, -87.843405];
Zipcodes['61858'] = [40.134258, -87.761877];
Zipcodes['61859'] = [40.150428, -87.962905];
Zipcodes['61862'] = [40.306027, -87.958606];
Zipcodes['61863'] = [39.904888, -88.281899];
Zipcodes['61864'] = [39.975064, -88.152723];
Zipcodes['61865'] = [40.302705, -87.802055];
Zipcodes['61866'] = [40.311624, -88.157981];
Zipcodes['61870'] = [39.903218, -87.615621];
Zipcodes['61871'] = [40.191174, -87.979219];
Zipcodes['61872'] = [39.93044, -88.376961];
Zipcodes['61873'] = [40.133896, -88.039122];
Zipcodes['61874'] = [40.054258, -88.266563];
Zipcodes['61875'] = [40.103467, -88.416253];
Zipcodes['61876'] = [39.901327, -87.836174];
Zipcodes['61877'] = [39.992886, -88.075895];
Zipcodes['61878'] = [40.242651, -88.145453];
Zipcodes['61880'] = [39.973795, -88.248906];
Zipcodes['61882'] = [40.10623, -88.754729];
Zipcodes['61883'] = [40.034937, -87.639918];
Zipcodes['61884'] = [40.090615, -88.49107];
Zipcodes['61910'] = [39.671146, -88.297107];
Zipcodes['61911'] = [39.700925, -88.469319];
Zipcodes['61912'] = [39.522381, -88.040843];
Zipcodes['61913'] = [39.826572, -88.471563];
Zipcodes['61914'] = [39.630343, -88.762449];
Zipcodes['61917'] = [39.717434, -87.894093];
Zipcodes['61919'] = [39.784439, -88.13777];
Zipcodes['61920'] = [39.511992, -88.154169];
Zipcodes['61924'] = [39.786183, -87.663252];
Zipcodes['61925'] = [39.719105, -88.840528];
Zipcodes['61928'] = [39.455959, -88.498197];
Zipcodes['61929'] = [39.824789, -88.625797];
Zipcodes['61930'] = [39.679328, -88.129];
Zipcodes['61931'] = [39.586143, -88.368055];
Zipcodes['61932'] = [39.799669, -87.874909];
Zipcodes['61933'] = [39.548469, -87.896313];
Zipcodes['61936'] = [39.803188, -88.726844];
Zipcodes['61937'] = [39.733828, -88.657314];
Zipcodes['61938'] = [39.479932, -88.37585];
Zipcodes['61940'] = [39.803938, -87.812475];
Zipcodes['61941'] = [39.804583, -88.075037];
Zipcodes['61942'] = [39.814412, -88.003205];
Zipcodes['61943'] = [39.661522, -88.023744];
Zipcodes['61944'] = [39.599796, -87.702066];
Zipcodes['61949'] = [39.657567, -87.858415];
Zipcodes['61951'] = [39.595821, -88.590378];
Zipcodes['61953'] = [39.797652, -88.290629];
Zipcodes['61955'] = [39.581626, -87.587505];
Zipcodes['61956'] = [39.85819, -88.135009];
Zipcodes['61957'] = [39.441804, -88.611557];
Zipcodes['62001'] = [38.881431, -89.745011];
Zipcodes['62002'] = [38.939234, -90.134948];
Zipcodes['62006'] = [39.064316, -90.678987];
Zipcodes['62009'] = [39.093581, -89.798129];
Zipcodes['62010'] = [38.919081, -90.048335];
Zipcodes['62011'] = [39.128667, -89.217556];
Zipcodes['62012'] = [39.044764, -90.148254];
Zipcodes['62013'] = [38.956374, -90.57358];
Zipcodes['62014'] = [39.043373, -89.950356];
Zipcodes['62015'] = [39.212268, -89.544222];
Zipcodes['62016'] = [39.29683, -90.415521];
Zipcodes['62017'] = [39.065108, -89.368607];
Zipcodes['62018'] = [38.913876, -90.081863];
Zipcodes['62019'] = [39.018669, -89.441465];
Zipcodes['62021'] = [38.983648, -89.976563];
Zipcodes['62022'] = [39.003161, -90.32618];
Zipcodes['62023'] = [39.108699, -89.779025];
Zipcodes['62024'] = [38.883132, -90.08995];
Zipcodes['62025'] = [38.829908, -89.931046];
Zipcodes['62026'] = [38.789441, -90.003005];
Zipcodes['62027'] = [39.243387, -90.557406];
Zipcodes['62028'] = [38.958193, -90.354834];
Zipcodes['62030'] = [39.154609, -90.164457];
Zipcodes['62031'] = [39.111496, -90.53693];
Zipcodes['62032'] = [39.115814, -89.288315];
Zipcodes['62033'] = [39.136563, -89.839916];
Zipcodes['62034'] = [38.754873, -89.972216];
Zipcodes['62035'] = [38.958664, -90.242706];
Zipcodes['62036'] = [38.918311, -90.589735];
Zipcodes['62037'] = [39.010943, -90.465066];
Zipcodes['62040'] = [38.714928, -90.09893];
Zipcodes['62044'] = [39.365787, -90.202032];
Zipcodes['62045'] = [39.264194, -90.690137];
Zipcodes['62046'] = [38.892463, -89.838728];
Zipcodes['62047'] = [39.123135, -90.626568];
Zipcodes['62048'] = [38.772852, -90.13716];
Zipcodes['62049'] = [39.141039, -89.47951];
Zipcodes['62050'] = [39.442243, -90.573308];
Zipcodes['62051'] = [39.20165, -89.403275];
Zipcodes['62052'] = [39.112832, -90.319526];
Zipcodes['62053'] = [39.319254, -90.66542];
Zipcodes['62054'] = [39.207474, -90.350632];
Zipcodes['62056'] = [39.175595, -89.674359];
Zipcodes['62058'] = [38.968437, -89.764607];
Zipcodes['62059'] = [38.653126, -90.173352];
Zipcodes['62060'] = [38.681805, -90.142416];
Zipcodes['62061'] = [38.790684, -89.776379];
Zipcodes['62062'] = [38.725658, -89.963126];
Zipcodes['62063'] = [39.198525, -90.149861];
Zipcodes['62067'] = [38.929024, -89.983292];
Zipcodes['62069'] = [39.089106, -89.739298];
Zipcodes['62074'] = [38.955001, -89.689739];
Zipcodes['62075'] = [39.302086, -89.295837];
Zipcodes['62076'] = [39.341875, -89.233793];
Zipcodes['62077'] = [39.031822, -89.511541];
Zipcodes['62078'] = [39.47985, -90.486842];
Zipcodes['62079'] = [39.108389, -90.131819];
Zipcodes['62080'] = [39.141918, -89.1031];
Zipcodes['62081'] = [39.264927, -90.225];
Zipcodes['62082'] = [39.489989, -90.317508];
Zipcodes['62083'] = [39.351192, -89.198408];
Zipcodes['62084'] = [38.840697, -90.063424];
Zipcodes['62085'] = [39.0819, -89.804504];
Zipcodes['62086'] = [38.989931, -89.589606];
Zipcodes['62087'] = [38.814811, -90.071029];
Zipcodes['62088'] = [39.014677, -89.798527];
Zipcodes['62089'] = [39.117829, -89.486654];
Zipcodes['62090'] = [38.667097, -90.166525];
Zipcodes['62091'] = [39.056246, -89.610567];
Zipcodes['62092'] = [39.418572, -90.431226];
Zipcodes['62093'] = [39.064746, -89.841993];
Zipcodes['62094'] = [39.238645, -89.349827];
Zipcodes['62095'] = [38.862573, -90.074295];
Zipcodes['62097'] = [38.936676, -89.852687];
Zipcodes['62098'] = [39.383756, -90.29943];
Zipcodes['62201'] = [38.635131, -90.140711];
Zipcodes['62203'] = [38.601691, -90.075971];
Zipcodes['62204'] = [38.629486, -90.095925];
Zipcodes['62205'] = [38.602768, -90.118561];
Zipcodes['62206'] = [38.56793, -90.16933];
Zipcodes['62207'] = [38.582893, -90.125415];
Zipcodes['62208'] = [38.595341, -90.004735];
Zipcodes['62214'] = [38.376365, -89.603686];
Zipcodes['62215'] = [38.507849, -89.600785];
Zipcodes['62216'] = [38.632462, -89.602359];
Zipcodes['62217'] = [38.173836, -89.838573];
Zipcodes['62218'] = [38.522194, -89.473978];
Zipcodes['62219'] = [38.603613, -89.437167];
Zipcodes['62220'] = [38.478277, -89.998032];
Zipcodes['62221'] = [38.511713, -89.902416];
Zipcodes['62223'] = [38.535923, -90.059477];
Zipcodes['62225'] = [38.542974, -89.852127];
Zipcodes['62226'] = [38.536763, -90.000344];
Zipcodes['62230'] = [38.635005, -89.530995];
Zipcodes['62231'] = [38.653115, -89.326853];
Zipcodes['62232'] = [38.632444, -90.00545];
Zipcodes['62233'] = [37.942252, -89.788551];
Zipcodes['62234'] = [38.682181, -89.980621];
Zipcodes['62236'] = [38.433901, -90.211941];
Zipcodes['62237'] = [38.197835, -89.569294];
Zipcodes['62238'] = [38.052604, -89.543786];
Zipcodes['62239'] = [38.524614, -90.156695];
Zipcodes['62240'] = [38.501857, -90.176557];
Zipcodes['62241'] = [38.011185, -89.893884];
Zipcodes['62242'] = [38.098949, -89.937731];
Zipcodes['62243'] = [38.419053, -89.8894];
Zipcodes['62244'] = [38.195693, -90.198775];
Zipcodes['62245'] = [38.531041, -89.575162];
Zipcodes['62246'] = [38.890366, -89.427577];
Zipcodes['62248'] = [38.30589, -89.992855];
Zipcodes['62249'] = [38.755333, -89.667323];
Zipcodes['62250'] = [38.540493, -89.264342];
Zipcodes['62253'] = [38.777732, -89.300085];
Zipcodes['62254'] = [38.609368, -89.822871];
Zipcodes['62255'] = [38.313204, -89.783121];
Zipcodes['62256'] = [38.226376, -90.231493];
Zipcodes['62257'] = [38.284874, -89.728709];
Zipcodes['62258'] = [38.460897, -89.782081];
Zipcodes['62260'] = [38.460929, -90.098634];
Zipcodes['62261'] = [38.006752, -89.997271];
Zipcodes['62262'] = [38.939413, -89.263839];
Zipcodes['62263'] = [38.318988, -89.401159];
Zipcodes['62264'] = [38.315878, -89.899945];
Zipcodes['62265'] = [38.506152, -89.680732];
Zipcodes['62266'] = [38.484804, -89.6573];
Zipcodes['62268'] = [38.276295, -89.539572];
Zipcodes['62269'] = [38.600006, -89.915402];
Zipcodes['62271'] = [38.440109, -89.520464];
Zipcodes['62272'] = [37.996234, -89.616433];
Zipcodes['62273'] = [38.78684, -89.583768];
Zipcodes['62274'] = [38.091163, -89.40027];
Zipcodes['62275'] = [38.814556, -89.547072];
Zipcodes['62277'] = [38.096972, -90.112198];
Zipcodes['62278'] = [38.20377, -89.991304];
Zipcodes['62279'] = [38.133836, -90.135803];
Zipcodes['62280'] = [37.846621, -89.648009];
Zipcodes['62281'] = [38.70688, -89.779355];
Zipcodes['62282'] = [38.356433, -89.71286];
Zipcodes['62284'] = [38.864144, -89.310441];
Zipcodes['62285'] = [38.392279, -90.008393];
Zipcodes['62286'] = [38.116958, -89.717653];
Zipcodes['62288'] = [37.993373, -89.68961];
Zipcodes['62289'] = [38.597636, -89.751278];
Zipcodes['62292'] = [38.213149, -89.681962];
Zipcodes['62293'] = [38.616002, -89.695483];
Zipcodes['62294'] = [38.702562, -89.867485];
Zipcodes['62295'] = [38.271875, -90.310764];
Zipcodes['62297'] = [38.052121, -89.806332];
Zipcodes['62298'] = [38.308842, -90.149413];
Zipcodes['62301'] = [39.931155, -91.388714];
Zipcodes['62305'] = [39.928192, -91.343933];
Zipcodes['62311'] = [40.222576, -90.935227];
Zipcodes['62312'] = [39.707275, -91.024203];
Zipcodes['62313'] = [40.317726, -91.228289];
Zipcodes['62314'] = [39.767988, -90.891698];
Zipcodes['62316'] = [40.235474, -91.053025];
Zipcodes['62319'] = [40.15292, -90.73001];
Zipcodes['62320'] = [40.030454, -91.082935];
Zipcodes['62321'] = [40.409716, -91.095432];
Zipcodes['62323'] = [39.800419, -90.674455];
Zipcodes['62324'] = [39.998277, -90.966328];
Zipcodes['62325'] = [40.045624, -91.162046];
Zipcodes['62326'] = [40.422789, -90.813562];
Zipcodes['62330'] = [40.571437, -91.125784];
Zipcodes['62334'] = [40.395595, -91.248792];
Zipcodes['62336'] = [40.467551, -91.173341];
Zipcodes['62338'] = [39.998692, -91.237201];
Zipcodes['62339'] = [40.128504, -91.0381];
Zipcodes['62340'] = [39.734718, -90.728238];
Zipcodes['62341'] = [40.43343, -91.300936];
Zipcodes['62343'] = [39.696603, -91.245013];
Zipcodes['62344'] = [40.179891, -90.824965];
Zipcodes['62345'] = [39.697299, -91.112564];
Zipcodes['62346'] = [40.139846, -90.936403];
Zipcodes['62347'] = [39.870093, -91.088955];
Zipcodes['62348'] = [40.188341, -91.371244];
Zipcodes['62349'] = [40.170546, -91.191859];
Zipcodes['62351'] = [40.113608, -91.268867];
Zipcodes['62352'] = [39.568163, -90.654562];
Zipcodes['62353'] = [39.973028, -90.757546];
Zipcodes['62354'] = [40.517396, -91.342092];
Zipcodes['62355'] = [39.412432, -90.778724];
Zipcodes['62356'] = [39.588873, -91.07967];
Zipcodes['62357'] = [39.68582, -90.867067];
Zipcodes['62358'] = [40.587804, -91.263164];
Zipcodes['62359'] = [40.037373, -91.212083];
Zipcodes['62360'] = [39.817324, -91.261413];
Zipcodes['62361'] = [39.492118, -90.621524];
Zipcodes['62362'] = [39.776193, -90.764319];
Zipcodes['62363'] = [39.590496, -90.769665];
Zipcodes['62365'] = [39.796408, -91.165114];
Zipcodes['62366'] = [39.472086, -90.901821];
Zipcodes['62367'] = [40.299887, -90.865155];
Zipcodes['62370'] = [39.517293, -91.007228];
Zipcodes['62373'] = [40.242748, -91.364953];
Zipcodes['62374'] = [40.407678, -90.910538];
Zipcodes['62375'] = [40.002127, -90.857282];
Zipcodes['62376'] = [40.113632, -91.407737];
Zipcodes['62378'] = [39.905859, -90.634784];
Zipcodes['62379'] = [40.269272, -91.403368];
Zipcodes['62380'] = [40.23823, -91.190901];
Zipcodes['62401'] = [39.118715, -88.562384];
Zipcodes['62410'] = [38.5388, -87.733213];
Zipcodes['62411'] = [39.071265, -88.733641];
Zipcodes['62413'] = [39.138821, -87.837837];
Zipcodes['62414'] = [39.174446, -88.826372];
Zipcodes['62417'] = [38.703351, -87.765689];
Zipcodes['62418'] = [39.022272, -88.973758];
Zipcodes['62419'] = [38.626535, -87.980115];
Zipcodes['62420'] = [39.287028, -87.998551];
Zipcodes['62421'] = [38.752142, -87.943453];
Zipcodes['62422'] = [39.233282, -88.888586];
Zipcodes['62423'] = [39.462687, -87.57989];
Zipcodes['62424'] = [38.988265, -88.428594];
Zipcodes['62425'] = [38.836831, -88.098691];
Zipcodes['62426'] = [38.892329, -88.664014];
Zipcodes['62427'] = [38.873127, -87.649864];
Zipcodes['62428'] = [39.262654, -88.136713];
Zipcodes['62431'] = [39.225294, -88.996541];
Zipcodes['62432'] = [39.133517, -88.141763];
Zipcodes['62433'] = [39.106742, -87.715756];
Zipcodes['62434'] = [38.842047, -88.321795];
Zipcodes['62436'] = [39.182449, -88.256092];
Zipcodes['62438'] = [39.325541, -88.870056];
Zipcodes['62439'] = [38.744062, -87.639699];
Zipcodes['62440'] = [39.39431, -88.26325];
Zipcodes['62441'] = [39.394116, -87.698696];
Zipcodes['62442'] = [39.303946, -87.853807];
Zipcodes['62443'] = [38.955761, -88.631112];
Zipcodes['62444'] = [39.278519, -88.734717];
Zipcodes['62445'] = [39.165285, -88.330174];
Zipcodes['62446'] = [38.489913, -88.206714];
Zipcodes['62447'] = [39.313232, -88.444715];
Zipcodes['62448'] = [38.968356, -88.179181];
Zipcodes['62449'] = [39.004119, -87.91063];
Zipcodes['62450'] = [38.713171, -88.089293];
Zipcodes['62451'] = [38.971206, -87.611114];
Zipcodes['62452'] = [38.590061, -88.014703];
Zipcodes['62454'] = [38.992348, -87.764978];
Zipcodes['62458'] = [39.025807, -88.854507];
Zipcodes['62459'] = [38.929977, -88.027782];
Zipcodes['62460'] = [38.601695, -87.727208];
Zipcodes['62461'] = [39.194169, -88.684349];
Zipcodes['62462'] = [39.238766, -88.468514];
Zipcodes['62463'] = [39.280639, -88.59123];
Zipcodes['62464'] = [38.997956, -87.82379];
Zipcodes['62465'] = [39.346135, -88.638265];
Zipcodes['62466'] = [38.728414, -87.852631];
Zipcodes['62467'] = [39.128079, -88.435402];
Zipcodes['62468'] = [39.276946, -88.270309];
Zipcodes['62469'] = [39.351236, -88.333827];
Zipcodes['62471'] = [38.958929, -89.130198];
Zipcodes['62473'] = [39.021484, -88.567904];
Zipcodes['62474'] = [39.456917, -88.035856];
Zipcodes['62475'] = [38.881919, -88.048614];
Zipcodes['62476'] = [38.532205, -88.015447];
Zipcodes['62477'] = [39.233553, -87.643438];
Zipcodes['62478'] = [39.179972, -87.743616];
Zipcodes['62479'] = [39.037041, -88.306897];
Zipcodes['62480'] = [38.997392, -87.999575];
Zipcodes['62481'] = [39.126601, -88.022953];
Zipcodes['62501'] = [39.926572, -88.814353];
Zipcodes['62510'] = [39.530665, -89.028916];
Zipcodes['62512'] = [40.143468, -89.210602];
Zipcodes['62513'] = [39.721278, -89.150274];
Zipcodes['62514'] = [39.767497, -89.045007];
Zipcodes['62515'] = [39.860176, -89.365875];
Zipcodes['62517'] = [39.59421, -89.433251];
Zipcodes['62518'] = [40.051402, -89.203009];
Zipcodes['62519'] = [39.928801, -89.395313];
Zipcodes['62520'] = [39.824552, -89.456341];
Zipcodes['62521'] = [39.81017, -88.927784];
Zipcodes['62522'] = [39.827583, -89.0478];
Zipcodes['62523'] = [39.843721, -88.952944];
Zipcodes['62526'] = [39.901709, -88.989191];
Zipcodes['62530'] = [39.560144, -89.656576];
Zipcodes['62531'] = [39.674378, -89.37756];
Zipcodes['62532'] = [39.776207, -88.977597];
Zipcodes['62533'] = [39.44586, -89.629816];
Zipcodes['62534'] = [39.529684, -88.800594];
Zipcodes['62535'] = [39.925511, -88.96604];
Zipcodes['62536'] = [39.629019, -89.652265];
Zipcodes['62537'] = [39.852606, -89.09325];
Zipcodes['62538'] = [39.356306, -89.520465];
Zipcodes['62539'] = [39.866801, -89.249159];
Zipcodes['62540'] = [39.584873, -89.419855];
Zipcodes['62541'] = [39.956556, -89.370868];
Zipcodes['62543'] = [39.970816, -89.151833];
Zipcodes['62544'] = [39.70855, -88.973742];
Zipcodes['62545'] = [39.767754, -89.384972];
Zipcodes['62546'] = [39.431455, -89.445923];
Zipcodes['62547'] = [39.770482, -89.242253];
Zipcodes['62548'] = [39.981254, -89.283539];
Zipcodes['62549'] = [39.771029, -88.867155];
Zipcodes['62550'] = [39.617876, -88.989628];
Zipcodes['62551'] = [39.854146, -89.155878];
Zipcodes['62553'] = [39.271818, -89.105098];
Zipcodes['62554'] = [39.944482, -88.882274];
Zipcodes['62555'] = [39.46298, -89.210466];
Zipcodes['62556'] = [39.474974, -89.375213];
Zipcodes['62557'] = [39.401766, -89.105904];
Zipcodes['62558'] = [39.586712, -89.549853];
Zipcodes['62560'] = [39.29863, -89.602818];
Zipcodes['62561'] = [39.871116, -89.506726];
Zipcodes['62563'] = [39.719259, -89.496252];
Zipcodes['62565'] = [39.416775, -88.803851];
Zipcodes['62567'] = [39.646674, -89.190804];
Zipcodes['62568'] = [39.559732, -89.287031];
Zipcodes['62570'] = [39.578559, -89.451718];
Zipcodes['62571'] = [39.381008, -88.960225];
Zipcodes['62572'] = [39.359076, -89.697767];
Zipcodes['62573'] = [39.945515, -89.072649];
Zipcodes['62601'] = [39.750251, -90.039027];
Zipcodes['62610'] = [39.538883, -90.446226];
Zipcodes['62611'] = [39.884116, -90.398177];
Zipcodes['62612'] = [39.896048, -90.063158];
Zipcodes['62613'] = [39.98262, -89.665216];
Zipcodes['62615'] = [39.585684, -89.754068];
Zipcodes['62617'] = [40.156822, -90.16989];
Zipcodes['62618'] = [40.008414, -90.397111];
Zipcodes['62621'] = [39.72672, -90.52058];
Zipcodes['62622'] = [39.977864, -90.34305];
Zipcodes['62624'] = [40.15929, -90.346784];
Zipcodes['62625'] = [39.901203, -89.701769];
Zipcodes['62626'] = [39.28461, -89.883614];
Zipcodes['62627'] = [40.059128, -90.111077];
Zipcodes['62628'] = [39.7794, -90.402013];
Zipcodes['62629'] = [39.677477, -89.716922];
Zipcodes['62630'] = [39.27113, -90.086348];
Zipcodes['62631'] = [39.827417, -90.354199];
Zipcodes['62633'] = [40.22354, -89.870793];
Zipcodes['62634'] = [40.013776, -89.451557];
Zipcodes['62635'] = [40.296538, -89.465729];
Zipcodes['62638'] = [39.609301, -90.081236];
Zipcodes['62639'] = [40.03767, -90.486012];
Zipcodes['62640'] = [39.428269, -89.80498];
Zipcodes['62642'] = [40.093598, -89.724853];
Zipcodes['62643'] = [40.241528, -89.457767];
Zipcodes['62644'] = [40.285418, -90.061898];
Zipcodes['62649'] = [39.357549, -90.060042];
Zipcodes['62650'] = [39.738301, -90.220141];
Zipcodes['62655'] = [40.169931, -89.986537];
Zipcodes['62656'] = [40.139677, -89.366192];
Zipcodes['62661'] = [39.668004, -89.826974];
Zipcodes['62663'] = [39.541057, -90.327474];
Zipcodes['62664'] = [40.212562, -89.724144];
Zipcodes['62665'] = [39.816949, -90.529555];
Zipcodes['62666'] = [40.082439, -89.539596];
Zipcodes['62667'] = [39.487765, -89.992297];
Zipcodes['62668'] = [39.565929, -90.240289];
Zipcodes['62670'] = [39.743479, -89.898578];
Zipcodes['62671'] = [40.172249, -89.549117];
Zipcodes['62672'] = [39.398009, -89.804455];
Zipcodes['62673'] = [40.097105, -89.9688];
Zipcodes['62674'] = [39.441118, -90.023313];
Zipcodes['62675'] = [40.026477, -89.867144];
Zipcodes['62677'] = [39.852668, -89.879391];
Zipcodes['62681'] = [40.136241, -90.542754];
Zipcodes['62682'] = [40.298725, -89.630971];
Zipcodes['62684'] = [39.909142, -89.586759];
Zipcodes['62685'] = [39.139898, -89.991836];
Zipcodes['62688'] = [39.944993, -89.924125];
Zipcodes['62689'] = [39.534044, -89.765721];
Zipcodes['62690'] = [39.505643, -89.766163];
Zipcodes['62691'] = [39.958491, -90.228819];
Zipcodes['62692'] = [39.583553, -89.939164];
Zipcodes['62693'] = [39.958048, -89.516172];
Zipcodes['62694'] = [39.62278, -90.477118];
Zipcodes['62695'] = [39.619374, -90.224197];
Zipcodes['62701'] = [39.800544, -89.648894];
Zipcodes['62702'] = [39.824222, -89.64273];
Zipcodes['62703'] = [39.763035, -89.631099];
Zipcodes['62704'] = [39.773498, -89.68384];
Zipcodes['62706'] = [39.797886, -89.655398];
Zipcodes['62707'] = [39.855571, -89.650079];
Zipcodes['62711'] = [39.766136, -89.732756];
Zipcodes['62712'] = [39.748105, -89.581559];
Zipcodes['62801'] = [38.511022, -89.140448];
Zipcodes['62803'] = [38.453336, -89.294973];
Zipcodes['62806'] = [38.387363, -88.086971];
Zipcodes['62807'] = [38.751557, -88.929233];
Zipcodes['62808'] = [38.30618, -89.188699];
Zipcodes['62809'] = [38.278964, -88.337811];
Zipcodes['62810'] = [38.205899, -88.743061];
Zipcodes['62811'] = [38.387361, -87.903604];
Zipcodes['62812'] = [38.006432, -88.92252];
Zipcodes['62814'] = [38.378397, -88.731915];
Zipcodes['62815'] = [38.458352, -87.966441];
Zipcodes['62816'] = [38.189787, -88.922971];
Zipcodes['62817'] = [37.954474, -88.476107];
Zipcodes['62818'] = [38.377679, -87.967518];
Zipcodes['62819'] = [37.974472, -89.004769];
Zipcodes['62820'] = [38.248629, -88.235358];
Zipcodes['62821'] = [38.071022, -88.129673];
Zipcodes['62822'] = [37.973972, -89.054102];
Zipcodes['62823'] = [38.517707, -88.437321];
Zipcodes['62824'] = [38.669458, -88.341676];
Zipcodes['62825'] = [37.998152, -89.05902];
Zipcodes['62827'] = [38.170656, -88.047697];
Zipcodes['62828'] = [38.194532, -88.601576];
Zipcodes['62829'] = [38.003336, -88.490961];
Zipcodes['62830'] = [38.443409, -88.967632];
Zipcodes['62831'] = [38.23495, -89.222731];
Zipcodes['62832'] = [38.00859, -89.252759];
Zipcodes['62833'] = [38.340234, -88.167646];
Zipcodes['62835'] = [38.106046, -88.321669];
Zipcodes['62836'] = [38.094569, -88.805455];
Zipcodes['62837'] = [38.36518, -88.357724];
Zipcodes['62838'] = [38.864237, -88.745462];
Zipcodes['62839'] = [38.660786, -88.49381];
Zipcodes['62841'] = [37.858686, -88.996437];
Zipcodes['62842'] = [38.467921, -88.389471];
Zipcodes['62843'] = [38.38221, -88.199061];
Zipcodes['62844'] = [38.251442, -88.048528];
Zipcodes['62846'] = [38.150884, -88.855063];
Zipcodes['62848'] = [38.434045, -89.166391];
Zipcodes['62849'] = [38.58494, -88.773698];
Zipcodes['62850'] = [38.494727, -88.59482];
Zipcodes['62851'] = [38.427545, -88.659954];
Zipcodes['62852'] = [38.349942, -87.866757];
Zipcodes['62853'] = [38.516107, -88.912193];
Zipcodes['62854'] = [38.76451, -88.824003];
Zipcodes['62856'] = [37.952271, -88.838385];
Zipcodes['62858'] = [38.811813, -88.49295];
Zipcodes['62859'] = [38.066004, -88.539666];
Zipcodes['62860'] = [38.041367, -88.731934];
Zipcodes['62861'] = [38.035177, -88.045277];
Zipcodes['62862'] = [38.219114, -88.299337];
Zipcodes['62863'] = [38.415849, -87.85988];
Zipcodes['62864'] = [38.330513, -88.902367];
Zipcodes['62865'] = [37.974942, -89.082497];
Zipcodes['62867'] = [37.912235, -88.098929];
Zipcodes['62868'] = [38.67771, -88.228125];
Zipcodes['62869'] = [37.9566, -88.277773];
Zipcodes['62870'] = [38.635153, -89.053057];
Zipcodes['62871'] = [37.882055, -88.269243];
Zipcodes['62872'] = [38.271585, -88.800672];
Zipcodes['62874'] = [37.917551, -88.978331];
Zipcodes['62875'] = [38.755451, -89.121169];
Zipcodes['62876'] = [38.277107, -89.194829];
Zipcodes['62877'] = [38.392467, -89.19963];
Zipcodes['62878'] = [38.575036, -88.4966];
Zipcodes['62879'] = [38.765021, -88.359032];
Zipcodes['62880'] = [38.867518, -88.885942];
Zipcodes['62881'] = [38.638551, -88.927259];
Zipcodes['62882'] = [38.636254, -89.118646];
Zipcodes['62883'] = [38.168038, -89.104683];
Zipcodes['62884'] = [38.085286, -89.042349];
Zipcodes['62885'] = [38.860481, -89.064583];
Zipcodes['62886'] = [38.378661, -88.534706];
Zipcodes['62887'] = [38.186329, -88.369035];
Zipcodes['62888'] = [38.127458, -89.228979];
Zipcodes['62889'] = [38.456539, -88.823598];
Zipcodes['62890'] = [37.906205, -88.737966];
Zipcodes['62891'] = [38.016005, -89.041198];
Zipcodes['62892'] = [38.808565, -89.07566];
Zipcodes['62893'] = [38.465816, -89.030176];
Zipcodes['62894'] = [38.209538, -89.039635];
Zipcodes['62895'] = [38.305545, -88.555138];
Zipcodes['62896'] = [37.892664, -88.912076];
Zipcodes['62897'] = [38.093889, -88.911821];
Zipcodes['62898'] = [38.350379, -89.060166];
Zipcodes['62899'] = [38.618912, -88.642448];
Zipcodes['62901'] = [37.740824, -89.208976];
Zipcodes['62902'] = [37.674381, -89.112452];
Zipcodes['62903'] = [37.674118, -89.276234];
Zipcodes['62905'] = [37.55771, -89.367362];
Zipcodes['62906'] = [37.462889, -89.177551];
Zipcodes['62907'] = [37.863159, -89.495706];
Zipcodes['62908'] = [37.310226, -88.864901];
Zipcodes['62910'] = [37.153109, -88.534459];
Zipcodes['62912'] = [37.476199, -89.032362];
Zipcodes['62914'] = [37.040124, -89.204316];
Zipcodes['62915'] = [37.784449, -89.122134];
Zipcodes['62916'] = [37.943305, -89.578759];
Zipcodes['62917'] = [37.693416, -88.651628];
Zipcodes['62918'] = [37.786372, -89.086175];
Zipcodes['62919'] = [37.524023, -88.138368];
Zipcodes['62920'] = [37.553584, -89.232109];
Zipcodes['62921'] = [37.798612, -89.082777];
Zipcodes['62922'] = [37.620754, -88.814807];
Zipcodes['62923'] = [37.342143, -89.035402];
Zipcodes['62924'] = [37.815148, -89.195549];
Zipcodes['62926'] = [37.366678, -89.131409];
Zipcodes['62927'] = [37.943544, -89.251992];
Zipcodes['62928'] = [37.475405, -88.567239];
Zipcodes['62930'] = [37.847199, -88.441577];
Zipcodes['62931'] = [37.523685, -88.275056];
Zipcodes['62932'] = [37.892867, -89.213656];
Zipcodes['62933'] = [37.772369, -89.01965];
Zipcodes['62934'] = [37.707239, -88.358633];
Zipcodes['62935'] = [37.844774, -88.638638];
Zipcodes['62938'] = [37.384128, -88.545966];
Zipcodes['62939'] = [37.546199, -88.976711];
Zipcodes['62940'] = [37.731566, -89.460578];
Zipcodes['62941'] = [37.244528, -88.970223];
Zipcodes['62942'] = [37.635784, -89.473569];
Zipcodes['62943'] = [37.356264, -88.746097];
Zipcodes['62946'] = [37.700824, -88.520323];
Zipcodes['62947'] = [37.528691, -88.453991];
Zipcodes['62948'] = [37.819917, -89.02596];
Zipcodes['62949'] = [37.827923, -89.144607];
Zipcodes['62950'] = [37.746447, -89.560711];
Zipcodes['62951'] = [37.825691, -88.927343];
Zipcodes['62952'] = [37.417455, -89.34345];
Zipcodes['62953'] = [37.209221, -88.847034];
Zipcodes['62954'] = [37.696253, -88.273377];
Zipcodes['62956'] = [37.279978, -88.931355];
Zipcodes['62957'] = [37.312496, -89.426131];
Zipcodes['62958'] = [37.610662, -89.142212];
Zipcodes['62959'] = [37.711169, -88.914627];
Zipcodes['62960'] = [37.233516, -88.724007];
Zipcodes['62961'] = [37.340904, -89.253148];
Zipcodes['62962'] = [37.07536, -89.335889];
Zipcodes['62963'] = [37.094559, -89.169921];
Zipcodes['62964'] = [37.130321, -89.226576];
Zipcodes['62965'] = [37.767341, -88.518311];
Zipcodes['62966'] = [37.771161, -89.353317];
Zipcodes['62967'] = [37.584276, -88.735808];
Zipcodes['62969'] = [37.139667, -89.333679];
Zipcodes['62970'] = [37.221461, -89.108584];
Zipcodes['62972'] = [37.540668, -88.816118];
Zipcodes['62974'] = [37.779479, -88.796733];
Zipcodes['62975'] = [37.640592, -89.371224];
Zipcodes['62976'] = [37.21984, -89.2127];
Zipcodes['62977'] = [37.853186, -88.541876];
Zipcodes['62979'] = [37.811478, -88.233629];
Zipcodes['62982'] = [37.424825, -88.365319];
Zipcodes['62983'] = [37.89931, -89.104813];
Zipcodes['62984'] = [37.754002, -88.146249];
Zipcodes['62985'] = [37.467521, -88.685895];
Zipcodes['62987'] = [37.618725, -88.660351];
Zipcodes['62988'] = [37.226148, -89.294708];
Zipcodes['62990'] = [37.228582, -89.398842];
Zipcodes['62992'] = [37.276105, -89.141909];
Zipcodes['62994'] = [37.906286, -89.347378];
Zipcodes['62995'] = [37.419276, -88.879938];
Zipcodes['62996'] = [37.17514, -89.155786];
Zipcodes['62997'] = [37.983572, -89.590535];
Zipcodes['62998'] = [37.512071, -89.458147];
Zipcodes['62999'] = [37.869529, -89.056407];
Zipcodes['63005'] = [38.644214, -90.651407];
Zipcodes['63010'] = [38.430371, -90.3921];
Zipcodes['63011'] = [38.605173, -90.557679];
Zipcodes['63012'] = [38.347377, -90.453537];
Zipcodes['63013'] = [38.405246, -91.152562];
Zipcodes['63014'] = [38.651805, -91.313445];
Zipcodes['63015'] = [38.39591, -90.751976];
Zipcodes['63016'] = [38.35655, -90.650978];
Zipcodes['63017'] = [38.656285, -90.541867];
Zipcodes['63019'] = [38.231258, -90.37501];
Zipcodes['63020'] = [38.10715, -90.571596];
Zipcodes['63021'] = [38.56984, -90.545689];
Zipcodes['63023'] = [38.26863, -90.698824];
Zipcodes['63025'] = [38.490877, -90.615713];
Zipcodes['63026'] = [38.50272, -90.458936];
Zipcodes['63028'] = [38.142859, -90.39212];
Zipcodes['63030'] = [38.143218, -90.752184];
Zipcodes['63031'] = [38.812046, -90.35362];
Zipcodes['63033'] = [38.798443, -90.274206];
Zipcodes['63034'] = [38.845391, -90.288495];
Zipcodes['63036'] = [37.976284, -90.372096];
Zipcodes['63037'] = [38.422437, -91.319529];
Zipcodes['63038'] = [38.577073, -90.667807];
Zipcodes['63039'] = [38.491673, -90.843735];
Zipcodes['63040'] = [38.573668, -90.634357];
Zipcodes['63041'] = [38.285959, -90.773624];
Zipcodes['63042'] = [38.781326, -90.37908];
Zipcodes['63043'] = [38.729228, -90.45499];
Zipcodes['63044'] = [38.772903, -90.429784];
Zipcodes['63045'] = [38.769628, -90.464026];
Zipcodes['63047'] = [38.195501, -90.487417];
Zipcodes['63048'] = [38.260366, -90.392425];
Zipcodes['63049'] = [38.477962, -90.528642];
Zipcodes['63050'] = [38.258066, -90.57979];
Zipcodes['63051'] = [38.377777, -90.576635];
Zipcodes['63052'] = [38.393026, -90.432576];
Zipcodes['63053'] = [38.366493, -90.363972];
Zipcodes['63055'] = [38.529619, -90.835038];
Zipcodes['63056'] = [38.399617, -91.215];
Zipcodes['63060'] = [38.260585, -90.875984];
Zipcodes['63061'] = [38.273403, -90.810499];
Zipcodes['63068'] = [38.565884, -91.244062];
Zipcodes['63069'] = [38.463537, -90.709983];
Zipcodes['63070'] = [38.287631, -90.421202];
Zipcodes['63071'] = [38.150005, -90.82112];
Zipcodes['63072'] = [38.369499, -90.817766];
Zipcodes['63073'] = [38.584692, -90.769496];
Zipcodes['63074'] = [38.726366, -90.38892];
Zipcodes['63077'] = [38.329039, -90.984466];
Zipcodes['63079'] = [38.266315, -91.100066];
Zipcodes['63080'] = [38.214096, -91.078109];
Zipcodes['63084'] = [38.4219, -91.019289];
Zipcodes['63087'] = [38.005068, -90.463566];
Zipcodes['63088'] = [38.548627, -90.501705];
Zipcodes['63089'] = [38.460533, -90.878903];
Zipcodes['63090'] = [38.535474, -91.048733];
Zipcodes['63091'] = [38.362896, -91.387251];
Zipcodes['63101'] = [38.631397, -90.192753];
Zipcodes['63102'] = [38.634489, -90.186258];
Zipcodes['63103'] = [38.629824, -90.217052];
Zipcodes['63104'] = [38.611128, -90.214087];
Zipcodes['63105'] = [38.644292, -90.32807];
Zipcodes['63106'] = [38.644424, -90.207972];
Zipcodes['63107'] = [38.663685, -90.211992];
Zipcodes['63108'] = [38.64485, -90.253405];
Zipcodes['63109'] = [38.584601, -90.294432];
Zipcodes['63110'] = [38.625369, -90.266467];
Zipcodes['63111'] = [38.558084, -90.250008];
Zipcodes['63112'] = [38.659923, -90.283324];
Zipcodes['63113'] = [38.65802, -90.2476];
Zipcodes['63114'] = [38.702256, -90.363674];
Zipcodes['63115'] = [38.681855, -90.24017];
Zipcodes['63116'] = [38.579976, -90.265142];
Zipcodes['63117'] = [38.630811, -90.330406];
Zipcodes['63118'] = [38.592292, -90.225035];
Zipcodes['63119'] = [38.590054, -90.34987];
Zipcodes['63120'] = [38.690583, -90.262119];
Zipcodes['63121'] = [38.708063, -90.300626];
Zipcodes['63122'] = [38.579356, -90.420881];
Zipcodes['63123'] = [38.547432, -90.328109];
Zipcodes['63124'] = [38.636558, -90.374651];
Zipcodes['63125'] = [38.519437, -90.292738];
Zipcodes['63126'] = [38.549585, -90.378685];
Zipcodes['63127'] = [38.534493, -90.417387];
Zipcodes['63128'] = [38.491966, -90.386567];
Zipcodes['63129'] = [38.457809, -90.31914];
Zipcodes['63130'] = [38.664917, -90.324897];
Zipcodes['63131'] = [38.616725, -90.439561];
Zipcodes['63132'] = [38.676067, -90.377441];
Zipcodes['63133'] = [38.682271, -90.306694];
Zipcodes['63134'] = [38.740211, -90.345825];
Zipcodes['63135'] = [38.751048, -90.299537];
Zipcodes['63136'] = [38.744761, -90.259924];
Zipcodes['63137'] = [38.749684, -90.212956];
Zipcodes['63138'] = [38.801066, -90.192381];
Zipcodes['63139'] = [38.610351, -90.291845];
Zipcodes['63140'] = [38.739364, -90.324019];
Zipcodes['63141'] = [38.657953, -90.455394];
Zipcodes['63143'] = [38.611729, -90.321432];
Zipcodes['63144'] = [38.619444, -90.348189];
Zipcodes['63146'] = [38.700996, -90.480257];
Zipcodes['63147'] = [38.693802, -90.215331];
Zipcodes['63155'] = [38.627871, -90.205457];
Zipcodes['63301'] = [38.858035, -90.463316];
Zipcodes['63303'] = [38.739794, -90.543692];
Zipcodes['63304'] = [38.706113, -90.68602];
Zipcodes['63330'] = [39.259958, -90.790393];
Zipcodes['63332'] = [38.596875, -90.894031];
Zipcodes['63333'] = [39.03528, -91.320376];
Zipcodes['63334'] = [39.308441, -91.191569];
Zipcodes['63336'] = [39.331804, -90.934034];
Zipcodes['63339'] = [39.330048, -91.354785];
Zipcodes['63341'] = [38.660633, -90.806786];
Zipcodes['63343'] = [39.154813, -90.828184];
Zipcodes['63344'] = [39.22941, -91.009113];
Zipcodes['63345'] = [39.276876, -91.57269];
Zipcodes['63347'] = [39.065452, -90.769248];
Zipcodes['63348'] = [38.785381, -90.945242];
Zipcodes['63349'] = [38.983714, -91.150553];
Zipcodes['63350'] = [38.904226, -91.351355];
Zipcodes['63351'] = [38.858217, -91.31052];
Zipcodes['63352'] = [39.247787, -91.658947];
Zipcodes['63353'] = [39.454043, -91.113045];
Zipcodes['63357'] = [38.647778, -91.103758];
Zipcodes['63359'] = [39.159605, -91.343919];
Zipcodes['63361'] = [38.955583, -91.560141];
Zipcodes['63362'] = [38.93583, -90.895533];
Zipcodes['63363'] = [38.885437, -91.464668];
Zipcodes['63365'] = [38.709576, -90.873384];
Zipcodes['63366'] = [38.857457, -90.725305];
Zipcodes['63367'] = [38.774189, -90.795053];
Zipcodes['63368'] = [38.751545, -90.728422];
Zipcodes['63369'] = [38.938392, -90.776429];
Zipcodes['63373'] = [38.929549, -90.38534];
Zipcodes['63376'] = [38.800744, -90.61215];
Zipcodes['63377'] = [39.107191, -91.096214];
Zipcodes['63379'] = [39.001027, -90.995571];
Zipcodes['63380'] = [38.813115, -91.12213];
Zipcodes['63381'] = [38.986402, -91.253305];
Zipcodes['63382'] = [39.311299, -91.490493];
Zipcodes['63383'] = [38.804365, -91.193705];
Zipcodes['63384'] = [39.092528, -91.553592];
Zipcodes['63385'] = [38.796985, -90.857245];
Zipcodes['63386'] = [38.870205, -90.219539];
Zipcodes['63387'] = [39.183555, -91.016734];
Zipcodes['63388'] = [38.875118, -91.757125];
Zipcodes['63389'] = [39.000511, -90.772845];
Zipcodes['63390'] = [38.803397, -91.040068];
Zipcodes['63401'] = [39.689938, -91.466239];
Zipcodes['63430'] = [40.388088, -91.529125];
Zipcodes['63431'] = [39.747308, -92.312248];
Zipcodes['63432'] = [40.497239, -91.993928];
Zipcodes['63433'] = [39.511304, -91.116943];
Zipcodes['63434'] = [39.909786, -91.947237];
Zipcodes['63435'] = [40.199137, -91.58368];
Zipcodes['63436'] = [39.500689, -91.539951];
Zipcodes['63437'] = [39.723901, -92.236527];
Zipcodes['63438'] = [39.968514, -91.687967];
Zipcodes['63439'] = [39.803818, -91.859595];
Zipcodes['63440'] = [39.96857, -91.741425];
Zipcodes['63441'] = [39.482617, -91.321165];
Zipcodes['63443'] = [39.66058, -91.869458];
Zipcodes['63445'] = [40.395028, -91.723151];
Zipcodes['63446'] = [40.140034, -92.002285];
Zipcodes['63447'] = [40.094404, -91.908896];
Zipcodes['63448'] = [40.015964, -91.542113];
Zipcodes['63450'] = [39.668909, -92.15026];
Zipcodes['63451'] = [39.917465, -92.206036];
Zipcodes['63452'] = [40.092903, -91.780556];
Zipcodes['63453'] = [40.49419, -91.864421];
Zipcodes['63454'] = [39.922981, -91.650377];
Zipcodes['63456'] = [39.65385, -91.726569];
Zipcodes['63457'] = [40.16585, -91.711889];
Zipcodes['63458'] = [39.992451, -92.019907];
Zipcodes['63459'] = [39.584494, -91.382316];
Zipcodes['63460'] = [40.005142, -92.209328];
Zipcodes['63461'] = [39.80218, -91.555549];
Zipcodes['63462'] = [39.419207, -91.70113];
Zipcodes['63463'] = [39.851458, -91.781062];
Zipcodes['63465'] = [40.527638, -91.679549];
Zipcodes['63466'] = [40.2662, -91.618556];
Zipcodes['63467'] = [39.648489, -91.267284];
Zipcodes['63468'] = [39.67971, -92.018554];
Zipcodes['63469'] = [39.825851, -92.041218];
Zipcodes['63471'] = [39.926333, -91.496752];
Zipcodes['63472'] = [40.405293, -91.577311];
Zipcodes['63473'] = [40.251733, -91.76878];
Zipcodes['63474'] = [40.349765, -91.893573];
Zipcodes['63501'] = [40.166289, -92.59121];
Zipcodes['63530'] = [39.906219, -92.443723];
Zipcodes['63531'] = [40.300263, -92.241036];
Zipcodes['63532'] = [39.766564, -92.571886];
Zipcodes['63533'] = [40.199773, -92.392226];
Zipcodes['63534'] = [39.737309, -92.648407];
Zipcodes['63535'] = [40.580678, -92.674502];
Zipcodes['63536'] = [40.485291, -92.355927];
Zipcodes['63537'] = [40.144472, -92.157091];
Zipcodes['63538'] = [39.953937, -92.679871];
Zipcodes['63539'] = [39.925596, -92.768903];
Zipcodes['63540'] = [40.098346, -92.397896];
Zipcodes['63541'] = [40.50417, -92.627042];
Zipcodes['63543'] = [40.35242, -92.014125];
Zipcodes['63544'] = [40.243351, -92.850174];
Zipcodes['63545'] = [40.26475, -92.967999];
Zipcodes['63546'] = [40.337948, -92.502527];
Zipcodes['63547'] = [40.135832, -92.297714];
Zipcodes['63548'] = [40.536364, -92.489163];
Zipcodes['63549'] = [40.017249, -92.490999];
Zipcodes['63551'] = [40.499174, -92.742526];
Zipcodes['63552'] = [39.753192, -92.450313];
Zipcodes['63555'] = [40.486404, -92.193179];
Zipcodes['63556'] = [40.176075, -93.11234];
Zipcodes['63557'] = [39.982807, -92.878405];
Zipcodes['63558'] = [39.748773, -92.751379];
Zipcodes['63559'] = [40.242723, -92.76685];
Zipcodes['63560'] = [40.367197, -93.115387];
Zipcodes['63561'] = [40.410574, -92.534729];
Zipcodes['63563'] = [40.304993, -92.072933];
Zipcodes['63565'] = [40.48062, -92.947276];
Zipcodes['63566'] = [40.029563, -92.952336];
Zipcodes['63567'] = [40.413304, -92.704585];
Zipcodes['63601'] = [37.821084, -90.543883];
Zipcodes['63620'] = [37.38173, -90.651947];
Zipcodes['63621'] = [37.474997, -90.565976];
Zipcodes['63622'] = [37.790992, -90.907431];
Zipcodes['63623'] = [37.679325, -90.906361];
Zipcodes['63624'] = [37.755635, -90.633945];
Zipcodes['63625'] = [37.563413, -90.997619];
Zipcodes['63626'] = [38.072732, -90.709843];
Zipcodes['63627'] = [38.038291, -90.252161];
Zipcodes['63628'] = [37.93458, -90.534178];
Zipcodes['63629'] = [37.38061, -91.239387];
Zipcodes['63630'] = [38.027933, -90.719351];
Zipcodes['63631'] = [37.754571, -90.788037];
Zipcodes['63633'] = [37.442584, -91.020136];
Zipcodes['63636'] = [37.293641, -90.575534];
Zipcodes['63637'] = [37.712286, -90.518346];
Zipcodes['63638'] = [37.214858, -91.019115];
Zipcodes['63640'] = [37.755067, -90.369022];
Zipcodes['63645'] = [37.543368, -90.325994];
Zipcodes['63648'] = [37.815283, -90.701003];
Zipcodes['63650'] = [37.61105, -90.630518];
Zipcodes['63653'] = [37.861318, -90.587878];
Zipcodes['63654'] = [37.487151, -90.857524];
Zipcodes['63655'] = [37.388946, -90.187025];
Zipcodes['63656'] = [37.596555, -90.792841];
Zipcodes['63660'] = [37.915773, -90.706399];
Zipcodes['63662'] = [37.538433, -90.05031];
Zipcodes['63663'] = [37.625409, -90.647867];
Zipcodes['63664'] = [37.917196, -90.9142];
Zipcodes['63665'] = [37.29295, -90.843941];
Zipcodes['63670'] = [37.904016, -90.164527];
Zipcodes['63673'] = [37.841831, -89.991058];
Zipcodes['63674'] = [38.036719, -90.659176];
Zipcodes['63675'] = [37.314474, -90.694768];
Zipcodes['63701'] = [37.332339, -89.585745];
Zipcodes['63703'] = [37.274934, -89.540005];
Zipcodes['63730'] = [37.092999, -89.92192];
Zipcodes['63732'] = [37.594135, -89.57593];
Zipcodes['63735'] = [36.979123, -89.785206];
Zipcodes['63736'] = [37.083813, -89.519853];
Zipcodes['63738'] = [37.08984, -89.95723];
Zipcodes['63739'] = [37.35855, -89.800259];
Zipcodes['63740'] = [37.175369, -89.680721];
Zipcodes['63742'] = [37.160012, -89.446952];
Zipcodes['63743'] = [37.519108, -89.823448];
Zipcodes['63744'] = [37.193333, -89.718347];
Zipcodes['63745'] = [37.255887, -89.659456];
Zipcodes['63746'] = [37.707841, -89.694828];
Zipcodes['63747'] = [37.555807, -89.817465];
Zipcodes['63748'] = [37.662219, -89.610066];
Zipcodes['63750'] = [37.150162, -90.163028];
Zipcodes['63751'] = [37.275815, -90.128182];
Zipcodes['63755'] = [37.439458, -89.6408];
Zipcodes['63758'] = [37.192135, -89.550526];
Zipcodes['63760'] = [37.257417, -89.899164];
Zipcodes['63763'] = [37.064063, -90.173309];
Zipcodes['63764'] = [37.32863, -89.969568];
Zipcodes['63766'] = [37.44193, -89.85009];
Zipcodes['63767'] = [37.043581, -89.610378];
Zipcodes['63769'] = [37.527609, -89.752643];
Zipcodes['63770'] = [37.594553, -89.700436];
Zipcodes['63771'] = [37.075599, -89.71477];
Zipcodes['63774'] = [37.09425, -89.774723];
Zipcodes['63775'] = [37.711303, -89.877458];
Zipcodes['63780'] = [37.176379, -89.495855];
Zipcodes['63781'] = [37.527511, -89.926219];
Zipcodes['63782'] = [37.122136, -90.035305];
Zipcodes['63783'] = [37.609495, -89.682287];
Zipcodes['63784'] = [36.994979, -89.696024];
Zipcodes['63785'] = [37.266837, -89.822215];
Zipcodes['63787'] = [37.121154, -90.132881];
Zipcodes['63801'] = [36.904917, -89.618496];
Zipcodes['63820'] = [36.824291, -89.325641];
Zipcodes['63821'] = [36.049343, -90.238906];
Zipcodes['63822'] = [36.660487, -89.99888];
Zipcodes['63823'] = [36.885642, -89.445022];
Zipcodes['63824'] = [37.004943, -89.526454];
Zipcodes['63825'] = [36.93141, -89.945758];
Zipcodes['63826'] = [36.187094, -89.848981];
Zipcodes['63827'] = [36.261189, -89.895723];
Zipcodes['63828'] = [36.750369, -89.691841];
Zipcodes['63829'] = [36.03551, -90.308867];
Zipcodes['63830'] = [36.13535, -89.690139];
Zipcodes['63833'] = [36.650344, -89.730765];
Zipcodes['63834'] = [36.914293, -89.274233];
Zipcodes['63837'] = [36.45029, -89.986451];
Zipcodes['63839'] = [36.047538, -89.809631];
Zipcodes['63840'] = [36.191637, -89.884209];
Zipcodes['63841'] = [36.771316, -89.984451];
Zipcodes['63845'] = [36.711758, -89.314713];
Zipcodes['63846'] = [36.826259, -89.799347];
Zipcodes['63847'] = [36.442711, -90.034724];
Zipcodes['63848'] = [36.437047, -89.888407];
Zipcodes['63849'] = [36.13968, -89.991152];
Zipcodes['63850'] = [36.822362, -89.782141];
Zipcodes['63851'] = [36.259153, -89.717229];
Zipcodes['63852'] = [36.367334, -90.015154];
Zipcodes['63853'] = [36.058277, -89.867412];
Zipcodes['63855'] = [36.069107, -90.056809];
Zipcodes['63857'] = [36.224266, -90.04576];
Zipcodes['63860'] = [36.678151, -89.592139];
Zipcodes['63862'] = [36.590133, -89.67786];
Zipcodes['63863'] = [36.563034, -89.97695];
Zipcodes['63866'] = [36.528537, -89.605643];
Zipcodes['63867'] = [36.750147, -89.551598];
Zipcodes['63868'] = [36.845769, -89.689692];
Zipcodes['63869'] = [36.607532, -89.495216];
Zipcodes['63870'] = [36.621298, -89.844043];
Zipcodes['63873'] = [36.430734, -89.661517];
Zipcodes['63874'] = [36.561686, -89.813713];
Zipcodes['63876'] = [36.132299, -90.164943];
Zipcodes['63877'] = [36.079496, -89.849151];
Zipcodes['63878'] = [36.503021, -89.822358];
Zipcodes['63879'] = [36.359516, -89.812721];
Zipcodes['63880'] = [36.329979, -90.025872];
Zipcodes['63882'] = [36.91639, -89.219749];
Zipcodes['63901'] = [36.771042, -90.451548];
Zipcodes['63932'] = [36.687148, -90.244962];
Zipcodes['63933'] = [36.519181, -90.111928];
Zipcodes['63934'] = [37.198552, -90.368661];
Zipcodes['63935'] = [36.675213, -90.92147];
Zipcodes['63936'] = [36.808925, -90.129028];
Zipcodes['63937'] = [36.953817, -90.753404];
Zipcodes['63939'] = [36.68408, -90.689336];
Zipcodes['63940'] = [36.768003, -90.227674];
Zipcodes['63941'] = [36.880602, -91.177143];
Zipcodes['63942'] = [36.554015, -91.074164];
Zipcodes['63943'] = [36.834861, -90.801209];
Zipcodes['63944'] = [37.113156, -90.359498];
Zipcodes['63945'] = [36.638612, -90.54944];
Zipcodes['63950'] = [37.262925, -90.414877];
Zipcodes['63951'] = [37.114896, -90.257851];
Zipcodes['63952'] = [37.009877, -90.64274];
Zipcodes['63953'] = [36.587245, -90.637706];
Zipcodes['63954'] = [36.567418, -90.470263];
Zipcodes['63955'] = [36.602079, -90.678656];
Zipcodes['63956'] = [37.213951, -90.521728];
Zipcodes['63957'] = [37.141905, -90.69231];
Zipcodes['63960'] = [36.95694, -90.131465];
Zipcodes['63961'] = [36.567208, -90.277076];
Zipcodes['63962'] = [36.844028, -90.281507];
Zipcodes['63964'] = [37.239691, -90.40274];
Zipcodes['63965'] = [36.965172, -91.0213];
Zipcodes['63966'] = [36.970682, -90.261525];
Zipcodes['63967'] = [36.940696, -90.485325];
Zipcodes['64001'] = [39.101185, -93.536551];
Zipcodes['64011'] = [38.959756, -94.067926];
Zipcodes['64012'] = [38.788391, -94.551918];
Zipcodes['64014'] = [39.00941, -94.256518];
Zipcodes['64015'] = [39.020721, -94.301688];
Zipcodes['64016'] = [39.112922, -94.214765];
Zipcodes['64017'] = [39.199706, -94.032505];
Zipcodes['64018'] = [39.454719, -94.721261];
Zipcodes['64019'] = [38.777542, -93.865281];
Zipcodes['64020'] = [38.967425, -93.599784];
Zipcodes['64021'] = [39.112583, -93.625724];
Zipcodes['64022'] = [39.201655, -93.672247];
Zipcodes['64024'] = [39.327214, -94.227268];
Zipcodes['64028'] = [39.285202, -94.832349];
Zipcodes['64029'] = [39.005904, -94.216393];
Zipcodes['64030'] = [38.881359, -94.522744];
Zipcodes['64034'] = [38.858572, -94.297554];
Zipcodes['64035'] = [39.290513, -93.812219];
Zipcodes['64036'] = [39.200558, -93.935126];
Zipcodes['64037'] = [39.04663, -93.733848];
Zipcodes['64040'] = [38.719336, -93.987754];
Zipcodes['64048'] = [39.437785, -94.363578];
Zipcodes['64050'] = [39.115787, -94.410569];
Zipcodes['64052'] = [39.073616, -94.450866];
Zipcodes['64053'] = [39.107561, -94.464692];
Zipcodes['64054'] = [39.117502, -94.440932];
Zipcodes['64055'] = [39.050847, -94.397998];
Zipcodes['64056'] = [39.113129, -94.314919];
Zipcodes['64057'] = [39.071957, -94.319308];
Zipcodes['64058'] = [39.173856, -94.31356];
Zipcodes['64060'] = [39.370952, -94.368787];
Zipcodes['64061'] = [38.786719, -94.084421];
Zipcodes['64062'] = [39.456489, -94.177881];
Zipcodes['64063'] = [38.912225, -94.353417];
Zipcodes['64064'] = [38.98297, -94.343194];
Zipcodes['64065'] = [38.952177, -94.404397];
Zipcodes['64066'] = [39.139616, -94.138543];
Zipcodes['64067'] = [39.154284, -93.836041];
Zipcodes['64068'] = [39.258315, -94.389653];
Zipcodes['64070'] = [38.886611, -94.148894];
Zipcodes['64071'] = [39.027828, -93.831717];
Zipcodes['64072'] = [39.240552, -94.299635];
Zipcodes['64074'] = [39.083651, -94.071296];
Zipcodes['64075'] = [38.9996, -94.145262];
Zipcodes['64076'] = [38.983162, -93.948056];
Zipcodes['64077'] = [39.224642, -94.139091];
Zipcodes['64078'] = [38.708429, -94.471589];
Zipcodes['64079'] = [39.3638, -94.793302];
Zipcodes['64080'] = [38.764216, -94.260423];
Zipcodes['64081'] = [38.910627, -94.411428];
Zipcodes['64082'] = [38.862188, -94.408306];
Zipcodes['64083'] = [38.801995, -94.448846];
Zipcodes['64084'] = [39.385681, -94.069434];
Zipcodes['64085'] = [39.343321, -93.955431];
Zipcodes['64086'] = [38.899171, -94.272385];
Zipcodes['64088'] = [39.167487, -94.183165];
Zipcodes['64089'] = [39.389996, -94.564952];
Zipcodes['64090'] = [38.760043, -94.163889];
Zipcodes['64092'] = [39.217578, -94.811119];
Zipcodes['64093'] = [38.792552, -93.730877];
Zipcodes['64096'] = [39.196804, -93.557939];
Zipcodes['64097'] = [39.113702, -93.990407];
Zipcodes['64098'] = [39.468076, -94.891475];
Zipcodes['64101'] = [39.103468, -94.60061];
Zipcodes['64102'] = [39.094311, -94.603204];
Zipcodes['64105'] = [39.106182, -94.590621];
Zipcodes['64106'] = [39.105274, -94.570088];
Zipcodes['64108'] = [39.084895, -94.58647];
Zipcodes['64109'] = [39.06583, -94.567597];
Zipcodes['64110'] = [39.034455, -94.572637];
Zipcodes['64111'] = [39.05708, -94.593899];
Zipcodes['64112'] = [39.036004, -94.59525];
Zipcodes['64113'] = [39.01414, -94.595493];
Zipcodes['64114'] = [38.957802, -94.598599];
Zipcodes['64116'] = [39.148811, -94.575242];
Zipcodes['64117'] = [39.164449, -94.522657];
Zipcodes['64118'] = [39.212864, -94.573951];
Zipcodes['64119'] = [39.209967, -94.514665];
Zipcodes['64120'] = [39.135007, -94.514957];
Zipcodes['64123'] = [39.114403, -94.521806];
Zipcodes['64124'] = [39.107143, -94.539062];
Zipcodes['64125'] = [39.106463, -94.494153];
Zipcodes['64126'] = [39.091093, -94.495978];
Zipcodes['64127'] = [39.089069, -94.538388];
Zipcodes['64128'] = [39.065482, -94.534791];
Zipcodes['64129'] = [39.049005, -94.497409];
Zipcodes['64130'] = [39.034782, -94.542049];
Zipcodes['64131'] = [38.959809, -94.571951];
Zipcodes['64132'] = [38.979726, -94.541547];
Zipcodes['64133'] = [39.013884, -94.45772];
Zipcodes['64134'] = [38.930972, -94.487154];
Zipcodes['64136'] = [39.012793, -94.405733];
Zipcodes['64137'] = [38.931621, -94.542913];
Zipcodes['64138'] = [38.967254, -94.470948];
Zipcodes['64139'] = [38.967134, -94.40466];
Zipcodes['64145'] = [38.87803, -94.596188];
Zipcodes['64146'] = [38.882998, -94.571894];
Zipcodes['64147'] = [38.851782, -94.554647];
Zipcodes['64149'] = [38.865459, -94.471606];
Zipcodes['64150'] = [39.172175, -94.632499];
Zipcodes['64151'] = [39.215355, -94.630084];
Zipcodes['64152'] = [39.219065, -94.724719];
Zipcodes['64153'] = [39.274581, -94.726643];
Zipcodes['64154'] = [39.281279, -94.626948];
Zipcodes['64155'] = [39.281154, -94.567841];
Zipcodes['64156'] = [39.273669, -94.517765];
Zipcodes['64157'] = [39.276856, -94.470557];
Zipcodes['64158'] = [39.23438, -94.482916];
Zipcodes['64161'] = [39.158585, -94.450909];
Zipcodes['64163'] = [39.344487, -94.688231];
Zipcodes['64164'] = [39.329353, -94.627065];
Zipcodes['64165'] = [39.320814, -94.577513];
Zipcodes['64166'] = [39.32215, -94.523009];
Zipcodes['64167'] = [39.319092, -94.482038];
Zipcodes['64401'] = [39.633389, -94.715455];
Zipcodes['64402'] = [40.251742, -94.323106];
Zipcodes['64420'] = [40.486462, -94.289201];
Zipcodes['64421'] = [39.90836, -94.927604];
Zipcodes['64422'] = [39.894787, -94.493961];
Zipcodes['64423'] = [40.192871, -94.851656];
Zipcodes['64424'] = [40.264687, -94.032246];
Zipcodes['64426'] = [40.516654, -93.861005];
Zipcodes['64427'] = [40.108578, -94.846324];
Zipcodes['64428'] = [40.423083, -95.088263];
Zipcodes['64429'] = [39.736744, -94.225807];
Zipcodes['64430'] = [39.83899, -94.565539];
Zipcodes['64431'] = [40.528193, -94.996257];
Zipcodes['64432'] = [40.264012, -94.661169];
Zipcodes['64433'] = [40.238844, -94.680901];
Zipcodes['64434'] = [40.258404, -94.736154];
Zipcodes['64436'] = [39.851704, -94.691249];
Zipcodes['64437'] = [40.139951, -95.363369];
Zipcodes['64438'] = [40.158583, -94.413766];
Zipcodes['64439'] = [39.524407, -94.753446];
Zipcodes['64440'] = [39.585134, -94.910338];
Zipcodes['64441'] = [40.392917, -94.2713];
Zipcodes['64442'] = [40.497186, -94.005525];
Zipcodes['64443'] = [39.742886, -94.653163];
Zipcodes['64444'] = [39.485425, -94.648031];
Zipcodes['64445'] = [40.540243, -95.149108];
Zipcodes['64446'] = [40.323896, -95.399116];
Zipcodes['64448'] = [39.603918, -94.827258];
Zipcodes['64449'] = [40.0412, -94.978475];
Zipcodes['64451'] = [39.994021, -95.212592];
Zipcodes['64453'] = [40.344716, -94.469469];
Zipcodes['64454'] = [39.60785, -94.593794];
Zipcodes['64455'] = [40.180658, -95.005056];
Zipcodes['64456'] = [40.49313, -94.391085];
Zipcodes['64457'] = [40.162713, -94.682355];
Zipcodes['64458'] = [40.514955, -94.145198];
Zipcodes['64459'] = [39.928729, -94.645016];
Zipcodes['64461'] = [40.514354, -94.807278];
Zipcodes['64463'] = [40.062598, -94.482585];
Zipcodes['64465'] = [39.550388, -94.288483];
Zipcodes['64466'] = [40.16622, -95.106267];
Zipcodes['64467'] = [40.391688, -94.176662];
Zipcodes['64468'] = [40.342732, -94.881155];
Zipcodes['64469'] = [39.932801, -94.357064];
Zipcodes['64470'] = [40.173356, -95.226671];
Zipcodes['64471'] = [40.265744, -94.193607];
Zipcodes['64473'] = [39.979261, -95.076209];
Zipcodes['64474'] = [39.754427, -94.379661];
Zipcodes['64475'] = [40.436447, -94.629368];
Zipcodes['64476'] = [40.452961, -94.830608];
Zipcodes['64477'] = [39.575462, -94.452859];
Zipcodes['64479'] = [40.338127, -94.670899];
Zipcodes['64480'] = [40.054115, -94.705477];
Zipcodes['64481'] = [40.353947, -93.887733];
Zipcodes['64482'] = [40.447478, -95.556291];
Zipcodes['64483'] = [40.038869, -94.839127];
Zipcodes['64484'] = [39.5612, -95.017149];
Zipcodes['64485'] = [39.95417, -94.816319];
Zipcodes['64486'] = [40.537477, -94.613008];
Zipcodes['64487'] = [40.300935, -95.095911];
Zipcodes['64489'] = [40.234191, -94.548227];
Zipcodes['64490'] = [39.742047, -94.514843];
Zipcodes['64491'] = [40.446045, -95.358054];
Zipcodes['64492'] = [39.481857, -94.544417];
Zipcodes['64493'] = [39.630595, -94.304498];
Zipcodes['64494'] = [39.990658, -94.609645];
Zipcodes['64496'] = [40.49814, -95.622045];
Zipcodes['64497'] = [39.929538, -94.219874];
Zipcodes['64498'] = [40.540253, -95.312458];
Zipcodes['64499'] = [40.397091, -94.415275];
Zipcodes['64501'] = [39.766248, -94.844438];
Zipcodes['64503'] = [39.749277, -94.845718];
Zipcodes['64504'] = [39.691247, -94.905924];
Zipcodes['64505'] = [39.846744, -94.823879];
Zipcodes['64506'] = [39.789915, -94.803312];
Zipcodes['64507'] = [39.736854, -94.740379];
Zipcodes['64601'] = [39.805381, -93.579097];
Zipcodes['64620'] = [39.894531, -94.089951];
Zipcodes['64622'] = [39.49255, -93.542717];
Zipcodes['64623'] = [39.469802, -93.333256];
Zipcodes['64624'] = [39.575736, -93.782858];
Zipcodes['64625'] = [39.744043, -93.802883];
Zipcodes['64628'] = [39.793634, -93.047921];
Zipcodes['64630'] = [40.037666, -93.156885];
Zipcodes['64631'] = [39.837305, -92.88282];
Zipcodes['64632'] = [40.468986, -93.766788];
Zipcodes['64633'] = [39.359951, -93.48192];
Zipcodes['64635'] = [39.952801, -93.433857];
Zipcodes['64636'] = [40.128144, -93.982727];
Zipcodes['64637'] = [39.575717, -93.917412];
Zipcodes['64638'] = [39.617643, -93.620136];
Zipcodes['64639'] = [39.400435, -93.215667];
Zipcodes['64640'] = [39.898207, -93.935966];
Zipcodes['64641'] = [40.164488, -93.402039];
Zipcodes['64642'] = [40.170792, -93.836449];
Zipcodes['64643'] = [39.613045, -93.369158];
Zipcodes['64644'] = [39.739066, -93.970808];
Zipcodes['64645'] = [40.30007, -93.32232];
Zipcodes['64646'] = [40.098954, -93.30898];
Zipcodes['64647'] = [40.026443, -93.967299];
Zipcodes['64648'] = [39.989188, -93.79024];
Zipcodes['64649'] = [39.773347, -94.08639];
Zipcodes['64650'] = [39.637875, -94.053727];
Zipcodes['64651'] = [39.776866, -93.181934];
Zipcodes['64652'] = [40.021508, -93.418192];
Zipcodes['64653'] = [39.895133, -93.194089];
Zipcodes['64654'] = [39.848589, -93.775568];
Zipcodes['64655'] = [40.44653, -93.257493];
Zipcodes['64656'] = [39.67691, -93.692789];
Zipcodes['64657'] = [40.1323, -94.207594];
Zipcodes['64658'] = [39.662965, -92.917299];
Zipcodes['64659'] = [39.781505, -93.28978];
Zipcodes['64660'] = [39.576684, -93.097156];
Zipcodes['64661'] = [40.536056, -93.517477];
Zipcodes['64664'] = [39.749704, -93.651286];
Zipcodes['64667'] = [40.400409, -93.34476];
Zipcodes['64668'] = [39.355068, -93.704415];
Zipcodes['64670'] = [40.052618, -94.140261];
Zipcodes['64671'] = [39.531881, -94.050775];
Zipcodes['64672'] = [40.538926, -93.249737];
Zipcodes['64673'] = [40.374309, -93.586901];
Zipcodes['64674'] = [39.967615, -93.156958];
Zipcodes['64676'] = [39.646173, -93.030107];
Zipcodes['64679'] = [40.23723, -93.580806];
Zipcodes['64681'] = [39.649257, -93.213258];
Zipcodes['64682'] = [39.549491, -93.495161];
Zipcodes['64683'] = [40.073782, -93.603525];
Zipcodes['64686'] = [39.748701, -93.623824];
Zipcodes['64688'] = [39.812391, -93.369305];
Zipcodes['64689'] = [39.875177, -94.151152];
Zipcodes['64701'] = [38.624518, -94.329252];
Zipcodes['64720'] = [38.404278, -94.335778];
Zipcodes['64722'] = [38.276462, -94.554491];
Zipcodes['64723'] = [38.361639, -94.559592];
Zipcodes['64724'] = [38.15321, -94.006638];
Zipcodes['64725'] = [38.490658, -94.372655];
Zipcodes['64726'] = [38.527153, -93.921941];
Zipcodes['64728'] = [37.698899, -94.517846];
Zipcodes['64730'] = [38.255968, -94.283819];
Zipcodes['64733'] = [38.584554, -93.82749];
Zipcodes['64734'] = [38.654734, -94.559334];
Zipcodes['64735'] = [38.372423, -93.727337];
Zipcodes['64738'] = [37.901664, -93.651689];
Zipcodes['64739'] = [38.51128, -94.096624];
Zipcodes['64740'] = [38.227505, -93.702953];
Zipcodes['64741'] = [37.818234, -94.562345];
Zipcodes['64742'] = [38.508095, -94.541195];
Zipcodes['64743'] = [38.663695, -94.213956];
Zipcodes['64744'] = [37.848197, -93.978926];
Zipcodes['64745'] = [38.15126, -94.507744];
Zipcodes['64746'] = [38.602146, -94.496724];
Zipcodes['64747'] = [38.576924, -94.17142];
Zipcodes['64748'] = [37.364764, -94.102264];
Zipcodes['64750'] = [37.921459, -94.113212];
Zipcodes['64752'] = [38.065018, -94.563468];
Zipcodes['64755'] = [37.337416, -94.308691];
Zipcodes['64756'] = [37.61903, -94.015167];
Zipcodes['64759'] = [37.521844, -94.267108];
Zipcodes['64761'] = [38.608025, -93.675642];
Zipcodes['64762'] = [37.546659, -94.501483];
Zipcodes['64763'] = [38.144005, -93.7115];
Zipcodes['64765'] = [37.99678, -94.442531];
Zipcodes['64767'] = [37.737357, -94.201452];
Zipcodes['64769'] = [37.49318, -94.548201];
Zipcodes['64770'] = [38.281451, -93.989507];
Zipcodes['64771'] = [37.772695, -94.463438];
Zipcodes['64772'] = [37.825103, -94.333493];
Zipcodes['64776'] = [38.042283, -93.675435];
Zipcodes['64778'] = [37.919372, -94.47919];
Zipcodes['64779'] = [38.092018, -94.396212];
Zipcodes['64780'] = [38.059202, -94.046961];
Zipcodes['64781'] = [37.976643, -93.817473];
Zipcodes['64783'] = [37.999796, -94.105267];
Zipcodes['64784'] = [37.664651, -94.234169];
Zipcodes['64788'] = [38.423906, -94.019548];
Zipcodes['64790'] = [37.919348, -94.222169];
Zipcodes['64801'] = [37.106902, -94.497647];
Zipcodes['64804'] = [37.019944, -94.510905];
Zipcodes['64830'] = [37.23082, -94.410561];
Zipcodes['64831'] = [36.659128, -94.456762];
Zipcodes['64832'] = [37.340209, -94.573598];
Zipcodes['64833'] = [37.19381, -94.129842];
Zipcodes['64834'] = [37.1857, -94.572848];
Zipcodes['64835'] = [37.147517, -94.438287];
Zipcodes['64836'] = [37.189255, -94.279255];
Zipcodes['64840'] = [37.020059, -94.342858];
Zipcodes['64841'] = [37.078712, -94.411027];
Zipcodes['64842'] = [36.789007, -94.105994];
Zipcodes['64843'] = [36.733479, -94.45317];
Zipcodes['64844'] = [36.910917, -94.239554];
Zipcodes['64847'] = [36.592151, -94.438637];
Zipcodes['64848'] = [37.185314, -93.995267];
Zipcodes['64849'] = [37.254396, -94.445292];
Zipcodes['64850'] = [36.860533, -94.401675];
Zipcodes['64854'] = [36.549701, -94.437596];
Zipcodes['64855'] = [37.336855, -94.513011];
Zipcodes['64856'] = [36.549263, -94.252192];
Zipcodes['64857'] = [37.245867, -94.438623];
Zipcodes['64858'] = [36.900862, -94.530534];
Zipcodes['64859'] = [37.164209, -94.113408];
Zipcodes['64861'] = [36.695352, -94.154072];
Zipcodes['64862'] = [37.100665, -94.119523];
Zipcodes['64863'] = [36.559367, -94.58334];
Zipcodes['64865'] = [36.842651, -94.581313];
Zipcodes['64866'] = [36.869577, -94.156423];
Zipcodes['64867'] = [36.724466, -94.23472];
Zipcodes['64868'] = [36.687541, -94.61285];
Zipcodes['64870'] = [37.182917, -94.477775];
Zipcodes['64873'] = [37.010659, -94.053847];
Zipcodes['64874'] = [36.752144, -94.052997];
Zipcodes['65001'] = [38.27761, -92.004757];
Zipcodes['65010'] = [38.78781, -92.241975];
Zipcodes['65011'] = [38.402263, -92.71027];
Zipcodes['65013'] = [38.269917, -91.752129];
Zipcodes['65014'] = [38.322804, -91.624649];
Zipcodes['65016'] = [38.560706, -91.919121];
Zipcodes['65017'] = [38.092307, -92.494503];
Zipcodes['65018'] = [38.620078, -92.564068];
Zipcodes['65020'] = [38.006239, -92.780644];
Zipcodes['65023'] = [38.653901, -92.396296];
Zipcodes['65024'] = [38.629998, -91.774725];
Zipcodes['65025'] = [38.667143, -92.69929];
Zipcodes['65026'] = [38.324361, -92.564791];
Zipcodes['65032'] = [38.347628, -92.383086];
Zipcodes['65034'] = [38.559677, -92.818105];
Zipcodes['65035'] = [38.363815, -91.946963];
Zipcodes['65037'] = [38.243419, -92.849146];
Zipcodes['65039'] = [38.711076, -92.296636];
Zipcodes['65040'] = [38.348268, -92.284585];
Zipcodes['65041'] = [38.63302, -91.489758];
Zipcodes['65043'] = [38.623012, -92.102287];
Zipcodes['65046'] = [38.775851, -92.477586];
Zipcodes['65047'] = [38.150894, -92.578446];
Zipcodes['65048'] = [38.37312, -92.040605];
Zipcodes['65049'] = [38.199958, -92.67452];
Zipcodes['65050'] = [38.521827, -92.686401];
Zipcodes['65051'] = [38.462526, -91.797971];
Zipcodes['65052'] = [38.076413, -92.70546];
Zipcodes['65053'] = [38.541217, -92.36959];
Zipcodes['65054'] = [38.483962, -91.946925];
Zipcodes['65058'] = [38.269515, -92.130674];
Zipcodes['65059'] = [38.701317, -91.888159];
Zipcodes['65061'] = [38.594245, -91.634877];
Zipcodes['65062'] = [38.50121, -91.659734];
Zipcodes['65063'] = [38.725915, -92.087514];
Zipcodes['65064'] = [38.402845, -92.471423];
Zipcodes['65065'] = [38.132046, -92.669926];
Zipcodes['65066'] = [38.345418, -91.490472];
Zipcodes['65067'] = [38.744622, -91.728111];
Zipcodes['65068'] = [38.828625, -92.598054];
Zipcodes['65069'] = [38.757504, -91.608296];
Zipcodes['65072'] = [38.266773, -92.72935];
Zipcodes['65074'] = [38.484301, -92.468144];
Zipcodes['65075'] = [38.237326, -92.250624];
Zipcodes['65076'] = [38.379486, -92.194056];
Zipcodes['65077'] = [38.769625, -91.818502];
Zipcodes['65078'] = [38.381135, -93.035985];
Zipcodes['65079'] = [38.155851, -92.751986];
Zipcodes['65080'] = [38.636261, -91.978927];
Zipcodes['65081'] = [38.633981, -92.779889];
Zipcodes['65082'] = [38.219313, -92.421422];
Zipcodes['65083'] = [38.1475, -92.443614];
Zipcodes['65084'] = [38.438023, -92.855365];
Zipcodes['65085'] = [38.405742, -92.083782];
Zipcodes['65101'] = [38.49743, -92.133196];
Zipcodes['65109'] = [38.573482, -92.276821];
Zipcodes['65201'] = [38.900945, -92.238427];
Zipcodes['65202'] = [39.01716, -92.297814];
Zipcodes['65203'] = [38.895872, -92.395843];
Zipcodes['65215'] = [38.951687, -92.320389];
Zipcodes['65216'] = [38.958171, -92.326298];
Zipcodes['65230'] = [39.29055, -92.681308];
Zipcodes['65231'] = [39.017843, -91.907822];
Zipcodes['65232'] = [39.160695, -91.756];
Zipcodes['65233'] = [38.910695, -92.723915];
Zipcodes['65236'] = [39.448439, -93.088686];
Zipcodes['65237'] = [38.767567, -92.794578];
Zipcodes['65239'] = [39.533015, -92.420798];
Zipcodes['65240'] = [39.220047, -92.131098];
Zipcodes['65243'] = [39.275217, -92.35057];
Zipcodes['65244'] = [39.4848, -92.659485];
Zipcodes['65246'] = [39.370188, -92.993886];
Zipcodes['65247'] = [39.635081, -92.470867];
Zipcodes['65248'] = [39.138606, -92.660133];
Zipcodes['65250'] = [39.0301, -92.840101];
Zipcodes['65251'] = [38.849998, -91.978774];
Zipcodes['65254'] = [39.211594, -92.846129];
Zipcodes['65255'] = [39.102418, -92.218415];
Zipcodes['65256'] = [39.121584, -92.455552];
Zipcodes['65257'] = [39.287086, -92.533811];
Zipcodes['65258'] = [39.526093, -92.138242];
Zipcodes['65259'] = [39.475322, -92.563651];
Zipcodes['65260'] = [39.593302, -92.400522];
Zipcodes['65261'] = [39.462382, -92.916036];
Zipcodes['65262'] = [38.960143, -91.955313];
Zipcodes['65263'] = [39.448143, -92.218052];
Zipcodes['65264'] = [39.088314, -91.68564];
Zipcodes['65265'] = [39.203373, -91.885371];
Zipcodes['65270'] = [39.413639, -92.39495];
Zipcodes['65274'] = [39.015477, -92.685507];
Zipcodes['65275'] = [39.453066, -91.994965];
Zipcodes['65276'] = [38.852749, -92.957912];
Zipcodes['65278'] = [39.342148, -92.412206];
Zipcodes['65279'] = [38.994375, -92.545027];
Zipcodes['65280'] = [39.233565, -91.754613];
Zipcodes['65281'] = [39.466112, -92.788003];
Zipcodes['65282'] = [39.382472, -91.839756];
Zipcodes['65283'] = [39.509271, -91.827525];
Zipcodes['65284'] = [39.186823, -92.30416];
Zipcodes['65285'] = [39.271734, -91.999838];
Zipcodes['65286'] = [39.507751, -93.220276];
Zipcodes['65287'] = [38.883655, -92.52232];
Zipcodes['65301'] = [38.699117, -93.221345];
Zipcodes['65305'] = [38.733116, -93.553158];
Zipcodes['65320'] = [39.066396, -92.945955];
Zipcodes['65321'] = [39.075267, -93.447502];
Zipcodes['65322'] = [38.969776, -92.966365];
Zipcodes['65323'] = [38.453529, -93.616304];
Zipcodes['65324'] = [38.097093, -93.009748];
Zipcodes['65325'] = [38.439546, -93.1888];
Zipcodes['65326'] = [38.16584, -93.13917];
Zipcodes['65327'] = [38.976539, -93.484089];
Zipcodes['65329'] = [38.602086, -92.957038];
Zipcodes['65330'] = [39.222686, -92.950634];
Zipcodes['65332'] = [38.619836, -93.413505];
Zipcodes['65333'] = [38.900768, -93.319941];
Zipcodes['65334'] = [38.838248, -93.246143];
Zipcodes['65335'] = [38.513709, -93.322812];
Zipcodes['65336'] = [38.785151, -93.557492];
Zipcodes['65337'] = [38.794493, -93.431379];
Zipcodes['65338'] = [38.357194, -93.305888];
Zipcodes['65339'] = [39.188819, -93.371603];
Zipcodes['65340'] = [39.088482, -93.175934];
Zipcodes['65344'] = [39.318962, -93.204192];
Zipcodes['65345'] = [38.537892, -93.126897];
Zipcodes['65347'] = [38.98104, -93.046305];
Zipcodes['65348'] = [38.712425, -92.989911];
Zipcodes['65349'] = [39.233822, -93.053552];
Zipcodes['65350'] = [38.654785, -93.10301];
Zipcodes['65351'] = [38.994519, -93.381385];
Zipcodes['65354'] = [38.643566, -92.885997];
Zipcodes['65355'] = [38.219531, -93.366959];
Zipcodes['65360'] = [38.52016, -93.514635];
Zipcodes['65401'] = [37.899248, -91.768537];
Zipcodes['65409'] = [37.950967, -91.77954];
Zipcodes['65436'] = [37.623801, -91.96361];
Zipcodes['65438'] = [36.931336, -91.501101];
Zipcodes['65439'] = [37.664882, -91.094227];
Zipcodes['65440'] = [37.599217, -91.190612];
Zipcodes['65441'] = [38.096433, -91.159018];
Zipcodes['65443'] = [38.131713, -92.094612];
Zipcodes['65444'] = [37.37778, -92.053848];
Zipcodes['65446'] = [37.810903, -91.269805];
Zipcodes['65449'] = [37.852033, -91.494674];
Zipcodes['65452'] = [37.967139, -92.266342];
Zipcodes['65453'] = [38.107481, -91.427005];
Zipcodes['65456'] = [37.784607, -91.18978];
Zipcodes['65457'] = [37.823799, -92.051019];
Zipcodes['65459'] = [38.02972, -92.096732];
Zipcodes['65461'] = [37.687102, -92.02376];
Zipcodes['65462'] = [37.684681, -91.864589];
Zipcodes['65463'] = [37.847675, -92.783349];
Zipcodes['65464'] = [37.186578, -91.899782];
Zipcodes['65466'] = [37.211312, -91.386372];
Zipcodes['65468'] = [37.254359, -91.798258];
Zipcodes['65470'] = [37.54739, -92.401779];
Zipcodes['65473'] = [37.709893, -92.147911];
Zipcodes['65479'] = [37.342026, -91.636139];
Zipcodes['65483'] = [37.298969, -91.955093];
Zipcodes['65484'] = [37.356223, -92.227015];
Zipcodes['65486'] = [38.103192, -92.306674];
Zipcodes['65501'] = [37.45461, -91.541535];
Zipcodes['65529'] = [37.929116, -91.989143];
Zipcodes['65534'] = [37.70079, -92.260406];
Zipcodes['65535'] = [38.070003, -91.274154];
Zipcodes['65536'] = [37.691691, -92.647854];
Zipcodes['65541'] = [37.643728, -91.758724];
Zipcodes['65542'] = [37.49571, -91.883741];
Zipcodes['65543'] = [37.453945, -92.290231];
Zipcodes['65548'] = [37.005701, -91.712048];
Zipcodes['65550'] = [37.84413, -91.943747];
Zipcodes['65552'] = [37.498044, -92.172291];
Zipcodes['65555'] = [37.362235, -91.778188];
Zipcodes['65556'] = [37.835355, -92.403661];
Zipcodes['65557'] = [37.516799, -92.144602];
Zipcodes['65559'] = [38.016857, -91.614457];
Zipcodes['65560'] = [37.59661, -91.503867];
Zipcodes['65564'] = [37.232593, -91.956395];
Zipcodes['65565'] = [37.896485, -91.239276];
Zipcodes['65566'] = [37.808607, -91.161474];
Zipcodes['65567'] = [37.849482, -92.54003];
Zipcodes['65570'] = [37.452634, -92.094798];
Zipcodes['65571'] = [37.186576, -91.644761];
Zipcodes['65580'] = [38.116506, -91.789901];
Zipcodes['65582'] = [38.204152, -91.926434];
Zipcodes['65583'] = [37.82843, -92.257625];
Zipcodes['65584'] = [37.828894, -92.131217];
Zipcodes['65586'] = [37.864779, -91.432955];
Zipcodes['65588'] = [36.966274, -91.29365];
Zipcodes['65589'] = [37.237297, -91.827444];
Zipcodes['65590'] = [37.636585, -92.975332];
Zipcodes['65591'] = [37.97938, -92.590769];
Zipcodes['65601'] = [37.530032, -93.58573];
Zipcodes['65603'] = [37.533454, -93.856535];
Zipcodes['65604'] = [37.269914, -93.598471];
Zipcodes['65605'] = [36.989679, -93.73973];
Zipcodes['65606'] = [36.697248, -91.359401];
Zipcodes['65608'] = [36.902123, -92.667292];
Zipcodes['65609'] = [36.526793, -92.176983];
Zipcodes['65610'] = [37.042315, -93.525315];
Zipcodes['65611'] = [36.549823, -93.372126];
Zipcodes['65612'] = [37.213797, -93.538811];
Zipcodes['65613'] = [37.640914, -93.387351];
Zipcodes['65614'] = [36.733727, -92.89709];
Zipcodes['65616'] = [36.670737, -93.2488];
Zipcodes['65617'] = [37.437614, -93.334662];
Zipcodes['65618'] = [36.759505, -92.367121];
Zipcodes['65619'] = [37.122459, -93.386624];
Zipcodes['65620'] = [37.017469, -92.955719];
Zipcodes['65622'] = [37.635235, -93.110239];
Zipcodes['65623'] = [36.748889, -93.906575];
Zipcodes['65624'] = [36.731636, -93.561154];
Zipcodes['65625'] = [36.674069, -93.816497];
Zipcodes['65626'] = [36.587772, -92.113109];
Zipcodes['65627'] = [36.566955, -93.032874];
Zipcodes['65629'] = [36.891538, -93.000516];
Zipcodes['65630'] = [36.838159, -93.226303];
Zipcodes['65631'] = [37.000699, -93.423999];
Zipcodes['65632'] = [37.499015, -92.815963];
Zipcodes['65633'] = [36.899134, -93.535971];
Zipcodes['65634'] = [38.015397, -93.194616];
Zipcodes['65635'] = [37.531741, -93.717143];
Zipcodes['65637'] = [36.776353, -92.190751];
Zipcodes['65638'] = [36.833134, -92.330273];
Zipcodes['65640'] = [37.698467, -93.566848];
Zipcodes['65641'] = [36.544001, -93.771047];
Zipcodes['65644'] = [37.510802, -93.037975];
Zipcodes['65646'] = [37.333448, -93.703246];
Zipcodes['65647'] = [36.69296, -94.007064];
Zipcodes['65648'] = [37.408778, -93.166512];
Zipcodes['65649'] = [37.617274, -93.623215];
Zipcodes['65650'] = [37.813994, -93.441928];
Zipcodes['65652'] = [37.133087, -92.949997];
Zipcodes['65653'] = [36.840868, -93.140301];
Zipcodes['65654'] = [37.021684, -93.897529];
Zipcodes['65655'] = [36.603082, -92.419582];
Zipcodes['65656'] = [36.793766, -93.46783];
Zipcodes['65657'] = [36.839394, -93.018217];
Zipcodes['65658'] = [36.546676, -93.638135];
Zipcodes['65660'] = [37.323909, -92.270747];
Zipcodes['65661'] = [37.45627, -93.829081];
Zipcodes['65662'] = [37.419061, -92.572331];
Zipcodes['65663'] = [37.630156, -93.246009];
Zipcodes['65664'] = [37.198054, -93.615176];
Zipcodes['65666'] = [36.57483, -92.360456];
Zipcodes['65667'] = [37.308459, -92.514047];
Zipcodes['65668'] = [37.930666, -93.288163];
Zipcodes['65669'] = [36.915521, -93.295633];
Zipcodes['65672'] = [36.560353, -93.219489];
Zipcodes['65674'] = [37.791219, -93.608207];
Zipcodes['65675'] = [36.93093, -93.492387];
Zipcodes['65676'] = [36.578048, -92.599028];
Zipcodes['65679'] = [36.574234, -93.094922];
Zipcodes['65680'] = [36.641983, -92.981749];
Zipcodes['65681'] = [36.545551, -93.468709];
Zipcodes['65682'] = [37.416231, -93.979593];
Zipcodes['65685'] = [37.753739, -93.151133];
Zipcodes['65686'] = [36.626859, -93.443782];
Zipcodes['65688'] = [36.649279, -91.693279];
Zipcodes['65689'] = [37.135825, -92.090842];
Zipcodes['65690'] = [36.592338, -91.264476];
Zipcodes['65692'] = [36.62145, -91.650328];
Zipcodes['65702'] = [37.053256, -92.485409];
Zipcodes['65704'] = [37.12762, -92.586107];
Zipcodes['65705'] = [37.092357, -93.623318];
Zipcodes['65706'] = [37.322817, -92.898613];
Zipcodes['65707'] = [37.233674, -93.843315];
Zipcodes['65708'] = [36.910924, -93.908754];
Zipcodes['65710'] = [37.485555, -93.421088];
Zipcodes['65711'] = [37.176382, -92.275679];
Zipcodes['65712'] = [37.110815, -93.806027];
Zipcodes['65713'] = [37.405733, -92.739217];
Zipcodes['65714'] = [37.040971, -93.320743];
Zipcodes['65715'] = [36.731375, -92.574001];
Zipcodes['65717'] = [37.06044, -92.416048];
Zipcodes['65720'] = [36.947556, -92.962543];
Zipcodes['65721'] = [36.980529, -93.209387];
Zipcodes['65722'] = [37.589022, -92.807729];
Zipcodes['65723'] = [36.953147, -94.048372];
Zipcodes['65724'] = [37.840458, -93.316158];
Zipcodes['65725'] = [37.429655, -93.264141];
Zipcodes['65726'] = [36.619595, -93.242859];
Zipcodes['65727'] = [37.765849, -93.295111];
Zipcodes['65728'] = [36.877092, -93.3353];
Zipcodes['65729'] = [36.538889, -92.563512];
Zipcodes['65730'] = [36.623583, -94.224668];
Zipcodes['65731'] = [36.653457, -93.125924];
Zipcodes['65732'] = [37.9348, -93.177165];
Zipcodes['65733'] = [36.533097, -92.851245];
Zipcodes['65734'] = [36.796585, -93.953748];
Zipcodes['65735'] = [38.039886, -93.486638];
Zipcodes['65737'] = [36.712204, -93.350973];
Zipcodes['65738'] = [37.137273, -93.490452];
Zipcodes['65739'] = [36.510963, -93.274118];
Zipcodes['65740'] = [36.711926, -93.163213];
Zipcodes['65741'] = [36.795809, -92.404237];
Zipcodes['65742'] = [37.125343, -93.088984];
Zipcodes['65744'] = [36.624477, -92.893379];
Zipcodes['65745'] = [36.522672, -93.983629];
Zipcodes['65746'] = [37.143195, -92.7877];
Zipcodes['65747'] = [36.600861, -93.580084];
Zipcodes['65752'] = [37.332402, -93.838349];
Zipcodes['65753'] = [36.994555, -93.056207];
Zipcodes['65754'] = [36.838441, -93.282222];
Zipcodes['65755'] = [36.801592, -92.632683];
Zipcodes['65756'] = [37.096307, -93.960331];
Zipcodes['65757'] = [37.282852, -93.098839];
Zipcodes['65759'] = [36.767553, -93.048394];
Zipcodes['65760'] = [36.616251, -92.276774];
Zipcodes['65761'] = [36.5412, -92.721785];
Zipcodes['65762'] = [36.680587, -92.66287];
Zipcodes['65764'] = [37.81452, -92.948674];
Zipcodes['65766'] = [36.536052, -92.268646];
Zipcodes['65767'] = [37.852251, -93.155937];
Zipcodes['65768'] = [36.919435, -92.270035];
Zipcodes['65769'] = [36.919096, -93.799405];
Zipcodes['65770'] = [37.422627, -93.548226];
Zipcodes['65771'] = [36.774833, -93.204208];
Zipcodes['65772'] = [36.59795, -94.024935];
Zipcodes['65773'] = [36.750977, -92.516888];
Zipcodes['65774'] = [37.930273, -93.489344];
Zipcodes['65775'] = [36.701055, -91.856335];
Zipcodes['65777'] = [36.517396, -91.980986];
Zipcodes['65778'] = [36.530448, -91.271025];
Zipcodes['65779'] = [37.981959, -93.384659];
Zipcodes['65781'] = [37.355539, -93.417572];
Zipcodes['65783'] = [37.710693, -92.903269];
Zipcodes['65784'] = [36.730108, -92.297404];
Zipcodes['65785'] = [37.708475, -93.825054];
Zipcodes['65786'] = [37.967389, -92.963419];
Zipcodes['65787'] = [38.067687, -92.898872];
Zipcodes['65788'] = [36.806689, -91.694945];
Zipcodes['65789'] = [36.857229, -91.889823];
Zipcodes['65790'] = [36.702937, -92.109573];
Zipcodes['65791'] = [36.579155, -91.49723];
Zipcodes['65793'] = [36.99172, -91.980005];
Zipcodes['65801'] = [37.21421, -93.295218];
Zipcodes['65802'] = [37.210537, -93.35169];
Zipcodes['65803'] = [37.283159, -93.289905];
Zipcodes['65804'] = [37.148205, -93.252457];
Zipcodes['65806'] = [37.205661, -93.298579];
Zipcodes['65807'] = [37.167524, -93.324808];
Zipcodes['65809'] = [37.173467, -93.189943];
Zipcodes['65810'] = [37.119734, -93.309724];
Zipcodes['65897'] = [37.199857, -93.280538];
Zipcodes['66002'] = [39.55166, -95.140354];
Zipcodes['66006'] = [38.788836, -95.238103];
Zipcodes['66007'] = [39.13603, -94.944555];
Zipcodes['66008'] = [39.718181, -95.176388];
Zipcodes['66010'] = [38.091956, -94.996041];
Zipcodes['66012'] = [39.066626, -94.92058];
Zipcodes['66013'] = [38.730666, -94.695551];
Zipcodes['66014'] = [38.228845, -94.987105];
Zipcodes['66015'] = [38.075724, -95.400167];
Zipcodes['66016'] = [39.467255, -95.222694];
Zipcodes['66017'] = [39.703716, -95.273961];
Zipcodes['66018'] = [38.96405, -94.974413];
Zipcodes['66020'] = [39.339359, -95.111443];
Zipcodes['66021'] = [38.75661, -95.005098];
Zipcodes['66023'] = [39.512268, -95.392384];
Zipcodes['66024'] = [39.762082, -94.889502];
Zipcodes['66025'] = [38.891903, -95.08334];
Zipcodes['66026'] = [38.395871, -94.866312];
Zipcodes['66027'] = [39.361406, -94.916526];
Zipcodes['66030'] = [38.815123, -94.940262];
Zipcodes['66031'] = [38.831962, -94.890083];
Zipcodes['66032'] = [38.277458, -95.276974];
Zipcodes['66033'] = [38.358776, -95.127585];
Zipcodes['66035'] = [39.870959, -95.253634];
Zipcodes['66039'] = [38.104411, -95.164855];
Zipcodes['66040'] = [38.356875, -94.738049];
Zipcodes['66041'] = [39.600303, -95.307974];
Zipcodes['66042'] = [38.422764, -95.079878];
Zipcodes['66043'] = [39.248453, -94.905575];
Zipcodes['66044'] = [39.032931, -95.209092];
Zipcodes['66045'] = [38.954328, -95.261527];
Zipcodes['66046'] = [38.904524, -95.209753];
Zipcodes['66047'] = [38.893051, -95.34616];
Zipcodes['66048'] = [39.272723, -94.980405];
Zipcodes['66049'] = [38.979249, -95.345686];
Zipcodes['66050'] = [39.025738, -95.430057];
Zipcodes['66052'] = [39.008348, -95.04558];
Zipcodes['66053'] = [38.581078, -94.668065];
Zipcodes['66054'] = [39.20622, -95.198329];
Zipcodes['66056'] = [38.151453, -94.844282];
Zipcodes['66058'] = [39.541062, -95.520379];
Zipcodes['66060'] = [39.418672, -95.331986];
Zipcodes['66061'] = [38.893755, -94.879968];
Zipcodes['66062'] = [38.838296, -94.784409];
Zipcodes['66064'] = [38.480714, -94.988936];
Zipcodes['66066'] = [39.203002, -95.330593];
Zipcodes['66067'] = [38.619765, -95.284211];
Zipcodes['66070'] = [39.211355, -95.453339];
Zipcodes['66071'] = [38.574468, -94.8667];
Zipcodes['66072'] = [38.31847, -94.974751];
Zipcodes['66073'] = [39.092072, -95.380998];
Zipcodes['66075'] = [38.200962, -94.684056];
Zipcodes['66076'] = [38.632455, -95.443013];
Zipcodes['66078'] = [38.48454, -95.25801];
Zipcodes['66079'] = [38.542059, -95.124399];
Zipcodes['66080'] = [38.412899, -95.254945];
Zipcodes['66083'] = [38.732299, -94.830141];
Zipcodes['66085'] = [38.806276, -94.645167];
Zipcodes['66086'] = [39.114913, -95.081394];
Zipcodes['66087'] = [39.808933, -95.139757];
Zipcodes['66088'] = [39.341326, -95.456454];
Zipcodes['66090'] = [39.787949, -94.973367];
Zipcodes['66091'] = [38.17207, -95.327652];
Zipcodes['66092'] = [38.688813, -95.10642];
Zipcodes['66093'] = [38.20781, -95.494138];
Zipcodes['66094'] = [39.959183, -95.341745];
Zipcodes['66095'] = [38.441094, -95.438861];
Zipcodes['66097'] = [39.328599, -95.246083];
Zipcodes['66101'] = [39.118743, -94.625905];
Zipcodes['66102'] = [39.108964, -94.692539];
Zipcodes['66103'] = [39.060329, -94.626783];
Zipcodes['66104'] = [39.150159, -94.689533];
Zipcodes['66105'] = [39.086225, -94.636591];
Zipcodes['66106'] = [39.069454, -94.702613];
Zipcodes['66109'] = [39.165351, -94.829341];
Zipcodes['66111'] = [39.084029, -94.789887];
Zipcodes['66112'] = [39.114955, -94.770108];
Zipcodes['66115'] = [39.148258, -94.605084];
Zipcodes['66118'] = [39.100904, -94.613056];
Zipcodes['66160'] = [39.055816, -94.610055];
Zipcodes['66202'] = [39.02315, -94.669466];
Zipcodes['66203'] = [39.020426, -94.70585];
Zipcodes['66204'] = [38.990381, -94.678214];
Zipcodes['66205'] = [39.031229, -94.630732];
Zipcodes['66206'] = [38.958711, -94.618649];
Zipcodes['66207'] = [38.955644, -94.643247];
Zipcodes['66208'] = [38.997529, -94.628369];
Zipcodes['66209'] = [38.900851, -94.634114];
Zipcodes['66210'] = [38.926531, -94.704907];
Zipcodes['66211'] = [38.92512, -94.635624];
Zipcodes['66212'] = [38.955565, -94.679875];
Zipcodes['66213'] = [38.899195, -94.703706];
Zipcodes['66214'] = [38.963579, -94.713965];
Zipcodes['66215'] = [38.9558, -94.743054];
Zipcodes['66216'] = [39.013565, -94.741563];
Zipcodes['66217'] = [39.013669, -94.775402];
Zipcodes['66218'] = [39.012931, -94.817887];
Zipcodes['66219'] = [38.953198, -94.778381];
Zipcodes['66220'] = [38.959691, -94.819014];
Zipcodes['66221'] = [38.846811, -94.713671];
Zipcodes['66223'] = [38.861597, -94.668768];
Zipcodes['66224'] = [38.862095, -94.623627];
Zipcodes['66226'] = [39.030956, -94.861176];
Zipcodes['66227'] = [38.972669, -94.874836];
Zipcodes['66251'] = [38.917163, -94.658844];
Zipcodes['66401'] = [38.932367, -96.309652];
Zipcodes['66402'] = [38.914, -95.843271];
Zipcodes['66403'] = [39.890613, -96.270122];
Zipcodes['66404'] = [39.863837, -96.172821];
Zipcodes['66406'] = [39.894106, -96.420729];
Zipcodes['66407'] = [39.227879, -96.185867];
Zipcodes['66408'] = [39.949593, -95.975154];
Zipcodes['66409'] = [38.9192, -95.555145];
Zipcodes['66411'] = [39.641998, -96.643774];
Zipcodes['66412'] = [39.9078, -96.777424];
Zipcodes['66413'] = [38.768875, -95.880725];
Zipcodes['66414'] = [38.830778, -95.685485];
Zipcodes['66415'] = [39.689412, -96.137602];
Zipcodes['66416'] = [39.525706, -95.86324];
Zipcodes['66417'] = [39.656551, -96.037085];
Zipcodes['66418'] = [39.279496, -95.933586];
Zipcodes['66419'] = [39.360679, -95.598882];
Zipcodes['66422'] = [39.333975, -96.083967];
Zipcodes['66423'] = [38.853961, -96.1222];
Zipcodes['66424'] = [39.674689, -95.404063];
Zipcodes['66425'] = [39.806734, -95.721284];
Zipcodes['66427'] = [39.661283, -96.438829];
Zipcodes['66428'] = [39.676116, -95.931358];
Zipcodes['66429'] = [39.09377, -95.534186];
Zipcodes['66431'] = [38.80593, -96.002771];
Zipcodes['66432'] = [39.491388, -96.077576];
Zipcodes['66434'] = [39.86304, -95.554804];
Zipcodes['66436'] = [39.468312, -95.70384];
Zipcodes['66438'] = [39.835044, -96.510931];
Zipcodes['66439'] = [39.656331, -95.540086];
Zipcodes['66440'] = [39.268181, -95.676822];
Zipcodes['66441'] = [38.973521, -96.803091];
Zipcodes['66442'] = [39.09167, -96.790143];
Zipcodes['66449'] = [39.390138, -96.856465];
Zipcodes['66451'] = [38.60326, -95.673215];
Zipcodes['66501'] = [39.052914, -96.236894];
Zipcodes['66502'] = [39.139945, -96.528718];
Zipcodes['66503'] = [39.265014, -96.69527];
Zipcodes['66506'] = [39.195974, -96.581468];
Zipcodes['66507'] = [39.051133, -96.030609];
Zipcodes['66508'] = [39.858566, -96.644306];
Zipcodes['66509'] = [39.325448, -95.78492];
Zipcodes['66510'] = [38.499626, -95.610199];
Zipcodes['66512'] = [39.208241, -95.541486];
Zipcodes['66514'] = [39.126231, -96.875602];
Zipcodes['66515'] = [39.936249, -95.703739];
Zipcodes['66516'] = [39.616501, -95.720797];
Zipcodes['66517'] = [39.11092, -96.702353];
Zipcodes['66518'] = [39.965606, -96.550756];
Zipcodes['66520'] = [39.411721, -96.611487];
Zipcodes['66521'] = [39.470744, -96.229434];
Zipcodes['66522'] = [39.866131, -95.938164];
Zipcodes['66523'] = [38.615774, -95.833994];
Zipcodes['66524'] = [38.790939, -95.506078];
Zipcodes['66526'] = [39.064612, -96.162615];
Zipcodes['66527'] = [39.74697, -95.68097];
Zipcodes['66528'] = [38.60287, -95.537804];
Zipcodes['66531'] = [39.312395, -96.838498];
Zipcodes['66532'] = [39.821429, -95.377943];
Zipcodes['66533'] = [39.163187, -95.947495];
Zipcodes['66534'] = [39.876714, -95.836775];
Zipcodes['66535'] = [39.26703, -96.433702];
Zipcodes['66536'] = [39.215441, -96.0695];
Zipcodes['66537'] = [38.751242, -95.719623];
Zipcodes['66538'] = [39.850557, -96.044691];
Zipcodes['66539'] = [39.141967, -95.84039];
Zipcodes['66540'] = [39.484787, -95.970371];
Zipcodes['66541'] = [39.978561, -96.343592];
Zipcodes['66542'] = [39.010744, -95.544771];
Zipcodes['66543'] = [38.63957, -95.599414];
Zipcodes['66544'] = [39.688855, -96.273293];
Zipcodes['66546'] = [38.893955, -95.72967];
Zipcodes['66547'] = [39.231558, -96.295611];
Zipcodes['66548'] = [39.670854, -96.762724];
Zipcodes['66549'] = [39.420581, -96.435724];
Zipcodes['66550'] = [39.655442, -95.820665];
Zipcodes['66552'] = [39.587682, -95.629855];
Zipcodes['66554'] = [39.494814, -96.790528];
Zipcodes['66603'] = [39.057532, -95.674747];
Zipcodes['66604'] = [39.039022, -95.726188];
Zipcodes['66605'] = [39.010828, -95.638819];
Zipcodes['66606'] = [39.061436, -95.719951];
Zipcodes['66607'] = [39.041205, -95.635345];
Zipcodes['66608'] = [39.080837, -95.663859];
Zipcodes['66609'] = [38.978222, -95.660742];
Zipcodes['66610'] = [38.976726, -95.844565];
Zipcodes['66611'] = [39.018756, -95.696387];
Zipcodes['66612'] = [39.040224, -95.680334];
Zipcodes['66614'] = [39.009926, -95.837954];
Zipcodes['66615'] = [39.050826, -95.897866];
Zipcodes['66616'] = [39.069817, -95.623043];
Zipcodes['66617'] = [39.134832, -95.623239];
Zipcodes['66618'] = [39.139508, -95.748267];
Zipcodes['66619'] = [38.947079, -95.690198];
Zipcodes['66621'] = [39.03312, -95.70238];
Zipcodes['66622'] = [39.026315, -95.722224];
Zipcodes['66701'] = [37.829814, -94.758937];
Zipcodes['66710'] = [37.551598, -95.624499];
Zipcodes['66711'] = [37.637096, -94.685001];
Zipcodes['66712'] = [37.564876, -94.714519];
Zipcodes['66713'] = [37.040492, -94.785294];
Zipcodes['66714'] = [37.636602, -95.698621];
Zipcodes['66716'] = [37.938576, -95.038044];
Zipcodes['66717'] = [37.699465, -95.681038];
Zipcodes['66720'] = [37.648326, -95.457198];
Zipcodes['66724'] = [37.346978, -94.856724];
Zipcodes['66725'] = [37.156583, -94.895319];
Zipcodes['66732'] = [37.804211, -95.192631];
Zipcodes['66733'] = [37.590295, -95.254947];
Zipcodes['66734'] = [37.613816, -94.819509];
Zipcodes['66735'] = [37.517994, -94.715108];
Zipcodes['66736'] = [37.551477, -95.861095];
Zipcodes['66738'] = [38.013752, -94.723418];
Zipcodes['66739'] = [37.12578, -94.655261];
Zipcodes['66740'] = [37.465762, -95.371007];
Zipcodes['66741'] = [37.720033, -94.663918];
Zipcodes['66742'] = [37.919584, -95.348902];
Zipcodes['66743'] = [37.508867, -94.879644];
Zipcodes['66746'] = [37.667983, -94.952979];
Zipcodes['66748'] = [37.799844, -95.436048];
Zipcodes['66749'] = [37.933761, -95.415032];
Zipcodes['66751'] = [37.9296, -95.289751];
Zipcodes['66753'] = [37.363794, -95.016159];
Zipcodes['66754'] = [38.004856, -94.885625];
Zipcodes['66755'] = [37.91177, -95.168695];
Zipcodes['66756'] = [37.547537, -94.641053];
Zipcodes['66757'] = [37.414353, -95.698279];
Zipcodes['66758'] = [38.015934, -95.586909];
Zipcodes['66760'] = [37.348316, -94.624352];
Zipcodes['66761'] = [37.911039, -95.570175];
Zipcodes['66762'] = [37.393878, -94.710478];
Zipcodes['66763'] = [37.463236, -94.697764];
Zipcodes['66767'] = [38.078779, -94.709796];
Zipcodes['66769'] = [37.856527, -94.889957];
Zipcodes['66770'] = [37.080735, -94.715342];
Zipcodes['66771'] = [37.494851, -95.145787];
Zipcodes['66772'] = [37.740201, -95.187512];
Zipcodes['66773'] = [37.2594, -94.80545];
Zipcodes['66775'] = [37.701935, -95.148771];
Zipcodes['66776'] = [37.467579, -95.497665];
Zipcodes['66777'] = [37.787226, -95.975742];
Zipcodes['66778'] = [37.00045, -94.83891];
Zipcodes['66779'] = [37.829395, -94.965819];
Zipcodes['66780'] = [37.610325, -95.055722];
Zipcodes['66781'] = [37.288583, -94.733491];
Zipcodes['66782'] = [37.284955, -94.926099];
Zipcodes['66783'] = [37.876775, -95.733175];
Zipcodes['66801'] = [38.411743, -96.223507];
Zipcodes['66830'] = [38.596073, -96.091023];
Zipcodes['66833'] = [38.695591, -96.195012];
Zipcodes['66834'] = [38.862121, -96.455358];
Zipcodes['66835'] = [38.526914, -96.266195];
Zipcodes['66838'] = [38.555437, -96.800671];
Zipcodes['66839'] = [38.217399, -95.748127];
Zipcodes['66840'] = [38.076924, -96.876735];
Zipcodes['66842'] = [38.024842, -96.657047];
Zipcodes['66843'] = [38.222479, -96.740487];
Zipcodes['66845'] = [38.298731, -96.508392];
Zipcodes['66846'] = [38.64016, -96.473816];
Zipcodes['66849'] = [38.877505, -96.603227];
Zipcodes['66850'] = [38.407683, -96.712994];
Zipcodes['66851'] = [38.217863, -96.918638];
Zipcodes['66852'] = [38.071642, -95.895277];
Zipcodes['66853'] = [38.001895, -96.235303];
Zipcodes['66854'] = [38.252132, -95.988479];
Zipcodes['66856'] = [38.397806, -95.811788];
Zipcodes['66857'] = [38.103409, -95.633361];
Zipcodes['66858'] = [38.472506, -96.92242];
Zipcodes['66859'] = [38.556888, -96.964887];
Zipcodes['66860'] = [38.12728, -96.165629];
Zipcodes['66861'] = [38.364105, -97.006364];
Zipcodes['66862'] = [38.154999, -96.556128];
Zipcodes['66863'] = [37.833687, -96.076297];
Zipcodes['66864'] = [38.354887, -95.981495];
Zipcodes['66865'] = [38.208888, -96.274533];
Zipcodes['66866'] = [38.174049, -97.103198];
Zipcodes['66868'] = [38.544773, -95.979419];
Zipcodes['66869'] = [38.46881, -96.515162];
Zipcodes['66870'] = [37.932517, -95.976085];
Zipcodes['66871'] = [38.362643, -95.610225];
Zipcodes['66872'] = [38.798958, -96.763708];
Zipcodes['66873'] = [38.612773, -96.666147];
Zipcodes['66901'] = [39.560035, -97.63895];
Zipcodes['66930'] = [39.704618, -97.490688];
Zipcodes['66932'] = [39.779487, -98.930885];
Zipcodes['66933'] = [39.675419, -96.872319];
Zipcodes['66935'] = [39.826457, -97.635469];
Zipcodes['66936'] = [39.918731, -98.301928];
Zipcodes['66937'] = [39.599827, -97.26481];
Zipcodes['66938'] = [39.589651, -97.444346];
Zipcodes['66939'] = [39.85286, -97.90429];
Zipcodes['66940'] = [39.802418, -97.453186];
Zipcodes['66941'] = [39.830775, -98.447992];
Zipcodes['66942'] = [39.7908, -97.997936];
Zipcodes['66943'] = [39.671811, -96.966281];
Zipcodes['66944'] = [39.840229, -97.293099];
Zipcodes['66945'] = [39.88701, -96.878386];
Zipcodes['66946'] = [39.967939, -96.998839];
Zipcodes['66948'] = [39.59825, -97.852349];
Zipcodes['66949'] = [39.644677, -98.205176];
Zipcodes['66951'] = [39.864211, -99.037761];
Zipcodes['66952'] = [39.835805, -98.604785];
Zipcodes['66953'] = [39.679524, -97.117817];
Zipcodes['66955'] = [39.980007, -97.32062];
Zipcodes['66956'] = [39.796509, -98.215629];
Zipcodes['66958'] = [39.889446, -97.177186];
Zipcodes['66959'] = [39.922959, -97.529682];
Zipcodes['66960'] = [39.935732, -97.42164];
Zipcodes['66961'] = [39.693369, -97.7756];
Zipcodes['66962'] = [39.599322, -97.114331];
Zipcodes['66963'] = [39.641884, -98.014288];
Zipcodes['66964'] = [39.934388, -97.793764];
Zipcodes['66966'] = [39.783664, -97.762232];
Zipcodes['66967'] = [39.819725, -98.794227];
Zipcodes['66968'] = [39.835272, -97.063088];
Zipcodes['66970'] = [39.950165, -98.016856];
Zipcodes['67001'] = [37.757001, -97.635437];
Zipcodes['67002'] = [37.700824, -97.106918];
Zipcodes['67003'] = [37.104737, -98.05679];
Zipcodes['67004'] = [37.281587, -97.762919];
Zipcodes['67005'] = [37.068836, -96.995926];
Zipcodes['67008'] = [37.488584, -96.752899];
Zipcodes['67009'] = [37.225287, -98.268794];
Zipcodes['67010'] = [37.655436, -96.976049];
Zipcodes['67012'] = [37.657681, -96.558732];
Zipcodes['67013'] = [37.381869, -97.26822];
Zipcodes['67016'] = [37.888815, -97.518173];
Zipcodes['67017'] = [37.827762, -97.111646];
Zipcodes['67018'] = [37.071269, -97.853695];
Zipcodes['67019'] = [37.314492, -96.784487];
Zipcodes['67020'] = [38.007932, -97.675907];
Zipcodes['67021'] = [37.776118, -98.910519];
Zipcodes['67022'] = [37.076932, -97.626852];
Zipcodes['67023'] = [37.346062, -96.592276];
Zipcodes['67024'] = [37.11281, -96.492173];
Zipcodes['67025'] = [37.642355, -97.78496];
Zipcodes['67026'] = [37.511055, -97.491983];
Zipcodes['67028'] = [37.476462, -98.97979];
Zipcodes['67029'] = [37.171432, -99.279961];
Zipcodes['67030'] = [37.785616, -97.546808];
Zipcodes['67031'] = [37.410484, -97.63512];
Zipcodes['67035'] = [37.648184, -98.394963];
Zipcodes['67036'] = [37.289848, -97.882621];
Zipcodes['67037'] = [37.571832, -97.217235];
Zipcodes['67038'] = [37.120165, -96.680273];
Zipcodes['67039'] = [37.527668, -96.97429];
Zipcodes['67041'] = [38.054094, -97.127536];
Zipcodes['67042'] = [37.857264, -96.780718];
Zipcodes['67045'] = [37.854518, -96.322318];
Zipcodes['67047'] = [37.639619, -96.0503];
Zipcodes['67049'] = [37.170582, -97.846447];
Zipcodes['67050'] = [37.674517, -97.67745];
Zipcodes['67051'] = [37.089302, -97.196565];
Zipcodes['67052'] = [37.657727, -97.574242];
Zipcodes['67053'] = [38.247072, -97.345742];
Zipcodes['67054'] = [37.57504, -99.316072];
Zipcodes['67055'] = [37.783374, -97.203149];
Zipcodes['67056'] = [38.019344, -97.523472];
Zipcodes['67057'] = [37.07054, -98.780083];
Zipcodes['67058'] = [37.317916, -98.061352];
Zipcodes['67059'] = [37.658966, -99.097013];
Zipcodes['67060'] = [37.542973, -97.363698];
Zipcodes['67061'] = [37.082321, -98.377237];
Zipcodes['67062'] = [38.143105, -97.432958];
Zipcodes['67063'] = [38.340735, -97.233749];
Zipcodes['67065'] = [37.478079, -98.528675];
Zipcodes['67066'] = [37.773726, -98.757991];
Zipcodes['67067'] = [37.794756, -97.26646];
Zipcodes['67068'] = [37.621851, -98.119542];
Zipcodes['67070'] = [37.072489, -98.553014];
Zipcodes['67071'] = [37.208622, -98.889662];
Zipcodes['67072'] = [37.535234, -96.627995];
Zipcodes['67073'] = [38.389068, -97.320426];
Zipcodes['67074'] = [37.670184, -96.736046];
Zipcodes['67101'] = [37.785277, -97.464182];
Zipcodes['67102'] = [37.027431, -96.799812];
Zipcodes['67103'] = [37.266039, -97.555077];
Zipcodes['67104'] = [37.265078, -98.664433];
Zipcodes['67105'] = [37.219989, -97.682944];
Zipcodes['67106'] = [37.463347, -97.758054];
Zipcodes['67107'] = [38.200195, -97.542678];
Zipcodes['67108'] = [37.816344, -97.690681];
Zipcodes['67109'] = [37.561275, -99.485817];
Zipcodes['67110'] = [37.469649, -97.223627];
Zipcodes['67111'] = [37.615011, -97.937844];
Zipcodes['67112'] = [37.452778, -98.418891];
Zipcodes['67114'] = [38.067626, -97.291649];
Zipcodes['67117'] = [38.074214, -97.346242];
Zipcodes['67118'] = [37.481665, -97.854212];
Zipcodes['67119'] = [37.23869, -97.176546];
Zipcodes['67120'] = [37.468085, -97.37377];
Zipcodes['67122'] = [37.619001, -96.428116];
Zipcodes['67123'] = [37.948316, -96.996293];
Zipcodes['67124'] = [37.634439, -98.773642];
Zipcodes['67127'] = [37.16482, -99.487114];
Zipcodes['67131'] = [37.425145, -96.9469];
Zipcodes['67132'] = [37.850881, -96.584695];
Zipcodes['67133'] = [37.577615, -97.107094];
Zipcodes['67134'] = [37.476985, -98.67207];
Zipcodes['67135'] = [37.914249, -97.453602];
Zipcodes['67137'] = [37.631501, -96.227605];
Zipcodes['67138'] = [37.260232, -98.419614];
Zipcodes['67140'] = [37.079884, -97.357466];
Zipcodes['67142'] = [37.452585, -98.11362];
Zipcodes['67143'] = [37.319166, -98.974517];
Zipcodes['67144'] = [37.81917, -97.003301];
Zipcodes['67146'] = [37.384475, -97.104616];
Zipcodes['67147'] = [37.865125, -97.305039];
Zipcodes['67149'] = [37.539732, -97.625937];
Zipcodes['67150'] = [37.028169, -98.197912];
Zipcodes['67151'] = [38.142146, -97.257065];
Zipcodes['67152'] = [37.263003, -97.411479];
Zipcodes['67154'] = [37.963538, -97.122174];
Zipcodes['67155'] = [37.274948, -99.137561];
Zipcodes['67156'] = [37.248449, -96.963204];
Zipcodes['67159'] = [37.427657, -98.309416];
Zipcodes['67202'] = [37.686984, -97.33492];
Zipcodes['67203'] = [37.698891, -97.364641];
Zipcodes['67204'] = [37.762497, -97.358547];
Zipcodes['67205'] = [37.749466, -97.423567];
Zipcodes['67206'] = [37.703734, -97.224505];
Zipcodes['67207'] = [37.665927, -97.230236];
Zipcodes['67208'] = [37.703847, -97.279266];
Zipcodes['67209'] = [37.651448, -97.42819];
Zipcodes['67210'] = [37.642884, -97.210096];
Zipcodes['67211'] = [37.667063, -97.317043];
Zipcodes['67212'] = [37.702404, -97.438899];
Zipcodes['67213'] = [37.667472, -97.364129];
Zipcodes['67214'] = [37.707178, -97.31775];
Zipcodes['67215'] = [37.61587, -97.435032];
Zipcodes['67216'] = [37.611241, -97.312617];
Zipcodes['67217'] = [37.617773, -97.362058];
Zipcodes['67218'] = [37.668511, -97.280361];
Zipcodes['67219'] = [37.769251, -97.316284];
Zipcodes['67220'] = [37.751156, -97.278361];
Zipcodes['67221'] = [37.635429, -97.258223];
Zipcodes['67223'] = [37.742442, -97.495566];
Zipcodes['67226'] = [37.768695, -97.220336];
Zipcodes['67227'] = [37.626915, -97.501132];
Zipcodes['67228'] = [37.777903, -97.180625];
Zipcodes['67230'] = [37.681467, -97.167833];
Zipcodes['67232'] = [37.643807, -97.162328];
Zipcodes['67235'] = [37.688993, -97.500046];
Zipcodes['67260'] = [37.719325, -97.293383];
Zipcodes['67301'] = [37.20823, -95.756081];
Zipcodes['67330'] = [37.158055, -95.309148];
Zipcodes['67332'] = [37.043368, -95.236567];
Zipcodes['67333'] = [37.043544, -95.865924];
Zipcodes['67334'] = [37.023897, -96.177144];
Zipcodes['67335'] = [37.274582, -95.526745];
Zipcodes['67336'] = [37.069267, -95.068849];
Zipcodes['67337'] = [37.053665, -95.598198];
Zipcodes['67340'] = [37.059513, -95.712198];
Zipcodes['67341'] = [37.34437, -95.428356];
Zipcodes['67342'] = [37.060121, -95.355286];
Zipcodes['67344'] = [37.287469, -95.953898];
Zipcodes['67345'] = [37.370251, -96.20041];
Zipcodes['67346'] = [37.321407, -96.448189];
Zipcodes['67347'] = [37.154402, -95.951547];
Zipcodes['67349'] = [37.495978, -96.280486];
Zipcodes['67351'] = [37.143556, -95.568937];
Zipcodes['67352'] = [37.333195, -96.090692];
Zipcodes['67353'] = [37.308381, -96.317193];
Zipcodes['67354'] = [37.206453, -95.411526];
Zipcodes['67355'] = [37.03167, -96.00584];
Zipcodes['67356'] = [37.176257, -95.145772];
Zipcodes['67357'] = [37.338103, -95.253064];
Zipcodes['67360'] = [37.038024, -96.106561];
Zipcodes['67361'] = [37.137644, -96.217607];
Zipcodes['67363'] = [37.332888, -95.719728];
Zipcodes['67364'] = [37.038569, -95.824277];
Zipcodes['67401'] = [38.831832, -97.65455];
Zipcodes['67410'] = [38.956476, -97.21909];
Zipcodes['67416'] = [38.670931, -97.564987];
Zipcodes['67417'] = [39.433969, -97.545581];
Zipcodes['67418'] = [39.18491, -98.051868];
Zipcodes['67420'] = [39.400935, -98.085468];
Zipcodes['67422'] = [39.032152, -97.59933];
Zipcodes['67423'] = [38.96562, -97.974945];
Zipcodes['67425'] = [38.782025, -97.907988];
Zipcodes['67427'] = [38.498463, -98.399346];
Zipcodes['67428'] = [38.380425, -97.417317];
Zipcodes['67430'] = [39.575518, -98.442139];
Zipcodes['67431'] = [38.951046, -97.012425];
Zipcodes['67432'] = [39.330815, -97.181145];
Zipcodes['67436'] = [39.280781, -97.73046];
Zipcodes['67437'] = [39.506757, -98.557352];
Zipcodes['67438'] = [38.501985, -97.272112];
Zipcodes['67439'] = [38.759762, -98.221114];
Zipcodes['67441'] = [38.878591, -97.102832];
Zipcodes['67442'] = [38.646058, -97.775762];
Zipcodes['67443'] = [38.380609, -97.525769];
Zipcodes['67444'] = [38.551066, -98.11842];
Zipcodes['67445'] = [39.371384, -97.824151];
Zipcodes['67446'] = [39.461302, -98.325251];
Zipcodes['67447'] = [39.474587, -96.968674];
Zipcodes['67448'] = [38.664369, -97.402645];
Zipcodes['67449'] = [38.682946, -96.896947];
Zipcodes['67450'] = [38.626311, -98.457346];
Zipcodes['67451'] = [38.700938, -97.132001];
Zipcodes['67452'] = [39.241286, -98.368356];
Zipcodes['67454'] = [38.69884, -98.112105];
Zipcodes['67455'] = [39.028528, -98.162663];
Zipcodes['67456'] = [38.59624, -97.680439];
Zipcodes['67457'] = [38.38547, -97.987741];
Zipcodes['67458'] = [39.167764, -97.311485];
Zipcodes['67459'] = [38.603854, -98.345792];
Zipcodes['67460'] = [38.382552, -97.699453];
Zipcodes['67464'] = [38.55862, -97.897299];
Zipcodes['67466'] = [39.327815, -97.472357];
Zipcodes['67467'] = [39.142355, -97.69582];
Zipcodes['67468'] = [39.459362, -97.25749];
Zipcodes['67470'] = [38.922721, -97.507633];
Zipcodes['67473'] = [39.378657, -98.740571];
Zipcodes['67474'] = [39.595779, -98.701985];
Zipcodes['67475'] = [38.589659, -97.055151];
Zipcodes['67476'] = [38.544278, -97.436217];
Zipcodes['67478'] = [39.385696, -97.949921];
Zipcodes['67480'] = [38.965807, -97.400502];
Zipcodes['67481'] = [39.012909, -98.388594];
Zipcodes['67482'] = [39.026891, -97.259713];
Zipcodes['67483'] = [38.558165, -97.198369];
Zipcodes['67484'] = [38.995746, -97.850499];
Zipcodes['67485'] = [39.315729, -98.497175];
Zipcodes['67487'] = [39.172892, -97.064316];
Zipcodes['67490'] = [38.803346, -98.444885];
Zipcodes['67491'] = [38.383354, -97.901877];
Zipcodes['67492'] = [38.819721, -96.959508];
Zipcodes['67501'] = [37.977493, -97.926452];
Zipcodes['67502'] = [38.126714, -97.929805];
Zipcodes['67505'] = [38.031355, -97.9458];
Zipcodes['67510'] = [37.992123, -98.206508];
Zipcodes['67511'] = [38.433638, -99.050582];
Zipcodes['67512'] = [38.239061, -98.3351];
Zipcodes['67513'] = [38.445112, -99.538302];
Zipcodes['67514'] = [37.861953, -98.202381];
Zipcodes['67515'] = [38.599313, -100.065329];
Zipcodes['67516'] = [38.392958, -99.686778];
Zipcodes['67518'] = [38.403991, -100.17337];
Zipcodes['67519'] = [37.935674, -99.102922];
Zipcodes['67520'] = [38.589677, -99.187712];
Zipcodes['67521'] = [38.628167, -99.712902];
Zipcodes['67522'] = [38.122196, -97.747612];
Zipcodes['67523'] = [38.193103, -99.565606];
Zipcodes['67524'] = [38.384234, -98.378211];
Zipcodes['67525'] = [38.572533, -98.563033];
Zipcodes['67526'] = [38.297376, -98.544467];
Zipcodes['67529'] = [38.076237, -99.254408];
Zipcodes['67530'] = [38.352915, -98.798117];
Zipcodes['67543'] = [37.890194, -97.8023];
Zipcodes['67544'] = [38.569827, -98.750563];
Zipcodes['67545'] = [38.180598, -98.647621];
Zipcodes['67546'] = [38.224507, -97.817285];
Zipcodes['67547'] = [37.938109, -99.472318];
Zipcodes['67548'] = [38.591491, -99.333997];
Zipcodes['67550'] = [38.179706, -99.126865];
Zipcodes['67552'] = [37.860545, -99.236466];
Zipcodes['67553'] = [38.654479, -99.320027];
Zipcodes['67554'] = [38.371059, -98.184884];
Zipcodes['67556'] = [38.604619, -99.520035];
Zipcodes['67557'] = [37.919958, -98.958293];
Zipcodes['67559'] = [38.372351, -99.458927];
Zipcodes['67560'] = [38.399335, -99.939209];
Zipcodes['67561'] = [38.109017, -98.096853];
Zipcodes['67563'] = [37.848214, -99.555359];
Zipcodes['67564'] = [38.529013, -98.935649];
Zipcodes['67565'] = [38.600591, -99.044062];
Zipcodes['67566'] = [37.948658, -98.095317];
Zipcodes['67567'] = [38.264735, -98.971998];
Zipcodes['67568'] = [38.020901, -98.316736];
Zipcodes['67570'] = [37.787407, -97.964176];
Zipcodes['67572'] = [38.689472, -99.917014];
Zipcodes['67573'] = [38.264522, -98.412196];
Zipcodes['67574'] = [38.226164, -99.396759];
Zipcodes['67575'] = [38.401956, -99.256489];
Zipcodes['67576'] = [38.035977, -98.795217];
Zipcodes['67578'] = [37.969088, -98.586679];
Zipcodes['67579'] = [38.188579, -98.235601];
Zipcodes['67581'] = [37.991713, -98.408385];
Zipcodes['67583'] = [37.799311, -98.474937];
Zipcodes['67584'] = [38.681972, -100.163736];
Zipcodes['67601'] = [38.873553, -99.34086];
Zipcodes['67621'] = [39.827807, -99.128505];
Zipcodes['67622'] = [39.904321, -99.726987];
Zipcodes['67623'] = [39.444471, -98.954264];
Zipcodes['67625'] = [39.389004, -99.685595];
Zipcodes['67626'] = [38.88157, -98.716946];
Zipcodes['67627'] = [38.922235, -99.213448];
Zipcodes['67628'] = [39.627384, -99.001622];
Zipcodes['67629'] = [39.699951, -100.199353];
Zipcodes['67631'] = [39.009136, -100.081392];
Zipcodes['67632'] = [39.329657, -99.581063];
Zipcodes['67634'] = [38.823292, -98.604391];
Zipcodes['67635'] = [39.600342, -100.451227];
Zipcodes['67637'] = [38.94012, -99.589967];
Zipcodes['67638'] = [39.632689, -98.847107];
Zipcodes['67639'] = [39.62452, -99.318496];
Zipcodes['67640'] = [38.896752, -99.046483];
Zipcodes['67642'] = [39.368629, -99.83097];
Zipcodes['67643'] = [39.642956, -100.288609];
Zipcodes['67644'] = [39.640667, -99.146689];
Zipcodes['67645'] = [39.60201, -99.894163];
Zipcodes['67646'] = [39.617594, -99.594174];
Zipcodes['67647'] = [39.936787, -99.526067];
Zipcodes['67648'] = [39.087245, -98.58037];
Zipcodes['67649'] = [39.140977, -98.688549];
Zipcodes['67650'] = [39.37267, -100.102673];
Zipcodes['67651'] = [39.205783, -99.03991];
Zipcodes['67653'] = [39.9067, -100.208054];
Zipcodes['67654'] = [39.835348, -99.954228];
Zipcodes['67656'] = [38.980998, -99.754596];
Zipcodes['67657'] = [39.212621, -99.609557];
Zipcodes['67658'] = [39.115239, -98.934375];
Zipcodes['67659'] = [39.322801, -100.005362];
Zipcodes['67660'] = [38.717542, -99.148098];
Zipcodes['67661'] = [39.820462, -99.33761];
Zipcodes['67663'] = [39.212155, -99.334632];
Zipcodes['67664'] = [39.818077, -99.583111];
Zipcodes['67665'] = [38.849085, -98.88253];
Zipcodes['67667'] = [38.712895, -99.332255];
Zipcodes['67669'] = [39.458229, -99.34415];
Zipcodes['67671'] = [38.874863, -99.141414];
Zipcodes['67672'] = [38.993852, -99.905192];
Zipcodes['67673'] = [39.16978, -98.814055];
Zipcodes['67674'] = [38.888967, -99.096496];
Zipcodes['67675'] = [39.409508, -99.089312];
Zipcodes['67701'] = [39.394217, -101.050067];
Zipcodes['67730'] = [39.808975, -101.111247];
Zipcodes['67731'] = [39.765932, -101.537318];
Zipcodes['67732'] = [39.3744, -101.351567];
Zipcodes['67733'] = [39.329761, -101.506468];
Zipcodes['67734'] = [39.542948, -100.88884];
Zipcodes['67735'] = [39.346293, -101.773854];
Zipcodes['67736'] = [38.846256, -100.448783];
Zipcodes['67737'] = [39.088682, -100.493179];
Zipcodes['67738'] = [38.999341, -100.650512];
Zipcodes['67739'] = [39.878452, -100.812808];
Zipcodes['67740'] = [39.356281, -100.364317];
Zipcodes['67741'] = [39.38688, -102.0048];
Zipcodes['67743'] = [39.388082, -101.241257];
Zipcodes['67744'] = [39.860825, -100.941108];
Zipcodes['67745'] = [39.807589, -101.345391];
Zipcodes['67747'] = [39.042059, -101.061021];
Zipcodes['67748'] = [39.070273, -100.859845];
Zipcodes['67749'] = [39.834858, -100.562519];
Zipcodes['67751'] = [39.117291, -100.35627];
Zipcodes['67752'] = [38.99481, -100.232863];
Zipcodes['67753'] = [39.432606, -100.747899];
Zipcodes['67756'] = [39.797204, -101.836735];
Zipcodes['67757'] = [39.552908, -100.617249];
Zipcodes['67758'] = [38.897753, -101.778701];
Zipcodes['67761'] = [38.951098, -101.526474];
Zipcodes['67762'] = [38.841393, -102.000884];
Zipcodes['67764'] = [38.870091, -101.157571];
Zipcodes['67801'] = [37.730309, -100.060028];
Zipcodes['67831'] = [37.185621, -99.79598];
Zipcodes['67834'] = [37.534619, -99.620072];
Zipcodes['67835'] = [37.986646, -100.309167];
Zipcodes['67836'] = [38.03961, -101.994562];
Zipcodes['67837'] = [37.554824, -100.675137];
Zipcodes['67838'] = [38.058041, -101.133767];
Zipcodes['67839'] = [38.470501, -100.441624];
Zipcodes['67840'] = [37.052337, -99.952272];
Zipcodes['67841'] = [37.628481, -100.225933];
Zipcodes['67842'] = [37.547883, -99.765317];
Zipcodes['67843'] = [37.730253, -99.93724];
Zipcodes['67844'] = [37.376942, -100.197149];
Zipcodes['67846'] = [38.026003, -100.778949];
Zipcodes['67849'] = [38.160097, -99.72895];
Zipcodes['67850'] = [38.631411, -100.638969];
Zipcodes['67851'] = [38.089153, -101.015668];
Zipcodes['67853'] = [37.885578, -100.528171];
Zipcodes['67854'] = [38.100966, -100.001318];
Zipcodes['67855'] = [37.540963, -101.698775];
Zipcodes['67857'] = [37.993894, -101.547801];
Zipcodes['67859'] = [37.235142, -100.770135];
Zipcodes['67860'] = [38.022313, -101.299594];
Zipcodes['67861'] = [38.473999, -101.390429];
Zipcodes['67862'] = [37.488076, -101.952538];
Zipcodes['67863'] = [38.565208, -101.208173];
Zipcodes['67864'] = [37.176517, -100.31727];
Zipcodes['67865'] = [37.435511, -99.96837];
Zipcodes['67867'] = [37.56612, -100.455678];
Zipcodes['67868'] = [37.820265, -100.710263];
Zipcodes['67869'] = [37.249646, -100.56255];
Zipcodes['67870'] = [37.529415, -101.021561];
Zipcodes['67871'] = [38.492375, -100.916966];
Zipcodes['67876'] = [37.862844, -99.743464];
Zipcodes['67877'] = [37.554925, -100.843926];
Zipcodes['67878'] = [38.0048, -101.810208];
Zipcodes['67879'] = [38.470775, -101.794586];
Zipcodes['67880'] = [37.598434, -101.322308];
Zipcodes['67882'] = [37.804181, -99.879885];
Zipcodes['67901'] = [37.097866, -100.895222];
Zipcodes['67950'] = [37.062774, -101.867684];
Zipcodes['67951'] = [37.161192, -101.328612];
Zipcodes['67952'] = [37.293085, -101.122238];
Zipcodes['67953'] = [37.221665, -101.85485];
Zipcodes['67954'] = [37.178005, -101.619073];
Zipcodes['68001'] = [41.33371, -96.954903];
Zipcodes['68002'] = [41.499022, -96.337716];
Zipcodes['68003'] = [41.069795, -96.38148];
Zipcodes['68004'] = [42.009736, -96.623048];
Zipcodes['68005'] = [41.141142, -95.894004];
Zipcodes['68007'] = [41.37344, -96.184378];
Zipcodes['68008'] = [41.540632, -96.169877];
Zipcodes['68010'] = [41.257232, -96.133503];
Zipcodes['68014'] = [41.282697, -96.963094];
Zipcodes['68015'] = [41.386614, -96.649718];
Zipcodes['68016'] = [41.021841, -96.10172];
Zipcodes['68017'] = [41.057884, -96.662056];
Zipcodes['68018'] = [41.302097, -96.615195];
Zipcodes['68019'] = [41.781019, -96.375352];
Zipcodes['68020'] = [41.971179, -96.264175];
Zipcodes['68022'] = [41.277405, -96.246406];
Zipcodes['68023'] = [41.470121, -96.022357];
Zipcodes['68025'] = [41.437899, -96.491294];
Zipcodes['68028'] = [41.106888, -96.261771];
Zipcodes['68029'] = [41.657003, -96.273537];
Zipcodes['68030'] = [42.319604, -96.461936];
Zipcodes['68031'] = [41.63955, -96.533211];
Zipcodes['68033'] = [41.129405, -96.524848];
Zipcodes['68034'] = [41.451684, -96.227542];
Zipcodes['68036'] = [41.387375, -96.952345];
Zipcodes['68037'] = [40.983506, -96.130047];
Zipcodes['68038'] = [41.948591, -96.434638];
Zipcodes['68039'] = [42.113604, -96.340497];
Zipcodes['68040'] = [41.287287, -96.740022];
Zipcodes['68041'] = [41.241423, -96.493389];
Zipcodes['68042'] = [41.093728, -96.436419];
Zipcodes['68044'] = [41.547591, -96.448311];
Zipcodes['68045'] = [41.825554, -96.492473];
Zipcodes['68046'] = [41.121818, -96.052772];
Zipcodes['68047'] = [42.111188, -96.733089];
Zipcodes['68048'] = [40.98408, -95.942013];
Zipcodes['68050'] = [41.300053, -96.842321];
Zipcodes['68055'] = [42.054161, -96.47219];
Zipcodes['68056'] = [41.141242, -95.904413];
Zipcodes['68057'] = [41.66187, -96.723527];
Zipcodes['68058'] = [41.003954, -96.244019];
Zipcodes['68059'] = [41.06445, -96.158755];
Zipcodes['68061'] = [41.80659, -96.219182];
Zipcodes['68062'] = [42.198845, -96.67751];
Zipcodes['68063'] = [41.723443, -96.496909];
Zipcodes['68064'] = [41.345285, -96.353772];
Zipcodes['68065'] = [41.077914, -96.8463];
Zipcodes['68066'] = [41.184632, -96.618892];
Zipcodes['68067'] = [42.156883, -96.488163];
Zipcodes['68068'] = [41.397626, -96.207454];
Zipcodes['68069'] = [41.241065, -96.315939];
Zipcodes['68070'] = [41.196681, -96.800948];
Zipcodes['68071'] = [42.237863, -96.475436];
Zipcodes['68072'] = [41.610125, -96.504695];
Zipcodes['68073'] = [41.239178, -96.416488];
Zipcodes['68102'] = [41.261537, -95.932558];
Zipcodes['68104'] = [41.295431, -96.002289];
Zipcodes['68105'] = [41.240735, -95.964919];
Zipcodes['68106'] = [41.238785, -96.002274];
Zipcodes['68107'] = [41.209065, -95.951808];
Zipcodes['68108'] = [41.237556, -95.931392];
Zipcodes['68110'] = [41.302425, -95.892763];
Zipcodes['68111'] = [41.295458, -95.964916];
Zipcodes['68112'] = [41.384177, -95.966427];
Zipcodes['68113'] = [41.117881, -95.906546];
Zipcodes['68114'] = [41.263139, -96.05204];
Zipcodes['68116'] = [41.298493, -96.165988];
Zipcodes['68117'] = [41.206571, -96.003104];
Zipcodes['68118'] = [41.263023, -96.178105];
Zipcodes['68122'] = [41.363392, -96.051876];
Zipcodes['68123'] = [41.102245, -95.947634];
Zipcodes['68124'] = [41.235443, -96.052175];
Zipcodes['68127'] = [41.206275, -96.0539];
Zipcodes['68128'] = [41.178452, -96.07087];
Zipcodes['68130'] = [41.233443, -96.197982];
Zipcodes['68131'] = [41.26432, -95.964744];
Zipcodes['68132'] = [41.265016, -96.002958];
Zipcodes['68133'] = [41.112304, -95.997685];
Zipcodes['68134'] = [41.296304, -96.050687];
Zipcodes['68135'] = [41.205868, -96.193319];
Zipcodes['68136'] = [41.168052, -96.184662];
Zipcodes['68137'] = [41.205468, -96.118756];
Zipcodes['68138'] = [41.157433, -96.131116];
Zipcodes['68142'] = [41.369212, -96.101872];
Zipcodes['68144'] = [41.234983, -96.11845];
Zipcodes['68147'] = [41.17653, -95.956793];
Zipcodes['68152'] = [41.367418, -95.996625];
Zipcodes['68154'] = [41.265517, -96.114976];
Zipcodes['68157'] = [41.178739, -95.993774];
Zipcodes['68164'] = [41.297022, -96.105868];
Zipcodes['68178'] = [41.265361, -95.945394];
Zipcodes['68182'] = [41.257745, -96.010674];
Zipcodes['68183'] = [41.257093, -95.939786];
Zipcodes['68301'] = [40.470495, -96.524982];
Zipcodes['68303'] = [40.262348, -97.415341];
Zipcodes['68304'] = [40.892599, -96.379841];
Zipcodes['68305'] = [40.370253, -95.864948];
Zipcodes['68307'] = [40.79224, -96.148576];
Zipcodes['68309'] = [40.049051, -96.572866];
Zipcodes['68310'] = [40.249821, -96.74949];
Zipcodes['68313'] = [40.783701, -97.271337];
Zipcodes['68314'] = [41.016148, -97.022214];
Zipcodes['68315'] = [40.243334, -97.536515];
Zipcodes['68316'] = [41.013204, -97.613575];
Zipcodes['68317'] = [40.673244, -96.504507];
Zipcodes['68318'] = [40.164921, -96.648576];
Zipcodes['68319'] = [40.937755, -97.75971];
Zipcodes['68320'] = [40.479563, -95.946634];
Zipcodes['68321'] = [40.400056, -95.696669];
Zipcodes['68322'] = [40.329898, -97.54779];
Zipcodes['68323'] = [40.128391, -96.372773];
Zipcodes['68324'] = [40.554076, -96.290145];
Zipcodes['68325'] = [40.010667, -97.755297];
Zipcodes['68326'] = [40.283769, -97.684106];
Zipcodes['68327'] = [40.012886, -97.621764];
Zipcodes['68328'] = [40.474387, -96.840805];
Zipcodes['68329'] = [40.497921, -96.169239];
Zipcodes['68330'] = [40.707409, -97.341196];
Zipcodes['68331'] = [40.472889, -96.714812];
Zipcodes['68332'] = [40.327928, -96.410319];
Zipcodes['68333'] = [40.620687, -96.950188];
Zipcodes['68335'] = [40.296696, -97.810381];
Zipcodes['68336'] = [40.965855, -96.690917];
Zipcodes['68337'] = [40.098408, -95.831894];
Zipcodes['68338'] = [40.313729, -97.246006];
Zipcodes['68339'] = [40.732053, -96.870103];
Zipcodes['68340'] = [40.126412, -97.738518];
Zipcodes['68341'] = [40.38729, -96.920794];
Zipcodes['68342'] = [40.103982, -96.947486];
Zipcodes['68343'] = [40.618234, -97.132103];
Zipcodes['68344'] = [40.566742, -96.399847];
Zipcodes['68345'] = [40.036455, -96.019176];
Zipcodes['68346'] = [40.660639, -96.048762];
Zipcodes['68347'] = [40.805502, -96.416644];
Zipcodes['68348'] = [40.298805, -96.115779];
Zipcodes['68349'] = [40.83163, -96.296896];
Zipcodes['68350'] = [40.030491, -97.094664];
Zipcodes['68351'] = [40.650778, -97.434866];
Zipcodes['68352'] = [40.152084, -97.22602];
Zipcodes['68354'] = [40.638995, -97.579833];
Zipcodes['68355'] = [40.123524, -95.567952];
Zipcodes['68357'] = [40.312925, -96.547756];
Zipcodes['68358'] = [40.532318, -96.609426];
Zipcodes['68359'] = [40.631292, -97.284829];
Zipcodes['68360'] = [40.950556, -96.956412];
Zipcodes['68361'] = [40.501353, -97.603498];
Zipcodes['68362'] = [40.154197, -97.420811];
Zipcodes['68364'] = [40.836794, -97.230387];
Zipcodes['68365'] = [40.619345, -97.735015];
Zipcodes['68366'] = [40.981597, -96.434617];
Zipcodes['68367'] = [41.039521, -97.39684];
Zipcodes['68368'] = [40.55433, -96.813122];
Zipcodes['68370'] = [40.14428, -97.556231];
Zipcodes['68371'] = [40.763634, -97.783303];
Zipcodes['68372'] = [40.602526, -96.60045];
Zipcodes['68375'] = [40.037018, -97.492568];
Zipcodes['68376'] = [40.170222, -95.938382];
Zipcodes['68377'] = [40.217009, -97.019327];
Zipcodes['68378'] = [40.398308, -96.020851];
Zipcodes['68379'] = [40.529115, -95.869802];
Zipcodes['68380'] = [40.242606, -96.405599];
Zipcodes['68381'] = [40.076766, -96.480787];
Zipcodes['68382'] = [40.59722, -96.024177];
Zipcodes['68401'] = [40.750809, -97.582698];
Zipcodes['68402'] = [40.908276, -96.857101];
Zipcodes['68403'] = [40.920566, -96.166772];
Zipcodes['68404'] = [40.621919, -96.755233];
Zipcodes['68405'] = [40.76205, -97.073498];
Zipcodes['68406'] = [40.503459, -97.381738];
Zipcodes['68407'] = [40.922907, -96.263729];
Zipcodes['68409'] = [40.907713, -95.95418];
Zipcodes['68410'] = [40.635824, -95.883774];
Zipcodes['68413'] = [40.846119, -96.026323];
Zipcodes['68414'] = [40.317601, -95.697886];
Zipcodes['68415'] = [40.061922, -96.821283];
Zipcodes['68416'] = [40.410391, -97.459709];
Zipcodes['68417'] = [40.733016, -96.094497];
Zipcodes['68418'] = [40.69358, -96.379013];
Zipcodes['68419'] = [40.602959, -96.511279];
Zipcodes['68420'] = [40.07901, -96.189143];
Zipcodes['68421'] = [40.493981, -95.743549];
Zipcodes['68422'] = [40.388735, -96.694709];
Zipcodes['68423'] = [40.807477, -96.95745];
Zipcodes['68424'] = [40.299195, -97.016261];
Zipcodes['68428'] = [40.966093, -96.805216];
Zipcodes['68429'] = [40.062369, -97.373402];
Zipcodes['68430'] = [40.679616, -96.667679];
Zipcodes['68431'] = [40.067562, -95.438183];
Zipcodes['68433'] = [40.053054, -95.738208];
Zipcodes['68434'] = [40.909586, -97.116949];
Zipcodes['68436'] = [40.433561, -97.744246];
Zipcodes['68437'] = [40.252896, -95.638479];
Zipcodes['68438'] = [40.626563, -96.744708];
Zipcodes['68439'] = [40.995946, -97.206667];
Zipcodes['68440'] = [40.0539, -97.012623];
Zipcodes['68441'] = [40.224693, -96.249812];
Zipcodes['68442'] = [40.239003, -95.772913];
Zipcodes['68443'] = [40.454775, -96.361203];
Zipcodes['68444'] = [40.41117, -97.584712];
Zipcodes['68445'] = [40.401474, -97.086612];
Zipcodes['68446'] = [40.647219, -96.166654];
Zipcodes['68447'] = [40.195353, -96.073041];
Zipcodes['68448'] = [40.545229, -96.038302];
Zipcodes['68450'] = [40.355234, -96.226201];
Zipcodes['68452'] = [40.401587, -97.852541];
Zipcodes['68453'] = [40.402246, -97.339834];
Zipcodes['68454'] = [40.686895, -96.28738];
Zipcodes['68455'] = [40.819689, -95.897413];
Zipcodes['68456'] = [40.909062, -97.301709];
Zipcodes['68457'] = [40.157565, -95.712063];
Zipcodes['68458'] = [40.22581, -96.492404];
Zipcodes['68460'] = [40.896327, -97.428385];
Zipcodes['68461'] = [40.762334, -96.511432];
Zipcodes['68462'] = [40.952129, -96.519659];
Zipcodes['68463'] = [40.875803, -96.129031];
Zipcodes['68464'] = [40.413364, -97.18959];
Zipcodes['68465'] = [40.493152, -97.020707];
Zipcodes['68466'] = [40.066854, -96.642831];
Zipcodes['68467'] = [40.865457, -97.598134];
Zipcodes['68502'] = [40.78401, -96.700404];
Zipcodes['68503'] = [40.824473, -96.673893];
Zipcodes['68504'] = [40.854478, -96.660184];
Zipcodes['68505'] = [40.824398, -96.615664];
Zipcodes['68506'] = [40.783832, -96.639581];
Zipcodes['68507'] = [40.859487, -96.61172];
Zipcodes['68508'] = [40.818065, -96.705504];
Zipcodes['68510'] = [40.807097, -96.648326];
Zipcodes['68512'] = [40.73746, -96.705659];
Zipcodes['68514'] = [40.934063, -96.650743];
Zipcodes['68516'] = [40.735276, -96.64279];
Zipcodes['68517'] = [40.932207, -96.603253];
Zipcodes['68520'] = [40.805895, -96.572561];
Zipcodes['68521'] = [40.861818, -96.714783];
Zipcodes['68522'] = [40.791284, -96.760102];
Zipcodes['68523'] = [40.7315, -96.755301];
Zipcodes['68524'] = [40.906992, -96.787368];
Zipcodes['68526'] = [40.729979, -96.582051];
Zipcodes['68527'] = [40.845382, -96.511331];
Zipcodes['68528'] = [40.824999, -96.824998];
Zipcodes['68531'] = [40.908026, -96.710468];
Zipcodes['68532'] = [40.795421, -96.843344];
Zipcodes['68588'] = [40.824921, -96.696429];
Zipcodes['68601'] = [41.454145, -97.398786];
Zipcodes['68620'] = [41.716659, -98.051161];
Zipcodes['68621'] = [41.492162, -96.639488];
Zipcodes['68622'] = [41.922575, -98.520853];
Zipcodes['68623'] = [41.436243, -98.102119];
Zipcodes['68624'] = [41.345613, -97.229358];
Zipcodes['68626'] = [41.169497, -96.992008];
Zipcodes['68627'] = [41.547605, -98.165686];
Zipcodes['68628'] = [41.216215, -97.843634];
Zipcodes['68629'] = [41.707272, -97.131094];
Zipcodes['68631'] = [41.68998, -97.355634];
Zipcodes['68632'] = [41.243105, -97.133872];
Zipcodes['68633'] = [41.706202, -96.896404];
Zipcodes['68634'] = [41.390732, -97.506636];
Zipcodes['68635'] = [41.084576, -97.022886];
Zipcodes['68636'] = [42.009224, -98.152606];
Zipcodes['68637'] = [41.815455, -98.644164];
Zipcodes['68638'] = [41.377775, -98.007171];
Zipcodes['68640'] = [41.45877, -97.766341];
Zipcodes['68641'] = [41.73567, -97.015265];
Zipcodes['68642'] = [41.689215, -97.513932];
Zipcodes['68643'] = [41.705976, -97.263334];
Zipcodes['68644'] = [41.704257, -97.670015];
Zipcodes['68647'] = [41.518774, -97.62834];
Zipcodes['68648'] = [41.401381, -96.780226];
Zipcodes['68649'] = [41.505048, -96.785365];
Zipcodes['68651'] = [41.228224, -97.570129];
Zipcodes['68652'] = [41.861112, -98.090096];
Zipcodes['68653'] = [41.562834, -97.495748];
Zipcodes['68654'] = [41.067034, -97.779138];
Zipcodes['68655'] = [41.648119, -98.261209];
Zipcodes['68658'] = [41.199533, -97.292671];
Zipcodes['68659'] = [41.545943, -96.935406];
Zipcodes['68660'] = [41.574116, -97.867733];
Zipcodes['68661'] = [41.492505, -97.088623];
Zipcodes['68662'] = [41.210704, -97.417564];
Zipcodes['68663'] = [41.311214, -97.701007];
Zipcodes['68664'] = [41.704573, -96.786835];
Zipcodes['68665'] = [41.71387, -98.418973];
Zipcodes['68666'] = [41.110595, -97.611211];
Zipcodes['68667'] = [41.115455, -97.306937];
Zipcodes['68669'] = [41.079231, -97.236898];
Zipcodes['68701'] = [42.019825, -97.442556];
Zipcodes['68710'] = [42.448281, -96.849399];
Zipcodes['68711'] = [42.194967, -99.039009];
Zipcodes['68713'] = [42.540294, -98.973236];
Zipcodes['68714'] = [42.368746, -99.5033];
Zipcodes['68715'] = [41.971271, -97.605926];
Zipcodes['68716'] = [41.927041, -96.822664];
Zipcodes['68717'] = [42.38335, -97.222448];
Zipcodes['68718'] = [42.669933, -97.692085];
Zipcodes['68719'] = [42.969389, -98.559565];
Zipcodes['68720'] = [42.344997, -98.01466];
Zipcodes['68722'] = [42.929935, -98.869016];
Zipcodes['68723'] = [42.285585, -97.218555];
Zipcodes['68724'] = [42.627619, -97.884932];
Zipcodes['68725'] = [42.197414, -98.777902];
Zipcodes['68726'] = [42.184662, -98.210112];
Zipcodes['68727'] = [42.515296, -97.202478];
Zipcodes['68728'] = [42.387427, -96.930001];
Zipcodes['68729'] = [42.477415, -97.907316];
Zipcodes['68730'] = [42.767263, -97.534632];
Zipcodes['68731'] = [42.407821, -96.492072];
Zipcodes['68732'] = [42.488576, -96.97166];
Zipcodes['68733'] = [42.28292, -96.722184];
Zipcodes['68734'] = [42.429051, -98.798338];
Zipcodes['68735'] = [42.146817, -98.446612];
Zipcodes['68736'] = [42.744837, -97.371385];
Zipcodes['68739'] = [42.627643, -97.26157];
Zipcodes['68740'] = [42.146637, -97.301618];
Zipcodes['68741'] = [42.36491, -96.594532];
Zipcodes['68742'] = [42.350211, -98.499895];
Zipcodes['68743'] = [42.472448, -96.624763];
Zipcodes['68745'] = [42.456197, -97.083883];
Zipcodes['68746'] = [42.809636, -98.437442];
Zipcodes['68747'] = [42.391145, -97.496125];
Zipcodes['68748'] = [41.836996, -97.474624];
Zipcodes['68749'] = [42.460326, -97.475323];
Zipcodes['68751'] = [42.69043, -96.981426];
Zipcodes['68752'] = [42.020231, -97.711668];
Zipcodes['68753'] = [42.854067, -99.485011];
Zipcodes['68755'] = [42.926512, -99.130016];
Zipcodes['68756'] = [42.183568, -97.980286];
Zipcodes['68757'] = [42.65328, -96.927927];
Zipcodes['68758'] = [41.778406, -97.783026];
Zipcodes['68759'] = [42.751759, -99.346454];
Zipcodes['68760'] = [42.708842, -97.874979];
Zipcodes['68761'] = [42.021589, -97.956375];
Zipcodes['68763'] = [42.515901, -98.588982];
Zipcodes['68764'] = [42.404882, -98.23167];
Zipcodes['68765'] = [42.3423, -97.613711];
Zipcodes['68766'] = [42.42839, -98.40925];
Zipcodes['68767'] = [42.197018, -97.536586];
Zipcodes['68768'] = [41.999989, -97.070194];
Zipcodes['68769'] = [42.309481, -97.824652];
Zipcodes['68770'] = [42.561623, -96.764827];
Zipcodes['68771'] = [42.395738, -97.360096];
Zipcodes['68773'] = [42.362211, -98.119232];
Zipcodes['68774'] = [42.812222, -97.274892];
Zipcodes['68776'] = [42.457187, -96.451047];
Zipcodes['68777'] = [42.860734, -98.687868];
Zipcodes['68778'] = [42.879535, -99.80724];
Zipcodes['68779'] = [41.952592, -97.209291];
Zipcodes['68780'] = [42.632961, -99.12819];
Zipcodes['68781'] = [42.027562, -97.839951];
Zipcodes['68783'] = [42.636497, -98.139394];
Zipcodes['68784'] = [42.257167, -96.891367];
Zipcodes['68785'] = [42.427966, -96.722393];
Zipcodes['68786'] = [42.50156, -97.557442];
Zipcodes['68787'] = [42.213826, -97.030949];
Zipcodes['68788'] = [41.835484, -96.725466];
Zipcodes['68789'] = [42.535428, -97.983803];
Zipcodes['68790'] = [42.168389, -97.186038];
Zipcodes['68791'] = [41.99758, -96.93982];
Zipcodes['68792'] = [42.727629, -97.100144];
Zipcodes['68801'] = [40.949883, -98.297321];
Zipcodes['68803'] = [40.956158, -98.414569];
Zipcodes['68810'] = [40.863259, -98.467445];
Zipcodes['68812'] = [40.909086, -99.280446];
Zipcodes['68813'] = [41.699277, -99.856993];
Zipcodes['68814'] = [41.308527, -99.41025];
Zipcodes['68815'] = [41.428903, -99.155996];
Zipcodes['68816'] = [41.174853, -98.133715];
Zipcodes['68817'] = [41.26748, -98.793822];
Zipcodes['68818'] = [40.822012, -98.006221];
Zipcodes['68820'] = [41.112899, -98.701416];
Zipcodes['68821'] = [42.007491, -99.762704];
Zipcodes['68822'] = [41.388461, -99.631356];
Zipcodes['68823'] = [41.956564, -99.155956];
Zipcodes['68824'] = [41.007094, -98.618145];
Zipcodes['68825'] = [41.264062, -99.968062];
Zipcodes['68826'] = [41.163588, -98.016399];
Zipcodes['68827'] = [41.042659, -98.181454];
Zipcodes['68828'] = [41.558194, -99.248739];
Zipcodes['68831'] = [41.106685, -98.544036];
Zipcodes['68832'] = [40.770258, -98.366824];
Zipcodes['68833'] = [41.785968, -100.076596];
Zipcodes['68834'] = [41.014915, -99.657783];
Zipcodes['68835'] = [41.331899, -98.61724];
Zipcodes['68836'] = [40.705215, -99.37727];
Zipcodes['68837'] = [41.669828, -99.07315];
Zipcodes['68838'] = [41.212162, -98.662361];
Zipcodes['68840'] = [40.759666, -98.877542];
Zipcodes['68841'] = [40.762203, -98.158229];
Zipcodes['68842'] = [41.580675, -98.5345];
Zipcodes['68843'] = [40.905842, -97.878615];
Zipcodes['68844'] = [41.053759, -99.063394];
Zipcodes['68845'] = [40.718088, -99.170857];
Zipcodes['68846'] = [41.062699, -97.883831];
Zipcodes['68847'] = [40.738535, -99.020515];
Zipcodes['68849'] = [40.701456, -99.100884];
Zipcodes['68850'] = [40.826405, -99.739779];
Zipcodes['68852'] = [41.168335, -99.144321];
Zipcodes['68853'] = [41.287717, -98.980747];
Zipcodes['68854'] = [41.009922, -98.002428];
Zipcodes['68855'] = [41.169337, -99.325453];
Zipcodes['68856'] = [41.503044, -99.817591];
Zipcodes['68858'] = [40.965845, -99.37754];
Zipcodes['68859'] = [41.458741, -98.82218];
Zipcodes['68860'] = [41.133983, -99.795816];
Zipcodes['68861'] = [40.698946, -99.263886];
Zipcodes['68862'] = [41.604066, -98.93836];
Zipcodes['68863'] = [40.766301, -99.532137];
Zipcodes['68864'] = [41.255217, -98.238229];
Zipcodes['68865'] = [40.896549, -98.178659];
Zipcodes['68866'] = [40.968939, -99.129182];
Zipcodes['68869'] = [40.996135, -98.884197];
Zipcodes['68870'] = [40.8453, -99.158364];
Zipcodes['68871'] = [41.140917, -98.852753];
Zipcodes['68872'] = [41.092874, -98.332167];
Zipcodes['68873'] = [41.240069, -98.453879];
Zipcodes['68874'] = [41.634546, -99.44441];
Zipcodes['68875'] = [41.523113, -98.685407];
Zipcodes['68876'] = [40.82518, -98.749999];
Zipcodes['68878'] = [40.981333, -99.506834];
Zipcodes['68879'] = [41.903183, -99.509852];
Zipcodes['68881'] = [41.434669, -99.359595];
Zipcodes['68882'] = [41.435696, -98.391066];
Zipcodes['68883'] = [40.820652, -98.577851];
Zipcodes['68901'] = [40.590946, -98.366104];
Zipcodes['68920'] = [40.154543, -99.339914];
Zipcodes['68922'] = [40.336428, -99.899773];
Zipcodes['68923'] = [40.368328, -99.473203];
Zipcodes['68924'] = [40.495504, -99.124672];
Zipcodes['68925'] = [40.428761, -98.430108];
Zipcodes['68926'] = [40.105983, -99.840291];
Zipcodes['68927'] = [40.499975, -99.633924];
Zipcodes['68928'] = [40.284345, -98.60684];
Zipcodes['68929'] = [40.154009, -99.094314];
Zipcodes['68930'] = [40.284768, -98.415566];
Zipcodes['68932'] = [40.303716, -98.739803];
Zipcodes['68933'] = [40.518328, -98.040619];
Zipcodes['68934'] = [40.341976, -98.150717];
Zipcodes['68935'] = [40.378358, -97.962002];
Zipcodes['68936'] = [40.314966, -99.775367];
Zipcodes['68937'] = [40.569279, -99.858924];
Zipcodes['68938'] = [40.415596, -98.117141];
Zipcodes['68939'] = [40.10974, -98.961742];
Zipcodes['68940'] = [40.538385, -99.25176];
Zipcodes['68941'] = [40.468824, -98.267895];
Zipcodes['68942'] = [40.096586, -98.320788];
Zipcodes['68943'] = [40.031109, -97.887306];
Zipcodes['68944'] = [40.637829, -98.094112];
Zipcodes['68945'] = [40.560983, -98.773268];
Zipcodes['68946'] = [40.106742, -99.990473];
Zipcodes['68947'] = [40.301383, -99.051893];
Zipcodes['68948'] = [40.342314, -100.027727];
Zipcodes['68949'] = [40.432746, -99.393472];
Zipcodes['68950'] = [40.455849, -98.66413];
Zipcodes['68952'] = [40.081713, -98.679112];
Zipcodes['68954'] = [40.614718, -98.233726];
Zipcodes['68955'] = [40.591991, -98.540295];
Zipcodes['68956'] = [40.631616, -98.68814];
Zipcodes['68957'] = [40.273952, -98.246364];
Zipcodes['68958'] = [40.530287, -99.507393];
Zipcodes['68959'] = [40.48554, -98.923098];
Zipcodes['68960'] = [40.083803, -99.138983];
Zipcodes['68961'] = [40.208997, -98.043802];
Zipcodes['68964'] = [40.235195, -97.902786];
Zipcodes['68966'] = [40.159905, -99.467272];
Zipcodes['68967'] = [40.253917, -99.63162];
Zipcodes['68970'] = [40.10798, -98.529779];
Zipcodes['68971'] = [40.124355, -99.250192];
Zipcodes['68972'] = [40.101604, -98.803401];
Zipcodes['68973'] = [40.444547, -98.562912];
Zipcodes['68974'] = [40.125862, -97.867653];
Zipcodes['68975'] = [40.61881, -97.948619];
Zipcodes['68976'] = [40.602317, -99.709135];
Zipcodes['68977'] = [40.066948, -99.630525];
Zipcodes['68978'] = [40.06666, -98.0975];
Zipcodes['68979'] = [40.59828, -97.853549];
Zipcodes['68980'] = [40.676614, -98.250701];
Zipcodes['68981'] = [40.286007, -98.892394];
Zipcodes['68982'] = [40.330764, -99.19517];
Zipcodes['69001'] = [40.250669, -100.661196];
Zipcodes['69020'] = [40.153957, -100.26232];
Zipcodes['69021'] = [40.14161, -101.540343];
Zipcodes['69022'] = [40.341844, -100.187143];
Zipcodes['69023'] = [40.481826, -101.89873];
Zipcodes['69024'] = [40.240961, -100.863894];
Zipcodes['69025'] = [40.620868, -100.50948];
Zipcodes['69026'] = [40.025388, -100.410645];
Zipcodes['69027'] = [40.502503, -101.524265];
Zipcodes['69028'] = [40.635927, -100.054271];
Zipcodes['69029'] = [40.677788, -100.242829];
Zipcodes['69030'] = [40.18343, -101.916354];
Zipcodes['69032'] = [40.579352, -101.063208];
Zipcodes['69033'] = [40.552146, -101.694374];
Zipcodes['69034'] = [40.250769, -100.433692];
Zipcodes['69036'] = [40.027907, -100.298018];
Zipcodes['69037'] = [40.184984, -101.405741];
Zipcodes['69038'] = [40.61081, -100.739407];
Zipcodes['69039'] = [40.706065, -100.387701];
Zipcodes['69040'] = [40.354996, -101.119758];
Zipcodes['69041'] = [40.177036, -101.720375];
Zipcodes['69042'] = [40.475456, -100.379004];
Zipcodes['69043'] = [40.128711, -101.255201];
Zipcodes['69044'] = [40.130145, -101.016489];
Zipcodes['69045'] = [40.465392, -101.369178];
Zipcodes['69046'] = [40.06821, -100.104099];
Zipcodes['69101'] = [41.150189, -100.805308];
Zipcodes['69120'] = [41.432195, -100.242481];
Zipcodes['69121'] = [41.543566, -101.773562];
Zipcodes['69122'] = [41.099788, -102.086763];
Zipcodes['69123'] = [41.044466, -100.370866];
Zipcodes['69125'] = [41.592506, -102.855514];
Zipcodes['69127'] = [41.105836, -101.918791];
Zipcodes['69128'] = [41.230119, -103.907963];
Zipcodes['69129'] = [41.134977, -102.380427];
Zipcodes['69130'] = [40.897081, -99.961723];
Zipcodes['69131'] = [41.416551, -102.997395];
Zipcodes['69132'] = [40.814912, -101.006611];
Zipcodes['69133'] = [41.219563, -103.471892];
Zipcodes['69134'] = [40.806617, -101.369279];
Zipcodes['69135'] = [42.144777, -100.266113];
Zipcodes['69138'] = [40.995816, -100.176551];
Zipcodes['69140'] = [40.840299, -101.77312];
Zipcodes['69141'] = [41.304157, -102.936025];
Zipcodes['69142'] = [41.913938, -100.304871];
Zipcodes['69143'] = [41.19271, -101.019374];
Zipcodes['69144'] = [41.342468, -101.62135];
Zipcodes['69145'] = [41.251546, -103.68349];
Zipcodes['69146'] = [41.34836, -101.869141];
Zipcodes['69147'] = [41.574086, -102.095212];
Zipcodes['69148'] = [41.688782, -102.610804];
Zipcodes['69149'] = [41.221745, -102.677147];
Zipcodes['69150'] = [40.821947, -101.541332];
Zipcodes['69151'] = [41.090203, -100.495252];
Zipcodes['69152'] = [42.065406, -101.149729];
Zipcodes['69153'] = [41.075658, -101.670275];
Zipcodes['69154'] = [41.460919, -102.353212];
Zipcodes['69155'] = [41.188361, -101.389426];
Zipcodes['69156'] = [41.276565, -103.345817];
Zipcodes['69157'] = [41.966773, -100.158443];
Zipcodes['69161'] = [42.156506, -100.781305];
Zipcodes['69162'] = [41.129189, -103.016494];
Zipcodes['69163'] = [41.569639, -100.541642];
Zipcodes['69165'] = [41.152671, -101.170763];
Zipcodes['69166'] = [42.030913, -100.618323];
Zipcodes['69167'] = [41.596232, -101.006031];
Zipcodes['69168'] = [40.748165, -101.981251];
Zipcodes['69169'] = [40.821043, -101.179886];
Zipcodes['69170'] = [40.79581, -100.744272];
Zipcodes['69171'] = [40.890659, -100.068161];
Zipcodes['69201'] = [42.692363, -100.614583];
Zipcodes['69210'] = [42.390453, -99.856953];
Zipcodes['69211'] = [42.836333, -101.316348];
Zipcodes['69212'] = [42.979813, -100.803245];
Zipcodes['69214'] = [42.448252, -100.100493];
Zipcodes['69216'] = [42.951708, -101.008059];
Zipcodes['69217'] = [42.26594, -99.716152];
Zipcodes['69218'] = [42.774795, -101.612778];
Zipcodes['69219'] = [42.713708, -101.025102];
Zipcodes['69220'] = [42.918531, -100.168594];
Zipcodes['69221'] = [42.641545, -100.310434];
Zipcodes['69301'] = [42.126924, -102.889507];
Zipcodes['69331'] = [41.897596, -102.991053];
Zipcodes['69333'] = [42.050947, -101.966635];
Zipcodes['69334'] = [41.821808, -103.29016];
Zipcodes['69335'] = [42.020655, -102.142622];
Zipcodes['69336'] = [41.628549, -103.166962];
Zipcodes['69337'] = [42.876447, -102.983];
Zipcodes['69339'] = [42.583427, -103.442759];
Zipcodes['69340'] = [42.149843, -102.196795];
Zipcodes['69341'] = [41.739079, -103.679013];
Zipcodes['69343'] = [42.665533, -101.910901];
Zipcodes['69345'] = [41.59419, -103.710493];
Zipcodes['69346'] = [42.625227, -103.792979];
Zipcodes['69347'] = [42.594852, -102.683817];
Zipcodes['69348'] = [42.331438, -103.207858];
Zipcodes['69350'] = [41.961247, -101.764942];
Zipcodes['69351'] = [42.115127, -102.413129];
Zipcodes['69352'] = [41.803986, -103.973548];
Zipcodes['69353'] = [41.74705, -103.418918];
Zipcodes['69354'] = [42.381413, -103.381836];
Zipcodes['69355'] = [41.781972, -103.516935];
Zipcodes['69356'] = [41.890922, -103.451135];
Zipcodes['69357'] = [42.110818, -103.767984];
Zipcodes['69358'] = [42.076823, -103.979768];
Zipcodes['69360'] = [42.738551, -102.486558];
Zipcodes['69361'] = [42.102096, -103.566082];
Zipcodes['69365'] = [42.947495, -102.460981];
Zipcodes['69366'] = [42.067934, -101.581195];
Zipcodes['69367'] = [42.814352, -103.316932];
Zipcodes['70001'] = [29.982705, -90.169068];
Zipcodes['70002'] = [30.010189, -90.162617];
Zipcodes['70003'] = [30.001164, -90.209724];
Zipcodes['70005'] = [29.999416, -90.134065];
Zipcodes['70006'] = [30.014032, -90.191345];
Zipcodes['70030'] = [29.819197, -90.43971];
Zipcodes['70031'] = [29.943492, -90.295565];
Zipcodes['70032'] = [29.95038, -89.996001];
Zipcodes['70036'] = [29.684562, -90.131823];
Zipcodes['70037'] = [29.715894, -90.035359];
Zipcodes['70038'] = [29.331064, -89.400699];
Zipcodes['70039'] = [29.877209, -90.377249];
Zipcodes['70040'] = [29.740211, -89.922596];
Zipcodes['70041'] = [29.333868, -89.467526];
Zipcodes['70043'] = [29.956176, -89.964417];
Zipcodes['70047'] = [29.972092, -90.354156];
Zipcodes['70049'] = [30.030039, -90.562201];
Zipcodes['70050'] = [29.38336, -89.595672];
Zipcodes['70051'] = [30.073542, -90.642768];
Zipcodes['70052'] = [30.070157, -90.687348];
Zipcodes['70053'] = [29.917069, -90.052204];
Zipcodes['70056'] = [29.887441, -90.027304];
Zipcodes['70057'] = [29.962198, -90.464199];
Zipcodes['70058'] = [29.868874, -90.064872];
Zipcodes['70062'] = [29.991657, -90.258116];
Zipcodes['70065'] = [30.026672, -90.25345];
Zipcodes['70067'] = [29.710879, -90.096784];
Zipcodes['70068'] = [30.156347, -90.436783];
Zipcodes['70070'] = [29.829842, -90.310627];
Zipcodes['70071'] = [30.05192, -90.703341];
Zipcodes['70072'] = [29.809263, -90.138308];
Zipcodes['70075'] = [29.927459, -89.91921];
Zipcodes['70076'] = [30.054467, -90.64973];
Zipcodes['70079'] = [30.002153, -90.412317];
Zipcodes['70080'] = [29.871092, -90.421812];
Zipcodes['70082'] = [29.571513, -89.752824];
Zipcodes['70083'] = [29.511914, -89.843736];
Zipcodes['70084'] = [30.072724, -90.573197];
Zipcodes['70085'] = [29.818077, -89.759469];
Zipcodes['70086'] = [30.036193, -90.865406];
Zipcodes['70087'] = [29.994018, -90.320832];
Zipcodes['70090'] = [29.969253, -90.701136];
Zipcodes['70091'] = [29.16749, -89.216283];
Zipcodes['70092'] = [29.899112, -89.895936];
Zipcodes['70094'] = [29.915778, -90.207605];
Zipcodes['70112'] = [29.95609, -90.07735];
Zipcodes['70113'] = [29.942292, -90.083732];
Zipcodes['70114'] = [29.937445, -90.032322];
Zipcodes['70115'] = [29.92393, -90.102484];
Zipcodes['70116'] = [29.96744, -90.064783];
Zipcodes['70117'] = [29.968157, -90.029962];
Zipcodes['70118'] = [29.944708, -90.125674];
Zipcodes['70119'] = [29.975752, -90.087845];
Zipcodes['70121'] = [29.961736, -90.159183];
Zipcodes['70122'] = [30.008505, -90.064718];
Zipcodes['70123'] = [29.949989, -90.205559];
Zipcodes['70124'] = [30.007379, -90.103919];
Zipcodes['70125'] = [29.951928, -90.10335];
Zipcodes['70126'] = [30.019485, -90.017565];
Zipcodes['70127'] = [30.028404, -89.9742];
Zipcodes['70128'] = [30.044717, -89.951375];
Zipcodes['70129'] = [30.080851, -89.813366];
Zipcodes['70130'] = [29.936406, -90.069932];
Zipcodes['70131'] = [29.906333, -89.95834];
Zipcodes['70139'] = [29.95023, -90.070995];
Zipcodes['70148'] = [30.02732, -90.067597];
Zipcodes['70163'] = [29.949981, -90.075462];
Zipcodes['70301'] = [29.808328, -90.750267];
Zipcodes['70310'] = [29.790543, -90.802982];
Zipcodes['70339'] = [29.904627, -91.184723];
Zipcodes['70340'] = [29.669742, -91.104288];
Zipcodes['70341'] = [30.02649, -91.083333];
Zipcodes['70342'] = [29.693147, -91.23834];
Zipcodes['70343'] = [29.548398, -90.557239];
Zipcodes['70344'] = [29.401184, -90.633055];
Zipcodes['70345'] = [29.501844, -90.23789];
Zipcodes['70346'] = [30.10609, -91.017988];
Zipcodes['70352'] = [29.684424, -90.940194];
Zipcodes['70353'] = [29.358143, -90.700118];
Zipcodes['70354'] = [29.43028, -90.342585];
Zipcodes['70355'] = [29.687187, -90.43547];
Zipcodes['70356'] = [29.631566, -90.970879];
Zipcodes['70357'] = [29.257799, -90.192019];
Zipcodes['70358'] = [29.214732, -90.027546];
Zipcodes['70359'] = [29.689847, -90.777822];
Zipcodes['70360'] = [29.578671, -90.805402];
Zipcodes['70363'] = [29.506722, -90.700777];
Zipcodes['70364'] = [29.635994, -90.679678];
Zipcodes['70372'] = [29.782462, -90.988904];
Zipcodes['70373'] = [29.597594, -90.311472];
Zipcodes['70374'] = [29.604507, -90.501988];
Zipcodes['70375'] = [29.695664, -90.531568];
Zipcodes['70377'] = [29.459272, -90.532111];
Zipcodes['70380'] = [29.752074, -91.103597];
Zipcodes['70390'] = [29.890288, -91.03187];
Zipcodes['70391'] = [29.995146, -91.060326];
Zipcodes['70392'] = [29.699424, -91.31847];
Zipcodes['70393'] = [29.995924, -90.99897];
Zipcodes['70394'] = [29.692681, -90.61612];
Zipcodes['70395'] = [29.697436, -90.86173];
Zipcodes['70397'] = [29.388473, -90.830994];
Zipcodes['70401'] = [30.533553, -90.455443];
Zipcodes['70402'] = [30.516501, -90.46926];
Zipcodes['70403'] = [30.485555, -90.487688];
Zipcodes['70420'] = [30.498753, -89.944416];
Zipcodes['70422'] = [30.735737, -90.491513];
Zipcodes['70426'] = [30.926497, -89.858614];
Zipcodes['70427'] = [30.744198, -89.912079];
Zipcodes['70431'] = [30.616752, -89.967363];
Zipcodes['70433'] = [30.462717, -90.140504];
Zipcodes['70435'] = [30.556992, -90.106639];
Zipcodes['70436'] = [30.834554, -90.533106];
Zipcodes['70437'] = [30.619454, -90.209393];
Zipcodes['70438'] = [30.843721, -90.101029];
Zipcodes['70441'] = [30.859967, -90.749066];
Zipcodes['70442'] = [30.703049, -90.333198];
Zipcodes['70443'] = [30.630554, -90.542882];
Zipcodes['70444'] = [30.897005, -90.489375];
Zipcodes['70445'] = [30.371531, -89.892306];
Zipcodes['70446'] = [30.634711, -90.358474];
Zipcodes['70447'] = [30.422656, -90.205855];
Zipcodes['70448'] = [30.36218, -90.039451];
Zipcodes['70449'] = [30.273961, -90.670145];
Zipcodes['70450'] = [30.942521, -90.261736];
Zipcodes['70451'] = [30.549613, -90.481067];
Zipcodes['70452'] = [30.416894, -89.75483];
Zipcodes['70453'] = [30.685545, -90.782221];
Zipcodes['70454'] = [30.362846, -90.358594];
Zipcodes['70455'] = [30.529855, -90.323981];
Zipcodes['70456'] = [30.787059, -90.50462];
Zipcodes['70457'] = [30.527464, -90.113355];
Zipcodes['70458'] = [30.262075, -89.794105];
Zipcodes['70460'] = [30.299875, -89.835997];
Zipcodes['70461'] = [30.234038, -89.709634];
Zipcodes['70462'] = [30.376279, -90.580741];
Zipcodes['70463'] = [30.65807, -89.903817];
Zipcodes['70464'] = [30.541322, -89.890516];
Zipcodes['70465'] = [30.875781, -90.51384];
Zipcodes['70466'] = [30.568714, -90.500761];
Zipcodes['70471'] = [30.404287, -90.061307];
Zipcodes['70501'] = [30.239007, -91.991284];
Zipcodes['70503'] = [30.171005, -92.054782];
Zipcodes['70506'] = [30.195834, -92.080781];
Zipcodes['70507'] = [30.278324, -92.028037];
Zipcodes['70508'] = [30.155238, -92.028225];
Zipcodes['70510'] = [29.894612, -92.193173];
Zipcodes['70512'] = [30.415729, -91.920497];
Zipcodes['70513'] = [29.899977, -91.903269];
Zipcodes['70514'] = [29.845424, -91.557762];
Zipcodes['70515'] = [30.452467, -92.577743];
Zipcodes['70516'] = [30.370902, -92.303577];
Zipcodes['70517'] = [30.290824, -91.786378];
Zipcodes['70518'] = [30.134691, -91.927341];
Zipcodes['70519'] = [30.081749, -91.906967];
Zipcodes['70520'] = [30.329615, -92.033349];
Zipcodes['70522'] = [29.748439, -91.435682];
Zipcodes['70523'] = [29.872128, -91.530498];
Zipcodes['70524'] = [30.557334, -92.318572];
Zipcodes['70525'] = [30.408131, -92.217328];
Zipcodes['70526'] = [30.210529, -92.378876];
Zipcodes['70528'] = [29.884157, -91.959577];
Zipcodes['70529'] = [30.202279, -92.16158];
Zipcodes['70531'] = [30.231157, -92.500746];
Zipcodes['70532'] = [30.4709, -92.697075];
Zipcodes['70533'] = [29.901326, -92.031146];
Zipcodes['70534'] = [30.165634, -92.454959];
Zipcodes['70535'] = [30.471149, -92.419836];
Zipcodes['70537'] = [30.251631, -92.570201];
Zipcodes['70538'] = [29.80709, -91.487512];
Zipcodes['70541'] = [30.420629, -92.042561];
Zipcodes['70542'] = [30.009678, -92.584774];
Zipcodes['70543'] = [30.335404, -92.501138];
Zipcodes['70544'] = [29.905016, -91.656399];
Zipcodes['70546'] = [30.266368, -92.673552];
Zipcodes['70548'] = [29.790545, -92.420528];
Zipcodes['70549'] = [30.046794, -92.832958];
Zipcodes['70550'] = [30.515714, -92.189654];
Zipcodes['70551'] = [30.461914, -91.983452];
Zipcodes['70552'] = [30.042453, -91.659967];
Zipcodes['70554'] = [30.629478, -92.490928];
Zipcodes['70555'] = [30.086857, -92.143336];
Zipcodes['70556'] = [30.190636, -92.583797];
Zipcodes['70558'] = [30.100525, -92.075759];
Zipcodes['70559'] = [30.139347, -92.508109];
Zipcodes['70560'] = [29.937141, -91.864508];
Zipcodes['70563'] = [30.029251, -91.731591];
Zipcodes['70570'] = [30.531941, -92.106967];
Zipcodes['70575'] = [29.948922, -92.158095];
Zipcodes['70576'] = [30.780968, -92.418253];
Zipcodes['70577'] = [30.549507, -91.918752];
Zipcodes['70578'] = [30.230073, -92.263673];
Zipcodes['70580'] = [30.675428, -92.428438];
Zipcodes['70581'] = [30.258119, -92.737129];
Zipcodes['70582'] = [30.163505, -91.775338];
Zipcodes['70583'] = [30.261419, -92.124012];
Zipcodes['70584'] = [30.388058, -92.113453];
Zipcodes['70585'] = [30.878323, -92.407716];
Zipcodes['70586'] = [30.727263, -92.356045];
Zipcodes['70589'] = [30.684908, -92.044194];
Zipcodes['70591'] = [30.257744, -92.832802];
Zipcodes['70592'] = [30.077154, -92.017724];
Zipcodes['70601'] = [30.226792, -93.215443];
Zipcodes['70605'] = [30.129276, -93.273527];
Zipcodes['70607'] = [30.038451, -93.190093];
Zipcodes['70609'] = [30.179071, -93.21482];
Zipcodes['70611'] = [30.348118, -93.2036];
Zipcodes['70615'] = [30.254009, -93.117713];
Zipcodes['70629'] = [30.231071, -93.21864];
Zipcodes['70630'] = [30.03117, -93.059283];
Zipcodes['70631'] = [29.853375, -93.568288];
Zipcodes['70632'] = [29.883232, -92.876911];
Zipcodes['70633'] = [30.426005, -93.39129];
Zipcodes['70634'] = [30.799649, -93.243292];
Zipcodes['70637'] = [30.707711, -92.974144];
Zipcodes['70638'] = [30.842711, -92.77199];
Zipcodes['70639'] = [30.983355, -93.497879];
Zipcodes['70640'] = [30.365879, -92.916564];
Zipcodes['70643'] = [29.737191, -92.779067];
Zipcodes['70644'] = [30.792345, -92.945596];
Zipcodes['70645'] = [29.928002, -93.473885];
Zipcodes['70646'] = [30.105014, -92.932777];
Zipcodes['70647'] = [30.247243, -93.015323];
Zipcodes['70648'] = [30.505073, -92.889361];
Zipcodes['70650'] = [30.233755, -92.920679];
Zipcodes['70651'] = [30.571997, -92.960871];
Zipcodes['70652'] = [30.613738, -93.247407];
Zipcodes['70653'] = [30.667604, -93.558664];
Zipcodes['70654'] = [30.6922, -92.87442];
Zipcodes['70655'] = [30.625103, -92.717084];
Zipcodes['70656'] = [30.914161, -92.926727];
Zipcodes['70657'] = [30.500463, -93.140462];
Zipcodes['70658'] = [30.521695, -93.036234];
Zipcodes['70659'] = [30.921932, -93.284285];
Zipcodes['70660'] = [30.57264, -93.460816];
Zipcodes['70661'] = [30.353644, -93.653664];
Zipcodes['70662'] = [30.798103, -93.005982];
Zipcodes['70663'] = [30.278305, -93.40559];
Zipcodes['70665'] = [30.121881, -93.451224];
Zipcodes['70668'] = [30.175044, -93.605585];
Zipcodes['70669'] = [30.242348, -93.272318];
Zipcodes['70706'] = [30.607238, -90.907361];
Zipcodes['70710'] = [30.358232, -91.268832];
Zipcodes['70711'] = [30.524507, -90.592006];
Zipcodes['70712'] = [30.969575, -91.596702];
Zipcodes['70714'] = [30.587189, -91.127633];
Zipcodes['70715'] = [30.814542, -91.657426];
Zipcodes['70719'] = [30.363175, -91.299555];
Zipcodes['70721'] = [30.223391, -91.079505];
Zipcodes['70722'] = [30.854787, -90.945939];
Zipcodes['70723'] = [30.059602, -90.840237];
Zipcodes['70725'] = [30.132614, -90.975919];
Zipcodes['70726'] = [30.433416, -90.90231];
Zipcodes['70729'] = [30.587693, -91.344857];
Zipcodes['70730'] = [30.809925, -91.094653];
Zipcodes['70732'] = [30.635912, -91.585224];
Zipcodes['70733'] = [30.289843, -90.800199];
Zipcodes['70734'] = [30.206556, -90.996999];
Zipcodes['70736'] = [30.633975, -91.338448];
Zipcodes['70737'] = [30.226092, -90.92498];
Zipcodes['70739'] = [30.601066, -90.965337];
Zipcodes['70740'] = [30.310056, -91.439765];
Zipcodes['70743'] = [30.025982, -90.770238];
Zipcodes['70744'] = [30.55941, -90.667123];
Zipcodes['70747'] = [30.87426, -91.672236];
Zipcodes['70748'] = [30.815023, -91.206165];
Zipcodes['70749'] = [30.629553, -91.450662];
Zipcodes['70750'] = [30.46394, -91.787529];
Zipcodes['70752'] = [30.595551, -91.41723];
Zipcodes['70753'] = [30.638578, -91.683835];
Zipcodes['70754'] = [30.40741, -90.75046];
Zipcodes['70755'] = [30.594669, -91.529819];
Zipcodes['70756'] = [30.523718, -91.650273];
Zipcodes['70757'] = [30.368748, -91.531236];
Zipcodes['70759'] = [30.712455, -91.586374];
Zipcodes['70760'] = [30.699747, -91.482689];
Zipcodes['70761'] = [30.974954, -91.038279];
Zipcodes['70762'] = [30.57834, -91.464653];
Zipcodes['70763'] = [30.048701, -90.736504];
Zipcodes['70764'] = [30.234516, -91.307982];
Zipcodes['70767'] = [30.481128, -91.330712];
Zipcodes['70769'] = [30.30743, -90.940643];
Zipcodes['70770'] = [30.651321, -90.993161];
Zipcodes['70772'] = [30.437481, -91.464796];
Zipcodes['70773'] = [30.596783, -91.373333];
Zipcodes['70774'] = [30.214554, -90.761904];
Zipcodes['70775'] = [30.858253, -91.369];
Zipcodes['70776'] = [30.260016, -91.091026];
Zipcodes['70777'] = [30.73968, -91.07711];
Zipcodes['70778'] = [30.162048, -90.818385];
Zipcodes['70780'] = [30.298735, -91.185292];
Zipcodes['70782'] = [30.951919, -91.511804];
Zipcodes['70783'] = [30.669405, -91.416824];
Zipcodes['70785'] = [30.557101, -90.819236];
Zipcodes['70786'] = [30.582437, -90.954355];
Zipcodes['70787'] = [30.961632, -91.44071];
Zipcodes['70788'] = [30.132093, -91.183796];
Zipcodes['70789'] = [30.940581, -91.082957];
Zipcodes['70791'] = [30.649627, -91.157635];
Zipcodes['70801'] = [30.449581, -91.185971];
Zipcodes['70802'] = [30.445471, -91.17527];
Zipcodes['70803'] = [30.410008, -91.174013];
Zipcodes['70805'] = [30.488923, -91.158261];
Zipcodes['70806'] = [30.448797, -91.124704];
Zipcodes['70807'] = [30.555634, -91.208201];
Zipcodes['70808'] = [30.404146, -91.139137];
Zipcodes['70809'] = [30.394049, -91.07058];
Zipcodes['70810'] = [30.345423, -91.094293];
Zipcodes['70811'] = [30.537682, -91.134907];
Zipcodes['70812'] = [30.500175, -91.110497];
Zipcodes['70813'] = [30.527861, -91.197563];
Zipcodes['70814'] = [30.485018, -91.061669];
Zipcodes['70815'] = [30.455363, -91.066491];
Zipcodes['70816'] = [30.426892, -91.027834];
Zipcodes['70817'] = [30.375109, -90.980419];
Zipcodes['70818'] = [30.542313, -91.050102];
Zipcodes['70819'] = [30.47174, -91.007785];
Zipcodes['70820'] = [30.365415, -91.202669];
Zipcodes['70825'] = [30.451972, -91.187756];
Zipcodes['70836'] = [30.387574, -91.083564];
Zipcodes['71001'] = [32.591884, -92.911426];
Zipcodes['71002'] = [32.120379, -93.11254];
Zipcodes['71003'] = [32.629546, -93.030824];
Zipcodes['71004'] = [32.741443, -93.860079];
Zipcodes['71006'] = [32.729183, -93.656132];
Zipcodes['71007'] = [32.362527, -94.006751];
Zipcodes['71008'] = [32.347123, -92.968651];
Zipcodes['71009'] = [32.578008, -93.891836];
Zipcodes['71016'] = [32.224441, -93.116314];
Zipcodes['71018'] = [32.821036, -93.432601];
Zipcodes['71019'] = [32.038461, -93.306638];
Zipcodes['71021'] = [32.967708, -93.446542];
Zipcodes['71023'] = [32.464514, -93.40458];
Zipcodes['71024'] = [32.48892, -93.219881];
Zipcodes['71027'] = [32.246949, -93.686859];
Zipcodes['71028'] = [32.486338, -93.076046];
Zipcodes['71029'] = [32.836168, -93.833718];
Zipcodes['71030'] = [32.183671, -93.793639];
Zipcodes['71031'] = [32.007616, -92.897035];
Zipcodes['71032'] = [32.094865, -93.795369];
Zipcodes['71033'] = [32.439845, -94.00382];
Zipcodes['71034'] = [32.184222, -93.304168];
Zipcodes['71037'] = [32.475082, -93.48762];
Zipcodes['71038'] = [32.943192, -93.072935];
Zipcodes['71039'] = [32.430294, -93.2897];
Zipcodes['71040'] = [32.783708, -92.998142];
Zipcodes['71043'] = [32.886012, -93.887836];
Zipcodes['71044'] = [32.954178, -93.882456];
Zipcodes['71045'] = [32.335904, -93.162819];
Zipcodes['71046'] = [32.172426, -93.935005];
Zipcodes['71047'] = [32.302147, -93.905099];
Zipcodes['71048'] = [32.835964, -92.850708];
Zipcodes['71049'] = [32.014274, -93.971246];
Zipcodes['71051'] = [32.319454, -93.477953];
Zipcodes['71052'] = [32.015449, -93.656448];
Zipcodes['71055'] = [32.676883, -93.293312];
Zipcodes['71060'] = [32.64095, -93.98931];
Zipcodes['71061'] = [32.753997, -93.976297];
Zipcodes['71063'] = [31.911513, -93.514993];
Zipcodes['71064'] = [32.911171, -93.688336];
Zipcodes['71065'] = [31.803198, -93.502679];
Zipcodes['71066'] = [31.874781, -93.198262];
Zipcodes['71067'] = [32.61081, -93.463765];
Zipcodes['71068'] = [32.276729, -93.303455];
Zipcodes['71069'] = [32.971292, -93.988152];
Zipcodes['71070'] = [32.084929, -92.98803];
Zipcodes['71071'] = [32.918355, -93.449941];
Zipcodes['71072'] = [32.938004, -93.312011];
Zipcodes['71073'] = [32.51414, -93.311486];
Zipcodes['71075'] = [32.991404, -93.479899];
Zipcodes['71078'] = [32.278203, -93.797767];
Zipcodes['71079'] = [32.95697, -92.808435];
Zipcodes['71082'] = [32.842181, -93.978352];
Zipcodes['71101'] = [32.505424, -93.748748];
Zipcodes['71103'] = [32.491546, -93.771694];
Zipcodes['71104'] = [32.483775, -93.731646];
Zipcodes['71105'] = [32.455146, -93.709919];
Zipcodes['71106'] = [32.384654, -93.736285];
Zipcodes['71107'] = [32.589392, -93.861725];
Zipcodes['71108'] = [32.443173, -93.787826];
Zipcodes['71109'] = [32.467911, -93.813872];
Zipcodes['71110'] = [32.500371, -93.630892];
Zipcodes['71111'] = [32.572361, -93.696339];
Zipcodes['71112'] = [32.448826, -93.645111];
Zipcodes['71115'] = [32.264471, -93.56293];
Zipcodes['71118'] = [32.393679, -93.804064];
Zipcodes['71119'] = [32.490669, -93.925462];
Zipcodes['71129'] = [32.393039, -93.926716];
Zipcodes['71201'] = [32.539557, -92.106941];
Zipcodes['71202'] = [32.399906, -92.05655];
Zipcodes['71203'] = [32.581991, -92.018572];
Zipcodes['71209'] = [32.531362, -92.070982];
Zipcodes['71212'] = [32.526084, -92.074299];
Zipcodes['71218'] = [32.350797, -91.787398];
Zipcodes['71219'] = [32.325706, -91.69322];
Zipcodes['71220'] = [32.864539, -91.908541];
Zipcodes['71222'] = [32.82922, -92.660475];
Zipcodes['71223'] = [32.89102, -91.67854];
Zipcodes['71225'] = [32.50693, -92.350471];
Zipcodes['71226'] = [32.250749, -92.437982];
Zipcodes['71227'] = [32.529605, -92.474256];
Zipcodes['71229'] = [32.631923, -91.896808];
Zipcodes['71230'] = [32.240305, -91.592824];
Zipcodes['71232'] = [32.414389, -91.496652];
Zipcodes['71233'] = [32.325145, -90.931697];
Zipcodes['71234'] = [32.64523, -92.330531];
Zipcodes['71235'] = [32.690015, -92.673804];
Zipcodes['71237'] = [32.588352, -91.481055];
Zipcodes['71238'] = [32.363057, -92.375235];
Zipcodes['71241'] = [32.779372, -92.352043];
Zipcodes['71242'] = [32.792233, -91.412918];
Zipcodes['71243'] = [31.95173, -91.816093];
Zipcodes['71245'] = [32.526936, -92.71556];
Zipcodes['71247'] = [32.271289, -92.720338];
Zipcodes['71250'] = [32.958209, -91.572997];
Zipcodes['71251'] = [32.220631, -92.67689];
Zipcodes['71253'] = [32.996085, -91.315728];
Zipcodes['71254'] = [32.807682, -91.235149];
Zipcodes['71256'] = [32.947741, -92.70947];
Zipcodes['71259'] = [32.259266, -91.848657];
Zipcodes['71260'] = [32.907919, -92.234963];
Zipcodes['71261'] = [32.778105, -91.694108];
Zipcodes['71263'] = [32.871845, -91.425837];
Zipcodes['71264'] = [32.601281, -91.772528];
Zipcodes['71266'] = [32.711035, -91.477007];
Zipcodes['71268'] = [32.346067, -92.73702];
Zipcodes['71269'] = [32.440635, -91.793784];
Zipcodes['71270'] = [32.489856, -92.643874];
Zipcodes['71272'] = [32.529187, -92.651631];
Zipcodes['71275'] = [32.511899, -92.816833];
Zipcodes['71276'] = [32.581774, -91.159545];
Zipcodes['71277'] = [32.943343, -92.550972];
Zipcodes['71279'] = [32.486719, -91.856879];
Zipcodes['71280'] = [32.717701, -92.101204];
Zipcodes['71282'] = [32.346878, -91.231932];
Zipcodes['71286'] = [32.64739, -91.23319];
Zipcodes['71291'] = [32.565188, -92.173211];
Zipcodes['71292'] = [32.396681, -92.214761];
Zipcodes['71295'] = [32.145309, -91.708966];
Zipcodes['71301'] = [31.274156, -92.467107];
Zipcodes['71302'] = [31.197018, -92.37022];
Zipcodes['71303'] = [31.281321, -92.547344];
Zipcodes['71316'] = [31.288968, -91.755272];
Zipcodes['71320'] = [31.113686, -91.776];
Zipcodes['71322'] = [30.871128, -92.175542];
Zipcodes['71323'] = [31.26656, -92.210375];
Zipcodes['71325'] = [31.016772, -92.336244];
Zipcodes['71326'] = [31.772181, -91.585739];
Zipcodes['71327'] = [30.976902, -92.034859];
Zipcodes['71328'] = [31.357684, -92.188903];
Zipcodes['71330'] = [31.110023, -92.241499];
Zipcodes['71331'] = [31.252072, -92.065984];
Zipcodes['71333'] = [30.909986, -92.07187];
Zipcodes['71334'] = [31.668507, -91.581596];
Zipcodes['71336'] = [32.027122, -91.591595];
Zipcodes['71339'] = [31.005742, -91.936359];
Zipcodes['71340'] = [31.759812, -91.80805];
Zipcodes['71341'] = [31.056111, -92.164117];
Zipcodes['71342'] = [31.593716, -92.137601];
Zipcodes['71343'] = [31.520809, -91.899171];
Zipcodes['71345'] = [30.728356, -91.975451];
Zipcodes['71346'] = [31.118246, -92.374928];
Zipcodes['71350'] = [31.066509, -92.060267];
Zipcodes['71351'] = [31.184246, -91.960829];
Zipcodes['71353'] = [30.612978, -91.767213];
Zipcodes['71354'] = [31.404457, -91.750053];
Zipcodes['71355'] = [31.058397, -91.841215];
Zipcodes['71356'] = [30.835335, -92.02834];
Zipcodes['71357'] = [32.118991, -91.299302];
Zipcodes['71358'] = [30.716673, -91.84786];
Zipcodes['71359'] = [31.324701, -92.426319];
Zipcodes['71360'] = [31.324271, -92.354146];
Zipcodes['71362'] = [30.899117, -91.961057];
Zipcodes['71366'] = [31.950193, -91.352398];
Zipcodes['71367'] = [30.875518, -92.288288];
Zipcodes['71368'] = [31.879707, -91.681882];
Zipcodes['71369'] = [30.936543, -91.854161];
Zipcodes['71371'] = [31.674053, -92.249684];
Zipcodes['71373'] = [31.375868, -91.585156];
Zipcodes['71375'] = [31.842335, -91.468245];
Zipcodes['71378'] = [31.942365, -91.708659];
Zipcodes['71401'] = [31.794781, -91.944613];
Zipcodes['71403'] = [31.210416, -93.445374];
Zipcodes['71404'] = [31.752115, -92.746016];
Zipcodes['71405'] = [31.409505, -92.40173];
Zipcodes['71406'] = [31.734226, -93.505319];
Zipcodes['71407'] = [31.517243, -92.48646];
Zipcodes['71409'] = [31.310753, -92.689221];
Zipcodes['71410'] = [31.964224, -92.771219];
Zipcodes['71411'] = [31.905455, -93.090534];
Zipcodes['71414'] = [31.825687, -93.024517];
Zipcodes['71415'] = [32.025394, -92.142355];
Zipcodes['71416'] = [31.540434, -92.896951];
Zipcodes['71417'] = [31.508204, -92.649646];
Zipcodes['71418'] = [32.137308, -92.048469];
Zipcodes['71419'] = [31.797619, -93.728964];
Zipcodes['71422'] = [32.076589, -92.657982];
Zipcodes['71423'] = [31.603431, -92.553342];
Zipcodes['71424'] = [31.1795, -92.696673];
Zipcodes['71425'] = [31.885091, -91.870687];
Zipcodes['71426'] = [31.491792, -93.461665];
Zipcodes['71427'] = [31.384968, -92.891088];
Zipcodes['71428'] = [31.609205, -93.088533];
Zipcodes['71429'] = [31.400435, -93.426223];
Zipcodes['71430'] = [31.067683, -92.527582];
Zipcodes['71432'] = [31.749577, -92.46771];
Zipcodes['71433'] = [31.00118, -92.639215];
Zipcodes['71434'] = [31.442012, -92.942844];
Zipcodes['71435'] = [32.025951, -92.152261];
Zipcodes['71438'] = [31.1018, -92.880251];
Zipcodes['71439'] = [31.339524, -93.367906];
Zipcodes['71441'] = [31.951551, -92.15343];
Zipcodes['71446'] = [31.157403, -93.173885];
Zipcodes['71447'] = [31.411719, -92.81051];
Zipcodes['71449'] = [31.521833, -93.54044];
Zipcodes['71450'] = [31.779008, -93.398733];
Zipcodes['71452'] = [31.582642, -92.945043];
Zipcodes['71454'] = [31.69142, -92.858382];
Zipcodes['71455'] = [31.403448, -92.9895];
Zipcodes['71456'] = [31.619037, -92.961449];
Zipcodes['71457'] = [31.739201, -93.091426];
Zipcodes['71459'] = [31.077352, -93.22042];
Zipcodes['71461'] = [31.12101, -93.297735];
Zipcodes['71462'] = [31.669175, -93.736203];
Zipcodes['71463'] = [30.808963, -92.647273];
Zipcodes['71465'] = [31.871987, -92.203887];
Zipcodes['71466'] = [31.215909, -92.748853];
Zipcodes['71467'] = [31.563329, -92.387104];
Zipcodes['71468'] = [31.475874, -93.138491];
Zipcodes['71469'] = [31.694472, -93.270631];
Zipcodes['71472'] = [31.168851, -92.787163];
Zipcodes['71473'] = [32.068969, -92.429741];
Zipcodes['71474'] = [31.277182, -93.014278];
Zipcodes['71475'] = [31.201296, -93.1246];
Zipcodes['71479'] = [31.857738, -92.353053];
Zipcodes['71480'] = [31.870004, -92.279];
Zipcodes['71483'] = [31.892384, -92.657875];
Zipcodes['71485'] = [31.173893, -92.520418];
Zipcodes['71486'] = [31.627595, -93.658762];
Zipcodes['71497'] = [31.749019, -93.097965];
Zipcodes['71601'] = [34.17938, -91.892483];
Zipcodes['71602'] = [34.27466, -92.132158];
Zipcodes['71603'] = [34.121075, -92.088127];
Zipcodes['71630'] = [33.61235, -91.233515];
Zipcodes['71631'] = [33.581916, -92.264171];
Zipcodes['71635'] = [33.13246, -92.004317];
Zipcodes['71638'] = [33.520936, -91.484668];
Zipcodes['71639'] = [33.886641, -91.536646];
Zipcodes['71640'] = [33.121283, -91.247331];
Zipcodes['71642'] = [33.388539, -91.876108];
Zipcodes['71643'] = [34.016891, -91.576497];
Zipcodes['71644'] = [34.09986, -91.715419];
Zipcodes['71646'] = [33.207451, -91.775994];
Zipcodes['71647'] = [33.36436, -92.114035];
Zipcodes['71651'] = [33.326924, -92.295767];
Zipcodes['71652'] = [33.89324, -92.322752];
Zipcodes['71653'] = [33.371542, -91.284111];
Zipcodes['71654'] = [33.621105, -91.37108];
Zipcodes['71655'] = [33.628015, -91.73055];
Zipcodes['71658'] = [33.325314, -91.573421];
Zipcodes['71659'] = [34.135067, -91.780736];
Zipcodes['71660'] = [33.76064, -92.19826];
Zipcodes['71661'] = [33.146196, -91.511986];
Zipcodes['71662'] = [33.81164, -91.511885];
Zipcodes['71663'] = [33.226443, -91.429483];
Zipcodes['71665'] = [33.93287, -92.135251];
Zipcodes['71666'] = [33.744892, -91.261168];
Zipcodes['71667'] = [33.93556, -91.844415];
Zipcodes['71670'] = [33.745277, -91.400895];
Zipcodes['71671'] = [33.602006, -92.096018];
Zipcodes['71674'] = [33.826243, -91.181163];
Zipcodes['71675'] = [33.606476, -91.94164];
Zipcodes['71676'] = [33.051964, -91.555111];
Zipcodes['71677'] = [33.76185, -91.490645];
Zipcodes['71678'] = [34.024635, -91.827185];
Zipcodes['71701'] = [33.579188, -92.817453];
Zipcodes['71720'] = [33.775378, -92.638313];
Zipcodes['71721'] = [33.890889, -93.206447];
Zipcodes['71722'] = [33.721791, -93.174681];
Zipcodes['71724'] = [33.334924, -92.534802];
Zipcodes['71725'] = [34.039987, -92.535234];
Zipcodes['71726'] = [33.682267, -92.99141];
Zipcodes['71728'] = [34.008912, -93.105276];
Zipcodes['71730'] = [33.201125, -92.633528];
Zipcodes['71740'] = [33.076871, -93.167354];
Zipcodes['71742'] = [33.827537, -92.45334];
Zipcodes['71743'] = [33.889333, -93.097793];
Zipcodes['71744'] = [33.496803, -92.480163];
Zipcodes['71745'] = [33.499739, -92.394977];
Zipcodes['71747'] = [33.053051, -92.22566];
Zipcodes['71748'] = [33.902984, -92.433246];
Zipcodes['71749'] = [33.071881, -92.818579];
Zipcodes['71751'] = [33.406805, -92.770625];
Zipcodes['71752'] = [33.391416, -93.191768];
Zipcodes['71753'] = [33.225678, -93.184674];
Zipcodes['71758'] = [33.309289, -92.914948];
Zipcodes['71759'] = [33.31508, -92.659656];
Zipcodes['71762'] = [33.342917, -92.768357];
Zipcodes['71763'] = [33.905227, -92.803797];
Zipcodes['71764'] = [33.431882, -93.048947];
Zipcodes['71765'] = [33.141882, -92.331795];
Zipcodes['71766'] = [33.734007, -92.496273];
Zipcodes['71770'] = [33.390938, -93.309963];
Zipcodes['71772'] = [33.833785, -93.126452];
Zipcodes['71801'] = [33.623006, -93.611656];
Zipcodes['71820'] = [33.785683, -94.24202];
Zipcodes['71822'] = [33.670153, -94.147931];
Zipcodes['71823'] = [33.829113, -94.123319];
Zipcodes['71825'] = [33.877866, -93.556441];
Zipcodes['71826'] = [33.09254, -93.723219];
Zipcodes['71827'] = [33.389945, -93.409857];
Zipcodes['71831'] = [33.771695, -93.851461];
Zipcodes['71832'] = [34.041462, -94.357126];
Zipcodes['71833'] = [34.134854, -94.023014];
Zipcodes['71834'] = [33.114843, -93.957845];
Zipcodes['71835'] = [33.675464, -93.451161];
Zipcodes['71836'] = [33.713096, -94.394164];
Zipcodes['71837'] = [33.254898, -93.845308];
Zipcodes['71838'] = [33.653606, -93.793448];
Zipcodes['71839'] = [33.292126, -93.743268];
Zipcodes['71841'] = [34.161997, -94.339515];
Zipcodes['71842'] = [33.893347, -94.292923];
Zipcodes['71845'] = [33.315589, -93.643958];
Zipcodes['71846'] = [33.924063, -94.124186];
Zipcodes['71847'] = [33.921878, -93.630167];
Zipcodes['71851'] = [33.856659, -93.942522];
Zipcodes['71852'] = [33.985571, -93.85297];
Zipcodes['71853'] = [33.593799, -93.938035];
Zipcodes['71854'] = [33.444906, -93.894766];
Zipcodes['71855'] = [33.863124, -93.740145];
Zipcodes['71857'] = [33.801479, -93.337654];
Zipcodes['71858'] = [33.585124, -93.26375];
Zipcodes['71859'] = [33.722244, -93.923077];
Zipcodes['71860'] = [33.309324, -93.492407];
Zipcodes['71861'] = [33.100688, -93.499923];
Zipcodes['71862'] = [33.779653, -93.728054];
Zipcodes['71864'] = [33.508168, -93.289815];
Zipcodes['71865'] = [33.741705, -94.145956];
Zipcodes['71866'] = [33.868442, -94.380289];
Zipcodes['71901'] = [34.52645, -92.97407];
Zipcodes['71909'] = [34.642151, -92.989927];
Zipcodes['71913'] = [34.446001, -93.096602];
Zipcodes['71921'] = [34.250818, -93.405281];
Zipcodes['71922'] = [34.023241, -93.413401];
Zipcodes['71923'] = [34.088935, -93.055583];
Zipcodes['71929'] = [34.303737, -93.159556];
Zipcodes['71933'] = [34.413747, -93.355793];
Zipcodes['71935'] = [34.394967, -93.751325];
Zipcodes['71937'] = [34.398539, -94.381645];
Zipcodes['71940'] = [34.01347, -93.50325];
Zipcodes['71941'] = [34.249093, -92.955284];
Zipcodes['71942'] = [34.221234, -92.994236];
Zipcodes['71943'] = [34.332363, -93.586172];
Zipcodes['71944'] = [34.240165, -94.321995];
Zipcodes['71945'] = [34.500308, -94.384724];
Zipcodes['71949'] = [34.687439, -93.213413];
Zipcodes['71950'] = [34.256158, -93.718063];
Zipcodes['71952'] = [34.276513, -93.821435];
Zipcodes['71953'] = [34.616046, -94.210584];
Zipcodes['71956'] = [34.610493, -93.133011];
Zipcodes['71957'] = [34.563818, -93.595537];
Zipcodes['71958'] = [34.112359, -93.65565];
Zipcodes['71959'] = [34.2499, -93.928716];
Zipcodes['71960'] = [34.472746, -93.693273];
Zipcodes['71961'] = [34.600315, -93.83836];
Zipcodes['71962'] = [34.04887, -93.361722];
Zipcodes['71964'] = [34.424134, -93.240731];
Zipcodes['71965'] = [34.654748, -93.737364];
Zipcodes['71968'] = [34.52417, -93.290493];
Zipcodes['71969'] = [34.669242, -93.62921];
Zipcodes['71970'] = [34.668934, -93.489154];
Zipcodes['71971'] = [34.303846, -94.034364];
Zipcodes['71972'] = [34.389092, -94.242016];
Zipcodes['71973'] = [34.285151, -94.302667];
Zipcodes['71998'] = [34.12618, -93.052783];
Zipcodes['71999'] = [34.127255, -93.059309];
Zipcodes['72001'] = [35.058317, -92.885863];
Zipcodes['72002'] = [34.665968, -92.540194];
Zipcodes['72003'] = [34.38013, -91.405022];
Zipcodes['72004'] = [34.394188, -91.86177];
Zipcodes['72005'] = [35.537189, -91.070427];
Zipcodes['72006'] = [35.243873, -91.359583];
Zipcodes['72007'] = [34.999311, -91.979237];
Zipcodes['72010'] = [35.312605, -91.524084];
Zipcodes['72011'] = [34.500623, -92.465885];
Zipcodes['72012'] = [35.099105, -91.930254];
Zipcodes['72013'] = [35.455344, -92.368472];
Zipcodes['72014'] = [35.430262, -91.112129];
Zipcodes['72015'] = [34.485985, -92.585077];
Zipcodes['72016'] = [34.9834, -92.631977];
Zipcodes['72017'] = [34.887328, -91.387903];
Zipcodes['72019'] = [34.633802, -92.699001];
Zipcodes['72020'] = [35.472466, -91.469203];
Zipcodes['72021'] = [34.856494, -91.216285];
Zipcodes['72022'] = [34.606423, -92.493912];
Zipcodes['72023'] = [34.964339, -92.063643];
Zipcodes['72024'] = [34.752292, -91.74381];
Zipcodes['72025'] = [35.052328, -92.999375];
Zipcodes['72026'] = [34.517311, -91.317798];
Zipcodes['72027'] = [35.369809, -92.57902];
Zipcodes['72028'] = [35.543456, -92.431432];
Zipcodes['72029'] = [34.710347, -91.278025];
Zipcodes['72030'] = [35.427088, -92.675908];
Zipcodes['72031'] = [35.603748, -92.520925];
Zipcodes['72032'] = [35.065558, -92.362771];
Zipcodes['72034'] = [35.053787, -92.47871];
Zipcodes['72035'] = [35.078887, -92.458269];
Zipcodes['72036'] = [35.011116, -91.273098];
Zipcodes['72038'] = [34.452648, -91.238203];
Zipcodes['72039'] = [35.356086, -92.397345];
Zipcodes['72040'] = [34.96967, -91.532797];
Zipcodes['72041'] = [34.733825, -91.47115];
Zipcodes['72042'] = [34.274591, -91.323299];
Zipcodes['72044'] = [35.693152, -92.169769];
Zipcodes['72045'] = [35.093088, -92.077385];
Zipcodes['72046'] = [34.559321, -91.941489];
Zipcodes['72047'] = [35.228094, -92.210596];
Zipcodes['72048'] = [34.225236, -91.111548];
Zipcodes['72051'] = [35.794736, -92.315027];
Zipcodes['72052'] = [35.142496, -91.779184];
Zipcodes['72053'] = [34.700535, -92.228432];
Zipcodes['72055'] = [34.098693, -91.35419];
Zipcodes['72057'] = [34.131153, -92.326497];
Zipcodes['72058'] = [35.245865, -92.374241];
Zipcodes['72059'] = [35.154708, -91.342138];
Zipcodes['72060'] = [35.077141, -91.570454];
Zipcodes['72061'] = [35.324341, -92.289884];
Zipcodes['72063'] = [35.318066, -92.790118];
Zipcodes['72064'] = [34.796673, -91.618453];
Zipcodes['72065'] = [34.534624, -92.286377];
Zipcodes['72066'] = [34.978458, -91.71668];
Zipcodes['72067'] = [35.562102, -92.16594];
Zipcodes['72068'] = [35.143961, -91.721213];
Zipcodes['72069'] = [34.53214, -91.137845];
Zipcodes['72070'] = [35.014877, -92.700586];
Zipcodes['72072'] = [34.516622, -91.765041];
Zipcodes['72073'] = [34.368496, -91.653968];
Zipcodes['72074'] = [35.065658, -91.1054];
Zipcodes['72075'] = [35.640856, -91.31073];
Zipcodes['72076'] = [34.912086, -92.137672];
Zipcodes['72079'] = [34.389235, -92.194478];
Zipcodes['72080'] = [35.426935, -92.844394];
Zipcodes['72081'] = [35.381616, -91.670102];
Zipcodes['72082'] = [35.238631, -91.66935];
Zipcodes['72083'] = [34.605783, -92.01177];
Zipcodes['72084'] = [34.137096, -92.6895];
Zipcodes['72085'] = [35.366681, -91.827731];
Zipcodes['72086'] = [34.806999, -91.907634];
Zipcodes['72087'] = [34.59673, -92.826193];
Zipcodes['72088'] = [35.602275, -92.261009];
Zipcodes['72099'] = [34.900648, -92.154384];
Zipcodes['72101'] = [35.228342, -91.150668];
Zipcodes['72102'] = [35.141903, -91.829167];
Zipcodes['72103'] = [34.590777, -92.381266];
Zipcodes['72104'] = [34.346385, -92.815479];
Zipcodes['72106'] = [34.973928, -92.525787];
Zipcodes['72107'] = [35.141743, -92.536054];
Zipcodes['72108'] = [34.728038, -91.103615];
Zipcodes['72110'] = [35.154561, -92.772448];
Zipcodes['72111'] = [35.238551, -92.131305];
Zipcodes['72112'] = [35.580917, -91.229715];
Zipcodes['72113'] = [34.859573, -92.397599];
Zipcodes['72114'] = [34.764902, -92.260348];
Zipcodes['72116'] = [34.800258, -92.245317];
Zipcodes['72117'] = [34.780634, -92.140428];
Zipcodes['72118'] = [34.840468, -92.326035];
Zipcodes['72119'] = [34.836907, -92.295044];
Zipcodes['72120'] = [34.902228, -92.240817];
Zipcodes['72121'] = [35.455643, -91.785232];
Zipcodes['72122'] = [34.76976, -92.78031];
Zipcodes['72123'] = [35.257764, -91.236698];
Zipcodes['72125'] = [35.066215, -92.790635];
Zipcodes['72126'] = [34.943121, -92.908561];
Zipcodes['72127'] = [35.17311, -92.606047];
Zipcodes['72128'] = [34.313396, -92.63536];
Zipcodes['72129'] = [34.311744, -92.554155];
Zipcodes['72130'] = [35.661399, -92.056438];
Zipcodes['72131'] = [35.40412, -92.19102];
Zipcodes['72132'] = [34.439337, -92.188484];
Zipcodes['72133'] = [34.096022, -91.483353];
Zipcodes['72134'] = [34.61777, -91.350611];
Zipcodes['72135'] = [34.879554, -92.588996];
Zipcodes['72136'] = [35.240238, -92.03032];
Zipcodes['72137'] = [35.336271, -92.042179];
Zipcodes['72139'] = [35.362156, -91.509915];
Zipcodes['72140'] = [34.380707, -91.186485];
Zipcodes['72141'] = [35.521886, -92.695392];
Zipcodes['72142'] = [34.673729, -92.073923];
Zipcodes['72143'] = [35.226714, -91.736844];
Zipcodes['72150'] = [34.310188, -92.397419];
Zipcodes['72152'] = [34.356114, -91.979332];
Zipcodes['72153'] = [35.65606, -92.321852];
Zipcodes['72156'] = [35.287278, -92.682972];
Zipcodes['72157'] = [35.273655, -92.565675];
Zipcodes['72160'] = [34.442806, -91.529738];
Zipcodes['72165'] = [35.576596, -91.4665];
Zipcodes['72166'] = [34.069097, -91.221874];
Zipcodes['72167'] = [34.425121, -92.650983];
Zipcodes['72168'] = [34.434521, -91.96555];
Zipcodes['72169'] = [35.394537, -91.226888];
Zipcodes['72170'] = [34.574355, -91.421539];
Zipcodes['72173'] = [35.100101, -92.211144];
Zipcodes['72175'] = [34.33704, -91.757363];
Zipcodes['72176'] = [34.992756, -91.851804];
Zipcodes['72178'] = [35.203821, -91.612682];
Zipcodes['72179'] = [35.497725, -91.865641];
Zipcodes['72180'] = [34.531162, -92.214438];
Zipcodes['72181'] = [35.162396, -92.445162];
Zipcodes['72182'] = [34.431083, -92.092137];
Zipcodes['72183'] = [34.6015, -92.192268];
Zipcodes['72199'] = [34.828288, -92.290419];
Zipcodes['72201'] = [34.747026, -92.280938];
Zipcodes['72202'] = [34.73813, -92.247251];
Zipcodes['72204'] = [34.717572, -92.358332];
Zipcodes['72205'] = [34.749493, -92.35144];
Zipcodes['72206'] = [34.633113, -92.253224];
Zipcodes['72207'] = [34.775843, -92.342614];
Zipcodes['72209'] = [34.682543, -92.341564];
Zipcodes['72210'] = [34.7139, -92.50845];
Zipcodes['72211'] = [34.746283, -92.417157];
Zipcodes['72212'] = [34.785888, -92.415028];
Zipcodes['72223'] = [34.790813, -92.495154];
Zipcodes['72227'] = [34.77685, -92.373303];
Zipcodes['72301'] = [35.13432, -90.179292];
Zipcodes['72311'] = [34.720727, -90.896838];
Zipcodes['72313'] = [35.530795, -90.13947];
Zipcodes['72315'] = [35.899435, -89.899259];
Zipcodes['72320'] = [34.81107, -90.542405];
Zipcodes['72322'] = [35.068836, -90.80794];
Zipcodes['72324'] = [35.390418, -90.786566];
Zipcodes['72325'] = [35.33068, -90.25394];
Zipcodes['72326'] = [35.116264, -90.907852];
Zipcodes['72327'] = [35.243418, -90.333854];
Zipcodes['72328'] = [34.013236, -91.108775];
Zipcodes['72329'] = [35.623914, -89.971523];
Zipcodes['72330'] = [35.591372, -90.201839];
Zipcodes['72331'] = [35.281026, -90.466302];
Zipcodes['72332'] = [35.101162, -90.302348];
Zipcodes['72333'] = [34.286699, -90.881176];
Zipcodes['72335'] = [34.99439, -90.746054];
Zipcodes['72338'] = [35.432015, -90.14224];
Zipcodes['72339'] = [35.416034, -90.264922];
Zipcodes['72340'] = [34.958544, -91.038698];
Zipcodes['72341'] = [34.87839, -90.769095];
Zipcodes['72342'] = [34.447871, -90.674778];
Zipcodes['72346'] = [35.082326, -90.48449];
Zipcodes['72347'] = [35.381764, -90.992507];
Zipcodes['72348'] = [34.941764, -90.417889];
Zipcodes['72350'] = [35.499779, -90.12918];
Zipcodes['72351'] = [35.675945, -90.097725];
Zipcodes['72352'] = [34.655967, -90.734283];
Zipcodes['72353'] = [34.322777, -90.996365];
Zipcodes['72354'] = [35.642289, -90.312623];
Zipcodes['72355'] = [34.464893, -90.852339];
Zipcodes['72358'] = [35.792094, -89.892431];
Zipcodes['72359'] = [35.018514, -90.71833];
Zipcodes['72360'] = [34.750303, -90.780769];
Zipcodes['72364'] = [35.214002, -90.189576];
Zipcodes['72365'] = [35.563201, -90.420076];
Zipcodes['72366'] = [34.525653, -90.951772];
Zipcodes['72367'] = [34.184851, -91.015236];
Zipcodes['72368'] = [34.810402, -91.023371];
Zipcodes['72369'] = [34.455236, -90.789531];
Zipcodes['72370'] = [35.672875, -90.05801];
Zipcodes['72372'] = [34.96759, -90.950562];
Zipcodes['72373'] = [35.285814, -90.606692];
Zipcodes['72374'] = [34.563424, -90.838469];
Zipcodes['72376'] = [35.088428, -90.308];
Zipcodes['72377'] = [35.675299, -90.340104];
Zipcodes['72379'] = [34.069046, -91.048826];
Zipcodes['72383'] = [34.491175, -91.039601];
Zipcodes['72384'] = [35.375795, -90.219489];
Zipcodes['72386'] = [35.461832, -90.3694];
Zipcodes['72387'] = [35.315679, -90.772679];
Zipcodes['72389'] = [34.372315, -90.883883];
Zipcodes['72390'] = [34.551084, -90.679239];
Zipcodes['72391'] = [35.676512, -90.269545];
Zipcodes['72392'] = [34.965598, -91.100606];
Zipcodes['72394'] = [35.058646, -90.609059];
Zipcodes['72395'] = [35.591618, -90.024744];
Zipcodes['72396'] = [35.229655, -90.831944];
Zipcodes['72401'] = [35.830646, -90.578121];
Zipcodes['72404'] = [35.775228, -90.785018];
Zipcodes['72405'] = [35.90269, -90.650204];
Zipcodes['72410'] = [35.947477, -91.097648];
Zipcodes['72411'] = [35.745029, -90.584413];
Zipcodes['72412'] = [36.125736, -90.691725];
Zipcodes['72413'] = [36.303813, -90.815443];
Zipcodes['72414'] = [35.835097, -90.370007];
Zipcodes['72415'] = [36.126668, -91.175013];
Zipcodes['72416'] = [35.960914, -90.783329];
Zipcodes['72417'] = [35.91569, -90.532225];
Zipcodes['72419'] = [35.737271, -90.353441];
Zipcodes['72421'] = [35.778842, -90.97891];
Zipcodes['72422'] = [36.424558, -90.519488];
Zipcodes['72424'] = [36.391856, -90.728726];
Zipcodes['72425'] = [36.228844, -90.745296];
Zipcodes['72426'] = [35.856098, -90.041596];
Zipcodes['72427'] = [35.868766, -90.948959];
Zipcodes['72428'] = [35.741421, -90.216729];
Zipcodes['72429'] = [35.492674, -90.941747];
Zipcodes['72430'] = [36.335981, -90.173615];
Zipcodes['72431'] = [35.632571, -91.079409];
Zipcodes['72432'] = [35.55307, -90.725948];
Zipcodes['72433'] = [36.025347, -91.038162];
Zipcodes['72434'] = [36.217536, -91.156352];
Zipcodes['72435'] = [36.313068, -90.552888];
Zipcodes['72436'] = [36.239729, -90.47999];
Zipcodes['72437'] = [35.830482, -90.456102];
Zipcodes['72438'] = [35.941708, -90.226926];
Zipcodes['72440'] = [35.992386, -91.264695];
Zipcodes['72441'] = [36.435603, -90.389364];
Zipcodes['72442'] = [35.825848, -90.186546];
Zipcodes['72443'] = [36.180201, -90.402926];
Zipcodes['72444'] = [36.428649, -90.852789];
Zipcodes['72445'] = [35.978197, -91.026586];
Zipcodes['72447'] = [35.908785, -90.328361];
Zipcodes['72449'] = [36.166607, -90.817171];
Zipcodes['72450'] = [36.079547, -90.51644];
Zipcodes['72453'] = [36.306385, -90.667004];
Zipcodes['72454'] = [36.406778, -90.202659];
Zipcodes['72455'] = [36.321334, -91.048884];
Zipcodes['72456'] = [36.448592, -90.325407];
Zipcodes['72457'] = [36.085439, -91.068234];
Zipcodes['72458'] = [36.068281, -91.155588];
Zipcodes['72459'] = [36.271449, -91.297478];
Zipcodes['72460'] = [36.347012, -91.153514];
Zipcodes['72461'] = [36.266319, -90.2742];
Zipcodes['72462'] = [36.389232, -90.759315];
Zipcodes['72464'] = [36.453208, -90.145504];
Zipcodes['72466'] = [36.041989, -91.329589];
Zipcodes['72467'] = [35.842221, -90.677602];
Zipcodes['72469'] = [35.951825, -91.289073];
Zipcodes['72470'] = [36.474682, -90.692281];
Zipcodes['72471'] = [35.824776, -91.121353];
Zipcodes['72472'] = [35.594379, -90.547553];
Zipcodes['72473'] = [35.738276, -91.171315];
Zipcodes['72476'] = [36.04611, -90.916792];
Zipcodes['72478'] = [36.465214, -91.053688];
Zipcodes['72479'] = [35.631671, -90.898939];
Zipcodes['72482'] = [36.268749, -91.358891];
Zipcodes['72501'] = [35.79118, -91.6534];
Zipcodes['72512'] = [36.222377, -91.750663];
Zipcodes['72513'] = [36.231448, -91.647677];
Zipcodes['72515'] = [36.27639, -92.056388];
Zipcodes['72517'] = [36.146299, -91.95408];
Zipcodes['72519'] = [36.134123, -92.180309];
Zipcodes['72520'] = [36.39809, -91.72473];
Zipcodes['72521'] = [35.949672, -91.541117];
Zipcodes['72522'] = [35.80471, -91.466823];
Zipcodes['72523'] = [35.627793, -91.844656];
Zipcodes['72524'] = [35.840215, -91.311438];
Zipcodes['72526'] = [35.877612, -91.766207];
Zipcodes['72527'] = [35.740376, -91.68317];
Zipcodes['72528'] = [36.238176, -92.114983];
Zipcodes['72529'] = [36.289134, -91.567606];
Zipcodes['72530'] = [35.616447, -91.967098];
Zipcodes['72531'] = [36.313953, -92.147815];
Zipcodes['72532'] = [36.086297, -91.588552];
Zipcodes['72533'] = [35.977941, -92.245263];
Zipcodes['72534'] = [35.590036, -91.73681];
Zipcodes['72536'] = [36.143874, -91.768166];
Zipcodes['72537'] = [36.448984, -92.234056];
Zipcodes['72538'] = [36.445567, -92.100292];
Zipcodes['72539'] = [36.330106, -91.722581];
Zipcodes['72540'] = [35.919431, -91.910055];
Zipcodes['72542'] = [36.300396, -91.465971];
Zipcodes['72543'] = [35.459891, -91.99849];
Zipcodes['72544'] = [36.403646, -92.189682];
Zipcodes['72546'] = [35.577135, -91.944853];
Zipcodes['72550'] = [35.710135, -91.783873];
Zipcodes['72553'] = [35.7009, -91.482101];
Zipcodes['72554'] = [36.443532, -91.555087];
Zipcodes['72555'] = [35.768733, -91.871509];
Zipcodes['72556'] = [36.013879, -91.97001];
Zipcodes['72560'] = [35.840811, -92.079416];
Zipcodes['72561'] = [35.932392, -91.813154];
Zipcodes['72562'] = [35.730615, -91.394453];
Zipcodes['72564'] = [35.607044, -91.447244];
Zipcodes['72565'] = [36.21916, -91.908365];
Zipcodes['72566'] = [36.207916, -92.064676];
Zipcodes['72567'] = [35.817453, -91.919775];
Zipcodes['72568'] = [35.566789, -91.621471];
Zipcodes['72569'] = [36.081124, -91.458219];
Zipcodes['72571'] = [35.641673, -91.55121];
Zipcodes['72572'] = [35.897608, -91.29177];
Zipcodes['72573'] = [36.076165, -91.797364];
Zipcodes['72576'] = [36.348507, -91.856063];
Zipcodes['72577'] = [36.033447, -91.711961];
Zipcodes['72578'] = [36.474086, -91.859442];
Zipcodes['72579'] = [35.842624, -91.44487];
Zipcodes['72581'] = [35.547053, -91.96746];
Zipcodes['72583'] = [36.414782, -91.994123];
Zipcodes['72584'] = [36.147922, -91.847342];
Zipcodes['72585'] = [36.178603, -92.014578];
Zipcodes['72587'] = [36.219736, -91.817286];
Zipcodes['72601'] = [36.238038, -93.085958];
Zipcodes['72611'] = [36.246858, -93.316997];
Zipcodes['72615'] = [36.315419, -93.01203];
Zipcodes['72616'] = [36.348327, -93.550846];
Zipcodes['72617'] = [36.019997, -92.386927];
Zipcodes['72619'] = [36.37357, -92.591504];
Zipcodes['72623'] = [36.459148, -92.292698];
Zipcodes['72624'] = [36.082078, -93.363694];
Zipcodes['72626'] = [36.322178, -92.551534];
Zipcodes['72628'] = [35.839875, -93.281762];
Zipcodes['72629'] = [35.738502, -92.566795];
Zipcodes['72630'] = [36.461353, -92.92079];
Zipcodes['72631'] = [36.469075, -93.728427];
Zipcodes['72632'] = [36.373296, -93.743854];
Zipcodes['72633'] = [36.140942, -92.870884];
Zipcodes['72634'] = [36.233065, -92.551825];
Zipcodes['72635'] = [36.317361, -92.483979];
Zipcodes['72636'] = [35.990981, -92.716181];
Zipcodes['72638'] = [36.328182, -93.402386];
Zipcodes['72639'] = [36.021866, -92.496667];
Zipcodes['72640'] = [36.002794, -93.05943];
Zipcodes['72641'] = [35.997041, -93.212373];
Zipcodes['72642'] = [36.370544, -92.544307];
Zipcodes['72644'] = [36.414606, -92.967758];
Zipcodes['72645'] = [35.804129, -92.575389];
Zipcodes['72648'] = [36.087809, -93.162053];
Zipcodes['72650'] = [35.862684, -92.690813];
Zipcodes['72651'] = [36.388162, -92.475143];
Zipcodes['72653'] = [36.342381, -92.38543];
Zipcodes['72655'] = [35.909895, -93.035855];
Zipcodes['72658'] = [36.15042, -92.321486];
Zipcodes['72660'] = [36.478646, -93.377872];
Zipcodes['72661'] = [36.430373, -92.597275];
Zipcodes['72662'] = [36.426384, -93.184571];
Zipcodes['72663'] = [35.938718, -92.332714];
Zipcodes['72666'] = [35.949391, -93.286821];
Zipcodes['72668'] = [36.418465, -92.772377];
Zipcodes['72669'] = [36.06479, -92.873627];
Zipcodes['72670'] = [35.981012, -93.371111];
Zipcodes['72672'] = [36.287895, -92.844306];
Zipcodes['72675'] = [36.000216, -92.800216];
Zipcodes['72677'] = [36.264412, -92.700636];
Zipcodes['72679'] = [35.735176, -92.801028];
Zipcodes['72680'] = [35.880674, -92.330799];
Zipcodes['72682'] = [36.135623, -92.732034];
Zipcodes['72683'] = [35.932007, -93.115062];
Zipcodes['72685'] = [36.064122, -92.98553];
Zipcodes['72686'] = [35.599437, -92.962006];
Zipcodes['72687'] = [36.285164, -92.653934];
Zipcodes['72701'] = [35.992387, -94.085584];
Zipcodes['72703'] = [36.118051, -94.043538];
Zipcodes['72704'] = [36.105647, -94.2965];
Zipcodes['72712'] = [36.398394, -94.22235];
Zipcodes['72713'] = [36.297956, -94.282784];
Zipcodes['72714'] = [36.46735, -94.222151];
Zipcodes['72715'] = [36.467146, -94.304915];
Zipcodes['72717'] = [35.835094, -94.408423];
Zipcodes['72718'] = [36.264638, -94.220694];
Zipcodes['72719'] = [36.367161, -94.295087];
Zipcodes['72721'] = [35.780737, -93.77853];
Zipcodes['72722'] = [36.343752, -94.469914];
Zipcodes['72727'] = [35.913471, -93.934029];
Zipcodes['72729'] = [35.788441, -94.473128];
Zipcodes['72730'] = [36.030478, -94.268678];
Zipcodes['72732'] = [36.432453, -93.959439];
Zipcodes['72734'] = [36.27025, -94.451058];
Zipcodes['72736'] = [36.413792, -94.465005];
Zipcodes['72738'] = [36.162486, -93.886698];
Zipcodes['72739'] = [36.426746, -94.328797];
Zipcodes['72740'] = [36.104648, -93.655394];
Zipcodes['72742'] = [35.996323, -93.51538];
Zipcodes['72744'] = [35.962601, -94.435558];
Zipcodes['72745'] = [36.246524, -94.102609];
Zipcodes['72747'] = [36.370358, -94.580852];
Zipcodes['72749'] = [35.868416, -94.437597];
Zipcodes['72751'] = [36.463315, -94.119873];
Zipcodes['72752'] = [35.834982, -93.556963];
Zipcodes['72753'] = [35.933889, -94.331072];
Zipcodes['72756'] = [36.321106, -93.990073];
Zipcodes['72758'] = [36.299426, -94.140989];
Zipcodes['72760'] = [35.84052, -93.702256];
Zipcodes['72761'] = [36.165256, -94.456061];
Zipcodes['72762'] = [36.18126, -94.225296];
Zipcodes['72764'] = [36.175681, -94.060222];
Zipcodes['72768'] = [36.471983, -94.490056];
Zipcodes['72769'] = [36.035804, -94.500097];
Zipcodes['72773'] = [36.022252, -93.835973];
Zipcodes['72774'] = [35.930543, -94.144646];
Zipcodes['72776'] = [35.912982, -93.666899];
Zipcodes['72801'] = [35.280583, -93.141596];
Zipcodes['72802'] = [35.31894, -93.081593];
Zipcodes['72821'] = [35.45843, -93.713907];
Zipcodes['72823'] = [35.25257, -92.894541];
Zipcodes['72824'] = [35.126107, -93.415332];
Zipcodes['72826'] = [35.129532, -93.710153];
Zipcodes['72827'] = [34.871709, -93.625647];
Zipcodes['72828'] = [34.930718, -93.534996];
Zipcodes['72829'] = [35.103569, -93.175277];
Zipcodes['72830'] = [35.517899, -93.510271];
Zipcodes['72832'] = [35.428288, -93.668646];
Zipcodes['72833'] = [35.01485, -93.50123];
Zipcodes['72834'] = [35.170653, -93.201502];
Zipcodes['72835'] = [35.272957, -93.347922];
Zipcodes['72837'] = [35.524976, -93.104222];
Zipcodes['72838'] = [34.888199, -93.68034];
Zipcodes['72839'] = [35.629712, -93.244286];
Zipcodes['72840'] = [35.45809, -93.615427];
Zipcodes['72841'] = [34.865078, -93.777084];
Zipcodes['72842'] = [35.089887, -93.584537];
Zipcodes['72843'] = [35.579441, -92.902292];
Zipcodes['72845'] = [35.36165, -93.377876];
Zipcodes['72846'] = [35.4839, -93.326796];
Zipcodes['72847'] = [35.371377, -93.267294];
Zipcodes['72851'] = [35.255427, -93.438111];
Zipcodes['72852'] = [35.668768, -93.553758];
Zipcodes['72853'] = [35.013464, -93.22872];
Zipcodes['72854'] = [35.696685, -93.428617];
Zipcodes['72855'] = [35.245001, -93.667573];
Zipcodes['72856'] = [35.768992, -93.057382];
Zipcodes['72857'] = [34.862186, -93.32409];
Zipcodes['72858'] = [35.213311, -93.043996];
Zipcodes['72860'] = [34.965767, -93.44586];
Zipcodes['72863'] = [35.369401, -93.511253];
Zipcodes['72865'] = [35.306339, -93.550736];
Zipcodes['72901'] = [35.365188, -94.414866];
Zipcodes['72903'] = [35.354958, -94.360725];
Zipcodes['72904'] = [35.415224, -94.382518];
Zipcodes['72905'] = [35.263063, -94.242068];
Zipcodes['72908'] = [35.309004, -94.407401];
Zipcodes['72916'] = [35.271983, -94.372193];
Zipcodes['72921'] = [35.463135, -94.1915];
Zipcodes['72923'] = [35.308712, -94.289369];
Zipcodes['72926'] = [34.710472, -94.024727];
Zipcodes['72927'] = [35.080085, -93.939782];
Zipcodes['72928'] = [35.291342, -93.930652];
Zipcodes['72930'] = [35.434713, -94.127067];
Zipcodes['72932'] = [35.604794, -94.376843];
Zipcodes['72933'] = [35.328642, -94.013024];
Zipcodes['72934'] = [35.686804, -94.284335];
Zipcodes['72935'] = [35.494338, -94.132753];
Zipcodes['72936'] = [35.189722, -94.218475];
Zipcodes['72937'] = [35.134729, -94.391456];
Zipcodes['72938'] = [35.004129, -94.377828];
Zipcodes['72940'] = [35.122069, -94.259293];
Zipcodes['72941'] = [35.347955, -94.164694];
Zipcodes['72943'] = [35.180144, -93.786949];
Zipcodes['72944'] = [35.042497, -94.237592];
Zipcodes['72945'] = [35.099425, -94.362627];
Zipcodes['72946'] = [35.652068, -94.135842];
Zipcodes['72947'] = [35.553358, -94.036777];
Zipcodes['72948'] = [35.702664, -94.392935];
Zipcodes['72949'] = [35.562791, -93.807178];
Zipcodes['72950'] = [34.793332, -93.903086];
Zipcodes['72951'] = [35.3005, -93.889348];
Zipcodes['72952'] = [35.568598, -94.301384];
Zipcodes['72955'] = [35.614629, -94.437681];
Zipcodes['72956'] = [35.468947, -94.348179];
Zipcodes['72958'] = [34.9057, -94.150994];
Zipcodes['72959'] = [35.778528, -94.062195];
Zipcodes['73001'] = [35.226206, -98.416515];
Zipcodes['73002'] = [34.96417, -97.741182];
Zipcodes['73003'] = [35.668905, -97.49738];
Zipcodes['73004'] = [35.169807, -97.796972];
Zipcodes['73005'] = [35.051308, -98.240141];
Zipcodes['73006'] = [34.906219, -98.400221];
Zipcodes['73007'] = [35.685355, -97.327459];
Zipcodes['73008'] = [35.510852, -97.642541];
Zipcodes['73009'] = [35.286345, -98.372834];
Zipcodes['73010'] = [35.100739, -97.673404];
Zipcodes['73011'] = [34.847842, -97.746577];
Zipcodes['73012'] = [35.673673, -97.592444];
Zipcodes['73013'] = [35.61699, -97.500777];
Zipcodes['73014'] = [35.587534, -98.145189];
Zipcodes['73015'] = [35.070873, -98.595544];
Zipcodes['73016'] = [35.794599, -97.689364];
Zipcodes['73017'] = [34.925443, -98.095442];
Zipcodes['73018'] = [35.048478, -97.938657];
Zipcodes['73019'] = [35.205661, -97.442738];
Zipcodes['73020'] = [35.46191, -97.262598];
Zipcodes['73021'] = [35.32409, -98.670405];
Zipcodes['73022'] = [35.616939, -97.992248];
Zipcodes['73024'] = [35.370939, -98.80878];
Zipcodes['73025'] = [35.729682, -97.569841];
Zipcodes['73026'] = [35.24046, -97.275732];
Zipcodes['73027'] = [35.96752, -97.24276];
Zipcodes['73028'] = [35.977961, -97.631503];
Zipcodes['73029'] = [34.906458, -98.208171];
Zipcodes['73030'] = [34.472693, -97.188731];
Zipcodes['73031'] = [35.03381, -97.631399];
Zipcodes['73032'] = [34.400463, -97.050581];
Zipcodes['73033'] = [35.306975, -98.555087];
Zipcodes['73034'] = [35.703333, -97.433595];
Zipcodes['73036'] = [35.506223, -97.958645];
Zipcodes['73038'] = [35.124525, -98.451329];
Zipcodes['73040'] = [35.645563, -98.375225];
Zipcodes['73041'] = [35.028147, -98.875792];
Zipcodes['73042'] = [35.222848, -98.247001];
Zipcodes['73043'] = [35.734241, -98.403655];
Zipcodes['73044'] = [35.867906, -97.434361];
Zipcodes['73045'] = [35.511632, -97.141539];
Zipcodes['73047'] = [35.456802, -98.315149];
Zipcodes['73048'] = [35.49658, -98.546273];
Zipcodes['73049'] = [35.58214, -97.28939];
Zipcodes['73050'] = [35.938315, -97.256014];
Zipcodes['73051'] = [35.031833, -97.246443];
Zipcodes['73052'] = [34.833332, -97.596182];
Zipcodes['73053'] = [35.355908, -98.412145];
Zipcodes['73054'] = [35.675227, -97.203883];
Zipcodes['73055'] = [34.645249, -97.92308];
Zipcodes['73056'] = [36.169358, -97.61278];
Zipcodes['73057'] = [34.831027, -97.428642];
Zipcodes['73058'] = [35.819993, -97.225807];
Zipcodes['73059'] = [35.328975, -98.050724];
Zipcodes['73061'] = [36.351116, -97.022355];
Zipcodes['73062'] = [35.022301, -98.735171];
Zipcodes['73063'] = [36.060733, -97.425731];
Zipcodes['73064'] = [35.375491, -97.753526];
Zipcodes['73065'] = [35.255255, -97.610137];
Zipcodes['73066'] = [35.501707, -97.328229];
Zipcodes['73067'] = [34.911077, -97.907103];
Zipcodes['73068'] = [35.138752, -97.282243];
Zipcodes['73069'] = [35.252521, -97.464398];
Zipcodes['73070'] = [35.227641, -97.42718];
Zipcodes['73071'] = [35.237099, -97.407945];
Zipcodes['73072'] = [35.211565, -97.501018];
Zipcodes['73073'] = [36.161933, -97.394581];
Zipcodes['73074'] = [34.833426, -97.274183];
Zipcodes['73075'] = [34.748844, -97.2324];
Zipcodes['73077'] = [36.303005, -97.268141];
Zipcodes['73078'] = [35.674845, -97.753508];
Zipcodes['73079'] = [35.210934, -98.012001];
Zipcodes['73080'] = [35.016436, -97.449803];
Zipcodes['73082'] = [34.776197, -97.90211];
Zipcodes['73084'] = [35.525673, -97.346456];
Zipcodes['73086'] = [34.485294, -96.971843];
Zipcodes['73089'] = [35.268848, -97.798843];
Zipcodes['73090'] = [35.397531, -97.962446];
Zipcodes['73092'] = [35.131203, -98.073831];
Zipcodes['73093'] = [35.108417, -97.506471];
Zipcodes['73095'] = [34.907688, -97.285181];
Zipcodes['73096'] = [35.51281, -98.722072];
Zipcodes['73097'] = [35.398931, -97.645011];
Zipcodes['73098'] = [34.627041, -97.164925];
Zipcodes['73099'] = [35.518409, -97.766172];
Zipcodes['73102'] = [35.470652, -97.51909];
Zipcodes['73103'] = [35.489035, -97.518988];
Zipcodes['73104'] = [35.475123, -97.5038];
Zipcodes['73105'] = [35.519523, -97.503225];
Zipcodes['73106'] = [35.482265, -97.536656];
Zipcodes['73107'] = [35.481939, -97.574484];
Zipcodes['73108'] = [35.450299, -97.567681];
Zipcodes['73109'] = [35.432796, -97.524518];
Zipcodes['73110'] = [35.448057, -97.39451];
Zipcodes['73111'] = [35.519496, -97.477422];
Zipcodes['73112'] = [35.518006, -97.572972];
Zipcodes['73114'] = [35.578131, -97.517635];
Zipcodes['73115'] = [35.442311, -97.441535];
Zipcodes['73116'] = [35.546701, -97.564592];
Zipcodes['73117'] = [35.474862, -97.462502];
Zipcodes['73118'] = [35.518242, -97.530937];
Zipcodes['73119'] = [35.421495, -97.568962];
Zipcodes['73120'] = [35.581316, -97.573674];
Zipcodes['73121'] = [35.519732, -97.444896];
Zipcodes['73122'] = [35.520484, -97.61344];
Zipcodes['73127'] = [35.477618, -97.648683];
Zipcodes['73128'] = [35.442497, -97.662583];
Zipcodes['73129'] = [35.431646, -97.484321];
Zipcodes['73130'] = [35.458769, -97.345832];
Zipcodes['73131'] = [35.576458, -97.469294];
Zipcodes['73132'] = [35.543835, -97.63894];
Zipcodes['73134'] = [35.6151, -97.569399];
Zipcodes['73135'] = [35.400268, -97.433076];
Zipcodes['73139'] = [35.386377, -97.525689];
Zipcodes['73141'] = [35.519962, -97.394475];
Zipcodes['73142'] = [35.609765, -97.646818];
Zipcodes['73145'] = [35.424279, -97.414037];
Zipcodes['73149'] = [35.389204, -97.489087];
Zipcodes['73150'] = [35.401238, -97.329876];
Zipcodes['73151'] = [35.571686, -97.416539];
Zipcodes['73159'] = [35.387855, -97.578835];
Zipcodes['73160'] = [35.336297, -97.475078];
Zipcodes['73162'] = [35.582011, -97.640928];
Zipcodes['73165'] = [35.358755, -97.37304];
Zipcodes['73169'] = [35.380573, -97.648406];
Zipcodes['73170'] = [35.325285, -97.552201];
Zipcodes['73173'] = [35.340805, -97.627491];
Zipcodes['73179'] = [35.425259, -97.649483];
Zipcodes['73401'] = [34.207795, -97.106823];
Zipcodes['73425'] = [34.462092, -97.570239];
Zipcodes['73430'] = [33.898323, -97.361382];
Zipcodes['73432'] = [34.252177, -96.422675];
Zipcodes['73433'] = [34.64052, -97.420324];
Zipcodes['73434'] = [34.618434, -97.578939];
Zipcodes['73435'] = [34.357864, -97.495];
Zipcodes['73436'] = [34.285608, -97.040507];
Zipcodes['73437'] = [34.367943, -97.412376];
Zipcodes['73438'] = [34.281559, -97.503211];
Zipcodes['73439'] = [33.937901, -96.744112];
Zipcodes['73440'] = [33.961721, -96.889375];
Zipcodes['73441'] = [33.867323, -97.440147];
Zipcodes['73442'] = [34.323029, -97.689263];
Zipcodes['73443'] = [34.161956, -97.297727];
Zipcodes['73444'] = [34.4745, -97.388925];
Zipcodes['73446'] = [34.088832, -96.778025];
Zipcodes['73447'] = [34.247252, -96.85345];
Zipcodes['73448'] = [33.948312, -97.106144];
Zipcodes['73449'] = [33.991024, -96.543615];
Zipcodes['73450'] = [34.267306, -96.538942];
Zipcodes['73453'] = [34.030828, -97.27098];
Zipcodes['73455'] = [34.229981, -96.760353];
Zipcodes['73456'] = [34.109825, -97.623006];
Zipcodes['73458'] = [34.349739, -97.313891];
Zipcodes['73459'] = [33.773242, -97.139314];
Zipcodes['73460'] = [34.297279, -96.667517];
Zipcodes['73461'] = [34.356085, -96.433061];
Zipcodes['73463'] = [34.123762, -97.434885];
Zipcodes['73481'] = [34.427014, -97.527411];
Zipcodes['73487'] = [34.469502, -97.442062];
Zipcodes['73488'] = [34.493463, -97.555045];
Zipcodes['73491'] = [34.467572, -97.65959];
Zipcodes['73501'] = [34.557943, -98.290972];
Zipcodes['73503'] = [34.682971, -98.49182];
Zipcodes['73505'] = [34.572847, -98.487889];
Zipcodes['73507'] = [34.728258, -98.474582];
Zipcodes['73520'] = [34.268706, -97.977189];
Zipcodes['73521'] = [34.634457, -99.315307];
Zipcodes['73523'] = [34.655169, -99.285311];
Zipcodes['73526'] = [34.792879, -99.265561];
Zipcodes['73527'] = [34.578229, -98.622288];
Zipcodes['73528'] = [34.398748, -98.653325];
Zipcodes['73529'] = [34.337674, -97.952942];
Zipcodes['73530'] = [34.254868, -99.051492];
Zipcodes['73531'] = [34.195431, -98.528173];
Zipcodes['73532'] = [34.629571, -99.588932];
Zipcodes['73533'] = [34.504963, -97.866672];
Zipcodes['73537'] = [34.468361, -99.679178];
Zipcodes['73538'] = [34.775838, -98.383602];
Zipcodes['73539'] = [34.457248, -99.280735];
Zipcodes['73540'] = [34.467092, -98.560889];
Zipcodes['73541'] = [34.788728, -98.180721];
Zipcodes['73542'] = [34.398024, -98.935928];
Zipcodes['73543'] = [34.470568, -98.391798];
Zipcodes['73544'] = [34.660295, -99.754445];
Zipcodes['73546'] = [34.222944, -98.723483];
Zipcodes['73547'] = [34.973872, -99.404615];
Zipcodes['73548'] = [34.211349, -98.131958];
Zipcodes['73549'] = [34.644812, -99.152829];
Zipcodes['73550'] = [34.703473, -99.901034];
Zipcodes['73551'] = [34.307588, -98.880594];
Zipcodes['73552'] = [34.628741, -98.760163];
Zipcodes['73553'] = [34.329576, -98.701219];
Zipcodes['73554'] = [34.84157, -99.608195];
Zipcodes['73555'] = [34.501511, -98.965923];
Zipcodes['73556'] = [34.725983, -99.386657];
Zipcodes['73557'] = [34.731024, -98.500784];
Zipcodes['73559'] = [34.730027, -98.940492];
Zipcodes['73560'] = [34.546578, -99.448356];
Zipcodes['73562'] = [34.190541, -98.39712];
Zipcodes['73564'] = [34.848463, -98.999632];
Zipcodes['73565'] = [34.019909, -97.893975];
Zipcodes['73566'] = [34.615969, -98.931114];
Zipcodes['73567'] = [34.747384, -98.17093];
Zipcodes['73568'] = [34.263389, -98.219291];
Zipcodes['73569'] = [33.936131, -97.782013];
Zipcodes['73570'] = [34.51463, -99.114772];
Zipcodes['73571'] = [34.914039, -99.928091];
Zipcodes['73572'] = [34.372115, -98.3269];
Zipcodes['73573'] = [34.144062, -97.961663];
Zipcodes['73601'] = [35.487491, -98.950794];
Zipcodes['73620'] = [35.62233, -99.025127];
Zipcodes['73622'] = [35.400168, -98.997714];
Zipcodes['73624'] = [35.359659, -99.171886];
Zipcodes['73625'] = [35.692338, -99.190277];
Zipcodes['73626'] = [35.43872, -99.285133];
Zipcodes['73627'] = [35.197014, -99.452704];
Zipcodes['73628'] = [35.630462, -99.69984];
Zipcodes['73632'] = [35.269165, -98.914828];
Zipcodes['73638'] = [35.849095, -99.795626];
Zipcodes['73639'] = [35.739089, -98.96062];
Zipcodes['73641'] = [35.269648, -99.19678];
Zipcodes['73642'] = [35.822727, -99.909944];
Zipcodes['73644'] = [35.405055, -99.426566];
Zipcodes['73645'] = [35.172186, -99.868655];
Zipcodes['73646'] = [35.819443, -98.62326];
Zipcodes['73647'] = [35.43551, -99.151277];
Zipcodes['73650'] = [35.644545, -99.4224];
Zipcodes['73651'] = [35.025165, -99.069729];
Zipcodes['73654'] = [35.85851, -99.374149];
Zipcodes['73655'] = [34.980189, -99.24916];
Zipcodes['73658'] = [35.948227, -98.718781];
Zipcodes['73659'] = [35.87748, -98.940126];
Zipcodes['73660'] = [35.631691, -99.91387];
Zipcodes['73661'] = [35.17474, -99.021184];
Zipcodes['73662'] = [35.31797, -99.661062];
Zipcodes['73663'] = [36.102944, -98.894969];
Zipcodes['73664'] = [35.17451, -99.235923];
Zipcodes['73666'] = [35.415025, -99.920299];
Zipcodes['73667'] = [36.005902, -99.0399];
Zipcodes['73668'] = [35.224604, -99.976077];
Zipcodes['73669'] = [35.750492, -98.743216];
Zipcodes['73673'] = [35.052195, -99.637624];
Zipcodes['73701'] = [36.426744, -97.791989];
Zipcodes['73703'] = [36.440704, -97.966838];
Zipcodes['73705'] = [36.339188, -97.902082];
Zipcodes['73716'] = [36.485133, -98.551474];
Zipcodes['73717'] = [36.83916, -98.783763];
Zipcodes['73718'] = [36.210239, -98.166753];
Zipcodes['73719'] = [36.964281, -98.263874];
Zipcodes['73720'] = [36.194635, -97.870054];
Zipcodes['73722'] = [36.911995, -98.350361];
Zipcodes['73724'] = [36.029768, -98.690945];
Zipcodes['73726'] = [36.587429, -98.508281];
Zipcodes['73727'] = [36.481719, -98.042668];
Zipcodes['73728'] = [36.729158, -98.4042];
Zipcodes['73729'] = [36.392827, -98.454409];
Zipcodes['73730'] = [36.311725, -97.541166];
Zipcodes['73731'] = [36.644994, -98.603442];
Zipcodes['73733'] = [36.254662, -97.68794];
Zipcodes['73734'] = [35.983368, -97.852175];
Zipcodes['73735'] = [36.280361, -98.062871];
Zipcodes['73736'] = [36.34469, -97.680689];
Zipcodes['73737'] = [36.296299, -98.576856];
Zipcodes['73738'] = [36.439255, -97.558448];
Zipcodes['73739'] = [36.514647, -98.134637];
Zipcodes['73741'] = [36.55371, -98.270121];
Zipcodes['73742'] = [36.096292, -97.921951];
Zipcodes['73743'] = [36.56325, -97.992664];
Zipcodes['73744'] = [35.950038, -98.290872];
Zipcodes['73746'] = [36.68818, -98.662014];
Zipcodes['73747'] = [36.233568, -98.33966];
Zipcodes['73749'] = [36.702659, -98.155153];
Zipcodes['73750'] = [35.853124, -97.94355];
Zipcodes['73753'] = [36.555251, -97.863739];
Zipcodes['73754'] = [36.382547, -98.096765];
Zipcodes['73755'] = [36.14368, -98.598056];
Zipcodes['73756'] = [35.978568, -98.111886];
Zipcodes['73757'] = [36.280109, -97.446871];
Zipcodes['73758'] = [36.934851, -98.085735];
Zipcodes['73759'] = [36.861861, -97.706196];
Zipcodes['73760'] = [36.383293, -98.166938];
Zipcodes['73761'] = [36.684967, -98.031302];
Zipcodes['73762'] = [35.713641, -97.975292];
Zipcodes['73763'] = [36.102881, -98.325303];
Zipcodes['73764'] = [35.854734, -98.201695];
Zipcodes['73766'] = [36.64506, -97.83673];
Zipcodes['73768'] = [36.369201, -98.274804];
Zipcodes['73770'] = [36.054081, -98.497896];
Zipcodes['73771'] = [36.876317, -97.958292];
Zipcodes['73772'] = [35.864523, -98.421152];
Zipcodes['73773'] = [36.253126, -97.920025];
Zipcodes['73801'] = [36.485433, -99.412748];
Zipcodes['73832'] = [36.049393, -99.689049];
Zipcodes['73834'] = [36.851782, -99.57098];
Zipcodes['73835'] = [35.968551, -99.218586];
Zipcodes['73838'] = [36.238724, -98.876324];
Zipcodes['73840'] = [36.402432, -99.643115];
Zipcodes['73841'] = [36.548017, -99.619271];
Zipcodes['73842'] = [36.821781, -99.216173];
Zipcodes['73843'] = [36.398856, -99.829637];
Zipcodes['73844'] = [36.89909, -100.16007];
Zipcodes['73848'] = [36.633991, -100.039852];
Zipcodes['73851'] = [36.573932, -99.781545];
Zipcodes['73852'] = [36.47926, -99.120104];
Zipcodes['73853'] = [36.239248, -99.159683];
Zipcodes['73855'] = [36.874731, -99.853036];
Zipcodes['73857'] = [36.270809, -99.332734];
Zipcodes['73858'] = [36.168196, -99.926684];
Zipcodes['73859'] = [36.137333, -99.285943];
Zipcodes['73860'] = [36.542527, -98.842859];
Zipcodes['73901'] = [36.754581, -101.075164];
Zipcodes['73931'] = [36.587331, -100.801469];
Zipcodes['73932'] = [36.690185, -100.513912];
Zipcodes['73933'] = [36.740858, -102.579737];
Zipcodes['73937'] = [36.566611, -102.759935];
Zipcodes['73938'] = [36.932374, -100.578761];
Zipcodes['73939'] = [36.776778, -101.789896];
Zipcodes['73942'] = [36.74404, -101.496362];
Zipcodes['73944'] = [36.581573, -101.103438];
Zipcodes['73945'] = [36.826905, -101.232916];
Zipcodes['73946'] = [36.935479, -102.80408];
Zipcodes['73947'] = [36.841931, -102.166856];
Zipcodes['73949'] = [36.596924, -101.987742];
Zipcodes['73950'] = [36.854789, -100.89497];
Zipcodes['73951'] = [36.942874, -101.053915];
Zipcodes['73960'] = [36.491667, -101.792613];
Zipcodes['74001'] = [36.488677, -96.062405];
Zipcodes['74002'] = [36.561392, -96.144457];
Zipcodes['74003'] = [36.728268, -96.067573];
Zipcodes['74006'] = [36.712522, -95.895054];
Zipcodes['74008'] = [35.924499, -95.877707];
Zipcodes['74010'] = [35.808125, -96.362114];
Zipcodes['74011'] = [35.981812, -95.809191];
Zipcodes['74012'] = [36.051418, -95.805731];
Zipcodes['74014'] = [36.052965, -95.663961];
Zipcodes['74015'] = [36.182181, -95.718832];
Zipcodes['74016'] = [36.545156, -95.45126];
Zipcodes['74017'] = [36.390927, -95.587689];
Zipcodes['74019'] = [36.284765, -95.603194];
Zipcodes['74020'] = [36.246194, -96.42075];
Zipcodes['74021'] = [36.378774, -95.845826];
Zipcodes['74022'] = [36.925468, -95.978443];
Zipcodes['74023'] = [35.957327, -96.751877];
Zipcodes['74026'] = [35.716909, -96.77966];
Zipcodes['74027'] = [36.81342, -95.607171];
Zipcodes['74028'] = [35.794437, -96.519656];
Zipcodes['74029'] = [36.822884, -95.889907];
Zipcodes['74030'] = [35.984509, -96.56502];
Zipcodes['74032'] = [36.20693, -96.898778];
Zipcodes['74033'] = [35.947098, -96.006341];
Zipcodes['74034'] = [36.232619, -96.562884];
Zipcodes['74035'] = [36.404491, -96.377862];
Zipcodes['74036'] = [36.158187, -95.5066];
Zipcodes['74037'] = [35.998551, -95.97989];
Zipcodes['74038'] = [36.156887, -96.559215];
Zipcodes['74039'] = [35.901719, -96.236557];
Zipcodes['74041'] = [35.937851, -96.058945];
Zipcodes['74042'] = [36.880785, -95.551221];
Zipcodes['74044'] = [36.083465, -96.380671];
Zipcodes['74045'] = [36.221475, -96.699649];
Zipcodes['74046'] = [35.753208, -96.572917];
Zipcodes['74047'] = [35.850773, -96.027278];
Zipcodes['74048'] = [36.691792, -95.650961];
Zipcodes['74050'] = [36.072262, -96.06655];
Zipcodes['74051'] = [36.61401, -95.955767];
Zipcodes['74052'] = [36.066692, -96.570042];
Zipcodes['74053'] = [36.430484, -95.731944];
Zipcodes['74054'] = [36.280388, -96.329327];
Zipcodes['74055'] = [36.276728, -95.818917];
Zipcodes['74056'] = [36.795306, -96.330856];
Zipcodes['74058'] = [36.362625, -96.758594];
Zipcodes['74059'] = [35.971732, -97.067586];
Zipcodes['74060'] = [36.25735, -96.251151];
Zipcodes['74061'] = [36.533125, -95.892635];
Zipcodes['74062'] = [36.037717, -96.90095];
Zipcodes['74063'] = [36.150767, -96.19959];
Zipcodes['74066'] = [35.994351, -96.170162];
Zipcodes['74068'] = [35.907256, -96.577887];
Zipcodes['74070'] = [36.400791, -96.087994];
Zipcodes['74071'] = [35.778762, -96.264795];
Zipcodes['74072'] = [36.95996, -95.616134];
Zipcodes['74073'] = [36.29874, -96.01023];
Zipcodes['74074'] = [36.078654, -97.064146];
Zipcodes['74075'] = [36.169107, -97.083365];
Zipcodes['74077'] = [36.124004, -97.069021];
Zipcodes['74078'] = [36.125273, -97.079718];
Zipcodes['74079'] = [35.743102, -96.656155];
Zipcodes['74080'] = [36.542107, -95.749012];
Zipcodes['74081'] = [36.181667, -96.460082];
Zipcodes['74082'] = [36.448822, -95.880889];
Zipcodes['74083'] = [36.87921, -95.753383];
Zipcodes['74084'] = [36.528019, -96.333939];
Zipcodes['74085'] = [36.110793, -96.722608];
Zipcodes['74103'] = [36.156146, -95.995692];
Zipcodes['74104'] = [36.14624, -95.954196];
Zipcodes['74105'] = [36.097442, -95.961087];
Zipcodes['74106'] = [36.191942, -95.98515];
Zipcodes['74107'] = [36.11488, -96.032371];
Zipcodes['74108'] = [36.140188, -95.789544];
Zipcodes['74110'] = [36.188283, -95.953083];
Zipcodes['74112'] = [36.147036, -95.902328];
Zipcodes['74114'] = [36.126943, -95.946695];
Zipcodes['74115'] = [36.199308, -95.90889];
Zipcodes['74116'] = [36.188756, -95.842573];
Zipcodes['74117'] = [36.246042, -95.901615];
Zipcodes['74119'] = [36.142062, -95.98933];
Zipcodes['74120'] = [36.150734, -95.97737];
Zipcodes['74126'] = [36.237483, -96.017974];
Zipcodes['74127'] = [36.173134, -96.046424];
Zipcodes['74128'] = [36.147408, -95.850349];
Zipcodes['74129'] = [36.12585, -95.871575];
Zipcodes['74130'] = [36.241454, -95.956565];
Zipcodes['74131'] = [36.050812, -96.073378];
Zipcodes['74132'] = [36.048782, -96.016734];
Zipcodes['74133'] = [36.039275, -95.877463];
Zipcodes['74134'] = [36.10981, -95.797189];
Zipcodes['74135'] = [36.097276, -95.923171];
Zipcodes['74136'] = [36.0624, -95.941457];
Zipcodes['74137'] = [36.022431, -95.930573];
Zipcodes['74145'] = [36.097138, -95.890482];
Zipcodes['74146'] = [36.099209, -95.855543];
Zipcodes['74171'] = [36.050615, -95.952561];
Zipcodes['74301'] = [36.677521, -95.242565];
Zipcodes['74330'] = [36.444528, -95.261977];
Zipcodes['74331'] = [36.647697, -94.951834];
Zipcodes['74332'] = [36.547699, -95.315622];
Zipcodes['74333'] = [36.793909, -95.082138];
Zipcodes['74337'] = [36.140741, -95.336936];
Zipcodes['74338'] = [36.255961, -94.712719];
Zipcodes['74339'] = [36.934026, -94.87974];
Zipcodes['74340'] = [36.474142, -95.017845];
Zipcodes['74342'] = [36.427344, -94.944658];
Zipcodes['74343'] = [36.736252, -94.82092];
Zipcodes['74344'] = [36.595479, -94.740655];
Zipcodes['74346'] = [36.439428, -94.754142];
Zipcodes['74347'] = [36.199164, -94.78827];
Zipcodes['74349'] = [36.512542, -95.028058];
Zipcodes['74350'] = [36.466226, -95.047763];
Zipcodes['74352'] = [36.135062, -95.193613];
Zipcodes['74354'] = [36.889474, -94.880953];
Zipcodes['74358'] = [36.917964, -94.880518];
Zipcodes['74359'] = [36.168484, -94.852172];
Zipcodes['74360'] = [36.979996, -94.836481];
Zipcodes['74361'] = [36.308236, -95.306846];
Zipcodes['74363'] = [36.959977, -94.711216];
Zipcodes['74364'] = [36.196316, -94.948524];
Zipcodes['74365'] = [36.287874, -95.059289];
Zipcodes['74366'] = [36.377954, -95.028918];
Zipcodes['74367'] = [36.416088, -95.112888];
Zipcodes['74368'] = [36.207257, -94.848247];
Zipcodes['74369'] = [36.92462, -95.202002];
Zipcodes['74370'] = [36.749784, -94.680364];
Zipcodes['74401'] = [35.723651, -95.458203];
Zipcodes['74403'] = [35.681993, -95.307488];
Zipcodes['74421'] = [35.762714, -96.042025];
Zipcodes['74422'] = [35.643184, -95.690292];
Zipcodes['74423'] = [35.642284, -95.219757];
Zipcodes['74425'] = [35.171244, -95.622996];
Zipcodes['74426'] = [35.445471, -95.52932];
Zipcodes['74427'] = [35.677081, -94.886897];
Zipcodes['74428'] = [35.548672, -95.648126];
Zipcodes['74429'] = [35.948792, -95.632985];
Zipcodes['74430'] = [35.129363, -95.646448];
Zipcodes['74431'] = [35.457795, -95.939104];
Zipcodes['74432'] = [35.282171, -95.650568];
Zipcodes['74434'] = [35.782597, -95.194809];
Zipcodes['74435'] = [35.593287, -95.094694];
Zipcodes['74436'] = [35.805572, -95.704649];
Zipcodes['74437'] = [35.436505, -95.927179];
Zipcodes['74438'] = [35.513228, -95.762052];
Zipcodes['74441'] = [35.976851, -95.145009];
Zipcodes['74442'] = [35.123823, -95.816571];
Zipcodes['74445'] = [35.631467, -95.828602];
Zipcodes['74446'] = [35.851152, -95.322222];
Zipcodes['74447'] = [35.649678, -96.012211];
Zipcodes['74450'] = [35.599554, -95.472748];
Zipcodes['74451'] = [35.723862, -95.011345];
Zipcodes['74452'] = [36.121366, -95.068301];
Zipcodes['74454'] = [35.842528, -95.50907];
Zipcodes['74455'] = [35.361613, -95.268894];
Zipcodes['74456'] = [35.710845, -95.9926];
Zipcodes['74457'] = [36.005973, -94.841439];
Zipcodes['74458'] = [35.88232, -95.58786];
Zipcodes['74459'] = [35.518777, -95.492126];
Zipcodes['74460'] = [35.504767, -95.964493];
Zipcodes['74462'] = [35.279649, -95.146719];
Zipcodes['74463'] = [35.770005, -95.551033];
Zipcodes['74464'] = [35.944081, -94.974083];
Zipcodes['74467'] = [35.967372, -95.393018];
Zipcodes['74468'] = [35.617263, -95.59935];
Zipcodes['74469'] = [35.51191, -95.321491];
Zipcodes['74470'] = [35.476717, -95.166878];
Zipcodes['74471'] = [35.834204, -94.871883];
Zipcodes['74472'] = [35.250048, -95.259719];
Zipcodes['74477'] = [36.003882, -95.266587];
Zipcodes['74501'] = [34.984203, -95.786716];
Zipcodes['74521'] = [34.662141, -95.099369];
Zipcodes['74522'] = [34.882542, -95.676716];
Zipcodes['74523'] = [34.251959, -95.602314];
Zipcodes['74525'] = [34.332767, -96.055576];
Zipcodes['74528'] = [34.702267, -95.727506];
Zipcodes['74530'] = [34.429746, -96.536907];
Zipcodes['74531'] = [34.881495, -96.228053];
Zipcodes['74533'] = [34.208012, -96.197011];
Zipcodes['74534'] = [34.62394, -96.360742];
Zipcodes['74535'] = [34.484118, -96.444883];
Zipcodes['74536'] = [34.582807, -95.42299];
Zipcodes['74538'] = [34.5694, -96.24928];
Zipcodes['74540'] = [34.571036, -95.708133];
Zipcodes['74543'] = [34.359386, -95.381336];
Zipcodes['74546'] = [34.852076, -95.580117];
Zipcodes['74547'] = [34.81008, -95.598826];
Zipcodes['74549'] = [34.562468, -94.894185];
Zipcodes['74552'] = [35.113371, -95.248923];
Zipcodes['74553'] = [34.71336, -95.925331];
Zipcodes['74554'] = [34.925675, -95.719977];
Zipcodes['74555'] = [34.249162, -95.964544];
Zipcodes['74556'] = [34.458207, -96.201347];
Zipcodes['74557'] = [34.387277, -95.698212];
Zipcodes['74558'] = [34.50478, -95.165928];
Zipcodes['74560'] = [34.648749, -95.760132];
Zipcodes['74561'] = [35.130293, -95.415199];
Zipcodes['74562'] = [34.306972, -95.312687];
Zipcodes['74563'] = [34.969955, -95.072632];
Zipcodes['74565'] = [34.81983, -95.822508];
Zipcodes['74567'] = [34.450336, -95.336203];
Zipcodes['74569'] = [34.477288, -95.924577];
Zipcodes['74570'] = [34.862799, -96.100965];
Zipcodes['74571'] = [34.719633, -95.049044];
Zipcodes['74572'] = [34.625926, -96.430569];
Zipcodes['74574'] = [34.676744, -95.261965];
Zipcodes['74576'] = [34.668289, -96.042682];
Zipcodes['74577'] = [34.733498, -94.86177];
Zipcodes['74578'] = [34.906147, -95.341662];
Zipcodes['74601'] = [36.702158, -97.148862];
Zipcodes['74604'] = [36.701928, -96.982361];
Zipcodes['74630'] = [36.499408, -97.435455];
Zipcodes['74631'] = [36.79918, -97.303867];
Zipcodes['74632'] = [36.940722, -97.341297];
Zipcodes['74633'] = [36.710214, -96.776634];
Zipcodes['74636'] = [36.822566, -97.522218];
Zipcodes['74637'] = [36.602785, -96.643875];
Zipcodes['74640'] = [36.57102, -97.649023];
Zipcodes['74641'] = [36.807356, -96.860722];
Zipcodes['74643'] = [36.655037, -97.575123];
Zipcodes['74644'] = [36.5597, -97.095983];
Zipcodes['74646'] = [36.821978, -97.442056];
Zipcodes['74647'] = [36.9181, -96.994561];
Zipcodes['74650'] = [36.480421, -96.720016];
Zipcodes['74651'] = [36.47096, -97.16449];
Zipcodes['74652'] = [36.859145, -96.644047];
Zipcodes['74653'] = [36.648616, -97.340668];
Zipcodes['74701'] = [34.009926, -96.377439];
Zipcodes['74720'] = [33.831478, -96.371632];
Zipcodes['74721'] = [33.879785, -96.162468];
Zipcodes['74722'] = [34.379582, -94.898278];
Zipcodes['74723'] = [33.992941, -96.017164];
Zipcodes['74724'] = [34.342044, -94.861546];
Zipcodes['74726'] = [33.941237, -96.174339];
Zipcodes['74727'] = [34.018703, -95.858735];
Zipcodes['74728'] = [34.157141, -94.820188];
Zipcodes['74729'] = [34.146808, -96.254066];
Zipcodes['74730'] = [33.912288, -96.4311];
Zipcodes['74731'] = [33.870143, -96.577163];
Zipcodes['74733'] = [33.822346, -96.481884];
Zipcodes['74734'] = [34.090553, -94.538276];
Zipcodes['74735'] = [34.096722, -95.261523];
Zipcodes['74736'] = [33.914485, -94.99277];
Zipcodes['74737'] = [34.030741, -94.902967];
Zipcodes['74738'] = [33.895691, -95.443392];
Zipcodes['74740'] = [33.767393, -94.597149];
Zipcodes['74741'] = [33.795005, -96.287607];
Zipcodes['74743'] = [34.006722, -95.510994];
Zipcodes['74745'] = [33.859098, -94.816353];
Zipcodes['74747'] = [33.758123, -96.315483];
Zipcodes['74748'] = [34.130818, -96.493039];
Zipcodes['74750'] = [33.985488, -95.011517];
Zipcodes['74753'] = [33.907851, -96.533959];
Zipcodes['74754'] = [34.191987, -95.119525];
Zipcodes['74755'] = [34.119409, -95.142024];
Zipcodes['74756'] = [34.08451, -95.374012];
Zipcodes['74759'] = [34.056297, -95.719572];
Zipcodes['74760'] = [34.163146, -95.355768];
Zipcodes['74761'] = [34.005089, -95.200867];
Zipcodes['74764'] = [34.090935, -95.057481];
Zipcodes['74766'] = [34.086021, -94.9739];
Zipcodes['74801'] = [35.315129, -96.969671];
Zipcodes['74804'] = [35.391465, -96.921469];
Zipcodes['74820'] = [34.8044, -96.715374];
Zipcodes['74824'] = [35.888506, -96.871285];
Zipcodes['74825'] = [34.822522, -96.391992];
Zipcodes['74826'] = [35.021029, -96.887141];
Zipcodes['74827'] = [34.939754, -96.343384];
Zipcodes['74829'] = [35.504916, -96.466379];
Zipcodes['74830'] = [35.144519, -96.664618];
Zipcodes['74831'] = [34.894175, -97.034356];
Zipcodes['74832'] = [35.828769, -97.006776];
Zipcodes['74833'] = [35.583036, -96.413893];
Zipcodes['74834'] = [35.69671, -96.878659];
Zipcodes['74836'] = [34.442069, -96.629676];
Zipcodes['74837'] = [35.34312, -96.456133];
Zipcodes['74839'] = [35.241439, -96.045582];
Zipcodes['74840'] = [35.308627, -96.769722];
Zipcodes['74842'] = [34.618599, -96.615173];
Zipcodes['74843'] = [34.65702, -96.728623];
Zipcodes['74844'] = [34.875738, -96.589142];
Zipcodes['74845'] = [35.253247, -95.879832];
Zipcodes['74848'] = [35.079874, -96.343719];
Zipcodes['74849'] = [34.978391, -96.735519];
Zipcodes['74850'] = [35.109686, -96.10715];
Zipcodes['74851'] = [35.426238, -97.081953];
Zipcodes['74852'] = [35.12394, -97.008872];
Zipcodes['74854'] = [35.110667, -96.765976];
Zipcodes['74855'] = [35.513888, -96.906423];
Zipcodes['74856'] = [34.396638, -96.816268];
Zipcodes['74857'] = [35.34024, -97.200546];
Zipcodes['74859'] = [35.456479, -96.321622];
Zipcodes['74860'] = [35.531903, -96.569823];
Zipcodes['74864'] = [35.498742, -96.707028];
Zipcodes['74865'] = [34.613477, -96.818238];
Zipcodes['74866'] = [35.066457, -96.846905];
Zipcodes['74867'] = [34.965048, -96.537532];
Zipcodes['74868'] = [35.278531, -96.644613];
Zipcodes['74869'] = [35.590368, -96.751152];
Zipcodes['74871'] = [34.596505, -96.55163];
Zipcodes['74872'] = [34.771696, -96.988016];
Zipcodes['74873'] = [35.232856, -96.977226];
Zipcodes['74875'] = [35.872583, -96.958564];
Zipcodes['74878'] = [35.004771, -97.069829];
Zipcodes['74880'] = [35.379751, -96.120506];
Zipcodes['74881'] = [35.714361, -97.079617];
Zipcodes['74883'] = [35.219868, -96.253013];
Zipcodes['74884'] = [35.184918, -96.515465];
Zipcodes['74901'] = [35.299141, -94.50136];
Zipcodes['74902'] = [35.251531, -94.467919];
Zipcodes['74930'] = [35.184955, -94.775052];
Zipcodes['74931'] = [35.686198, -94.7867];
Zipcodes['74932'] = [35.145602, -94.535377];
Zipcodes['74935'] = [34.961951, -94.911181];
Zipcodes['74936'] = [35.395424, -94.727941];
Zipcodes['74937'] = [34.817524, -94.587454];
Zipcodes['74939'] = [34.729805, -94.632473];
Zipcodes['74940'] = [34.949481, -94.631817];
Zipcodes['74941'] = [35.267539, -94.876146];
Zipcodes['74942'] = [34.894594, -94.978025];
Zipcodes['74943'] = [35.099974, -95.099411];
Zipcodes['74944'] = [35.130606, -95.005635];
Zipcodes['74945'] = [35.583882, -94.817396];
Zipcodes['74946'] = [35.396251, -94.452432];
Zipcodes['74947'] = [34.99147, -94.515856];
Zipcodes['74948'] = [35.467998, -94.568927];
Zipcodes['74949'] = [34.683524, -94.772087];
Zipcodes['74951'] = [35.171573, -94.663636];
Zipcodes['74953'] = [35.0381, -94.571497];
Zipcodes['74954'] = [35.412586, -94.490555];
Zipcodes['74955'] = [35.501381, -94.757501];
Zipcodes['74956'] = [35.096432, -94.774368];
Zipcodes['74957'] = [34.523791, -94.655141];
Zipcodes['74959'] = [35.270912, -94.623306];
Zipcodes['74960'] = [35.810426, -94.650548];
Zipcodes['74962'] = [35.544019, -94.940814];
Zipcodes['74963'] = [34.412238, -94.563365];
Zipcodes['74964'] = [36.111948, -94.658801];
Zipcodes['74965'] = [35.99654, -94.631092];
Zipcodes['74966'] = [34.894289, -94.870567];
Zipcodes['75001'] = [32.960047, -96.83724];
Zipcodes['75002'] = [33.089854, -96.6086];
Zipcodes['75006'] = [32.962141, -96.898585];
Zipcodes['75007'] = [33.005262, -96.896742];
Zipcodes['75009'] = [33.338899, -96.752977];
Zipcodes['75010'] = [33.04078, -96.875462];
Zipcodes['75013'] = [33.114327, -96.693964];
Zipcodes['75019'] = [32.961253, -96.985817];
Zipcodes['75020'] = [33.772722, -96.606962];
Zipcodes['75021'] = [33.727988, -96.478369];
Zipcodes['75022'] = [33.024484, -97.119504];
Zipcodes['75023'] = [33.056789, -96.730859];
Zipcodes['75024'] = [33.077854, -96.811028];
Zipcodes['75025'] = [33.090085, -96.740008];
Zipcodes['75028'] = [33.032998, -97.064618];
Zipcodes['75032'] = [32.857112, -96.43121];
Zipcodes['75033'] = [33.184818, -96.844308];
Zipcodes['75034'] = [33.121286, -96.838536];
Zipcodes['75035'] = [33.166306, -96.775635];
Zipcodes['75036'] = [33.133351, -96.914663];
Zipcodes['75038'] = [32.87226, -96.97881];
Zipcodes['75039'] = [32.884959, -96.937297];
Zipcodes['75040'] = [32.928902, -96.619782];
Zipcodes['75041'] = [32.880196, -96.651555];
Zipcodes['75042'] = [32.914495, -96.674995];
Zipcodes['75043'] = [32.855772, -96.578214];
Zipcodes['75044'] = [32.964609, -96.649688];
Zipcodes['75048'] = [32.969015, -96.579523];
Zipcodes['75050'] = [32.773069, -97.005227];
Zipcodes['75051'] = [32.725591, -96.992072];
Zipcodes['75052'] = [32.664684, -97.029478];
Zipcodes['75054'] = [32.613848, -97.025353];
Zipcodes['75056'] = [33.075699, -96.908088];
Zipcodes['75057'] = [33.050725, -96.974623];
Zipcodes['75058'] = [33.449112, -96.737121];
Zipcodes['75060'] = [32.798049, -96.952563];
Zipcodes['75061'] = [32.825907, -96.966915];
Zipcodes['75062'] = [32.84737, -96.957871];
Zipcodes['75063'] = [32.910627, -97.000481];
Zipcodes['75065'] = [33.11034, -97.00545];
Zipcodes['75067'] = [33.016102, -96.998962];
Zipcodes['75068'] = [33.177497, -96.952388];
Zipcodes['75069'] = [33.164093, -96.59544];
Zipcodes['75070'] = [33.154678, -96.696669];
Zipcodes['75071'] = [33.245816, -96.63072];
Zipcodes['75072'] = [33.187899, -96.699951];
Zipcodes['75074'] = [33.033284, -96.672758];
Zipcodes['75075'] = [33.021268, -96.741558];
Zipcodes['75076'] = [33.800692, -96.726011];
Zipcodes['75077'] = [33.081178, -97.060925];
Zipcodes['75078'] = [33.241332, -96.812388];
Zipcodes['75080'] = [32.976063, -96.74208];
Zipcodes['75081'] = [32.949566, -96.70742];
Zipcodes['75082'] = [32.994599, -96.666758];
Zipcodes['75087'] = [32.948579, -96.442091];
Zipcodes['75088'] = [32.891629, -96.541754];
Zipcodes['75089'] = [32.93758, -96.544176];
Zipcodes['75090'] = [33.604131, -96.550405];
Zipcodes['75092'] = [33.652066, -96.700263];
Zipcodes['75093'] = [33.034224, -96.811608];
Zipcodes['75094'] = [33.01983, -96.613463];
Zipcodes['75098'] = [33.014492, -96.534832];
Zipcodes['75101'] = [32.270024, -96.702449];
Zipcodes['75102'] = [32.086623, -96.635777];
Zipcodes['75103'] = [32.51693, -95.883547];
Zipcodes['75104'] = [32.584814, -96.972312];
Zipcodes['75105'] = [32.267126, -96.367988];
Zipcodes['75109'] = [32.042784, -96.343754];
Zipcodes['75110'] = [32.079637, -96.520474];
Zipcodes['75114'] = [32.616552, -96.443365];
Zipcodes['75115'] = [32.599427, -96.864075];
Zipcodes['75116'] = [32.66066, -96.911882];
Zipcodes['75117'] = [32.711103, -95.858365];
Zipcodes['75119'] = [32.323939, -96.590285];
Zipcodes['75124'] = [32.318568, -95.970223];
Zipcodes['75125'] = [32.53675, -96.621707];
Zipcodes['75126'] = [32.742601, -96.452883];
Zipcodes['75127'] = [32.699397, -95.791444];
Zipcodes['75132'] = [32.938662, -96.380424];
Zipcodes['75134'] = [32.622839, -96.779517];
Zipcodes['75135'] = [33.067548, -96.224353];
Zipcodes['75137'] = [32.634831, -96.91341];
Zipcodes['75140'] = [32.635479, -95.686402];
Zipcodes['75141'] = [32.639977, -96.693284];
Zipcodes['75142'] = [32.568163, -96.250214];
Zipcodes['75143'] = [32.364579, -96.244987];
Zipcodes['75144'] = [32.108388, -96.207955];
Zipcodes['75146'] = [32.566313, -96.748636];
Zipcodes['75147'] = [32.426069, -96.06456];
Zipcodes['75148'] = [32.124647, -96.02579];
Zipcodes['75149'] = [32.773369, -96.618811];
Zipcodes['75150'] = [32.813863, -96.63098];
Zipcodes['75152'] = [32.439871, -96.678051];
Zipcodes['75153'] = [32.166522, -96.358838];
Zipcodes['75154'] = [32.524259, -96.809069];
Zipcodes['75155'] = [32.229113, -96.475509];
Zipcodes['75156'] = [32.277923, -96.105287];
Zipcodes['75157'] = [32.463433, -96.449255];
Zipcodes['75158'] = [32.465314, -96.401101];
Zipcodes['75159'] = [32.604328, -96.5406];
Zipcodes['75160'] = [32.764343, -96.293313];
Zipcodes['75161'] = [32.738794, -96.163857];
Zipcodes['75163'] = [32.166777, -96.118531];
Zipcodes['75164'] = [33.075574, -96.303404];
Zipcodes['75165'] = [32.371654, -96.80303];
Zipcodes['75166'] = [33.014721, -96.449374];
Zipcodes['75167'] = [32.360829, -96.915523];
Zipcodes['75169'] = [32.703269, -95.994666];
Zipcodes['75172'] = [32.605321, -96.674073];
Zipcodes['75173'] = [33.061737, -96.395825];
Zipcodes['75180'] = [32.719382, -96.618565];
Zipcodes['75181'] = [32.723894, -96.555946];
Zipcodes['75182'] = [32.800525, -96.547577];
Zipcodes['75189'] = [32.94205, -96.311669];
Zipcodes['75201'] = [32.788309, -96.799572];
Zipcodes['75202'] = [32.778463, -96.803734];
Zipcodes['75203'] = [32.744319, -96.801867];
Zipcodes['75204'] = [32.80219, -96.78889];
Zipcodes['75205'] = [32.837129, -96.797155];
Zipcodes['75206'] = [32.831279, -96.771191];
Zipcodes['75207'] = [32.7877, -96.820656];
Zipcodes['75208'] = [32.754622, -96.839633];
Zipcodes['75209'] = [32.84831, -96.825474];
Zipcodes['75210'] = [32.771314, -96.747148];
Zipcodes['75211'] = [32.736827, -96.907506];
Zipcodes['75212'] = [32.78225, -96.878833];
Zipcodes['75214'] = [32.828956, -96.742915];
Zipcodes['75215'] = [32.752897, -96.761];
Zipcodes['75216'] = [32.711324, -96.781215];
Zipcodes['75217'] = [32.712583, -96.682045];
Zipcodes['75218'] = [32.840254, -96.704041];
Zipcodes['75219'] = [32.810894, -96.813193];
Zipcodes['75220'] = [32.868529, -96.876118];
Zipcodes['75223'] = [32.792565, -96.745584];
Zipcodes['75224'] = [32.711405, -96.838591];
Zipcodes['75225'] = [32.864887, -96.790359];
Zipcodes['75226'] = [32.783102, -96.776627];
Zipcodes['75227'] = [32.76681, -96.6869];
Zipcodes['75228'] = [32.824924, -96.679438];
Zipcodes['75229'] = [32.893614, -96.867317];
Zipcodes['75230'] = [32.902679, -96.793393];
Zipcodes['75231'] = [32.873494, -96.747555];
Zipcodes['75232'] = [32.658999, -96.839593];
Zipcodes['75233'] = [32.703913, -96.871889];
Zipcodes['75234'] = [32.924853, -96.888139];
Zipcodes['75235'] = [32.832614, -96.849082];
Zipcodes['75236'] = [32.688844, -96.93571];
Zipcodes['75237'] = [32.664916, -96.872401];
Zipcodes['75238'] = [32.878511, -96.707852];
Zipcodes['75240'] = [32.930154, -96.792727];
Zipcodes['75241'] = [32.666747, -96.758878];
Zipcodes['75243'] = [32.912633, -96.736637];
Zipcodes['75244'] = [32.925367, -96.836334];
Zipcodes['75246'] = [32.792496, -96.772763];
Zipcodes['75247'] = [32.814402, -96.878295];
Zipcodes['75248'] = [32.969699, -96.797334];
Zipcodes['75249'] = [32.636053, -96.964574];
Zipcodes['75251'] = [32.919435, -96.77478];
Zipcodes['75252'] = [32.996776, -96.788479];
Zipcodes['75253'] = [32.674426, -96.609228];
Zipcodes['75254'] = [32.944116, -96.80009];
Zipcodes['75261'] = [32.903206, -97.05313];
Zipcodes['75270'] = [32.78126, -96.801922];
Zipcodes['75287'] = [32.999309, -96.841688];
Zipcodes['75390'] = [32.813992, -96.842808];
Zipcodes['75401'] = [33.189002, -96.108829];
Zipcodes['75402'] = [33.067866, -96.08797];
Zipcodes['75407'] = [33.155792, -96.48041];
Zipcodes['75409'] = [33.3555, -96.516313];
Zipcodes['75410'] = [32.79246, -95.637012];
Zipcodes['75411'] = [33.868296, -95.637752];
Zipcodes['75412'] = [33.800848, -95.13475];
Zipcodes['75413'] = [33.435954, -96.164755];
Zipcodes['75414'] = [33.623644, -96.427868];
Zipcodes['75415'] = [33.455683, -95.723798];
Zipcodes['75416'] = [33.598091, -95.32831];
Zipcodes['75417'] = [33.439658, -95.140371];
Zipcodes['75418'] = [33.571922, -96.170962];
Zipcodes['75420'] = [33.065239, -95.71835];
Zipcodes['75421'] = [33.66136, -95.731245];
Zipcodes['75422'] = [33.139105, -95.92361];
Zipcodes['75423'] = [33.291919, -96.199842];
Zipcodes['75424'] = [33.305372, -96.389293];
Zipcodes['75426'] = [33.648255, -95.011561];
Zipcodes['75428'] = [33.274704, -95.917561];
Zipcodes['75429'] = [33.278511, -95.892297];
Zipcodes['75431'] = [33.01419, -95.460935];
Zipcodes['75432'] = [33.385933, -95.67335];
Zipcodes['75433'] = [33.098096, -95.803234];
Zipcodes['75434'] = [33.416263, -95.344129];
Zipcodes['75435'] = [33.500508, -95.290328];
Zipcodes['75436'] = [33.742203, -95.25565];
Zipcodes['75437'] = [33.257166, -95.464741];
Zipcodes['75438'] = [33.615571, -96.072162];
Zipcodes['75439'] = [33.55352, -96.282897];
Zipcodes['75440'] = [32.872902, -95.753991];
Zipcodes['75441'] = [33.427656, -95.642911];
Zipcodes['75442'] = [33.176073, -96.346433];
Zipcodes['75446'] = [33.615093, -95.900092];
Zipcodes['75447'] = [33.772396, -96.124356];
Zipcodes['75448'] = [33.316269, -95.788968];
Zipcodes['75449'] = [33.423386, -95.949483];
Zipcodes['75450'] = [33.397908, -95.461615];
Zipcodes['75451'] = [32.9593, -95.12521];
Zipcodes['75452'] = [33.402226, -96.23857];
Zipcodes['75453'] = [33.009868, -95.93267];
Zipcodes['75454'] = [33.282924, -96.561165];
Zipcodes['75455'] = [33.217422, -94.983011];
Zipcodes['75457'] = [33.176424, -95.209861];
Zipcodes['75459'] = [33.528758, -96.682333];
Zipcodes['75460'] = [33.662129, -95.604973];
Zipcodes['75462'] = [33.624342, -95.491739];
Zipcodes['75468'] = [33.507381, -95.403599];
Zipcodes['75469'] = [33.399609, -95.82251];
Zipcodes['75470'] = [33.587176, -95.802008];
Zipcodes['75471'] = [33.056806, -95.373518];
Zipcodes['75472'] = [32.884423, -95.869602];
Zipcodes['75473'] = [33.815579, -95.488005];
Zipcodes['75474'] = [32.924005, -96.109433];
Zipcodes['75475'] = [33.485946, -96.257579];
Zipcodes['75476'] = [33.708311, -96.240654];
Zipcodes['75477'] = [33.529909, -95.738073];
Zipcodes['75478'] = [33.173205, -95.348615];
Zipcodes['75479'] = [33.630179, -96.330285];
Zipcodes['75480'] = [33.015266, -95.195886];
Zipcodes['75481'] = [33.320029, -95.373504];
Zipcodes['75482'] = [33.167968, -95.608224];
Zipcodes['75486'] = [33.745235, -95.744327];
Zipcodes['75487'] = [33.33936, -95.160193];
Zipcodes['75488'] = [33.778767, -95.996778];
Zipcodes['75489'] = [33.520308, -96.485194];
Zipcodes['75490'] = [33.438031, -96.30828];
Zipcodes['75491'] = [33.486324, -96.39593];
Zipcodes['75492'] = [33.597043, -95.991995];
Zipcodes['75493'] = [33.142229, -95.114425];
Zipcodes['75494'] = [32.917192, -95.25986];
Zipcodes['75495'] = [33.439195, -96.546841];
Zipcodes['75496'] = [33.363703, -96.065379];
Zipcodes['75497'] = [32.921857, -95.576169];
Zipcodes['75501'] = [33.371831, -94.139813];
Zipcodes['75503'] = [33.51791, -94.13711];
Zipcodes['75550'] = [33.498221, -94.890845];
Zipcodes['75551'] = [33.119332, -94.220491];
Zipcodes['75554'] = [33.57901, -94.779754];
Zipcodes['75555'] = [32.939166, -94.142316];
Zipcodes['75556'] = [33.154789, -94.062407];
Zipcodes['75558'] = [33.255524, -94.849719];
Zipcodes['75559'] = [33.50355, -94.649921];
Zipcodes['75560'] = [33.202156, -94.36614];
Zipcodes['75561'] = [33.516756, -94.281559];
Zipcodes['75562'] = [32.935893, -94.253046];
Zipcodes['75563'] = [33.027773, -94.393045];
Zipcodes['75564'] = [32.887825, -94.249636];
Zipcodes['75565'] = [32.950209, -94.073953];
Zipcodes['75566'] = [33.152559, -94.490158];
Zipcodes['75567'] = [33.31607, -94.320839];
Zipcodes['75568'] = [33.193211, -94.587279];
Zipcodes['75569'] = [33.4412, -94.122717];
Zipcodes['75570'] = [33.463886, -94.437383];
Zipcodes['75571'] = [33.207019, -94.77363];
Zipcodes['75572'] = [33.225893, -94.127695];
Zipcodes['75573'] = [33.348093, -94.229102];
Zipcodes['75574'] = [33.325004, -94.550659];
Zipcodes['75601'] = [32.508595, -94.724189];
Zipcodes['75602'] = [32.4368, -94.643472];
Zipcodes['75603'] = [32.40305, -94.711177];
Zipcodes['75604'] = [32.513584, -94.828048];
Zipcodes['75605'] = [32.585123, -94.710328];
Zipcodes['75630'] = [32.853106, -94.572524];
Zipcodes['75631'] = [32.261589, -94.449852];
Zipcodes['75633'] = [32.138496, -94.274156];
Zipcodes['75638'] = [33.020475, -94.729699];
Zipcodes['75639'] = [32.278637, -94.168956];
Zipcodes['75640'] = [32.708417, -94.683181];
Zipcodes['75641'] = [32.384546, -94.575669];
Zipcodes['75642'] = [32.358198, -94.1782];
Zipcodes['75643'] = [32.005834, -94.361607];
Zipcodes['75644'] = [32.791213, -94.999846];
Zipcodes['75645'] = [32.67875, -94.879548];
Zipcodes['75647'] = [32.516559, -94.963086];
Zipcodes['75650'] = [32.513277, -94.548846];
Zipcodes['75651'] = [32.669181, -94.524475];
Zipcodes['75652'] = [32.239409, -94.728328];
Zipcodes['75654'] = [32.091227, -94.834457];
Zipcodes['75656'] = [33.011304, -94.572161];
Zipcodes['75657'] = [32.794973, -94.310306];
Zipcodes['75658'] = [32.172179, -94.899076];
Zipcodes['75659'] = [32.507254, -94.092462];
Zipcodes['75661'] = [32.638008, -94.153019];
Zipcodes['75662'] = [32.38033, -94.8741];
Zipcodes['75667'] = [31.988175, -94.844274];
Zipcodes['75668'] = [32.907075, -94.678637];
Zipcodes['75669'] = [32.032182, -94.554822];
Zipcodes['75670'] = [32.55933, -94.422306];
Zipcodes['75671'] = [32.489068, -94.314972];
Zipcodes['75672'] = [32.468303, -94.299079];
Zipcodes['75680'] = [32.010317, -94.718133];
Zipcodes['75681'] = [31.954241, -94.683616];
Zipcodes['75682'] = [32.261589, -94.953623];
Zipcodes['75683'] = [32.826816, -94.737224];
Zipcodes['75684'] = [32.277021, -94.937156];
Zipcodes['75686'] = [32.967111, -94.944207];
Zipcodes['75687'] = [32.105834, -94.963425];
Zipcodes['75689'] = [32.187066, -94.961598];
Zipcodes['75691'] = [32.311917, -94.551752];
Zipcodes['75692'] = [32.470841, -94.118036];
Zipcodes['75693'] = [32.53378, -94.86066];
Zipcodes['75694'] = [32.638328, -94.344401];
Zipcodes['75701'] = [32.322885, -95.296265];
Zipcodes['75702'] = [32.363991, -95.311744];
Zipcodes['75703'] = [32.235111, -95.320764];
Zipcodes['75704'] = [32.401635, -95.448727];
Zipcodes['75705'] = [32.375415, -95.076528];
Zipcodes['75706'] = [32.472409, -95.294022];
Zipcodes['75707'] = [32.303774, -95.168767];
Zipcodes['75708'] = [32.413219, -95.208673];
Zipcodes['75709'] = [32.312406, -95.385525];
Zipcodes['75711'] = [32.336975, -95.283818];
Zipcodes['75750'] = [32.26754, -95.077715];
Zipcodes['75751'] = [32.069812, -95.920717];
Zipcodes['75752'] = [32.251937, -95.799861];
Zipcodes['75754'] = [32.412949, -95.64943];
Zipcodes['75755'] = [32.641695, -95.075624];
Zipcodes['75756'] = [32.293485, -95.578644];
Zipcodes['75757'] = [32.11, -95.348317];
Zipcodes['75758'] = [32.271436, -95.521442];
Zipcodes['75759'] = [32.036141, -95.415783];
Zipcodes['75760'] = [31.809907, -94.863217];
Zipcodes['75762'] = [32.218341, -95.413445];
Zipcodes['75763'] = [32.042754, -95.523388];
Zipcodes['75764'] = [31.895231, -95.14368];
Zipcodes['75765'] = [32.642043, -95.234859];
Zipcodes['75766'] = [31.9463, -95.25187];
Zipcodes['75770'] = [32.158557, -95.643712];
Zipcodes['75771'] = [32.532678, -95.427268];
Zipcodes['75773'] = [32.681453, -95.447702];
Zipcodes['75778'] = [32.300926, -95.709837];
Zipcodes['75779'] = [31.863226, -95.484612];
Zipcodes['75780'] = [31.989107, -95.091218];
Zipcodes['75782'] = [32.068956, -95.588366];
Zipcodes['75783'] = [32.816459, -95.424768];
Zipcodes['75784'] = [31.871756, -94.965633];
Zipcodes['75785'] = [31.75786, -95.176209];
Zipcodes['75788'] = [31.822539, -94.916146];
Zipcodes['75789'] = [32.109512, -95.094194];
Zipcodes['75790'] = [32.526985, -95.643165];
Zipcodes['75791'] = [32.221677, -95.220676];
Zipcodes['75792'] = [32.46911, -95.106318];
Zipcodes['75801'] = [31.741893, -95.551175];
Zipcodes['75803'] = [31.881777, -95.679494];
Zipcodes['75831'] = [31.473667, -96.009365];
Zipcodes['75833'] = [31.268635, -95.843907];
Zipcodes['75835'] = [31.284891, -95.490625];
Zipcodes['75838'] = [31.4789, -96.229757];
Zipcodes['75839'] = [31.618014, -95.559002];
Zipcodes['75840'] = [31.782559, -96.093621];
Zipcodes['75844'] = [31.525333, -95.422927];
Zipcodes['75845'] = [31.087576, -95.076959];
Zipcodes['75846'] = [31.347499, -96.179086];
Zipcodes['75847'] = [31.342009, -95.119727];
Zipcodes['75848'] = [31.772741, -96.334029];
Zipcodes['75849'] = [31.396569, -95.476161];
Zipcodes['75850'] = [31.135677, -95.935045];
Zipcodes['75851'] = [31.092367, -95.499937];
Zipcodes['75852'] = [30.999541, -95.699976];
Zipcodes['75853'] = [31.946872, -95.814466];
Zipcodes['75855'] = [31.557331, -95.863641];
Zipcodes['75856'] = [31.201358, -95.25369];
Zipcodes['75858'] = [31.380811, -95.136741];
Zipcodes['75859'] = [31.887924, -96.254291];
Zipcodes['75860'] = [31.622647, -96.245354];
Zipcodes['75861'] = [31.851579, -95.888459];
Zipcodes['75862'] = [30.964728, -95.325895];
Zipcodes['75865'] = [31.058171, -94.96239];
Zipcodes['75884'] = [31.789238, -95.892617];
Zipcodes['75886'] = [31.777759, -95.916435];
Zipcodes['75901'] = [31.301675, -94.641408];
Zipcodes['75903'] = [31.374077, -94.748009];
Zipcodes['75904'] = [31.32705, -94.839618];
Zipcodes['75925'] = [31.614855, -95.062493];
Zipcodes['75926'] = [31.244111, -94.958914];
Zipcodes['75928'] = [30.671395, -93.71134];
Zipcodes['75929'] = [31.226678, -94.191712];
Zipcodes['75930'] = [31.33998, -94.035231];
Zipcodes['75931'] = [31.095522, -93.994918];
Zipcodes['75932'] = [31.056147, -93.615655];
Zipcodes['75933'] = [30.553812, -93.812696];
Zipcodes['75934'] = [30.895058, -94.759121];
Zipcodes['75935'] = [31.719778, -94.199007];
Zipcodes['75936'] = [30.951446, -94.58884];
Zipcodes['75937'] = [31.481989, -94.395878];
Zipcodes['75938'] = [30.940517, -94.35364];
Zipcodes['75939'] = [31.017256, -94.801457];
Zipcodes['75941'] = [31.187072, -94.758803];
Zipcodes['75942'] = [30.825788, -94.416471];
Zipcodes['75943'] = [31.658422, -94.894177];
Zipcodes['75944'] = [31.354095, -94.38768];
Zipcodes['75946'] = [31.814449, -94.51503];
Zipcodes['75948'] = [31.274876, -93.721735];
Zipcodes['75949'] = [31.229843, -94.509183];
Zipcodes['75951'] = [30.934425, -94.059578];
Zipcodes['75954'] = [31.904341, -94.048094];
Zipcodes['75956'] = [30.676367, -93.98312];
Zipcodes['75959'] = [31.512269, -93.839109];
Zipcodes['75960'] = [30.910921, -94.854674];
Zipcodes['75961'] = [31.558362, -94.504771];
Zipcodes['75962'] = [31.619839, -94.648445];
Zipcodes['75964'] = [31.62953, -94.77679];
Zipcodes['75965'] = [31.728994, -94.626523];
Zipcodes['75966'] = [30.827971, -93.714454];
Zipcodes['75968'] = [31.252156, -93.954016];
Zipcodes['75969'] = [31.445715, -94.88759];
Zipcodes['75972'] = [31.502076, -94.121587];
Zipcodes['75973'] = [31.711317, -93.942853];
Zipcodes['75974'] = [31.958364, -94.239838];
Zipcodes['75975'] = [31.890537, -94.418619];
Zipcodes['75976'] = [31.519361, -94.950427];
Zipcodes['75977'] = [31.065187, -93.786673];
Zipcodes['75979'] = [30.748559, -94.38912];
Zipcodes['75980'] = [31.094487, -94.350197];
Zipcodes['76001'] = [32.631081, -97.155866];
Zipcodes['76002'] = [32.621896, -97.093509];
Zipcodes['76005'] = [32.801114, -97.075615];
Zipcodes['76006'] = [32.787079, -97.102578];
Zipcodes['76008'] = [32.682396, -97.626635];
Zipcodes['76009'] = [32.41621, -97.202071];
Zipcodes['76010'] = [32.72271, -97.080659];
Zipcodes['76011'] = [32.75203, -97.082615];
Zipcodes['76012'] = [32.75513, -97.139242];
Zipcodes['76013'] = [32.719328, -97.155027];
Zipcodes['76014'] = [32.692105, -97.086517];
Zipcodes['76015'] = [32.691638, -97.135959];
Zipcodes['76016'] = [32.689222, -97.18913];
Zipcodes['76017'] = [32.662513, -97.164024];
Zipcodes['76018'] = [32.662289, -97.090382];
Zipcodes['76020'] = [32.900196, -97.561179];
Zipcodes['76021'] = [32.852971, -97.130443];
Zipcodes['76022'] = [32.830632, -97.145045];
Zipcodes['76023'] = [33.041952, -97.597065];
Zipcodes['76028'] = [32.532677, -97.306547];
Zipcodes['76031'] = [32.346162, -97.328078];
Zipcodes['76033'] = [32.291735, -97.516796];
Zipcodes['76034'] = [32.891478, -97.149747];
Zipcodes['76035'] = [32.565628, -97.646152];
Zipcodes['76036'] = [32.570974, -97.421275];
Zipcodes['76039'] = [32.860285, -97.086028];
Zipcodes['76040'] = [32.819908, -97.102794];
Zipcodes['76041'] = [32.237638, -96.84175];
Zipcodes['76043'] = [32.198144, -97.76065];
Zipcodes['76044'] = [32.425936, -97.539874];
Zipcodes['76048'] = [32.408186, -97.801312];
Zipcodes['76049'] = [32.459823, -97.706972];
Zipcodes['76050'] = [32.266777, -97.155824];
Zipcodes['76051'] = [32.942026, -97.07993];
Zipcodes['76052'] = [32.979054, -97.371308];
Zipcodes['76053'] = [32.81645, -97.180683];
Zipcodes['76054'] = [32.857279, -97.178552];
Zipcodes['76055'] = [32.159658, -97.148471];
Zipcodes['76058'] = [32.471765, -97.426814];
Zipcodes['76059'] = [32.393655, -97.330665];
Zipcodes['76060'] = [32.639649, -97.217476];
Zipcodes['76061'] = [32.502595, -97.198145];
Zipcodes['76063'] = [32.57074, -97.144837];
Zipcodes['76064'] = [32.309287, -97.03736];
Zipcodes['76065'] = [32.469436, -96.99067];
Zipcodes['76066'] = [32.69621, -97.999217];
Zipcodes['76067'] = [32.802422, -98.142547];
Zipcodes['76070'] = [32.21662, -97.659129];
Zipcodes['76071'] = [33.011335, -97.474362];
Zipcodes['76073'] = [33.095616, -97.721202];
Zipcodes['76077'] = [32.281995, -97.713766];
Zipcodes['76078'] = [33.093271, -97.462843];
Zipcodes['76082'] = [32.970044, -97.733748];
Zipcodes['76084'] = [32.431715, -97.088354];
Zipcodes['76085'] = [32.861116, -97.692165];
Zipcodes['76086'] = [32.752204, -97.78927];
Zipcodes['76087'] = [32.66842, -97.791086];
Zipcodes['76088'] = [32.850685, -97.902855];
Zipcodes['76092'] = [32.954493, -97.148867];
Zipcodes['76093'] = [32.210022, -97.405815];
Zipcodes['76102'] = [32.757779, -97.328872];
Zipcodes['76103'] = [32.748947, -97.272638];
Zipcodes['76104'] = [32.728391, -97.317852];
Zipcodes['76105'] = [32.723287, -97.270008];
Zipcodes['76106'] = [32.816685, -97.359631];
Zipcodes['76107'] = [32.742058, -97.38173];
Zipcodes['76108'] = [32.788074, -97.523247];
Zipcodes['76109'] = [32.70065, -97.386965];
Zipcodes['76110'] = [32.707831, -97.338265];
Zipcodes['76111'] = [32.780469, -97.300587];
Zipcodes['76112'] = [32.747426, -97.217658];
Zipcodes['76114'] = [32.774573, -97.407427];
Zipcodes['76115'] = [32.6785, -97.330511];
Zipcodes['76116'] = [32.720723, -97.447033];
Zipcodes['76117'] = [32.803436, -97.267376];
Zipcodes['76118'] = [32.793694, -97.194034];
Zipcodes['76119'] = [32.691143, -97.261538];
Zipcodes['76120'] = [32.766511, -97.180715];
Zipcodes['76123'] = [32.623041, -97.403392];
Zipcodes['76126'] = [32.648847, -97.514799];
Zipcodes['76127'] = [32.776618, -97.430502];
Zipcodes['76129'] = [32.709324, -97.36269];
Zipcodes['76131'] = [32.881821, -97.345943];
Zipcodes['76132'] = [32.668193, -97.415906];
Zipcodes['76133'] = [32.652918, -97.379538];
Zipcodes['76134'] = [32.643558, -97.330104];
Zipcodes['76135'] = [32.836325, -97.469908];
Zipcodes['76137'] = [32.859685, -97.291523];
Zipcodes['76140'] = [32.619935, -97.278061];
Zipcodes['76148'] = [32.868421, -97.252132];
Zipcodes['76155'] = [32.824042, -97.049138];
Zipcodes['76164'] = [32.780688, -97.353863];
Zipcodes['76177'] = [32.966541, -97.303778];
Zipcodes['76179'] = [32.909919, -97.434281];
Zipcodes['76180'] = [32.840721, -97.224241];
Zipcodes['76182'] = [32.886498, -97.208097];
Zipcodes['76201'] = [33.220974, -97.146038];
Zipcodes['76203'] = [33.212131, -97.151813];
Zipcodes['76205'] = [33.192632, -97.129266];
Zipcodes['76207'] = [33.222016, -97.176122];
Zipcodes['76208'] = [33.208706, -97.05541];
Zipcodes['76209'] = [33.2315, -97.109986];
Zipcodes['76210'] = [33.149467, -97.096016];
Zipcodes['76225'] = [33.374041, -97.667809];
Zipcodes['76226'] = [33.105493, -97.17752];
Zipcodes['76227'] = [33.273393, -96.990395];
Zipcodes['76228'] = [33.61791, -98.062664];
Zipcodes['76230'] = [33.52374, -97.931932];
Zipcodes['76233'] = [33.542725, -96.894849];
Zipcodes['76234'] = [33.276319, -97.514178];
Zipcodes['76238'] = [33.479016, -97.351845];
Zipcodes['76239'] = [33.512179, -97.576642];
Zipcodes['76240'] = [33.661602, -97.176369];
Zipcodes['76241'] = [33.645414, -97.176257];
Zipcodes['76244'] = [32.927979, -97.276559];
Zipcodes['76245'] = [33.823803, -96.863133];
Zipcodes['76247'] = [33.101183, -97.347457];
Zipcodes['76248'] = [32.929111, -97.226206];
Zipcodes['76249'] = [33.278823, -97.30231];
Zipcodes['76250'] = [33.630059, -97.242452];
Zipcodes['76251'] = [33.658977, -97.685455];
Zipcodes['76252'] = [33.670696, -97.377989];
Zipcodes['76253'] = [33.625251, -97.313579];
Zipcodes['76255'] = [33.811378, -97.718678];
Zipcodes['76258'] = [33.371085, -96.932195];
Zipcodes['76259'] = [33.198695, -97.312];
Zipcodes['76261'] = [33.793074, -97.963802];
Zipcodes['76262'] = [33.009665, -97.219981];
Zipcodes['76263'] = [33.504346, -97.437358];
Zipcodes['76264'] = [33.748511, -96.836828];
Zipcodes['76265'] = [33.719876, -97.5408];
Zipcodes['76266'] = [33.372827, -97.213258];
Zipcodes['76267'] = [33.369335, -97.397099];
Zipcodes['76268'] = [33.62779, -96.765256];
Zipcodes['76270'] = [33.461447, -97.758976];
Zipcodes['76271'] = [33.468882, -96.913865];
Zipcodes['76272'] = [33.455973, -97.127134];
Zipcodes['76273'] = [33.743893, -96.936121];
Zipcodes['76301'] = [33.905072, -98.480433];
Zipcodes['76302'] = [33.866113, -98.484843];
Zipcodes['76305'] = [34.006193, -98.387687];
Zipcodes['76306'] = [33.949681, -98.527158];
Zipcodes['76308'] = [33.854833, -98.5373];
Zipcodes['76309'] = [33.895517, -98.543996];
Zipcodes['76310'] = [33.805189, -98.528479];
Zipcodes['76311'] = [33.973277, -98.508262];
Zipcodes['76351'] = [33.558025, -98.695427];
Zipcodes['76354'] = [34.090195, -98.625379];
Zipcodes['76357'] = [34.07876, -98.184843];
Zipcodes['76360'] = [33.882756, -98.88271];
Zipcodes['76363'] = [33.513208, -99.523946];
Zipcodes['76364'] = [34.143303, -99.011419];
Zipcodes['76365'] = [33.757451, -98.208383];
Zipcodes['76366'] = [33.714631, -98.774879];
Zipcodes['76367'] = [33.971461, -98.714841];
Zipcodes['76370'] = [33.45617, -98.89571];
Zipcodes['76371'] = [33.482721, -99.654925];
Zipcodes['76372'] = [33.236008, -98.932538];
Zipcodes['76373'] = [34.158677, -99.127698];
Zipcodes['76374'] = [33.343882, -98.763147];
Zipcodes['76377'] = [34.008318, -98.258589];
Zipcodes['76379'] = [33.668239, -98.489118];
Zipcodes['76380'] = [33.603022, -99.213584];
Zipcodes['76384'] = [34.141323, -99.31882];
Zipcodes['76388'] = [33.322836, -99.642126];
Zipcodes['76389'] = [33.512837, -98.481235];
Zipcodes['76401'] = [32.271207, -98.205511];
Zipcodes['76402'] = [32.216519, -98.215848];
Zipcodes['76424'] = [32.801213, -98.88698];
Zipcodes['76426'] = [33.16006, -97.840034];
Zipcodes['76427'] = [33.130596, -98.396473];
Zipcodes['76429'] = [32.759373, -98.666449];
Zipcodes['76430'] = [32.804487, -99.22307];
Zipcodes['76431'] = [33.302787, -97.868426];
Zipcodes['76432'] = [31.836985, -98.784332];
Zipcodes['76433'] = [32.305725, -98.009624];
Zipcodes['76435'] = [32.224529, -98.855993];
Zipcodes['76436'] = [31.868174, -98.218408];
Zipcodes['76437'] = [32.38227, -99.023871];
Zipcodes['76439'] = [32.617963, -97.926819];
Zipcodes['76442'] = [31.890896, -98.613096];
Zipcodes['76443'] = [32.113392, -99.211458];
Zipcodes['76444'] = [32.123666, -98.577422];
Zipcodes['76445'] = [32.305404, -98.542165];
Zipcodes['76446'] = [32.074747, -98.359696];
Zipcodes['76448'] = [32.392615, -98.790658];
Zipcodes['76449'] = [32.901592, -98.315542];
Zipcodes['76450'] = [33.060398, -98.652502];
Zipcodes['76452'] = [31.760412, -98.397118];
Zipcodes['76453'] = [32.584194, -98.335694];
Zipcodes['76454'] = [32.226539, -98.700377];
Zipcodes['76455'] = [31.821442, -98.366808];
Zipcodes['76457'] = [31.944662, -98.027972];
Zipcodes['76458'] = [33.215811, -98.191865];
Zipcodes['76459'] = [33.275311, -98.390215];
Zipcodes['76460'] = [33.306476, -98.500269];
Zipcodes['76462'] = [32.520718, -98.029665];
Zipcodes['76463'] = [32.455408, -98.418168];
Zipcodes['76464'] = [32.579207, -99.156392];
Zipcodes['76466'] = [32.428875, -98.733107];
Zipcodes['76469'] = [32.336117, -99.202392];
Zipcodes['76470'] = [32.503071, -98.674946];
Zipcodes['76471'] = [32.107292, -98.982886];
Zipcodes['76472'] = [32.607745, -98.179771];
Zipcodes['76474'] = [31.958671, -98.775052];
Zipcodes['76475'] = [32.673266, -98.485899];
Zipcodes['76476'] = [32.362649, -97.924354];
Zipcodes['76481'] = [32.996252, -98.698362];
Zipcodes['76483'] = [33.160117, -99.282788];
Zipcodes['76484'] = [32.740311, -98.302489];
Zipcodes['76486'] = [33.014639, -98.05319];
Zipcodes['76487'] = [32.972482, -97.904879];
Zipcodes['76490'] = [32.961949, -98.044304];
Zipcodes['76491'] = [33.057138, -99.038826];
Zipcodes['76501'] = [31.075303, -97.252433];
Zipcodes['76502'] = [31.104022, -97.412045];
Zipcodes['76504'] = [31.142333, -97.374637];
Zipcodes['76511'] = [30.798077, -97.419451];
Zipcodes['76513'] = [31.057023, -97.503];
Zipcodes['76518'] = [30.852029, -97.137995];
Zipcodes['76519'] = [30.994797, -97.033363];
Zipcodes['76520'] = [30.872601, -96.917318];
Zipcodes['76522'] = [31.219045, -97.968224];
Zipcodes['76523'] = [30.802735, -97.272414];
Zipcodes['76524'] = [31.268644, -97.202495];
Zipcodes['76525'] = [31.473539, -98.182044];
Zipcodes['76527'] = [30.836156, -97.795604];
Zipcodes['76528'] = [31.441046, -97.774565];
Zipcodes['76530'] = [30.71223, -97.424823];
Zipcodes['76531'] = [31.686812, -98.18703];
Zipcodes['76534'] = [30.884371, -97.378453];
Zipcodes['76537'] = [30.817309, -97.602978];
Zipcodes['76538'] = [31.628871, -97.922097];
Zipcodes['76539'] = [31.090618, -97.995741];
Zipcodes['76541'] = [31.114384, -97.729347];
Zipcodes['76542'] = [30.989388, -97.702738];
Zipcodes['76543'] = [31.124743, -97.643542];
Zipcodes['76544'] = [31.330254, -97.689632];
Zipcodes['76548'] = [31.054577, -97.641727];
Zipcodes['76549'] = [31.017077, -97.833092];
Zipcodes['76550'] = [31.102626, -98.259776];
Zipcodes['76554'] = [30.967883, -97.376957];
Zipcodes['76556'] = [30.696609, -96.844814];
Zipcodes['76557'] = [31.293783, -97.40019];
Zipcodes['76559'] = [31.083202, -97.614056];
Zipcodes['76561'] = [31.428851, -97.542938];
Zipcodes['76565'] = [31.645074, -98.349211];
Zipcodes['76566'] = [31.514088, -98.000163];
Zipcodes['76567'] = [30.631487, -97.028281];
Zipcodes['76569'] = [30.939337, -97.217459];
Zipcodes['76570'] = [31.082267, -96.953514];
Zipcodes['76571'] = [30.949256, -97.604438];
Zipcodes['76573'] = [30.818652, -97.502432];
Zipcodes['76574'] = [30.579326, -97.388618];
Zipcodes['76577'] = [30.613397, -97.183669];
Zipcodes['76578'] = [30.535409, -97.23717];
Zipcodes['76579'] = [31.197289, -97.241907];
Zipcodes['76596'] = [31.476271, -97.726962];
Zipcodes['76597'] = [31.484459, -97.709839];
Zipcodes['76598'] = [31.475478, -97.734902];
Zipcodes['76599'] = [31.464153, -97.737501];
Zipcodes['76621'] = [31.873806, -97.087391];
Zipcodes['76622'] = [31.816191, -97.243292];
Zipcodes['76623'] = [32.226783, -96.800447];
Zipcodes['76624'] = [31.661047, -96.955129];
Zipcodes['76626'] = [32.122997, -96.723082];
Zipcodes['76627'] = [32.102216, -97.389311];
Zipcodes['76628'] = [32.037791, -96.969837];
Zipcodes['76629'] = [31.14975, -96.651785];
Zipcodes['76630'] = [31.349603, -97.222639];
Zipcodes['76631'] = [31.986553, -96.96585];
Zipcodes['76632'] = [31.30653, -97.065653];
Zipcodes['76633'] = [31.695107, -97.328851];
Zipcodes['76634'] = [31.805316, -97.570542];
Zipcodes['76635'] = [31.71629, -96.661428];
Zipcodes['76636'] = [32.170232, -97.290211];
Zipcodes['76637'] = [31.774623, -97.756011];
Zipcodes['76638'] = [31.558396, -97.444501];
Zipcodes['76639'] = [31.866953, -96.655804];
Zipcodes['76640'] = [31.696619, -97.073707];
Zipcodes['76641'] = [32.044707, -96.786919];
Zipcodes['76642'] = [31.498209, -96.525475];
Zipcodes['76643'] = [31.449362, -97.197582];
Zipcodes['76645'] = [32.012383, -97.127012];
Zipcodes['76648'] = [31.848596, -96.807361];
Zipcodes['76649'] = [32.001589, -97.882698];
Zipcodes['76650'] = [31.980079, -96.863626];
Zipcodes['76651'] = [32.184674, -96.868299];
Zipcodes['76652'] = [32.117538, -97.575601];
Zipcodes['76653'] = [31.295398, -96.607195];
Zipcodes['76654'] = [31.72749, -97.013346];
Zipcodes['76655'] = [31.392436, -97.174161];
Zipcodes['76656'] = [31.177841, -97.063678];
Zipcodes['76657'] = [31.438276, -97.399681];
Zipcodes['76660'] = [31.93561, -96.892676];
Zipcodes['76661'] = [31.307238, -96.84033];
Zipcodes['76664'] = [31.560686, -96.802657];
Zipcodes['76665'] = [31.925006, -97.715407];
Zipcodes['76666'] = [32.040254, -96.894989];
Zipcodes['76667'] = [31.657082, -96.465417];
Zipcodes['76670'] = [32.136018, -96.988963];
Zipcodes['76671'] = [31.988449, -97.531227];
Zipcodes['76673'] = [31.751025, -96.891756];
Zipcodes['76676'] = [31.876385, -96.959031];
Zipcodes['76678'] = [31.651121, -96.749192];
Zipcodes['76679'] = [31.955435, -96.57674];
Zipcodes['76680'] = [31.190743, -96.801353];
Zipcodes['76681'] = [31.915937, -96.436057];
Zipcodes['76682'] = [31.45345, -96.913489];
Zipcodes['76685'] = [31.339417, -97.008179];
Zipcodes['76686'] = [31.743177, -96.540199];
Zipcodes['76687'] = [31.369198, -96.486256];
Zipcodes['76689'] = [31.662159, -97.506079];
Zipcodes['76690'] = [32.104295, -97.794848];
Zipcodes['76691'] = [31.769851, -97.098169];
Zipcodes['76692'] = [31.959701, -97.330421];
Zipcodes['76693'] = [31.81195, -96.44386];
Zipcodes['76701'] = [31.552011, -97.1382];
Zipcodes['76704'] = [31.575445, -97.124247];
Zipcodes['76705'] = [31.626707, -97.084474];
Zipcodes['76706'] = [31.456322, -97.084442];
Zipcodes['76707'] = [31.553876, -97.159082];
Zipcodes['76708'] = [31.627123, -97.215893];
Zipcodes['76710'] = [31.539723, -97.195009];
Zipcodes['76711'] = [31.513756, -97.152983];
Zipcodes['76712'] = [31.526506, -97.260888];
Zipcodes['76798'] = [31.546107, -97.120959];
Zipcodes['76801'] = [31.744154, -99.056447];
Zipcodes['76802'] = [31.715632, -98.894643];
Zipcodes['76820'] = [30.784257, -99.041286];
Zipcodes['76821'] = [31.738169, -99.950504];
Zipcodes['76823'] = [31.687276, -99.168269];
Zipcodes['76824'] = [30.936768, -98.467066];
Zipcodes['76825'] = [31.09852, -99.393909];
Zipcodes['76827'] = [31.527193, -99.136616];
Zipcodes['76828'] = [32.006167, -99.292204];
Zipcodes['76831'] = [30.757565, -98.957787];
Zipcodes['76832'] = [30.962958, -98.746925];
Zipcodes['76834'] = [31.881956, -99.480043];
Zipcodes['76836'] = [31.422466, -99.587642];
Zipcodes['76837'] = [31.20719, -99.892672];
Zipcodes['76841'] = [30.854076, -100.137309];
Zipcodes['76842'] = [30.92318, -99.10172];
Zipcodes['76844'] = [31.450936, -98.502117];
Zipcodes['76845'] = [31.538066, -99.488185];
Zipcodes['76848'] = [30.839235, -99.563748];
Zipcodes['76849'] = [30.472352, -99.821506];
Zipcodes['76852'] = [31.35766, -99.457261];
Zipcodes['76853'] = [31.267613, -98.371832];
Zipcodes['76854'] = [30.555872, -99.528236];
Zipcodes['76856'] = [30.691484, -99.273312];
Zipcodes['76857'] = [31.931233, -98.927404];
Zipcodes['76858'] = [31.207138, -99.614549];
Zipcodes['76859'] = [30.885268, -99.858861];
Zipcodes['76861'] = [31.595587, -100.201635];
Zipcodes['76862'] = [31.41481, -99.705265];
Zipcodes['76864'] = [31.558415, -98.748856];
Zipcodes['76865'] = [31.849653, -100.153554];
Zipcodes['76866'] = [31.43985, -99.928032];
Zipcodes['76869'] = [30.909651, -98.975666];
Zipcodes['76870'] = [31.604321, -98.529648];
Zipcodes['76871'] = [31.270261, -98.98578];
Zipcodes['76872'] = [31.335813, -99.148158];
Zipcodes['76873'] = [31.505779, -99.3701];
Zipcodes['76874'] = [30.476915, -100.122717];
Zipcodes['76875'] = [31.601451, -100.015619];
Zipcodes['76877'] = [31.157059, -98.764007];
Zipcodes['76878'] = [31.657754, -99.292932];
Zipcodes['76880'] = [31.444902, -98.315136];
Zipcodes['76882'] = [31.800285, -99.71096];
Zipcodes['76884'] = [31.690741, -99.569051];
Zipcodes['76885'] = [30.858957, -98.861313];
Zipcodes['76887'] = [31.031568, -99.158464];
Zipcodes['76888'] = [31.609899, -99.64049];
Zipcodes['76890'] = [31.694189, -98.766342];
Zipcodes['76901'] = [31.508725, -100.648748];
Zipcodes['76902'] = [31.544368, -100.724514];
Zipcodes['76903'] = [31.481519, -100.437903];
Zipcodes['76904'] = [31.287019, -100.423597];
Zipcodes['76905'] = [31.49983, -100.315802];
Zipcodes['76908'] = [31.432248, -100.403167];
Zipcodes['76909'] = [31.441425, -100.466262];
Zipcodes['76930'] = [31.110531, -101.119614];
Zipcodes['76932'] = [31.192457, -101.563757];
Zipcodes['76933'] = [31.88084, -100.283968];
Zipcodes['76934'] = [31.61489, -100.676674];
Zipcodes['76935'] = [31.072297, -100.424831];
Zipcodes['76936'] = [30.891813, -100.655315];
Zipcodes['76937'] = [31.3911, -100.150274];
Zipcodes['76939'] = [31.268563, -100.590295];
Zipcodes['76940'] = [31.436812, -100.126944];
Zipcodes['76941'] = [31.245277, -100.861245];
Zipcodes['76943'] = [30.470373, -101.321635];
Zipcodes['76945'] = [31.87304, -100.604685];
Zipcodes['76950'] = [30.43594, -100.564774];
Zipcodes['76951'] = [31.802748, -101.055625];
Zipcodes['76953'] = [31.727528, -100.31996];
Zipcodes['76955'] = [31.299186, -100.138886];
Zipcodes['76957'] = [31.372301, -100.305145];
Zipcodes['76958'] = [31.64468, -100.75515];
Zipcodes['77002'] = [29.756845, -95.365652];
Zipcodes['77003'] = [29.750624, -95.345812];
Zipcodes['77004'] = [29.724272, -95.364242];
Zipcodes['77005'] = [29.718435, -95.423555];
Zipcodes['77006'] = [29.740861, -95.391303];
Zipcodes['77007'] = [29.771579, -95.40893];
Zipcodes['77008'] = [29.798249, -95.416933];
Zipcodes['77009'] = [29.795344, -95.36759];
Zipcodes['77010'] = [29.753615, -95.359737];
Zipcodes['77011'] = [29.742988, -95.307114];
Zipcodes['77012'] = [29.718525, -95.274137];
Zipcodes['77013'] = [29.795268, -95.233977];
Zipcodes['77014'] = [29.985844, -95.465176];
Zipcodes['77015'] = [29.764825, -95.171416];
Zipcodes['77016'] = [29.862447, -95.297733];
Zipcodes['77017'] = [29.691984, -95.250232];
Zipcodes['77018'] = [29.827404, -95.426101];
Zipcodes['77019'] = [29.753344, -95.412855];
Zipcodes['77020'] = [29.768297, -95.308971];
Zipcodes['77021'] = [29.69843, -95.3569];
Zipcodes['77022'] = [29.83159, -95.379554];
Zipcodes['77023'] = [29.721825, -95.318275];
Zipcodes['77024'] = [29.767148, -95.505627];
Zipcodes['77025'] = [29.685706, -95.434764];
Zipcodes['77026'] = [29.800187, -95.328888];
Zipcodes['77027'] = [29.739314, -95.446424];
Zipcodes['77028'] = [29.825122, -95.28636];
Zipcodes['77029'] = [29.759365, -95.256626];
Zipcodes['77030'] = [29.705557, -95.401754];
Zipcodes['77031'] = [29.652205, -95.54623];
Zipcodes['77032'] = [29.985874, -95.345795];
Zipcodes['77033'] = [29.668308, -95.338574];
Zipcodes['77034'] = [29.614699, -95.19318];
Zipcodes['77035'] = [29.655503, -95.471663];
Zipcodes['77036'] = [29.699048, -95.536507];
Zipcodes['77037'] = [29.891373, -95.390561];
Zipcodes['77038'] = [29.919329, -95.4412];
Zipcodes['77039'] = [29.908394, -95.349795];
Zipcodes['77040'] = [29.876546, -95.532244];
Zipcodes['77041'] = [29.857781, -95.585719];
Zipcodes['77042'] = [29.740605, -95.560257];
Zipcodes['77043'] = [29.81093, -95.584639];
Zipcodes['77044'] = [29.906312, -95.180561];
Zipcodes['77045'] = [29.647339, -95.431435];
Zipcodes['77046'] = [29.734428, -95.433099];
Zipcodes['77047'] = [29.61065, -95.386863];
Zipcodes['77048'] = [29.623228, -95.327644];
Zipcodes['77049'] = [29.832928, -95.14904];
Zipcodes['77050'] = [29.902887, -95.269296];
Zipcodes['77051'] = [29.656113, -95.378696];
Zipcodes['77053'] = [29.585681, -95.461267];
Zipcodes['77054'] = [29.68025, -95.404913];
Zipcodes['77055'] = [29.796897, -95.49271];
Zipcodes['77056'] = [29.748202, -95.468948];
Zipcodes['77057'] = [29.744068, -95.48921];
Zipcodes['77058'] = [29.561579, -95.099626];
Zipcodes['77059'] = [29.613528, -95.118667];
Zipcodes['77060'] = [29.935512, -95.397624];
Zipcodes['77061'] = [29.647155, -95.276204];
Zipcodes['77062'] = [29.575781, -95.134334];
Zipcodes['77063'] = [29.735679, -95.521587];
Zipcodes['77064'] = [29.924186, -95.541691];
Zipcodes['77065'] = [29.92647, -95.603801];
Zipcodes['77066'] = [29.957224, -95.503319];
Zipcodes['77067'] = [29.952629, -95.446609];
Zipcodes['77068'] = [30.007063, -95.488362];
Zipcodes['77069'] = [29.987811, -95.523217];
Zipcodes['77070'] = [29.979455, -95.573317];
Zipcodes['77071'] = [29.651785, -95.519929];
Zipcodes['77072'] = [29.700441, -95.585023];
Zipcodes['77073'] = [30.000147, -95.400069];
Zipcodes['77074'] = [29.687947, -95.515725];
Zipcodes['77075'] = [29.621466, -95.268968];
Zipcodes['77076'] = [29.860021, -95.382184];
Zipcodes['77077'] = [29.753633, -95.642004];
Zipcodes['77078'] = [29.854057, -95.244813];
Zipcodes['77079'] = [29.777978, -95.603102];
Zipcodes['77080'] = [29.81835, -95.522439];
Zipcodes['77081'] = [29.712829, -95.478516];
Zipcodes['77082'] = [29.72449, -95.640002];
Zipcodes['77083'] = [29.693991, -95.64783];
Zipcodes['77084'] = [29.827486, -95.65992];
Zipcodes['77085'] = [29.620509, -95.485333];
Zipcodes['77086'] = [29.919035, -95.491606];
Zipcodes['77087'] = [29.686519, -95.303917];
Zipcodes['77088'] = [29.881487, -95.452619];
Zipcodes['77089'] = [29.586959, -95.225601];
Zipcodes['77090'] = [30.009427, -95.439603];
Zipcodes['77091'] = [29.853067, -95.435966];
Zipcodes['77092'] = [29.831681, -95.474905];
Zipcodes['77093'] = [29.863156, -95.341927];
Zipcodes['77094'] = [29.770918, -95.698677];
Zipcodes['77095'] = [29.910049, -95.65884];
Zipcodes['77096'] = [29.675339, -95.479372];
Zipcodes['77098'] = [29.734813, -95.416098];
Zipcodes['77099'] = [29.670869, -95.58599];
Zipcodes['77204'] = [29.720884, -95.343537];
Zipcodes['77301'] = [30.309853, -95.43128];
Zipcodes['77302'] = [30.222119, -95.344934];
Zipcodes['77303'] = [30.377952, -95.37714];
Zipcodes['77304'] = [30.32776, -95.516045];
Zipcodes['77306'] = [30.28584, -95.312397];
Zipcodes['77316'] = [30.306163, -95.68639];
Zipcodes['77318'] = [30.444908, -95.551907];
Zipcodes['77320'] = [30.807729, -95.558036];
Zipcodes['77326'] = [30.503708, -94.81848];
Zipcodes['77327'] = [30.347992, -94.930738];
Zipcodes['77328'] = [30.405402, -95.192082];
Zipcodes['77331'] = [30.617698, -95.184377];
Zipcodes['77334'] = [30.781836, -95.373439];
Zipcodes['77335'] = [30.591653, -94.920713];
Zipcodes['77336'] = [30.069576, -95.106699];
Zipcodes['77338'] = [30.014356, -95.282995];
Zipcodes['77339'] = [30.046777, -95.221022];
Zipcodes['77340'] = [30.656507, -95.530744];
Zipcodes['77341'] = [30.69825, -95.55896];
Zipcodes['77342'] = [30.738781, -95.559212];
Zipcodes['77345'] = [30.053105, -95.157943];
Zipcodes['77346'] = [29.991995, -95.177268];
Zipcodes['77350'] = [30.808479, -94.912079];
Zipcodes['77351'] = [30.717792, -94.817231];
Zipcodes['77354'] = [30.207853, -95.642971];
Zipcodes['77355'] = [30.156257, -95.74926];
Zipcodes['77356'] = [30.465662, -95.729888];
Zipcodes['77357'] = [30.156882, -95.181121];
Zipcodes['77358'] = [30.543328, -95.443351];
Zipcodes['77359'] = [30.773832, -95.299653];
Zipcodes['77360'] = [30.846249, -95.117513];
Zipcodes['77362'] = [30.156589, -95.671465];
Zipcodes['77363'] = [30.316241, -95.849802];
Zipcodes['77364'] = [30.772997, -95.214951];
Zipcodes['77365'] = [30.11348, -95.274545];
Zipcodes['77367'] = [30.848145, -95.390115];
Zipcodes['77368'] = [30.451225, -94.840633];
Zipcodes['77369'] = [30.421815, -94.73533];
Zipcodes['77371'] = [30.49399, -94.98118];
Zipcodes['77372'] = [30.2677, -95.201009];
Zipcodes['77373'] = [30.062169, -95.383966];
Zipcodes['77374'] = [30.392213, -94.644093];
Zipcodes['77375'] = [30.094665, -95.586187];
Zipcodes['77376'] = [30.443987, -94.675487];
Zipcodes['77377'] = [30.059863, -95.672207];
Zipcodes['77378'] = [30.478056, -95.384905];
Zipcodes['77379'] = [30.039457, -95.534383];
Zipcodes['77380'] = [30.136972, -95.46836];
Zipcodes['77381'] = [30.177126, -95.502489];
Zipcodes['77382'] = [30.195137, -95.547917];
Zipcodes['77384'] = [30.233846, -95.49577];
Zipcodes['77385'] = [30.186682, -95.429005];
Zipcodes['77386'] = [30.100262, -95.356488];
Zipcodes['77388'] = [30.061932, -95.465877];
Zipcodes['77389'] = [30.123469, -95.517064];
Zipcodes['77396'] = [29.943359, -95.262498];
Zipcodes['77401'] = [29.704019, -95.460905];
Zipcodes['77406'] = [29.64609, -95.794526];
Zipcodes['77407'] = [29.670004, -95.708089];
Zipcodes['77412'] = [29.560824, -96.458267];
Zipcodes['77414'] = [28.904769, -95.887564];
Zipcodes['77415'] = [28.936977, -95.706673];
Zipcodes['77417'] = [29.460956, -95.956007];
Zipcodes['77418'] = [29.987117, -96.252942];
Zipcodes['77419'] = [28.87215, -96.251935];
Zipcodes['77420'] = [29.233389, -95.918796];
Zipcodes['77422'] = [28.96955, -95.582181];
Zipcodes['77423'] = [29.833142, -95.986919];
Zipcodes['77426'] = [30.1223, -96.258442];
Zipcodes['77428'] = [28.672888, -96.156984];
Zipcodes['77429'] = [29.995758, -95.665088];
Zipcodes['77430'] = [29.259878, -95.667662];
Zipcodes['77431'] = [29.132598, -95.818919];
Zipcodes['77432'] = [29.035751, -96.228247];
Zipcodes['77433'] = [29.945301, -95.741369];
Zipcodes['77434'] = [29.523643, -96.318161];
Zipcodes['77435'] = [29.515694, -96.121026];
Zipcodes['77436'] = [29.365048, -96.252644];
Zipcodes['77437'] = [29.204365, -96.272726];
Zipcodes['77440'] = [28.89016, -96.145464];
Zipcodes['77441'] = [29.697428, -95.909559];
Zipcodes['77442'] = [29.437903, -96.495003];
Zipcodes['77443'] = [29.359204, -96.18075];
Zipcodes['77444'] = [29.286377, -95.777811];
Zipcodes['77445'] = [30.097218, -96.064246];
Zipcodes['77446'] = [30.087117, -95.989558];
Zipcodes['77447'] = [30.055282, -95.824783];
Zipcodes['77448'] = [29.416447, -96.085418];
Zipcodes['77449'] = [29.837611, -95.73374];
Zipcodes['77450'] = [29.748169, -95.736154];
Zipcodes['77451'] = [29.463216, -96.025392];
Zipcodes['77453'] = [29.231938, -96.019246];
Zipcodes['77454'] = [29.54538, -96.238523];
Zipcodes['77455'] = [29.13158, -96.445375];
Zipcodes['77456'] = [28.927379, -96.08966];
Zipcodes['77457'] = [28.700403, -95.945058];
Zipcodes['77458'] = [28.96317, -96.2498];
Zipcodes['77459'] = [29.526393, -95.533799];
Zipcodes['77460'] = [29.397434, -96.384512];
Zipcodes['77461'] = [29.393325, -95.774833];
Zipcodes['77464'] = [29.620932, -95.939254];
Zipcodes['77465'] = [28.711934, -96.226079];
Zipcodes['77466'] = [29.792687, -96.012215];
Zipcodes['77467'] = [29.234129, -96.182424];
Zipcodes['77468'] = [29.13719, -95.851225];
Zipcodes['77469'] = [29.491375, -95.702129];
Zipcodes['77470'] = [29.533816, -96.532663];
Zipcodes['77471'] = [29.54852, -95.86204];
Zipcodes['77473'] = [29.794594, -96.103745];
Zipcodes['77474'] = [29.77406, -96.190029];
Zipcodes['77475'] = [29.51133, -96.672979];
Zipcodes['77476'] = [29.676603, -95.973393];
Zipcodes['77477'] = [29.624274, -95.568008];
Zipcodes['77478'] = [29.617523, -95.60829];
Zipcodes['77479'] = [29.56504, -95.635059];
Zipcodes['77480'] = [29.091495, -95.770074];
Zipcodes['77481'] = [29.471484, -95.570843];
Zipcodes['77482'] = [29.092714, -95.903493];
Zipcodes['77483'] = [28.780687, -95.871565];
Zipcodes['77484'] = [30.08563, -95.92719];
Zipcodes['77485'] = [29.640656, -96.06303];
Zipcodes['77486'] = [29.160441, -95.691989];
Zipcodes['77488'] = [29.295708, -96.095122];
Zipcodes['77489'] = [29.600498, -95.515537];
Zipcodes['77493'] = [29.853217, -95.831457];
Zipcodes['77494'] = [29.744657, -95.815536];
Zipcodes['77498'] = [29.640975, -95.655784];
Zipcodes['77502'] = [29.679854, -95.199591];
Zipcodes['77503'] = [29.690596, -95.157031];
Zipcodes['77504'] = [29.647916, -95.18978];
Zipcodes['77505'] = [29.648337, -95.141499];
Zipcodes['77506'] = [29.718387, -95.191126];
Zipcodes['77507'] = [29.624371, -95.063465];
Zipcodes['77510'] = [29.348871, -95.091618];
Zipcodes['77511'] = [29.380763, -95.245193];
Zipcodes['77514'] = [29.688046, -94.625902];
Zipcodes['77515'] = [29.173439, -95.451545];
Zipcodes['77517'] = [29.359526, -95.14081];
Zipcodes['77518'] = [29.505183, -94.986711];
Zipcodes['77519'] = [30.218323, -94.575058];
Zipcodes['77520'] = [29.736716, -95.000847];
Zipcodes['77521'] = [29.811924, -94.963306];
Zipcodes['77523'] = [29.766303, -94.865759];
Zipcodes['77530'] = [29.789356, -95.113987];
Zipcodes['77531'] = [29.038855, -95.401937];
Zipcodes['77532'] = [29.932894, -95.057219];
Zipcodes['77533'] = [30.087172, -94.599557];
Zipcodes['77534'] = [29.215163, -95.311193];
Zipcodes['77535'] = [30.054844, -94.922316];
Zipcodes['77536'] = [29.691659, -95.116531];
Zipcodes['77538'] = [29.974167, -94.522942];
Zipcodes['77539'] = [29.452997, -95.02847];
Zipcodes['77541'] = [29.016114, -95.325708];
Zipcodes['77545'] = [29.537323, -95.473042];
Zipcodes['77546'] = [29.511132, -95.193008];
Zipcodes['77547'] = [29.740123, -95.239114];
Zipcodes['77550'] = [29.307853, -94.769334];
Zipcodes['77551'] = [29.279462, -94.833527];
Zipcodes['77554'] = [29.23501, -94.924489];
Zipcodes['77555'] = [29.310761, -94.776472];
Zipcodes['77560'] = [29.866863, -94.587444];
Zipcodes['77561'] = [30.134736, -94.735504];
Zipcodes['77562'] = [29.829761, -95.05494];
Zipcodes['77563'] = [29.303625, -95.032385];
Zipcodes['77564'] = [30.232134, -94.655967];
Zipcodes['77565'] = [29.538069, -95.03292];
Zipcodes['77566'] = [29.052739, -95.474351];
Zipcodes['77568'] = [29.365831, -94.988044];
Zipcodes['77571'] = [29.688455, -95.05738];
Zipcodes['77573'] = [29.503785, -95.0873];
Zipcodes['77575'] = [30.081073, -94.752565];
Zipcodes['77577'] = [29.259741, -95.264579];
Zipcodes['77578'] = [29.485422, -95.362322];
Zipcodes['77580'] = [29.85752, -94.874131];
Zipcodes['77581'] = [29.561597, -95.279759];
Zipcodes['77582'] = [30.013779, -94.662106];
Zipcodes['77583'] = [29.374205, -95.453756];
Zipcodes['77584'] = [29.545041, -95.350692];
Zipcodes['77585'] = [30.330645, -94.534176];
Zipcodes['77586'] = [29.581848, -95.038739];
Zipcodes['77587'] = [29.661024, -95.228361];
Zipcodes['77590'] = [29.378627, -94.916651];
Zipcodes['77591'] = [29.399363, -94.999115];
Zipcodes['77597'] = [29.828398, -94.696298];
Zipcodes['77598'] = [29.53942, -95.134993];
Zipcodes['77611'] = [30.014439, -93.828243];
Zipcodes['77612'] = [30.401923, -93.935124];
Zipcodes['77613'] = [30.027694, -94.358782];
Zipcodes['77614'] = [30.306015, -93.747906];
Zipcodes['77615'] = [30.357102, -94.065681];
Zipcodes['77616'] = [30.566807, -94.209042];
Zipcodes['77617'] = [29.530784, -94.469858];
Zipcodes['77619'] = [29.945971, -93.916656];
Zipcodes['77622'] = [29.862397, -94.286046];
Zipcodes['77623'] = [29.561256, -94.414021];
Zipcodes['77624'] = [30.678969, -94.271465];
Zipcodes['77625'] = [30.367544, -94.391402];
Zipcodes['77627'] = [29.990409, -94.006974];
Zipcodes['77629'] = [29.977461, -94.43306];
Zipcodes['77630'] = [30.066254, -93.870098];
Zipcodes['77632'] = [30.201515, -93.804648];
Zipcodes['77640'] = [29.880511, -93.941151];
Zipcodes['77642'] = [29.95802, -93.891921];
Zipcodes['77650'] = [29.438515, -94.663658];
Zipcodes['77651'] = [29.98055, -93.948021];
Zipcodes['77655'] = [29.709922, -93.939365];
Zipcodes['77656'] = [30.424433, -94.169063];
Zipcodes['77657'] = [30.226788, -94.188851];
Zipcodes['77659'] = [30.171444, -94.432275];
Zipcodes['77660'] = [30.645369, -94.139641];
Zipcodes['77661'] = [29.77996, -94.368614];
Zipcodes['77662'] = [30.177924, -94.018171];
Zipcodes['77663'] = [30.512662, -94.406722];
Zipcodes['77664'] = [30.584852, -94.396222];
Zipcodes['77665'] = [29.789888, -94.406324];
Zipcodes['77701'] = [30.073053, -94.105486];
Zipcodes['77702'] = [30.086285, -94.128289];
Zipcodes['77703'] = [30.114058, -94.109271];
Zipcodes['77705'] = [29.908454, -94.175243];
Zipcodes['77706'] = [30.100359, -94.166937];
Zipcodes['77707'] = [30.053142, -94.166056];
Zipcodes['77708'] = [30.150453, -94.153426];
Zipcodes['77713'] = [30.068794, -94.259663];
Zipcodes['77801'] = [30.639285, -96.362454];
Zipcodes['77802'] = [30.660911, -96.322289];
Zipcodes['77803'] = [30.678262, -96.387152];
Zipcodes['77807'] = [30.677687, -96.483601];
Zipcodes['77808'] = [30.797736, -96.312084];
Zipcodes['77830'] = [30.532425, -95.998153];
Zipcodes['77831'] = [30.731755, -95.907465];
Zipcodes['77833'] = [30.205898, -96.410067];
Zipcodes['77835'] = [30.193586, -96.588051];
Zipcodes['77836'] = [30.5375, -96.680513];
Zipcodes['77837'] = [31.014211, -96.677701];
Zipcodes['77840'] = [30.611527, -96.323267];
Zipcodes['77843'] = [30.615329, -96.340234];
Zipcodes['77845'] = [30.5611, -96.275139];
Zipcodes['77852'] = [30.428385, -96.757581];
Zipcodes['77853'] = [30.354551, -96.840806];
Zipcodes['77855'] = [31.154981, -96.103067];
Zipcodes['77856'] = [31.092107, -96.4122];
Zipcodes['77857'] = [30.791279, -96.755021];
Zipcodes['77859'] = [30.879524, -96.516335];
Zipcodes['77861'] = [30.721716, -96.109298];
Zipcodes['77863'] = [30.390769, -96.558881];
Zipcodes['77864'] = [30.960288, -95.894142];
Zipcodes['77865'] = [31.211668, -96.203161];
Zipcodes['77866'] = [30.494875, -96.214829];
Zipcodes['77867'] = [30.742731, -96.570727];
Zipcodes['77868'] = [30.365776, -96.04642];
Zipcodes['77871'] = [31.0572, -96.110997];
Zipcodes['77872'] = [30.884964, -96.089548];
Zipcodes['77873'] = [30.582632, -95.800538];
Zipcodes['77875'] = [30.578914, -95.941674];
Zipcodes['77876'] = [30.597562, -95.856922];
Zipcodes['77878'] = [30.491249, -96.434382];
Zipcodes['77879'] = [30.416515, -96.465717];
Zipcodes['77880'] = [30.286936, -96.189365];
Zipcodes['77882'] = [30.911384, -96.3791];
Zipcodes['77901'] = [28.806249, -96.985438];
Zipcodes['77904'] = [28.917567, -97.011864];
Zipcodes['77905'] = [28.737418, -97.060318];
Zipcodes['77950'] = [28.246142, -96.8974];
Zipcodes['77951'] = [28.635946, -96.911444];
Zipcodes['77954'] = [29.096488, -97.295716];
Zipcodes['77957'] = [29.035875, -96.716211];
Zipcodes['77960'] = [28.653515, -97.252385];
Zipcodes['77961'] = [28.846143, -96.377036];
Zipcodes['77962'] = [29.030815, -96.503389];
Zipcodes['77963'] = [28.652457, -97.405775];
Zipcodes['77964'] = [29.378848, -96.834606];
Zipcodes['77968'] = [28.916065, -96.854022];
Zipcodes['77969'] = [28.782649, -96.653994];
Zipcodes['77970'] = [28.846341, -96.434149];
Zipcodes['77971'] = [28.833262, -96.521612];
Zipcodes['77973'] = [28.532467, -96.9642];
Zipcodes['77974'] = [28.897853, -97.288877];
Zipcodes['77975'] = [29.575684, -97.081976];
Zipcodes['77976'] = [28.940564, -97.13418];
Zipcodes['77977'] = [28.680828, -96.801866];
Zipcodes['77978'] = [28.690358, -96.57791];
Zipcodes['77979'] = [28.60965, -96.61898];
Zipcodes['77982'] = [28.444644, -96.491788];
Zipcodes['77983'] = [28.376402, -96.696758];
Zipcodes['77984'] = [29.465685, -97.193893];
Zipcodes['77986'] = [29.476607, -96.796078];
Zipcodes['77987'] = [29.345578, -97.080203];
Zipcodes['77988'] = [28.836933, -96.884758];
Zipcodes['77990'] = [28.484888, -96.924886];
Zipcodes['77991'] = [28.785362, -96.596011];
Zipcodes['77993'] = [28.855654, -97.419639];
Zipcodes['77994'] = [29.178194, -97.47155];
Zipcodes['77995'] = [29.200412, -97.091881];
Zipcodes['78001'] = [28.231577, -99.221995];
Zipcodes['78002'] = [29.28375, -98.737693];
Zipcodes['78003'] = [29.70798, -99.103894];
Zipcodes['78004'] = [29.857679, -98.537702];
Zipcodes['78005'] = [28.935722, -98.832941];
Zipcodes['78006'] = [29.85855, -98.703353];
Zipcodes['78007'] = [28.476072, -98.408487];
Zipcodes['78008'] = [28.727589, -98.25982];
Zipcodes['78009'] = [29.367585, -98.889807];
Zipcodes['78010'] = [29.910753, -99.04681];
Zipcodes['78011'] = [28.813097, -98.726115];
Zipcodes['78012'] = [28.771756, -98.453507];
Zipcodes['78013'] = [29.977171, -98.904977];
Zipcodes['78014'] = [28.395098, -99.113861];
Zipcodes['78015'] = [29.742896, -98.651458];
Zipcodes['78016'] = [29.181395, -98.951719];
Zipcodes['78017'] = [28.741551, -99.208039];
Zipcodes['78019'] = [28.024355, -99.270844];
Zipcodes['78021'] = [28.437456, -98.83137];
Zipcodes['78022'] = [28.238271, -98.168594];
Zipcodes['78023'] = [29.621586, -98.756372];
Zipcodes['78024'] = [29.995463, -99.485565];
Zipcodes['78025'] = [30.07779, -99.285401];
Zipcodes['78026'] = [28.747304, -98.559149];
Zipcodes['78027'] = [29.980917, -98.529304];
Zipcodes['78028'] = [30.03651, -99.163044];
Zipcodes['78029'] = [29.899539, -99.186974];
Zipcodes['78039'] = [29.31215, -98.834792];
Zipcodes['78040'] = [27.513375, -99.50214];
Zipcodes['78041'] = [27.559571, -99.462957];
Zipcodes['78043'] = [27.570451, -99.304188];
Zipcodes['78044'] = [27.73253, -99.229736];
Zipcodes['78045'] = [27.833815, -99.681424];
Zipcodes['78046'] = [27.385525, -99.389132];
Zipcodes['78050'] = [29.089151, -98.502462];
Zipcodes['78052'] = [29.210468, -98.775324];
Zipcodes['78055'] = [29.830348, -99.331352];
Zipcodes['78056'] = [29.540929, -98.942615];
Zipcodes['78057'] = [29.043063, -99.016422];
Zipcodes['78058'] = [30.105919, -99.600886];
Zipcodes['78059'] = [29.189493, -98.841011];
Zipcodes['78061'] = [28.901927, -99.126644];
Zipcodes['78062'] = [28.749449, -98.202784];
Zipcodes['78063'] = [29.689692, -98.911216];
Zipcodes['78064'] = [28.924564, -98.429083];
Zipcodes['78065'] = [29.079818, -98.642303];
Zipcodes['78066'] = [29.493028, -98.891867];
Zipcodes['78067'] = [27.103631, -99.363349];
Zipcodes['78069'] = [29.188365, -98.671845];
Zipcodes['78070'] = [29.892301, -98.408273];
Zipcodes['78071'] = [28.474444, -98.199054];
Zipcodes['78072'] = [28.32531, -98.586392];
Zipcodes['78073'] = [29.243915, -98.622442];
Zipcodes['78074'] = [29.942285, -98.792226];
Zipcodes['78075'] = [28.589505, -98.270578];
Zipcodes['78076'] = [26.934109, -99.131024];
Zipcodes['78101'] = [29.353553, -98.238308];
Zipcodes['78102'] = [28.415399, -97.71444];
Zipcodes['78104'] = [28.523624, -97.762016];
Zipcodes['78107'] = [28.568278, -97.579237];
Zipcodes['78108'] = [29.570142, -98.217797];
Zipcodes['78109'] = [29.477983, -98.293406];
Zipcodes['78111'] = [29.034959, -97.73698];
Zipcodes['78112'] = [29.186904, -98.392789];
Zipcodes['78113'] = [28.945213, -98.132466];
Zipcodes['78114'] = [29.126771, -98.198371];
Zipcodes['78116'] = [29.102255, -97.743964];
Zipcodes['78117'] = [28.938338, -97.923572];
Zipcodes['78118'] = [28.874787, -97.937367];
Zipcodes['78119'] = [28.72143, -97.913782];
Zipcodes['78121'] = [29.351052, -98.088866];
Zipcodes['78122'] = [29.457249, -97.707606];
Zipcodes['78123'] = [29.605116, -98.039135];
Zipcodes['78124'] = [29.557271, -98.146892];
Zipcodes['78125'] = [28.531848, -97.966545];
Zipcodes['78130'] = [29.693649, -98.068676];
Zipcodes['78132'] = [29.758817, -98.191068];
Zipcodes['78133'] = [29.883877, -98.252229];
Zipcodes['78140'] = [29.306802, -97.763362];
Zipcodes['78141'] = [28.908121, -97.618884];
Zipcodes['78142'] = [28.536291, -97.812957];
Zipcodes['78143'] = [29.25871, -97.851241];
Zipcodes['78144'] = [28.951209, -97.892578];
Zipcodes['78145'] = [28.645392, -97.992487];
Zipcodes['78146'] = [28.603767, -97.839917];
Zipcodes['78147'] = [29.073446, -98.080406];
Zipcodes['78148'] = [29.543251, -98.296478];
Zipcodes['78150'] = [29.538151, -98.281563];
Zipcodes['78151'] = [28.869945, -97.713456];
Zipcodes['78152'] = [29.429237, -98.204532];
Zipcodes['78154'] = [29.547147, -98.262605];
Zipcodes['78155'] = [29.542968, -97.937698];
Zipcodes['78159'] = [29.225941, -97.585019];
Zipcodes['78160'] = [29.245074, -97.908914];
Zipcodes['78161'] = [29.286151, -98.050314];
Zipcodes['78162'] = [28.578178, -97.794476];
Zipcodes['78163'] = [29.768727, -98.44052];
Zipcodes['78164'] = [28.979611, -97.520952];
Zipcodes['78201'] = [29.468413, -98.528889];
Zipcodes['78202'] = [29.428224, -98.460787];
Zipcodes['78203'] = [29.415125, -98.459335];
Zipcodes['78204'] = [29.404404, -98.505028];
Zipcodes['78205'] = [29.423945, -98.48642];
Zipcodes['78207'] = [29.422124, -98.525978];
Zipcodes['78208'] = [29.439875, -98.458105];
Zipcodes['78209'] = [29.488906, -98.456235];
Zipcodes['78210'] = [29.395776, -98.464401];
Zipcodes['78211'] = [29.34255, -98.570024];
Zipcodes['78212'] = [29.464611, -98.493653];
Zipcodes['78213'] = [29.516395, -98.523017];
Zipcodes['78214'] = [29.34976, -98.476736];
Zipcodes['78215'] = [29.440446, -98.480293];
Zipcodes['78216'] = [29.538739, -98.491258];
Zipcodes['78217'] = [29.539974, -98.42005];
Zipcodes['78218'] = [29.494749, -98.396559];
Zipcodes['78219'] = [29.439928, -98.384478];
Zipcodes['78220'] = [29.412433, -98.398385];
Zipcodes['78221'] = [29.29864, -98.483115];
Zipcodes['78222'] = [29.355761, -98.386375];
Zipcodes['78223'] = [29.310488, -98.387514];
Zipcodes['78224'] = [29.310237, -98.546151];
Zipcodes['78225'] = [29.387967, -98.525846];
Zipcodes['78226'] = [29.383778, -98.56994];
Zipcodes['78227'] = [29.389895, -98.639378];
Zipcodes['78228'] = [29.460646, -98.571279];
Zipcodes['78229'] = [29.505502, -98.577033];
Zipcodes['78230'] = [29.546515, -98.557207];
Zipcodes['78231'] = [29.581019, -98.542526];
Zipcodes['78232'] = [29.590145, -98.476253];
Zipcodes['78233'] = [29.555319, -98.364027];
Zipcodes['78234'] = [29.460896, -98.43834];
Zipcodes['78235'] = [29.344242, -98.438991];
Zipcodes['78236'] = [29.385897, -98.622521];
Zipcodes['78237'] = [29.420924, -98.566465];
Zipcodes['78238'] = [29.471786, -98.617678];
Zipcodes['78239'] = [29.516184, -98.361849];
Zipcodes['78240'] = [29.525291, -98.604382];
Zipcodes['78242'] = [29.3504, -98.607054];
Zipcodes['78243'] = [29.371037, -98.592987];
Zipcodes['78244'] = [29.477668, -98.351082];
Zipcodes['78245'] = [29.39317, -98.74144];
Zipcodes['78247'] = [29.581433, -98.408776];
Zipcodes['78248'] = [29.590028, -98.525262];
Zipcodes['78249'] = [29.568476, -98.614271];
Zipcodes['78250'] = [29.502729, -98.664897];
Zipcodes['78251'] = [29.461683, -98.676727];
Zipcodes['78252'] = [29.335952, -98.690709];
Zipcodes['78253'] = [29.470229, -98.787591];
Zipcodes['78254'] = [29.530564, -98.72718];
Zipcodes['78255'] = [29.649165, -98.666344];
Zipcodes['78256'] = [29.622289, -98.626164];
Zipcodes['78257'] = [29.647103, -98.581072];
Zipcodes['78258'] = [29.634209, -98.489808];
Zipcodes['78259'] = [29.625165, -98.421852];
Zipcodes['78260'] = [29.698541, -98.492729];
Zipcodes['78261'] = [29.68958, -98.402411];
Zipcodes['78263'] = [29.32891, -98.312898];
Zipcodes['78264'] = [29.201192, -98.508966];
Zipcodes['78266'] = [29.650495, -98.338431];
Zipcodes['78330'] = [27.755818, -97.925974];
Zipcodes['78332'] = [27.724313, -98.134487];
Zipcodes['78336'] = [27.927892, -97.171277];
Zipcodes['78338'] = [26.856162, -97.732223];
Zipcodes['78339'] = [27.800017, -97.796112];
Zipcodes['78340'] = [28.1207, -97.19689];
Zipcodes['78341'] = [27.575018, -98.471129];
Zipcodes['78342'] = [27.660163, -98.075692];
Zipcodes['78343'] = [27.626037, -97.766234];
Zipcodes['78344'] = [27.559603, -98.854901];
Zipcodes['78347'] = [27.573374, -97.486404];
Zipcodes['78349'] = [27.373485, -98.285472];
Zipcodes['78351'] = [27.667801, -97.75258];
Zipcodes['78352'] = [27.98154, -97.689782];
Zipcodes['78353'] = [26.882961, -98.240519];
Zipcodes['78355'] = [27.18888, -98.206411];
Zipcodes['78357'] = [27.933768, -98.567347];
Zipcodes['78358'] = [28.067949, -97.042232];
Zipcodes['78359'] = [27.93022, -97.300538];
Zipcodes['78360'] = [26.860504, -98.832055];
Zipcodes['78361'] = [27.053231, -98.747572];
Zipcodes['78362'] = [27.853993, -97.196496];
Zipcodes['78363'] = [27.436813, -97.853828];
Zipcodes['78368'] = [28.113668, -97.801551];
Zipcodes['78369'] = [27.42933, -99.08597];
Zipcodes['78370'] = [27.935487, -97.605556];
Zipcodes['78371'] = [27.512602, -99.073571];
Zipcodes['78372'] = [28.009554, -98.098803];
Zipcodes['78373'] = [27.776251, -97.130398];
Zipcodes['78374'] = [27.885392, -97.300174];
Zipcodes['78375'] = [27.39631, -98.171285];
Zipcodes['78376'] = [27.367472, -98.483299];
Zipcodes['78377'] = [28.322116, -97.162472];
Zipcodes['78379'] = [27.306614, -97.780427];
Zipcodes['78380'] = [27.776069, -97.741915];
Zipcodes['78382'] = [28.11318, -97.046273];
Zipcodes['78383'] = [28.072822, -97.944062];
Zipcodes['78384'] = [27.734594, -98.471677];
Zipcodes['78385'] = [27.121601, -97.836132];
Zipcodes['78387'] = [28.081035, -97.548899];
Zipcodes['78389'] = [28.246302, -97.650811];
Zipcodes['78390'] = [28.007673, -97.349938];
Zipcodes['78391'] = [28.179576, -97.708393];
Zipcodes['78393'] = [28.185211, -97.354437];
Zipcodes['78401'] = [27.796812, -97.399092];
Zipcodes['78402'] = [27.851846, -97.439165];
Zipcodes['78404'] = [27.767919, -97.399444];
Zipcodes['78405'] = [27.774039, -97.438731];
Zipcodes['78406'] = [27.771962, -97.51545];
Zipcodes['78407'] = [27.807622, -97.447745];
Zipcodes['78408'] = [27.799635, -97.443688];
Zipcodes['78409'] = [27.827786, -97.509559];
Zipcodes['78410'] = [27.837656, -97.593129];
Zipcodes['78411'] = [27.729177, -97.385089];
Zipcodes['78412'] = [27.703573, -97.345045];
Zipcodes['78413'] = [27.685823, -97.406015];
Zipcodes['78414'] = [27.662421, -97.371535];
Zipcodes['78415'] = [27.658984, -97.495147];
Zipcodes['78416'] = [27.74933, -97.437585];
Zipcodes['78417'] = [27.73753, -97.467701];
Zipcodes['78418'] = [27.389078, -97.329145];
Zipcodes['78419'] = [27.69696, -97.269235];
Zipcodes['78501'] = [26.215817, -98.239572];
Zipcodes['78503'] = [26.162332, -98.248702];
Zipcodes['78504'] = [26.28279, -98.236933];
Zipcodes['78516'] = [26.144539, -98.121312];
Zipcodes['78520'] = [25.960875, -97.548607];
Zipcodes['78521'] = [25.943923, -97.317944];
Zipcodes['78526'] = [25.995689, -97.450848];
Zipcodes['78535'] = [26.24961, -97.731281];
Zipcodes['78536'] = [26.643234, -98.443726];
Zipcodes['78537'] = [26.173186, -98.057967];
Zipcodes['78538'] = [26.367213, -97.974038];
Zipcodes['78539'] = [26.280689, -98.183295];
Zipcodes['78540'] = [26.549081, -97.991646];
Zipcodes['78541'] = [26.452135, -98.276811];
Zipcodes['78542'] = [26.432513, -98.089884];
Zipcodes['78543'] = [26.301157, -98.005073];
Zipcodes['78545'] = [26.585644, -99.134651];
Zipcodes['78547'] = [26.304187, -98.696403];
Zipcodes['78548'] = [26.276549, -98.655268];
Zipcodes['78549'] = [26.489349, -98.029688];
Zipcodes['78550'] = [26.255724, -97.6635];
Zipcodes['78552'] = [26.193782, -97.75069];
Zipcodes['78557'] = [26.109464, -98.253463];
Zipcodes['78558'] = [26.292747, -98.023689];
Zipcodes['78559'] = [26.145744, -97.82572];
Zipcodes['78560'] = [26.265326, -98.485759];
Zipcodes['78561'] = [26.468912, -97.915781];
Zipcodes['78562'] = [26.283802, -97.891227];
Zipcodes['78563'] = [26.672311, -98.254972];
Zipcodes['78564'] = [26.671224, -99.131115];
Zipcodes['78565'] = [26.244459, -98.561327];
Zipcodes['78566'] = [26.137185, -97.388567];
Zipcodes['78567'] = [26.044155, -97.738421];
Zipcodes['78569'] = [26.378857, -97.746805];
Zipcodes['78570'] = [26.171539, -97.911049];
Zipcodes['78572'] = [26.201484, -98.357159];
Zipcodes['78573'] = [26.296191, -98.299959];
Zipcodes['78574'] = [26.31117, -98.372914];
Zipcodes['78575'] = [26.033306, -97.550231];
Zipcodes['78576'] = [26.317103, -98.463699];
Zipcodes['78577'] = [26.163436, -98.19389];
Zipcodes['78578'] = [26.043955, -97.306593];
Zipcodes['78579'] = [26.08921, -97.946286];
Zipcodes['78580'] = [26.500598, -97.747802];
Zipcodes['78582'] = [26.530903, -98.740235];
Zipcodes['78583'] = [26.285511, -97.471595];
Zipcodes['78584'] = [26.569737, -98.99195];
Zipcodes['78585'] = [26.484168, -99.080648];
Zipcodes['78586'] = [26.109941, -97.645414];
Zipcodes['78588'] = [26.73968, -98.39852];
Zipcodes['78589'] = [26.151151, -98.159307];
Zipcodes['78590'] = [26.501875, -97.635063];
Zipcodes['78591'] = [26.639132, -98.551887];
Zipcodes['78592'] = [26.062006, -97.846971];
Zipcodes['78593'] = [26.275747, -97.823524];
Zipcodes['78594'] = [26.34637, -97.806243];
Zipcodes['78595'] = [26.314551, -98.540404];
Zipcodes['78596'] = [26.097256, -97.999651];
Zipcodes['78597'] = [26.273207, -97.27637];
Zipcodes['78598'] = [26.541967, -97.419907];
Zipcodes['78599'] = [26.217004, -97.987952];
Zipcodes['78602'] = [30.132505, -97.329527];
Zipcodes['78605'] = [30.773295, -98.04518];
Zipcodes['78606'] = [30.086444, -98.457963];
Zipcodes['78607'] = [30.819379, -98.488114];
Zipcodes['78608'] = [30.931006, -97.936974];
Zipcodes['78609'] = [30.746922, -98.451367];
Zipcodes['78610'] = [30.079459, -97.841011];
Zipcodes['78611'] = [30.801051, -98.28349];
Zipcodes['78612'] = [30.094071, -97.495094];
Zipcodes['78613'] = [30.507079, -97.819464];
Zipcodes['78614'] = [29.410411, -97.587167];
Zipcodes['78615'] = [30.463118, -97.392857];
Zipcodes['78616'] = [29.926602, -97.553539];
Zipcodes['78617'] = [30.147803, -97.603558];
Zipcodes['78618'] = [30.488233, -99.173583];
Zipcodes['78619'] = [30.112156, -98.031043];
Zipcodes['78620'] = [30.225607, -98.136297];
Zipcodes['78621'] = [30.337023, -97.36042];
Zipcodes['78622'] = [29.782595, -97.779435];
Zipcodes['78623'] = [29.955285, -98.216849];
Zipcodes['78624'] = [30.291786, -98.870915];
Zipcodes['78626'] = [30.657895, -97.617843];
Zipcodes['78628'] = [30.664543, -97.778616];
Zipcodes['78629'] = [29.476842, -97.44894];
Zipcodes['78631'] = [30.33977, -99.30658];
Zipcodes['78632'] = [29.685544, -97.471489];
Zipcodes['78633'] = [30.735166, -97.75545];
Zipcodes['78634'] = [30.55985, -97.544081];
Zipcodes['78635'] = [30.202352, -98.541681];
Zipcodes['78636'] = [30.316898, -98.424992];
Zipcodes['78638'] = [29.659588, -97.785196];
Zipcodes['78639'] = [30.656288, -98.455537];
Zipcodes['78640'] = [29.994866, -97.821239];
Zipcodes['78641'] = [30.557918, -97.913672];
Zipcodes['78642'] = [30.695665, -97.939508];
Zipcodes['78643'] = [30.679688, -98.714851];
Zipcodes['78644'] = [29.872592, -97.674363];
Zipcodes['78645'] = [30.450041, -97.971978];
Zipcodes['78648'] = [29.694265, -97.638111];
Zipcodes['78650'] = [30.303885, -97.218899];
Zipcodes['78652'] = [30.134626, -97.860639];
Zipcodes['78653'] = [30.338229, -97.52073];
Zipcodes['78654'] = [30.571849, -98.210498];
Zipcodes['78655'] = [29.816062, -97.835053];
Zipcodes['78656'] = [29.904511, -97.807308];
Zipcodes['78657'] = [30.533379, -98.378353];
Zipcodes['78658'] = [29.595952, -97.622539];
Zipcodes['78659'] = [30.207417, -97.125389];
Zipcodes['78660'] = [30.43963, -97.594687];
Zipcodes['78661'] = [29.721948, -97.758944];
Zipcodes['78662'] = [29.944801, -97.443564];
Zipcodes['78663'] = [30.415519, -98.311699];
Zipcodes['78664'] = [30.505414, -97.647284];
Zipcodes['78665'] = [30.548874, -97.636201];
Zipcodes['78666'] = [29.881662, -97.984794];
Zipcodes['78669'] = [30.442478, -98.123381];
Zipcodes['78670'] = [29.7648, -97.848529];
Zipcodes['78671'] = [30.218956, -98.629901];
Zipcodes['78672'] = [30.882248, -98.489135];
Zipcodes['78674'] = [30.670574, -97.598481];
Zipcodes['78675'] = [30.434638, -98.707142];
Zipcodes['78676'] = [30.03875, -98.159591];
Zipcodes['78677'] = [29.347767, -97.59018];
Zipcodes['78681'] = [30.541758, -97.716638];
Zipcodes['78701'] = [30.270569, -97.742589];
Zipcodes['78702'] = [30.263378, -97.714483];
Zipcodes['78703'] = [30.293268, -97.76605];
Zipcodes['78704'] = [30.243032, -97.765081];
Zipcodes['78705'] = [30.294713, -97.738978];
Zipcodes['78712'] = [30.283916, -97.732937];
Zipcodes['78717'] = [30.489544, -97.753842];
Zipcodes['78719'] = [30.139718, -97.672615];
Zipcodes['78721'] = [30.271276, -97.683455];
Zipcodes['78722'] = [30.290085, -97.714582];
Zipcodes['78723'] = [30.304238, -97.68575];
Zipcodes['78724'] = [30.292709, -97.617945];
Zipcodes['78725'] = [30.230011, -97.608576];
Zipcodes['78726'] = [30.4308, -97.840085];
Zipcodes['78727'] = [30.428984, -97.717708];
Zipcodes['78728'] = [30.453202, -97.688674];
Zipcodes['78729'] = [30.457246, -97.756419];
Zipcodes['78730'] = [30.363577, -97.839838];
Zipcodes['78731'] = [30.348244, -97.768139];
Zipcodes['78732'] = [30.380104, -97.891857];
Zipcodes['78733'] = [30.321401, -97.884739];
Zipcodes['78734'] = [30.380059, -97.94781];
Zipcodes['78735'] = [30.269121, -97.868816];
Zipcodes['78736'] = [30.24836, -97.949745];
Zipcodes['78737'] = [30.177042, -97.956662];
Zipcodes['78738'] = [30.307015, -97.988911];
Zipcodes['78739'] = [30.175967, -97.890894];
Zipcodes['78741'] = [30.229681, -97.713687];
Zipcodes['78742'] = [30.242354, -97.6592];
Zipcodes['78744'] = [30.177791, -97.725147];
Zipcodes['78745'] = [30.207424, -97.798244];
Zipcodes['78746'] = [30.29686, -97.809566];
Zipcodes['78747'] = [30.124749, -97.741214];
Zipcodes['78748'] = [30.160024, -97.823302];
Zipcodes['78749'] = [30.216175, -97.85583];
Zipcodes['78750'] = [30.441084, -97.786667];
Zipcodes['78751'] = [30.310756, -97.722767];
Zipcodes['78752'] = [30.331428, -97.703643];
Zipcodes['78753'] = [30.383591, -97.673349];
Zipcodes['78754'] = [30.356711, -97.639281];
Zipcodes['78756'] = [30.322255, -97.740163];
Zipcodes['78757'] = [30.35167, -97.732666];
Zipcodes['78758'] = [30.387697, -97.706655];
Zipcodes['78759'] = [30.402705, -97.760938];
Zipcodes['78801'] = [29.362904, -99.898132];
Zipcodes['78802'] = [29.146243, -99.921055];
Zipcodes['78827'] = [28.430942, -99.723746];
Zipcodes['78828'] = [29.844256, -100.009166];
Zipcodes['78829'] = [28.890559, -99.583662];
Zipcodes['78830'] = [28.51949, -99.477126];
Zipcodes['78832'] = [29.409706, -100.391405];
Zipcodes['78833'] = [29.694926, -99.971776];
Zipcodes['78834'] = [28.544594, -99.868351];
Zipcodes['78836'] = [28.31626, -99.633712];
Zipcodes['78837'] = [29.930781, -101.284923];
Zipcodes['78838'] = [29.534647, -99.706512];
Zipcodes['78839'] = [28.714473, -99.739675];
Zipcodes['78840'] = [29.729784, -100.926008];
Zipcodes['78841'] = [29.843444, -101.473569];
Zipcodes['78842'] = [29.639773, -101.005136];
Zipcodes['78843'] = [29.343574, -100.78914];
Zipcodes['78850'] = [29.285285, -99.329182];
Zipcodes['78851'] = [30.21642, -101.92463];
Zipcodes['78852'] = [28.943749, -100.227139];
Zipcodes['78860'] = [28.401006, -100.230598];
Zipcodes['78861'] = [29.414591, -99.155756];
Zipcodes['78870'] = [29.273522, -99.608011];
Zipcodes['78871'] = [29.900867, -101.620307];
Zipcodes['78872'] = [28.907223, -99.881037];
Zipcodes['78873'] = [29.815376, -99.727302];
Zipcodes['78877'] = [28.953437, -100.60675];
Zipcodes['78879'] = [29.647104, -99.711307];
Zipcodes['78880'] = [29.951024, -100.275549];
Zipcodes['78881'] = [29.382382, -99.496031];
Zipcodes['78883'] = [29.673791, -99.317226];
Zipcodes['78884'] = [29.636597, -99.506534];
Zipcodes['78885'] = [29.835326, -99.557796];
Zipcodes['78886'] = [29.155307, -99.18621];
Zipcodes['78931'] = [30.038364, -96.44144];
Zipcodes['78932'] = [30.164115, -96.718195];
Zipcodes['78933'] = [29.801467, -96.368317];
Zipcodes['78934'] = [29.702699, -96.567123];
Zipcodes['78935'] = [29.695493, -96.424208];
Zipcodes['78938'] = [29.813771, -96.708409];
Zipcodes['78940'] = [29.940125, -96.658827];
Zipcodes['78941'] = [29.727617, -97.15845];
Zipcodes['78942'] = [30.155484, -96.938267];
Zipcodes['78943'] = [29.698796, -96.595534];
Zipcodes['78944'] = [30.001358, -96.486785];
Zipcodes['78945'] = [29.916659, -96.888985];
Zipcodes['78946'] = [30.18837, -96.779058];
Zipcodes['78947'] = [30.443442, -97.063655];
Zipcodes['78948'] = [30.321498, -96.976823];
Zipcodes['78949'] = [29.847787, -97.08019];
Zipcodes['78950'] = [29.904948, -96.482617];
Zipcodes['78951'] = [29.620516, -96.836597];
Zipcodes['78953'] = [29.844028, -97.358297];
Zipcodes['78954'] = [30.043016, -96.699188];
Zipcodes['78956'] = [29.686382, -96.933775];
Zipcodes['78957'] = [30.013881, -97.182098];
Zipcodes['78959'] = [29.700745, -97.301328];
Zipcodes['78962'] = [29.674439, -96.7345];
Zipcodes['78963'] = [29.91692, -97.028763];
Zipcodes['79001'] = [35.290798, -102.805404];
Zipcodes['79002'] = [35.204414, -100.770299];
Zipcodes['79003'] = [35.639808, -100.082662];
Zipcodes['79005'] = [36.386253, -100.516052];
Zipcodes['79007'] = [35.668323, -101.412247];
Zipcodes['79008'] = [35.698383, -101.423838];
Zipcodes['79009'] = [34.529103, -102.883363];
Zipcodes['79010'] = [35.451665, -102.140733];
Zipcodes['79011'] = [35.63542, -100.20705];
Zipcodes['79012'] = [35.117246, -102.107254];
Zipcodes['79013'] = [36.040954, -101.996154];
Zipcodes['79014'] = [35.923984, -100.332663];
Zipcodes['79015'] = [34.947109, -101.900712];
Zipcodes['79016'] = [34.983383, -101.914804];
Zipcodes['79018'] = [35.705732, -102.343965];
Zipcodes['79019'] = [34.979959, -101.388558];
Zipcodes['79021'] = [33.983664, -101.993014];
Zipcodes['79022'] = [36.098125, -102.60691];
Zipcodes['79024'] = [36.439773, -100.369084];
Zipcodes['79025'] = [34.949401, -102.201165];
Zipcodes['79027'] = [34.497573, -102.337979];
Zipcodes['79029'] = [35.901966, -102.025897];
Zipcodes['79031'] = [34.244322, -102.449905];
Zipcodes['79032'] = [34.287801, -101.894919];
Zipcodes['79033'] = [36.306844, -100.976612];
Zipcodes['79034'] = [36.380058, -100.164764];
Zipcodes['79035'] = [34.68165, -102.781898];
Zipcodes['79036'] = [35.59276, -101.544363];
Zipcodes['79039'] = [35.239266, -101.132823];
Zipcodes['79040'] = [36.29648, -101.58718];
Zipcodes['79041'] = [34.043255, -101.931966];
Zipcodes['79042'] = [34.741342, -101.893567];
Zipcodes['79043'] = [34.368347, -102.134819];
Zipcodes['79044'] = [35.863315, -102.367488];
Zipcodes['79045'] = [34.899855, -102.514844];
Zipcodes['79046'] = [36.13903, -100.107146];
Zipcodes['79051'] = [36.444349, -102.224098];
Zipcodes['79052'] = [34.358439, -101.764658];
Zipcodes['79053'] = [34.408017, -102.579402];
Zipcodes['79054'] = [35.429618, -100.783905];
Zipcodes['79056'] = [36.217254, -100.221361];
Zipcodes['79057'] = [35.198478, -100.621274];
Zipcodes['79058'] = [35.594731, -101.951347];
Zipcodes['79059'] = [35.799469, -100.73389];
Zipcodes['79061'] = [35.524283, -100.452264];
Zipcodes['79062'] = [35.988501, -101.530407];
Zipcodes['79063'] = [34.517009, -102.115148];
Zipcodes['79064'] = [34.180943, -102.119262];
Zipcodes['79065'] = [35.51732, -100.899954];
Zipcodes['79066'] = [35.499266, -101.03425];
Zipcodes['79068'] = [35.3285, -101.444138];
Zipcodes['79070'] = [36.236595, -100.786511];
Zipcodes['79072'] = [34.171907, -101.744828];
Zipcodes['79073'] = [33.956084, -101.949248];
Zipcodes['79078'] = [35.698705, -101.550344];
Zipcodes['79079'] = [35.206557, -100.248529];
Zipcodes['79080'] = [35.701544, -101.212706];
Zipcodes['79081'] = [36.218996, -101.204732];
Zipcodes['79082'] = [34.218791, -102.286186];
Zipcodes['79083'] = [35.869815, -101.512425];
Zipcodes['79084'] = [36.259687, -102.02658];
Zipcodes['79085'] = [34.739899, -102.509167];
Zipcodes['79086'] = [36.125218, -101.784188];
Zipcodes['79087'] = [36.320843, -102.917155];
Zipcodes['79088'] = [34.575464, -101.691958];
Zipcodes['79091'] = [34.870604, -102.100172];
Zipcodes['79092'] = [35.313659, -102.510275];
Zipcodes['79093'] = [36.266369, -101.029969];
Zipcodes['79094'] = [34.840077, -101.5097];
Zipcodes['79095'] = [34.884163, -100.196165];
Zipcodes['79096'] = [35.458033, -100.199727];
Zipcodes['79097'] = [35.430575, -101.173894];
Zipcodes['79098'] = [35.152354, -102.215756];
Zipcodes['79101'] = [35.206072, -101.8395];
Zipcodes['79102'] = [35.201263, -101.849182];
Zipcodes['79103'] = [35.18215, -101.800373];
Zipcodes['79104'] = [35.201147, -101.789954];
Zipcodes['79106'] = [35.203051, -101.891662];
Zipcodes['79107'] = [35.231515, -101.794239];
Zipcodes['79108'] = [35.301258, -101.675725];
Zipcodes['79109'] = [35.166416, -101.886423];
Zipcodes['79110'] = [35.150993, -101.869766];
Zipcodes['79111'] = [35.220443, -101.708078];
Zipcodes['79118'] = [35.106367, -101.739911];
Zipcodes['79119'] = [35.109084, -102.017887];
Zipcodes['79121'] = [35.174461, -101.929219];
Zipcodes['79124'] = [35.259324, -102.047444];
Zipcodes['79201'] = [34.404718, -100.341146];
Zipcodes['79220'] = [33.766958, -100.764319];
Zipcodes['79223'] = [34.231669, -100.480873];
Zipcodes['79225'] = [34.249634, -99.507466];
Zipcodes['79226'] = [34.918336, -100.997087];
Zipcodes['79227'] = [33.871603, -99.747306];
Zipcodes['79229'] = [33.666927, -100.744262];
Zipcodes['79230'] = [34.710122, -100.053583];
Zipcodes['79231'] = [33.941637, -101.065843];
Zipcodes['79233'] = [34.498476, -100.494137];
Zipcodes['79234'] = [34.216998, -100.926434];
Zipcodes['79235'] = [33.942142, -101.26349];
Zipcodes['79236'] = [33.603167, -100.29976];
Zipcodes['79237'] = [34.857952, -100.673364];
Zipcodes['79239'] = [34.655623, -100.763893];
Zipcodes['79240'] = [34.883937, -100.760664];
Zipcodes['79241'] = [34.179633, -101.387813];
Zipcodes['79243'] = [33.767731, -101.025326];
Zipcodes['79244'] = [34.057899, -100.793152];
Zipcodes['79245'] = [34.680965, -100.476496];
Zipcodes['79247'] = [34.396883, -99.380703];
Zipcodes['79248'] = [33.943094, -100.22369];
Zipcodes['79250'] = [33.894281, -101.598542];
Zipcodes['79251'] = [34.943421, -100.451566];
Zipcodes['79252'] = [34.306674, -99.797428];
Zipcodes['79255'] = [34.349913, -101.050762];
Zipcodes['79256'] = [33.881975, -100.83115];
Zipcodes['79257'] = [34.446931, -101.298874];
Zipcodes['79258'] = [34.243411, -101.296434];
Zipcodes['79259'] = [34.358303, -100.454434];
Zipcodes['79261'] = [34.449799, -100.872884];
Zipcodes['79311'] = [33.857882, -101.881538];
Zipcodes['79312'] = [34.022954, -102.307259];
Zipcodes['79313'] = [33.812561, -102.16092];
Zipcodes['79314'] = [33.630178, -103.03047];
Zipcodes['79316'] = [33.134142, -102.3048];
Zipcodes['79322'] = [33.661255, -101.209231];
Zipcodes['79323'] = [32.933054, -102.904556];
Zipcodes['79324'] = [33.887607, -102.735385];
Zipcodes['79325'] = [34.414294, -102.934273];
Zipcodes['79326'] = [34.099124, -102.214321];
Zipcodes['79329'] = [33.716447, -101.691654];
Zipcodes['79330'] = [33.018178, -101.262086];
Zipcodes['79331'] = [32.671095, -101.972672];
Zipcodes['79336'] = [33.59973, -102.40023];
Zipcodes['79339'] = [33.886628, -102.354854];
Zipcodes['79342'] = [32.903137, -102.331883];
Zipcodes['79343'] = [33.645727, -101.552578];
Zipcodes['79344'] = [33.856787, -103.014315];
Zipcodes['79345'] = [33.337542, -102.33135];
Zipcodes['79346'] = [33.645304, -102.825853];
Zipcodes['79347'] = [34.18618, -102.797922];
Zipcodes['79350'] = [33.731452, -101.825896];
Zipcodes['79351'] = [32.938226, -101.777029];
Zipcodes['79353'] = [33.783578, -102.591413];
Zipcodes['79355'] = [33.166024, -102.894785];
Zipcodes['79356'] = [33.28459, -101.342082];
Zipcodes['79357'] = [33.646471, -101.405761];
Zipcodes['79358'] = [33.421975, -102.190949];
Zipcodes['79359'] = [32.923636, -102.544132];
Zipcodes['79360'] = [32.689865, -102.724434];
Zipcodes['79363'] = [33.707731, -102.045021];
Zipcodes['79364'] = [33.445487, -101.63203];
Zipcodes['79366'] = [33.530315, -101.685343];
Zipcodes['79367'] = [33.589372, -102.168049];
Zipcodes['79369'] = [33.943198, -102.159111];
Zipcodes['79370'] = [33.444643, -100.929803];
Zipcodes['79371'] = [33.979698, -102.69717];
Zipcodes['79372'] = [33.424804, -102.501112];
Zipcodes['79373'] = [33.158706, -101.839339];
Zipcodes['79376'] = [33.247697, -102.637249];
Zipcodes['79377'] = [32.929648, -102.133506];
Zipcodes['79378'] = [33.02766, -102.446749];
Zipcodes['79379'] = [33.495033, -102.653489];
Zipcodes['79380'] = [33.739154, -102.327823];
Zipcodes['79381'] = [33.312912, -101.770523];
Zipcodes['79382'] = [33.468919, -102.01762];
Zipcodes['79383'] = [33.32435, -101.90802];
Zipcodes['79401'] = [33.586823, -101.851832];
Zipcodes['79402'] = [33.581238, -101.842013];
Zipcodes['79403'] = [33.643534, -101.758167];
Zipcodes['79404'] = [33.527702, -101.793717];
Zipcodes['79406'] = [33.585914, -101.878393];
Zipcodes['79407'] = [33.56146, -102.085741];
Zipcodes['79410'] = [33.570071, -101.891011];
Zipcodes['79411'] = [33.570115, -101.857732];
Zipcodes['79412'] = [33.546383, -101.856516];
Zipcodes['79413'] = [33.545748, -101.889936];
Zipcodes['79414'] = [33.547794, -101.92023];
Zipcodes['79415'] = [33.700795, -101.896988];
Zipcodes['79416'] = [33.600114, -101.982775];
Zipcodes['79423'] = [33.439985, -101.855477];
Zipcodes['79424'] = [33.465175, -101.935271];
Zipcodes['79501'] = [32.740591, -99.890392];
Zipcodes['79502'] = [33.182154, -100.263878];
Zipcodes['79503'] = [32.887469, -99.696382];
Zipcodes['79504'] = [32.310489, -99.353711];
Zipcodes['79505'] = [33.55955, -99.844136];
Zipcodes['79506'] = [32.117436, -100.330036];
Zipcodes['79508'] = [32.269967, -99.830323];
Zipcodes['79510'] = [32.266413, -99.518894];
Zipcodes['79511'] = [32.434136, -101.26903];
Zipcodes['79512'] = [32.323175, -100.931991];
Zipcodes['79517'] = [32.879511, -101.246784];
Zipcodes['79518'] = [33.30818, -100.745809];
Zipcodes['79519'] = [32.044159, -99.700329];
Zipcodes['79520'] = [32.876994, -100.128483];
Zipcodes['79521'] = [33.120371, -99.631772];
Zipcodes['79525'] = [32.660278, -99.826967];
Zipcodes['79526'] = [32.650421, -100.756399];
Zipcodes['79527'] = [32.595492, -101.099466];
Zipcodes['79528'] = [33.164602, -100.578188];
Zipcodes['79529'] = [33.435018, -99.8295];
Zipcodes['79530'] = [32.103908, -99.779744];
Zipcodes['79532'] = [32.408016, -100.714264];
Zipcodes['79533'] = [32.848659, -99.547268];
Zipcodes['79534'] = [32.753255, -100.224591];
Zipcodes['79535'] = [32.223438, -100.479835];
Zipcodes['79536'] = [32.474124, -100.024523];
Zipcodes['79537'] = [32.27586, -100.219861];
Zipcodes['79538'] = [31.976237, -99.702377];
Zipcodes['79539'] = [33.359951, -99.889262];
Zipcodes['79540'] = [33.150657, -100.07114];
Zipcodes['79541'] = [32.147788, -99.810223];
Zipcodes['79543'] = [32.720841, -100.380112];
Zipcodes['79544'] = [33.293231, -99.858473];
Zipcodes['79545'] = [32.415212, -100.573539];
Zipcodes['79546'] = [32.880122, -100.488267];
Zipcodes['79547'] = [33.197744, -99.905443];
Zipcodes['79548'] = [33.071167, -99.912835];
Zipcodes['79549'] = [32.89857, -100.93202];
Zipcodes['79553'] = [32.940917, -99.850965];
Zipcodes['79556'] = [32.485191, -100.392114];
Zipcodes['79560'] = [32.665235, -100.213046];
Zipcodes['79561'] = [32.512808, -100.176641];
Zipcodes['79562'] = [32.236251, -99.866451];
Zipcodes['79563'] = [32.415823, -99.90623];
Zipcodes['79565'] = [32.378574, -101.102996];
Zipcodes['79566'] = [32.156601, -100.111723];
Zipcodes['79567'] = [31.988595, -99.97707];
Zipcodes['79601'] = [32.572842, -99.642121];
Zipcodes['79602'] = [32.335677, -99.667488];
Zipcodes['79603'] = [32.333551, -99.927724];
Zipcodes['79605'] = [32.434599, -99.781827];
Zipcodes['79606'] = [32.362767, -99.817334];
Zipcodes['79607'] = [32.423188, -99.838681];
Zipcodes['79699'] = [32.46219, -99.714942];
Zipcodes['79701'] = [31.992446, -102.080985];
Zipcodes['79703'] = [31.98001, -102.131551];
Zipcodes['79705'] = [32.104144, -102.059752];
Zipcodes['79706'] = [31.821861, -101.954609];
Zipcodes['79707'] = [32.037424, -102.195228];
Zipcodes['79713'] = [32.496157, -101.747536];
Zipcodes['79714'] = [32.312258, -102.640206];
Zipcodes['79718'] = [30.99421, -103.734733];
Zipcodes['79719'] = [31.460655, -103.404137];
Zipcodes['79720'] = [32.199486, -101.504783];
Zipcodes['79721'] = [32.224895, -101.527363];
Zipcodes['79730'] = [31.192539, -103.052043];
Zipcodes['79731'] = [31.435696, -102.581722];
Zipcodes['79733'] = [32.110338, -101.366796];
Zipcodes['79734'] = [30.68216, -103.980976];
Zipcodes['79735'] = [30.786235, -102.851189];
Zipcodes['79738'] = [32.751934, -101.474837];
Zipcodes['79739'] = [31.769325, -101.506391];
Zipcodes['79741'] = [32.007678, -102.647611];
Zipcodes['79742'] = [31.360283, -102.852658];
Zipcodes['79743'] = [31.236626, -102.789065];
Zipcodes['79744'] = [30.890855, -101.912948];
Zipcodes['79745'] = [31.832862, -103.054925];
Zipcodes['79748'] = [32.389997, -101.663975];
Zipcodes['79749'] = [32.255488, -101.834148];
Zipcodes['79752'] = [31.113084, -102.191259];
Zipcodes['79754'] = [31.820092, -103.488035];
Zipcodes['79755'] = [31.525261, -101.997544];
Zipcodes['79756'] = [31.534766, -102.877408];
Zipcodes['79758'] = [32.013098, -102.327298];
Zipcodes['79759'] = [31.799217, -102.633009];
Zipcodes['79761'] = [31.855583, -102.348437];
Zipcodes['79762'] = [31.925286, -102.357886];
Zipcodes['79763'] = [31.81292, -102.454612];
Zipcodes['79764'] = [31.876762, -102.473341];
Zipcodes['79765'] = [31.896642, -102.262922];
Zipcodes['79766'] = [31.71573, -102.375828];
Zipcodes['79769'] = [31.682772, -102.625287];
Zipcodes['79770'] = [31.854982, -103.912233];
Zipcodes['79772'] = [31.400312, -103.584082];
Zipcodes['79776'] = [31.77515, -102.555724];
Zipcodes['79777'] = [31.484586, -103.161046];
Zipcodes['79778'] = [31.258503, -101.973541];
Zipcodes['79780'] = [31.039416, -103.65751];
Zipcodes['79781'] = [30.61472, -101.76562];
Zipcodes['79782'] = [32.189391, -101.882857];
Zipcodes['79783'] = [32.332045, -102.050176];
Zipcodes['79785'] = [31.321361, -103.940052];
Zipcodes['79788'] = [31.603154, -102.983513];
Zipcodes['79789'] = [31.774778, -103.270419];
Zipcodes['79821'] = [31.976815, -106.599316];
Zipcodes['79830'] = [30.211507, -103.626765];
Zipcodes['79832'] = [30.36324, -103.649247];
Zipcodes['79834'] = [29.182737, -103.262462];
Zipcodes['79835'] = [31.935653, -106.596089];
Zipcodes['79836'] = [31.57011, -106.211608];
Zipcodes['79837'] = [32.095261, -105.162407];
Zipcodes['79838'] = [31.486292, -106.16111];
Zipcodes['79839'] = [31.42078, -105.64224];
Zipcodes['79842'] = [30.013027, -103.027525];
Zipcodes['79843'] = [29.869824, -104.412332];
Zipcodes['79845'] = [29.653344, -104.397247];
Zipcodes['79846'] = [29.387969, -103.990395];
Zipcodes['79847'] = [31.747031, -105.175314];
Zipcodes['79848'] = [30.11968, -102.397964];
Zipcodes['79849'] = [31.56691, -106.258375];
Zipcodes['79851'] = [31.279537, -105.257914];
Zipcodes['79852'] = [29.364936, -103.615479];
Zipcodes['79853'] = [31.434058, -106.063238];
Zipcodes['79854'] = [30.625445, -104.61006];
Zipcodes['79855'] = [30.930866, -104.860712];
Zipcodes['79901'] = [31.759397, -106.480128];
Zipcodes['79902'] = [31.784855, -106.496881];
Zipcodes['79903'] = [31.786231, -106.441911];
Zipcodes['79904'] = [31.849199, -106.464792];
Zipcodes['79905'] = [31.766054, -106.426202];
Zipcodes['79906'] = [31.809024, -106.419547];
Zipcodes['79907'] = [31.707231, -106.326563];
Zipcodes['79908'] = [31.833114, -106.364975];
Zipcodes['79911'] = [31.936069, -106.535685];
Zipcodes['79912'] = [31.848583, -106.534316];
Zipcodes['79915'] = [31.746041, -106.371426];
Zipcodes['79916'] = [31.866612, -106.387217];
Zipcodes['79918'] = [31.846889, -106.36942];
Zipcodes['79920'] = [31.822773, -106.459822];
Zipcodes['79922'] = [31.810369, -106.555964];
Zipcodes['79924'] = [31.902236, -106.413548];
Zipcodes['79925'] = [31.805328, -106.368845];
Zipcodes['79927'] = [31.645424, -106.274864];
Zipcodes['79928'] = [31.678635, -106.115148];
Zipcodes['79930'] = [31.810423, -106.468958];
Zipcodes['79932'] = [31.874971, -106.607981];
Zipcodes['79934'] = [31.950669, -106.433518];
Zipcodes['79935'] = [31.768054, -106.330277];
Zipcodes['79936'] = [31.773676, -106.295415];
Zipcodes['79938'] = [31.845514, -106.019659];
Zipcodes['80002'] = [39.793716, -105.108382];
Zipcodes['80003'] = [39.825413, -105.064435];
Zipcodes['80004'] = [39.81378, -105.122182];
Zipcodes['80005'] = [39.85165, -105.131418];
Zipcodes['80007'] = [39.867038, -105.197733];
Zipcodes['80010'] = [39.73925, -104.86171];
Zipcodes['80011'] = [39.744033, -104.790013];
Zipcodes['80012'] = [39.699766, -104.837834];
Zipcodes['80013'] = [39.661462, -104.765764];
Zipcodes['80014'] = [39.663552, -104.837771];
Zipcodes['80015'] = [39.628055, -104.793254];
Zipcodes['80016'] = [39.593026, -104.7224];
Zipcodes['80017'] = [39.699437, -104.775296];
Zipcodes['80018'] = [39.7019, -104.686962];
Zipcodes['80019'] = [39.778586, -104.684754];
Zipcodes['80020'] = [39.930782, -105.074287];
Zipcodes['80021'] = [39.890663, -105.113724];
Zipcodes['80022'] = [39.861115, -104.788777];
Zipcodes['80023'] = [39.975844, -105.009381];
Zipcodes['80024'] = [39.844106, -104.918414];
Zipcodes['80025'] = [39.925508, -105.299486];
Zipcodes['80026'] = [40.012151, -105.09991];
Zipcodes['80027'] = [39.951506, -105.15813];
Zipcodes['80030'] = [39.830822, -105.03747];
Zipcodes['80031'] = [39.873936, -105.038792];
Zipcodes['80033'] = [39.772675, -105.104801];
Zipcodes['80045'] = [39.746661, -104.837224];
Zipcodes['80101'] = [39.406936, -103.944435];
Zipcodes['80102'] = [39.734017, -104.438524];
Zipcodes['80103'] = [39.7912, -104.093839];
Zipcodes['80104'] = [39.302112, -104.819674];
Zipcodes['80105'] = [39.636719, -103.966232];
Zipcodes['80106'] = [39.186147, -104.515915];
Zipcodes['80107'] = [39.411894, -104.572151];
Zipcodes['80108'] = [39.444376, -104.853179];
Zipcodes['80109'] = [39.372344, -104.912085];
Zipcodes['80110'] = [39.645936, -105.011368];
Zipcodes['80111'] = [39.612004, -104.882787];
Zipcodes['80112'] = [39.573236, -104.86489];
Zipcodes['80113'] = [39.644445, -104.965111];
Zipcodes['80116'] = [39.320019, -104.709712];
Zipcodes['80117'] = [39.395522, -104.389585];
Zipcodes['80118'] = [39.18964, -104.881926];
Zipcodes['80120'] = [39.593836, -105.01146];
Zipcodes['80121'] = [39.610385, -104.95381];
Zipcodes['80122'] = [39.580309, -104.954917];
Zipcodes['80123'] = [39.61601, -105.069449];
Zipcodes['80124'] = [39.532798, -104.891219];
Zipcodes['80125'] = [39.485382, -105.062779];
Zipcodes['80126'] = [39.542161, -104.963124];
Zipcodes['80127'] = [39.530726, -105.164344];
Zipcodes['80128'] = [39.560922, -105.079925];
Zipcodes['80129'] = [39.546879, -105.011599];
Zipcodes['80130'] = [39.528342, -104.923869];
Zipcodes['80131'] = [39.476265, -105.00904];
Zipcodes['80132'] = [39.086552, -104.85834];
Zipcodes['80133'] = [39.113465, -104.899806];
Zipcodes['80134'] = [39.482825, -104.77999];
Zipcodes['80135'] = [39.254744, -105.156973];
Zipcodes['80136'] = [39.791978, -104.279929];
Zipcodes['80137'] = [39.712176, -104.594097];
Zipcodes['80138'] = [39.517532, -104.670911];
Zipcodes['80202'] = [39.751526, -104.997673];
Zipcodes['80203'] = [39.731419, -104.982764];
Zipcodes['80204'] = [39.734757, -105.020678];
Zipcodes['80205'] = [39.758861, -104.964699];
Zipcodes['80206'] = [39.730369, -104.952511];
Zipcodes['80207'] = [39.761385, -104.916696];
Zipcodes['80209'] = [39.706581, -104.96575];
Zipcodes['80210'] = [39.676626, -104.962315];
Zipcodes['80211'] = [39.767444, -105.019736];
Zipcodes['80212'] = [39.772047, -105.048027];
Zipcodes['80214'] = [39.743312, -105.069025];
Zipcodes['80215'] = [39.744773, -105.115977];
Zipcodes['80216'] = [39.787707, -104.961139];
Zipcodes['80218'] = [39.731168, -104.970715];
Zipcodes['80219'] = [39.695293, -105.035601];
Zipcodes['80220'] = [39.733782, -104.916465];
Zipcodes['80221'] = [39.81602, -105.011552];
Zipcodes['80222'] = [39.671314, -104.92823];
Zipcodes['80223'] = [39.695794, -105.003843];
Zipcodes['80224'] = [39.688065, -104.911575];
Zipcodes['80226'] = [39.710131, -105.092127];
Zipcodes['80227'] = [39.665458, -105.102196];
Zipcodes['80228'] = [39.694935, -105.173187];
Zipcodes['80229'] = [39.85425, -104.957598];
Zipcodes['80230'] = [39.720338, -104.889308];
Zipcodes['80231'] = [39.671225, -104.887861];
Zipcodes['80232'] = [39.688198, -105.089484];
Zipcodes['80233'] = [39.897536, -104.943922];
Zipcodes['80234'] = [39.909992, -105.002643];
Zipcodes['80235'] = [39.647607, -105.089482];
Zipcodes['80236'] = [39.651633, -105.039699];
Zipcodes['80237'] = [39.639871, -104.901711];
Zipcodes['80238'] = [39.77741, -104.879652];
Zipcodes['80239'] = [39.789197, -104.833616];
Zipcodes['80241'] = [39.929079, -104.954277];
Zipcodes['80246'] = [39.705173, -104.930718];
Zipcodes['80247'] = [39.697809, -104.878897];
Zipcodes['80249'] = [39.854746, -104.695804];
Zipcodes['80260'] = [39.866989, -105.001354];
Zipcodes['80264'] = [39.742477, -104.985482];
Zipcodes['80266'] = [39.790007, -104.900321];
Zipcodes['80290'] = [39.744092, -104.986754];
Zipcodes['80293'] = [39.746079, -104.98972];
Zipcodes['80294'] = [39.749794, -104.989712];
Zipcodes['80301'] = [40.04569, -105.20089];
Zipcodes['80302'] = [40.025056, -105.348664];
Zipcodes['80303'] = [39.973222, -105.209276];
Zipcodes['80304'] = [40.044727, -105.288351];
Zipcodes['80305'] = [39.976873, -105.248683];
Zipcodes['80310'] = [40.003986, -105.261368];
Zipcodes['80401'] = [39.718872, -105.235342];
Zipcodes['80403'] = [39.828768, -105.316923];
Zipcodes['80419'] = [39.728414, -105.202468];
Zipcodes['80420'] = [39.324401, -106.11611];
Zipcodes['80421'] = [39.460932, -105.501199];
Zipcodes['80422'] = [39.861574, -105.539757];
Zipcodes['80423'] = [39.845901, -106.554702];
Zipcodes['80424'] = [39.473285, -106.00426];
Zipcodes['80425'] = [39.350155, -105.205591];
Zipcodes['80426'] = [39.875477, -106.990946];
Zipcodes['80427'] = [39.793654, -105.524087];
Zipcodes['80428'] = [40.843694, -106.925249];
Zipcodes['80430'] = [40.519317, -106.41632];
Zipcodes['80432'] = [39.230346, -105.837507];
Zipcodes['80433'] = [39.498109, -105.303437];
Zipcodes['80434'] = [40.903319, -106.284985];
Zipcodes['80435'] = [39.573898, -105.934721];
Zipcodes['80436'] = [39.781044, -105.640202];
Zipcodes['80438'] = [39.744312, -105.796262];
Zipcodes['80439'] = [39.634422, -105.440245];
Zipcodes['80440'] = [39.132289, -106.055005];
Zipcodes['80442'] = [39.92467, -105.887899];
Zipcodes['80443'] = [39.503377, -106.174069];
Zipcodes['80444'] = [39.632198, -105.734642];
Zipcodes['80446'] = [40.163892, -105.934574];
Zipcodes['80447'] = [40.29556, -105.822108];
Zipcodes['80448'] = [39.477204, -105.834433];
Zipcodes['80449'] = [38.929671, -105.790096];
Zipcodes['80451'] = [40.040045, -106.04454];
Zipcodes['80452'] = [39.712014, -105.597334];
Zipcodes['80453'] = [39.664805, -105.24284];
Zipcodes['80454'] = [39.629881, -105.247461];
Zipcodes['80455'] = [40.094966, -105.397702];
Zipcodes['80456'] = [39.310412, -105.689471];
Zipcodes['80457'] = [39.65097, -105.299732];
Zipcodes['80459'] = [40.1547, -106.414187];
Zipcodes['80461'] = [39.242242, -106.287866];
Zipcodes['80463'] = [39.982326, -106.758075];
Zipcodes['80465'] = [39.609094, -105.205535];
Zipcodes['80466'] = [39.988037, -105.544054];
Zipcodes['80467'] = [40.207634, -106.825857];
Zipcodes['80468'] = [39.914883, -106.110896];
Zipcodes['80469'] = [40.214461, -106.967989];
Zipcodes['80470'] = [39.379796, -105.324404];
Zipcodes['80471'] = [39.935049, -105.420534];
Zipcodes['80473'] = [40.449427, -106.160134];
Zipcodes['80474'] = [39.923207, -105.569046];
Zipcodes['80475'] = [39.410988, -105.608182];
Zipcodes['80476'] = [39.670628, -105.834343];
Zipcodes['80478'] = [40.022149, -105.856936];
Zipcodes['80479'] = [39.992645, -106.847261];
Zipcodes['80480'] = [40.672888, -106.341566];
Zipcodes['80481'] = [40.101411, -105.576764];
Zipcodes['80482'] = [39.901527, -105.785677];
Zipcodes['80483'] = [40.091798, -106.93598];
Zipcodes['80487'] = [40.581425, -106.885954];
Zipcodes['80498'] = [39.746631, -106.27053];
Zipcodes['80501'] = [40.164832, -105.101852];
Zipcodes['80503'] = [40.169014, -105.210376];
Zipcodes['80504'] = [40.16564, -105.029231];
Zipcodes['80510'] = [40.2125, -105.6066];
Zipcodes['80512'] = [40.631157, -105.574043];
Zipcodes['80513'] = [40.299451, -105.118553];
Zipcodes['80514'] = [40.070612, -104.955294];
Zipcodes['80515'] = [40.462346, -105.382303];
Zipcodes['80516'] = [40.052867, -105.021054];
Zipcodes['80517'] = [40.397935, -105.614927];
Zipcodes['80520'] = [40.114073, -104.933438];
Zipcodes['80521'] = [40.593083, -105.127429];
Zipcodes['80524'] = [40.646854, -105.029519];
Zipcodes['80525'] = [40.527358, -105.041438];
Zipcodes['80526'] = [40.52219, -105.129441];
Zipcodes['80528'] = [40.499003, -105.005051];
Zipcodes['80530'] = [40.101551, -104.923044];
Zipcodes['80532'] = [40.483892, -105.465565];
Zipcodes['80534'] = [40.331863, -104.937467];
Zipcodes['80535'] = [40.736224, -105.191062];
Zipcodes['80536'] = [40.878827, -105.381223];
Zipcodes['80537'] = [40.38308, -105.200416];
Zipcodes['80538'] = [40.544612, -105.245108];
Zipcodes['80540'] = [40.224619, -105.400456];
Zipcodes['80542'] = [40.234051, -104.999017];
Zipcodes['80543'] = [40.349543, -104.857385];
Zipcodes['80544'] = [40.103105, -105.1715];
Zipcodes['80545'] = [40.842012, -105.702819];
Zipcodes['80546'] = [40.527112, -104.850247];
Zipcodes['80547'] = [40.52663, -104.965364];
Zipcodes['80549'] = [40.857542, -105.044126];
Zipcodes['80550'] = [40.488775, -104.899732];
Zipcodes['80601'] = [39.962169, -104.807922];
Zipcodes['80602'] = [39.966162, -104.908888];
Zipcodes['80603'] = [39.951146, -104.660237];
Zipcodes['80610'] = [40.673865, -104.591302];
Zipcodes['80611'] = [40.624941, -104.249087];
Zipcodes['80612'] = [40.878964, -104.823773];
Zipcodes['80615'] = [40.545658, -104.645698];
Zipcodes['80620'] = [40.375758, -104.713561];
Zipcodes['80621'] = [40.107188, -104.801281];
Zipcodes['80622'] = [40.538696, -104.441956];
Zipcodes['80623'] = [40.28534, -104.782547];
Zipcodes['80624'] = [40.435451, -104.423055];
Zipcodes['80631'] = [40.444516, -104.679555];
Zipcodes['80634'] = [40.40141, -104.792395];
Zipcodes['80640'] = [39.88755, -104.880816];
Zipcodes['80642'] = [40.051416, -104.612139];
Zipcodes['80643'] = [40.112927, -104.489176];
Zipcodes['80644'] = [40.334515, -104.430048];
Zipcodes['80645'] = [40.283314, -104.649185];
Zipcodes['80646'] = [40.475379, -104.697597];
Zipcodes['80648'] = [40.762507, -104.7305];
Zipcodes['80649'] = [40.400836, -104.185793];
Zipcodes['80650'] = [40.64873, -104.781215];
Zipcodes['80651'] = [40.241735, -104.80941];
Zipcodes['80652'] = [40.138876, -104.275168];
Zipcodes['80653'] = [40.446125, -103.977787];
Zipcodes['80654'] = [40.135588, -104.081882];
Zipcodes['80701'] = [40.125042, -103.817561];
Zipcodes['80705'] = [40.271114, -103.834267];
Zipcodes['80720'] = [40.073492, -103.187333];
Zipcodes['80721'] = [40.688165, -102.152977];
Zipcodes['80722'] = [40.476059, -103.200495];
Zipcodes['80723'] = [40.193681, -103.543329];
Zipcodes['80726'] = [40.923171, -102.776849];
Zipcodes['80727'] = [40.024443, -102.510758];
Zipcodes['80728'] = [40.639921, -102.87286];
Zipcodes['80729'] = [40.876288, -104.226608];
Zipcodes['80731'] = [40.587662, -102.59793];
Zipcodes['80732'] = [40.988467, -104.305251];
Zipcodes['80733'] = [40.357168, -103.474672];
Zipcodes['80734'] = [40.519891, -102.291952];
Zipcodes['80735'] = [39.709865, -102.244077];
Zipcodes['80736'] = [40.811815, -103.027237];
Zipcodes['80737'] = [40.895118, -102.198592];
Zipcodes['80740'] = [39.744369, -103.384859];
Zipcodes['80741'] = [40.566834, -103.476991];
Zipcodes['80742'] = [40.755781, -103.835018];
Zipcodes['80743'] = [40.215059, -102.956289];
Zipcodes['80744'] = [40.873626, -102.391968];
Zipcodes['80745'] = [40.895496, -103.412556];
Zipcodes['80746'] = [40.607064, -102.456953];
Zipcodes['80747'] = [40.933839, -103.18087];
Zipcodes['80749'] = [40.867058, -102.547276];
Zipcodes['80750'] = [40.414765, -103.604846];
Zipcodes['80751'] = [40.651901, -103.241063];
Zipcodes['80754'] = [40.665383, -103.636232];
Zipcodes['80755'] = [39.896543, -102.345821];
Zipcodes['80757'] = [39.791027, -103.58313];
Zipcodes['80758'] = [40.138836, -102.193094];
Zipcodes['80759'] = [40.111353, -102.690716];
Zipcodes['80801'] = [39.73228, -103.101271];
Zipcodes['80802'] = [38.827829, -102.150471];
Zipcodes['80804'] = [39.34684, -103.248069];
Zipcodes['80805'] = [39.296843, -102.437367];
Zipcodes['80807'] = [39.321667, -102.223231];
Zipcodes['80808'] = [39.008243, -104.303547];
Zipcodes['80809'] = [38.852008, -105.008613];
Zipcodes['80810'] = [38.822287, -102.428708];
Zipcodes['80812'] = [39.633425, -102.830967];
Zipcodes['80813'] = [38.754427, -105.120108];
Zipcodes['80814'] = [38.944862, -105.189664];
Zipcodes['80815'] = [39.389193, -103.098983];
Zipcodes['80816'] = [38.857741, -105.315864];
Zipcodes['80817'] = [38.588209, -104.67753];
Zipcodes['80818'] = [39.421692, -103.464845];
Zipcodes['80819'] = [38.947428, -104.998103];
Zipcodes['80820'] = [38.777049, -105.566978];
Zipcodes['80821'] = [39.00028, -103.447221];
Zipcodes['80822'] = [39.677784, -102.678662];
Zipcodes['80823'] = [38.663464, -103.419186];
Zipcodes['80824'] = [39.672236, -102.499647];
Zipcodes['80825'] = [38.833241, -102.827694];
Zipcodes['80826'] = [39.218744, -103.691289];
Zipcodes['80827'] = [39.056483, -105.467359];
Zipcodes['80828'] = [39.264079, -103.704966];
Zipcodes['80829'] = [38.829101, -104.932973];
Zipcodes['80830'] = [39.114217, -103.912818];
Zipcodes['80831'] = [38.981118, -104.499945];
Zipcodes['80832'] = [38.896512, -103.766539];
Zipcodes['80833'] = [38.763233, -103.96509];
Zipcodes['80834'] = [39.296552, -102.880716];
Zipcodes['80835'] = [39.218796, -104.022559];
Zipcodes['80836'] = [39.303192, -102.582488];
Zipcodes['80840'] = [39.000068, -104.864468];
Zipcodes['80860'] = [38.700164, -105.097304];
Zipcodes['80861'] = [39.323701, -102.771452];
Zipcodes['80862'] = [38.922172, -103.058029];
Zipcodes['80863'] = [39.032718, -105.054396];
Zipcodes['80864'] = [38.699006, -104.187552];
Zipcodes['80902'] = [38.683656, -104.82224];
Zipcodes['80903'] = [38.832791, -104.813816];
Zipcodes['80904'] = [38.862565, -104.87047];
Zipcodes['80905'] = [38.81891, -104.838348];
Zipcodes['80906'] = [38.752594, -104.878211];
Zipcodes['80907'] = [38.878994, -104.821148];
Zipcodes['80908'] = [39.047585, -104.69054];
Zipcodes['80909'] = [38.852566, -104.774326];
Zipcodes['80910'] = [38.812066, -104.77458];
Zipcodes['80911'] = [38.752825, -104.719732];
Zipcodes['80913'] = [38.687426, -104.748914];
Zipcodes['80914'] = [38.824014, -104.705448];
Zipcodes['80915'] = [38.849836, -104.716971];
Zipcodes['80916'] = [38.802461, -104.708714];
Zipcodes['80917'] = [38.886837, -104.74047];
Zipcodes['80918'] = [38.910393, -104.780362];
Zipcodes['80919'] = [38.929852, -104.878821];
Zipcodes['80920'] = [38.955706, -104.772275];
Zipcodes['80921'] = [39.060887, -104.819136];
Zipcodes['80922'] = [38.88995, -104.700832];
Zipcodes['80923'] = [38.926735, -104.714341];
Zipcodes['80924'] = [38.966933, -104.723123];
Zipcodes['80925'] = [38.77004, -104.643867];
Zipcodes['80926'] = [38.634719, -104.902972];
Zipcodes['80927'] = [38.9283, -104.658773];
Zipcodes['80928'] = [38.630401, -104.401637];
Zipcodes['80929'] = [38.824594, -104.608368];
Zipcodes['80930'] = [38.805059, -104.506438];
Zipcodes['80938'] = [38.906503, -104.656877];
Zipcodes['80939'] = [38.872785, -104.675754];
Zipcodes['80951'] = [38.878533, -104.657577];
Zipcodes['81001'] = [38.295329, -104.537238];
Zipcodes['81003'] = [38.277652, -104.656426];
Zipcodes['81004'] = [38.044054, -104.71441];
Zipcodes['81005'] = [38.212315, -104.831773];
Zipcodes['81006'] = [38.239909, -104.477851];
Zipcodes['81007'] = [38.385809, -104.795882];
Zipcodes['81008'] = [38.405766, -104.57872];
Zipcodes['81019'] = [37.942304, -104.835772];
Zipcodes['81020'] = [37.465135, -104.609994];
Zipcodes['81021'] = [38.419198, -103.376492];
Zipcodes['81022'] = [38.024844, -104.434721];
Zipcodes['81023'] = [38.066322, -104.926481];
Zipcodes['81024'] = [37.229315, -104.711619];
Zipcodes['81025'] = [38.455047, -104.355296];
Zipcodes['81027'] = [37.246664, -103.723169];
Zipcodes['81029'] = [37.082909, -102.508878];
Zipcodes['81030'] = [38.104859, -103.511496];
Zipcodes['81033'] = [38.19577, -103.855605];
Zipcodes['81036'] = [38.415124, -102.792829];
Zipcodes['81039'] = [37.93907, -104.085204];
Zipcodes['81040'] = [37.783222, -105.227208];
Zipcodes['81041'] = [37.852383, -102.397941];
Zipcodes['81043'] = [38.119748, -102.223113];
Zipcodes['81044'] = [37.859663, -102.947678];
Zipcodes['81045'] = [38.565411, -103.154447];
Zipcodes['81046'] = [37.295922, -104.368605];
Zipcodes['81047'] = [38.009822, -102.190671];
Zipcodes['81049'] = [37.304307, -103.389831];
Zipcodes['81050'] = [37.855707, -103.471739];
Zipcodes['81052'] = [37.974424, -102.638977];
Zipcodes['81054'] = [37.911665, -103.190484];
Zipcodes['81055'] = [37.430009, -105.104414];
Zipcodes['81057'] = [38.123016, -102.901912];
Zipcodes['81058'] = [38.10851, -103.886689];
Zipcodes['81059'] = [37.563258, -104.001465];
Zipcodes['81062'] = [38.29689, -103.964339];
Zipcodes['81063'] = [38.43071, -103.77106];
Zipcodes['81064'] = [37.224707, -102.999283];
Zipcodes['81067'] = [38.01618, -103.725034];
Zipcodes['81069'] = [37.891792, -104.889437];
Zipcodes['81071'] = [38.433234, -102.260787];
Zipcodes['81073'] = [37.425411, -102.705136];
Zipcodes['81076'] = [38.343221, -103.601595];
Zipcodes['81077'] = [38.019499, -103.613443];
Zipcodes['81081'] = [37.065471, -104.155847];
Zipcodes['81082'] = [37.185365, -104.413603];
Zipcodes['81084'] = [37.564028, -102.428082];
Zipcodes['81087'] = [37.391644, -102.412798];
Zipcodes['81089'] = [37.630413, -104.674625];
Zipcodes['81090'] = [37.365902, -102.230461];
Zipcodes['81091'] = [37.14909, -104.964703];
Zipcodes['81092'] = [38.215278, -102.908255];
Zipcodes['81101'] = [37.469311, -105.85273];
Zipcodes['81120'] = [37.195313, -106.34935];
Zipcodes['81121'] = [37.016916, -107.419824];
Zipcodes['81122'] = [37.301809, -107.537448];
Zipcodes['81123'] = [37.411212, -105.56571];
Zipcodes['81124'] = [37.285443, -106.106869];
Zipcodes['81125'] = [37.819261, -106.069857];
Zipcodes['81126'] = [37.161451, -105.347251];
Zipcodes['81128'] = [37.079692, -106.620963];
Zipcodes['81129'] = [37.104955, -106.021658];
Zipcodes['81130'] = [37.701575, -107.096886];
Zipcodes['81131'] = [37.865412, -105.679141];
Zipcodes['81132'] = [37.714226, -106.416175];
Zipcodes['81133'] = [37.45001, -105.32842];
Zipcodes['81136'] = [37.71951, -105.813477];
Zipcodes['81137'] = [37.051911, -107.613601];
Zipcodes['81138'] = [37.023023, -105.627388];
Zipcodes['81140'] = [37.299115, -106.161365];
Zipcodes['81141'] = [37.16141, -105.893647];
Zipcodes['81143'] = [38.158406, -105.849977];
Zipcodes['81144'] = [37.553535, -106.139482];
Zipcodes['81146'] = [37.646122, -105.668323];
Zipcodes['81147'] = [37.286627, -107.085416];
Zipcodes['81148'] = [37.171822, -105.985415];
Zipcodes['81149'] = [38.100089, -106.346144];
Zipcodes['81151'] = [37.239069, -105.711645];
Zipcodes['81152'] = [37.134542, -105.395987];
Zipcodes['81154'] = [37.634046, -106.667927];
Zipcodes['81155'] = [38.28645, -106.065517];
Zipcodes['81201'] = [38.555478, -106.107468];
Zipcodes['81210'] = [38.808683, -106.677952];
Zipcodes['81211'] = [38.972891, -106.380522];
Zipcodes['81212'] = [38.542383, -105.423488];
Zipcodes['81220'] = [38.297911, -107.477679];
Zipcodes['81221'] = [38.362378, -105.14243];
Zipcodes['81222'] = [38.354894, -105.816135];
Zipcodes['81223'] = [38.364528, -105.612647];
Zipcodes['81224'] = [38.880077, -106.932779];
Zipcodes['81225'] = [38.962294, -107.016317];
Zipcodes['81226'] = [38.337906, -105.089415];
Zipcodes['81227'] = [38.549326, -106.2901];
Zipcodes['81228'] = [39.031633, -106.27699];
Zipcodes['81230'] = [38.395065, -106.953683];
Zipcodes['81232'] = [38.27535, -105.609007];
Zipcodes['81233'] = [38.492323, -105.813776];
Zipcodes['81235'] = [37.965061, -107.405174];
Zipcodes['81236'] = [38.676257, -106.263703];
Zipcodes['81237'] = [38.616312, -106.621254];
Zipcodes['81239'] = [38.536682, -106.608252];
Zipcodes['81240'] = [38.500352, -105.034304];
Zipcodes['81241'] = [38.638258, -106.490872];
Zipcodes['81242'] = [38.471839, -106.072905];
Zipcodes['81243'] = [38.265999, -107.192094];
Zipcodes['81244'] = [38.36392, -105.164927];
Zipcodes['81248'] = [38.419207, -106.500053];
Zipcodes['81251'] = [39.142548, -106.437579];
Zipcodes['81252'] = [38.110643, -105.427521];
Zipcodes['81253'] = [38.169467, -105.066493];
Zipcodes['81301'] = [37.529427, -107.821502];
Zipcodes['81303'] = [37.133408, -107.843803];
Zipcodes['81320'] = [37.720141, -108.735147];
Zipcodes['81321'] = [37.31297, -108.734537];
Zipcodes['81323'] = [37.702727, -108.126639];
Zipcodes['81324'] = [37.641979, -109.009593];
Zipcodes['81325'] = [37.919329, -108.712763];
Zipcodes['81326'] = [37.145589, -108.116569];
Zipcodes['81327'] = [37.516777, -108.656346];
Zipcodes['81328'] = [37.270759, -108.329659];
Zipcodes['81330'] = [37.234851, -108.478286];
Zipcodes['81331'] = [37.445868, -108.907291];
Zipcodes['81332'] = [37.700344, -107.991227];
Zipcodes['81334'] = [37.146129, -108.61295];
Zipcodes['81335'] = [37.543801, -108.719791];
Zipcodes['81401'] = [38.514973, -107.766148];
Zipcodes['81403'] = [38.314564, -107.92836];
Zipcodes['81410'] = [38.80969, -107.976469];
Zipcodes['81411'] = [38.251835, -108.97886];
Zipcodes['81413'] = [38.975741, -107.899073];
Zipcodes['81415'] = [38.625512, -107.627585];
Zipcodes['81416'] = [38.640468, -108.254583];
Zipcodes['81418'] = [38.899354, -108.05804];
Zipcodes['81419'] = [38.883071, -107.747726];
Zipcodes['81422'] = [38.320239, -108.70826];
Zipcodes['81423'] = [38.009319, -108.427263];
Zipcodes['81424'] = [38.345022, -108.502319];
Zipcodes['81425'] = [38.458292, -108.118672];
Zipcodes['81426'] = [37.866128, -107.820786];
Zipcodes['81427'] = [38.025834, -107.609747];
Zipcodes['81428'] = [38.962987, -107.599578];
Zipcodes['81429'] = [38.37922, -108.882861];
Zipcodes['81430'] = [38.053745, -108.067189];
Zipcodes['81431'] = [38.143531, -108.514118];
Zipcodes['81432'] = [38.129867, -107.745344];
Zipcodes['81433'] = [37.722006, -107.579393];
Zipcodes['81434'] = [38.981711, -107.43059];
Zipcodes['81435'] = [37.868751, -107.925117];
Zipcodes['81501'] = [39.071844, -108.546];
Zipcodes['81503'] = [39.034026, -108.486598];
Zipcodes['81504'] = [39.120298, -108.488532];
Zipcodes['81505'] = [39.214122, -108.588404];
Zipcodes['81506'] = [39.124877, -108.558662];
Zipcodes['81507'] = [39.009885, -108.651873];
Zipcodes['81520'] = [39.111083, -108.433844];
Zipcodes['81521'] = [39.156637, -108.878833];
Zipcodes['81522'] = [38.685456, -108.921083];
Zipcodes['81523'] = [38.924718, -108.889477];
Zipcodes['81524'] = [39.290535, -108.732843];
Zipcodes['81525'] = [39.259524, -108.967298];
Zipcodes['81526'] = [39.083037, -108.345615];
Zipcodes['81527'] = [38.798468, -108.469982];
Zipcodes['81601'] = [39.587562, -107.338712];
Zipcodes['81610'] = [40.289242, -108.842569];
Zipcodes['81611'] = [39.158173, -106.668632];
Zipcodes['81612'] = [39.051104, -106.845014];
Zipcodes['81615'] = [39.213371, -106.942734];
Zipcodes['81620'] = [39.61303, -106.503056];
Zipcodes['81621'] = [39.37811, -106.87636];
Zipcodes['81623'] = [39.246027, -107.162912];
Zipcodes['81624'] = [39.159217, -107.745149];
Zipcodes['81625'] = [40.704673, -107.736157];
Zipcodes['81630'] = [39.45801, -108.56434];
Zipcodes['81631'] = [39.626454, -106.771255];
Zipcodes['81632'] = [39.622675, -106.607079];
Zipcodes['81633'] = [40.387427, -108.46898];
Zipcodes['81635'] = [39.535315, -108.066676];
Zipcodes['81637'] = [39.777826, -107.105814];
Zipcodes['81638'] = [40.300403, -107.642739];
Zipcodes['81639'] = [40.564993, -107.246656];
Zipcodes['81640'] = [40.668299, -108.514046];
Zipcodes['81641'] = [40.109264, -107.818214];
Zipcodes['81642'] = [39.312809, -106.567699];
Zipcodes['81643'] = [39.124092, -108.149294];
Zipcodes['81645'] = [39.455587, -106.458361];
Zipcodes['81646'] = [39.1044, -108.005195];
Zipcodes['81647'] = [39.579088, -107.532937];
Zipcodes['81648'] = [39.904489, -108.791994];
Zipcodes['81649'] = [39.484255, -106.291641];
Zipcodes['81650'] = [39.751037, -108.113722];
Zipcodes['81652'] = [39.466243, -107.673259];
Zipcodes['81653'] = [40.895748, -107.24223];
Zipcodes['81654'] = [39.205565, -107.045797];
Zipcodes['81655'] = [39.753051, -106.685878];
Zipcodes['81656'] = [39.279456, -106.7871];
Zipcodes['81657'] = [39.649325, -106.325837];
Zipcodes['82001'] = [41.100082, -104.924884];
Zipcodes['82005'] = [41.146344, -104.867879];
Zipcodes['82007'] = [41.084121, -104.683151];
Zipcodes['82009'] = [41.383479, -104.853477];
Zipcodes['82050'] = [41.446986, -104.266331];
Zipcodes['82051'] = [41.603073, -105.648828];
Zipcodes['82052'] = [41.120008, -105.346553];
Zipcodes['82053'] = [41.240864, -104.340467];
Zipcodes['82054'] = [41.064273, -104.36052];
Zipcodes['82055'] = [41.370798, -106.256463];
Zipcodes['82058'] = [42.153086, -105.608898];
Zipcodes['82059'] = [41.053423, -105.178649];
Zipcodes['82060'] = [41.28812, -104.497508];
Zipcodes['82061'] = [41.492118, -105.143201];
Zipcodes['82063'] = [40.770156, -105.970518];
Zipcodes['82070'] = [41.219644, -105.844164];
Zipcodes['82072'] = [41.427295, -105.550267];
Zipcodes['82073'] = [41.174864, -105.437857];
Zipcodes['82081'] = [41.533638, -104.48082];
Zipcodes['82082'] = [41.264882, -104.090072];
Zipcodes['82083'] = [41.724667, -105.990857];
Zipcodes['82084'] = [41.050832, -105.515933];
Zipcodes['82190'] = [44.519967, -110.649087];
Zipcodes['82201'] = [42.001919, -105.129783];
Zipcodes['82210'] = [41.711626, -104.758303];
Zipcodes['82212'] = [42.261018, -104.535651];
Zipcodes['82213'] = [42.494688, -105.004445];
Zipcodes['82214'] = [42.264246, -104.751287];
Zipcodes['82215'] = [42.466858, -104.70063];
Zipcodes['82217'] = [41.759812, -104.139524];
Zipcodes['82218'] = [41.936235, -104.140039];
Zipcodes['82219'] = [42.477914, -104.483044];
Zipcodes['82221'] = [41.638038, -104.328472];
Zipcodes['82222'] = [43.167545, -104.646704];
Zipcodes['82223'] = [42.12379, -104.389798];
Zipcodes['82224'] = [42.815996, -104.919629];
Zipcodes['82225'] = [42.961282, -104.319456];
Zipcodes['82227'] = [42.717565, -104.730863];
Zipcodes['82229'] = [42.877643, -104.985904];
Zipcodes['82240'] = [42.159346, -104.131944];
Zipcodes['82242'] = [42.647281, -104.136182];
Zipcodes['82243'] = [41.991964, -104.409516];
Zipcodes['82244'] = [41.897536, -104.439041];
Zipcodes['82301'] = [41.983168, -107.478998];
Zipcodes['82310'] = [42.445655, -107.877513];
Zipcodes['82321'] = [41.092985, -107.653534];
Zipcodes['82322'] = [42.24648, -107.548191];
Zipcodes['82323'] = [41.029544, -107.520699];
Zipcodes['82324'] = [41.66053, -106.430211];
Zipcodes['82325'] = [41.152307, -106.785328];
Zipcodes['82327'] = [42.141638, -106.630301];
Zipcodes['82329'] = [42.042894, -106.106129];
Zipcodes['82331'] = [41.399861, -106.819184];
Zipcodes['82332'] = [41.064102, -107.301653];
Zipcodes['82334'] = [41.953662, -106.977324];
Zipcodes['82335'] = [41.761698, -106.825523];
Zipcodes['82336'] = [41.698283, -108.208018];
Zipcodes['82401'] = [44.011214, -108.169952];
Zipcodes['82410'] = [44.351803, -108.155391];
Zipcodes['82411'] = [44.449929, -108.476582];
Zipcodes['82412'] = [44.790361, -108.553046];
Zipcodes['82414'] = [44.403789, -109.606717];
Zipcodes['82420'] = [44.918902, -108.464543];
Zipcodes['82421'] = [44.886983, -108.60215];
Zipcodes['82422'] = [44.49769, -108.379467];
Zipcodes['82423'] = [44.987313, -108.587815];
Zipcodes['82426'] = [44.516609, -108.036197];
Zipcodes['82428'] = [44.298594, -107.590462];
Zipcodes['82430'] = [43.804187, -108.17914];
Zipcodes['82431'] = [44.871763, -108.157894];
Zipcodes['82432'] = [44.214963, -107.847847];
Zipcodes['82433'] = [44.062254, -109.104718];
Zipcodes['82434'] = [44.345688, -108.295425];
Zipcodes['82435'] = [44.819966, -109.051004];
Zipcodes['82440'] = [44.717507, -108.867737];
Zipcodes['82441'] = [44.551786, -107.61138];
Zipcodes['82442'] = [43.914059, -107.33342];
Zipcodes['82443'] = [43.717103, -108.475221];
Zipcodes['82450'] = [44.50397, -109.433833];
Zipcodes['82501'] = [43.027802, -108.237888];
Zipcodes['82510'] = [42.999206, -108.60909];
Zipcodes['82512'] = [43.030418, -109.244958];
Zipcodes['82513'] = [43.627771, -109.629169];
Zipcodes['82514'] = [43.066578, -109.023081];
Zipcodes['82515'] = [42.897138, -108.566831];
Zipcodes['82516'] = [43.212968, -108.827654];
Zipcodes['82520'] = [42.661426, -108.638471];
Zipcodes['82523'] = [43.410909, -108.854483];
Zipcodes['82601'] = [43.069925, -106.341585];
Zipcodes['82604'] = [42.914918, -106.707293];
Zipcodes['82609'] = [42.771582, -106.186063];
Zipcodes['82620'] = [42.538666, -107.064701];
Zipcodes['82630'] = [43.329656, -107.307406];
Zipcodes['82633'] = [43.022149, -105.39185];
Zipcodes['82635'] = [43.421808, -106.21834];
Zipcodes['82636'] = [42.828794, -106.158007];
Zipcodes['82637'] = [42.760002, -105.857058];
Zipcodes['82638'] = [43.058737, -107.254284];
Zipcodes['82639'] = [43.738005, -106.695553];
Zipcodes['82640'] = [43.564866, -106.129374];
Zipcodes['82642'] = [43.278653, -107.660846];
Zipcodes['82643'] = [43.422337, -106.278957];
Zipcodes['82644'] = [42.842884, -106.373906];
Zipcodes['82646'] = [42.977645, -106.768219];
Zipcodes['82648'] = [43.097998, -106.915896];
Zipcodes['82649'] = [43.278998, -108.01296];
Zipcodes['82701'] = [43.69408, -104.458957];
Zipcodes['82710'] = [44.693122, -104.306556];
Zipcodes['82711'] = [44.711467, -104.480627];
Zipcodes['82712'] = [44.525837, -104.123779];
Zipcodes['82714'] = [44.551895, -104.680045];
Zipcodes['82716'] = [44.53546, -105.642705];
Zipcodes['82718'] = [43.92249, -105.608852];
Zipcodes['82720'] = [44.795996, -104.670545];
Zipcodes['82721'] = [44.455642, -104.898384];
Zipcodes['82723'] = [43.991936, -104.430623];
Zipcodes['82725'] = [44.876792, -105.702593];
Zipcodes['82727'] = [44.334992, -105.201469];
Zipcodes['82729'] = [44.371182, -104.359823];
Zipcodes['82730'] = [44.044891, -104.714289];
Zipcodes['82731'] = [44.800584, -105.223923];
Zipcodes['82732'] = [43.723972, -105.582726];
Zipcodes['82801'] = [44.832499, -106.896325];
Zipcodes['82831'] = [44.668518, -106.041629];
Zipcodes['82832'] = [44.605821, -106.764278];
Zipcodes['82833'] = [44.605323, -107.162982];
Zipcodes['82834'] = [44.260573, -106.731065];
Zipcodes['82835'] = [44.756041, -106.359287];
Zipcodes['82836'] = [44.822519, -107.608741];
Zipcodes['82837'] = [44.726993, -106.277909];
Zipcodes['82838'] = [44.918115, -107.462869];
Zipcodes['82839'] = [44.960639, -107.171384];
Zipcodes['82842'] = [44.571412, -106.939501];
Zipcodes['82844'] = [44.806077, -107.195736];
Zipcodes['82845'] = [44.758205, -106.731271];
Zipcodes['82901'] = [41.379272, -108.978176];
Zipcodes['82922'] = [43.142442, -110.45682];
Zipcodes['82923'] = [42.614983, -109.406742];
Zipcodes['82925'] = [43.168041, -109.784367];
Zipcodes['82929'] = [41.549363, -109.884876];
Zipcodes['82930'] = [40.958206, -110.607498];
Zipcodes['82932'] = [42.124393, -109.385213];
Zipcodes['82933'] = [41.269258, -110.497834];
Zipcodes['82934'] = [41.612466, -109.995644];
Zipcodes['82935'] = [41.677722, -109.659962];
Zipcodes['82936'] = [41.054019, -110.180295];
Zipcodes['82937'] = [41.34708, -110.264865];
Zipcodes['82938'] = [41.094299, -109.897385];
Zipcodes['82939'] = [41.218922, -110.345567];
Zipcodes['82941'] = [42.933762, -109.690898];
Zipcodes['82942'] = [41.699569, -108.765057];
Zipcodes['82943'] = [41.70196, -109.183982];
Zipcodes['82944'] = [41.129601, -110.49642];
Zipcodes['82945'] = [41.720087, -108.938903];
Zipcodes['83001'] = [43.394562, -110.629831];
Zipcodes['83011'] = [43.590313, -110.532466];
Zipcodes['83012'] = [43.715608, -110.807167];
Zipcodes['83013'] = [44.048662, -110.426087];
Zipcodes['83014'] = [43.448603, -110.966859];
Zipcodes['83025'] = [43.592022, -110.825985];
Zipcodes['83101'] = [41.976485, -110.54741];
Zipcodes['83110'] = [42.725902, -110.849219];
Zipcodes['83111'] = [42.818977, -111.011357];
Zipcodes['83112'] = [42.874872, -110.84907];
Zipcodes['83113'] = [42.5542, -110.267543];
Zipcodes['83114'] = [42.10037, -110.959785];
Zipcodes['83115'] = [42.911509, -110.238712];
Zipcodes['83116'] = [41.759056, -110.549109];
Zipcodes['83118'] = [43.047804, -110.92798];
Zipcodes['83119'] = [42.643912, -110.988218];
Zipcodes['83120'] = [43.109389, -111.138878];
Zipcodes['83121'] = [41.849863, -110.540299];
Zipcodes['83122'] = [42.821735, -110.958386];
Zipcodes['83123'] = [42.236912, -110.268354];
Zipcodes['83124'] = [41.758808, -110.31588];
Zipcodes['83126'] = [42.581108, -110.904209];
Zipcodes['83127'] = [42.918972, -110.997753];
Zipcodes['83128'] = [43.040079, -110.722208];
Zipcodes['83201'] = [42.90602, -112.373628];
Zipcodes['83202'] = [42.968171, -112.364866];
Zipcodes['83203'] = [43.025242, -112.428141];
Zipcodes['83204'] = [42.805005, -112.523931];
Zipcodes['83209'] = [42.857816, -112.426196];
Zipcodes['83210'] = [43.020349, -112.869167];
Zipcodes['83211'] = [42.655458, -113.076465];
Zipcodes['83212'] = [42.505985, -112.54789];
Zipcodes['83213'] = [43.443926, -113.364262];
Zipcodes['83214'] = [42.539846, -112.307675];
Zipcodes['83215'] = [43.443135, -112.812428];
Zipcodes['83217'] = [42.769278, -111.927009];
Zipcodes['83218'] = [43.318029, -112.164538];
Zipcodes['83220'] = [42.344881, -111.382503];
Zipcodes['83221'] = [43.294732, -112.512503];
Zipcodes['83223'] = [42.183103, -111.404309];
Zipcodes['83226'] = [44.372441, -114.009103];
Zipcodes['83227'] = [44.158678, -114.495906];
Zipcodes['83228'] = [42.233621, -112.06023];
Zipcodes['83232'] = [42.134701, -111.971027];
Zipcodes['83233'] = [42.135042, -111.237956];
Zipcodes['83234'] = [42.422608, -112.101232];
Zipcodes['83235'] = [44.618424, -114.053247];
Zipcodes['83236'] = [43.191095, -111.872456];
Zipcodes['83237'] = [42.027452, -111.778558];
Zipcodes['83238'] = [42.381236, -111.099924];
Zipcodes['83239'] = [42.529656, -111.357857];
Zipcodes['83241'] = [42.514851, -111.765362];
Zipcodes['83243'] = [42.232852, -112.665036];
Zipcodes['83244'] = [43.902492, -112.951366];
Zipcodes['83245'] = [42.872883, -112.184887];
Zipcodes['83246'] = [42.605661, -112.031259];
Zipcodes['83250'] = [42.693011, -112.213168];
Zipcodes['83251'] = [43.944697, -113.944659];
Zipcodes['83252'] = [42.174414, -112.406564];
Zipcodes['83253'] = [44.535798, -113.754846];
Zipcodes['83254'] = [42.354653, -111.239581];
Zipcodes['83255'] = [43.711763, -113.459371];
Zipcodes['83261'] = [42.220389, -111.405131];
Zipcodes['83262'] = [43.216953, -112.844421];
Zipcodes['83263'] = [42.181249, -111.753213];
Zipcodes['83271'] = [42.442204, -112.91278];
Zipcodes['83272'] = [42.094673, -111.382157];
Zipcodes['83274'] = [43.348834, -112.097983];
Zipcodes['83276'] = [42.755078, -111.436316];
Zipcodes['83277'] = [43.074569, -112.681207];
Zipcodes['83278'] = [44.336299, -114.813696];
Zipcodes['83281'] = [42.309016, -111.959392];
Zipcodes['83283'] = [42.357766, -111.667812];
Zipcodes['83285'] = [43.072385, -111.303013];
Zipcodes['83286'] = [42.063423, -112.015748];
Zipcodes['83287'] = [42.021675, -111.457289];
Zipcodes['83301'] = [42.400555, -114.524876];
Zipcodes['83302'] = [42.063779, -114.733505];
Zipcodes['83311'] = [42.407951, -113.560796];
Zipcodes['83312'] = [42.113303, -113.636354];
Zipcodes['83313'] = [43.348356, -114.1992];
Zipcodes['83314'] = [42.971598, -114.948649];
Zipcodes['83316'] = [42.625527, -114.8828];
Zipcodes['83318'] = [42.439675, -113.815864];
Zipcodes['83320'] = [43.404258, -113.889395];
Zipcodes['83321'] = [42.345625, -114.847442];
Zipcodes['83322'] = [43.316817, -114.966954];
Zipcodes['83323'] = [42.474682, -113.449466];
Zipcodes['83324'] = [42.871953, -114.262189];
Zipcodes['83325'] = [42.589607, -114.236404];
Zipcodes['83327'] = [43.502552, -114.772135];
Zipcodes['83328'] = [42.570241, -114.616281];
Zipcodes['83330'] = [42.941821, -114.694121];
Zipcodes['83332'] = [42.797531, -114.928868];
Zipcodes['83333'] = [43.571854, -114.24087];
Zipcodes['83334'] = [42.42108, -114.292723];
Zipcodes['83335'] = [42.582942, -114.073821];
Zipcodes['83336'] = [42.56468, -113.82465];
Zipcodes['83337'] = [43.224071, -115.152058];
Zipcodes['83338'] = [42.72853, -114.432126];
Zipcodes['83340'] = [43.736102, -114.582188];
Zipcodes['83341'] = [42.435607, -114.374711];
Zipcodes['83342'] = [42.144967, -113.245397];
Zipcodes['83344'] = [42.411167, -114.132506];
Zipcodes['83346'] = [42.195629, -113.903113];
Zipcodes['83347'] = [42.654874, -113.794029];
Zipcodes['83348'] = [43.281182, -114.12129];
Zipcodes['83349'] = [43.110594, -114.196305];
Zipcodes['83350'] = [42.710955, -113.601781];
Zipcodes['83352'] = [43.050625, -114.412947];
Zipcodes['83353'] = [43.69066, -114.331612];
Zipcodes['83354'] = [43.670143, -114.32231];
Zipcodes['83355'] = [42.757435, -114.722206];
Zipcodes['83401'] = [43.542409, -111.877936];
Zipcodes['83402'] = [43.526347, -112.182045];
Zipcodes['83404'] = [43.426779, -112.015502];
Zipcodes['83406'] = [43.439076, -111.933623];
Zipcodes['83414'] = [43.861439, -110.935725];
Zipcodes['83420'] = [44.059467, -111.324799];
Zipcodes['83421'] = [44.001893, -111.547349];
Zipcodes['83422'] = [43.689055, -111.301074];
Zipcodes['83423'] = [44.283954, -112.302167];
Zipcodes['83424'] = [43.901882, -111.19763];
Zipcodes['83425'] = [43.932034, -112.224678];
Zipcodes['83427'] = [43.357538, -111.753549];
Zipcodes['83428'] = [43.392652, -111.120168];
Zipcodes['83429'] = [44.481672, -111.375346];
Zipcodes['83431'] = [43.688427, -112.032935];
Zipcodes['83433'] = [44.492859, -111.317901];
Zipcodes['83434'] = [43.756386, -112.006611];
Zipcodes['83435'] = [43.978916, -112.586433];
Zipcodes['83436'] = [43.866825, -111.455085];
Zipcodes['83438'] = [43.959665, -111.759258];
Zipcodes['83440'] = [43.808572, -111.80862];
Zipcodes['83442'] = [43.628711, -111.834027];
Zipcodes['83443'] = [43.530905, -111.545848];
Zipcodes['83444'] = [43.719372, -112.301574];
Zipcodes['83445'] = [44.037765, -111.74528];
Zipcodes['83446'] = [44.36515, -112.212801];
Zipcodes['83448'] = [43.84955, -111.711682];
Zipcodes['83449'] = [43.426475, -111.357387];
Zipcodes['83450'] = [43.844949, -112.395676];
Zipcodes['83451'] = [43.866555, -111.633321];
Zipcodes['83452'] = [43.770111, -111.329232];
Zipcodes['83454'] = [43.594581, -111.956873];
Zipcodes['83455'] = [43.605032, -111.135365];
Zipcodes['83460'] = [43.816379, -111.782607];
Zipcodes['83462'] = [45.300529, -113.817801];
Zipcodes['83463'] = [45.56955, -113.975467];
Zipcodes['83464'] = [44.480616, -113.198582];
Zipcodes['83465'] = [44.79274, -113.672383];
Zipcodes['83466'] = [45.418061, -114.036232];
Zipcodes['83467'] = [45.042699, -113.856993];
Zipcodes['83468'] = [44.922252, -113.592213];
Zipcodes['83469'] = [45.069679, -114.408056];
Zipcodes['83501'] = [46.251696, -116.925017];
Zipcodes['83520'] = [46.544148, -116.326467];
Zipcodes['83522'] = [45.913366, -116.473604];
Zipcodes['83523'] = [46.227361, -116.46752];
Zipcodes['83524'] = [46.351686, -116.642415];
Zipcodes['83525'] = [45.740081, -115.393231];
Zipcodes['83526'] = [46.147763, -116.412071];
Zipcodes['83530'] = [45.91803, -116.021573];
Zipcodes['83533'] = [46.115669, -116.240833];
Zipcodes['83535'] = [46.535362, -116.736279];
Zipcodes['83536'] = [46.212969, -116.022672];
Zipcodes['83537'] = [46.63317, -116.529463];
Zipcodes['83539'] = [46.256824, -114.846627];
Zipcodes['83540'] = [46.345204, -116.782679];
Zipcodes['83541'] = [46.496171, -116.500541];
Zipcodes['83542'] = [45.561255, -116.32483];
Zipcodes['83543'] = [46.273201, -116.23343];
Zipcodes['83544'] = [46.490917, -116.151412];
Zipcodes['83545'] = [46.426424, -116.421793];
Zipcodes['83546'] = [46.573071, -115.791519];
Zipcodes['83547'] = [45.308337, -116.344568];
Zipcodes['83548'] = [46.336718, -116.507654];
Zipcodes['83549'] = [45.361765, -116.266593];
Zipcodes['83552'] = [45.983939, -115.923312];
Zipcodes['83553'] = [46.393409, -115.897468];
Zipcodes['83554'] = [45.688206, -116.269206];
Zipcodes['83555'] = [46.076618, -116.716222];
Zipcodes['83602'] = [44.091006, -116.146459];
Zipcodes['83604'] = [42.747481, -115.681461];
Zipcodes['83605'] = [43.664057, -116.651729];
Zipcodes['83607'] = [43.710191, -116.73763];
Zipcodes['83610'] = [44.743366, -116.77357];
Zipcodes['83611'] = [45.343874, -115.052335];
Zipcodes['83612'] = [44.849742, -116.534073];
Zipcodes['83615'] = [44.705694, -116.042463];
Zipcodes['83616'] = [43.775558, -116.394954];
Zipcodes['83617'] = [43.936819, -116.505193];
Zipcodes['83619'] = [43.969273, -116.911632];
Zipcodes['83622'] = [44.101355, -115.884587];
Zipcodes['83623'] = [43.041564, -115.400212];
Zipcodes['83624'] = [42.924882, -116.148521];
Zipcodes['83626'] = [43.667568, -116.82535];
Zipcodes['83627'] = [42.913544, -115.537294];
Zipcodes['83628'] = [43.577972, -116.965309];
Zipcodes['83629'] = [43.911871, -116.130418];
Zipcodes['83631'] = [43.884695, -115.777315];
Zipcodes['83632'] = [44.527024, -116.408436];
Zipcodes['83633'] = [43.045206, -115.198933];
Zipcodes['83634'] = [43.446499, -116.32771];
Zipcodes['83636'] = [43.906938, -116.659563];
Zipcodes['83637'] = [44.103916, -115.155157];
Zipcodes['83638'] = [45.123654, -115.714849];
Zipcodes['83639'] = [43.399579, -116.89948];
Zipcodes['83641'] = [43.350004, -116.635708];
Zipcodes['83642'] = [43.573507, -116.401064];
Zipcodes['83643'] = [44.629195, -116.456067];
Zipcodes['83644'] = [43.747154, -116.584107];
Zipcodes['83645'] = [44.337832, -116.576452];
Zipcodes['83646'] = [43.649585, -116.431758];
Zipcodes['83647'] = [43.400748, -115.522903];
Zipcodes['83648'] = [43.049186, -115.86586];
Zipcodes['83650'] = [42.896316, -116.664341];
Zipcodes['83651'] = [43.586036, -116.627262];
Zipcodes['83654'] = [45.233872, -116.245041];
Zipcodes['83655'] = [43.951249, -116.788488];
Zipcodes['83656'] = [43.724975, -116.795398];
Zipcodes['83657'] = [44.236331, -116.302013];
Zipcodes['83660'] = [43.801737, -116.92799];
Zipcodes['83661'] = [44.105708, -116.704889];
Zipcodes['83666'] = [43.961533, -115.973898];
Zipcodes['83669'] = [43.724752, -116.488562];
Zipcodes['83670'] = [44.018634, -116.312919];
Zipcodes['83672'] = [44.374148, -116.957099];
Zipcodes['83676'] = [43.656041, -116.907523];
Zipcodes['83677'] = [45.039515, -115.257998];
Zipcodes['83686'] = [43.498853, -116.604299];
Zipcodes['83687'] = [43.609972, -116.529149];
Zipcodes['83702'] = [43.665484, -116.14435];
Zipcodes['83703'] = [43.664123, -116.241473];
Zipcodes['83704'] = [43.627161, -116.287653];
Zipcodes['83705'] = [43.554774, -116.221422];
Zipcodes['83706'] = [43.591442, -116.194224];
Zipcodes['83709'] = [43.548356, -116.285218];
Zipcodes['83712'] = [43.617164, -116.093176];
Zipcodes['83713'] = [43.639, -116.33746];
Zipcodes['83714'] = [43.706504, -116.276717];
Zipcodes['83716'] = [43.875935, -115.160367];
Zipcodes['83720'] = [43.617707, -116.199731];
Zipcodes['83725'] = [43.604822, -116.205619];
Zipcodes['83801'] = [47.959008, -116.645781];
Zipcodes['83802'] = [47.277988, -115.61877];
Zipcodes['83803'] = [48.021094, -116.372648];
Zipcodes['83804'] = [48.045283, -116.971344];
Zipcodes['83805'] = [48.832637, -116.499445];
Zipcodes['83806'] = [46.877578, -116.374066];
Zipcodes['83808'] = [47.320639, -116.064352];
Zipcodes['83809'] = [48.062337, -116.581152];
Zipcodes['83810'] = [47.542489, -116.450803];
Zipcodes['83811'] = [48.110531, -116.159009];
Zipcodes['83812'] = [47.019444, -116.284061];
Zipcodes['83813'] = [48.115223, -116.675933];
Zipcodes['83814'] = [47.69066, -116.654917];
Zipcodes['83815'] = [47.726282, -116.789948];
Zipcodes['83821'] = [48.523392, -116.846526];
Zipcodes['83822'] = [48.171288, -116.999708];
Zipcodes['83823'] = [46.820946, -116.540911];
Zipcodes['83824'] = [47.10929, -116.943362];
Zipcodes['83825'] = [48.247679, -116.607832];
Zipcodes['83826'] = [48.983727, -116.151874];
Zipcodes['83827'] = [46.778344, -116.173678];
Zipcodes['83830'] = [47.078864, -116.352745];
Zipcodes['83832'] = [46.552294, -116.909567];
Zipcodes['83833'] = [47.525342, -116.743955];
Zipcodes['83834'] = [47.008809, -116.727499];
Zipcodes['83835'] = [47.79695, -116.660251];
Zipcodes['83836'] = [48.244312, -116.192345];
Zipcodes['83837'] = [47.528666, -116.090583];
Zipcodes['83839'] = [47.622772, -116.180527];
Zipcodes['83840'] = [48.312613, -116.515056];
Zipcodes['83841'] = [48.200063, -116.769838];
Zipcodes['83842'] = [47.444837, -116.546548];
Zipcodes['83843'] = [46.722245, -116.940158];
Zipcodes['83844'] = [46.728763, -117.019905];
Zipcodes['83845'] = [48.742885, -116.095552];
Zipcodes['83846'] = [47.485189, -115.734588];
Zipcodes['83847'] = [48.578903, -116.429984];
Zipcodes['83848'] = [48.72267, -116.914992];
Zipcodes['83849'] = [47.538098, -115.991959];
Zipcodes['83850'] = [47.443958, -116.207333];
Zipcodes['83851'] = [47.296344, -116.899761];
Zipcodes['83852'] = [48.317847, -116.533723];
Zipcodes['83854'] = [47.726224, -116.976167];
Zipcodes['83855'] = [46.994784, -116.888154];
Zipcodes['83856'] = [48.332471, -116.898893];
Zipcodes['83857'] = [46.886607, -116.811269];
Zipcodes['83858'] = [47.855304, -116.903837];
Zipcodes['83860'] = [48.180541, -116.536223];
Zipcodes['83861'] = [47.249038, -116.586899];
Zipcodes['83864'] = [48.4349, -116.504282];
Zipcodes['83866'] = [47.142833, -116.44001];
Zipcodes['83867'] = [47.506245, -115.946984];
Zipcodes['83868'] = [47.516331, -116.182813];
Zipcodes['83869'] = [47.982345, -116.892358];
Zipcodes['83870'] = [47.138599, -116.853614];
Zipcodes['83871'] = [46.7419, -116.742752];
Zipcodes['83872'] = [46.852373, -116.973137];
Zipcodes['83873'] = [47.582915, -115.843529];
Zipcodes['83874'] = [47.643153, -115.804985];
Zipcodes['83876'] = [47.455719, -116.948921];
Zipcodes['84001'] = [40.345248, -110.273487];
Zipcodes['84002'] = [40.431158, -110.288359];
Zipcodes['84003'] = [40.471654, -111.68059];
Zipcodes['84004'] = [40.488843, -111.727776];
Zipcodes['84005'] = [40.322736, -111.999426];
Zipcodes['84006'] = [40.596926, -112.1238];
Zipcodes['84007'] = [40.345116, -110.203186];
Zipcodes['84008'] = [40.03344, -109.193763];
Zipcodes['84009'] = [40.553454, -112.018129];
Zipcodes['84010'] = [40.873122, -111.835036];
Zipcodes['84013'] = [40.295983, -112.094474];
Zipcodes['84014'] = [40.936646, -111.833312];
Zipcodes['84015'] = [41.120356, -112.060775];
Zipcodes['84017'] = [40.956239, -111.214298];
Zipcodes['84018'] = [41.221884, -111.448409];
Zipcodes['84020'] = [40.497673, -111.866118];
Zipcodes['84021'] = [40.108226, -110.533757];
Zipcodes['84022'] = [40.350464, -112.640285];
Zipcodes['84023'] = [40.903341, -109.217325];
Zipcodes['84024'] = [41.007673, -111.447161];
Zipcodes['84025'] = [40.975812, -111.899335];
Zipcodes['84026'] = [40.294056, -109.845098];
Zipcodes['84027'] = [40.15519, -110.777931];
Zipcodes['84028'] = [41.937391, -111.389504];
Zipcodes['84029'] = [40.572887, -112.953253];
Zipcodes['84031'] = [40.650103, -110.656708];
Zipcodes['84032'] = [40.369309, -111.187015];
Zipcodes['84033'] = [40.979974, -111.49149];
Zipcodes['84034'] = [39.992371, -113.878933];
Zipcodes['84035'] = [40.408831, -109.292489];
Zipcodes['84036'] = [40.699219, -111.020425];
Zipcodes['84037'] = [41.02248, -111.948762];
Zipcodes['84038'] = [41.892017, -111.263545];
Zipcodes['84039'] = [40.440132, -109.812742];
Zipcodes['84040'] = [41.091695, -111.897185];
Zipcodes['84041'] = [41.069929, -111.981255];
Zipcodes['84042'] = [40.341125, -111.724706];
Zipcodes['84043'] = [40.411552, -111.87428];
Zipcodes['84044'] = [40.736085, -112.121213];
Zipcodes['84045'] = [40.292383, -111.928756];
Zipcodes['84046'] = [40.952558, -109.796276];
Zipcodes['84047'] = [40.615317, -111.891815];
Zipcodes['84049'] = [40.504123, -111.513399];
Zipcodes['84050'] = [41.024099, -111.664031];
Zipcodes['84051'] = [40.389463, -110.427015];
Zipcodes['84052'] = [40.166509, -109.997739];
Zipcodes['84053'] = [40.434984, -109.986655];
Zipcodes['84054'] = [40.838644, -111.91785];
Zipcodes['84055'] = [40.745066, -111.149113];
Zipcodes['84056'] = [41.129126, -111.99193];
Zipcodes['84057'] = [40.314256, -111.709495];
Zipcodes['84058'] = [40.274761, -111.714706];
Zipcodes['84059'] = [40.303539, -111.748581];
Zipcodes['84060'] = [40.644391, -111.506642];
Zipcodes['84061'] = [40.764039, -111.318822];
Zipcodes['84062'] = [40.383842, -111.681871];
Zipcodes['84063'] = [40.211814, -109.694218];
Zipcodes['84064'] = [41.682918, -111.112032];
Zipcodes['84065'] = [40.493936, -111.943725];
Zipcodes['84066'] = [40.346286, -110.067282];
Zipcodes['84067'] = [41.171885, -112.048033];
Zipcodes['84069'] = [40.365189, -112.485465];
Zipcodes['84070'] = [40.577114, -111.889106];
Zipcodes['84071'] = [40.353814, -112.267902];
Zipcodes['84072'] = [40.364041, -110.632985];
Zipcodes['84073'] = [40.345859, -110.41634];
Zipcodes['84074'] = [40.582074, -112.303592];
Zipcodes['84075'] = [41.056525, -112.069852];
Zipcodes['84076'] = [40.447153, -109.840165];
Zipcodes['84078'] = [40.465526, -109.510856];
Zipcodes['84080'] = [40.033229, -112.451892];
Zipcodes['84081'] = [40.602762, -112.038247];
Zipcodes['84082'] = [40.340345, -111.361662];
Zipcodes['84083'] = [40.817789, -113.739568];
Zipcodes['84084'] = [40.621392, -111.966054];
Zipcodes['84085'] = [40.578587, -109.924987];
Zipcodes['84086'] = [41.417628, -111.201226];
Zipcodes['84087'] = [40.889116, -111.92823];
Zipcodes['84088'] = [40.59507, -111.960812];
Zipcodes['84092'] = [40.551993, -111.7148];
Zipcodes['84093'] = [40.594711, -111.828542];
Zipcodes['84094'] = [40.571647, -111.861939];
Zipcodes['84095'] = [40.558566, -111.941674];
Zipcodes['84096'] = [40.487037, -112.099983];
Zipcodes['84097'] = [40.304692, -111.673174];
Zipcodes['84098'] = [40.746854, -111.532877];
Zipcodes['84101'] = [40.756311, -111.899962];
Zipcodes['84102'] = [40.75989, -111.863618];
Zipcodes['84103'] = [40.803408, -111.847108];
Zipcodes['84104'] = [40.749441, -111.981537];
Zipcodes['84105'] = [40.738346, -111.859829];
Zipcodes['84106'] = [40.706768, -111.855726];
Zipcodes['84107'] = [40.658017, -111.884519];
Zipcodes['84108'] = [40.80904, -111.727516];
Zipcodes['84109'] = [40.705812, -111.704544];
Zipcodes['84111'] = [40.755812, -111.884261];
Zipcodes['84112'] = [40.765254, -111.841362];
Zipcodes['84113'] = [40.762733, -111.832849];
Zipcodes['84114'] = [40.777946, -111.888256];
Zipcodes['84115'] = [40.714767, -111.892689];
Zipcodes['84116'] = [40.867238, -112.025095];
Zipcodes['84117'] = [40.66167, -111.835629];
Zipcodes['84118'] = [40.656001, -112.038127];
Zipcodes['84119'] = [40.700258, -111.946425];
Zipcodes['84120'] = [40.69686, -112.001744];
Zipcodes['84121'] = [40.616981, -111.686812];
Zipcodes['84123'] = [40.658368, -111.921749];
Zipcodes['84124'] = [40.677751, -111.797558];
Zipcodes['84128'] = [40.7052, -112.04257];
Zipcodes['84129'] = [40.653577, -111.962063];
Zipcodes['84138'] = [40.766735, -111.887272];
Zipcodes['84150'] = [40.7715, -111.891064];
Zipcodes['84180'] = [40.770444, -111.901105];
Zipcodes['84301'] = [41.613619, -112.127191];
Zipcodes['84302'] = [41.584713, -112.051612];
Zipcodes['84304'] = [41.821842, -111.979131];
Zipcodes['84305'] = [41.943813, -112.06436];
Zipcodes['84306'] = [41.784766, -112.053817];
Zipcodes['84307'] = [41.480119, -112.537206];
Zipcodes['84308'] = [41.966388, -111.967628];
Zipcodes['84309'] = [41.713846, -112.099135];
Zipcodes['84310'] = [41.335387, -111.846823];
Zipcodes['84311'] = [41.820132, -112.110569];
Zipcodes['84312'] = [41.7773, -112.156094];
Zipcodes['84313'] = [41.367926, -113.682487];
Zipcodes['84314'] = [41.649382, -112.092944];
Zipcodes['84315'] = [41.167508, -112.137773];
Zipcodes['84316'] = [41.778746, -112.448024];
Zipcodes['84317'] = [41.307551, -111.620162];
Zipcodes['84318'] = [41.801665, -111.804226];
Zipcodes['84319'] = [41.593349, -111.660033];
Zipcodes['84320'] = [41.952943, -111.852109];
Zipcodes['84321'] = [41.774813, -111.712761];
Zipcodes['84322'] = [41.758224, -111.812556];
Zipcodes['84324'] = [41.494331, -111.941164];
Zipcodes['84325'] = [41.737236, -111.997879];
Zipcodes['84326'] = [41.672326, -111.817761];
Zipcodes['84327'] = [41.865561, -111.997136];
Zipcodes['84328'] = [41.539368, -111.844414];
Zipcodes['84329'] = [41.804208, -113.476254];
Zipcodes['84330'] = [41.906277, -112.170239];
Zipcodes['84331'] = [41.94356, -112.35869];
Zipcodes['84332'] = [41.70314, -111.81202];
Zipcodes['84333'] = [41.910272, -111.7985];
Zipcodes['84334'] = [41.78959, -112.15188];
Zipcodes['84335'] = [41.823969, -111.883495];
Zipcodes['84336'] = [41.877442, -112.858319];
Zipcodes['84337'] = [41.690849, -112.282644];
Zipcodes['84338'] = [41.913878, -111.935809];
Zipcodes['84339'] = [41.625291, -111.938952];
Zipcodes['84340'] = [41.402988, -112.178864];
Zipcodes['84341'] = [41.770678, -111.812392];
Zipcodes['84401'] = [41.218748, -112.032586];
Zipcodes['84403'] = [41.214976, -111.889608];
Zipcodes['84404'] = [41.269519, -112.117658];
Zipcodes['84405'] = [41.149885, -111.956861];
Zipcodes['84408'] = [41.192579, -111.939483];
Zipcodes['84414'] = [41.327146, -111.965471];
Zipcodes['84501'] = [39.530532, -110.810886];
Zipcodes['84511'] = [37.527347, -109.411511];
Zipcodes['84512'] = [37.169691, -109.565719];
Zipcodes['84513'] = [39.258422, -111.008306];
Zipcodes['84515'] = [38.910823, -109.35248];
Zipcodes['84516'] = [39.128434, -111.067081];
Zipcodes['84518'] = [39.388873, -110.876886];
Zipcodes['84520'] = [39.530686, -110.418374];
Zipcodes['84521'] = [39.417033, -110.762446];
Zipcodes['84522'] = [38.86031, -111.223206];
Zipcodes['84523'] = [39.058485, -111.220112];
Zipcodes['84525'] = [39.073151, -110.22313];
Zipcodes['84526'] = [39.681544, -110.96517];
Zipcodes['84528'] = [39.402323, -111.075783];
Zipcodes['84529'] = [39.711527, -110.773264];
Zipcodes['84530'] = [38.244079, -109.404321];
Zipcodes['84531'] = [37.059844, -109.832962];
Zipcodes['84532'] = [38.517666, -109.385155];
Zipcodes['84533'] = [37.405415, -110.537252];
Zipcodes['84534'] = [37.215644, -109.266301];
Zipcodes['84535'] = [37.849881, -109.296406];
Zipcodes['84536'] = [37.130407, -110.218555];
Zipcodes['84537'] = [39.299637, -111.173357];
Zipcodes['84539'] = [39.5761, -110.400681];
Zipcodes['84540'] = [39.216507, -109.520457];
Zipcodes['84542'] = [39.742906, -110.48845];
Zipcodes['84601'] = [40.227242, -111.69581];
Zipcodes['84602'] = [40.254108, -111.650082];
Zipcodes['84604'] = [40.318664, -111.576954];
Zipcodes['84606'] = [40.214921, -111.626174];
Zipcodes['84620'] = [38.922437, -111.924302];
Zipcodes['84621'] = [39.05142, -111.835095];
Zipcodes['84622'] = [39.098174, -111.813528];
Zipcodes['84623'] = [39.453838, -111.58845];
Zipcodes['84624'] = [39.382623, -112.59373];
Zipcodes['84626'] = [39.936023, -111.984892];
Zipcodes['84627'] = [39.355361, -111.585664];
Zipcodes['84628'] = [39.935745, -112.1343];
Zipcodes['84629'] = [39.765512, -111.452879];
Zipcodes['84630'] = [39.310615, -111.834217];
Zipcodes['84631'] = [38.997719, -112.396775];
Zipcodes['84632'] = [39.64495, -111.646511];
Zipcodes['84633'] = [39.939789, -111.891247];
Zipcodes['84634'] = [39.17805, -111.834167];
Zipcodes['84635'] = [39.39071, -112.721528];
Zipcodes['84636'] = [39.139593, -112.338879];
Zipcodes['84637'] = [38.721755, -112.487146];
Zipcodes['84638'] = [39.501927, -112.257709];
Zipcodes['84639'] = [39.507173, -111.900107];
Zipcodes['84640'] = [39.46475, -112.392214];
Zipcodes['84642'] = [39.256568, -111.552177];
Zipcodes['84643'] = [39.128724, -111.684878];
Zipcodes['84644'] = [38.848875, -112.33208];
Zipcodes['84645'] = [39.860513, -111.840052];
Zipcodes['84646'] = [39.541055, -111.608795];
Zipcodes['84647'] = [39.527088, -111.40656];
Zipcodes['84648'] = [39.686596, -111.868172];
Zipcodes['84649'] = [39.346577, -112.330014];
Zipcodes['84651'] = [39.957762, -111.693744];
Zipcodes['84652'] = [39.017239, -111.875876];
Zipcodes['84653'] = [40.047985, -111.668488];
Zipcodes['84654'] = [38.864166, -111.678983];
Zipcodes['84655'] = [39.971313, -111.80682];
Zipcodes['84656'] = [39.235172, -112.108787];
Zipcodes['84657'] = [38.818586, -111.938937];
Zipcodes['84660'] = [40.040637, -111.419114];
Zipcodes['84662'] = [39.439773, -111.485741];
Zipcodes['84663'] = [40.192659, -111.489219];
Zipcodes['84664'] = [40.126231, -111.577176];
Zipcodes['84665'] = [39.192121, -111.616247];
Zipcodes['84667'] = [39.500344, -111.664838];
Zipcodes['84701'] = [38.590731, -111.800446];
Zipcodes['84710'] = [37.469176, -112.510553];
Zipcodes['84711'] = [38.725549, -112.048812];
Zipcodes['84712'] = [38.136558, -111.975577];
Zipcodes['84713'] = [38.332558, -112.516895];
Zipcodes['84714'] = [37.838191, -113.765957];
Zipcodes['84715'] = [38.332217, -111.603131];
Zipcodes['84716'] = [37.944418, -111.44226];
Zipcodes['84718'] = [37.579509, -112.083025];
Zipcodes['84719'] = [37.660881, -112.781467];
Zipcodes['84720'] = [37.593409, -113.292337];
Zipcodes['84721'] = [37.768677, -113.133595];
Zipcodes['84722'] = [37.484812, -113.555594];
Zipcodes['84723'] = [38.175495, -112.249497];
Zipcodes['84724'] = [38.674153, -112.150144];
Zipcodes['84725'] = [37.540352, -113.780206];
Zipcodes['84726'] = [37.753358, -111.580362];
Zipcodes['84728'] = [39.333131, -113.912398];
Zipcodes['84729'] = [37.382639, -112.66015];
Zipcodes['84730'] = [38.736397, -112.019284];
Zipcodes['84731'] = [38.186485, -112.763384];
Zipcodes['84732'] = [38.445321, -111.904087];
Zipcodes['84733'] = [37.29713, -113.805681];
Zipcodes['84734'] = [38.36269, -110.576846];
Zipcodes['84735'] = [37.64493, -112.449555];
Zipcodes['84736'] = [37.663277, -111.787795];
Zipcodes['84737'] = [37.029351, -113.246397];
Zipcodes['84738'] = [37.216322, -113.698554];
Zipcodes['84739'] = [38.641996, -112.222964];
Zipcodes['84740'] = [38.284281, -112.264387];
Zipcodes['84741'] = [37.188452, -112.249236];
Zipcodes['84742'] = [37.517737, -113.158779];
Zipcodes['84743'] = [38.208247, -112.185731];
Zipcodes['84744'] = [38.626126, -111.918708];
Zipcodes['84745'] = [37.208408, -113.262655];
Zipcodes['84746'] = [37.238607, -113.350607];
Zipcodes['84747'] = [38.446277, -111.637732];
Zipcodes['84749'] = [38.354346, -111.499498];
Zipcodes['84750'] = [38.438672, -112.192102];
Zipcodes['84751'] = [38.37009, -112.920624];
Zipcodes['84752'] = [38.251007, -112.88724];
Zipcodes['84753'] = [37.965227, -113.858043];
Zipcodes['84754'] = [38.610772, -112.117106];
Zipcodes['84755'] = [37.226514, -112.7853];
Zipcodes['84756'] = [37.742488, -113.54774];
Zipcodes['84757'] = [37.46457, -113.269305];
Zipcodes['84758'] = [37.326515, -112.780576];
Zipcodes['84759'] = [37.926071, -112.390081];
Zipcodes['84760'] = [37.949582, -112.661783];
Zipcodes['84761'] = [37.852329, -112.866017];
Zipcodes['84762'] = [37.497245, -112.73698];
Zipcodes['84763'] = [37.146693, -113.069504];
Zipcodes['84764'] = [37.785331, -111.932454];
Zipcodes['84765'] = [37.337765, -114.017951];
Zipcodes['84766'] = [38.598516, -112.345688];
Zipcodes['84767'] = [37.201399, -112.985805];
Zipcodes['84770'] = [37.13839, -113.629601];
Zipcodes['84772'] = [37.793286, -112.947567];
Zipcodes['84773'] = [38.25075, -111.470479];
Zipcodes['84774'] = [37.243249, -113.280893];
Zipcodes['84775'] = [38.289608, -111.03342];
Zipcodes['84776'] = [37.62961, -112.08248];
Zipcodes['84779'] = [37.334886, -113.122148];
Zipcodes['84780'] = [37.133167, -113.473684];
Zipcodes['84781'] = [37.396842, -113.475054];
Zipcodes['84782'] = [37.379912, -113.667644];
Zipcodes['84783'] = [37.26383, -113.682839];
Zipcodes['84784'] = [37.018671, -112.969318];
Zipcodes['84790'] = [37.046092, -113.557306];
Zipcodes['85003'] = [33.45052, -112.078343];
Zipcodes['85004'] = [33.451609, -112.06988];
Zipcodes['85006'] = [33.465234, -112.048771];
Zipcodes['85007'] = [33.446529, -112.090319];
Zipcodes['85008'] = [33.463884, -111.987763];
Zipcodes['85009'] = [33.444546, -112.128675];
Zipcodes['85012'] = [33.507577, -112.070981];
Zipcodes['85013'] = [33.511112, -112.084731];
Zipcodes['85014'] = [33.507223, -112.05769];
Zipcodes['85015'] = [33.508637, -112.102056];
Zipcodes['85016'] = [33.510166, -112.031684];
Zipcodes['85017'] = [33.508782, -112.123068];
Zipcodes['85018'] = [33.519784, -111.993594];
Zipcodes['85019'] = [33.511534, -112.143319];
Zipcodes['85020'] = [33.566061, -112.055642];
Zipcodes['85021'] = [33.561061, -112.094924];
Zipcodes['85022'] = [33.627136, -112.049222];
Zipcodes['85023'] = [33.634118, -112.093163];
Zipcodes['85024'] = [33.689593, -112.044699];
Zipcodes['85026'] = [33.448905, -111.973547];
Zipcodes['85027'] = [33.677473, -112.10228];
Zipcodes['85028'] = [33.581606, -112.008136];
Zipcodes['85029'] = [33.595414, -112.10975];
Zipcodes['85031'] = [33.493971, -112.166926];
Zipcodes['85032'] = [33.62592, -112.002503];
Zipcodes['85033'] = [33.493589, -112.214037];
Zipcodes['85034'] = [33.430385, -112.014921];
Zipcodes['85035'] = [33.471668, -112.195393];
Zipcodes['85037'] = [33.486707, -112.268258];
Zipcodes['85040'] = [33.405566, -112.025492];
Zipcodes['85041'] = [33.386995, -112.109574];
Zipcodes['85042'] = [33.355545, -112.052242];
Zipcodes['85043'] = [33.433065, -112.197172];
Zipcodes['85044'] = [33.339721, -112.006194];
Zipcodes['85045'] = [33.298893, -112.105877];
Zipcodes['85048'] = [33.301983, -112.028911];
Zipcodes['85050'] = [33.687374, -111.998931];
Zipcodes['85051'] = [33.558759, -112.132498];
Zipcodes['85053'] = [33.629595, -112.131846];
Zipcodes['85054'] = [33.681025, -111.944882];
Zipcodes['85083'] = [33.745359, -112.174635];
Zipcodes['85085'] = [33.752614, -112.090157];
Zipcodes['85086'] = [33.822515, -112.130286];
Zipcodes['85087'] = [33.925005, -112.148875];
Zipcodes['85118'] = [33.356986, -111.363091];
Zipcodes['85119'] = [33.386823, -111.508244];
Zipcodes['85120'] = [33.386881, -111.561667];
Zipcodes['85121'] = [33.137154, -111.914311];
Zipcodes['85122'] = [32.916728, -111.747967];
Zipcodes['85123'] = [32.713078, -111.678371];
Zipcodes['85128'] = [32.962844, -111.509688];
Zipcodes['85131'] = [32.653843, -111.577149];
Zipcodes['85132'] = [32.926956, -111.214093];
Zipcodes['85135'] = [32.994887, -110.779872];
Zipcodes['85137'] = [33.136377, -111.02257];
Zipcodes['85138'] = [33.017663, -111.991284];
Zipcodes['85139'] = [32.923874, -112.206708];
Zipcodes['85140'] = [33.248065, -111.489238];
Zipcodes['85141'] = [32.641808, -111.434533];
Zipcodes['85142'] = [33.192391, -111.66594];
Zipcodes['85143'] = [33.156022, -111.519755];
Zipcodes['85145'] = [32.541314, -111.391056];
Zipcodes['85147'] = [33.097774, -111.724833];
Zipcodes['85172'] = [32.904833, -111.955451];
Zipcodes['85173'] = [33.268271, -111.136483];
Zipcodes['85191'] = [32.984984, -111.446934];
Zipcodes['85192'] = [32.962942, -110.678531];
Zipcodes['85193'] = [32.813284, -111.840448];
Zipcodes['85194'] = [32.901117, -111.628048];
Zipcodes['85201'] = [33.433545, -111.8504];
Zipcodes['85202'] = [33.379757, -111.875018];
Zipcodes['85203'] = [33.450359, -111.802894];
Zipcodes['85204'] = [33.39572, -111.786608];
Zipcodes['85205'] = [33.433165, -111.71357];
Zipcodes['85206'] = [33.397432, -111.7165];
Zipcodes['85207'] = [33.453269, -111.636673];
Zipcodes['85208'] = [33.402405, -111.632073];
Zipcodes['85209'] = [33.378291, -111.634444];
Zipcodes['85210'] = [33.389733, -111.843505];
Zipcodes['85212'] = [33.320607, -111.630757];
Zipcodes['85213'] = [33.448413, -111.7662];
Zipcodes['85215'] = [33.511896, -111.579396];
Zipcodes['85224'] = [33.323511, -111.876159];
Zipcodes['85225'] = [33.317828, -111.830541];
Zipcodes['85226'] = [33.275114, -111.92509];
Zipcodes['85233'] = [33.352445, -111.812562];
Zipcodes['85234'] = [33.365502, -111.73966];
Zipcodes['85236'] = [33.322922, -111.719613];
Zipcodes['85248'] = [33.214898, -111.866427];
Zipcodes['85249'] = [33.227059, -111.7997];
Zipcodes['85250'] = [33.52713, -111.889428];
Zipcodes['85251'] = [33.494489, -111.920714];
Zipcodes['85253'] = [33.544242, -111.958823];
Zipcodes['85254'] = [33.614603, -111.949671];
Zipcodes['85255'] = [33.668188, -111.822512];
Zipcodes['85256'] = [33.500609, -111.835063];
Zipcodes['85257'] = [33.463212, -111.913762];
Zipcodes['85258'] = [33.557918, -111.892948];
Zipcodes['85259'] = [33.601041, -111.809497];
Zipcodes['85260'] = [33.608066, -111.889177];
Zipcodes['85262'] = [33.85797, -111.80515];
Zipcodes['85263'] = [33.933095, -111.559501];
Zipcodes['85264'] = [33.687759, -111.487369];
Zipcodes['85266'] = [33.762367, -111.919091];
Zipcodes['85268'] = [33.606701, -111.737015];
Zipcodes['85281'] = [33.42744, -111.934004];
Zipcodes['85282'] = [33.393984, -111.932906];
Zipcodes['85283'] = [33.364638, -111.931604];
Zipcodes['85284'] = [33.338285, -111.933906];
Zipcodes['85286'] = [33.272273, -111.833863];
Zipcodes['85295'] = [33.305282, -111.736462];
Zipcodes['85296'] = [33.332328, -111.749802];
Zipcodes['85297'] = [33.280561, -111.7333];
Zipcodes['85298'] = [33.239818, -111.728198];
Zipcodes['85301'] = [33.533729, -112.17854];
Zipcodes['85302'] = [33.56809, -112.178089];
Zipcodes['85303'] = [33.533976, -112.220843];
Zipcodes['85304'] = [33.594547, -112.179512];
Zipcodes['85305'] = [33.529964, -112.255657];
Zipcodes['85306'] = [33.623562, -112.175283];
Zipcodes['85307'] = [33.530363, -112.310045];
Zipcodes['85308'] = [33.660382, -112.184964];
Zipcodes['85309'] = [33.535553, -112.376355];
Zipcodes['85310'] = [33.700977, -112.158084];
Zipcodes['85320'] = [33.835575, -113.232201];
Zipcodes['85321'] = [32.361993, -112.584724];
Zipcodes['85322'] = [33.159113, -112.872037];
Zipcodes['85323'] = [33.419233, -112.326866];
Zipcodes['85324'] = [34.121566, -112.037863];
Zipcodes['85325'] = [33.962832, -113.869413];
Zipcodes['85326'] = [33.229169, -112.582866];
Zipcodes['85328'] = [33.252628, -114.62036];
Zipcodes['85329'] = [33.431771, -112.29641];
Zipcodes['85331'] = [33.884228, -111.939601];
Zipcodes['85332'] = [34.207147, -112.921018];
Zipcodes['85333'] = [32.976231, -113.310151];
Zipcodes['85334'] = [33.593912, -114.363356];
Zipcodes['85335'] = [33.585408, -112.326811];
Zipcodes['85336'] = [32.508773, -114.743963];
Zipcodes['85337'] = [32.975552, -112.865818];
Zipcodes['85338'] = [33.372975, -112.410829];
Zipcodes['85339'] = [33.240894, -112.142689];
Zipcodes['85340'] = [33.516382, -112.423586];
Zipcodes['85341'] = [31.972569, -112.719502];
Zipcodes['85342'] = [33.944517, -112.451343];
Zipcodes['85343'] = [33.337759, -112.690863];
Zipcodes['85344'] = [33.911407, -114.257178];
Zipcodes['85345'] = [33.572326, -112.248142];
Zipcodes['85346'] = [33.609914, -114.158252];
Zipcodes['85347'] = [32.785966, -113.806891];
Zipcodes['85348'] = [33.699178, -113.663856];
Zipcodes['85349'] = [32.47645, -114.683518];
Zipcodes['85350'] = [32.563568, -114.705499];
Zipcodes['85351'] = [33.605791, -112.28406];
Zipcodes['85352'] = [32.691195, -113.896256];
Zipcodes['85353'] = [33.417511, -112.276974];
Zipcodes['85354'] = [33.40894, -113.070116];
Zipcodes['85355'] = [33.57457, -112.452923];
Zipcodes['85356'] = [32.689354, -114.185176];
Zipcodes['85357'] = [34.020672, -113.450652];
Zipcodes['85360'] = [34.548522, -113.574386];
Zipcodes['85361'] = [33.752261, -112.629383];
Zipcodes['85362'] = [34.285469, -112.843233];
Zipcodes['85363'] = [33.584969, -112.304916];
Zipcodes['85364'] = [32.704556, -114.656304];
Zipcodes['85365'] = [33.017596, -114.110521];
Zipcodes['85367'] = [32.660831, -114.393618];
Zipcodes['85371'] = [33.967316, -114.390048];
Zipcodes['85373'] = [33.669222, -112.300263];
Zipcodes['85374'] = [33.644893, -112.395034];
Zipcodes['85375'] = [33.671383, -112.357187];
Zipcodes['85377'] = [33.823214, -111.913271];
Zipcodes['85378'] = [33.644551, -112.319905];
Zipcodes['85379'] = [33.602126, -112.374041];
Zipcodes['85381'] = [33.609138, -112.230287];
Zipcodes['85382'] = [33.655591, -112.247623];
Zipcodes['85383'] = [33.798406, -112.305179];
Zipcodes['85387'] = [33.709159, -112.446694];
Zipcodes['85388'] = [33.604213, -112.431618];
Zipcodes['85390'] = [33.903622, -112.875667];
Zipcodes['85392'] = [33.47657, -112.309428];
Zipcodes['85395'] = [33.485001, -112.405856];
Zipcodes['85396'] = [33.53652, -112.702463];
Zipcodes['85501'] = [33.559004, -110.769126];
Zipcodes['85530'] = [33.086348, -110.26669];
Zipcodes['85531'] = [32.870869, -109.780728];
Zipcodes['85532'] = [33.409566, -110.842059];
Zipcodes['85533'] = [33.287357, -109.380485];
Zipcodes['85534'] = [32.722226, -109.153128];
Zipcodes['85535'] = [32.98647, -109.912689];
Zipcodes['85536'] = [33.147511, -109.989395];
Zipcodes['85539'] = [33.335717, -110.929878];
Zipcodes['85540'] = [33.003983, -109.380872];
Zipcodes['85541'] = [34.223305, -111.347339];
Zipcodes['85542'] = [33.324677, -110.310846];
Zipcodes['85543'] = [32.953734, -109.89878];
Zipcodes['85544'] = [34.383579, -111.477279];
Zipcodes['85545'] = [33.565248, -111.140415];
Zipcodes['85546'] = [32.699939, -109.588569];
Zipcodes['85550'] = [33.548457, -110.509039];
Zipcodes['85551'] = [32.737814, -109.562282];
Zipcodes['85552'] = [32.818661, -109.785582];
Zipcodes['85553'] = [33.793838, -111.223484];
Zipcodes['85554'] = [33.996399, -110.99877];
Zipcodes['85601'] = [31.583502, -111.331128];
Zipcodes['85602'] = [32.167495, -110.411875];
Zipcodes['85603'] = [31.432669, -109.919056];
Zipcodes['85605'] = [32.307852, -109.47082];
Zipcodes['85606'] = [32.005295, -109.930093];
Zipcodes['85607'] = [31.489773, -109.336869];
Zipcodes['85608'] = [31.45693, -109.580927];
Zipcodes['85609'] = [32.069297, -110.096907];
Zipcodes['85610'] = [31.741414, -109.671897];
Zipcodes['85611'] = [31.644094, -110.511801];
Zipcodes['85613'] = [31.535877, -110.413721];
Zipcodes['85614'] = [31.8113, -110.931085];
Zipcodes['85615'] = [31.398136, -110.210651];
Zipcodes['85616'] = [31.701985, -110.322671];
Zipcodes['85617'] = [31.577488, -109.718764];
Zipcodes['85618'] = [32.776312, -110.694166];
Zipcodes['85619'] = [32.395744, -110.736666];
Zipcodes['85620'] = [31.33849, -109.944654];
Zipcodes['85621'] = [31.387974, -110.899729];
Zipcodes['85622'] = [31.803004, -111.091051];
Zipcodes['85623'] = [32.653993, -110.784488];
Zipcodes['85624'] = [31.52042, -110.722998];
Zipcodes['85625'] = [31.876026, -109.664234];
Zipcodes['85626'] = [31.359301, -109.568278];
Zipcodes['85627'] = [32.007788, -110.235535];
Zipcodes['85629'] = [31.959623, -111.109271];
Zipcodes['85630'] = [31.886839, -110.179831];
Zipcodes['85631'] = [32.581306, -110.562511];
Zipcodes['85632'] = [32.058428, -109.183668];
Zipcodes['85633'] = [31.607377, -111.530499];
Zipcodes['85634'] = [32.01236, -112.021352];
Zipcodes['85635'] = [31.553108, -110.171088];
Zipcodes['85637'] = [31.719434, -110.690906];
Zipcodes['85638'] = [31.666024, -110.059506];
Zipcodes['85639'] = [31.771716, -111.68229];
Zipcodes['85640'] = [31.569034, -111.044692];
Zipcodes['85641'] = [32.021138, -110.64626];
Zipcodes['85643'] = [32.460684, -110.000342];
Zipcodes['85645'] = [31.698208, -111.142576];
Zipcodes['85646'] = [31.621042, -111.050259];
Zipcodes['85648'] = [31.496389, -111.001338];
Zipcodes['85650'] = [31.498887, -110.201319];
Zipcodes['85653'] = [32.39879, -111.386333];
Zipcodes['85654'] = [32.415139, -111.154573];
Zipcodes['85658'] = [32.515937, -111.152624];
Zipcodes['85701'] = [32.217037, -110.971338];
Zipcodes['85704'] = [32.33942, -110.984997];
Zipcodes['85705'] = [32.271205, -110.991724];
Zipcodes['85706'] = [32.147828, -110.935368];
Zipcodes['85707'] = [32.180177, -110.889632];
Zipcodes['85708'] = [32.175766, -110.860159];
Zipcodes['85709'] = [32.141344, -110.990119];
Zipcodes['85710'] = [32.214388, -110.823663];
Zipcodes['85711'] = [32.215025, -110.883461];
Zipcodes['85712'] = [32.252876, -110.886584];
Zipcodes['85713'] = [32.19457, -111.014995];
Zipcodes['85714'] = [32.169961, -110.946558];
Zipcodes['85715'] = [32.24671, -110.836157];
Zipcodes['85716'] = [32.242195, -110.923528];
Zipcodes['85718'] = [32.313135, -110.921964];
Zipcodes['85719'] = [32.246222, -110.948769];
Zipcodes['85721'] = [32.231069, -110.949568];
Zipcodes['85723'] = [32.181859, -110.96499];
Zipcodes['85724'] = [32.240787, -110.945605];
Zipcodes['85726'] = [32.204341, -110.945294];
Zipcodes['85730'] = [32.189785, -110.720475];
Zipcodes['85734'] = [32.067613, -110.867172];
Zipcodes['85735'] = [32.103886, -111.334504];
Zipcodes['85736'] = [31.870886, -111.366976];
Zipcodes['85737'] = [32.414615, -110.946561];
Zipcodes['85739'] = [32.612171, -111.008938];
Zipcodes['85741'] = [32.335292, -111.041758];
Zipcodes['85742'] = [32.386275, -111.055323];
Zipcodes['85743'] = [32.302288, -111.181839];
Zipcodes['85745'] = [32.248976, -111.061335];
Zipcodes['85746'] = [32.069621, -111.090488];
Zipcodes['85747'] = [32.093168, -110.772312];
Zipcodes['85748'] = [32.219012, -110.752137];
Zipcodes['85749'] = [32.281851, -110.731649];
Zipcodes['85750'] = [32.29795, -110.844898];
Zipcodes['85755'] = [32.468351, -110.981507];
Zipcodes['85756'] = [32.07033, -110.906256];
Zipcodes['85757'] = [32.128356, -111.122305];
Zipcodes['85901'] = [34.314806, -110.024014];
Zipcodes['85902'] = [34.156052, -110.159067];
Zipcodes['85911'] = [34.092017, -110.420286];
Zipcodes['85912'] = [34.414631, -109.910227];
Zipcodes['85920'] = [33.858568, -109.178184];
Zipcodes['85922'] = [33.510722, -109.129127];
Zipcodes['85923'] = [34.323125, -110.340346];
Zipcodes['85924'] = [34.566622, -109.672357];
Zipcodes['85925'] = [34.01885, -109.352493];
Zipcodes['85926'] = [33.769253, -109.979275];
Zipcodes['85927'] = [33.990796, -109.47879];
Zipcodes['85928'] = [34.517988, -110.557473];
Zipcodes['85929'] = [34.171165, -109.965072];
Zipcodes['85930'] = [34.034763, -109.770668];
Zipcodes['85931'] = [34.370005, -110.915647];
Zipcodes['85932'] = [33.968777, -109.180738];
Zipcodes['85933'] = [34.357093, -110.509263];
Zipcodes['85934'] = [34.302485, -110.244323];
Zipcodes['85935'] = [34.159121, -109.927354];
Zipcodes['85936'] = [34.591725, -109.290423];
Zipcodes['85937'] = [34.569735, -110.014345];
Zipcodes['85938'] = [34.163398, -109.414295];
Zipcodes['85939'] = [34.431428, -110.085261];
Zipcodes['85940'] = [34.252948, -109.701772];
Zipcodes['85941'] = [33.891524, -110.063212];
Zipcodes['85942'] = [34.773906, -110.034184];
Zipcodes['86001'] = [35.357736, -111.771155];
Zipcodes['86003'] = [35.242516, -111.202037];
Zipcodes['86004'] = [35.380939, -111.373463];
Zipcodes['86005'] = [35.087726, -111.619198];
Zipcodes['86011'] = [35.189512, -111.654712];
Zipcodes['86015'] = [35.222777, -111.814066];
Zipcodes['86016'] = [35.752462, -111.641148];
Zipcodes['86017'] = [34.94067, -111.681081];
Zipcodes['86018'] = [35.238727, -111.969784];
Zipcodes['86020'] = [35.992798, -111.497817];
Zipcodes['86021'] = [36.940135, -113.146245];
Zipcodes['86022'] = [36.770728, -112.55829];
Zipcodes['86023'] = [36.050484, -112.013386];
Zipcodes['86024'] = [34.670921, -111.190888];
Zipcodes['86025'] = [34.892186, -110.065756];
Zipcodes['86028'] = [35.032476, -109.815501];
Zipcodes['86029'] = [34.970555, -110.01311];
Zipcodes['86030'] = [35.853914, -110.799175];
Zipcodes['86031'] = [35.363492, -110.066576];
Zipcodes['86032'] = [35.07961, -110.283924];
Zipcodes['86033'] = [36.677302, -110.256301];
Zipcodes['86034'] = [35.770876, -110.138147];
Zipcodes['86035'] = [35.405431, -111.023813];
Zipcodes['86036'] = [36.788898, -111.815496];
Zipcodes['86038'] = [34.910517, -111.489339];
Zipcodes['86039'] = [35.972151, -110.569445];
Zipcodes['86040'] = [36.659592, -111.385712];
Zipcodes['86042'] = [35.861758, -110.384151];
Zipcodes['86043'] = [35.809701, -110.533441];
Zipcodes['86044'] = [36.735526, -110.80265];
Zipcodes['86045'] = [36.141967, -111.123676];
Zipcodes['86046'] = [35.436907, -112.176239];
Zipcodes['86047'] = [35.180686, -110.620994];
Zipcodes['86052'] = [36.286604, -112.143353];
Zipcodes['86053'] = [36.758183, -111.056391];
Zipcodes['86054'] = [36.674955, -110.61808];
Zipcodes['86301'] = [34.59808, -112.420191];
Zipcodes['86303'] = [34.497122, -112.432618];
Zipcodes['86305'] = [34.769794, -112.756958];
Zipcodes['86313'] = [34.554217, -112.45286];
Zipcodes['86314'] = [34.598238, -112.330783];
Zipcodes['86315'] = [34.674028, -112.289062];
Zipcodes['86320'] = [35.374687, -112.623878];
Zipcodes['86321'] = [34.553561, -113.088961];
Zipcodes['86322'] = [34.472349, -111.807627];
Zipcodes['86323'] = [34.766056, -112.404241];
Zipcodes['86324'] = [34.820131, -112.107922];
Zipcodes['86325'] = [34.733452, -111.898858];
Zipcodes['86326'] = [34.698565, -112.013827];
Zipcodes['86327'] = [34.556792, -112.110908];
Zipcodes['86329'] = [34.485888, -112.276637];
Zipcodes['86331'] = [34.760088, -112.116994];
Zipcodes['86332'] = [34.303998, -112.617848];
Zipcodes['86333'] = [34.323041, -112.08197];
Zipcodes['86334'] = [34.94801, -112.539593];
Zipcodes['86335'] = [34.64831, -111.663313];
Zipcodes['86336'] = [34.878547, -111.858031];
Zipcodes['86337'] = [35.241004, -113.051203];
Zipcodes['86338'] = [34.572993, -112.757506];
Zipcodes['86343'] = [34.192694, -112.319545];
Zipcodes['86351'] = [34.778463, -111.785141];
Zipcodes['86401'] = [35.113668, -113.738093];
Zipcodes['86403'] = [34.476908, -114.344135];
Zipcodes['86404'] = [34.59005, -114.307833];
Zipcodes['86406'] = [34.382386, -114.080626];
Zipcodes['86409'] = [35.461006, -114.01806];
Zipcodes['86411'] = [35.447534, -113.7437];
Zipcodes['86413'] = [35.169837, -114.256898];
Zipcodes['86426'] = [35.013382, -114.549707];
Zipcodes['86429'] = [35.282882, -114.477507];
Zipcodes['86431'] = [35.425209, -114.247385];
Zipcodes['86432'] = [36.799245, -113.723952];
Zipcodes['86433'] = [34.928582, -114.358558];
Zipcodes['86434'] = [35.656412, -113.592776];
Zipcodes['86435'] = [36.005447, -112.945058];
Zipcodes['86436'] = [34.738969, -114.416933];
Zipcodes['86437'] = [35.395096, -113.597852];
Zipcodes['86438'] = [34.68319, -113.987734];
Zipcodes['86440'] = [34.889709, -114.542181];
Zipcodes['86441'] = [35.56535, -114.300069];
Zipcodes['86442'] = [35.089613, -114.560868];
Zipcodes['86443'] = [35.948754, -114.444872];
Zipcodes['86444'] = [35.9054, -114.060636];
Zipcodes['86445'] = [35.687485, -114.555935];
Zipcodes['86502'] = [35.143098, -109.609484];
Zipcodes['86503'] = [36.216887, -109.682415];
Zipcodes['86504'] = [35.860391, -109.139361];
Zipcodes['86505'] = [35.623754, -109.706962];
Zipcodes['86506'] = [35.354086, -109.258761];
Zipcodes['86507'] = [36.424039, -109.28343];
Zipcodes['86508'] = [35.352067, -109.08707];
Zipcodes['86510'] = [36.227221, -110.272115];
Zipcodes['86511'] = [35.739875, -109.368886];
Zipcodes['86512'] = [35.095074, -109.243165];
Zipcodes['86514'] = [36.905887, -109.325806];
Zipcodes['86515'] = [35.707431, -108.984322];
Zipcodes['86520'] = [36.044337, -109.971428];
Zipcodes['86535'] = [36.823128, -109.865633];
Zipcodes['86538'] = [36.421077, -109.633858];
Zipcodes['86540'] = [35.941437, -109.443978];
Zipcodes['86544'] = [36.544317, -109.142198];
Zipcodes['86545'] = [36.717772, -109.540775];
Zipcodes['86547'] = [36.548824, -109.47439];
Zipcodes['86556'] = [36.228313, -109.220314];
Zipcodes['87001'] = [35.414284, -106.38284];
Zipcodes['87002'] = [34.635642, -106.828652];
Zipcodes['87004'] = [35.368875, -106.597242];
Zipcodes['87005'] = [35.265519, -108.003];
Zipcodes['87006'] = [34.484499, -106.843476];
Zipcodes['87007'] = [34.965422, -107.429815];
Zipcodes['87008'] = [35.14945, -106.402006];
Zipcodes['87010'] = [35.399804, -106.139052];
Zipcodes['87011'] = [34.082253, -105.972281];
Zipcodes['87012'] = [36.127297, -106.651627];
Zipcodes['87013'] = [35.893886, -107.335622];
Zipcodes['87014'] = [35.172546, -107.406978];
Zipcodes['87015'] = [35.075906, -106.182984];
Zipcodes['87016'] = [34.755154, -106.01559];
Zipcodes['87017'] = [36.309675, -106.733986];
Zipcodes['87018'] = [36.108441, -107.530966];
Zipcodes['87020'] = [35.014369, -107.925009];
Zipcodes['87021'] = [35.192118, -107.878974];
Zipcodes['87022'] = [34.910012, -106.694816];
Zipcodes['87023'] = [34.600498, -106.767483];
Zipcodes['87024'] = [35.673525, -106.749408];
Zipcodes['87025'] = [35.834344, -106.617257];
Zipcodes['87026'] = [34.981149, -107.207734];
Zipcodes['87027'] = [36.127313, -106.947373];
Zipcodes['87028'] = [34.354216, -106.782522];
Zipcodes['87029'] = [36.419337, -107.043099];
Zipcodes['87031'] = [34.779132, -106.725659];
Zipcodes['87032'] = [34.867859, -106.041992];
Zipcodes['87034'] = [34.922209, -107.634373];
Zipcodes['87035'] = [34.952042, -105.909388];
Zipcodes['87036'] = [34.372063, -106.239212];
Zipcodes['87037'] = [36.188025, -107.78041];
Zipcodes['87038'] = [35.087627, -107.412347];
Zipcodes['87040'] = [35.137707, -107.412885];
Zipcodes['87041'] = [35.60168, -106.320833];
Zipcodes['87042'] = [34.828325, -106.686225];
Zipcodes['87043'] = [35.278891, -106.430926];
Zipcodes['87044'] = [35.712493, -106.65656];
Zipcodes['87045'] = [35.397086, -108.015496];
Zipcodes['87046'] = [36.285987, -106.915086];
Zipcodes['87047'] = [35.250846, -106.288772];
Zipcodes['87048'] = [35.239575, -106.627061];
Zipcodes['87049'] = [35.131868, -107.629093];
Zipcodes['87051'] = [35.036407, -107.852784];
Zipcodes['87052'] = [35.528464, -106.370598];
Zipcodes['87053'] = [35.575114, -106.882524];
Zipcodes['87056'] = [35.161578, -105.885105];
Zipcodes['87059'] = [34.996756, -106.300636];
Zipcodes['87060'] = [34.738681, -106.736554];
Zipcodes['87061'] = [34.705002, -106.381477];
Zipcodes['87062'] = [34.461861, -106.697221];
Zipcodes['87063'] = [34.554978, -105.890557];
Zipcodes['87064'] = [36.183894, -106.538655];
Zipcodes['87068'] = [34.892448, -106.645912];
Zipcodes['87070'] = [35.022532, -105.740476];
Zipcodes['87072'] = [35.608277, -106.395497];
Zipcodes['87083'] = [35.644968, -106.363648];
Zipcodes['87101'] = [35.096687, -106.643394];
Zipcodes['87102'] = [35.082357, -106.646768];
Zipcodes['87104'] = [35.103044, -106.674464];
Zipcodes['87105'] = [34.9943, -106.684771];
Zipcodes['87106'] = [35.007139, -106.60982];
Zipcodes['87107'] = [35.1378, -106.642543];
Zipcodes['87108'] = [35.072343, -106.578318];
Zipcodes['87109'] = [35.151667, -106.574947];
Zipcodes['87110'] = [35.108194, -106.578147];
Zipcodes['87111'] = [35.140776, -106.514586];
Zipcodes['87112'] = [35.100609, -106.516275];
Zipcodes['87113'] = [35.19597, -106.573215];
Zipcodes['87114'] = [35.197465, -106.681757];
Zipcodes['87116'] = [35.048917, -106.561112];
Zipcodes['87117'] = [34.997595, -106.520858];
Zipcodes['87120'] = [35.140539, -106.760969];
Zipcodes['87121'] = [35.017046, -106.888308];
Zipcodes['87122'] = [35.188529, -106.493544];
Zipcodes['87123'] = [35.061361, -106.47123];
Zipcodes['87124'] = [35.26992, -106.795643];
Zipcodes['87144'] = [35.330173, -106.710088];
Zipcodes['87151'] = [35.007689, -106.860341];
Zipcodes['87301'] = [35.521594, -108.765319];
Zipcodes['87305'] = [35.403244, -108.731483];
Zipcodes['87310'] = [35.746651, -108.595868];
Zipcodes['87311'] = [35.606614, -108.500209];
Zipcodes['87312'] = [35.429092, -108.345406];
Zipcodes['87313'] = [35.798151, -108.221042];
Zipcodes['87315'] = [34.662552, -108.550822];
Zipcodes['87316'] = [35.489015, -108.526183];
Zipcodes['87317'] = [35.58023, -108.752428];
Zipcodes['87319'] = [35.477281, -108.939644];
Zipcodes['87320'] = [35.807587, -108.871347];
Zipcodes['87321'] = [35.065303, -108.405061];
Zipcodes['87322'] = [35.485328, -108.662496];
Zipcodes['87323'] = [35.45684, -108.186127];
Zipcodes['87325'] = [35.983933, -108.572204];
Zipcodes['87326'] = [35.27705, -108.8829];
Zipcodes['87327'] = [35.071983, -108.788665];
Zipcodes['87328'] = [36.03035, -109.032494];
Zipcodes['87347'] = [35.385165, -108.457911];
Zipcodes['87357'] = [34.829377, -108.409286];
Zipcodes['87364'] = [36.133635, -108.712048];
Zipcodes['87365'] = [35.535889, -108.13794];
Zipcodes['87375'] = [35.671097, -108.78887];
Zipcodes['87401'] = [36.738158, -108.183294];
Zipcodes['87402'] = [36.796608, -108.151748];
Zipcodes['87410'] = [36.858227, -107.895466];
Zipcodes['87412'] = [36.644965, -107.547462];
Zipcodes['87413'] = [36.460675, -107.964476];
Zipcodes['87415'] = [36.827164, -108.097457];
Zipcodes['87416'] = [36.585149, -108.451585];
Zipcodes['87417'] = [36.750143, -108.351987];
Zipcodes['87418'] = [36.950686, -108.150215];
Zipcodes['87419'] = [36.919306, -107.640257];
Zipcodes['87420'] = [36.862541, -108.935813];
Zipcodes['87421'] = [36.776813, -108.527439];
Zipcodes['87455'] = [36.273138, -108.713751];
Zipcodes['87461'] = [36.452072, -108.924767];
Zipcodes['87499'] = [36.315966, -108.244437];
Zipcodes['87501'] = [35.708182, -105.851021];
Zipcodes['87505'] = [35.619795, -105.866981];
Zipcodes['87506'] = [35.824563, -105.95535];
Zipcodes['87507'] = [35.621812, -106.108636];
Zipcodes['87508'] = [35.52063, -105.945842];
Zipcodes['87510'] = [36.361964, -106.476353];
Zipcodes['87511'] = [36.088858, -105.975214];
Zipcodes['87512'] = [36.923365, -105.343529];
Zipcodes['87513'] = [36.583677, -105.701239];
Zipcodes['87514'] = [36.529556, -105.600602];
Zipcodes['87515'] = [36.557083, -106.377119];
Zipcodes['87516'] = [36.177805, -106.46628];
Zipcodes['87517'] = [36.390291, -105.792712];
Zipcodes['87518'] = [36.520228, -106.547028];
Zipcodes['87519'] = [36.764696, -105.635034];
Zipcodes['87520'] = [36.875021, -106.627345];
Zipcodes['87521'] = [36.017989, -105.612452];
Zipcodes['87522'] = [35.964027, -105.843072];
Zipcodes['87523'] = [36.01112, -105.862884];
Zipcodes['87524'] = [36.969739, -105.537486];
Zipcodes['87525'] = [36.59849, -105.498054];
Zipcodes['87527'] = [36.151131, -105.83841];
Zipcodes['87528'] = [36.785461, -106.878876];
Zipcodes['87529'] = [36.445855, -105.666136];
Zipcodes['87530'] = [36.340759, -106.201773];
Zipcodes['87531'] = [36.228167, -105.901692];
Zipcodes['87532'] = [35.989751, -106.12563];
Zipcodes['87535'] = [35.566771, -105.747189];
Zipcodes['87537'] = [36.142126, -106.259898];
Zipcodes['87538'] = [35.493179, -105.537676];
Zipcodes['87539'] = [36.385561, -105.980171];
Zipcodes['87540'] = [35.406897, -105.873481];
Zipcodes['87543'] = [36.104014, -105.657806];
Zipcodes['87544'] = [35.766966, -106.295094];
Zipcodes['87547'] = [35.810977, -106.207688];
Zipcodes['87548'] = [36.175928, -106.185341];
Zipcodes['87549'] = [36.293273, -105.964597];
Zipcodes['87551'] = [36.699368, -106.661441];
Zipcodes['87552'] = [35.670149, -105.613853];
Zipcodes['87553'] = [36.220387, -105.734355];
Zipcodes['87554'] = [36.548538, -106.070143];
Zipcodes['87556'] = [36.81216, -105.557233];
Zipcodes['87557'] = [36.295569, -105.635107];
Zipcodes['87558'] = [36.673943, -105.455517];
Zipcodes['87560'] = [35.236716, -105.526282];
Zipcodes['87562'] = [35.406624, -105.655221];
Zipcodes['87564'] = [36.61565, -105.617949];
Zipcodes['87565'] = [35.515858, -105.472867];
Zipcodes['87566'] = [36.067572, -106.077432];
Zipcodes['87567'] = [35.994015, -106.018691];
Zipcodes['87569'] = [35.350327, -105.305158];
Zipcodes['87571'] = [36.351554, -105.436519];
Zipcodes['87573'] = [35.771712, -105.707218];
Zipcodes['87575'] = [36.642865, -106.595419];
Zipcodes['87577'] = [36.640015, -106.003009];
Zipcodes['87578'] = [36.047441, -105.770114];
Zipcodes['87579'] = [36.086354, -105.531922];
Zipcodes['87580'] = [36.559553, -105.574603];
Zipcodes['87581'] = [36.505251, -106.153042];
Zipcodes['87582'] = [36.179124, -105.994457];
Zipcodes['87583'] = [35.282182, -105.35227];
Zipcodes['87701'] = [35.542113, -104.933487];
Zipcodes['87710'] = [36.382439, -105.230236];
Zipcodes['87711'] = [35.238165, -105.13906];
Zipcodes['87712'] = [35.883099, -105.16602];
Zipcodes['87713'] = [36.170787, -105.361135];
Zipcodes['87714'] = [36.464267, -105.058928];
Zipcodes['87715'] = [36.010924, -105.422539];
Zipcodes['87718'] = [36.597713, -105.239551];
Zipcodes['87722'] = [36.145776, -105.275569];
Zipcodes['87723'] = [36.058822, -105.375556];
Zipcodes['87724'] = [35.172012, -104.985298];
Zipcodes['87728'] = [36.603784, -104.594635];
Zipcodes['87729'] = [36.303412, -104.817842];
Zipcodes['87730'] = [36.108829, -104.231521];
Zipcodes['87731'] = [35.700849, -105.399029];
Zipcodes['87732'] = [35.976454, -105.465516];
Zipcodes['87733'] = [35.75266, -103.854816];
Zipcodes['87734'] = [36.169098, -105.091094];
Zipcodes['87735'] = [36.048689, -105.119781];
Zipcodes['87736'] = [35.97805, -105.180592];
Zipcodes['87740'] = [36.767149, -104.363941];
Zipcodes['87742'] = [35.871632, -105.444275];
Zipcodes['87743'] = [35.974399, -104.020342];
Zipcodes['87745'] = [35.790752, -105.266773];
Zipcodes['87746'] = [35.655214, -104.111228];
Zipcodes['87747'] = [36.354478, -104.436408];
Zipcodes['87749'] = [36.577591, -105.127259];
Zipcodes['87750'] = [35.824979, -104.927063];
Zipcodes['87752'] = [35.994218, -104.65737];
Zipcodes['87753'] = [35.863313, -104.954298];
Zipcodes['87801'] = [33.932613, -107.073841];
Zipcodes['87820'] = [33.937649, -108.58682];
Zipcodes['87821'] = [33.949882, -108.072146];
Zipcodes['87823'] = [34.183485, -106.958998];
Zipcodes['87824'] = [33.828581, -108.981719];
Zipcodes['87825'] = [33.949242, -107.655484];
Zipcodes['87827'] = [34.362281, -108.137414];
Zipcodes['87828'] = [34.205751, -106.92055];
Zipcodes['87829'] = [34.236618, -108.658481];
Zipcodes['87830'] = [33.708322, -108.732689];
Zipcodes['87831'] = [34.283021, -106.887208];
Zipcodes['87832'] = [33.842141, -106.834787];
Zipcodes['87901'] = [33.160855, -107.016787];
Zipcodes['87930'] = [32.827149, -107.351899];
Zipcodes['87931'] = [32.923922, -107.344226];
Zipcodes['87933'] = [32.791109, -107.278867];
Zipcodes['87935'] = [33.254706, -107.22865];
Zipcodes['87936'] = [32.753234, -107.260214];
Zipcodes['87937'] = [32.591271, -107.195218];
Zipcodes['87939'] = [33.373336, -107.337249];
Zipcodes['87940'] = [32.64207, -107.006626];
Zipcodes['87941'] = [32.706565, -107.214959];
Zipcodes['87942'] = [33.060968, -107.348906];
Zipcodes['87943'] = [33.450136, -107.69103];
Zipcodes['88001'] = [32.290212, -106.753138];
Zipcodes['88002'] = [32.382005, -106.457106];
Zipcodes['88003'] = [32.278723, -106.745667];
Zipcodes['88004'] = [32.250185, -106.949226];
Zipcodes['88005'] = [32.22305, -106.987591];
Zipcodes['88007'] = [32.389107, -106.975113];
Zipcodes['88008'] = [31.818657, -106.757282];
Zipcodes['88009'] = [31.912585, -108.536315];
Zipcodes['88011'] = [32.37716, -106.576316];
Zipcodes['88012'] = [32.588854, -106.771269];
Zipcodes['88020'] = [31.780985, -108.827111];
Zipcodes['88021'] = [31.987939, -106.80368];
Zipcodes['88022'] = [32.80274, -108.188137];
Zipcodes['88023'] = [32.722804, -108.088252];
Zipcodes['88024'] = [32.065401, -106.618009];
Zipcodes['88025'] = [33.108583, -108.725916];
Zipcodes['88026'] = [32.752019, -108.162996];
Zipcodes['88027'] = [32.036233, -106.681433];
Zipcodes['88028'] = [32.966912, -108.670549];
Zipcodes['88029'] = [31.844879, -107.694722];
Zipcodes['88030'] = [32.17345, -107.727421];
Zipcodes['88034'] = [32.591736, -107.965081];
Zipcodes['88038'] = [33.126399, -108.357592];
Zipcodes['88039'] = [33.293104, -108.575461];
Zipcodes['88040'] = [31.597235, -108.354972];
Zipcodes['88041'] = [32.810436, -107.882355];
Zipcodes['88042'] = [32.811929, -107.565713];
Zipcodes['88043'] = [32.602923, -108.116404];
Zipcodes['88044'] = [31.980785, -107.100742];
Zipcodes['88045'] = [32.331117, -108.700286];
Zipcodes['88046'] = [32.267757, -106.79682];
Zipcodes['88047'] = [32.214414, -106.715531];
Zipcodes['88048'] = [32.144679, -106.655035];
Zipcodes['88049'] = [33.051365, -108.05469];
Zipcodes['88051'] = [32.999584, -108.919849];
Zipcodes['88052'] = [32.450264, -106.601839];
Zipcodes['88053'] = [32.895982, -108.213797];
Zipcodes['88055'] = [32.727788, -108.740985];
Zipcodes['88056'] = [31.840794, -109.003105];
Zipcodes['88061'] = [32.628543, -108.406412];
Zipcodes['88063'] = [31.799062, -106.550459];
Zipcodes['88065'] = [32.71371, -108.302292];
Zipcodes['88072'] = [32.138064, -106.616106];
Zipcodes['88081'] = [32.219357, -106.293671];
Zipcodes['88101'] = [34.499488, -103.257721];
Zipcodes['88103'] = [34.382137, -103.316944];
Zipcodes['88112'] = [34.922782, -103.134424];
Zipcodes['88113'] = [33.804492, -103.088842];
Zipcodes['88114'] = [33.488489, -103.417431];
Zipcodes['88115'] = [33.931766, -103.34467];
Zipcodes['88116'] = [33.894862, -103.819006];
Zipcodes['88118'] = [34.229125, -103.659365];
Zipcodes['88119'] = [34.359273, -104.368696];
Zipcodes['88120'] = [34.899377, -103.424962];
Zipcodes['88121'] = [34.679819, -103.977198];
Zipcodes['88124'] = [34.536174, -103.65183];
Zipcodes['88125'] = [33.705861, -103.225418];
Zipcodes['88126'] = [33.811266, -103.372019];
Zipcodes['88130'] = [34.131526, -103.326663];
Zipcodes['88132'] = [33.907343, -103.166813];
Zipcodes['88134'] = [34.371703, -104.016711];
Zipcodes['88135'] = [34.559992, -103.077686];
Zipcodes['88136'] = [34.244075, -104.815925];
Zipcodes['88201'] = [33.668801, -104.496734];
Zipcodes['88203'] = [33.29067, -104.067489];
Zipcodes['88210'] = [32.774587, -104.224156];
Zipcodes['88220'] = [32.314867, -104.420014];
Zipcodes['88230'] = [33.21917, -104.397563];
Zipcodes['88231'] = [32.424271, -103.261279];
Zipcodes['88232'] = [33.057714, -104.146125];
Zipcodes['88240'] = [32.674675, -103.446805];
Zipcodes['88242'] = [32.812901, -103.161796];
Zipcodes['88244'] = [32.764127, -103.224949];
Zipcodes['88250'] = [32.701984, -104.955846];
Zipcodes['88252'] = [32.155238, -103.378041];
Zipcodes['88253'] = [32.983812, -104.55055];
Zipcodes['88254'] = [32.627878, -104.404823];
Zipcodes['88255'] = [32.810923, -103.99922];
Zipcodes['88256'] = [32.193262, -103.888887];
Zipcodes['88260'] = [32.993024, -103.382844];
Zipcodes['88262'] = [33.126398, -103.403889];
Zipcodes['88263'] = [32.107048, -104.096767];
Zipcodes['88264'] = [32.862974, -103.709883];
Zipcodes['88265'] = [32.584198, -103.346423];
Zipcodes['88267'] = [33.296728, -103.392341];
Zipcodes['88268'] = [32.176322, -104.379342];
Zipcodes['88301'] = [33.749089, -105.851999];
Zipcodes['88310'] = [32.735184, -106.040383];
Zipcodes['88311'] = [32.812102, -105.990607];
Zipcodes['88312'] = [33.431883, -105.64395];
Zipcodes['88314'] = [33.110333, -105.867203];
Zipcodes['88316'] = [33.770884, -105.370793];
Zipcodes['88317'] = [32.860606, -105.721271];
Zipcodes['88318'] = [34.228811, -105.512507];
Zipcodes['88321'] = [34.780375, -105.446147];
Zipcodes['88323'] = [33.485337, -105.564843];
Zipcodes['88324'] = [33.422541, -105.526952];
Zipcodes['88325'] = [32.855647, -105.853305];
Zipcodes['88330'] = [32.867091, -106.096608];
Zipcodes['88336'] = [33.331009, -105.275935];
Zipcodes['88337'] = [32.996446, -105.884495];
Zipcodes['88338'] = [33.537195, -105.384696];
Zipcodes['88339'] = [32.958055, -105.323386];
Zipcodes['88340'] = [33.176135, -105.583242];
Zipcodes['88341'] = [33.473475, -105.84559];
Zipcodes['88342'] = [32.389294, -106.108507];
Zipcodes['88343'] = [33.235068, -105.067785];
Zipcodes['88344'] = [32.508945, -105.217761];
Zipcodes['88345'] = [33.352439, -105.671005];
Zipcodes['88346'] = [33.348146, -105.540707];
Zipcodes['88347'] = [32.755123, -105.677393];
Zipcodes['88348'] = [33.414894, -105.402258];
Zipcodes['88349'] = [32.801462, -105.809662];
Zipcodes['88350'] = [32.613868, -105.65282];
Zipcodes['88351'] = [33.47152, -105.097724];
Zipcodes['88352'] = [33.165891, -105.993861];
Zipcodes['88353'] = [34.499108, -105.103307];
Zipcodes['88354'] = [32.738816, -105.494552];
Zipcodes['88355'] = [33.362914, -105.669404];
Zipcodes['88401'] = [35.074583, -103.780275];
Zipcodes['88410'] = [35.895504, -103.316361];
Zipcodes['88411'] = [35.247343, -103.17642];
Zipcodes['88414'] = [36.779584, -104.025877];
Zipcodes['88415'] = [36.362784, -103.343082];
Zipcodes['88416'] = [35.477253, -104.209466];
Zipcodes['88417'] = [34.930955, -104.244496];
Zipcodes['88418'] = [36.75136, -103.641609];
Zipcodes['88419'] = [36.899379, -103.541874];
Zipcodes['88421'] = [35.326882, -104.497305];
Zipcodes['88422'] = [36.345284, -103.991476];
Zipcodes['88424'] = [36.439537, -103.664381];
Zipcodes['88426'] = [35.518317, -103.447525];
Zipcodes['88427'] = [34.781751, -103.764187];
Zipcodes['88430'] = [35.670117, -103.209111];
Zipcodes['88431'] = [35.212488, -104.336778];
Zipcodes['88434'] = [35.120075, -103.335512];
Zipcodes['88435'] = [34.885885, -104.824923];
Zipcodes['88436'] = [36.158639, -103.158755];
Zipcodes['88439'] = [35.493775, -104.378213];
Zipcodes['89001'] = [37.337655, -115.302378];
Zipcodes['89002'] = [35.99846, -114.961803];
Zipcodes['89003'] = [36.946108, -116.764346];
Zipcodes['89004'] = [36.041627, -115.412576];
Zipcodes['89005'] = [35.999628, -114.85204];
Zipcodes['89007'] = [36.666657, -114.226747];
Zipcodes['89008'] = [37.286087, -114.513105];
Zipcodes['89010'] = [37.824694, -118.079197];
Zipcodes['89011'] = [36.084816, -114.96318];
Zipcodes['89012'] = [36.011917, -115.043158];
Zipcodes['89013'] = [37.368945, -117.262121];
Zipcodes['89014'] = [36.061545, -115.057671];
Zipcodes['89015'] = [36.03889, -114.928316];
Zipcodes['89017'] = [37.702215, -115.333645];
Zipcodes['89018'] = [36.570645, -115.668006];
Zipcodes['89019'] = [35.769491, -115.515911];
Zipcodes['89020'] = [36.562525, -116.41983];
Zipcodes['89021'] = [36.640213, -114.471026];
Zipcodes['89022'] = [38.518412, -117.014924];
Zipcodes['89025'] = [36.683211, -114.789729];
Zipcodes['89026'] = [35.749227, -115.284749];
Zipcodes['89027'] = [36.814532, -114.093334];
Zipcodes['89029'] = [35.129747, -114.655672];
Zipcodes['89030'] = [36.212305, -115.123134];
Zipcodes['89031'] = [36.258457, -115.170743];
Zipcodes['89032'] = [36.222386, -115.173585];
Zipcodes['89034'] = [36.809235, -114.149779];
Zipcodes['89039'] = [35.26574, -114.872449];
Zipcodes['89040'] = [36.349077, -114.52233];
Zipcodes['89042'] = [37.798162, -114.358597];
Zipcodes['89043'] = [38.277492, -114.407217];
Zipcodes['89044'] = [35.93987, -115.056285];
Zipcodes['89045'] = [38.873308, -117.098251];
Zipcodes['89046'] = [35.513924, -114.810598];
Zipcodes['89047'] = [37.757726, -117.63865];
Zipcodes['89048'] = [36.163631, -115.98748];
Zipcodes['89049'] = [38.390984, -116.399315];
Zipcodes['89052'] = [35.979874, -115.107944];
Zipcodes['89054'] = [35.920973, -115.190516];
Zipcodes['89060'] = [36.391556, -116.057568];
Zipcodes['89061'] = [36.07011, -115.906249];
Zipcodes['89070'] = [36.500819, -115.63956];
Zipcodes['89074'] = [36.03637, -115.081186];
Zipcodes['89081'] = [36.258477, -115.107492];
Zipcodes['89084'] = [36.297486, -115.156932];
Zipcodes['89085'] = [36.309602, -115.198175];
Zipcodes['89086'] = [36.2836, -115.109962];
Zipcodes['89101'] = [36.172788, -115.122324];
Zipcodes['89102'] = [36.14534, -115.186607];
Zipcodes['89103'] = [36.11236, -115.212148];
Zipcodes['89104'] = [36.151188, -115.10937];
Zipcodes['89106'] = [36.182066, -115.162487];
Zipcodes['89107'] = [36.171241, -115.209288];
Zipcodes['89108'] = [36.205088, -115.223683];
Zipcodes['89109'] = [36.131622, -115.169932];
Zipcodes['89110'] = [36.173653, -115.055479];
Zipcodes['89113'] = [36.060204, -115.262325];
Zipcodes['89115'] = [36.253702, -115.042936];
Zipcodes['89117'] = [36.140961, -115.281091];
Zipcodes['89118'] = [36.077788, -115.213011];
Zipcodes['89119'] = [36.08452, -115.146071];
Zipcodes['89120'] = [36.080652, -115.095868];
Zipcodes['89121'] = [36.12141, -115.091367];
Zipcodes['89122'] = [36.103965, -115.037826];
Zipcodes['89123'] = [36.035216, -115.149123];
Zipcodes['89124'] = [36.391806, -115.68302];
Zipcodes['89128'] = [36.196784, -115.264406];
Zipcodes['89129'] = [36.23325, -115.290253];
Zipcodes['89130'] = [36.253482, -115.226706];
Zipcodes['89131'] = [36.306143, -115.243024];
Zipcodes['89134'] = [36.202627, -115.307808];
Zipcodes['89135'] = [36.095302, -115.37165];
Zipcodes['89138'] = [36.202093, -115.364999];
Zipcodes['89139'] = [36.034324, -115.211139];
Zipcodes['89141'] = [35.988147, -115.207423];
Zipcodes['89142'] = [36.148113, -115.031001];
Zipcodes['89143'] = [36.321693, -115.292872];
Zipcodes['89144'] = [36.177489, -115.319431];
Zipcodes['89145'] = [36.167493, -115.276239];
Zipcodes['89146'] = [36.14325, -115.226789];
Zipcodes['89147'] = [36.112892, -115.27987];
Zipcodes['89148'] = [36.063355, -115.296763];
Zipcodes['89149'] = [36.270796, -115.294623];
Zipcodes['89156'] = [36.159032, -114.981742];
Zipcodes['89158'] = [36.104781, -115.176621];
Zipcodes['89161'] = [36.03739, -115.508236];
Zipcodes['89166'] = [36.337311, -115.468547];
Zipcodes['89169'] = [36.124089, -115.141811];
Zipcodes['89178'] = [35.987827, -115.295352];
Zipcodes['89179'] = [35.891605, -115.327935];
Zipcodes['89183'] = [35.996194, -115.157976];
Zipcodes['89191'] = [36.247282, -115.004186];
Zipcodes['89301'] = [39.531134, -114.767404];
Zipcodes['89310'] = [39.474204, -117.140299];
Zipcodes['89311'] = [39.236499, -114.140629];
Zipcodes['89314'] = [38.862677, -115.641802];
Zipcodes['89315'] = [39.185485, -114.844867];
Zipcodes['89316'] = [39.671344, -116.013637];
Zipcodes['89317'] = [38.904533, -115.016277];
Zipcodes['89318'] = [39.438588, -114.817216];
Zipcodes['89319'] = [39.279172, -114.987342];
Zipcodes['89402'] = [39.230418, -120.002174];
Zipcodes['89403'] = [39.227048, -119.474346];
Zipcodes['89404'] = [41.841663, -118.878658];
Zipcodes['89405'] = [40.450473, -119.469236];
Zipcodes['89406'] = [39.358998, -117.946764];
Zipcodes['89408'] = [39.670843, -119.006409];
Zipcodes['89409'] = [38.7265, -117.949713];
Zipcodes['89410'] = [38.783116, -119.55929];
Zipcodes['89411'] = [39.018363, -119.853038];
Zipcodes['89412'] = [41.121163, -119.704982];
Zipcodes['89413'] = [39.073447, -119.922857];
Zipcodes['89414'] = [41.061054, -117.220629];
Zipcodes['89415'] = [38.517193, -118.788741];
Zipcodes['89418'] = [40.574412, -118.041481];
Zipcodes['89419'] = [40.342885, -118.287699];
Zipcodes['89420'] = [38.508039, -118.180527];
Zipcodes['89421'] = [42.044517, -117.947009];
Zipcodes['89422'] = [38.430363, -118.180623];
Zipcodes['89423'] = [38.997135, -119.735334];
Zipcodes['89424'] = [40.108309, -119.448215];
Zipcodes['89425'] = [41.658303, -117.881592];
Zipcodes['89426'] = [41.477569, -117.61734];
Zipcodes['89427'] = [38.899942, -118.645827];
Zipcodes['89428'] = [39.256879, -119.630973];
Zipcodes['89429'] = [39.356861, -119.269648];
Zipcodes['89430'] = [38.795686, -119.317837];
Zipcodes['89431'] = [39.538324, -119.749794];
Zipcodes['89433'] = [39.597562, -119.778137];
Zipcodes['89434'] = [39.540617, -119.543993];
Zipcodes['89436'] = [39.617274, -119.704343];
Zipcodes['89437'] = [39.501156, -119.468805];
Zipcodes['89438'] = [40.80374, -117.127161];
Zipcodes['89439'] = [39.523352, -119.990384];
Zipcodes['89440'] = [39.296058, -119.662018];
Zipcodes['89441'] = [39.68139, -119.687547];
Zipcodes['89442'] = [39.693035, -119.344768];
Zipcodes['89444'] = [38.739834, -119.38272];
Zipcodes['89445'] = [41.121069, -118.008548];
Zipcodes['89446'] = [40.878797, -117.953217];
Zipcodes['89447'] = [38.829718, -119.11477];
Zipcodes['89448'] = [38.998185, -119.914964];
Zipcodes['89449'] = [38.955158, -119.911473];
Zipcodes['89450'] = [39.241348, -119.938309];
Zipcodes['89451'] = [39.269009, -119.945726];
Zipcodes['89460'] = [38.902253, -119.792493];
Zipcodes['89496'] = [39.19947, -118.571012];
Zipcodes['89501'] = [39.526012, -119.812637];
Zipcodes['89502'] = [39.494062, -119.742329];
Zipcodes['89503'] = [39.541037, -119.840128];
Zipcodes['89506'] = [39.698837, -119.826268];
Zipcodes['89508'] = [39.78062, -119.913755];
Zipcodes['89509'] = [39.497313, -119.826926];
Zipcodes['89510'] = [39.926336, -119.710793];
Zipcodes['89511'] = [39.425376, -119.871891];
Zipcodes['89512'] = [39.550957, -119.801071];
Zipcodes['89519'] = [39.478968, -119.857134];
Zipcodes['89521'] = [39.371633, -119.675912];
Zipcodes['89523'] = [39.526594, -119.915771];
Zipcodes['89557'] = [39.542443, -119.815919];
Zipcodes['89701'] = [39.12893, -119.725413];
Zipcodes['89703'] = [39.136939, -119.869938];
Zipcodes['89704'] = [39.275258, -119.787456];
Zipcodes['89705'] = [39.090042, -119.836881];
Zipcodes['89706'] = [39.222737, -119.701574];
Zipcodes['89801'] = [41.165012, -115.757235];
Zipcodes['89815'] = [40.56473, -115.596546];
Zipcodes['89820'] = [40.428841, -116.961096];
Zipcodes['89821'] = [40.555728, -116.415688];
Zipcodes['89822'] = [40.4922, -116.188965];
Zipcodes['89823'] = [40.950306, -115.160662];
Zipcodes['89825'] = [41.904428, -114.709644];
Zipcodes['89826'] = [41.930723, -115.410766];
Zipcodes['89828'] = [40.735333, -115.34339];
Zipcodes['89830'] = [41.321657, -114.177126];
Zipcodes['89831'] = [41.715996, -116.098342];
Zipcodes['89832'] = [41.978749, -116.191836];
Zipcodes['89833'] = [40.482842, -115.331629];
Zipcodes['89834'] = [41.408342, -116.228124];
Zipcodes['89835'] = [41.276395, -114.873835];
Zipcodes['89883'] = [40.84892, -114.157677];
Zipcodes['90001'] = [33.973665, -118.249039];
Zipcodes['90002'] = [33.948987, -118.24678];
Zipcodes['90003'] = [33.964077, -118.273684];
Zipcodes['90004'] = [34.076219, -118.310963];
Zipcodes['90005'] = [34.059374, -118.308962];
Zipcodes['90006'] = [34.048094, -118.294045];
Zipcodes['90007'] = [34.028165, -118.284829];
Zipcodes['90008'] = [34.010724, -118.342883];
Zipcodes['90010'] = [34.060987, -118.299884];
Zipcodes['90011'] = [34.00709, -118.258681];
Zipcodes['90012'] = [34.065975, -118.238642];
Zipcodes['90013'] = [34.044685, -118.240022];
Zipcodes['90014'] = [34.043006, -118.2519];
Zipcodes['90015'] = [34.039309, -118.266255];
Zipcodes['90016'] = [34.028331, -118.354338];
Zipcodes['90017'] = [34.053098, -118.264505];
Zipcodes['90018'] = [34.028632, -118.317347];
Zipcodes['90019'] = [34.049841, -118.33846];
Zipcodes['90020'] = [34.066382, -118.309831];
Zipcodes['90021'] = [34.029036, -118.23787];
Zipcodes['90022'] = [34.023728, -118.156275];
Zipcodes['90023'] = [34.022502, -118.199613];
Zipcodes['90024'] = [34.065645, -118.435284];
Zipcodes['90025'] = [34.045421, -118.445873];
Zipcodes['90026'] = [34.078991, -118.263804];
Zipcodes['90027'] = [34.127603, -118.296381];
Zipcodes['90028'] = [34.099811, -118.326837];
Zipcodes['90029'] = [34.089875, -118.294631];
Zipcodes['90031'] = [34.084197, -118.208567];
Zipcodes['90032'] = [34.078294, -118.185785];
Zipcodes['90033'] = [34.05038, -118.211991];
Zipcodes['90034'] = [34.030578, -118.399613];
Zipcodes['90035'] = [34.051426, -118.385765];
Zipcodes['90036'] = [34.070291, -118.349191];
Zipcodes['90037'] = [34.00266, -118.287507];
Zipcodes['90038'] = [34.088017, -118.327168];
Zipcodes['90039'] = [34.111885, -118.261033];
Zipcodes['90040'] = [33.994523, -118.149952];
Zipcodes['90041'] = [34.137412, -118.207607];
Zipcodes['90042'] = [34.114708, -118.192098];
Zipcodes['90043'] = [33.988505, -118.336358];
Zipcodes['90044'] = [33.952653, -118.292013];
Zipcodes['90045'] = [33.942387, -118.416062];
Zipcodes['90046'] = [34.10781, -118.362247];
Zipcodes['90047'] = [33.95543, -118.309568];
Zipcodes['90048'] = [34.072877, -118.372655];
Zipcodes['90049'] = [34.09254, -118.491064];
Zipcodes['90052'] = [33.978304, -118.258907];
Zipcodes['90056'] = [33.993581, -118.368839];
Zipcodes['90057'] = [34.061735, -118.276757];
Zipcodes['90058'] = [34.002844, -118.2164];
Zipcodes['90059'] = [33.927469, -118.248796];
Zipcodes['90061'] = [33.92128, -118.274186];
Zipcodes['90062'] = [34.003493, -118.309093];
Zipcodes['90063'] = [34.045248, -118.18578];
Zipcodes['90064'] = [34.035309, -118.426486];
Zipcodes['90065'] = [34.109595, -118.228689];
Zipcodes['90066'] = [34.002028, -118.430656];
Zipcodes['90067'] = [34.056437, -118.41369];
Zipcodes['90068'] = [34.124179, -118.327351];
Zipcodes['90069'] = [34.093953, -118.381531];
Zipcodes['90071'] = [34.052379, -118.255209];
Zipcodes['90073'] = [34.05733, -118.459534];
Zipcodes['90077'] = [34.108023, -118.456964];
Zipcodes['90079'] = [34.040587, -118.255404];
Zipcodes['90089'] = [34.021543, -118.285574];
Zipcodes['90094'] = [33.975307, -118.418708];
Zipcodes['90095'] = [34.071183, -118.443404];
Zipcodes['90201'] = [33.970343, -118.171368];
Zipcodes['90210'] = [34.100517, -118.41463];
Zipcodes['90211'] = [34.064956, -118.382981];
Zipcodes['90212'] = [34.06216, -118.401882];
Zipcodes['90220'] = [33.87978, -118.229679];
Zipcodes['90221'] = [33.885811, -118.206459];
Zipcodes['90222'] = [33.91224, -118.237607];
Zipcodes['90230'] = [33.997862, -118.393617];
Zipcodes['90232'] = [34.019323, -118.391902];
Zipcodes['90240'] = [33.955729, -118.118346];
Zipcodes['90241'] = [33.940582, -118.129472];
Zipcodes['90242'] = [33.921793, -118.140588];
Zipcodes['90245'] = [33.917145, -118.401554];
Zipcodes['90247'] = [33.891769, -118.297006];
Zipcodes['90248'] = [33.87834, -118.2841];
Zipcodes['90249'] = [33.90139, -118.315697];
Zipcodes['90250'] = [33.913651, -118.350946];
Zipcodes['90254'] = [33.864259, -118.399303];
Zipcodes['90255'] = [33.979248, -118.217438];
Zipcodes['90260'] = [33.888534, -118.349564];
Zipcodes['90262'] = [33.923831, -118.201352];
Zipcodes['90263'] = [34.039281, -118.708146];
Zipcodes['90265'] = [34.070245, -118.851042];
Zipcodes['90266'] = [33.891787, -118.402814];
Zipcodes['90270'] = [33.988557, -118.187688];
Zipcodes['90272'] = [34.092567, -118.534363];
Zipcodes['90274'] = [33.7801, -118.372412];
Zipcodes['90275'] = [33.753134, -118.367973];
Zipcodes['90277'] = [33.830006, -118.387124];
Zipcodes['90278'] = [33.873605, -118.370264];
Zipcodes['90280'] = [33.944159, -118.192761];
Zipcodes['90290'] = [34.107752, -118.616218];
Zipcodes['90291'] = [33.993396, -118.465193];
Zipcodes['90292'] = [33.976078, -118.452066];
Zipcodes['90293'] = [33.947305, -118.439841];
Zipcodes['90301'] = [33.956464, -118.358681];
Zipcodes['90302'] = [33.97535, -118.35609];
Zipcodes['90303'] = [33.936291, -118.332639];
Zipcodes['90304'] = [33.93736, -118.358795];
Zipcodes['90305'] = [33.958134, -118.330905];
Zipcodes['90401'] = [34.016099, -118.492454];
Zipcodes['90402'] = [34.034666, -118.504039];
Zipcodes['90403'] = [34.030755, -118.492101];
Zipcodes['90404'] = [34.026649, -118.473835];
Zipcodes['90405'] = [34.011057, -118.472152];
Zipcodes['90501'] = [33.833744, -118.314022];
Zipcodes['90502'] = [33.833181, -118.292062];
Zipcodes['90503'] = [33.839463, -118.353699];
Zipcodes['90504'] = [33.86728, -118.331642];
Zipcodes['90505'] = [33.807882, -118.347957];
Zipcodes['90506'] = [33.884696, -118.329998];
Zipcodes['90601'] = [34.007937, -118.030324];
Zipcodes['90602'] = [33.974242, -118.02233];
Zipcodes['90603'] = [33.944866, -117.992338];
Zipcodes['90604'] = [33.927691, -118.013828];
Zipcodes['90605'] = [33.952444, -118.0214];
Zipcodes['90606'] = [33.977272, -118.066363];
Zipcodes['90620'] = [33.845223, -118.015292];
Zipcodes['90621'] = [33.874224, -117.993368];
Zipcodes['90623'] = [33.850616, -118.039565];
Zipcodes['90630'] = [33.818477, -118.038307];
Zipcodes['90631'] = [33.942708, -117.952483];
Zipcodes['90638'] = [33.902045, -118.008961];
Zipcodes['90639'] = [33.905746, -118.014907];
Zipcodes['90640'] = [34.015444, -118.111012];
Zipcodes['90650'] = [33.90779, -118.082487];
Zipcodes['90660'] = [33.989524, -118.089295];
Zipcodes['90670'] = [33.933565, -118.062611];
Zipcodes['90680'] = [33.80114, -117.995327];
Zipcodes['90701'] = [33.867605, -118.080631];
Zipcodes['90703'] = [33.867745, -118.069471];
Zipcodes['90704'] = [33.399325, -118.435329];
Zipcodes['90706'] = [33.887821, -118.12725];
Zipcodes['90710'] = [33.798905, -118.300239];
Zipcodes['90712'] = [33.849034, -118.147902];
Zipcodes['90713'] = [33.848022, -118.112746];
Zipcodes['90715'] = [33.841027, -118.079141];
Zipcodes['90716'] = [33.830473, -118.072959];
Zipcodes['90717'] = [33.793456, -118.317054];
Zipcodes['90720'] = [33.795012, -118.062692];
Zipcodes['90723'] = [33.898883, -118.166629];
Zipcodes['90731'] = [33.732353, -118.278151];
Zipcodes['90732'] = [33.737321, -118.313433];
Zipcodes['90740'] = [33.754334, -118.073267];
Zipcodes['90742'] = [33.721015, -118.07617];
Zipcodes['90743'] = [33.72953, -118.092436];
Zipcodes['90744'] = [33.777214, -118.259604];
Zipcodes['90745'] = [33.820268, -118.264125];
Zipcodes['90746'] = [33.859231, -118.252312];
Zipcodes['90747'] = [33.863785, -118.251354];
Zipcodes['90755'] = [33.803166, -118.167132];
Zipcodes['90802'] = [33.751279, -118.20682];
Zipcodes['90803'] = [33.754191, -118.132363];
Zipcodes['90804'] = [33.781686, -118.148365];
Zipcodes['90805'] = [33.864617, -118.180567];
Zipcodes['90806'] = [33.80887, -118.178486];
Zipcodes['90807'] = [33.830205, -118.181181];
Zipcodes['90808'] = [33.823943, -118.111335];
Zipcodes['90810'] = [33.819841, -118.222378];
Zipcodes['90813'] = [33.782259, -118.196793];
Zipcodes['90814'] = [33.771581, -118.143361];
Zipcodes['90815'] = [33.795067, -118.115724];
Zipcodes['90822'] = [33.778436, -118.118648];
Zipcodes['90831'] = [33.768308, -118.200762];
Zipcodes['90840'] = [33.784566, -118.121313];
Zipcodes['91001'] = [34.194489, -118.138263];
Zipcodes['91006'] = [34.135159, -118.026685];
Zipcodes['91007'] = [34.128284, -118.047732];
Zipcodes['91008'] = [34.153159, -117.968818];
Zipcodes['91010'] = [34.141012, -117.958661];
Zipcodes['91011'] = [34.217935, -118.202255];
Zipcodes['91016'] = [34.1528, -118.000482];
Zipcodes['91020'] = [34.21137, -118.230688];
Zipcodes['91024'] = [34.175276, -118.046017];
Zipcodes['91030'] = [34.108957, -118.156615];
Zipcodes['91040'] = [34.257696, -118.338455];
Zipcodes['91042'] = [34.315503, -118.264847];
Zipcodes['91046'] = [34.211677, -118.240922];
Zipcodes['91101'] = [34.146771, -118.138922];
Zipcodes['91103'] = [34.167361, -118.164144];
Zipcodes['91104'] = [34.165356, -118.123751];
Zipcodes['91105'] = [34.13946, -118.166649];
Zipcodes['91106'] = [34.139402, -118.128658];
Zipcodes['91107'] = [34.159007, -118.087353];
Zipcodes['91108'] = [34.122372, -118.114234];
Zipcodes['91125'] = [34.137644, -118.124952];
Zipcodes['91201'] = [34.17051, -118.289463];
Zipcodes['91202'] = [34.183302, -118.264478];
Zipcodes['91203'] = [34.153338, -118.262974];
Zipcodes['91204'] = [34.135557, -118.26094];
Zipcodes['91205'] = [34.13658, -118.245839];
Zipcodes['91206'] = [34.160359, -118.213769];
Zipcodes['91207'] = [34.167939, -118.244241];
Zipcodes['91208'] = [34.185665, -118.239497];
Zipcodes['91210'] = [34.144653, -118.258061];
Zipcodes['91214'] = [34.235728, -118.246655];
Zipcodes['91301'] = [34.123286, -118.758541];
Zipcodes['91302'] = [34.122556, -118.669821];
Zipcodes['91303'] = [34.19829, -118.602203];
Zipcodes['91304'] = [34.227791, -118.644607];
Zipcodes['91306'] = [34.209532, -118.577563];
Zipcodes['91307'] = [34.200485, -118.664852];
Zipcodes['91311'] = [34.292495, -118.602424];
Zipcodes['91316'] = [34.158542, -118.516597];
Zipcodes['91320'] = [34.173377, -118.947977];
Zipcodes['91321'] = [34.363715, -118.487039];
Zipcodes['91324'] = [34.238208, -118.55029];
Zipcodes['91325'] = [34.237519, -118.517712];
Zipcodes['91326'] = [34.281775, -118.555697];
Zipcodes['91330'] = [34.244522, -118.526677];
Zipcodes['91331'] = [34.255294, -118.419703];
Zipcodes['91335'] = [34.200175, -118.540958];
Zipcodes['91340'] = [34.28667, -118.434817];
Zipcodes['91342'] = [34.326584, -118.382261];
Zipcodes['91343'] = [34.238802, -118.482296];
Zipcodes['91344'] = [34.289467, -118.504699];
Zipcodes['91345'] = [34.265879, -118.456748];
Zipcodes['91350'] = [34.430599, -118.502774];
Zipcodes['91351'] = [34.433727, -118.458061];
Zipcodes['91352'] = [34.230854, -118.365856];
Zipcodes['91354'] = [34.458422, -118.55414];
Zipcodes['91355'] = [34.40185, -118.570014];
Zipcodes['91356'] = [34.154999, -118.547559];
Zipcodes['91360'] = [34.213305, -118.874135];
Zipcodes['91361'] = [34.137283, -118.882044];
Zipcodes['91362'] = [34.192431, -118.819486];
Zipcodes['91364'] = [34.154803, -118.594816];
Zipcodes['91367'] = [34.177311, -118.615704];
Zipcodes['91371'] = [34.185015, -118.576088];
Zipcodes['91377'] = [34.184724, -118.766517];
Zipcodes['91381'] = [34.373221, -118.607971];
Zipcodes['91384'] = [34.520101, -118.668276];
Zipcodes['91387'] = [34.402519, -118.363463];
Zipcodes['91390'] = [34.576515, -118.492764];
Zipcodes['91401'] = [34.178483, -118.431791];
Zipcodes['91402'] = [34.222506, -118.444689];
Zipcodes['91403'] = [34.147757, -118.464738];
Zipcodes['91405'] = [34.202494, -118.448048];
Zipcodes['91406'] = [34.197454, -118.488978];
Zipcodes['91411'] = [34.178517, -118.45963];
Zipcodes['91423'] = [34.149493, -118.431882];
Zipcodes['91436'] = [34.149588, -118.489708];
Zipcodes['91501'] = [34.193527, -118.294006];
Zipcodes['91502'] = [34.176817, -118.309323];
Zipcodes['91504'] = [34.213006, -118.317736];
Zipcodes['91505'] = [34.175833, -118.347536];
Zipcodes['91506'] = [34.169706, -118.323548];
Zipcodes['91601'] = [34.168253, -118.372286];
Zipcodes['91602'] = [34.150818, -118.368159];
Zipcodes['91604'] = [34.140187, -118.394603];
Zipcodes['91605'] = [34.207341, -118.400973];
Zipcodes['91606'] = [34.185767, -118.388323];
Zipcodes['91607'] = [34.165783, -118.399795];
Zipcodes['91608'] = [34.138735, -118.350857];
Zipcodes['91701'] = [34.137828, -117.600301];
Zipcodes['91702'] = [34.281525, -117.80009];
Zipcodes['91706'] = [34.09741, -117.968269];
Zipcodes['91708'] = [33.952084, -117.639784];
Zipcodes['91709'] = [33.942969, -117.725648];
Zipcodes['91710'] = [34.005097, -117.682525];
Zipcodes['91711'] = [34.128066, -117.714773];
Zipcodes['91722'] = [34.097333, -117.906707];
Zipcodes['91723'] = [34.084747, -117.886844];
Zipcodes['91724'] = [34.078991, -117.854411];
Zipcodes['91730'] = [34.100402, -117.581282];
Zipcodes['91731'] = [34.078573, -118.043456];
Zipcodes['91732'] = [34.072407, -118.014524];
Zipcodes['91733'] = [34.045809, -118.053756];
Zipcodes['91737'] = [34.155238, -117.578097];
Zipcodes['91739'] = [34.156386, -117.516936];
Zipcodes['91740'] = [34.119367, -117.855054];
Zipcodes['91741'] = [34.163363, -117.834121];
Zipcodes['91744'] = [34.0313, -117.936777];
Zipcodes['91745'] = [33.999005, -117.972146];
Zipcodes['91746'] = [34.043304, -117.986859];
Zipcodes['91748'] = [33.978293, -117.902317];
Zipcodes['91750'] = [34.148058, -117.766635];
Zipcodes['91752'] = [33.996484, -117.531335];
Zipcodes['91754'] = [34.052922, -118.143465];
Zipcodes['91755'] = [34.050965, -118.114394];
Zipcodes['91759'] = [34.263699, -117.699574];
Zipcodes['91761'] = [34.035602, -117.591528];
Zipcodes['91762'] = [34.059409, -117.669298];
Zipcodes['91763'] = [34.07206, -117.698288];
Zipcodes['91764'] = [34.074087, -117.605618];
Zipcodes['91765'] = [33.985166, -117.812005];
Zipcodes['91766'] = [34.042286, -117.756106];
Zipcodes['91767'] = [34.083086, -117.737997];
Zipcodes['91768'] = [34.064363, -117.79093];
Zipcodes['91770'] = [34.065108, -118.082791];
Zipcodes['91773'] = [34.107485, -117.808752];
Zipcodes['91775'] = [34.115261, -118.088311];
Zipcodes['91776'] = [34.088952, -118.094468];
Zipcodes['91780'] = [34.101509, -118.055859];
Zipcodes['91784'] = [34.141146, -117.655583];
Zipcodes['91786'] = [34.10525, -117.66207];
Zipcodes['91789'] = [34.018195, -117.854821];
Zipcodes['91790'] = [34.066964, -117.937007];
Zipcodes['91791'] = [34.061624, -117.892235];
Zipcodes['91792'] = [34.022581, -117.902359];
Zipcodes['91801'] = [34.090924, -118.127761];
Zipcodes['91803'] = [34.074736, -118.145959];
Zipcodes['91901'] = [32.813624, -116.726969];
Zipcodes['91902'] = [32.673217, -117.006101];
Zipcodes['91905'] = [32.678648, -116.307485];
Zipcodes['91906'] = [32.65805, -116.476042];
Zipcodes['91910'] = [32.637096, -117.061758];
Zipcodes['91911'] = [32.607352, -117.05036];
Zipcodes['91913'] = [32.622895, -116.986892];
Zipcodes['91914'] = [32.66604, -116.95424];
Zipcodes['91915'] = [32.623602, -116.949804];
Zipcodes['91916'] = [32.911035, -116.632006];
Zipcodes['91917'] = [32.641996, -116.753767];
Zipcodes['91931'] = [32.837596, -116.580305];
Zipcodes['91932'] = [32.565354, -117.114567];
Zipcodes['91934'] = [32.648992, -116.152565];
Zipcodes['91935'] = [32.694344, -116.775514];
Zipcodes['91941'] = [32.759266, -116.997091];
Zipcodes['91942'] = [32.777267, -117.021344];
Zipcodes['91945'] = [32.733147, -117.034068];
Zipcodes['91948'] = [32.864953, -116.473036];
Zipcodes['91950'] = [32.669067, -117.093487];
Zipcodes['91962'] = [32.846766, -116.519775];
Zipcodes['91963'] = [32.647953, -116.596357];
Zipcodes['91977'] = [32.726237, -116.994318];
Zipcodes['91978'] = [32.698921, -116.930959];
Zipcodes['91980'] = [32.585187, -116.63254];
Zipcodes['92003'] = [33.285274, -117.197777];
Zipcodes['92004'] = [33.197705, -116.167463];
Zipcodes['92007'] = [33.023046, -117.275581];
Zipcodes['92008'] = [33.14248, -117.313444];
Zipcodes['92009'] = [33.095339, -117.245769];
Zipcodes['92010'] = [33.165381, -117.281427];
Zipcodes['92011'] = [33.106764, -117.296327];
Zipcodes['92014'] = [32.963232, -117.250367];
Zipcodes['92019'] = [32.778844, -116.871415];
Zipcodes['92020'] = [32.795605, -116.969754];
Zipcodes['92021'] = [32.835732, -116.874617];
Zipcodes['92024'] = [33.056868, -117.257577];
Zipcodes['92025'] = [33.077814, -117.033131];
Zipcodes['92026'] = [33.210965, -117.124788];
Zipcodes['92027'] = [33.131601, -116.976937];
Zipcodes['92028'] = [33.386469, -117.224957];
Zipcodes['92029'] = [33.08553, -117.123967];
Zipcodes['92036'] = [33.032413, -116.265177];
Zipcodes['92037'] = [32.854642, -117.251772];
Zipcodes['92040'] = [32.90891, -116.891972];
Zipcodes['92054'] = [33.193069, -117.359676];
Zipcodes['92055'] = [33.411196, -117.433259];
Zipcodes['92056'] = [33.199582, -117.298764];
Zipcodes['92057'] = [33.25462, -117.283311];
Zipcodes['92058'] = [33.263974, -117.344777];
Zipcodes['92059'] = [33.382822, -117.051995];
Zipcodes['92060'] = [33.350376, -116.864443];
Zipcodes['92061'] = [33.300352, -116.930524];
Zipcodes['92064'] = [32.984352, -117.013537];
Zipcodes['92065'] = [33.040373, -116.830075];
Zipcodes['92066'] = [33.215412, -116.535834];
Zipcodes['92067'] = [33.020908, -117.190346];
Zipcodes['92069'] = [33.175303, -117.157621];
Zipcodes['92070'] = [33.188575, -116.74328];
Zipcodes['92071'] = [32.850685, -116.995314];
Zipcodes['92075'] = [32.997248, -117.26058];
Zipcodes['92078'] = [33.117065, -117.180807];
Zipcodes['92081'] = [33.164549, -117.24055];
Zipcodes['92082'] = [33.251828, -117.002794];
Zipcodes['92083'] = [33.198084, -117.248364];
Zipcodes['92084'] = [33.227283, -117.200085];
Zipcodes['92086'] = [33.313998, -116.629537];
Zipcodes['92091'] = [33.052389, -117.182817];
Zipcodes['92092'] = [32.875545, -117.236144];
Zipcodes['92093'] = [32.882118, -117.237083];
Zipcodes['92096'] = [33.128156, -117.156194];
Zipcodes['92101'] = [32.717807, -117.172086];
Zipcodes['92102'] = [32.715981, -117.117495];
Zipcodes['92103'] = [32.747816, -117.167332];
Zipcodes['92104'] = [32.741933, -117.127814];
Zipcodes['92105'] = [32.740404, -117.089868];
Zipcodes['92106'] = [32.709885, -117.237857];
Zipcodes['92107'] = [32.739055, -117.245404];
Zipcodes['92108'] = [32.773983, -117.142706];
Zipcodes['92109'] = [32.786772, -117.234207];
Zipcodes['92110'] = [32.765244, -117.200391];
Zipcodes['92111'] = [32.806482, -117.168966];
Zipcodes['92113'] = [32.694637, -117.119756];
Zipcodes['92114'] = [32.707751, -117.055297];
Zipcodes['92115'] = [32.761549, -117.071905];
Zipcodes['92116'] = [32.764841, -117.123124];
Zipcodes['92117'] = [32.824893, -117.199714];
Zipcodes['92118'] = [32.698795, -117.20911];
Zipcodes['92119'] = [32.817888, -117.031956];
Zipcodes['92120'] = [32.793994, -117.073796];
Zipcodes['92121'] = [32.897123, -117.201647];
Zipcodes['92122'] = [32.856798, -117.210156];
Zipcodes['92123'] = [32.809615, -117.134201];
Zipcodes['92124'] = [32.828255, -117.085262];
Zipcodes['92126'] = [32.91199, -117.143421];
Zipcodes['92127'] = [33.018765, -117.124098];
Zipcodes['92128'] = [32.996967, -117.072979];
Zipcodes['92129'] = [32.963536, -117.126221];
Zipcodes['92130'] = [32.947616, -117.205825];
Zipcodes['92131'] = [32.899687, -117.047318];
Zipcodes['92132'] = [32.713672, -117.172039];
Zipcodes['92134'] = [32.725055, -117.145415];
Zipcodes['92135'] = [32.694976, -117.194354];
Zipcodes['92136'] = [32.683013, -117.122978];
Zipcodes['92139'] = [32.680031, -117.048886];
Zipcodes['92140'] = [32.741279, -117.197714];
Zipcodes['92145'] = [32.868496, -117.143172];
Zipcodes['92147'] = [32.724745, -117.21875];
Zipcodes['92154'] = [32.579101, -116.966528];
Zipcodes['92155'] = [32.658116, -117.156946];
Zipcodes['92161'] = [32.874072, -117.231563];
Zipcodes['92173'] = [32.555577, -117.051191];
Zipcodes['92179'] = [32.583904, -116.933475];
Zipcodes['92182'] = [32.774983, -117.074228];
Zipcodes['92201'] = [33.709453, -116.236967];
Zipcodes['92203'] = [33.751601, -116.241426];
Zipcodes['92210'] = [33.703465, -116.325898];
Zipcodes['92211'] = [33.765401, -116.334205];
Zipcodes['92220'] = [33.939919, -116.839331];
Zipcodes['92222'] = [32.783449, -114.54953];
Zipcodes['92223'] = [33.937821, -116.98137];
Zipcodes['92225'] = [33.717266, -114.660084];
Zipcodes['92227'] = [32.99005, -115.336852];
Zipcodes['92230'] = [33.908785, -116.768782];
Zipcodes['92231'] = [32.687636, -115.564374];
Zipcodes['92233'] = [33.147384, -115.500405];
Zipcodes['92234'] = [33.814131, -116.465548];
Zipcodes['92236'] = [33.702765, -116.07244];
Zipcodes['92239'] = [33.768064, -115.21693];
Zipcodes['92240'] = [33.95154, -116.520104];
Zipcodes['92241'] = [33.84634, -116.283302];
Zipcodes['92242'] = [34.188779, -114.306484];
Zipcodes['92243'] = [32.770197, -115.59859];
Zipcodes['92249'] = [32.726068, -115.48215];
Zipcodes['92250'] = [32.777373, -115.127981];
Zipcodes['92251'] = [32.86923, -115.714039];
Zipcodes['92252'] = [34.147976, -116.284218];
Zipcodes['92253'] = [33.656743, -116.292162];
Zipcodes['92254'] = [33.543837, -115.998275];
Zipcodes['92256'] = [34.07206, -116.617666];
Zipcodes['92257'] = [33.336237, -115.525689];
Zipcodes['92258'] = [33.92155, -116.559564];
Zipcodes['92259'] = [32.734784, -115.946436];
Zipcodes['92260'] = [33.69723, -116.406257];
Zipcodes['92262'] = [33.862959, -116.556682];
Zipcodes['92264'] = [33.712892, -116.497107];
Zipcodes['92266'] = [33.199348, -114.915318];
Zipcodes['92267'] = [34.297218, -114.207105];
Zipcodes['92268'] = [34.16146, -116.550014];
Zipcodes['92270'] = [33.763838, -116.430388];
Zipcodes['92273'] = [32.803021, -115.697968];
Zipcodes['92274'] = [33.470242, -116.113717];
Zipcodes['92275'] = [33.207817, -115.880817];
Zipcodes['92276'] = [33.832316, -116.386425];
Zipcodes['92277'] = [34.12777, -115.690134];
Zipcodes['92278'] = [34.235057, -116.058265];
Zipcodes['92280'] = [34.129347, -114.985225];
Zipcodes['92281'] = [33.050343, -115.612651];
Zipcodes['92282'] = [33.962575, -116.658296];
Zipcodes['92283'] = [32.891546, -114.772943];
Zipcodes['92284'] = [34.160076, -116.424717];
Zipcodes['92285'] = [34.337655, -116.513672];
Zipcodes['92301'] = [34.651787, -117.526105];
Zipcodes['92304'] = [34.548749, -115.690805];
Zipcodes['92305'] = [34.14382, -116.868376];
Zipcodes['92307'] = [34.571231, -117.133092];
Zipcodes['92308'] = [34.420222, -117.156134];
Zipcodes['92309'] = [35.249196, -116.156236];
Zipcodes['92310'] = [35.334411, -116.763014];
Zipcodes['92311'] = [34.965648, -117.001509];
Zipcodes['92313'] = [34.031317, -117.314989];
Zipcodes['92314'] = [34.254118, -116.796378];
Zipcodes['92315'] = [34.21713, -116.941614];
Zipcodes['92316'] = [34.057066, -117.391179];
Zipcodes['92317'] = [34.245076, -117.208732];
Zipcodes['92320'] = [33.98738, -117.050284];
Zipcodes['92321'] = [34.250041, -117.147272];
Zipcodes['92322'] = [34.254171, -117.322576];
Zipcodes['92324'] = [34.025186, -117.288849];
Zipcodes['92325'] = [34.246699, -117.276027];
Zipcodes['92327'] = [34.867867, -116.835914];
Zipcodes['92328'] = [36.371785, -116.979764];
Zipcodes['92332'] = [34.907091, -115.364459];
Zipcodes['92333'] = [34.26066, -116.982149];
Zipcodes['92335'] = [34.087333, -117.465158];
Zipcodes['92336'] = [34.136862, -117.460336];
Zipcodes['92337'] = [34.049665, -117.471472];
Zipcodes['92338'] = [34.787721, -116.251208];
Zipcodes['92339'] = [34.083932, -116.837368];
Zipcodes['92341'] = [34.236643, -117.062838];
Zipcodes['92342'] = [34.760294, -117.335913];
Zipcodes['92344'] = [34.391804, -117.406566];
Zipcodes['92345'] = [34.360972, -117.313039];
Zipcodes['92346'] = [34.124049, -117.177573];
Zipcodes['92347'] = [34.950014, -117.258047];
Zipcodes['92350'] = [34.05294, -117.261599];
Zipcodes['92352'] = [34.267686, -117.186721];
Zipcodes['92354'] = [34.050788, -117.250153];
Zipcodes['92356'] = [34.496251, -116.89253];
Zipcodes['92358'] = [34.246232, -117.567638];
Zipcodes['92359'] = [34.085289, -117.076311];
Zipcodes['92363'] = [34.711078, -114.581982];
Zipcodes['92364'] = [35.359598, -115.438772];
Zipcodes['92365'] = [34.896688, -116.560418];
Zipcodes['92368'] = [34.664388, -117.314875];
Zipcodes['92369'] = [34.139411, -117.219664];
Zipcodes['92371'] = [34.438338, -117.542594];
Zipcodes['92372'] = [34.448675, -117.634916];
Zipcodes['92373'] = [34.011947, -117.159702];
Zipcodes['92374'] = [34.068515, -117.175322];
Zipcodes['92376'] = [34.110537, -117.380021];
Zipcodes['92377'] = [34.152363, -117.404068];
Zipcodes['92378'] = [34.231995, -117.224887];
Zipcodes['92382'] = [34.206218, -117.112272];
Zipcodes['92384'] = [36.043338, -116.119168];
Zipcodes['92385'] = [34.213444, -117.230588];
Zipcodes['92386'] = [34.237036, -116.825291];
Zipcodes['92389'] = [35.824572, -116.274755];
Zipcodes['92391'] = [34.239689, -117.236669];
Zipcodes['92392'] = [34.479015, -117.408834];
Zipcodes['92394'] = [34.563464, -117.353579];
Zipcodes['92395'] = [34.504828, -117.298985];
Zipcodes['92397'] = [34.354089, -117.753718];
Zipcodes['92398'] = [34.982793, -116.85162];
Zipcodes['92399'] = [34.032312, -117.010577];
Zipcodes['92401'] = [34.105906, -117.291814];
Zipcodes['92404'] = [34.178633, -117.238926];
Zipcodes['92405'] = [34.145781, -117.302217];
Zipcodes['92407'] = [34.225162, -117.388487];
Zipcodes['92408'] = [34.083619, -117.26846];
Zipcodes['92410'] = [34.111575, -117.263691];
Zipcodes['92411'] = [34.122809, -117.325808];
Zipcodes['92415'] = [34.105054, -117.286848];
Zipcodes['92501'] = [33.996523, -117.374179];
Zipcodes['92503'] = [33.882884, -117.451725];
Zipcodes['92504'] = [33.908353, -117.401342];
Zipcodes['92505'] = [33.926666, -117.492804];
Zipcodes['92506'] = [33.934761, -117.366882];
Zipcodes['92507'] = [33.991122, -117.306821];
Zipcodes['92508'] = [33.890389, -117.328257];
Zipcodes['92509'] = [34.003266, -117.442004];
Zipcodes['92518'] = [33.896337, -117.277065];
Zipcodes['92521'] = [33.96714, -117.326372];
Zipcodes['92530'] = [33.633056, -117.370461];
Zipcodes['92532'] = [33.690557, -117.302435];
Zipcodes['92536'] = [33.491994, -116.816945];
Zipcodes['92539'] = [33.527867, -116.64762];
Zipcodes['92543'] = [33.698645, -116.979161];
Zipcodes['92544'] = [33.645934, -116.881628];
Zipcodes['92545'] = [33.731119, -117.035872];
Zipcodes['92548'] = [33.763908, -117.109032];
Zipcodes['92549'] = [33.803818, -116.679273];
Zipcodes['92551'] = [33.88274, -117.224878];
Zipcodes['92553'] = [33.92335, -117.245333];
Zipcodes['92555'] = [33.910996, -117.113765];
Zipcodes['92557'] = [33.972548, -117.258259];
Zipcodes['92561'] = [33.630418, -116.58229];
Zipcodes['92562'] = [33.53124, -117.343999];
Zipcodes['92563'] = [33.580016, -117.14736];
Zipcodes['92567'] = [33.826142, -117.084467];
Zipcodes['92570'] = [33.779946, -117.322571];
Zipcodes['92571'] = [33.842129, -117.194982];
Zipcodes['92582'] = [33.807607, -117.015163];
Zipcodes['92583'] = [33.796911, -116.931371];
Zipcodes['92584'] = [33.661431, -117.182284];
Zipcodes['92585'] = [33.742167, -117.167238];
Zipcodes['92586'] = [33.707681, -117.200428];
Zipcodes['92587'] = [33.693929, -117.250504];
Zipcodes['92590'] = [33.48294, -117.226084];
Zipcodes['92591'] = [33.534332, -117.109211];
Zipcodes['92592'] = [33.510939, -117.021505];
Zipcodes['92595'] = [33.616349, -117.260346];
Zipcodes['92596'] = [33.668913, -117.047314];
Zipcodes['92602'] = [33.736498, -117.734933];
Zipcodes['92603'] = [33.629259, -117.79408];
Zipcodes['92604'] = [33.690689, -117.789137];
Zipcodes['92606'] = [33.692523, -117.831206];
Zipcodes['92610'] = [33.683363, -117.65408];
Zipcodes['92612'] = [33.660018, -117.824428];
Zipcodes['92614'] = [33.677951, -117.83457];
Zipcodes['92617'] = [33.641973, -117.841046];
Zipcodes['92618'] = [33.667307, -117.73358];
Zipcodes['92620'] = [33.706601, -117.756948];
Zipcodes['92624'] = [33.456649, -117.666808];
Zipcodes['92625'] = [33.601966, -117.864254];
Zipcodes['92626'] = [33.679591, -117.908531];
Zipcodes['92627'] = [33.648112, -117.921203];
Zipcodes['92629'] = [33.475061, -117.70563];
Zipcodes['92630'] = [33.644929, -117.684312];
Zipcodes['92637'] = [33.608229, -117.726099];
Zipcodes['92646'] = [33.661029, -117.967948];
Zipcodes['92647'] = [33.723228, -118.008692];
Zipcodes['92648'] = [33.679045, -118.013011];
Zipcodes['92649'] = [33.720242, -118.045383];
Zipcodes['92651'] = [33.57148, -117.772889];
Zipcodes['92653'] = [33.594012, -117.700734];
Zipcodes['92655'] = [33.746029, -117.987602];
Zipcodes['92656'] = [33.575201, -117.730352];
Zipcodes['92657'] = [33.595058, -117.829131];
Zipcodes['92660'] = [33.634626, -117.874882];
Zipcodes['92661'] = [33.603594, -117.912672];
Zipcodes['92662'] = [33.605086, -117.892319];
Zipcodes['92663'] = [33.625204, -117.928767];
Zipcodes['92672'] = [33.372936, -117.520963];
Zipcodes['92673'] = [33.466908, -117.614016];
Zipcodes['92675'] = [33.499774, -117.653704];
Zipcodes['92676'] = [33.749488, -117.616386];
Zipcodes['92677'] = [33.528654, -117.701253];
Zipcodes['92678'] = [33.651816, -117.507104];
Zipcodes['92679'] = [33.649438, -117.561405];
Zipcodes['92683'] = [33.752428, -117.99387];
Zipcodes['92688'] = [33.61873, -117.6108];
Zipcodes['92691'] = [33.611945, -117.665867];
Zipcodes['92692'] = [33.612175, -117.640452];
Zipcodes['92694'] = [33.51501, -117.580613];
Zipcodes['92697'] = [33.649727, -117.849085];
Zipcodes['92701'] = [33.748622, -117.858714];
Zipcodes['92703'] = [33.74852, -117.906162];
Zipcodes['92704'] = [33.721131, -117.90511];
Zipcodes['92705'] = [33.766007, -117.786763];
Zipcodes['92706'] = [33.766002, -117.881693];
Zipcodes['92707'] = [33.709935, -117.870431];
Zipcodes['92708'] = [33.710582, -117.951129];
Zipcodes['92780'] = [33.736808, -117.819678];
Zipcodes['92782'] = [33.728377, -117.797472];
Zipcodes['92801'] = [33.844983, -117.952151];
Zipcodes['92802'] = [33.808314, -117.923689];
Zipcodes['92804'] = [33.81801, -117.975193];
Zipcodes['92805'] = [33.830053, -117.905683];
Zipcodes['92806'] = [33.83796, -117.870494];
Zipcodes['92807'] = [33.848733, -117.788357];
Zipcodes['92808'] = [33.856247, -117.740232];
Zipcodes['92821'] = [33.931943, -117.854942];
Zipcodes['92823'] = [33.928535, -117.796074];
Zipcodes['92831'] = [33.87951, -117.896036];
Zipcodes['92832'] = [33.868772, -117.929093];
Zipcodes['92833'] = [33.880509, -117.961132];
Zipcodes['92835'] = [33.900573, -117.914667];
Zipcodes['92840'] = [33.787165, -117.931889];
Zipcodes['92841'] = [33.786833, -117.982041];
Zipcodes['92843'] = [33.763963, -117.931538];
Zipcodes['92844'] = [33.765599, -117.969506];
Zipcodes['92845'] = [33.782907, -118.026373];
Zipcodes['92860'] = [33.925506, -117.547703];
Zipcodes['92861'] = [33.817985, -117.811293];
Zipcodes['92865'] = [33.826393, -117.851307];
Zipcodes['92866'] = [33.784537, -117.844791];
Zipcodes['92867'] = [33.815667, -117.799162];
Zipcodes['92868'] = [33.787967, -117.876343];
Zipcodes['92869'] = [33.795479, -117.764943];
Zipcodes['92870'] = [33.881158, -117.854783];
Zipcodes['92879'] = [33.882614, -117.528205];
Zipcodes['92880'] = [33.92539, -117.607254];
Zipcodes['92881'] = [33.82388, -117.570026];
Zipcodes['92882'] = [33.862874, -117.600092];
Zipcodes['92883'] = [33.75801, -117.480258];
Zipcodes['92886'] = [33.895411, -117.800829];
Zipcodes['92887'] = [33.88728, -117.733928];
Zipcodes['93001'] = [34.362376, -119.351268];
Zipcodes['93003'] = [34.283668, -119.223196];
Zipcodes['93004'] = [34.278191, -119.164558];
Zipcodes['93010'] = [34.227888, -119.079553];
Zipcodes['93012'] = [34.203394, -118.991442];
Zipcodes['93013'] = [34.440133, -119.503306];
Zipcodes['93015'] = [34.408873, -118.870202];
Zipcodes['93021'] = [34.305181, -118.883929];
Zipcodes['93022'] = [34.403276, -119.298355];
Zipcodes['93023'] = [34.509095, -119.25191];
Zipcodes['93030'] = [34.205097, -119.17532];
Zipcodes['93033'] = [34.160093, -119.135801];
Zipcodes['93035'] = [34.186287, -119.228885];
Zipcodes['93036'] = [34.248141, -119.153454];
Zipcodes['93040'] = [34.602043, -119.019792];
Zipcodes['93041'] = [34.163189, -119.186677];
Zipcodes['93042'] = [33.255655, -119.503588];
Zipcodes['93043'] = [34.170161, -119.202929];
Zipcodes['93060'] = [34.372031, -119.096695];
Zipcodes['93063'] = [34.308048, -118.686969];
Zipcodes['93064'] = [34.245272, -118.699849];
Zipcodes['93065'] = [34.262388, -118.772219];
Zipcodes['93066'] = [34.297699, -119.014558];
Zipcodes['93067'] = [34.423101, -119.596155];
Zipcodes['93101'] = [34.419259, -119.708049];
Zipcodes['93103'] = [34.437367, -119.679284];
Zipcodes['93105'] = [34.52904, -119.767895];
Zipcodes['93106'] = [34.417243, -119.851292];
Zipcodes['93108'] = [34.448974, -119.605427];
Zipcodes['93109'] = [34.406408, -119.726927];
Zipcodes['93110'] = [34.4395, -119.767827];
Zipcodes['93111'] = [34.449185, -119.804782];
Zipcodes['93117'] = [34.479453, -120.084094];
Zipcodes['93201'] = [35.856859, -119.507526];
Zipcodes['93202'] = [36.318686, -119.70752];
Zipcodes['93203'] = [35.119995, -118.845899];
Zipcodes['93204'] = [35.913203, -120.082138];
Zipcodes['93205'] = [35.573416, -118.486441];
Zipcodes['93206'] = [35.443913, -119.479136];
Zipcodes['93207'] = [35.895294, -118.595627];
Zipcodes['93208'] = [36.129777, -118.639777];
Zipcodes['93210'] = [36.167464, -120.438556];
Zipcodes['93212'] = [36.014226, -119.562985];
Zipcodes['93215'] = [35.779464, -119.191935];
Zipcodes['93218'] = [35.857141, -119.04493];
Zipcodes['93219'] = [35.888238, -119.294957];
Zipcodes['93220'] = [35.378735, -118.781845];
Zipcodes['93221'] = [36.296394, -119.029935];
Zipcodes['93222'] = [34.886245, -119.220436];
Zipcodes['93223'] = [36.301112, -119.207875];
Zipcodes['93224'] = [35.255235, -119.625226];
Zipcodes['93225'] = [34.786607, -119.029849];
Zipcodes['93226'] = [35.735652, -118.738373];
Zipcodes['93227'] = [36.344445, -119.418562];
Zipcodes['93230'] = [36.29223, -119.622676];
Zipcodes['93234'] = [36.196092, -120.084807];
Zipcodes['93235'] = [36.385067, -119.223124];
Zipcodes['93237'] = [36.474499, -118.904268];
Zipcodes['93238'] = [35.850772, -118.49771];
Zipcodes['93239'] = [36.032769, -119.986409];
Zipcodes['93240'] = [35.617547, -118.452285];
Zipcodes['93241'] = [35.24526, -118.90528];
Zipcodes['93242'] = [36.441137, -119.730278];
Zipcodes['93243'] = [34.839984, -118.814946];
Zipcodes['93244'] = [36.38634, -118.989715];
Zipcodes['93245'] = [36.286104, -119.834059];
Zipcodes['93246'] = [36.317668, -119.927482];
Zipcodes['93247'] = [36.201735, -119.083478];
Zipcodes['93249'] = [35.621697, -119.887747];
Zipcodes['93250'] = [35.669076, -119.201969];
Zipcodes['93251'] = [35.370603, -119.69734];
Zipcodes['93252'] = [34.802883, -119.359267];
Zipcodes['93254'] = [34.946218, -119.802837];
Zipcodes['93255'] = [35.67733, -118.101417];
Zipcodes['93256'] = [35.971731, -119.313511];
Zipcodes['93257'] = [35.980858, -118.889315];
Zipcodes['93258'] = [36.058332, -119.152094];
Zipcodes['93260'] = [35.819086, -118.661189];
Zipcodes['93261'] = [35.81148, -119.116738];
Zipcodes['93262'] = [36.436989, -118.671533];
Zipcodes['93263'] = [35.487248, -119.278324];
Zipcodes['93265'] = [36.217713, -118.693606];
Zipcodes['93266'] = [36.171729, -119.856304];
Zipcodes['93267'] = [36.143623, -119.073877];
Zipcodes['93268'] = [35.167567, -119.410017];
Zipcodes['93270'] = [35.942448, -119.058349];
Zipcodes['93271'] = [36.440041, -118.88028];
Zipcodes['93272'] = [36.047354, -119.344428];
Zipcodes['93274'] = [36.177934, -119.379975];
Zipcodes['93276'] = [35.317597, -119.409306];
Zipcodes['93277'] = [36.301334, -119.372586];
Zipcodes['93280'] = [35.652242, -119.4464];
Zipcodes['93283'] = [35.592099, -118.297698];
Zipcodes['93285'] = [35.749127, -118.493401];
Zipcodes['93286'] = [36.46271, -119.093242];
Zipcodes['93287'] = [35.723801, -118.920662];
Zipcodes['93291'] = [36.391777, -119.372842];
Zipcodes['93292'] = [36.366243, -119.211636];
Zipcodes['93301'] = [35.384336, -119.020562];
Zipcodes['93304'] = [35.339439, -119.023655];
Zipcodes['93305'] = [35.389875, -118.98518];
Zipcodes['93306'] = [35.468193, -118.779751];
Zipcodes['93307'] = [35.24512, -118.9411];
Zipcodes['93308'] = [35.547493, -118.946868];
Zipcodes['93309'] = [35.343046, -119.06504];
Zipcodes['93311'] = [35.153729, -119.168362];
Zipcodes['93312'] = [35.391845, -119.119548];
Zipcodes['93313'] = [35.165449, -119.043514];
Zipcodes['93314'] = [35.393866, -119.244846];
Zipcodes['93401'] = [35.235871, -120.615914];
Zipcodes['93402'] = [35.295232, -120.835468];
Zipcodes['93405'] = [35.300465, -120.734843];
Zipcodes['93407'] = [35.306628, -120.669175];
Zipcodes['93408'] = [35.321107, -120.718897];
Zipcodes['93409'] = [35.323566, -120.697375];
Zipcodes['93410'] = [35.304456, -120.656938];
Zipcodes['93420'] = [35.176011, -120.476757];
Zipcodes['93422'] = [35.465349, -120.689439];
Zipcodes['93424'] = [35.198617, -120.786068];
Zipcodes['93426'] = [35.840139, -120.975186];
Zipcodes['93427'] = [34.642959, -120.239126];
Zipcodes['93428'] = [35.585, -121.041474];
Zipcodes['93429'] = [34.867662, -120.539904];
Zipcodes['93430'] = [35.484767, -120.944173];
Zipcodes['93432'] = [35.47852, -120.481435];
Zipcodes['93433'] = [35.120888, -120.619664];
Zipcodes['93434'] = [34.940269, -120.62007];
Zipcodes['93436'] = [34.599926, -120.376532];
Zipcodes['93437'] = [34.760644, -120.508877];
Zipcodes['93440'] = [34.768631, -120.25826];
Zipcodes['93441'] = [34.733126, -120.094969];
Zipcodes['93442'] = [35.401398, -120.799935];
Zipcodes['93444'] = [35.035913, -120.502562];
Zipcodes['93445'] = [35.044075, -120.620707];
Zipcodes['93446'] = [35.659068, -120.717584];
Zipcodes['93449'] = [35.160934, -120.653342];
Zipcodes['93450'] = [36.085423, -120.818522];
Zipcodes['93451'] = [35.932541, -120.560861];
Zipcodes['93452'] = [35.746485, -121.223348];
Zipcodes['93453'] = [35.241345, -120.219268];
Zipcodes['93454'] = [34.923013, -120.259463];
Zipcodes['93455'] = [34.848314, -120.482883];
Zipcodes['93458'] = [34.953245, -120.492119];
Zipcodes['93460'] = [34.673317, -119.743616];
Zipcodes['93461'] = [35.63334, -120.268391];
Zipcodes['93463'] = [34.606422, -120.135431];
Zipcodes['93465'] = [35.542716, -120.733796];
Zipcodes['93501'] = [35.065798, -118.221782];
Zipcodes['93505'] = [35.108958, -117.956461];
Zipcodes['93510'] = [34.450906, -118.227614];
Zipcodes['93512'] = [37.891866, -118.554569];
Zipcodes['93513'] = [37.03614, -118.335671];
Zipcodes['93514'] = [37.492115, -118.390408];
Zipcodes['93516'] = [35.008132, -117.642563];
Zipcodes['93517'] = [38.278077, -119.274809];
Zipcodes['93518'] = [35.385111, -118.491168];
Zipcodes['93519'] = [35.314205, -117.960728];
Zipcodes['93522'] = [36.316298, -117.592333];
Zipcodes['93523'] = [34.995292, -117.88399];
Zipcodes['93524'] = [34.908021, -117.827817];
Zipcodes['93526'] = [36.857125, -118.258145];
Zipcodes['93527'] = [35.922932, -118.078936];
Zipcodes['93528'] = [35.371876, -117.641803];
Zipcodes['93529'] = [37.777706, -119.188102];
Zipcodes['93530'] = [36.493858, -117.890389];
Zipcodes['93531'] = [35.23178, -118.613067];
Zipcodes['93532'] = [34.689848, -118.533689];
Zipcodes['93534'] = [34.71766, -118.15232];
Zipcodes['93535'] = [34.698392, -117.889945];
Zipcodes['93536'] = [34.73767, -118.387424];
Zipcodes['93541'] = [38.018646, -119.059726];
Zipcodes['93543'] = [34.475278, -117.969599];
Zipcodes['93544'] = [34.488206, -117.75661];
Zipcodes['93545'] = [36.610541, -118.026281];
Zipcodes['93546'] = [37.549126, -118.839814];
Zipcodes['93549'] = [36.292726, -117.865352];
Zipcodes['93550'] = [34.410334, -118.059957];
Zipcodes['93551'] = [34.589618, -118.227381];
Zipcodes['93552'] = [34.583523, -118.027343];
Zipcodes['93553'] = [34.445239, -117.894868];
Zipcodes['93554'] = [35.369218, -117.759126];
Zipcodes['93555'] = [35.582384, -117.683597];
Zipcodes['93558'] = [35.339454, -117.585133];
Zipcodes['93560'] = [34.878782, -118.318326];
Zipcodes['93561'] = [35.098666, -118.516706];
Zipcodes['93562'] = [35.701221, -117.307544];
Zipcodes['93563'] = [34.370922, -117.813363];
Zipcodes['93581'] = [35.112906, -118.570979];
Zipcodes['93591'] = [34.597861, -117.815573];
Zipcodes['93601'] = [37.390775, -119.732363];
Zipcodes['93602'] = [37.06684, -119.388942];
Zipcodes['93603'] = [36.488679, -118.998074];
Zipcodes['93604'] = [37.347908, -119.529808];
Zipcodes['93605'] = [37.176219, -119.249523];
Zipcodes['93606'] = [36.798715, -120.016273];
Zipcodes['93608'] = [36.504987, -120.338664];
Zipcodes['93609'] = [36.526166, -119.863891];
Zipcodes['93610'] = [37.087285, -120.288867];
Zipcodes['93611'] = [36.826668, -119.680572];
Zipcodes['93612'] = [36.814849, -119.711909];
Zipcodes['93614'] = [37.217571, -119.731069];
Zipcodes['93615'] = [36.503739, -119.283846];
Zipcodes['93616'] = [36.65468, -119.594333];
Zipcodes['93618'] = [36.522118, -119.386628];
Zipcodes['93619'] = [36.914176, -119.593979];
Zipcodes['93620'] = [37.043497, -120.620508];
Zipcodes['93621'] = [36.746633, -119.101388];
Zipcodes['93622'] = [36.785618, -120.625382];
Zipcodes['93623'] = [37.476596, -119.674433];
Zipcodes['93624'] = [36.378545, -120.130151];
Zipcodes['93625'] = [36.624229, -119.671752];
Zipcodes['93626'] = [37.043286, -119.691306];
Zipcodes['93627'] = [36.510942, -120.106292];
Zipcodes['93628'] = [36.770844, -118.848499];
Zipcodes['93630'] = [36.716574, -120.126955];
Zipcodes['93631'] = [36.480065, -119.517339];
Zipcodes['93633'] = [36.729122, -118.956235];
Zipcodes['93634'] = [37.223609, -119.000936];
Zipcodes['93635'] = [37.020187, -120.8817];
Zipcodes['93636'] = [36.984877, -119.877519];
Zipcodes['93637'] = [36.918079, -120.185933];
Zipcodes['93638'] = [37.043434, -120.033693];
Zipcodes['93640'] = [36.672698, -120.439737];
Zipcodes['93641'] = [36.705482, -119.039956];
Zipcodes['93643'] = [37.205054, -119.518434];
Zipcodes['93644'] = [37.418018, -119.592567];
Zipcodes['93645'] = [37.152109, -119.620136];
Zipcodes['93646'] = [36.632274, -119.289353];
Zipcodes['93647'] = [36.60076, -119.175485];
Zipcodes['93648'] = [36.620623, -119.520582];
Zipcodes['93650'] = [36.840197, -119.800121];
Zipcodes['93651'] = [37.002388, -119.514858];
Zipcodes['93652'] = [36.595012, -119.904577];
Zipcodes['93653'] = [37.256031, -119.909059];
Zipcodes['93654'] = [36.658709, -119.401274];
Zipcodes['93656'] = [36.450944, -119.936819];
Zipcodes['93657'] = [36.816858, -119.458702];
Zipcodes['93660'] = [36.609339, -120.165168];
Zipcodes['93662'] = [36.536573, -119.638651];
Zipcodes['93664'] = [37.049821, -119.190726];
Zipcodes['93665'] = [36.963314, -120.650315];
Zipcodes['93666'] = [36.546208, -119.336177];
Zipcodes['93667'] = [36.965663, -119.316835];
Zipcodes['93668'] = [36.657616, -120.293101];
Zipcodes['93669'] = [37.290909, -119.548365];
Zipcodes['93670'] = [36.485557, -119.254153];
Zipcodes['93673'] = [36.452353, -119.482381];
Zipcodes['93675'] = [36.724723, -119.192779];
Zipcodes['93701'] = [36.749601, -119.78781];
Zipcodes['93702'] = [36.739443, -119.754209];
Zipcodes['93703'] = [36.768856, -119.762411];
Zipcodes['93704'] = [36.80031, -119.800906];
Zipcodes['93705'] = [36.787297, -119.827321];
Zipcodes['93706'] = [36.655905, -119.920259];
Zipcodes['93710'] = [36.824105, -119.76271];
Zipcodes['93711'] = [36.836551, -119.833849];
Zipcodes['93720'] = [36.862209, -119.760793];
Zipcodes['93721'] = [36.732808, -119.783829];
Zipcodes['93722'] = [36.799747, -119.87762];
Zipcodes['93723'] = [36.789756, -119.952559];
Zipcodes['93725'] = [36.627211, -119.731888];
Zipcodes['93726'] = [36.793666, -119.759715];
Zipcodes['93727'] = [36.751612, -119.695397];
Zipcodes['93728'] = [36.757302, -119.818282];
Zipcodes['93730'] = [36.903919, -119.747335];
Zipcodes['93737'] = [36.753613, -119.645581];
Zipcodes['93740'] = [36.816381, -119.746115];
Zipcodes['93741'] = [36.76743, -119.796035];
Zipcodes['93901'] = [36.647856, -121.630305];
Zipcodes['93905'] = [36.685068, -121.605992];
Zipcodes['93906'] = [36.722676, -121.633667];
Zipcodes['93907'] = [36.766781, -121.666722];
Zipcodes['93908'] = [36.626032, -121.602085];
Zipcodes['93920'] = [36.052246, -121.514405];
Zipcodes['93921'] = [36.553641, -121.922271];
Zipcodes['93923'] = [36.405775, -121.796018];
Zipcodes['93924'] = [36.415603, -121.637651];
Zipcodes['93925'] = [36.588482, -121.399217];
Zipcodes['93926'] = [36.52587, -121.396735];
Zipcodes['93927'] = [36.275587, -121.391924];
Zipcodes['93928'] = [36.082739, -121.470787];
Zipcodes['93930'] = [36.199798, -121.082308];
Zipcodes['93932'] = [35.989755, -121.064359];
Zipcodes['93933'] = [36.676966, -121.781451];
Zipcodes['93940'] = [36.562251, -121.805604];
Zipcodes['93943'] = [36.597649, -121.87331];
Zipcodes['93944'] = [36.600652, -121.918535];
Zipcodes['93950'] = [36.619101, -121.925439];
Zipcodes['93953'] = [36.587441, -121.944816];
Zipcodes['93954'] = [36.172975, -120.902682];
Zipcodes['93955'] = [36.614789, -121.788022];
Zipcodes['93960'] = [36.405625, -121.323055];
Zipcodes['93962'] = [36.624716, -121.646487];
Zipcodes['94002'] = [37.513447, -122.296824];
Zipcodes['94005'] = [37.688826, -122.408935];
Zipcodes['94010'] = [37.571918, -122.375028];
Zipcodes['94014'] = [37.690686, -122.447738];
Zipcodes['94015'] = [37.681313, -122.480852];
Zipcodes['94018'] = [37.505997, -122.473318];
Zipcodes['94019'] = [37.455666, -122.407966];
Zipcodes['94020'] = [37.268977, -122.221545];
Zipcodes['94021'] = [37.250345, -122.249636];
Zipcodes['94022'] = [37.35536, -122.144935];
Zipcodes['94024'] = [37.350388, -122.098686];
Zipcodes['94025'] = [37.451252, -122.182343];
Zipcodes['94027'] = [37.457072, -122.201058];
Zipcodes['94028'] = [37.374883, -122.212675];
Zipcodes['94030'] = [37.59971, -122.4033];
Zipcodes['94037'] = [37.553301, -122.495834];
Zipcodes['94038'] = [37.53572, -122.473676];
Zipcodes['94040'] = [37.380272, -122.085221];
Zipcodes['94041'] = [37.388239, -122.075351];
Zipcodes['94043'] = [37.411913, -122.068919];
Zipcodes['94044'] = [37.606598, -122.47723];
Zipcodes['94060'] = [37.247137, -122.273493];
Zipcodes['94061'] = [37.461147, -122.236948];
Zipcodes['94062'] = [37.422531, -122.28582];
Zipcodes['94063'] = [37.493306, -122.195606];
Zipcodes['94065'] = [37.533418, -122.249558];
Zipcodes['94066'] = [37.625389, -122.432356];
Zipcodes['94070'] = [37.496469, -122.272556];
Zipcodes['94074'] = [37.331714, -122.341466];
Zipcodes['94080'] = [37.655175, -122.418384];
Zipcodes['94085'] = [37.388617, -122.017933];
Zipcodes['94086'] = [37.371634, -122.023202];
Zipcodes['94087'] = [37.351615, -122.03668];
Zipcodes['94089'] = [37.412402, -122.015605];
Zipcodes['94102'] = [37.779562, -122.419293];
Zipcodes['94103'] = [37.772983, -122.411343];
Zipcodes['94104'] = [37.791412, -122.402129];
Zipcodes['94105'] = [37.791575, -122.393733];
Zipcodes['94107'] = [37.758547, -122.39954];
Zipcodes['94108'] = [37.791886, -122.408517];
Zipcodes['94109'] = [37.794485, -122.42227];
Zipcodes['94110'] = [37.750021, -122.415201];
Zipcodes['94111'] = [37.798359, -122.399201];
Zipcodes['94112'] = [37.720546, -122.443166];
Zipcodes['94114'] = [37.758208, -122.435556];
Zipcodes['94115'] = [37.786029, -122.437366];
Zipcodes['94116'] = [37.745399, -122.486065];
Zipcodes['94117'] = [37.770051, -122.44428];
Zipcodes['94118'] = [37.780146, -122.46153];
Zipcodes['94121'] = [37.778591, -122.495912];
Zipcodes['94122'] = [37.760459, -122.483894];
Zipcodes['94123'] = [37.802045, -122.433969];
Zipcodes['94124'] = [37.728995, -122.381315];
Zipcodes['94127'] = [37.736027, -122.457207];
Zipcodes['94128'] = [37.621955, -122.383797];
Zipcodes['94129'] = [37.79734, -122.464466];
Zipcodes['94130'] = [37.819655, -122.36866];
Zipcodes['94131'] = [37.745917, -122.441473];
Zipcodes['94132'] = [37.722214, -122.484083];
Zipcodes['94133'] = [37.801962, -122.411];
Zipcodes['94134'] = [37.718849, -122.411297];
Zipcodes['94158'] = [37.772534, -122.388384];
Zipcodes['94188'] = [37.74071, -122.380921];
Zipcodes['94301'] = [37.444218, -122.149855];
Zipcodes['94303'] = [37.457883, -122.115097];
Zipcodes['94304'] = [37.397253, -122.166979];
Zipcodes['94305'] = [37.418256, -122.170871];
Zipcodes['94306'] = [37.4162, -122.130858];
Zipcodes['94401'] = [37.575172, -122.319881];
Zipcodes['94402'] = [37.540103, -122.330103];
Zipcodes['94403'] = [37.538449, -122.305447];
Zipcodes['94404'] = [37.555999, -122.267679];
Zipcodes['94501'] = [37.774712, -122.278097];
Zipcodes['94502'] = [37.739495, -122.239465];
Zipcodes['94503'] = [38.187913, -122.22365];
Zipcodes['94505'] = [37.871268, -121.596984];
Zipcodes['94506'] = [37.806426, -121.902424];
Zipcodes['94507'] = [37.85429, -122.006599];
Zipcodes['94508'] = [38.585784, -122.444202];
Zipcodes['94509'] = [37.992003, -121.812566];
Zipcodes['94510'] = [38.113205, -122.119708];
Zipcodes['94511'] = [38.02813, -121.629851];
Zipcodes['94512'] = [38.13115, -121.821736];
Zipcodes['94513'] = [37.924899, -121.686724];
Zipcodes['94514'] = [37.825646, -121.634353];
Zipcodes['94515'] = [38.635718, -122.615085];
Zipcodes['94516'] = [37.827495, -122.1663];
Zipcodes['94517'] = [37.891077, -121.893056];
Zipcodes['94518'] = [37.950521, -122.022078];
Zipcodes['94519'] = [37.988804, -122.012765];
Zipcodes['94520'] = [37.993028, -122.035251];
Zipcodes['94521'] = [37.95291, -121.969695];
Zipcodes['94523'] = [37.954163, -122.075772];
Zipcodes['94525'] = [38.048073, -122.229208];
Zipcodes['94526'] = [37.814734, -121.984525];
Zipcodes['94528'] = [37.841477, -121.958154];
Zipcodes['94530'] = [37.920742, -122.300635];
Zipcodes['94531'] = [37.956648, -121.77779];
Zipcodes['94533'] = [38.280745, -122.00638];
Zipcodes['94534'] = [38.241946, -122.118272];
Zipcodes['94535'] = [38.270937, -121.937017];
Zipcodes['94536'] = [37.570508, -121.98125];
Zipcodes['94538'] = [37.501368, -121.956753];
Zipcodes['94539'] = [37.516292, -121.911891];
Zipcodes['94541'] = [37.673757, -122.087823];
Zipcodes['94542'] = [37.65532, -122.025826];
Zipcodes['94544'] = [37.633507, -122.056772];
Zipcodes['94545'] = [37.629761, -122.124238];
Zipcodes['94546'] = [37.745399, -122.103127];
Zipcodes['94547'] = [38.006586, -122.25716];
Zipcodes['94548'] = [37.974295, -121.651441];
Zipcodes['94549'] = [37.901137, -122.119081];
Zipcodes['94550'] = [37.519983, -121.575595];
Zipcodes['94551'] = [37.750512, -121.749249];
Zipcodes['94552'] = [37.705778, -122.007585];
Zipcodes['94553'] = [37.978299, -122.157109];
Zipcodes['94555'] = [37.547594, -122.09822];
Zipcodes['94556'] = [37.831971, -122.105922];
Zipcodes['94558'] = [38.389135, -122.188412];
Zipcodes['94559'] = [38.251125, -122.29609];
Zipcodes['94560'] = [37.504253, -122.03191];
Zipcodes['94561'] = [37.993485, -121.691219];
Zipcodes['94562'] = [38.430651, -122.408853];
Zipcodes['94563'] = [37.873867, -122.205312];
Zipcodes['94564'] = [37.990555, -122.272499];
Zipcodes['94565'] = [38.008595, -121.928315];
Zipcodes['94566'] = [37.648731, -121.848024];
Zipcodes['94567'] = [38.667018, -122.407012];
Zipcodes['94568'] = [37.715966, -121.901141];
Zipcodes['94569'] = [38.044092, -122.191943];
Zipcodes['94571'] = [38.152071, -121.759773];
Zipcodes['94572'] = [38.023858, -122.232788];
Zipcodes['94573'] = [38.458864, -122.428831];
Zipcodes['94574'] = [38.542448, -122.419923];
Zipcodes['94575'] = [37.840961, -122.109502];
Zipcodes['94576'] = [38.549256, -122.4758];
Zipcodes['94577'] = [37.716659, -122.169274];
Zipcodes['94578'] = [37.706765, -122.125565];
Zipcodes['94579'] = [37.6865, -122.157215];
Zipcodes['94580'] = [37.678001, -122.133367];
Zipcodes['94582'] = [37.765347, -121.915432];
Zipcodes['94583'] = [37.771693, -121.989829];
Zipcodes['94585'] = [38.191832, -121.938269];
Zipcodes['94586'] = [37.567125, -121.852246];
Zipcodes['94587'] = [37.590008, -122.075187];
Zipcodes['94588'] = [37.792648, -121.862807];
Zipcodes['94589'] = [38.141467, -122.24687];
Zipcodes['94590'] = [38.095789, -122.249689];
Zipcodes['94591'] = [38.127191, -122.196855];
Zipcodes['94592'] = [38.090998, -122.285418];
Zipcodes['94595'] = [37.872087, -122.068971];
Zipcodes['94596'] = [37.889009, -122.036778];
Zipcodes['94597'] = [37.917697, -122.071818];
Zipcodes['94598'] = [37.916916, -122.014917];
Zipcodes['94599'] = [38.399485, -122.380082];
Zipcodes['94601'] = [37.77576, -122.218572];
Zipcodes['94602'] = [37.805235, -122.204229];
Zipcodes['94603'] = [37.739266, -122.175548];
Zipcodes['94605'] = [37.761408, -122.150198];
Zipcodes['94606'] = [37.791366, -122.244513];
Zipcodes['94607'] = [37.808842, -122.304697];
Zipcodes['94608'] = [37.836844, -122.289143];
Zipcodes['94609'] = [37.834816, -122.264195];
Zipcodes['94610'] = [37.812329, -122.240377];
Zipcodes['94611'] = [37.831917, -122.215793];
Zipcodes['94612'] = [37.807709, -122.266857];
Zipcodes['94613'] = [37.78072, -122.183029];
Zipcodes['94618'] = [37.844821, -122.238552];
Zipcodes['94619'] = [37.792612, -122.160084];
Zipcodes['94621'] = [37.75113, -122.210178];
Zipcodes['94702'] = [37.866321, -122.286348];
Zipcodes['94703'] = [37.863715, -122.275684];
Zipcodes['94704'] = [37.866417, -122.260922];
Zipcodes['94705'] = [37.865205, -122.238384];
Zipcodes['94706'] = [37.889698, -122.295858];
Zipcodes['94707'] = [37.89836, -122.27909];
Zipcodes['94708'] = [37.903067, -122.26194];
Zipcodes['94709'] = [37.878291, -122.266252];
Zipcodes['94710'] = [37.863923, -122.306465];
Zipcodes['94720'] = [37.871957, -122.252198];
Zipcodes['94801'] = [37.949377, -122.397358];
Zipcodes['94803'] = [37.95684, -122.282358];
Zipcodes['94804'] = [37.915484, -122.337828];
Zipcodes['94805'] = [37.942209, -122.323545];
Zipcodes['94806'] = [37.978427, -122.336418];
Zipcodes['94901'] = [37.980709, -122.500606];
Zipcodes['94903'] = [38.02213, -122.55881];
Zipcodes['94904'] = [37.947225, -122.551775];
Zipcodes['94920'] = [37.886768, -122.462619];
Zipcodes['94922'] = [38.356806, -122.950945];
Zipcodes['94923'] = [38.339415, -123.034525];
Zipcodes['94924'] = [37.966904, -122.738816];
Zipcodes['94925'] = [37.931846, -122.507738];
Zipcodes['94928'] = [38.34593, -122.693507];
Zipcodes['94929'] = [38.254528, -122.962371];
Zipcodes['94930'] = [37.969793, -122.61533];
Zipcodes['94931'] = [38.325172, -122.716977];
Zipcodes['94933'] = [38.017502, -122.688148];
Zipcodes['94937'] = [38.116482, -122.930353];
Zipcodes['94938'] = [38.030237, -122.729513];
Zipcodes['94939'] = [37.938252, -122.535948];
Zipcodes['94940'] = [38.203971, -122.891482];
Zipcodes['94941'] = [37.924775, -122.600183];
Zipcodes['94945'] = [38.122687, -122.553142];
Zipcodes['94946'] = [38.074734, -122.695265];
Zipcodes['94947'] = [38.1062, -122.624985];
Zipcodes['94949'] = [38.060094, -122.50641];
Zipcodes['94950'] = [38.021161, -122.761734];
Zipcodes['94951'] = [38.322548, -122.644894];
Zipcodes['94952'] = [38.234553, -122.760385];
Zipcodes['94954'] = [38.23501, -122.557369];
Zipcodes['94956'] = [38.021454, -122.81817];
Zipcodes['94957'] = [37.963421, -122.561808];
Zipcodes['94960'] = [37.997023, -122.578338];
Zipcodes['94963'] = [38.00761, -122.664748];
Zipcodes['94964'] = [37.940595, -122.490712];
Zipcodes['94965'] = [37.837347, -122.527482];
Zipcodes['94970'] = [37.925882, -122.659833];
Zipcodes['94971'] = [38.233029, -122.896959];
Zipcodes['94972'] = [38.336642, -122.939307];
Zipcodes['94973'] = [38.012591, -122.6394];
Zipcodes['95002'] = [37.438672, -122.011582];
Zipcodes['95003'] = [37.0067, -121.878207];
Zipcodes['95004'] = [36.874755, -121.630732];
Zipcodes['95005'] = [37.098363, -122.089295];
Zipcodes['95006'] = [37.171267, -122.149524];
Zipcodes['95007'] = [37.110125, -122.114345];
Zipcodes['95008'] = [37.279173, -121.955565];
Zipcodes['95010'] = [36.976355, -121.954723];
Zipcodes['95012'] = [36.788828, -121.734889];
Zipcodes['95013'] = [37.215315, -121.739614];
Zipcodes['95014'] = [37.308354, -122.081882];
Zipcodes['95017'] = [37.094684, -122.237466];
Zipcodes['95018'] = [37.083874, -122.059898];
Zipcodes['95019'] = [36.935853, -121.784476];
Zipcodes['95020'] = [37.01621, -121.559418];
Zipcodes['95023'] = [36.919993, -121.322188];
Zipcodes['95030'] = [37.221646, -121.983242];
Zipcodes['95032'] = [37.18014, -121.901553];
Zipcodes['95033'] = [37.158275, -121.973678];
Zipcodes['95035'] = [37.440397, -121.874693];
Zipcodes['95037'] = [37.13182, -121.681247];
Zipcodes['95039'] = [36.827745, -121.76529];
Zipcodes['95041'] = [37.050534, -122.058279];
Zipcodes['95043'] = [36.518177, -120.982913];
Zipcodes['95045'] = [36.825807, -121.518565];
Zipcodes['95046'] = [37.099308, -121.59924];
Zipcodes['95050'] = [37.350431, -121.952405];
Zipcodes['95051'] = [37.348311, -121.984435];
Zipcodes['95053'] = [37.348462, -121.937271];
Zipcodes['95054'] = [37.3931, -121.964796];
Zipcodes['95060'] = [36.9932, -122.0984];
Zipcodes['95062'] = [36.973969, -121.989846];
Zipcodes['95064'] = [36.991596, -122.058868];
Zipcodes['95065'] = [37.032242, -121.984402];
Zipcodes['95066'] = [37.067198, -122.014521];
Zipcodes['95070'] = [37.254929, -122.052181];
Zipcodes['95073'] = [37.04068, -121.93174];
Zipcodes['95075'] = [36.793553, -121.191975];
Zipcodes['95076'] = [36.945781, -121.756058];
Zipcodes['95110'] = [37.346559, -121.909747];
Zipcodes['95111'] = [37.283853, -121.826869];
Zipcodes['95112'] = [37.344599, -121.88344];
Zipcodes['95113'] = [37.334013, -121.890443];
Zipcodes['95116'] = [37.350471, -121.852554];
Zipcodes['95117'] = [37.310072, -121.962184];
Zipcodes['95118'] = [37.255364, -121.891912];
Zipcodes['95119'] = [37.226971, -121.788682];
Zipcodes['95120'] = [37.185746, -121.842738];
Zipcodes['95121'] = [37.304611, -121.809773];
Zipcodes['95122'] = [37.329224, -121.834821];
Zipcodes['95123'] = [37.244238, -121.831604];
Zipcodes['95124'] = [37.256831, -121.922815];
Zipcodes['95125'] = [37.295599, -121.894071];
Zipcodes['95126'] = [37.326889, -121.916797];
Zipcodes['95127'] = [37.371671, -121.787097];
Zipcodes['95128'] = [37.315884, -121.936431];
Zipcodes['95129'] = [37.305958, -122.00072];
Zipcodes['95130'] = [37.277676, -121.986238];
Zipcodes['95131'] = [37.387267, -121.897948];
Zipcodes['95132'] = [37.418446, -121.787978];
Zipcodes['95133'] = [37.371723, -121.860556];
Zipcodes['95134'] = [37.430021, -121.945263];
Zipcodes['95135'] = [37.223357, -121.525695];
Zipcodes['95136'] = [37.271379, -121.852512];
Zipcodes['95138'] = [37.245612, -121.748865];
Zipcodes['95139'] = [37.224877, -121.763856];
Zipcodes['95140'] = [37.351498, -121.640881];
Zipcodes['95148'] = [37.333429, -121.781668];
Zipcodes['95202'] = [37.959736, -121.288287];
Zipcodes['95203'] = [37.954302, -121.32932];
Zipcodes['95204'] = [37.973736, -121.319246];
Zipcodes['95205'] = [37.964578, -121.259841];
Zipcodes['95206'] = [37.908471, -121.395306];
Zipcodes['95207'] = [38.002125, -121.324979];
Zipcodes['95209'] = [38.046863, -121.352802];
Zipcodes['95210'] = [38.031786, -121.297886];
Zipcodes['95211'] = [37.980264, -121.311149];
Zipcodes['95212'] = [38.049939, -121.23297];
Zipcodes['95215'] = [37.949395, -121.157059];
Zipcodes['95219'] = [38.037428, -121.512498];
Zipcodes['95220'] = [38.200231, -121.235031];
Zipcodes['95221'] = [38.072286, -120.548466];
Zipcodes['95222'] = [38.05133, -120.590173];
Zipcodes['95223'] = [38.446323, -119.812958];
Zipcodes['95224'] = [38.231324, -120.322688];
Zipcodes['95225'] = [38.202552, -120.864148];
Zipcodes['95226'] = [38.236881, -120.866044];
Zipcodes['95227'] = [38.210353, -121.051256];
Zipcodes['95228'] = [37.907268, -120.688285];
Zipcodes['95230'] = [37.99765, -120.894682];
Zipcodes['95231'] = [37.873793, -121.290146];
Zipcodes['95232'] = [38.360526, -120.577243];
Zipcodes['95233'] = [38.186187, -120.368557];
Zipcodes['95236'] = [38.044271, -121.039254];
Zipcodes['95237'] = [38.153954, -121.146561];
Zipcodes['95240'] = [38.123544, -121.159078];
Zipcodes['95242'] = [38.137068, -121.384546];
Zipcodes['95245'] = [38.302747, -120.609465];
Zipcodes['95246'] = [38.249708, -120.488857];
Zipcodes['95247'] = [38.136093, -120.443942];
Zipcodes['95248'] = [38.317709, -120.461112];
Zipcodes['95249'] = [38.18995, -120.619555];
Zipcodes['95251'] = [38.080062, -120.45358];
Zipcodes['95252'] = [38.158628, -120.843247];
Zipcodes['95253'] = [38.147169, -121.195284];
Zipcodes['95254'] = [38.199838, -120.964242];
Zipcodes['95255'] = [38.419852, -120.480023];
Zipcodes['95257'] = [38.379926, -120.468017];
Zipcodes['95258'] = [38.172065, -121.308915];
Zipcodes['95301'] = [37.306149, -120.655999];
Zipcodes['95303'] = [37.470288, -120.671083];
Zipcodes['95304'] = [37.680641, -121.381561];
Zipcodes['95305'] = [37.815927, -120.361092];
Zipcodes['95306'] = [37.362898, -120.119424];
Zipcodes['95307'] = [37.555815, -120.953858];
Zipcodes['95309'] = [37.87142, -120.432387];
Zipcodes['95310'] = [38.085839, -120.37855];
Zipcodes['95311'] = [37.705686, -120.091035];
Zipcodes['95312'] = [37.421071, -120.652983];
Zipcodes['95313'] = [37.417958, -121.051042];
Zipcodes['95315'] = [37.422791, -120.774354];
Zipcodes['95316'] = [37.539625, -120.668689];
Zipcodes['95317'] = [37.12738, -120.506433];
Zipcodes['95318'] = [37.666045, -119.807549];
Zipcodes['95319'] = [37.629004, -120.885131];
Zipcodes['95320'] = [37.818556, -121.006905];
Zipcodes['95321'] = [37.931691, -119.836151];
Zipcodes['95322'] = [37.18197, -121.019969];
Zipcodes['95323'] = [37.604536, -120.68369];
Zipcodes['95324'] = [37.393149, -120.898989];
Zipcodes['95325'] = [37.480803, -120.244862];
Zipcodes['95326'] = [37.589098, -120.855436];
Zipcodes['95327'] = [37.84753, -120.486557];
Zipcodes['95328'] = [37.558049, -120.90941];
Zipcodes['95329'] = [37.67763, -120.41805];
Zipcodes['95330'] = [37.81345, -121.317273];
Zipcodes['95333'] = [37.244674, -120.242741];
Zipcodes['95334'] = [37.349677, -120.751284];
Zipcodes['95335'] = [38.124887, -120.093146];
Zipcodes['95336'] = [37.830267, -121.20102];
Zipcodes['95337'] = [37.743039, -121.23838];
Zipcodes['95338'] = [37.507011, -119.983789];
Zipcodes['95340'] = [37.340087, -120.406406];
Zipcodes['95341'] = [37.231045, -120.501072];
Zipcodes['95345'] = [37.590894, -119.96346];
Zipcodes['95346'] = [38.070475, -120.173353];
Zipcodes['95348'] = [37.420522, -120.499608];
Zipcodes['95350'] = [37.67173, -121.007285];
Zipcodes['95351'] = [37.624124, -120.997319];
Zipcodes['95354'] = [37.639029, -120.964772];
Zipcodes['95355'] = [37.672906, -120.946594];
Zipcodes['95356'] = [37.718573, -121.032309];
Zipcodes['95357'] = [37.671153, -120.886268];
Zipcodes['95358'] = [37.611634, -121.107858];
Zipcodes['95360'] = [37.326002, -121.038891];
Zipcodes['95361'] = [37.79219, -120.785662];
Zipcodes['95363'] = [37.373888, -121.251453];
Zipcodes['95364'] = [38.2247, -119.759979];
Zipcodes['95365'] = [37.301183, -120.331482];
Zipcodes['95366'] = [37.762766, -121.125277];
Zipcodes['95367'] = [37.7297, -120.946758];
Zipcodes['95368'] = [37.713269, -121.085404];
Zipcodes['95369'] = [37.525404, -120.40425];
Zipcodes['95370'] = [37.984401, -120.348066];
Zipcodes['95372'] = [37.991289, -120.262791];
Zipcodes['95374'] = [37.316807, -120.855753];
Zipcodes['95375'] = [38.225689, -119.960116];
Zipcodes['95376'] = [37.736819, -121.433998];
Zipcodes['95377'] = [37.674004, -121.516857];
Zipcodes['95379'] = [37.966386, -120.212648];
Zipcodes['95380'] = [37.47423, -120.875248];
Zipcodes['95382'] = [37.527558, -120.851106];
Zipcodes['95383'] = [38.092995, -120.232807];
Zipcodes['95385'] = [37.609821, -121.248495];
Zipcodes['95386'] = [37.66729, -120.622092];
Zipcodes['95387'] = [37.554375, -121.244651];
Zipcodes['95388'] = [37.421299, -120.599587];
Zipcodes['95389'] = [37.784127, -119.428263];
Zipcodes['95391'] = [37.78079, -121.598048];
Zipcodes['95401'] = [38.449137, -122.793752];
Zipcodes['95403'] = [38.503685, -122.754057];
Zipcodes['95404'] = [38.525733, -122.690443];
Zipcodes['95405'] = [38.438136, -122.669057];
Zipcodes['95407'] = [38.394656, -122.745932];
Zipcodes['95409'] = [38.468485, -122.582138];
Zipcodes['95410'] = [39.23494, -123.699854];
Zipcodes['95412'] = [38.712606, -123.314146];
Zipcodes['95415'] = [39.020509, -123.367549];
Zipcodes['95417'] = [39.687908, -123.650699];
Zipcodes['95419'] = [38.423469, -122.958181];
Zipcodes['95420'] = [39.359358, -123.766011];
Zipcodes['95421'] = [38.583569, -123.18267];
Zipcodes['95422'] = [38.985515, -122.642535];
Zipcodes['95423'] = [39.067376, -122.62972];
Zipcodes['95425'] = [38.823053, -123.063859];
Zipcodes['95426'] = [38.833425, -122.734061];
Zipcodes['95427'] = [39.253239, -123.569247];
Zipcodes['95428'] = [39.824803, -123.179609];
Zipcodes['95429'] = [39.756049, -123.358701];
Zipcodes['95430'] = [38.461425, -123.047186];
Zipcodes['95431'] = [38.349069, -122.516176];
Zipcodes['95432'] = [39.108492, -123.64542];
Zipcodes['95435'] = [39.003149, -122.874798];
Zipcodes['95436'] = [38.486828, -122.905617];
Zipcodes['95437'] = [39.443949, -123.68592];
Zipcodes['95439'] = [38.490556, -122.775906];
Zipcodes['95441'] = [38.721626, -123.047292];
Zipcodes['95442'] = [38.378218, -122.516487];
Zipcodes['95443'] = [39.050131, -122.739631];
Zipcodes['95444'] = [38.432624, -122.869241];
Zipcodes['95445'] = [38.83252, -123.472532];
Zipcodes['95446'] = [38.519427, -123.010679];
Zipcodes['95448'] = [38.622985, -122.889121];
Zipcodes['95449'] = [38.936137, -123.119445];
Zipcodes['95450'] = [38.509805, -123.208042];
Zipcodes['95451'] = [38.922136, -122.789564];
Zipcodes['95452'] = [38.429598, -122.536744];
Zipcodes['95453'] = [39.045931, -122.941077];
Zipcodes['95454'] = [39.784048, -123.49579];
Zipcodes['95456'] = [39.258886, -123.759164];
Zipcodes['95457'] = [38.924036, -122.549083];
Zipcodes['95458'] = [39.109008, -122.777635];
Zipcodes['95459'] = [38.976628, -123.604854];
Zipcodes['95460'] = [39.305245, -123.748616];
Zipcodes['95461'] = [38.754006, -122.55211];
Zipcodes['95462'] = [38.473361, -123.021541];
Zipcodes['95463'] = [39.18956, -123.580591];
Zipcodes['95464'] = [39.126246, -122.847075];
Zipcodes['95465'] = [38.417342, -123.046012];
Zipcodes['95466'] = [39.056924, -123.505651];
Zipcodes['95467'] = [38.812954, -122.548099];
Zipcodes['95468'] = [38.918887, -123.605684];
Zipcodes['95469'] = [39.398936, -123.075253];
Zipcodes['95470'] = [39.299023, -123.239243];
Zipcodes['95471'] = [38.532224, -122.97359];
Zipcodes['95472'] = [38.398815, -122.861923];
Zipcodes['95476'] = [38.255842, -122.460908];
Zipcodes['95480'] = [38.684685, -123.368051];
Zipcodes['95482'] = [39.136804, -123.261089];
Zipcodes['95485'] = [39.20847, -122.92572];
Zipcodes['95486'] = [38.474137, -123.024203];
Zipcodes['95488'] = [39.727177, -123.789174];
Zipcodes['95490'] = [39.47932, -123.34816];
Zipcodes['95492'] = [38.530486, -122.811835];
Zipcodes['95493'] = [39.222323, -122.985488];
Zipcodes['95494'] = [38.896447, -123.343183];
Zipcodes['95497'] = [38.725274, -123.458314];
Zipcodes['95501'] = [40.80201, -124.144824];
Zipcodes['95503'] = [40.738403, -124.107815];
Zipcodes['95511'] = [40.166028, -123.584144];
Zipcodes['95514'] = [40.286741, -123.662286];
Zipcodes['95519'] = [40.963005, -124.052501];
Zipcodes['95521'] = [40.843356, -124.045334];
Zipcodes['95524'] = [40.820835, -124.050022];
Zipcodes['95525'] = [40.953782, -123.824438];
Zipcodes['95526'] = [40.461528, -123.676487];
Zipcodes['95527'] = [40.950917, -123.421121];
Zipcodes['95528'] = [40.496986, -123.930811];
Zipcodes['95531'] = [41.862668, -124.130542];
Zipcodes['95536'] = [40.501241, -124.209928];
Zipcodes['95537'] = [40.727152, -124.219177];
Zipcodes['95538'] = [41.868335, -124.154257];
Zipcodes['95540'] = [40.566787, -124.125976];
Zipcodes['95542'] = [40.093254, -123.759831];
Zipcodes['95543'] = [41.888836, -123.802766];
Zipcodes['95545'] = [40.261551, -124.086134];
Zipcodes['95546'] = [41.185995, -123.731494];
Zipcodes['95547'] = [40.569463, -124.077504];
Zipcodes['95548'] = [41.589864, -124.06069];
Zipcodes['95549'] = [40.666676, -123.908382];
Zipcodes['95550'] = [40.78112, -123.835974];
Zipcodes['95551'] = [40.674987, -124.231146];
Zipcodes['95552'] = [40.344419, -123.417217];
Zipcodes['95553'] = [40.231152, -123.868597];
Zipcodes['95554'] = [40.296384, -123.777138];
Zipcodes['95555'] = [41.302551, -123.977637];
Zipcodes['95556'] = [41.307717, -123.587415];
Zipcodes['95558'] = [40.33758, -124.261177];
Zipcodes['95559'] = [40.190281, -123.748297];
Zipcodes['95560'] = [40.142727, -123.838819];
Zipcodes['95562'] = [40.4412, -124.100415];
Zipcodes['95563'] = [40.894251, -123.56406];
Zipcodes['95564'] = [40.79088, -124.204748];
Zipcodes['95565'] = [40.455186, -124.015198];
Zipcodes['95567'] = [41.950683, -124.097094];
Zipcodes['95568'] = [41.484857, -123.491487];
Zipcodes['95569'] = [40.361364, -123.862122];
Zipcodes['95570'] = [41.113752, -124.08264];
Zipcodes['95571'] = [40.365541, -123.94428];
Zipcodes['95573'] = [40.914926, -123.668723];
Zipcodes['95585'] = [39.858717, -123.666166];
Zipcodes['95587'] = [39.969684, -123.75537];
Zipcodes['95589'] = [40.061443, -123.979797];
Zipcodes['95595'] = [40.087565, -123.153498];
Zipcodes['95601'] = [38.424865, -120.830278];
Zipcodes['95602'] = [38.991491, -121.103544];
Zipcodes['95603'] = [38.911346, -121.08576];
Zipcodes['95604'] = [39.238526, -120.071431];
Zipcodes['95605'] = [38.593521, -121.539867];
Zipcodes['95606'] = [38.756412, -122.193271];
Zipcodes['95607'] = [38.830616, -122.126037];
Zipcodes['95608'] = [38.626935, -121.331191];
Zipcodes['95610'] = [38.694923, -121.272207];
Zipcodes['95612'] = [38.384706, -121.578721];
Zipcodes['95613'] = [38.81263, -120.891712];
Zipcodes['95614'] = [38.886238, -120.979699];
Zipcodes['95615'] = [38.310755, -121.541817];
Zipcodes['95616'] = [38.554147, -121.798514];
Zipcodes['95618'] = [38.543019, -121.690538];
Zipcodes['95619'] = [38.680605, -120.814261];
Zipcodes['95620'] = [38.404935, -121.754379];
Zipcodes['95621'] = [38.695494, -121.308624];
Zipcodes['95623'] = [38.632532, -120.844015];
Zipcodes['95624'] = [38.43415, -121.305874];
Zipcodes['95625'] = [38.357311, -121.908639];
Zipcodes['95626'] = [38.733794, -121.489006];
Zipcodes['95627'] = [38.734513, -122.025346];
Zipcodes['95628'] = [38.652096, -121.254384];
Zipcodes['95629'] = [38.512788, -120.687523];
Zipcodes['95630'] = [38.662781, -121.140107];
Zipcodes['95631'] = [39.054944, -120.753975];
Zipcodes['95632'] = [38.274451, -121.259201];
Zipcodes['95633'] = [38.849203, -120.822384];
Zipcodes['95634'] = [38.900629, -120.706782];
Zipcodes['95635'] = [38.910748, -120.908621];
Zipcodes['95636'] = [38.611654, -120.48682];
Zipcodes['95637'] = [38.853015, -122.27287];
Zipcodes['95638'] = [38.342681, -121.070152];
Zipcodes['95639'] = [38.40854, -121.495365];
Zipcodes['95640'] = [38.341564, -120.939989];
Zipcodes['95641'] = [38.155571, -121.604816];
Zipcodes['95642'] = [38.343506, -120.763948];
Zipcodes['95645'] = [38.894035, -121.796808];
Zipcodes['95646'] = [38.631743, -120.015362];
Zipcodes['95648'] = [38.922812, -121.312005];
Zipcodes['95650'] = [38.809175, -121.171375];
Zipcodes['95651'] = [38.820884, -120.93519];
Zipcodes['95652'] = [38.662638, -121.400828];
Zipcodes['95653'] = [38.697075, -121.978414];
Zipcodes['95655'] = [38.549228, -121.278598];
Zipcodes['95658'] = [38.881183, -121.169014];
Zipcodes['95659'] = [38.858745, -121.589913];
Zipcodes['95660'] = [38.678867, -121.3805];
Zipcodes['95661'] = [38.741274, -121.249266];
Zipcodes['95662'] = [38.689174, -121.218435];
Zipcodes['95663'] = [38.857372, -121.182526];
Zipcodes['95664'] = [38.801281, -121.037856];
Zipcodes['95665'] = [38.395176, -120.652755];
Zipcodes['95666'] = [38.541376, -120.329097];
Zipcodes['95667'] = [38.733395, -120.791347];
Zipcodes['95668'] = [38.827555, -121.495182];
Zipcodes['95669'] = [38.489518, -120.891642];
Zipcodes['95670'] = [38.604268, -121.280327];
Zipcodes['95671'] = [38.694464, -121.153753];
Zipcodes['95672'] = [38.72226, -120.992955];
Zipcodes['95673'] = [38.690518, -121.465662];
Zipcodes['95674'] = [38.953949, -121.481896];
Zipcodes['95675'] = [38.545458, -120.742909];
Zipcodes['95676'] = [38.87433, -121.713844];
Zipcodes['95677'] = [38.79292, -121.232006];
Zipcodes['95678'] = [38.765353, -121.288473];
Zipcodes['95679'] = [38.916595, -122.285276];
Zipcodes['95680'] = [38.241412, -121.57832];
Zipcodes['95681'] = [38.998092, -121.354198];
Zipcodes['95682'] = [38.598969, -120.963066];
Zipcodes['95683'] = [38.511992, -121.096261];
Zipcodes['95684'] = [38.566104, -120.501215];
Zipcodes['95685'] = [38.434012, -120.762015];
Zipcodes['95686'] = [38.157794, -121.520223];
Zipcodes['95687'] = [38.333133, -121.920151];
Zipcodes['95688'] = [38.421343, -122.030124];
Zipcodes['95689'] = [38.470889, -120.615679];
Zipcodes['95690'] = [38.203404, -121.626239];
Zipcodes['95691'] = [38.627951, -121.593287];
Zipcodes['95692'] = [39.043066, -121.409804];
Zipcodes['95693'] = [38.398531, -121.21951];
Zipcodes['95694'] = [38.57166, -122.064168];
Zipcodes['95695'] = [38.694038, -121.852932];
Zipcodes['95697'] = [38.732881, -121.807195];
Zipcodes['95698'] = [38.816357, -121.91077];
Zipcodes['95699'] = [38.436774, -120.857624];
Zipcodes['95701'] = [39.226549, -120.767022];
Zipcodes['95703'] = [38.989027, -120.987775];
Zipcodes['95709'] = [38.749032, -120.690916];
Zipcodes['95713'] = [39.081347, -120.93239];
Zipcodes['95714'] = [39.201159, -120.836798];
Zipcodes['95715'] = [39.276651, -120.644012];
Zipcodes['95717'] = [39.142594, -120.842689];
Zipcodes['95720'] = [38.850937, -120.251267];
Zipcodes['95721'] = [38.81614, -120.095999];
Zipcodes['95722'] = [39.009801, -121.034707];
Zipcodes['95726'] = [38.761976, -120.413495];
Zipcodes['95728'] = [39.294653, -120.401407];
Zipcodes['95735'] = [38.840287, -120.150294];
Zipcodes['95736'] = [39.039122, -120.977494];
Zipcodes['95742'] = [38.562201, -121.2047];
Zipcodes['95746'] = [38.746924, -121.166434];
Zipcodes['95747'] = [38.778421, -121.368829];
Zipcodes['95757'] = [38.331652, -121.436979];
Zipcodes['95758'] = [38.429667, -121.445213];
Zipcodes['95762'] = [38.683688, -121.064727];
Zipcodes['95765'] = [38.819738, -121.278321];
Zipcodes['95776'] = [38.698692, -121.699462];
Zipcodes['95811'] = [38.59318, -121.483098];
Zipcodes['95814'] = [38.580568, -121.49559];
Zipcodes['95815'] = [38.605628, -121.445453];
Zipcodes['95816'] = [38.575407, -121.464803];
Zipcodes['95817'] = [38.550605, -121.457026];
Zipcodes['95818'] = [38.554487, -121.497058];
Zipcodes['95819'] = [38.566862, -121.437563];
Zipcodes['95820'] = [38.53491, -121.444202];
Zipcodes['95821'] = [38.625693, -121.384916];
Zipcodes['95822'] = [38.512844, -121.494215];
Zipcodes['95823'] = [38.474726, -121.442636];
Zipcodes['95824'] = [38.517435, -121.44144];
Zipcodes['95825'] = [38.591897, -121.408467];
Zipcodes['95826'] = [38.543848, -121.378284];
Zipcodes['95827'] = [38.54836, -121.327497];
Zipcodes['95828'] = [38.489426, -121.395969];
Zipcodes['95829'] = [38.489546, -121.323452];
Zipcodes['95830'] = [38.490508, -121.284171];
Zipcodes['95831'] = [38.495787, -121.530256];
Zipcodes['95832'] = [38.447413, -121.496138];
Zipcodes['95833'] = [38.619049, -121.517552];
Zipcodes['95834'] = [38.635212, -121.518608];
Zipcodes['95835'] = [38.668491, -121.525602];
Zipcodes['95837'] = [38.693765, -121.598941];
Zipcodes['95838'] = [38.645723, -121.445254];
Zipcodes['95841'] = [38.660272, -121.348164];
Zipcodes['95842'] = [38.687038, -121.348865];
Zipcodes['95843'] = [38.715329, -121.363675];
Zipcodes['95864'] = [38.584047, -121.375846];
Zipcodes['95901'] = [39.223939, -121.49405];
Zipcodes['95903'] = [39.121296, -121.371098];
Zipcodes['95910'] = [39.462883, -120.829783];
Zipcodes['95912'] = [39.000399, -122.074234];
Zipcodes['95913'] = [39.629004, -122.192151];
Zipcodes['95914'] = [39.407586, -121.378772];
Zipcodes['95915'] = [39.991368, -121.230731];
Zipcodes['95916'] = [39.651415, -121.381635];
Zipcodes['95917'] = [39.435859, -121.763625];
Zipcodes['95918'] = [39.301229, -121.337929];
Zipcodes['95919'] = [39.434438, -121.265555];
Zipcodes['95920'] = [39.449794, -121.936372];
Zipcodes['95922'] = [39.46673, -121.071865];
Zipcodes['95923'] = [40.183804, -121.136688];
Zipcodes['95925'] = [39.484108, -121.192649];
Zipcodes['95926'] = [39.745772, -121.843923];
Zipcodes['95928'] = [39.681488, -121.83721];
Zipcodes['95929'] = [39.725787, -121.850213];
Zipcodes['95930'] = [39.548715, -121.159878];
Zipcodes['95932'] = [39.246934, -122.012177];
Zipcodes['95934'] = [40.0601, -120.907957];
Zipcodes['95935'] = [39.379344, -121.192261];
Zipcodes['95936'] = [39.580756, -120.774346];
Zipcodes['95937'] = [38.884065, -121.998485];
Zipcodes['95938'] = [39.604713, -121.795288];
Zipcodes['95939'] = [39.561685, -122.611698];
Zipcodes['95941'] = [39.516948, -121.273873];
Zipcodes['95942'] = [39.915994, -121.667031];
Zipcodes['95943'] = [39.575214, -122.034536];
Zipcodes['95944'] = [39.54016, -120.851449];
Zipcodes['95945'] = [39.193267, -120.980122];
Zipcodes['95946'] = [39.209159, -121.213824];
Zipcodes['95947'] = [40.159399, -120.844084];
Zipcodes['95948'] = [39.339264, -121.770785];
Zipcodes['95949'] = [39.10267, -121.133852];
Zipcodes['95950'] = [39.043447, -121.918099];
Zipcodes['95951'] = [39.722512, -122.004433];
Zipcodes['95953'] = [39.259738, -121.77633];
Zipcodes['95954'] = [39.854189, -121.577866];
Zipcodes['95955'] = [39.294516, -122.201715];
Zipcodes['95956'] = [39.88414, -121.115945];
Zipcodes['95957'] = [39.052285, -121.825177];
Zipcodes['95958'] = [39.564204, -121.797937];
Zipcodes['95959'] = [39.351529, -120.870549];
Zipcodes['95960'] = [39.441443, -121.016626];
Zipcodes['95961'] = [39.041333, -121.562835];
Zipcodes['95962'] = [39.323405, -121.272549];
Zipcodes['95963'] = [39.747832, -122.30336];
Zipcodes['95965'] = [39.593945, -121.581363];
Zipcodes['95966'] = [39.475369, -121.414133];
Zipcodes['95968'] = [39.435177, -121.551779];
Zipcodes['95969'] = [39.720742, -121.638663];
Zipcodes['95970'] = [39.410269, -122.05203];
Zipcodes['95971'] = [39.929228, -120.924431];
Zipcodes['95972'] = [39.422772, -121.337648];
Zipcodes['95973'] = [39.876526, -121.859642];
Zipcodes['95974'] = [39.497297, -121.755698];
Zipcodes['95975'] = [39.224325, -121.153696];
Zipcodes['95977'] = [39.176595, -121.291692];
Zipcodes['95978'] = [39.908439, -121.529616];
Zipcodes['95979'] = [39.290302, -122.475519];
Zipcodes['95981'] = [39.652827, -121.039944];
Zipcodes['95982'] = [39.173231, -121.805673];
Zipcodes['95983'] = [40.058261, -120.738932];
Zipcodes['95984'] = [39.982778, -121.127623];
Zipcodes['95986'] = [39.361516, -120.765192];
Zipcodes['95987'] = [39.103611, -122.247003];
Zipcodes['95988'] = [39.497191, -122.287568];
Zipcodes['95991'] = [39.015503, -121.611202];
Zipcodes['95993'] = [39.082497, -121.680114];
Zipcodes['96001'] = [40.577176, -122.454715];
Zipcodes['96002'] = [40.527769, -122.318645];
Zipcodes['96003'] = [40.667279, -122.319538];
Zipcodes['96006'] = [41.133532, -120.842566];
Zipcodes['96007'] = [40.456801, -122.308203];
Zipcodes['96008'] = [40.722641, -122.109784];
Zipcodes['96009'] = [41.05604, -121.020802];
Zipcodes['96010'] = [40.95028, -123.235155];
Zipcodes['96011'] = [41.055897, -121.891889];
Zipcodes['96013'] = [40.973657, -121.692399];
Zipcodes['96014'] = [41.388135, -122.780858];
Zipcodes['96015'] = [41.51178, -120.87766];
Zipcodes['96016'] = [40.894208, -121.508834];
Zipcodes['96017'] = [41.10596, -122.312517];
Zipcodes['96019'] = [40.687354, -122.39945];
Zipcodes['96020'] = [40.306787, -121.261874];
Zipcodes['96021'] = [39.923479, -122.270454];
Zipcodes['96022'] = [40.336308, -122.447977];
Zipcodes['96023'] = [41.909076, -121.976846];
Zipcodes['96024'] = [40.547045, -122.881185];
Zipcodes['96025'] = [41.200136, -122.319045];
Zipcodes['96027'] = [41.389877, -123.231074];
Zipcodes['96028'] = [41.007447, -121.457463];
Zipcodes['96029'] = [39.939297, -122.489191];
Zipcodes['96031'] = [41.175624, -123.198176];
Zipcodes['96032'] = [41.585132, -122.979631];
Zipcodes['96033'] = [40.740573, -122.592563];
Zipcodes['96034'] = [41.444019, -122.60496];
Zipcodes['96035'] = [40.04156, -122.185031];
Zipcodes['96037'] = [41.544922, -122.920964];
Zipcodes['96038'] = [41.6071, -122.545715];
Zipcodes['96039'] = [41.668928, -123.376416];
Zipcodes['96040'] = [40.789806, -121.474515];
Zipcodes['96041'] = [40.513498, -123.196046];
Zipcodes['96044'] = [41.933028, -122.674967];
Zipcodes['96046'] = [40.610365, -123.428901];
Zipcodes['96047'] = [40.479226, -122.692468];
Zipcodes['96048'] = [40.830359, -123.023489];
Zipcodes['96050'] = [41.853472, -122.951015];
Zipcodes['96051'] = [40.916328, -122.310822];
Zipcodes['96052'] = [40.68268, -122.786454];
Zipcodes['96054'] = [41.261057, -121.118551];
Zipcodes['96055'] = [40.099121, -121.998375];
Zipcodes['96056'] = [41.067151, -121.317746];
Zipcodes['96057'] = [41.271859, -121.919695];
Zipcodes['96058'] = [41.727048, -121.907369];
Zipcodes['96059'] = [40.433747, -121.829421];
Zipcodes['96061'] = [40.347865, -121.487779];
Zipcodes['96062'] = [40.555597, -122.091265];
Zipcodes['96063'] = [40.370374, -121.606194];
Zipcodes['96064'] = [41.754703, -122.365621];
Zipcodes['96065'] = [40.890167, -121.956786];
Zipcodes['96067'] = [41.322137, -122.321022];
Zipcodes['96068'] = [41.102992, -121.19834];
Zipcodes['96069'] = [40.685336, -122.016069];
Zipcodes['96071'] = [40.694474, -121.457269];
Zipcodes['96073'] = [40.598601, -122.19493];
Zipcodes['96074'] = [39.862061, -122.590966];
Zipcodes['96075'] = [40.362328, -121.876387];
Zipcodes['96076'] = [40.391542, -122.937883];
Zipcodes['96078'] = [40.082374, -122.176071];
Zipcodes['96080'] = [40.16538, -122.441718];
Zipcodes['96084'] = [40.795834, -121.868694];
Zipcodes['96085'] = [41.740818, -123.077331];
Zipcodes['96086'] = [41.924212, -123.260809];
Zipcodes['96087'] = [40.633134, -122.58707];
Zipcodes['96088'] = [40.51087, -121.876112];
Zipcodes['96090'] = [40.007351, -122.119339];
Zipcodes['96091'] = [41.043873, -122.800791];
Zipcodes['96092'] = [39.970579, -121.970304];
Zipcodes['96093'] = [40.765478, -122.941428];
Zipcodes['96094'] = [41.514922, -122.350943];
Zipcodes['96096'] = [40.636311, -121.876399];
Zipcodes['96097'] = [41.756147, -122.659466];
Zipcodes['96101'] = [41.456252, -120.511306];
Zipcodes['96103'] = [39.780156, -120.670755];
Zipcodes['96104'] = [41.460183, -120.129865];
Zipcodes['96105'] = [39.688645, -120.143996];
Zipcodes['96106'] = [39.735482, -120.543862];
Zipcodes['96107'] = [38.408277, -119.548075];
Zipcodes['96108'] = [41.78726, -120.401722];
Zipcodes['96109'] = [39.994178, -120.101708];
Zipcodes['96110'] = [41.241917, -120.124629];
Zipcodes['96111'] = [39.415013, -120.017132];
Zipcodes['96112'] = [41.892497, -120.171944];
Zipcodes['96113'] = [40.188076, -120.145684];
Zipcodes['96114'] = [40.292605, -120.488614];
Zipcodes['96115'] = [41.691471, -120.079321];
Zipcodes['96116'] = [41.257291, -120.446007];
Zipcodes['96117'] = [40.533462, -120.235356];
Zipcodes['96118'] = [39.639597, -120.247144];
Zipcodes['96119'] = [41.040421, -120.553555];
Zipcodes['96120'] = [38.742445, -119.869382];
Zipcodes['96121'] = [40.164393, -120.380042];
Zipcodes['96122'] = [39.820738, -120.47627];
Zipcodes['96123'] = [40.845738, -120.322219];
Zipcodes['96124'] = [39.638793, -120.467942];
Zipcodes['96125'] = [39.613559, -120.602378];
Zipcodes['96126'] = [39.549726, -120.403579];
Zipcodes['96128'] = [40.348899, -120.406017];
Zipcodes['96129'] = [39.835122, -120.360049];
Zipcodes['96130'] = [40.549051, -120.634032];
Zipcodes['96132'] = [40.900067, -120.513882];
Zipcodes['96133'] = [38.620964, -119.505184];
Zipcodes['96134'] = [41.835365, -121.44499];
Zipcodes['96135'] = [39.812985, -120.20928];
Zipcodes['96136'] = [40.34623, -120.081321];
Zipcodes['96137'] = [40.469325, -121.229871];
Zipcodes['96140'] = [39.232018, -120.0964];
Zipcodes['96141'] = [39.098928, -120.205231];
Zipcodes['96142'] = [38.998631, -120.103881];
Zipcodes['96143'] = [39.260575, -120.026291];
Zipcodes['96145'] = [39.180998, -120.151396];
Zipcodes['96146'] = [39.215897, -120.24517];
Zipcodes['96148'] = [39.249777, -120.05759];
Zipcodes['96150'] = [38.87332, -120.068481];
Zipcodes['96155'] = [38.762986, -120.087069];
Zipcodes['96161'] = [39.347714, -120.22471];
Zipcodes['96701'] = [21.406053, -157.884952];
Zipcodes['96703'] = [22.13614, -159.371914];
Zipcodes['96704'] = [19.294365, -155.840633];
Zipcodes['96705'] = [21.899532, -159.568546];
Zipcodes['96706'] = [21.344782, -158.022298];
Zipcodes['96707'] = [21.363106, -158.082206];
Zipcodes['96708'] = [20.88283, -156.232168];
Zipcodes['96710'] = [19.842479, -155.247512];
Zipcodes['96712'] = [21.651861, -157.982756];
Zipcodes['96713'] = [20.73415, -156.106979];
Zipcodes['96714'] = [22.13125, -159.520972];
Zipcodes['96716'] = [21.910263, -159.600932];
Zipcodes['96717'] = [21.557794, -157.90534];
Zipcodes['96719'] = [20.240185, -155.833027];
Zipcodes['96720'] = [19.65981, -155.232473];
Zipcodes['96722'] = [22.18838, -159.460894];
Zipcodes['96725'] = [19.596712, -155.901448];
Zipcodes['96726'] = [19.421466, -155.810917];
Zipcodes['96727'] = [20.105628, -155.486032];
Zipcodes['96728'] = [19.86198, -155.129014];
Zipcodes['96729'] = [21.17788, -157.12032];
Zipcodes['96730'] = [21.538215, -157.844346];
Zipcodes['96731'] = [21.678079, -157.969207];
Zipcodes['96732'] = [20.877263, -156.460289];
Zipcodes['96734'] = [21.395084, -157.758188];
Zipcodes['96737'] = [19.107314, -155.780612];
Zipcodes['96738'] = [19.871941, -155.802163];
Zipcodes['96740'] = [19.75682, -155.919968];
Zipcodes['96741'] = [21.955528, -159.515192];
Zipcodes['96742'] = [21.176668, -156.965748];
Zipcodes['96743'] = [20.02661, -155.783672];
Zipcodes['96744'] = [21.447639, -157.82885];
Zipcodes['96746'] = [22.092806, -159.352501];
Zipcodes['96747'] = [21.916529, -159.631835];
Zipcodes['96748'] = [21.131173, -156.835237];
Zipcodes['96749'] = [19.621282, -154.989735];
Zipcodes['96750'] = [19.522551, -155.916577];
Zipcodes['96751'] = [22.10668, -159.305017];
Zipcodes['96752'] = [22.116819, -159.680753];
Zipcodes['96753'] = [20.727291, -156.440699];
Zipcodes['96754'] = [22.188693, -159.403313];
Zipcodes['96755'] = [20.19059, -155.772657];
Zipcodes['96756'] = [21.904822, -159.453084];
Zipcodes['96757'] = [21.154453, -156.977379];
Zipcodes['96759'] = [21.460185, -158.059966];
Zipcodes['96760'] = [19.55233, -155.05481];
Zipcodes['96761'] = [20.919732, -156.616806];
Zipcodes['96762'] = [21.618531, -157.9439];
Zipcodes['96763'] = [20.836482, -156.927377];
Zipcodes['96764'] = [19.993996, -155.247917];
Zipcodes['96765'] = [21.925575, -159.499138];
Zipcodes['96766'] = [22.011419, -159.405069];
Zipcodes['96768'] = [20.829164, -156.297222];
Zipcodes['96769'] = [21.958619, -159.61846];
Zipcodes['96770'] = [21.13432, -157.210856];
Zipcodes['96771'] = [19.525888, -155.143981];
Zipcodes['96772'] = [19.065548, -155.668201];
Zipcodes['96773'] = [19.912071, -155.276904];
Zipcodes['96774'] = [19.968674, -155.315061];
Zipcodes['96776'] = [20.002196, -155.375449];
Zipcodes['96777'] = [19.227605, -155.466558];
Zipcodes['96778'] = [19.427903, -155.08911];
Zipcodes['96779'] = [20.909974, -156.384337];
Zipcodes['96780'] = [19.965975, -155.224929];
Zipcodes['96781'] = [19.805841, -155.200161];
Zipcodes['96782'] = [21.451242, -157.915616];
Zipcodes['96783'] = [19.843088, -155.106084];
Zipcodes['96785'] = [19.478177, -155.230158];
Zipcodes['96786'] = [21.537569, -158.022383];
Zipcodes['96789'] = [21.483875, -157.934284];
Zipcodes['96790'] = [20.711696, -156.294358];
Zipcodes['96791'] = [21.56364, -158.185065];
Zipcodes['96792'] = [21.468625, -158.166906];
Zipcodes['96793'] = [20.89556, -156.529838];
Zipcodes['96795'] = [21.335013, -157.710924];
Zipcodes['96796'] = [21.961892, -159.667851];
Zipcodes['96797'] = [21.40845, -158.017646];
Zipcodes['96799'] = [-14.322923, -170.752349];
Zipcodes['96813'] = [21.316548, -157.845053];
Zipcodes['96814'] = [21.291214, -157.844002];
Zipcodes['96815'] = [21.27216, -157.822286];
Zipcodes['96816'] = [21.29122, -157.790863];
Zipcodes['96817'] = [21.355506, -157.821647];
Zipcodes['96818'] = [21.342518, -157.939826];
Zipcodes['96819'] = [21.347117, -157.877317];
Zipcodes['96820'] = [21.336329, -157.920711];
Zipcodes['96821'] = [21.311172, -157.75023];
Zipcodes['96822'] = [21.320468, -157.810364];
Zipcodes['96825'] = [21.292852, -157.692266];
Zipcodes['96826'] = [21.291518, -157.826869];
Zipcodes['96848'] = [21.301329, -157.814276];
Zipcodes['96850'] = [21.303792, -157.862662];
Zipcodes['96853'] = [21.335083, -157.940482];
Zipcodes['96857'] = [21.488059, -158.05302];
Zipcodes['96858'] = [21.344563, -157.883446];
Zipcodes['96859'] = [21.36005, -157.889776];
Zipcodes['96860'] = [21.356893, -157.947265];
Zipcodes['96863'] = [21.436909, -157.745463];
Zipcodes['96910'] = [13.452852, 144.747191];
Zipcodes['96913'] = [13.478311, 144.815058];
Zipcodes['96915'] = [13.374452, 144.708125];
Zipcodes['96916'] = [13.265897, 144.689161];
Zipcodes['96917'] = [13.282464, 144.742688];
Zipcodes['96928'] = [13.355917, 144.657305];
Zipcodes['96929'] = [13.567326, 144.878059];
Zipcodes['96950'] = [15.186894, 145.754396];
Zipcodes['96951'] = [14.152493, 145.212904];
Zipcodes['96952'] = [15.009768, 145.621127];
Zipcodes['97001'] = [44.990352, -120.60398];
Zipcodes['97002'] = [45.240483, -122.793841];
Zipcodes['97003'] = [45.503723, -122.864778];
Zipcodes['97004'] = [45.254962, -122.449377];
Zipcodes['97005'] = [45.491429, -122.803974];
Zipcodes['97006'] = [45.52598, -122.855888];
Zipcodes['97007'] = [45.442813, -122.871155];
Zipcodes['97008'] = [45.460435, -122.802481];
Zipcodes['97009'] = [45.422994, -122.33277];
Zipcodes['97011'] = [45.387422, -122.03674];
Zipcodes['97013'] = [45.220824, -122.66828];
Zipcodes['97014'] = [45.643396, -121.78935];
Zipcodes['97015'] = [45.416832, -122.533128];
Zipcodes['97016'] = [46.052109, -123.262509];
Zipcodes['97017'] = [45.17301, -122.368645];
Zipcodes['97018'] = [45.897546, -122.812402];
Zipcodes['97019'] = [45.482455, -122.190722];
Zipcodes['97020'] = [45.222642, -122.838847];
Zipcodes['97021'] = [45.38544, -121.120556];
Zipcodes['97022'] = [45.346725, -122.319961];
Zipcodes['97023'] = [45.204013, -122.200657];
Zipcodes['97024'] = [45.544172, -122.439611];
Zipcodes['97026'] = [45.10466, -122.937725];
Zipcodes['97027'] = [45.38565, -122.592827];
Zipcodes['97028'] = [45.288449, -121.807281];
Zipcodes['97029'] = [45.300418, -120.813181];
Zipcodes['97030'] = [45.509906, -122.433165];
Zipcodes['97031'] = [45.610947, -121.601374];
Zipcodes['97032'] = [45.179495, -122.785599];
Zipcodes['97033'] = [45.20048, -120.584073];
Zipcodes['97034'] = [45.409482, -122.685688];
Zipcodes['97035'] = [45.413601, -122.725252];
Zipcodes['97037'] = [45.067048, -121.027621];
Zipcodes['97038'] = [45.095438, -122.558954];
Zipcodes['97039'] = [45.47118, -120.618953];
Zipcodes['97040'] = [45.617111, -121.388115];
Zipcodes['97041'] = [45.419804, -121.595831];
Zipcodes['97042'] = [45.207722, -122.537224];
Zipcodes['97045'] = [45.320278, -122.536461];
Zipcodes['97048'] = [46.014428, -122.974795];
Zipcodes['97049'] = [45.370656, -121.87327];
Zipcodes['97050'] = [45.67358, -120.781632];
Zipcodes['97051'] = [45.888686, -122.920711];
Zipcodes['97053'] = [45.827979, -122.883286];
Zipcodes['97054'] = [45.960809, -122.92737];
Zipcodes['97055'] = [45.361095, -122.157888];
Zipcodes['97056'] = [45.799539, -122.96885];
Zipcodes['97057'] = [45.004221, -120.751607];
Zipcodes['97058'] = [45.552489, -121.151799];
Zipcodes['97060'] = [45.531327, -122.36909];
Zipcodes['97062'] = [45.365223, -122.762255];
Zipcodes['97063'] = [45.224901, -121.301127];
Zipcodes['97064'] = [45.859077, -123.235525];
Zipcodes['97065'] = [45.607642, -120.644619];
Zipcodes['97067'] = [45.323984, -121.990453];
Zipcodes['97068'] = [45.351714, -122.66902];
Zipcodes['97070'] = [45.308221, -122.770991];
Zipcodes['97071'] = [45.134191, -122.826487];
Zipcodes['97078'] = [45.476351, -122.890808];
Zipcodes['97080'] = [45.478314, -122.390727];
Zipcodes['97086'] = [45.445315, -122.527925];
Zipcodes['97089'] = [45.426416, -122.442992];
Zipcodes['97101'] = [45.090196, -123.228702];
Zipcodes['97102'] = [45.789443, -123.942252];
Zipcodes['97103'] = [46.13334, -123.698966];
Zipcodes['97106'] = [45.654355, -123.136366];
Zipcodes['97107'] = [45.560392, -123.887436];
Zipcodes['97108'] = [45.27423, -123.675455];
Zipcodes['97109'] = [45.73774, -123.194646];
Zipcodes['97110'] = [45.891851, -123.951192];
Zipcodes['97111'] = [45.284527, -123.195204];
Zipcodes['97112'] = [45.275046, -123.876355];
Zipcodes['97113'] = [45.507876, -123.042958];
Zipcodes['97114'] = [45.187855, -123.07657];
Zipcodes['97115'] = [45.277411, -123.027128];
Zipcodes['97116'] = [45.58087, -123.165766];
Zipcodes['97117'] = [45.631941, -123.335885];
Zipcodes['97118'] = [45.556365, -123.916492];
Zipcodes['97119'] = [45.471666, -123.221412];
Zipcodes['97121'] = [46.203665, -123.981224];
Zipcodes['97122'] = [45.161422, -123.82578];
Zipcodes['97123'] = [45.444372, -122.976426];
Zipcodes['97124'] = [45.569201, -122.947143];
Zipcodes['97125'] = [45.663043, -123.216945];
Zipcodes['97127'] = [45.246263, -123.111527];
Zipcodes['97128'] = [45.211741, -123.281699];
Zipcodes['97130'] = [45.706794, -123.93868];
Zipcodes['97131'] = [45.711405, -123.824306];
Zipcodes['97132'] = [45.324219, -122.98733];
Zipcodes['97133'] = [45.689392, -123.013035];
Zipcodes['97134'] = [45.461766, -123.970593];
Zipcodes['97135'] = [45.19858, -123.961925];
Zipcodes['97136'] = [45.630592, -123.920769];
Zipcodes['97137'] = [45.219509, -122.948493];
Zipcodes['97138'] = [45.905454, -123.708754];
Zipcodes['97140'] = [45.35308, -122.865845];
Zipcodes['97141'] = [45.507531, -123.737139];
Zipcodes['97144'] = [45.743491, -123.303052];
Zipcodes['97145'] = [45.84373, -123.951649];
Zipcodes['97146'] = [46.12514, -123.911368];
Zipcodes['97147'] = [45.685605, -123.88613];
Zipcodes['97148'] = [45.361441, -123.259852];
Zipcodes['97149'] = [45.112951, -123.939045];
Zipcodes['97201'] = [45.508551, -122.688283];
Zipcodes['97202'] = [45.482012, -122.644451];
Zipcodes['97203'] = [45.603552, -122.737916];
Zipcodes['97204'] = [45.518371, -122.673946];
Zipcodes['97205'] = [45.52009, -122.702169];
Zipcodes['97206'] = [45.481598, -122.598295];
Zipcodes['97208'] = [45.528666, -122.678981];
Zipcodes['97209'] = [45.531124, -122.683951];
Zipcodes['97210'] = [45.545484, -122.736853];
Zipcodes['97211'] = [45.585627, -122.637259];
Zipcodes['97212'] = [45.544256, -122.643248];
Zipcodes['97213'] = [45.538194, -122.600014];
Zipcodes['97214'] = [45.513962, -122.644031];
Zipcodes['97215'] = [45.51505, -122.597392];
Zipcodes['97216'] = [45.513946, -122.558527];
Zipcodes['97217'] = [45.596784, -122.695414];
Zipcodes['97218'] = [45.576236, -122.600873];
Zipcodes['97219'] = [45.454231, -122.698526];
Zipcodes['97220'] = [45.550787, -122.55956];
Zipcodes['97221'] = [45.496555, -122.730453];
Zipcodes['97222'] = [45.440273, -122.615888];
Zipcodes['97223'] = [45.44029, -122.776604];
Zipcodes['97224'] = [45.4057, -122.795689];
Zipcodes['97225'] = [45.503048, -122.770132];
Zipcodes['97227'] = [45.544151, -122.677095];
Zipcodes['97229'] = [45.549242, -122.809952];
Zipcodes['97230'] = [45.557757, -122.505268];
Zipcodes['97231'] = [45.687631, -122.824202];
Zipcodes['97232'] = [45.528929, -122.643927];
Zipcodes['97233'] = [45.515307, -122.502168];
Zipcodes['97236'] = [45.483, -122.512338];
Zipcodes['97239'] = [45.489938, -122.690453];
Zipcodes['97258'] = [45.513258, -122.675435];
Zipcodes['97266'] = [45.482967, -122.557619];
Zipcodes['97267'] = [45.408427, -122.612867];
Zipcodes['97301'] = [44.951839, -123.006246];
Zipcodes['97302'] = [44.903176, -123.064432];
Zipcodes['97303'] = [45.030354, -123.023689];
Zipcodes['97304'] = [45.006596, -123.111691];
Zipcodes['97305'] = [45.015712, -122.929101];
Zipcodes['97306'] = [44.842904, -123.094751];
Zipcodes['97310'] = [44.875911, -122.974514];
Zipcodes['97317'] = [44.902416, -122.906292];
Zipcodes['97321'] = [44.65345, -123.139526];
Zipcodes['97322'] = [44.627553, -123.017613];
Zipcodes['97324'] = [44.33862, -123.62776];
Zipcodes['97325'] = [44.833821, -122.85223];
Zipcodes['97326'] = [44.637919, -123.665781];
Zipcodes['97327'] = [44.375669, -122.947812];
Zipcodes['97329'] = [44.371153, -122.289743];
Zipcodes['97330'] = [44.647997, -123.259576];
Zipcodes['97331'] = [44.56339, -123.281237];
Zipcodes['97333'] = [44.468069, -123.293831];
Zipcodes['97335'] = [44.637754, -122.880457];
Zipcodes['97336'] = [44.356759, -122.850832];
Zipcodes['97338'] = [44.9352, -123.339152];
Zipcodes['97341'] = [44.819359, -124.043333];
Zipcodes['97342'] = [44.739311, -121.879087];
Zipcodes['97343'] = [44.575254, -123.75149];
Zipcodes['97344'] = [44.868832, -123.465969];
Zipcodes['97345'] = [44.411545, -122.578909];
Zipcodes['97346'] = [44.769479, -122.378538];
Zipcodes['97347'] = [45.078353, -123.659383];
Zipcodes['97348'] = [44.383294, -123.122261];
Zipcodes['97350'] = [44.658055, -121.987865];
Zipcodes['97351'] = [44.815295, -123.129486];
Zipcodes['97352'] = [44.749395, -123.027593];
Zipcodes['97355'] = [44.532241, -122.821044];
Zipcodes['97357'] = [44.752661, -123.825581];
Zipcodes['97358'] = [44.81199, -122.399639];
Zipcodes['97360'] = [44.750447, -122.482368];
Zipcodes['97361'] = [44.778573, -123.326057];
Zipcodes['97362'] = [45.062012, -122.75654];
Zipcodes['97364'] = [44.998839, -123.986967];
Zipcodes['97365'] = [44.671297, -124.030569];
Zipcodes['97366'] = [44.581254, -124.047978];
Zipcodes['97367'] = [44.914756, -123.974231];
Zipcodes['97368'] = [45.024543, -123.903115];
Zipcodes['97369'] = [44.76005, -124.056774];
Zipcodes['97370'] = [44.564899, -123.47559];
Zipcodes['97371'] = [44.990634, -123.191125];
Zipcodes['97373'] = [45.057999, -122.77642];
Zipcodes['97374'] = [44.69286, -122.774937];
Zipcodes['97375'] = [44.986965, -122.598275];
Zipcodes['97376'] = [44.498235, -123.975013];
Zipcodes['97377'] = [44.46479, -123.103063];
Zipcodes['97378'] = [45.084883, -123.422867];
Zipcodes['97380'] = [44.786018, -123.929969];
Zipcodes['97381'] = [44.939485, -122.720739];
Zipcodes['97383'] = [44.79942, -122.722525];
Zipcodes['97384'] = [44.791099, -122.620074];
Zipcodes['97385'] = [44.865853, -122.72355];
Zipcodes['97386'] = [44.359177, -122.727043];
Zipcodes['97388'] = [44.880014, -124.018204];
Zipcodes['97389'] = [44.532672, -123.090331];
Zipcodes['97390'] = [44.355893, -123.862682];
Zipcodes['97391'] = [44.58498, -123.884047];
Zipcodes['97392'] = [44.795611, -122.930376];
Zipcodes['97394'] = [44.398974, -124.016407];
Zipcodes['97396'] = [45.104718, -123.549185];
Zipcodes['97401'] = [44.067988, -123.080181];
Zipcodes['97402'] = [44.047736, -123.230905];
Zipcodes['97403'] = [44.035412, -123.052413];
Zipcodes['97404'] = [44.105226, -123.133025];
Zipcodes['97405'] = [43.953602, -123.155864];
Zipcodes['97406'] = [42.615873, -124.021266];
Zipcodes['97408'] = [44.142995, -123.058241];
Zipcodes['97410'] = [42.801618, -123.11932];
Zipcodes['97411'] = [43.058954, -124.346773];
Zipcodes['97412'] = [44.20493, -123.54782];
Zipcodes['97413'] = [44.053948, -121.945845];
Zipcodes['97414'] = [42.967578, -124.1561];
Zipcodes['97415'] = [42.13783, -124.133433];
Zipcodes['97416'] = [43.037299, -123.695025];
Zipcodes['97417'] = [42.94021, -123.214865];
Zipcodes['97419'] = [44.180828, -123.413145];
Zipcodes['97420'] = [43.346779, -124.105673];
Zipcodes['97423'] = [43.185814, -124.174163];
Zipcodes['97424'] = [43.746815, -123.053386];
Zipcodes['97426'] = [43.89893, -123.029954];
Zipcodes['97429'] = [42.967743, -123.054406];
Zipcodes['97430'] = [44.155053, -123.711683];
Zipcodes['97431'] = [43.869538, -122.84149];
Zipcodes['97432'] = [43.104294, -123.429446];
Zipcodes['97434'] = [43.621458, -122.702625];
Zipcodes['97435'] = [43.690188, -123.328152];
Zipcodes['97436'] = [43.643348, -123.586975];
Zipcodes['97437'] = [44.099083, -123.38707];
Zipcodes['97438'] = [43.944242, -122.682456];
Zipcodes['97439'] = [44.073537, -124.0275];
Zipcodes['97441'] = [43.751829, -124.166146];
Zipcodes['97442'] = [42.780374, -123.425369];
Zipcodes['97443'] = [43.250484, -123.004257];
Zipcodes['97444'] = [42.440347, -124.327441];
Zipcodes['97446'] = [44.260727, -123.054648];
Zipcodes['97447'] = [43.277863, -122.49955];
Zipcodes['97448'] = [44.210894, -123.279343];
Zipcodes['97449'] = [43.563367, -124.040505];
Zipcodes['97450'] = [42.908908, -124.374673];
Zipcodes['97451'] = [43.853108, -123.307095];
Zipcodes['97452'] = [43.874112, -122.750081];
Zipcodes['97453'] = [43.980396, -123.850469];
Zipcodes['97454'] = [44.218056, -122.819137];
Zipcodes['97455'] = [43.968553, -122.919656];
Zipcodes['97456'] = [44.343303, -123.355];
Zipcodes['97457'] = [43.064513, -123.229471];
Zipcodes['97458'] = [43.048844, -124.008543];
Zipcodes['97459'] = [43.484217, -124.182735];
Zipcodes['97461'] = [44.098278, -123.505515];
Zipcodes['97462'] = [43.486928, -123.41831];
Zipcodes['97463'] = [43.740681, -122.387167];
Zipcodes['97465'] = [42.763521, -124.344681];
Zipcodes['97466'] = [42.848945, -124.085233];
Zipcodes['97467'] = [43.738923, -123.907887];
Zipcodes['97469'] = [42.922517, -123.417703];
Zipcodes['97470'] = [43.250676, -123.24241];
Zipcodes['97471'] = [43.21907, -123.487894];
Zipcodes['97473'] = [43.693676, -123.914793];
Zipcodes['97476'] = [42.842229, -124.402796];
Zipcodes['97477'] = [44.058465, -123.011597];
Zipcodes['97478'] = [44.089122, -122.842278];
Zipcodes['97479'] = [43.403997, -123.196293];
Zipcodes['97480'] = [44.128446, -123.832726];
Zipcodes['97481'] = [43.115279, -123.56598];
Zipcodes['97484'] = [42.933641, -122.845947];
Zipcodes['97486'] = [43.351883, -123.528156];
Zipcodes['97487'] = [43.988857, -123.3872];
Zipcodes['97488'] = [44.128572, -122.41733];
Zipcodes['97489'] = [44.11146, -122.60682];
Zipcodes['97490'] = [44.00792, -123.605229];
Zipcodes['97492'] = [43.803028, -122.515672];
Zipcodes['97493'] = [43.904594, -124.023424];
Zipcodes['97494'] = [43.330557, -123.327782];
Zipcodes['97495'] = [43.281204, -123.314335];
Zipcodes['97496'] = [43.066483, -123.472707];
Zipcodes['97497'] = [42.665706, -123.425495];
Zipcodes['97498'] = [44.273368, -124.009002];
Zipcodes['97499'] = [43.591682, -123.237036];
Zipcodes['97501'] = [42.285729, -122.897456];
Zipcodes['97502'] = [42.408529, -122.945969];
Zipcodes['97503'] = [42.604691, -122.923719];
Zipcodes['97504'] = [42.328207, -122.797427];
Zipcodes['97520'] = [42.169559, -122.511785];
Zipcodes['97522'] = [42.633855, -122.434112];
Zipcodes['97523'] = [42.104149, -123.644574];
Zipcodes['97524'] = [42.467469, -122.647705];
Zipcodes['97525'] = [42.430826, -123.059798];
Zipcodes['97526'] = [42.535703, -123.338257];
Zipcodes['97527'] = [42.380766, -123.386568];
Zipcodes['97530'] = [42.140414, -123.111983];
Zipcodes['97531'] = [42.210128, -123.644729];
Zipcodes['97532'] = [42.518364, -123.656431];
Zipcodes['97534'] = [42.019254, -123.699385];
Zipcodes['97535'] = [42.267976, -122.812339];
Zipcodes['97536'] = [42.781908, -122.494698];
Zipcodes['97537'] = [42.552527, -123.145949];
Zipcodes['97538'] = [42.314375, -123.667583];
Zipcodes['97539'] = [42.576422, -122.786215];
Zipcodes['97540'] = [42.192697, -122.817256];
Zipcodes['97541'] = [42.775859, -122.745256];
Zipcodes['97543'] = [42.377249, -123.560325];
Zipcodes['97544'] = [42.207015, -123.300113];
Zipcodes['97601'] = [42.443391, -122.074145];
Zipcodes['97603'] = [42.132624, -121.664612];
Zipcodes['97604'] = [42.977733, -122.206736];
Zipcodes['97620'] = [42.276396, -119.90391];
Zipcodes['97621'] = [42.507326, -121.285253];
Zipcodes['97622'] = [42.391276, -121.006931];
Zipcodes['97623'] = [42.198886, -121.312645];
Zipcodes['97624'] = [42.548485, -121.849636];
Zipcodes['97625'] = [42.315005, -121.58392];
Zipcodes['97626'] = [42.724806, -122.013922];
Zipcodes['97627'] = [42.070151, -122.072244];
Zipcodes['97630'] = [42.30592, -120.387701];
Zipcodes['97632'] = [42.036014, -121.405479];
Zipcodes['97633'] = [42.03414, -121.562829];
Zipcodes['97634'] = [42.133724, -121.802382];
Zipcodes['97635'] = [41.929992, -120.294195];
Zipcodes['97636'] = [42.657898, -120.578432];
Zipcodes['97637'] = [42.472998, -119.960274];
Zipcodes['97638'] = [43.149706, -121.019853];
Zipcodes['97639'] = [42.428528, -121.491604];
Zipcodes['97640'] = [42.986303, -120.536402];
Zipcodes['97641'] = [43.28819, -120.531731];
Zipcodes['97701'] = [43.997653, -121.034186];
Zipcodes['97702'] = [43.982794, -121.209726];
Zipcodes['97703'] = [44.176389, -121.382887];
Zipcodes['97707'] = [43.826223, -121.492124];
Zipcodes['97709'] = [43.897567, -121.858605];
Zipcodes['97710'] = [42.143383, -118.538755];
Zipcodes['97711'] = [44.706235, -120.667902];
Zipcodes['97712'] = [43.77784, -120.40625];
Zipcodes['97720'] = [43.58683, -118.896086];
Zipcodes['97721'] = [42.526347, -118.446082];
Zipcodes['97722'] = [43.00734, -118.727222];
Zipcodes['97730'] = [44.522652, -121.645373];
Zipcodes['97731'] = [43.089622, -121.855238];
Zipcodes['97732'] = [43.485397, -118.323574];
Zipcodes['97733'] = [43.420396, -121.926184];
Zipcodes['97734'] = [44.53136, -121.335136];
Zipcodes['97735'] = [43.390117, -120.992389];
Zipcodes['97736'] = [42.674267, -118.901909];
Zipcodes['97737'] = [43.499532, -121.761312];
Zipcodes['97738'] = [43.484045, -119.156464];
Zipcodes['97739'] = [43.672452, -121.488187];
Zipcodes['97741'] = [44.658094, -121.038736];
Zipcodes['97750'] = [44.833779, -120.290466];
Zipcodes['97751'] = [44.131635, -119.780362];
Zipcodes['97752'] = [44.130545, -120.200874];
Zipcodes['97753'] = [44.197653, -120.985556];
Zipcodes['97754'] = [44.128712, -120.515361];
Zipcodes['97756'] = [44.281118, -121.191786];
Zipcodes['97758'] = [43.452315, -119.770927];
Zipcodes['97759'] = [44.48994, -121.778037];
Zipcodes['97760'] = [44.384504, -121.245576];
Zipcodes['97761'] = [44.860771, -121.294898];
Zipcodes['97801'] = [45.670827, -118.823832];
Zipcodes['97810'] = [45.672446, -118.396069];
Zipcodes['97812'] = [45.575157, -120.249832];
Zipcodes['97813'] = [45.853015, -118.527975];
Zipcodes['97814'] = [44.796293, -117.783263];
Zipcodes['97817'] = [44.728775, -118.60596];
Zipcodes['97818'] = [45.763786, -119.775772];
Zipcodes['97819'] = [44.485996, -117.763025];
Zipcodes['97820'] = [44.14382, -119.194763];
Zipcodes['97823'] = [45.250865, -120.196512];
Zipcodes['97824'] = [45.354301, -117.75647];
Zipcodes['97825'] = [44.385126, -119.496273];
Zipcodes['97826'] = [45.664296, -119.232316];
Zipcodes['97827'] = [45.615978, -117.857558];
Zipcodes['97828'] = [45.534674, -117.146773];
Zipcodes['97830'] = [44.966605, -120.183114];
Zipcodes['97833'] = [44.933827, -118.072967];
Zipcodes['97834'] = [45.029675, -117.231861];
Zipcodes['97835'] = [45.899223, -118.771941];
Zipcodes['97836'] = [45.321152, -119.47462];
Zipcodes['97837'] = [44.519548, -118.061902];
Zipcodes['97838'] = [45.850974, -119.287822];
Zipcodes['97839'] = [45.573379, -119.606853];
Zipcodes['97840'] = [44.856099, -116.941231];
Zipcodes['97841'] = [45.46824, -117.940325];
Zipcodes['97842'] = [45.610676, -116.799565];
Zipcodes['97843'] = [45.504563, -119.880612];
Zipcodes['97844'] = [45.88415, -119.548836];
Zipcodes['97845'] = [44.414444, -118.90375];
Zipcodes['97846'] = [45.27778, -117.114947];
Zipcodes['97848'] = [44.722177, -119.580554];
Zipcodes['97850'] = [45.308936, -118.156042];
Zipcodes['97856'] = [44.776928, -119.117972];
Zipcodes['97857'] = [45.359229, -117.52324];
Zipcodes['97859'] = [45.524732, -118.431925];
Zipcodes['97862'] = [45.922063, -118.316063];
Zipcodes['97864'] = [44.805075, -119.471458];
Zipcodes['97865'] = [44.461466, -119.18004];
Zipcodes['97867'] = [45.078037, -117.993416];
Zipcodes['97868'] = [45.409294, -118.853582];
Zipcodes['97869'] = [44.397563, -118.633979];
Zipcodes['97870'] = [44.787989, -117.186563];
Zipcodes['97873'] = [44.1283, -118.995];
Zipcodes['97874'] = [44.757804, -119.848466];
Zipcodes['97875'] = [45.815394, -119.148634];
Zipcodes['97876'] = [45.522336, -118.028055];
Zipcodes['97877'] = [44.810954, -118.395946];
Zipcodes['97880'] = [45.088669, -118.882506];
Zipcodes['97882'] = [45.907338, -119.349551];
Zipcodes['97883'] = [45.128645, -117.651842];
Zipcodes['97884'] = [44.455341, -118.222235];
Zipcodes['97885'] = [45.650491, -117.509759];
Zipcodes['97886'] = [45.802308, -118.27241];
Zipcodes['97901'] = [43.589056, -117.098305];
Zipcodes['97902'] = [42.913311, -117.49413];
Zipcodes['97903'] = [44.271554, -117.598441];
Zipcodes['97904'] = [43.896317, -118.488634];
Zipcodes['97905'] = [44.581872, -117.465428];
Zipcodes['97906'] = [43.46499, -117.811858];
Zipcodes['97907'] = [44.419501, -117.345514];
Zipcodes['97908'] = [44.240035, -117.931435];
Zipcodes['97909'] = [44.210938, -117.379018];
Zipcodes['97910'] = [42.854644, -117.50912];
Zipcodes['97911'] = [43.876363, -118.055064];
Zipcodes['97913'] = [43.634378, -117.277911];
Zipcodes['97914'] = [44.111552, -117.079858];
Zipcodes['97917'] = [43.549479, -118.123502];
Zipcodes['97918'] = [43.962589, -117.389698];
Zipcodes['97920'] = [44.066001, -117.750402];
Zipcodes['98001'] = [47.30968, -122.264368];
Zipcodes['98002'] = [47.30834, -122.215422];
Zipcodes['98003'] = [47.305205, -122.314161];
Zipcodes['98004'] = [47.618337, -122.205341];
Zipcodes['98005'] = [47.612771, -122.167621];
Zipcodes['98006'] = [47.553023, -122.151845];
Zipcodes['98007'] = [47.613825, -122.144365];
Zipcodes['98008'] = [47.606529, -122.111468];
Zipcodes['98010'] = [47.315643, -121.998898];
Zipcodes['98011'] = [47.757352, -122.198698];
Zipcodes['98012'] = [47.841376, -122.198939];
Zipcodes['98014'] = [47.699745, -121.845156];
Zipcodes['98019'] = [47.737043, -121.934247];
Zipcodes['98020'] = [47.80059, -122.374193];
Zipcodes['98021'] = [47.792571, -122.208431];
Zipcodes['98022'] = [47.169491, -121.684569];
Zipcodes['98023'] = [47.311491, -122.36281];
Zipcodes['98024'] = [47.573424, -121.899182];
Zipcodes['98025'] = [47.434394, -121.952354];
Zipcodes['98026'] = [47.837569, -122.333895];
Zipcodes['98027'] = [47.514016, -121.993456];
Zipcodes['98028'] = [47.749858, -122.247244];
Zipcodes['98029'] = [47.557623, -122.01152];
Zipcodes['98030'] = [47.367214, -122.198051];
Zipcodes['98031'] = [47.405902, -122.191923];
Zipcodes['98032'] = [47.391942, -122.257192];
Zipcodes['98033'] = [47.675982, -122.193578];
Zipcodes['98034'] = [47.715809, -122.213748];
Zipcodes['98036'] = [47.809708, -122.280394];
Zipcodes['98037'] = [47.838719, -122.284543];
Zipcodes['98038'] = [47.419378, -121.958008];
Zipcodes['98039'] = [47.634866, -122.240269];
Zipcodes['98040'] = [47.564004, -122.231214];
Zipcodes['98042'] = [47.364009, -122.116743];
Zipcodes['98043'] = [47.794329, -122.302607];
Zipcodes['98045'] = [47.476623, -121.682885];
Zipcodes['98047'] = [47.259534, -122.247477];
Zipcodes['98050'] = [47.547343, -121.939703];
Zipcodes['98051'] = [47.34621, -121.881291];
Zipcodes['98052'] = [47.682254, -122.12045];
Zipcodes['98053'] = [47.668736, -122.015096];
Zipcodes['98055'] = [47.447472, -122.200356];
Zipcodes['98056'] = [47.51256, -122.188902];
Zipcodes['98057'] = [47.471944, -122.219389];
Zipcodes['98058'] = [47.440607, -122.126349];
Zipcodes['98059'] = [47.494409, -122.130221];
Zipcodes['98065'] = [47.597225, -121.779633];
Zipcodes['98068'] = [47.431901, -121.374119];
Zipcodes['98070'] = [47.407347, -122.473485];
Zipcodes['98072'] = [47.760777, -122.130501];
Zipcodes['98074'] = [47.622544, -122.04421];
Zipcodes['98075'] = [47.58609, -122.037436];
Zipcodes['98077'] = [47.752379, -122.060283];
Zipcodes['98087'] = [47.860067, -122.267267];
Zipcodes['98092'] = [47.290224, -122.125648];
Zipcodes['98101'] = [47.610902, -122.336422];
Zipcodes['98102'] = [47.634441, -122.32369];
Zipcodes['98103'] = [47.673874, -122.33471];
Zipcodes['98104'] = [47.600458, -122.332226];
Zipcodes['98105'] = [47.659861, -122.284977];
Zipcodes['98106'] = [47.544034, -122.349874];
Zipcodes['98107'] = [47.667527, -122.377551];
Zipcodes['98108'] = [47.535065, -122.305931];
Zipcodes['98109'] = [47.630583, -122.345596];
Zipcodes['98110'] = [47.646967, -122.534561];
Zipcodes['98112'] = [47.63752, -122.287651];
Zipcodes['98115'] = [47.685753, -122.283703];
Zipcodes['98116'] = [47.576382, -122.397387];
Zipcodes['98117'] = [47.687754, -122.385942];
Zipcodes['98118'] = [47.542036, -122.268537];
Zipcodes['98119'] = [47.636846, -122.370011];
Zipcodes['98121'] = [47.615192, -122.350581];
Zipcodes['98122'] = [47.609503, -122.295593];
Zipcodes['98125'] = [47.716883, -122.300564];
Zipcodes['98126'] = [47.551914, -122.373669];
Zipcodes['98133'] = [47.740485, -122.342826];
Zipcodes['98134'] = [47.581061, -122.338152];
Zipcodes['98136'] = [47.536602, -122.392466];
Zipcodes['98144'] = [47.585627, -122.29196];
Zipcodes['98146'] = [47.500147, -122.361235];
Zipcodes['98148'] = [47.444948, -122.325833];
Zipcodes['98154'] = [47.606211, -122.333792];
Zipcodes['98155'] = [47.754413, -122.300477];
Zipcodes['98158'] = [47.450455, -122.308014];
Zipcodes['98164'] = [47.605962, -122.332029];
Zipcodes['98166'] = [47.451576, -122.352986];
Zipcodes['98168'] = [47.489569, -122.29996];
Zipcodes['98174'] = [47.604569, -122.335359];
Zipcodes['98177'] = [47.742088, -122.371177];
Zipcodes['98178'] = [47.498764, -122.245065];
Zipcodes['98188'] = [47.446563, -122.272106];
Zipcodes['98195'] = [47.653833, -122.308348];
Zipcodes['98198'] = [47.391319, -122.314971];
Zipcodes['98199'] = [47.651367, -122.406278];
Zipcodes['98201'] = [47.994464, -122.186162];
Zipcodes['98203'] = [47.941999, -122.23408];
Zipcodes['98204'] = [47.902665, -122.255861];
Zipcodes['98207'] = [47.987223, -122.224193];
Zipcodes['98208'] = [47.900198, -122.187044];
Zipcodes['98220'] = [48.684945, -122.195194];
Zipcodes['98221'] = [48.454172, -122.603874];
Zipcodes['98222'] = [48.570667, -122.817466];
Zipcodes['98223'] = [48.21712, -121.95354];
Zipcodes['98224'] = [47.728877, -121.563797];
Zipcodes['98225'] = [48.758248, -122.50652];
Zipcodes['98226'] = [48.796704, -122.432388];
Zipcodes['98229'] = [48.705945, -122.398326];
Zipcodes['98230'] = [48.953455, -122.705013];
Zipcodes['98232'] = [48.564396, -122.409902];
Zipcodes['98233'] = [48.500911, -122.345655];
Zipcodes['98235'] = [48.463237, -122.231223];
Zipcodes['98236'] = [47.948393, -122.412822];
Zipcodes['98237'] = [48.533808, -121.710652];
Zipcodes['98238'] = [48.335597, -122.34451];
Zipcodes['98239'] = [48.198192, -122.654078];
Zipcodes['98240'] = [48.948225, -122.620684];
Zipcodes['98241'] = [48.204009, -121.140498];
Zipcodes['98243'] = [48.613869, -123.006955];
Zipcodes['98244'] = [48.77253, -121.909628];
Zipcodes['98245'] = [48.66915, -122.912045];
Zipcodes['98247'] = [48.897231, -122.313728];
Zipcodes['98248'] = [48.865625, -122.623172];
Zipcodes['98249'] = [48.014236, -122.542794];
Zipcodes['98250'] = [48.553849, -123.085857];
Zipcodes['98251'] = [47.911547, -121.606422];
Zipcodes['98252'] = [48.061797, -121.714468];
Zipcodes['98253'] = [48.083955, -122.572471];
Zipcodes['98255'] = [48.523516, -121.991998];
Zipcodes['98256'] = [47.821525, -121.406544];
Zipcodes['98257'] = [48.406025, -122.512147];
Zipcodes['98258'] = [48.034269, -122.081489];
Zipcodes['98260'] = [48.035925, -122.45263];
Zipcodes['98261'] = [48.482491, -122.88396];
Zipcodes['98262'] = [48.696599, -122.681092];
Zipcodes['98263'] = [48.523789, -122.065597];
Zipcodes['98264'] = [48.94992, -122.454803];
Zipcodes['98266'] = [48.974043, -122.03499];
Zipcodes['98267'] = [48.390147, -121.265831];
Zipcodes['98270'] = [48.056723, -122.146904];
Zipcodes['98271'] = [48.089968, -122.237681];
Zipcodes['98272'] = [47.857847, -121.885309];
Zipcodes['98273'] = [48.402754, -122.376148];
Zipcodes['98274'] = [48.348771, -122.145467];
Zipcodes['98275'] = [47.913018, -122.302253];
Zipcodes['98276'] = [48.92775, -122.321038];
Zipcodes['98277'] = [48.321988, -122.627027];
Zipcodes['98278'] = [48.340604, -122.673992];
Zipcodes['98279'] = [48.636443, -122.818196];
Zipcodes['98280'] = [48.596133, -122.877668];
Zipcodes['98281'] = [48.984669, -123.059496];
Zipcodes['98282'] = [48.212509, -122.499121];
Zipcodes['98283'] = [48.800551, -121.30355];
Zipcodes['98284'] = [48.546832, -122.135222];
Zipcodes['98286'] = [48.568814, -122.957539];
Zipcodes['98288'] = [47.621403, -121.326488];
Zipcodes['98290'] = [47.955367, -121.978502];
Zipcodes['98292'] = [48.22555, -122.30507];
Zipcodes['98294'] = [47.838377, -121.766185];
Zipcodes['98295'] = [48.983371, -122.195069];
Zipcodes['98296'] = [47.840783, -122.101084];
Zipcodes['98297'] = [48.702059, -123.026515];
Zipcodes['98303'] = [47.149874, -122.681692];
Zipcodes['98304'] = [46.884601, -121.736645];
Zipcodes['98305'] = [48.024446, -124.23633];
Zipcodes['98310'] = [47.589689, -122.617462];
Zipcodes['98311'] = [47.627363, -122.642445];
Zipcodes['98312'] = [47.565804, -122.76154];
Zipcodes['98314'] = [47.562471, -122.638719];
Zipcodes['98315'] = [47.72455, -122.716011];
Zipcodes['98320'] = [47.696342, -123.055901];
Zipcodes['98321'] = [47.152606, -122.071738];
Zipcodes['98323'] = [46.97986, -121.985335];
Zipcodes['98325'] = [47.966814, -122.75969];
Zipcodes['98326'] = [48.159044, -124.500534];
Zipcodes['98327'] = [47.106258, -122.661608];
Zipcodes['98328'] = [46.858049, -122.164147];
Zipcodes['98329'] = [47.360808, -122.744139];
Zipcodes['98330'] = [46.757092, -122.153221];
Zipcodes['98331'] = [47.745828, -124.180639];
Zipcodes['98332'] = [47.372234, -122.595793];
Zipcodes['98333'] = [47.257686, -122.646552];
Zipcodes['98335'] = [47.296947, -122.623661];
Zipcodes['98336'] = [46.597949, -122.077799];
Zipcodes['98337'] = [47.561042, -122.638765];
Zipcodes['98338'] = [47.034315, -122.276854];
Zipcodes['98339'] = [48.02766, -122.746448];
Zipcodes['98340'] = [47.910095, -122.57106];
Zipcodes['98342'] = [47.755273, -122.511484];
Zipcodes['98345'] = [47.700204, -122.624293];
Zipcodes['98346'] = [47.826022, -122.527151];
Zipcodes['98349'] = [47.270073, -122.758454];
Zipcodes['98350'] = [47.906498, -124.622368];
Zipcodes['98351'] = [47.19966, -122.774258];
Zipcodes['98353'] = [47.53864, -122.492613];
Zipcodes['98354'] = [47.251994, -122.317289];
Zipcodes['98355'] = [46.703839, -122.039922];
Zipcodes['98356'] = [46.581909, -122.271384];
Zipcodes['98357'] = [48.333855, -124.635404];
Zipcodes['98358'] = [48.044909, -122.687459];
Zipcodes['98359'] = [47.431079, -122.574916];
Zipcodes['98360'] = [47.051413, -122.15324];
Zipcodes['98361'] = [46.53842, -121.504864];
Zipcodes['98362'] = [48.030261, -123.329376];
Zipcodes['98363'] = [48.003513, -123.822854];
Zipcodes['98364'] = [47.854353, -122.586113];
Zipcodes['98365'] = [47.884858, -122.701425];
Zipcodes['98366'] = [47.541253, -122.577029];
Zipcodes['98367'] = [47.462142, -122.666209];
Zipcodes['98368'] = [48.041261, -122.826664];
Zipcodes['98370'] = [47.754025, -122.626294];
Zipcodes['98371'] = [47.199455, -122.320212];
Zipcodes['98372'] = [47.206693, -122.262642];
Zipcodes['98373'] = [47.148479, -122.324136];
Zipcodes['98374'] = [47.129077, -122.263923];
Zipcodes['98375'] = [47.104262, -122.326892];
Zipcodes['98376'] = [47.846837, -122.852262];
Zipcodes['98377'] = [46.508777, -121.819187];
Zipcodes['98380'] = [47.601806, -122.879419];
Zipcodes['98381'] = [48.288607, -124.45685];
Zipcodes['98382'] = [48.023295, -123.106268];
Zipcodes['98383'] = [47.66291, -122.718605];
Zipcodes['98385'] = [47.137939, -122.095041];
Zipcodes['98387'] = [47.060925, -122.388909];
Zipcodes['98388'] = [47.209498, -122.684297];
Zipcodes['98390'] = [47.211481, -122.230571];
Zipcodes['98391'] = [47.177103, -122.166173];
Zipcodes['98392'] = [47.73238, -122.565945];
Zipcodes['98394'] = [47.320469, -122.78968];
Zipcodes['98396'] = [47.109838, -122.000292];
Zipcodes['98402'] = [47.25383, -122.444295];
Zipcodes['98403'] = [47.2658, -122.459139];
Zipcodes['98404'] = [47.208981, -122.411638];
Zipcodes['98405'] = [47.245647, -122.472399];
Zipcodes['98406'] = [47.264107, -122.512591];
Zipcodes['98407'] = [47.291951, -122.517727];
Zipcodes['98408'] = [47.197399, -122.446518];
Zipcodes['98409'] = [47.21303, -122.474947];
Zipcodes['98416'] = [47.262706, -122.48196];
Zipcodes['98418'] = [47.223229, -122.446405];
Zipcodes['98421'] = [47.260637, -122.400209];
Zipcodes['98422'] = [47.290368, -122.392395];
Zipcodes['98424'] = [47.235351, -122.354196];
Zipcodes['98430'] = [47.116718, -122.568752];
Zipcodes['98433'] = [47.092304, -122.608054];
Zipcodes['98438'] = [47.135003, -122.492082];
Zipcodes['98439'] = [47.130862, -122.502192];
Zipcodes['98443'] = [47.205005, -122.372661];
Zipcodes['98444'] = [47.152323, -122.448851];
Zipcodes['98445'] = [47.137427, -122.404715];
Zipcodes['98446'] = [47.131879, -122.375825];
Zipcodes['98447'] = [47.145262, -122.443631];
Zipcodes['98465'] = [47.251298, -122.537437];
Zipcodes['98466'] = [47.228971, -122.538054];
Zipcodes['98467'] = [47.203279, -122.567719];
Zipcodes['98498'] = [47.159592, -122.557929];
Zipcodes['98499'] = [47.16625, -122.506083];
Zipcodes['98501'] = [46.978514, -122.875905];
Zipcodes['98502'] = [47.0812, -123.024841];
Zipcodes['98503'] = [47.02332, -122.793549];
Zipcodes['98505'] = [47.073195, -122.973645];
Zipcodes['98506'] = [47.108292, -122.870623];
Zipcodes['98512'] = [46.953394, -123.048034];
Zipcodes['98513'] = [46.99337, -122.733221];
Zipcodes['98516'] = [47.098919, -122.785426];
Zipcodes['98520'] = [46.988577, -123.828523];
Zipcodes['98524'] = [47.366613, -122.835817];
Zipcodes['98526'] = [47.474411, -123.993988];
Zipcodes['98527'] = [46.596413, -123.911888];
Zipcodes['98528'] = [47.447252, -122.896217];
Zipcodes['98530'] = [46.809954, -122.87544];
Zipcodes['98531'] = [46.742709, -122.901618];
Zipcodes['98532'] = [46.626308, -123.052671];
Zipcodes['98533'] = [46.66116, -122.395587];
Zipcodes['98535'] = [47.103805, -124.117485];
Zipcodes['98536'] = [47.19494, -124.104095];
Zipcodes['98537'] = [46.807051, -123.580894];
Zipcodes['98538'] = [46.482768, -123.146135];
Zipcodes['98539'] = [46.637769, -123.273227];
Zipcodes['98541'] = [47.06085, -123.389802];
Zipcodes['98542'] = [46.533037, -122.725418];
Zipcodes['98544'] = [46.738971, -123.026988];
Zipcodes['98546'] = [47.321272, -122.914006];
Zipcodes['98547'] = [46.783992, -124.037789];
Zipcodes['98548'] = [47.540911, -123.173864];
Zipcodes['98550'] = [47.112596, -123.923008];
Zipcodes['98552'] = [47.31622, -123.946557];
Zipcodes['98555'] = [47.5334, -123.063352];
Zipcodes['98556'] = [46.885644, -123.140966];
Zipcodes['98557'] = [47.043242, -123.269956];
Zipcodes['98558'] = [46.936152, -122.560492];
Zipcodes['98559'] = [46.958822, -123.327364];
Zipcodes['98560'] = [47.309541, -123.422799];
Zipcodes['98562'] = [47.237991, -124.199826];
Zipcodes['98563'] = [47.211055, -123.609674];
Zipcodes['98564'] = [46.449477, -122.41965];
Zipcodes['98565'] = [46.578391, -122.907094];
Zipcodes['98566'] = [47.406546, -123.969588];
Zipcodes['98568'] = [46.862611, -123.301202];
Zipcodes['98569'] = [46.990692, -124.143351];
Zipcodes['98570'] = [46.630713, -122.650947];
Zipcodes['98571'] = [47.202082, -124.198329];
Zipcodes['98572'] = [46.541337, -123.328312];
Zipcodes['98575'] = [47.454482, -123.801164];
Zipcodes['98576'] = [46.846725, -122.648425];
Zipcodes['98577'] = [46.641849, -123.610389];
Zipcodes['98579'] = [46.788204, -123.12547];
Zipcodes['98580'] = [46.986627, -122.510337];
Zipcodes['98581'] = [46.358779, -123.068659];
Zipcodes['98582'] = [46.523681, -122.625742];
Zipcodes['98583'] = [47.000882, -123.486663];
Zipcodes['98584'] = [47.247915, -123.153261];
Zipcodes['98585'] = [46.573437, -122.444631];
Zipcodes['98586'] = [46.544556, -123.840333];
Zipcodes['98587'] = [47.322856, -124.15296];
Zipcodes['98588'] = [47.446808, -123.032753];
Zipcodes['98589'] = [46.833445, -122.795317];
Zipcodes['98590'] = [46.710981, -123.996186];
Zipcodes['98591'] = [46.442319, -122.747383];
Zipcodes['98592'] = [47.33395, -123.076235];
Zipcodes['98593'] = [46.402675, -123.010116];
Zipcodes['98595'] = [46.897192, -124.107841];
Zipcodes['98596'] = [46.492252, -122.952658];
Zipcodes['98597'] = [46.844262, -122.492927];
Zipcodes['98601'] = [45.938373, -122.437855];
Zipcodes['98602'] = [45.869291, -121.279683];
Zipcodes['98603'] = [46.055519, -122.414757];
Zipcodes['98604'] = [45.805756, -122.510771];
Zipcodes['98605'] = [45.777018, -121.664181];
Zipcodes['98606'] = [45.729197, -122.45605];
Zipcodes['98607'] = [45.642413, -122.379611];
Zipcodes['98609'] = [46.071811, -122.865709];
Zipcodes['98610'] = [45.77542, -122.019081];
Zipcodes['98611'] = [46.305145, -122.900687];
Zipcodes['98612'] = [46.250472, -123.287184];
Zipcodes['98613'] = [45.754201, -120.972338];
Zipcodes['98614'] = [46.283303, -123.919747];
Zipcodes['98616'] = [46.075128, -122.183189];
Zipcodes['98617'] = [45.640493, -121.134015];
Zipcodes['98619'] = [45.971638, -121.268098];
Zipcodes['98620'] = [45.870446, -120.779305];
Zipcodes['98621'] = [46.400335, -123.543675];
Zipcodes['98623'] = [45.799594, -121.493459];
Zipcodes['98624'] = [46.33813, -123.964974];
Zipcodes['98625'] = [46.111708, -122.569586];
Zipcodes['98626'] = [46.17216, -122.773533];
Zipcodes['98628'] = [45.848608, -121.066798];
Zipcodes['98629'] = [45.877319, -122.619409];
Zipcodes['98631'] = [46.388005, -124.021855];
Zipcodes['98632'] = [46.204917, -123.062646];
Zipcodes['98635'] = [45.750031, -121.185577];
Zipcodes['98638'] = [46.393932, -123.762275];
Zipcodes['98639'] = [45.640468, -121.966293];
Zipcodes['98640'] = [46.631904, -124.042597];
Zipcodes['98641'] = [46.570991, -124.041625];
Zipcodes['98642'] = [45.803287, -122.701656];
Zipcodes['98643'] = [46.291997, -123.653638];
Zipcodes['98644'] = [46.319301, -124.05319];
Zipcodes['98645'] = [46.331865, -122.760081];
Zipcodes['98647'] = [46.315241, -123.401371];
Zipcodes['98648'] = [45.705945, -121.945583];
Zipcodes['98649'] = [46.315533, -122.532291];
Zipcodes['98650'] = [45.946837, -121.532395];
Zipcodes['98651'] = [45.739967, -121.583528];
Zipcodes['98660'] = [45.661204, -122.718844];
Zipcodes['98661'] = [45.640067, -122.625018];
Zipcodes['98662'] = [45.689134, -122.577391];
Zipcodes['98663'] = [45.654667, -122.662337];
Zipcodes['98664'] = [45.61951, -122.577179];
Zipcodes['98665'] = [45.681881, -122.652658];
Zipcodes['98670'] = [45.863751, -121.14605];
Zipcodes['98671'] = [45.610395, -122.275481];
Zipcodes['98672'] = [45.819522, -121.472434];
Zipcodes['98673'] = [45.680823, -120.95157];
Zipcodes['98674'] = [45.951267, -122.665541];
Zipcodes['98675'] = [45.873992, -122.321688];
Zipcodes['98682'] = [45.67296, -122.483238];
Zipcodes['98683'] = [45.603287, -122.51017];
Zipcodes['98684'] = [45.630556, -122.514839];
Zipcodes['98685'] = [45.715209, -122.693161];
Zipcodes['98686'] = [45.723305, -122.62187];
Zipcodes['98801'] = [47.431512, -120.345038];
Zipcodes['98802'] = [47.491932, -120.16375];
Zipcodes['98811'] = [47.734239, -120.373833];
Zipcodes['98812'] = [48.131489, -119.806042];
Zipcodes['98813'] = [48.006302, -119.616949];
Zipcodes['98814'] = [48.195344, -120.294989];
Zipcodes['98815'] = [47.528586, -120.479708];
Zipcodes['98816'] = [48.0554, -120.331968];
Zipcodes['98817'] = [47.792389, -119.993594];
Zipcodes['98819'] = [48.584492, -119.825853];
Zipcodes['98821'] = [47.552197, -120.563703];
Zipcodes['98822'] = [47.97905, -120.623327];
Zipcodes['98823'] = [47.338298, -119.635157];
Zipcodes['98824'] = [47.068516, -119.866543];
Zipcodes['98826'] = [47.783145, -120.843168];
Zipcodes['98827'] = [48.856329, -119.752106];
Zipcodes['98828'] = [47.310078, -120.178259];
Zipcodes['98829'] = [48.297285, -119.805681];
Zipcodes['98830'] = [47.880724, -119.533889];
Zipcodes['98831'] = [47.936759, -120.136253];
Zipcodes['98832'] = [47.331263, -119.027962];
Zipcodes['98833'] = [48.768736, -120.507298];
Zipcodes['98834'] = [48.120335, -120.066403];
Zipcodes['98836'] = [47.471923, -120.438475];
Zipcodes['98837'] = [47.146993, -119.315908];
Zipcodes['98840'] = [48.29181, -119.513783];
Zipcodes['98841'] = [48.379699, -119.289234];
Zipcodes['98843'] = [47.740379, -120.087144];
Zipcodes['98844'] = [48.927274, -119.228032];
Zipcodes['98845'] = [47.439304, -119.935355];
Zipcodes['98846'] = [48.014632, -119.971215];
Zipcodes['98847'] = [47.424424, -120.618215];
Zipcodes['98848'] = [47.168643, -119.842939];
Zipcodes['98849'] = [48.54099, -119.409523];
Zipcodes['98850'] = [47.405284, -120.038663];
Zipcodes['98851'] = [47.413886, -119.43223];
Zipcodes['98852'] = [48.365489, -120.669753];
Zipcodes['98853'] = [47.466322, -119.282052];
Zipcodes['98855'] = [48.674509, -119.253008];
Zipcodes['98856'] = [48.343268, -120.263948];
Zipcodes['98857'] = [46.990068, -119.024761];
Zipcodes['98858'] = [47.678077, -119.888544];
Zipcodes['98859'] = [48.797856, -118.955073];
Zipcodes['98860'] = [47.464012, -119.115018];
Zipcodes['98862'] = [48.598872, -120.22241];
Zipcodes['98901'] = [46.6652, -120.338543];
Zipcodes['98902'] = [46.598048, -120.534558];
Zipcodes['98903'] = [46.530038, -120.940959];
Zipcodes['98908'] = [46.640252, -120.781887];
Zipcodes['98921'] = [46.430723, -120.316813];
Zipcodes['98922'] = [47.226222, -120.865422];
Zipcodes['98923'] = [46.666086, -120.717225];
Zipcodes['98925'] = [47.282645, -121.277777];
Zipcodes['98926'] = [46.988457, -120.354859];
Zipcodes['98930'] = [46.269222, -119.892008];
Zipcodes['98932'] = [46.332713, -120.156231];
Zipcodes['98933'] = [46.402929, -120.637565];
Zipcodes['98934'] = [46.980641, -120.419425];
Zipcodes['98935'] = [46.131947, -120.073684];
Zipcodes['98936'] = [46.532769, -120.164849];
Zipcodes['98937'] = [46.792772, -121.226151];
Zipcodes['98938'] = [46.388363, -120.096655];
Zipcodes['98939'] = [46.501013, -120.470706];
Zipcodes['98940'] = [47.362385, -121.039385];
Zipcodes['98941'] = [47.224663, -121.000243];
Zipcodes['98942'] = [46.801335, -120.66481];
Zipcodes['98943'] = [47.183995, -120.965412];
Zipcodes['98944'] = [46.507007, -119.672018];
Zipcodes['98946'] = [47.068304, -120.756392];
Zipcodes['98947'] = [46.687597, -120.80919];
Zipcodes['98948'] = [46.237785, -120.368797];
Zipcodes['98950'] = [46.815145, -119.971371];
Zipcodes['98951'] = [46.456028, -120.513604];
Zipcodes['98952'] = [46.350332, -120.816955];
Zipcodes['98953'] = [46.441503, -120.232789];
Zipcodes['99001'] = [47.638729, -117.585826];
Zipcodes['99003'] = [47.917122, -117.274929];
Zipcodes['99004'] = [47.419011, -117.599877];
Zipcodes['99005'] = [47.844047, -117.363446];
Zipcodes['99006'] = [47.951494, -117.506357];
Zipcodes['99008'] = [47.503335, -117.933438];
Zipcodes['99009'] = [48.026086, -117.252446];
Zipcodes['99011'] = [47.618653, -117.64842];
Zipcodes['99012'] = [47.381309, -117.191336];
Zipcodes['99013'] = [47.88114, -117.835284];
Zipcodes['99014'] = [47.561161, -117.596644];
Zipcodes['99016'] = [47.597264, -117.121002];
Zipcodes['99017'] = [47.184114, -117.886748];
Zipcodes['99018'] = [47.291678, -117.152079];
Zipcodes['99019'] = [47.637085, -117.074721];
Zipcodes['99020'] = [47.563185, -117.497051];
Zipcodes['99021'] = [47.849534, -117.187271];
Zipcodes['99022'] = [47.59978, -117.708985];
Zipcodes['99023'] = [47.564726, -117.144555];
Zipcodes['99025'] = [47.793859, -117.075344];
Zipcodes['99026'] = [47.800969, -117.633772];
Zipcodes['99027'] = [47.710128, -117.12756];
Zipcodes['99029'] = [47.706909, -117.829566];
Zipcodes['99030'] = [47.477797, -117.112936];
Zipcodes['99031'] = [47.413753, -117.329774];
Zipcodes['99032'] = [47.327317, -118.033606];
Zipcodes['99033'] = [47.230554, -117.097758];
Zipcodes['99034'] = [47.886178, -117.762403];
Zipcodes['99036'] = [47.520054, -117.271957];
Zipcodes['99037'] = [47.636616, -117.199994];
Zipcodes['99039'] = [47.338133, -117.231303];
Zipcodes['99040'] = [47.898469, -118.003678];
Zipcodes['99101'] = [48.316361, -117.937275];
Zipcodes['99102'] = [46.79218, -117.25036];
Zipcodes['99103'] = [47.735811, -118.924379];
Zipcodes['99105'] = [46.956914, -118.072907];
Zipcodes['99109'] = [48.292416, -117.69864];
Zipcodes['99110'] = [48.008305, -117.581111];
Zipcodes['99111'] = [46.855891, -117.451152];
Zipcodes['99113'] = [46.584474, -117.196143];
Zipcodes['99114'] = [48.655198, -117.735834];
Zipcodes['99115'] = [47.674057, -119.377651];
Zipcodes['99116'] = [48.014782, -118.935774];
Zipcodes['99117'] = [47.648785, -118.570121];
Zipcodes['99118'] = [48.897363, -118.637784];
Zipcodes['99119'] = [48.509196, -117.314915];
Zipcodes['99121'] = [48.936195, -118.469204];
Zipcodes['99122'] = [47.687561, -118.207492];
Zipcodes['99123'] = [47.886491, -119.054823];
Zipcodes['99124'] = [48.001499, -118.953675];
Zipcodes['99125'] = [46.961364, -117.747257];
Zipcodes['99126'] = [48.7301, -117.971921];
Zipcodes['99128'] = [47.097949, -117.081329];
Zipcodes['99129'] = [48.010724, -118.156673];
Zipcodes['99130'] = [46.999152, -117.18554];
Zipcodes['99131'] = [48.297748, -118.094973];
Zipcodes['99133'] = [48.01013, -119.136483];
Zipcodes['99134'] = [47.451045, -118.296869];
Zipcodes['99135'] = [47.661126, -119.091245];
Zipcodes['99136'] = [46.674693, -117.948898];
Zipcodes['99137'] = [48.14812, -118.115758];
Zipcodes['99138'] = [48.231375, -118.390882];
Zipcodes['99139'] = [48.716605, -117.25263];
Zipcodes['99140'] = [48.111429, -118.646173];
Zipcodes['99141'] = [48.717122, -118.167964];
Zipcodes['99143'] = [46.765536, -117.848245];
Zipcodes['99144'] = [47.368101, -118.49041];
Zipcodes['99146'] = [48.957496, -118.29774];
Zipcodes['99147'] = [47.859324, -118.462023];
Zipcodes['99148'] = [48.107298, -117.571356];
Zipcodes['99149'] = [47.224937, -117.467951];
Zipcodes['99150'] = [48.806233, -118.610771];
Zipcodes['99151'] = [48.664465, -118.061215];
Zipcodes['99152'] = [48.83693, -117.399892];
Zipcodes['99153'] = [48.910859, -117.165651];
Zipcodes['99154'] = [47.391292, -118.355776];
Zipcodes['99155'] = [48.311477, -119.006425];
Zipcodes['99156'] = [48.161494, -117.190043];
Zipcodes['99157'] = [48.918184, -117.796302];
Zipcodes['99158'] = [47.133008, -117.24013];
Zipcodes['99159'] = [47.32684, -118.76587];
Zipcodes['99160'] = [48.868941, -118.301224];
Zipcodes['99161'] = [46.903644, -117.148073];
Zipcodes['99163'] = [46.730384, -117.218395];
Zipcodes['99166'] = [48.608711, -118.667275];
Zipcodes['99167'] = [48.411697, -118.133525];
Zipcodes['99169'] = [47.119773, -118.378417];
Zipcodes['99170'] = [47.252956, -117.397216];
Zipcodes['99171'] = [47.098248, -117.65389];
Zipcodes['99173'] = [48.040922, -117.857659];
Zipcodes['99174'] = [47.007232, -117.356887];
Zipcodes['99176'] = [47.09219, -117.412253];
Zipcodes['99179'] = [46.494979, -117.125923];
Zipcodes['99180'] = [48.305211, -117.3065];
Zipcodes['99181'] = [48.144796, -117.766339];
Zipcodes['99185'] = [47.726994, -118.711068];
Zipcodes['99201'] = [47.662482, -117.436729];
Zipcodes['99202'] = [47.657011, -117.378966];
Zipcodes['99203'] = [47.62955, -117.403247];
Zipcodes['99204'] = [47.647242, -117.426704];
Zipcodes['99205'] = [47.696825, -117.444879];
Zipcodes['99206'] = [47.62765, -117.254607];
Zipcodes['99207'] = [47.689203, -117.388974];
Zipcodes['99208'] = [47.785815, -117.452348];
Zipcodes['99212'] = [47.665317, -117.307293];
Zipcodes['99216'] = [47.686361, -117.216702];
Zipcodes['99217'] = [47.740709, -117.261376];
Zipcodes['99218'] = [47.758924, -117.409465];
Zipcodes['99223'] = [47.597087, -117.317796];
Zipcodes['99224'] = [47.647672, -117.534951];
Zipcodes['99251'] = [47.753833, -117.417864];
Zipcodes['99258'] = [47.666041, -117.402348];
Zipcodes['99301'] = [46.377835, -118.951726];
Zipcodes['99320'] = [46.324787, -119.506156];
Zipcodes['99321'] = [46.858962, -119.893382];
Zipcodes['99322'] = [45.947031, -120.155532];
Zipcodes['99323'] = [46.189604, -118.871824];
Zipcodes['99324'] = [46.043727, -118.385451];
Zipcodes['99326'] = [46.668839, -118.841759];
Zipcodes['99328'] = [46.275681, -117.870851];
Zipcodes['99329'] = [46.139705, -118.14695];
Zipcodes['99330'] = [46.494519, -119.030287];
Zipcodes['99333'] = [46.697027, -118.139386];
Zipcodes['99335'] = [46.647174, -118.439679];
Zipcodes['99336'] = [46.214052, -119.17573];
Zipcodes['99337'] = [46.052007, -119.098495];
Zipcodes['99338'] = [46.173626, -119.274012];
Zipcodes['99341'] = [46.906596, -118.642171];
Zipcodes['99343'] = [46.59672, -119.164729];
Zipcodes['99344'] = [46.822362, -119.171787];
Zipcodes['99345'] = [45.933504, -119.655171];
Zipcodes['99346'] = [46.030682, -119.303226];
Zipcodes['99347'] = [46.42335, -117.574429];
Zipcodes['99348'] = [46.402889, -118.435437];
Zipcodes['99349'] = [46.731147, -119.700541];
Zipcodes['99350'] = [46.142479, -119.736752];
Zipcodes['99352'] = [46.245767, -119.291463];
Zipcodes['99353'] = [46.313808, -119.387768];
Zipcodes['99354'] = [46.332253, -119.299105];
Zipcodes['99356'] = [45.829079, -120.360018];
Zipcodes['99357'] = [46.89131, -119.661625];
Zipcodes['99359'] = [46.513035, -118.161895];
Zipcodes['99360'] = [46.090544, -118.667563];
Zipcodes['99361'] = [46.236679, -118.143236];
Zipcodes['99362'] = [46.101061, -118.313625];
Zipcodes['99363'] = [46.045569, -118.885254];
Zipcodes['99371'] = [46.785258, -118.332223];
Zipcodes['99401'] = [46.087207, -117.249856];
Zipcodes['99402'] = [46.221144, -117.35126];
Zipcodes['99403'] = [46.375542, -117.2903];
Zipcodes['99501'] = [61.219998, -149.85784];
Zipcodes['99502'] = [61.163652, -149.996643];
Zipcodes['99503'] = [62.043689, -158.174466];
Zipcodes['99504'] = [61.190578, -149.607154];
Zipcodes['99505'] = [61.284745, -149.653973];
Zipcodes['99506'] = [61.271958, -149.807145];
Zipcodes['99507'] = [61.115132, -149.616122];
Zipcodes['99508'] = [61.2014, -149.817502];
Zipcodes['99513'] = [61.214772, -149.885658];
Zipcodes['99515'] = [61.116381, -149.890375];
Zipcodes['99516'] = [61.079332, -149.709777];
Zipcodes['99517'] = [61.190346, -149.939108];
Zipcodes['99518'] = [61.159532, -149.884764];
Zipcodes['99519'] = [70.01736, -149.829741];
Zipcodes['99530'] = [61.184682, -149.983704];
Zipcodes['99540'] = [61.009658, -149.317707];
Zipcodes['99546'] = [51.85567, -176.668632];
Zipcodes['99547'] = [52.223414, -174.203358];
Zipcodes['99548'] = [56.271969, -158.789108];
Zipcodes['99549'] = [56.965241, -158.586416];
Zipcodes['99550'] = [57.867012, -152.856248];
Zipcodes['99551'] = [60.920984, -161.402087];
Zipcodes['99552'] = [60.906997, -161.220873];
Zipcodes['99553'] = [54.117318, -165.830835];
Zipcodes['99554'] = [62.710997, -164.649719];
Zipcodes['99555'] = [59.288961, -158.664343];
Zipcodes['99556'] = [59.834171, -151.576142];
Zipcodes['99557'] = [61.468883, -155.215247];
Zipcodes['99558'] = [62.631627, -160.215106];
Zipcodes['99559'] = [60.772862, -161.871467];
Zipcodes['99561'] = [60.151845, -164.251542];
Zipcodes['99563'] = [61.550446, -165.356458];
Zipcodes['99564'] = [56.295019, -158.393324];
Zipcodes['99565'] = [56.304267, -158.500259];
Zipcodes['99566'] = [61.270907, -142.605516];
Zipcodes['99567'] = [61.299272, -149.021202];
Zipcodes['99568'] = [60.271797, -150.605205];
Zipcodes['99569'] = [58.804992, -158.528543];
Zipcodes['99571'] = [55.266171, -162.804744];
Zipcodes['99572'] = [60.529893, -149.952595];
Zipcodes['99573'] = [61.569192, -145.670475];
Zipcodes['99574'] = [61.117521, -147.60689];
Zipcodes['99575'] = [61.796698, -158.08102];
Zipcodes['99576'] = [59.361044, -158.822923];
Zipcodes['99577'] = [61.17425, -149.284329];
Zipcodes['99578'] = [60.216265, -162.041999];
Zipcodes['99579'] = [58.224788, -157.456539];
Zipcodes['99580'] = [59.345782, -157.487023];
Zipcodes['99581'] = [62.822098, -164.708371];
Zipcodes['99583'] = [54.887802, -163.408322];
Zipcodes['99585'] = [61.871555, -162.06087];
Zipcodes['99586'] = [62.690716, -144.237987];
Zipcodes['99587'] = [60.965808, -148.876643];
Zipcodes['99588'] = [62.707758, -146.719388];
Zipcodes['99589'] = [59.237008, -161.57936];
Zipcodes['99590'] = [62.896051, -160.112989];
Zipcodes['99591'] = [56.600936, -169.681052];
Zipcodes['99602'] = [62.185115, -159.858776];
Zipcodes['99603'] = [59.538519, -151.186927];
Zipcodes['99604'] = [61.509167, -166.111471];
Zipcodes['99605'] = [60.869131, -149.471513];
Zipcodes['99606'] = [59.737057, -154.902414];
Zipcodes['99607'] = [61.54027, -160.348983];
Zipcodes['99608'] = [57.590981, -154.363413];
Zipcodes['99609'] = [60.86382, -162.536598];
Zipcodes['99610'] = [60.280964, -151.273];
Zipcodes['99611'] = [60.83481, -150.724223];
Zipcodes['99612'] = [55.084993, -162.316806];
Zipcodes['99613'] = [59.070918, -155.377509];
Zipcodes['99614'] = [59.937456, -164.062221];
Zipcodes['99615'] = [57.682717, -153.378849];
Zipcodes['99620'] = [63.02919, -163.536654];
Zipcodes['99621'] = [60.793231, -161.430844];
Zipcodes['99622'] = [59.877575, -163.177317];
Zipcodes['99623'] = [61.439281, -149.922743];
Zipcodes['99624'] = [57.489377, -153.853499];
Zipcodes['99625'] = [59.068246, -156.901021];
Zipcodes['99626'] = [61.517121, -160.35465];
Zipcodes['99627'] = [63.548519, -154.575648];
Zipcodes['99628'] = [58.739523, -158.914501];
Zipcodes['99630'] = [60.370179, -166.268173];
Zipcodes['99631'] = [60.596322, -149.638299];
Zipcodes['99632'] = [62.11233, -163.664502];
Zipcodes['99633'] = [58.78516, -156.885872];
Zipcodes['99634'] = [60.678696, -162.015137];
Zipcodes['99635'] = [61.068571, -153.003192];
Zipcodes['99636'] = [59.485309, -157.309089];
Zipcodes['99637'] = [60.529752, -165.106622];
Zipcodes['99638'] = [53.007863, -168.718502];
Zipcodes['99639'] = [59.985333, -151.507668];
Zipcodes['99640'] = [60.718588, -155.572329];
Zipcodes['99641'] = [60.881739, -162.464569];
Zipcodes['99643'] = [57.218798, -153.329674];
Zipcodes['99644'] = [57.910127, -152.382358];
Zipcodes['99645'] = [61.62442, -148.982077];
Zipcodes['99647'] = [59.879392, -154.070926];
Zipcodes['99648'] = [55.934456, -159.155754];
Zipcodes['99649'] = [57.505137, -157.285832];
Zipcodes['99650'] = [61.94257, -162.884603];
Zipcodes['99651'] = [58.952583, -161.747032];
Zipcodes['99652'] = [61.515547, -149.97553];
Zipcodes['99653'] = [60.15673, -154.33961];
Zipcodes['99654'] = [61.605123, -149.422671];
Zipcodes['99655'] = [59.742402, -161.887147];
Zipcodes['99656'] = [61.865263, -157.35707];
Zipcodes['99657'] = [61.728028, -161.678432];
Zipcodes['99658'] = [62.091622, -163.248973];
Zipcodes['99659'] = [63.458499, -162.134486];
Zipcodes['99660'] = [57.179076, -170.322824];
Zipcodes['99661'] = [55.262272, -160.709422];
Zipcodes['99662'] = [61.841929, -165.583279];
Zipcodes['99663'] = [59.40119, -151.624586];
Zipcodes['99664'] = [60.133829, -149.500604];
Zipcodes['99665'] = [62.667392, -159.535075];
Zipcodes['99666'] = [62.440625, -165.145536];
Zipcodes['99667'] = [61.697681, -152.140403];
Zipcodes['99668'] = [61.653861, -157.10302];
Zipcodes['99669'] = [60.360071, -151.017855];
Zipcodes['99670'] = [58.675534, -156.994126];
Zipcodes['99671'] = [63.473394, -162.259981];
Zipcodes['99672'] = [60.569633, -150.761371];
Zipcodes['99674'] = [61.591292, -148.061929];
Zipcodes['99675'] = [62.982698, -156.083829];
Zipcodes['99676'] = [62.571112, -149.60647];
Zipcodes['99677'] = [60.894438, -146.668561];
Zipcodes['99678'] = [59.263241, -160.472561];
Zipcodes['99679'] = [61.10435, -160.937231];
Zipcodes['99680'] = [60.345143, -162.663729];
Zipcodes['99681'] = [60.60713, -165.128009];
Zipcodes['99682'] = [61.190862, -151.184237];
Zipcodes['99683'] = [62.415358, -150.407836];
Zipcodes['99684'] = [64.073219, -160.2064];
Zipcodes['99685'] = [53.873112, -166.483834];
Zipcodes['99686'] = [61.04283, -146.151875];
Zipcodes['99688'] = [61.886957, -149.620229];
Zipcodes['99689'] = [59.484561, -139.419308];
Zipcodes['99690'] = [60.462401, -164.83464];
Zipcodes['99691'] = [62.728975, -153.582886];
Zipcodes['99692'] = [53.883787, -166.544899];
Zipcodes['99693'] = [60.850605, -148.033815];
Zipcodes['99694'] = [61.619917, -149.826544];
Zipcodes['99695'] = [55.918199, -159.493298];
Zipcodes['99701'] = [67.37543, -149.728458];
Zipcodes['99702'] = [64.650615, -147.052322];
Zipcodes['99703'] = [64.834706, -147.622949];
Zipcodes['99704'] = [64.288447, -149.202428];
Zipcodes['99705'] = [64.773899, -147.267614];
Zipcodes['99706'] = [64.009705, -144.681574];
Zipcodes['99707'] = [65.155019, -146.25258];
Zipcodes['99709'] = [64.87735, -148.14857];
Zipcodes['99712'] = [65.058583, -145.925646];
Zipcodes['99714'] = [64.381305, -146.530601];
Zipcodes['99720'] = [66.563762, -152.767992];
Zipcodes['99721'] = [68.149752, -151.697066];
Zipcodes['99722'] = [68.165374, -145.401362];
Zipcodes['99723'] = [71.253861, -156.798246];
Zipcodes['99724'] = [66.318235, -146.795494];
Zipcodes['99725'] = [64.861187, -148.023914];
Zipcodes['99726'] = [66.75531, -151.414738];
Zipcodes['99727'] = [65.976517, -161.139178];
Zipcodes['99729'] = [63.243983, -147.978063];
Zipcodes['99730'] = [65.648991, -145.614841];
Zipcodes['99732'] = [64.079431, -141.971999];
Zipcodes['99733'] = [65.838617, -144.175331];
Zipcodes['99734'] = [70.422628, -149.299665];
Zipcodes['99736'] = [66.069488, -162.764572];
Zipcodes['99737'] = [63.4435, -145.295615];
Zipcodes['99738'] = [65.149633, -142.654406];
Zipcodes['99739'] = [64.968406, -162.827159];
Zipcodes['99740'] = [67.636544, -144.680484];
Zipcodes['99741'] = [64.741422, -156.873394];
Zipcodes['99742'] = [63.752679, -171.68834];
Zipcodes['99743'] = [63.926835, -149.9883];
Zipcodes['99744'] = [64.330823, -149.053453];
Zipcodes['99745'] = [66.051578, -154.232791];
Zipcodes['99746'] = [65.705524, -156.310953];
Zipcodes['99747'] = [70.118453, -143.686818];
Zipcodes['99748'] = [64.314143, -158.778761];
Zipcodes['99749'] = [66.978607, -160.431777];
Zipcodes['99750'] = [67.74279, -164.530444];
Zipcodes['99751'] = [66.923402, -156.904071];
Zipcodes['99752'] = [67.28686, -161.184862];
Zipcodes['99753'] = [64.941084, -161.145458];
Zipcodes['99754'] = [64.902073, -157.693531];
Zipcodes['99755'] = [63.75455, -149.211207];
Zipcodes['99756'] = [64.701264, -151.212006];
Zipcodes['99757'] = [63.790253, -152.461794];
Zipcodes['99758'] = [65.151781, -149.372384];
Zipcodes['99759'] = [69.737117, -162.83412];
Zipcodes['99760'] = [64.41719, -149.150356];
Zipcodes['99761'] = [67.62087, -163.057747];
Zipcodes['99762'] = [64.739455, -164.654688];
Zipcodes['99763'] = [66.82821, -161.034669];
Zipcodes['99764'] = [62.685441, -141.822756];
Zipcodes['99765'] = [64.730679, -158.131037];
Zipcodes['99766'] = [68.276605, -165.986279];
Zipcodes['99767'] = [65.499413, -150.153341];
Zipcodes['99768'] = [65.26045, -153.567369];
Zipcodes['99769'] = [63.679706, -170.483324];
Zipcodes['99770'] = [66.611235, -160.097617];
Zipcodes['99771'] = [64.356124, -161.196462];
Zipcodes['99772'] = [65.840938, -165.900648];
Zipcodes['99773'] = [66.880505, -157.149979];
Zipcodes['99774'] = [66.023711, -149.080307];
Zipcodes['99775'] = [64.857468, -147.834584];
Zipcodes['99776'] = [63.328691, -143.486599];
Zipcodes['99777'] = [65.184004, -152.055102];
Zipcodes['99778'] = [65.252359, -166.352133];
Zipcodes['99780'] = [63.639378, -141.51097];
Zipcodes['99781'] = [67.468627, -146.064642];
Zipcodes['99782'] = [70.647539, -159.939272];
Zipcodes['99783'] = [65.612081, -168.078096];
Zipcodes['99784'] = [64.6828, -163.405534];
Zipcodes['99785'] = [65.343196, -166.508946];
Zipcodes['99786'] = [67.090944, -157.887097];
Zipcodes['99788'] = [66.646753, -143.789298];
Zipcodes['99789'] = [70.213483, -150.989674];
Zipcodes['99790'] = [65.459488, -148.10749];
Zipcodes['99791'] = [70.480786, -157.325459];
Zipcodes['99801'] = [58.37291, -134.178445];
Zipcodes['99820'] = [57.378398, -134.279042];
Zipcodes['99824'] = [58.265381, -134.400144];
Zipcodes['99825'] = [58.087681, -136.288309];
Zipcodes['99826'] = [58.41229, -135.808626];
Zipcodes['99827'] = [58.820812, -136.211459];
Zipcodes['99829'] = [58.024766, -135.30821];
Zipcodes['99830'] = [56.771079, -134.089981];
Zipcodes['99832'] = [57.965619, -136.071325];
Zipcodes['99833'] = [56.840409, -133.187084];
Zipcodes['99835'] = [56.960285, -135.03733];
Zipcodes['99836'] = [56.248273, -134.6522];
Zipcodes['99840'] = [59.560379, -135.338279];
Zipcodes['99841'] = [57.955765, -135.517468];
Zipcodes['99850'] = [58.488208, -135.484316];
Zipcodes['99901'] = [55.655512, -131.331901];
Zipcodes['99903'] = [55.733699, -132.104533];
Zipcodes['99918'] = [55.916873, -132.695503];
Zipcodes['99919'] = [55.645685, -132.49613];
Zipcodes['99921'] = [55.515474, -132.701922];
Zipcodes['99922'] = [55.307528, -133.046815];
Zipcodes['99923'] = [56.000518, -130.037474];
Zipcodes['99925'] = [55.550203, -132.945947];
Zipcodes['99926'] = [55.138352, -131.470425];
Zipcodes['99927'] = [56.331881, -133.60642];
Zipcodes['99929'] = [56.221499, -131.923588];
export default Zipcodes;