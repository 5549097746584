import React, { useContext, useEffect, useState } from 'react';
import './PaymentMethod.css'
import { stripePromise } from '../../stripe';
import { capitalizeWords, generateRandomString } from '../../backend-functions/custom-functions';
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { COLORS } from '../variables';
import { attachPaymentMethod, changeStatusofInvoice, getPaymentMethods, processPayment, savePaymentMethod } from '../../backend-functions/Payments';
import { AuthContext } from '../../context/AuthContext';
import { changeStatus } from '../../backend-functions/Users';
import { useNavigate } from 'react-router-dom';


const PaymentMethodForm = ({ amount, listingID, invoice, country }) => {
    const { userDetails } = useContext(AuthContext)
    const [ error, setError ] = useState('');
    const elements = useElements();
    const [ methods, setMethods ] = useState(null);
  
    const navigate = useNavigate();
console.log(userDetails, amount, listingID, invoice, country)
    useEffect(() => {
      const fetchData = async () => {
        const methods = await getPaymentMethods(userDetails.stripeCustomerID);
        setMethods(methods)
      }

      fetchData();
    }, [userDetails.stripeCustomerID]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const stripe = await stripePromise;
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)
        });

        if(paymentMethod){
          await processCustomerPayment(paymentMethod)
        }else{
          setError(error)
        }
      }    

      const processCustomerPayment = async (paymentMethod) =>{
        setError('Please Wait...')
        if(paymentMethod){
          // attach payment method to customer in stripe
          await attachPaymentMethod(paymentMethod.id, userDetails.stripeCustomerID)        
        }

        const paymentIntent = await processPayment({amount: amount * 100, paymentMethodId: paymentMethod.id, id: userDetails.stripeCustomerID, email: userDetails.email}, listingID, country)

        if(paymentIntent.status === 'failed'){
          setError(paymentIntent.error)
        }

        if(paymentIntent.status === 'succeeded'){
          await changeStatusofInvoice(invoice.id, "PAID", paymentIntent.id);
          await changeStatus(invoice.agent_ID, false)

          //redirect to success page
          navigate(`/success/${invoice.id}/${invoice.success_token}`) 
        }
      }

      const handleClick = async (paymentMethod) => {
        processCustomerPayment(paymentMethod)
      }
  
    return (
      <form className="payment-form" onSubmit={handleSubmit}>
        <p>Total Amount to Pay: <strong>{country === 'US' ? '$' : '£'}{amount}</strong></p>
        <CardElement className='card-element'/>
        {error && <p className="error">{error}</p>}
        <div className='center'>
          <button type="submit" className="submit-button">Submit Payment</button>
        </div>

        {methods && methods.length > 0 &&
        <>
          <Flex>
            <Cont>
              <hr style={{width: '100%'}}/>
            </Cont>
            <Cont>
              <p>or Pay with</p>
            </Cont>
            <Cont>
              <hr style={{width: '100%'}}/>
            </Cont>
          </Flex>

          <Flex direction='column'>
            {/* map methods here in COntainer */}
            {methods.map((method, index) => (
              <Container key={index} onClick={()=>{handleClick(method)}}>
                <Flex direction='column' style={{alignItems: 'start'}}>
                  <Strong>
                    <span>{capitalizeWords(method.card.brand)}</span>  <span>**** **** **** {method.card.last4}</span>
                  </Strong>
                  <SmallText>{method.card.exp_month + "/" + method.card.exp_year}</SmallText>
                </Flex>
              </Container>
            ))}
          </Flex>
        </>
        }
        <br/>
      </form>
    );
  };
  

  const Flex = styled.div`
    display: flex;
    flex-direction: ${props => props.direction};
    box-sizing: border-box;
  `;

  const Cont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  `;

  const Strong = styled.p`
    margin: 0;
    text-align: left;
    font-weight: bold;
    display: flex;
    gap: 5px;

    @media only screen and (max-width: 780px) {
      font-size: 14px;
      flex-direction: column;
    }
  `;

  const Container = styled.div`
    background: transparent;
    border: 1px solid ${COLORS.blue};
    width: 100%;
    border-radius: 8px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    cursor: pointer;
    box-sizing: border-box;
    margin: 5px 0px;

    &:hover{
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  `;

  const SmallText = styled.p`
    font-size: 12px;
    margin: 0;
    text-align: left;
  `;

  export default PaymentMethodForm;