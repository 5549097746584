import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS, ListingStatus } from '../components/variables'
import AnonymousBidders from '../components/Containers/AnonymousBidders'
import TopBidder from '../components/Containers/TopBidder'
import BigItem from '../components/Containers/BigItem'
import { deleteListing, getDealListingByListing, getMostRecentListing } from '../backend-functions/Listings'
import { getBiddings } from '../backend-functions/Biddings'
import { getTopAgents } from '../backend-functions/Users'
import BigButton from '../components/Buttons/BigButton'
import { useNavigate } from 'react-router-dom'
import TopWinningBidders from '../components/Containers/TopWinningBidders'
import Confetti from '../components/Background/Confetti'
import Pagination from '../components/Pagination/Pagination'
import Error from '../components/Forms/Error'
import Modal from '../components/Modal/Modal'
import Relist from '../components/Forms/Relist'
import Loading from '../components/Modal/Loading'

const HomePageSeller = ({ uid }) => {
  // get url parameters
  const bid = new URLSearchParams(window.location.search).get('bid');
  const deal = new URLSearchParams(window.location.search).get('deal');
  const agent = new URLSearchParams(window.location.search).get('agent');

  const [mostRecent, setMostRecent] = useState('');
  const [ bidder, setBidder ] = useState(null);
  const navigate = useNavigate();
  const [ dealListing, setDealListing ] = useState(null);
  const [ disabled, setButton ] = useState(false);
  const [ confetti, setConfetti ] = useState(bid && bid === 'successful' || (deal && deal === 'successful'));
  const [header, setHeader] = useState({rank: 'Rank', agent: 'Agent', rating: 'Rating', bid: 'Bid %', type: 'header'});

  const [ isNull, setIsNull ] = useState(true);
  const [ isRelist, setisRelist ] = useState(false);
  const [ isDelete, setisDelete ] = useState(false);
  const [error, setError] = useState('')

  const additionalDesc = {
    "Bidding Ongoing": "Your listing is currently under ongoing bidding. Please wait till the bidding period is over to see who won your listing.",
    "List Ended": "Your bidding period is over. Please choose between the Top 3 winning agents who you want to deal your listing with.",
    "Deal Ongoing": "Please click on <b>'Go to Portal'</b> to proceed with your deal. If you wish to continue with a different agent, click on <b>'Relist'</b> to put your listing back on the bidding period."
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const listing = await getMostRecentListing(uid);
        setMostRecent(listing);

        if(listing){
          if(listing.status === 'List Ended'){
            header['button'] = ''
          }

          if (listing.biddings) {
            const biddings = listing.biddings;
            const formattedBiddings = await getBiddings(biddings);
            setBidder(formattedBiddings);
          }

          // if there is already a deal
          if(!listing.isDelete){
            const deal = await getDealListingByListing(listing.id);
            setDealListing(deal)
            setIsNull(false); 
          }
        }else{
          setIsNull(false);
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchData();
  }, [uid]);
    
  const handleDelete = async (e) => {
    e.preventDefault();
    setError("Please Wait...");
    await deleteListing(mostRecent.id);

    window.location.reload();
  }
  
  return (
    <div>
      {confetti && <Confetti setConfetti={setConfetti} />}

      {/* MODALS */}
      {/* DELETE */}
      {mostRecent && 
      <Modal isOpen={isDelete} setOpen={setisDelete}>
        <h2>Are you sure you want to delete?</h2>
        <p>Please note that this action is irreversible.</p>
        <Error error={error} />
        <FlexCol stop="yes" style={{gap: '10px'}}>
              <ButtonCont justifyContent='end'>
                <BigButton text='Cancel' bgColor={COLORS.light_blue} color={COLORS.white} onClick={() => setisDelete(!isDelete)} />
              </ButtonCont>
              <ButtonCont justifyContent='start'>
                 <BigButton text='Confirm' bgColor={COLORS.red} color={COLORS.white} onClick={handleDelete} />
              </ButtonCont>
        </FlexCol>
      </Modal>}

      {/* RELIST */}
      {mostRecent && 
      <Modal isOpen={isRelist} setOpen={setisRelist}>
        <Relist listingId={mostRecent.id} handleRelist={()=>setisRelist(!isRelist)} />
      </Modal>}

      {/* ACTUAL CONTENT */}
      <br/>
      <FlexCol
        stop="yes"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <Line width="42%"></Line>
        <Text>
          Your <HighLight>Listing</HighLight>
        </Text>
        <Line width="42%"></Line>
      </FlexCol>
     
      <FlexCol
        stop="yes"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0%",
        }}
      >  
        <Padding>
          {mostRecent && 
          <>
            <br></br>
            Everything is anonymous on both sides for you and the agent until you have chosen an agent to deal with your listing. 
            <br/><br/>
            <span dangerouslySetInnerHTML={{ __html: additionalDesc[mostRecent.status] }}></span>
            <br></br><br></br>

            {/* Go to Portal and Relist buttons */}
            <FlexCol stop="yes">
              {/* show if there is a deal already */}
              <ButtonCont justifyContent='start'>
                {dealListing && <BigButton text='Go to Portal' bgColor={COLORS.light_blue} color={COLORS.white} onClick={()=>navigate('/portal/' + dealListing.id)} />}
              </ButtonCont>
              {/* show if the listing has ended */}
              <ButtonCont justifyContent='end'>
                {mostRecent && (mostRecent.status !== 'Bidding Ongiong' && mostRecent.status !== 'Sold Property') && <BigButton text='Relist' bgColor={COLORS.red} color={COLORS.white} onClick={() => setisRelist(!isRelist)} />}
              </ButtonCont>
            </FlexCol>
            <br/>
          </>
          }
          {/* LISTING INFO */}
          {mostRecent ? <BigItem listing={mostRecent} key={mostRecent.id} /> : 
            <>
              {isNull ?
                <Loading />
                :
                <><BlurText style={{textAlign: 'center', color:COLORS.red, marginTop: '20px'}}>
                  No Active Listing Yet
                </BlurText>
                <br/>
                <span style={{textAlign: 'center'}}>
                  <BigButton text='Create Listing' bgColor={COLORS.light_blue} color={COLORS.white} onClick={()=>navigate('/create')} />
                </span></>
              }
            </>}
          <br></br>
        </Padding>
      </FlexCol>

      {/* MORE BIDDERS OF YOUR LISTING */}
      {bidder && bidder.length > 0 && header && !isNull &&
        <FlexCol>
          <Padding>
            <br/>
            <BlurText>Bidders:</BlurText>
            <Container>
              <Pagination data={bidder} header={header} winningId={dealListing && dealListing.agent_id ? dealListing.agent_id : null} showBid={mostRecent.status !== 'List Ended'? null : 'Deal'} isNull={isNull} listingId={mostRecent.id} sellerId={mostRecent.owner_id} postcode={mostRecent.postcode} disabled={disabled} setButton={setButton} setConfetti={setConfetti}/>
            </Container>
          </Padding>
        </FlexCol>
      }
      <br/>
      
      {/* Edit and Delete buttons */}
      {mostRecent && ListingStatus[mostRecent.status] < 2 &&
      <Padding>
        <FlexCol stop="yes" style={{gap: '10px'}}>
              <ButtonCont justifyContent='end'>
                <BigButton text='Edit' bgColor={COLORS.light_blue} color={COLORS.white} onClick={()=>setisRelist(!isRelist)} />
              </ButtonCont>
              <ButtonCont justifyContent='start'>
                <BigButton text='Delete' bgColor={COLORS.red} color={COLORS.white} onClick={() => setisDelete(!isDelete)} />
              </ButtonCont>
        </FlexCol>
      </Padding>}
      <br/>
    </div>
  );
}

const Text = styled.h1`
  color: ${COLORS.blue};
  flex-shrink: 0;
  margin: 0px 10px;
  @media only screen and (max-width: 780px) {
    font-size: 20px;
  }
`;

const BlurText = styled.p`
  color: ${COLORS.blue_faded};
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 780px) {
    flex-direction: ${props => (props.stop ? 'row' : 'column')};
    font-size: 20px;
  }
`;

const Padding = styled.div`
  width: 100%;
  padding: 0px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  @media only screen and (max-width: 780px) {
    flex-direction: ${props => (props.stop ? 'row' : 'column')};
    padding: 0px 40px;
}
`;

const HighLight = styled.span`
    color: ${COLORS.yellow};
    text-decoration: underline;
    text-decoration-color: ${COLORS.blue};
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.height};
    width: 100%;
    box-sizing: border-box;

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => (props.stop ? 'row' : 'column')};
        height: auto;
    }
`;

const ButtonCont = styled.div`
    width: 50%;
    display: flex;
    justify-content: ${props => props.justifyContent};
`;

const Line = styled.span`
  background: ${COLORS.blue};
  height: 2px;
  // width: ${props => props.width};
  flex-grow: 1;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    gap: 30px;
    @media only screen and (max-width: 780px) {
      padding: 0px;
    }
`;

export default HomePageSeller
