import React, { useState } from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import BigButton from '../Buttons/BigButton';
import Date from './Date';
import { calculateListingDate, checkDate, formatDate } from '../../backend-functions/custom-functions';
import { changeListingDate } from '../../backend-functions/Listings';
import Error from './Error';
import { useNavigate } from 'react-router-dom';
import DropdownField from './DropdownField';

const Relist = ({handleRelist, listingId}) => {
  const [days, setDays] = useState(1);
  const [error, setError] = useState('');

  const filterOptions = [
    { value: "1", label: "1" },
    { value: "3", label: "3" },
    { value: "5", label: "5" },
    { value: "7", label: "7" },
  ];


  const navigate = useNavigate();

  const handlesetDays = async (value, label) =>{
    value = parseFloat(label);
    setDays(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const date = calculateListingDate(days)
    
    setError('Please Wait...');
    changeListingDate(listingId, date).then(() => {
      window.location.reload();
    })
  }

  return (
    <div>
      <h2>Change Listing Date</h2>
      <p>Give yourself more time by relisting for longer.</p>
      <form onSubmit={handleSubmit}>
        <Error error={error} />
        <h3>End in:</h3>
        <Flex stop='yes' style={{justifyContent: 'center'}}>
          <Flex stop='yes' style={{justifyContent: 'center', gap: '10px'}}>
            <StyledDropdown options={filterOptions} setSelectedOption={handlesetDays} />
            <p>day/s</p>
          </Flex>
        </Flex>

        <br />
        <Flex style={{ justifyContent: "center" }}>
          <Flex style={{ justifyContent: "center" }}>
            <BigButton
              bgColor={COLORS.red}
              onClick={handleRelist}
              color={COLORS.white}
              text="Cancel"
            />
            &nbsp;&nbsp;
            <BigButton
              bgColor={COLORS.blue}
              type="submit"
              color={COLORS.white}
              text="Relist"
            />
          </Flex>
        </Flex>
      </form>
      <br />
    </div>
  );
}

const Flex = styled.div`
    display: flex;

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => props.stop ? 'row' : 'column-reverse'};
    }
`;

const StyledDropdown = styled(DropdownField)`
    height: 100%;
    width: 50px;
    margin: 0;
`

export default Relist
