import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'
import TopNavbar from '../components/Navbar/Navbar'
import logo from '../img/logo.png'
import '../../src/components/css/animations.css'
import TitleLogo from '../components/Navbar/TitleLogo'


const AboutUs = () => {
  const [country, setCountry] = useState('');

  useEffect(() => {
    fetch('https://ipinfo.io/json?token=28eef5fa28f61d')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country);
      })
      .catch(error => {
        console.error('Error fetching IP information:', error);
      });
  }, []);

  return (
    <div>
      <Container height="10vh">
        <TopNavbar bgColor={COLORS.blue}></TopNavbar>
      </Container>
      <Main>
        <h1>About Us</h1>

        <Title>Welcome to The Agent Genie - Your Partner For Finding The Best {country === 'US' ? 'Realtor' : 'Estate Agent'}!</Title>
        <Description>
        At The Agent Genie, we are passionate about property, we have been dedicated to getting sellers the lowest commission fee possible and we are committed to delivering the highest quality local {country === 'US' ? 'realtor' : 'estate agent'}s to our valued customers.
        </Description>

        <Title>Our Journey</Title>
        <Description>The Agent Genie started as an idea during lockdown in 2020. Over 3 years of planning, we have developed a platform that allows sellers to pick the {country === 'US' ? 'realtor' : 'estate agent'} who bids the least amount based on their commission they will charge for the sale of the property.</Description>

        <Title>Our Mission</Title>
        <Description>Our mission at The Agent Genie is to give sellers a better option when choosing the {country === 'US' ? 'realtor' : 'estate agent'} to sell their property. We believe {country === 'US' ? 'realtor' : 'estate agent'}s should bid for the right to sell the property, and from the bidding process, sellers can choose the best option for them. We are dedicated to making a difference in the property market and will become a vital part of the process for picking the {country === 'US' ? 'realtor' : 'estate agent'} to sell your property.</Description>

        <Title>What Sets Us Apart</Title>
        <Description>What makes The Agent Genie unique is our concept is the first ever to be done in this marketspace. {country === 'US' ? 'Realtor' : 'Estate Agent'}s are bidding for the right to sell your property based on their commission. This has never been done before and will only help grow {country === 'US' ? 'realtor' : 'estate agent'}s who want to be transparent with their fees and also offer the best customer service.</Description>

        <Title>Our Team</Title>
        <Description>Behind The Agent Genie's platform is a team of dedicated professionals. Our Founder is Tomos Lazenby who is an expert in marketing and developed this idea from having worked as an {country === 'US' ? 'realtor' : 'estate agent'} in London for a short time before moving into helping {country === 'US' ? 'realtor' : 'estate agent'}s with their marketing efforts online.</Description>

        <Title>Our Services</Title>
        <Description>At The Agent Genie, the service is to help sellers find the best local {country === 'US' ? 'realtor' : 'estate agent'} who will offer the lowest commission with {country === 'US' ? 'realtor' : 'estate agent'}s bidding against each other for the right to sell the property. After bidding is finished, sellers choose who they want to work with.</Description>

        <Title>Our Commitment to Quality</Title>
        <Description>Quality is at the heart of everything we do. Any {country === 'US' ? 'realtor' : 'estate agent'} who doesn’t stick to their winning commission bid will be struck off the platform and blacklisted. We want to help honest {country === 'US' ? 'realtor' : 'estate agent'}s who will use this platform for more business and agree to the terms of use.</Description>

        <Title>Customer Satisfaction</Title>
        <Description>Sellers will be able to give a review on the {country === 'US' ? 'realtor' : 'estate agent'} after they’ve sold their property. This will build up credibility for the {country === 'US' ? 'realtor' : 'estate agent'}s who offer the best service giving them more business in future.</Description>

        <Title>Get in Touch</Title>
        <Description>We invite you to explore our platform and be sure to download our app on your phone for easy access on the go. If you have any questions or require assistance, please do not hesitate to email <a href="mailto:help@theagentgenie.com">help@theagentgenie.com</a>.</Description>

        <Description>Thank you for considering The Agent Genie as your partner in finding the best {country === 'US' ? 'realtor' : 'estate agent'} for you. We look forward to the opportunity to serve you and exceed your expectations. Together, we can achieve great things.</Description>

        <TitleLogo color={COLORS.blue} padding="0" />
        
      </Main>
    </div>
  )
}

const Container = styled.div`
    position: relative;
    height: ${ props => props.height};
`;

const Main = styled.div`
    padding: 2% 10%;
`;

const Title = styled.h3`
    font-weight: bold;
`;

const Description = styled.p``;


export default AboutUs
