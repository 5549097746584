// import React from 'react'
// import { useEffect } from 'react'

// const CompleteRegistrationPixelCode = () => {
//   useEffect(() => {
//     !function(f,b,e,v,n,t,s)
//     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//     n.queue=[];t=b.createElement(e);t.async=!0;
//     t.src=v;s=b.getElementsByTagName(e)[0];
//     s.parentNode.insertBefore(t,s)}(window, document,'script',
//     'https://connect.facebook.net/en_US/fbevents.js');
//     fbq('init', '320998360563317');
//     fbq('track', 'CompleteRegistration');
//   }, []);
//   return null
// }

// export default CompleteRegistrationPixelCode

import React from 'react';

const CompleteRegistrationPixelCode = () => {
  // Initialize Facebook Pixel
  const initPixel = () => {
    window.fbq('init', 320998360563317);
    window.fbq('track', 'CompleteRegistration');
  };

  // Run initPixel on component mount
  React.useEffect(() => {
    initPixel();
  }, []);

  return (
    <>
      {/* Facebook Pixel Code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=320998360563317&ev=PageView&noscript=1`}
        />
      </noscript>
      {/* End Facebook Pixel Code */}
    </>
  );
};

export default CompleteRegistrationPixelCode;

