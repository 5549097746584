
import React, { useContext, useState } from 'react'
import { COLORS } from '../components/variables'
import styled from 'styled-components'
import BigButton from '../components/Buttons/BigButton'
import logo from '../img/logo.png'
import InputwithIcon from '../components/Forms/InputwithIcon'
import { faUser, faLock, faGoogleLogo } from '@fortawesome/free-solid-svg-icons';
import TitleLogo from '../components/Navbar/TitleLogo'
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import Error from '../components/Forms/Error'
import { getUser } from '../backend-functions/Users'
import { handleErrorMessage } from '../backend-functions/custom-functions'

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const {dispatch} = useContext(AuthContext);

  const passwordReset = async (e) => {
    e.preventDefault();

    try {
      setError('Please Wait...');
      sendPasswordResetEmail(auth, email)
        .then(() => {
          alert("A password reset link has been sent to your email.")
        })
        .catch((error) => {
          setError(handleErrorMessage(error.message));
        })
    } catch (error) {
      setError(handleErrorMessage(error.message));
      console.log(error);
    }
  };

  return (
    <FlexCol>
      <FlexRow width='50%' padding='50px'>
        {/* <Text href='/'>TheAgent
            <HighLight>Genie</HighLight>
        </Text> */}
        <TitleLogo color={COLORS.blue}/>
        <Logo src={logo} className='floating' alt='The Agent Genie'/>
      </FlexRow>
      <FlexRow width='50%' padding='50px'>
        <FlexCol stop='yes'>
            <a href='/register?type=Seller' style={{marginRight: '10px'}}>
                <BigButton text='Become a Seller' bgColor={COLORS.blue} color={COLORS.white}/>
            </a>
            <a href='/register?type=Agent'>
                <BigButton text='Become an Agent' border={COLORS.blue} bgColor={COLORS.white} color={COLORS.blue}/>
            </a>
        </FlexCol>
        <BigText style={{marginTop: '50px'}}>Forgot &nbsp;
            <HighLight>Password</HighLight>
        </BigText>
        <p style={{marginTop: '-30px'}}>Input your email address so we can send you a password reset link.</p>

        {/* FORMS */}
        <Form onSubmit={passwordReset}>
          <Error error={error} />
          <InputwithIcon value={email} onChange={(e)=>setEmail(e.target.value)} type="text" placeholder="Email Address" icon={faUser}/>
          <br></br>
          <FlexCol stop='yes' style={{justifyContent: 'center'}}>
            <BigButton text='&nbsp; Reset Password &nbsp;' color={COLORS.white} bgColor={COLORS.blue} type="submit"></BigButton>
          </FlexCol>
          <br></br>
        </Form>
      </FlexRow>
    </FlexCol>
  )
}

const Line = styled.span`
  background: ${COLORS.blue};
  height: 1px;
  width: 100%;
`;

const Form = styled.form`
  margin-top: 30px;
  width: 70%;

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const ForgotPass = styled.div`
  margin: 10px 0px;
  text-align: end;
  width: 100%;
  font-size: 12px;
  color: ${COLORS.blue};

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.height};
    width: 100%;
    box-sizing: border-box;

    @media only screen and (max-width: 780px) {
        flex-direction: ${props => (props.stop ? 'row' : 'column')};
    }
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: column;
    background: ${props => props.background};
    box-sizing: border-box;
    width: ${props => props.width};
    padding: ${props => (props.padding ? props.padding : '0px')};

    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: 20px;
    }
`;

const Text = styled.span`
  color: ${COLORS.blue};
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
`;

const BigText = styled.h1`
  color: ${COLORS.blue};
  font-weight: 900;
  font-size: 48px;
  @media only screen and (max-width: 780px) {
    font-size: 40px
  }
`;

const HighLight = styled.span`
  color: ${COLORS.yellow};
`;

const Logo = styled.img`
    // background: blue;
    width: 95%;
    height: 95%;
    margin-top: 5px;
    @media only screen and (max-width: 780px) {
      display: none;
    }
`;

export default ForgotPassword