import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS, DefaultProPic } from '../components/variables'
import TopNavbar from '../components/Navbar/Navbar'
import BigButton from '../components/Buttons/BigButton'
import InputField from '../components/Forms/InputField'
import { AuthContext } from '../context/AuthContext'
import Error from '../components/Forms/Error'
import { editProfile } from '../backend-functions/Auth'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../backend-functions/Users'
import Loading from '../components/Modal/Loading'
import { deletePaymentMethod, getPaymentMethods } from '../backend-functions/Payments'
import { capitalizeWords } from '../backend-functions/custom-functions'

const EditProfile = () => {
  const { userDetails } = useContext(AuthContext);

  const navigate = useNavigate();

  const [ user, setUser ] = useState(null);

  const [ uid, setUid ] = useState(userDetails.uid);
  const [ username, setUsername ] = useState(userDetails.username);
  const [ fname, setFname ] = useState(user ? user.fname : '');
  const [ lname, setLname ] = useState(user ? user.lname : '');
  const [ email, setEmail ] = useState(user ? user.email : '');
  const [ phone, setPhone ] = useState(user ? user.phone : '');
  const [ city, setCity ] = useState(user ? user.city : '');
  const [ street, setStreet ] = useState(user ? user.street : '');
  const [ postcode, setPostcode ] = useState(user ? user.postcode : '');
  const [ company, setCompany ] = useState(user ? user.company : '');
  const [ accStatus, setAccStatus ] = useState(user && user.isDelete ? 'RESTRICTED' : 'ACTIVE');
  const [ appStatus, setAppStatus ] = useState(user ? user.application_status : 'PENDING');

  const [ profilePic, setProfilePic ] = useState(user ? user.img : DefaultProPic);
  const [ file, setFile ] = useState(null);

  const [ methods, setMethods ] = useState([]);

  const [ error, setError ] = useState('');
  const [ response, setResponse ] = useState('');

  const getPMs = async () => {
    if(userDetails.usertype === 'Agent'){
      const methods = await getPaymentMethods(userDetails.stripeCustomerID);
      setMethods(methods)
    }
  }
  useEffect(() => {
    getPMs();
  }, [userDetails.stripeCustomerID]);

  const fetchData = async () => {
    try{
        const user = await getUser(userDetails.uid);
        setUser(user);
 
        setFname(user.fname);
        setLname(user.lname);
        setEmail(user.email);
        setPhone(user.phone);
        setCity(user.city);
        setStreet(user.street);
        setPostcode(user.postcode);
        setCompany(user.company);
        setProfilePic(user.img || DefaultProPic);
        setAccStatus(user.isDelete ? 'RESTRICTED' : 'ACTIVE');
        setAppStatus(user.application_status);
    }catch(error){
        console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userDetails]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Set the selected file in state
      setProfilePic(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deletePaymentMethod(id);
      await getPMs();
      setResponse('Payment Method successfully deleted');
    } catch (error) {
      console.error('Error handling payment method:', error);
    }
  };

  const handleSubmit = async () => {
    setError('Please Wait...')
    let userObj = {
        fname: fname,
        lname: lname,
        email: email,
        phone: phone,
        street: street,
        city: city,
        postcode: postcode,
    }

    if(file){
        userObj.img = file
    }

    if(company){
        userObj.company = company
    }

    const response = await editProfile(userObj);

    if(response){
        navigate('/account?success=true')

        setError('Profile updated successsfully');
        fetchData();
    }
  }

  return (
    <div>
      <Container height="10vh">
        <TopNavbar bgColor={COLORS.blue} btncolor={COLORS.yellow}></TopNavbar>
      </Container>
      <Flex padding='15%'>
        <BigText>Edit Profile</BigText>
        <Flex gap='15px'>
            <Flex align='center' gap='15px'>
              <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
              <ImageContainer>
                <Image src={profilePic} alt="Profile Picture" />
                <ImageOverlay>Change Photo</ImageOverlay>
              </ImageContainer>
              <InputField
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={handleFileInputChange}
                  style={{
                    display: 'none', // Hide the input element
                  }}
                />
              </label>
            </Flex>

            {!user && <Loading/>}

            {error && <Flex>
                <Error error={error} />
            </Flex>}

            {/* <Flex align='start' direction='row'>
                <InputField required label='Unique ID' disabled value={uid} setValue={setUid} />
                <InputField required label='Username' disabled value={username} setValue={setUsername} />
            </Flex> */}
            <Flex gap='10px' align='start' direction='row'>
                <InputField required label='First Name' value={fname} setValue={setFname} />
                <InputField required label='Last Name' value={lname} setValue={setLname} />
            </Flex>
            <Flex gap='10px' align='start' direction='row'>
                <InputField required label='Email Address' disabled value={email} setValue={setEmail}/>
                <InputField required label='Phone Number' value={phone} setValue={setPhone} />
            </Flex>
            <Flex gap='10px' align='start' direction='row'>
                <InputField required label='City' value={city} setValue={setCity} />
                <InputField required label='Street' value={street} setValue={setStreet} />
                <InputField required label={user && user.country === 'US' ? 'Zip Code' : 'Post Code'} value={postcode} setValue={setPostcode} />
            </Flex>
            <Flex gap='10px' align='start' direction='row'>
                {userDetails.usertype === 'Agent' && <InputField required label='Company' value={company} setValue={setCompany} />}
                <InputField required label='Account Status' disabled value={accStatus} setValue={setAccStatus} />
                {userDetails.usertype === 'Agent' && <InputField required label='Application Status' disabled value={appStatus} setValue={setAppStatus} />}
            </Flex>

            <Flex align='center' gap='15px'>
                <BigButton text='Save Changes' color={COLORS.white} bgColor={COLORS.blue} onClick={handleSubmit} />
            </Flex>

            <SmallText>{response}</SmallText>
            {methods.length > 0 &&
            <Flex>
              <BigText>Saved Payment Methods</BigText>
              <Flex direction='column'>
                {/* map methods here in COntainer */}
                {methods.map((method, index) => (
                  <Container key={index} border={COLORS.blue} style={{
                    padding: '10px',
                    marginBottom: '20px',
                    border: `1px solid ${COLORS.blue}`,
                    borderRadius: '5px',
                    display: 'flex'
                  }}>
                    <Flex direction='column' style={{alignItems: 'start', width: '100%'}}>
                      <Strong>
                        <span>{capitalizeWords(method.card.brand)}</span>  <span>**** **** **** {method.card.last4}</span>
                      </Strong>
                      <SmallText>{method.card.exp_month + "/" + method.card.exp_year}</SmallText>
                    </Flex>
                    <Flex direction='column' style={{alignItems: 'end', width: 'fit-content'}}>
                      <svg style={{cursor: 'pointer'}} onClick={()=>{handleDelete(method.id)}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={COLORS.red} class="bi bi-x-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </Flex>
                  </Container>
                ))}
              </Flex>
            </Flex>}
        </Flex>
      </Flex>
    </div>
  )
}

const Container = styled.div`
  position: relative;
  height: ${(props) => props.height};
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.direction ? props.direction : 'column'};
  width: ${(props) => props.width ? props.width : '100%'};
  padding: ${props => `2% ${props.padding}`};
  background: ${props => props.bg};
  box-sizing: border-box;
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  gap: ${props => props.gap};

  @media only screen and (max-width: 1080px) {
    width: 100%;
    flex-direction: ${(props) => props.direction === 'row' ? 'column' : ''};
  }
`;

const BigText = styled.h2`
  color: ${COLORS.blue};
  @media only screen and (max-width: 1080px) {
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;

  @media only screen and (max-width: 1080px) {
    width: 150px;
    height: 150px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${ImageContainer}:hover & {
    opacity: 1;
  }
`;

const SmallText = styled.p`
    font-size: 12px;
    margin: 0;
    text-align: left;
  `;

  const Strong = styled.p`
    margin: 0;
    text-align: left;
    font-weight: bold;
    display: flex;
    gap: 5px;

    @media only screen and (max-width: 780px) {
      font-size: 14px;
      flex-direction: column;
    }
  `;

export default EditProfile
