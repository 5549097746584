import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../variables';

const DropdownField = ({ options, setSelectedOption, selectedValue = 1, ...props }) => {
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find(option => option.value === selectedValue);
console.log(selectedValue, selectedOption.label)
    setSelectedOption(selectedValue, selectedOption.label);
  };

  return (
    <InputContainer>
      {props.label}
      <Select value={selectedValue} onChange={handleSelectChange} {...props}>
        <option value="" disabled>
          Select an option
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  // margin: 0px 3px;
`;

const Select = styled.select`
  border: 1px solid ${COLORS.blue_faded};
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  color: ${COLORS.blue_faded};
`;

export default DropdownField;
