import React, { useContext, useEffect, useState } from 'react'
import { COLORS } from '../variables.js'
import styled from 'styled-components'
import Modal from './Modal.js'
import { AuthContext } from '../../context/AuthContext.js'
import { acceptPrivacy } from '../../backend-functions/Users.js'

import BigButton from '../Buttons/BigButton.js'

const Privacy_Policy = () => {
  const { userDetails, updateUserDetailsField } = useContext(AuthContext);

  //will show if the user hasn't accepted the privacy policy 
  const [ isOpen, setIsOpen ] = useState( true );

  useEffect(() => {
    if(!userDetails){
      const acceptedPolicy = document.cookie.includes('acceptedPolicy=true');
      if (acceptedPolicy) {
        setIsOpen(false);
      }
    }else if(userDetails && userDetails.privacy_accepted){
      setIsOpen(false);
    }
  }, [userDetails]); 

  const [ isChecked, setIsChecked ] = useState(false);

  const handleChange = async () => {
    setIsChecked(!isChecked);

    if(userDetails && userDetails.privacy_accepted === false){
       await acceptPrivacy(userDetails.uid, true);
       updateUserDetailsField("privacy_accepted", true);
    }

    if(!userDetails){
      document.cookie = "acceptedPolicy=true; max-age=31536000";
    }

    setIsOpen(false);
  }

  return (
    <Container>
      <Modal isOpen={isOpen} setOpen={setIsOpen}>
        <BigText>Privacy Policy</BigText>
            <Description>
                By using The Agent Genie and clicking "Accept" implies that you have read and agreed to our privacy policy. You can click <a href='/privacy-policy' style={{textDecoration: 'underline'}}>here</a> to know more about our privacy policy.
                <div style={{padding: 16 + "px", textAlign: "center"}}>
                  <BigButton text="Accept" bgColor="MidnightBlue" color="White" onClick={handleChange}/>
                </div>
            </Description>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
    color: ${COLORS.blue};
`;

const BigText = styled.h2``;

const Description = styled.p`
    // max-width: 500px;
    text-align: center;
`;

export default Privacy_Policy
