import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    font-weight: bold;
    text-align: center;
    background: ${props => props.bgColor};
    border: ${props => (props.border ? '2px solid ' + props.border : '0')};
    padding: 0px 10px;
    border-radius: 10px;
    cursor: pointer;
    pointer-events: auto;
`;

const Text = styled.p`
    color: ${props => props.color};
    font-size: 14px;
    font-family: 'Poppins';
    margin: 10px;
    letter-spacing: 1.5px;
`;

const BigButton = (props) => {
  return (
    <Button bgColor={ props.bgColor } border={ props.border } type={props.type} style={{ pointerEvents: 'auto' }} onClick={props.onClick}>
      <Text color={ props.color }>
        {props.text}
      </Text>
    </Button>
  )
}

BigButton.defaultProps = {
  text: 'Continue',
  type: 'button'
}

export default BigButton
