import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS, ListingStatus } from '../components/variables'
import TopNavbar from '../components/Navbar/Navbar'
import SmallButton from '../components/Buttons/SmallButton'
import AnonymousBidders from '../components/Containers/AnonymousBidders'
import BigButton from '../components/Buttons/BigButton'
import BigItem from '../components/Containers/BigItem'
import Pagination from '../components/Pagination/Pagination'
import TopWinningBidders from '../components/Containers/TopWinningBidders'
import Modal from '../components/Modal/Modal'
import Date from '../components/Forms/Date'
import Confetti from '../components/Background/Confetti'
import { AuthContext } from '../context/AuthContext'
import AddBidding from '../components/Forms/AddBidding'
import { getBiddings } from '../backend-functions/Biddings'
import { deleteListing, getDealListingByListing, getListingById } from '../backend-functions/Listings'
import Relist from '../components/Forms/Relist'
import Error from '../components/Forms/Error'

const Listing = () => {
    const { id } = useParams();
    const { userDetails, dispatch } = useContext(AuthContext);

    const bid = new URLSearchParams(window.location.search).get('bid');
    const deal = new URLSearchParams(window.location.search).get('deal');
    const agent = new URLSearchParams(window.location.search).get('agent');

    const [ bidder, setBidder ] = useState(null);
    const [ dealListing, setDealListing ] = useState(null);
    const [ disabled, setButton ] = useState(false);

    const [ message, setMessage ] = useState('Please Wait...');

    const navigate = useNavigate();

    const [ data, setData ] = useState(null);
    const [ portalLink, setPortalLink ] = useState('');
    const [ error, setError ] = useState('');
    const [ yourBid, setYourBid ] = useState(null);

    const [ isRelist, setisRelist ] = useState(false);
    const [ isDelete, setisDelete ] = useState(false);
    const [ isNull, setIsNull ] = useState(true);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ confetti, setConfetti ] = useState(bid && bid === 'successful' || (deal && deal === 'successful' && agent) || (dealListing && dealListing.agent_id === userDetails.uid));

    useEffect(() => {
      const fetchData = async () => {
        try {
          const unsubscribe = getListingById(id, async (listingData) => {
            setData(listingData); // Update state with the new data
  
            if(!listingData){
              setMessage("Listing not found");
              setIsNull(!isNull);
            }else{
              if(userDetails.usertype === "Agent" && listingData.biddings.length > 0){
                setYourBid(listingData.biddings.find(bidding => bidding.agentId === userDetails.uid).bid);
              }
            }
          });

          return () => {
            unsubscribe();
          };
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }, [id]);
    
    // Use another useEffect to update portalLink when dealListing changes
    useEffect(() => {
      if (dealListing) {
        setPortalLink('/portal/' + dealListing.id);
        if(dealListing.agent_id === userDetails.uid){
          setConfetti(true);
        }
      }
    }, [dealListing]);
     
    useEffect(() => {
      if (data && data.biddings) {
        getBiddings(data.biddings).then((biddings) => {
          setBidder(biddings);
        });
      }

      if (data && !data.isDelete) {
        getDealListingByListing(id).then((dealResult) => {
          setDealListing(dealResult);
        });
      }
    }, [data]);

    const handleDelete = async (e) => {
      e.preventDefault();
      setError("Please Wait...");
      setIsDisabled(true);
      await deleteListing(id);
      setIsDisabled(false);
      navigate("/");
    }

    const header = {rank: 'Rank', agent: 'Agent', rating: 'Rating', bid: 'Bid %', type: 'header'};

  return (
    <div>
      {confetti && <Confetti setConfetti={setConfetti} />}
      <Container height="10vh">
            <TopNavbar bgColor={COLORS.blue} btncolor={COLORS.yellow}></TopNavbar>
        </Container>

      <Main>
        <Modal isOpen={isRelist} setOpen={setisRelist}>
            <Relist handleRelist={() => setisRelist(!isRelist)} listingId={id} />
        </Modal>

        {/* FOR OWNERS ONLY */}
        <Modal isOpen={isDelete} setOpen={setisDelete}>
            <h2>Are you sure you want to delete?</h2>
            <p>Please note that this action is irreversible.</p>
            <Error error={error} />
            <FlexCont width='60%'>
              <SpaceBetween>
                <SmallButton bgColor={COLORS.blue} color={COLORS.white} text='Cancel' onClick={() => setisDelete(!isDelete)} />
                <SmallButton bgColor={COLORS.red} color={COLORS.white} text='Delete' disabled={isDisabled} onClick={handleDelete} />
              </SpaceBetween>
            </FlexCont>
        </Modal>

          {/* SHOW IF DEAL IS ALREADY DONE */}
          {data && ListingStatus[data.status] > ListingStatus['List Ended'] && dealListing && (userDetails.usertype === "Seller" || (userDetails.usertype === "Agent" && dealListing.agent_id === userDetails.uid)) && (
            <Link href={portalLink}>Go to Portal</Link>
          )}
            
          { !data && <BlurText><br/>{message}</BlurText>}
           {/* SHOW ONLY IF THE USER IS AN AGENT */}
           {data && <>{!data.isDelete ? <>
          <SpaceBetween>
            {data && userDetails.usertype === 'Agent' && data.status === 'Bidding Ongoing' && (
              <AddBidding agentId={userDetails.uid} listingId={data.id} width='80%' sellerId={data.owner_id} postcode={data.postcode}/>
            )}
            {yourBid && <BlurText>Your Bid: &nbsp; {yourBid}%
              {dealListing && dealListing.agent_id === userDetails.uid && <span> - You Won!</span>}
            </BlurText>}
          </SpaceBetween>

          {/* SHOW ONLY IF THE LISTING HAS ENDED */}
          {data && data.status !== 'Bidding Ongoing' && data.owner_id === userDetails.uid && data.status !== 'Sold Property' && (
            <ButtonCont>
              <BigButton bgColor={COLORS.red} color={COLORS.white} text="Relist" onClick={() => setisRelist(!isRelist)} />
            </ButtonCont>
          )}
          <br></br>
          <FlexCol>
          <BigItem listing={data} />
          </FlexCol>
          <br></br>

          {!bidder || bidder.length < 1 && 
            <center><p>No biddings yet</p></center>
          }
          {bidder && bidder.length > 0 && 
            <FlexCol>
              <BlurText>Top Bidders</BlurText>
              <ListContainer>
              {bidder.slice(0, 4).map((top) => {
                const bg = (dealListing && dealListing.agent_id === top.id) ? COLORS.yellow : "white";
                
                return (data.status !== 'List Ended' || userDetails.usertype === "Agent") ? (
                  <AnonymousBidders data={top} bgColor={bg} />
                ) : (
                  <TopWinningBidders data={top} listingId={data.id} sellerId={data.owner_id} disabled={disabled} postcode={data.postcode} setButton={setButton} setConfetti={setConfetti} />
                );
              })}
              </ListContainer>
            </FlexCol>
          }
          <br></br>

          {bidder && bidder.length > 4 && 
            <FlexCol>
              <BlurText>More Bidders</BlurText>
                <Pagination data={bidder.slice(4)} header={header} isNull={isNull} />
            </FlexCol>
          }
          <br></br>
        

        {/* SHOW ONLY IF THE USER IS THE OWNER OF THE LISTING */}
        {data && data.owner_id === userDetails.uid && data.status !== 'Sold Property' &&
          <Flex style={{justifyContent: 'center', padding: '20px'}}>
            <FlexCont width='15%'>
              <SpaceBetween>
                <SmallButton bgColor={COLORS.green} text='Edit' onClick={() => setisRelist(!isRelist)} />
                <SmallButton bgColor={COLORS.red} color={COLORS.white} text='Delete' onClick={() => setisDelete(!isDelete)} />
              </SpaceBetween>
            </FlexCont>
          </Flex>}
          </> : <h2>Listing is not found</h2>} </>}
          </Main>
    </div>
  )
}

const Main = styled.div`
  padding: 0px;
  padding: 0px 10%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  box-sizing:border-box;

  @media only screen and (max-width: 780px) {
    padding: 10px;
  }
`;

const Container = styled.div`
    position: relative;
    height: ${ props => props.height};
`;

const Form = styled.form`
  width: 30%;
  margin: 20px 0px;

  @media only screen and (max-width: 780px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

const Input = styled.input`
  border: 1px solid ${COLORS.blue_faded};
  border-radius: 4px;
  padding: 10px;
  width: 25%;
  box-sizing:border-box;
  color: ${COLORS.blue_faded}
`;

const ListContainer = styled.div`
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 30px;
    @media only screen and (max-width: 780px) {
      padding: 0px;
      justify-content: center;
    }
`;

const Flex = styled.div`
    display: flex;

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

const FlexCol = styled.div`
 width: 100%;
`;

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media only screen and (max-width: 780px) {
      flex-direction: column;
    }
`;

const FlexCont = styled.div`
    width: ${props => props.width};
    background: ${props => props.bg};
    color: ${COLORS.blue};
    padding: 0px 20px;
    // display: flex;
    // justify-content: center;
    
    box-sizing: border-box;

    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: 0px;
    }
`;

const Link = styled.a`
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    color: ${props => props.color};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
`;

const BlurText = styled.p`
  color: ${COLORS.blue_faded};
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const ButtonCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`;

export default Listing
