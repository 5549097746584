const AuthReducer = (state, action) => {
    switch (action.type) {
      case "LOGIN": {
        const { authUser, userDetails } = action.payload;
        return {
          authUser: authUser,
          userDetails: userDetails,
        };
      }
      
      case "LOGOUT": {
        return {
          authUser: null,
          userDetails: null,
        };
      }

      case "UPDATE_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload,
      };

      case "UPDATE_AUTH_USER":
      return {
        ...state,
        authUser: action.payload,
      };
      
      default:
        return state;
    }
  };
  
  export default AuthReducer;