import LandingPage from './pages/LandingPageAgent';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import BrowseListings from './pages/BrowseListings';
import Listing from './pages/Listing';
import CreateListing from './pages/CreateListing';
import AgentAnonymous from './pages/AgentAnonymous';
import AgentProfile from './pages/AgentProfile';
import Portal from './pages/Portal';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './context/AuthContext';
import Dashboard from './pages/Admin/Dashboard';
import UserAccounts from './pages/Admin/UserAccounts';
import ApproveAgents from './pages/Admin/ApproveAgents';
import Listings from './pages/Admin/BrowseListings';
import PortalLinks from './pages/Admin/PortalLinks';
import Invoices from './pages/Admin/Invoices';
import Agent from './pages/Admin/Agent';
import User from './pages/Admin/User';
import { UserTypes } from './components/variables';
import AddUser from './pages/Admin/AddUser';
import EditProfile from './pages/EditProfile';
import SuccessURL from './pages/SuccessURL';
import CancelURL from './pages/CancelURL';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ReferralAgreement from './pages/ReferralAgreement';
import ForgotPassword from './pages/ForgotPassword';
import AboutUs from './pages/AboutUs';
import { listenNotifications } from './backend-functions/Notifications';
import LandingPageSeller from './pages/LandingPageSeller';
import LandingPageAgent from './pages/LandingPageAgent';
import InstallPWA from './components/Modal/InstallPWA';
import { auth } from './firebase';
import { assignCountryToCollection, assignUKCountry, updateListingStatus } from './backend-functions/custom-functions';
import { changeUsername } from './backend-functions/Users';
import TestPage from './pages/TestPage';
import CompleteRegistrationPixelCode from './components/CompleteRegistrationPixelCode';
import Footer from '../src/components/Navbar/Footer';

function App() {
  const { authUser } = useContext(AuthContext);
  const { userDetails } = useContext(AuthContext);

  // only registered users can access
  const RequireAuth = ({ children }) => {
    return authUser && userDetails ? children : <Navigate to='/become-a-seller'/>
  }

  // only admin users can access
  const RequireAdmin= ({ children }) => {
    return authUser && userDetails.typehierarchy <= UserTypes.Manager ? children : <Navigate to='/become-a-seller'/>
  }

  // get temporarily the agent name for the referral agreemet
  const [ agentName, setAgentName ] = useState(' ')

  useEffect(() => {
    // Try to retrieve the agentName from localStorage
    const storedAgentName = localStorage.getItem('agentName');
  
    // If a storedAgentName exists, use it; otherwise, use the default value
    if (storedAgentName) {
      setAgentName(storedAgentName);
    } else {
      // If it doesn't exist in localStorage, set a default value
      setAgentName('Default Name');
    }
  }, [localStorage.getItem('agentName')]);

  useEffect(() => {
    // ask permission for notificiations
    if('Notification' in window){
      if(Notification.permission === 'default'){
        Notification.requestPermission()
      }
    }
  }, [])

  useEffect(() => {
    // Listen for notifications when the component mounts
    if ('Notification' in window && userDetails) {
      listenNotifications(userDetails.uid);
    }
  }, [userDetails]);

  const checkCookieExist = (cookieName) => {
    // Get all cookies as a string
    const cookies = document.cookie;
  
    // Split the string into an array of individual cookies
    const cookieArray = cookies.split(';');
  
    // Iterate through the array and check if the desired cookie exists
    for (const cookie of cookieArray) {
      const [name, value] = cookie.trim().split('=');
      if (name === cookieName) {
        return true; // Cookie with the specified name exists
      }
    }
  
    return false; // Cookie with the specified name does not exist
  }

  useEffect(() => {
    updateListingStatus();
  }, [])


  return (
    <div className="App">
      {!checkCookieExist('hidePrompt') && <InstallPWA /> }
      {authUser && userDetails && <CompleteRegistrationPixelCode/>}
      <BrowserRouter>
        <Routes>
          {/* Guest Pages */}
          <Route path='/test' element={<TestPage/>} />
          <Route path='/become-a-seller' element={<LandingPageSeller/>} />
          <Route path='/become-an-agent' element={<LandingPageAgent/>} />
          <Route path='/login' element={<LoginPage/>} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/forgot-password' element={<ForgotPassword/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='/terms-of-use' element={<TermsOfUse/>} />
          <Route path='/referral-agreement' element={ <ReferralAgreement agentName={agentName}/> }/>
          <Route path='/referral-agreement/:id' element={<ReferralAgreement/>} />
          <Route path='/create' element={<CreateListing/>} />

          {/* Authorized Pages */}
          <Route path='/' element={<RequireAuth><HomePage/></RequireAuth>} />
          <Route path='/home' element={<RequireAuth><HomePage /></RequireAuth>} />
          {/* <Route path='/browse' element={<RequireAuth><BrowseListings/></RequireAuth>} /> */}
          <Route path='/listing/:id' element={<RequireAuth><Listing/></RequireAuth>} />
          <Route path='/secretagent/:id' element={<RequireAuth><AgentAnonymous/></RequireAuth>} />
          <Route path='/agent/:id' element={<RequireAuth><AgentProfile/></RequireAuth>} />
          <Route path='/portal/:id' element={<RequireAuth><Portal/></RequireAuth>} />
          <Route path='/account' element={<RequireAuth><EditProfile/></RequireAuth>} />
          <Route path='/about-us' element={<RequireAuth><AboutUs/></RequireAuth>} />

          <Route path='/success/:invoiceID/:token' element={<RequireAuth><SuccessURL/></RequireAuth>} />
          <Route path='/cancel/:invoiceID/:token' element={<RequireAuth><CancelURL/></RequireAuth>} />

          {/* Admin Pages */}
          <Route path='/dashboard' element={<RequireAdmin><Dashboard/></RequireAdmin>} />
          <Route path='/users' element={<RequireAdmin><UserAccounts/></RequireAdmin>} />
          <Route path='/agents' element={<RequireAdmin><ApproveAgents/></RequireAdmin>} />
          <Route path='/approveagent/:id' element={<RequireAdmin><Agent/></RequireAdmin>} />
          <Route path='/listings' element={<RequireAdmin><Listings/></RequireAdmin>} />
          <Route path='/portals' element={<RequireAdmin><PortalLinks/></RequireAdmin>} />
          <Route path='/view/:id' element={<RequireAdmin><User/></RequireAdmin>} />
          <Route path='/adduser' element={<RequireAdmin><AddUser/></RequireAdmin>} />
          <Route path='/invoices' element={<RequireAdmin><Invoices/></RequireAdmin>} />
        </Routes>
      </BrowserRouter>
      
      <Footer/>
    </div>
  );
}

export default App;
