import React, { useContext, useEffect, useState } from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import Modal from './Modal'
import BigButton from '../Buttons/BigButton'
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { logout } from '../../backend-functions/Auth'
import { getUser } from '../../backend-functions/Users'

const Blocked_Notif = () => {
  const navigate = useNavigate();
  const {userDetails, dispatch} = useContext(AuthContext);

  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(()=> {
    const fetchData = async () => {
      const user = getUser(userDetails.uid);

      setIsOpen(user.isDelete);
    }

    if(userDetails && userDetails.uid)
      fetchData();
  }, []);

  // const handleClick = async () => {
  //   const response = await logout();
  //   if(response){
  //     navigate('/');
  //     dispatch({type: "LOGOUT"});
  //     alert("You have successfully logout!");
  //   }
  // }

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen}>
        <BigText>Blocked Account</BigText>
        <Description>
            Your account has been blocked by the admins of The Agent Genie. <br/> 
            If there is some mistake, please send an email to support@gmail.com.
        </Description>
        <BigButton text='Okay' bgColor={COLORS.blue} color={COLORS.white} onClick={handleClose} />
    </Modal>
  )
}

const BigText = styled.h3`
    color: ${COLORS.blue};
`;

const Description = styled.p`
    color: ${COLORS.blue};
`;

export default Blocked_Notif
