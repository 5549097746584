import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import { calculateSpentTime, convertFirestoreTimestampToJSDate } from '../../backend-functions/custom-functions';

const PortalItem = ({data}) => {
  const [ongoingTime, setOngoingTime] = useState('');
  const [stopWatch, setStopWatch] = useState(null);

  useEffect(() => {
    // Cleanup function to clear the interval when component is unmounted
    return () => {
        if (stopWatch) {
          clearInterval(stopWatch);
        }
      };
  });

  useEffect(() => {
    if(data && data.sold_price == 0){
        const interval = setInterval(() => {
            calculateSpentTime(data.actualtimestamp)
                .then((spentTime) => {
                  let string = "";
                  // display spent time
                  if (spentTime.days > 0) {
                    string += spentTime.days + "d ";
                  }
                  if (spentTime.hours > 0) {
                    string += spentTime.hours + "h ";
                  }
                  if (spentTime.minutes > 0) {
                    string += spentTime.minutes + "m ";
                  }
                  if (spentTime.seconds > 0) {
                    string += spentTime.seconds + "s ";
                  }
                  setOngoingTime(string); // Update ongoingTime with the calculated
                })
                .catch((error) => {
                    console.log(error);
                    clearInterval(interval);
                });
        }, 1000); //Recurring every 1 second

        return () => {
            clearInterval(interval); // Clear the interval when component is unmounted or when `data` changes
        };
    }else{
      setOngoingTime('Property Sold');
    }
  }, [data]);

  const info = {
    'Seller': data.seller,
    'Agent': data.agent,
    [data.country === 'US' ? "Zip Code" : "Post Code"] : data.postcode,
    'Bid': data.bid,
    'Offer Price': (new Intl.NumberFormat('en-US', { style: 'currency', currency: data.country === 'US' ? 'USD' : 'GBP' })).format(data.offer_price),
    'Sold Price': (new Intl.NumberFormat('en-US', { style: 'currency', currency: data.country === 'US' ? 'USD' : 'GBP' })).format(data.sold_price),
    "Time Unsold": ongoingTime,
  };

  const width = (100 / Object.keys(info).length) + '%';

  return (
    <ColorCont border="yes">
      <div style={{ background: COLORS.blue, height: "15%", width: "100%" }}>
        &nbsp;
      </div>
      <FlexCol>
        {Object.keys(info).map((key) => (
          <ColorCont width={key === 'Time Unsold' || key === 'Offer Price' || key === 'Sold Price' ? '23%' : width} padding="20px 0px">
            <Title>{key}</Title>
            <Inside key={key}>{info[key]}</Inside>
          </ColorCont>
        ))}
      </FlexCol>
    </ColorCont>
  );
}

const ColorCont = styled.div`
// border: 2px solid red;
    display: flex;
    flex-direction: column;
    width: ${props => props.width ? props.width : '100%'};
    padding: ${props => props.padding};
    align-items: center;
    justify-contents: center;
    white-space: nowrap;
    text-overflow: ellipsis; 
    box-shadow: ${props => props.border ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : '0'};
    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: ${props => props.border ? '0' : '10px 0px'};
    }
`;

const FlexCol = styled.div`
    display: flex;
    height: 85%;
    width: 100%;
    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

const Title = styled.p`
    margin: 0;
`;

const Inside = styled.h2`
    margin: 0;
`;

export default PortalItem
