import React, { useContext, useState } from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { dealListing } from '../../backend-functions/Listings'
import Error from '../Forms/Error'
import { AuthContext } from '../../context/AuthContext'

const DealButton = ({data, listingId, sellerId, setButton, disabled, setConfetti, postcode}) => {
    const [ error, setError ] = useState('')
    const navigate = useNavigate()
    const { userDetails } = useContext(AuthContext)

    const handleDealClick = async (e) => {
        e.preventDefault();

        if(!disabled){
            const userConfirmed = window.confirm("Are you sure you want to proceed?")

            if(userConfirmed){
                setButton(true)
                setError('Please Wait')

                const agentId = data.id
                const bid = data.Bid

                const deal = await dealListing(listingId, sellerId, agentId, bid, userDetails.country)

                if(deal){
                    navigate('/portal/'+ deal +'?deal=successful');
                }
            }
        }
    }

    if (data.Rank === '1st' || data.Rank === '2nd' || data.Rank === '3rd') {
        return (
          <Container>
            <Error error={error} />
            <Button onClick={handleDealClick}>
                Deal
            </Button>
          </Container>
        );
    } else {
        // For 4th rank and beyond, return null
        return null;
    }    
}

const Container = styled.div`
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
`;

const Button = styled.a`
    background: ${COLORS.light_blue};
    color: ${COLORS.white};
    // right: 10px;
    padding: 5px 0px;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    // display: none;

    @media only screen and (max-width: 780px) {
        display: block;
    }
`;

export default DealButton
