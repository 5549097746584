import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components';
import { COLORS } from '../components/variables';
import BigButton from '../components/Buttons/BigButton'; 
import { getInvoiceById } from '../backend-functions/Payments'
import Loading from '../components/Modal/Loading';


const SuccessURL = () => {
  const { invoiceID, token } = useParams();
  const [ isExpired, setIsExpired ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(true);

  const navigate = useNavigate();

  // check if the invoiceID is existing, the token is correct
  useEffect(() => {
    const fetchData = async () => {
        try {
            const invoice = await getInvoiceById(invoiceID);
            setIsLoading(false)
            if(!invoice || (invoice && (invoice.success_token !== token))){
                setIsExpired(true);
                return;
            }

            setIsExpired(false);
        } catch (error) {
            console.error(error);
        }
    }
    fetchData();
  }, [invoiceID]);

  const handleRedirect = () => {
    navigate('/')
  }

  return (
    <Container>
        {!isLoading ?
        <>
        {!isExpired ? 
        <Card>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill={COLORS.green} class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
            </svg>
            <Text>Your Payment has been successful. Thank you for paying.</Text>
            <BigButton text='Continue' bgColor={COLORS.blue} color={COLORS.white} onClick={handleRedirect} />
        </Card>
        :
        <Card>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill={COLORS.red} class="bi bi-bug" viewBox="0 0 16 16">
                <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"/>
            </svg>
            <Text>Error. This link has already expired.</Text>
            <BigButton text='Go Back' bgColor={COLORS.blue} color={COLORS.white} onClick={handleRedirect} />
        </Card>
        }
        </>
        :
        <Loading />
        }
    </Container>
  )
}

const Container = styled.div`
    background: ${COLORS.blue};
    color: ${COLORS.white};
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Card = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 25%;
    height: 50%;
    background: ${COLORS.white};
    border-radius: 15px;
    border: 2px solid ${COLORS.gray};
    color: ${COLORS.blue};
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    @media only screen and (max-width: 780px) {
        width: 80%;
    }
`;

const Text = styled.h3`
    color: ${COLORS.blue};
    text-align: center;
`;

export default SuccessURL
