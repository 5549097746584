import React, { useContext, useEffect, useState } from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import bg from '../../img/bg.jpeg'
import USbg from '../../img/USbg.jpg'
import logo from '../../img/logo.png'
import '../css/animations.css'
import BigButton from '../Buttons/BigButton'
import UKSeller from '../../img/UK_SELLER.gif'
import UKAgent from '../../img/UK_AGENT.gif'
import USSeller from '../../img/US_SELLER.gif'
import USAgent from '../../img/US_AGENT.gif'

const Background = (props) => {
  const btnText = (props.uType == "Agent") ? "Free Sign Up" : "Create Listing";
  const btnLink = (props.uType == "Agent") ? "/register?type=Agent" : "/create";

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [videoSource, setVideoSource] = useState(null);

  const [country, setCountry] = useState('');

  useEffect(() => {
    if (props.uType === "Agent") {
        setVideoSource(UKAgent);
        setTitle("Looking For More Local Sellers?");
        setDescription("Bid against other estate agents for the right to list a seller's property based on your commission you will charge. <b>Do this <u>anonymously</u> until you win the listing!</b>");
    } else {
        setVideoSource(UKSeller);
        setTitle("How Much Commission Will Your Local Estate Agent Bid?");
        setDescription("Find out which estate agent will charge you the lowest commission to sell your property. <b>Do this <u>anonymously</u> until you pick a winning agent!<b>");
    }

    fetch('https://ipinfo.io/json?token=28eef5fa28f61d')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country);

        if (props.uType === "Agent") {
          if (data.country === "US") {
            setVideoSource(USAgent);
            setTitle("Looking For More Local Sellers?");
            setDescription("Bid against other real estate agents for the right to list a seller's property based on your commission you will charge. <b>Do this <u>anonymously</u> until you win the listing!</b>");
          } else {
            setVideoSource(UKAgent);
            setTitle("Looking For More Local Sellers?");
            setDescription("Bid against other estate agents for the right to list a seller's property based on your commission you will charge. <b>Do this <u>anonymously</u> until you win the listing!</b>");
          }
        } else {
          if (data.country === "US") {
            setVideoSource(USSeller);
            setTitle("How Much Commission Will Your Local Real Estate Agent Bid?");
            setDescription("Find out which realtor will charge you the lowest commission to sell your property. <b>Do this <u>anonymously</u> until you pick a winning realtor!<b>");
          } else {
            setVideoSource(UKSeller);
            setTitle("How Much Commission Will Your Local Estate Agent Bid?");
            setDescription("Find out which estate agent will charge you the lowest commission to sell your property. <b>Do this <u>anonymously</u> until you pick a winning agent!<b>");
          }
        }
      })
      .catch(error => {
        console.error('Error fetching IP information:', error);
      });
  }, [props.uType]);

  return (
    <Container bgUrl={country === 'US' ? USbg : bg}>
      <Back></Back>
      <Front>
        <Cont>
            <Flex width='50%'>
                <FlexBox>
                    <Title>{title}</Title>
                    <Description dangerouslySetInnerHTML={{ __html: description }}></Description>
                </FlexBox>
                <FlexBox style={{ paddingTop: '20px' }}>
                    <a href={btnLink}><BigButton text={btnText} bgColor={COLORS.yellow}/></a>
                </FlexBox>
                {props.uType == "Agent" ? 
                    <FlexCol>
                        <FlexBox width='33%'>
                            <Number>5k <Highlight>+</Highlight></Number>
                            <Description>Sellers {country === 'US' ? 'Across The State' : 'Nationwide'} </Description>
                        </FlexBox>
                        <FlexBox width='33%'>
                            <Number>22k <Highlight>+</Highlight></Number>
                            <Description>Commission bids</Description>
                        </FlexBox>
                        <FlexBox width='33%'>
                            <Number>{ country === 'US' ? '$' : '£' }500k <Highlight>+</Highlight></Number>
                            <Description>Commissions Won</Description>
                        </FlexBox>
                    </FlexCol>
                    :
                    <FlexCol>
                        <FlexBox width='33%'>
                            <Number>{ country === 'US' ? '1,566,354' : '57,600 ' }<Highlight>+</Highlight></Number>
                            <Description>{ country === 'US' ? 'Realtors Across The US' : 'UK Estate Agents' }</Description>
                        </FlexBox>
                        <FlexBox width='33%'>
                            <Number>{ country === 'US' ? '5' : '1' }M <Highlight>+</Highlight></Number>
                            <Description>{ country === 'US' ? 'Sales Completed Last Year' : 'Sales Completed Last Year in the UK' }</Description>
                        </FlexBox>
                        <FlexBox width='33%'>
                            <Number>{ country === 'US' ? '$10k' : '£5k' } <Highlight>+</Highlight></Number>
                            <Description>{ country === 'US' ? 'Realtors Fees Saved' : 'Estate Agent Fees Saved' }</Description>
                        </FlexBox>
                    </FlexCol>
                }
            </Flex>
            { videoSource && <LogoCont width='50%'>
                {/* <Video autoPlay loop muted>
                    <source src={videoSource} type="video/mp4" />
                </Video> */}
                <img src={videoSource} alt="" style={{ width: '100%' }}/>
            </LogoCont>}
        </Cont>
      </Front>
    </Container>
  )
}

const Container = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url(${props => props.bgUrl});
    background-size: cover;
    background-position: center;

    @media only screen and (max-width: 780px) {
        width: 100vw;
    }
`;

const Back = styled.div`
    background: ${ COLORS.blue_faded };
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
`;

const Front = styled.div`
    // background: ${ COLORS.blue_faded };
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    z-index: 999
`;

const Cont = styled.div`
    // background: rgba(0, 255, 0, 0.1);
    height: 90%;
    position: relative;
    display: flex;
    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }
`;

const Flex = styled.div`
    background: ${props => props.color};
    width: ${props => props.width};
    display: flex;
    flex-direction: column;
    padding: 100px;
    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: 25px;
        box-sizing: border-box;
    }
`;
const Video = styled.video`
    width: 100%;
`;

const LogoCont = styled.div`
    background: ${props => props.color};
    width: ${props => props.width};
    // position: absolute;
    margin-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: 0 25px 25px;
        box-sizing: border-box;
    }
`;

const FlexBox = styled.div`
    // background: ${props => props.color};
    width: ${props => props.width};
    @media only screen and (max-width: 780px) {
        width: 100%;
    }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 60px 0px;
    gap: 20px;
    @media only screen and (max-width: 780px) {
        width: 100%;
        padding: 0px;
        margin: 20px 0px;
        box-sizing: border-box;
        // background: blue
    }
`;

const Highlight = styled.span`
    color: ${ COLORS.yellow };
`;

const Title = styled.h1`
    color: ${COLORS.white};
    font-weight: 900;
    font-size: 40px;
    margin: 0;

    @media only screen and (max-width: 780px) {
        font-size: 24px;
    }
`;

const Number = styled.h1`
    color: ${ COLORS.white };
    // background: red;
    margin: 0;
    @media only screen and (max-width: 780px) {
        font-size: 18px
    }
`;

const Description = styled.p`
    color: ${COLORS.white};
    // background: blue;
    margin: 0;
    @media only screen and (max-width: 780px) {
        font-size: 14px;
        width: 100%;
        box-sizing: border-box;
        white-space: normal; // Add this line to wrap the text down
    }
`;
export default Background
