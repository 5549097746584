import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'
import { useParams } from 'react-router-dom';
import { getReferralAgreement } from '../backend-functions/Auth';
import Loading from '../components/Modal/Loading';

const ReferralAgreement = ({agentName}) => {
  const { id } = useParams()

  const [ name, setName ] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      if(id !== undefined){
        const name = await getReferralAgreement(id);

        setName(name)
      }else{
        setName(agentName)
      }
    }

    fetchData()
  }, [id, agentName]);

  return (
    <Page>
      { name ? 
      <Container>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>REFERRAL AGREEMENT</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>THIS AGREEMENT</span></strong><span style={{fontSize:"11pt"}}>&nbsp;is made</span></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>BETWEEN:</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>1. Social Impact Marketing Ltd a company registered in England and Wales under number 12816194 whose registered office is at 19 St. Brides Road, Wick, Cowbridge, Wales, CF71 7QB (hereinafter known as the &ldquo;Company&rdquo; or &ldquo;we&rdquo; or &ldquo;us&rdquo;); and</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>2. <b>{name}</b>&nbsp;&nbsp;.(hereinafter known as &ldquo;the Agent&quot; or &ldquo;you&rdquo;)</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Each a Party and together the Parties.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>IT IS AGREED</span></strong><span style={{fontSize:"11pt"}}>&nbsp;as follows:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>1.</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>DEFINITIONS AND INTERPRETATION</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>1.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>In this Agreement, the following definitions apply:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>&ldquo;The Agent Genie&rdquo;</span></strong><span style={{fontSize:"11pt"}}>&nbsp;means Social Impact Marketing Ltd</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>&quot;Agency Fees&quot;</span></strong><span style={{fontSize:"11pt"}}>&nbsp;means the estate agency fees you will charge a Client if you are</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>instructed to sell the Property.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>&quot;Client&quot;</span></strong><span style={{fontSize:"11pt"}}>&nbsp;means a person (being a legal or beneficial owner of or such persons&apos;</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>representative of a Property) requiring the services of an estate agent who has been</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>referred to you by us through the Site.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>&quot;Fees&quot;</span></strong><span style={{fontSize:"11pt"}}>&nbsp;means the fees payable by you for our Service in accordance with clause 5.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>&quot;Referral&quot;</span></strong><span style={{fontSize:"11pt"}}>&nbsp;means a referral or introduction made through the Site between a Client and</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>an estate agent.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>&quot;Property&quot;&nbsp;</span></strong><span style={{fontSize:"11pt"}}>means the property specified by the Client on the Site in relation to which the Client requires estate agency services.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>&quot;Site&quot;</span></strong><span style={{fontSize:"11pt"}}>&nbsp;means our website (&quot;</span><a href="http://www.theagentgenie.com"><u><span style={{color:"#1155cc", fontSize:"11pt"}}>www.theagentgenie.com</span></u></a><span style={{fontSize:"11pt"}}>&quot;).</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>1.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>In this Agreement, unless context otherwise requires:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>1.2.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>reference to a person includes a natural person, corporate or unincorporated body</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>(whether or not having separate legal capacity);</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>1.2.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>a reference to a company shall include any company, corporation or other body</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>corporate, wherever and however incorporated or established; and</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>1.2.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>a reference to &ldquo;writing&rdquo; or &ldquo;written&rdquo; includes e-mail.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>2.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>COMMENCEMENT AND DURATION</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>This Agreement shall commence on the date when it has been signed by both</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Parties and shall continue, unless terminated earlier in accordance with clause 11.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>3.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>OUR SERVICE</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>3.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>The Site is designed to enable a Client to find the cheapest estate agent/realtor.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>3.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>We make no warranty as to the quality of the Client we refer to you and are not liable</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>for any loss or damage you may incur as a result of an introduction to a Client,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>including without limitation any failure by the Client to pay you for your work.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>3.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>We will not be a party to any contract made between you and the Client and you will</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>be responsible for all regulatory requirements relevant to the engagement of that</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Client.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>3.4&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>When we send you the Client&apos;s Referral request we provide a phone number and</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>email with which you can contact the Client through the Site. Calls made and emails</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>sent using the contact details we provide may be recorded and retained, and may be</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>used where necessary to enforce this Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>4.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>YOUR OBLIGATIONS</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You shall ensure that your Agency Fees are complete and accurate in all material</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>respects and are not misleading.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You shall promptly respond to a Referral request by contacting the Client using the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>contact details we provided.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>If you do not use our contact details, and a Client contacts you after the Referral our</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Fees will still be payable. You must notify us that you have been contacted.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.4&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>If, following receipt of a Referral, you wish to raise a claim that a Client had already</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>instructed you and/or you had already attended the Property to carry out a valuation</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>in the 4 weeks prior to receiving the Referral, you must do so within 14 days of the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Referral date. Claims raised after this time will not be considered. You agree to</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>provide documentary evidence to our reasonable satisfaction within 7 days of a claim</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>being raised. If you are unable to do so Fees will still be payable.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.5&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You agree that you are fully responsible and liable for the actions of any persons</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>who access the Site on your behalf and at all material times will act and will ensure</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>that any persons who access the Site on your behalf shall act in good faith.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.6&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You acknowledge that we may edit, modify or remove any Agency Fees which we</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>consider are in breach of any of the provisions of the Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.7&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You shall not produce any marketing material for the Company&apos;s services or use the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Company&apos;s name, logo or trade marks on any marketing material for the Services</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>without the prior written consent of the Company.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.8</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You shall not, without the Company&rsquo;s prior written consent, make or give any</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>representations, warranties or other promises concerning the Site which are not</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>contained in the Company&apos;s marketing material.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.9&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You shall keep separate accounts and records giving correct and adequate details of</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>all Referral and Clients. You shall permit the duly appointed representatives of the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Company at all reasonable times, but no more than once in any 12-month period, to</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>inspect all such accounts and records and to take copies of them.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.10&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>If any dispute arises as to the Fees which cannot be settled between the Company</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>and the Agent then it shall be referred to the Company&apos;s auditors for settlement and</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>their decision, save in the case of manifest error, shall be final and binding on both</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>parties.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4.11&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>The Agent (including any employees, agents, subcontractor or anyone acting on the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Agent&apos;s behalf) shall ensure that it does not do anything which might adversely affect</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the Company&rsquo;s public image, reputation, goodwill or bring the Company into</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>disrepute;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>5.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>FEES AND PAYMENT</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>5.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You agree to pay us a fee of 0.20% of the final Property sales price for every</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Referral that results in a Property sale.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>5.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>For the avoidance of doubt, Fees shall remain payable if you are instructed to market</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the Property within 6 months of the Referral.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>5.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You shall notify us, within 5 business days, if you are instructed to market the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Property, and when the Property sale exchanges. If you fail to inform us, within 5</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>business days of the Property exchange then a non-notification fee of &pound;95 will</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>be payable upon receipt of invoice.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>5.4&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>You will be invoiced on Property exchange and when we are notified. Payment is due and payable within 5 days of completion of the Property sale or as agreed in writing between the Company and the Agent. Your payment method on your account will be automatically billed for the referral fee.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>If you fail to make any payment of the Fees by the 5 day deadline, then you shall pay us a late payment fee of &pound;75 plus interest on the overdue amount at the rate of 6% per cent per annum above Barclays Bank plc&apos;s base rate from time to time. Such interest shall accrue on a daily basis from the due date until actual payment of the overdue amount, whether before or after judgment. You shall pay the interest together with the overdue amount.</span><span style={{fontSize:"11pt"}}><br/></span><span style={{fontSize:"11pt"}}><br/></span><span style={{fontSize:"11pt"}}>Failure to make payment will result in your account being banned and you will be denied any future access to our services.&nbsp;</span><span style={{fontSize:"11pt"}}><br/></span><span style={{fontSize:"11pt"}}><br/></span><span style={{fontSize:"11pt"}}>After a long period without receiving payment we will inform our debt collection services to collect the debt on our behalf. This could result in you having a CCJ against your name or company. This could also affect your credit rating as an individual.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>6.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>CHANGES</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>The Company may from time to time adjust the terms of the Agreement. The</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Company shall immediately notify the Agent of any such changes in accordance with</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>clause 7. The Agent may terminate the Agreement in accordance with clause 10 if</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the adjustments are deemed unsatisfactory.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>7.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>NOTICES</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>7.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Any notice required to be given under this Agreement shall be in writing and signed</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>by the person giving it and may be delivered personally or sent by facsimile</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>transmission or other electronic means or by first class post to the address set out</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>above in respect of each of the parties or to such other address as may otherwise be</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>notified by either party to the other as being an alternative address for service.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>7.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Any notice so served shall be deemed to be received:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>(a) if delivered personally, at the time of delivery;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>(b) if sent by email or other electronic means, on the day of transmission provided</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>it is sent during Business Hours on a Business Day and if not on the next</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Business day; and</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>(c) if sent by post, 48 hours after the date of posting.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>8.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>CONFIDENTIALITY</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>8.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Each party undertakes that it shall not at any time or at any time during this</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Agreement, and for a period of three years after termination of this Agreement,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>disclose to any person any confidential information concerning the business, affairs,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>customers, clients or suppliers of the other party or of any member of the group of</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>companies to which the other party belongs, except as permitted by clause 8.2.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>8.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>Confidentiality exceptions</span></strong><span style={{fontSize:"11pt"}}>. Each party may disclose the other party&rsquo;s confidential</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>information:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>(a) to its employees, officers, representatives or advisers who need to know such</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>information for the purposes of carrying out the party&apos;s obligations under this</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Agreement. Each party shall procure that its employees, officers,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>representatives or advisers to whom it discloses the other party&apos;s confidential</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>information comply with this clause 8 and</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>(b) as may be required by law, a court of competent jurisdiction or any</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>governmental or regulatory authority.</span></p>
        <p><br/></p>
        <p><span style={{fontSize:"11pt"}}>(c)&nbsp;</span><strong><span style={{fontSize:"11pt"}}>Limited use of confidential information</span></strong><span style={{fontSize:"11pt"}}>. No party shall use any other party&rsquo;s confidential information for any purpose other than to perform its obligations under this Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>8.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>All documents and other records (in whatever form) containing confidential</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>information supplied to or acquired in connection with this Agreement shall be</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>returned promptly to the Company or Agent (as applicable) on termination of this</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Agreement, and no copies shall be kept, whether digitally or otherwise.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>9.&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>COMPLIANCE</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Each party shall at its own expense comply with all laws and regulations relating to</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>its activities under this Agreement, as they may change from time to time, and with</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>any conditions binding on it in any applicable licences, registrations, permits and</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>approvals.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>10.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>DATA PROTECTION</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>10.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Definitions.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(a) Agreed Purposes:</span></strong><span style={{fontSize:"11pt"}}>&nbsp;For the purpose of providing Referrals via the Site and</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>fulfilling the obligations under this Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(b) Controller, data controller, processor, data processor, data subject,</span></strong></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>personal data, processing and appropriate technical and organisational</span></strong></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>measures</span></strong><span style={{fontSize:"11pt"}}>: as set out in the Data Protection Legislation in force at the time.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(c) Data Protection Legislation:</span></strong><span style={{fontSize:"11pt"}}>&nbsp;all legislation and regulatory requirements in force</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>from time to time relating to the use of personal data and the privacy of electronic</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>communications, including, without limitation (i) any data protection legislation from</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>time to time in force in the UK including the Data Protection Act 2018 or any</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>successor legislation, as well as (ii) the General Data Protection Regulation ((EU)</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>2016/679) (</span><strong><span style={{fontSize:"11pt"}}>GDPR</span></strong><span style={{fontSize:"11pt"}}>) and any other directly applicable European Union regulation</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>relating to data protection and privacy (for so long as and to the extent that the law of</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the European Union has legal effect in the UK).</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(d) Permitted Recipients:</span></strong><span style={{fontSize:"11pt"}}>&nbsp;The parties to this Agreement, the employees of each</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>party, any third parties engaged to perform obligations in connection with this</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(e) Shared Personal Data:</span></strong><span style={{fontSize:"11pt"}}>&nbsp;the personal data to be shared between the parties</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>under this Agreement. Shared Personal Data shall be confined to the following</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>categories of information relevant to the following categories of data subject:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Name</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Address</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Contact Information &mdash; e.g. email</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>10.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>Shared Personal Data.</span></strong><span style={{fontSize:"11pt"}}>&nbsp;The provisions which follow out the framework for the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>sharing of personal data between the parties as data controllers. Each party</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>acknowledges that one party (the Data Discloser) will regularly disclose to the other</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>party (the Data Recipient) Shared Personal Data collected by the Data Discloser for</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the Agreed Purposes. Each party shall:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(a)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;ensure that it has all necessary consents and notices in place to enable lawful</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>transfer of the Shared Personal Data to the Data Recipient for the Agreed Purposes;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(b)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;give full information to any data subject whose personal data may be processed</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>under this Agreement of the nature such processing. This includes giving notice that,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>on the termination of this Agreement, personal data relating to them may be retained</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>by or, as the case may be, transferred to one or more of the Data Recipients, their</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>successors and assigns;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(c)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;process the Shared Personal Data only for the Agreed Purposes;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(d)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;not disclose or allow access to the Shared Personal Data to anyone other than</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the Permitted Recipients;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(e)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;ensure that all Permitted Recipients are subject to written contractual obligations</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>concerning the Shared Personal Data (including obligations of confidentiality) which</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>are no less demanding than those imposed by this Agreement;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(f)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;ensure that it has in place appropriate technical and organisational measures,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>reviewed and approved by the other party, to protect against unauthorised or</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>unlawful processing of personal data and against accidental loss or destruction of, or</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>damage to, personal data.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(g)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;not transfer any personal data obtained from the Data Discloser outside of the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>European Economic Area unless the prior written consent of the data subject has</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>been obtained and the following conditions are fulfilled:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(i)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;complies with the provisions of Articles 26 of the GDPR (in the event the third</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>party is a joint controller); and</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(ii)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;the transferring party complies with its obligations under the Data Protection</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Legislation ensures that (i) the transfer is to a country approved by the European</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Commission as providing adequate protection pursuant to Article 45 GDPR; (ii) there</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>are appropriate safeguards in place pursuant to Article 46 GDPR; or (iii) one of the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>derogations for specific situations in Article 49 GDPR applies to the transfer.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>10.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>Compliance</span></strong><span style={{fontSize:"11pt"}}>: Each party shall comply with the Data Protection Legislation and</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>agrees that any material breach of the Data Protection Legislation shall, if not</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>remedied within 30 days of written notice from the other party, give grounds to the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>other party to terminate this Agreement with immediate effect.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>10.4&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>Mutual Assistance</span></strong><span style={{fontSize:"11pt"}}>. Each party shall assist the other in complying with all applicable</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>requirements of the Data Protection Legislation. In particular, each party shall:</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(a)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;consult with the other party about any notices given to data subjects in relation to</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the Shared Personal Data;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(b)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;promptly inform the other party about the receipt of any data subject access</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>request;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(c)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;provide the other party with reasonable assistance in complying with any data</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>subject access request;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(d)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;not disclose or release any Shared Personal Data in response to a data subject</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>access request without first consulting with and obtaining the consent of the other party.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(e)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;assist the other party, at the cost of the other party, in responding to any request</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>from a data subject and in ensuring compliance with its obligations under the Data</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Protection Legislation with respect to security, breach notifications, impact</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>assessments and consultations with supervisory authorities or regulators;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(f)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;notify the other party without undue delay on becoming aware of any breach of</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the Data Protection Legislation;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(g)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;at the written direction of the Data Discloser, delete or return Shared Personal</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Data and copies thereof to the Data Discloser on termination of this Agreement</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>unless required by law to store the personal data;</span></p>
        <p><br/></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(h)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;use compatible technology for the processing of Shared Personal Data to ensure</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>that there is no lack of accuracy resulting from personal data transfers;</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(i)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;maintain complete and accurate records and information to demonstrate its</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>compliance with this clause 10 and allow for audits by the other party or the other</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>party&apos;s designated auditor; and</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>(j)</span></strong><span style={{fontSize:"11pt"}}>&nbsp;provide the other party with contact details of at least one employee as point of</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>contact and responsible manager for all issues arising out of the Data Protection</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Legislation, including the joint training of relevant staff, the procedures to be followed</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>in the event of a data security breach, and the regular review of the parties&rsquo;</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>compliance with the Data Protection Legislation.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>10.5&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>Indemnity</span></strong><span style={{fontSize:"11pt"}}>. Each party shall indemnify the other against all claims and proceedings</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>and all liability, loss, costs and expenses incurred by the other as a result of any</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>claim made or brought by a data subject or other legal person in respect of any loss,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>damage or distress caused to them as a result of any breach by the other party of</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the Data Protection Legislation by that party, its employees or agents, provided that</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>the indemnified party gives to the indemnifier prompt notice of such claim, full</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>information about the circumstances giving rise to it, reasonable assistance in</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>dealing with the claim and sole authority to manage, defend and/or settle it.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>11.</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><strong><span style={{fontSize:"11pt"}}>TERMINATION AND CONSEQUENCES OF TERMINATION</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>11.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Either Party may terminate this Agreement with immediate effect by giving the other</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Party written notice.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>11.2&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Termination of this Agreement does not affect your ongoing obligation to pay the Fees</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>for any Referrals in connection with Agreement prior to the date of termination.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>11.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>On termination of this Agreement, the following clauses shall continue in force: clause</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>4 (Your Obligations), clause 5 (Fees and Payments), clause 8 (Confidentiality), clause</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>10 (Data Protection) and clause 13 (Governing Law and Jurisdiction).</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>11.4&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Termination of this Agreement shall not affect any rights, remedies, obligations or</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>liabilities of the parties that have accrued up to the date of termination, including the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>right to claim damages in respect of any breach of the Agreement which existed at or</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>before the date of termination.</span></p>
        <p><br/></p>
        <p><br/></p>
        <p><br/></p>
        <p><br/></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>12.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>GENERAL</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.1&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Nothing in this Agreement is intended to, or shall be deemed to, establish any</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>partnership or joint venture between any of the Parties, constitute any Party the agent of</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>another Party, or authorise any Party to make or enter into any commitments for or on</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>behalf of any other Party.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.2</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Each Party confirms it is acting on its own behalf and not for the benefit of any other</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>person.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.3&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>This Agreement constitutes the entire Agreement between the Parties and supersedes</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>and extinguishes all previous agreements, promises, assurances, warranties,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>representations and understandings between them, whether written or oral, relating to</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>its subject matter.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.4&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>Each party acknowledges that in entering into this Agreement it does not rely on any</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>statement, representation, assurance or warranty (whether made innocently or</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>negligently) that is not set out in this Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.5&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>No variation of this Agreement shall be effective unless it is in writing and signed by the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Parties (or their authorised representatives).</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.6&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>This Agreement is personal to the Parties and neither Party shall assign, transfer,</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>mortgage, charge, subcontract, declare a trust over or deal in any other manner with</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>any of its rights and obligations under this Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.7&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>No failure or delay by a Party to exercise any right or remedy provided under this</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Agreement or by law shall constitute a waiver of that or any other right or remedy, nor</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>shall it prevent or restrict the further exercise of that or any other right or remedy.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>12.8</span><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span><span style={{fontSize:"11pt"}}>&nbsp;If any provision or part-provision of this Agreement is or becomes invalid, illegal or</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>unenforceable, it shall be deemed modified to the minimum extent necessary to make it</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>valid, legal and enforceable. If such modification is not possible, the relevant provision</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>or part-provision shall be deemed deleted. Any modification to or deletion of a provision</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>or part-provision under this clause shall not affect the validity and enforceability of the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>rest of this Agreement.</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><strong><span style={{fontSize:"11pt"}}>13.&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>&nbsp; &nbsp;&nbsp;</span></strong><strong><span style={{fontSize:"11pt"}}>GOVERNING LAW AND JURISDICTION</span></strong></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>This Agreement and any dispute or claim arising out of or in connection with it or its subject</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>matter or formation (including non-contractual disputes or claims) shall be governed by and</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>construed in accordance with the law of England and Wales. The courts of England and Wales</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>this Agreement or its subject matter or formation (including non-contractual disputes or claims).</span></p>
        <p><br/></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>This Agreement has been entered into by a duly authorised officer or representative of the</span></p>
        <p style={{textAlign:"justify"}}><span style={{fontSize:"11pt"}}>Parties.</span></p>
      </Container>: <Loading />}
    </Page>
  )
}

const Page = styled.div`
    background: ${COLORS.blue};
    padding: 5% 15%;
    min-height: 100vh;
`;

const Container = styled.div`
    background: ${COLORS.white};
    border: 2px solid ${COLORS.gray};
    padding: 20px;
    border-radius: 5px;
`;

export default ReferralAgreement
