import { collection, doc, getDoc, getDocs, limit, orderBy, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { getNumberSuffix } from "./custom-functions";
import { getUser } from "./Users";
import { createNotification } from "./Notifications";

export function sortBiddingMap(biddings) {
  // Sort the array based on the values (bids) in ascending order
  return biddings.sort((a, b) => a.bid - b.bid);
}


export const addBidding = async (listingId, agentId, currentBid, sellerId, postcode) => {
  try {
    const listingRef = doc(db, "listings", listingId);

    // Get the current data of the listing
    const listingSnapshot = await getDoc(listingRef);

    if(listingSnapshot.exists()){
      const listingData = listingSnapshot.data();
      let biddings = listingData.biddings || [];

      const indexToUpdate = biddings.findIndex(bidding => bidding.agentId === agentId);

      if(indexToUpdate !== -1){
        // if agent has already bid before, edit their bid
        biddings[indexToUpdate].bid = currentBid;
      }else{
        // if not, add their bid
        biddings.push({agentId: agentId, bid: currentBid});
      }

      biddings = await sortBiddingMap(biddings)

      // add bidding to the listing
      await updateDoc(listingRef, {
        biddings: biddings,
      });

      // notify the seller that a new bid has come
      const notifObject = {
        body: 'An agent has bid('+ currentBid +'%) on your listing (' + postcode + ')',
        icon: '../public/logo.png',
        data: {
          url: '/',
          action: 'View Listing'
        }
      }

      await createNotification(notifObject, sellerId)

      // notify all the other agents that they have been outbid
      if(biddings){
        biddings.forEach(async bidInfo => {
          const { agentId, bid } = bidInfo;

          if(bid > currentBid){
            const notifObject = {
              body: 'An agent has outbid you of '+ currentBid + '% in the listing of ' + postcode,
              icon: '../public/logo.png',
              data: {
                url: '/',
                action: 'View Biddings'
              }
            };
        
            // Create a notification for each agent without awaiting completion
            await createNotification(notifObject, agentId);
          }
        });
      }
    }
    

    console.log("Bid successful");
  } catch (error) {
    console.error("Error add bidding: ", error);
  }
};

export const getBiddings = async (biddings) => {
  try {
    const bidObj = [];

    // Create an array of promises
    const promises = biddings.map(({ agentId, bid }) => {
      return getUser(agentId).then((agent) => {
        const rank = bidObj.length + 1;
        const newBid = {
          id: agent.uid,
          Rank: `${rank}${getNumberSuffix(rank)}`,
          Name: agent.username,
          'Genie Score': agent.genie_score,
          Bid: bid + '%',
        };
        bidObj.push(newBid);
      });
    });    

    // Wait for all promises to resolve
    await Promise.all(promises);

    return bidObj;
  } catch (error) {
    console.error("Error in getting biddings: ", error);
    return [];
  }
};