import React from 'react'
import styled from 'styled-components'

const Image = (props) => {
  return (
    <Container image={props.image} />
  )
}

const Container = styled.div`
    width: 250px;
    height: 300px;
    background: transparent;
    background-image: url(${props => props.image}); // Use the props directly, not the entire URL
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;


export default Image
