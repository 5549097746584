import React from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import bg from '../../img/bg.jpeg'
import USbg from '../../img/USbg.jpg'
import logo from '../../img/logo.png'
import '../css/animations.css'
import BigButton from '../Buttons/BigButton'
import InputwithButton from '../Forms/InputwithButton'

const HomeBG = (props) => {
  const uType = props.uType;

  const title = (uType == "Agent") ? "Looking For More Local Sellers?" : "Create a Listing and find out how much your local estate agent will bid";
  const description = (uType == "Agent") ? "Bid against other estate agents for the right to list a sellers property based on your commission you will charge. <b>Do this <u>anonymously</u> until you win the listing!</b>" : "Find out which estate agent will charge you the lowest commission to sell your property. <b>Do this <u>anonymously</u> until you pick a winnng agent!<b>";

  return (
    <Container>
      <Back></Back>
      <Front>
        <Cont>
            <Flex width='50%'>
                <FlexBox>
                    <Title>{title}</Title>
                    <Description dangerouslySetInnerHTML={{ __html: description }}></Description>
                </FlexBox>
                <FlexBox style={{ paddingTop: '20px' }}>
                    {uType == "Seller" ? <InputwithButton /> : <a href='browse'><BigButton text='Browse Listings'/></a>}
                </FlexBox>
            </Flex>
            <LogoCont size='500px'>
                <Logo src={logo} className='floating' alt='The Agent Genie'/>
            </LogoCont>
        </Cont>
      </Front>
    </Container>
  )
}

const Container = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
`;

const Back = styled.div`
    background: ${ COLORS.blue_faded };
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
`;

const Front = styled.div`
    // background: brown;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    z-index: 999
`;

const Cont = styled.div`
    // background: rgba(0, 255, 0, 0.1);
    height: 90%;
    position: relative;
    display: flex;
`;

const Flex = styled.div`
    background: ${props => props.color};
    width: ${props => props.width};
    display: flex;
    flex-direction: column;
    padding: 100px;
    @media only screen and (max-width: 860px) {
        width: 100%;
        padding: 50px ;
    }
`;

const LogoCont = styled.div`
    background: ${props => props.color};
    width: ${props => props.size};
    height: ${props => props.size};
    position: absolute;
    right: 50px;
    @media only screen and (max-width: 860px) {
        display: none;
    }
`;

const FlexBox = styled.div`
    background: ${props => props.color};
    width: ${props => props.width};

    @media only screen and (max-width: 860px) {
        width: 100%;
    }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 60px 0px;
    @media only screen and (max-width: 560px) {
        width: 100%;
        padding: 0px;
        // flex-direction: column;
        margin: 20px 0px;
        gap: 8px;
        // background: blue
    }
`;

const Highlight = styled.span`
    color: ${ COLORS.yellow };
`;

const Logo = styled.img`
    // background: blue;
    width: 80%;
    height: 80%;
    margin-top: 50px;
    @media only screen and (max-width: 780px) {
        display: none;
    }
`;

const Title = styled.h1`
    color: ${COLORS.white};
    font-weight: 900;
    font-size: 40px;
    margin: 0;

    @media only screen and (max-width: 780px) {
        font-size: 24px;
    }
`;

const Number = styled.h1`
    color: ${ COLORS.white };
    // background: red;
    margin: 0;
    @media only screen and (max-width: 780px) {
        font-size: 18px
    }
`;

const Description  = styled.p`
    color: ${ COLORS.white };
    // background: blue;
    margin: 0;
    @media only screen and (max-width: 780px) {
        font-size: 14px
    }
`;

export default HomeBG
