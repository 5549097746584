import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/variables'
import TopNavbar from '../components/Navbar/Navbar'
import Chat from '../components/Chat/Chat'
import BigButton from '../components/Buttons/BigButton'
import CarouselImg from '../components/Carousel/CarouselImg'
import { useNavigate, useParams } from 'react-router-dom'
import PortalItem from '../components/Containers/PortalItem'
import { addDealListingLink, addImagestoPortal, getImagesofPortal, getLinkofPortal, getPortalofListing } from '../backend-functions/Listings'
import Modal from '../components/Modal/Modal'
import InputField from '../components/Forms/InputField'
import InputMultipleFiles from '../components/Forms/InputMultipleFiles'
import { fileUpload } from '../backend-functions/custom-functions'
import Error from '../components/Forms/Error'
import { AuthContext } from '../context/AuthContext'
import AddPrice from '../components/Forms/AddPrice'
import Confetti from '../components/Background/Confetti'
import RatingScore from '../components/Containers/RatingScore'
import { rateAgent } from '../backend-functions/Users'
import PaymentMethodForm from '../components/Forms/PaymentMethodForm'
import { stripePromise } from '../stripe'
import { getInvoice } from '../backend-functions/Payments'
import StatusToolTip from '../components/Modal/StatusToolTip'
import Item from '../components/Containers/Item'

const Portal = () => {
  const { id } = useParams();
  const { userDetails } = useContext(AuthContext);

  const deal = new URLSearchParams(window.location.search).get('deal');

  const [ info, setInfo ] = useState(null);
  const [ invoice, setInvoice ] = useState(null);
  const [ imgs, setImgs ] = useState(null);
  const [ portalLink, setPortalLink ] = useState(null);
  const [rating, setRating] = useState(null);
  const [commission, setCommission] = useState(null);

  const [ imgmodal, setImgModal ] = useState(false);
  const [ linkmodal, setLinkModal ] = useState(false);
  const [ pricemodal, setPriceModal ] = useState(false);
  const [ ratemodal, setRateModal ] = useState(false);
  const [ invoicemodal, setInvoiceModal ] = useState(false);

  const [ selectedImages, setSelectedImages ] = useState([]);
  const [ link, setLink ] = useState('');
  const [ site, setSite ] = useState('');
  const [ message, setMessage ] = useState('Please Wait...');
  const [ ratingMsg, setRatingMsg ] = useState('');

  const [error, setError] = useState('');
  const [priceType, setPriceType] = useState('');

  const [ confetti, setConfetti ] = useState(deal && deal === 'successful');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unsubscribeListing = getPortalofListing(id, async (listing) => {
          setInfo(listing);

          if(!listing){
            setMessage('Portal is not found');
          }
          if(listing && listing.sold_price > 0){
            setConfetti(true);

            const soldPrice = parseFloat(listing.sold_price);
            const bidPercentage = parseFloat(listing.bid);
            const totalPrice = (soldPrice * (bidPercentage / 100) * 0.2);
            setCommission(totalPrice)

            // get the invoice of the deal listing
            if(userDetails.usertype === 'Agent'){
              const invoice = await getInvoice(id, userDetails.uid);
    
              if(invoice){
                setInvoice(invoice);
            
                // ask agent to pay if the invoice is not yet paid
                if(invoice.payment_status !== "PAID"){
                  setInvoiceModal(true);
                }
              }
            }            

            if(userDetails.usertype === 'Seller' && !listing.isRated && listing.agent_id){
              setRateModal(true);
            }
          }
          if(userDetails.typehierarchy > 2 && userDetails.uid !== listing.seller_id && userDetails.uid !== listing.agent_id){
            setInfo(null);
            navigate("/");
          }
        });

        const unsubscribeImages = getImagesofPortal(id, (image) => {
          setImgs(image);
        });

        const unsubscribeLinks = getLinkofPortal(id, (link) => {
          setPortalLink(link);
        });

        return () => {
          unsubscribeListing();
          unsubscribeImages();
          unsubscribeLinks();
        };
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleImageUpload = async (e) => {
    e.preventDefault();

    if (selectedImages.length > 0) {
      setError("Please Wait...");
      try {
        await Promise.all(
          selectedImages.map(async (image) => {
            const imageFile = await fileUpload(
              image,
              "deal_listings/" + id + "/" + image.name
            );
            await addImagestoPortal(imageFile, id);
          })
        );
        setError("All images uploaded successfully");
        setImgModal(!imgmodal);
      } catch (error) {
        console.error("Error uploading images:", error);
        setError("Error uploading images. Please try again.");
      }
    } else {
      setError("Please upload at least one image");
    }
  };

  const handleAddLink = async (e) => {
    e.preventDefault();

    if(link !== ""){
      setError('Please wait...');
      await addDealListingLink(id, site, link);
      setError('');
      setLinkModal(!linkmodal);
    }else{
      setError('Link cannot be empty');
    }
  }

  const handleRating = async () => {
    if(ratingMsg === '' || !rating){
      setError('Please fill in all the details')
      return null;
    }

    setError('Please wait...')
    // alert(rating + " " + ratingMsg + " " + info.agent_id);
    const response = await rateAgent(info.agent_id, info.seller_id, rating, ratingMsg, id);

    if(response){
      setRateModal(false);
      alert("Thank you for sending us your feedback.");
    }
  }

  return (
    <div>
     {confetti && <Confetti setConfetti={setConfetti} />}
     <Container height="10vh">
        <TopNavbar bgColor={COLORS.blue} btncolor={COLORS.yellow}></TopNavbar>
      </Container>

      <Modal isOpen={imgmodal} setOpen={setImgModal}>
        <InputMultipleFiles selectedImages={selectedImages} setSelectedImages={setSelectedImages} onClick={handleImageUpload} error={error} />
      </Modal>

      <Modal isOpen={linkmodal} setOpen={setLinkModal}>
        <form onSubmit={handleAddLink}>
          <BigText>Add Portal Link</BigText>
          <Error error={error} />
          <FlexBetween style={{alignItems: 'start'}}>
            <InputField setValue={setSite} type='text' label='Site Name:' placeholder="Rightmove, Zoopla, etc." />
          </FlexBetween>
          <br/>
          <FlexBetween style={{alignItems: 'start'}}>
            <InputField setValue={setLink} type='text' label='Link URL:' />
          </FlexBetween>
          <br/>
          <BigButton bgColor={COLORS.blue} text='Submit' type='submit' color={COLORS.white} />
        </form>
      </Modal>

      {/* ADD PRICES */}
      <Modal isOpen={pricemodal} setOpen={setPriceModal}>
        {info && <AddPrice dealId={id} priceType={priceType} setModal={setPriceModal} country={info.country} setConfetti={setConfetti} setInvoiceModal={setInvoiceModal} bid={info.bid} agentID={info.agent_id} postCode={info.postcode}/>}
      </Modal>

      {/* NOTIFICATION FOR INVOICE FOR AGENTS ONLY */}
      {info && invoice && <Modal isOpen={invoicemodal} setOpen={setInvoiceModal}>
        <BigText>Congratulations</BigText>
        <p>Well done for selling the property! <br/> 
        As per the Terms and Conditions, we will be collecting a 20% commission from your bid. <br/>
        Your account will be <strong>temporarily restricted</strong> until you have paid the said amount. Thank you.</p>
        <PaymentMethodForm amount={commission} listingID={info.listing_id} invoice={invoice} country={info.country} />
      </Modal>}

      {/* NOTIFICATION FOR RATING THE AGENT FOR SELLERS ONLY */}
      <Modal isOpen={ratemodal} setOpen={setRateModal}>
        <BigText>Congratulations</BigText>
        <p>Rate Your Seller</p>
        <Img/>
        {error}
        <RatingScore setRating={setRating}/>
        <br/>
        <TextArea placeholder='Let us know your experience with your Agent...' value={ratingMsg} onChange={(e) => {setRatingMsg(e.target.value)}}></TextArea>
        <br/>
        <BigButton text='Submit' bgColor={COLORS.blue} color={COLORS.white} onClick={handleRating}/>
      </Modal>

      {info && <Container>
        <Cont>
            <FlexBetween>
                <BigText>Track your progess</BigText>
                {info && info.sold_price == 0 &&
                <StatusToolTip text={info.offer_price > 0 ? "Sold Price" : "Offer Accepted"}>
                  {info.offer_price == 0 && <BigButton text="Offer Accepted?" bgColor={COLORS.light_blue} color={COLORS.white} onClick={()=>{setPriceModal(true); setPriceType('offer_price')}} />}
                  {info.offer_price > 0 && <BigButton text="Sold Price?" bgColor={COLORS.light_blue} color={COLORS.white} onClick={()=>{setPriceModal(true); setPriceType('sold_price')}} />}
                </StatusToolTip>
                }
            </FlexBetween>
            <br/>
            <PortalItem data={info} />
            <br/>
            <ChatCont>
                <Chat dealId={id} postCode={info.postcode} otherPic={userDetails.usertype === "Seller" ? info.agent_img : info.seller_img} status={info.sold_price > 0 ? 'Done' : 'Ongoing'} otherPerson={userDetails.usertype === "Seller" ? info.agent : info.seller}/>
            </ChatCont>
            <br/>
            <FlexBetween>
                <BigText>Photos</BigText>
            </FlexBetween>
            <div>
              Upload images relating to your property listing.
            </div>
            <br/>
            <div>
                {imgs && <CarouselImg data={imgs} />}
            </div>
            <br/>
            <FlexCenter>
                <BigButton onClick={() => info.sold_price > 0 ? alert('Listing has been sold. You cannot upload an image anymore.') : setImgModal(!imgmodal)} text="+ Add More Photos" bgColor={COLORS.light_blue} color={COLORS.white}></BigButton>
            </FlexCenter>
            <br/>
            <FlexBetween>
                <BigText>Portal Links</BigText>
                <BigButton onClick={() => setLinkModal(!linkmodal)} text="+ Add Link" bgColor={COLORS.light_blue} color={COLORS.white}></BigButton>
                {/* {portalLink && <a href={portalLink} target='blank'><BigButton text='Link' bgColor={COLORS.blue} color={COLORS.white}/></a>} */}
            </FlexBetween>
            <div>
              Add your links to {info.country === 'US' ? 'Zillow' : 'Rightmove, Zoopla,'} and other real estate market sites.
            </div>
            <br/>
            {/* {portalLink && <ChatCont>
                <Link src={portalLink}></Link>
            </ChatCont> } */}
            {portalLink && portalLink.length > 0 && portalLink.map(portal => {
              return <Item data={portal} />
            })
            }
            <br/><br/>
            <FlexCenter style={{marginTop: "5%"}}>
                <Clickable href="mailto:theagentgenie@gmail.com">If you need any help, please send us a ticket</Clickable>
            </FlexCenter>
        </Cont>
        <br/>
      </Container>}
      {!info && <BigText style={{margin: '20%'}}>{message}</BigText>}
    </div>
  )
}

const Container = styled.div`
    position: relative;
    height: ${ props => props.height};
`;

const Cont = styled.div`
    width: 80%;
    margin: 0 10%;
    @media only screen and (max-width: 780px) {
        width: 90%;
        margin: 0 5%;
    }
`;

const BigText = styled.h1`
    @media only screen and (max-width: 780px) {
        font-size: 20px;
    }
`;

const ChatCont = styled.div`
    width: 100%;
    height: 500px;
`;

const FlexBetween = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Link = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    background: ${COLORS.gray}
`;

const Clickable = styled.a`
    background-color: transparent;
    border: 0;
    color: ${COLORS.blue};
    font-size: 16px;
    cursor: pointer;
`;

const Img = styled.img`
    width: 150px;
    height: 150px;
    background: gray;
    border-radius: 50%;
`;

const TextArea = styled.textarea`
    max-width: 100%;
    min-width: 100%;
    resize: none;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid ${COLORS.gray};
`;

export default Portal
