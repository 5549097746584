import React, { useContext, useState } from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import InputField from './InputField';
import BigButton from '../Buttons/BigButton';
import Error from './Error';
import { addDealPrice } from '../../backend-functions/Listings';
import { AuthContext } from '../../context/AuthContext';

const AddPrice = ({dealId, priceType, setModal, setConfetti, setInvoiceModal, bid, agentID, postCode, country}) => {
  const { userDetails } = useContext(AuthContext);

  const type = priceType === "offer_price" ? "offer" : "sell";
  const description = priceType === "offer_price" ? "How much is the offer that has been accepted?" : "How much is the property sold for?";

  const [ price, setPrice ] = useState('0');

  const [ error, setError ] = useState('');

  function extractNumbersFromString(input) {
    // Check if input is a string
    if (typeof input !== 'string') {
      return 'Input is not a string.';
    }
  
    // Use regular expression to match numbers in the string
    const numbers = input.replace(/\D/g, ''); // Replace non-digit characters with empty string
  
    return numbers;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const priceValue = extractNumbersFromString(price);

    // if(userDetails.isDelete){
    //   alert("Your account is currently RESTRICTED. Please settle your dues or contact support@theagentgenie.com");
    //   return;
    // }

    if(priceValue < 1 || isNaN(priceValue)){
      if(country === 'US'){
        setError('Price cannot be $' + price);
        return;
      }
        setError('Price cannot be £' + price);
        return;
    }

    setError('Please Wait...');
    const finalPrice = await addDealPrice(dealId, priceType, priceValue, bid, agentID, postCode);

    if(finalPrice){
        setModal(false);
        setConfetti(true);
    }

    if(priceType === 'sold_price' && userDetails.usertype === 'Agent'){
      setInvoiceModal(true);
      // send invoice to email
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <BigText>{description}</BigText>
      <Error error={error} />
      <InputContainer>
        { country === 'US' ? '$' : '£'}
        <InputField value={price} setValue={setPrice} borderless={true} />
      </InputContainer>
      <br/>
      <BigButton text="Submit" bgColor={COLORS.blue} color={COLORS.white} type="submit" />
    </form>
  )
}

const BigText = styled.h2`
    @media only screen and (max-width: 780px) {
        font-size: 20px;
    }
`;

const InputContainer = styled.div`
    width: 100%;
    border: 1px solid ${COLORS.blue};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
`;

export default AddPrice
