import React, { useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../variables'
import { Link } from 'react-router-dom';

const InputwithButton = () => {
    const [postCode, setPostCode] = useState('');

    const handleChange = (e) => {
        setPostCode(e.target.value);
    }

  return (
    <Container>
        <Input placeholder='Postal Code' type='text' value={postCode} onChange={handleChange}></Input>
        <Link to={`/create?postcode=${postCode}`}>
            <Button>Create Listing</Button>
        </Link>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    background: ${COLORS.white};
    padding: 5px;
    border-radius: 10px;
    justify-content: center;
`;

const Input = styled.input`
    width: 100%;
    box-sizing:border-box;
    height: 100%;
    padding: 10px;
    background: none;
    border-color: transparent;

    &:focus {
        outline: none;
    }
`;

const Button = styled.button`
    background: ${COLORS.blue};
    color: ${COLORS.white};
    padding: 10px;
    border-radius: 10px;
    width: 150px;
    font-family: Poppins;
    cursor: pointer;
`;

export default InputwithButton
