import React from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import { useState } from 'react'

const ImgUpload = ({
  id,
  setError,
  selectedImage,
  selectedFileName,
  setSelectedImage,
  setSelectedFileName,
}) => {
  const [preview, setPreview] = useState(null);
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(file);
        setPreview(URL.createObjectURL(file));
        setSelectedFileName(file.name);
        setError(""); // Clear any previous error
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
      setError("Please select an image.");
    }
  };

  return (
    <ImageUploadContainer>
      {selectedImage ? (
        <Flex>
          <ImagePreview src={preview} alt="Preview" />
          {selectedFileName}
        </Flex>
      ) : (
        <Flex>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="currentColor"
            class="bi bi-cloud-arrow-up-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
          </svg>
          Drag and Drop <br /> or <br />
          <CustomInputLabel htmlFor={id}>Browse</CustomInputLabel>
        </Flex>
      )}
      <Input
        type="file"
        id={id}
        name={id}
        accept="image/*"
        onChange={handleImageChange}
      />
    </ImageUploadContainer>
  );
};

const ImageUploadContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${COLORS.blue};
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
`;

const ImagePreview = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 16px;
`;

const CustomInputLabel = styled.label`
  display: inline-block;
  padding: 5px 20px;
  background-color: ${COLORS.blue};
  color: ${COLORS.white};
  border-radius: 10px;
  cursor: pointer;
`;

const Flex = styled.div`
  color: ${COLORS.blue_faded};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
`;

const Input = styled.input`
  position: absolute;
  left: -9999px;
  top: -9999px;
`;

const Error = styled.p`
  font-style: italic;
  color: ${COLORS.red};
`;

export default ImgUpload
