import React from 'react'
import { COLORS } from '../variables'
import styled from 'styled-components'
import bg from '../../img/profile-1.jpg'

const UserLists = ({users, url, baseUrl}) => {
  if(!users)
    return null;
  
  return (
    <div style={{ textAlign: 'right' }}>
      {users.length === 0 ? (
        <>
          <br></br>
          <Text align='center'>No data yet</Text>
        </>
      ) : (
        <>
          {users.map((user) => (
            <Container key={user.id} href={`${url}${user.id}`}>
              <Image src={bg} />
              <Text align='start'>{user.name} • {user.postcode}</Text>
            </Container>
          ))}
          <Link href={baseUrl}>See more...</Link>
        </>
      )}
    </div>
  )
}

const Container = styled.a`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    gap: 15px;
`;

const Image = styled.img`
    height: 38px;
    width: 38px;
    border-radius: 50%;
    object-fit: cover;
`;

const Text = styled.p`
    margin: 0;
    font-size: 16px;
    width: 100%;
    color: ${COLORS.blue};
    text-align: ${props => props.align};
`;

const Link = styled.a`
color: ${COLORS.blue};
`;

export default UserLists
