import React from 'react'
import styled from 'styled-components'
import Item from '../Containers/Item'
import { COLORS } from '../variables'
import Loading from '../Modal/Loading'

const Carousel = ({isNull, ...props}) => {
  return (
    <Container shadow={props.shadow}>
        <HideonSmallScreen><Item data={props.header}/></HideonSmallScreen>
        {props.listings.length > 0 && props.listings.map(list => {
            return <Item data={list} type={props.type} showBid={props.showBid} winningId={props.winningId} {...props}/>
        })}
        {props.listings.length === 0 && !isNull &&
          <center><p>No data yet</p></center>
        }
        {props.link && <div style={{width: '100%', textAlign: 'center', marginTop: '15px', display: props.page ? 'none' : 'block'}}>
          <More href={props.link}>See more...</More>
        </div>}

        {/* Loading */}
      { props.listings.length < 1 && isNull && <Loading/> }
    </Container>
  )
}

const Container = styled.div`
    box-shadow: ${props => props.shadow ? '0' : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'};
    padding: 20px 20px;
    position: relative;
    @media only screen and (max-width: 780px) {
      padding: 0px;
      box-shadow: none;
    }
`;

const More = styled.a`
    color: ${COLORS.blue};

    @media only screen and (max-width: 780px) {
      font-size: 16px;
      margin: 10px;
    }
`;

const HideonSmallScreen = styled.span`
  @media only screen and (max-width: 780px) {
    display: none;
  }
`;


export default Carousel
