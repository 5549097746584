import React, { useEffect, useState } from 'react'
import { COLORS } from '../../components/variables'
import styled from 'styled-components'
import Sidebar from '../../components/Navbar/Sidebar'
import InputwithIcon from '../../components/Forms/InputwithIcon'
import InputField from '../../components/Forms/InputField'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import DropdownField from '../../components/Forms/DropdownField'
import Pagination from '../../components/Pagination/Pagination'
import { getAgents, updateApplicationStatus } from '../../backend-functions/Users'
import Modal from '../../components/Modal/Modal'
import SmallButton from '../../components/Buttons/SmallButton'

const ApproveAgents = () => {
  const filterOptions = [
    { value: 'All', label: 'All' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Denied', label: 'Denied' },
    { value: 'Pending', label: 'Pending' },
  ];

  const [ selectedID, setSelectedID ] = useState('');
  const [ list, setList ] = useState([]);
  const [ allAgents, setAllAgents ] = useState([]);
  const header = {img: '', name: 'Name', country: 'Country', code: 'Post Code', usertype: 'Type', type: 'header', status: 'Status', button1: '', button2: '', button3: ''};
  const [ selectedFilter, setSelectedFilter ] = useState('All');
  const [ keyword, setKeyword ] = useState('');
  const [ action, setAction ] = useState('');
  const [ confirmModal, setConfirmModal ] = useState(false);
  const [ isDisabled, setIsDisabled ] = useState(false);

  const [ isNull, setIsNull ] = useState(true);

  const handleApprove = (id) => {
    setSelectedID(id);
    setConfirmModal(true);
    setAction('approve');
  }

  const handleDeny = (id) => {
    setSelectedID(id);
    setConfirmModal(true);
    setAction('deny');
  }

  const handleConfirmation = async () => {
    setIsDisabled(true);
    const newStatus = action === "approve" ? "Approved" : "Denied";
    await updateApplicationStatus(selectedID, newStatus);
    setConfirmModal(false);
    setIsDisabled(false);
  }

  // fetch agents list from database
  getAgents(handleApprove, handleDeny, keyword, selectedFilter, (usersData) => {
    setAllAgents(usersData)
    setList(usersData);
  
    if(usersData.length < 1){
      setIsNull(false)
    }
  });

  // useEffect(() => {
  //   const unsubscribe = getAgents(handleApprove, handleDeny, keyword, selectedFilter, (usersData) => {
  //     setList(usersData);

  //     if(usersData.length < 1){
  //       setIsNull(!isNull)
  //     }
  //   });

  //   // To unsubscribe the real-time listener when the component unmounts or when you no longer need it
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  /* [keyword, selectedFilter, handleApprove, handleDeny] */

  // Filter allAgents
  useEffect(() => {
    // Filter list based on keyword and selectedFilter
    const newList = allAgents.filter(agent => {
      // Filter by keyword
      const isMatchingKeyword = agent.Name.toLowerCase().includes(keyword.toLowerCase())

      // Filter by selectedFilter (if it's not 'All')
      const isMatchingFilter = selectedFilter === 'All' || agent.Status === selectedFilter

      const isReturned = isMatchingFilter && isMatchingKeyword
  
      return isReturned;
    });
    setList(newList)
  }, [keyword, selectedFilter])

  return (
    <Page>
      {/* CONFIRMATION MODAL */}
      <Modal isOpen={confirmModal} setOpen={setConfirmModal}>
        <h4>Are you sure you want to <i>{action}</i> account?</h4>
        <Flex>
          <SmallButton text='Yes' bgColor={COLORS.green} color={COLORS.blue} onClick={handleConfirmation} disabled={isDisabled} />
          <SmallButton text='No' bgColor={COLORS.red} color={COLORS.white} onClick={() => {setConfirmModal(false)}} disabled={isDisabled} />
        </Flex>
      </Modal>

      <Sidebar approveAgents={true} />
      <Content>

      <h2>Approve Agents</h2>   
      {/* Search and Filter */}
      <SpaceBetween>
        <Flex>
          <InputwithIcon icon={faSearch} placeholder='Search by Name' value={keyword} onChange={(e) => {setKeyword(e.target.value)}} />
        </Flex> 
        <Flex>
          <p>Filter:</p>
          <DropdownField options={filterOptions} selectedValue={selectedFilter} setSelectedOption={setSelectedFilter} />
        </Flex>
      </SpaceBetween>
      <br></br>
      {/* Data Table */}
      <Pagination data={list} header={header} isNull={isNull} />
      </Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`;

const Content = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex-grow: 1;
  border-radius: 10px;
`;

const SpaceBetween = styled.div`
  display flex;
  justify-content: space-between;
  width: 50%;
  gap: 20px;

  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Flex = styled.div`
  display flex;
  width: 50%;
  align-items: center;
  gap: 5px;
`;

export default ApproveAgents
